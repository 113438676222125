import React, { useState } from "react";
import { Container, Row, Col, Button, Modal} from "reactstrap";
import VerticalBar from "./AppointmentTrendBarChart";
import DonutChart from "./DonutChart";
import ReferralTrendBarChart from "./ReferralTrendBarChart";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { Hux } from "./../../../hoc/Hux"
import "../Default/Index.css"
import axios from "axios";
import { toastr } from 'react-redux-toastr'



const Default = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [secretKey, setSecretKey] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;
  const handleVerify = () => {
    if (!secretKey) {
      setErrorMessage('Please enter a secret key.');
      return;
    }
    const AuthData = {
      secret_key: secretKey,
    };
    const signinurl = `${API_ENDPOINT}api/adminlogin/`;
    axios.post(signinurl, AuthData)
      .then(response => {
        const accessToken = response.data.token;
        localStorage.removeItem('microServiceSidebar')
        localStorage.setItem('microServiceSidebar', 1);
        // localStorage.setItem('accessToken', `Bearer ${accessToken}`);
        localStorage.setItem("accessToken", "Bearer " + accessToken);
        setModalIsOpen(false);
        setErrorMessage('');
        toastr.success('Successfully verified!', 'Logged in as Super user');
        setTimeout(() => {
          window.location = "/authservices"
        }, 1500);
        // window.location = "/authservices"
      })
      .catch(error => {
        setErrorMessage('Invalid secret key. Please try again.');
        console.log(error)
      });
  };

  const handleSecretKeyChange = (event) => {
    setSecretKey(event.target.value);
  };

  return (
    <Hux>
      <Container fluid>
        <Header>
          <HeaderTitle>Welcome to Equipo Admin Dashboard</HeaderTitle>
          <div style={{ width: "100%" , display:"flex", justifyContent:"end"}}>
              <Button onClick={() => setModalIsOpen(true)}>
                External API Services
              </Button>
          </div>
        </Header>
        <Row className="f-row" >
          <Col md="3">
            <DonutChart />
          </Col>
          <Col md="9" >
            <div className="referral-card">
              <ReferralTrendBarChart />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <VerticalBar />
          </Col>
        </Row>

        <Modal isOpen={modalIsOpen} toggle={() => setModalIsOpen(false)}>
          <div className="modal-content-api">
            <div className="subscribe">
              <p>LOGIN</p>
              <input type="text" name="secretKey" className="subscribe-input" placeholder="Enter your secret key" value={secretKey} onChange={handleSecretKeyChange} />
              <br />
              {errorMessage && <div style={{ color: 'red', marginTop: 22, fontSize: 14 }}>{errorMessage}</div>}
              <div className="submit-btn" onClick={handleVerify}>Verify</div>
            </div>
          </div>
        </Modal>
      </Container>
    </Hux>
  );
};

export default Default;