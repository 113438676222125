import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button } from 'reactstrap'
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';

function CustomDataRangePicker(props) {
  const [showDateRange, setShowDateRange] = useState(false)

  const parentsetStartDate = props.setStartDate
  const parentsetEndDate = props.setEndDate
  const buttonClick = props.buttonClickReset

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {

    parentsetStartDate(startDate);
    parentsetEndDate(endDate)

  }, [startDate, endDate])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    setStartDate("")
    setEndDate("")
  }, [buttonClick])


  const today = () => {
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - 0);

    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() - 0);

    setStartDate(startDate);
    setEndDate(endDate);
  }

  const yesterday = () => {
    const currentDate = new Date();

    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - 1);

    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() - 1);

    setStartDate(startDate);
    setEndDate(endDate);
  }


  const thisWeek = () => {
    const currentDate = new Date();
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  }
  const lastWeek = () => {
    const currentDate = new Date();
    const startOfLastWeek = new Date(currentDate);
    startOfLastWeek.setDate(currentDate.getDate() - currentDate.getDay() - 7);

    const endOfLastWeek = new Date(startOfLastWeek);
    endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);

    setStartDate(startOfLastWeek);
    setEndDate(endOfLastWeek);
  }


  const last30days = () => {
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(endDate.getDate() - 30);

    setStartDate(startDate);
    setEndDate(endDate);
  }


  const thisMonth = () => {
    const startOfMonth = moment.utc().startOf('month');
    const endDate = moment().endOf('month');
    setStartDate(startOfMonth.toDate());
    setEndDate(endDate.toDate());
  }

  const yearToDate = () => {
    const today = moment.utc();
    const startOfYear = moment.utc().startOf('year');
    setStartDate(startOfYear.toDate());
    setEndDate(today.subtract(0, 'days').toDate());
  }

  return (
    <>
      <Row>
        <Col xs={10}>
          {
            (startDate && endDate) && (
              <div className="input-container" >
                <Input
                  style={{ cursor: "pointer" }}
                  type='text'
                  onClick={() => setShowDateRange(true)}
                  value={moment(startDate).format("DD-MMM-YYYY") + ' to ' + moment(endDate).format("DD-MMM-YYYY")}
                />
                <FontAwesomeIcon icon={faCalendarDays} className="calendar-icon"
                  onClick={() => setShowDateRange(true)} style={{ cursor: "pointer" }} />
              </div>
            )
          }
          {
            (startDate === "" && endDate === "") && (
              <div className='input-container'>
                <Input type='text' placeholder='Select Date Range' onClick={() => setShowDateRange(true)} style={{ cursor: "pointer" }} />
                <FontAwesomeIcon icon={faCalendarDays} className="calendar-icon" onClick={() => setShowDateRange(true)} style={{ cursor: "pointer" }} />
              </div>
            )}

        </Col>
      </Row>
      {
        showDateRange &&
        <Row>
          <Col className='custom-date-range' style={{ width: '400px', height: '300px' }}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
              showYearDropdown
              dateFormat="DD/MM/YYYY"
              showMonthDropdown
              inline
              utc
            />

            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
              showYearDropdown
              dateFormat="DD/MM/YYYY"
              inline
              showMonthDropdown
              utc
            />
            <div className='custom-date-range-selections' style={{ height: '300px' }}>
              <ul>
                <li onClick={today}>Today</li>
                <li onClick={yesterday}>Yesterday</li>
                <li onClick={thisWeek}>This week</li>
                <li onClick={lastWeek}>Last week</li>
                <li onClick={last30days}>Last 30 days</li>
                <li onClick={thisMonth}>This month</li>
                <li onClick={yearToDate}>YTD</li>
              </ul><br/>
              <Button style={{ marginLeft: "10px" }} onClick={() => setShowDateRange(false)} className='btn btn-success'
                disabled={startDate && endDate ? false : true}
                title={startDate && endDate ? '' : 'Select Date Range'}>Ok</Button>
              <Button style={{ marginLeft: "10px" }}
                onClick={() => {
                  setShowDateRange(false)
                  setStartDate("")
                  setEndDate("")
                }} className='btn btn-danger'>Cancel</Button>
            </div>
          </Col>
        </Row>
      }
    </>
  )
}

export default CustomDataRangePicker







