import React, { useEffect, useState, useRef } from 'react'
import {
    Breadcrumb, BreadcrumbItem, Button, Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Modal, ModalBody, ModalHeader,
    ModalFooter
} from 'reactstrap'
import Select from 'react-select'
import Cookie from 'js-cookie';
import Header from '../../components/Header';
import HeaderTitle from '../../components/HeaderTitle';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AxiosInter from './../../AxiosInter';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons' // eslint-disable-line
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // eslint-disable-line
import EditHL7Modal from '../../newComponents/utilities/EditHL7Modal'; // eslint-disable-line
import moment from "moment";// eslint-disable-line
import MomentConfig from '../../common/MomentConfig' // eslint-disable-line
import Spinner from 'react-bootstrap/Spinner';
import { toastr } from "react-redux-toastr";
import Datetime from 'react-datetime';
import { FaCalendarAlt } from 'react-icons/fa';
import "react-datetime/css/react-datetime.css";

const blankState = (
    <Card className="blank-state">
        <CardBody>
            <h3>No Records Available</h3>
        </CardBody>
    </Card>
)

// const CustomDatePickerInput = ({ value, onClick }) => (
//     <div className="custom-datepicker-input">
//         <input
//             type="text"
//             className="custom-datepicker-input-field"
//             value={value}
//             onClick={onClick}
//             placeholder='Select Imported Date'
//         />
//         <FontAwesomeIcon icon={faCalendar} className="calendar-icon" onClick={onClick} />
//     </div>
// );

const HL7 = () => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");
    const [blankStateStatus, setBlankStateStatus] = useState(false)
    const [hl7data, setHl7Data] = useState([])
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [totalSize, setTotalSize] = useState(0); // eslint-disable-line
    const [offset, setOffset] = useState(0);
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [numberOfRecords, setNumberOfRecords] = useState(0)
    const [searchKeyword, setSearchKeyword] = useState("")
    const [clickedRowData, setClickedRowData] = useState()// eslint-disable-line
    const [editHL7Modal, setEditHL7Modal] = useState(false) // eslint-disable-line
    const [externalOrderId, setExternalOrderId] = useState("") // eslint-disable-line
    const [orderSerialNumber, setOrderSerialNumber] = useState("") // eslint-disable-line
    const [updatedTime, setUpdatedTime] = useState("") // eslint-disable-line
    const [isCSVGeneratedOn, setIsCSVGeneratedOn] = useState("") // eslint-disable-line
    const [isCSVGenerated, setIsCSVGenerated] = useState("") // eslint-disable-line
    const [isInsuranceETLProcessed, setIsInsuranceETLProcessed] = useState("") // eslint-disable-line
    const [isInsuranceETLProcessedOn, setIsInsuranceETLProcessedOn] = useState("") // eslint-disable-line
    const [isReferralETLProcessed, setIsReferralETLProcessed] = useState("") // eslint-disable-line
    const [isReferralETLProcessedOn, setIsReferralETLProcessedOn] = useState("") // eslint-disable-line
    const [isPatientETLProcessed, setIsPatientETLProcessed] = useState("") // eslint-disable-line
    const [isPatientETLProcessedOn, setIsPatientETLProcessedOn] = useState("") // eslint-disable-line
    const [selectedStatus, setSelectedStatus] = useState("") // eslint-disable-line
    const [jsonModalData, setJsonModalData] = useState('');
    const [jsonModalVisible, setJsonModalVisible] = useState(false);
    const [importedOn, setImportedOn] = useState(moment().startOf('day'));
    const [importedOnEndDate, setImportedOnEndDate] = useState(moment());
    const [fromState, setFromState] = useState("")
    const [reset, setReset] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [clear, setClear] = useState(0)
    const [stateOptions, setStateOptions] = useState([]);
    const [countFieldName, setCountFieldName] = useState("")
    const [notProcessedFilter, setNotProcessedFilter] = useState(null)
    const fileExportName = "HL7 Clinical Import Log";
    // const fileName = `Equipo-${fileExportName}-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`;
    const fileName = `Equipo-${fileExportName}-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`;

    const [isCalendarOpen, setIsCalendarOpen] = useState(false); // eslint-disable-line
    const [isCalendarOpenEndDate, setIsCalendarOpenEndDate] = useState(false); // eslint-disable-line
    const datetimeRef = useRef(null);
    const datetimeRefEndDate = useRef(null);

    // const statusOptions = [
    //     { value: "1", label: "Completed" },
    //     { value: "0", label: "Processing" },
    // ];

    const handleApply = () => {
        setImportedOn(importedOn);
        if (
            datetimeRef.current &&
            typeof datetimeRef.current._closeCalendar === "function"
        ) {
            datetimeRef.current._closeCalendar(); // Use _closeCalendar if it's a function
        }
        setIsCalendarOpen(false); // Update state to reflect calendar closed
    };

    const handleApplyEndDate = () => {
        setImportedOnEndDate(importedOnEndDate);
        if (
            datetimeRefEndDate.current &&
            typeof datetimeRefEndDate.current._closeCalendar === "function"
        ) {
            datetimeRefEndDate.current._closeCalendar(); // Use _closeCalendar if it's a function
        }
        setIsCalendarOpenEndDate(false); // Update state to reflect calendar closed
    };
    const handleChangeStartDate = (newDate) => {
        setImportedOn(newDate);
        if (importedOnEndDate && newDate.isAfter(importedOnEndDate)) {
            setImportedOnEndDate(null);
        }
    };

    const handleChangeEndDate = (newDate) => {
        if (importedOn && newDate.isBefore(importedOn)) {
            toastr.warning('End date must be after start date');
        } else {
            setImportedOnEndDate(newDate);
        }
    };

    const handleOpenCalendar = (openCalendar) => {
        setIsCalendarOpen(true);
        openCalendar();
    };

    const handleOpenCalendarEndDate = (openCalendar) => {
        setIsCalendarOpenEndDate(true);
        openCalendar();
    };

    const handleCloseCalendar = () => {
        setIsCalendarOpen(false);
    };

    const handleCloseCalendarEndDate = () => {
        setIsCalendarOpenEndDate(false);
    };

    const renderInput = (props, openCalendar) => (
        <div style={{ position: "relative", display: "inline-block" }}>
            <input
                {...props}
                readOnly
                onClick={() => handleOpenCalendar(openCalendar)}
            />
            <FaCalendarAlt
                onClick={() => handleOpenCalendar(openCalendar)}
                style={{
                    position: "absolute",
                    right: 10,
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                }}
            />
        </div>
    );

    const renderInputEndDate = (props, openCalendar) => (
        <div style={{ position: "relative", display: "inline-block" }}>
            <input
                {...props}
                readOnly
                onClick={() => handleOpenCalendarEndDate(openCalendar)}
            />
            <FaCalendarAlt
                onClick={() => handleOpenCalendarEndDate(openCalendar)}
                style={{
                    position: "absolute",
                    right: 10,
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                }}
            />
        </div>
    );

    const renderView = (mode, renderDefault, viewProps) => {
        if (mode === "time") {
            return (
                <div className="custom-time-view" >
                    {renderDefault(viewProps)}
                    <Button onClick={handleApply}
                        className="apply-button" style={{ color: 'white', backgroundColor: "#0085CF", textAlign: "center", width: '100%' }}
                    >
                        Apply
                    </Button>
                </div>
            );
        }
        return renderDefault(viewProps);
    };

    const renderViewEndDate = (mode, renderDefault, viewProps) => {
        if (mode === "time") {
            return (
                <div className="custom-time-view">
                    {renderDefault(viewProps)}
                    <Button onClick={handleApplyEndDate}
                        className="apply-button" style={{ color: 'white', backgroundColor: "#0085CF", textAlign: "center", width: '100%' }}>
                        Apply
                    </Button>
                </div>
            );
        }
        return renderDefault(viewProps);
    };


    const csvOptions = [
        { value: "1", label: "Generated" },
        { value: "0", label: "Not Generated" }
    ]

    const referralETLOptions = [
        { value: "1", label: "Processed" },
        { value: "0", label: "Not Processed" }
    ]

    const handleJsonModalOpen = (jsonData) => {
        setJsonModalData(jsonData);
        setJsonModalVisible(true);
    };

    const handleJsonModalClose = () => {
        setJsonModalVisible(false);
    };

    // const handleExternalOrderIdChange = (value) => {
    //     setExternalOrderId(value);
    // };

    // const handleOrderSerialNumberChange = (value) => {
    //     setOrderSerialNumber(value)
    // }

    const handleChangeFromState = (value) => {
        setFromState(value)
    }

    const handleTableChange = (type, newState) => {
        setPage(newState.page);
        setSizePerPage(newState.sizePerPage);
        setOffset((newState.page - 1) * newState.sizePerPage);

        if (newState.sortField !== undefined && newState.sortOrder !== undefined) {
            setSortColumn(newState.sortField);
            setSortOrder(newState.sortOrder);
        } else {
            setSortColumn("");
            setSortOrder("");
        }
    };

    const hl7Validation = (cell) => {
        if (cell && typeof cell === 'string') {
            if (cell.length > 150) {
                return (
                    <div>
                        {`${cell.substring(0, 100)}... `}
                        <Button color="link" onClick={() => handleJsonModalOpen(cell)}>View More</Button>
                    </div>
                );
            } else {
                return cell;
            }
        } else {
            return cell;
        }
    };

    const dobFormatter = (cell) => {
        return cell
            ? moment(cell).format("DD-MMM-YYYY h:mm A")
            : "N/A";
    };

    const basicValidation = (cell) => {
        return cell ? cell : "N/A";
    };

    const statusFormatter = (cell, row) => {
        let status = "";
        if (cell == 1) {   // eslint-disable-line eqeqeq
            status = "Processed";
        } else {
            status = " Not Processed";
        }
        return status;
    };

    const csvStatusFormatter = (cell, row) => {
        let status = "";
        if (cell == 1) {  // eslint-disable-line eqeqeq
            status = "Generated";
        } else {
            status = "Not Generated";
        }
        return status;
    }

    // const actionsFormatter = (cell, row) => { // eslint-disable-line
    //     return (
    //         <>
    //             <Container>
    //                 <Row className="justify-content-center">
    //                     <Button
    //                         outline
    //                         className="text-center mr-2"
    //                         onClick={() => {
    //                             setClickedRowData(row)
    //                             setEditHL7Modal(true)
    //                         }}
    //                     >
    //                         <FontAwesomeIcon icon={faPenToSquare} className="align-middle" fixedWidth title="Edit" />
    //                     </Button>
    //                 </Row>
    //             </Container>
    //         </>
    //     )
    // }

    // const externalOrderIdLoadOptions = (inputValue) => {
    //     if (inputValue.length >= 2) { // Check if inputValue exists and has length >= 2
    //         const searchParams = new URLSearchParams({ external_order_id: inputValue });
    //         return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-clinical-import-log-external-order-id/?${searchParams.toString()}`, {
    //             headers: {
    //                 Authorization: API_TOKEN,
    //             },
    //         })
    //             .then((response) => {
    //                 const externalOrderId = response.data.data.map(item => ({ value: item.external_order_id, label: item.external_order_id }))
    //                 return externalOrderId; // Returning the data
    //             });
    //     }
    // };

    // const orderSerialNumberLoadOptions = (inputValue) => {
    //     if (inputValue.length >= 2) { // Check if inputValue exists and has length >= 2
    //         const searchParams = new URLSearchParams({ order_serial_number: inputValue });
    //         return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-clinical-import-log-order-serial-number/?${searchParams.toString()}`, {
    //             headers: {
    //                 Authorization: API_TOKEN,
    //             },
    //         })
    //             .then((response) => {
    //                 const orderSerialNumber = response.data.data.map(item => ({ value: item.order_serial_number, label: item.order_serial_number }))
    //                 return orderSerialNumber; // Returning the data
    //             });
    //     }
    // };

    const fetchStateOptions = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-clinical-import-log-from-state/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((response) => {
                const fromState = response.data.data.map(item => ({ value: item.from_state, label: item.from_state }));
                setStateOptions(fromState);
            })
            .catch((error) => {
                console.error('Error fetching state options:', error);
            });
    };

    const fetchhl7Data = (countfield = "", inputValue) => {
        setIsLoading(true);
        if (countfield !== "") {
            setSearchKeyword("")
        }
        const params = {
            "from_state": fromState.label || "",
            "is_csv_generated": isCSVGenerated.value || "",
            "is_referral_etl_processed": isReferralETLProcessed.value || "",
            "imported_on_start": moment(importedOn).format('YYYY-MM-DD HH:mm:00'),
            "imported_on_end": moment(importedOnEndDate).format('YYYY-MM-DD HH:mm:59'),
            "search_keyword": inputValue || "",
            "sortColumn": sortColumn,
            "sortOrder": sortOrder,
            "off_set": offset || "",
            "record_limit": sizePerPage || "",
            "is_pagination": 1,
            "generated_count_check": countfield ? 1 : "",
            "count_field_name": countfield
        };

        AxiosInter.post(`${API_ENDPOINT}/admin-api/list-clinical-import-log/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((response) => {
                setHl7Data(response?.data?.data);
                setNumberOfRecords(response?.data?.total_record_count);
                setTotalSize(response?.data?.total_record_count)
                setCountFieldName(response?.data?.not_generated_processed_count)
            })
            .catch((error) => {
                console.error("HL7 Error:", error);
            })
            .finally(() => {
                setIsLoading(false); // Set loading state to false after data fetch completes
            });
    };

    const exportHl7Data = (countfield = notProcessedFilter) => {
        const params = {
            "file_name": fileName ? fileName : "",
            "from_state": fromState.label || "",
            "is_csv_generated": isCSVGenerated.value || "",
            "is_referral_etl_processed": isReferralETLProcessed.value || "",
            "imported_on_start": moment(importedOn).format('YYYY-MM-DD HH:mm:00'),
            "imported_on_end": moment(importedOnEndDate).format('YYYY-MM-DD HH:mm:59'),
            "search_keyword": searchKeyword || "",
            "sortColumn": sortColumn,
            "sortOrder": sortOrder,
            "off_set": "",
            "record_limit": "",
            "is_pagination": "",
            "generated_count_check": countfield ? 1 : "",
            "count_field_name": countfield,
            "external_order_id": externalOrderId ? externalOrderId : "",
            "order_serial_number": orderSerialNumber ? orderSerialNumber : "",
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/export-clinical-import-log/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
            responseType: "blob",
        })
            .then((res) => {
                if (res.status === 200) {
                    var fileDownload = require("js-file-download");
                    fileDownload(res.data, `${fileName}`, res["headers"]["x-suggested-filename"]);
                    toastr.success("Success", "Export successful");
                }
            })
    }


    useEffect(() => {
        if (hl7data.length === 0 && Array.isArray(hl7data)) { // Add Array.isArray(hl7data) check
            setBlankStateStatus(true);
        } else {
            setBlankStateStatus(false);
        }
    }, [hl7data]);

    useEffect(() => {
        fetchhl7Data()
    }, [reset, sortColumn, sortOrder])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchhl7Data(notProcessedFilter, searchKeyword);
    }, [notProcessedFilter, offset, clear]); // eslint-disable-line

    useEffect(() => {
        fetchStateOptions()
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    const resetValues = () => {
        setFromState("")
        setIsCSVGenerated("")
        setIsReferralETLProcessed("")
        setImportedOn(new Date().setHours(0, 0, 0))
        setImportedOnEndDate(new Date())
        setCountFieldName("")
        setNotProcessedFilter(null)
        setOffset('')
        setReset(!reset)
    }

    const clearValues = () => {
        setSearchKeyword("")
        setClear(!clear)
        setOffset('')
    }

    const columns = [
        {
            dataField: "external_order_id",
            text: "External Order Id",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '100px' },
            style: { width: '100px' },
        },
        // {
        // text: 'Actions',
        // sort: false,
        // formatter: actionsFormatter,
        // headerStyle: { width: '100px' },
        // style: { width: '100px' },
        // },
        {
            dataField: "imported_by",
            text: "Imported By",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            formatter: statusFormatter,
            headerStyle: { width: '100px' },
            style: { width: '100px' },
        },
        {
            dataField: "hl7_message",
            text: "HL7 Message",
            sort: true,
            formatter: hl7Validation,
            headerStyle: { width: '150px' },
            style: { width: '150px' },
        },
        {
            dataField: "patient_info_json",
            text: "Patient Info",
            sort: true,
            headerStyle: { width: '150px' },
            style: { width: '150px' },
            formatter: hl7Validation
        },
        {
            dataField: "insurance_info_json",
            text: "Insurance Info",
            sort: true,
            headerStyle: { width: '150px' },
            style: { width: '150px' },
            formatter: hl7Validation
        },
        {
            dataField: "referral_info_json",
            text: "Referral Info",
            sort: true,
            headerStyle: { width: '150px' },
            style: { width: '150px' },
            formatter: hl7Validation
        },
        {
            dataField: "api_response",
            text: "Api Response",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '115px' },
            style: { width: '115px' },
        },
        {
            dataField: "order_serial_number",
            text: "Order Serial Number",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "imported_on",
            text: "Imported On",
            sort: true,
            formatter: dobFormatter,
            headerStyle: { width: '115px' },
            style: { width: '115px' },
        },
        {
            dataField: "updated_by",
            text: "Updated By",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "updated_time",
            text: "Updated Time",
            sort: true,
            formatter: dobFormatter,
            headerStyle: { width: '115px' },
            style: { width: '115px' },
        },
        {
            dataField: "is_csv_generated",
            text: "Is CSV Generated",
            sort: true,
            formatter: csvStatusFormatter,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "is_csv_generated_on",
            text: "Is CSV Generated On",
            sort: true,
            formatter: dobFormatter,
            headerStyle: { width: '115px' },
            style: { width: '115px' },
        },
        {
            dataField: "is_insurance_etl_processed",
            text: "Is Insurance ETL Processed",
            sort: true,
            formatter: statusFormatter,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "is_insurance_etl_processed_on",
            text: "Is Insurance ETL Processed On",
            sort: true,
            formatter: dobFormatter,
            headerStyle: { width: '115px' },
            style: { width: '115px' },
        },
        {
            dataField: "is_referral_etl_processed",
            text: "Is Referral ETL Processed",
            sort: true,
            formatter: statusFormatter,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "is_referral_etl_processed_on",
            text: "Is Referral ETL Processed On",
            sort: true,
            formatter: dobFormatter,
            headerStyle: { width: '115px' },
            style: { width: '115px' },
        },
        {
            dataField: "is_patient_etl_processed",
            text: "Is Patient ETL Processed",
            sort: true,
            formatter: statusFormatter,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "is_patient_etl_processed_on",
            text: "Is Patient ETL Processed On",
            sort: true,
            formatter: dobFormatter,
            headerStyle: { width: '115px' },
            style: { width: '115px' },
        },
        {
            dataField: "from_state",
            text: "From State",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },

    ]

    return (
        <Container fluid>
            {isLoading && (
                <div className="spinner-container">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )}
            <Header>
                <HeaderTitle>HL7 Clinical Import Log</HeaderTitle>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/dashboard">Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <Link active>HL7 Clinical Import Log</Link>
                    </BreadcrumbItem>
                </Breadcrumb>
            </Header>
            <div>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xs={12} sm={6} lg={2}>
                                <Label>State</Label>
                                <Select
                                    placeholder='Select State'
                                    options={stateOptions}
                                    isSearchable
                                    isClearable
                                    onChange={handleChangeFromState}
                                    value={fromState}
                                />
                            </Col>
                            <Col xs={12} sm={6} lg={2}>
                                <Label>CSV Generated</Label>
                                <Select
                                    options={csvOptions}
                                    value={isCSVGenerated}
                                    onChange={(e) => {
                                        setIsCSVGenerated(e)
                                    }}
                                />
                            </Col>
                            <Col xs={12} sm={6} lg={2}>
                                <Label>Referral ETL </Label>
                                <Select
                                    options={referralETLOptions}
                                    value={isReferralETLProcessed}
                                    onChange={(e) => {
                                        setIsReferralETLProcessed(e)
                                    }}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <div className='d-flex flex-wrap' style={{ gap: "24px" }}>
                                    <div>
                                        <Label>Imported On Start Date</Label>
                                        <div style={{ position: "relative", minWidth: "200px" }}>
                                            <Datetime
                                                ref={datetimeRef}
                                                value={importedOn}
                                                dateFormat="DD MMM YYYY"
                                                timeFormat="hh:mm A"
                                                onChange={handleChangeStartDate}
                                                renderInput={renderInput}
                                                renderView={renderView}
                                                onClose={handleCloseCalendar}
                                                onOpen={() => setIsCalendarOpen(true)}
                                                onBlur={() => setIsCalendarOpen(false)}
                                                inputProps={{ style: { height: '37px' } }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Label>Imported On End Date</Label>
                                        <div style={{ position: "relative", minWidth: "200px" }}>
                                            <Datetime
                                                ref={datetimeRefEndDate}
                                                value={importedOnEndDate}
                                                dateFormat="DD MMM YYYY"
                                                timeFormat="hh:mm A"
                                                onChange={handleChangeEndDate}
                                                renderInput={renderInputEndDate}
                                                renderView={renderViewEndDate}
                                                onClose={handleCloseCalendarEndDate}
                                                onOpen={() => setIsCalendarOpenEndDate(true)}
                                                onBlur={() => setIsCalendarOpenEndDate(false)}
                                                inputProps={{ style: { height: '37px' } }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col sm="3">
                                <Label>External Order Id</Label>
                                <AsyncSelect
                                    placeholder='Select Order Id'
                                    isSearchable
                                    isClearable
                                    loadOptions={externalOrderIdLoadOptions}
                                    onChange={handleExternalOrderIdChange}
                                    value={externalOrderId}
                                />
                            </Col>
                            <Col sm="3">
                                <Label>Order Serial Number</Label>
                                <AsyncSelect
                                    placeholder='Select Serial Number'
                                    loadOptions={orderSerialNumberLoadOptions}
                                    isSearchable
                                    isClearable
                                    onChange={handleOrderSerialNumberChange}
                                    value={orderSerialNumber}
                                />
                            </Col> */}

                            {/* <Col sm="2">
                                <Label>State</Label>
                                <Select
                                    placeholder='Select State'
                                    options={stateOptions}
                                    isSearchable
                                    isClearable
                                    onChange={handleChangeFromState}
                                    value={fromState}
                                />
                            </Col>
                            <Col sm="2">
                                <Label>CSV Generated</Label>
                                <Select
                                    options={csvOptions}
                                    value={isCSVGenerated}
                                    onChange={(e) => {
                                        setIsCSVGenerated(e)
                                    }}
                                />
                            </Col>
                            <Col sm="2">
                                <Label>Referral ETL </Label>
                                <Select
                                    options={referralETLOptions}
                                    value={isReferralETLProcessed}
                                    onChange={(e) => {
                                        setIsReferralETLProcessed(e)
                                    }}
                                />
                            </Col>
                            <Col sm="2">
                                <Label>Imported On Start Date</Label>
                                <div style={{ position: "relative", display: "inline-block" }}>
                                    <Datetime
                                        ref={datetimeRef}
                                        value={importedOn}
                                        dateFormat="DD MMM YYYY"
                                        timeFormat="hh:mm A"
                                        onChange={handleChangeStartDate}
                                        renderInput={renderInput}
                                        renderView={renderView}
                                        onClose={handleCloseCalendar}
                                        onOpen={() => setIsCalendarOpen(true)}
                                        onBlur={() => setIsCalendarOpen(false)}
                                    />
                                </div>
                            </Col>
                            <Col sm="2">
                                <Label>Imported On End Date</Label>
                                <div style={{ position: "relative", display: "inline-block" }}>
                                    <Datetime
                                        ref={datetimeRefEndDate}
                                        value={importedOnEndDate}
                                        dateFormat="DD MMM YYYY"
                                        timeFormat="hh:mm A"
                                        onChange={handleChangeEndDate}
                                        renderInput={renderInputEndDate}
                                        renderView={renderViewEndDate}
                                        onClose={handleCloseCalendarEndDate}
                                        onOpen={() => setIsCalendarOpenEndDate(true)}
                                        onBlur={() => setIsCalendarOpenEndDate(false)}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <Button
                                    className='patient-go-buttons'
                                    onClick={() => {
                                        setPage(1)
                                        setOffset('');
                                        setReset(!reset);
                                        setNotProcessedFilter("")
                                    }}
                                >Go</Button>
                                <Button
                                    className='patient-reset-buttons'
                                    type='reset'
                                    onClick={() => {
                                        resetValues()
                                    }}
                                >Reset</Button>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col>
                                <div className="d-flex">
                                    <Button
                                        className='patient-go-buttons'
                                        onClick={() => {
                                            setPage(1)
                                            setOffset('');
                                            setReset(!reset);
                                            setNotProcessedFilter("")
                                        }}
                                    >Go</Button>
                                    <Button
                                        className='patient-reset-buttons'
                                        type='reset'
                                        onClick={() => {
                                            resetValues()
                                        }}
                                    >Reset</Button>
                                </div>
                            </Col>
                        </Row>
                        <div className="separator mt-2" />
                        <Row className='filter-button ml-0 mt-2'>
                            <div className='mb-2'>
                                <Button className='btn btn-primary'
                                    onClick={() => {
                                        setOffset("")
                                        setPage(1);
                                        setNotProcessedFilter("is_csv_generated");
                                    }}>
                                    CSV Not Generated{" "}
                                    <span className='badge badge-dark'>
                                        {countFieldName?.is_csv_generated ? countFieldName?.is_csv_generated : 0}
                                    </span>
                                </Button>
                            </div>
                            <div className='mb-2'>
                                <Button className='btn btn-primary'
                                    onClick={() => {
                                        setOffset("")
                                        setPage(1);
                                        setNotProcessedFilter("is_referral_etl_processed");
                                    }}>
                                    Referral ETL Not Processed{" "}
                                    <span className='badge badge-dark'>
                                        {countFieldName?.is_referral_etl_processed ? countFieldName?.is_referral_etl_processed : 0}
                                    </span>
                                </Button>
                            </div>
                            <div className='mb-2'>
                                <Button className='btn btn-primary'
                                    onClick={() => {
                                        setOffset("")
                                        setPage(1);
                                        setNotProcessedFilter("is_insurance_etl_processed");
                                    }}>
                                    Insurance ETL Not Processed{" "}
                                    <span className='badge badge-dark'>
                                        {countFieldName?.is_insurance_etl_processed ? countFieldName?.is_insurance_etl_processed : 0}
                                    </span>
                                </Button>
                            </div>
                            <div className='mb-2'>
                                <Button className='btn btn-primary'
                                    onClick={() => {
                                        setOffset("")
                                        setPage(1);
                                        setNotProcessedFilter("is_patient_etl_processed");
                                    }}>
                                    Patient ETL Not Processed{" "}
                                    <span className='badge badge-dark'>
                                        {countFieldName?.is_patient_etl_processed ? countFieldName?.is_patient_etl_processed : 0}
                                    </span>
                                </Button>
                            </div>
                        </Row>
                        <div className="separator mt-1" />
                    </CardHeader>
                    <CardHeader>
                        <Row>
                            <Col sm='2'>
                                <div className='number-of-records'>
                                    <p>Number of Records:{numberOfRecords ? numberOfRecords : 0}</p>
                                </div>
                            </Col>
                            <Col>
                                <div className='c-search-field form-inline justify-content-end'>
                                    <div className='form-group mr-2'>
                                        <Input
                                            placeholder='Order Serial Number/External Id'
                                            id='search'
                                            autoFocus="autoFocus"
                                            value={searchKeyword}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                setSearchKeyword(inputValue);
                                                fetchhl7Data("", inputValue)
                                            }}

                                        />
                                    </div>
                                    <Button
                                        className='clear-search-button'
                                        onClick={() => {
                                            clearValues();
                                        }}
                                        disabled={!searchKeyword}
                                        title={!searchKeyword ? "Nothing to clear" : ""}
                                    >
                                        Clear
                                    </Button>
                                    <Button className="btn btn-export"
                                        onClick={() => {
                                            exportHl7Data()
                                        }}
                                        disabled={hl7data.length > 0 ? false : true}
                                        title={hl7data.length > 0 ? '' : 'Nothing to Export'}
                                    >
                                        Export
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <div className="separator" />
                    </CardHeader>
                    <CardBody className="patient-list dropdown-menu-up">
                        {!blankStateStatus ?
                            <div className='hl7table'>
                                <BootstrapTable
                                    bootstrap4
                                    bordered={false}
                                    hover
                                    remote
                                    keyField='id'
                                    data={hl7data}
                                    columns={columns}
                                    className='react-bootstrap-table'
                                    pagination={paginationFactory({
                                        page: page,
                                        sizePerPage: sizePerPage,
                                        sizePerPageList: [50, 100, 150, 200],
                                        totalSize
                                    })}
                                    onTableChange={(type, newState) => {
                                        handleTableChange(type, newState);
                                    }}
                                />
                            </div> : blankState
                        }
                    </CardBody>
                </Card>
                {/* <EditHL7Modal
                    editHL7Modal={editHL7Modal}
                    setEditHL7Modal={setEditHL7Modal}
                    clickedRowData={clickedRowData}
                    fetchhl7Data={fetchhl7Data}
                    statusOptions={statusOptions}
                /> */}
                <JsonModal
                    visible={jsonModalVisible}
                    jsonData={jsonModalData}
                    onClose={handleJsonModalClose}
                />
            </div>
        </Container>
    )
}

const JsonModal = ({ visible, jsonData, header, onClose }) => {
    const [copied, setCopied] = useState(false);
    const textAreaRef = useRef(null);

    const handleCopy = () => {
        const textArea = textAreaRef.current;
        textArea.select();
        navigator.clipboard.writeText(textArea.value)
            .then(() => {
                setCopied(true);
                // Reset copied state to false after a delay
                setTimeout(() => setCopied(false), 1000); // Change 1000 to your desired delay in milliseconds
            })
            .catch(() => setCopied(false));
    };

    useEffect(() => {
        // Reset copied state when the modal is closed
        if (!visible) {
            setCopied(false);
        }
    }, [visible]);

    return (
        <Modal isOpen={visible} toggle={onClose}>
            <ModalHeader toggle={onClose}>{header}</ModalHeader>
            <ModalBody>
                <pre style={{
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                    fontSize: '16px',
                    maxHeight: '300px',
                    overflowY: 'auto'
                }}>
                    <textarea ref={textAreaRef} value={jsonData} readOnly style={{ display: 'none' }} />
                    {jsonData}
                </pre>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleCopy} className='clear-search-button'>
                    {copied ? 'Copied!' : 'Copy'}
                </Button>
                <Button color="secondary" onClick={onClose}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};

export default HL7
