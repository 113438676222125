import React, { useState, useEffect } from 'react';
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { Col, Label, Row, Button, Input, Alert, Card, CardBody, Container } from "reactstrap";
import './DateRangeSelector.css';
import axios from 'axios';
// import AxiosInter from './../../AxiosInter.js';
// import Cookie from 'js-cookie';
import equipologo from "../../assets/img/logos/equipo-logo_low-res_with-tagline.png";
import { toastr } from 'react-redux-toastr';
import { useLocation, useNavigate } from "react-router-dom";

const AppUserPasswordReset = () => {

    // CONTEXT VARIABLES
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    let navigate = useNavigate(); // eslint-disable-line

    const email = query.get("email")

    if(email){
        let pattern = /b'/g;
        let result1 = email.replace(pattern,"");
        var result = result1.replace("'","");
    }
    // LOCAL VARIABLES 
    // const API_TOKEN = Cookie.get("access_token_admin");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const EQUIPO_ENDPOINT = process.env.REACT_APP_EQUIPO_APP_URL_LINK;

    const [spinner, setSpinner] = useState(false)

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [decEmail, setDecEmail] = useState("");
   

    const OverlayLoader = () => (
        <div className="page">

        <div className="item">
            <i className="loader --1"></i>
        </div>
        </div>
    );

    const BlowFishDec = async () => {
        debugger
        try {
          const params = {
            text: result || "",
          };
      
          const { data } = await axios.post(
            `${API_ENDPOINT}/admin-api/decryption/`,
            params
          );
      
          if (data) {
            // console.log(data.slice(0, data.indexOf("\\")));
            // setDecEmail(data.slice(0, data.indexOf("\\")));
            setDecEmail(data);
          }
        } catch (error) {
          console.log(error);
          toastr.error("Failed", "Please try again");
        }
      };
      
  
      
     const SubmitPassword = () => {
        setSpinner(true)
        if(password.toString() === confirmPassword.toString()){
            // debugger
            const param={
                "app_user_email":decEmail,
                "password": password ? password : ""
             }
             
             axios.post(`${API_ENDPOINT}/admin-api/app-user-password/`,param,)
               .then(function (response) {
                 if (response.status === 201) {
                     toastr.success("Successful", "Password reset successful")
                     setTimeout(() => {
                        setSpinner(false);
                        window.location.href = `${EQUIPO_ENDPOINT}login`;
                    }, 3000);
                 }
               })
               .catch(function (error) {
                toastr.error("Failed", "Please try again")
               });
        } else {
            toastr.error("Failed", "Password and Confirm password does not match")
        }
     };




    // USE EFFECTS
    useEffect(() => {
        if (result) BlowFishDec();
      }, [result]);     // eslint-disable-line

    return (
        <AvForm>
            <AvGroup>
                <Container className="vh-50">
                    <Row className="m-auto">
                       
                        <div  className="card-password">
                            <Card className="background">
                                <CardBody>
                                    <div className="body-width">
                                

                                        <Row >
                                            <Col>
                                                <div className="m-sm-4">
                                            <Alert className="alert-success adjust">
                                                    <div className="text-center">
                                                        <img
                                                            src={equipologo}
                                                            alt="equipo logo"
                                                            className="img-fluid"
                                                            width="150"
                                                            height="150"
                                                            />
                                                    </div>
                                                    </Alert>
                                                    <br></br>
                                                    <h4>Reset Your Password</h4>
                                                    <br></br>
                                                    {spinner ? <OverlayLoader className="overlay-z-index" /> : null}

                                                    <Label for="Password">New Password <span className="required">*</span> </Label>
                                                    <Input
                                                        id="Password"
                                                        value={password}
                                                        type="password"
                                                        placeholder="Enter the password"
                                                        onChange={(e) => { if (e.target.value.length <= 200) setPassword(e.target.value) }}
                                                        />
                                                         <br></br>
                                                    <Label for="Password">Confirm New Password <span className="required">*</span> </Label>
                                                    <Input
                                                        id="Password"
                                                        type="password"
                                                        value={confirmPassword}
                                                        placeholder="Enter password again"
                                                        onChange={(e) => { if (e.target.value.length <= 200) setConfirmPassword(e.target.value) }}
                                                        
                                                        />
                                                <br></br>
                                                        <Button onClick={()=>{ SubmitPassword() }} color="videodoc-green" size="lg">
                                                    Submit
                                                </Button>
                                                </div>
                                                
                                            </Col>
                                        </Row>


                                    </div>

                                </CardBody>
                            </Card>

                        </div>
                    </Row>
                </Container>

            </AvGroup>
        </AvForm>
    )
}

export default AppUserPasswordReset;
