/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState,useContext } from 'react';
import { Card, Col, Row,Button, Breadcrumb,CardHeader,Label, BreadcrumbItem, Container} from 'reactstrap';
import { Link } from "react-router-dom";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { GlobalState } from '../../contextAPI/Context'
import Select from "react-select";
import { Select as SelectVirtualized} from "react-select-virtualized";
import moment from 'moment';
import  RemotePagination  from '../../newComponents/utilities/RemotePagination'
import SearchBarComponent from '../../newComponents/utilities/SearchBarComponent'
import MomentConfig from '../../common/MomentConfig';


const InAppCallLog = () => {

  const {  groupNamesForDropDown } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const fileExportName='InAppCallLog'
  const [spinner,setSpinner] = useState(false);
  const [search, setSearch] = useState(false);
  const [inAppCallLogDetails,setInAppCallLogDetails] = useState("");
  const [reload,setReload] = useState(0);
  const [offset,setOffset] = useState(0);
  const [sizePerPage,setSizePerPage] = useState(50);
  const [isPagination,setIsPagination] = useState(1); // eslint-disable-line
  const [totalSize,setTotalSize] = useState(0);
  const [page,setPage] = useState(1);
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [yearDropDownOptions,setYearDropDownOptions] = useState("");
  const [selectedYear,setSelectedYear] = useState("");
  const [selectedMonth,setSelectedMonth] = useState("");
  const [selectedGroup,setSelectedGroup] = useState("");
  const [callStatus,setSelectedCallStatus] = useState("");
  const [sortColumn,setSortColumn] =  useState("");
  const [sortOrder,setSortOrder] =  useState("");
  const [searchKeyword,setSearchKeyword] = useState("")
  const [groupDropDown, setGroupDropDown] = useState("");// eslint-disable-line
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`

  const validation = (cell) => {
    return cell ? cell : "N/A"
  }

  const callStatusValidation = (cell,row)=>{
    if (row.callStatus === 'initiated') {
      return (
        <>
        <h6 style={{'color':'#337ab7'}}>{row.callStatus}</h6>
        </>
      )
    }
    else if (row.callStatus === 'completed') {
      return (
        <>
        <h6 style={{'color':'green'}}>{row.callStatus}</h6>
        </>
      )

    }
    else if (row.callStatus === 'no-answer') {
      return (
        <>
        <h6 style={{'color':'red'}}>{row.callStatus}</h6>
        </>
      )

    }
    else if (row.callStatus === 'ringing') {
      return (
        <>
        <h6 style={{'color':'gray'}}>{row.callStatus}</h6>
        </>
      )

    }
    else if (row.chronic === null) {
      return (
        <>
        N/A
        </>
      )

    }
  }

  const monthOptions = [
    {
      value:1,
      label:'January'
    },
    {
      value:2,
      label:'February'
    },
    {
      value:3,
      label:'March'
    },
    {
      value:4,
      label:'April'
    },
    {
      value:5,
      label:'May'
    },
    {
      value:6,
      label:'June'
    },
    {
      value:7,
      label:'July'
    },
    {
      value:8,
      label:'August'
    },
    {
      value:9,
      label:'September'
    },
    {
      value:10,
      label:'October'
    },
    {
      value:11,
      label:'November'
    },
    {
      value:12,
      label:'December'
    },
  ]

  const callStatusOptions = [
    {
      value:1,
      label:'initiated'
    },
    {
      value:2,
      label:'completed'
    },
    {
      value:3,
      label:'no-answer'
    },
    {
      value:4,
      label:'ringing'
    },

  ]

  const dobFormatter = (cell) => {
    return cell ? moment(cell).format(MomentConfig.MOMENT_FORMAT_DATE) : "N/A"
  }

// const timeFormatter = (cell) => {
//   if (cell) {
//     var stillUtc = moment.utc(cell).toDate();
//     var local = moment(stillUtc).local().format("dddd, MMMM Do YYYY, h:mm:ss a"); 
//     return local;
//   }
//   else {
//       return 'N/A'
//   }
  

// }

  
  const columns = [
    {
      dataField: 'groupName',
      text: 'Group',
      sort: true,
      formatter:validation
    },
    {
      dataField: 'recipientName',
      text: 'Recipient Name',
      sort: true,
      formatter:validation
    },
    {
      dataField: 'recipientPhone',
      text: 'Recipient Phone',
      sort: true,
      formatter:validation
    },
    {
      dataField: 'caller',
      text: 'Caller',
      sort: true,
    //   formatter: statusFormatter
    },
    {
      dataField: 'callStart',
      text: 'Called on',
      sort: false,
      formatter:dobFormatter
    },
    {
      dataField: 'callDuration',
      text: 'Duration',
      sort: false,
      formatter:validation
    },
    {
      dataField: 'callStatus',
      text: 'Call Status',
      sort: false,
      formatter:callStatusValidation
    },
    
  ];



  const fetchInAppCallLogDetails = () => {
    setSpinner(true);
    const params =
    {
     
      "call_status": callStatus ? callStatus.label:"",
      "groupID": selectedGroup ? selectedGroup.value : "",
      "is_pagination":isPagination ? isPagination:"",
      "record_limit":sizePerPage ? sizePerPage:"",
      "offset":offset,
      "call_year":selectedYear ? selectedYear.label:"",
      "call_month":selectedMonth ? selectedMonth.value:"",
      "search_keyword":searchKeyword ? searchKeyword:"",
      "sortOrder": sortOrder ? sortOrder : "DESC",
      "sortColumn": sortColumn ? sortColumn : "",
 
    }


    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-app-call-log-details/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        if (response.data.data.length > 0) {
          setInAppCallLogDetails(response.data.data);
          setTotalSize(response.data.record_count)
          setSpinner(false);
          localStorage.setItem('offset', offset);
        
        }
        else {
          setTotalSize(0);
          setInAppCallLogDetails("")
          setSpinner(false);
          setIsDataEmpty(true);
        
        }
      })
      .catch(error => {
       toastr.error("Error","Something went wrong")
       setSpinner(false);
      });
  };
  const exportTableData = () => {

    const params =
    {
      "file_name": fileName ? fileName : "",
      "call_status": callStatus ? callStatus.label:"",
      "groupID": selectedGroup ? selectedGroup.value : "",
      "is_pagination":isPagination ? isPagination:"",
      "record_limit": "",
      "OFFSET":offset,
      "call_year":selectedYear ? selectedYear.label:"",
      "call_month":selectedMonth ? selectedMonth.value:"",
      "search_keyword":searchKeyword ? searchKeyword:"",
      "sortOrder": sortOrder ? sortOrder : "DESC",
      "sortColumn": sortColumn ? sortColumn : "",

    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-inapp-call-log-table-data/`,params,{
        headers: {
           
          Authorization: API_TOKEN,

        },
        responseType: "blob"
      })
        .then((response) => { 
          if (response.status === 200) {
            var fileDownload = require("js-file-download");
            fileDownload(
              response.data ,`${fileName}`,
              response["headers"]["x-suggested-filename"]
            );
            toastr.success(
              "Success",
              "Export successful"
            );
          
          }
          
        })
        .catch((error) => {
        
            toastr.error(
                "Failed",
                "Something went wrong"
              );
        });
    };

  const handleTableChange = (type, newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
    setOffset((newState.page - 1) * newState.sizePerPage)
    

    if(newState.sortField === null && newState.sortOrder === undefined) {
    
      setSortColumn("goc.id")
      setSortOrder("DESC")
    }
   else if(newState.sortField === 'groupName') {
    
      setSortColumn("grp.name")
      setSortOrder(newState.sortOrder)
      setReload(reload+1)
    }
   else if(newState.sortField === 'caller') {
    
      setSortColumn("appUsers.name")
      setSortOrder(newState.sortOrder)
      setReload(reload+1)
    }
    
   else {
      
      setSortColumn(newState.sortField)
      setSortOrder(newState.sortOrder)
      setReload(reload+1)
    }
  }
  const createYearDropdownOptions = () => {
    let options = []
    let id = 0
    const d = new Date();
    var yearNow = d.getFullYear()
    for(var i = 2015;i<=yearNow;i++) {
       options.push({value:id,label:i})
       id = id + 1;
    
    }
    setYearDropDownOptions(options);
  };



  const searchButtonValidation = () => {
    if (selectedGroup || selectedMonth || selectedYear || callStatus ) {
      setSearch(true)
    } else {
      setSearch(false)
    }
  }


  const clearSearchValues = () => {
    setSearchKeyword("");
    setReload(reload + 1)
   
  }

  const clearValues = () => {
    setSelectedCallStatus("");
    setSelectedGroup("");
    setSelectedMonth("");
    setSelectedYear("");
    setReload(reload + 1)
  }

  useEffect(() => {
    fetchInAppCallLogDetails();
    createYearDropdownOptions();
   
  }, []) // eslint-disable-line

  useEffect(() => {
    searchButtonValidation();
  }, [selectedMonth,selectedYear,selectedGroup,callStatus]) // eslint-disable-line



  useEffect(() => {
    if(reload > 1) {
    fetchInAppCallLogDetails();
    }
  }, [reload]) // eslint-disable-line



  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);

    }

  }, [searchKeyword]) // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setReload(reload + 1)

    }

  }, [searchKeyword]) // eslint-disable-line

  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0)
      // setPage
      setTotalSize(0);
      setReload(reload + 1)
      // setReloadGroupDetails(true);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]) // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem('offset')
    if (offset !== previousOffset || sizePerPage) {
      setReload(reload + 1)
    }



  }, [offset, sizePerPage]) // eslint-disable-line
  
 useEffect(() => {   
       window.scrollTo(0, 0);   
        }, []);


  return (
      <div>
           <Container fluid>
            <Header>
          <HeaderTitle>
            InApp Call Log

          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>In App Call Log</BreadcrumbItem>
          </Breadcrumb>

        </Header>
        </Container>
          
     
       
        <Card>
        
            <CardHeader>

                <Row>
                <Col sm="2" md="2" lg="2">
                <Label>Year</Label>
                <Select
                  name="Year"
                  options={yearDropDownOptions}
                  placeholder="Choose Year"
                  // isDisabled = {!m_zipList || !adminNames?true:false}
                  isClearable
                  value={selectedYear}
                  onChange={(e) => {

                    setSelectedYear(e)
                  }}
                />
              </Col>
                <Col sm="2" md="2" lg="2">
                <Label>Month</Label>
                <Select
                  name="month"
                  options={monthOptions}
                  placeholder="Choose Month"
                  // isDisabled = {!m_zipList || !adminNames?true:false}
                  isClearable
                  value={selectedMonth}
                  onChange={(e) => {

                    setSelectedMonth(e)
                  }}
                />
              </Col>
              <Col sm="2" md="2" lg="2">
                <Label>Group Name</Label>
                <SelectVirtualized
                  cacheOptions
                  isClearable
                  isSearchable
                  options = {groupNamesForDropDown}
                  value={selectedGroup}
                  placeholder={'Choose Group Name'}
                  onChange={(event) => {
                    setSelectedGroup(event)} 
                  }
                  // loadOptions={inputValue => getAsyncOptions(inputValue)}
                  />
       
              </Col>
            
              <Col sm="2" md="2" lg="2">
                <Label>Call Status</Label>
                <Select
                  name="callStatus"
                  options={callStatusOptions}
                  placeholder="Choose status"
                  // isDisabled = {!m_zipList || !adminNames?true:false}
                  isClearable
                  value={callStatus}
                  onChange={(e) => {

                    setSelectedCallStatus(e)
                  }}
                />
              </Col>
              <Col>
                <Button
                  className="patient-go-buttons"
                  onClick={() => {
                    setSearchKeyword("")
                    offset === 0 ? setReload(reload + 1) : setOffset(0)
                    // setIsSearch(true);
                    setPage(1);



                  }}
                  disabled={spinner || !search ? true : false}
                  title={spinner || !search ? "Choose at least one item to search " : ""}
                >Go</Button> {" "}

                <Button
                  className="patient-reset-buttons"
                  onClick={() => { clearValues() }}
                  disabled={spinner || !search ? true : false}
                  title={spinner || !search ? "Nothing To Clear" : ""}
                >Reset</Button>

              </Col>
              
                </Row>
      
        <div className="separator" />
          </CardHeader>
          <div className='groups-search'>
          <SearchBarComponent
            searchKeyword =  {searchKeyword}
            setSearchKeyword = {setSearchKeyword}
            totalSize = {totalSize}
            columns={columns}
            clearValues = {clearSearchValues}
          /> 

          </div>

   
    
              <RemotePagination
                data={inAppCallLogDetails ? inAppCallLogDetails : ""}
                setSubscriptionDetails={setInAppCallLogDetails}
                columns={columns}
                page={page}
                sizePerPage={sizePerPage}
                FileExportName={fileExportName}
                exportTableData = {exportTableData}
                isGlobalSearch={isGlobalSearch}
                totalSize={totalSize ? totalSize :""}
                onTableChange={(type, newState) => {
                  handleTableChange(type, newState);
                }}
                isDataEmpty={isDataEmpty}
                setIsGlobalSearch={setIsGlobalSearch}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
                


              /> 




        </Card>

     
    </div>
  );
}

export default InAppCallLog;
