/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState, useContext } from 'react';
import { Card, Col, Row, Button, Breadcrumb, BreadcrumbItem, Container, Label, Input, Spinner } from 'reactstrap';
import { Link, useParams, useLocation } from "react-router-dom";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { GlobalState } from '../../contextAPI/Context'
import FacilityStatusChangeModal from "../../../src/newComponents/utilities/FacilityStatusChangeModal"
import EditFacilityModal from '../../newComponents/utilities/EditFacilityModal';
import RemotePaginationForProgram from '../../newComponents/utilities/RemotePaginationForProgram'
import NewFacilityModal from '../../newComponents/utilities/NewFacilityModal';
import SearchBarComponentForProgram from '../../newComponents/utilities/SearchBarComponentForProgram';
import { useNavigate } from "react-router-dom";
import MomentConfig from '../../common/MomentConfig';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import NotificationPreferenceModal from '../../../src/newComponents/utilities/NotificationPreferenceModal'
import EditCareServiceCategoryModal from '../../../src/newComponents/utilities/EditCareServiceCategoryModal'
import DeleteConfirmationModal from "../../newComponents/utilities/DeleteConfirmationModal";
import StatusChangeModalPrograms from '../../newComponents/utilities/StatusChangeModalPrograms';

const CareServiceProgramCategory = (props) => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");
    const [facilityDetails, setFacilityDetails] = useState([]);
    const [facilityID, setFacilityID] = useState(0);
    const [modalState, setModalState] = useState(false);
    const [status, setStatus] = useState(0);
    const [editFacilityModal, setEditFacilityModal] = useState(false);
    const [editType, setEditType] = useState("");
    const [statusChangeModal, setStatusChangeModal] = useState(false);
    const [editGroupID, setEditGroupID] = useState("");//eslint-disable-line
    const [editFacilityID, setEditFacilityID] = useState("");
    const [editFacilityName, setEditFacilityName] = useState("");
    const [editAddress1, setEditAddress1] = useState("");
    const [editAddress2, setEditAddress2] = useState("");
    const [editPrimaryPhone, setEditPrimaryPhone] = useState("");
    const [editReferalFax, setEditReferalFax] = useState("");
    const [editContactPerson, setEditContactPerson] = useState("");
    const [editDepartment, setEditDepartment] = useState("");
    const [editFloor, setEditFloor] = useState("");
    const [editWing, setEditWing] = useState("");
    const [editRoom, setEditRoom] = useState("");
    const [editLatitude, setEditLatitude] = useState("");
    const [editCenter, setEditCenter] = useState("")
    const [editLongitude, setEditLongitude] = useState("");
    const [editTimeZone, setEditTimeZone] = useState("");
    const [editNPI, setEditNPI] = useState("");;
    const [editZipID, setEditZipID] = useState("");//eslint-disable-line
    const [editZipCode, setEditZipCode] = useState("");
    const [editDescription, setEditDescription] = useState("");
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [totalSize, setTotalSize] = useState();
    const [offset, setOffset] = useState(0);
    const [newFacilityModal, setNewFacilityModal] = useState(false);
    const [selectedFacilityName, setSelectedFacilityName] = useState("");//eslint-disable-line
    const [selectedType, setSelectedType] = useState("");//eslint-disable-line
    const [selectedTimeZone, setSelectedTimeZone] = useState("");//eslint-disable-line
    const [selectedStatus, setSelectedStatus] = useState("");//eslint-disable-line
    const [searchKeyword, setSearchKeyword] = useState("");//eslint-disable-line
    const [selectedAccessType, setSelectedAccessType] = useState("");//eslint-disable-line
    const [selectedIsPrimary, setSelectedIsPrimary] = useState("");//eslint-disable-line
    const [spinner, setSpinner] = useState(false);//eslint-disable-line
    const [search, setSearch] = useState(false);//eslint-disable-line
    const [reloadFacilityDetails, setReloadFacilityDetails] = useState(false);
    const [groupDetailsList, setGroupDetailsList] = useState([]);//eslint-disable-line
    const [isGlobalSearch, setIsGlobalSearch] = useState(false);
    const [accessType, setAccessType] = useState("");
    const [isPrimary, setIsPrimary] = useState("");
    const [reload, setReload] = useState(0);
    const fileExportName = 'Group-Facilities'
    const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`//eslint-disable-line
    const [sortColumn, setSortColumn] = useState("");//eslint-disable-line
    const [sortOrder, setSortOrder] = useState("");//eslint-disable-line
    const [notificationPreferenceModal, setNotificationPreferenceModal] = useState(false);
    const [notificationToggleButton, setNotificationToggleButton] = useState(false);//eslint-disable-line
    const [newEmail, setNewEmail] = useState([])
    const [centralNotification, setCentralNotification] = useState(2)
    const [currentFacilityStatus, setFacilityCurrentStatus] = useState(0)//eslint-disable-line
    const [newTitle, setNewTitle] = useState("");
    const [groupIdValue, setGroupIdValue] = useState();
    const [modal, setModal] = useState(false);
    const [selectedId, setSelectedId] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();

    const goBack = () => {
        navigate(-1);
      };
    

    const { fetchFacilityAccessType, facilityAccessType,
        setFacilityReload, facilityReload, m_zipList, facilityListLoading, timeZoneList
    } = useContext(GlobalState);


    const toggleClose = (tab) => {
        setModalState("")
        setNewTitle("")
    };

    let { id } = useParams()

    let groupDetailsDataCheck = false;//eslint-disable-line

    const statusFormatterCSV = (cell) => {
        return cell ? "Active" : "Inactive"
    }
    const basicValidation = (cell) => {
        return cell ? cell : "N/A"
    }
    const toggleForCategory = (tab) => {
        setModalState("")
        setNewTitle("")
    };

    const actionsFormatter = (cell, row) => {
        return (
            <>
                <Button
                    className="money-button-blue"
                    outline
                    title={"Edit Category"}
                    onClick={() => {
                        setModal(true);
                        setNewTitle(row.title);
                        setGroupIdValue(row.group_id);
                        setModalState("edit");
                        setSelectedId(row.id)
                    }}
                >
                    <FontAwesomeIcon icon={faEdit} title={"Edit Category"} />
                </Button>
                {row.status === 0 ? <Button
                    className="money-button-blue"
                    outline
                    title={"Inactive"}
                >
                    <FontAwesomeIcon icon={faTrash} style={{ color: "#808080" }} />
                </Button> : <Button
                    className="money-button-blue"
                    outline
                    title={"Delete Category"}
                    onClick={() => {
                        setSelectedId(row.id)
                        setDeleteModal(true);
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} style={{ color: "#E12C2C" }} title={"Delete Category"} />
                </Button>}
            </>
        )
    }

    const statusFormatter = (cell, row) => {
        let status = "";
        let badgeclass = " ";
        if (cell === 1) {
            status = "Active";
            badgeclass = "badge badge-success statustoggle status-button-width";
        } else if (cell === 0) {
            status = "Inactive";
            badgeclass = "badge badge-danger statustoggle status-button-width";
        }
        else {
            status = "N/A";
        }

        return (
            <>
                <span
                    className={badgeclass}
                    onClick={() => {
                        setStatus(row.status)
                        setFacilityID(row.id)
                        setStatusChangeModal(true)
                    }}
                >
                    {status}
                </span>
            </>
        );
    };

    const columns = [
        {
            dataField: 'title',
            text: 'Category',
            sort: true,
            formatter: basicValidation,
            csvFormatter: basicValidation,
        },

        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: statusFormatter,
            csvFormatter: statusFormatterCSV,
        },
        {
            text: 'Actions',
            sort: false,
            csvExport: false,
            formatter: actionsFormatter
        }
    ];

    const getCarePlanCategory = () => {
        const params =
        {
            "program_id": id ? id : "",
        }
        setSpinner(true);
        AxiosInter.post(`${API_ENDPOINT}/admin-api/get-care-plan-program-category/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.data.data.length > 0) {
                    setFacilityDetails(response.data.data);
                    setTotalSize(response.data.record_count)
                    setGroupDetailsList(response.data.data);
                    setSpinner(false);
                    localStorage.setItem('offset', offset);
                }
                else {
                    setTotalSize(0)
                    setFacilityDetails("");
                    setGroupDetailsList("");
                    setSpinner(false);
                }
            })
            .catch(error => {
                setSpinner(false);
            });
    };
    const addNewCategory = () => {
        const params =
        {
            "program_id": id ? id : "",
            "title": newTitle ? newTitle : "",
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/add-care-plan-category/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                toastr.success("Success", "New category added")
                setReload(reload + 1);
                getCarePlanCategory()
                toggleForCategory();
            })
            .catch(error => {
                if (error.response.data.message) {
                    toastr.error("Error", error.response.data.message)
                }
                else {
                    toastr.error("Error", "Something went wrong !")
                }
            });
    }
    const deleteCategory = () => {
        setSpinner(true);
        const params = {
            row_id: selectedId ? selectedId : "",
        };

        AxiosInter
            .post(`${API_ENDPOINT}/admin-api/delete-care-plan-category/`, params, {
                headers: {
                    Authorization: API_TOKEN,
                },
            })
            .then((response) => {
                toastr.success("Success", "Deleted successfully");
                setSpinner(false);
                setReload(reload + 1);
                setDeleteModal(false);
                getCarePlanCategory()

            })
            .catch((error) => {
                toastr.error("Error", "Something went wrong");
                setSpinner(false);
            });
    };
    const updateCarePlanCategoryStatus = () => {
        setSpinner(true);
        const params = {
            "row_id": facilityID ? facilityID : "",
            "status": status === 0 ? 1 : 0
        };

        AxiosInter
            .post(`${API_ENDPOINT}/admin-api/update-program-category-status/`, params, {
                headers: {
                    Authorization: API_TOKEN,
                },
            })
            .then((response) => {
                setReload(reload + 1)
                setStatusChangeModal(false);
                setSpinner(false);
                toastr.success('Status Updated', 'Status updated successfully');
            })
            .catch((error) => {
                toastr.error("Error", "Something went wrong");
                setSpinner(false);
            });
    };

    const handleCategoryTableChange = (type, newState) => {
        setPage(newState.page)
        setSizePerPage(newState.sizePerPage)
        setOffset((newState.page - 1) * newState.sizePerPage)
        const currentIndex = 0;
        if (newState.sortField !== "" && newState.sortOrder !== "") {
            let result;
            if (newState.sortOrder === 'asc') {
                result = facilityDetails.sort((a, b) => {
                    if (a[newState.sortField] > b[newState.sortField]) {
                        return 1;
                    } else if (b[newState.sortField] > a[newState.sortField]) {
                        return -1;
                    }
                    return 0;
                });
            } else {
                result = facilityDetails.sort((a, b) => {
                    if (a[newState.sortField] > b[newState.sortField]) {
                        return -1;
                    } else if (b[newState.sortField] > a[newState.sortField]) {
                        return 1;
                    }
                    return 0;
                });
            }
            setFacilityDetails(result.slice(currentIndex, currentIndex + sizePerPage));
        }
    }
    const searchButtonValidation = () => {
        if (selectedFacilityName || selectedType || selectedTimeZone || selectedStatus || selectedAccessType || selectedIsPrimary) {
            setSearch(true)
        } else {
            setSearch(false)
        }
    }

    useEffect(() => {
        if (location.state) {
            localStorage.setItem('isFilterApplied', JSON.stringify(location.state.detail))
        }

    }, [location])

    useEffect(() => {
        if (facilityAccessType.length <= 0) {
            fetchFacilityAccessType();
        }

    }, [facilityAccessType]) // eslint-disable-line

    useEffect(() => {
        if (facilityReload >= 1) {
            getCarePlanCategory();
        }
    }, [facilityReload])//eslint-disable-line

    useEffect(() => { if (reload) { getCarePlanCategory(); setReload(false); } }, [reload])// eslint-disable-line
    useEffect(() => {
        if (isGlobalSearch) {
            setOffset(0)
            setTotalSize(0);
            getCarePlanCategory();
            setIsGlobalSearch(false);
        }
    }, [isGlobalSearch])//eslint-disable-line

    useEffect(() => {
        var previousOffset = localStorage.getItem('offset')
        if (offset !== previousOffset) {
            getCarePlanCategory();
        }

    }, [offset, sizePerPage]) // eslint-disable-line

    useEffect(() => {
        if (searchKeyword.length >= 2) {
            setIsGlobalSearch(true);


        }
    }, [searchKeyword])

    useEffect(() => {
        if (searchKeyword.length === 0) {
            setReloadFacilityDetails(true)
        }

    }, [searchKeyword])

    useEffect(() => {
        searchButtonValidation();
    }, [selectedFacilityName, selectedType, selectedTimeZone, selectedStatus, selectedAccessType, selectedIsPrimary])//eslint-disable-line

    useEffect(() => {
        document.title = "Equipo Admin | Groups"
    }, [])
    return (
        <div>
            <Container fluid>
                <Header>
                    <HeaderTitle>
                        Programs
                    </HeaderTitle>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/dashboard">Dashboard</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem><Link to="/programs">Programs</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Category</BreadcrumbItem>
                    </Breadcrumb>
                </Header>
                <Card className="adv-search-background-card-pt2">
                    <Container fluid>
                        <Row style={{ 'marginBottom': '0rem' }}>
                            <div style={{ 'justifyContent': 'left' }}>
                                <FontAwesomeIcon
                                    size={'lg'}
                                    style={{ 'cursor': 'pointer' }}
                                    title={'Go Back'}
                                    icon={faArrowLeft}
                                    onClick={goBack}
                                />
                            </div>
                            <Col>
                                <Button className="Add-btn pull-right"
                                    onClick={() => {
                                        setModalState("addCSCategory");
                                    }}
                                >
                                    Add new category
                                </Button>
                                <h2 style={{ 'textAlign': 'center' }}>{localStorage.getItem("title")}</h2>
                            </Col>
                        </Row>
                    </Container>
                </Card>
                <Card>
                    {!facilityListLoading ?
                        <>
                            <>
                                {modalState === "addCSCategory" ?
                                    <Row style={{ paddingLeft: 40, paddingTop: 30 }}>
                                        <Col sm={1.5}>
                                            <Label for="cpt_code">Category<span className="required">*</span></Label>
                                        </Col>
                                        <Col sm={4}>
                                            <Input
                                                id="cpt_code"
                                                placeholder="Enter Category Name"
                                                value={newTitle}
                                                onChange={(e) => {
                                                    setNewTitle(e.target.value)
                                                }}
                                            />
                                        </Col>
                                        <Col sm={4} className="program-tick-minus" style={{ paddingLeft: 0 }}>
                                            <Button
                                                className="tick-button"
                                                onClick={() => {
                                                    addNewCategory()
                                                }}
                                                title="Click to save"
                                            ><FontAwesomeIcon icon={faCheck} title="Click to save" /></Button>

                                            <Button
                                                className="minus-button"
                                                title="Close"
                                                fixedWidth
                                                onClick={toggleClose}><FontAwesomeIcon icon={faTimes} title="Close" /></Button></Col>

                                    </Row> : ""}<br />
                            </>
                        </> : <Spinner />}
                    <SearchBarComponentForProgram
                        searchKeyword={searchKeyword}
                        setSearchKeyword={setSearchKeyword}
                        totalSize={totalSize}
                        columns={columns}
                    />
                    <Row>
                        <Col>
                            <RemotePaginationForProgram
                                data={facilityDetails ? facilityDetails : ""}
                                columns={columns}
                                page={page}
                                sizePerPage={sizePerPage}
                                totalSize={totalSize ? totalSize : ""}
                                fetchFacilityByGroup={getCarePlanCategory}
                                FileExportName={fileExportName}
                                onTableChange={(type, newState) => {
                                    handleCategoryTableChange(type, newState);

                                }
                                }
                            />
                        </Col>
                    </Row>
                </Card>
                <NewFacilityModal
                    newFacilityModal={newFacilityModal}
                    setNewFacilityModal={setNewFacilityModal}
                    timeZoneList={timeZoneList}
                    groupID={id}
                />
                <EditFacilityModal
                    facilityAccessType={facilityAccessType}
                    setEditFacilityModal={setEditFacilityModal}
                    editFacilityModal={editFacilityModal}
                    timeZoneList={timeZoneList}
                    editType={editType}
                    zipCodeList={m_zipList}
                    editFacilityName={editFacilityName}
                    setEditFacilityName={setEditFacilityName}
                    editAddress1={editAddress1}
                    setEditAddress1={setEditAddress1}
                    editAddress2={editAddress2}
                    setEditAddress2={setEditAddress2}
                    editPrimaryPhone={editPrimaryPhone}
                    setEditPrimaryPhone={setEditPrimaryPhone}
                    editReferalFax={editReferalFax}
                    setEditReferalFax={setEditReferalFax}
                    editDepartment={editDepartment}
                    setEditDepartment={setEditDepartment}
                    editRoom={editRoom}
                    setEditRoom={setEditRoom}
                    editLatitude={editLatitude}
                    setEditLatitude={setEditLatitude}
                    editLongitude={editLongitude}
                    setEditLongitude={setEditLongitude}
                    editTimeZone={editTimeZone}
                    setEditTimeZone={setEditTimeZone}
                    editNPI={editNPI}
                    setEditNPI={setEditNPI}
                    editZipID={editZipID}
                    editZipCode={editZipCode}
                    setEditZipCode={setEditZipCode}
                    editFloor={editFloor}
                    setEditFloor={setEditFloor}
                    editWing={editWing}
                    setEditWing={setEditWing}
                    editContactPerson={editContactPerson}
                    setEditContactPerson={setEditContactPerson}
                    editFacilityID={editFacilityID}
                    setEditFacilityID={setEditFacilityID}
                    facilityReload={facilityReload}
                    setFacilityReload={setFacilityReload}
                    editDescription={editDescription}
                    setEditDescription={setEditDescription}
                    setEditType={setEditType}
                    accessType={accessType}
                    setAccessType={setAccessType}
                    isPrimary={isPrimary}
                    setIsPrimary={setIsPrimary}
                    editGroupID={id}
                    setEditGroupID={setEditGroupID}
                    editCenter={editCenter}
                    setEditCenter={setEditCenter}

                />
                <FacilityStatusChangeModal
                    facilityID={facilityID}
                    facilityReload={facilityReload}
                    setFacilityReload={setFacilityReload}
                    status={status}

                />
                <EditCareServiceCategoryModal
                    newTitle={newTitle}
                    setNewTitle={setNewTitle}
                    setGroupIdValue={setGroupIdValue}
                    groupIdValue={groupIdValue}
                    modal={modal}
                    setModal={setModal}
                    setReload={setReload}
                    reload={reload}
                    selectedId={selectedId}
                />
                <DeleteConfirmationModal
                    deleteModal={deleteModal}
                    setDeleteModal={setDeleteModal}
                    deleteFunction={deleteCategory}
                />
                <StatusChangeModalPrograms
                    modalState={statusChangeModal}
                    setModalState={setStatusChangeModal}
                    status={status}
                    updateCarePlanProgramStatus={updateCarePlanCategoryStatus}
                />

                <NotificationPreferenceModal
                    notificationPreferenceModal={notificationPreferenceModal}
                    setNotificationPreferenceModal={setNotificationPreferenceModal}
                    newEmail={newEmail}
                    setNewEmail={setNewEmail}
                    id={id}
                    facilityID={facilityID}
                    currentFacilityStatus={currentFacilityStatus}
                    facilityReload={facilityReload}
                    setFacilityReload={setFacilityReload}
                    centralNotification={centralNotification}
                    setCentralNotification={setCentralNotification}
                    setReloadFacilityDetails={setReloadFacilityDetails}
                    reloadFacilityDetails={reloadFacilityDetails}
                    fetchFacilityByGroup={getCarePlanCategory}
                />
            </Container>
        </div>
    );
}

export default CareServiceProgramCategory;