import React, { useEffect, useState } from "react";
import ReferralOrderSender from "./ReferralOrderSender";
import ReferralOrderFullfiller from "./ReferralOrderFullfiller";
import ReferralCentralNotification from "./ReferralCentralNotification";
import AxiosInter from './../../AxiosInter.js';
import { Button, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import AsyncSelect from "react-select/async";
import Cookie from 'js-cookie';


const ReferralPreferance = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [groupID, setGroupID] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [search, setSearch] = useState(false);
  const [categoryTypePopulated, setcategoryTypePopulated] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");

  const handleGroupNameChange = (value) => {
    setGroupName(value.label);
    setGroupID(value);
  };
  const handleInputChange = (value) => {
    setInputValue(value);
  };

  

  const groupNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };


  const clearValues = () => {
    setGroupID(0);
    setGroupName("");
    setcategoryTypePopulated([])
  };
  const enableOrDisableSearch = () => {
    if (groupID) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  useEffect(() => {
    enableOrDisableSearch();
  }, [groupID]); // eslint-disable-line

  return (
    <div className="col-md-12">
      <Card>
        <br></br>
        <Row style={{ marginBottom: "20px" }}>
          <CardHeader className="ref-preference-grp-pad">
            <br></br>
            <h5>
              <strong>Select Group Name:</strong>
            </h5>
          </CardHeader>

          <Col sm={2}>
            <br></br>
            <AsyncSelect
              cacheOptions
              isSearchable
              type="text"
              name="groupName"
              placeholder="Select Group."
              value={groupID}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              validate={{
                maxLength: { value: 120 },
              }}
              onInputChange={handleInputChange}
              onChange={handleGroupNameChange}
              loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
            />
          </Col>
          <div>
            <Col sm={0}>
              <Button
                className="patient-reset-buttons mrg-top-27"
                onClick={() => {
                  clearValues();
                }}
                disabled={!search ? true : false}
                title={!search ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>
            </Col>
          </div>
        </Row>
        <Row>
          <Col sm={12} style={{ textAlign: "center" }}>
            {groupName && <h4 style={{ fontWeight: "bold" }}>{groupName}</h4>}
          </Col>
        </Row>

        {groupID ? (
          <>
            <CardBody>
              <div className="row">
                <div className="col-md-4">
                  <ReferralOrderFullfiller
                    groupID={groupID}
                    setGroupID={setGroupID}
                    categoryTypePopulated={categoryTypePopulated}
                    setcategoryTypePopulated={setcategoryTypePopulated}
                  />
                </div>
                <div className="col-md-4">
                  <ReferralOrderSender
                    groupID={groupID}
                    setGroupID={setGroupID}
                    categoryTypePopulated={categoryTypePopulated}
                    setcategoryTypePopulated={setcategoryTypePopulated}
                  />
                </div>

                <div className="col-md-4">
                  <ReferralCentralNotification
                    groupID={groupID}
                    setGroupID={setGroupID}
                    categoryTypePopulated={categoryTypePopulated}
                  />
                </div>

                <Col sm={1}></Col>
              </div>
            </CardBody>
          </>
        ) : (
          ""
        )}
      </Card>
    </div>
  );
};

export default ReferralPreferance;
