import React, { useState, useEffect } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";

import AsyncSelect from "react-select/async";
import Cookie from 'js-cookie';
//import _ from "lodash";
//import { NULL } from 'node-sass';

const NewPatientEvaluationsModal = (props) => {
  const {
    newPatientEvaluationsModal,
    setNewPatientEvaluationsModal,
    userDataReload,
    setUserDataReload,
    newGroupName,
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  // const [newGroupName, setNewGroupName] = useState("");

  const [newPatientEvaluationDescription, setNewPatientEvaluationDescription] =
    useState("");

  const [newPatientEvaluationTitle, setNewPatientEvaluationTitle] =
    useState("");
  const [grp, setGrp] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");

  const [addButtonStatus, setAddButtonStatus] = useState(true);
  const [timeZoneDropDown, setTimeZoneDropDown] = useState([]); // eslint-disable-line

  const toggle = () => {
    setNewPatientEvaluationsModal(!newPatientEvaluationsModal);

    //setNewGroupName("")
    setNewPatientEvaluationDescription("");
    setNewPatientEvaluationTitle("");
  };

  const confirmation = () => {
    if (!newGroupName) {
      toastr.error("group Name Is Required", "Please enter the Group name!");
    }
    if (!newPatientEvaluationDescription) {
      toastr.error(
        "description Field Is Required",
        "Please enter the description!"
      );
    }
    if (!newPatientEvaluationTitle) {
      toastr.error("Title Field Is Required", "Please enter the description!");
    }

    if (
      newGroupName &&
      newPatientEvaluationDescription &&
      newPatientEvaluationTitle
    ) {
      CreatePatientEvaluationapiCall();
    }
  };

  const CreatePatientEvaluationapiCall = () => {
    // let gp_num = newCenterID ? newCenterID.value : ""
    // let gp_string = '' + gp_num

    const params = {
      groupID: grp ? grp[0].value : null,
      description: newPatientEvaluationDescription
        ? newPatientEvaluationDescription
        : "",
      title: newPatientEvaluationTitle ? newPatientEvaluationTitle : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/create-new-patient-evaluations/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setUserDataReload(userDataReload + 1);
        toastr.success(
          "Successfully Created",
          "Patient Evaluations successfully created"
        );
        toggle();
      })
      .catch(function (error) {
        toastr.error("Failed", "Please try again");
      });
  };

  const disableAddButton = () => {
    if (
      newGroupName &&
      newPatientEvaluationTitle &&
      newPatientEvaluationDescription
    ) {
      setAddButtonStatus(false);
    } else {
      setAddButtonStatus(true);
    }
  };

  const fetchGroupbyId = (newGroupName) => {
    AxiosInter
      .get(
        `${API_ENDPOINT}/admin-api/get-groupid-list/${Number(newGroupName)}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          setGrp(response.data.data);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong in region listing !!");
      });
  };

  useEffect(() => {
    if (newGroupName !== "" && newGroupName !== undefined) {
      fetchGroupbyId(newGroupName);
    }
  }, [newGroupName]); // eslint-disable-line

  useEffect(() => {
    disableAddButton();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    newGroupName,
    newPatientEvaluationTitle,
    newPatientEvaluationDescription,
  ]); // eslint-disable-line

  const handleInputChange = (value) => {
    setInputValue(value);
  };
  // const handleChange = value => {
  //   setNewGroupName(value);
  // }
  //   const handleCategoryChange = value => {
  //     setNewCategory(value);
  //   }

  // const loadHabitOptionsForGroupName = (inputValue) => {
  //   if (inputValue.length >= 4) {
  //     const searchParams = new URLSearchParams ({name: inputValue});
  //     const url = `${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`;
  //     var temp = fetch(url,
  //       {
  //         headers: {
  //           Authorization: API_TOKEN,
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => data.data);
  //     return temp;
  //   }
  // };

  const loadHabitOptionsForGroupName = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  return (
    <div>
      <Modal
        isOpen={newPatientEvaluationsModal}
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Add New Patient Evaluations{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          <FormGroup>
            <Row>
              <Col sm={6}>
                <Label>
                  Group Name <span className="required">*</span> :
                </Label>
                <AsyncSelect
                  cacheOptions
                  isSearchable
                  placeholder="Group Name"
                  value={grp ? grp : ""}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  onInputChange={handleInputChange}
                  onChange={(e) => {
                    setGrp(e);
                  }}
                  loadOptions={(inputValue) =>
                    loadHabitOptionsForGroupName(inputValue)
                  }
                />
                {/* <Input
                                        id="Group Name"
                                        
                                        value={newGroupName}
                                        placeholder="Enter the Group"
                                        onChange={(e) => { if (e.target.value.length <= 150) setNewGroupName(e.target.value) }}
                                    /> */}
              </Col>
              <Col sm={6}>
                <Label>
                  Title<span className="required">*</span> :
                </Label>

                <Input
                  value={newPatientEvaluationTitle}
                  placeholder="Enter the description"
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setNewPatientEvaluationTitle(e.target.value);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Label>
                  Description<span className="required">*</span> :
                </Label>
                <Input
                  id="Description"
                  type="textarea"
                  rows={3}
                  cols={2}
                  value={newPatientEvaluationDescription}
                  placeholder="Enter the description"
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setNewPatientEvaluationDescription(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button  custom-modal-footer">
          <Button
            onClick={() => {
              confirmation();
            }}
            disabled={addButtonStatus}
            title={addButtonStatus ? "Fill the required fields" : ""}
          >
            Save
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewPatientEvaluationsModal;
