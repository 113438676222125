import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, Container,Spinner, ModalBody, ModalFooter, Row, Col, Card,CardHeader,CardBody, Label, FormGroup, } from 'reactstrap';
import DropZone from '../utilities/DropZone'
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import AsyncSelect from "react-select/async";
import ImportAppUsersLogDetails from '../../newComponents/utilities/ImportAppUsersLogDetails';

// import _ from "lodash";// eslint-disable-line



const CSVUploadModal = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

    const [csvFile, setCsvFile] = useState("");
    const [logFileName,setLogFileName] = useState("");
    const [selectedGroup,setSelectedGroup] = useState([]);
    const [buttonBlankState,setButtonBlankState] = useState(true);
    const [spinner,setSpinner] = useState(false);
    const [logDetailsModal,setLogDetailsModal] = useState(false);
    const [apiLoading,setApiLoading] = useState(false);
    const [fileReady,setFileReady] = useState(false); // eslint-disable-line

   

    const {
        setUploadModal,
        uploadModal,
        // groupDropDownOptions,
        appUserReload,
        setAppUserReload
       
    }=props;

    // const getAsyncOptions = (inputValue) => { 
    //     if(groupDropDownOptions){
    //      return new Promise((resolve, reject) => {
    //          const filtered = _.filter(groupDropDownOptions, o =>
    //            _.startsWith(_.toLower(o.label), _.toLower(inputValue))
    //          );
    //          resolve(filtered);
    //        });
    //     }
    // }

    const loadOptionsForGroupNames = (inputValue) => {
      if (inputValue.length >= 4) {
        const searchParams = new URLSearchParams ({name: inputValue});
        return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-group-names-list/?${searchParams.toString()}`,
         {
              headers: {
                  Authorization: API_TOKEN,
              }
          })
              .then((response)=> response.data.data)
              .catch((error) => {
                throw error;
              });
      }
    }


    const toggle = () => { setUploadModal(!uploadModal); }

 

    const documentUpload = (
        <>
          <Card className="kyc-bg-color2">
            <CardHeader tag="h5">Upload Document </CardHeader>
            <CardBody>
    
              {/* DRAG AND DROP COMPONENT  */}
              <DropZone
                setFileReady={setFileReady}
                // documentTypeId={documentTypeId}
                setCsvFile={setCsvFile}
              
              />
            </CardBody>
          </Card>
          <Label>The file should be in .csv format  </Label>
        </>
      )

      const validate = () => {
          if(csvFile) {
            setButtonBlankState(false)
          }
          else {
            setButtonBlankState(true)
          }
      }


    const uploadCSVFile = () => { 
      setApiLoading(true);
      if (selectedGroup.length > 0) {
        var groupIDs = selectedGroup.map((item)=>{
          return Number(item.value)
      })
      }
      else {
        groupIDs = ""
      }
     
        setSpinner(true);
        
        const FileFormData = new FormData();
        FileFormData.set("file", csvFile[0]);
        FileFormData.set("group_id",groupIDs);

        AxiosInter.post(`${API_ENDPOINT}/admin-api/import-app-users/`, FileFormData, {
            headers: {
              Authorization: API_TOKEN,
            },
          })
          .then(function (response) {
            toastr.success(
              "Success",
              response.data.message
            );
            setSpinner(false);
            toggle();
            setAppUserReload(appUserReload + 1);
            setLogDetailsModal(true);
            setLogFileName(response.data.data.log_file_name)
            setApiLoading(false)
          })
          .catch(function (error) {
            if (error.response.data) {
              toastr.error(
                "Error",
                error.response.data.message
              );
            }
            else {
              toastr.error(
                "Error",
                'Something went wrong' 
              );
            }
            
            setSpinner(false);
            setApiLoading(false);
    
          });
      }

    


    useEffect(() => {
        if (!uploadModal) {
            setUploadModal(false);
            setCsvFile("");
            setSelectedGroup("");
            
        };

    }, [uploadModal]) // eslint-disable-line

    // useEffect(() => {
    //     if (selectedGroup) {
    //         getGroupIds();
    //     }

    // }, [selectedGroup]) // eslint-disable-line

    // useEffect(() => { if(groupList) createGroupOption();  }, [groupList]) // eslint-disable-line 

    useEffect(() => {
    
            validate();
        

    }, [csvFile]) // eslint-disable-line

   



    return (
        <div>
            <Modal isOpen={uploadModal} size="md">
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Import App Users</ModalHeader>
                {spinner ? <Spinner/>:""}
                <ModalBody className="overflow-modal">
                    <Container fluid>
                        <FormGroup>
                            <Row>
                                <Col sm={12}>
                                <Label>Group Name</Label>
                                <AsyncSelect
                                isMulti
                                cacheOptions
                                isClearable
                                isSearchable
                                value={selectedGroup}
                                placeholder={'Search Group'}
                                onChange={(e) => {
                                    setSelectedGroup(e)
                                }}
                                // loadOptions={inputValue => getAsyncOptions(inputValue)}
                                loadOptions={loadOptionsForGroupNames}
                                />
                            

             
                                </Col>


                            </Row><br/>
                            <Row>
                            <Col sm={12}>
                            {documentUpload}
                            </Col>
                          
                            </Row>

                        </FormGroup>
                    </Container>

                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button

                        onClick={() => {
                            uploadCSVFile();
                        }}
                        disabled={buttonBlankState || apiLoading ? true:false}
                        title= {buttonBlankState ? "Please attach a csv file":"Click to Upload"}

                    >Upload</Button>{' '}

                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <ImportAppUsersLogDetails
            logDetailsModal = {logDetailsModal}
            setLogDetailsModal = {setLogDetailsModal}
            logFileName = {logFileName}
            setLogFileName = {setLogFileName}

            
            />
        </div>
    );
}

export default CSVUploadModal;