import L from "leaflet";
import mapic from "../../assets/medical-39_icon-icons.com_73906.svg"

// export default L.icon({
//   iconSize: [25, 41],
//   iconAnchor: [10, 41],
//   popupAnchor: [2, -40],
//   iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
//   shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
// });
let markeric = L.icon({
    iconUrl: mapic,
    iconRetinaUrl: mapic,
    iconAnchor: [5, 55],
    popupAnchor: [10, -44],
   // iconSize: [25, 55],
   iconSize: [30,65]
  });
  export default markeric;
  