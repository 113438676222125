import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import AsyncSelect from "react-select/async";



const NewMicroServiceGroup = (props) => {
    const {
        newdrgModal,
        setNewDrgModal,
        reload,
        setReload



    } = props

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;
    const API_TOKEN = localStorage.getItem("accessToken")
    const [serviceOptions, setServiceOptions] = useState([]); // eslint-disable-line
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [newGroupName, setNewGroupName] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newServices, setNewServices] = useState([]);
    const regXForNubers = /^\d*\.?\d*$/; // eslint-disable-line

    const toggle = () => {
        setNewDrgModal(!newdrgModal);
        setNewGroupName("")
        setNewEmail("")

    }


    const CreateGroupApiCall = () => {
        let array = [] // eslint-disable-line
       const options = newServices.map((item) =>{
            return item.value;
       })
        const params = {

            name: newGroupName,
            email: newEmail ? newEmail : "",
            services: options ? options : "",
            // services_id: array_id ? array_id :"",
            status: 1

        }
        // debugger

        axios.post(`${API_ENDPOINT}api/groups/`, params, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(response => {
                toastr.success("Success", "New Group added");
                toggle();
                setReload(reload + 1);



            })
            .catch(error => {

                toastr.error("Failed", "Something went wrong !")

            });
    }

const fetchServiceOptions = (inputValue) => {
    if(inputValue.length >= 4){
        const searchParams = new URLSearchParams ({name: inputValue});
        return axios.get(`${API_ENDPOINT}api/services-load-options/?${searchParams.toString()}`, {
            headers: {
              Authorization: API_TOKEN,
            }
         })
       .then((response) => response.data.data)
       .catch((error) => {
            throw error;
       });
      }
};

  useEffect(() =>{
    // debugger
    console.log(newServices)
    if (newdrgModal){
        setNewServices([])
    }
    
    
  },[newdrgModal]) // eslint-disable-line react-hooks/exhaustive-deps
  
    const disableAddButton = () => {
// debugger
        if (newGroupName && newEmail && (newServices.length>0)) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }

    useEffect(() => { disableAddButton() }, [newGroupName, newEmail, newServices]) // eslint-disable-line


    return (
        <div>
            <Modal isOpen={newdrgModal} toggle={toggle} size="md" scrollable={true} >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add New Group </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row  >
                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        Group Name <span className="required">*</span> :

                                    </Label>
                                </div>


                                <Input
                                    id="drgCode"
                                    value={newGroupName}
                                    placeholder="Enter Group Name"
                                    onChange={(e) => { if (e.target.value.length <= 150) setNewGroupName(e.target.value) }}
                                />
                            </Col>

                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        Email :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Input
                                        id="Description"
                                        value={newEmail}
                                        placeholder="Enter Email"
                                        onChange={(e) => { if (e.target.value.length <= 150) setNewEmail(e.target.value) }}
                                    />
                                </div>
                            </Col>
                        </Row><br></br>
                        <Row  >
                            <Col  >
                                <div className="" >
                                    <Label>

                                        Services  <span className=""></span> :

                                    </Label>
                                </div>

                                <div className="" >
                                    <AsyncSelect
                                        // options={mdcOptions}
                                        onChange={(event) => setNewServices(event)}
                                        value={newServices}
                                        placeholder="Select Services"
                                        isSearchable
                                        isClearable
                                        isMulti
                                        loadOptions={(inputValue) => fetchServiceOptions(inputValue)}
                                    />
                                </div>
                            </Col>
                            
                        </Row>


                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={CreateGroupApiCall}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>

        </div>

    );
};

export default NewMicroServiceGroup;