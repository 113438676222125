/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState, useContext } from 'react';
import { Card, Col, Row, Button, Breadcrumb, BreadcrumbItem, Container, CardHeader, Label, Input, Spinner, DropdownToggle, UncontrolledDropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link, useParams, useLocation } from "react-router-dom";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit } from "@fortawesome/free-solid-svg-icons";
import { GlobalState } from '../../contextAPI/Context'
import FacilityStatusChangeModal from "../../../src/newComponents/utilities/FacilityStatusChangeModal"
import EditFacilityModal from '../../newComponents/utilities/EditFacilityModal';
import RemotePagination from '../../newComponents/utilities/RemotePagination'
import NewFacilityModal from '../../newComponents/utilities/NewFacilityModal';
import Select from "react-select";
import SearchBarComponent from '../../newComponents/utilities/SearchBarComponent';
import { useNavigate } from "react-router-dom";
import MomentConfig from '../../common/MomentConfig';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { faEllipsisV, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import NotificationPreferenceModal from '../../../src/newComponents/utilities/NotificationPreferenceModal'


const GroupFacilities = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [facilityDetails, setFacilityDetails] = useState([]);
  const [facilityID, setFacilityID] = useState("");
  const [modalState, setModalState] = useState(false);
  const [status, setStatus] = useState(0);
  const [editFacilityModal, setEditFacilityModal] = useState(false);
  const [editType, setEditType] = useState("");

  const [editGroupID, setEditGroupID] = useState("");//eslint-disable-line
  const [editFacilityID, setEditFacilityID] = useState("");
  const [editFacilityName, setEditFacilityName] = useState("");
  const [editAddress1, setEditAddress1] = useState("");
  const [editAddress2, setEditAddress2] = useState("");
  const [editPrimaryPhone, setEditPrimaryPhone] = useState("");
  const [editReferalFax, setEditReferalFax] = useState("");
  const [editContactPerson, setEditContactPerson] = useState("");
  const [editDepartment, setEditDepartment] = useState("");
  const [editFloor, setEditFloor] = useState("");
  const [editWing, setEditWing] = useState("");
  const [editRoom, setEditRoom] = useState("");
  const [editLatitude, setEditLatitude] = useState("");
  const [editCenter, setEditCenter] = useState("")
  const [editLongitude, setEditLongitude] = useState("");
  const [editTimeZone, setEditTimeZone] = useState("");
  const [editNPI, setEditNPI] = useState("");;
  const [editZipID, setEditZipID] = useState("");;
  const [editZipCode, setEditZipCode] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [totalSize, setTotalSize] = useState();
  const [offset, setOffset] = useState(0);
  const [newFacilityModal, setNewFacilityModal] = useState(false);
  const [selectedFacilityName, setSelectedFacilityName] = useState("");
  const [selectedType, setSelectedType] = useState("");
  // const [timeZoneDropDownOptions, setTimeZoneDropDownOptions] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedAccessType, setSelectedAccessType] = useState("");
  const [selectedIsPrimary, setSelectedIsPrimary] = useState("");
  const [data, setData] = useState([]);//eslint-disable-line
  const [isSearch, setIsSearch] = useState(false);//eslint-disable-line
  const [spinner, setSpinner] = useState(false);
  const [search, setSearch] = useState(false);
  const [reloadFacilityDetails, setReloadFacilityDetails] = useState(false);
  const [groupDetailsList, setGroupDetailsList] = useState([]);//eslint-disable-line
  const [reloadGroupDetails, setReloadGroupDetails] = useState(false);//eslint-disable-line
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [accessType, setAccessType] = useState("");
  const [isPrimary, setIsPrimary] = useState("");
  const [reload, setReload] = useState(0);
  const fileExportName = 'Group-Facilities'
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  // const [isDataEmpty, setIsDataEmpty] = useState(false);
  // const [searchRecordCount, setSearchRecordCount] = useState("");
  // const [groupFacilityDetails, setGroupFacilityDetails]= useState("");
  const [notificationPreferenceModal, setNotificationPreferenceModal] = useState(false);
  const [notificationToggleButton, setNotificationToggleButton] = useState(false);//eslint-disable-line
  const [newEmail, setNewEmail] = useState([])

  const [centralNotification, setCentralNotification] = useState(2)
  const [currentFacilityStatus, setFacilityCurrentStatus] = useState(0)

  let navigate = useNavigate();
  let location = useLocation();

  const goBack = () => {
    navigate(-1);
  };


  const statusOptions = [
    { value: "1", label: 'Active' },
    { value: "0", label: 'Inactive' }
  ]
  const typeDropDown = [
    { value: '1', label: "Hospital" },
    { value: '2', label: "Clinic" },
    { value: '3', label: "SNF" }]

  const accessTypeOptions = [

    {
      value: 'O',
      label: `Owner`,
    },
    {
      value: 'S',
      label: `Supervisor`,
    },
    {
      value: 'E',
      label: `External`,
    },
  ]

  const isPrimaryOptions = [
    {
      value: 1,
      label: `Yes`,
    },
    {
      value: 0,
      label: `No`,
    },

  ]
  const { fetchFacilityAccessType, facilityAccessType,
    setFacilityReload, facilityReload, m_zipList, facilityListLoading, timeZoneList
  } = useContext(GlobalState);




  let { id } = useParams()

  let groupDetailsDataCheck = false;//eslint-disable-line

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }
  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }
  const accessTypeFormatterCSV = (cell) => {
    if (cell === "O") {
      return "Owner"
    } else if (cell === "E") {
      return "External"
    } else if (cell === "S") {
      return "Supervisor"
    } else {
      return 'N/A'
    }
  }
  const isPrimaryFormatterCSV = (cell) => {
    return cell ? "Yes" : "No"
  }
  const accessTypeValidation = (cell) => {

    if (cell === "O") {
      return "Owner"
    } else if (cell === "E") {
      return "External"
    } else if (cell === "S") {
      return "Supervisor"
    } else {
      return 'N/A'
    }

  }
  const isPrimaryValidation = (cell) => {

    if (cell === 0) {
      return "No"
    } else if (cell === 1) {
      return 'Yes'
    } else {
      return ' N/A'
    }

  }



  const onClickNotigicationP = (row) => {
    // debugger
    var temp = []
    setFacilityID(row.id)
    setFacilityCurrentStatus(row.status)
    setCentralNotification(row.enableCentralNotification != null ? row.enableCentralNotification : 0)

    if (row.email === null) {

      setNewEmail('')
    }
    else {
      var parsedArray = JSON.parse(row.email)


      console.log('Parsed array', parsedArray)
      /* for (var i=0; i < parsedArray.length; i++) {
        console.log(parsedArray[i].trim())
        // temp.append(parsedArray[i].trim())
        setNewEmail(parsedArray)
      } */
      // eslint-disable-next-line array-callback-return
      parsedArray.map((item) => {
        temp.push(item.trim())

      })

    }
    console.log('TempValue', temp)
    console.log('TempStr', temp.toString())
    setNewEmail(temp)
    setNotificationPreferenceModal(true)
  }


  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={1.5}>
            <Button
              // className="money-button"
              outline
              style={{ 'margin-left': '3rem' }}
              onClick={() => {

                setEditFacilityModal(true)
                setEditType(row.type)
                setEditFacilityName(row.name)
                setEditAddress1(row.address1)
                setEditPrimaryPhone(row.primaryPhone)
                setEditReferalFax(row.referralFax)
                setEditFloor(row.floor)
                setEditWing(row.wing)
                setEditLatitude(row.latitude)
                setEditLongitude(row.longitude)
                setEditDepartment(row.department)
                setEditRoom(row.room)
                setEditTimeZone(row.workTime)
                setEditZipID(row.zipID)
                setEditAddress2(row.address2)
                setEditContactPerson(row.contactPerson)
                setEditFacilityID(row.id)
                setEditNPI(row.npi)
                setEditDescription(row.description)
                setAccessType(row.facilityType)
                setIsPrimary(row.isPrimary)
                setEditCenter(row.centerID)





              }}
            >

              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </Col>


          <Col sm={2}>



            <UncontrolledDropdown title="Options">
              <DropdownToggle className="dropdown-options">
                <FontAwesomeIcon
                  icon={faEllipsisV}


                  size={'lg'}


                />
              </DropdownToggle>


              <DropdownMenu align="end" container="body">
                <DropdownItem
                  onClick={() => {
                    onClickNotigicationP(row)

                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} />{" "}
                  Notification Preference
                </DropdownItem>
              </DropdownMenu>

            </UncontrolledDropdown>

          </Col>


        </Row>
      </>
    )


  }

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle button-width";
    } else if (cell === 0) {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle button-width";
    }
    else {
      status = "N/A";
    }

    return (
      <>
        <span
          className={badgeclass}
          onClick={() => {
            setStatus(row.status)
            setFacilityID(row.id)
            setModalState(true)
          }}
        >
          {status}
        </span>
      </>
    );
  };
  const clearValues = () => {
    setSelectedFacilityName("")
    setSelectedType("")
    setSelectedTimeZone("")
    setSelectedStatus("")
    setData("")
    setSelectedAccessType("")
    setSelectedIsPrimary("")
    setReloadFacilityDetails(true)
  }
  const columns = [
    {
      dataField: 'name',
      text: 'Facility',
      sort: true,
      //   formatter: setImage
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

      // formatter: basicValidation
    },
    {
      dataField: 'address1',
      text: 'Address',
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      // formatter: basicValidation
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
    },
    {
      dataField: 'workTime',
      text: 'Time Zone',
      sort: false,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: 'facilityType',
      text: 'Access Type',
      sort: true,
      // csvExport: false,
      csvFormatter: accessTypeFormatterCSV,
      formatter: accessTypeValidation
    },
    {
      dataField: 'isPrimary',
      text: 'Is Primary',
      sort: true,
      csvFormatter: isPrimaryFormatterCSV,
      formatter: isPrimaryValidation
    },
    {
      //   dataField: 'SubscriptionName',
      text: 'Actions',
      sort: false,
      csvExport: false,
      formatter: actionsFormatter
    }
  ];



  const fetchFacilityByGroup = () => {
    // debugger
    const params =
    {
      "groupid": id ? id : "",
      "facility_name": selectedFacilityName ? selectedFacilityName : "",
      "facility_type": selectedType ? selectedType.label : "",
      "facility_timezone": selectedTimeZone ? selectedTimeZone.label : "",
      "facility_status": selectedStatus ? selectedStatus.value : "",
      "record_limit": sizePerPage ? sizePerPage : "",
      "offset": offset ? offset : "0",
      "search_keyword": searchKeyword ? searchKeyword : "",
      "is_pagination": "1",
      "facility_access_type": selectedAccessType ? selectedAccessType.value : "",
      "facility_isprimary": selectedIsPrimary ? selectedIsPrimary.value : "",
      "sortOrder": sortOrder ? sortOrder : "",
      "sortColumn": sortColumn ? sortColumn : ""



    }

    setSpinner(true);

    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-facility-details-by-group/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        if (response.data.data.length > 0) {
          setFacilityDetails(response.data.data);
          // console.log(response.data.record_count)
          setTotalSize(response.data.record_count)
          setGroupDetailsList(response.data.data);
          // setSearchRecordCount(response.data.search_record_count);
          setSpinner(false);
          localStorage.setItem('offset', offset);

        }
        else {
          setTotalSize(0)
          setFacilityDetails("");
          setGroupDetailsList("");
          // if (isSearch) {
          //   groupDetailsDataCheck = false

          // }
          // setIsDataEmpty(true);
          setSpinner(false);

        }
      })
      .catch(error => {

        setSpinner(false);
        // setApiLoaded(true);
        // console.log(error);
        // debugger;
      });
  };

  const exportTableData = () => {

    const params =
    {
      "file_name": fileName ? fileName : "",
      "groupid": id ? window.atob(id) : "",
      "facility_name": selectedFacilityName ? selectedFacilityName : "",
      "facility_type": selectedType ? selectedType.label : "",
      "facility_timezone": selectedTimeZone ? selectedTimeZone.label : "",
      "facility_status": selectedStatus ? selectedStatus.value : "",
      "record_limit": "",
      "offset": "",
      "search_keyword": searchKeyword ? searchKeyword : "",
      "is_pagination": "",
      "facility_access_type": selectedAccessType ? selectedAccessType.value : "",
      "facility_isprimary": selectedIsPrimary ? selectedIsPrimary.value : "",
      "sortOrder": sortOrder ? sortOrder : "",
      "sortColumn": sortColumn ? sortColumn : ""


    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-groupfacility-table-data/`, params, {
      headers: {

        Authorization: API_TOKEN,

      },
      responseType: "blob"
    })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data, `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success(
            "Success",
            "Export successful"
          );

        }

      })
      .catch((error) => {

        toastr.error(
          "Failed",
          "Something went wrong"
        );
      });
  };


  const handleCategoryTableChange = (type, newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
    setOffset((newState.page - 1) * newState.sizePerPage)
    // setData(groupDetailsList)0000
    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("")
      setSortOrder("")
    }
    else {
      setSortColumn(newState.sortField)
      setSortOrder(newState.sortOrder)
      setFacilityReload(facilityReload + 1)
    }
  }
  const searchButtonValidation = () => {
    if (selectedFacilityName || selectedType || selectedTimeZone || selectedStatus || selectedAccessType || selectedIsPrimary) {
      setSearch(true)
    } else {
      setSearch(false)
    }
  }

  useEffect(() => {
    if (location.state) {
      localStorage.setItem('isFilterApplied', JSON.stringify(location.state.detail))
    }

  }, [location])


  /* useEffect(() => {
    fetchFacilityByGroup();
  
  }, [facilityReload]) // eslint-disable-line */

  // useEffect(() => { if (timeZoneList) setTimeZoneDropDown(timeZoneList); }, [timeZoneList])

  useEffect(() => {
    if (facilityAccessType.length <= 0) {
      fetchFacilityAccessType();
    }

  }, [facilityAccessType]) // eslint-disable-line

  useEffect(() => {
    if (facilityReload >= 1) {
      fetchFacilityByGroup();
      //setFacilityReload(false)
    }
  }, [facilityReload])//eslint-disable-line

  useEffect(() => { if (reloadFacilityDetails) { fetchFacilityByGroup(); setReloadFacilityDetails(false); } }, [reloadFacilityDetails])// eslint-disable-line
  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0)
      setTotalSize(0);
      fetchFacilityByGroup();
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch])//eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem('offset')
    if (offset !== previousOffset) {
      fetchFacilityByGroup();
    }

  }, [offset, sizePerPage]) // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);


    }
  }, [searchKeyword])

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setReloadFacilityDetails(true)

    }

  }, [searchKeyword])

  useEffect(() => {
    searchButtonValidation();
  }, [selectedFacilityName, selectedType, selectedTimeZone, selectedStatus, selectedAccessType, selectedIsPrimary])//eslint-disable-line

  useEffect(() => {
    document.title = "Equipo Admin | Groups"
  }, [])

  const clearSearchValues = () => {
    setSearchKeyword("");
    setReload(reload + 1)
  }
  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Groups
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem><Link to="/groups">Groups</Link></BreadcrumbItem>
            <BreadcrumbItem active>Group Facilities</BreadcrumbItem>
          </Breadcrumb>
        </Header>


        <Card className="adv-search-background-card-pt2">
          <Container fluid>
            <Row style={{ 'marginBottom': '0rem' }}>
              <div style={{ 'justifyContent': 'left' }}>
                <FontAwesomeIcon
                  size={'lg'}
                  style={{ 'cursor': 'pointer' }}
                  title={'Go Back'}
                  icon={faArrowLeft}
                  onClick={goBack}

                />




              </div>
              <Col>
                <Button className="Add-btn pull-right"
                  onClick={() => {
                    setNewFacilityModal(true)

                  }}

                >
                  Add new facility
                </Button>
                <h2 style={{ 'textAlign': 'center' }}>{localStorage.getItem("groupName")}</h2>
              </Col>
            </Row>
          </Container>
        </Card>

        <Card>
          {!facilityListLoading ?
            <CardHeader>

              <Row>
                <Col sm="4" md="2" lg="2.5">
                  <Label>Facility Name</Label>
                  <Input
                    name="Facility"
                    //options={facilityDropDown}
                    placeholder="Enter facility name"
                    isSearchable
                    isClearable
                    value={selectedFacilityName}
                    onChange={(e) => {
                      setSelectedFacilityName(e.target.value)
                    }}
                  />
                </Col>

                <Col xs={1.5}>
                  <Label>Type</Label>
                  <Select
                    name="Type"
                    options={typeDropDown}
                    placeholder="Select facility type"
                    isClearable
                    value={selectedType}
                    onChange={(e) => {

                      setSelectedType(e)
                    }}
                  />
                </Col>

                <Col sm="4" md="2" lg="2.5">
                  <Label>Time Zone</Label>
                  <Select
                    name="TimeZone"
                    options={timeZoneList}
                    placeholder="Choose TimeZone"
                    isClearable
                    value={selectedTimeZone}
                    onChange={(e) => {

                      setSelectedTimeZone(e)
                    }}
                  />
                </Col>
                <Col sm="2" md="2" lg="2">
                  <Label>Status</Label>
                  <Select
                    name="Status"
                    options={statusOptions}
                    placeholder="Choose status"
                    isClearable
                    value={selectedStatus}
                    onChange={(e) => {

                      setSelectedStatus(e)
                    }}
                  />
                </Col>
                <Col sm="2" md="2" lg="2">
                  <Label>Access Type</Label>
                  <Select
                    name="AccessType"
                    options={accessTypeOptions}
                    placeholder="Choose access type"
                    isClearable
                    value={selectedAccessType}
                    onChange={(e) => {

                      setSelectedAccessType(e)
                    }}
                  />
                </Col>

                <Col xs={1.5}>
                  <Label>Is Primary</Label>
                  <Select
                    name="IsPrimary"
                    options={isPrimaryOptions}
                    placeholder="Choose is primary"
                    isClearable
                    value={selectedIsPrimary}
                    onChange={(e) => {

                      setSelectedIsPrimary(e)
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="patient-go-buttons"
                    onClick={() => {
                      setSearchKeyword("")
                      setData("")
                      offset === 0 ? fetchFacilityByGroup() : setOffset(0)
                      setIsSearch(true);
                      setPage(1);
                      // if (selectedStatus) {setFacilityStatus(selectedStatus ? getValueFromArray(selectedStatus) : "")}
                    }}
                    disabled={spinner || !search ? true : false}
                    title={spinner || !search ? "Choose at least one item to search " : ""}
                  >Go</Button>
                  <Button
                    className="patient-reset-buttons"
                    onClick={() => { clearValues() }}
                    disabled={spinner || !search ? true : false}
                    title={spinner || !search ? "Nothing To Clear" : ""}
                  >Reset</Button>

                </Col>

              </Row>
              <div className="separator" />
            </CardHeader> : <Spinner />}
          <div className='groups-search'>
            <SearchBarComponent
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              totalSize={totalSize}
              columns={columns}
              // data={groupDetailsList ? groupDetailsList : ""}

              clearValues={clearSearchValues}
            />
          </div>
          <Row>
            <Col>
              <RemotePagination
                data={facilityDetails ? facilityDetails : ""}
                columns={columns}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize ? totalSize : ""}
                // isDataEmpty={isDataEmpty}
                fetchFacilityByGroup={fetchFacilityByGroup}
                FileExportName={fileExportName}
                exportTableData={exportTableData}
                onTableChange={(type, newState) => {
                  handleCategoryTableChange(type, newState);

                }
                }
              // searchKeyword={searchKeyword}
              // setSearchKeyword={setSearchKeyword}
              />

              {/* <BootstrapTable
              bootstrap4
              bordered={true}
              keyField='id'
              data={facilityDetails}
              noDataIndication={'Table is empty'}
              columns={columns}
              striped
              hover
              condensed
              pagination={paginationFactory({
                sizePerPage: 10,
                sizePerPageList: [50, 100, 150, 200],
              })}

            /> */}


            </Col>

          </Row>
        </Card>

        <NewFacilityModal
          newFacilityModal={newFacilityModal}
          setNewFacilityModal={setNewFacilityModal}
          timeZoneList={timeZoneList}
          groupID={id}
        />
        <EditFacilityModal
          facilityAccessType={facilityAccessType}
          setEditFacilityModal={setEditFacilityModal}
          editFacilityModal={editFacilityModal}
          timeZoneList={timeZoneList}
          editType={editType}
          zipCodeList={m_zipList}
          editFacilityName={editFacilityName}
          setEditFacilityName={setEditFacilityName}
          editAddress1={editAddress1}
          setEditAddress1={setEditAddress1}
          editAddress2={editAddress2}
          setEditAddress2={setEditAddress2}
          editPrimaryPhone={editPrimaryPhone}
          setEditPrimaryPhone={setEditPrimaryPhone}
          editReferalFax={editReferalFax}
          setEditReferalFax={setEditReferalFax}
          editDepartment={editDepartment}
          setEditDepartment={setEditDepartment}
          editRoom={editRoom}
          setEditRoom={setEditRoom}
          editLatitude={editLatitude}
          setEditLatitude={setEditLatitude}
          editLongitude={editLongitude}
          setEditLongitude={setEditLongitude}
          editTimeZone={editTimeZone}
          setEditTimeZone={setEditTimeZone}
          editNPI={editNPI}
          setEditNPI={setEditNPI}
          editZipID={editZipID}
          editZipCode={editZipCode}
          setEditZipCode={setEditZipCode}
          editFloor={editFloor}
          setEditFloor={setEditFloor}
          editWing={editWing}
          setEditWing={setEditWing}
          editContactPerson={editContactPerson}
          setEditContactPerson={setEditContactPerson}
          editFacilityID={editFacilityID}
          setEditFacilityID={setEditFacilityID}
          facilityReload={facilityReload}
          setFacilityReload={setFacilityReload}
          editDescription={editDescription}
          setEditDescription={setEditDescription}
          setEditType={setEditType}
          accessType={accessType}
          setAccessType={setAccessType}
          isPrimary={isPrimary}
          setIsPrimary={setIsPrimary}
          editGroupID={id}
          setEditGroupID={setEditGroupID}
          editCenter={editCenter}
          setEditCenter={setEditCenter}
          accessTypeOptions={accessTypeOptions}

        />
        <FacilityStatusChangeModal
          facilityID={facilityID}
          facilityReload={facilityReload}
          setFacilityReload={setFacilityReload}
          modalState={modalState}
          setModalState={setModalState}
          status={status}

        />
        <NotificationPreferenceModal

          notificationPreferenceModal={notificationPreferenceModal}
          setNotificationPreferenceModal={setNotificationPreferenceModal}

          newEmail={newEmail}
          setNewEmail={setNewEmail}

          id={id}
          facilityID={facilityID}
          currentFacilityStatus={currentFacilityStatus}

          facilityReload={facilityReload}
          setFacilityReload={setFacilityReload}

          centralNotification={centralNotification}
          setCentralNotification={setCentralNotification}

          // setSpinner={setSpinner}
          setReloadFacilityDetails={setReloadFacilityDetails}
          reloadFacilityDetails={reloadFacilityDetails}
          fetchFacilityByGroup={fetchFacilityByGroup}
        />
      </Container>
    </div>
  );
}

export default GroupFacilities;