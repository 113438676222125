import React, { useState, useEffect } from "react";
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';

const AuthNewService = (props) => {
    const{
        newServiceModal,
        setNewServiceModal,
        reload,
        setReload
    }= props
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;
    const API_TOKEN = localStorage.getItem("accessToken")
    //const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [newServiceName, setNewServiceName] = useState("");

    const CreateNewService = () => {
       
        const params = {
            name:newServiceName
        }
        axios.post(`${API_ENDPOINT}api/services/`, params, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
        .then(response => {
            
            toastr.success("Success", "New Service added");
            toggle();
            setReload(true);



        })
        .catch(error => {

            
            toastr.error("Failed", "Something went wrong !")

        });
    }
    useEffect(() => {
        if (reload === true) {
          window.location.reload();
        }
      }, [reload]);
    // const disableAddButton = () => {

    //     if (newServiceName) {
            
    //         setAddButtonStatus(false)
    //     }
    //     else {
            
    //         setAddButtonStatus(true)
    //     }
    // }
    const toggle = () => {
        setNewServiceModal(!newServiceModal);
        // setNewDrgCode("")
        // setNewMdc("")
        // setNewType("")
        // setNewDescription("")
        // setNewWeight("")
        // setNewGlos("")
        // setNewAlos("")

    }
    const confirmation = () => {
        
        if (!newServiceName) {
          toastr.error(
            "Name Is Required",
            "Please enter the Name!"
          );
        }
       
    
        if (newServiceName) {
          // CreateProviderApiCall();
          CreateNewService();
        }
      };
    return(
    <div>
        <Modal isOpen={newServiceModal} toggle={toggle} size="md" scrollable={true} >
            <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add New Service </ModalHeader>
            <ModalBody className="overflow-modal disable-scrollbars" >
                <FormGroup>
                    <Row  >
                        <Col sm={6} >
                            <div className="" >
                                <Label>

                                    Service Name  <span className="required">*</span> :

                                </Label>
                            </div>
                            <Input
                                id="serviceid"
                                value={newServiceName}
                                placeholder="Enter Service Name"
                                onChange={(e)=>{
                                    setNewServiceName(e.target.value);
                                 }}
                            />
                        </Col>
                        {/* <Col sm={6} >
                            <div className="" >
                                <Label>

                                    Description :

                                </Label>
                            </div>

                            <div className="" >
                                <Input
                                    id="Description"
                                    value={newDescription}
                                    placeholder="Enter Description"
                                    onChange={(e) => { if (e.target.value.length <= 150) setNewDescription(e.target.value) }}
                                />
                            </div>
                        </Col> */}
                    </Row>
                    
                </FormGroup>
            </ModalBody>
            <ModalFooter className="advanced-search-button  custom-modal-footer">
                <Button
                    className="yes-button button-width"
                    onClick={() => {
                        confirmation();
                      }}
                      //disabled={disableAddButton()} 

                    // onClick={
                        
                    //     CreateNewService()}
                //   
                //     title={addButtonStatus ? "Fill the required fields" : ""}
                >Add</Button>{' '}
                <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    </div>
    )
}
export default AuthNewService;
