import React, { useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {Container,Card,CardHeader,CardBody,Row,Col,Input,Button,Breadcrumb,BreadcrumbItem,Label,Spinner,Modal,ModalBody,ModalFooter,
         Form,FormGroup,ModalHeader} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import AsyncSelect from "react-select/async";
import AxiosInter from './../../AxiosInter';
import Cookie from 'js-cookie';
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select'
import { Select as SelectVirtualized } from "react-select-virtualized";

const blankState = (
   <Card className="blank-state">
      <CardBody>
         <h3>No records available</h3>
      </CardBody>
   </Card>
);

function SetupSheet() {
   // local variables
   const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
   const API_TOKEN = Cookie.get("access_token_admin");

   // states
   const [data, setData] = useState([])
   const [numberOfRecords, setNumberOfRecords] = useState(0)
   const [searchKeyword, setSearchKeyword] = useState('')
   const [blankStateStatus, setBlankStateStatus] = useState(false)
   const [loading, setLoading] = useState(false)
   const [page, setPage] = useState(1);
   const [sizePerPage, setSizePerPage] = useState(50);
   const [totalSize, setTotalSize] = useState(0);
   const [sortColumn, setSortColumn] = useState("");
   const [sortOrder, setSortOrder] = useState("");
   const [reload, setReload] = useState(false)
   const [populatedTypeOption, setPopulatedTypeOption] = useState([])
   const regularExpression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

   // filter values stored here 
   const [groupNameOptions, setGroupNameOptions] = useState([]);
   const [selectedGroupName, setSelectedGroupName] = useState();

   const [npi, setNPI] = useState("");
   const statusOptions = [
      { value: '0', label: 'Not Processed' },
      { value: '1', label: 'Processed' },
      { value: '99', label: 'Error' },
   ]
   const [statusSelected, setStatusSelected] = useState();

   // const [typeOptions, setTypeOptions] = useState([])
   const [typeSelected, setTypeSelected] = useState();

   // onclick edit button single row data stored here
   // this is used for edit modal to popup data
   // after edit this data will modified 
   const [editRowData, setEditRowData] = useState({});

   // modal
   const [viewModalState, setViewModalState] = useState(false)
   const [viewMoreData, setViewMoreData] = useState('')
   const [editModalState, setEditModalState] = useState(false)

   const viewModalStateToggle = () => {
      setViewModalState(false)
   }

   const editModalStateToggle = () => {
      setEditModalState(false)
   }

   const moreDetails = (moreData) => {
      let data = moreData
      setViewMoreData(data)
      setViewModalState(true)
   }

   const fetchSetupSheetData = () => {
      setLoading(true);
      const params = {
         grpname: selectedGroupName ? selectedGroupName.label : "",
         npi: npi ? npi : "",
         ordertype: typeSelected ? typeSelected.label : "",
         processed: statusSelected ? statusSelected.value : "",
         is_pagination: "1",
         record_limit: "50",
         off_set: "0",
         sortOrder: sortOrder ? sortOrder : "",
         sortColumn: sortColumn ? sortColumn : "",
         searchKeyword: searchKeyword ? searchKeyword : "",
      };
      AxiosInter.post(`${API_ENDPOINT}/admin-api/fetch-vsetup-details/`, params, {
         headers: {
            Authorization: API_TOKEN,
         },
      })
         .then((res) => {
            if (res.data.data.length > 0) {
               setNumberOfRecords(res.data.record_count);
               setTotalSize(res.data.record_count);
               setData(res.data.data)
               setLoading(false);
               setReload(false)
            }
            else {
               setData([])
               setNumberOfRecords(0);
               setLoading(false);
               setReload(false)
            }
         })
         .catch(function (error) {
            setData([])
            setLoading(false);
            setReload(false)
         });
   };

   const fetchGroupNames = () => {
      setLoading(true);
      AxiosInter.get(`${API_ENDPOINT}/admin-api/setup-groupname-list/`)
         .then((res) => {
            if (res?.data?.data?.length > 0) {
               const groupOptions=res?.data?.data?.map(item=>({value:item.groupName,label:item.groupName}))
               setGroupNameOptions(groupOptions)
               setLoading(false);
               setReload(false)
            }
            else {
               setGroupNameOptions()
               setLoading(false);
               setReload(false)
            }
         })
         .catch(function (error) {
            setGroupNameOptions([])
            setLoading(false);
            setReload(false)
         });
   }

   const columns = [
      {
         dataField: 'groupName',
         text: 'Group Name',
         sort: true,
         formatter: (cell) => commonFormatter(cell)
      },
      {
         dataField: 'type',
         text: 'Type',
         sort: true,
         formatter: (cell, row) => typeFormatter(cell, row)
      },
      {
         dataField: 'npi',
         text: 'NPI',
         sort: true,
         formatter: (cell) => commonFormatter(cell)
      },
      {
         dataField: 'email',
         text: 'Email',
         sort: true,
         formatter: (cell) => commonFormatter(cell)
      },
      {
         dataField: 'createdOn',
         text: 'Created on',
         sort: true,
         formatter: (cell, row) => createdOnFormatter(cell, row)
      },
      {
         dataField: 'response_details',
         text: 'Response',
         sort: true,
         formatter: (cell, row) => responseDetailsFormatter(cell, row)
      },
      {
         dataField: 'is_Processed',
         text: 'Status',
         sort: true,
         formatter: (cell, row) => processedFormatter(cell, row)
      },
      {
         dataField: 'is_Processed',
         text: 'Action',
         formatter: (cell, row) => actionFormatter(cell, row)
      },
   ];

   const commonFormatter = (cell) => {
      if (cell === null || cell === '') {
         return "N/A"
      }
      return cell
   }

   const processedFormatter = (cell, row) => {
      if (cell === null || cell === '') {
         return 'N/A'
      }
      if (cell === 0) {
         return <span className="badge badge-primary">Not processed</span>
      }
      if (cell === 1) {
         return <span className="badge badge-success">Processed</span>
      }
      if (cell === 99 || cell === 2) {
         return <span className="badge badge-danger">Error</span>
      }
   }

   // on edit button click, storing row data to editRowData and that values are used to popup in edit modal
   // on edit button click, populating type and status and that values showing in edit modal
   const actionFormatter = (cell, row) => {
      if(cell === '' || cell === null){
         return ''
      }
      if (cell === 1) {
         return null
      }
      else {
         return (
            <div className="pl-3">
               <FontAwesomeIcon
                  icon={faEdit}
                  color={'#3f86fa'}
                  style={{ 'cursor': 'pointer' }}
                  onClick={() => {
                     setEditModalState(true)
                     setEditRowData(row)

                     // populating type
                     // eslint-disable-next-line no-unused-vars
                     let typeId = row.orderTypeID + '' //converting into string
                     let type = row.type
                     const populatedType = [{ value: row.orderTypeID, label: type }]
                     setPopulatedTypeOption(populatedType)

                     // populating status
                     let statusID = row.is_Processed
                     // eslint-disable-next-line array-callback-return
                     let populatedStatus = statusOptions.filter(eachStatus => {
                        // eslint-disable-next-line eqeqeq
                        if (eachStatus.value == statusID) {
                           return eachStatus
                        }
                     })
                     setStatusSelected(populatedStatus)
                  }}

                  title="Edit"
               />
            </div>
         )
      }
   }

   const typeFormatter = (cell, row) => {
      if (cell === '' || cell === null) {
         return <>N/A</>
      }
      else return <>{cell}</>
   }

   // extract date from string
   const createdOnFormatter = (cell, row) => {
      if (cell === '' || cell === null) {
         return <>N/A</>
      }
      const timestamp = cell
      const datePart = timestamp?.split("T")[0];
      return datePart
   }

   const responseDetailsFormatter = (cell, row) => {
      if (cell === null || cell === '') {
         return 'N/A'
      }

      return (
         <>
            {(cell.length > 50)?
                  <p>
                     {/* showing 50 character and view more */}
                     {cell.slice(0, 50)}
                     <br />
                     <span
                        style={{ color: 'blue', cursor: 'pointer' }}
                        onClick={() => moreDetails(cell)}
                     >
                        view more
                     </span>
                  </p>
                  :
                  cell
            }
         </>
      )
   }

   const clearFilterValues = () => {
      setSelectedGroupName('')
      setNPI('')
      setStatusSelected('')
      setTypeSelected('')
      setReload(true)
   }

   // checking selectedGroupName, npi, statusSelected, typeSelected have any value
   // if there is any value exist return false
   // so Go button active
   // else case Go button disabled 
   const checkFilterValuesExist = () => {
      if (!selectedGroupName && !npi && !statusSelected && !typeSelected) {
         return true
      }
      else {
         return false
      }
   }

   useEffect(() => {
      checkFilterValuesExist()
   }, [selectedGroupName, npi, statusSelected, typeSelected]) // eslint-disable-line react-hooks/exhaustive-deps

   const handleTableChange = (type, newState) => {
      setPage(newState.page)
      setSizePerPage(newState.sizePerPage)
      if (newState.sortField === null && newState.sortOrder === undefined) {
         setSortColumn("")
         setSortOrder("")
      }
      else if (newState.sortOrder) {
         setSortOrder(newState.sortOrder);
         setSortColumn(newState.sortField);
         setReload(true);
      }
   }

   const handleSearch = (e) => {
      setSearchKeyword(e.target.value)
      setReload(true)
   }

   const handleGroupChange=(value)=>{
      setSelectedGroupName(value)
   }

   const loadOptionsForTypes = (inputValue) => {
      if (inputValue.length >= 3) {
         const searchParams = new URLSearchParams({ name: inputValue });
         return AxiosInter.get(`${API_ENDPOINT}/admin-api/referral-order-type/?${searchParams.toString()}`,
            {
               headers: {
                  Authorization: API_TOKEN,
               }
            })
            .then((response) => response.data.data)
            .catch((error) => {
               throw error;
            });
      }
   }

   const validateFields = (groupName, type, npi, email, status) => {
      if (groupName?.length === 0) {
         toastr.error('Error', 'Enter group name')
         return false
      }
      if (type?.length === 0) {
         toastr.error('Error', 'Select type')
         return false
      }// eslint-disable-next-line eqeqeq
      if (npi?.length != 10) {
         toastr.error('Error', 'Enter a valid 10 digit NPI')
         return false
      }
      if (!regularExpression.test(email)) {
         toastr.error('Error', 'Enter a valid email')
         return false
      }
      if (status?.length === 0) {
         toastr.error('Error', 'Select status')
         return false
      }
      else {
         return true
      }
   }

   const handleUpdate = () => {
      // console.log(editRowData.id);
      // console.log(editRowData.groupName);
      // console.log(populatedTypeOption[0]);
      // console.log(editRowData.npi);
      // console.log(editRowData.email);
      // console.log(statusSelected);
      let populatedType = populatedTypeOption ? populatedTypeOption[0] : ''

      // validating all fields are correct and filled
      let validated = validateFields(editRowData.groupName, populatedType, editRowData.npi, editRowData.email, statusSelected)

      if (validated) {
         updateSetUpsheet(editRowData.id, editRowData.groupName, populatedType, editRowData.npi, editRowData.email, statusSelected)
      }
   }

   const updateSetUpsheet = (id, groupName, type, npi, email, status) => {
      let statusValue = status.value
      const params = {
         npi: npi,
         email: email,
         groupName: groupName,
         is_Processed: statusValue,
         orderTypeID: type.value + '', //converting to string
      };
      AxiosInter.patch(`${API_ENDPOINT}/admin-api/sanitas-vsetup-edit/${id}/`, params, {
         headers: {
            Authorization: API_TOKEN,
         },
      })
         .then((resp) => {
            toastr.success('Success', 'Record updated')
            editModalStateToggle()
            setStatusSelected()
            clearFilterValues()
            fetchGroupNames()
            setReload(true)
         })
         .catch((error) => toastr.error('Error', 'Record not updated'))
   }

   // all useEffects are here
   useEffect(() => {
      fetchSetupSheetData()
      fetchGroupNames()
   }, []) // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      // eslint-disable-next-line eqeqeq
      if (searchKeyword.length == 0) {
         setReload(true)
      }
   }, [searchKeyword])

   useEffect(() => {
      if (reload) {
         fetchSetupSheetData()
      }
   }, [reload]) // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (data.length === 0) {
         setBlankStateStatus(true)
      } else {
         setBlankStateStatus(false)
      }
   }, [data]);

   return (
      <Container fluid>
         {loading && (
            <div className="spinner-container">
               <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
               </Spinner>
            </div>
         )}
         <Header>
            <HeaderTitle>
               Setup Sheet
            </HeaderTitle>
            <Breadcrumb>
               <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
               </BreadcrumbItem>
               <BreadcrumbItem active>Setup Sheet</BreadcrumbItem>
            </Breadcrumb>
         </Header>
         <Card>
               <CardHeader>
                  <Row>
                     <Col sm="4" md="2" lg="3">
                        <Label>Group Name</Label>
                        <SelectVirtualized
                           name="groupName"
                           options={groupNameOptions}
                           placeholder="Group name"
                           isClearable
                           isSearchable
                           value={selectedGroupName}
                           onChange={handleGroupChange}
                        />
                     </Col>
                     <Col sm="2" md="2" lg="2">
                        <Label>NPI</Label>
                        <Input
                           style={{ padding: '18px 10px' }}
                           className="form-control"
                           type="number"
                           name="npi"
                           placeholder="Enter NPI"
                           value={npi}
                           onChange={(e) => {
                              if (e.target.value.length >= 11) {
                                 toastr.warning("Invalid NPI", "number length exceeded")
                              } else {
                                 setNPI(e.target.value)
                              }
                           }}
                        />
                     </Col>
                     <Col sm="4" md="2" lg="2.5">
                        <Label>Status</Label>
                        <Select
                           options={statusOptions}
                           value={statusSelected}
                           onChange={(e) => setStatusSelected(e)}
                        />
                     </Col>
                     <Col sm="4" md="2" lg="3">
                        <Label>Type</Label>
                        <AsyncSelect
                           cacheOptions
                           isClearable
                           isSearchable
                           value={typeSelected}
                           placeholder={'Search atleast 3 letters'}
                           onChange={(e) => {
                              setTypeSelected(e)
                           }}
                           loadOptions={loadOptionsForTypes}
                        />
                     </Col>
                     <Col>
                        <Button
                           className="patient-go-buttons"
                           onClick={() => {
                              setReload(true)
                           }}
                           disabled={checkFilterValuesExist()}
                           title={checkFilterValuesExist() ? 'Choose at least one item to search' : ''}
                        >
                           Go
                        </Button>
                        <Button
                           className="patient-reset-buttons"
                           onClick={() => clearFilterValues()}
                           disabled={checkFilterValuesExist()}
                           title={checkFilterValuesExist() ? "Nothing To Clear" : ""}
                        >
                           Reset
                        </Button>
                     </Col>
                  </Row>
                  <div className="separator" />
               </CardHeader>
            <div className="facility_search_component">
               <CardHeader>
                  <Row>
                     <Col sm="2">
                        <div className="number-of-records">
                           <p>Number of records: <strong>{numberOfRecords}</strong></p>
                        </div>
                     </Col>
                     <Col>
                        <div className='c-search-field form-inline justify-content-end'>
                           <div className='form-group mr-2'>
                              <Input
                                 autoFocus="autofocus"
                                 type="text"
                                 placeholder="Search"
                                 value={searchKeyword}
                                 onChange={(e) => handleSearch(e)}
                              >
                              </Input>
                           </div>
                           <Button
                              className="clear-search-button"
                              onClick={() => {
                                 setSearchKeyword('')
                                 setReload(true)
                              }
                              }
                              disabled={searchKeyword ? false : true}
                              title={searchKeyword ? "" : "Nothing To Clear"}
                           >Clear</Button>
                        </div>
                     </Col>
                  </Row>
                  <div className="separator" />
                  <div className="float-right pull-right">
                  </div>
               </CardHeader>
               <CardBody className="patient-list dropdown-menu-up">
                  {blankStateStatus ? blankState : ''}
                  {!blankStateStatus ?
                     <BootstrapTable
                        keyField="id"
                        data={data}
                        columns={columns}
                        remote
                        bootstrap4
                        hover
                        bordered={false}
                        pagination={paginationFactory({
                           page: page,
                           sizePerPage: sizePerPage,
                           sizePerPageList: [50, 100, 150, 200],
                           totalSize
                        })}
                        onTableChange={(type, newState) => {
                           handleTableChange(type, newState);
                        }}
                     /> : ''
                  }
               </CardBody>
            </div>
         </Card>
         {/* this modal is for view more --> Response */}
         <Modal isOpen={viewModalState} toggle={viewModalStateToggle}>
            <ModalBody>
               <pre style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}>
                  {viewMoreData}
               </pre>
            </ModalBody>
            <ModalFooter>
               <Button color="secondary" onClick={viewModalStateToggle}>Close</Button>
            </ModalFooter>
         </Modal>

         {/* this modal is for edit */}
         <Modal isOpen={editModalState} toggle={editModalStateToggle}>
            <ModalHeader tag="h4" cssModule={{ "modal-title": "w-100 text-center" }}>Edit Setup Sheet Details</ModalHeader>
            <ModalBody>
               <Form>
                  <FormGroup>
                     <Row>
                        <Col sm={6} className="pt-2">
                           <Label>Group Name</Label>
                           <Input
                              name="groupName"
                              value={editRowData.groupName}
                              type="text"
                              placeholder="Group name"
                              // on change updating editRowData.groupName
                              onChange={(e) => {
                                 const { name, value } = e.target
                                 setEditRowData(prevS => (
                                    {
                                       ...prevS,
                                       [name]: value
                                    }
                                 ))
                              }}
                           />
                        </Col>
                        <Col sm={6} className="pt-2">
                           <Label>Type</Label>
                           <AsyncSelect
                              cacheOptions
                              isClearable
                              isSearchable
                              value={populatedTypeOption}
                              placeholder={'Search atleast 3 letters'}
                              onChange={(e) => {
                                 // on change assign new type to populatedTypeOption
                                 setPopulatedTypeOption([e])
                              }}
                              loadOptions={loadOptionsForTypes}
                           />
                        </Col>
                        <Col sm={6} className="pt-4">
                           <Label>NPI</Label>
                           <Input
                              name="npi"
                              value={editRowData.npi}
                              placeholder="NPI"
                              type="number"
                              onChange={(e) => {
                                 if (e.target.value.length >= 11) {
                                    toastr.warning("Invalid NPI", "number length exceeded")
                                 } else {
                                    const { name, value } = e.target
                                    setEditRowData(prevS => (
                                       {
                                          ...prevS,
                                          [name]: value
                                       }
                                    ))
                                 }
                              }}
                           />
                        </Col>
                        <Col sm={6} className="pt-4">
                           <Label>Email</Label>
                           <Input
                              name="email"
                              value={editRowData.email}
                              placeholder="Email"
                              type="email"
                              onChange={(e) => {
                                 const { name, value } = e.target
                                 setEditRowData(prevS => (
                                    {
                                       ...prevS,
                                       [name]: value
                                    }
                                 ))
                              }}
                           />
                        </Col>
                        <Col sm={6} className="pt-4">
                           <Label>Status</Label>
                           <Select
                              options={statusOptions}
                              value={statusSelected}
                              onChange={(e) => setStatusSelected(e)}
                              styles={{
                                 menuList: (provided) => ({
                                    ...provided,
                                    maxHeight: '70px',
                                 }),
                              }}
                           />
                        </Col>
                     </Row>
                  </FormGroup>
               </Form>
            </ModalBody>
            <ModalFooter>
               <Button color="success" onClick={handleUpdate}>Update</Button>
               <Button color="primary" onClick={editModalStateToggle}>Close</Button>
            </ModalFooter>
         </Modal>
      </Container >
   )
}

export default SetupSheet