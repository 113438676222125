import axios from 'axios';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import Cookie from 'js-cookie';
import { logout } from './redux/actions/auth.js';
import { toastr } from 'react-redux-toastr';
import AES from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const secretKey = 'equipo';
const instance = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Accept: 'application/json',
  },
});
let isRefreshing = false;
let refreshQueue = [];

const getToken = () => {
  const encryptedAccessToken = Cookie.get('access_token_admin');
  if (!encryptedAccessToken) {
    return null;
  }
  const decryptedAccessToken = decryptToken(encryptedAccessToken.replace('Bearer ', ''));
  return decryptedAccessToken;
};

const isTokenExpired = (token) => {
  const decoded = jwtDecode(token);
  return dayjs.unix(decoded.exp).diff(dayjs()) < 1;
};

const encryptToken = (token) => {
  const encryptedToken = AES.encrypt(token, secretKey).toString();
  return encryptedToken;
};

const decryptToken = (encryptedToken) => {
  const decryptedToken = AES.decrypt(encryptedToken, secretKey).toString(encUtf8);
  return decryptedToken;
};

const refreshToken = async () => {
  if (isRefreshing) {
    // If token refresh is already in progress, add the API request to the refresh queue
    return new Promise((resolve, reject) => {
      refreshQueue.push({ resolve, reject });
    });
  }
  isRefreshing = true;
  try {
    const encryptedRefreshToken = Cookie.get('refresh_token_admin');
    const refreshToken = decryptToken(encryptedRefreshToken);
    const response = await axios.post(`${API_ENDPOINT}/admin-api/refresh-token/`, {
      refresh_token_admin: refreshToken,
    });
    const newAccessToken = `Bearer ${response.data.access_token_admin}`;
    const encryptedAccessToken = encryptToken(newAccessToken);
    const newRefreshToken = response.data.refresh_token_admin; // Assuming the server returns a refresh token
    const encryptedNewRefreshToken = encryptToken(newRefreshToken);
    Cookie.set('refresh_token_admin', encryptedNewRefreshToken);
    Cookie.set('access_token_admin', encryptedAccessToken);
    // Resolve all the queued API requests with the new access token
    refreshQueue.forEach((req) => {
      req.resolve(newAccessToken);
    });
    refreshQueue = [];
    isRefreshing = false;
    return newAccessToken;
  } catch (error) {
    // Reject all the queued API requests with the error
    refreshQueue.forEach((req) => {
      req.reject(error);
    });
    refreshQueue = [];
    isRefreshing = false;
    toastr.error('Unable to obtain new tokens, logging you out');
    logout();
    Cookie.remove('access_token_admin');
    Cookie.remove('refresh_token_admin');
    throw error;
  }
};

const checkBearerKeyword = (token) => {
  if (typeof token === 'string' && token.startsWith("Bearer ")) {
    return token;
  } else if (typeof token === 'string') {
    return "Bearer " + token;
  } else {
    throw new Error("Invalid token: must be a string");
  }
};


const handleRequest = async (req) => {
 
  req.headers.Authorization = checkBearerKeyword(getToken())
  const token = getToken();
  if (!token) {
    toastr.error('Token expired, logging you out');
    logout();
    Cookie.remove('access_token_admin');
    Cookie.remove('refresh_token_admin');
    throw new Error('Refresh token missing');
  }
  if (!isTokenExpired(token)) {
    return req;
  }
  try {
    const newAccessToken = await refreshToken();
    req.headers.Authorization = newAccessToken;
    return req;
  } catch (error) {
    throw error;
  }
};

instance.interceptors.request.use(handleRequest);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    
    if (error && error.response && error.response.status === 401) {
      const originalRequest = error.config;
      try {
        const newAccessToken = await refreshToken();
        originalRequest.headers.Authorization = newAccessToken;
        return instance(originalRequest);
      } catch (error) {
        toastr.error('Unable to obtain new tokens, logging you out');
        logout();
        Cookie.remove('access_token_admin');
        Cookie.remove('refresh_token_admin');
        throw error;
      }
    }
    if (error && error.response && error.response.status === 200 && error.config.url.includes('/refresh-token')) {
      // Here you can handle the scenario when the /refresh-token endpoint doesn't return any error
      // Modify the code as per your requirement
      console.log('Refresh token error');
    }
    // toastr.error('Some internal error occurred');
    return Promise.reject(error);
  }
);

export default instance;
