import React, {useState,useEffect} from 'react';
import { Button,  Modal,Container, ModalBody,Spinner, ModalFooter, ModalHeader ,Row, Col,Label, FormGroup,} from 'reactstrap';
import Select from 'react-select';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';

const FacilityDepartmentAssignRoomModal = (props) => {

    const {
        assignRoomModal,
        setAssignRoomModal,
        departmentOptions,
        roomOptions,
        facilityID,
        setFacilityDepartmentModal,
        facilityDepartmentModal,
        getAssignRoomDetails
      }=props

    const [newDepartment, setNewDepartment] = useState("");
    const [newRoom, setNewRoom] = useState(""); // eslint-disable-line
    const [addButtonStatus, setAddButtonStatus] = useState(false);
    const [spinner,setSpinner] = useState(false); // eslint-disable-line
    const [newRoomsForAdd, setNewRoomsForAdd]= useState([]);
    const [roomsForAdd, setRoomsForAdd]= useState([]);
    // const [reload,setReload] = useState(0);


    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
   

    const toggle = () => {  
        
        setAssignRoomModal(!assignRoomModal);
                           

    }

    const handleRoomChange =(event)=> {
        setNewRoomsForAdd(event)
    }

    const confirmation = () => {
        if (!newDepartment) {
            return toastr.error("Error", "Choose a department")
        }
        if (roomsForAdd.length === 0) {
            return toastr.error("Error", "Choose a room")
        }
        if (newDepartment && roomsForAdd ) {
            addAssignRoomDetails();
        }

    };
   
    const tempGetRoomsForSaving = () => {

        var array = []
        newRoomsForAdd.map((item) => {       // eslint-disable-line
           array.push(item.label.toString())
        })
        setRoomsForAdd(array)
    }
    
    const disableAddButton = () => {

        if (newRoomsForAdd) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }
    
  

    const addAssignRoomDetails = () => {
        setSpinner(true);
        AxiosInter.post(`${API_ENDPOINT}/admin-api/add-facility-assign-room/`, {
        
        "facility_id": facilityID ? facilityID : "",
        "department": newDepartment ? newDepartment.label : "",
        "room": roomsForAdd ? roomsForAdd.toString() : ""

            }, {
            headers: {
                Authorization: API_TOKEN,
            },
            })
            .then(function (response) {
                setSpinner(false)
                toastr.success("Success", "Room assign successful")
                setAssignRoomModal(!assignRoomModal)
                setFacilityDepartmentModal(!facilityDepartmentModal)
                getAssignRoomDetails();

            })
            .catch(function (error) {
                setSpinner(false)
                toastr.error("Update Failed", error.response.data.message)
            });

   
}
useEffect(()=>{
    if(!assignRoomModal) {
        setNewDepartment("")
        setNewRoom("") 
        setNewRoomsForAdd([]);
        
    }
  
},[assignRoomModal])

useEffect(() => { 
    disableAddButton() 
}, [newRoomsForAdd]) // eslint-disable-line

useEffect(()=>{
    if (newRoomsForAdd) {
        tempGetRoomsForSaving();    
} 
    
  },[newRoomsForAdd]) // eslint-disable-line

    return (
         <div>
           <Modal isOpen={assignRoomModal} size="md" scrollable={true} >
            <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}} toggle={toggle}>Assign Room</ModalHeader>
            <ModalBody className="overflow-modal" >
               {spinner ? <Spinner/>:""}
                <FormGroup>
                <Container fluid>
                <Row >
                    <Col sm={12} >
                      
                            <Label>
                                    Department    <span className="required">*</span> :
                            </Label>
                      
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                       
                        <Select
                                    options={departmentOptions}
                                    onChange={(event) => setNewDepartment(event)}
                                    value={newDepartment}
                                    placeholder="Select Department"
                                    isSearchable
                                    isClearable
                                />
                      
                    </Col>
                </Row><br/>
                <Row >
                        <Col sm={12} >
                           
                                <Label>
                                    Room  <span className="required">*</span> :
                                </Label>
                            
                        </Col>
                        <Col sm={12}>
                           
                                <Select
                                    isMulti
                                    options={roomOptions}
                                    id="room"
                                    value={newRoomsForAdd}
                                    placeholder="Select room"
                                    onChange={(event) =>handleRoomChange(event)}
                                />
                            
                        </Col>
                    </Row>
                    </Container>
                    </FormGroup>
                  
                 </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button 
                                    onClick={()=>confirmation()}
                                    disabled={addButtonStatus}
                                    title={addButtonStatus ? "Mandatory fields required" : ""}
                                    >Save</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
  }

  export default FacilityDepartmentAssignRoomModal;