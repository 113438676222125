import React, { useEffect } from 'react' // eslint-disable-line
import { Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Col, ModalFooter, Button, Input } from 'reactstrap'
import Select from 'react-select'
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';

const EditHL7Modal = (props) => {

    const { editHL7Modal, setEditHL7Modal, clickedRowData, fetchhl7Data, statusOptions } = props
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");

    const updateHL7Data = () => {

        const params = {

        }
        AxiosInter.post(`${API_ENDPOINT}/admin-api/update/`, params, {
            headers: {
                Authorization: API_TOKEN
            }
        })
            .then(response => {
                setEditHL7Modal(!editHL7Modal)
                toastr.success('Success', 'HL7 Data Updated SuccessFully')
                fetchhl7Data()
            })
            .catch(error=>{
                toastr.error("Error", error)
            })
    }


    return (
        <div>
            <Modal isOpen={editHL7Modal} size='md'>
                <ModalHeader tag="h4" cssModule={{ "modal-title": "w-100 text-center" }}>HL7 Clinical Import Log</ModalHeader>
                <ModalBody className='overflow-modal'>
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col md='6'>
                                    <FormGroup>
                                        <Label>External Order Id</Label>
                                        <Input
                                            value={clickedRowData?.External_order_id}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md='6'>
                                    <FormGroup>
                                        <Label>Imported By</Label>
                                        <Input
                                            value={clickedRowData?.Imported_by}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md='6'>
                                    <FormGroup>
                                        <Label>Hl7 Message</Label>
                                        <Input></Input>
                                    </FormGroup>
                                </Col>
                                <Col md='6'>
                                    <FormGroup>
                                        <Label>Status</Label>
                                        <Select
                                            value={clickedRowData?.Status}
                                            options={statusOptions}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md='6'>
                                    <FormGroup>
                                        <Label>From State</Label>
                                        <Input
                                            value={clickedRowData?.From_state}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md='6'>
                                    <FormGroup>
                                        <Label>Test</Label>
                                        <Select
                                           
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter className='advanced-search-button  custom-modal-footer'>
                    <Button
                        onClick={() => {
                            updateHL7Data()
                            setEditHL7Modal(false)
                        }}
                    >
                        Update
                    </Button>{" "}
                    <Button
                        outline
                        className='no-button button-width'
                        onClick={() => {
                            setEditHL7Modal(false)
                        }}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default EditHL7Modal


