import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Container, Spinner, Row, Col, Input, Button, Card, CardHeader, CardBody } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import { toastr } from 'react-redux-toastr';

const Encryption = () => {

  // LOCAL VARIABLES 
  const [encString, setEncString] = useState("");
  const [decString, setDecString] = useState("");
  const [loading, setLoading] = useState(false);
  const [decMrn, setDecMrn] = useState("");
  const [encMrn, setEncMrn] = useState("");
  const EQUIPO_API_ENDPOINT = process.env.REACT_APP_EQUIPO_APP_API_URL_LINK;

  const APIToDecrypt = () => {
    setLoading(true);
    axios.get(
      `${EQUIPO_API_ENDPOINT}saas/rpm/patient/report/enc-service/dec/${encMrn}`)
      .then((res) => {
        if (res.data.status === 1) {
          toastr.success('Successful', 'Decrypted successfully');
          setDecString(res.data.data.details)
          setLoading(false);
        }
        else {
          toastr.error('Error', 'Decryption failed');
        setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error('Error', 'Decryption failed');
        setLoading(false);
      });
  };

  const APIToEncrypt = () => {

    setLoading(true);
    axios.get(
      `${EQUIPO_API_ENDPOINT}saas/rpm/patient/report/enc-service/enc/${decMrn}`)
      .then((res) => {
        if (res.data.status === 1) {
          toastr.success('Successful', 'Encrypted successfully');
          setEncString(res.data.data.details)
          setLoading(false);
        } else {
          toastr.error('Error', 'Encryption failed');
        setLoading(false);
        }
      })
      .catch((error) => {
        toastr.error('Error', 'Encryption failed');
        setLoading(false);
      });
  };


  let loader = null;

  const Loader = () => {
    if (loading) {
      return loader = <Spinner />;
    }
  }

  useEffect(() => { 
    Loader()
   }, [loading]) // eslint-disable-line
   useEffect(() => {   
    window.scrollTo(0, 0);   
     }, []);
     
  return (
    <Hux>
      {loader}
      <Container fluid>
        <Header>
          <HeaderTitle>Encryption And Decryption </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Encryption and Decryption</BreadcrumbItem>
          </Breadcrumb>
        </Header>



        <Row>
          <Col xs="6">
            <Card className="CardForDecryption result-card">
              <CardHeader>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <h3>
                    Text to Decrypt
                  </h3>
                </div>
              </CardHeader>
              <CardBody>

                <Row>
                  <Col sm="12"md="12">

                    <Input
                      type="text"
                      name="dec"
                      className='input-dec'
                      placeholder="Text to Decrypt"
                      value={encMrn}
                      validate={{
                        maxLength: { value: 120 }
                      }}
                      onChange={(e) => { setEncMrn(e.target.value) }}
                    />
                  </Col>
                  <Col sm="12" className='btn-dec'>

                    <Button color="success" onClick={() => { APIToDecrypt() }}>
                      Decrypt
                    </Button>
                    <Button
                      className="reset-btn-enc"
                      onClick={() => { setEncMrn(""); setDecString("") }}
                      disabled={ !encMrn ? true : false}
                      title={!encMrn ? "Nothing To Clear" : ""}
                    >Reset</Button>
                  </Col>
                </Row>
                <div className="separator" />

                <div style={{ display:'flex', justifyContent:'center' }}>

                <Row>
                  <Col>
                    <Card className="result">
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {decString ? `Decrypted Text - ${decString}` : "Please enter the string to Decrypt"}
                      </div>
                    </Card>
                  </Col>
                </Row>
                </div>
              </CardBody>

            </Card>
          </Col>

          <Col xs="6">
            <Card className="CardForDecryption result-card" >
              <CardHeader>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <h3>
                    Text to Encrypt
                  </h3>
                </div>
              </CardHeader>
              <CardBody >
                <Row>
                  <Col sm="12"md="12">

                    <Input
                      type="text"
                      name="dec"
                      className='input-dec'
                      placeholder="Text to Encrypt"
                      value={decMrn}
                      validate={{
                        maxLength: { value: 120 }
                      }}
                      onChange={(e) => { setDecMrn(e.target.value) }}
                    />
                  </Col>
                  <Col sm="12" className='btn-dec'>

                    <Button color="success" onClick={() => { APIToEncrypt() }}>
                      Encrypt
                    </Button>
                    <Button
                      className="reset-btn-enc"
                      onClick={() => { setDecMrn(""); setEncString("") }}
                      disabled={ !decMrn ? true : false}
                      title={!decMrn ? "Nothing To Clear" : ""}
                    >Reset</Button>
                  </Col>

                </Row>
                <div className="separator" />

                    <div style={{ display:'flex', justifyContent:'center' }}>

                <Row >
                  <Col>
                    <Card className="result">
                      <div style={{ display: 'flex', justifyContent: 'center' }}>

                        {encString ? `Encrypted Text - ${encString}`  : "Please enter the string to Encrypt"}

                        
                      </div>
                    </Card>
                  </Col>
                </Row>
                    </div>

              </CardBody>
            </Card>
          </Col>
        </Row>




      </Container>
    </Hux>
  );
};


export default Encryption;
