import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import { toastr } from "react-redux-toastr";


const EditAuthServiceModal = (props) => {
  const [isRecord, setIsRecord] = useState(true);
  const [spinner, setSpinner] = useState(false); // eslint-disable-line
  //const [reload, setReload] = useState(false);
  const API_TOKEN = localStorage.getItem("accessToken")



  const {

    setStatus,
    status,
    editServiceName,
    editServiceModal,
    setEditServiceModal,
    setEditServiceName,
    editServiceId,
    reload,
    setReload,
    setInitialServiceNameLength,
    initialServiceNameLength,
    ListServices
  } = props;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;

  // const [initialServiceNameLength, setInitialServiceNameLength] = useState(editServiceName.length);
  // console.log(initialServiceNameLength,'initialServiceNameLength')


  useEffect(() => {
    //console.log(status, editServiceName + "experimental")
  }, [status, editServiceName]

  )
  const confirmation = () => {
    updateServiceCall()
    setEditServiceModal(!editServiceModal)
    setReload(reload + 1);
  }

  const disableUpdateButton = () => {
    // debugger
    // console.log(editServiceName.length,'snl')
    let serviceNameLength=editServiceName.length;
    setInitialServiceNameLength(editServiceName.length);
    // debugger
    if(serviceNameLength < initialServiceNameLength) {
      console.log('less')
      setIsRecord(false)
    } else if(serviceNameLength > initialServiceNameLength) {
      console.log('greater')
      setIsRecord(false)
    }
    else{
      console.log('equal')
      setIsRecord(true)
    }
    // setIsRecord(!isRecord)
  }
  
  
  // Call disableUpdateButton function when the editServiceName state changes
  useEffect(() => {
    setInitialServiceNameLength(editServiceName.length);
    disableUpdateButton();
  }, [editServiceName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //debugger
   // console.log(serviceList)
    ListServices();
}, []); // eslint-disable-line react-hooks/exhaustive-deps

useEffect(() => { if (reload) { ListServices() } }, [reload]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateServiceCall = () => {
    // debugger
    setSpinner(true)
    axios
      .put(
        `${API_ENDPOINT}api/services/${editServiceId}`,
        {
          // "status": status,
          "status": status ? status : false,
          "name": editServiceName ? editServiceName : "",
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        if (response.out !== null) {
          setSpinner(false);
          setReload(reload +1);
          toastr.success(
            "Success",
            `User details successfully updated ${response.out}`
          );
        }
        // } else {
        //   toastr.success("Success", "User details successfully updated");
        // }
        //   setEditServiceModal(!setEditServiceModal);
        //   setAppUserReload(appUserReload + 1);
      })
      .catch(function (error) {
        if (error.response.data) {
          toastr.error("Update Failed", error.response.data.message);
        } else {
          toastr.error("Update Failed", "Something went wrong");
        }
      });
  }
  //console.log(editServiceName, status)
  const toggle = () => {
    //debugger
    setEditServiceModal(!editServiceModal);
    //setStatus(!status);

  };
  // useEffect(() => {
  //   if (reload === true) {
  //     window.location.reload();
  //   }
  // }, [reload]);
  useEffect(() => {
    //debugger
    //console.log(editServiceModal)
  }, [editServiceModal])

  const handleChange = (event) => { // eslint-disable-line

    setStatus(event.status)
    setEditServiceName(event.editServiceName)
    //console.log(event.target.editServiceName);
  }

  const handleClick = () => { // eslint-disable-line
    // handle button click
  }

  return (
    <Modal
      isOpen={editServiceModal}
      toggle={toggle}
      size="lg"
      scrollable={true}>
      <ModalHeader
        tag="h4"
        cssModule={{ "modal-title": "w-100 text-center" }}
        toggle={toggle}
      >
        Edit Services{" "}
      </ModalHeader>
      <ModalBody className="overflow-modal disable-scrollbars">
        {/* {spinner ? <Spinner /> : ""} */}
        <FormGroup>
          <Row>

            <Col sm={6}>
              <Label>
                Service Name:<span className="required">*</span>
              </Label>

              <Input
                onChange={(e) => {
                  setEditServiceName(e.target.value);
                }}
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="name"
                placeholder="Add a Service Name"
                value={editServiceName}
                validate={{
                  maxLength: { value: 120 },
                }}
              //onInputChange={handleInputChange}
              //onChange={handleCategoryChange}
              //loadOptions={(inputValue) => categoryloadOptions(inputValue)}
              />
            </Col>
            {/* <Col sm={6}>
                <div className="mt-2 ml-2">
              <Label>
                Service Status:<span className="required">*</span>
              </Label>
              <div className={styles.detailProfValue}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  value={status}
                  onToggle={() => {
                    setStatus(!status)
                  }}
                />
              </div>
              </div>
            </Col> */}
          </Row>
          <br />
        </FormGroup>
      </ModalBody>
      <ModalFooter className="advanced-search-button custom-modal-footer">
        <Button className="yes-button button-width"
          onClick={() => {
            confirmation()
          }}
          disabled={isRecord} > Update</Button>
        <Button outline className="no-button button-width" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>

  )
}
export default EditAuthServiceModal;