import React, { useEffect, useState,useContext } from "react";
import { GlobalState } from '../../contextAPI/Context'
import AuthServiceStatusChangeModal from "../../newComponents/utilities/AuthServiceStatusChangeModal"
import EditAuthServiceModal from "../../newComponents/utilities/EditAuthServiceModal";
import {
    Col,
    Row,
    Spinner,
    } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RemotePaginationAuth from "../../newComponents/utilities/RemotePaginationAuth";
import axios from "axios";


const AuthServicesList = () => {

    const API_TOKEN = localStorage.getItem("accessToken")
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;
    
    const {spinner,setSpinner} = useContext(GlobalState);
    const [serviceList, setService] = useState([]);
    const [rowData, setRowData] = useState("");  // eslint-disable-line
    const [editServiceName, setEditServiceName] = useState([]);
    const [editGroupName, setEditGroupName] = useState("");
    const [editServiceModal, setEditServiceModal] = useState(false);
    const [editServiceId, setEditServiceId] = useState("");
    const [status, setStatus] = useState("true");
    const [serviceId, setServiceId] = useState('');
    const [modalState, setModalState] = useState(false);
    const [reload, setReload] = useState(0);
    const [initialServiceNameLength, setInitialServiceNameLength] = useState(editServiceName.length);

    // const clearValues = () => {
    //     setService("");
    //     setTotalSize(0);
    //     //setApiLoaded(true)
    //   };

    const ListServices = () => {
        setSpinner(true);
        setReload(reload +1)
        axios
            .get(`${API_ENDPOINT}api/services/`,
                {
                    headers: {
                        Authorization: API_TOKEN,
                    },
                })
            .then((response) => {
                //debugger
                if (response.data.length > 0) {

                    setService(response.data);
                    console.log(response.data)
                    // setTotalSize(response.data.record_count);
                    setReload(0)
                    setSpinner(false);
                } else {
                    setService("");
                    setSpinner(false);
                    // setIsDataEmpty(true);
                }
            })
            .catch(function (error) {
                setService("");
                setSpinner(false);
                console.log("working..")

            });
    }

    const basicValidation = (cell) => {
        return cell ? cell : "N/A";
    };
    useEffect(() => {
        //debugger
        if (reload){
        console.log(serviceList)
        ListServices();
        setReload(0)
        }
    }, [reload]);  // eslint-disable-line

    // useEffect(() => {  ListServices() }, [])

    const actionsFormatter = (cell, row) => {
        return (
            <>
                <Row>
                    <Col sm={2}>
                        <FontAwesomeIcon
                            icon={faEdit}
                            size={"1x"}
                            color={"#3f86fa"}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                //debugger;
                                setRowData(row);
                                setEditServiceModal(true);
                                // editFacilityData(row.address1, row.address2, row.contactPerson, row.department, row.description1, row.floor, row.id, row.latitude, row.longitude, row.name1, row.npi, row.primaryPhone, row.referralFax, row.room, row.type1, row.wing, row.workTime, row.zipID, row.facilityType, row.isPrimary)
                                setEditServiceId(row.id);
                                // setEditGroupName(row.name);
                                // setEditCategory(row.category);
                                //setEditGroupName(row.GroupName);
                                setEditServiceName(row.name);
                                setInitialServiceNameLength(row.name.length);
                                setStatus(row.status);
                                //      if (row.name) {
                                //         setEditServiceName({
                                //         value: (row ? row.name : "N/A").toString(),

                                //         label: (row ? row.name : "N/A").toString(),
                                //     });
                                // }
                                console.log(row.name.length,'rnl')
                                // debugger





                                // if (row.name) {
                                //   setEditGroupName({
                                //     value: (row ? row.groupID : "N/A").toString(),
                                //     label: (row ? row.name : "N/A").toString(),
                                //   });
                                // }

                                // debugger
                            }}
                            title="Edit"
                        />
                    </Col>

                    {/* <Col sm={2}>
                <FontAwesomeIcon
                  icon={faTrash}
                  color = {'#E12C2C'}
                  style = {{'cursor':'pointer'}}
                  onClick={() => {
                    setHabitID(row.id);
                    setDeleteModal(true);
                   }}
                
                  title = "Delete"
    
                />
            </Col> */}
                </Row>
            </>
        );
    };
    // debugger
    console.log(editServiceId)
    // debugger
    useEffect(() => {
        // debugger
        console.log(editGroupName)
    },
        [editGroupName]
    )

    const statusFormatter = (cell, row) => {
        // debugger
        let status = "";
        let badgeclass = " ";
        if (row.status === true) {
            status = "Active";
            badgeclass = "badge badge-success statustoggle status-button-width";
        } else if (row.status === false) {
            status = "Inactive";
            badgeclass = "badge badge-danger statustoggle status-button-width";
        }
        else {
            status = "N/A";
        }

        return (
            <>
                <span
                    className={badgeclass}
                    onClick={() => {
                        setStatus(row.status);
                        setServiceId(row.id);
                        setModalState(true);
                        setEditServiceName(row.name);

                    }}
                >
                    {status}
                </span>
            </>
        );
    };

    const columns = [
        {
            dataField: "name",
            text: "Service Name",
            style: {
                textAlign: "left",
                // paddingLeft:30
            },
            formatter: basicValidation,
        },
        {
            dataField: "status",
            text: "Status",
            formatter: statusFormatter,
            style: {
                textAlign: "left",
            },

        },
        {
            text: "Actions",
            sort: false,
            formatter: actionsFormatter,
            style: {
                textAlign: "left",
            },
        },
    ];

    return (
        <div>
            <div className="text-center">
                <div>
                    <RemotePaginationAuth
                        data={serviceList ? serviceList : ""}
                        columns={columns}
                    />
                    {/* <BootstrapTable
                     data={serviceList}
                     columns={columns}
                     bordered={false}
                     keyField='id'
                    bootstrap4
                    hover
                    /> */}
                </div>
            </div>
            {spinner ? <Spinner /> : ""}
            <div>
                <EditAuthServiceModal

                    editServiceName={editServiceName}
                    editGroupName={editGroupName}
                    editServiceModal={editServiceModal}
                    setEditServiceModal={setEditServiceModal}
                    setEditServiceName={setEditServiceName}
                    setEditGroupName={setEditGroupName}
                    status={status}
                    setStatus={setStatus}
                    serviceId={serviceId}
                    setServiceId={setServiceId}
                    editServiceId={editServiceId}
                    setEditServiceId={setEditServiceId}
                    reload={reload}
                    setReload={setReload}
                    initialServiceNameLength={initialServiceNameLength}
                    setInitialServiceNameLength={setInitialServiceNameLength}
                    ListServices = {ListServices}
                />
                <AuthServiceStatusChangeModal
                    setModalState={setModalState}
                    status={status}
                    setStatus={setStatus}
                    serviceId={serviceId}
                    modalState={modalState}
                    setServiceId={setServiceId}
                    editServiceName={editServiceName}
                    setEditServiceName={setEditServiceName}
                    reload={reload}
                    setReload={setReload}
                    ListServices = {ListServices}
                />
            </div>
        </div>
    )
}
export default AuthServicesList;