import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  Spinner,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import Cookie from 'js-cookie';


const AddNewICDModal = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState("");

  const [conditionsOptions, setConditionsOptions] = useState("");
  const [selectedCondition, setSelectedCondition] = useState("");
  const [drgCodeOptions, setDrgCodeOptions] = useState("");

  const [isChronic, setIsChronic] = useState(0);
  const [drgCode, setDrgCode] = useState("");

  const [icdCode, setIcdCode] = useState("");
  const [icdDescription, setIcdDescription] = useState("");

  const [spinner, setSpinner] = useState("");

  const [addButtonBlankState, setAddButtonBlankState] = useState(false);

  const {
    addModal,
    setAddModal,
    icdCategoryList,

    setIcdCategory,
    reloadTableData,
    setReloadTableData,
    icdConditions,
    drgDetails,
  } = props;

  const chronicOptions = [
    {
      value: 1,
      label: "Yes",
    },
    {
      value: 0,
      label: "No",
    },
  ];

  const toggle = () => {
    setAddModal(!addModal);
  };

  const setICDCategoryDropdown = (data) => {
    var array = data;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.description,
      };
    });
    setCategoryOptions(options);
  };

  const setICDConditionsDropDown = (data) => {
    var array = data;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.category,
      };
    });
    setConditionsOptions(options);
  };

  const setDrgCodeDropDown = (data) => {
    var array = data;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.code,
      };
    });
    setDrgCodeOptions(options);
  };

  const addNewIcdDetails = () => {
    setSpinner(true);
    const params = {
      icd_code: icdCode ? icdCode : "",
      icd_category_id: selectedCategory ? selectedCategory.value : null,
      icd_description: icdDescription ? icdDescription : "",
      icd_condition_id: selectedCondition ? selectedCondition.value : "",
      drg_code: drgCode ? drgCode.label : "",
      isChronic: isChronic ? isChronic.value : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/add-icd-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        setSpinner(false);
        setReloadTableData(reloadTableData + 1);
        toastr.success("Success", "ICD details saved successfully");
        toggle();
      })
      .catch((error) => {
        setSpinner(false);
        toastr.error("Error", error.response.data.message);
      });
  };

  const validate = () => {
    if (icdCode && icdDescription) {
      setAddButtonBlankState(false);
    } else {
      setAddButtonBlankState(true);
    }
  };

  useEffect(() => {
    if (addModal) {
      setICDCategoryDropdown(icdCategoryList);
      setICDConditionsDropDown(icdConditions);
      setDrgCodeDropDown(drgDetails);
    }
  }, [addModal]); // eslint-disable-line

  useEffect(() => {
    if (!addModal) {
      setIcdCode("");
      setIcdCategory("");
      setSelectedCategory("");
      setIcdDescription("");
      setDrgCode("");
      setIsChronic(0);
      setSelectedCondition("");
      setSpinner(false);
    }
  }, [addModal]); // eslint-disable-line

  useEffect(() => {
    validate();
  }, [icdCode, icdDescription]); // eslint-disable-line

  return (
    <div>
      <Modal isOpen={addModal} size="lg">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Add ICD Details{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal">
          {spinner ? <Spinner /> : ""}
          <FormGroup>
            <Row>
              <Col sm={6}>
                <Label for="icd_code">
                  ICD Code<span className="required">*</span>
                </Label>
                <Input
                  id="icd_code"
                  placeholder="Code"
                  value={icdCode}
                  onChange={(e) => {
                    setIcdCode(e.target.value);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Label for="icd_category">ICD Category</Label>
                <Select
                  name="icd_category"
                  options={categoryOptions}
                  placeholder="Choose ICD Category"
                  isSearchable
                  isClearable
                  value={selectedCategory}
                  onChange={(e) => {
                    setSelectedCategory(e);
                  }}
                />
              </Col>
            </Row>
            <br />

            <Row>
              <Col sm={6}>
                <Label for="group_name">
                  ICD Description<span className="required">*</span>
                </Label>
                <Input
                  id="description"
                  type="textarea"
                  rows={4}
                  cols={2}
                  placeholder="Description"
                  value={icdDescription}
                  onChange={(e) => {
                    setIcdDescription(e.target.value);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Label for="icd_condition">Condition</Label>
                <Select
                  name="icd_condition"
                  options={conditionsOptions}
                  value={selectedCondition}
                  placeholder="Choose ICD Condition"
                  isSearchable
                  isClearable
                  onChange={(e) => {
                    setSelectedCondition(e);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <Label for="drg_code">DRG Code</Label>
                <Select
                  name="drg_code"
                  options={drgCodeOptions}
                  value={drgCode}
                  placeholder="Choose DRG Code"
                  isSearchable
                  isClearable
                  onChange={(e) => {
                    setDrgCode(e);
                  }}
                />
              </Col>

              <Col sm={6}>
                <Label for="is_chronic">Chronic</Label>
                <Select
                  name="is_chronic"
                  options={chronicOptions}
                  placeholder="Chronic"
                  isSearchable
                  isClearable
                  value={isChronic}
                  onChange={(e) => {
                    setIsChronic(e);
                  }}
                />
              </Col>
            </Row>
            <br />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            onClick={() => {
              addNewIcdDetails();
            }}
            disabled={addButtonBlankState ? true : false}
            title={
              addButtonBlankState
                ? "Please fill in all mandatory fields"
                : "Click to save ICD details"
            }
          >
            Add
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddNewICDModal;
