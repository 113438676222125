import React from "react";
import {
    Button,
    Modal,
    ModalHeader,
    Form,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Input,
    Label,
    FormGroup,
} from "reactstrap";
import moment from "moment";
import { useState, useEffect, useContext } from "react";
import AxiosInter from './../../AxiosInter.js';
import Cookie from "js-cookie";
import { toastr } from "react-redux-toastr";
import _, { first } from "lodash"; // eslint-disable-line
import AsyncSelect from "react-select/async";
import { GlobalState } from "../../contextAPI/Context";
import Select from "react-select";


const PatientListModal = (props) => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");

    const {
        m_zipList,
        zipCodeOptions, // eslint-disable-line
    } = useContext(GlobalState);



    const { patientListModal, setPatientListModal, clickedRowData, patientList, setPatientList } = props
    const [fullPateintData, setFullPatientData] = useState();
    const [zip, setZip] = useState(null)
    const [countryCodeList, setCountryCodeList] = useState()

    const toggle = () => {
        setPatientListModal(!patientListModal);
        setFullPatientData("")
    };

    const genderOptions = [
        { value: '0', label: 'male' },
        { value: '1', label: 'female' },
        { value: '2', label: 'not specified' }
    ]
    const populateGender = (gender) => {
        if (gender!= null){
        const lowerCaseGender = gender.toLowerCase();
        const gendervalue = genderOptions.filter(function (obj) {
            return obj.label.toLowerCase() === lowerCaseGender;
        });
    
        return gendervalue;
    }
    };

    const populateCountryCode = (countryCode) => {
        const countryCodeValue = countryCodeList?.filter(function (obj) {
            return obj.label === countryCode
        })
        return countryCodeValue
    }

    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        setFullPatientData((prevState) => {
            return (
                {
                    ...prevState,
                    [name]: value
                }
            )
        })
    }

    const handleChangeGender = (e) => {
        setFullPatientData((prevState) => {
            return (
                {
                    ...prevState,
                    gender: e?.label
                }
            )
        })
    }

    const handleCountryCodeChange = (e) => {
        setFullPatientData((prevState) => {
            return (
                {
                    ...prevState,
                    countryCode: e?.label
                }
            )
        })
    }

    const listAllPatientDetails = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-patient-demographics/?patient_id=${clickedRowData?.id}&patientGroupID=${clickedRowData.patientGroupID}`,
            {
                headers: {
                    Authorization: API_TOKEN,
                },
            }
        )
            .then((response) => {
                if (response.data) {
                    setFullPatientData(response?.data?.data[0]);
                    setZip({ value: response.data.data[0].zipID, label: response.data.data[0].zipcode })
                }
            })
            .catch((error) => {
                throw error;
            })
    };

    const updatePatientList = () => {
        let id = clickedRowData.id;
        let updatedData = patientList.map((obj => {
            if (obj.id === id) {
                return {
                    ...obj,
                    inviteID: 1
                }
            }
            return obj;

        }))
        setPatientList(updatedData)
    }
    const validateMobile = (mobile) => {
        // US phone number regex pattern
        const usPattern = /^\+?1?\s*\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        // Indian phone number regex pattern
        const indianPattern = /^\+?91?[-\s]?\d{10}$/;
    
        return usPattern.test(mobile) || indianPattern.test(mobile);
    };
    const validateEmail = (email) => {
        // Email regex pattern
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


        return emailPattern.test(email);
    };
    const updateAllPatientDetails = () => {
        
        debugger
        const zipID = m_zipList?.filter(function (obj) {
            return (obj.zipcode === zip?.label)
        })
        
        if( fullPateintData?.mobliePhone?.length > 0){
        let mob=`${fullPateintData.mobile_country_code}${ fullPateintData?.mobliePhone}` 
        if(!validateMobile(mob)){
            toastr.warning("Please enter valid mobile number");
            return
        } 
        }
        
        if( fullPateintData?.email?.length > 0){
            if(!validateEmail(fullPateintData?.email)){
                toastr.warning("Please enter valid email");
                return
            }
        }

        if (fullPateintData?.first_name?.length > 0 &&
            fullPateintData?.last_name?.length > 0 &&
            fullPateintData?.gender?.length > 0 &&
            fullPateintData?.dob?.length > 0 &&
            fullPateintData?.email?.length > 0 &&
            fullPateintData?.mobliePhone?.length > 0
        ) {
            let params = {
                ...fullPateintData,
                patient_id: clickedRowData?.id,
                zipID: zipID.length > 0 ? zipID[0]?.id : "",
                zipcode: zip ? zip.label : "",
                patientGroupID: clickedRowData? clickedRowData.patientGroupID : ""
            }


            AxiosInter.post(`${API_ENDPOINT}/admin-api/update-patient-demographics/`, params,
                {
                    headers: {
                        Authorization: API_TOKEN,
                    },
                }
            )
                .then((response) => {
                    if (response.status === 200) {
                        toggle();
                        toastr.success("Success", "Patient Details Updated");
                        updatePatientList();
                    }
                })
                .catch((error) => {
                    try {
                        const errorMessage = error.response.data.message || "{}";
                        console.log("Error Message:", errorMessage); // Log the error message
                        const errorData = JSON.parse(errorMessage);
                        const statusCode = errorData.statusCode;

                        if (statusCode === 23) {
                            setPatientListModal(!patientListModal);
                            updatePatientList();
                            toastr.success("Patient invited successfully");
                        } else if (statusCode === 19) {
                            toastr.warning("User already exists");
                        } else if (statusCode === 21) {
                            toastr.warning("Phone number already exists");
                        } else if (statusCode === 20) {
                            toastr.warning("Email already exists");
                        } else if (statusCode === 25) {
                            setPatientListModal(!patientListModal);
                            updatePatientList();
                            toastr.success("Data inserted to all tables successfully");
                        } else {
                            toastr.error("Error", "Something went wrong");
                        }
                    } catch (parseError) {
                        toastr.error("Error", "Failed to parse server response");
                        console.error(parseError);
                    }
                });


        } else {
            toastr.warning("Please fill the mandatory fields")
        }

    }

    const FetchCountryListAPICall = async () => {
        await AxiosInter.get(`${API_ENDPOINT}/admin-api/get-country-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                let allCountryCode = response.data
                let filteredCountryCode = allCountryCode.map(item => {
                    return {
                        value: item.id,
                        label: item.country_isd_code,
                    }
                })
                setCountryCodeList(filteredCountryCode)

            })
            .catch(function (error) {

            });
    }

    useEffect(() => {
        if (clickedRowData) {
            listAllPatientDetails();
        }
    }, [clickedRowData]); // eslint-disable-line

    useEffect(() => {
        FetchCountryListAPICall();
    }, []); // eslint-disable-line

    const getAsyncOptions = (inputValue) => {
        if (m_zipList && inputValue.length >= 4) {
            return new Promise((resolve, reject) => {
                const filtered = _.filter(m_zipList, (o) =>
                    _.startsWith(_.toLower(o.zipcode), _.toLower(inputValue))
                ).map(x => ({ value: x.id, label: x.zipcode, city: x.city, state: x.state }));
                resolve(filtered);
            });
        }
    };

    return (
        <div>
            <Modal isOpen={patientListModal} size="md">
                <ModalHeader
                    tag="h4"
                    cssModule={{ "modal-title": "w-100 text-center" }}
                >
                    Patient Details
                </ModalHeader>
                <ModalBody className="overflow-modal">
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>
                                            First Name <span className="required">*</span>
                                        </Label>
                                        <Input
                                            type="text"
                                            value={fullPateintData?.first_name}
                                            onChange={handleInputChange}
                                            name="first_name"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Middle Name </Label>
                                        <Input
                                            type="text"
                                            value={fullPateintData?.middle_name}
                                            name="middle_name"
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Last Name <span className="required">*</span></Label>
                                        <Input
                                            type="text"
                                            value={fullPateintData?.last_name}
                                            name="last_name"
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>

                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>Gender{" "}<span className="required">*</span></Label>
                                        <Select
                                            options={genderOptions}
                                            placeholder="gender"
                                            value={populateGender(fullPateintData?.gender)}
                                            onChange={handleChangeGender}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label>DOB{" "}<span className="required">*</span></Label>
                                        <Input
                                            type="date"
                                            value={fullPateintData ? moment(fullPateintData.dob).format('YYYY-MM-DD') : ''}
                                            name="dob"
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="country_name">Country Name{" "}</Label>
                                        <Input type="text"
                                            value={fullPateintData?.country_name}
                                            name="country_name"
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="email">Email{" "}<span className="required">*</span></Label>
                                        <Input
                                            id="email"
                                            type="text"
                                            value={fullPateintData?.email}
                                            name="email"
                                            onChange={handleInputChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <Label for="code">
                                        Country Code{" "}
                                    </Label>
                                    <Select
                                        options={countryCodeList}
                                        onChange={handleCountryCodeChange}
                                        value={populateCountryCode(fullPateintData?.countryCode)}
                                        placeholder="Select"
                                        isSearchable
                                        isClearable
                                        menuPosition="fixed"
                                    />
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="mobilePhone">Mobile Number{" "}<span className="required">*</span></Label>
                                        <Input
                                            id="mobliePhone"
                                            type="tel"
                                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                            value={fullPateintData?.mobliePhone}
                                            name="mobliePhone"
                                            onChange={handleInputChange}
                                            minLength={10}
                                            maxLength={13}
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <Label for="address1">Address{" "}</Label>
                                        <Input
                                            id="address1"
                                            type="textarea"
                                            value={fullPateintData?.address1}
                                            name="address1"
                                            onChange={handleInputChange}
                                            rows={4}
                                            cols={2}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="zipid">Zipcode{" "}</Label>
                                        <AsyncSelect type="text"
                                            value={zip}
                                            name="zipcode"
                                            onChange={(event) => {
                                                setZip(event)
                                            }}
                                            loadOptions={(inputValue) => getAsyncOptions(inputValue)}
                                            isSearchable
                                            isClearable
                                            cacheOptions
                                            menuPosition="fixed"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="city">City{" "}</Label>
                                        <Input type="text"
                                            value={zip?.city || fullPateintData?.city}
                                            disabled
                                            placeholder="City"
                                            maxLength="250"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label for="state">State{" "}</Label>
                                        <Input type="text"
                                            value={zip?.state || fullPateintData?.state_name}
                                            disabled
                                            placeholder="State"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        onClick={() => {
                            updateAllPatientDetails()
                        }}
                    >Confirm
                    </Button>{" "}
                    <Button outline className="no-button button-width" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default PatientListModal;