import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,Button
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import { GlobalState } from '../../contextAPI/Context';


/* const { SearchBar, ClearSearchButton } = Search; */

const OrderAgainstRuleDataTable = (props) => {

  const { blankStateMessage } = useContext(GlobalState) // eslint-disable-line
                     
                    // LOCAL VARIABLES      
  // eslint-disable-next-line no-unused-vars
  const { totalSize,onTableChange,orderSerialNumber,setOrderSerialNumber,reload,setReload,searchOrderAgainstRule,SetSearchOrderAgainstRule,exportDataForOrderAgainstRule } = props
  const [numberOfRecords, setNumberOfRecords] = useState(0)
  const [blankStateStatus, setBlankStateStatus] = useState(false);
  const MyExportCSV = (props) => {
    const handleClick = () => {
      exportDataForOrderAgainstRule();
    };
    return (
      <div>
        <button className="btn btn-export mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No data available in table</h3>
      </CardBody>
    </Card>
  );

  const options = {
    page: props.page,
    nextPageText: 'Next',
    prePageText: 'Previous',
    sizePerPage: props.sizePerPage,
    sizePerPageList: [25, 50, 150, 200],
    totalSize
  };

  useEffect(() => {
    if (props.data.length === 0) {
      setBlankStateStatus(true);
    } else {
      setBlankStateStatus(false);
    }
  }, [props.data.length]);

  useEffect(() => { setNumberOfRecords(totalSize) }, [totalSize]);
  return (
    <div className="physicians_table table-list-height">
      <Card>
        <ToolkitProvider
          keyField="id"
          data={props.data}
          columns={props.columns}
          search
          
          exportCSV={{
            fileName: `Equipo-Order-Against-Rule-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
          }}
        >
          
          {(props) => (
            <div>
              <CardHeader>
              {/* <Row>
                  <Col>
                    <FaxLogFilters />
                  </Col>
                </Row> */}
                <Row>
                  <Col sm="2">
                    <div className="number-of-records">
                      <p>Number of records: <strong>{numberOfRecords}</strong></p>
                    </div>
                  </Col>
                  <Col>
                    {/* <SearchBar {...props.searchProps} className="search-box" />
                    <ClearSearchButton {...props.searchProps} className="clear-search-box" /> */}
                    <div className="search-order-serial-number" >

                    
                      </div>
                      <div className="form-inline justify-content-end">
                      <div className="form-group mr-2">
                      <Input
                      id="num"
                      value={searchOrderAgainstRule}
                      
                      placeholder="order serial number"
                      onChange = {(e)=>{
                        
                        SetSearchOrderAgainstRule(e.target.value)
                        
                        
                      }}
                      />
                      </div>
                      <Button
                        className="clear-search-box"
                        onClick={() => {SetSearchOrderAgainstRule("");  setReload(reload+1) }}
                      >
                        Clear
                      </Button>
                      <div className="export-button ml-2">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                    </div>
                  </Col>
                 
                </Row>
                <div className="separator" />
                <div className="float-right pull-right">
                </div>
              </CardHeader>
              <CardBody className="patient-list">
                
                {blankStateStatus ? blankState : ""}
                {!blankStateStatus && 
                <BootstrapTable
                {...props.baseProps}
                remote
                pagination={paginationFactory(options)}            
                onTableChange={ onTableChange }
                
              />}
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </div>
  );
};

export default OrderAgainstRuleDataTable;