import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  Input,
  Spinner,
  Container,
  NavItem,
  Form,
  FormGroup,
  Label,
  NavLink,
  Card,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  CardBody
} from "reactstrap";
import classnames from "classnames";
import AxiosInter from './../../AxiosInter.js';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastr } from "react-redux-toastr";
import { faArrowLeft, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import ToggleButton from "react-toggle-button";
import Cookie from 'js-cookie';


const GroupSecurity = (props) => {

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [spinner, setSpinner] = useState(false);
  const [groupSecurityDetails, setGroupSecurityDetails] = useState([]);
  const [passwordActiveDuration, setPasswordActiveDuration] = useState("");

  const [invalidAttempts, setInvalidAttempts] = useState("");
  const [minPasswordLength, setMinPasswordLength] = useState("");
  const [maxPasswordLength, setMaxPasswordLength] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [passwordAlphanumeric, setPasswordAlphanumeric] = useState("");
  const [passwordAlphanumericNumberUpperCase, setPasswordAlphanumericNumberUpperCase] = useState("");
  const [passwordAlphanumericNumberUpperCaseSplChar, setPasswordAlphanumericNumberUpperCaseSplChar] = useState("");
  const [passwordWithUserName, setPasswordWithUserName] = useState(false);
  const [blockedPasswords, setBlockedPasswords] = useState("");
  const [securityQuestions, setSecurityQuestions] = useState("");

  const [inValidMinPasswordLength, setInValidMinPasswordLength] = useState(false);
  const [inValidMaxPasswordLength, setInValidMaxPasswordLength] = useState(false);

  const [invalidPasswordExpirePolicy, setInvalidPasswordExpirePolicy] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [groupName, setGroupName] = useState("");
  const [reload, setReload] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  let { id } = useParams();
  const [activeTab, setActiveTab] = useState("1");

  const goBack = () => {
    navigate(-1);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getGroupSecurityDetails = () => {
    setSpinner(true);
    const getParams = {
      group_id: id,
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/get-group-security-details/`,
        getParams,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        setGroupSecurityDetails(response.data.data);
        setSpinner(false);
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong!");
      });
  };

  const updateSecurityDetails = () => {
    setSpinner(true);

    const updateParameters = {
      group_id: id ? atob(id) : "",
      admin_id: localStorage.getItem("adminID") ? localStorage.getItem("adminID") : "",
      invalid_attempts_allowed: invalidAttempts != null ? Number(invalidAttempts) : "",
      password_active_duration: passwordActiveDuration != null ? Number(passwordActiveDuration) : "",
      password_min_length: minPasswordLength ? Number(minPasswordLength) : "",
      password_max_length: maxPasswordLength ? Number(maxPasswordLength) : "",
      password_alphanumeric: passwordAlphanumeric != null ? passwordAlphanumeric : "",
      password_alphanumeric_number_uppercase: passwordAlphanumericNumberUpperCase != null ? passwordAlphanumericNumberUpperCase : "",
      password_alphanumeric_number_uppercase_spl_character: passwordAlphanumericNumberUpperCaseSplChar != null ? passwordAlphanumericNumberUpperCaseSplChar : "",
      password_with_username: passwordWithUserName != null ? passwordWithUserName : "",
      blocked_passwords: blockedPasswords != null ? Number(blockedPasswords) : "",
      security_questions: securityQuestions != null ? Number(securityQuestions) : "",
      ip_address: ipAddress !== "" ? ipAddress : "",
    };

    if (minPasswordLength < 6) {
      setInValidMinPasswordLength(true);
      setSpinner(false);
      return toastr.error(
        "Error",
        "Minimum number of characters shouldn't be less than 6"
      );
    } else if (minPasswordLength > 20) {
      setInValidMinPasswordLength(true);
      setSpinner(false);
      return toastr.error(
        "Error",
        "Minimum number of characters shouldn't be more than 20"
      );
    } else if (maxPasswordLength > 20) {
      setInValidMaxPasswordLength(true);
      setSpinner(false);
      return toastr.error(
        "Error",
        "Maximum number of characters shouldn't be more than 20"
      );
    } else if (maxPasswordLength < 6) {
      setInvalidPasswordExpirePolicy(true);
      setSpinner(false);
      return toastr.error(
        "Error",
        "Maximum number of characters shouldn't be less than 6"
      );
    }

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-group-security-details/`,
        updateParameters,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        toastr.success("Success", "User security updated");
        setSpinner(false);
        setReload(reload + 1);
        setInValidMaxPasswordLength(false);
        setInValidMinPasswordLength(false);
        setInvalidPasswordExpirePolicy(false);
        navigate({
          pathname: "/groups"
        });
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
        setSpinner(false);
      });
  };

  useEffect(() => {
    getGroupSecurityDetails();
    document.title = "Equipo Admin | Groups";
  }, []); // eslint-disable-line

  useEffect(() => {
    if (reload) {
      getGroupSecurityDetails();
    }
  }, [reload]); // eslint-disable-line

  useEffect(() => {
    if (location.state) {
      localStorage.setItem(
        "isFilterApplied",
        JSON.stringify(location.state.detail)
      );
    }
  }, [location]);

  useEffect(() => {
    if (groupSecurityDetails) {
      // eslint-disable-next-line array-callback-return
      groupSecurityDetails.map((item) => {
        // eslint-disable-line
        // debugger;
        setMinPasswordLength(item.password_min_length);
        setMaxPasswordLength(item.password_max_length);
        setPasswordAlphanumeric(item.password_alphanumeric);
        setPasswordAlphanumericNumberUpperCase(item.password_alphanumeric_number_uppercase);
        setPasswordAlphanumericNumberUpperCaseSplChar(item.password_alphanumeric_number_uppercase_spl_character);
        setPasswordWithUserName(item.password_with_username);
        setPasswordActiveDuration(item.password_active_duration);
        setBlockedPasswords(item.blocked_passwords);
        setInvalidAttempts(item.invalid_attempts);
        setIpAddress(item.ip_address);
        setSecurityQuestions(item.security_questions);
      });
    }
  }, [groupSecurityDetails]);

  const passwordUserNameToggle = () => {
    if (passwordWithUserName) {
      setPasswordWithUserName(0);
    } else {
      setPasswordWithUserName(1);
    }
  };
  // CheckUserInactivity()
  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>Groups</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/groups">Groups</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>User Security</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>
      <Card className="adv-search-background-card" >
        <div style={{ display: "flex", justifyContent: "left", }}>
          <FontAwesomeIcon
            size={"lg"}
            style={{ cursor: "pointer" }}
            title={"Go Back"}
            icon={faArrowLeft}
            onClick={goBack}
          />
        </div>
        <span style={{ textAlign: "center" }}>
          <div>
            <FontAwesomeIcon icon={faShieldAlt} />
            <h4 style={{ display: "inline", marginLeft: "0.5rem" }}>
              Group Security
            </h4>
          </div>
          <hr style={{ width: "50%" }} />
        </span>
        <span style={{ textAlign: "center" }}>
          <h4 style={{ display: "inline", marginLeft: "0.5rem" }}>
            {localStorage.getItem("groupName")}
          </h4>
        </span>

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              style={{
                borderRadius: '15px',
                background: 'linear-gradient(145deg, #e6e6e6, #ffffff)',
                boxShadow: '5px 5px 15px #cccccc, -5px -5px 15px #ffffff',
                borderColor: 'transparent',
                fontSize: '2rem',
              }}
              onClick={() => {
                toggle("1");
              }}
            >
              <h5>Password Rules</h5>
            </NavLink>
          </NavItem>
          {/* <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            Security Questions
          </NavLink>
                    </NavItem> */}
        </Nav>
        <br />
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {spinner ? <Spinner /> : ""}
            <Row>
              <Col sm={12}>
                <Form>
                  <FormGroup>
                    <div className="roles-list-card card">
                      <CardBody style={{
                        borderRadius: '15px',
                        background: 'linear-gradient(145deg, #e6e6e6, #ffffff)',
                        boxShadow: '5px 5px 15px #cccccc, -5px -5px 15px #ffffff',
                        borderColor: 'transparent',
                      }}>
                        <Col sm={6}>
                          <Input
                            type="radio"
                            name="passwordRadio"
                            onClick={(e) => {
                              setPasswordAlphanumeric(1);
                              setPasswordAlphanumericNumberUpperCase(0);
                              setPasswordAlphanumericNumberUpperCaseSplChar(0);
                            }}
                            checked={passwordAlphanumeric === 1 ? true : false}
                          />
                          <Label>Password should be Alphanumeric</Label>
                        </Col>

                        <Col sm={6}>
                          <Input
                            type="radio"
                            name="passwordRadio"
                            checked={
                              passwordAlphanumericNumberUpperCase === 1
                                ? true
                                : false
                            }
                            onClick={(e) => {
                              setPasswordAlphanumeric(0);
                              setPasswordAlphanumericNumberUpperCase(1);
                              setPasswordAlphanumericNumberUpperCaseSplChar(0);
                            }}
                          />
                          <Label>
                            Password should be Alphanumeric with at least one
                            Number and Uppercase character
                          </Label>
                        </Col>

                        <Col sm={6}>
                          <Input
                            type="radio"
                            name="passwordRadio"
                            checked={
                              passwordAlphanumericNumberUpperCaseSplChar === 1
                                ? true
                                : false
                            }
                            onClick={(e) => {
                              setPasswordAlphanumeric(0);
                              setPasswordAlphanumericNumberUpperCase(0);
                              setPasswordAlphanumericNumberUpperCaseSplChar(1);
                            }}
                          />
                          <Label>
                            Password should be Alphanumeric with at least one
                            Number, One Uppercase character and one Special
                            Character.
                          </Label>
                        </Col>
                      </CardBody>
                    </div>
                    <div className="roles-list-card card">
                      <CardBody style={{
                        borderRadius: '15px',
                        background: 'linear-gradient(145deg, #e6e6e6, #ffffff)',
                        boxShadow: '5px 5px 15px #cccccc, -5px -5px 15px #ffffff',
                        borderColor: 'transparent',
                      }}>
                        <Row>
                          <Col sm={3}>
                            <Label>
                              Number of security questions to be configured per
                              user:
                            </Label>
                          </Col>
                          <Col sm={1}>
                            <Input
                              // style={{"border-radius": "25px"}}
                              style={{
                                borderRadius: '15px',
                                background: 'linear-gradient(145deg, #ffffff, #ffffff)',
                                boxShadow: '5px 5px 15px #cccccc, -5px -5px 15px #ffffff',
                                borderColor: 'transparent',
                              }}
                              type="number"
                              id="security_questions_number"
                              value={securityQuestions}
                              onChange={(e) => {
                                setSecurityQuestions(e.target.value);
                              }}
                            />
                          </Col>
                        </Row><br></br>
                        <Row>
                          <Col sm={4} md={3}>
                            <Label>
                              Number of characters required in password
                            </Label>
                          </Col>
                          <Label>
                            Min:
                          </Label>
                          <Col sm={1}>
                            <Input
                              // style={{"border-radius": "25px"}}
                              style={{
                                borderRadius: '15px',
                                background: 'linear-gradient(145deg, #ffffff, #ffffff)',
                                boxShadow: '5px 5px 15px #cccccc, -5px -5px 15px #ffffff',
                                borderColor: 'transparent',
                              }}
                              type="number"
                              id="min_password_length"
                              placeholder="Minimum"
                              value={minPasswordLength}
                              invalid={inValidMinPasswordLength}
                              onChange={(e) => {
                                setMinPasswordLength(e.target.value);
                              }}
                            />
                            {/* <FormFeedback>Minimum number of characters should not be less than 6</FormFeedback> */}
                          </Col>


                          <Label>
                            Max:
                          </Label>
                          <Col sm={4} md={1}>
                            <Input
                              // style={{"border-radius": "25px"}}
                              style={{
                                borderRadius: '15px',
                                background: 'linear-gradient(145deg, #ffffff, #ffffff)',
                                boxShadow: '5px 5px 15px #cccccc, -5px -5px 15px #ffffff',
                                borderColor: 'transparent',
                              }}
                              id="max_password_length"
                              placeholder="Maximum "
                              type="number"
                              value={maxPasswordLength}
                              onChange={(e) => {
                                setMaxPasswordLength(e.target.value);
                              }}
                              invalid={inValidMaxPasswordLength}
                            />
                            {/* <FormFeedback>Maximum number of characters should be greater than 20</FormFeedback> */}
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col sm={4} md={1}>
                            <Label>Cannot use last</Label>
                          </Col>
                          <Col sm={4} md={1}>
                            <Input
                              // style={{"border-radius": "25px"}}
                              style={{
                                borderRadius: '15px',
                                background: 'linear-gradient(145deg, #ffffff, #ffffff)',
                                boxShadow: '5px 5px 15px #cccccc, -5px -5px 15px #ffffff',
                                borderColor: 'transparent',
                              }}
                              type="number"
                              value={blockedPasswords}
                              onChange={(e) => {
                                setBlockedPasswords(e.target.value);
                              }}
                            />
                          </Col>

                          <Col sm={2} md={2}>
                            passwords.
                          </Col>
                        </Row>
                      </CardBody>
                    </div>

                    <div className="roles-list-card card">
                      <CardBody style={{
                        borderRadius: '15px',
                        background: 'linear-gradient(145deg, #e6e6e6, #ffffff)',
                        boxShadow: '5px 5px 15px #cccccc, -5px -5px 15px #ffffff',
                        borderColor: 'transparent',
                      }}>
                        <Row>
                          <Col sm={3}>
                            <p>Password cannot contain username</p>
                          </Col>
                          <Col sm={2}>
                            <ToggleButton

                              inactiveLabel={"OFF"}
                              activeLabel={"ON"}
                              value={passwordWithUserName === 1 ? true : false}
                              onToggle={() => {
                                passwordUserNameToggle();
                              }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={3}>
                            <p>Passwords will expire in</p>
                          </Col>
                          <Col sm={1}>
                            <Input
                              // style={{"border-radius": "25px"}}
                              style={{
                                borderRadius: '15px',
                                background: 'linear-gradient(145deg, ##ffffff, #ffffff)',
                                boxShadow: '5px 5px 15px #cccccc, -5px -5px 15px #ffffff',
                                borderColor: 'transparent',
                              }}
                              type="number"
                              id="active_duration"
                              value={passwordActiveDuration}
                              invalid={invalidPasswordExpirePolicy}
                              onChange={(e) => {
                                setPasswordActiveDuration(e.target.value);
                              }}
                            />
                          </Col>
                          <p style={{ display: "inline" }}>days.</p>
                        </Row>
                        <Row>

                          <Col sm={3}>
                            <Label>
                              No of consecutive invalid login attempts that will lockout the
                              account
                            </Label>
                          </Col>

                          <Col sm={1}>
                            <Input
                              // style={{"border-radius": "25px"}}
                              style={{
                                borderRadius: '15px',
                                background: 'linear-gradient(145deg, #ffffff, #ffffff)',
                                boxShadow: '5px 5px 15px #cccccc, -5px -5px 15px #ffffff',
                                borderColor: 'transparent',
                              }}
                              type="number"
                              id="=invalid_attempts"
                              value={invalidAttempts}
                              onChange={(e) => {
                                setInvalidAttempts(e.target.value);
                              }}
                              minLength="1"
                              maxLength="120"
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Label>
                              Allow access from following IP address(s) only:
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6}>
                            <Input
                              id="group_name"
                              type="textarea"
                              rows="4"
                              cols="10"
                              // style={{ resize: "none" }}
                              style={{
                                borderRadius: '15px',
                                background: 'linear-gradient(145deg, #ffffff, #ffffff)',
                                boxShadow: '5px 5px 15px #cccccc, -5px -5px 15px #ffffff',
                                borderColor: 'transparent',
                              }}
                              placeholder="Allowed IP addresses"
                              value={ipAddress}
                              onChange={(e) => {
                                setIpAddress(e.target.value);
                              }}
                              minLength="1"
                              maxLength="120"
                            />
                            <br />
                          </Col>
                        </Row>
                      </CardBody>
                    </div>

                    <div style={{ marginLeft: "0.70rem" }}>
                      <Button style={{
                        borderRadius: '10px'
                      }}
                        onClick={() => {
                          updateSecurityDetails();

                        }}
                      >
                        Update Security
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </TabPane>
          {/* <TabPane tabId="2">
                    <Row>
                        <Col sm={4}>
                            <Label>Minimum number of characters required in password</Label>
                        </Col>
                        <Col sm={2}>
                            <Input
                                type="number"

                            />
                        </Col>
                        <Button
                            type="submit"
                            // onClick = {()=>{
                                
                            // }}

                        >
                            Update
                    </Button>
                    </Row>
                </TabPane> */}
        </TabContent>
      </Card>
    </div>
  );
};

export default GroupSecurity;
