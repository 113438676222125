import Cookie from "js-cookie"
import React, { useState } from 'react'
import AsyncSelect from "react-select/async"
import { Button, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import AxiosInter from './../../AxiosInter.js'
import { toastr } from "react-redux-toastr"

function AddNewAssigneeInsuranceMapping({ isOpen, toggleModal, setReload }) {
   const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
   const API_TOKEN = Cookie.get("access_token_admin")

   const [groupName, setGroupName] = useState("")
   const [appUser, setappUser] = useState("")
   const [insurance, setInsurance] = useState("")
   const [loader, setLoader] = useState(false)

   const handleGroupChange = (value) => {
      setGroupName(value)
   }

   const handleAppUserChange = (appUser) => {
      setappUser(appUser)
   }

   const groupNameloadOptions = (inputValue) => {
      if (inputValue.length >= 4) {
         const searchParams = new URLSearchParams({ name: inputValue })
         return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
            {
               headers: {
                  Authorization: API_TOKEN,
               },
            }
         )
            .then((response) => response.data.data)
            .catch((error) => {
               throw error
            })
      }
   }

   const appUserloadOptions = (inputValue) => {
      if (inputValue.length >= 4) {
         const params = {
            "groupID": (groupName?.value) + '',
            "name": inputValue,
         }
         // const searchParams = new URLSearchParams({ name: inputValue });
         return AxiosInter.post(`${API_ENDPOINT}/admin-api/fetch-group-appusers/`, 
            params
            ,
            {
               headers: {
                  Authorization: API_TOKEN,
               },
            }
         )
            .then((response) => response.data.data)
            .catch((error) => {
               throw error;
            });
      }
   };

   const handleAddNewCategory = (e) => {
      e.preventDefault()
      setLoader(true)

      const params = {
         "groupId": (groupName?.value) ? groupName.value : '',
         "userId": (appUser?.value) ? appUser.value : '',
         "insuranceName": insurance ? insurance : '',
      }

      AxiosInter
         .post(`${API_ENDPOINT}/admin-api/create-referral-assignee-insurance/`, params, {
            headers: {
               Authorization: API_TOKEN,
            }
         })
         .then((response) => {
            if (response.status === 201) {
               toastr.success("Success", "New Entry created")
               setLoader(false)
               toggleModal()
               setReload(true)
            }
         })
         .catch((error) => {
            if (error?.response?.data?.message) {
               toastr.error("Failed", error.response.data.message)
               setLoader(false)
            }
            else {
               toastr.error("Failed", "something went to wrong")
               setLoader(false)
            }
         })
   }

   return (
      <>
         <Modal
            isOpen={isOpen}
            size="md"
            className="referral-category-configuration"
         >
            <ModalHeader
               tag="h4"
               cssModule={{ "modal-title": "w-100 text-center" }}
               toggle={toggleModal}
            >
               Add Insurance Mapping
            </ModalHeader>
            <Form
               onSubmit={handleAddNewCategory}
            >
               <ModalBody>
                  {
                     loader && <Spinner />
                  }
                  <Row>
                     <Col xs={12} className="bottom-space">
                        <Label>
                           Group Name <span className="required">*</span>
                        </Label>
                        <AsyncSelect
                           cacheOptions
                           isClearable
                           isSearchable
                           type="text"
                           name="groupName"
                           placeholder="Search with at least 4 characters."
                           value={groupName}
                           onChange={handleGroupChange}
                           loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
                           required
                        />
                     </Col>
                     <Col xs={12} className="bottom-space">
                        <Label>
                           App User Name <span className="required">*</span>
                        </Label>
                        <AsyncSelect
                           cacheOptions
                           isClearable
                           isSearchable
                           type="text"
                           name="appusername"
                           placeholder="Search with at least 4 characters."
                           value={appUser}
                           onChange={handleAppUserChange}
                           loadOptions={(inputValue) => appUserloadOptions(inputValue)}
                           required
                        />
                     </Col>
                     <Col xs={12} className="bottom-space">
                        <Label>
                           Insurance Name <span className="required">*</span>
                        </Label>
                        <Input
                           type='text'
                           value={insurance}
                           placeholder='Enter insurance name'
                           style={{ padding: '18px 10px' }}
                           onChange={(e) => setInsurance(e.target.value)}
                           required
                        />
                     </Col>
                  </Row>
               </ModalBody>
               <ModalFooter className="advanced-search-button  custom-modal-footer">
                  <Button
                     type="submit"
                     className="yes-button button-width"
                     style={{ cursor: "pointer" }}
                  >
                     Add
                  </Button>{" "}
                  <Button
                     outline
                     style={{ cursor: "pointer" }}
                     className="no-button button-width"
                     onClick={toggleModal}
                  >
                     Cancel
                  </Button>
               </ModalFooter>
            </Form>
         </Modal>
      </>
   )
}

export default AddNewAssigneeInsuranceMapping