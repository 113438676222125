import React, { useContext } from "react";
import { connect } from "react-redux";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { GlobalState } from '../contextAPI/Context';

const NavbarToggle = (props) => {
  const { hideMenu, setHideMenu } = useContext(GlobalState);
  return (
    // eslint-disable-next-line
    <span className="sidebar-toggle d-flex mr-2" onClick={() => { setHideMenu(!hideMenu) }}> 

      <i className="hamburger align-self-center" />

    </span>
  );
};

const NavbarDropdowns = connect(store => ({
  sidebar: store.sidebar
}))(({ sidebar }) => {
  return (
    <Collapse navbar>
      <Nav className={!sidebar.isOnRight ? "ml-auto" : "mr-auto"} navbar>
        <UncontrolledDropdown nav inNavbar className="ml-lg-1">
          <DropdownToggle nav caret>
            <FontAwesomeIcon icon={faCog} className="align-middle" />
          </DropdownToggle>
          <DropdownMenu align="end">
            <DropdownItem>
              <Link to="/logout">
                <FontAwesomeIcon
                  icon={faArrowAltCircleRight}
                  fixedWidth
                  className="mr-2 align-middle"
                />
              Sign out</Link>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Collapse>
  );
});

const NavbarComponent = (props) => {
  return (
    <span className="setting-btn">
      <Navbar expand/*  className="navbar-theme" */ >
      <NavbarToggle toggleFunction={props.toggleFunction} />
      <NavbarDropdowns />
    </Navbar>
    </span>
  );
};

export default connect(store => ({
  sidebar: store.sidebar
}))(NavbarComponent);
