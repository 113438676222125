import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from "react-select";

import { GlobalState } from "../../contextAPI/Context";

const MoveToNewGroupModal = (props) => {
    const {
        serviceFormID,
        editGroupModal,
        setEditGroupModal,
        editType,
        reload,
        setReload


    } = props;

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");

    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const {
        groupNamesForDropDown,
    } = useContext(GlobalState);

    const toggle = () => {
        (setEditGroupModal(!editGroupModal));
        setSelectedGroup([]);

    }


    const UpdateServiceForm = () => {
        const params = {
            "form_id": serviceFormID ? serviceFormID : 0,
            "form_group_id": selectedGroup ? selectedGroup.value : 0

        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-form-groupId/`, params, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(response => {
                if (response.status === 201) {
                    toggle();
                    toastr.success("Success", "Form Successfully Moved to Selected Group");
                    setReload(reload + 1);
                }
            })
            .catch(error => {
                toastr.error("Error", "Something went wrong !")


            });
    }


    const populateGroupName = () => {
        groupNamesForDropDown.map((data) => { // eslint-disable-line
            if (data.label === editType) {
                setSelectedGroup({ value: data.value, label: data.label })

            }
        })
    }

    const disableAddButton = () => {

        if (selectedGroup) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }


    useEffect(() => { disableAddButton() }, [selectedGroup]) // eslint-disable-line
    useEffect(() => {
        if (!editGroupModal)
            setEditGroupModal(false);
    }, [editGroupModal]) // eslint-disable-line

    useEffect(() => {
        if (editGroupModal) {
            populateGroupName()
        }

    }, [editGroupModal]) // eslint-disable-line
    return (
        <div>
            <Modal isOpen={editGroupModal} size="md" scrollable={true} className="move-to-group" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Move to Group </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>

                        <Row >
                            <Col sm={12} >
                                <div className="" >
                                    <Label>

                                        Select Group  <span className=""></span> :

                                    </Label>
                                </div>

                                <div className="" >

                                    <Select
                                        options={groupNamesForDropDown}
                                        onChange={(event) => setSelectedGroup(event)}
                                        value={selectedGroup}
                                        placeholder="Select Group"
                                        isSearchable
                                        isClearable
                                        menuPlacement="top"
                                    // isMulti
                                    />
                                </div>
                            </Col>

                        </Row>




                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={UpdateServiceForm}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default MoveToNewGroupModal;