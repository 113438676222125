import React from "react";
import { useState, useEffect, useContext } from "react";
import { GlobalState } from "../../contextAPI/Context";
// eslint-disable-next-line no-unused-vars
import _ from "lodash";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  //useMapEvents,
} from "react-leaflet";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
//import { Hux } from "../../hoc/Hux";
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Nav,
  NavItem,
  NavLink,
  Card,
  Col,
  Row,
  Button,
  Label,
  Input,
  CardBody,
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Link } from "react-router-dom";
import classnames from "classnames";
import markeric from "./mapmarkericon";
import AsyncSelect from "react-select/async";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select as SelectVirtualized } from "react-select-virtualized";
import Cookie from 'js-cookie';


function LocationMarker() {
  const { m_zipList } = useContext(GlobalState);
  const [zipCodeOptions, setZipCodeOptions] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedZip, setSelectedZip] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [groupMarker, setGroupMarker] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [facilityMarker, setFacilityMarker] = useState(null);
  const [facilitytems, setFacilityItems] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [groupItems, setGroupItems] = useState([]);
  const [groupLocationReload, setGroupLocationReload] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [facilityLocationReload, setFacilityLocationReload] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [facilityspinner, setFacilitySpinner] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [groupspinner, setGroupSpinner] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [IsFacilityDataEmpty, setIsFacilityDataEmpty] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [IsGroupsDataEmpty, setIsGroupsDataEmpty] = useState(false);
  // const [zipCodeOptions, setZipCodeOptions] = useState([]);
  // const [zipCodeOptionsforGroup, setZipCodeOptionsForGroup] = useState([]);

  const [selectedNpi, setSelectedNpi] = useState("");
  const [selectedMiles, setSelectedMiles] = useState("");
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const [selectedGroupForGroup, setSelectedGroupForGroup] = useState("");
  const [selectedZipForGroup, setSelectedZipForGroup] = useState("");
  const [selectedNpiForGroup, setSelectedNpiForGroup] = useState("");
  const [selectedMilesForGroup, setSelectedMilesForGroup] = useState("");
  const [searchButtonClickedForGroup, setSearchButtonClickedForGroup] =
    useState(false);
  const [groupsFilter, setGroupsFilter] = useState(false);
  const [groupMapCenter, setGroupMapCenter] = useState([
    45.9896587, -94.6113288,
  ]);
  const [selectedFacility, setSelectedFacility] = useState("");
  const [facilityFilter, setFacilityFilter] = useState(false);
  const [facilityMapCenter, setFacilityMapCenter] = useState([
    28.6138954, -77.2090057,
  ]);
  const [facilityMapZoom, setFacilityMapZoom] = useState(5);
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [facilityOptions, setFacilityOptions] = useState([{}]);

  const fetchFacilityLocationeDetails = async () => {
    setFacilitySpinner(true);
    setFacilityLocationReload(true);

    const params = {
      groupName: selectedGroup ? selectedGroup.label : "",
      // "facilityName": selectedZip ? selectedZip: "" ,
      facilityName: selectedFacility ? selectedFacility.label : "",

      zipid: selectedZip ? selectedZip.label : "",
      npi: selectedNpi ? selectedNpi : "",
      distance: selectedMiles ? selectedMiles : "",
    };

    //    setSpinner (true);

    await AxiosInter
      .post(`${API_ENDPOINT}/admin-api/facility-location-search/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.features.length > 0) {
          setFacilitySpinner(false);
          const allitems = response.data.data.features;

          setFacilityItems(allitems);
          setFacilityLocationReload(true);
          setFacilityMapCenter([
            allitems[0].geometry.coordinates[1],
            allitems[0].geometry.coordinates[0],
          ]);
        } else {
          setFacilitySpinner(false);
          setFacilityItems([]);
          setIsFacilityDataEmpty(true);
          setFacilityLocationReload(true);
        }
      })
      .catch((error) => {
        toastr.error("No Data Found");
        setFacilityLocationReload(true);
      });
  };
  const fetchGroupLocationeDetails = async () => {
    setGroupSpinner(true);
    setGroupLocationReload(true);
    //debugger;

    const params = {
      groupName: selectedGroupForGroup ? selectedGroupForGroup.label : "",

      zipid: selectedZipForGroup ? selectedZipForGroup.label : "",
      npi: selectedNpiForGroup ? selectedNpiForGroup : "",
      distance: selectedMilesForGroup ? selectedMilesForGroup : "",
    };

    //    setSpinner (true);

    await AxiosInter
      .post(`${API_ENDPOINT}/admin-api/groups-location-search/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.features.length > 0) {
          setGroupSpinner(false);
          const allitems = response.data.data.features;

          setGroupItems(allitems);
          setGroupLocationReload(true);
          setGroupMapCenter([
            allitems[0].geometry.coordinates[1],
            allitems[0].geometry.coordinates[0],
          ]);
        } else {
          setGroupSpinner(false);
          setGroupItems([]);
          setIsGroupsDataEmpty(true);
          setGroupLocationReload(true);
        }
      })
      .catch((error) => {
        toastr.error("No Data Found");
        setGroupLocationReload(true);
      });
  };

  // console.table(facilitytems[0].properties.facilityName[0])

  const clearFacilityFilter = () => {
    setSelectedGroup("");
    setSelectedZip("");
    setSelectedFacility("");
    setSelectedNpi("");
    setFacilityFilter(false);
    setFacilityLocationReload(false);
    setSelectedMiles("");
    setSearchButtonClicked(false);
  };
  const clearGroupsFilter = () => {
    setSelectedGroupForGroup("");
    setSelectedZipForGroup("");
    setSelectedNpiForGroup("");

    setGroupsFilter(false);
    setGroupLocationReload(false);
    setSelectedNpiForGroup("");
    setSelectedMilesForGroup("");
    setSearchButtonClickedForGroup(false);
  };
  const filterButtonValidationForFacility = () => {
    if (selectedGroup || selectedZip || selectedFacility || selectedNpi) {
      setFacilityFilter(true);
    } else {
      setFacilityFilter(false);
    }
  };
  const filterButtonValidationForGroup = () => {
    if (selectedGroupForGroup || selectedZipForGroup || selectedNpiForGroup) {
      setGroupsFilter(true);
    } else {
      setGroupsFilter(false);
    }
  };
  useEffect(() => {
    if (activeTab === "1") {
      //fetchGroupLocationeDetails();
      clearGroupsFilter();
      clearFacilityFilter();
      setSearchButtonClicked(false);
    } else if (activeTab === "2") {
      // fetchFacilityLocationeDetails();
      clearFacilityFilter();
      clearGroupsFilter();
      setSearchButtonClickedForGroup(false);
    }
  }, [activeTab]); // eslint-disable-line
  useEffect(() => {   
       window.scrollTo(0, 0);   
        }, []);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (selectedGroup || selectedZip || selectedFacility || selectedNpi) {
      filterButtonValidationForFacility();
    }
  }, [selectedGroup, selectedZip, selectedFacility, selectedNpi]); // eslint-disable-line

  useEffect(() => {
    if (selectedGroupForGroup || selectedZipForGroup || selectedNpiForGroup) {
      filterButtonValidationForGroup();
    }
  }, [selectedGroupForGroup, selectedZipForGroup, selectedNpi]); // eslint-disable-line

  // function MyComponent() {
  //   const map = useMapEvents({
  //     click: () => {
  //       map.locate();
  //     },
  //     locationfound: (location) => {
  //       console.log("location found:", location);
  //     },
  //   });
  //   return null;
  // }
  function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }

  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handleGroupNameChange = (value) => {
    setSelectedGroup(value);
  };
  const handleGroupNameChangeForGroup = (value) => {
    setSelectedGroupForGroup(value);
  };
  const handleFacilityNameChange = (value) => {
    setSelectedFacility(value);
  };

 
  const groupNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

   const facilityloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      const url = `${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`;
      if (selectedGroup !== "" && selectedGroup !== null) {
        // console.log(selectedGroup);
        var temp= AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-by-group-name/${inputValue}/${selectedGroup.value}`,
          {
            headers: {
              Authorization: API_TOKEN,
            },
          }
        )
          .then((response) => response.data.data)
        console.log(selectedGroup);
        return temp;
      } else {
        var tempr = AxiosInter.get(url,
          {
            headers: {
              Authorization: API_TOKEN,
            },
          }
        )
          .then((response) => response.data.data)
        return tempr;
      }
    }
  };

  const createZipOption = (data) => {
    var array = data;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.zipcode,
      };
    });
    setZipCodeOptions(options);
  };

  useEffect(() => {
    if (m_zipList) createZipOption(m_zipList);
  }, [m_zipList]); // eslint-disable-line
  // useEffect(() => {
  //   if (selectedGroup) createZipOption();
  // }, [selectedGroup]); // eslint-disable-line
  // useEffect(() => {
  //   if (selectedGroup) {
  //     debugger
  //     facilityloadOptions();
  //   }
  // }, [selectedGroup]);
  // let grp = selectedGroup;
  // useEffect(()=>{
  //   if(facilityLocationReload){
  //     setSearchButtonClicked(false);
  //   }
  // },[facilityLocationReload])

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>Locations</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>
      <Card>
        <CardBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
                style={{ cursor: "pointer" }}
              >
                Group Locations
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
                style={{ cursor: "pointer" }}
              >
                Facility Locations
              </NavLink>
            </NavItem>
          </Nav>
          <br />
          <>
            {activeTab === "1" ? (
              <>
                <Row>
                  <Col sm="3">
                    <Label>
                      Zip code
                      <span className="required">*</span>
                    </Label>
                    {/* <Input
                          id="ZipCode"
                          value={selectedZipForGroup ? selectedZipForGroup : ""}
                          placeholder="Enter the ZipCode"
                          onChange={(e) => {
                            setSelectedZipForGroup(e.target.value);
                          }}
                        /> */}
                    {/* <AsyncSelect
                      cacheOptions
                      isClearable
                      isSearchable
                      placeholder="Select Zipcode"
                      value={selectedZipForGroup}
                      onChange={(event) => setSelectedZipForGroup(event)}
                      loadOptions={(inputValue) => getAsyncOptions(inputValue)}
                    /> */}
                    <SelectVirtualized
                      name="zip"
                      options={zipCodeOptions}
                      value={selectedZipForGroup}
                      placeholder="Choose Zip Code"
                      isSearchable
                      isClearable
                      // value={selectedTimeZone}
                      onChange={(e) => {
                        //  console.log(e)
                        setSelectedZipForGroup(e);
                        setSearchButtonClickedForGroup(false);
                      }}
                    />
                  </Col>
                  <Col sm="2">
                    <Label for="Miles">
                      Enter Distance Range
                      <span className="required">*</span>
                    </Label>
                    <Input
                      id="Miles"
                      type="text"
                      value={selectedMilesForGroup}
                      placeholder="Enter distance in miles "
                      onChange={(e) => {
                        if (e.target.value.length >= 20) {
                          toastr.warning(
                            "Invalid Number",
                            "number length exceeded"
                          );
                        } else {
                          setSelectedMilesForGroup(e.target.value);
                        }
                        setSearchButtonClickedForGroup(false);
                      }}
                      style={{ margin: "0" }}
                    />
                  </Col>
                  <Col className="mrg-top-30">
                    <FontAwesomeIcon
                      icon={faSearch}
                      color={"#E12C2C"}
                      size={"2x"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        fetchGroupLocationeDetails();
                        setFacilityMapZoom(5);
                        setSearchButtonClickedForGroup(true);
                      }}
                      title="search locations"
                    />
                  </Col>
                </Row>
                {
                  // eslint-disable-next-line eqeqeq
                  selectedZipForGroup != "" &&
                  // eslint-disable-next-line eqeqeq
                  selectedMilesForGroup != "" &&
                  searchButtonClickedForGroup === true ? (
                    <Row>
                      <Col sm="3">
                        <Label>Group Name</Label>
                        <AsyncSelect
                          cacheOptions
                          isClearable
                          isSearchable
                          type="text"
                          name="groupName"
                          placeholder="Enter Group Name."
                          value={selectedGroupForGroup}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          validate={{
                            maxLength: { value: 120 },
                          }}
                          onInputChange={handleInputChange}
                          onChange={handleGroupNameChangeForGroup}
                          loadOptions={(inputValue) =>
                            groupNameloadOptions(inputValue)
                          }
                        />
                      </Col>
                      <br></br>

                      <Col sm="3">
                        <Label for="npi">NPI</Label>
                        <Input
                          id="npi"
                          type="number"
                          value={selectedNpiForGroup}
                          placeholder="Enter the NPI number "
                          onChange={(e) => {
                            if (e.target.value.length >= 20) {
                              toastr.warning(
                                "Invalid Number",
                                "number length exceeded"
                              );
                            } else {
                              setSelectedNpiForGroup(e.target.value);
                            }
                          }}
                          style={{ margin: "0" }}
                        />
                      </Col>

                      <br></br>
                      <Col sm="2">
                        <Button
                          className="icd-go-buttons"
                          onClick={() => {
                            // debugger;

                            setGroupLocationReload(groupLocationReload + 1);
                            setFacilityMapZoom(5);
                          }}
                          disabled={!groupsFilter ? true : false}
                          title={
                            !groupsFilter
                              ? "Choose at least one item to search "
                              : ""
                          }
                        >
                          Filter
                        </Button>
                        <Button
                          className="icd-reset-buttons"
                          onClick={() => {
                            clearGroupsFilter();
                            setFacilityMapZoom(5);
                            setGroupMapCenter([45.9896587, -94.6113288]);
                          }}
                          disabled={!groupsFilter ? true : false}
                          title={!groupsFilter ? "Nothing To Clear" : ""}
                        >
                          Reset
                        </Button>
                        <br></br>
                        <br></br>
                        <br></br>
                      </Col>
                      <MapContainer
                        //center={[30.97653406591645, -120.55680864667518]}

                        center={[45.9896587, -94.6113288]}
                        zoom={facilityMapZoom}
                        scrollWheelZoom={false}
                        className="facility-map"
                      >
                        <ChangeView
                          center={groupMapCenter}
                          zoom={facilityMapZoom}
                        />

                        {/* <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        /> */}
                        <TileLayer
                          url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                          attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          maxZoom={20}
                        />
                        {groupItems.map((groups) => (
                          <Marker
                            //key={groups.properties.name}
                            position={[
                              groups.geometry.coordinates[1],
                              groups.geometry.coordinates[0],
                            ]}
                            onClick={() => {
                              setGroupMarker(groups);
                            }}
                            icon={markeric}
                          >
                            <Popup
                              key={groups.properties.groupName}
                              position={[
                                groups.geometry.coordinates[1],
                                groups.geometry.coordinates[0],
                              ]}
                            >
                              <>
                                <h2>
                                  <b>{groups.properties.groupName}</b>
                                </h2>
                                <h5>
                                  <mark>ADDRESS:</mark>
                                  <b>{groups.properties.address}</b>
                                </h5>
                                <h5>
                                  <mark>ZIP CODE:</mark>
                                  <b>{groups.properties.zipcode}</b>
                                </h5>
                                <h5>
                                  <mark>NPI:</mark>
                                  <b>{groups.properties.npi}</b>
                                </h5>
                              </>
                            </Popup>
                          </Marker>
                        ))}
                      </MapContainer>
                    </Row>
                  ) : (
                    ""
                  )
                }
              </>
            ) : (
              <>
                <Row>
                  <Col sm="3">
                    <Label>
                      Zip code
                      <span className="required">*</span>
                    </Label>

                    <SelectVirtualized
                      name="zip"
                      options={zipCodeOptions}
                      value={selectedZip}
                      placeholder="Choose Zip Code"
                      isSearchable
                      isClearable
                      // value={selectedTimeZone}
                      onChange={(e) => {
                        //  console.log(e)
                        setSelectedZip(e);
                        setSearchButtonClicked(false);
                      }}
                    />
                  </Col>
                  <Col sm="2">
                    <Label for="Miles">
                      Enter Distance Range
                      <span className="required">*</span>
                    </Label>
                    <Input
                      id="Miles"
                      type="text"
                      value={selectedMiles}
                      placeholder="Enter distance in miles "
                      onChange={(e) => {
                        if (e.target.value.length >= 20) {
                          toastr.warning(
                            "Invalid Number",
                            "number length exceeded"
                          );
                        } else {
                          setSelectedMiles(e.target.value);
                        }
                        setSearchButtonClicked(false);
                      }}
                      style={{ margin: "0" }}
                    />
                  </Col>
                  <Col className="mrg-top-30">
                    <FontAwesomeIcon
                      icon={faSearch}
                      color={"#E12C2C"}
                      size={"2x"}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        fetchFacilityLocationeDetails();
                        setFacilityMapZoom(5);
                        setSearchButtonClicked(true);
                      }}
                      title="search locations"
                    />
                  </Col>
                </Row>
                {selectedZip &&
                selectedMiles !== "" &&
                searchButtonClicked === true ? (
                  <Row>
                    <Col sm="3" className="mt-3">
                      <Label>Group Name</Label>
                      {/* <Input
                      id="GroupName"
                      value={selectedGroup ? selectedGroup : ""}
                      placeholder="Enter the name"
                      onChange={(e) => {
                        setSelectedGroup(e.target.value);
                      }}
                    /> */}

                      <AsyncSelect
                        cacheOptions
                        isClearable
                        isSearchable
                        type="text"
                        name="GroupName"
                        placeholder="Enter Group Name."
                        value={selectedGroup}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        validate={{
                          maxLength: { value: 120 },
                        }}
                        onInputChange={handleInputChange}
                        onChange={handleGroupNameChange}
                        loadOptions={(inputValue) =>
                          groupNameloadOptions(inputValue)
                        }
                      />
                    </Col>
                    <br></br>
                    <Col sm="3" className="mt-3">
                      <Label>Facility Name</Label>
                      {/* <Input
                      id="FacilityName"
                      value={selectedFacility ? selectedFacility : ""}
                      placeholder="Enter the FacilityName"
                      onChange={(e) => {
                        setSelectedFacility(e.target.value);
                      }}
                    /> */}

                      <AsyncSelect
                        cacheOptions
                        isClearable
                        isSearchable
                        type="text"
                        name="FacilityName"
                        placeholder="Enter the Facility Name."
                        value={selectedFacility}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        validate={{
                          maxLength: { value: 120 },
                        }}
                        onInputChange={handleInputChange}
                        onChange={handleFacilityNameChange}
                        loadOptions={(inputValue) =>
                          facilityloadOptions(inputValue)
                        }
                      />
                    </Col>
                    <br></br>

                    <Col sm="3" className="mt-3">
                      <Label for="npi">NPI</Label>
                      <Input
                        id="npi"
                        type="number"
                        value={selectedNpi}
                        placeholder="Enter the NPI number "
                        onChange={(e) => {
                          if (e.target.value.length >= 20) {
                            toastr.warning(
                              "Invalid Number",
                              "number length exceeded"
                            );
                          } else {
                            setSelectedNpi(e.target.value);
                          }
                        }}
                        style={{ margin: "0" }}
                      />
                    </Col>
                    <br></br>
                    <Col sm="2" className="mt-3">
                      <Button
                        className="icd-go-buttons"
                        onClick={() => {
                          // debugger;

                          fetchFacilityLocationeDetails();
                          setFacilityMapZoom(5);
                          // setFacilityMapCenter([
                          //   facilitytems[0].geometry.coordinates[1],
                          //   facilitytems[0].geometry.coordinates[0],
                          // ]);
                        }}
                        disabled={!facilityFilter ? true : false}
                        title={
                          !facilityFilter
                            ? "Choose at least one item to search "
                            : ""
                        }
                      >
                        Filter
                      </Button>
                      <Button
                        className="icd-reset-buttons"
                        onClick={() => {
                          clearFacilityFilter();
                          setFacilityMapZoom(5);
                          setFacilityMapCenter([28.6138954, -77.2090057]);
                        }}
                        disabled={!facilityFilter ? true : false}
                        title={!facilityFilter ? "Nothing To Clear" : ""}
                      >
                        Reset
                      </Button>
                      <br></br>
                      <br></br>
                      <br></br>
                    </Col>
                    <br></br>
                    <MapContainer
                      //center={[30.97653406591645, -120.55680864667518]}
                      center={[51.00741325, -1.4291810058482342]}
                      // center = {facilityMapCenter}

                      // center={[facilitytems ? facilitytems[0].geometry.coordinates[1]:51.00741325,facilitytems ? facilitytems[0].geometry.coordinates[0]:-1.4291810058482342]}
                      zoom={facilityMapZoom}
                      scrollWheelZoom={false}
                      className="facility-map"
                    >
                      <ChangeView
                        center={facilityMapCenter}
                        zoom={facilityMapZoom}
                      />

                      {/* <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        /> */}
                      {/* <TileLayer
          url="https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default//EPSG3857_500m/{z}/{y}/{x}.jpeg"
          attribution="&copy; NASA Blue Marble, image service by OpenGeo"
          maxNativeZoom={8}
        /> */}
                      {/* <TileLayer
        url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        maxZoom={20}
      /> */}
                      {/* <TileLayer
        attribution="Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
        className="basemap"
        maxNativeZoom={19}
        maxZoom={19}
        subdomains={["clarity"]}
        url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      /> */}
                      <TileLayer
                        url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
                        attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        maxZoom={20}
                      />

                      {facilitytems.map((facility) => (
                        <Marker
                          //key={facility.properties.name}
                          position={[
                            facility.geometry.coordinates[1],
                            facility.geometry.coordinates[0],
                          ]}
                          onClick={() => {
                            setFacilityMarker(facility);
                          }}
                          icon={markeric}
                        >
                          <Popup
                            key={facility.properties.facilityName}
                            position={[
                              facility.geometry.coordinates[1],
                              facility.geometry.coordinates[0],
                            ]}
                          >
                            <>
                              <h2>{facility.properties.facilityName}</h2>
                              <h5>
                                <mark>ADDRESS:</mark>
                                <b>{facility.properties.address1}</b>
                              </h5>
                              <h5>
                                <mark>TYPE:</mark>
                                <b>{facility.properties.type}</b>
                              </h5>
                              <h5>
                                <mark>GroupName:</mark>
                                <b>{facility.properties.GroupName}</b>
                              </h5>
                              <h5>
                                <mark>ZIP CODE:</mark>
                                <b>{facility.properties.zipcode}</b>
                              </h5>
                              <h5>
                                <mark>NPI:</mark>
                                <b>{facility.properties.npi}</b>
                              </h5>
                            </>
                          </Popup>
                        </Marker>
                      ))}
                    </MapContainer>
                  </Row>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        </CardBody>
      </Card>
    </div>
  );
}

export default LocationMarker;
