
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  // eslint-disable-next-line no-unused-vars
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  // eslint-disable-next-line no-unused-vars
  Row,
  // eslint-disable-next-line no-unused-vars
  Col,
  // eslint-disable-next-line no-unused-vars
  Label,
  // eslint-disable-next-line no-unused-vars
  FormGroup,
  // eslint-disable-next-line no-unused-vars
  Input,
} from "reactstrap";
const ReferralOrderProcessing = (props) => {
  const {
    orderProcessModal,
    setOrderProcessModal,
    // eslint-disable-next-line no-unused-vars
    setReferralOrderResponceStatus,
    // eslint-disable-next-line no-unused-vars
    referralOrderResponseStatus,
    referralOrderIDList,
    // eslint-disable-next-line no-unused-vars
    setReferralOrderIDList,
    referralOrderResponseDetails,
    // eslint-disable-next-line no-unused-vars
    setReferralOrderResponceDetails,
  } = props;
  const [tablRow, setTableRow] = useState([{}]);
  const toggle = () => {
    setOrderProcessModal(!orderProcessModal);
  };
  const tref = useRef(null);

  // eslint-disable-next-line array-callback-return
  const test = () => {
    let a = [];
    let temp = [];
    // eslint-disable-next-line array-callback-return
    referralOrderIDList.map((item) => {
      // eslint-disable-next-line array-callback-return
      referralOrderResponseStatus.map((st) => {
        // eslint-disable-next-line array-callback-return
        referralOrderResponseDetails.map((dt) => {
          // eslint-disable-next-line eqeqeq
          if (item == st) {
            let res = temp.includes(item);
            if (res) {
              a = a.filter((obj) => {
                return obj.item !== item;
              });
              temp.push(item);
              a.push({ item: item, status: "Sucesss", details: dt });
            } else {
              temp.push(item);
              a.push({ item: item, status: "Sucesss", details: dt });
            }
          } else {
            let res = temp.includes(item);
            if (!res) {
              console.log("compare///", item, st);
              temp.push(item);
              a.push({ item: item, status: "processing", details: "" });
            }
          }
        });
      });
    });
    // console.log("terererrete", a);
    const ids = a.map((o) => o.item);
    const filtered = a.filter(
      ({ item }, index) => !ids.includes(item, index + 1)
    );
    setTableRow(filtered);
  };
  useEffect(() => {
    test();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralOrderIDList, referralOrderResponseStatus]);
  useEffect(() => {
    console.log(tablRow);
  }, [tablRow]);
  return (
    <div>
      <Modal
        id="OrderProcessingmodal"
        isOpen={orderProcessModal}
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Processing orders{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          {/* <FormGroup>
            <Row>
              <Col>
                <Label>#########</Label>
              </Col>
            </Row>
          </FormGroup> */}
          <table>
            <thead>
              <tr>
                <th>Order Serial Number</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>

            <tbody>
              {referralOrderResponseStatus.length 
                ? tablRow.map((item) => (
                    <tr id={item} ref={tref}>
                      <td>{item.item}</td>
                      <td>{item.status ? item.status : "Processing"}</td>
                      <td>{item.details ? item.details : ""}</td>
                    </tr>
                  ))
                : referralOrderIDList.map((item) => (
                    <tr id={item} ref={tref}>
                      <td>{item}</td>
                      <td>Processing</td>
                      <td> </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter className="advanced-search-buconsoletton custom-modal-footer">
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ReferralOrderProcessing;
