import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'


const DeleteModalProviderSpecialityRank = (props) => {
  const {

    deleteModal,
    setDeleteModal,
    id,
    reload, setReload,
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const toggle = () => {
    setDeleteModal(!deleteModal);
  };

  const confirmation = () => {
   
    const updatedStatus = {
      psr_id: id,
      isDelete: 1
    };

    AxiosInter.post( `${API_ENDPOINT}/admin-api/provider-speciality-ranking-delete/`, updatedStatus,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
        if (res.status === 201) {
          setReload(reload+1)
          toggle()
          toastr.success('Successfully Deleted', 'record deleted successfully');
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={deleteModal}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Status</ModalHeader>
        <ModalBody>
            <div style={{'textAlign':'center'}}>
              Are you sure you want to delete?
            </div>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteModalProviderSpecialityRank;
