import React, { useState, useEffect } from "react";
import { Doughnut } from 'react-chartjs-2';
import { RefreshCw } from "react-feather";
import AxiosInter from './../../../AxiosInter.js';
import Cookie from 'js-cookie';

import {
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  Spinner
} from "reactstrap";

const DonutChart = () => {

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  
   const [patientCount, setPatientCount] = useState("");
    const [spinner, setSpinner] = useState(false)

  const data = {
    labels: ["Active Patients", "Inactive Patients"],
    datasets: [
      {
        data: [patientCount ? patientCount?.activePatient : 0, patientCount ? patientCount?.inactivePatient : 0],
        backgroundColor: [
          "#4CBB17",
          "#A9A9A9"
        ],
        borderColor: "transparent",
        count:[
          patientCount ? patientCount?.activePatient : 0,
          patientCount ? patientCount?.inactivePatient : 0
        ]
      }
    ]
  };

  const fetchPatientCountAPI = () => {
    setSpinner(true)
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-patient-count/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(function (response) {
        
        if(response.data){
           setPatientCount(response.data);
          }
          setSpinner(false)
      })
      // .catch(function (error) {
      //   setSpinner(false)
      //   setPatientCount("");
      // });
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    showTooltips: true,
    legend: {
      display: false,
      position: 'right',
    },
    cutoutPercentage: 75,
  };

  const plugins = [{
    beforeDraw: function(chart) {
     var width = chart.width,
         height = chart.height,
         ctx = chart.ctx;
         ctx.restore();
         var fontSize = 0.8799;
         ctx.font = fontSize + "em sans-serif";
         ctx.textBaseline = "middle";
         var activecount = chart.tooltip._data.datasets[0].count[0];
         var inactivecount = chart.tooltip._data.datasets[0].count[1];
         var str1 = ('Active Patients : ' + activecount);
         var str2 = ('Inactive Patient Count : ' + inactivecount); // eslint-disable-line
         var textarea = [str1],
         textX = Math.round((width - ctx.measureText(textarea).width) / 2),
         textY = height / 2;
         ctx.fillStyle = '#24174B';
         ctx.fillText(textarea, textX, textY);
         ctx.save();
    } 
  }]

  useEffect(() => { fetchPatientCountAPI(); }, []) // eslint-disable-line

  return (
    <Card className="flex-fill w-100 donut-card-patient ">
      <CardHeader>
        <div className="card-actions float-right">
          <span className="cursor-pointer mr-1">
            <RefreshCw
            onClick={() => {fetchPatientCountAPI()}}
            />
          </span>{" "}
        </div>
        <CardTitle tag="h5" className="mb-0">
          Total Patient Count
          <div className="separator" />
        </CardTitle>
      </CardHeader>
      <CardBody className="d-flex">
        <div className="align-self-center w-100">
          <div className="py-3">
            <div className="chart chart-xs">
            {spinner ? <Spinner /> : ""}
            <div className="donut-chart">
              <Doughnut data={data} options={options}  plugins={plugins}  width={200} height={200} />

            </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DonutChart;