import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader,Container, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import DatePicker from "react-datepicker";
import moment from 'moment';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';





const ImmunizationModal = (props) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");

    const [buttonBlankState,setButtonBlankState] = useState(false);
    const [date, setDate] = useState(moment().toDate());
    
   

    
    const {
        setModal,
        modal,
        modalState,
        setModalState,
        drugName,
        setDrugName,
        brandName,
        setBrandName,
        scientificName,
        setScientificName,
        genericName,
        setGenericName,
        genericNameCode,
        setGenericNameCode,
        notes,
        setNotes,
        dosage,
        setDosage,
        unitOfMeasure,
        setUnitOfMeasure,
        refillPointivity,
        setRefillPointivity,
        expiryDate,
        setExpiryDate,
        code,
        setCode,
        reload,
        setReload,
        immunizationId,
        fetchImmunizationDrugNamesList,
        fetchImmunizationDrugGenericNamesList
       
        
    } = props

    

    const toggle = () => {
        setModal(!modal)
       
    }

    const getDate =(date) => {

        setDate(date);
    }



    const editImmunizationDetails = () => {
        
        const params =
        {
            "immunization_id":immunizationId ? immunizationId:"",
            "immunization_brand_name": brandName ? brandName : "" ,
            "immunization_code": code ? code : "" ,
            "immunization_dosage": dosage ? dosage : "",
            "immunization_drug_name": drugName ? drugName : "",
            "immunization_drug_generic_name": genericName ? genericName : "",
            "immunization_drug_generic_name_code": genericNameCode ? genericNameCode : "",
            "immunization_notes": notes ? notes : "",
            "immunization_refill_pointivity": refillPointivity ? refillPointivity : "",
            "immunization_scientific_name": scientificName ? scientificName : "",
            "immunization_unit_of_measure": unitOfMeasure ? unitOfMeasure : "",
            "immunization_expiry_date": expiryDate ? expiryDate : ""
        }

 
        AxiosInter.post(`${API_ENDPOINT}/admin-api/edit-immunization-details/`,params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
          .then(response => {
            setReload(reload + 1)
            toastr.success("Success","Immunization details updated");
            fetchImmunizationDrugNamesList();
            fetchImmunizationDrugGenericNamesList();
            toggle();

            
            
          })
          .catch(error => {
            toastr.error("Error",'Something went wrong !')
           
          });

    }

    const addNewImmunizationDetails = () => {
        const params =
        {
            "immunization_brand_name": brandName ? brandName : "" ,
            "immunization_code": code ? code : "" ,
            "immunization_dosage": dosage ? dosage : "",
            "immunization_drug_name": drugName ? drugName : "",
            "immunization_drug_generic_name": genericName ? genericName : "",
            "immunization_drug_generic_name_code": genericNameCode ? genericNameCode : "",
            "immunization_notes": notes ? notes : "",
            "immunization_refill_pointivity": refillPointivity ? refillPointivity : "",
            "immunization_scientific_name": scientificName ? scientificName : "",
            "immunization_unit_of_measure": unitOfMeasure ? unitOfMeasure : "",
            "immunization_expiry_date": date ? date : ""
        }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/add-new-immunization-details/`,params,{
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        fetchImmunizationDrugNamesList();
        fetchImmunizationDrugGenericNamesList();
        setReload(reload + 1)
        toastr.success("Success","Immunization details added");
        window.location.reload();
        toggle();

        
        
      })
      .catch(error => {
        if (error.response.data.message) {
            // toastr.error("Error",error.response.data.message)
            toastr.error("Failed", "Maybe you are adding a duplicate entry")
        } 
        else {
            toastr.error("Error","Something went wrong !")
        }  
      
       
      });


    }

    const validate = () => {

        if (code && genericName && genericNameCode) {
            setButtonBlankState(false)
        }
        else {
            setButtonBlankState(true)
        }
      }



   


    useEffect(() => {
        if (!modal) {
           setModal(false);
           setModalState('initial')
           setDrugName("");
           setCode("");
           setBrandName("");
           setScientificName("");
           setGenericName("");
           setGenericNameCode("");
           setNotes("");
           setDosage("");
           setUnitOfMeasure("");
           setRefillPointivity("");
           setDate("");
           setExpiryDate("");
   
         
        };

    }, [modal]) // eslint-disable-line


 


  useEffect(() => {
  
        validate();
    

}, [code,genericName,genericNameCode]) // eslint-disable-line




    
    return (
        <div>
            <Modal isOpen={modal} size="lg" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>{modalState === "add"? "New Immunization":"Edit Immunization"} </ModalHeader>
                <ModalBody className="overflow-modal">
                <Container fluid>
                    <FormGroup>
                        <Row>
                            <Col sm={6}>
                                <Label for="drug_name">Drug Name</Label>
                                <Input
                                    id="drug_name"
                                    type = "text"
                                    placeholder="Drug Name"
                                    value={drugName}
                                    onChange={(e) => {
                                        setDrugName(e.target.value)

                                    }}
                                />
                            </Col>
                     
                        <Col sm={6}>
                                <Label for="code">Code<span className="required">*</span></Label>
                                <Input
                                    id="code"
                                    type = "text"
                                    placeholder="Code"
                                    value={code}
                                    onChange={(e) => {
                                        setCode(e.target.value)

                                    }}
                                />
                            </Col>

                        </Row><br/>
                        <Row>
                        <Col sm={6}>
                                <Label for="brandName">Brand Name</Label>
                                <Input
                                    id="brandName"
                                    type = "text"
                                    placeholder="Brand Name"
                                    value={brandName}
                                    onChange={(e) => {
                                        setBrandName(e.target.value)

                                    }}
                                />
                            </Col>
                    
                        <Col sm={6}>
                                <Label for="scientificName">Scientific Name</Label>
                                <Input
                                    id="scientificName"
                                    type = "text"
                                    placeholder="Scientific Name"
                                    value={scientificName}
                                    onChange={(e) => {
                                        setScientificName(e.target.value)

                                    }}
                                />
                            </Col>
                        </Row><br/>
                        <Row>
                        <Col sm={6}>
                                <Label for="genericName">Generic Name<span className="required">*</span></Label>
                                <Input
                                    id="genericName"
                                    type = "text"
                                    placeholder="Generic Name"
                                    value={genericName}
                                    onChange={(e) => {
                                        setGenericName(e.target.value)

                                    }}
                                />
                            </Col>
                     
                        <Col sm={6}>
                                <Label for="genericNameCode">Generic Name Code<span className="required">*</span></Label>
                                <Input
                                    id="genericNameCode"
                                    type = "text"
                                    placeholder="Generic Name Code"
                                    value={genericNameCode}
                                    onChange={(e) => {
                                        setGenericNameCode(e.target.value)

                                    }}
                                />
                            </Col>
                        </Row><br/>
                        <Row>
                        <Col sm={6}>
                                <Label for="notes">Notes</Label>
                                <Input
                                    id="notes"
                                    type = "text"
                                    placeholder="Notes"
                                    value={notes}
                                    onChange={(e) => {
                                        setNotes(e.target.value)

                                    }}
                                />
                            </Col>
                     
                        <Col sm={6}>
                                <Label for="dosage">Dosage</Label>
                                <Input
                                    id="dosage"
                                    type = "text"
                                    placeholder="Dosage"
                                    value={dosage}
                                    onChange={(e) => {
                                        setDosage(e.target.value)

                                    }}
                                />
                            </Col>
                        </Row><br/>
                        <Row>
                        <Col sm={4}>
                                <Label for="unitOfMeasure">Unit of Measure</Label>
                                <Input
                                    id="unitOfMeasure"
                                    type = "text"
                                    placeholder="Unit of Measure"
                                    value={unitOfMeasure}
                                    onChange={(e) => {
                                        setUnitOfMeasure(e.target.value)

                                    }}
                                />
                            </Col>
                      
                        <Col sm={4}>
                                <Label for="refillPointivity">Refill Pointivity</Label>
                                <Input
                                    id="refillPointivity"
                                    type = "text"
                                    placeholder="Unit of Measure"
                                    value={refillPointivity}
                                    onChange={(e) => {
                                        setRefillPointivity(e.target.value)

                                    }}
                                />
                        </Col>
                    {modalState === "edit"?
                    <>
                        <Col sm={4}>
                                   
                                   <Label for="expiryDate">Expiry Date</Label>
                                   <div className="sign-up-dob-input">
                                   <DatePicker
                                     className="form-control"
                                     dateFormat="dd MMM YYY"
                                     selected = {expiryDate ? moment(expiryDate).toDate():""}
                                     minDate={moment().toDate()}
                                     onSelect={(date) => 
                                       setExpiryDate(date)
                                    }
                                     onChange={(date) => {
                                        setExpiryDate("")
                                        
                                      }}
                                     showYearDropdown
                                     showMonthDropdown
                                   />
                                   
                                   </div>
                                  
                               </Col>
                    </>:<>
                    <Col sm={4}>
                    <Label for="expiryDate">Expiry Date</Label> 
                                        <div className="sign-up-dob-input">
                                        <DatePicker
                                          className="form-control"
                                          dateFormat="dd MMM yyyy"
                                          selected = {date}
                                          minDate={moment().toDate()}
                                          onChange={date => {  
                                            getDate(date)
                                           }}
                                          showYearDropdown
                                          showMonthDropdown
                                        />
                                        
                                        </div>
                </Col>
                    </>}

                            
                        </Row><br/>

                    </FormGroup>
                    </Container>

                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button
                  
                        onClick={() => {
                          modalState === 'edit' ? editImmunizationDetails():addNewImmunizationDetails()
                        }}
                    disabled = {buttonBlankState}
                    title= {buttonBlankState ? "Please fill in all mandatory fields":(!buttonBlankState && modalState)?"Click to save":"Click to update"}

                    >{modalState === 'edit' ?'Update':'Add'}</Button>{' '}

                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ImmunizationModal;