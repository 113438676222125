import React, { useState, useEffect } from "react";
import ToggleButton from "react-toggle-button";

import {
  Button,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Cookie from 'js-cookie';
window.Buffer = window.Buffer || require("buffer").Buffer;

const GroupPreferenceModal = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const EQUIPO_APP_URL_LINK = process.env.REACT_APP_EQUIPO_APP_URL_LINK;


  const {
    setGroupEditModal,
    groupEditModal,
    groupId,
    setReload,
    reload,
    editGroupName,
    setGroupId,
    editInboundFax,
    setEditInboundFax,
    count,//eslint-disable-line
    setCount//eslint-disable-line
  } = props;

  const [updateButtonBlankState, setUpdateButtonBlankState] = useState(false); //eslint-disable-line
  const [spinner, setSpinner] = useState(false);
  const [ssoIntegrationToggle, setSsoIntegrationToggle] = useState(false);
  const [inboundFaxToggle, setInboundFaxToggle] = useState(false);
  const [pcpAdmission, setPcpAdmission] = useState(false);
  const [notificationAdmission, setNotificationAdmission] = useState(false);
  const [ssoLoginToggle, setSsoLoginToggle] = useState(false);
  const [exclusivityLink, setExclusivityLink] = useState("");
  const [isEnableSsoIntegration, setIsEnableSsoIntegration] = useState("");//eslint-disable-line
  const [isCopied, setIsCopied] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [cModal, setCModal] = useState(false);
  const [fNumberList, setFNumberList] = useState([])
  const [portal, setPortal] = useState()
  const [url, setUrl] = useState();
  const [selectedPortal, setSelectedPortal] = useState();
  const [urlId, setUrlId] = useState(undefined)
  const [copiedStatus, setCopiedStatus] = useState({
    web_url: false,
    api_url: false,
    app_store_url: false,
    play_store_url: false,
    web_socket_api_url: false
  });

  const toggle = () => {
    setGroupEditModal(!groupEditModal);
    setUrlId(undefined)
    setSsoLoginToggle(!ssoLoginToggle);
    setSsoIntegrationToggle(false)
  };
  const toggleForConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
    // setSsoLoginToggle(false)
  };
  const toggleForCModal = () => {
    // setCount(1)
    setCModal(false);
    // setSsoLoginToggle(!ssoLoginToggle)
  };
  // const toggleForCModal = () => {
  //   setCModal(!cModal);
  //   setSsoLoginToggle(true)
  // };

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const onCopyUrl = (field) => {
    setCopiedStatus({ ...copiedStatus, [field]: true });
    setTimeout(() => {
      setCopiedStatus({ ...copiedStatus, [field]: false });
    }, 1000); // Reset the copied status after 2 seconds
  };

  const modalPopupFunction = () => {
    if (ssoLoginToggle === true || ssoLoginToggle === 1) {
      setCModal(!cModal)

    }
    else if (ssoLoginToggle === false || ssoLoginToggle === 0) {

      setConfirmationModal(!confirmationModal)
    }
  };

  const updateSsoIntegration = () => {

    setSpinner(true);
    const updateParameters = {
      group_id: groupId ? groupId : "",
      isEnableSsoIntegration: ssoIntegrationToggle ? 1 : 0,
      isEnableSsoLogin: (ssoLoginToggle === true) || (ssoLoginToggle === 1) ? 1 : 0,
      isEnableInboundFax: (inboundFaxToggle === true) || (inboundFaxToggle === 1) ? 1 : 0

    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-sso-integration/`,
        updateParameters,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          toggle();
          setReload(reload + 1);
          toastr.success((ssoIntegrationToggle === true || ssoIntegrationToggle === 1) && (ssoLoginToggle === false || ssoLoginToggle === 0) ? "Changes updated successfully" : "Changes updated successfully");
          setSpinner(false);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };
  const updateLoginViaSso = () => {

    setSpinner(true);
    const updateParameters = {

      group_id: groupId ? groupId : "",
      isEnableSsoLogin: ssoLoginToggle ? 1 : 0,
      isEnableSsoIntegration: ssoIntegrationToggle ? 1 : 0,
      notifyPCPonAdmission: pcpAdmission ? 1 : 0,
      receiveNotificationOnAdmission: notificationAdmission ? 1 : 0,

    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-login-via-sso/`,
        updateParameters,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          toggle();
          setReload(reload + 1);
          toastr.success("Success", "Group preferences saved");
          setSpinner(false);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };
  const updateSsoForGroup = () => {

    setSpinner(true);
    const updateParameters = {

      group_id: groupId ? groupId : "",
      isSsoEnabled: ssoLoginToggle ? 1 : 0,

    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-sso-for-group/`,
        updateParameters,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          toggle();
          setReload(reload + 1);
          // toastr.success("Success", "Group preferences saved");
          setSpinner(false);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };
  const getSsoToggleValues = () => {
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/get-sso-toggle-values/${groupId}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        console.log(response.data.data);

        if (response.data.data.length > 0) {
          // console.log("isSsoEnabled",response.data.data[0]['isSsoEnabled'])
          setSsoIntegrationToggle(response.data.data[0]['isEnableSsoIntegration'])
          setSsoLoginToggle(response.data.data[0]['isEnableSsoLogin'])
          setInboundFaxToggle(response.data.data[0]['isEnableInboundFax'])
          setPcpAdmission(response.data.data[0]['notifyPCPonAdmission'])
          setNotificationAdmission(response.data.data[0]['receiveNotificationOnAdmission'])
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong!");
      });
  };

  useEffect(() => {
    loadPatientPortalOptions()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!groupEditModal) {
      setSelectedPortal(null)
      setUrl(null)
    }
  }, [groupEditModal])

  useEffect(() => {
    if (groupEditModal) {
      const params = {
        group_id: groupId,
        portal_id: null
      }
      portalUrlLists(params)
    }

  }, [groupEditModal]) // eslint-disable-line

  useEffect(() => {
    if (selectedPortal) {
      const params = {
        group_id: null,
        portal_id: selectedPortal?.value
      }
      portalUrlLists(params)
    }
  }, [selectedPortal]) // eslint-disable-line

  const loadPatientPortalOptions = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/patient-portals/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => {
        setPortal(response?.data)
      })
  }

  const portalUrlLists = (params) => {

    AxiosInter.post(`${API_ENDPOINT}/admin-api/patient-group-portals/`, params, {
      headers: {
        Authorization: API_TOKEN,
      }
    }).then((response) => {
      let urlIds = response?.data[0]?.id
      let portalName = response?.data[0]?.portal_name
      let portalValue = response?.data[0]?.portal_ID
      setUrl(response?.data[0])
      if (urlIds != undefined) { // eslint-disable-line eqeqeq
        setSelectedPortal(portalValue ? { value: portalValue, label: portalName } : null)
        setUrlId(urlIds)
      }
    })
  }

  const updatePortals = async () => {
    try {
      const params = {
        portal_ID: selectedPortal?.value,
      };
      if (urlId !== undefined) {
        const response = await AxiosInter.put(  // eslint-disable-line
          `${API_ENDPOINT}/admin-api/patient-group-portals/update/${urlId}/`,
          params,
          {
            headers: {
              Authorization: API_TOKEN,
            },
          }
        );
      } else {
        const paramss = {
          portal_ID: selectedPortal?.value,
          groupID: groupId
        };
        const response = await AxiosInter.post(`${API_ENDPOINT}/admin-api/portal-GroupMapping/create/`, paramss, { // eslint-disable-line
          headers: {
            Authorization: API_TOKEN,
          },
        })
      }

    } catch (error) {
      toastr.error("Patient Portal Mapping Failed");
    }
  };

  useEffect(() => {
    if (groupEditModal)
      getSsoToggleValues()
  }, [groupEditModal])//eslint-disable-line

  useEffect(() => {
    if (groupEditModal) {
      setSpinner(true);
      // Simulate an async operation
      setTimeout(() => {
        setSpinner(false);
      }, 2000); // Adjust the timeout duration as needed
    }
  }, [groupEditModal]);

  const equipoEncode = (number) => {
    let dataProcess = (
      (((number + 512) * 33 - 89) * 2954 + 9874) *
      82
    ).toString();

    let firstPartNumber = dataProcess.slice(0, 4);
    let revNumber = dataProcess.split("").reverse().join("");
    let secondPartNumber = revNumber.slice(0, -4);
    try {
      let firstencode = Buffer.from(secondPartNumber, "binary").toString(
        "base64"
      );

      let comb = firstencode.concat(firstPartNumber);
      let array = [{ output: comb }];
      //  let seconden = Buffer.from(array[0].toString, "binary").toString("base64");
      //    console.log(seconden)

      // let jsonString = JSON.stringify({ ...array });
      let jsonString = JSON.stringify(array[0]);

      let secondencode = Buffer.from(jsonString, "binary").toString("base64");

      return secondencode;
    } catch (err) {
      console.log(err);
    }
  };
  const UrlGenerateForExclusivity = () => {
    let gID = groupId;

    if (gID === "undefined" || gID === "") {
      setGroupId(0);
    }
    if (
      gID > 0 &&
      (ssoIntegrationToggle === 1 ||
        ssoIntegrationToggle === true)
    ) {
      let encodedlink = `${EQUIPO_APP_URL_LINK}${equipoEncode(
        gID
      )}/metadata`;

      setExclusivityLink(encodedlink);
    }
  };
  const saveTwoApis = () => {
    if ((ssoIntegrationToggle === true) || (ssoIntegrationToggle === 1)) {
      if ((ssoLoginToggle === true) || (ssoLoginToggle === 1)) {
        updateSsoIntegration();
        setSsoLoginToggle(1);
        updateLoginViaSso();
        updateSsoForGroup();
      } else if ((ssoLoginToggle === false) || (ssoLoginToggle === 0)) {
        updateSsoIntegration();
        updateSsoForGroup();
        // setSsoLoginToggle(0);
        setSsoIntegrationToggle(1)
        updateLoginViaSso();
      }

    } else if ((ssoIntegrationToggle === false) || (ssoIntegrationToggle === 0)) {
      updateSsoIntegration();
      setSsoLoginToggle(0);
      updateLoginViaSso();
      updateSsoForGroup();


    }
  }
  useEffect(() => {
    if (
      ssoIntegrationToggle === true ||
      ssoIntegrationToggle === 1
    ) {
      UrlGenerateForExclusivity();
    }
  }, [ssoIntegrationToggle]); // eslint-disable-line


  // useEffect(() => {
  //   if (ssoLoginToggle) {
  //     setConfirmationModal(true)
  //   }
  // }, [ssoLoginToggle]); // eslint-disable-line

  // useEffect(() => {
  //   if (groupEditModal) {
  //     // setCModal(false)
  //     setCount(1)
  //     setCModal(false)
  //   }
  // }, [groupEditModal]); // eslint-disable-line

  // useEffect(() => {
  //   if (!ssoLoginToggle && count!==1) {
  //     setCModal(true)
  //   }
  // }, [ssoLoginToggle]); // eslint-disable-line

  const updateFaxForGroup = () => {

    setSpinner(true);
    const updateParameters = {

      group_id: groupId ? groupId : "",
      inboundFax: editInboundFax ? editInboundFax : "",

    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-fax-for-group/`,
        updateParameters,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          toggle();
          setReload(reload + 1);
          // toastr.success("Success", "Group preferences saved");
          setSpinner(false);
          getFaxNumberList();
        }
      })
      .catch((error) => {
        if (error.response) {
          toastr.error(error.response.data.message);
        } else {
          toastr.error('An error occurred while processing your request');
        }
        setSpinner(false);
      });
  };
  const getFaxNumberList = () => {

    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/faxnumber-list/${groupId}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {

        if (response.data.data.length > 0) {
          // console.log("isSsoEnabled",response.data.data[0]['isSsoEnabled'])
          setFNumberList(response.data.data)
        }
      })
      .catch((error) => {
        console.error("Error", "Something went wrong!");
      });
  };
  useEffect(() => {
    if (groupEditModal === true) {
      getFaxNumberList();
    }

  }, [groupEditModal]) // eslint-disable-line react-hooks/exhaustive-deps

  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  let duplicateEntryShown = false;

  const checkDuplicateInboundFax = debounce((sav = false) => {
    // check for duplicate entries of editInboundFax
    const duplicateEntry = fNumberList.find((item) => item === editInboundFax);
    if (duplicateEntry) {
      if (!duplicateEntryShown) {
        toastr.error("Duplicate entry found for fax number");
        duplicateEntryShown = true;
        setTimeout(() => {
          duplicateEntryShown = false;
        }, 3000);
      }
    } else {
      if (sav === true) {
        saveTwoApis();
        updateFaxForGroup();
      }
    }
  }, 0);


  return (
    <div>
      <Modal isOpen={groupEditModal} size="md">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Group Preference - {editGroupName}
        </ModalHeader>
        <ModalBody className="overflow-modal">
          {spinner ? <Spinner /> : (
            <Form>
              <FormGroup style={{ marginLeft: "4rem", marginTop: "2rem" }}>
                <Row >
                  <Col sm={6}>
                    <Label >
                      Enable SSO Integration :
                    </Label>
                  </Col>
                  <Col sm={4}>
                    <ToggleButton
                      inactiveLabel={"OFF"}
                      activeLabel={"ON"}
                      value={ssoIntegrationToggle}
                      onToggle={() => {
                        setSsoIntegrationToggle(!ssoIntegrationToggle)
                        setSsoLoginToggle(false)
                      }}
                    />

                  </Col>
                </Row><br />
                <Row style={{ marginLeft: "2rem" }}>
                  {
                    (ssoIntegrationToggle === true ||
                      ssoIntegrationToggle === 1) ? (
                      <div className="copycontainer d-flex">
                        <Col sm={12} style={{ marginLeft: "2rem" }}>
                          <Input
                            type="text"
                            value={exclusivityLink}
                            readOnly
                            onChange={(event) =>
                              setExclusivityLink(event.target.value)
                            }
                          />
                        </Col>
                        <Col className="float: right;display: flex;">
                          <CopyToClipboard text={exclusivityLink} onCopy={onCopyText}>
                            <div className="copy-area">
                              <FontAwesomeIcon icon={faCopy} title={"Copy URL"} color="#1061c4" style={{ "font-size": 24 }} />
                              <span
                                className={`copy-feedback ${isCopied ? "active" : ""
                                  }`}
                              >
                                Copied!
                              </span>
                            </div>
                          </CopyToClipboard>
                        </Col>
                      </div>
                    ) : (
                      ""
                    )}
                </Row><br />
                {ssoIntegrationToggle ?
                  <Row >
                    <Col sm={6}>
                      <Label >
                        Enable login via SSO only :
                      </Label>
                    </Col>
                    <Col sm={4}>
                      <ToggleButton
                        inactiveLabel={"OFF"}
                        activeLabel={"ON"}
                        value={ssoLoginToggle}
                        onClick={() => {
                          // setSsoLoginToggle(!ssoLoginToggle)
                          // setConfirmationModal(!confirmationModal)
                          // setCount(3)
                          modalPopupFunction()
                        }}
                      />

                    </Col><br></br><br></br>
                  </Row>
                  : ""}
                <div>
                  <Modal style={{ width: "30%" }} isOpen={confirmationModal}>
                    <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }}>Enable login via SSO confirmation</ModalHeader>
                    <ModalBody>
                      <div style={{ "text-align": "center" }}>
                        Are you sure you want to disable direct login of all the users in to the application?
                      </div>
                    </ModalBody>
                    <ModalFooter className="custom-modal-footer">
                      <Button
                        className="yes-button button-width"
                        onClick={() => {
                          setConfirmationModal(!confirmationModal);
                          setSsoLoginToggle(true);
                          // setCount(3)

                        }}>
                        Yes
                      </Button>{" "}
                      <Button
                        outline
                        className="no-button button-width"
                        onClick={toggleForConfirmationModal}>
                        No
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
                <div>
                  <Modal style={{ width: "30%" }} isOpen={cModal}>
                    <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }}>Disable login via SSO confirmation</ModalHeader>
                    <ModalBody>
                      <div style={{ "text-align": "center" }}>
                        Are you sure you want to enable direct login of all the users in to the application?
                      </div>
                    </ModalBody>
                    <ModalFooter className="custom-modal-footer">
                      <Button
                        className="yes-button button-width"
                        onClick={() => {
                          setCModal(!cModal);
                          setSsoLoginToggle(false);

                        }}>
                        Yes
                      </Button>{" "}
                      <Button
                        outline
                        className="no-button button-width"
                        onClick={toggleForCModal}>
                        No
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
                <Row >
                  <Col sm={6}>
                    <Label >
                      Enable Inbound Fax :
                    </Label>
                  </Col>
                  <Col sm={4}>
                    <ToggleButton
                      inactiveLabel={"OFF"}
                      activeLabel={"ON"}
                      value={inboundFaxToggle}
                      onToggle={() => {
                        setInboundFaxToggle(!inboundFaxToggle)
                      }}

                    />

                  </Col>
                </Row><br />
            
                {inboundFaxToggle ?
                  <Row >
                    <Col sm={6}>
                      <Label >
                        Enter Fax Number :
                      </Label>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="text"
                        placeholder="Enter fax number"
                        value={editInboundFax}
                        onChange={(event) =>
                          setEditInboundFax(event.target.value)
                        }
                        onBlur={checkDuplicateInboundFax}
                      />

                    </Col>
                  </Row>
                  : ""}<br />
                      <Row >
                  <Col sm={6}>
                    <Label >
                      Notify PCP on Admission :
                    </Label>
                  </Col>
                  <Col sm={4}>
                    <ToggleButton
                      inactiveLabel={"OFF"}
                      activeLabel={"ON"}
                      value={pcpAdmission}
                      onToggle={() => {
                        setPcpAdmission(!pcpAdmission)
                      }}

                    />

                  </Col>
                </Row><br />
                <Row >
                  <Col sm={6}>
                    <Label >
                      Receive Notification on Admission :
                    </Label>
                  </Col>
                  <Col sm={4}>
                    <ToggleButton
                      inactiveLabel={"OFF"}
                      activeLabel={"ON"}
                      value={notificationAdmission}
                      onToggle={() => {
                        setNotificationAdmission(!notificationAdmission)
                      }}

                    />

                  </Col>
                </Row><br />
                <Row>
                  <Col sm={6}>
                    <Label>Patient Portal:</Label>
                  </Col>
                  <Col sm={6}>
                    <Select
                      options={portal}
                      placeholder="Choose Portal"
                      value={selectedPortal}
                      onChange={(e) => {
                        setSelectedPortal(e)
                        setUrl({})
                      }}
                    />
                  </Col>
                </Row><br />
                <Row style={{ marginLeft: "2rem" }}>
                  <div className="copycontainer d-flex">
                    <Col sm={12} style={{ marginLeft: "2rem" }}>
                      <Label> Web Url</Label>
                      <Input
                        type="text"
                        value={url?.web_url}
                        disabled={true}
                        onChange={(e) => {
                          setUrl({ ...url, web_url: e.target.value })
                        }}
                      />
                    </Col>
                    <Col className="float: right;display: flex;">
                      <CopyToClipboard text={url?.web_url} onCopy={() => onCopyUrl('web_url')}>
                        <div className="copy-area">
                          <FontAwesomeIcon icon={faCopy} title={"Copy URL"} color="#1061c4" style={{ "font-size": 24, marginTop: '25px' }} />
                          <span className={`copy-feedback ${copiedStatus.web_url ? "active" : ""}`}>
                            Copied!
                          </span>
                        </div>
                      </CopyToClipboard>
                    </Col>
                  </div>
                </Row><br />
                <Row style={{ marginLeft: "2rem" }}>
                  <div className="copycontainer d-flex">
                    <Col sm={12} style={{ marginLeft: "2rem" }}>
                      <Label>Api Url</Label>
                      <Input
                        type="text"
                        disabled={true}
                        value={url?.api_url}
                        onChange={(e) => {
                          setUrl({ ...url, api_url: e.target.value })
                        }}
                      />
                    </Col>
                    <Col className="float: right;display: flex;">
                      <CopyToClipboard text={url?.api_url} onCopy={() => onCopyUrl('api_url')}>
                        <div className="copy-area">
                          <FontAwesomeIcon icon={faCopy} title={"Copy URL"} color="#1061c4" style={{ "font-size": 24, marginTop: '25px' }} />
                          <span className={`copy-feedback ${copiedStatus.api_url ? "active" : ""}`}>
                            Copied!
                          </span>
                        </div>
                      </CopyToClipboard>
                    </Col>
                  </div>
                </Row><br />
                <Row style={{ marginLeft: "2rem" }}>
                  <div className="copycontainer d-flex">
                    <Col sm={12} style={{ marginLeft: "2rem" }}>
                      <Label>App Store Url</Label>
                      <Input
                        type="text"
                        disabled={true}
                        value={url?.app_store_url || ''}
                        onChange={(e) => {
                          setUrl({ ...url, app_store_url: e.target.value })
                        }}
                      />
                    </Col>
                    <Col className="float: right;display: flex;">
                      <CopyToClipboard text={url?.app_store_url} onCopy={() => onCopyUrl('app_store_url')}>
                        <div className="copy-area">
                          <FontAwesomeIcon icon={faCopy} title={"Copy URL"} color="#1061c4" style={{ "font-size": 24, marginTop: '25px' }} />
                          <span className={`copy-feedback ${copiedStatus.app_store_url ? "active" : ""}`}>
                            Copied!
                          </span>
                        </div>
                      </CopyToClipboard>
                    </Col>
                  </div>
                </Row><br />
                <Row style={{ marginLeft: "2rem" }}>
                  <div className="copycontainer d-flex">
                    <Col sm={12} style={{ marginLeft: "2rem" }}>
                      <Label>Playstore Url</Label>
                      <Input
                        type="text"
                        disabled={true}
                        value={url?.play_store_url || ''}
                        onChange={(e) => {
                          setUrl({ ...url, play_store_url: e.target.value })
                        }}
                      />
                    </Col>
                    <Col className="float: right;display: flex;">
                      <CopyToClipboard text={url?.play_store_url} onCopy={() => onCopyUrl('play_store_url')}>
                        <div className="copy-area">
                          <FontAwesomeIcon icon={faCopy} title={"Copy URL"} color="#1061c4" style={{ "font-size": 24, marginTop: '25px' }} />
                          <span className={`copy-feedback ${copiedStatus.play_store_url ? "active" : ""}`}>
                            Copied!
                          </span>
                        </div>
                      </CopyToClipboard>
                    </Col>
                  </div>
                </Row><br />
                <Row style={{ marginLeft: "2rem" }}>
                  <div className="copycontainer d-flex">
                    <Col sm={12} style={{ marginLeft: "2rem" }}>
                      <Label>Web Socket Api Url</Label>
                      <Input
                        type="text"
                        disabled={true}
                        value={url?.web_socket_api_url}
                        onChange={(e) => {
                          setUrl({ ...url, web_socket_api_url: e.target.value })
                        }}
                      />
                    </Col>
                    <Col className="float: right;display: flex;">
                      <CopyToClipboard text={url?.web_socket_api_url} onCopy={() => onCopyUrl('web_socket_api_url')}>
                        <div className="copy-area">
                          <FontAwesomeIcon icon={faCopy} title={"Copy URL"} color="#1061c4" style={{ "font-size": 24, marginTop: '25px' }} />
                          <span className={`copy-feedback ${copiedStatus.web_socket_api_url ? "active" : ""}`}>
                            Copied!
                          </span>
                        </div>
                      </CopyToClipboard>
                    </Col>
                  </div>
                </Row>
              </FormGroup>
            </Form>
          )}
        </ModalBody>
        <ModalFooter className="advanced-search-button  custom-modal-footer">
          <Button
            onClick={() => {
              // ssoIntegrationToggle && ssoLoginToggle ? updateLoginViaSso() : updateSsoIntegration();
              const savi = true
              if(selectedPortal){

                checkDuplicateInboundFax(savi)
                updatePortals()
              }else{
                toastr.warning("Select Patient Portal");
              }
             
            }}
            disabled={updateButtonBlankState}
            title={updateButtonBlankState ? "" : ""}
          >
            Save
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default GroupPreferenceModal;
