import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader,Container, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';


const CPTCodeModal = (props) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    

    const [buttonBlankState,setButtonBlankState] = useState(false);  
    const {
        setModal,
        modal,
        modalState,
        editCptCode,
        setEditCptCode,
        editCptCategory,
        setEditCptCategory,
        editCptDescription,
        setEditCptDescription,
        editCptLongDescription,
        setEditCptLongDescription,
        setModalState,
        cptCategoryList,
        editCptId,
        setReloadCPTCodeTableData,
        
    } = props

    const toggle = () => {
        setModal(!modal)
    }

    const setInitialCategory = () => {
        cptCategoryList.map((data) => { // eslint-disable-line
           
            if (data.label === editCptCategory) {
                setEditCptCategory({ value: data.value, label: editCptCategory })
  
            }
        })
    }
     const editCptDetails = () => {
        const params =
        {
            "cpt_id":editCptId ? editCptId:"",
            "cpt_code":editCptCode ? editCptCode:"",
            "cpt_description":editCptDescription ? editCptDescription:"",
            "cpt_long_description":editCptLongDescription ? editCptLongDescription:"",
            "cpt_category_id":editCptCategory ? editCptCategory.value:""
           
            
        }
 
        AxiosInter.post(`${API_ENDPOINT}/admin-api/edit-cpt-details/`,params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
          .then(response => {
            setReloadCPTCodeTableData(true)
            toastr.success("Success","Updated successfully")
            toggle();

            
            
          })
          .catch(error => {
            toastr.error("Error",error.response.data.message)
           
          });

    }
    const addNewCpt = () => {
        const params =
        {
            "cpt_code": editCptCode ? editCptCode:"",
            "cpt_category_id":editCptCategory ? editCptCategory.value:"",
            "cpt_description":editCptDescription ? editCptDescription:"",
            "cpt_long_description":editCptLongDescription ? editCptLongDescription:""
        }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/add-cpt-details/`,params,{
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        setReloadCPTCodeTableData(true)
        toastr.success("Success","New CPT code added")
        toggle();

        
        
      })
      .catch(error => {
        if (error.response.data.message) {
            toastr.error("Error",error.response.data.message)
        } 
        else {
            toastr.error("Error","Something went wrong !")
        }  
      
       
      });


    }

    const validate = () => {

        if (
            editCptCode
    
        ) {
            setButtonBlankState(false)
        }
        else {
            setButtonBlankState(true)
        }
      }






    useEffect(() => {
        if (!modal) {
           setModal(false);
           setModalState("initial");
           setEditCptCode("");
           setEditCptDescription("");
           setEditCptLongDescription("");
           setEditCptCategory("");
        };

    }, [modal]) // eslint-disable-line

    // useEffect(() => {
    //     if (modal && cptCategoryList ) {
    //         setCPTCategoryDropdown(cptCategoryList)
    //     };

    // }, [modal,cptCategoryList]) // eslint-disable-line


  useEffect(() => {
    if (cptCategoryList && editCptCategory) {
        setInitialCategory();
    }

}, [cptCategoryList,editCptCategory]) // eslint-disable-line


  useEffect(() => {
  
        validate();
    

}, [editCptCode]) // eslint-disable-line

    return (
        <div>
            <Modal isOpen={modal} size="md">
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>{modalState === "add"? "New CPT Code":"Edit CPT Code"} </ModalHeader>
                <ModalBody className="overflow-modal">
                <Container fluid>
                    <FormGroup>
                        <Row>
                            <Col sm={12}>
                                <Label for="cpt_code">Code<span className="required">*</span></Label>
                                <Input
                                    id="cpt_code"
                                    placeholder="CPT Code"
                                    value={editCptCode}
                                    onChange={(e) => {
                                        setEditCptCode(e.target.value)

                                    }}
                                />
                            </Col>
                        </Row><br/>
                        <Row>
                            <Col sm={12}>
                            <Label for="description">Description</Label>
                                    <Input
                                        id="description"
                                        type="textarea"
                                        rows={4}
                                        cols={2}
                                        placeholder="Description"
                                        value={editCptDescription}
                                        onChange={(e) => {
                                            setEditCptDescription(e.target.value)
                                        }}

                                    />

                            </Col>

                        </Row><br/>
                        <Row>
                            <Col sm={12}>
                            <Label for="long_description">Long Description</Label>
                                    <Input
                                        id="long_description"
                                        type="textarea"
                                        rows={4}
                                        cols={2}
                                        placeholder="Long Description"
                                        value={editCptLongDescription}
                                        onChange={(e) => {
                                            setEditCptLongDescription(e.target.value)
                                        }}

                                    />

                            </Col>

                        </Row><br/>
                        <Row>
                            <Col sm={12}>
                                <Label for="cpt_category">CPT Category</Label>
                                <Select
                                    name="cpt_category"
                                    options={
                                        cptCategoryList
                                    }
                                    value={editCptCategory}
                                    placeholder="Choose Category"
                                    isSearchable
                                    isClearable
                                    onChange={(e) => {
                                      setEditCptCategory(e)
                                       
                                    }}
                                />

                            </Col>
                        </Row><br/>

                    </FormGroup>
                    </Container>

                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button
                  
                        onClick={() => {
                          modalState === 'edit' ? editCptDetails():addNewCpt()
                        }}
                    disabled = {buttonBlankState}
                    title= {buttonBlankState ? "Please fill in all mandatory fields":(!buttonBlankState && modalState)?"Click to save":"Click to update"}

                    >{modalState === 'edit' ?'Update':'Add'}</Button>{' '}

                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default CPTCodeModal;