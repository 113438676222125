import React, { createContext, useState, useEffect } from 'react'
import AxiosInter from './../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
export const GlobalState = createContext()

export const GlobalStateProvider = (props) => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");
    const [zipListLoaded, setZipListLoading] = useState(false);
    const [groupListLoading, setGroupListLoading] = useState(false);
    const [facilityListLoading, setFacilityListLoading] = useState(false);
    const [groupNamesList, setGroupNamesList] = useState("");
    const [icdCodeList, setIcdCodeList] = useState([]);
    const [cptCodesList, setCptCodesList] = useState([]);
    const [cptDescription, setCptDescription] = useState([]);
    const [cptCategoryList, setCptCategoryList] = useState([]);
    const [groupNamesForDropDown, setGroupNamesForDropDown] = useState([]);
    const [groupNamesForDropDownLoading, setGroupNamesForDropDownLoading] = useState(false);
    const [RegionNames, setRegionNames] = useState([]);
    const [RegionNamesReload, setRegionNamesReload] = useState(0);
    const [subscriptionNamesList, setSubscriptionNamesList] = useState([]);
    const [subscriptionNamesListLoading, setSubscriptionNamesListLoading] = useState(false);
    const [subscriptionNamesListReload, setSubscriptionNamesListReload] = useState(0);
    const [appUsersNames, setAppUsersNames] = useState([]);
    const [appUsersNamesListLoading, setAppUsersNamesListLoading] = useState(false);
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [timeZoneListLoading, setTimeZoneListLoading] = useState(false);
    const [zipCodeOptions, setZipCodeOptions] = useState([]);
    const [zipCodeOptionsLoading, setZipCodeOptionsLoading] = useState(false);
    const [insuranceGroupNames, setInsuranceGroupNames] = useState([]);
    const [insuranceGroupNamesLoading, setInsuranceGroupNamesLoading] = useState(false);
    const [insuranceCompanyPlanTypeNames, setInsuranceCompanyPlanTypeNames] = useState([]);
    const [insuranceCompanyPlanTypeNamesLoading, setInsuranceCompanyPlanTypeNamesLoading] = useState(false);
    const [insuranceCompanyNames, setInsuranceCompanyNames] = useState([]);
    const [insuranceCompanyNamesLoading, setInsuranceCompanyNamesLoading] = useState(false);
    const [immunizationDrugNames, setImmunizationDrugNames] = useState([]);
    const [immunizationDrugNamesLoading, setImmunizationDrugNamesLoading] = useState(false);
    const [immunizationDrugGenericNames, setImmunizationDrugGenericNames] = useState([]);
    const [immunizationDrugGenericNamesLoading, setImmunizationDrugGenericNamesLoading] = useState(false);
    const [icdPackageNames, setIcdPackageNames] = useState([]);
    const [icdPackageNamesListLoading, setIcdPackageNamesListLoading] = useState(false);
    const [icdPackageNamesReload, setIcdPackageNamesReload] = useState(0)
    const [cptPackageNames, setCptPackageNames] = useState([]);
    const [cptPackageNamesListLoading, setCptPackageNamesListLoading] = useState(false);
    const [cptPackageNamesReload, setCptPackageNamesReload] = useState(0)
    const [facilityAccessType, setFacilityAccessType] = useState([])

    const fetchGroupNames = () => {
        setGroupNamesForDropDownLoading(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-groups-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response?.data.data.length > 0) {
                    setGroupNamesForDropDown(response.data.data);
                    setGroupNamesForDropDownLoading(false);
                }
            })
        // .catch(error => {
        //     
        //     if (error) {
        //         new_token= Cookie.get("access_token_admin")  
        //         AxiosInter.get(`${API_ENDPOINT}/admin-api/get-groups-list/`, {
        //             headers: {
        //               Authorization: new_token,
        //             },
        //           })
        //       } else {
        //         setGroupNamesForDropDownLoading(false);
        //         toastr.error(
        //           "Failed",
        //           "Something went wrong"
        //         );
        //       }
        //     // toastr.error('Error','Something went wrong!')

        // });
    };

    const fetchZipCodeOptions = () => {
        setZipCodeOptionsLoading(true);

        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m_zip-code-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {

                if (response?.data?.data?.length > 0) {
                    setZipCodeOptions(response.data.data);
                    setZipCodeOptionsLoading(false);
                }
            })
        //   .catch(error => {
        //     if (error) {
        //         new_token= Cookie.get("access_token_admin")  
        //         AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m_zip-code-list/`, {
        //             headers: {
        //               Authorization: new_token,
        //             },
        //           })
        //       } else {
        //         setZipCodeOptionsLoading(false);
        //         toastr.error(
        //           "Failed",
        //           "Something went wrong"
        //         );
        //       }
        //     // toastr.error('Error','Something went wrong!')

        //   });
    };

    //////// Done ////////////////////
    const getAllSubscriptionPlans = () => {
        setSubscriptionNamesListLoading(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-subscription-plans-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.data.data.length > 0) {
                    setSubscriptionNamesList(response.data.data)
                    setSubscriptionNamesListLoading(false);
                }
            })
        //   .catch(error => {
        //       if (error.response.status === 401) {
        //         new_token= Cookie.get("access_token_admin")  
        //         AxiosInter.get(`${API_ENDPOINT}/admin-api/get-subscription-plans-list/`, {
        //             headers: {
        //               Authorization: new_token,
        //             },
        //           })
        //       } else {
        //         setSubscriptionNamesListLoading(false);
        //         toastr.error(
        //           "Failed",
        //           "Something went wrong"
        //         );
        //       }
        //     // toastr.error('Error','Something went wrong!')

        //   });
    };

    //////// Done ////////////////////
    const getTimeZoneList = () => {
        setTimeZoneListLoading(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-timezone-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.data.data.length > 0) {
                    setTimeZoneList(response.data.data)
                    setTimeZoneListLoading(false);
                }
            })
        //   .catch(error => {
        //     if (error.response.status === 401) {
        //         new_token= Cookie.get("access_token_admin")  
        //         AxiosInter.get(`${API_ENDPOINT}/admin-api/get-timezone-list/`, {
        //             headers: {
        //               Authorization: new_token,
        //             },
        //           })
        //       } else {
        //         setTimeZoneListLoading(false);
        //         toastr.error(
        //           "Failed",
        //           "Something went wrong"
        //         );
        //       }
        //     // toastr.error('Error','Something went wrong!')

        //   });
    };

    //////// Done ////////////////////
    const getAppUsersListForDropdown = () => {
        setAppUsersNamesListLoading(true);

        AxiosInter.get(`${API_ENDPOINT}/admin-api/app-users-names-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.data.data.length > 0) {
                    setAppUsersNames(response.data.data)
                    setAppUsersNamesListLoading(false);

                }
            })
            .catch(error => {
                setAppUsersNamesListLoading(false);
                toastr.error('Error', 'Something went wrong!')

            });
    };

    const FetchM_ZipListAPICall = async () => {
        setZipListLoading(true)

        await AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m_zip-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                if (response) {
                    setM_zipList(response?.data)
                }
                setZipListLoading(false)

            })
        // .catch(function (error) {
        //     if (error) {

        //         new_token= Cookie.get("access_token_admin")  
        //         AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m_zip-list/`, {
        //             headers: {
        //               Authorization: new_token,
        //             },
        //           })
        //         } else {
        //         setZipListLoading(false)
        //         toastr.error(
        //           "Failed",
        //           "Something went wrong"
        //         );
        //       }
        // });
    }

    //////// Done ////////////////////
    const FetchCountryListAPICall = async () => {
        await AxiosInter.get(`${API_ENDPOINT}/admin-api/get-country-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                setCountryCodeList(response.data)
            })
            .catch(function (error) {

            });
    }

    //////// Done ////////////////////
    const getICDCodesList = async () => {
        await AxiosInter.get(`${API_ENDPOINT}/admin-api/get-icd-code-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                setIcdCodeList(response.data.data)


            })
            .catch(function (error) {


            });
    }

    //////// Done ////////////////////
    const getCPTCodesList = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-cpt-code-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                setCptCodesList(response.data.data)


            })
            .catch(function (error) {


            });
    }

    //////// Done ////////////////////
    const getCPTDescription = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-cpt-code-description-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                setCptDescription(response.data.data)


            })
            .catch(function (error) {

            });
    }

    //////// Done ////////////////////
    const getCPTCategoryList = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-cpt-category-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.data.data.length > 0) {
                    setCptCategoryList(response.data.data)

                }


            })
            .catch(error => {

            });
    };

    ///////// Done ////////////////////
    const FetchM_status = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m-status/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                setM_statusList(response.data)

            })
        // .catch(function (error) {
        //     // console.log(error,"error in fetching m_status")
        // });
    }

    //////// Done ////////////////////
    const FetchTypeList = async () => {
        await AxiosInter.get(`${API_ENDPOINT}/admin-api/get-groupDoc-auth-types/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                setM_typeList(response.data)

            })
        // .catch(function (error) {

        // });
    }

    //////// Done ////////////////////
    const fetchInsuranceGroupNamesList = () => {
        setInsuranceGroupNamesLoading(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-group-insurance-names-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((response) => {
                if (response) {
                    setInsuranceGroupNames(response.data.data);
                    setInsuranceGroupNamesLoading(false);
                }
            })
            .catch(error => {
                setInsuranceGroupNames([]);
                setInsuranceGroupNamesLoading(false);
                toastr.error('Error', 'Something went wrong')

            });
    }

    //////// Done ////////////////////
    const fetchInsuranceGroupPlanTypeNames = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-group-insurance-plan-type-names-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        }).then(response => {
            if (response) {
                setInsuranceCompanyPlanTypeNames(response.data.data);
                setInsuranceCompanyPlanTypeNamesLoading(false);
            }
        })
            .catch((error) => {
                setInsuranceCompanyPlanTypeNames([]);
                setInsuranceCompanyPlanTypeNamesLoading(false);
                toastr.error('Error', 'Something went wrong')
            });
    };

    //////// Done ////////////////////
    const fetchInsuranceCompanyNamesList = () => {
        setInsuranceCompanyNamesLoading(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-group-insurance-company-names-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response) {
                    setInsuranceCompanyNames(response.data.data);
                    setInsuranceCompanyNamesLoading(false);


                }
            })
            .catch(error => {
                setInsuranceCompanyNames([]);
                setInsuranceCompanyNamesLoading(false);
                toastr.error('Error', 'Something went wrong')

            });
    };

    //////// Done ////////////////////
    const fetchImmunizationDrugNamesList = () => {
        setImmunizationDrugNamesLoading(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-immunization-drug-names/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response) {
                    setImmunizationDrugNames(response.data.data);
                    setImmunizationDrugNamesLoading(false);


                }
            })
            .catch(error => {
                setImmunizationDrugNames([]);
                setImmunizationDrugNamesLoading(false);
                toastr.error('Error', 'Something went wrong')

            });
    };

    //////// Done ////////////////////
    const fetchImmunizationDrugGenericNamesList = () => {
        setImmunizationDrugGenericNamesLoading(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-immunization-drug-generic-names/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response) {
                    setImmunizationDrugGenericNames(response.data.data);
                    setImmunizationDrugNamesLoading(false);


                }
            })
            .catch(error => {
                setImmunizationDrugGenericNames([]);
                setImmunizationDrugGenericNamesLoading(false);
                toastr.error('Error', 'Something went wrong')

            });
    };

    //////// Done ////////////////////
    const fetchICDPackageNamesList = () => {
        setIcdPackageNamesListLoading(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-icd-package-names-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response) {
                    setIcdPackageNames(response.data.data);
                    setIcdPackageNamesListLoading(false);


                }
            })
            .catch(error => {
                setIcdPackageNames([]);
                setIcdPackageNamesListLoading(false);
                toastr.error('Error', 'Something went wrong')

            });
    };

    //////// Done ////////////////////
    const fetchCPTPackageNamesList = () => {
        setCptPackageNamesListLoading(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-cpt-package-names-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response) {
                    setCptPackageNames(response.data.data);
                    setCptPackageNamesListLoading(false);


                }
            })
            .catch(error => {
                setCptPackageNames([]);
                setCptPackageNamesListLoading(false);
                toastr.error('Error', 'Something went wrong')

            });
    };

    //////// Done ////////////////////
    const fetchFacilityAccessType = () => {
        setFacilityListLoading(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-facility-access-type/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                setFacilityAccessType(response.data.data)
                setSpinner(false);
                setFacilityListLoading(false);



            })
            .catch(error => {
                setFacilityAccessType("");
                setFacilityListLoading(false);
            });
    };

    const getRegionNames = () => {

        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m-region-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.data.data.length > 0) {
                    setRegionNames(response.data.data)

                }
            })
            .catch(error => {

                toastr.error('Error', 'Something went wrong!')

            });
    };

    // COMMON
    const [token, setToken] = useState("")
    const [spinner, setSpinner] = useState(false)
    const [blankStateMessage, setBlankStateMessage] = useState(false)
    const [refundBlankStateMessage, setRefundBlankStateMessage] = useState(false)
    const [hideMenu, setHideMenu] = useState(true)
    const [details, setDetails] = useState("")
    const [userType, setUserType] = useState("")
    const [countryCodeList, setCountryCodeList] = useState("");
    const [loginCount, setLoginCount] = useState(0)
    let Token = localStorage.getItem("token");
    const [centerNamesForDropDown, setCenterNamesForDropDown] = useState([]);
    const [centerNamesForDropDownLoading, setCenterNamesForDropDownLoading] = useState([false]);
    const [m_zipList, setM_zipList] = useState("")
    const [m_statusList, setM_statusList] = useState("")
    const [m_typeList, setM_typeList] = useState("")

    // APPOINTMENTS  
    const [appoFromDate, setAppoFromDate] = useState("")
    const [appoToDate, setAppoToDate] = useState("")
    const [appStatusID, setAppStatusID] = useState("")
    const [couponCode, setCouponCode] = useState("")
    const [groupId, setGroupId] = useState("")
    const [doctorName, setDoctorName] = useState("")
    const [couponDropDown, setCouponDropDown] = useState([])
    const [groupDropDown, setGroupDropDown] = useState([])
    const [doctorDropDown, setDoctorDropDown] = useState([])
    const [appointmentDropDown, setAppointmentDropDown] = useState([])
    const [createdByDropDown, setCreatedByDropDown] = useState([])
    const [appointmentStatus, setAppointmentStatus] = useState([])
    const [providerID, setProviderID] = useState("")
    const [couponNameSearch, setCouponNameSearch] = useState("")
    const [appoSearch, setAppoSearch] = useState(1)
    const [today, setToday] = useState("")
    const [chartFromDate, setChartFromDate] = useState("");
    const [chartToDate, setChartToDate] = useState("");
    const [refundRules, setRefundRules] = useState("");
    const [refundRulesIds, setRefundRulesIds] = useState("");
    const [matching, setMatching] = useState(false);
    const [docterSpeciality, setDocterSpeciality] = useState(false);

    // ADMIN USERS
    const [userName, setUserName] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userStatus, setUserStatus] = useState("")
    const [AdminUserType, setAdminUserType] = useState("")
    const [userId, setUserId] = useState("")
    const [reloadUserData, setReloadUserData] = useState(0)

    // CORPORATE GROUPS  <OR> // HEALTHCARE PARTNERS // Groups
    const [corporateName, setCorporateName] = useState("")
    const [corporateStatus, setCorporateStatus] = useState("")
    const [corporateAddress, setCorporateAddress] = useState("")
    const [corporateListReload, setCorporateListReload] = useState(0)

    // MEDICATION 
    const [drugName, setDrugName] = useState("")
    const [drugCode, setDrugCode] = useState("")
    const [genericName, setGenericName] = useState("")
    const [genericNameCode, setGenericNameCode] = useState("")
    const [drugStatus, setDrugStatus] = useState("")
    const [medicationListReload, setMedicationListReload] = useState(0)

    // RULE CONFIGURATION
    const [ruleConfigListReload, setRuleConfigListReload] = useState(0)
    const [refundConfigListReload, setRefundConfigListReload] = useState(0)
    const [refundRuleList, setRefundRulesList] = useState();

    // FACILITY
    const [facilityReload, setFacilityReload] = useState(0)
    const [reloadFacilityDetails, setReloadFacilityDetails] = useState(false);

    // GROUPS
    const [groupNamesReload, setGroupNamesReload] = useState(0)

    //REFERRAL IMPORT LOG
    const [senderId, setSenderId] = useState("");
    const [fulFillerId, setFulFillerId] = useState("");
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [status, setStatus] = useState("");
    const [timeZone, setTimeZone] = useState("");
    const [orderColumn, setOrderColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [recLimit, setRecLimit] = useState("");
    const [counter, setCounter] = useState("");
    const [offset, setOffSet] = useState("");
    const [senderDropDownList, setSenderDropDownList] = useState([]);
    const [fullFillerDropDownList, setFullFillerDropDownList] = useState([]);
    const [importlogReload, setImportlogReload] = useState(0)
    const senderDropDown = []
    const fullFillerDropDown = []

    // REFERRAL SENDER
    const [referralSenderReload, setReferralSenderReload] = useState(0)

    // REFERRAL FULFILLER
    const [referralFulfillerReload, setReferralFulfillerReload] = useState(0)

    // REFERRAL DRAFT
    const [senderIdDraft, setSenderIdDraft] = useState("");
    const [fulFillerIdDraft, setFulFillerIdDraft] = useState("");
    const [yearDraft, setYearDraft] = useState("");
    const [monthDraft, setMonthDraft] = useState("");
    const [statusDraft, setStatusDraft] = useState("");
    const [timeZoneDraft, setTimeZoneDraft] = useState("");
    const [orderColumnDraft, setOrderColumnDraft] = useState("");
    const [draftReload, setDraftReload] = useState(0)

    // APP USERS
    const [appUserName, setAppUserName] = useState("");
    const [appUserRole, setAppUserRole] = useState("");
    const [appUserUsername, setAppUserUsername] = useState("");
    const [appUserNPI, setAppUserNPI] = useState("");
    const [appUserEmail, setAppUserEmail] = useState("");
    const [appUserStatus, setAppUserStatus] = useState("");
    const [appUserEmailDigest, setAppUserEmailDigest] = useState("");
    const [appUserGroup, setAppUserGroup] = useState("");
    const [appUserReload, setAppUserReload] = useState(0)
    const [roleListForDropDown, setRoleListForDropDown] = useState("")
    const [groupList, setGroupList] = useState([]);

    // PandaDoc
    const [pandaDocList, setPandaDocList] = useState([])
    const [senderName, setSenderName] = useState("");
    const [fulfillerName, setFulfillerName] = useState("");
    const [docStatus, setDocStatus] = useState(0);
    const [patientFirstName, setPatientFirstName] = useState("");
    const [patientLastName, setPatientLastName] = useState("");
    const [patientAccNumber, setPatientAccNumber] = useState("");
    const [docType, setDocType] = useState("");
    const [senderFulfillerData, setSenderFulfillerData] = useState("")
    const [pandaDocSpinner, setPandaDocSpinner] = useState(false)
    const [panDocReload, setPanDocReload] = useState(0)

    // FAX LOG
    const [appUserId, setAppUserId] = useState(0)
    const [patientId, setPatientId] = useState(0)
    const [physicianId, setPhysicianId] = useState(0)
    const [groupsId, setGroupsId] = useState(0)
    const [facilityId, setFacilityId] = useState(0)
    const [docName, setDocName] = useState("")
    const [recipientFaxNumber, setRecipientFaxNumber] = useState("")
    const [statusC, setStatusC] = useState("")
    const [faxReload, setFaxReload] = useState("")

    // PROVIDER SPECIALITY RANKING
    const [senderGroup, setSenderGroup] = useState("")
    const [fulfillerGroup, setfulfillerGroup] = useState("")
    const [fulfillerFacility, setfulfillerFacility] = useState("")
    const [orderType, setOrderType] = useState("")
    const [provider, setProvider] = useState("")
    const [psrStatus, setPSRStatus] = useState("")
    const [insuranceType, setInsuranceType]=useState("")
    const [selectedInsuranceType, setSelectedInsuranceType]=useState(null)

    useEffect(() => {
        if (Token) {
            FetchM_ZipListAPICall();
            fetchGroupNames();
            fetchZipCodeOptions();
        }
    }, [facilityId]) // eslint-disable-line 


    useEffect(() => {
        if (reloadFacilityDetails) {
            fetchFacilityAccessType();
        }
        if (groupNamesReload) {
            fetchGroupNames();
        }
        if (icdPackageNamesReload) {
            fetchICDPackageNamesList();
        }
        if (cptPackageNamesReload) {
            fetchCPTPackageNamesList();
        }
        if (subscriptionNamesListReload) {
            getAllSubscriptionPlans();
        }

        if (RegionNamesReload) {
            getRegionNames();
        }
    }, [facilityReload, groupNamesReload, icdPackageNamesReload, cptPackageNamesReload, subscriptionNamesListReload, RegionNamesReload]) // eslint-disable-line 

    return (
        <GlobalState.Provider value={{
            loginCount, setLoginCount,
            appoFromDate, setAppoFromDate,
            appointmentDropDown, setAppointmentDropDown,
            appointmentStatus, setAppointmentStatus,
            appoSearch, setAppoSearch,
            appoToDate, setAppoToDate,
            appStatusID, setAppStatusID,
            matching, setMatching,
            refundRules, setRefundRules,
            refundRulesIds, setRefundRulesIds,
            blankStateMessage, setBlankStateMessage,
            chartFromDate, setChartFromDate,
            chartToDate, setChartToDate,
            corporateAddress, setCorporateAddress,
            corporateListReload, setCorporateListReload,
            corporateName, setCorporateName,
            corporateStatus, setCorporateStatus,
            couponCode, setCouponCode,
            couponDropDown, setCouponDropDown,
            fullFillerDropDown,
            senderDropDown,
            countryCodeList, setCountryCodeList,
            m_statusList, setM_statusList,
            m_typeList, setM_typeList,
            couponNameSearch, setCouponNameSearch,
            facilityReload, setFacilityReload,
            reloadFacilityDetails, setReloadFacilityDetails,
            senderId, setSenderId,
            fulFillerId, setFulFillerId,
            year, setYear,
            month, setMonth,
            status, setStatus,
            timeZone, setTimeZone,
            orderColumn, setOrderColumn,
            sortOrder, setSortOrder,
            recLimit, setRecLimit,
            counter, setCounter,
            offset, setOffSet,
            senderDropDownList,
            referralSenderReload,
            setReferralSenderReload,
            referralFulfillerReload,
            setSenderDropDownList,
            setReferralFulfillerReload,
            fullFillerDropDownList,
            importlogReload, setImportlogReload,
            setFullFillerDropDownList,
            draftReload, setDraftReload,
            senderIdDraft, setSenderIdDraft,
            fulFillerIdDraft, setFulFillerIdDraft,
            yearDraft, setYearDraft,
            monthDraft, setMonthDraft,
            statusDraft, setStatusDraft,
            timeZoneDraft, setTimeZoneDraft,
            orderColumnDraft, setOrderColumnDraft,
            appUserName, setAppUserName,
            appUserRole, setAppUserRole,
            appUserUsername, setAppUserUsername,
            appUserNPI, setAppUserNPI,
            appUserEmail, setAppUserEmail,
            appUserStatus, setAppUserStatus,
            appUserEmailDigest, setAppUserEmailDigest,
            appUserGroup, setAppUserGroup,
            appUserReload, setAppUserReload,
            roleListForDropDown, setRoleListForDropDown,
            setM_zipList, m_zipList,
            groupList, setGroupList,
            pandaDocList, setPandaDocList,
            senderName, setSenderName,
            fulfillerName, setFulfillerName,
            docStatus, setDocStatus,
            patientFirstName, setPatientFirstName,
            patientLastName, setPatientLastName,
            patientAccNumber, setPatientAccNumber,
            senderFulfillerData, setSenderFulfillerData,
            pandaDocSpinner, setPandaDocSpinner,
            docType, setDocType,
            panDocReload, setPanDocReload,
            appUserId, setAppUserId,
            patientId, setPatientId,
            physicianId, setPhysicianId,
            groupsId, setGroupsId,
            facilityId, setFacilityId,
            docName, setDocName,
            recipientFaxNumber, setRecipientFaxNumber,
            statusC, setStatusC,
            faxReload, setFaxReload,
            senderGroup, setSenderGroup,
            fulfillerGroup, setfulfillerGroup,
            fulfillerFacility, setfulfillerFacility,
            orderType, setOrderType,
            insuranceType,setInsuranceType,
            selectedInsuranceType,setSelectedInsuranceType,
            provider, setProvider,
            psrStatus, setPSRStatus,
            createdByDropDown, setCreatedByDropDown,
            details, setDetails,
            doctorDropDown, setDoctorDropDown,
            doctorName, setDoctorName,
            drugCode, setDrugCode,
            drugName, setDrugName,
            drugStatus, setDrugStatus,
            genericName, setGenericName,
            genericNameCode, setGenericNameCode,
            groupDropDown, setGroupDropDown,
            groupId, setGroupId,
            hideMenu, setHideMenu,
            medicationListReload, setMedicationListReload,
            userName, setUserName,
            userEmail, setUserEmail,
            userStatus, setUserStatus,
            AdminUserType, setAdminUserType,
            userId, setUserId,
            reloadUserData, setReloadUserData,
            providerID, setProviderID,
            refundConfigListReload, setRefundConfigListReload,
            refundRuleList, setRefundRulesList,
            ruleConfigListReload, setRuleConfigListReload,
            spinner, setSpinner,
            refundBlankStateMessage, setRefundBlankStateMessage,
            docterSpeciality, setDocterSpeciality,
            today, setToday,
            token, setToken,
            userType, setUserType,
            groupNamesReload, setGroupNamesReload,
            zipListLoaded, setZipListLoading,
            icdCodeList, setIcdCodeList,
            cptCodesList, setCptCodesList,
            cptDescription, setCptDescription,
            cptCategoryList,
            groupListLoading, setGroupListLoading,
            groupNamesList, setGroupNamesList,
            facilityListLoading, setFacilityListLoading,
            insuranceCompanyPlanTypeNames, setInsuranceCompanyPlanTypeNames,
            insuranceCompanyPlanTypeNamesLoading, setInsuranceCompanyPlanTypeNamesLoading,
            insuranceGroupNames, setInsuranceGroupNames,
            insuranceGroupNamesLoading, setInsuranceGroupNamesLoading,
            insuranceCompanyNames, setInsuranceCompanyNames,
            insuranceCompanyNamesLoading, setInsuranceCompanyNamesLoading,
            immunizationDrugNames, setImmunizationDrugNames,
            immunizationDrugNamesLoading, setImmunizationDrugNamesLoading,
            immunizationDrugGenericNames, setImmunizationDrugGenericNames,
            immunizationDrugGenericNamesLoading, setImmunizationDrugGenericNamesLoading,
            icdPackageNames, setIcdPackageNames,
            icdPackageNamesListLoading, setIcdPackageNamesListLoading,
            icdPackageNamesReload, setIcdPackageNamesReload,
            cptPackageNames, setCptPackageNames,
            cptPackageNamesListLoading, setCptPackageNamesListLoading,
            cptPackageNamesReload, setCptPackageNamesReload,
            facilityAccessType, setFacilityAccessType,
            groupNamesForDropDown, setGroupNamesForDropDown,
            groupNamesForDropDownLoading, setGroupNamesForDropDownLoading,
            subscriptionNamesList, setSubscriptionNamesList,
            subscriptionNamesListLoading, setSubscriptionNamesListLoading,
            appUsersNames, setAppUsersNames,
            appUsersNamesListLoading, setAppUsersNamesListLoading,
            timeZoneList, setTimeZoneList,
            timeZoneListLoading, setTimeZoneListLoading,
            zipCodeOptions, setZipCodeOptions,
            zipCodeOptionsLoading, setZipCodeOptionsLoading,
            subscriptionNamesListReload, setSubscriptionNamesListReload,
            RegionNamesReload, setRegionNamesReload,
            RegionNames, setRegionNames,
            centerNamesForDropDown, setCenterNamesForDropDown,
            centerNamesForDropDownLoading, setCenterNamesForDropDownLoading,
            getAllSubscriptionPlans,
            getTimeZoneList,
            getAppUsersListForDropdown,
            FetchCountryListAPICall,
            getICDCodesList,
            getCPTCodesList,
            getCPTDescription,
            getCPTCategoryList,
            fetchInsuranceGroupNamesList,
            fetchInsuranceGroupPlanTypeNames,
            fetchInsuranceCompanyNamesList,
            FetchM_status,
            FetchTypeList,
            fetchImmunizationDrugNamesList,
            fetchImmunizationDrugGenericNamesList,
            fetchICDPackageNamesList,
            fetchCPTPackageNamesList,
            fetchFacilityAccessType,
        }}>
            {props.children}
        </GlobalState.Provider>
    )
}