import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  Label,
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { GlobalState } from "../../contextAPI/Context";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"; // eslint-disable-line
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import DeleteConfirmationModal from "../../newComponents/utilities/DeleteConfirmationModal";
import Select from "react-select";
import AxiosInter from './../../AxiosInter.js';
import Cookie from "js-cookie";
import { toastr } from "react-redux-toastr";
import MomentConfig from "../../common/MomentConfig";
import moment from "moment";
import AsyncSelect from "react-select/async";
import NewAutoProcessRule from "../../newComponents/utilities/NewAutoProcessRule";
import EditAutoProcessRule from "../../newComponents/utilities/EditAutoProcessRule";


const AutoProcessRule = (props) => {
  // CONTEXT VARIABLES
  const { spinner, setSpinner } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [data, setData] = useState([]); // eslint-disable-line
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [reload, setReload] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [isPagination, setIsPagination] = useState(1); // eslint-disable-line
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false); // eslint-disable-line
  const [search, setSearch] = useState(false); // eslint-disable-line
  const [toggleValue, setToggleValue] = useState(0);
  const [newAutoProcessRule, setNewAutoProcessRule] = useState(false);
  const [editAutoProcessRule, setEditAutoProcessRule] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const fileExportName = "AutoProcessExceptionRule";
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [ruleDetails, setRuleDetails] = useState("");
  const [filter, setFilter] = useState(false);
  const [editPatientAge, setEditPatientAge] = useState();
  const [editConditionLabel, setEditConditionLabel] = useState([]);
  const [visitNumbers, setVisitNumbers] = useState([]);
  const [rowId, setRowId] = useState();
  const [buttonBlankState, setButtonBlankState] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [ruleName, setRuleName] = useState("");
  const [referralType, setReferralType] = useState("");
  const [patientAgeCondition, setPatientAgeCondition] = useState("");
  const [patientCondition, setPatientCondition] = useState([]);
  const [patientInsurance, setPatientInsurance] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [orderPriority, setOrderPriority] = useState([]);
  const [patientGender, setPatientGender] = useState("");
  const [diagnosis, setDiagnosis] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [processStatus, setProcessStatus] = useState();
  const [createdDate, setCreatedDate] = useState("");
  const [createdBy, setCreatedBy] = useState();
  const [updatedDate, setUpdatedDate] = useState("");
  const [updatedBy, setUpdatedBy] = useState();
  const [senderFacilityId, setSenderFacilityId] = useState([]);
  const [auto_Assign_Fullfiller, set_Auto_Assign_Fullfiller] = useState(1);
  const [assign_FulfillerGroupId, setAssign_FulfillerGroupId] = useState({
    value: 0,
    label: "0",
  });
  const [isEnableDocTransmission, setIsEnableDocTransmission] = useState([]);
  const [doc_config_transmit_types, setDoc_config_transmit_types] = useState(
    []
  );
  const [allowedSenderFacility, setAllowedSenderFacility] = useState([]);
  const [excludedSenderFacility, setExcludedSenderFacility] = useState([]);
  const [referralCategory, setReferralCategory] = useState({ value: "", label: "" });
  const [mandatoryItems, setMandatoryItems] = useState(true);
  const [eligibilityCheck, setEligibilityCheck] = useState();
  const [preAuthCheck, setPreAuthCheck] = useState();
  const [preAuthRequestType, setPreAuthRequestType] = useState("");
  const [otherGenerateDocs, setOtherGenerateDocs] = useState([]);
  const [autoProcessEnable, setAutoProcessEnable] = useState();
  const [allowedDiagnosis, setAllowedDiagnosis] = useState([]);
  const [allowedProcedure, setAllowedProcedure] = useState([]);
  const [allowedInsurances, setAllowedInsurances] = useState([]);
  const [allowedServiceTypes, setAllowedServiceTypes] = useState([]);
  const [allowedNoOfVisitors, setAllowedNoOfVisitors] = useState([]);
  const [allowedPos, setAllowedPos] = useState();
  const [excludedDiagnosis, setExcludedDiagnosis] = useState([]);
  const [excludedProcedure, setExcludedProcedure] = useState([]);
  const [excludedInsurances, setExcludedInsurances] = useState([]);
  const [excludedServiceTypes, setExcludedServiceTypes] = useState([]);
  const [additionalKeyData, setAdditionalKeyData] = useState("");
  const [ruleType, setRuleType] = useState("");
  const [inputValue, setInputValue] = useState(""); // eslint-disable-line
  const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
  const [selectedOrderPriority, setSelectedOrderPriority] = useState([]);
  const [selectedPhysicianIds, setSelectedPhysicianIds] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedInsurances, setSelectedInsurances] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState([]);
  const [selectedMandatoryItems, setSelectedMandatoryItems] = useState([]);
  const [selectedFulfillerGroup, setSelectedFulfillerGroup] = useState([]);
  const [selectedAllowedFulfillerGroup, setSelectedAllowedFulfillerGroup] =
    useState([]);
  const [
    selectedAllowedFulfillerFacility,
    setSelectedAllowedFulfillerFacility,
  ] = useState([]);
  const [
    selectedAllowedFulfillerPhysician,
    setSelectedAllowedFulfillerPhysician,
  ] = useState([]);
  const [selectedExcludedFulfillerGroup, setSelectedExcludedFulfillerGroup] =
    useState([]);
  const [
    selectedExcludedFulfillerFacility,
    setSelectedExcludedFulfillerFacility,
  ] = useState([]);
  const [
    selectedExcludedFulfillerPhysician,
    setSelectedExcludedFulfillerPhysician,
  ] = useState([]);
  const [configuredDocOnSent, setConfiguredDocOnSent] = useState(false);
  const [editTransmitEmiForm, setEditTransmitEmiForm] = useState(false);
  const [editFaxNumber, setEditFaxNumber] = useState("");
  const [selectedFulfillerFacility, setSelectedFulfillerFacility] = useState(
    []
  );
  const [referralRuleData, setReferralRuleData] = useState([]);

  // *******Edit Autoprocess exception rules variables****** //

  const [editgroupId, setEditGroupId] = useState();
  const [editruleName, setEditRuleName] = useState("");
  const [editreferralType, setEditReferralType] = useState("");
  const [editruleType, setEditRuleType] = useState("");
  const [editpatientAgeCondition, setEditPatientAgeCondition] = useState([]);
  const [editpatientCondition, setEditPatientCondition] = useState([]);
  const [editpatientInsurance, setEditPatientInsurance] = useState([]);
  const [editserviceTypes, setEditServiceTypes] = useState([]);
  const [editorderPriority, setEditOrderPriority] = useState([]);
  const [editpatientGender, setEditPatientGender] = useState("");
  const [editdiagnosis, setEditDiagnosis] = useState([]);
  const [editprocedures, setEditProcedures] = useState([]);
  const [editprocessStatus, setEditProcessStatus] = useState();
  const [editcreatedDate, setEditCreatedDate] = useState("");
  const [editcreatedBy, setEditCreatedBy] = useState();
  const [editupdatedDate, setEditUpdatedDate] = useState("");
  const [editupdatedBy, setEditUpdatedBy] = useState();
  const [editfulfillerGroupId, setEditFulfillerGroupId] = useState([]);
  const [editfulfillerFacilityId, setEditFulfillerFacilityId] = useState([]);
  const [editfulfillerPhysicianId, setEditFulfillerPhysicianId] = useState([]);
  const [editmandatoryItems, setEditMandatoryItems] = useState([]);
  const [editeligibilityCheck, setEditEligibilityCheck] = useState();
  const [editpreAuthCheck, setEditPreAuthCheck] = useState();
  const [editpreAuthRequestType, setEditPreAuthRequestType] = useState("");
  const [editotherGenerateDocs, setEditOtherGenerateDocs] = useState([]);
  const [editautoProcessEnable, setEditAutoProcessEnable] = useState();
  const [editallowedDiagnosis, setEditAllowedDiagnosis] = useState([]);
  const [editallowedProcedure, setEditAllowedProcedure] = useState([]);
  const [editallowedInsurances, setEditAllowedInsurances] = useState([]);
  const [editallowedServiceTypes, setEditAllowedServiceTypes] = useState([]);
  const [editallowedNoOfVisitors, setEditAllowedNoOfVisitors] = useState([]);
  const [editallowedPos, setEditAllowedPos] = useState();
  const [editexcludedDiagnosis, setEditExcludedDiagnosis] = useState([]);
  const [editexcludedProcedure, setEditExcludedProcedure] = useState([]);
  const [editexcludedInsurances, setEditExcludedInsurances] = useState([]);
  const [editexcludedServiceTypes, setEditExcludedServiceTypes] = useState([]);
  const [editadditionalKeyData, setEditAdditionalKeyData] = useState("");
  const [editSenderFacilityId, setEditSenderFacilityId] = useState([]);

  const [editAuto_Assign_Fullfiller, setEditAuto_Assign_Fullfiller] =
    useState("");
  const [editAassign_FulfillerGroupId, setEditAassign_FulfillerGroupId] =
    useState({ value: "", label: "" });
  const [editIsEnableDocTransmission, setEditIsEnableDocTransmission] =useState([]); // eslint-disable-line
  const [editDoc_config_transmit_types, setEditDoc_config_transmit_types] =useState([]); // eslint-disable-line
  const [editAllowedSenderFacility, setEditAllowedSenderFacility] = useState([]); // eslint-disable-line
  const [editExcludedSenderFacility, setEditExcludedSenderFacility] = useState([]); // eslint-disable-line
  const [editReferralCategory, setEditReferralCategory] = useState({
    value: "",
    label: "",
  });
  const [editAllowedFulfillerFacilty, setEditAllowedFullfillerFacility] =
    useState("");
  const [editAllowedFulfillerphysician, setEditAllowedFullfillerPhysician] =
    useState("");
  const [editAllowedFulfillerGroup, setEditAllowedFullfillerGroup] =
    useState("");
  const [editExcludedFulfillerFacilty, setEditExcludedFullfillerFacility] =
    useState("");
  const [editExcludedFulfillerphysician, setEditExcludedFullfillerPhysician] =
    useState("");
  const [editExcludedFulfillerGroup, setEditExcludedFullfillerGroup] =
    useState("");
  const [transmitAdditionalAttachments, setTransmitAdditionalAttachments] =
    useState(false);
  const [editAttachmentType, setEditAttachmentType] = useState("");
  const [selectAttachmentType, setSelectAttachmentType] = useState([]);
  const [editRuleId, setEditRuleId] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [transmitEmiForm, seTransmitEmiForm] = useState(false);
  const [faxNumber, setFaxNumber] = useState("");
  const [selectedSenderGroup, setSelectedSenderGroup] = useState([]);
  const [editSenderGroup, setEditSelectedSenderGroup] = useState([]);
const [editSuggestingForm,setEditSuggestingForm] = useState([]);

 // *******Edit variable Ends****** //

  // ******* Options handled in UI ****** //

  const referralTypeOptions = [
    {
      value: "sender",
      label: `Sender`,
    },
    {
      value: "fulfiller",
      label: `Fulfiller`,
    },
  ];
  const ruleTypeOptions = [
    {
      value: "preAuthException",
      label: `PreAuthException`,
    },
    {
      value: "flowException",
      label: `FlowException`,
    },
  ];
  const requestTypeOptions = [
    {
      value: "SC",
      label: `Referral`,
    },
    {
      value: "HS",
      label: `outPatient Authorization`,
    },
  ];
  const genderOptions = [
    {
      value: "male",
      label: `Male`,
    },
    {
      value: "female",
      label: `Female`,
    },
    {
      value: "not specified",
      label: `Not Specified`,
    },
  ];
  const ageOptions = [
    {
      value: "less than",
      label: `Less than`,
    },
    {
      value: "greater than",
      label: `Greater than`,
    },
    {
      value: "equal to",
      label: `Equal to`,
    },
    {
      value: "between",
      label: `Between`,
    },
  ];
  const orderPriorityOptions = [
    {
      value: "Stat",
      label: `Stat`,
    },
    {
      value: "Urgent",
      label: `Urgent`,
    },
    {
      value: "Routine",
      label: `Routine`,
    },
  ];
  const additionalDocsOptions = [
    {
      value: "emi_form",
      label: `EMI Form`,
    },
  ];
  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleRuleNameChange = (value) => {
    setRuleName(value);
  };
 
  const ruleNameloadOptions = (inputValue) => {
    if (inputValue.length >= 3) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/autoprocess-rule-name-list/?${searchParams.toString()}`,
      {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then((response) => response.data.data)
        // .catch((error) => {
        //   throw error;
        // });
    }
  };

  const fetchReferralRuleMandatoryItem = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-referral-rule-mandatory-items/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setReferralRuleData(response.data.data);
        }
      })
      // .catch((error) => {
      //   console.log(error);
      // });
  };

  useEffect(() => {
    if (referralRuleData.length === 0) {
      fetchReferralRuleMandatoryItem();
    }
  }, [referralRuleData]); // eslint-disable-line
  const filterValidation = () => {
    if (ruleName || referralType) {
      setFilter(true);
    } else {
      setFilter(false);
    }
  };

  const clearFilterValues = () => {
    setRuleName("");
    setReferralType("");
    setReload(reload + 1);
  };

  useEffect(() => {
    filterValidation();
  }, [ruleName, referralType]); // eslint-disable-line

  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;

  //////////////////////////////////////////////////////////////
  //******************API FOR LOAD OPTIONS ******************//
  /////////////////////////////////////////////////////////////

  const loadServiceTypeOptions = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-order-type/${inputValue}/${0}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
    }
  };


  const setForReferralCategory = async (
    inputValue,
    setEditReferralCategory) => {
      const url = `${API_ENDPOINT}/admin-api/get-category-type-set-async/${inputValue.toString()}`;
    try {
      const response = await AxiosInter.get(url, {
        headers: {
          Authorization: API_TOKEN,
        },
      });
      const data = response.data;
      if (Array.isArray(data.data) && data.data.length > 0) {
        const label = data.data;
        console.log("rcc", label[0].labelF);

        setEditReferralCategory({ value: inputValue, label: label[0].labelF });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setEditReferralCategory(null); // set the value to null in case of errors
    }
  };
  

  const setForSenderFacility = async (inputValue, setEditSenderFacilityId) => {
    try {
    // eslint-disable-next-line eqeqeq
    if(inputValue != "" && inputValue != undefined  ){
      
      const response = await AxiosInter.get(
        `${API_ENDPOINT}/admin-api/get-sender-facility-list/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      );

      const data = response.data;

      const label = data.data;

      setEditSenderFacilityId(label[0]);
    }
    } catch (error) {
      console.error("Error fetching data:", error);
      setEditSenderFacilityId(null); // set the value to null in case of errors
    }
  };



  const setForSuggestingForm = async (inputValue, setEditSuggestingForm) => {
    try {
      // const url = `${API_ENDPOINT}/admin-api/get-sender-facility-list/${inputValue}`;
    // eslint-disable-next-line eqeqeq
    if(inputValue != "" && inputValue != undefined  ){
      
      const response = await AxiosInter.get(
        `${API_ENDPOINT}/admin-api/get-suggesting-form/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      );

      const data = response.data;

      const label = data.data;

      setEditSuggestingForm(label[0]);
    }
    } catch (error) {
      console.error("Error fetching data:", error);
      setEditSuggestingForm(null); // set the value to null in case of errors
    }
  };


  const getInitialFulfillerSelection = async (
    auto_assign_fulfiller,
    auto_assign_fulfillerGroupid
  ) => {
   
    if (auto_assign_fulfiller === 1) {
      setEditAuto_Assign_Fullfiller(1);
      setEditAassign_FulfillerGroupId({ value: 0, label: "0" });
    } else if (auto_assign_fulfiller === 0) {
      setEditAuto_Assign_Fullfiller(0);
      setEditAassign_FulfillerGroupId({ value: 0, label: "0" });
    } else {
      if (auto_assign_fulfiller === null) {
        setEditAuto_Assign_Fullfiller(0);
        setEditAassign_FulfillerGroupId({ value: 0, label: "0" });
      } else {
        setEditAuto_Assign_Fullfiller(2);

        const url = `${API_ENDPOINT}/admin-api/get-fulfillergroup-type-set/${auto_assign_fulfillerGroupid.toString()}`;
        try {
          const response = await AxiosInter.get(url, {
            headers: {
              Authorization: API_TOKEN,
            },
          });
          const data = response.data;
          const label = data.data;
          console.log("rcc", label[0].label);

          setEditAassign_FulfillerGroupId({
            value: label[0].value,
            label: label[0].label,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          // set the value to null in case of errors
        }
      }
    }
  };

  const loadPhysicianOptions = (inputValue) => {
    if (inputValue.length >= 3) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-data/?${searchParams.toString()}`,
      {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };


  const loadConditionOptions = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(
        `${API_ENDPOINT}/admin-api/get-conditions/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };


  const loadInsuranceOptions = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(
        `${API_ENDPOINT}/admin-api/get-insurance-company-names/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };


  const loadDiagnosisOptions = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(
        `${API_ENDPOINT}/admin-api/get-icd-code-description/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  
  const loadProcedureOptions = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(
        `${API_ENDPOINT}/admin-api/get-cpt-code-description/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .then((error) =>{
          throw error;
        });
    }
  };


  const loadFulfillerGroupOptions = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-fulfiller-group-by-name/${inputValue}/${0}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };
 

  const loadFacilityOptionsForFacility = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-fulfiller-facility-by-fulfiller-name/${inputValue}/${0}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  

  // #########  Default API  ######### //

  const fetchAutoProcessExceptionRules = () => {
    setSpinner(true);
    const params = {
      rule_name: ruleName ? ruleName.label : "",
      referral_type: referralType ? referralType.value : "",
      is_pagination: isPagination ? isPagination : "",
      record_limit: sizePerPage ? sizePerPage : "",
      offset: offset,
      search_keyword: searchKeyword ? searchKeyword : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter.post(`${API_ENDPOINT}/admin-api/list-autoprocess-rule/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setRuleDetails(response.data.data);
          setTotalSize(response.data.record_count);
          setSpinner(false);
          localStorage.setItem("offset", offset);
        } else {
          setTotalSize(0);
          setRuleDetails("");
          setSpinner(false);
          //   setIsDataEmpty(true);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };
  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };
  const timeFormatter = (cell, row) => {
    return cell
      ? `${moment(cell).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}`
      : "N/A";
  };

  // #########  Export API  ######### //
  const exportTableData = () => {
    const params = {
      file_name: fileName ? fileName : "",
      rule_name: ruleName ? ruleName : "",
      referral_type: referralType ? referralType.value : "",
      is_pagination: "1",
      record_limit: "",
      offset: offset,
      search_keyword: searchKeyword ? searchKeyword : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/export-autoprocess-rule/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  // #########  Delete API  ######### //

  const deleteAutoProcessExceptionRules = () => {
    setSpinner(true);
    const params = {
      row_id: rowId ? rowId : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/delete-autoprocess-rule/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        toastr.success("Success", "Deleted successfully");
        setSpinner(false);
        setDeleteModal(false);
        setReload(reload + 1);
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };
  const dobFormatter = (cell) => {
    return cell ? moment.utc(cell).local().format('DD-MMM-YYYY hh:mm a') : "N/A"
  }
  const actionsFormatter = (cell, row) => {
    return (
      <>
        <div style={{ gap: "20px", display: "flex", justifyContent: "center" }}>
          <FontAwesomeIcon
            icon={faEdit}
            size={"1x"}
            color={"#3f86fa"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              console.log("togVal", toggleValue);
              console.log("editotherGenerateDocs", editotherGenerateDocs);
              setEditAutoProcessRule(true);
              setRowId(row.id);
              setEditGroupId(row.group_id);
              setEditRuleName(row.rule_name);
              setEditReferralType(row.referral_type);

              // var ageValue = JSON.parse(row.patient_age_condition)
            
              setEditPatientAgeCondition(
                row.patient_age_condition != null
                  ? JSON.parse(row.patient_age_condition)
                  : []
              );
              setEditPatientCondition(
                row.patient_condition != null
                  ? JSON.parse(row.patient_condition)
                  : ""
              );
              // console.log(typeof row.patient_condition)
              setEditPatientInsurance(JSON.parse(row.patient_insurance));
              setEditServiceTypes(JSON.parse(row.service_types));
              var orderpriorityTemp = JSON.parse(row.order_priority);
              setEditOrderPriority(
                orderpriorityTemp != null ? orderpriorityTemp : ""
              );
              setEditPatientGender(row.patient_gender);
              setEditDiagnosis(
                row.diagnosis != null ? JSON.parse(row.diagnosis) : ""
              );
              setEditProcedures(
                row.procedures != null ? JSON.parse(row.procedures) : ""
              );
              setEditProcessStatus(row.status);
              setEditCreatedDate(row.created_date);
              setEditCreatedBy(row.created_by);
              setEditUpdatedDate(row.updated_date);
              setEditUpdatedBy(row.updated_by);
              setEditFulfillerGroupId(
                row.fulfiller_group_id != null
                  ? JSON.parse(row.fulfiller_group_id)
                  : ""
              );
              setEditSelectedSenderGroup(
                row.sender_group_id != null
                  ? JSON.parse(row.sender_group_id)
                  : ""
              );
              let formid = (row.form_id != null)
              ? row.form_id 
              : ""
              setForSuggestingForm(formid,setEditSuggestingForm)
              setEditFulfillerFacilityId(
                row.fulfiller_facility_id != null
                  ? JSON.parse(row.fulfiller_facility_id)
                  : ""
              );
            
              // setEditSenderFacilityId(
              //   row.sender_facility_id != null
              //     ? JSON.parse(row.sender_facility_id)
              //     : ""
              // );
              setEditFulfillerPhysicianId(
                row.fulfiller_physician_id != null
                  ? JSON.parse(row.fulfiller_physician_id)
                  : ""
              );
              setEditMandatoryItems(JSON.parse(row.mandatory_items));

              setEditEligibilityCheck(row.is_enable_eligiblity_check);
              setEditPreAuthCheck(row.is_enable_pre_auth_check);
              setEditPreAuthRequestType(row.pre_auth_request_type);
              // var docsTemp = JSON.parse(row.order_priority)

              setEditOtherGenerateDocs(JSON.parse(row.other_generate_docs));
              setEditAutoProcessEnable(row.is_auto_process_enable);
              setEditAllowedDiagnosis(JSON.parse(row.allowed_diagnosis));
              setEditAllowedProcedure(JSON.parse(row.allowed_procedure));
              setEditAllowedInsurances(JSON.parse(row.allowed_insurances));
              setEditAllowedServiceTypes(JSON.parse(row.allowed_service_types));
              setEditAllowedNoOfVisitors(row.allowed_no_of_visits);
              setEditAllowedPos(row.allowed_pos);
              setEditExcludedDiagnosis(JSON.parse(row.excluded_diagnosis));
              setEditExcludedProcedure(JSON.parse(row.excluded_procedure));
              setEditExcludedInsurances(JSON.parse(row.excluded_insurances));
              setEditExcludedServiceTypes(
                JSON.parse(row.excluded_service_types)
              );
              setEditAdditionalKeyData(row.additional_key_data);
           
              setEditPatientAge(JSON.parse(row.p_age));
              setMinValue(JSON.parse(row.p_min))
              setMaxValue(JSON.parse(row.p_max))
              setEditConditionLabel(JSON.parse(row.conditionLabel));
             
              setVisitNumbers(JSON.parse(row.visitNumber));

              setEditIsEnableDocTransmission(row.is_enable_docs_transmission);
              setEditDoc_config_transmit_types(
                JSON.parse(row.doc_config_transmit_types)
              );
              setConfiguredDocOnSent(
                JSON.parse(row.is_enable_docs_transmission)
              );

              setForReferralCategory(
                row.referral_category != null
                  ? JSON.parse(row.referral_category)
                  : ""
                ,
                setEditReferralCategory
              );
              getInitialFulfillerSelection(
                JSON.parse(row.auto_assign_fulfiller),
                JSON.parse(row.assign_fulfiller_group_id)
              );
         
              let facid =
                row.sender_facility_id != null
                  ? JSON.parse(row.sender_facility_id)
                  : "";
              // let facid = JSON.parse(row.sender_facility_id);
              setForSenderFacility(facid, setEditSenderFacilityId);
             
              let faxenable = JSON.parse(row.doc_config_transmit_types);
              
              // setEditTransmitEmiForm(faxenable[0]["faxenable"]);
              // setEditFaxNumber(faxenable[0]["faxnumber"]);
                // eslint-disable-next-line eqeqeq
              setEditTransmitEmiForm(faxenable != null &&  faxenable[0]!= undefined? faxenable[0]["faxenable"]: ""); 
              // eslint-disable-next-line eqeqeq
              setEditFaxNumber(faxenable != null &&  faxenable[0]!= undefined? faxenable[0]["faxnumber"]: "");
              setEditAllowedFullfillerGroup(
                JSON.parse(row.allowed_fulfiller_group_id)
              );
              setEditAllowedFullfillerFacility(
                JSON.parse(row.allowed_fulfiller_facility_id)
              );
              setEditAllowedFullfillerPhysician(
                JSON.parse(row.allowed_fulfiller_physician_id)
              );
              setEditExcludedFullfillerFacility(
                JSON.parse(row.excluded_fulfiller_facility_id)
              );
              setEditExcludedFullfillerPhysician(
                JSON.parse(row.excluded_fulfiller_physician_id)
              );
              setEditExcludedFullfillerGroup(
                JSON.parse(row.excluded_fulfiller_group_id)
              );
              setTransmitAdditionalAttachments(
                JSON.parse(row.is_enable_attachment_transmission_via_fax)
              );
              setEditAttachmentType(JSON.parse(row.faxing_attachment_types));
              setEditRuleId(row.id);
            }}
            title="Edit"
          />

          <FontAwesomeIcon
            icon={faTrash}
            color={"#E12C2C"}
            size={"1x"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setRowId(row.id);
              setDeleteModal(true);
            }}
            title="Delete"
          />
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "rule_name",
      text: "Name",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "referral_type",
      text: "Referral Type",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "created_by",
      text: "Created By",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "created_date",
      text: "Created On",
      sort: true,
      formatter: dobFormatter,
      timeFormatter: timeFormatter,
    },
    {
      dataField: "updated_by",
      text: "Updated By",
      sort: false,
      formatter: basicValidation,
    },
    {
      dataField: "updated_date",
      text: "Updated On",
      sort: false,
      formatter: dobFormatter,
      timeFormatter: timeFormatter,
    },
    {
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];

  const handleTableChange = (type, newState) => {
    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("");
      setSortOrder("");
    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setReload(reload + 1);
    }
  };
  const searchButtonValidation = () => {       // eslint-disable-line
    if (referralType || ruleName) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };
  const clearSearchValues = () => {
    setSearchKeyword("");
    setReload(reload + 1);
  };
  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (searchKeyword.length === 0) {
      setReload(reload + 1);
    }
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      setTotalSize(0);
      fetchAutoProcessExceptionRules();
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line
  useEffect(() => {
    if (reload) {
      fetchAutoProcessExceptionRules();
    }
  }, [reload]); // eslint-disable-line
  //useEffect(() => { searchButtonValidation(); }, [selectedMdc, selectedType])// eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset || sizePerPage) {
      setReload(reload + 1);
    }
  }, [offset, sizePerPage]); // eslint-disable-line
  // CheckUserInactivity();

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Auto Process Rule
            <Button
              className="Add-btn pull-right"
              onClick={() => {
                setNewAutoProcessRule(!newAutoProcessRule);
              }}
            >
              Add New Rule
            </Button>
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Auto Process Rule</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>

      <Card>
        <CardHeader>
          <Row>
            <Col sm="2" md="2" lg="2">
              <Label>Name</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                placeholder="Name"
                value={ruleName}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                onInputChange={handleInputChange}
                onChange={handleRuleNameChange}
                loadOptions={ruleNameloadOptions}
              />
            </Col>
            <Col sm="2" md="2" lg="2">
              <Label>Referral Type</Label>
              <Select
                name=""
                options={referralTypeOptions}
                placeholder="Referral Type"
                isClearable
                value={referralType}
                onChange={(e) => {
                  setReferralType(e);
                }}
              />
            </Col>

            <Col>
              <Button
                className="patient-go-buttons"
                onClick={() => {
                  setSearchKeyword("");
                  offset === 0 ? setReload(reload + 1) : setOffset(0);
                  setPage(1);
                }}
                disabled={spinner || !filter ? true : false}
                title={
                  spinner || !filter
                    ? "Choose at least one item to filter "
                    : ""
                }
              >
                Go
              </Button>{" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => {
                  clearFilterValues();
                }}
                disabled={spinner || !filter ? true : false}
                title={spinner || !filter ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <div className="separator" />
        </CardHeader>
        <div className="groups-search">
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={columns}
            clearValues={clearSearchValues}
          />
        </div>

        {spinner ? <Spinner /> : ""}

        <RemotePagination
          data={ruleDetails ? ruleDetails : ""}
          columns={columns}
          page={page}
          sizePerPage={sizePerPage}
          FileExportName={fileExportName}
          exportTableData={exportTableData}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            handleTableChange(type, newState);
          }}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
        />
      </Card>

      <NewAutoProcessRule
        newAutoProcessRule={newAutoProcessRule}
        faxNumber={faxNumber}
        setFaxNumber={setFaxNumber}
        transmitEmiForm={transmitEmiForm}
        seTransmitEmiForm={seTransmitEmiForm}
        setNewAutoProcessRule={setNewAutoProcessRule}
        buttonBlankState={buttonBlankState}
        setButtonBlankState={setButtonBlankState}
        ruleTypeOptions={ruleTypeOptions}
        loadServiceTypeOptions={loadServiceTypeOptions}
        loadDiagnosisOptions={loadDiagnosisOptions}
        orderPriorityOptions={orderPriorityOptions}
        loadProcedureOptions={loadProcedureOptions}
        loadFulfillerGroupOptions={loadFulfillerGroupOptions}
        loadFacilityOptionsForFacility={loadFacilityOptionsForFacility}
        loadPhysicianOptions={loadPhysicianOptions}
        ageOptions={ageOptions}
        genderOptions={genderOptions}
        loadConditionOptions={loadConditionOptions}
        loadInsuranceOptions={loadInsuranceOptions}
        requestTypeOptions={requestTypeOptions}
        toggleValue={toggleValue}
        setToggleValue={setToggleValue}
        additionalDocsOptions={additionalDocsOptions}
        groupId={groupId}
        ruleName={ruleName}
        referralType={referralType}
        patientAgeCondition={patientAgeCondition}
        patientCondition={patientCondition}
        patientInsurance={patientInsurance}
        serviceTypes={serviceTypes}
        orderPriority={orderPriority}
        patientGender={patientGender}
        diagnosis={diagnosis}
        procedures={procedures}
        processStatus={processStatus}
        createdDate={createdDate}
        createdBy={createdBy}
        updatedDate={updatedDate}
        updatedBy={updatedBy}
        senderFacilityId={senderFacilityId}
        setSenderFacilityId={setSenderFacilityId}
        mandatoryItems={mandatoryItems}
        eligibilityCheck={eligibilityCheck}
        preAuthCheck={preAuthCheck}
        preAuthRequestType={preAuthRequestType}
        otherGenerateDocs={otherGenerateDocs}
        autoProcessEnable={autoProcessEnable}
        allowedDiagnosis={allowedDiagnosis}
        allowedProcedure={allowedProcedure}
        allowedInsurances={allowedInsurances}
        allowedServiceTypes={allowedServiceTypes}
        allowedNoOfVisitors={allowedNoOfVisitors}
        allowedPos={allowedPos}
        excludedDiagnosis={excludedDiagnosis}
        excludedProcedure={excludedProcedure}
        excludedInsurances={excludedInsurances}
        excludedServiceTypes={excludedServiceTypes}
        additionalKeyData={additionalKeyData}
        ruleType={ruleType}
        setGroupId={setGroupId}
        setRuleName={setRuleName}
        setReferralType={setReferralType}
        setPatientAgeCondition={setPatientAgeCondition}
        setPatientCondition={setPatientCondition}
        setPatientInsurance={setPatientInsurance}
        setServiceTypes={setServiceTypes}
        setOrderPriority={setOrderPriority}
        setPatientGender={setPatientGender}
        setDiagnosis={setDiagnosis}
        setProcedures={setProcedures}
        setProcessStatus={setProcessStatus}
        setCreatedDate={setCreatedDate}
        setCreatedBy={setCreatedBy}
        setUpdatedDate={setUpdatedDate}
        setUpdatedBy={setUpdatedBy}
        // autoprocess rule configs
        assign_FulfillerGroupId={assign_FulfillerGroupId}
        setAssign_FulfillerGroupId={setAssign_FulfillerGroupId}
        allowedSenderFacility={allowedSenderFacility}
        setAllowedSenderFacility={setAllowedSenderFacility}
        excludedSenderFacility={excludedSenderFacility}
        setExcludedSenderFacility={setExcludedSenderFacility}
        doc_config_transmit_types={doc_config_transmit_types}
        setDoc_config_transmit_types={setDoc_config_transmit_types}
        auto_Assign_Fullfiller={auto_Assign_Fullfiller}
        set_Auto_Assign_Fullfiller={set_Auto_Assign_Fullfiller}
        isEnableDocTransmission={isEnableDocTransmission}
        setIsEnableDocTransmission={setIsEnableDocTransmission}
        referralCategory={referralCategory}
        setReferralCategory={setReferralCategory}
        setMandatoryItems={setMandatoryItems}
        setEligibilityCheck={setEligibilityCheck}
        setPreAuthCheck={setPreAuthCheck}
        setPreAuthRequestType={setPreAuthRequestType}
        setOtherGenerateDocs={setOtherGenerateDocs}
        setAutoProcessEnable={setAutoProcessEnable}
        setAllowedDiagnosis={setAllowedDiagnosis}
        setAllowedProcedure={setAllowedProcedure}
        setAllowedInsurances={setAllowedInsurances}
        setAllowedServiceTypes={setAllowedServiceTypes}
        setAllowedNoOfVisitors={setAllowedNoOfVisitors}
        setAllowedPos={setAllowedPos}
        setExcludedDiagnosis={setExcludedDiagnosis}
        setExcludedProcedure={setExcludedProcedure}
        setExcludedInsurances={setExcludedInsurances}
        setExcludedServiceTypes={setExcludedServiceTypes}
        setAdditionalKeyData={setAdditionalKeyData}
        setRuleType={setRuleType}
        referralTypeOptions={referralTypeOptions}
        selectedFulfillerGroup={selectedFulfillerGroup}
        configuredDocOnSent={configuredDocOnSent}
        setConfiguredDocOnSent={setConfiguredDocOnSent}
        selectedServiceTypes={selectedServiceTypes}
        selectedOrderPriority={selectedOrderPriority}
        selectedPhysicianIds={selectedPhysicianIds}
        selectedConditions={selectedConditions}
        selectedInsurances={selectedInsurances}
        selectedDiagnosis={selectedDiagnosis}
        selectedProcedure={selectedProcedure}
        selectedMandatoryItems={selectedMandatoryItems}
        selectedFulfillerFacility={selectedFulfillerFacility}
        setSelectedServiceTypes={setSelectedServiceTypes}
        setSelectedOrderPriority={setSelectedOrderPriority}
        setSelectedPhysicianIds={setSelectedPhysicianIds}
        setSelectedConditions={setSelectedConditions}
        setSelectedInsurances={setSelectedInsurances}
        setSelectedDiagnosis={setSelectedDiagnosis}
        setSelectedMandatoryItems={setSelectedMandatoryItems}
        setSelectedFulfillerGroup={setSelectedFulfillerGroup}
        setSelectedFulfillerFacility={setSelectedFulfillerFacility}
        setSelectedProcedure={setSelectedProcedure}
        referralRuleData={referralRuleData}
        setReload={setReload}
        reload={reload}
//         selectedSenderGroup={selectedSenderGroup}
//   setSelectedSenderGroup={setSelectedSenderGroup}
//   setEditSelectedSenderGroup={setEditSelectedSenderGroup}
// editSenderGroup= {editSenderGroup}
//         // loadOptionsForReferralCategory={loadOptionsForReferralCategory}
      />
      <EditAutoProcessRule
        newAutoProcessRule={newAutoProcessRule}
        setNewAutoProcessRule={setNewAutoProcessRule}
        buttonBlankState={buttonBlankState}
        setButtonBlankState={setButtonBlankState}
        ruleTypeOptions={ruleTypeOptions}
        loadServiceTypeOptions={loadServiceTypeOptions}
        loadDiagnosisOptions={loadDiagnosisOptions}
        orderPriorityOptions={orderPriorityOptions}
        loadProcedureOptions={loadProcedureOptions}
        loadFulfillerGroupOptions={loadFulfillerGroupOptions}
        loadFacilityOptionsForFacility={loadFacilityOptionsForFacility}
        loadPhysicianOptions={loadPhysicianOptions}
        ageOptions={ageOptions}
        genderOptions={genderOptions}
        loadConditionOptions={loadConditionOptions}
        loadInsuranceOptions={loadInsuranceOptions}
        requestTypeOptions={requestTypeOptions}
        toggleValue={toggleValue}
        setToggleValue={setToggleValue}
        additionalDocsOptions={additionalDocsOptions}
        editAutoProcessRule={editAutoProcessRule}
        setEditAutoProcessRule={setEditAutoProcessRule}
        editgroupId={editgroupId}
        editruleName={editruleName}
        editreferralType={editreferralType}
        editpatientAgeCondition={editpatientAgeCondition}
        editpatientCondition={editpatientCondition}
        editpatientInsurance={editpatientInsurance}
        editserviceTypes={editserviceTypes}
        editorderPriority={editorderPriority}
        editpatientGender={editpatientGender}
        editdiagnosis={editdiagnosis}
        editprocedures={editprocedures}
        editprocessStatus={editprocessStatus}
        editcreatedDate={editcreatedDate}
        editcreatedBy={editcreatedBy}
        editupdatedDate={editupdatedDate}
        editupdatedBy={editupdatedBy}
        editfulfillerGroupId={editfulfillerGroupId}
        editfulfillerFacilityId={editfulfillerFacilityId}
        editSenderFacilityId={editSenderFacilityId}
        editfulfillerPhysicianId={editfulfillerPhysicianId}
        editmandatoryItems={editmandatoryItems}
        editeligibilityCheck={editeligibilityCheck}
        editpreAuthCheck={editpreAuthCheck}
        editpreAuthRequestType={editpreAuthRequestType}
        editotherGenerateDocs={editotherGenerateDocs}
        editautoProcessEnable={editautoProcessEnable}
        editallowedDiagnosis={editallowedDiagnosis}
        editallowedProcedure={editallowedProcedure}
        editallowedInsurances={editallowedInsurances}
        editallowedServiceTypes={editallowedServiceTypes}
        editallowedNoOfVisitors={editallowedNoOfVisitors}
        editallowedPos={editallowedPos}
        editexcludedDiagnosis={editexcludedDiagnosis}
        editexcludedProcedure={editexcludedProcedure}
        editexcludedInsurances={editexcludedInsurances}
        editexcludedServiceTypes={editexcludedServiceTypes}
        editadditionalKeyData={editadditionalKeyData}
        editruleType={editruleType}
        setEditGroupId={setEditGroupId}
        setEditRuleName={setEditRuleName}
        setEditReferralType={setEditReferralType}
        setEditPatientAgeCondition={setEditPatientAgeCondition}
        setEditPatientCondition={setEditPatientCondition}
        setEditPatientInsurance={setEditPatientInsurance}
        setEditServiceTypes={setEditServiceTypes}
        setEditOrderPriority={setEditOrderPriority}
        setEditPatientGender={setEditPatientGender}
        setEditDiagnosis={setEditDiagnosis}
        setEditProcedures={setEditProcedures}
        setEditProcessStatus={setEditProcessStatus}
        setEditCreatedDate={setEditCreatedDate}
        setEditCreatedBy={setEditCreatedBy}
        setEditUpdatedDate={setEditUpdatedDate}
        setEditUpdatedBy={setEditUpdatedBy}
        setEditFulfillerGroupId={setEditFulfillerGroupId}
        setEditFulfillerFacilityId={setEditFulfillerFacilityId}
        setEditSenderFacilityId={setEditSenderFacilityId}
        setEditFulfillerPhysicianId={setEditFulfillerPhysicianId}
        setEditMandatoryItems={setEditMandatoryItems}
        setEditEligibilityCheck={setEditEligibilityCheck}
        setEditPreAuthCheck={setEditPreAuthCheck}
        setEditPreAuthRequestType={setEditPreAuthRequestType}
        setEditOtherGenerateDocs={setEditOtherGenerateDocs}
        setEditAutoProcessEnable={setEditAutoProcessEnable}
        setEditAllowedDiagnosis={setEditAllowedDiagnosis}
        setEditAllowedProcedure={setEditAllowedProcedure}
        setEditAllowedInsurances={setEditAllowedInsurances}
        setEditAllowedServiceTypes={setEditAllowedServiceTypes}
        setEditAllowedNoOfVisitors={setEditAllowedNoOfVisitors}
        setEditAllowedPos={setEditAllowedPos}
        setEditExcludedDiagnosis={setEditExcludedDiagnosis}
        setEditExcludedProcedure={setEditExcludedProcedure}
        setEditExcludedInsurances={setEditExcludedInsurances}
        setEditExcludedServiceTypes={setEditExcludedServiceTypes}
        setEditAdditionalKeyData={setEditAdditionalKeyData}
        setEditRuleType={setEditRuleType}
        referralRuleData={referralRuleData}
        referralTypeOptions={referralTypeOptions}
        selectedServiceTypes={selectedServiceTypes}
        setSelectedServiceTypes={setSelectedServiceTypes}
        setSelectedDiagnosis={setSelectedDiagnosis}
        selectedDiagnosis={selectedDiagnosis}
        selectedOrderPriority={selectedOrderPriority}
        setSelectedOrderPriority={setSelectedOrderPriority}
        selectedProcedure={selectedProcedure}
        setSelectedProcedure={setSelectedProcedure}
        selectedFulfillerGroup={selectedFulfillerGroup}
        setSelectedFulfillerGroup={setSelectedFulfillerGroup}
        selectedFulfillerFacility={selectedFulfillerFacility}
        setSelectedFulfillerFacility={setSelectedFulfillerFacility}
        selectedPhysicianIds={selectedPhysicianIds}
        setSelectedPhysicianIds={setSelectedPhysicianIds}
        setSelectedConditions={setSelectedConditions}
        selectedConditions={selectedConditions}
        setSelectedInsurances={setSelectedInsurances}
        selectedInsurances={selectedInsurances}
        editPatientAge={editPatientAge}
        setEditPatientAge={setEditPatientAge}
        editConditionLabel={editConditionLabel}
        setEditConditionLabel={setEditConditionLabel}
        setAllowedNoOfVisitors={setAllowedNoOfVisitors}
        allowedNoOfVisitors={allowedNoOfVisitors}
        visitNumbers={visitNumbers}
        setVisitNumbers={setVisitNumbers}
        setReload={setReload}
        fetchAutoProcessExceptionRules={fetchAutoProcessExceptionRules}
        // loadOptionsForReferralCategory={loadOptionsForReferralCategory}
        setEditReferralCategory={setEditReferralCategory}
        editReferralCategory={editReferralCategory}
        editAuto_Assign_Fullfiller={editAuto_Assign_Fullfiller}
        setEditAuto_Assign_Fullfiller={setEditAuto_Assign_Fullfiller}
        editAassign_FulfillerGroupId={editAassign_FulfillerGroupId}
        setEditAassign_FulfillerGroupId={setEditAassign_FulfillerGroupId}
        configuredDocOnSent={configuredDocOnSent}
        setConfiguredDocOnSent={setConfiguredDocOnSent}
        editTransmitEmiForm={editTransmitEmiForm}
        setEditTransmitEmiForm={setEditTransmitEmiForm}
        editFaxNumber={editFaxNumber}
        setEditFaxNumber={setEditFaxNumber}
        editAllowedFulfillerFacilty={editAllowedFulfillerFacilty}
        setEditAllowedFullfillerFacility={setEditAllowedFullfillerFacility}
        editAllowedFulfillerphysician={editAllowedFulfillerphysician}
        setEditAllowedFullfillerPhysician={setEditAllowedFullfillerPhysician}
        editAllowedFulfillerGroup={editAllowedFulfillerGroup}
        setEditAllowedFullfillerGroup={setEditAllowedFullfillerGroup}
        editExcludedFulfillerFacilty={editExcludedFulfillerFacilty}
        setEditExcludedFullfillerFacility={setEditExcludedFullfillerFacility}
        editExcludedFulfillerphysician={editExcludedFulfillerphysician}
        setEditExcludedFullfillerPhysician={setEditExcludedFullfillerPhysician}
        editExcludedFulfillerGroup={editExcludedFulfillerGroup}
        setEditExcludedFullfillerGroup={setEditExcludedFullfillerGroup}
        selectedAllowedFulfillerGroup={selectedAllowedFulfillerGroup}
        setSelectedAllowedFulfillerGroup={setSelectedAllowedFulfillerGroup}
        selectedAllowedFulfillerFacility={selectedAllowedFulfillerFacility}
        setSelectedAllowedFulfillerFacility={
          setSelectedAllowedFulfillerFacility
        }
        selectAttachmentType={selectAttachmentType}
        setSelectAttachmentType={setSelectAttachmentType}
        selectedAllowedFulfillerPhysician={selectedAllowedFulfillerPhysician}
        setSelectedAllowedFulfillerPhysician={
          setSelectedAllowedFulfillerPhysician
        }
        selectedExcludedFulfillerGroup={selectedExcludedFulfillerGroup}
        setSelectedExcludedFulfillerGroup={setSelectedExcludedFulfillerGroup}
        selectedExcludedFulfillerFacility={selectedExcludedFulfillerFacility}
        setSelectedExcludedFulfillerFacility={
          setSelectedExcludedFulfillerFacility
        }
        selectedExcludedFulfillerPhysician={selectedExcludedFulfillerPhysician}
        setSelectedExcludedFulfillerPhysician={
          setSelectedExcludedFulfillerPhysician
        }
        transmitAdditionalAttachments={transmitAdditionalAttachments}
        setTransmitAdditionalAttachments={setTransmitAdditionalAttachments}
        editAttachmentType={editAttachmentType}
        setEditAttachmentType={setEditAttachmentType}
        setEditRuleId={setEditRuleId}
        editRuleId={editRuleId}
        minValue={minValue}
        setMinValue={setMinValue}
        maxValue={maxValue}
        setMaxValue={setMaxValue}
        selectedSenderGroup={selectedSenderGroup}
        setSelectedSenderGroup={setSelectedSenderGroup}
        setEditSelectedSenderGroup={setEditSelectedSenderGroup}
      editSenderGroup= {editSenderGroup}
      editSuggestingForm={editSuggestingForm}
setEditSuggestingForm={setEditSuggestingForm}
        // editIsEnableDocTransmission={editIsEnableDocTransmission}
        // setEditIsEnableDocTransmission={setEditIsEnableDocTransmission}
        // editDoc_config_transmit_types={editDoc_config_transmit_types}
        // setEditDoc_config_transmit_types={setEditDoc_config_transmit_types}
      />
      <DeleteConfirmationModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteFunction={deleteAutoProcessExceptionRules}
      />
    </div>
  );
};
export default AutoProcessRule;
