import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Cookie from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import  Select  from "react-select";

const EditAllProviderSupportedInsurance = (props) => {
  const {
    userDataReload,
    setUserDataReload,
    editProviderInsuranceModal,
    setEditProviderInsuranceModal,
    editProviderName,
    setEditProviderName,
    editGroupName,
    setEditGroupName,
    editProviderNpi,
    setEditProviderNpi,
    editInsuranceName,
    setEditInsuranceName,
    editProviderMappingID,
    setshowRemoveButton,
    showRemoveButton,
    editProviderInsuranceType,
    insuranceType
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [spinner, setSpinner] = useState(false);
  const [updateButtonStatus, setUpdateButtonStatus] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");
  // const [showRemoveButton, setshowRemoveButton] = useState(false);
  const [groupID, setGroupID] = useState("");
  const [selectedInsuranceType, setSelectedInsuranceType]=useState(null)

  const toggle = () => {
    setEditProviderInsuranceModal(!editProviderInsuranceModal);
  };

  const confirmation = () => {
    if (!editInsuranceName[0].value) {
      toastr.error("Insurance Field Is Required", "Please select insurance!");
    }
    if (!editInsuranceName[0].code) {
      toastr.error("Insurance Code Is Required", "Please enter the insurance code!");
    }

    if (editInsuranceName[0].value && editInsuranceName[0].code) {
      updateProviderInsurance();
    }
  };

  const updateProviderInsurance = () => {

    setSpinner(true);
    // debugger
    // eslint-disable-next-line array-callback-return
    const result = editInsuranceName.map((item, index) => {
    if (item.code !== "" && item.label !== "") {
      const providerMappingID =
        editProviderMappingID[index] ||
        -1 * Math.floor(Math.random() * 1000000);
      return {
        ProviderMappingID: providerMappingID,
        groupID: groupID,
        ProviderNpi: editProviderNpi,
        insuranceCode: item.code,
        InsuranceName: item.label,
        providerName: editProviderName,
        insuranceType:selectedInsuranceType?selectedInsuranceType?.value:""
      };
    }
  })
  .filter((item) => item !== undefined);
    
    console.table(
    "editProviderMappingID:",
    editProviderMappingID,
    "editGroupName:",
    editGroupName,
    "editProviderName:",
    editProviderName,
    "editProviderNpi:",
    editProviderNpi,
    "editInsuranceName:",
    editInsuranceName,
    "editInsuranceType:",
    editProviderInsuranceType
    );
    console.log(result);

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-provider-insurance-mapping/`,
        {
          result: result ? result : "",
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setEditProviderInsuranceModal(!editProviderInsuranceModal);
        setUserDataReload(userDataReload + 1);
        toastr.success(
          "Successfully Updated",
          "Insurance details successfully updated"
        );
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error("Update Failed", "Please try again");
      });
  };

  const disableUpdateButton = () => {
    if (editInsuranceName) {
      setUpdateButtonStatus(false);
    } else {
      setUpdateButtonStatus(true);
    }
  };

  useEffect(() => {
    disableUpdateButton();
  }, [editInsuranceName]); // eslint-disable-line react-hooks/exhaustive-deps

  // utility function to get the previous value of a variable
  // function usePrevious(value) {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value;
  //   });
  //   return ref.current;
  // }
  // const prevLength = usePrevious(editInsuranceName.length);
  //   -----
  const handleAddItem = () => {
    // debugger
    // Check if the last item in the state array is not equal to { label: "", value: 0, code: "" }
    const lastItem = editInsuranceName[editInsuranceName.length - 1];
    if (
      lastItem &&
      lastItem.label === "" &&
      lastItem.value === 0 &&
      lastItem.code === ""
    ) {
      return; // If the last item is equal to { label: "", value: 0, code: "" }, exit the function
    }

    // Add a new item with empty values to the state array
    setEditInsuranceName([
      ...editInsuranceName,
      { label: "", value: 0, code: "" },
    ]);
  };

  const handleDeleteItem = (index) => {
    
    // Remove the item at the specified index from the state array
    if (editInsuranceName.length <= 2) {
      setshowRemoveButton(false);
    }

    setEditInsuranceName([
      ...editInsuranceName.slice(0, index),
      ...editInsuranceName.slice(index + 1),
    ]);
  };

  const handleUpdateItem = useCallback((index, updatedItem) => {
    // debugger
    // Update the item at the specified index with the new values
  
    setEditInsuranceName([
      ...editInsuranceName.slice(0, index),
      updatedItem,
      ...editInsuranceName.slice(index + 1),
    ]);
  }, [editInsuranceName]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const handleLabelChange = useCallback((index, label, value) => {   // eslint-disable-line
    // debugger
    // Update the label and value of the item at the specified index
  
    handleUpdateItem(index, {
      ...editInsuranceName[index],
      label,
      value,
    });
    if (editInsuranceName.length >= 2) {
      setshowRemoveButton(true);
    }
  }, [editInsuranceName, handleUpdateItem]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNameChange = useCallback((index, event) => {
    // debugger
    // Update the code of the item at the specified index

    handleUpdateItem(index, {
      ...editInsuranceName[index],
      label: event.target.value,
    });
    if (editInsuranceName.length >= 2) {
      setshowRemoveButton(true);
    }
  }, [editInsuranceName, handleUpdateItem]);//eslint-disable-line
  
  const handleCodeChange = useCallback((index, event) => {
    // debugger
    // Update the code of the item at the specified index
  
    handleUpdateItem(index, {
      ...editInsuranceName[index],
      code: event.target.value,
    });
    if (editInsuranceName.length >= 2) {
      setshowRemoveButton(true);
    }
  }, [editInsuranceName, handleUpdateItem]); // eslint-disable-line react-hooks/exhaustive-deps 

  const loadGroupID = async (editGroupName) => {
    if (editGroupName) {
      const searchParams = new URLSearchParams({ name: editGroupName });
      const url = `${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`;
      try {
        const response = await AxiosInter.get(url, {
          headers: {
            Authorization: API_TOKEN,
          },
        });
        const data = response.data;
  
        const groupNameOptions = data.data;
        if (groupNameOptions.length > 0) {
          return groupNameOptions[0].value;
        }
      } catch (error) {
        // Handle and log the error appropriately
        console.error("An error occurred:", error);
      }
    }
    return null;
  };
  

  useEffect(() => {
    const fetchGroupID = async () => {
      const groupID = await loadGroupID(editGroupName);
      console.log("groupID: ", groupID);
      setGroupID(groupID);
    };
    fetchGroupID();
  }, [editGroupName]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = value => {setInputValue(value)};  // eslint-disable-line

  useEffect(()=>{
    setSelectedInsuranceType(editProviderInsuranceType)
  },[editProviderInsuranceType])
 
  return (
    <div>
      <Modal isOpen={editProviderInsuranceModal} size="lg" scrollable={true}>
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit Supported Insurance
        </ModalHeader>
        <ModalBody className="">
          {spinner && <Spinner />}
          <FormGroup>
          
            <Row>
              <Col sm={2}>
                <Label>Group Name:</Label>
              </Col>
              <Col sm={3}>
                <Input
                  id="name"
                  value={editGroupName}
                  placeholder="Enter Group name"
                  disabled
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditGroupName(e.target.value);
                  }}
                />
              </Col>
            </Row><br></br>
            <Row>
              <Col sm={2}>
                <Label>Provider Name:</Label>
              </Col>
              <Col sm={3}>
                <Input
                  id="name"
                  value={editProviderName}
                  placeholder="Enter Provider name"
                  disabled
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditProviderName(e.target.value);
                  }}
                />
              </Col>
            </Row><br></br>
            <Row>
              <Col sm={2}>
                <Label>Provider NPI</Label>
              </Col>
              <Col sm={3}>
                <Input
                  id="name"
                  value={editProviderNpi}
                  placeholder="Enter NPI"
                  disabled
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditProviderNpi(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={2}>
                <Label>Insurance Type</Label>
              </Col>
              <Col sm={3}>
                <Select
                  options={insuranceType}
                  value={selectedInsuranceType}
                  onChange={(e) => {
                    setSelectedInsuranceType(e)
                  }}                 
                />
              </Col>
            </Row>
            <br />
            {editInsuranceName.map((item, index) => (
              <Row key={index}>
                <Col sm={2}>
                  <Label>
                    Insurance Name <span className="required">*</span>
                  </Label>
                </Col>
                <Col sm={4}>
                <Input
                    type="text"
                    value={item.label}
                    onChange={(event) => handleNameChange(index, event)}
                  />
                  {/* <AsyncSelect
                    cacheOptions
                    isClearable
                    isSearchable
                    value={{ value: item.value, label: item.label }}
                    onInputChange={handleInputChange}
                    loadOptions={loadOptionsForInsurance}
                    onChange={(selectedOption) => {
                      // debugger
                      // if (typeof value === 'undefined' && typeof label === 'undefined') {
                      //   // handle undefined value and label case
                      //   console.log('Both value and label are undefined');
                      //   return;
                      // }
                      
                      if (selectedOption != null) {
                        console.log('Both value and label are undefined');
                        const { label, value } = selectedOption;
                        handleLabelChange(index, label, value);
                       
                      } 
                     else{
                      console.log('Both value and label are undefined');
                      const value = null
                      let label = null
                    
                      handleLabelChange(index, label, value);
                     }
                     
                    }}
                    // onMenuClose={() => {
                    //   if (!item.label) {
                    //     handleLabelChange(index, "", "");
                    //   }
                    // }}
                    placeholder="Enter Insurances"
                  /> */}
                </Col>
                <br></br>
                <br></br>

                <Col sm={2}>
                  <Label>
                    Insurance Code <span className="required">*</span>
                  </Label>
                </Col>
                <Col sm={2}>
                  <Input
                    type="text"
                    placeholder=""
                    value={item.code}
                    onChange={(event) => handleCodeChange(index, event)}
                  />
                </Col>
                {showRemoveButton === true ? (
                  <Col sm={1}>
                    <FontAwesomeIcon
                      icon={faTrash}
                      color={"#E12C2C"}
                      size={"1x"}
                      style={{ cursor: "pointer" }}
                      disabled={showRemoveButton}
                      onClick={() => {
                        handleDeleteItem(index);
                      }}
                      title="Remove"
                    />
                  </Col>
                ) : (
                  ""
                )}
                <Col sm={1}>
                  <FontAwesomeIcon
                    icon={faPlus}
                    size={"1x"}
                    color={"#3f86fa"}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleAddItem();
                      setshowRemoveButton(true);
                    }}
                    title="Add"
                  />
                </Col>
              </Row>
            ))}
          </FormGroup>
        </ModalBody>

        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button
            onClick={() => {
              confirmation();
            }}
            disabled={updateButtonStatus}
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditAllProviderSupportedInsurance;
