import React, { useState, useEffect,useContext } from "react";
import {
  Button,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Cookie from 'js-cookie';
import { GlobalState } from "../../contextAPI/Context"
import Select from "react-select";

const EditTemplateModal = (props) => {
  const {
    editTemplateID, // eslint-disable-line
    setUserDataReload,
    userDataReload,
    editTemplateModal,
    setEditTemplateModal,
    editGroupName,
    setEditGroupName,
    editTemplatName,setEditTemplateName,templateDropDown

  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const { groupNamesForDropDown } = useContext(GlobalState);

  const [spinner, setSpinner] = useState(false);
  const [updateButtonStatus, setUpdateButtonStatus] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");

  const toggle = () => {
    setEditTemplateModal(!editTemplateModal);
  };

  const confirmation = () => {
    if (!editGroupName) {
      toastr.error("Name Field Is Required", "Please enter the group name!");
    }

    if (!editTemplatName) {
      toastr.error("Template Field Is Required", "Please enter the template name!");
    }

    if (editGroupName && editTemplatName) {
      updateTemplateCall();
    }
  };

  const updateTemplateCall = () => {
    setSpinner(true);
    let groupId_array = []
    if (editGroupName.length >=1){
         groupId_array = editGroupName.map(item => item.value)
    }
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/configure-group-with-edoc/`,
  {
           "isEdit":true,
          "group_id": editGroupName ? groupId_array:null,
          "template_id":editTemplatName ? editTemplatName.value:null
   
         
      },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setEditTemplateModal(!editTemplateModal);
        setUserDataReload(userDataReload + 1);
        toastr.success(
          "Successfully Updated",
          "Group Template Configuration successfully updated"
        );
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error("Update Failed", "Please try again");
      });
  };

  const handleInputChange = (value) => { // eslint-disable-line
    setInputValue(value);
  };
  const handleGroupNameChange = (value) => {
    setEditGroupName(value);
  };
  const handleTemplateNameChange = (value) => { // eslint-disable-line
    setEditTemplateName(value);
  };




  //   const fetchGroupById = (editGroupName) => {

  //     AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m-centerid-list/${Number(editCenter)}`, {
  //     headers: {
  //         Authorization: API_TOKEN,
  //     },
  //     })
  //     .then(response => {

  //         if (response.data.data.length > 0) {
  //            setCenter(response.data.data)
  //         }
  //     })
  //     .catch(error => {

  //         toastr.error('Error','Something went wrong in region listing !!')

  //     });
  //   };

  const disableUpdateButton = () => {
    if (editGroupName && editTemplatName) {
      setUpdateButtonStatus(false);
    } else {
      setUpdateButtonStatus(true);
    }
  };

  useEffect(() => {
    disableUpdateButton();
  }, [editGroupName, editTemplatName]); // eslint-disable-line

  //    useEffect(()=>{
  //     if(editGroupName !== '' && editGroupName !== undefined){

  //         handleGroupNameChange(inputValue)
  //     }
  //   },[editGroupName])   // eslint-disable-line

  return (
    <div>
      <Modal
        isOpen={editTemplateModal}
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit Template{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          {spinner ? <Spinner /> : ""}
          <FormGroup>
            <Row>
              <Col sm={6}>
              <Label>Group Name</Label>
              <Select
                    options={groupNamesForDropDown}
                    onChange={(event) => handleGroupNameChange(event)}
                    value={editGroupName}
                    placeholder="Select Group"
                    isSearchable
                    isClearable
                    isMulti
                  />
              </Col>
              <Col sm={6}>
              <Label>Template Name</Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(event) => {
                  setEditTemplateName(event);
                }}
                isClearable
                isSearchable
                placeholder="Select"
                value={editTemplatName}
                options={templateDropDown}
              />
              </Col>
            </Row>
            <br />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button
            onClick={() => {
              confirmation();
            }}
            disabled={updateButtonStatus}
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditTemplateModal;
