import React, { useState, useEffect } from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, FormGroup, Input} from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';

import AsyncSelect from 'react-select/async';
// eslint-disable-next-line no-unused-vars
import _ from "lodash";
//import { NULL } from 'node-sass';

const NewOrderTypeModal = (props) => {
   const { setNewEditReferralOrderTypeModal, newReferralOrderTypeModal,userDataReload,setUserDataReload,newOrderType,setNewOrderType,newOrderCategory,setNewOrderCategory,OrderCategoryloadOptions} = props;

    

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
     
    // eslint-disable-next-line no-unused-vars
    const [inputValue, setInputValue] = useState(''); 
    const[grp,setGrp] = useState("")     // eslint-disable-line
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [timeZoneDropDown, setTimeZoneDropDown] = useState([]); // eslint-disable-line

  

    const toggle = () => {
        setNewEditReferralOrderTypeModal(!newReferralOrderTypeModal);

        setNewOrderCategory("")
        setNewOrderType("")
       

    }



    const confirmation = () => {
      // debugger

        if (!newOrderType) {
            toastr.error("Order Type  Is Required", "Please enter the Order Type!")
        }
        if (!newOrderCategory) {
            toastr.error("Category Field Is Required", "Please enter the Category!")
        }
       
       


        if (newOrderType && newOrderCategory) {

            CreateNewOrderType()
        }

    };

   
    

    const CreateNewOrderType = () => {
        const valuesOnly = newOrderCategory.map(item => item.value.toString());
        const params = {
          type: newOrderType ? newOrderType : null,
          category_type: newOrderCategory ? valuesOnly : null
        };
      
        AxiosInter.post(`${API_ENDPOINT}/admin-api/referral-order-types-crud/`, params, {
          headers: {
            Authorization: API_TOKEN
          }
        })
          .then(function (response) {
            if (response.status === 201) {
              setUserDataReload(userDataReload + 1);
              toastr.success("Successfully Created", "Order Type successfully created");
              toggle();
            } else {
              // Handle other success status codes if necessary
              toastr.error("Failed", "Please try again");
            }
          })
          .catch(function (error) {
            console.log(error); // Log the error to the console for debugging
            if (error.response && error.response.data && error.response.data.error) {
              // Extract error message from the response
              const errorMessage = error.response.data.error;
              toastr.error("Failed", errorMessage);
            } else {
              // Handle other types of errors
              toastr.error("Failed", "Please try again");
            }
          });
      };
      


    const disableAddButton = () => {

        if (newOrderType && newOrderCategory ) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }

   
  
   



   

   
   
    useEffect(() => { disableAddButton() }, [newOrderType,newOrderCategory]) // eslint-disable-line


   



const handleInputChange = value => {
    setInputValue(value);
  };
 
  const handleCategoryChange = value => {
    setNewOrderCategory(value);
  }



    return (
        <div>
            <Modal isOpen={newReferralOrderTypeModal}  size="lg" scrollable={true} >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add New Order Type </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                        
            <Col sm={4}>
                            
                            <Label for="state">Order Type</Label>
                                        <Input
                                            id="state"
                                            type = "text"
                                            value = {newOrderType}
                                            
                                            placeholder="Order Type"
                                            onChange={
                                              (e) => 
                                              { console.log(e.target.value)
                                                // debugger
                                                setNewOrderType(e.target.value)}
                                            }
                                           
                                        />
                            </Col>
                            <Col sm={4}>
              <Label>Order Category :</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                isMulti
                type="text"
                name="Order Category"
                placeholder="Search with at least 4 characters."
                value={newOrderCategory}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleCategoryChange}
                loadOptions={(inputValue) => OrderCategoryloadOptions(inputValue)}
              />
            </Col>
            



                        </Row><br />
                        
                       
                           



                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        onClick={()=>{
                            confirmation()
                            
                        }}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Save</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewOrderTypeModal;