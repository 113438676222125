import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  Container,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  CardBody,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import ToggleButton from "react-toggle-button";
import AsyncSelect from "react-select/async";
import { GlobalState } from "../../contextAPI/Context";
import Cookie from "js-cookie";

const EditAutoProcessRule = (props) => {
  const {
    reload,
    setReload,
    preAuthRequestType,
    setPreAuthRequestType,
    referralTypeOptions,
    setEditAutoProcessRule,
    editAutoProcessRule,
    selectedConditions,
    setSelectedConditions,
    setSelectedInsurances,
    selectedInsurances,
    referralRuleData,
    setButtonBlankState,
    ruleTypeOptions,
    loadServiceTypeOptions,
    loadDiagnosisOptions,
    loadProcedureOptions,
    ageOptions,
    genderOptions,
    loadConditionOptions,
    loadInsuranceOptions,
    requestTypeOptions,
    toggleValue,
    setToggleValue,
    additionalDocsOptions,
    editgroupId,
    editruleName,
    editreferralType,
    editpatientAgeCondition,
    editpatientCondition,
    editpatientInsurance,
    editserviceTypes,
    editorderPriority,
    editpatientGender,
    editdiagnosis,
    editprocedures,
    editprocessStatus,
    editcreatedDate,
    editupdatedDate,
    editfulfillerGroupId,
    editfulfillerFacilityId,
    editfulfillerPhysicianId,
    editmandatoryItems,
    editeligibilityCheck,
    editpreAuthCheck,
    editpreAuthRequestType,
    editotherGenerateDocs,
    editautoProcessEnable,
    editallowedDiagnosis,
    editallowedProcedure,
    editallowedInsurances,
    editallowedServiceTypes,
    editallowedPos,
    editexcludedDiagnosis,
    editexcludedProcedure,
    editexcludedInsurances,
    editexcludedServiceTypes,
    editadditionalKeyData,
    setEditGroupId,
    setEditRuleName,
    setEditReferralType,
    setEditPatientAgeCondition,
    setEditPatientAge,
    setEditPatientCondition,
    setEditPatientInsurance,
    setEditServiceTypes,
    setEditOrderPriority,
    setEditPatientGender,
    setEditDiagnosis,
    setEditProcedures,
    setEditProcessStatus,
    setEditCreatedDate,
    setEditCreatedBy,
    setEditUpdatedDate,
    setEditUpdatedBy,
    setEditFulfillerGroupId,
    setEditFulfillerFacilityId,
    setEditFulfillerPhysicianId,
    setEditMandatoryItems,
    setEditEligibilityCheck,
    setEditPreAuthCheck,
    setEditPreAuthRequestType,
    setEditOtherGenerateDocs,
    setEditAutoProcessEnable,
    setEditAllowedDiagnosis,
    setEditAllowedProcedure,
    setEditAllowedServiceTypes,
    setEditAllowedNoOfVisitors,
    setEditAllowedPos,
    setEditExcludedDiagnosis,
    setEditExcludedProcedure,
    setEditExcludedServiceTypes,
    setEditAdditionalKeyData,
    selectedServiceTypes,
    setSelectedServiceTypes,
    setSelectedDiagnosis,
    selectedDiagnosis,
    orderPriorityOptions,
    selectedOrderPriority,
    setSelectedOrderPriority,
    selectedProcedure,
    setSelectedProcedure,
    selectedFulfillerGroup,
    loadFulfillerGroupOptions,
    setSelectedFulfillerGroup,
    selectedFulfillerFacility,
    loadFacilityOptionsForFacility,
    setSelectedFulfillerFacility,
    selectedPhysicianIds,
    loadPhysicianOptions,
    setSelectedPhysicianIds,
    editPatientAge,
    setAllowedNoOfVisitors,
    visitNumbers,
    fetchAutoProcessExceptionRules,
    editReferralCategory,
    setEditReferralCategory,
    editAuto_Assign_Fullfiller,
    setEditAuto_Assign_Fullfiller,
    editAassign_FulfillerGroupId,
    setEditAassign_FulfillerGroupId,
    editSenderFacilityId,
    setEditSenderFacilityId,
    configuredDocOnSent,
    setConfiguredDocOnSent,
    setEditTransmitEmiForm,
    editTransmitEmiForm,
    editFaxNumber,setEditFaxNumber,
    editAllowedFulfillerFacilty,setEditAllowedFullfillerFacility,
    editAllowedFulfillerphysician,setEditAllowedFullfillerPhysician,
    editAllowedFulfillerGroup,setEditAllowedFullfillerGroup,
    editExcludedFulfillerFacilty,setEditExcludedFullfillerFacility,
    editExcludedFulfillerphysician,setEditExcludedFullfillerPhysician,
    editExcludedFulfillerGroup,setEditExcludedFullfillerGroup,
    selectedAllowedFulfillerGroup,
    setSelectedAllowedFulfillerGroup,
    selectedAllowedFulfillerFacility,
    setSelectedAllowedFulfillerFacility,
    selectedAllowedFulfillerPhysician,
    setSelectedAllowedFulfillerPhysician,
    selectedExcludedFulfillerGroup,
    setSelectedExcludedFulfillerGroup,
    selectedExcludedFulfillerFacility,
    setSelectedExcludedFulfillerFacility,
    selectedExcludedFulfillerPhysician,
    setSelectedExcludedFulfillerPhysician,
    transmitAdditionalAttachments,
    setTransmitAdditionalAttachments,
    editAttachmentType,
    selectAttachmentType,
    setSelectAttachmentType,
    editRuleId,minValue,setMinValue,maxValue,setMaxValue
    ,selectedSenderGroup,setSelectedSenderGroup,
    editSenderGroup,setEditSelectedSenderGroup,
    setEditSuggestingForm,editSuggestingForm
  } = props;
  const { groupNamesForDropDown } = useContext(GlobalState);

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [selectedAge, setSelectedAge] = useState(""); // eslint-disable-line
  const [selectedAgeCondition, setSelectedAgeCondition] = useState("");
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);
  const [checkedFour, setCheckedFour] = useState(false);
  const [checkedFive, setCheckedFive] = useState(false);
  const [checkedSix, setCheckedSix] = useState(false);
  const [checkedSeven, setCheckedSeven] = useState(false);
  const [checkedEight, setCheckedEight] = useState(false);
  const [checkedNine, setCheckedNine] = useState(false);
  const [tempDiagnosis, setTempDiagnosis] = useState([]); // eslint-disable-line
  const [selectedAllowedServiceTypes, setSelectedAllowedServiceTypes] =
    useState([]);
  const [selectedExcludedServiceTypes, setSelectedExcludedServiceTypes] =
    useState([]);
  const [selectedAllowedDiagnosis, setSelectedAllowedDiagnosis] = useState([]);
  const [selectedExcludedDiagnosis, setSelectedExcludedDiagnosis] = useState(
    []
  );
  const [selectedAllowedProcedure, setSelectedAllowedProcedure] = useState([]);
  const [selectedExcludedProcedure, setSelectedExcludedProcedure] = useState(
    []
  );
  const [selectedAllowedInsurance, setSelectedAllowedInsurance] = useState([]);
  const [selectedExcludedInsurance, setSelectedExcludedInsurance] = useState(
    []
  );
  const [selectedadditionalDocs, setSelectedadditionalDocs] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setEditAutoProcessRule(!editAutoProcessRule);
    setEditGroupId();
    setEditRuleName("");
    setEditReferralType("");
    setEditPatientAgeCondition([]);
    setEditPatientCondition("");
    setEditPatientInsurance("");
    setEditServiceTypes("");
    setEditOrderPriority("");
    setEditPatientGender("");
    setEditDiagnosis("");
    setEditProcedures("");
    setEditProcessStatus();
    setEditCreatedDate("");
    setEditCreatedBy();
    setEditUpdatedDate("");
    setEditUpdatedBy();
    setEditFulfillerGroupId("");
    setEditSelectedSenderGroup([]);
    setEditFulfillerFacilityId([]);
    setEditFulfillerPhysicianId([]);
    setEditAllowedFullfillerGroup([]);
    setEditAllowedFullfillerPhysician([]);
    setEditAllowedFullfillerFacility([]);
    setEditExcludedFullfillerGroup([]);
    setEditExcludedFullfillerPhysician([]);
    setEditExcludedFullfillerFacility([]);
    setEditMandatoryItems("");
    setEditEligibilityCheck();
    setEditPreAuthCheck();
    setEditPreAuthRequestType("");
    setEditOtherGenerateDocs("");
    setEditAutoProcessEnable();
    setEditAllowedDiagnosis("");
    setEditAllowedProcedure("");
    setEditAllowedServiceTypes("");
    setEditAllowedNoOfVisitors("");
    setEditAllowedPos();
    setEditExcludedDiagnosis("");
    setEditExcludedProcedure("");
    setEditExcludedServiceTypes([]);
    setEditAdditionalKeyData();
    setSelectedAge("");
    setSelectedAgeCondition("");
    setSelectedProcedure("");
    setSelectedAllowedProcedure("");
    setSelectedExcludedProcedure("");
    setSelectedConditions("");
    setSelectedInsurances("");
    setSelectedAllowedServiceTypes("");
    setSelectedExcludedServiceTypes([]);
    setSelectedAllowedDiagnosis("");
    setSelectedExcludedDiagnosis("");
    setSelectedAllowedInsurance("");
    setSelectedExcludedInsurance("");
    setSelectedOrderPriority("");
    setCheckedOne("");
    setCheckedTwo("");
    setCheckedThree("");
    setCheckedFour("");
    setCheckedFive("");
    setCheckedSix("");
    setCheckedSeven("");
    setCheckedEight("");
    setCheckedNine("");
    setEditPatientAge("");
  };
  useEffect(() => {
    console.log(editReferralCategory);
  }, [editReferralCategory]);
 
  const loadFulfillerOptions = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-fulfillergroup-type-list/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        // .catch((error) => {
        //   throw error;
        // });
    }
  };


  const loadOptionsForReferralCategory = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-category-type-options-async/${inputValue.toString()}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then((response) => response.data.data)
        // .catch((error) => {
        //   throw error;
        // });
    }
  };


  const loadOptionsForSenderFacility = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-sender-facility-list/${inputValue.toString()}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then((response) => response.data.data)
        // .catch((error) => {
        //   throw error;
        // });
    }
  };

  const loadSenderGroupOptions = (inputValue) => {
    inputValue= editgroupId.value
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-sender-group-name/${inputValue}`,{
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        // .catch((error) => {
        //   throw error;
        // });
  };

  useEffect(()=>{
      // eslint-disable-next-line eqeqeq    
    if(editgroupId !="" && editgroupId != undefined&& editgroupId.value != undefined) {
      loadSenderGroupOptions(editgroupId.value);
  }},[editgroupId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handelOnChangeForReferralRuleItem = (e, item) => {
    let name = item.item;
    let itemId = item.id;
    if (name === "Guarantor Details") {
      setCheckedOne(e.target.checked);

      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Pre Auth") {
      setCheckedTwo(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        tempDiagnosis.push(JSON.stringify(itemId));
        // console.log('284',editmandatoryItems.filter(n => !tempDiagnosis.includes(n)))
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
      }
    }
    if (name === "Lab Report") {
      setCheckedThree(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Diagnosis") {
      setCheckedFour(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedDiagnosis([]);
        setSelectedExcludedDiagnosis([]);
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Emergency Contact") {
      setCheckedFive(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Procedure") {
      setCheckedSix(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedProcedure([]);
        setSelectedExcludedProcedure([]);
        setAllowedNoOfVisitors([]);
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Referral reason") {
      setCheckedSeven(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Insurance Details") {
      setCheckedEight(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedInsurance([]);
        setSelectedExcludedInsurance([]);
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "PCP Notes") {
      setCheckedNine(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
  };

  const handelCheckedForReferralRuleItem = (item) => {
    // eslint-disable-next-line no-unused-vars
    let itemId = item.id;

    // eslint-disable-next-line eqeqeq
    if (item == "Guarantor Details") {
      return checkedOne ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Pre Auth") {
      return checkedTwo ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Lab Report") {
      return checkedThree ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Diagnosis") {
      return checkedFour ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Emergency Contact") {
      return checkedFive ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Procedure") {
      return checkedSix ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Referral reason") {
      return checkedSeven ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Insurance Details") {
      return checkedEight ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "PCP Notes") {
      return checkedNine ? true : false;
    }
  };

  // const patientAgePopulate = () => {
  //     if (selectedAge && selectedAgeCondition) {
  //         setEditPatientAgeCondition([{ "age": selectedAge, "max": "0", "min": "0", "condition": selectedAgeCondition.value }])
  //     }
  //     else if (selectedAgeCondition.label == "Between") {
  //         setEditPatientAgeCondition([{ "age": "0", "max": maxValue, "min": minValue, "condition": selectedAgeCondition }])
  //     }
  //     else {
  //         setEditPatientAgeCondition("")
  //     }
  // }
  // useEffect(() => {
  //     if (selectedAge && selectedAgeCondition)
  //         patientAgePopulate()

  // }, [selectedAge, selectedAgeCondition]) // eslint-disable-line


  // const loadSuggestingFormOptions = (inputValue) => {
  //   const gid = editgroupId.value
  //   if (inputValue.length >= 1) {
  //     var temp = fetch(
  //       `${API_ENDPOINT}/admin-api/get-suggesting-form-load-options/${gid}/${inputValue}`,
  //       {
  //         headers: {
  //           Authorization: API_TOKEN,
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => data.data);
  //     return temp;
  //   }
  // };

  const loadSuggestingFormOptions = (inputValue) => {
    const gid = editgroupId.value
    if (inputValue.length >= 1) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-suggesting-form-load-options/${gid}/${inputValue}`,{
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const editAutoProcessRules = () => {
 
    // eslint-disable-next-line eqeqeq
    if (preAuthRequestType == "SC") {
      setPreAuthRequestType("Referral");
      // eslint-disable-next-line eqeqeq
    } else if (preAuthRequestType == "HS") {
      setPreAuthRequestType("outPatient Authorization");
    }

    // var temp = []
    // var orderPriorityArray = []
    // if (typeof orderPriority == 'object') {
    //     temp = orderPriority
    // } else {
    //     temp = orderPriority.split(",")
    // }
    // if (temp) {
    //     temp.map((item) => {
    //         orderPriorityArray.push(item.trim())
    //     })
    // }
    let admin_id = localStorage.getItem("adminID");
    if (selectedServiceTypes && selectedServiceTypes.length > 0) {
      // eslint-disable-next-line array-callback-return
      var serviceTypesResult = selectedServiceTypes.map((item) => {
        if (item && item.value) {
          console.log(item);
          console.log(item.value);
         
          return item.value.toString();
        }
      });
    }
    // let patientAgeConditionArray=selectedAge
       // eslint-disable-next-line eqeqeq   
    if (selectedAgeCondition && selectedAgeCondition.label != "Between") {
      // debugger
      // if editpatientAgeCondition present set editpatientAgeCondition length to zero
      if (editpatientAgeCondition && editpatientAgeCondition.length > 0) {
        editpatientAgeCondition.length = 0;
      }
      
      //  patientAgeConditionArray.push([{ "age": editPatientAge, "max": "0", "min": "0", "condition": selectedAgeCondition.value }])
      editpatientAgeCondition.push({
      // eslint-disable-next-line eqeqeq        
        age: (selectedAge =="")?editPatientAge:selectedAge,
        max: "0",
        min: "0",
        condition: selectedAgeCondition.value,
      });
      // eslint-disable-next-line eqeqeq

    } 
      // eslint-disable-next-line eqeqeq    
    else if (selectedAgeCondition.label == "Between") {
      
      editpatientAgeCondition.length = 0;
      // patientAgeConditionArray.push([{ "age": "0", "max": maxValue, "min": minValue, "condition": selectedAgeCondition }])
      editpatientAgeCondition.push({
        age: "0",
        max: maxValue,
        min: minValue,
        condition: selectedAgeCondition.value,
      });
    } else {
      setEditPatientAgeCondition([]);
    }
    // eslint-disable-next-line eqeqeq
    if (selectedAgeCondition == "less than") {
      setSelectedAgeCondition("Less than");
      // eslint-disable-next-line eqeqeq
    } else if (selectedAgeCondition == "greater than") {
      setSelectedAgeCondition("Greater than");
      // eslint-disable-next-line eqeqeq
    } else if (selectedAgeCondition == "equal to") {
      setSelectedAgeCondition("Equal to");
      // eslint-disable-next-line eqeqeq
    } else if (selectedAgeCondition == "between") {
      setSelectedAgeCondition("Between");
    }
    // eslint-disable-next-line eqeqeq
    if (selectedAgeCondition.label == "Between") {
      setMaxValue(maxValue);
      setMinValue(minValue);
    }
    if (selectedDiagnosis) {
      var diagnosisResult = selectedDiagnosis.map((item) => {
        return item;
      });
    }
    if (selectedOrderPriority) {
      var orderPriorityResult = selectedOrderPriority.map((item) => {
        return item.value;
      });
    }
    if (selectedProcedure) {
      var selectedProcedureResult = selectedProcedure.map((item) => {
        return item;
      });
    }

    if (selectedFulfillerGroup) {
      
      var fulfillerGroupResult = selectedFulfillerGroup.map((item) => {
        return item.value.toString();
      });
    }
    // if (selectedSenderGroup) {
    //   debugger
    //   console.log (selectedSenderGroup)
      
    //   var senderGroupResult = selectedSenderGroup.map((item) => {
    //     console.log("fmf",item)
    //     return item.value.toString();
    //   });
    // }
    if (selectedSenderGroup) {

    
      var senderGroupResult = selectedSenderGroup.map((item) => {
      
        return item ? item.value.toString() : "";
      });
    }
    else {
      console.warn("selectedSenderGroup is undefined");
    }
    

    if (selectedFulfillerFacility) {
      var fulfillerFacilityResult = selectedFulfillerFacility.map((item) => {
        return item.value.toString();
      });
    }
    if (selectedPhysicianIds) {
      var physicianResult = selectedPhysicianIds.map((item) => {
      
        return item.value.toString();
      });
    }
    if (selectedConditions) {
      var conditionResult = selectedConditions.map((item) => {
        return item;
      });
    }

    if (selectedInsurances) {
      var insuranceResult = selectedInsurances.map((item) => {
        return item.label;
      });
    }

    // if (selectedAllowedServiceTypes) {
    //     selectedAllowedServiceTypes.map((item) => {
    //         editallowedServiceTypes.push({ "id": item.value, "type": item.label, "is_clinical": 1, "is_transport": 0 })
    //     })
    // }
    if (selectedAllowedServiceTypes) {
      var allowedServiceTypesResult = selectedAllowedServiceTypes.map(
        (item) => {
          return {
            id: item.value,
            type: item.label,
            is_clinical: 1,
            is_transport: 0,
          };
        }
      );
    }
    if (selectedExcludedServiceTypes) {
      var excludedServiceTypesResult = selectedExcludedServiceTypes.map(
        (item) => {
          return {
            id: item.value,
            type: item.label,
            is_clinical: 1,
            is_transport: 0,
          };
        }
      );
    }
    // if (selectedExcludedServiceTypes) {
    //     selectedExcludedServiceTypes.map((item) => {
    //         editexcludedServiceTypes.push({ "id": item.value, "type": item.label, "is_clinical": 1, "is_transport": 0 })
    //     })
    // }
    if (selectedAllowedDiagnosis) {
      var allowedDiagnosisResult = selectedAllowedDiagnosis.map((item) => {
        return item;
      });
    }
    if (selectedExcludedDiagnosis) {
      var excludedDiagnosisResult = selectedExcludedDiagnosis.map((item) => {
        return item;
      });
    }
    if (selectedAllowedInsurance) {
      var allowedInsuranceResult = selectedAllowedInsurance.map((item) => {
        return item.label;
      });
    }
    if (selectedExcludedInsurance) {
      var excludedInsuranceResult = selectedExcludedInsurance.map((item) => {
        return item.label;
      });
    }
    if (selectedAllowedProcedure) {
      var allowedProcedureResult = selectedAllowedProcedure.map((item) => {
        return item;
      });
    }
    if (selectedExcludedProcedure) {
      var excludedProcedureResult = selectedExcludedProcedure.map((item) => {
        return item;
      });
    }
    
    if (selectedAllowedFulfillerGroup) {
      var allowedFulfillerGroupResult = selectedAllowedFulfillerGroup.map((item) => {
        return item;
      });
    }
    if (selectedAllowedFulfillerFacility) {
      var allowedFulfillerFacilityResult = selectedAllowedFulfillerFacility.map((item) => {
        return item;
      });
    } if (selectedAllowedFulfillerPhysician) {
      var allowedFulfillerPhysicianResult = selectedAllowedFulfillerPhysician.map((item) => {
        return item;
      });
    } if (selectedExcludedFulfillerGroup) {
      var excludedFulfillerGroupResult = selectedExcludedFulfillerGroup.map((item) => {
        return item;
      });
    } if (selectedExcludedFulfillerFacility) {
      var excludedFulfillerfacilityResult = selectedExcludedFulfillerFacility.map((item) => {
        return item;
      });
    } if (selectedExcludedFulfillerPhysician) {
      var excludedFulfillerPhysicianResult = selectedExcludedFulfillerPhysician.map((item) => {
        return item;
      });
    }
    if (selectAttachmentType) {
      var attachmentTypeResult = selectAttachmentType.map((item) => {
        return item;
      });
    }
    if (toggleValue === false) {
      setEditOtherGenerateDocs([]);
    }
    // var allowedNoOfValues = []
    // if (selectedAllowedProcedure && visitNumbers) {
    //     selectedAllowedProcedure.map((item) => {
    //
    //         allowedNoOfValues.push({ "procCode": item.code, "visitNumber": visitNumbers })
    //     })
    // }
    // let checkedValueArray = []
    // if (editmandatoryItems) {
    //     editmandatoryItems.map((item) => {
    //
    //         tempDiagnosis.push(item)
    //     })
    // }
    // const uniqueSet = new Set(tempDiagnosis);
    // const backToArray = [...uniqueSet];

    const editMandatoryItemsArray = editmandatoryItems.filter(
      (n) => !tempDiagnosis.includes(n)
    );

    let procedureCode = [];
    if (selectedAllowedProcedure) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedProcedure.map((item) => {
        procedureCode.push(item.code);
      });
    }
    let allowedVisitsNumbers = [];
    // if (selectedAllowedProcedure&&allowedNoOfVisitors) {
    //     selectedAllowedProcedure.map((item) => {

    //         procedureCode.push(item.code)
    //     })
    // }
    let allowedNoOfVisitsarray = [];
    let temp1 = [];
    let zip = (a1, a2) => a1.map((x, i) => [x, a2[i]]);
    if (typeof visitNumbers == "object") {
      allowedVisitsNumbers = visitNumbers;
    } else {
      allowedVisitsNumbers = visitNumbers.split(",");
    }
    if (allowedVisitsNumbers) {
      // eslint-disable-next-line array-callback-return
      allowedVisitsNumbers.map((item) => {
        temp1.push(item);
      });
    }
    let combined = zip(procedureCode, allowedVisitsNumbers);
    combined.map((items) =>
      allowedNoOfVisitsarray.push({ procCode: items[0], visitNumber: items[1] })
    );

 
  let autogID = 0
  if (editAuto_Assign_Fullfiller ===0){
    autogID = 0
  }
else if(editAuto_Assign_Fullfiller ===1){
  autogID = 0
}
else {
  autogID = editAassign_FulfillerGroupId.value
}
// debugger
    const params = {
      // first section
  
      rule_id:editRuleId ? editRuleId : "",
      group_id: editgroupId ? editgroupId.value : "",
      referral_type: editreferralType ? editreferralType.value : "",
      category:editReferralCategory ?editReferralCategory.value : "",
      senderFacility:editSenderFacilityId.length>0 ? editSenderFacilityId.value : "",
      rule_name: editruleName ? editruleName : "",
      
      // order details section
      service_types: serviceTypesResult,
      diagnosis: diagnosisResult,
      order_priority: orderPriorityResult,
      procedures: selectedProcedureResult,
      sender_group_id:senderGroupResult,
      fulfiller_group_id: fulfillerGroupResult,
      fulfiller_facility_id: fulfillerFacilityResult,
      fulfiller_physician_id: physicianResult,

      //patient details section 
      patient_age_condition: editpatientAgeCondition
        ? editpatientAgeCondition
        : "",
      patient_condition: conditionResult,
      patient_gender: editpatientGender ? editpatientGender.value : "",
      
      // extra fields
      status: editprocessStatus ? 1 : 1,
      created_date: editcreatedDate ? editcreatedDate : "",
      created_by: admin_id ? admin_id : "",
      updated_date: editupdatedDate ? editupdatedDate : "",
      updated_by: admin_id ? admin_id : "",
    
      //  Financial Data
      patient_insurance: insuranceResult,
      is_enable_eligiblity_check: editeligibilityCheck ? 1 : 0,
      is_enable_pre_auth_check: editpreAuthCheck ? 1 : 0,
      pre_auth_request_type: editpreAuthRequestType
        ? editpreAuthRequestType.value
        : "",
      allowed_pos: editallowedPos ? editallowedPos : "",
      
      // additional documents and fax 
      other_generate_docs: editotherGenerateDocs ? editotherGenerateDocs : "",
      configuredDocOnSent:configuredDocOnSent?configuredDocOnSent:"",
      tranimitEmiForm : editTransmitEmiForm ? editTransmitEmiForm:"",
      transmitFaxNumber : editFaxNumber ? editFaxNumber:"",
      // Fulfiller Selection
      auto_assign_fulfiller : editAuto_Assign_Fullfiller ? editAuto_Assign_Fullfiller : "",
      assign_fulfiller_group_id : editAassign_FulfillerGroupId ? autogID :"",

      // required Fields
      mandatory_items: editmandatoryItems ? editMandatoryItemsArray : [],
      allowed_service_types: allowedServiceTypesResult,
      excluded_service_types: excludedServiceTypesResult,
      allowed_fulfiller_group:allowedFulfillerGroupResult,
      excluded_fulfiller_group:excludedFulfillerGroupResult,
      allowed_fulfiller_facility:allowedFulfillerFacilityResult,
      excluded_fulfiller_facility:excludedFulfillerfacilityResult,
      allowed_fulfiller_physician:allowedFulfillerPhysicianResult,
      excluded_fulfiller_physician:excludedFulfillerPhysicianResult,
      allowed_diagnosis: allowedDiagnosisResult,
      excluded_diagnosis: excludedDiagnosisResult,
      allowed_procedure: allowedProcedureResult,
      allowed_no_of_visits: allowedNoOfVisitsarray
      ? allowedNoOfVisitsarray
      : "",
      excluded_procedure: excludedProcedureResult,
      allowed_insurances: allowedInsuranceResult,
      excluded_insurances: excludedInsuranceResult,
      
     
      additional_key_data: editadditionalKeyData ? editadditionalKeyData : "",
      transmitAdditionalAttachments:transmitAdditionalAttachments ? transmitAdditionalAttachments : "",
      attachmentTypeResult:attachmentTypeResult ? attachmentTypeResult: "",
      is_auto_process_enable: editautoProcessEnable ? 1 : 0,
      suggestingForm:editSuggestingForm?editSuggestingForm.value:"",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/update-autoprocess-rule/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        toastr.success("Success", "Auto Process Rule Conditions Updated");
        toggle();
        setReload(reload + 1);
        fetchAutoProcessExceptionRules();
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.success("Success", "Auto Process  Rule Conditions Updated");
          toggle();
          setReload(reload + 1);
          fetchAutoProcessExceptionRules();
        }
      });
  };

  // const populateServiceType = () => {
  //   var temp = editserviceTypes;
  //   if (editserviceTypes) {
  //     var res = [];
  //     // eslint-disable-next-line array-callback-return
  //     temp.map((item) => {
  //       fetch(
  //         `${API_ENDPOINT}/admin-api/fetch-order-type/${"null"}/${Number(
  //           item
  //         )}`,
  //         {
  //           headers: {
  //             Authorization: API_TOKEN,
  //           },
  //         }
  //       )
  //         .then((response) => response.json())
  //         .then((data) => {
  //           if (data.data) {
  //             res.push(data.data[0]);
  //           }
  //         });
  //     });
  //     setSelectedServiceTypes(res);
  //   }
  // };

  const populateServiceType = () => {
    var temp = editserviceTypes;
    if (editserviceTypes) {
      var res = [];
      // eslint-disable-next-line array-callback-return
      temp.map((item) => {AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-order-type/${"null"}/${Number(item)}`,{
              headers: {
                Authorization: API_TOKEN,
              },
            }
          )
          .then((response) => response.data)
          .then((data) => {
            if (data.data) {
              res.push(data.data[0]);
            }
          });
      });
      setSelectedServiceTypes(res);
    }
  };
  
  // const populateGroupId = async () => {
  //   let tempGroupId = editfulfillerGroupId;
  //   if (editfulfillerGroupId) {
  //     const resGroupId = [];
  //     for (let item of tempGroupId) {
  //       try {
  //         const response = await fetch(
  //           `${API_ENDPOINT}/admin-api/get-fulfiller-group-for-rule/${"null"}/${Number(
  //             item
  //           )}`,
  //           {
  //             headers: {
  //               Authorization: API_TOKEN,
  //             },
  //           }
  //         );
       
  //         const data = await response.json();
         
  //         resGroupId.push(data.data[0]);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }

  //     // console.log(resGroupId[0])
  //     console.log(resGroupId[0])
  //     setSelectedFulfillerGroup(resGroupId);
  //   }
  // };

  const populateGroupId = async () => {
    let tempGroupId = editfulfillerGroupId;
    if (editfulfillerGroupId) {
      const resGroupId = [];
      for (let item of tempGroupId) {
        try {
          const response = await AxiosInter.get(`${API_ENDPOINT}/admin-api/get-fulfiller-group-for-rule/${"null"}/${Number(item)}`,{
              headers: {
                Authorization: API_TOKEN,
              },
            }
          );
          const data = response.data;
          resGroupId.push(data.data[0]);
        } catch (error) {
          console.error(error);
        }
      }
  
      console.log(resGroupId[0]);
      setSelectedFulfillerGroup(resGroupId);
    }
  };
  
    const populateSenderGroupId = async () => {
    // debugger
    let tempGroupId = editSenderGroup;
    if (editSenderGroup) {
      const resGroupId = [];
      for (let item of tempGroupId) {
        try {
          const response = await AxiosInter.get(`${API_ENDPOINT}/admin-api/get-groupid-list/${Number(item)}`,{
              headers: {
                Authorization: API_TOKEN,
              },
            }
          );
          const data = response.data;
          resGroupId.push(data.data[0]);
        } catch (error) {
          console.error(error);
        }
      }
      // console.log(resGroupId[0])
      console.log(resGroupId[0])
      setSelectedSenderGroup(resGroupId);
    }
  };

  // const populatePhysicianId = async () => {
  //   let tempPhysicianId = editfulfillerPhysicianId;
  //   if (editfulfillerPhysicianId) {
  //     const resPhysicianId = [];
  //     for (let item of tempPhysicianId) {
  //       try {
  //         const response = await fetch(
  //           `${API_ENDPOINT}/admin-api/get-physician-data/${"null"}/${Number(
  //             item
  //           )}`,
  //           {
  //             headers: {
  //               Authorization: API_TOKEN,
  //             },
  //           }
  //         );
  //         const data = await response.json();
  //         console.log("physician label", data);
  //         resPhysicianId.push(data.data[0]);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }

  //     console.log(resPhysicianId);
  //     setSelectedPhysicianIds(resPhysicianId);
  //   }
  // };

  const populatePhysicianId = async () => {
    let tempPhysicianId = editfulfillerPhysicianId;
    if (editfulfillerPhysicianId) {
      const resPhysicianId = [];
      for (let item of tempPhysicianId) {
        try {
          const response = await AxiosInter.get(`${API_ENDPOINT}/admin-api/get-physician-data/${"null"}/${Number(item)}`,{
              headers: {
                Authorization: API_TOKEN,
              },
            }
          );
          const data = response.data;
          console.log("physician label", data);
          resPhysicianId.push(data.data[0]);
        } catch (error) {
          console.error(error);
        }
      }
      console.log(resPhysicianId);
      setSelectedPhysicianIds(resPhysicianId);
    }
  };

  // const populateFacilityId = async () => {
  //   let tempFacilityId = editfulfillerFacilityId;
  //   if (editfulfillerFacilityId) {
  //     const resFacilityId = [];
  //     for (let item of tempFacilityId) {
  //       try {
  //         const response = await fetch(
  //           `${API_ENDPOINT}/admin-api/fetch-fulfiller-facility-by-fulfiller-name/${"null"}/${Number(
  //             item
  //           )}`,
  //           {
  //             headers: {
  //               Authorization: API_TOKEN,
  //             },
  //           }
  //         );
  //         const data = await response.json();
  //         resFacilityId.push(data.data[0]);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //     setSelectedFulfillerFacility(resFacilityId);
  //   }
  // };

  const populateFacilityId = async () => {
    let tempFacilityId = editfulfillerFacilityId;
    if (editfulfillerFacilityId) {
      const resFacilityId = [];
      for (let item of tempFacilityId) {
        try {
          const response = await AxiosInter.get(
            `${API_ENDPOINT}/admin-api/fetch-fulfiller-facility-by-fulfiller-name/${"null"}/${Number(
              item
            )}`,
            {
              headers: {
                Authorization: API_TOKEN,
              },
            }
          );
          const data = response.data;
          resFacilityId.push(data.data[0]);
        } catch (error) {
          console.error(error);
        }
      }
      setSelectedFulfillerFacility(resFacilityId);
    }
  };


  const validate = () => {
    if (editruleName) {
      setButtonBlankState(false);
    } else {
      setButtonBlankState(true);
    }
  };
  useEffect(() => {
    validate();
  }, [editruleName, editreferralType]); // eslint-disable-line

  const setInitialReferralType = () => {
    // eslint-disable-next-line array-callback-return
    referralTypeOptions.map((data) => {
      // eslint-disable-line
      if (data.value === editreferralType) {
        setEditReferralType({ value: data.value, label: data.label });
      }
    });
  };
  //   const setInitialRuleType = () => {
  //     // eslint-disable-next-line array-callback-return
  //     ruleTypeOptions.map((data) => {
  //       // eslint-disable-line
  //       if (data.value === editruleType) {
  //         setEditRuleType({ value: data.value, label: data.label });
  //       }
  //     });
  //   };
  const setInitialGender = () => {
    // eslint-disable-next-line array-callback-return
    genderOptions.map((data) => {
      // eslint-disable-line
      if (data.value === editpatientGender) {
        setEditPatientGender({ value: data.value, label: data.label });
      }
    });
  };
  const setInitialAgeConditions = () => {
    // eslint-disable-next-line array-callback-return
    if (ageOptions.length > 0) {
      // eslint-disable-next-line array-callback-return
      ageOptions.map((data) => {
        // eslint-disable-line

        if (
          editpatientAgeCondition.length > 0 &&
          data.value === editpatientAgeCondition[0]["condition"]
        ) {
          setSelectedAgeCondition({ value: data.value, label: data.label });
        }
      });
    }
  };
  const setInitialRequestType = () => {
    // eslint-disable-next-line array-callback-return
    requestTypeOptions.map((data) => {
      // eslint-disable-line
      if (data.value === editpreAuthRequestType) {
        setEditPreAuthRequestType({ value: data.value, label: data.label });
      }
    });
  };

  const setInitialGroupName = () => {
    // eslint-disable-next-line array-callback-return
    groupNamesForDropDown.map((data) => {
      if (data.value === Number(editgroupId)) {
        // console.log(data.value,data.label)
        setEditGroupId({ value: data.value, label: data.label });
        // var x=JSON.parse(editpatientAgeCondition)
        // console.log('510',x[0]["age"])
      }
    });
  };

  const setInitialPriority = () => {
    var array = [];
    if (editorderPriority.length > 0) {
      // eslint-disable-next-line array-callback-return
      editorderPriority.map((item) => {
        // eslint-disable-line
        array.push({ value: item, label: item });
      });
    }
    setSelectedOrderPriority(array);
  };

  const setInitialDocs = () => {
    var array = [];
    // eslint-disable-next-line array-callback-return
    additionalDocsOptions.map((item) => {
      // eslint-disable-line
      array.push({ value: item.value, label: item.label });
    });

    setSelectedadditionalDocs(array);
  };
  const setCheckedOneValue = () => {
    // var array = []
    if (
      typeof editmandatoryItems != "undefined" &&
      // eslint-disable-next-line eqeqeq
      editmandatoryItems != "" &&
      editmandatoryItems != null
    ) {
      // eslint-disable-next-line array-callback-return
      editmandatoryItems.map((item) => {
        // eslint-disable-line
        // eslint-disable-next-line eqeqeq
        if (item == "1") {
          setCheckedOne(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "2") {
          setCheckedTwo(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "3") {
          setCheckedThree(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "4") {
          setCheckedFour(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "5") {
          setCheckedFive(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "6") {
          setCheckedSix(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "7") {
          setCheckedSeven(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "8") {
          setCheckedEight(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "9") {
          setCheckedNine(true);
        }
      });
    }
  };

  useEffect(() => {
    setCheckedOneValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editmandatoryItems]);

  const populatePatientInsurance = () => {
    var array = [];
    if (editpatientInsurance != null && editpatientInsurance.length > 0) {
      // eslint-disable-next-line array-callback-return
      editpatientInsurance.map((item) => {
        // eslint-disable-line
        array.push({ value: item, label: item });
      });
    }
    setSelectedInsurances(array);
  };
  const populateAllowedInsurance = () => {
    var array = [];
    if (editallowedInsurances != null && editallowedInsurances.length > 0) {
      // eslint-disable-next-line array-callback-return
      editallowedInsurances.map((item) => {
        // eslint-disable-line
        array.push({ value: item, label: item });
      });
    }
    setSelectedAllowedInsurance(array);
  };
  const populateExcludedInsurance = () => {
    var array = [];
    if (editexcludedInsurances != null && editexcludedInsurances.length > 0) {
      // eslint-disable-next-line array-callback-return
      editexcludedInsurances.map((item) => {
        // eslint-disable-line
        array.push({ value: item, label: item });
      });
    }
    setSelectedExcludedInsurance(array);
  };

  const populatePatientCondition = () => {
    var array = [];
    if (editpatientCondition.length > 0) {
      // eslint-disable-next-line array-callback-return
      editpatientCondition.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, category: item.category });
      });
    }
    setSelectedConditions(array);
  };
  const populateAllowedServiceTypes = () => {
    var array = [];
    if (editallowedServiceTypes != null && editallowedServiceTypes.length > 0) {
      // eslint-disable-next-line array-callback-return
      editallowedServiceTypes.map((item) => {
        // eslint-disable-line
        array.push({ value: item.id, label: item.type });
      });
    }
    setSelectedAllowedServiceTypes(array);
  };
  const populateExcludedServiceTypes = () => {
    var array = [];
    if (
      editexcludedServiceTypes != null &&
      editexcludedServiceTypes.length > 0
    ) {
      // eslint-disable-next-line array-callback-return
      editexcludedServiceTypes.map((item) => {
        // eslint-disable-line
        array.push({ value: item.id, label: item.type });
      });
    }
    setSelectedExcludedServiceTypes(array);
  };
  const populateDiagnosis = () => {
    var array = [];
    if (editdiagnosis.length > 0) {
      // eslint-disable-next-line array-callback-return
      editdiagnosis.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code: item.code, code_show: item.code_show });
      });
    }
    setSelectedDiagnosis(array);
  };
  const populateAllowedDiagnosis = () => {
    var array = [];
    if (editallowedDiagnosis != null && editallowedDiagnosis.length > 0) {
      // eslint-disable-next-line array-callback-return
      editallowedDiagnosis.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code_show: item.code_show });
      });
    }
    setSelectedAllowedDiagnosis(array);
  };
  const populateExcludedDiagnosis = () => {
    var array = [];
    if (editexcludedDiagnosis != null && editexcludedDiagnosis.length > 0) {
      // eslint-disable-next-line array-callback-return
      editexcludedDiagnosis.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code_show: item.code_show });
      });
    }
    setSelectedExcludedDiagnosis(array);
  };
  const populateProcedure = () => {
    var array = [];
    if (editprocedures != null && editprocedures.length > 0) {
      // eslint-disable-next-line array-callback-return
      editprocedures.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code: item.code, code_show: item.code_show });
      });
    }
    setSelectedProcedure(array);
  };
  const populateAllowedProcedure = () => {
    var array = [];
    if (editallowedProcedure != null && editallowedProcedure.length > 0) {
      // eslint-disable-next-line array-callback-return
      editallowedProcedure.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code: item.code, code_show: item.code_show });
      });
    }
    setSelectedAllowedProcedure(array);
  };
  const populateExcludedProcedure = () => {
    var array = [];
    if (editexcludedProcedure != null && editexcludedProcedure.length > 0) {
      // eslint-disable-next-line array-callback-return
      editexcludedProcedure.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code: item.code, code_show: item.code_show });
      });
    }
    setSelectedExcludedProcedure(array);
  };
  const setInitialFulfillerGroupNames = () => {
    var array = [];
    if (editfulfillerGroupId != null && editfulfillerGroupId.length > 0) {
      // eslint-disable-next-line array-callback-return
      editfulfillerGroupId.map((item) => {      
   
        console.log(item)
        // eslint-disable-line
       
        array.push({ value: item, label: item });
      });
    }
    setSelectedFulfillerGroup(array);
  };
  const setInitialSenderGroupNames = () => {
    var array = [];
    if (editSenderGroup != null && editSenderGroup.length > 0) {
      // eslint-disable-next-line array-callback-return
      editSenderGroup.map((item) => {      
   
        console.log(item)
        // eslint-disable-line
       
        array.push({ value: item, label: item });
      });
    }
    setSelectedSenderGroup(array);
  };
  const setInitialFulfillerFacilityNames = () => {
    var array = [];
    if (editfulfillerFacilityId != null && editfulfillerFacilityId.length > 0) {
      // eslint-disable-next-line array-callback-return
      editfulfillerFacilityId.map((item) => {
        // eslint-disable-line
        array.push({ value: item, label: item });
      });
    }
    setSelectedFulfillerFacility(array);
  };
  const setInitialPhysicianNames = () => {
    var array = [];
    // eslint-disable-next-line array-callback-return
    if (
      editfulfillerPhysicianId != null &&
      editfulfillerPhysicianId.length > 0
    ) {
      // eslint-disable-next-line array-callback-return
      editfulfillerPhysicianId.map((item) => {
        // eslint-disable-line

        array.push({ value: item, label: item });
      });
    }
    setSelectedPhysicianIds(array);
  };
  // useEffect(()=>{
  //     if(selectedInsurances.length<=0){
  //         setEditAllowedPos();
  //         setEditPreAuthRequestType("");
  //         setEditEligibilityCheck();
  //         setEditPreAuthCheck();
  //     }
  // })
  const setInitialAllowedFulfillerGroupNames = () => {
    var array = [];
    if (editAllowedFulfillerGroup != null && editAllowedFulfillerGroup.length > 0) {
      // eslint-disable-next-line array-callback-return
      editAllowedFulfillerGroup.map((item) => {
        // eslint-disable-line
       
        array.push({ value: item, label: item });
      });
    }
    setSelectedAllowedFulfillerGroup(array);
  };
  const setInitialAllowedFulfillerFacilityNames = () => {
    var array = [];
    if (editAllowedFulfillerFacilty != null && editAllowedFulfillerFacilty.length > 0) {
      // eslint-disable-next-line array-callback-return
      editAllowedFulfillerFacilty.map((item) => {
        // eslint-disable-line
        array.push({ value: item, label: item });
      });
    }
    setSelectedAllowedFulfillerFacility(array);
  };
  const setInitialAllowedPhysicianNames = () => {
    var array = [];
    // eslint-disable-next-line array-callback-return
    if (
      editAllowedFulfillerphysician != null &&
      editAllowedFulfillerphysician.length > 0
    ) {
      // eslint-disable-next-line array-callback-return
      editAllowedFulfillerphysician.map((item) => {
        // eslint-disable-line

        array.push({ value: item, label: item });
      });
    }
    setSelectedAllowedFulfillerPhysician(array);
  };
  const setInitialExcludedFulfillerGroupNames = () => {
    var array = [];
    if (editExcludedFulfillerGroup != null && editExcludedFulfillerGroup.length > 0) {
      // eslint-disable-next-line array-callback-return
      editExcludedFulfillerGroup.map((item) => {
        // eslint-disable-line
       
        array.push({ value: item, label: item });
      });
    }
    setSelectedExcludedFulfillerGroup(array);
  };
  const setInitialExcludedFulfillerFacilityNames = () => {
    var array = [];
    if (editExcludedFulfillerFacilty != null && editExcludedFulfillerFacilty.length > 0) {
      // eslint-disable-next-line array-callback-return
      editExcludedFulfillerFacilty.map((item) => {
        // eslint-disable-line
        array.push({ value: item, label: item });
      });
    }
    setSelectedExcludedFulfillerFacility(array);
  };
  const setInitialExcludedPhysicianNames = () => {
    var array = [];
    // eslint-disable-next-line array-callback-return
    if (
      editExcludedFulfillerphysician != null &&
      editExcludedFulfillerphysician.length > 0
    ) {
      // eslint-disable-next-line array-callback-return
      editExcludedFulfillerphysician.map((item) => {
        // eslint-disable-line

        array.push({ value: item, label: item });
      });
    }
    setSelectedExcludedFulfillerPhysician(array);
  };

  // const populateAllowedGroupId = async () => {
  //   let tempGroupId = editAllowedFulfillerGroup;
  //   if (editAllowedFulfillerGroup) {
  //     const resGroupId = [];
  //     for (let item of tempGroupId) {
  //       try {
  //         const response = await fetch(
  //           `${API_ENDPOINT}/admin-api/get-fulfiller-group-for-rule/${"null"}/${Number(
  //             item
  //           )}`,
  //           {
  //             headers: {
  //               Authorization: API_TOKEN,
  //             },
  //           }
  //         );
  //         const data = await response.json();
  //         resGroupId.push(data.data[0]);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }

  //     // console.log(resGroupId[0])
  //     setSelectedAllowedFulfillerGroup(resGroupId);
  //   }
  // };

  const populateAllowedGroupId = async () => {
    let tempGroupId = editAllowedFulfillerGroup;
    if (editAllowedFulfillerGroup) {
      const resGroupId = [];
      for (let item of tempGroupId) {
        try {
          const response = await AxiosInter.get(
            `${API_ENDPOINT}/admin-api/get-fulfiller-group-for-rule/${"null"}/${Number(
              item
            )}`,
            {
              headers: {
                Authorization: API_TOKEN,
              },
            }
          );
          const data = response.data;
          resGroupId.push(data.data[0]);
        } catch (error) {
          console.error(error);
        }
      }
      // console.log(resGroupId[0])
      setSelectedAllowedFulfillerGroup(resGroupId);
    }
  };

  // const populateAllowedPhysicianId = async () => {
  //   let tempPhysicianId = editAllowedFulfillerphysician;
  //   if (editAllowedFulfillerphysician) {
  //     const resPhysicianId = [];
  //     for (let item of tempPhysicianId) {
  //       try {
  //         const response = await fetch(
  //           `${API_ENDPOINT}/admin-api/get-physician-data/${"null"}/${Number(
  //             item
  //           )}`,
  //           {
  //             headers: {
  //               Authorization: API_TOKEN,
  //             },
  //           }
  //         );
  //         const data = await response.json();
  //         console.log("physician label", data);
  //         resPhysicianId.push(data.data[0]);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }

  //     console.log(resPhysicianId);
  //     setSelectedAllowedFulfillerPhysician(resPhysicianId);
  //   }
  // };

  const populateAllowedPhysicianId = async () => {
    let tempPhysicianId = editAllowedFulfillerphysician;
    if (editAllowedFulfillerphysician) {
      const resPhysicianId = [];
      for (let item of tempPhysicianId) {
        try {
          const response = await AxiosInter.get(
            `${API_ENDPOINT}/admin-api/get-physician-data/${"null"}/${Number(
              item
            )}`,
            {
              headers: {
                Authorization: API_TOKEN,
              },
            }
          );
          const data = response.data;
          console.log("physician label", data);
          resPhysicianId.push(data.data[0]);
        } catch (error) {
          console.error(error);
        }
      }

      console.log(resPhysicianId);
      setSelectedAllowedFulfillerPhysician(resPhysicianId);
    }
  };

  // const populateAllowedFacilityId = async () => {
  //   let tempFacilityId = editAllowedFulfillerFacilty;
  //   if (editAllowedFulfillerFacilty) {
  //     const resFacilityId = [];
  //     for (let item of tempFacilityId) {
  //       try {
  //         const response = await fetch(
  //           `${API_ENDPOINT}/admin-api/fetch-fulfiller-facility-by-fulfiller-name/${"null"}/${Number(
  //             item
  //           )}`,
  //           {
  //             headers: {
  //               Authorization: API_TOKEN,
  //             },
  //           }
  //         );
  //         const data = await response.json();
  //         resFacilityId.push(data.data[0]);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //     setSelectedAllowedFulfillerFacility(resFacilityId);
  //   }
  // };

  const populateAllowedFacilityId = async () => {
    let tempFacilityId = editAllowedFulfillerFacilty;
    if (editAllowedFulfillerFacilty) {
      const resFacilityId = [];
      for (let item of tempFacilityId) {
        try {
          const response = await AxiosInter.get(
            `${API_ENDPOINT}/admin-api/fetch-fulfiller-facility-by-fulfiller-name/${"null"}/${Number(
              item
            )}`,
            {
              headers: {
                Authorization: API_TOKEN,
              },
            }
          );
          const data = response.data;
          resFacilityId.push(data.data[0]);
        } catch (error) {
          console.error(error);
        }
      }
      setSelectedAllowedFulfillerFacility(resFacilityId);
    }
  };

  // const populateExcludedGroupId = async () => {
  //   let tempGroupId = editExcludedFulfillerGroup;
  //   if (editExcludedFulfillerGroup) {
  //     const resGroupId = [];
  //     for (let item of tempGroupId) {
  //       try {
  //         const response = await fetch(
  //           `${API_ENDPOINT}/admin-api/get-fulfiller-group-for-rule/${"null"}/${Number(
  //             item
  //           )}`,
  //           {
  //             headers: {
  //               Authorization: API_TOKEN,
  //             },
  //           }
  //         );
  //         const data = await response.json();
  //         resGroupId.push(data.data[0]);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }

  //     // console.log(resGroupId[0])
  //     setSelectedExcludedFulfillerGroup(resGroupId);
  //   }
  // };

  const populateExcludedGroupId = async () => {
    let tempGroupId = editExcludedFulfillerGroup;
    if (editExcludedFulfillerGroup) {
      const resGroupId = [];
      for (let item of tempGroupId) {
        try {
          const response = await AxiosInter.get(
            `${API_ENDPOINT}/admin-api/get-fulfiller-group-for-rule/${"null"}/${Number(
              item
            )}`,
            {
              headers: {
                Authorization: API_TOKEN,
              },
            }
          );
          const data = response.data;
          resGroupId.push(data.data[0]);
        } catch (error) {
          console.error(error);
        }
      }
      // console.log(resGroupId[0])
      setSelectedExcludedFulfillerGroup(resGroupId);
    }
  };

  // const populateExcludedPhysicianId = async () => {
  //   let tempPhysicianId = editExcludedFulfillerphysician;
  //   if (editExcludedFulfillerphysician) {
  //     const resPhysicianId = [];
  //     for (let item of tempPhysicianId) {
  //       try {
  //         const response = await fetch(
  //           `${API_ENDPOINT}/admin-api/get-physician-data/${"null"}/${Number(
  //             item
  //           )}`,
  //           {
  //             headers: {
  //               Authorization: API_TOKEN,
  //             },
  //           }
  //         );
  //         const data = await response.json();
  //         console.log("physician label", data);
  //         resPhysicianId.push(data.data[0]);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }

  //     console.log(resPhysicianId);
  //     setSelectedExcludedFulfillerPhysician(resPhysicianId);
  //   }
  // };

  const populateExcludedPhysicianId = async () => {
    let tempPhysicianId = editExcludedFulfillerphysician;
    if (editExcludedFulfillerphysician) {
      const resPhysicianId = [];
      for (let item of tempPhysicianId) {
        try {
          const response = await AxiosInter.get(
            `${API_ENDPOINT}/admin-api/get-physician-data/${"null"}/${Number(
              item
            )}`,
            {
              headers: {
                Authorization: API_TOKEN,
              },
            }
          );
          const data = response.data;
          console.log("physician label", data);
          resPhysicianId.push(data.data[0]);
        } catch (error) {
          console.error(error);
        }
      }

      console.log(resPhysicianId);
      setSelectedExcludedFulfillerPhysician(resPhysicianId);
    }
  };

  // const populateExcludedFacilityId = async () => {
  //   let tempFacilityId = editExcludedFulfillerFacilty;
  //   if (editExcludedFulfillerFacilty) {
  //     const resFacilityId = [];
  //     for (let item of tempFacilityId) {
  //       try {
  //         const response = await fetch(
  //           `${API_ENDPOINT}/admin-api/fetch-fulfiller-facility-by-fulfiller-name/${"null"}/${Number(
  //             item
  //           )}`,
  //           {
  //             headers: {
  //               Authorization: API_TOKEN,
  //             },
  //           }
  //         );
  //         const data = await response.json();
  //         resFacilityId.push(data.data[0]);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //     setSelectedExcludedFulfillerFacility(resFacilityId);
  //   }
  // };

  const populateExcludedFacilityId = async () => {
    let tempFacilityId = editExcludedFulfillerFacilty;
    if (editExcludedFulfillerFacilty) {
      const resFacilityId = [];
      for (let item of tempFacilityId) {
        try {
          const response = await AxiosInter.get(
            `${API_ENDPOINT}/admin-api/fetch-fulfiller-facility-by-fulfiller-name/${"null"}/${Number(
              item
            )}`,
            {
              headers: {
                Authorization: API_TOKEN,
              },
            }
          );
          const data = response.data;
          resFacilityId.push(data.data[0]);
        } catch (error) {
          console.error(error);
        }
      }
      setSelectedExcludedFulfillerFacility(resFacilityId);
    }
  };

  // const populateAttachmentType= async () => {
  //   let tempGroupId = editAttachmentType;
  //   if (editAttachmentType) {
  //     const resGroupId = [];
  //     for (let item of tempGroupId) {
  //       try {
  //         const response = await fetch(
  //           `${API_ENDPOINT}/admin-api/get-fax-attachment-list/${item}`,
  //           {
  //             headers: {
  //               Authorization: API_TOKEN,
  //             },
  //           }
  //         );
  //         const data = await response.json();
  //         resGroupId.push(data.data[0]);
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }

  //     // console.log(resGroupId[0])
  //     setSelectAttachmentType(resGroupId);
  //   }
  // };

  const populateAttachmentType= async () => {
    let tempGroupId = editAttachmentType;
    if (editAttachmentType) {
      const resGroupId = [];
      for (let item of tempGroupId) {
        try {
          const response = await AxiosInter.get(
            `${API_ENDPOINT}/admin-api/get-fax-attachment-list/${item}`,
            {
              headers: {
                Authorization: API_TOKEN,
              },
            }
          );
          const data = response.data;
          resGroupId.push(data.data[0]);
        } catch (error) {
          console.error(error);
        }
      }

      // console.log(resGroupId[0])
      setSelectAttachmentType(resGroupId);
    }
  };

  const setInitialAttachmentType = () => {
    var array = [];
    if (editAttachmentType != null && editAttachmentType.length > 0) {
      // eslint-disable-next-line array-callback-return
      editAttachmentType.map((item) => {
        // eslint-disable-line
        array.push({ value: item, label: item });
      });
    }
    setSelectAttachmentType(array);
  };
  useEffect(() => {
    setInitialPriority();
    setInitialFulfillerGroupNames();
    setInitialSenderGroupNames();
    setInitialFulfillerFacilityNames();
    setInitialPhysicianNames();
    populateExcludedServiceTypes();
    populateAllowedServiceTypes();
    populateDiagnosis();
    populateAllowedDiagnosis();
    populateExcludedDiagnosis();
    populateExcludedProcedure();
    populateAllowedProcedure();
    populateProcedure();
    setInitialDocs();
    populatePatientInsurance();
    populatePatientCondition();
    populateAllowedInsurance();
    populateExcludedInsurance();
    setInitialAllowedFulfillerGroupNames();
    setInitialAllowedFulfillerFacilityNames();
    setInitialAllowedPhysicianNames();
    setInitialExcludedFulfillerGroupNames();
    setInitialExcludedFulfillerFacilityNames();
    setInitialExcludedPhysicianNames();
    setInitialAttachmentType();
    // setInitialCondition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editExcludedFulfillerphysician,
    editExcludedFulfillerFacilty,
    editExcludedFulfillerGroup,
    editAllowedFulfillerphysician,
    editAllowedFulfillerFacilty,
    editAllowedFulfillerGroup,
    editorderPriority,
    editfulfillerGroupId,
    editfulfillerFacilityId,
    editfulfillerPhysicianId,
    editexcludedServiceTypes,
    editallowedServiceTypes,
    editallowedDiagnosis,
    editexcludedDiagnosis, // eslint-disable-line
    editdiagnosis,
    editexcludedProcedure,
    editallowedProcedure,
    editprocedures,
    editotherGenerateDocs,
    editpatientInsurance,
    editpatientCondition,
    editallowedInsurances,
    editexcludedInsurances,
    editAttachmentType,
    editSenderGroup
  ]);

  useEffect(() => {
    if (editotherGenerateDocs) {
      setToggleValue(0);
    }
  }, [editotherGenerateDocs]); // eslint-disable-line
  // useEffect(() => {
  //     if ( toggleValue ==false) {
  //
  //      setSelectedadditionalDocs([]) }
  // }, [])          // eslint-disable-line

  useEffect(() => {
   
    if (
      editAutoProcessRule &&
      referralTypeOptions &&
      ruleTypeOptions &&
      genderOptions &&
      requestTypeOptions &&
      groupNamesForDropDown &&
      ageOptions
    ) {
      //   setInitialRuleType();
      setInitialReferralType();
      setInitialGender();
      setInitialRequestType();
      setInitialGroupName();
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editAutoProcessRule,
    referralTypeOptions,
    ruleTypeOptions,
    genderOptions,
    requestTypeOptions,
    groupNamesForDropDown,
    ageOptions,
  ]); // eslint-disable-line

  // const ageValue =()=>{
  //     var x=JSON.parse(editpatientAgeCondition)
  //     setSelectedAge(x[0]["age"])
  // }
  
  // eslint-disable-next-line eqeqeq
  useEffect(()=>{ if(editAutoProcessRule == true ||editAutoProcessRule == 1 ){setInitialAgeConditions();}},[editAutoProcessRule]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    populateServiceType();
    populateGroupId();
    populateSenderGroupId();
    populatePhysicianId();
    populateFacilityId();
    populateAllowedGroupId();
    populateAllowedPhysicianId();
    populateAllowedFacilityId();
    populateExcludedGroupId();
    populateExcludedPhysicianId();
    populateExcludedFacilityId();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editserviceTypes,
    editfulfillerGroupId,
    editfulfillerPhysicianId,
    editfulfillerFacilityId,
    editExcludedFulfillerFacilty,
    editExcludedFulfillerphysician,
    editExcludedFulfillerGroup,
    editAllowedFulfillerFacilty,
    editAllowedFulfillerphysician,editAllowedFulfillerGroup,editSenderGroup
  ]); // eslint-disable-line
  // useEffect(() => {
  //     populateGroupId()
  // }, [editfulfillerGroupId])   // eslint-disable-line
  // useEffect(() => {
  //     populatePhysicianId()
  // }, [editfulfillerPhysicianId])   // eslint-disable-line
  // useEffect(() => {
  //     populateFacilityId()
  // }, [editfulfillerFacilityId])   // eslint-disable-line

  
    useEffect(() => {    populateAttachmentType();  },[editAttachmentType]) // eslint-disable-line react-hooks/exhaustive-deps

//  const loadOptionsForFaxAttachment = (inputValue) => {
//   if (inputValue.length >= 3) {
//     // const searchParams = new URLSearchParams ({name: inputValue});
//     const url = `${API_ENDPOINT}/admin-api/get-fax-attachment-list/${inputValue}`;
//     var temp = fetch(url, {
//       headers: {
//         Authorization: API_TOKEN,
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => data.data);
//     return temp;
//   }
// }; 

const loadOptionsForFaxAttachment = (inputValue) => {
  if (inputValue.length >= 3) {
    // const searchParams = new URLSearchParams ({name: inputValue});
    return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-fax-attachment-list/${inputValue}`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => response.data.data)
      .catch((error) => {
        throw error;
      });
  }
}; 

  return (
    <div>
      <Modal isOpen={editAutoProcessRule} size="lg" toggle={toggle}>
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          {" "}
          Edit Auto Process Rule Conditions{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal">
          <Container fluid>
            <FormGroup>
              <div className="roles-list-card card">
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="groups">
                        Select Group<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        id="groups"
                        type="text"
                        placeholder="Select Groups"
                        options={groupNamesForDropDown}
                        value={editgroupId}
                        onChange={(e) => {
                          setEditGroupId(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Label for="apply_for">
                        Apply For<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        id="apply_for"
                        type="text"
                        placeholder="Select Referral Type"
                        options={referralTypeOptions}
                        value={editreferralType}
                        onChange={(e) => {
                          setEditReferralType(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Label for="Category type">Category type:</Label>
                    </Col>
                    <Col sm={8}>
                      <AsyncSelect
                        cacheOptions
                        isSearchable
                        placeholder="Select Category"
                        value={editReferralCategory}
                        loadOptions={loadOptionsForReferralCategory}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        onChange={(e) => {
                          setEditReferralCategory(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  {editreferralType.value==="sender"?(<>
                    <Row>
                    <Col>
                      <Label for="Category type">Sender Facility:</Label>
                    </Col>
                    <Col sm={8}>
                      <AsyncSelect
                        cacheOptions
                        isSearchable
                        placeholder="Select Sender Facility"
                        value={editSenderFacilityId}
                        loadOptions={loadOptionsForSenderFacility}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        onChange={(e) => {
                          setEditSenderFacilityId(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br /></>):("")}
                 
                  <Row>
                    <Col>
                      <Label for="name">
                        Name<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="name"
                        type="text"
                        placeholder="Name"
                        value={editruleName}
                        onChange={(e) => {
                          setEditRuleName(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    {/* <Col>
                      <Label for="ruleType">
                        Rule Type<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        id="ruleType"
                        type="text"
                        placeholder="Select Rule Type"
                        options={ruleTypeOptions}
                        value={editruleType}
                        onChange={(e) => {
                          setEditRuleType(e);
                        }}
                      />
                    </Col> */}
                  </Row>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details
                    open={true}
                    // onKeyDown={handleKey}
                    onClick={() => setOpen((o) => !o)}
                  >
                    <summary>
                      <h6 className="role-list-head-custom">Order Details</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="serviceType">
                          Service Type<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Select Service Type"
                          value={selectedServiceTypes}
                          loadOptions={loadServiceTypeOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedServiceTypes(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="diagnosis">
                          Diagnosis<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Diagnosis"
                          defaultValue={selectedDiagnosis}
                          loadOptions={loadDiagnosisOptions}
                          getOptionLabel={(e) => e.code_show}
                          getOptionValue={(e) => e.id}
                          getOptionCode={(e) => e.code}
                          onChange={(e) => {
                            setSelectedDiagnosis(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="priority">Priority:</Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          isMulti
                          id="priority"
                          type="text"
                          placeholder="Select Priority"
                          options={orderPriorityOptions}
                          // value={[{value: editorderPriority,label: editorderPriority}]}
                          value={selectedOrderPriority}
                          onChange={(e) => {
                            setSelectedOrderPriority(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="procedure">Procedure:</Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Procedure"
                          value={selectedProcedure}
                          loadOptions={loadProcedureOptions}
                          getOptionLabel={(e) => e.code_show}
                          getOptionValue={(e) => e.id}
                          getOptionCode={(e) => e.code}
                          onChange={(e) => {
                            setSelectedProcedure(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    {editreferralType.label ==="Sender" ? (
                      <>
                        <Row>
                          <Col>
                            <Label for="fulfillerGroup">Fulfiller Group:</Label>
                          </Col>
                          <Col sm={8}>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              isSearchable
                              placeholder="Fulfiller Group"
                              value={selectedFulfillerGroup}
                              loadOptions={loadFulfillerGroupOptions}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              onChange={(e) => {
                                setSelectedFulfillerGroup(e);
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Label for="fulfillerFacility">
                              Fulfiller Facility:
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              isSearchable
                              placeholder="Fulfiller Facility"
                              value={selectedFulfillerFacility}
                              loadOptions={loadFacilityOptionsForFacility}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              onChange={(e) => {
                                setSelectedFulfillerFacility(e);
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Label for="fulfillerPhysician">
                              Fulfiller Physician:
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              isSearchable
                              placeholder="Fulfiller Physician"
                              value={selectedPhysicianIds}
                              loadOptions={loadPhysicianOptions}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              onChange={(e) => {
                                setSelectedPhysicianIds(e);
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                      </>
                    ) : (
                      <Row>
                      <Col>
                        <Label for="fulfillerGroup">sender Group:</Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="sender Group"
                          value={selectedSenderGroup}
                          loadOptions={loadSenderGroupOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedSenderGroup(e);
                          }}
                        />
                      </Col>
                    </Row>
                    )}
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details open={true}>
                    <summary>
                      <h6 className="role-list-head-custom">Patient Details</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="age">
                          Age<span className="">:</span>
                        </Label>
                      </Col>
                      <Col xs="4">
                        <Select
                          id="age"
                          type="text"
                          placeholder="Select"
                          options={ageOptions}
                          value={selectedAgeCondition}
                          onChange={(e) => {
                            setSelectedAgeCondition(e);
                          }}
                        />
                      </Col>
                      {
                      
                
                      
                      
                      selectedAgeCondition.label !== "Between" ? (
                        <>
                     
                          <Col xs="4">
                            <Input
                              id="age"
                              type="text"
                              placeholder="Age"
                              // value={editpatientAgeCondition ? editpatientAgeCondition[0]["age"] : ""}
                              defaultValue={editPatientAge}
                              onChange={(e) => {
                                setSelectedAge(e.target.value);
                              }}
                            />
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      {
                        // eslint-disable-next-line eqeqeq
                        selectedAgeCondition.label == "Between" ? (
                          <>
                       
                            <Col xs="2">
                              <Input
                                id="age"
                                type="text"
                                placeholder="Min"
                                value={minValue}
                                onChange={(e) => {
                                  setMinValue(e.target.value);
                                }}
                              />
                            </Col>
                            <Col xs="2">
                              <Input
                                id="age"
                                type="text"
                                placeholder="Max"
                                value={maxValue}
                                onChange={(e) => {
                                  setMaxValue(e.target.value);
                                }}
                              />
                            </Col>
                          </>
                        ) : (
                          ""
                        )
                      }
                    </Row>
                    <br />

                    <Row>
                      <Col>
                        <Label for="gender">
                          Gender<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          id="gender"
                          type="text"
                          placeholder="Gender"
                          options={genderOptions}
                          value={editpatientGender}
                          onChange={(e) => {
                            setEditPatientGender(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="condition">
                          Condition<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Conditions"
                          defaultValue={selectedConditions}
                          loadOptions={loadConditionOptions}
                          getOptionLabel={(e) => e.category}
                          getOptionValue={(e) => e.id}
                          onChange={(e) => {
                            setSelectedConditions(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details open={true}>
                    <summary>
                      <h6 className="role-list-head-custom">Financial Data</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="payor">
                          Payor<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Select Insurance"
                          value={selectedInsurances}
                          loadOptions={loadInsuranceOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedInsurances(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    {  // eslint-disable-next-line eqeqeq
                    editreferralType.value=="sender"?(<>
                   
                      {selectedInsurances.length > 0 ? (
                      <>
                        <div style={{ paddingLeft: "37%" }}>
                          <Row>
                            <Col sm={4}>
                              <Input
                                id="eligibility_checkbox"
                                type="checkbox"
                                checked={editeligibilityCheck}
                                onChange={(e) => {
                                  setEditEligibilityCheck(e.target.checked);
                                }}
                              />
                              <Label for="eligibility_checkbox">
                                {" "}
                                Enable Eligibility Check
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            {
                              // eslint-disable-next-line eqeqeq
                              editeligibilityCheck == true ? (
                                <>
                                  <Col sm={4}>
                                    <Input
                                      id="preAuth_checkbox"
                                      type="checkbox"
                                      checked={editpreAuthCheck}
                                      onChange={(e) => {
                                        setEditPreAuthCheck(e.target.checked);
                                      }}
                                    />

                                    <Label for="preAuth_checkbox">
                                      {" "}
                                      Enable Pre-Auth Check
                                    </Label>
                                  </Col>
                                </>
                              ) : (
                                ""
                              )
                            }
                            {editeligibilityCheck &&
                            // eslint-disable-next-line eqeqeq
                            editpreAuthCheck == true ? (
                              <>
                                <Col sm={6}>
                                  <Label for="requestType">
                                    Request Type<span className="">:</span>
                                  </Label>
                                  <Select
                                    id="requestType"
                                    type="text"
                                    placeholder="Request Type"
                                    options={requestTypeOptions}
                                    value={editpreAuthRequestType}
                                    onChange={(e) => {
                                      setEditPreAuthRequestType(e);
                                    }}
                                  />
                                </Col>
                                <Col sm={2}>
                                  <Label for="pos">
                                    POS<span className="">:</span>
                                  </Label>
                                  <Input
                                    id="pos"
                                    type="text"
                                    placeholder="pos"
                                    value={editallowedPos}
                                    onChange={(e) => {
                                      setEditAllowedPos(e.target.value);
                                    }}
                                  />
                                </Col>
                              </>
                            ) : (
                              ""
                            )}
                          </Row>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    </>):("")}
                    
                  </details>
                </CardBody>
              </div>

              <div className="roles-list-card card">
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="">
                        Requires Add-on documents with Order
                        <span className="">:</span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <ToggleButton
                        inactiveLabel={""}
                        activeLabel={""}
                        value={toggleValue}
                        onToggle={() => {
                          if (editotherGenerateDocs?.length > 0) {
                            setToggleValue(!toggleValue);
                          } else {
                            setToggleValue(!toggleValue);
                            setSelectedadditionalDocs([]);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  {
                    // eslint-disable-next-line eqeqeq
                    toggleValue == 1 ? (
                      <>
                        <Row>
                          <Col>
                            <Label for="additionalDocuments">
                              Additional Documents:
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <Select
                              isClearable
                              id="additionalDocuments"
                              type="text"
                              placeholder="Select Documents"
                              options={additionalDocsOptions}
                              defaultValue={selectedadditionalDocs}
                              onChange={(e) => {
                                setSelectedadditionalDocs(e);
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Label for="">
                              Transmit Configured Documents on sent
                              <span className="">:</span>
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <ToggleButton
                              inactiveLabel={""}
                              activeLabel={""}
                              value={configuredDocOnSent}
                              onToggle={() => {
                             
                                let a = !configuredDocOnSent
                                setConfiguredDocOnSent(!configuredDocOnSent);
                            // eslint-disable-next-line eqeqeq
                            if (a == 1 || a == true){
                              setConfiguredDocOnSent(true);
                            }
                            else {
                              setConfiguredDocOnSent(false);
                            }
                              }}
                            />
                          </Col>
                        </Row>
                        {(configuredDocOnSent === 1 || configuredDocOnSent === true) ? (<>  <Row>
                            <Col sm={4}>
                              <Input
                                id="eligibility_checkbox"
                                type="checkbox"
                                checked={editTransmitEmiForm}
                                onChange={(e) => {
                                  setEditTransmitEmiForm(e.target.checked);
                                }}
                              />
                              <Label for="eligibility_checkbox">
                                {" "}
                              Transmit Emi form via fax
                              </Label>
                            </Col>
                          </Row>
                     
                          {// eslint-disable-next-line eqeqeq
                            (editTransmitEmiForm == 1 || editTransmitEmiForm == true)?(<>
                                 <Row>
                                  <Col>
                                 
                              <Input
                                id="age"
                                type="text"
                                placeholder="enter multiple fax number with comma"
                                value={editFaxNumber}
                                onChange={(e) => {
                                  setEditFaxNumber(e.target.value);
                                }}
                              />
                              </Col>
                              </Row>
                            </>):("")
                          }</> ): ""}
                      </>
                    ) : (
                      ""
                    )
                  }
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details open={true}>
                    <summary>
                      <h6 className="role-list-head-custom">
                        Fulfiller Selection
                      </h6>
                    </summary>
                    <Row>
                      <div>
                        <label>
                          <input
                            type="radio"
                            value="1"
                            checked={editAuto_Assign_Fullfiller === 1}
                            onChange={() => setEditAuto_Assign_Fullfiller(1)}
                          />
                          Auto Select by Distance & Ranking
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value="0"
                            checked={editAuto_Assign_Fullfiller === 0}
                            onChange={() => setEditAuto_Assign_Fullfiller(0)}
                          />
                          Disable Auto Selection
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value="2"
                            checked={editAuto_Assign_Fullfiller === 2}
                            onChange={() => setEditAuto_Assign_Fullfiller(2)}
                          />
                          Custom
                        </label>
                        {editAuto_Assign_Fullfiller === 2 && (
                          <div>
                            <label>
                              Custom Fulfiller:
                              <AsyncSelect
                                cacheOptions
                                isSearchable
                                placeholder="Select Fulfiller"
                                value={editAassign_FulfillerGroupId}
                                loadOptions={loadFulfillerOptions}
                                getOptionLabel={(e) => e.label}
                                getOptionValue={(e) => e.value}
                                onChange={(e) => {
                                  setEditAassign_FulfillerGroupId(e);
                                }}
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </Row>
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details open={true}>
                    <summary>
                      <h6 className="role-list-head-custom">
                        Required Fields<span className="required">*</span>
                      </h6>
                    </summary>

                    {/* ////////////////////////////////////////////////////////////////////////////////// */}
                    {/* //////////////////////////// NEW-render ReferralRule ///////////////////////////// */}
                    {/* ////////////////////////////////////////////////////////////////////////////////// */}
                    <Container fluid>
                      <Row>
                        <Col>
                          <Input
                            id="serviceType_checkbox"
                            type="checkbox"
                            checked={true}
                            onChange={(e) => {
                              setEditMandatoryItems(e.target.checked);
                            }}
                          />
                          <Label for="serviceType_checkbox">Service Type</Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Label for="serviceType">
                            Allowed Service Type<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select Service Type"
                            value={selectedAllowedServiceTypes}
                            loadOptions={loadServiceTypeOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedAllowedServiceTypes(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col>
                          <Label for="excludedServiceType">
                            Excluded Service Type<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select Service Type"
                            value={selectedExcludedServiceTypes}
                            loadOptions={loadServiceTypeOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedExcludedServiceTypes(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />
                      {// eslint-disable-next-line eqeqeq
                      editreferralType.value=="sender"?(<>
                        <Row>
                        <Col>
                          <Label for="serviceType">
                            Allowed fulfiller Group<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select fulfiller Group"
                            value={selectedAllowedFulfillerGroup}
                            loadOptions={loadFulfillerGroupOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedAllowedFulfillerGroup(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br /><Row>
                        <Col>
                          <Label for="fulfillerfacility">
                          Allowed fulfiller facility<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select fulfiller facility"
                            value={selectedAllowedFulfillerFacility}
                            loadOptions={loadFacilityOptionsForFacility}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedAllowedFulfillerFacility(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br /><Row>
                        <Col>
                          <Label for="serviceType">
                          Allowed fulfiller Physician<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select fulfiller Physician"
                            value={selectedAllowedFulfillerPhysician}
                            loadOptions={loadPhysicianOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedAllowedFulfillerPhysician(e);
                            }}
                          />
                        </Col>
                      </Row>
                   
                      <br /><Row>
                        <Col>
                          <Label for="serviceType">
                        Excluded fulfiller group<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select fulfiller group"
                            value={selectedExcludedFulfillerGroup}
                            loadOptions={loadFulfillerGroupOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedExcludedFulfillerGroup(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br /><Row>
                        <Col>
                          <Label for="serviceType">
                          Excluded fulfiller facility<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select fulfiller facility"
                            value={selectedExcludedFulfillerFacility}
                            loadOptions={loadFacilityOptionsForFacility}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedExcludedFulfillerFacility(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br /><Row>
                        <Col>
                          <Label for="serviceType">
                          Excluded fulfiller Physician<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select fulfiller Physician"
                            value={selectedExcludedFulfillerPhysician}
                            loadOptions={loadPhysicianOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedExcludedFulfillerPhysician(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />
                      </>):("")}                      
                     

                      <>
                        {referralRuleData.length > 0
                          ? referralRuleData.map((item) => {
                              return (
                                <>
                                  <Row>
                                    <Col>
                                      <Input
                                        id={item.item}
                                        type="checkbox"
                                        checked={handelCheckedForReferralRuleItem(
                                          item.item
                                        )}
                                        defaultValue={handelCheckedForReferralRuleItem(
                                          item.item
                                        )}
                                        onChange={(e) => {
                                          handelOnChangeForReferralRuleItem(
                                            e,
                                            item
                                          );
                                        }}
                                      />
                                      <Label for={item.item}>
                                        {
                                          // eslint-disable-next-line eqeqeq
                                          item.item == "Original Referral"
                                            ? "PCP Notes"
                                            : item.item
                                        }
                                      </Label>
                                    </Col>
                                  </Row>

                                  {checkedFour && item.item === "Diagnosis" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Diagnosis
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Diagnosis"
                                            value={selectedAllowedDiagnosis}
                                            loadOptions={loadDiagnosisOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedAllowedDiagnosis(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Diagnosis
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Diagnosis"
                                            value={selectedExcludedDiagnosis}
                                            loadOptions={loadDiagnosisOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedExcludedDiagnosis(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {checkedSix && item.item === "Procedure" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Procedure
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Allowed Procedure"
                                            value={selectedAllowedProcedure}
                                            loadOptions={loadProcedureOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedAllowedProcedure(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <div id="allowedVisits">
                                        {/* {selectedAllowedProcedure.length > 0 ? */}

                                        {/* selectedAllowedProcedure.map((item)=>{ */}
                                        {/* return(<> */}
                                        <Row>
                                          <Col>
                                            <Label for="excludedServiceType">
                                              Allowed No. of Visits
                                              <span className="">:</span>
                                            </Label>
                                          </Col>
                                          <Col sm={8}>
                                            <Input
                                              id=""
                                              type="text"
                                              placeholder="allowed no of visits (1,2,3...)"
                                              defaultValue={visitNumbers}
                                              onChange={(e) => {
                                                // this.setAllowedNoOfVisitors(prevState => ({ allowedNoOfVisitors: { ...prevState, e.target.value } }))

                                                setAllowedNoOfVisitors(
                                                  e.target.value
                                                );
                                                // setVisits({...allowedNoOfVisitors,visitNumber:e.target.value})
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        {/* </>) */}
                                        {/* }) */}

                                        {/* : null} */}
                                      </div>
                                      <br />
                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Procedure
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Procedure"
                                            value={selectedExcludedProcedure}
                                            loadOptions={loadProcedureOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedExcludedProcedure(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {checkedEight &&
                                  item.item === "Insurance Details" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Insurances
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Insurance"
                                            value={selectedAllowedInsurance}
                                            loadOptions={loadInsuranceOptions}
                                            getOptionLabel={(e) => e.label}
                                            getOptionValue={(e) => e.value}
                                            onChange={(e) => {
                                              setSelectedAllowedInsurance(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Insurances
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Insurance"
                                            value={selectedExcludedInsurance}
                                            loadOptions={loadInsuranceOptions}
                                            getOptionLabel={(e) => e.label}
                                            getOptionValue={(e) => e.value}
                                            onChange={(e) => {
                                              setSelectedExcludedInsurance(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                          : ""}
                      </>
                    </Container>
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                { // eslint-disable-next-line eqeqeq
                editreferralType.value=="sender"?(<>
                  <Row>
                          <Col>
                            <Label for="">
                            Transmit Additional Attachments on Sent Via Fax
                              <span className="">:</span>
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <ToggleButton
                              inactiveLabel={"OFF"}
                              activeLabel={"ON"}
                              value={transmitAdditionalAttachments}
                              onToggle={() => {
                             
                                let a = !transmitAdditionalAttachments
                                setTransmitAdditionalAttachments(!transmitAdditionalAttachments);
                          // eslint-disable-next-line eqeqeq            
                            if (a == 1 || a == true){
                              setTransmitAdditionalAttachments(true);
                            }
                            else {
                              setTransmitAdditionalAttachments(false);
                            }
                              }}
                            />
                          </Col>
                        </Row>
                
                    <br/>
                    { // eslint-disable-next-line eqeqeq
                    (transmitAdditionalAttachments == 1 || transmitAdditionalAttachments == true) ?(<>
                    
                <Row>
                  
                  <Col sm={8}>
                    <AsyncSelect
                    isMulti
                      cacheOptions
                      isSearchable
                      placeholder="Select Attachment Type"
                      value={selectAttachmentType}
                      loadOptions={loadOptionsForFaxAttachment}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      onChange={(e) => {
                       
                        console.log(e)
                        setSelectAttachmentType(e);
                      }}
                    />
                  </Col>
                </Row>
                <br/>
                    
                    </>):("")}
                    
                  <Row>
                    <Col>
                      <Label for="">Auto Send Referral:</Label>
                    </Col>
                    <Col sm={8}>
                      <ToggleButton
                        inactiveLabel={"OFF"}
                        activeLabel={"ON"}
                        value={editautoProcessEnable}
                        onToggle={(e) => {
                          setEditAutoProcessEnable(!editautoProcessEnable);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                </>):(<>
                
                  <Row>
                  <Col>
                      <Label for="serviceType">
                      Suggesting Form:<span className="">:</span>
                      </Label>
                  </Col>
                  <Col sm={8}>
                    <AsyncSelect
                    isMulti
                      cacheOptions
                      isSearchable
                      placeholder="Suggesting Form:"
                      value={editSuggestingForm}
                      loadOptions={loadSuggestingFormOptions}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      onChange={(e) => {
                       
                        console.log(e)
                        
                        setEditSuggestingForm(e);
                      }}
                    />
                  </Col>
                </Row>
                <br/></>)}
               
                </CardBody>
              </div>
            </FormGroup>
          </Container>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            onClick={() => {
              
              editAutoProcessRules();
              // patientAgePopulate()
            }}
            // disabled={buttonBlankState}
            // title={
            //   buttonBlankState
            //     ? "Please fill in all mandatory fields"
            //     : !buttonBlankState
            //     ? "Click to save"
            //     : ""
            // }
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditAutoProcessRule;
