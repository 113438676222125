import React, { useState, useEffect } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";

import AsyncSelect from "react-select/async";
import Cookie from 'js-cookie';
//import _ from "lodash";
//import { NULL } from 'node-sass';

const NewServiceCategoryModal = (props) => {
  const {
    newServiceCategoryModal,
    setNewServiceCategoryModal,
    userDataReload,
    setUserDataReload,
    newGroupName,
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  // const [newGroupName, setNewGroupName] = useState("");

  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryDescription, setNewCategoryDescription] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");

  const [grp, setGrp] = useState("");
  const [addButtonStatus, setAddButtonStatus] = useState(true);
  const [timeZoneDropDown, setTimeZoneDropDown] = useState([]); // eslint-disable-line

  const toggle = () => {
    setNewServiceCategoryModal(!newServiceCategoryModal);

    // setNewGroupName("")
    setNewCategoryName("");
    setNewCategoryDescription("");
  };

  const confirmation = () => {
    if (!newGroupName) {
      toastr.error("group Name Is Required", "Please enter the Group name!");
    }
    if (!newCategoryName) {
      toastr.error("Category Field Is Required", "Please enter the Category!");
    }

    if (newGroupName && newCategoryName) {
      CreateServiceCategoryApiCall();
    }
  };

  const CreateServiceCategoryApiCall = () => {
    // let gp_num = newCenterID ? newCenterID.value : ""
    // let gp_string = '' + gp_num

    // debugger

    const params = {
      groupID: grp ? grp[0].value : null,
      name: newCategoryName ? newCategoryName : "",
      description: newCategoryDescription ? newCategoryDescription : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/create-new-service-category/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then(function (response) {
        setUserDataReload(userDataReload + 1);
        toastr.success(
          "Successfully Created",
          "Service Category successfully created"
        );
        toggle();
      })
      .catch(function (error) {
        toastr.error("Failed", "Please try again");
      });
  };

  const disableAddButton = () => {
    if (newGroupName && newCategoryName) {
      setAddButtonStatus(false);
    } else {
      setAddButtonStatus(true);
    }
  };

  const fetchGroupbyId = (newGroupName) => {
    AxiosInter
      .get(
        `${API_ENDPOINT}/admin-api/get-groupid-list/${Number(newGroupName)}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          setGrp(response.data.data);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong in region listing !!");
      });
  };

  useEffect(() => {
    if (newGroupName !== "" && newGroupName !== undefined) {
      fetchGroupbyId(newGroupName);
    }
  }, [newGroupName]); // eslint-disable-line

  useEffect(() => {
    disableAddButton();
  }, [newGroupName, newCategoryName]); // eslint-disable-line

  const handleInputChange = (value) => {
    setInputValue(value);
  };
  //   const handleChange = value => {
  //     setNewGroupName(value);
  //   }
  //   const handleCategoryChange = value => {
  //     setNewCategory(value);
  //   }

  const loadHabitOptionsForGroupName = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  return (
    <div>
      <Modal
        isOpen={newServiceCategoryModal}
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Add New Service Category{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          <FormGroup>
            <Row>
              <Col sm={6}>
                <Label>
                  Group Name <span className="required">*</span> :
                </Label>
                <AsyncSelect
                  cacheOptions
                  isSearchable
                  placeholder="Group Name"
                  value={grp ? grp : ""}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  onInputChange={handleInputChange}
                  onChange={(e) => {
                    setGrp(e);
                  }}
                  loadOptions={(inputValue) =>
                    loadHabitOptionsForGroupName(inputValue)
                  }
                />
              </Col>
              <Col sm={6}>
                <Label>
                  Service Category Name <span className="required">*</span> :
                </Label>

                <Input
                  value={newCategoryName}
                  placeholder="Enter the description"
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setNewCategoryName(e.target.value);
                  }}
                />
              </Col>

              <Col sm={6}>
                <Label>Description</Label>
                <Input
                  id="Category Description"
                  type="textarea"
                  rows={4}
                  cols={2}
                  value={newCategoryDescription}
                  placeholder="Enter the description"
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setNewCategoryDescription(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button  custom-modal-footer">
          <Button
            onClick={() => {
              confirmation();
            }}
            disabled={addButtonStatus}
            title={addButtonStatus ? "Fill the required fields" : ""}
          >
            Save
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewServiceCategoryModal;
