import React, { useState, useEffect } from "react";
import {
    TabContent, TabPane, Nav, Label, Container,
    NavItem, NavLink, Card, CardHeader, Button,
    Row, Col, Breadcrumb, BreadcrumbItem,
    Spinner, DropdownItem, DropdownMenu, DropdownToggle,
    Input, UncontrolledDropdown
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import AddCareServiceModal from "../../newComponents/utilities/AddCareServiceModal";
import EditCareRosterModal from "../../newComponents/utilities/EditCareRosterModal";
import AxiosInter from './../../AxiosInter.js';
import classnames from "classnames";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes, faCheck, faEllipsisV, faTrash, faHandHoldingMedical, faSitemap } from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import StatusChangeModalPrograms from '../../newComponents/utilities/StatusChangeModalPrograms';
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import DeleteConfirmationModal from "../../newComponents/utilities/DeleteConfirmationModal";
import Cookie from 'js-cookie';
import MomentConfig from "../../common/MomentConfig";
import moment from "moment";
import RemotePagination from "../../newComponents/utilities/RemotePagination";

const ProgramComponent = () => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");
    const [fileExportName, setFileExportName] = useState(""); // eslint-disable-line
    const [buttonBlankState, setButtonBlankState] = useState(false);// eslint-disable-line
    const [activeTab, setActiveTab] = useState("1");
    const [modal, setModal] = useState(false);
    const [categoryModal, setCategoryModal] = useState(false);
    const [modalState, setModalState] = useState("initial");
    const [categoryModalState, setCategoryModalState] = useState("initial");
    const [newCareService, setNewCareService] = useState("");
    const [editCptCategory, setEditCptCategory] = useState("");
    const [editCptDescription, setEditCptDescription] = useState("");
    const [editCptLongDescription, setEditCptLongDescription] = useState("");
    const [editCptId, setEditCptId] = useState("");// eslint-disable-line
    const [selectedCPTCode, setSelectedCPTCode] = useState(); // eslint-disable-line
    const [selectedCPTCodeDescription, setSelectedCPTCodeDescription] = useState(); // eslint-disable-line
    const [selectedCPTCategory, setSelectedCPTCategory] = useState(""); // eslint-disable-line
    const [deleteModal, setDeleteModal] = useState(false);
    const [status, setStatus] = useState("");
    const [statusChangeModal, setStatusChangeModal] = useState(false);
    const [editCptCategoryDescription, setEditCPTCategoryDescription] = useState("");
    const [editCptCategoryId, setEditCptCategoryId] = useState("");
    const [reloadCareService, setReloadCareService] = useState(0);
    const [reloadCPTCategoryTableData, setReloadCPTCategoryTableData] = useState(0);
    const [isFilterSaved, setIsFilterSaved] = useState(false);// eslint-disable-line
    const [spinner, setSpinner] = useState(false);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(1);
    const [cptDetails, setCptDetails] = useState([]);
    const [totalSize, setTotalSize] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [programId, setProgramId] = useState();
    const [editStatus, setEditStatus] = useState();
    const [cptCategoryDetails, setCptCategoryDetails] = useState([]);
    const [cptCategoryOffset, setCptCategoryOffset] = useState(0);
    const [cptCategoryPage, setCptCategoryPage] = useState(1);
    const [cptCategoryTotalSize, setCptCategoryTotalSize] = useState(0);
    const [selectedId, setSelectedId] = useState(0);
    const [newRoster, setNewRoster] = useState();
    const [cptCategorySizePerPage, setCptCategorySizePerPage] = useState(50);
    const [searchKeywordCPTCode, setSearchKeywordCPTCode] = useState("");
    const [searchKeywordCPTCategory, setSearchKeywordCPTCategory] = useState("");
    const [isGlobalSearchCPTCodes, setIsGlobalSearchCPTCodes] = useState(false);
    const [isGlobalSearchCPTCategory, setIsGlobalSearchCPTCategory] = useState(false);
    const [sortOrder, setSortOrder] = useState(""); // eslint-disable-line
    const [sortColumn, setSortColumn] = useState(""); // eslint-disable-line
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    let filtVal = localStorage.getItem('actTab')

    const fileName = `Equipo-${fileExportName}-Export-${moment().format(
        MomentConfig.MOMENT_FORMAT_DATEANDTIME
    )}.csv`;

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const toggleClose = (tab) => {
        setModalState("")
        setNewCareService("")

    };
    const toggleCloseForRoster = (tab) => {
        setCategoryModalState("")
        setNewRoster("")
    };

    const handleTableChange = (type, newState) => {
        setPage(newState.page);
        setSizePerPage(newState.sizePerPage);
        setOffset((newState.page - 1) * newState.sizePerPage);
        const currentIndex = 0;
        if (newState.sortField !== "" && newState.sortOrder !== "") {
            let result;
            if (newState.sortOrder === 'asc') {
                result = cptDetails.sort((a, b) => {
                    if (a[newState.sortField] > b[newState.sortField]) {
                        return 1;
                    } else if (b[newState.sortField] > a[newState.sortField]) {
                        return -1;
                    }
                    return 0;
                });
            } else {
                result = cptDetails.sort((a, b) => {
                    if (a[newState.sortField] > b[newState.sortField]) {
                        return -1;
                    } else if (b[newState.sortField] > a[newState.sortField]) {
                        return 1;
                    }
                    return 0;
                });
            }
            setCptDetails(result.slice(currentIndex, currentIndex + sizePerPage));
        }
    };

    const handleCategoryTableChange = (type, newState) => {
        setCptCategoryPage(newState.page);
        setCptCategorySizePerPage(newState.sizePerPage);
        setCptCategoryOffset((newState.page - 1) * newState.sizePerPage);
        const currentIndex = 0;
        if (newState.sortField !== "" && newState.sortOrder !== "") {
            let result;
            if (newState.sortOrder === 'asc') {
                result = cptCategoryDetails.sort((a, b) => {
                    if (a[newState.sortField] > b[newState.sortField]) {
                        return 1;
                    } else if (b[newState.sortField] > a[newState.sortField]) {
                        return -1;
                    }
                    return 0;
                });
            } else {
                result = cptCategoryDetails.sort((a, b) => {
                    if (a[newState.sortField] > b[newState.sortField]) {
                        return -1;
                    } else if (b[newState.sortField] > a[newState.sortField]) {
                        return 1;
                    }
                    return 0;
                });
            }
            setCptCategoryDetails(result.slice(currentIndex, currentIndex + sizePerPage));
        }
    };
    let navigate = useNavigate();

    const actionsFormatter = (cell, row) => {
        return (
            <>
                <Button
                    className="money-button-blue"
                    outline
                    onClick={() => {
                        setModal(true);
                        setNewCareService(row.title);
                        setModalState("edit");
                        setSelectedId(row.id)
                    }}
                    color="primary"
                    title="Edit Care Services"
                >
                    <FontAwesomeIcon icon={faEdit} fixedWidth />
                </Button>
                {
                    row.status === 0 ? <Button
                        className="money-button-blue"
                        color="primary"
                        title="Inactive"
                    >
                        <FontAwesomeIcon icon={faTrash} style={{ color: "#808080" }} />
                    </Button> : <Button
                        className="money-button-blue"
                        outline
                        onClick={() => {
                            setSelectedId(row.id)
                            setDeleteModal(true);
                        }}
                        color="primary"
                        title="Delete Care Services"
                    >
                        <FontAwesomeIcon icon={faTrash} style={{ color: "#E12C2C" }} />
                    </Button>
                }

                <Button
                    className="money-button-blue"
                    outline
                >
                    <UncontrolledDropdown
                        title="Options"
                        onClick={() => {
                            setProgramId(row.id)
                        }} >
                        <DropdownToggle className="dropdown-options">
                            <FontAwesomeIcon
                                icon={faEllipsisV}
                                size={'lg'}
                                onClick={() => {
                                    setProgramId(row.id)
                                }}
                            />
                        </DropdownToggle>
                        <DropdownMenu align="end" container="body">
                            <DropdownItem
                                onClick={() => {
                                    navigate({
                                        pathname: '/programs/category/' + (row.id),
                                        state: { detail: isFilterSaved }
                                    })
                                    localStorage.setItem('title', row.title);
                                }}
                            >
                                <FontAwesomeIcon icon={faSitemap} />{" "}
                                Category
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => {
                                    navigate({
                                        pathname: '/programs/service-provided/' + (row.id),
                                        state: { detail: isFilterSaved }
                                    })
                                    localStorage.setItem('title', row.title);
                                }}
                            >
                                <FontAwesomeIcon icon={faHandHoldingMedical} />{" "}
                                Service Provided
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Button>
            </>
        );
    };

    const clearCptSearchValues = () => {
        setSearchKeywordCPTCode("");
        setReloadCareService(true);
    };
    const clearCptCategorySearchValues = () => {
        setSearchKeywordCPTCategory("");
        setReloadCPTCategoryTableData(true);
    };

    const cptCategoryActionsFormatter = (cell, row) => {
        return (
            <>
                <Button
                    className="money-button-blue"
                    onClick={() => {
                        setCategoryModal(true);
                        setCategoryModalState("edit");
                        setEditCPTCategoryDescription(row.title);
                        setEditStatus(row.status);
                        setSelectedId(row.id)
                    }}
                    color="primary"
                    title="Edit Care Roster"
                >
                    <FontAwesomeIcon icon={faEdit} />
                </Button>
                {row.status === 0 ? <Button
                    className="money-button-blue"
                    color="primary"
                    title="Inactive"
                >
                    <FontAwesomeIcon icon={faTrash} style={{ color: "#808080" }} />
                </Button> : <Button
                    className="money-button-blue"
                    onClick={() => {
                        setSelectedId(row.id)
                        setDeleteModal(true);
                    }}
                    color="primary"
                    title="Delete Care Roster"
                >
                    <FontAwesomeIcon icon={faTrash} style={{ color: "#E12C2C" }} />
                </Button>}

                <Button
                    className="money-button-blue"
                    outline
                >
                    <UncontrolledDropdown
                        title="Options"
                        onClick={() => {
                            setProgramId(row.id)
                            localStorage.setItem('actTab', "2")
                        }} >
                        <DropdownToggle className="dropdown-options">
                            <FontAwesomeIcon
                                icon={faEllipsisV}
                                size={'lg'}
                                onClick={() => {
                                    setProgramId(row.id)
                                }}
                            />
                        </DropdownToggle>
                        <DropdownMenu align="end" container="body">
                            <DropdownItem
                                onClick={() => {
                                    navigate({
                                        pathname: '/programs/category/' + (row.id),
                                        state: { detail: isFilterSaved }
                                    })
                                    localStorage.setItem('title', row.title);
                                }}
                            >
                                <FontAwesomeIcon icon={faSitemap} />{" "}
                                Category
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => {
                                    navigate({
                                        pathname: '/programs/roster-service-provided/' + (row.id),
                                        state: { detail: isFilterSaved }
                                    })
                                    localStorage.setItem('title', row.title);
                                }}>
                                <FontAwesomeIcon icon={faHandHoldingMedical} />{" "}
                                Service Provided
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Button>
            </>
        );
    };

    const parentCategoryFormatter = (cell, row) => {
        return cell ? cell : "--";
    };
    const statusFormatter = (cell, row) => {
        let status = "";
        let badgeclass = " ";
        if (cell === 1) {
            status = "Active";
            badgeclass = "badge badge-success statustoggle status-button-width";
        } else {
            status = "Inactive";
            badgeclass = "badge badge-danger statustoggle status-button-width";
        }

        return (
            <span
                className={badgeclass}
                onClick={() => {
                    setSelectedId(row.id)
                    setStatusChangeModal(true);
                    setStatus(cell)
                }}
            >
                {status}
            </span>
        );
    };
    const columns = [
        {
            dataField: "title",
            text: "Title",
            sort: true,
            formatter: parentCategoryFormatter,
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            formatter: statusFormatter,
        },
        {
            dataField: "lastLoadDate",
            text: "Created On",
            sort: false,
            formatter: parentCategoryFormatter,
        },
        {
            text: "Actions",
            sort: false,
            formatter: actionsFormatter,
        },
    ];

    const cptCategoryColumns = [
        {
            dataField: "title",
            text: "Title",
            sort: true,
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            formatter: statusFormatter,
        },
        {
            dataField: "lastLoadDate",
            text: "Created On",
            sort: false,
            formatter: parentCategoryFormatter,
        },
        {
            text: "Actions",
            sort: false,
            formatter: cptCategoryActionsFormatter,
        },
    ];

    const getCarePlanProgram = () => {
        setSpinner(true);
        const searchParams = new URLSearchParams({ title: searchKeywordCPTCode });
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-care-plan-program/?${searchParams.toString()}`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((response) => {
                if (response.data.data.length > 0) {
                    setSpinner(false);
                    setTotalSize(response.data.record_count);
                    setCptDetails(response.data.data);
                    localStorage.setItem("offset", offset);
                    setReloadCareService(0);
                } else {
                    setTotalSize(0);
                    setSpinner(false);
                    setCptDetails("");
                    setIsDataEmpty(true);
                    setReloadCareService(0);
                }
            })
            .catch((error) => {
                setSpinner(false);
                toastr.error("Error", "Something went wrong");
            });
    };

    const exportCptCodeDetails = () => {
        // const params = {
        //     file_name: fileName ? fileName : "",
        //     cpt_code_id: selectedCPTCode ? selectedCPTCode.value : "",
        //     cpt_description: selectedCPTCodeDescription
        //         ? selectedCPTCodeDescription.label
        //         : "",
        //     offset: offset ? offset : "0",
        //     is_pagination: "1",
        //     record_limit: "",
        //     search_keyword: searchKeywordCPTCode ? searchKeywordCPTCode : "",
        //     sortOrder: sortOrder ? sortOrder : "",
        //     sortColumn: sortColumn ? sortColumn : "",
        // };
        // AxiosInter
        //     .post(`${API_ENDPOINT}/admin-api/export-cpt-code-table-data/`, params, {
        //         headers: {
        //             Authorization: API_TOKEN,
        //         },
        //         responseType: "blob",
        //     })
        //     .then((response) => {
        //         if (response.status === 200) {
        //             var fileDownload = require("js-file-download");
        //             fileDownload(
        //                 response.data,
        //                 `${fileName}`,
        //                 response["headers"]["x-suggested-filename"]
        //             );
        //             toastr.success("Success", "Export successful");
        //         }
        //     })
        //     .catch((error) => {
        //         toastr.error("Failed", "Something went wrong");
        //     });
        
        function jsonToCSV(jsonData) {
            if (jsonData.length === 0) {
                return '';
            }
        
            const headers = Object.keys(jsonData[0]);

            const csvRows = jsonData.map(row => {
                return headers.map(fieldName => {
                    let value = row[fieldName];
                    if (value === null || value === undefined) value = ''; // Handle null or undefined
                    return `"${value.toString().replace(/"/g, '""')}"`; // Handle quotes
                }).join(',');
            });

        
            return [
                headers.join(','), // CSV header row
                ...csvRows          // CSV data rows
            ].join('\r\n');
        }

        let csvData = jsonToCSV(cptDetails)
        var fileDownload = require("js-file-download");
        fileDownload(csvData, fileName);
    };

    const getCarePlanRoster = () => {
        setSpinner(true);
        const searchParams = new URLSearchParams({ title: searchKeywordCPTCategory });
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-care-plan-roster/?${searchParams.toString()}`, {

            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((response) => {
                if (response.data.data.length > 0) {
                    setSpinner(false);
                    setCptCategoryTotalSize(response.data.record_count);
                    setCptCategoryDetails(response.data.data);
                    localStorage.setItem("cptCategoryOffset", cptCategoryOffset);
                } else {
                    setCptCategoryTotalSize(0);
                    setSpinner(false);
                    setCptCategoryDetails("");
                    setIsDataEmpty(true);
                }
            })
            .catch((error) => {
                setSpinner(false);
                toastr.error("Error", "Something went wrong");
            });
    };

    const exportCptCategoryDetails = () => {
        // const params = {
        //     file_name: fileName ? fileName : "",
        //     cpt_category_id: selectedCPTCategory ? selectedCPTCategory.value : "",
        //     cpt_category_description: "",
        //     offset: cptCategoryOffset ? cptCategoryOffset : "0",
        //     is_pagination: "1",
        //     record_limit: "",
        //     search_keyword: searchKeywordCPTCategory ? searchKeywordCPTCategory : "",
        //     sortOrder: sortOrder ? sortOrder : "",
        //     sortColumn: sortColumn ? sortColumn : "",
        // };
        // AxiosInter
        //     .post(
        //         `${API_ENDPOINT}/admin-api/export-cpt-categories-table-data/`,
        //         params,
        //         {
        //             headers: {
        //                 Authorization: API_TOKEN,
        //             },
        //             responseType: "blob",
        //         }
        //     )
        //     .then((response) => {
        //         if (response.status === 200) {
        //             var fileDownload = require("js-file-download");
        //             fileDownload(
        //                 response.data,
        //                 `${fileName}`,
        //                 response["headers"]["x-suggested-filename"]
        //             );
        //             toastr.success("Success", "Export successful");
        //         }
        //     })
        //     .catch((error) => {
        //         toastr.error("Failed", "Something went wrong");
        //     });
        function jsonToCSV(jsonData) {
            if (jsonData.length === 0) {
                return '';
            }
        
            const headers = Object.keys(jsonData[0]);

            const csvRows = jsonData.map(row => {
                return headers.map(fieldName => {
                    let value = row[fieldName];
                    if (value === null || value === undefined) value = ''; // Handle null or undefined
                    return `"${value.toString().replace(/"/g, '""')}"`; // Handle quotes
                }).join(',');
            });

        
            return [
                headers.join(','), // CSV header row
                ...csvRows          // CSV data rows
            ].join('\r\n');
        }

        let csvData = jsonToCSV(cptCategoryDetails)
        var fileDownload = require("js-file-download");
        fileDownload(csvData, fileName);
    };


    const addNewCareService = () => {
        const params =
        {
            "title": newCareService ? newCareService : "",
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/add-care-plan-program/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                setReloadCareService(true)
                toastr.success("Success", "New care service added")
                toggleClose();
            })
            .catch(error => {
                if (error.response.data.message) {
                    toastr.error("Error", error.response.data.message)
                }
                else {
                    toastr.error("Error", "Something went wrong !")
                }
            });
    }

    const addNewCareRoster = () => {
        const params =
        {
            "title": newRoster ? newRoster : "",
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/add-care-plan-roster/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                setReloadCareService(true)
                toastr.success("Success", "New care roster added")
                toggleCloseForRoster();
            })
            .catch(error => {
                if (error.response.data.message) {
                    toastr.error("Error", error.response.data.message)
                }
                else {
                    toastr.error("Error", "Something went wrong !")
                }


            });
    }

    const deleteCarePlanProgram = () => {
        setSpinner(true);
        const params = {
            row_id: selectedId ? selectedId : "",
        };
        AxiosInter
            .post(`${API_ENDPOINT}/admin-api/delete-care-plan-program/`, params, {
                headers: {
                    Authorization: API_TOKEN,
                },
            })
            .then((response) => {
                toastr.success("Success", activeTab === "1" ? "Care service deleted successfully" : "Care roster deleted successfully");
                setSpinner(false);
                setReloadCareService(reloadCareService + 1)
                setDeleteModal(false);
            })
            .catch((error) => {
                toastr.error("Error", "Something went wrong");
                setSpinner(false);
            });
    };

    const updateCarePlanProgramStatus = () => {
        setSpinner(true);
        const params = {
            "row_id": selectedId ? selectedId : "",
            "status": status === 0 ? 1 : 0
        };
        AxiosInter
            .post(`${API_ENDPOINT}/admin-api/update-program-status/`, params, {
                headers: {
                    Authorization: API_TOKEN,
                },
            })
            .then((response) => {
                setReloadCareService(reloadCareService + 1)
                setStatusChangeModal(false);
                setSpinner(false);
                toastr.success('Status Updated', 'Status updated successfully');
            })
            .catch((error) => {
                toastr.error("Error", "Something went wrong");
                setSpinner(false);
            });
    };

    useEffect(() => {
        if (programId) {
            console.log('582', programId)
        }
    }, [programId]);

    useEffect(() => {
        if (filtVal === "2") {
            setActiveTab("2")
        }
    }, []); // eslint-disable-line


    useEffect(() => {
        if (activeTab === "2") getCarePlanRoster();
    }, []); // eslint-disable-line

    useEffect(() => {
        var previousOffset = localStorage.getItem("offset");
        if (offset !== previousOffset || sizePerPage) {
            setReloadCareService(reloadCareService + 1);
        }
    }, [offset, sizePerPage]); // eslint-disable-line

    useEffect(() => {
        var previousOffset = localStorage.getItem("cptCategoryOffset");
        if (cptCategoryOffset !== previousOffset || cptCategorySizePerPage) {
            setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1);
        }
    }, [cptCategoryOffset, cptCategorySizePerPage]); // eslint-disable-line

    useEffect(() => {
        if (reloadCareService) {
            getCarePlanProgram();
            getCarePlanRoster();
        }
    }, [reloadCareService]); // eslint-disable-line

    useEffect(() => {
        if (activeTab === "2" && reloadCPTCategoryTableData) {
            getCarePlanRoster();
        }
    }, [reloadCPTCategoryTableData]); // eslint-disable-line

    useEffect(() => {
        if (isGlobalSearchCPTCodes) {
            setOffset(0);
            setTotalSize(0);
            setReloadCareService(reloadCareService + 1);
            setIsGlobalSearchCPTCodes(false);
        }
    }, [isGlobalSearchCPTCodes]); // eslint-disable-line

    useEffect(() => {
        if (isGlobalSearchCPTCategory) {
            setOffset(0);
            setCptCategoryTotalSize(0);
            setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1);
            setIsGlobalSearchCPTCategory(false);
        }
    }, [isGlobalSearchCPTCategory]); // eslint-disable-line

    useEffect(() => {
        if (searchKeywordCPTCode.length > 0) {
            setReloadCareService(reloadCareService + 1);
        }
        if (searchKeywordCPTCode.length <= 0) {
            getCarePlanProgram()

        }
    }, [searchKeywordCPTCode]); // eslint-disable-line

    useEffect(() => {
        if (searchKeywordCPTCategory.length > 0) {
            setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1);
        }
        if (searchKeywordCPTCategory.length <= 0) {
            getCarePlanRoster()
        }
    }, [searchKeywordCPTCategory]); // eslint-disable-line

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <Container fluid>
                <Header>
                    <HeaderTitle>
                        Programs
                        {activeTab === "1" ? (
                            <Button
                                className="Add-btn pull-right"
                                onClick={() => {
                                    setModalState("add");
                                }}
                            >
                                Add Care Service
                            </Button>
                        ) : (
                            <Button
                                className="Add-btn pull-right"
                                onClick={() => {
                                    setCategoryModalState("add");
                                }}
                            >
                                Add Care Roster
                            </Button>
                        )}
                    </HeaderTitle>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/dashboard">Dashboard</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Programs</BreadcrumbItem>
                    </Breadcrumb>
                </Header>
            </Container>
            <Card className="adv-search-background-card">
                <CardHeader></CardHeader>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "1" })}
                            onClick={() => {
                                toggle("1");
                                localStorage.removeItem('actTab')
                                toggleCloseForRoster()
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            Care Services
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "2" })}
                            onClick={() => {
                                toggle("2");
                                toggleClose()
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            Care Roster
                        </NavLink>
                    </NavItem>
                </Nav>
                <br /><br />
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        {activeTab === "1" && modalState === "add" ?
                            <Row style={{ paddingLeft: 40 }}>
                                <Col sm={1.5}>
                                    <Label for="cpt_code">Care Service<span className="required">*</span></Label>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        id="cpt_code"
                                        placeholder="Enter Care Service Name"
                                        value={newCareService}
                                        onChange={(e) => {
                                            setNewCareService(e.target.value)
                                        }}
                                    />
                                </Col>
                                <Col sm={4} className="program-tick-minus" style={{ paddingLeft: 0 }}>
                                    <Button
                                        className="tick-button"
                                        onClick={() => {
                                            addNewCareService();
                                        }}
                                        disabled={buttonBlankState}
                                        title={buttonBlankState ? "Please fill in all mandatory fields" : (!buttonBlankState && modalState) ? "Click to save" : "Click to update"}
                                    ><FontAwesomeIcon icon={faCheck} title="Click to save" /></Button>
                                    <Button
                                        className="minus-button"
                                        title="Close"
                                        fixedWidth="true"
                                        onClick={toggleClose}><FontAwesomeIcon icon={faTimes}
                                        />
                                    </Button>
                                </Col>
                            </Row> : ""}
                        <div className="groups-search">
                            <SearchBarComponent
                                data={cptDetails ? cptDetails : ""}
                                searchKeyword={searchKeywordCPTCode}
                                setSearchKeyword={setSearchKeywordCPTCode}
                                totalSize={totalSize}
                                clearValues={clearCptSearchValues}
                                columns={columns}
                            /></div>
                        {!spinner ? (
                            <>
                                <RemotePagination
                                    data={cptDetails ? cptDetails : ""}
                                    columns={columns}
                                    page={page}
                                    isDataEmpty={isDataEmpty}
                                    sizePerPage={sizePerPage}
                                    FileExportName={fileExportName}
                                    exportTableData={
                                        activeTab === "1"
                                            ? exportCptCodeDetails
                                            : exportCptCategoryDetails
                                    }
                                    totalSize={totalSize ? totalSize : ""}
                                    searchKeyword={searchKeywordCPTCode}
                                    setSearchKeyword={setSearchKeywordCPTCode}
                                    reloadVariable={reloadCareService}
                                    setReloadDetails={setReloadCareService}
                                    onTableChange={(type, newState) => {
                                        handleTableChange(type, newState);
                                    }}
                                />
                            </>
                        ) : (
                            <Spinner />
                        )}
                    </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="2">
                        {activeTab === "2" && categoryModalState === "add" ?
                            <Row style={{ paddingLeft: 40 }}>
                                <Col sm={1.5}>
                                    <Label for="cpt_code">Care Roster<span className="required">*</span></Label>
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        id="cpt_code"
                                        placeholder="Enter Care Roster Name"
                                        value={newRoster}
                                        onChange={(e) => {
                                            setNewRoster(e.target.value)
                                        }}
                                    />
                                </Col>
                                <Col sm={4} className="program-tick-minus" style={{ paddingLeft: 0 }}>
                                    <Button
                                        className="tick-button"
                                        onClick={() => {
                                            addNewCareRoster();
                                        }}
                                        disabled={buttonBlankState}
                                        title={buttonBlankState ? "Please fill in all mandatory fields" : (!buttonBlankState &&
                                            modalState) ? "Click to save" : "Click to update"}
                                    ><FontAwesomeIcon icon={faCheck} title="Click to save" /></Button>
                                    <Button
                                        className="minus-button"
                                        title="close"
                                        fixedWidth="true"
                                        onClick={toggleCloseForRoster}><FontAwesomeIcon icon={faTimes}
                                        />
                                    </Button>
                                </Col>
                            </Row> : ""}
                        <div className="groups-search">
                            <SearchBarComponent
                                searchKeyword={searchKeywordCPTCategory}
                                setSearchKeyword={setSearchKeywordCPTCategory}
                                totalSize={cptCategoryTotalSize}
                                columns={columns}
                                data={cptCategoryDetails ? cptCategoryDetails : ""}
                                clearValues={clearCptCategorySearchValues}
                            /></div>
                        {!spinner ? (
                            <>
                                <RemotePagination
                                    data={cptCategoryDetails ? cptCategoryDetails : ""}
                                    columns={cptCategoryColumns}
                                    page={cptCategoryPage}
                                    isDataEmpty={isDataEmpty}
                                    sizePerPage={cptCategorySizePerPage}
                                    FileExportName={fileExportName}
                                    exportTableData={activeTab === "2" ? exportCptCategoryDetails : exportCptCodeDetails}
                                    totalSize={cptCategoryTotalSize ? cptCategoryTotalSize : ""}
                                    searchKeyword={searchKeywordCPTCategory}
                                    setSearchKeyword={setSearchKeywordCPTCategory}
                                    reloadVariable={reloadCPTCategoryTableData}
                                    setReloadDetails={setReloadCPTCategoryTableData}
                                    onTableChange={(type, newState) => {
                                        handleCategoryTableChange(type, newState);
                                    }}
                                />
                            </>
                        ) : (
                            <Spinner />
                        )}
                    </TabPane>
                </TabContent>
            </Card >
            <AddCareServiceModal
                modal={modal}
                setModal={setModal}
                newCareService={newCareService}
                setNewCareService={setNewCareService}
                editCptCategory={editCptCategory}
                setEditCptCategory={setEditCptCategory}
                editCptDescription={editCptDescription}
                setEditCptDescription={setEditCptDescription}
                editCptLongDescription={editCptLongDescription}
                setEditCptLongDescription={setEditCptLongDescription}
                setModalState={setModalState}
                modalState={modalState}
                editCptId={editCptId}
                selectedId={selectedId}
                setReloadCareService={setReloadCareService}
            />
            <EditCareRosterModal
                categoryModal={categoryModal}
                setCategoryModal={setCategoryModal}
                categoryModalState={categoryModalState}
                editCptCategoryDescription={editCptCategoryDescription}
                setEditCPTCategoryDescription={setEditCPTCategoryDescription}
                editCptCategoryId={editCptCategoryId}
                setEditCptCategoryId={setEditCptCategoryId}
                reloadCPTCategoryTableData={reloadCPTCategoryTableData}
                setReloadCPTCategoryTableData={setReloadCPTCategoryTableData}
                editStatus={editStatus}
                setEditStatus={setEditStatus}
                selectedId={selectedId}
            />
            <DeleteConfirmationModal
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                deleteFunction={deleteCarePlanProgram}
            />
            <StatusChangeModalPrograms
                modalState={statusChangeModal}
                setModalState={setStatusChangeModal}
                status={status}
                updateCarePlanProgramStatus={updateCarePlanProgramStatus}
            />
        </div >
    );
};

export default ProgramComponent;
