import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  Container,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { Select as SelectVirtualized } from "react-select-virtualized";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import CPTCodeListModal from "../../newComponents/utilities/CPTCodeListModal";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import { GlobalState } from "../../contextAPI/Context";
import Cookie from 'js-cookie';

const GroupsCptPackagesModal = (props) => {
  const { groupNamesForDropDown } = useContext(GlobalState);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [buttonBlankState, setButtonBlankState] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [listingModal, setListingModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchSpinner, setSearchSpinner] = useState(false);
  const [cptDetails, setCptDetails] = useState("");
  const [cptDetailsListing, setCptDetailsListing] = useState("");
  const [grp, setGrp] = useState("");

  // let selectedIcdCodes = [];

  const {
    cptPackageModal,
    setCptPackageModal,
    cptPackageModalState,
    setCptPackageModalState,

    selectedGroupName,
    setSelectedGroupName,
    cptPackageGroup,
    setCptPackageGroup,
    selectedCptPackage,
    setSelectedCptPackage,
    cptPackageName,
    setCptPackageName,
    cptPackageId,
    cptPackageReload,
    setCptPackageReload,
    setCptPackageNamesReload,
    cptPackageNamesReload,
  } = props;

  const toggle = () => {
    setCptPackageModal(!cptPackageModal);
  };

  const handleListItemClick = (event, value) => {
    if (event.target.checked) {
      addNewCptPackageDetails(event.target.value);
      // setSelectedIcdCodes(state1 => [...state1, event.target.value])
    }
  };

  // const checkInitialCheckedValues = () => {
  //     icdPackageName.map((item)=>{
  //         icdDetails.map((icdItem)=>{
  //             if (item.icdCode === icdItem.code) {
  //                 setIcdChecked(true)
  //             }

  //         });

  //     });
  // }

  const RenderCptListingView = (cptDetailsObj) => {
    return (
      <List
        sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}
      >
        {[cptDetails].map((item) => {
          return (
            <ListItem disablePadding>
              <ListItemButton dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    value={cptDetailsObj.code}
                    onClick={(event) => handleListItemClick(event)}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={cptDetailsObj.code}
                  secondary={cptDetailsObj.description}
                ></ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const filterSelectedValues = (data) => {
    if (selectedCptPackage.length > 0) {
      const selectedCpts = selectedCptPackage.map(
        (selectedCpt) => selectedCpt.cptCode
      );
      const filteredCptList = data.filter(
        (cptItem) => !selectedCpts.includes(cptItem.code)
      );
      let cptDetails = filteredCptList.map((item) => {
        return RenderCptListingView(item);
      });
      setCptDetailsListing(cptDetails);
    } else {
      let cptDetails = data.map((item) => {
        return RenderCptListingView(item);
      });
      setCptDetailsListing(cptDetails);
    }
  };

  const setInitialGroupName = () => {
    groupNamesForDropDown.map((data) =>  // eslint-disable-line
    {
      if (data.label === cptPackageGroup) {
        setSelectedGroupName({ value: data.value, label: cptPackageGroup });
      }
    });
  };

  const fetchCPTCodeDetails = () => {
    setSearchSpinner(true);
    const params = {
      cpt_search_keyword: searchKeyword ? searchKeyword : "",
    };

    //    setSpinner (true);

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-cpt-details-by-keyword/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setCptDetails(response.data.data);
          setSearchSpinner(false);
          setListingModal(true);
        } else {
          setCptDetails("");
          setSearchSpinner(false);
          setListingModal(false);
        }
      })
      .catch((error) => {
        toastr.error("Something went wrong");
      });
  };

  const addNewCptPackageDetails = (newCptCode) => {
    cptDetails.map((cptItem) => // eslint-disable-line
    {
      if (cptItem.code === newCptCode) {
        setSelectedCptPackage((state) => [
          ...state,
          {
            cptId: cptItem.id,
            cptCode: cptItem.code,
            cptDescription: cptItem.description,
          },
        ]);
        toastr.success("Success", "CPT package added");
      }
    });
  };

  const validate = () => {
    // debugger
    if (cptPackageName && grp) {
      setButtonBlankState(false);
    } else {
      setButtonBlankState(true);
    }
  };

  const handleDelete = (index) => {
    setSelectedCptPackage(selectedCptPackage.filter((_, i) => i !== index));
  };

  const updateCPTPackageDetails = () => {
    const params = {
      cpt_package_id: cptPackageId ? cptPackageId : "",
      cpt_package: selectedCptPackage ? selectedCptPackage : [],
      group_ID: selectedGroupName ? selectedGroupName.value : "",
      cpt_package_name: cptPackageName ? cptPackageName : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/edit-cpt-packages/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        toggle();
        toastr.success("Success", "CPT Package details updated");
        setCptPackageReload(cptPackageReload + 1);
        setCptPackageNamesReload(cptPackageNamesReload + 1);
      })
      .catch((error) => {
        if (error.response.data.message) {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong !");
        }
      });
  };

  const fetchGroupbyId = (selectedGroupName) => {
    AxiosInter
      .get(
        `${API_ENDPOINT}/admin-api/get-pkggroupid-list/${selectedGroupName}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          setGrp(response.data.data);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong in groups listing !!");
      });
  };

  useEffect(() => {
    if (selectedGroupName !== "" && selectedGroupName !== undefined) {
      fetchGroupbyId(selectedGroupName);
    }
  }, [selectedGroupName]); // eslint-disable-line

  const addNewCPTPackage = () => {
    const params = {
      cpt_package: selectedCptPackage ? selectedCptPackage : [],
      group_ID: grp ? grp[0].value : null,
      cpt_package_name: cptPackageName ? cptPackageName : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/add-cpt-packages/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        toggle();
        toastr.success("Success", "New CPT Package added");
        setCptPackageReload(cptPackageReload + 1);
        setCptPackageNamesReload(cptPackageNamesReload + 1);
      })
      .catch((error) => {
        if (error.response.data.message) {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong !");
        }
      });
  };

  const CptCodeChip = ({ cptPackage }) => (
    <>
      <Row>
        <Stack
          direction="row"
          spacing={1}
          sx={{ width: "100%", maxWidth: "100%", bgcolor: "background.paper" }}
        >
          <Col sm={12}>
            <Label for="icd_search">CPT Packages</Label>
            <br />

            {cptPackage ? (
              cptPackage.map((item) => {
                return (
                  <Tooltip title={item.cptDescription} placement="top" arrow>
                    <Chip
                      label={item.cptCode}
                      style={{ cursor: "pointer" }}
                      onDelete={() => {
                        handleDelete(cptPackage.indexOf(item));
                      }}
                      variant="outlined"
                    />
                  </Tooltip>
                );
              })
            ) : (
              <h1>No packages chosen</h1>
            )}
          </Col>
        </Stack>
      </Row>
    </>
  );

  useEffect(() => {
    if (!cptPackageModal) {
      setCptPackageModalState("initial");
      setSelectedCptPackage([]);
      setCptPackageModal(false);
      setCptPackageName("");
      setSelectedGroupName("");
      setSearchKeyword("");
      setCptPackageGroup("");
    }
  }, [cptPackageModal]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length > 2) {
      fetchCPTCodeDetails();
    }
  }, [searchKeyword]); // eslint-disable-line

  useEffect(() => {
    if (cptDetails) {
      filterSelectedValues(cptDetails);
    }
  }, [cptDetails]); // eslint-disable-line

  useEffect(() => {
    if (groupNamesForDropDown && cptPackageGroup) {
      setInitialGroupName();
    }
  }, [groupNamesForDropDown, cptPackageGroup]); // eslint-disable-line

  useEffect(() => {
    if (cptPackageName || grp) {
      validate();
    }
  }, [cptPackageName, selectedGroupName]); // eslint-disable-line

  return (
    <div>
      <Modal isOpen={cptPackageModal} size="lg" >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          {cptPackageModalState === "add"
            ? "Add CPT Package "
            : "Edit CPT Package"}{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal">
          <Container fluid>
            <FormGroup>
              <Row>
                <Col sm={6}>
                  <Label for="cpt_package_name">
                    Name<span className="required">*</span>
                  </Label>
                  <Input
                    id="cpt_package_name"
                    type="text"
                    placeholder="Name"
                    value={cptPackageName}
                    onChange={(e) => {
                      setCptPackageName(e.target.value);
                    }}
                  />
                </Col>

                <Col sm={6}>
                  <Label for="groupDropDown">Group</Label>
                  <SelectVirtualized
                    name="groupDropDown"
                    options={groupNamesForDropDown}
                    value={grp}
                    placeholder="Choose a Group"
                    isSearchable
                    isClearable
                    onChange={(e) => {
                      setGrp(e);
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Label for="cpt_search">Search CPT code</Label>
                  <Input
                    id="icd_search_keyword"
                    type="text"
                    placeholder="Search keyword"
                    value={searchKeyword}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />
                  <small className="required">
                    <span className="required">*</span>Length of search keyword
                    should be atleast 3
                  </small>
                </Col>
              </Row>
              <br />

              <CptCodeChip cptPackage={selectedCptPackage} />
            </FormGroup>
          </Container>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            onClick={() => {
              cptPackageModalState === "edit"
                ? updateCPTPackageDetails()
                : addNewCPTPackage();
            }}
            disabled={buttonBlankState}
            title={
              buttonBlankState
                ? "Please fill in all mandatory fields"
                : !buttonBlankState && cptPackageModalState === "add"
                ? "Click to save"
                : "Click to update"
            }
          >
            {cptPackageModalState === "edit" ? "Update" : "Add"}
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <CPTCodeListModal
        listingModal={listingModal}
        setListingModal={setListingModal}
        cptDetailsListing={cptDetailsListing}
        cptDetails={cptDetails}
        selectedCptPackage={selectedCptPackage}
        addNewCptPackageDetails={addNewCptPackageDetails}
      />
    </div>
  );
};

export default GroupsCptPackagesModal;
