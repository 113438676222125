import React, { useEffect, useState, useContext } from "react";
import AxiosInter from './../../AxiosInter.js';
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Label,
  CardHeader,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import ProviderStatusChangeModal from "../../newComponents/utilities/ProviderStatusChange";
import NewProviderModal from "../../newComponents/utilities/NewProviderModal";
import EditAllProviderModal from "../../newComponents/utilities/EditProvider";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MomentConfig from "../../common/MomentConfig";
import RemotePaginationForProvider from "../../newComponents/utilities/RemotePaginationForProvider";
import SearchBarComponentProvider from "../../newComponents/utilities/SearchBarComponentProvider";
import { GlobalState } from "../../contextAPI/Context";
import Cookie from 'js-cookie';


const AllProvider = () => {
  const { zipCodeOptions, m_zipList, zipListLoaded } = useContext(GlobalState);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const fileExportName = "Provider";
  const [spinner, setSpinner] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [search, setSearch] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [npi, setNpi] = useState("");
  const [providerType, setProviderType] = useState("");
  const [providerID, setProviderID] = useState("");
  // edit provider modal
  const [editProviderModal, setEditProviderModal] = useState(false);
  const [newProviderModal, setNewProviderModal] = useState(false);
  const [editGroupName, setEditGroupName] = useState("");
  const [editCategory, setEditCategory] = useState("");
  const [rowData, setRowData] = useState("");
  const [editProviderListID, setEditProviderListID] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editNpi, setEditNpi] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editFirstName, setEditFirstName] = useState("");
  const [editMiddleName, setEditMiddleName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editDegree, setEditDegree] = useState("");
  const [editCredentials, setEditCredentials] = useState("");
  const [editAddress, setEditAddress] = useState("");
  const [editAddress2, setEditAddress2] = useState("");
  const [editCity, setEditCity] = useState("");
  const [editState, setEditState] = useState("");
  const [editZipCode, setEditZipCode] = useState("");
  const [editZipID, setEditZipID] = useState("");
  const [editProviderType, setEditProviderType] = useState("");
  const [editProviderID, setEditProviderID] = useState("");
  const [editDoctorGroup, setEditDoctorGroup] = useState("");
  const [editFacility, setEditFacility] = useState("");
  const [editMarketingRep, setEditMarketingRep] = useState("");
  const [editLicenseNumber, setEditLicenseNumber] = useState("");
  const [editExpiration, setEditExpiration] = useState("");
  const [editDeaNumber, setEditDeaNumber] = useState("");
  const [editUpin, setEditUpin] = useState("");
  const [editStateMedicaidId, setEditStateMedicaidId] = useState("");
  const [editTaxonomyCode, setEditTaxonomyCode] = useState("");
  const [editFax, setEditFax] = useState("");
  const [editPhoneHome, setEditPhoneHome] = useState("");
  const [editContactNumber, setEditContactNumber] = useState("");
  const [editReferralStatus, setEditReferralStatus] = useState("");
  const [editNote, setEditNote] = useState("");
  const [status, setStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [userDataReload, setUserDataReload] = useState(0);
  const [userSizePerPage, setUserSizePerPage] = useState("50");
  const [offset, setOffset] = useState("0");
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  let loader = null;

  if (apiLoaded) {
    loader = <Spinner />;
  }
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;
  const statusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];

  const clearValues = () => {
    setProviderName("");
    setNpi("");
    setSelectedStatus("");
    setProviderType("");
    setProviderList("");
    setSortOrder("");
    setTotalSize(0);
    setUserDataReload(userDataReload + 1);
  };
  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else if (cell === 0) {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }
    else {

      
      status = "N/A";
     
    
    }
    return (
      <span
        className={badgeclass}
        onClick={() => {
          setProviderID(row.id);
          setStatus(cell);
          setModalState(true);
        }}
      >
        {status}
      </span>
    );
  };

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive";
  };
  if (apiLoaded) {
    loader = <Spinner />;
  }
  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faEdit}
              size={"1x"}
              color={"#3f86fa"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setEditProviderModal(true);
                setEditProviderListID(row.id);
                let pID = row.id;
                if (pID) {
                  AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-provider-id/${pID}`, {
                      headers: {
                        Authorization: API_TOKEN,
                      },
                  })
                    .then((response) => {
                      if (response.data.data.length > 0) {
                        const phone_trim = (name) => {
                          return (
                            name.slice(1, 4) +
                            name.slice(6, 9) +
                            name.slice(10, 14)
                          );
                        };
                        setEditNote(response.data.data[0]["note"]);
                        // eslint-disable-next-line eqeqeq
                        if (response.data.data[0]["contactnumber"] != "0") {
                          setEditContactNumber(
                            response.data.data[0]["contactnumber"]
                          );
                        }
                        setEditPhoneHome(response.data.data[0]["phone_home"]);
                        setEditFax(response.data.data[0]["fax"]);
                        if (response.data.data[0]["fax"] != null) {
                          // eslint-disable-next-line eqeqeq
                          if (response.data.data[0]["fax"][0] == "(") {
                            setEditFax(
                              phone_trim(response.data.data[0]["fax"])
                            );
                          }
                        }

                        if (response.data.data[0]["phone_home"] != null) {
                          // eslint-disable-next-line eqeqeq
                          if (response.data.data[0]["phone_home"][0] == "(") {
                            setEditPhoneHome(
                              phone_trim(response.data.data[0]["phone_home"])
                            );
                          }
                        }

                        setEditTaxonomyCode(
                          response.data.data[0]["taxonomy_code"]
                        );

                        setEditStateMedicaidId(
                          response.data.data[0]["state_medicaid_id"]
                        );
                        setEditUpin(response.data.data[0]["upin"]);
                        setEditDeaNumber(response.data.data[0]["dea_number"]);
                        setEditExpiration(response.data.data[0]["expiration"]);
                        setEditLicenseNumber(
                          response.data.data[0]["license_number"]
                        );
                        // eslint-disable-next-line eqeqeq
                        if (response.data.data[0]["facility"] != "[None]") {
                          setEditFacility(response.data.data[0]["facility"]);
                        }
                        // eslint-disable-next-line eqeqeq
                        if (response.data.data[0]["doctor_group"] != "[None]") {
                          setEditDoctorGroup(
                            response.data.data[0]["doctor_group"]
                          );
                        }
                        setEditProviderType(
                          response.data.data[0]["providertype"]
                        );
                        setEditState(response.data.data[0]["state"]);
                        setEditCity(response.data.data[0]["city"]);
                        setEditAddress2(response.data.data[0]["address2"]);
                        setEditAddress(response.data.data[0]["address"]);
                        setEditCredentials(
                          response.data.data[0]["credentials"]
                        );
                        if (
                          // eslint-disable-next-line eqeqeq
                          response.data.data[0]["marketing_rep"] != "[None]"
                        ) {
                          setEditMarketingRep(
                            response.data.data[0]["marketing_rep"]
                          );
                        }
                        setEditDegree(response.data.data[0]["degree"]);
                        setEditLastName(response.data.data[0]["last_name"]);
                        setEditMiddleName(response.data.data[0]["middle_name"]);
                        setEditFirstName(response.data.data[0]["first_name"]);
                        setEditEmail(response.data.data[0]["email"]);
                        setEditNpi(response.data.data[0]["npi"]);
                        setEditProviderID(response.data.data[0]["providerid"] ? response.data.data[0]["providerid"]:"");

                        // eslint-disable-next-line eqeqeq
                        if (response.data.data[0]["title"] == "Dr.") {
                          setEditTitle({ value: "1", label: "Dr." });
                        }
                        // eslint-disable-next-line eqeqeq
                        if (response.data.data[0]["title"] == "MS.") {
                          setEditTitle({ value: "1", label: "MS." });
                        }
                        // eslint-disable-next-line eqeqeq
                        if (response.data.data[0]["title"] == "MR.") {
                          setEditTitle({ value: "1", label: "MR." });
                        }
                        // eslint-disable-next-line eqeqeq
                        if (response.data.data[0]["title"] == "PROF.") {
                          setEditTitle({ value: "1", label: "PROF." });
                        }

                        // eslint-disable-next-line eqeqeq
                        if (response.data.data[0]["referralStatus"] == 1) {
                          setEditReferralStatus({ value: "1", label: "Active" });
                        }
                        // eslint-disable-next-line eqeqeq
                        if (response.data.data[0]["referralStatus"] == 0) {
                          setEditReferralStatus({ value: "2", label: "Inactive" });
                        }
                        setEditZipID(row.zipcode);
                      }
                    })
                    .catch((error) => {});
                }
              }}
              title="Edit Provider"
            />
          </Col>
        </Row>
      </>
    );
  };
  useEffect(() => {
    // console.log("edit zip", editZipID);
  }, [editZipID]);

  const columns = [
    {
      dataField: "name",
      text: "Provider Name",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "providertype",
      text: "Provider Type",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "city",
      text: "City",
      sort: true,
      formatter: basicValidation,
    },

    {
      dataField: "npi",
      text: "Npi",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
      align: "center",
      csvFormatter: statusFormatterCSV,
    },
    {
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];

  const onTableChangeForuser = (type, newState) => {
    setPage(newState.page);
    setUserSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("name");
      setSortOrder("desc");
      setUserDataReload(userDataReload + 1);
    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    }
  };

  const exportTableData = () => {
    const params = {
      providername: providerName ? providerName.label : "",
      npi: npi ? npi : "",
      providertype: providerType ? providerType.label : "",
      providerStatus: selectedStatus ? selectedStatus.value : "",
      is_pagination: "",
      record_limit: userSizePerPage ? userSizePerPage : "",
      OFFSET: offset ? offset.toString() : "",
      search_keyword: searchKeyword ? searchKeyword : "",
      sortOrder: sortOrder ? sortOrder : "desc",
      sortColumn: sortColumn ? sortColumn : "",
      file_name: fileName ? fileName : "",
    };
    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-provider-table-data/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
    })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      // .catch((error) => {
      //   toastr.error("Failed", "Something went wrong");
      // });
  };


  const searchProviderList = () => {
    setSpinner(true);
    setApiLoaded(true);
    const params = {
      providername: providerName ? providerName.label : "",
      npi: npi ? npi : "",
      providertype: providerType ? providerType.label : "",
      providerStatus: selectedStatus ? selectedStatus.value : "",
      is_pagination: "1",
      record_limit: userSizePerPage ? userSizePerPage : "20",
      OFFSET: offset ? offset.toString() : "0",
      search_keyword: searchKeyword ? searchKeyword : "",
      sortOrder: sortOrder ? sortOrder : "desc",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-provider-list/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
    })
      .then(function (response) {
        if (response.data.data.length > 0) {
          setProviderList(response.data.data);
          localStorage.setItem("offset", offset.toString());
          localStorage.removeItem("subscriptionFilter");
          localStorage.removeItem("groupNameFilter");
          localStorage.removeItem("statusFilter");
          localStorage.removeItem("adminNameFilter");
          localStorage.removeItem("zipCodeFilter");
          localStorage.removeItem("timeZoneFilter");
          setTotalSize(response.data.record_count);
          setApiLoaded(false);
          setSpinner(false);
        } else {
          setProviderList("");
          setApiLoaded(false);
          setSpinner(false);
        }
      })
      // .catch(function (error) {
      //   setProviderList("");
      //   setApiLoaded(false);
      //   setSpinner(false);
      // });
  };

  const enableOrDisableSearch = () => {
    if (providerName || npi || selectedStatus || providerType) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  useEffect(() => {
    enableOrDisableSearch();
  }, [providerName, selectedStatus, npi, providerType]); // eslint-disable-line

  useEffect(() => {
    if (userDataReload >= 1) {
      searchProviderList();
      localStorage.setItem("offset", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataReload]);

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset) {
      searchProviderList();
    }
  }, [offset]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length === 0) setUserDataReload(userDataReload + 1);
  }, [searchKeyword]); // eslint-disable-line
  // useEffect(() => {
  //   if (searchKeyword.length >= 2) {
  //     setIsGlobalSearch(true);
  //   }
  // }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      // setPage
      setTotalSize(0);
      setUserDataReload(userDataReload + 1);
      // setReloadGroupDetails(true);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleProviderNameChange = (value) => {
    setProviderName(value);
  };

  const handleProviderTypeChange = (value) => {
    setProviderType(value);
  };

  const ProviderNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({ name: inputValue }); // append inputValue to searchParams
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-name/?${searchParams.toString()}`,// append searchParams to url
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };  

  const ProviderTypeloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({ type: inputValue }); 
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-type/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };  

  const clearSearchValues = () => {
    setSearchKeyword("");
    setUserDataReload(userDataReload + 1);
  };

  return (
    <div>
      <Hux>
        {loader}
        <Container fluid>
          <Header>
            <HeaderTitle>
              Providers
              <Button
                className="Add-btn pull-right"
                onClick={() => {
                  setNewProviderModal(true);
                }}
                disabled={m_zipList ? false : true}
              >
                Add New Provider
              </Button>
            </HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Provider</BreadcrumbItem>
            </Breadcrumb>
          </Header>
          {/* <Card className="adv-search-background-card-pt2"></Card> */}
        </Container>
      </Hux>

      <Card className="adv-search-background-card">
        <CardHeader>
          <Row>
            <Col sm="3">
              <Label>Provider Name</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="Providername"
                placeholder="Search with at least 4 characters."
                value={providerName}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleProviderNameChange}
                loadOptions={(inputValue) =>
                  ProviderNameloadOptions(inputValue)
                }
              />
            </Col>

            <Col sm="3">
              <Label>Provider Type</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="ProviderType"
                placeholder="Search with at least 4 characters."
                value={providerType}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleProviderTypeChange}
                loadOptions={(inputValue) =>
                  ProviderTypeloadOptions(inputValue)
                }
              />
            </Col>
            <Col sm="2" md="2" lg="2">
              <Label>Status</Label>
              <Select
                name="Status"
                options={statusOptions}
                placeholder="Choose status"
                isClearable
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e);
                }}
              />
            </Col>
            <Col sm="2">
              <Label for="npi">NPI</Label>
              <Input
                id="npi"
                type="number"
                value={npi}
                placeholder="Enter the NPI  "
                onChange={(e) => {
                  if (e.target.value.length >= 20) {
                    toastr.warning("Invalid Number", "number length exceeded");
                  } else {
                    setNpi(e.target.value);
                  }
                }}
                style={{ margin: "0" }}
              />
            </Col>

            <Col sm="2">
              <Button
                className="patient-go-buttons"
                disabled={!search ? true : false}
                title={
                  spinner || !search
                    ? "Choose at least one item to search "
                    : ""
                }
                onClick={() => {
                  offset === "0" ? searchProviderList() : setOffset("0");
                }}
              >
                Go
              </Button>{" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => {
                  clearValues();
                }}
                disabled={!search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <div className="separator" />
        </CardHeader>

        <div className="groups-search">
          <SearchBarComponentProvider
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={columns}
            clearValues={clearSearchValues}
            setIsGlobalSearch={setIsGlobalSearch}
            isGlobalSearch={isGlobalSearch}

          />
        </div>

        <div> {zipListLoaded || spinner ? <Spinner /> : ""}</div>

        <RemotePaginationForProvider
          data={providerList ? providerList : ""}
          columns={columns}
          page={page}
          sizePerPage={userSizePerPage}
          FileExportName={fileExportName}
          exportTableData={exportTableData}
          isGlobalSearch={isGlobalSearch}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            onTableChangeForuser(type, newState);
          }}
          isDataEmpty={isDataEmpty}
          setIsGlobalSearch={setIsGlobalSearch}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
        />
      </Card>
      <div>
        <EditAllProviderModal
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
          editProviderListID={editProviderListID}
          setEditProviderListID={setEditProviderListID}
          editProviderID={editProviderID}
          setEditProviderID={setEditProviderID}
          editProviderModal={editProviderModal}
          setEditProviderModal={setEditProviderModal}
          rowData={rowData}
          editGroupName={editGroupName}
          setEditGroupName={setEditGroupName}
          editCategory={editCategory}
          setEditCategory={setEditCategory}
          setEditNote={setEditNote}
          editNote={editNote}
          setEditContactNumber={setEditContactNumber}
          editContactNumber={editContactNumber}
          setEditPhoneHome={setEditPhoneHome}
          editPhoneHome={editPhoneHome}
          setEditFax={setEditFax}
          editFax={editFax}
          setEditTaxonomyCode={setEditTaxonomyCode}
          editTaxonomyCode={editTaxonomyCode}
          setEditStateMedicaidId={setEditStateMedicaidId}
          editStateMedicaidId={editStateMedicaidId}
          setEditUpin={setEditUpin}
          editUpin={editUpin}
          setEditDeaNumber={setEditDeaNumber}
          editDeaNumber={editDeaNumber}
          setEditExpiration={setEditExpiration}
          editExpiration={editExpiration}
          setEditLicenseNumber={setEditLicenseNumber}
          editLicenseNumber={editLicenseNumber}
          setEditMarketingRep={setEditMarketingRep}
          editMarketingRep={editMarketingRep}
          setEditFacility={setEditFacility}
          editFacility={editFacility}
          setEditDoctorGroup={setEditDoctorGroup}
          editDoctorGroup={editDoctorGroup}
          setEditProviderType={setEditProviderType}
          editProviderType={editProviderType}
          setEditState={setEditState}
          editState={editState}
          setEditCity={setEditCity}
          editCity={editCity}
          setEditAddress2={setEditAddress2}
          editAddress2={editAddress2}
          setEditAddress={setEditAddress}
          editAddress={editAddress}
          setEditCredentials={setEditCredentials}
          editCredentials={editCredentials}
          setEditDegree={setEditDegree}
          editDegree={editDegree}
          setEditLastName={setEditLastName}
          editLastName={editLastName}
          setEditMiddleName={setEditMiddleName}
          editMiddleName={editMiddleName}
          setEditFirstName={setEditFirstName}
          editFirstName={editFirstName}
          setEditEmail={setEditEmail}
          editEmail={editEmail}
          setEditNpi={setEditNpi}
          editNpi={editNpi}
          setEditTitle={setEditTitle}
          editTitle={editTitle}
          setEditZipCode={setEditZipCode}
          editZipCode={editZipCode}
          editZipID={editZipID}
          setEditZipID={setEditZipID}
          setEditReferralStatus={setEditReferralStatus}
          editReferralStatus={editReferralStatus}
          zipCodeList={m_zipList}
        />

        <NewProviderModal
          newProviderModal={newProviderModal}
          setNewProviderModal={setNewProviderModal}
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
          zipCodeList={zipCodeOptions}
        />
        <ProviderStatusChangeModal
          modalState={modalState}
          setModalState={setModalState}
          setStatus={setStatus}
          status={status}
          providerID={providerID}
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
        />
      </div>
    </div>
  );
};

export default AllProvider;
