import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Breadcrumb, BreadcrumbItem, Spinner, Button, Row, Col, Card, CardHeader, Label } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { GlobalState } from '../../contextAPI/Context'
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";// eslint-disable-line
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemotePagination from '../../newComponents/utilities/RemotePagination'
import SearchBarComponent from '../../newComponents/utilities/SearchBarComponent'
import DeleteConfirmationModal from '../../newComponents/utilities/DeleteConfirmationModal'
import Select from 'react-select';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import MomentConfig from '../../common/MomentConfig';
import moment from 'moment';
import AsyncSelect from "react-select/async";
import NewAutoProcessExceptionRule from '../../newComponents/utilities/NewAutoProcessExceptionRule'
import EditAutoProcessExceptionRule from '../../newComponents/utilities/EditAutoProcessExceptionRule'


const AutoProcessExceptionRule = (props) => {
  // CONTEXT VARIABLES 
  const { spinner, setSpinner } = useContext(GlobalState);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  // LOCAL VARIABLES
  const [data, setData] = useState([]);// eslint-disable-line
  const [reload, setReload] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [isPagination, setIsPagination] = useState(1); // eslint-disable-line
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("")
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [isSearch, setIsSearch] = useState(false);// eslint-disable-line
  const [search, setSearch] = useState(false);// eslint-disable-line
  const [toggleValue, setToggleValue] = useState(0)
  const [newAutoProcessExceptionRule, setNewAutoProcessExceptionRule] = useState(false);
  const [editAutoProcessExceptionRule, setEditAutoProcessExceptionRule] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const fileExportName = 'AutoProcessExceptionRule'
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [exceptionRuleDetails, setExceptionRuleDetails] = useState("");
  const [filter, setFilter] = useState(false);
  const [editPatientAge, setEditPatientAge] = useState();
  const [editConditionLabel, setEditConditionLabel] = useState([]);
  const [visitNumbers, setVisitNumbers] = useState([])
  const [rowId, setRowId] = useState();
  const [buttonBlankState, setButtonBlankState] = useState(false);
  const [groupId, setGroupId] = useState();
  const [ruleName, setRuleName] = useState("");
  const [referralType, setReferralType] = useState("");
  const [patientAgeCondition, setPatientAgeCondition] = useState("");
  const [patientCondition, setPatientCondition] = useState([]);
  const [patientInsurance, setPatientInsurance] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [orderPriority, setOrderPriority] = useState([]);
  const [patientGender, setPatientGender] = useState("");
  const [diagnosis, setDiagnosis] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [processStatus, setProcessStatus] = useState();
  const [createdDate, setCreatedDate] = useState("");
  const [createdBy, setCreatedBy] = useState();
  const [updatedDate, setUpdatedDate] = useState("");
  const [updatedBy, setUpdatedBy] = useState();
  const [fulfillerGroupId, setFulfillerGroupId] = useState([]);
  const [fulfillerFacilityId, setFulfillerFacilityId] = useState([]);
  const [fulfillerPhysicianId, setFulfillerPhysicianId] = useState([]);
  const [mandatoryItems, setMandatoryItems] = useState(true);
  const [eligibilityCheck, setEligibilityCheck] = useState();
  const [preAuthCheck, setPreAuthCheck] = useState();
  const [preAuthRequestType, setPreAuthRequestType] = useState("");
  const [otherGenerateDocs, setOtherGenerateDocs] = useState([]);
  const [autoProcessEnable, setAutoProcessEnable] = useState();
  const [allowedDiagnosis, setAllowedDiagnosis] = useState([]);
  const [allowedProcedure, setAllowedProcedure] = useState([]);
  const [allowedInsurances, setAllowedInsurances] = useState([]);
  const [allowedServiceTypes, setAllowedServiceTypes] = useState([]);
  const [allowedNoOfVisitors, setAllowedNoOfVisitors] = useState([]);
  const [allowedPos, setAllowedPos] = useState();
  const [excludedDiagnosis, setExcludedDiagnosis] = useState([]);
  const [excludedProcedure, setExcludedProcedure] = useState([]);
  const [excludedInsurances, setExcludedInsurances] = useState([]);
  const [excludedServiceTypes, setExcludedServiceTypes] = useState([]);
  const [additionalKeyData, setAdditionalKeyData] = useState("");
  const [ruleType, setRuleType] = useState("");
  const [inputValue, setInputValue] = useState("");// eslint-disable-line
  const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
  const [selectedOrderPriority, setSelectedOrderPriority] = useState([]);
  const [selectedPhysicianIds, setSelectedPhysicianIds] = useState([]);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [selectedInsurances, setSelectedInsurances] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState([]);
  const [selectedMandatoryItems, setSelectedMandatoryItems] = useState([]);
  const [selectedFulfillerGroup, setSelectedFulfillerGroup] = useState([]);
  const [selectedFulfillerFacility, setSelectedFulfillerFacility] = useState([]);
  const [referralRuleData, setReferralRuleData] = useState([]);
  // *******Edit Autoprocess exception rules variables****** //
  const [editgroupId, setEditGroupId] = useState();
  const [editruleName, setEditRuleName] = useState("");
  const [editreferralType, setEditReferralType] = useState("");
  const [editruleType, setEditRuleType] = useState("");
  const [editpatientAgeCondition, setEditPatientAgeCondition] = useState([]);
  const [editpatientCondition, setEditPatientCondition] = useState([]);
  const [editpatientInsurance, setEditPatientInsurance] = useState([]);
  const [editserviceTypes, setEditServiceTypes] = useState([]);
  const [editorderPriority, setEditOrderPriority] = useState([]);
  const [editpatientGender, setEditPatientGender] = useState("");
  const [editdiagnosis, setEditDiagnosis] = useState([]);
  const [editprocedures, setEditProcedures] = useState([]);
  const [editprocessStatus, setEditProcessStatus] = useState();
  const [editcreatedDate, setEditCreatedDate] = useState("");
  const [editcreatedBy, setEditCreatedBy] = useState();
  const [editupdatedDate, setEditUpdatedDate] = useState("");
  const [editupdatedBy, setEditUpdatedBy] = useState();
  const [editfulfillerGroupId, setEditFulfillerGroupId] = useState([]);
  const [editfulfillerFacilityId, setEditFulfillerFacilityId] = useState([]);
  const [editfulfillerPhysicianId, setEditFulfillerPhysicianId] = useState([]);
  const [editmandatoryItems, setEditMandatoryItems] = useState([]);
  const [editeligibilityCheck, setEditEligibilityCheck] = useState();
  const [editpreAuthCheck, setEditPreAuthCheck] = useState();
  const [editpreAuthRequestType, setEditPreAuthRequestType] = useState("");
  const [editotherGenerateDocs, setEditOtherGenerateDocs] = useState([]);
  const [editautoProcessEnable, setEditAutoProcessEnable] = useState();
  const [editallowedDiagnosis, setEditAllowedDiagnosis] = useState([]);
  const [editallowedProcedure, setEditAllowedProcedure] = useState([]);
  const [editallowedInsurances, setEditAllowedInsurances] = useState([]);
  const [editallowedServiceTypes, setEditAllowedServiceTypes] = useState([]);
  const [editallowedNoOfVisitors, setEditAllowedNoOfVisitors] = useState([]);
  const [editallowedPos, setEditAllowedPos] = useState();
  const [editexcludedDiagnosis, setEditExcludedDiagnosis] = useState([]);
  const [editexcludedProcedure, setEditExcludedProcedure] = useState([]);
  const [editexcludedInsurances, setEditExcludedInsurances] = useState([]);
  const [editexcludedServiceTypes, setEditExcludedServiceTypes] = useState([]);
  const [editadditionalKeyData, setEditAdditionalKeyData] = useState("");
  let new_token= ""
  // *******Edit variable Ends****** //

  // ******* Options handled in UI ****** //

  const referralTypeOptions = [

    {
      value: 'sender',
      label: `Sender`,
    },
    {
      value: 'fulfiller',
      label: `Fulfiller`,
    }
  ]
  const ruleTypeOptions = [

    {
      value: 'preAuthException',
      label: `PreAuthException`,
    },
    {
      value: 'flowException',
      label: `FlowException`,
    }
  ]
  const requestTypeOptions = [

    {
      value: 'SC',
      label: `Referral`,
    },
    {
      value: 'HS',
      label: `outPatient Authorization`,
    }
  ]
  const genderOptions = [

    {
      value: 'male',
      label: `Male`,
    },
    {
      value: 'female',
      label: `Female`,
    },
    {
      value: 'not specified',
      label: `Not Specified`,
    }
  ]
  const ageOptions = [

    {
      value: 'less than',
      label: `Less than`,
    },
    {
      value: 'greater than',
      label: `Greater than`,
    },
    {
      value: 'equal to',
      label: `Equal to`,
    },
    {
      value: 'between',
      label: `Between`,
    }
  ]
  const orderPriorityOptions = [

    {
      value: 'Stat',
      label: `Stat`,
    },
    {
      value: 'Urgent',
      label: `Urgent`,
    },
    {
      value: 'Routine',
      label: `Routine`,
    }
  ]
  const additionalDocsOptions = [

    {
      value: 'emi_form',
      label: `EMI Form`,
    }
  ]
  const handleInputChange = value => {
    setInputValue(value);
  };

  const handleRuleNameChange = value => {
    setRuleName(value);
  };

  const ruleNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/rule-name-list/?${searchParams.toString()}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then((response) => response.data.data)
        // .catch((error) => {
        //   throw error;
        // });
    }
  };
  

  const fetchReferralRuleMandatoryItem = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-referral-rule-mandatory-items/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => {
        if (response.status ===200) {
          setReferralRuleData(response.data.data)
        }

      })
      // .catch((error) => {
      //   console.log(error)
      // });
  }

  useEffect(() => {

    if (referralRuleData.length === 0) {
      fetchReferralRuleMandatoryItem();

    }

  }, [referralRuleData]) // eslint-disable-line
  const filterValidation = () => {
    if (ruleName || referralType) {
      setFilter(true)
    } else {
      setFilter(false)
    }
  }


  const clearFilterValues = () => {
    setRuleName("");
    setReferralType("");
    setReload(reload + 1);
  }

  useEffect(() => {
    filterValidation();
  }, [ruleName, referralType]) // eslint-disable-line

  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`

  //////////////////////////////////////////////////////////////
  //******************API FOR LOAD OPTIONS ******************//
  /////////////////////////////////////////////////////////////

  const loadServiceTypeOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-order-type/${inputValue}/${0}`, {
        headers: {
          Authorization: API_TOKEN,
        }
      })
        .then((response) => response.data.data)
        .catch(error => {
          if (error.response.status === 401) {
            new_token = Cookie.get("access_token_admin")
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-order-type/${inputValue}/${0}`, {
              headers: {
                Authorization: new_token,
              }
            })
            .then((response)=>response.data.data)
            // .catch((error)=>{
            //   throw error;
          //   // })
          }else{
            throw error;
          }
        });
    }
  };
  
  const loadPhysicianOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-data/?${searchParams.toString()}`,
      {
        headers: {
          Authorization: API_TOKEN,
        }
      })
        .then((response) => response.data.data)
        .then((error) => {
          throw error;
        });
    }
  }

  const loadConditionOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-conditions/${inputValue}`, {
        headers: {
          Authorization: API_TOKEN,
        }
      })
        .then((response) => response.data.data)
        .then((error) => {
          throw error;
        });
    }
  }


 const loadInsuranceOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-insurance-company-names/${inputValue}`, {
        headers: {
          Authorization: API_TOKEN,
        }
      })
        .then((response) => response.data.data)
        .then((error) => {
          throw error;
        });
    }
  }

 const loadDiagnosisOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-icd-code-description/${inputValue}`, {
        headers: {
          Authorization: API_TOKEN,
        }
      })
        .then((response) => response.data.data)
        .then((error)=> {
          throw error;
        });
    }
  }

 
  const loadProcedureOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-cpt-code-description/${inputValue}`, {
        headers: {
          Authorization: API_TOKEN,
        }
      })
        .then((response) => response.data.data)
        .then((error) => {
          throw error;
        });
    }
  }

  const loadFulfillerGroupOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-fulfiller-group-by-name/${inputValue}/${0}`, {
        headers: {
          Authorization: API_TOKEN,
        }
      })
        .then((response) => response.data)
        .then((error) => {
          throw error;
        });
    }
  }

  const loadFacilityOptionsForFacility = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-fulfiller-facility-by-fulfiller-name/${inputValue}/${0}`, {
        headers: {
          Authorization: API_TOKEN,
        }
      })
        .then((response) => response.data.data)
        .then((error) => {
          throw error;
        });
    }
  }


  // #########  Default API  ######### //

   const fetchAutoProcessExceptionRules = () => {
    setSpinner(true);
    const params =
    {

      "rule_name": ruleName ? ruleName.label : "",
      "referral_type": referralType ? referralType.value : "",
      "is_pagination": isPagination ? isPagination : "",
      "record_limit": sizePerPage ? sizePerPage : "",
      "offset": offset,
      "search_keyword": searchKeyword ? searchKeyword : "",
      "sortOrder": sortOrder ? sortOrder : "",
      "sortColumn": sortColumn ? sortColumn : ""
    }


    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-auto-process-exception-rule-details/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        if (response.data.data.length > 0) {
          setExceptionRuleDetails(response.data.data);
          setTotalSize(response.data.record_count)
          setSpinner(false);
          localStorage.setItem('offset', offset);
          localStorage.removeItem("subscriptionFilter");
          localStorage.removeItem("groupNameFilter");
          localStorage.removeItem("statusFilter");
          localStorage.removeItem("adminNameFilter");
          localStorage.removeItem("zipCodeFilter");
          localStorage.removeItem("timeZoneFilter");

        }
        else {
          setTotalSize(0);
          setExceptionRuleDetails("")
          setSpinner(false);
          //   setIsDataEmpty(true);

        }
      })
      .catch(error => {
        toastr.error("Error", "Something went wrong")
        setSpinner(false);
      });
  };
  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }
  const timeFormatter = (cell, row) => {
    return cell ? `${moment(cell).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}` : 'N/A'

  }

   // #########  Export API  ######### //
  const exportTableData = () => {
    
    const params =
    {
      "file_name": fileName ? fileName : "",
      "rule_name": ruleName ? ruleName : "",
      "referral_type": referralType ? referralType.value : "",
      "is_pagination":"1",
      "record_limit": "",
      "offset": offset,
      "search_keyword": searchKeyword ? searchKeyword : "",
      "sortOrder":sortOrder ? sortOrder : "",
      "sortColumn":sortColumn ? sortColumn:""
      

    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-auto-process-exception-rule-details/`,params,{
        headers: {
           
          Authorization: API_TOKEN,

        },
        responseType: "blob"
      })
        .then((response) => { 
          if (response.status === 200) {
            var fileDownload = require("js-file-download");
            fileDownload(
              response.data ,`${fileName}`,
              response["headers"]["x-suggested-filename"]
            );
            toastr.success(
              "Success",
              "Export successful"
            );
          
          }
          
        })
        .catch((error) => {
        
            toastr.error(
                "Failed",
                "Something went wrong"
              );
        });
    };

  // #########  Delete API  ######### //


  const deleteAutoProcessExceptionRules = () => {
    setSpinner(true);
    const params =
    {
      "row_id": rowId ? rowId : ""

    }


    AxiosInter.post(`${API_ENDPOINT}/admin-api/delete-auto-process-exception-rules/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        toastr.success("Success", "Deleted successfully")
        setSpinner(false);
        setDeleteModal(false);
        setReload(reload + 1);
      })
      .catch(error => {
        toastr.error("Error", "Something went wrong")
        setSpinner(false);
      });
  };
  const minLength = 4;
  const customNoOptionsMessage = ({ inputValue }) => {
   if (inputValue.length < minLength) {
     return "Please enter at least 4 letters to search";
   } else {
     return "No option";
   }
 };


  const actionsFormatter = (cell, row) => {
    return (
      <>
        <div style={{ 'gap': '20px', 'display': 'flex', 'justifyContent': 'center' }}>

          <FontAwesomeIcon
            icon={faEdit}
            size={'1x'}
            color={'#3f86fa'}
            style={{ 'cursor': 'pointer' }}
            onClick={() => {
              // debugger
              console.log('togVal',toggleValue)
              console.log('editotherGenerateDocs',editotherGenerateDocs)
              setEditAutoProcessExceptionRule(true)
              setRowId(row.id);
              setEditGroupId(row.group_id);
              setEditRuleName(row.rule_name);
              setEditReferralType(row.referral_type)
              setEditRuleType(row.rule_type)
              // var ageValue = JSON.parse(row.patient_age_condition)
              setEditPatientAgeCondition(row.patient_age_condition != null ? JSON.parse(row.patient_age_condition):"");
              setEditPatientCondition(row.patient_condition != null ? JSON.parse(row.patient_condition):"");
              // console.log(typeof row.patient_condition)
              setEditPatientInsurance(JSON.parse(row.patient_insurance));
              setEditServiceTypes(JSON.parse(row.service_types));
              var orderpriorityTemp = JSON.parse(row.order_priority)
              setEditOrderPriority(orderpriorityTemp != null ? orderpriorityTemp :"");
              setEditPatientGender(row.patient_gender)
              setEditDiagnosis(row.diagnosis != null ? JSON.parse(row.diagnosis) :"");
              setEditProcedures(row.procedures != null ? JSON.parse(row.procedures):"");
              setEditProcessStatus(row.status);
              setEditCreatedDate(row.created_date);
              setEditCreatedBy(row.created_by);
              setEditUpdatedDate(row.updated_date);
              setEditUpdatedBy(row.updated_by);
              setEditFulfillerGroupId(row.fulfiller_group_id != null ? JSON.parse(row.fulfiller_group_id):"");
              setEditFulfillerFacilityId(row.fulfiller_facility_id != null ? JSON.parse(row.fulfiller_facility_id):"");
              setEditFulfillerPhysicianId(row.fulfiller_physician_id != null ? JSON.parse(row.fulfiller_physician_id):"");
              setEditMandatoryItems(JSON.parse(row.mandatory_items));
              setEditEligibilityCheck(row.is_enable_eligiblity_check);
              setEditPreAuthCheck(row.is_enable_pre_auth_check);
              setEditPreAuthRequestType(row.pre_auth_request_type);
              // var docsTemp = JSON.parse(row.order_priority)
              setEditOtherGenerateDocs(JSON.parse(row.other_generate_docs));
              setEditAutoProcessEnable(row.is_auto_process_enable);
              setEditAllowedDiagnosis(JSON.parse(row.allowed_diagnosis));
              setEditAllowedProcedure(JSON.parse(row.allowed_procedure));
              setEditAllowedInsurances(JSON.parse(row.allowed_insurances));
              setEditAllowedServiceTypes(JSON.parse(row.allowed_service_types));
              setEditAllowedNoOfVisitors(row.allowed_no_of_visits);
              setEditAllowedPos(row.allowed_pos);
              setEditExcludedDiagnosis(JSON.parse(row.excluded_diagnosis));
              setEditExcludedProcedure(JSON.parse(row.excluded_procedure));
              setEditExcludedInsurances(JSON.parse(row.excluded_insurances));
              setEditExcludedServiceTypes(JSON.parse(row.excluded_service_types));
              setEditAdditionalKeyData(row.additional_key_data);
              setEditPatientAge(JSON.parse(row.p_age));
              setEditConditionLabel(JSON.parse(row.conditionLabel))
              setVisitNumbers(JSON.parse(row.visitNumber))



            }}

            title="Edit"

          />


          <FontAwesomeIcon
            icon={faTrash}
            color={'#E12C2C'}
            size={'1x'}
            style={{ 'cursor': 'pointer' }}
            onClick={() => {
              setRowId(row.id)
              setDeleteModal(true)

            }}

            title="Delete"

          />

        </div>
      </>
    )
  }
  const columns = [
    {
      dataField: 'rule_name',
      text: 'Name',
      sort: true,
      formatter: basicValidation
    },
    {
      dataField: 'referral_type',
      text: 'Referral Type',
      sort: true,
      formatter: basicValidation
    },
    {
      dataField: 'created_by',
      text: 'Created By',
      sort: true,
      formatter: basicValidation
    },
    {
      dataField: 'created_date',
      text: 'Created On',
      sort: true,
      formatter: basicValidation,
      timeFormatter: timeFormatter
    },
    {
      dataField: 'updated_by',
      text: 'Updated By',
      sort: false,
      formatter: basicValidation,
    },
    {
      dataField: 'updated_date',
      text: 'Updated On',
      sort: false,
      formatter: basicValidation,
      timeFormatter: timeFormatter
    },
    {
      text: 'Actions',
      sort: false,
      formatter: actionsFormatter,


    }


  ];

  const handleTableChange = (type, newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
    setOffset((newState.page - 1) * newState.sizePerPage)

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("")
      setSortOrder("")
    }
    else {
      setSortColumn(newState.sortField)
      setSortOrder(newState.sortOrder)
      setReload(reload + 1)
    }

  }
  const searchButtonValidation = () => {// eslint-disable-line
    if (referralType || ruleName) {
      setSearch(true)
    } else {
      setSearch(false)
    }
  }
  const clearSearchValues = () => {
    setSearchKeyword("");
    setReload(reload + 1)

  }
  useEffect(() => { if (searchKeyword.length >= 2) { setIsGlobalSearch(true); } }, [searchKeyword]) // eslint-disable-line
  useEffect(() => { if (searchKeyword.length === 0) { setReload(reload + 1) } }, [searchKeyword]) // eslint-disable-line
  useEffect(() => { if (isGlobalSearch) { setOffset(0); setTotalSize(0); fetchAutoProcessExceptionRules(); setIsGlobalSearch(false); } }, [isGlobalSearch]) // eslint-disable-line
  useEffect(() => { if (reload) { fetchAutoProcessExceptionRules(); } }, [reload]) // eslint-disable-line
  //useEffect(() => { searchButtonValidation(); }, [selectedMdc, selectedType])// eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem('offset')
    if (offset !== previousOffset || sizePerPage) {
      setReload(reload + 1)
    }
  }, [offset, sizePerPage]) // eslint-disable-line

  useEffect(() => {   
    window.scrollTo(0, 0);   
     }, []);

  // CheckUserInactivity()

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Auto Process Exception Rule


            <Button className="Add-btn pull-right"
              onClick={() => {
                setNewAutoProcessExceptionRule(!newAutoProcessExceptionRule)
                

              }}
            >
              Add New Rule
            </Button>
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Auto Process Exception Rule</BreadcrumbItem>
          </Breadcrumb>

        </Header>
      </Container>



      <Card>
        <CardHeader>

          <Row>
            <Col sm="2" md="2" lg="2">
              <Label>Name</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                placeholder="Name"
                noOptionsMessage={customNoOptionsMessage}
                value={ruleName}
                getOptionLabel={e => e.label}
                getOptionValue={e => e.value}

                onInputChange={handleInputChange}
                onChange={handleRuleNameChange}
                loadOptions={ruleNameloadOptions}
              />
            </Col>
            <Col sm="2" md="2" lg="2">
              <Label>Referral Type</Label>
              <Select
                name=""
                options={referralTypeOptions}
                placeholder="Referral Type"
                isClearable
                value={referralType}
                onChange={(e) => {

                  setReferralType(e)
                }}
              />
            </Col>


            <Col>
              <Button
                className="patient-go-buttons"
                onClick={() => {
                  setSearchKeyword("")
                  offset === 0 ? setReload(reload + 1) : setOffset(0)
                  setPage(1);



                }}
                disabled={spinner || !filter ? true : false}
                title={spinner || !filter ? "Choose at least one item to filter " : ""}
              >Go</Button> {" "}

              <Button
                className="patient-reset-buttons"
                onClick={() => { clearFilterValues() }}
                disabled={spinner || !filter ? true : false}
                title={spinner || !filter ? "Nothing To Clear" : ""}
              >Reset</Button>

            </Col>

          </Row>

          <div className="separator" />
        </CardHeader>
        <div className='groups-search'>
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={columns}
            clearValues={clearSearchValues}
          />

        </div>

        {spinner ? <Spinner /> : ""}

        <RemotePagination
          data={exceptionRuleDetails ? exceptionRuleDetails : ""}
          columns={columns}
          page={page}
          sizePerPage={sizePerPage}
          FileExportName={fileExportName}
          exportTableData = {exportTableData}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            handleTableChange(type, newState);
          }}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}



        />




      </Card>


      <NewAutoProcessExceptionRule
        newAutoProcessExceptionRule={newAutoProcessExceptionRule}
        setNewAutoProcessExceptionRule={setNewAutoProcessExceptionRule}
        buttonBlankState={buttonBlankState}
        setButtonBlankState={setButtonBlankState}
        ruleTypeOptions={ruleTypeOptions}
        loadServiceTypeOptions={loadServiceTypeOptions}
        loadDiagnosisOptions={loadDiagnosisOptions}
        orderPriorityOptions={orderPriorityOptions}
        loadProcedureOptions={loadProcedureOptions}
        loadFulfillerGroupOptions={loadFulfillerGroupOptions}
        loadFacilityOptionsForFacility={loadFacilityOptionsForFacility}
        loadPhysicianOptions={loadPhysicianOptions}
        ageOptions={ageOptions}
        genderOptions={genderOptions}
        loadConditionOptions={loadConditionOptions}
        loadInsuranceOptions={loadInsuranceOptions}
        requestTypeOptions={requestTypeOptions}
        toggleValue={toggleValue}
        setToggleValue={setToggleValue}
        additionalDocsOptions={additionalDocsOptions}

        groupId={groupId}
        ruleName={ruleName}
        referralType={referralType}
        patientAgeCondition={patientAgeCondition}
        patientCondition={patientCondition}
        patientInsurance={patientInsurance}
        serviceTypes={serviceTypes}
        orderPriority={orderPriority}
        patientGender={patientGender}
        diagnosis={diagnosis}
        procedures={procedures}
        processStatus={processStatus}
        createdDate={createdDate}
        createdBy={createdBy}
        updatedDate={updatedDate}
        updatedBy={updatedBy}
        fulfillerGroupId={fulfillerGroupId}
        fulfillerFacilityId={fulfillerFacilityId}
        fulfillerPhysicianId={fulfillerPhysicianId}
        mandatoryItems={mandatoryItems}
        eligibilityCheck={eligibilityCheck}
        preAuthCheck={preAuthCheck}
        preAuthRequestType={preAuthRequestType}
        otherGenerateDocs={otherGenerateDocs}
        autoProcessEnable={autoProcessEnable}
        allowedDiagnosis={allowedDiagnosis}
        allowedProcedure={allowedProcedure}
        allowedInsurances={allowedInsurances}
        allowedServiceTypes={allowedServiceTypes}
        allowedNoOfVisitors={allowedNoOfVisitors}
        allowedPos={allowedPos}
        excludedDiagnosis={excludedDiagnosis}
        excludedProcedure={excludedProcedure}
        excludedInsurances={excludedInsurances}
        excludedServiceTypes={excludedServiceTypes}
        additionalKeyData={additionalKeyData}
        ruleType={ruleType}
        setGroupId={setGroupId}
        setRuleName={setRuleName}
        setReferralType={setReferralType}
        setPatientAgeCondition={setPatientAgeCondition}
        setPatientCondition={setPatientCondition}
        setPatientInsurance={setPatientInsurance}
        setServiceTypes={setServiceTypes}
        setOrderPriority={setOrderPriority}
        setPatientGender={setPatientGender}
        setDiagnosis={setDiagnosis}
        setProcedures={setProcedures}
        setProcessStatus={setProcessStatus}
        setCreatedDate={setCreatedDate}
        setCreatedBy={setCreatedBy}
        setUpdatedDate={setUpdatedDate}
        setUpdatedBy={setUpdatedBy}
        setFulfillerGroupId={setFulfillerGroupId}
        setFulfillerFacilityId={setFulfillerFacilityId}
        setFulfillerPhysicianId={setFulfillerPhysicianId}
        setMandatoryItems={setMandatoryItems}
        setEligibilityCheck={setEligibilityCheck}
        setPreAuthCheck={setPreAuthCheck}
        setPreAuthRequestType={setPreAuthRequestType}
        setOtherGenerateDocs={setOtherGenerateDocs}
        setAutoProcessEnable={setAutoProcessEnable}
        setAllowedDiagnosis={setAllowedDiagnosis}
        setAllowedProcedure={setAllowedProcedure}
        setAllowedInsurances={setAllowedInsurances}
        setAllowedServiceTypes={setAllowedServiceTypes}
        setAllowedNoOfVisitors={setAllowedNoOfVisitors}
        setAllowedPos={setAllowedPos}
        setExcludedDiagnosis={setExcludedDiagnosis}
        setExcludedProcedure={setExcludedProcedure}
        setExcludedInsurances={setExcludedInsurances}
        setExcludedServiceTypes={setExcludedServiceTypes}
        setAdditionalKeyData={setAdditionalKeyData}
        setRuleType={setRuleType}
        referralTypeOptions={referralTypeOptions}
        selectedFulfillerGroup={selectedFulfillerGroup}
        selectedServiceTypes={selectedServiceTypes}
        selectedOrderPriority={selectedOrderPriority}
        selectedPhysicianIds={selectedPhysicianIds}
        selectedConditions={selectedConditions}
        selectedInsurances={selectedInsurances}
        selectedDiagnosis={selectedDiagnosis}
        selectedProcedure={selectedProcedure}
        selectedMandatoryItems={selectedMandatoryItems}
        selectedFulfillerFacility={selectedFulfillerFacility}
        setSelectedServiceTypes={setSelectedServiceTypes}
        setSelectedOrderPriority={setSelectedOrderPriority}
        setSelectedPhysicianIds={setSelectedPhysicianIds}
        setSelectedConditions={setSelectedConditions}
        setSelectedInsurances={setSelectedInsurances}
        setSelectedDiagnosis={setSelectedDiagnosis}
        setSelectedMandatoryItems={setSelectedMandatoryItems}
        setSelectedFulfillerGroup={setSelectedFulfillerGroup}
        setSelectedFulfillerFacility={setSelectedFulfillerFacility}
        setSelectedProcedure={setSelectedProcedure}
        referralRuleData={referralRuleData}
        setReload={setReload}
        reload={reload}
        customNoOptionsMessage={customNoOptionsMessage}


      />
      <EditAutoProcessExceptionRule
        newAutoProcessExceptionRule={newAutoProcessExceptionRule}
        setNewAutoProcessExceptionRule={setNewAutoProcessExceptionRule}
        buttonBlankState={buttonBlankState}
        setButtonBlankState={setButtonBlankState}
        ruleTypeOptions={ruleTypeOptions}
        loadServiceTypeOptions={loadServiceTypeOptions}
        loadDiagnosisOptions={loadDiagnosisOptions}
        orderPriorityOptions={orderPriorityOptions}
        loadProcedureOptions={loadProcedureOptions}
        loadFulfillerGroupOptions={loadFulfillerGroupOptions}
        loadFacilityOptionsForFacility={loadFacilityOptionsForFacility}
        loadPhysicianOptions={loadPhysicianOptions}
        ageOptions={ageOptions}
        genderOptions={genderOptions}
        loadConditionOptions={loadConditionOptions}
        loadInsuranceOptions={loadInsuranceOptions}
        requestTypeOptions={requestTypeOptions}
        toggleValue={toggleValue}
        setToggleValue={setToggleValue}
        additionalDocsOptions={additionalDocsOptions}
        editAutoProcessExceptionRule={editAutoProcessExceptionRule}
        setEditAutoProcessExceptionRule={setEditAutoProcessExceptionRule}

        editgroupId={editgroupId}
        editruleName={editruleName}
        editreferralType={editreferralType}
        editpatientAgeCondition={editpatientAgeCondition}
        editpatientCondition={editpatientCondition}
        editpatientInsurance={editpatientInsurance}
        editserviceTypes={editserviceTypes}
        editorderPriority={editorderPriority}
        editpatientGender={editpatientGender}
        editdiagnosis={editdiagnosis}
        editprocedures={editprocedures}
        editprocessStatus={editprocessStatus}
        editcreatedDate={editcreatedDate}
        editcreatedBy={editcreatedBy}
        editupdatedDate={editupdatedDate}
        editupdatedBy={editupdatedBy}
        editfulfillerGroupId={editfulfillerGroupId}
        editfulfillerFacilityId={editfulfillerFacilityId}
        editfulfillerPhysicianId={editfulfillerPhysicianId}
        editmandatoryItems={editmandatoryItems}
        editeligibilityCheck={editeligibilityCheck}
        editpreAuthCheck={editpreAuthCheck}
        editpreAuthRequestType={editpreAuthRequestType}
        editotherGenerateDocs={editotherGenerateDocs}
        editautoProcessEnable={editautoProcessEnable}
        editallowedDiagnosis={editallowedDiagnosis}
        editallowedProcedure={editallowedProcedure}
        editallowedInsurances={editallowedInsurances}
        editallowedServiceTypes={editallowedServiceTypes}
        editallowedNoOfVisitors={editallowedNoOfVisitors}
        editallowedPos={editallowedPos}
        editexcludedDiagnosis={editexcludedDiagnosis}
        editexcludedProcedure={editexcludedProcedure}
        editexcludedInsurances={editexcludedInsurances}
        editexcludedServiceTypes={editexcludedServiceTypes}
        editadditionalKeyData={editadditionalKeyData}
        editruleType={editruleType}
        setEditGroupId={setEditGroupId}
        setEditRuleName={setEditRuleName}
        setEditReferralType={setEditReferralType}
        setEditPatientAgeCondition={setEditPatientAgeCondition}
        setEditPatientCondition={setEditPatientCondition}
        setEditPatientInsurance={setEditPatientInsurance}
        setEditServiceTypes={setEditServiceTypes}
        setEditOrderPriority={setEditOrderPriority}
        setEditPatientGender={setEditPatientGender}
        setEditDiagnosis={setEditDiagnosis}
        setEditProcedures={setEditProcedures}
        setEditProcessStatus={setEditProcessStatus}
        setEditCreatedDate={setEditCreatedDate}
        setEditCreatedBy={setEditCreatedBy}
        setEditUpdatedDate={setEditUpdatedDate}
        setEditUpdatedBy={setEditUpdatedBy}
        setEditFulfillerGroupId={setEditFulfillerGroupId}
        setEditFulfillerFacilityId={setEditFulfillerFacilityId}
        setEditFulfillerPhysicianId={setEditFulfillerPhysicianId}
        setEditMandatoryItems={setEditMandatoryItems}
        setEditEligibilityCheck={setEditEligibilityCheck}
        setEditPreAuthCheck={setEditPreAuthCheck}
        setEditPreAuthRequestType={setEditPreAuthRequestType}
        setEditOtherGenerateDocs={setEditOtherGenerateDocs}
        setEditAutoProcessEnable={setEditAutoProcessEnable}
        setEditAllowedDiagnosis={setEditAllowedDiagnosis}
        setEditAllowedProcedure={setEditAllowedProcedure}
        setEditAllowedInsurances={setEditAllowedInsurances}
        setEditAllowedServiceTypes={setEditAllowedServiceTypes}
        setEditAllowedNoOfVisitors={setEditAllowedNoOfVisitors}
        setEditAllowedPos={setEditAllowedPos}
        setEditExcludedDiagnosis={setEditExcludedDiagnosis}
        setEditExcludedProcedure={setEditExcludedProcedure}
        setEditExcludedInsurances={setEditExcludedInsurances}
        setEditExcludedServiceTypes={setEditExcludedServiceTypes}
        setEditAdditionalKeyData={setEditAdditionalKeyData}
        setEditRuleType={setEditRuleType}
        referralRuleData={referralRuleData}
        referralTypeOptions={referralTypeOptions}
        selectedServiceTypes={selectedServiceTypes}
        setSelectedServiceTypes={setSelectedServiceTypes}
        setSelectedDiagnosis={setSelectedDiagnosis}
        selectedDiagnosis={selectedDiagnosis}
        selectedOrderPriority={selectedOrderPriority}
        setSelectedOrderPriority={setSelectedOrderPriority}
        selectedProcedure={selectedProcedure}
        setSelectedProcedure={setSelectedProcedure}
        selectedFulfillerGroup={selectedFulfillerGroup}
        setSelectedFulfillerGroup={setSelectedFulfillerGroup}
        selectedFulfillerFacility={selectedFulfillerFacility}
        setSelectedFulfillerFacility={setSelectedFulfillerFacility}
        selectedPhysicianIds={selectedPhysicianIds}
        setSelectedPhysicianIds={setSelectedPhysicianIds}
        setSelectedConditions={setSelectedConditions}
        selectedConditions={selectedConditions}
        setSelectedInsurances={setSelectedInsurances}
        selectedInsurances={selectedInsurances}
        editPatientAge={editPatientAge}
        setEditPatientAge={setEditPatientAge}
        editConditionLabel={editConditionLabel}
        setEditConditionLabel={setEditConditionLabel}
        setAllowedNoOfVisitors={setAllowedNoOfVisitors}
        allowedNoOfVisitors={allowedNoOfVisitors}
        visitNumbers={visitNumbers}
        setVisitNumbers={setVisitNumbers}
        setReload={setReload}
        fetchAutoProcessExceptionRules={fetchAutoProcessExceptionRules}
        customNoOptionsMessage={customNoOptionsMessage}
        setRowId={setRowId}
        rowId={rowId}

      />
      <DeleteConfirmationModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteFunction={deleteAutoProcessExceptionRules}


      />



    </div>
  );

}
export default AutoProcessExceptionRule;