/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import {  Card, Col,  Row, Spinner,Breadcrumb, BreadcrumbItem, Container,CardHeader, CardBody, } from 'reactstrap';
import { Link } from "react-router-dom";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import "../../newComponents/utilities/DateRangeSelector.css";
import BootstrapTable from 'react-bootstrap-table-next';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import moment from 'moment'

const AWSQueue = () => {

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [spinner, setSpinner] = useState(false)
  const [apiLoaded, setApiLoaded] = useState(false)
  const [queueDetailsList, setQueueDetailsList] = useState([])

  const fetchQueues = () => {
    setSpinner(true);
    setApiLoaded(false);
   
    AxiosInter.get(`${API_ENDPOINT}/admin-api/list-queues/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(function (response) {
        if (response.data.data) {
          setQueueDetailsList(response.data.data);
         setApiLoaded(true);
          setSpinner(false);
        } else {
          setQueueDetailsList([]);
          setApiLoaded(true);
          setSpinner(false);
        }
      })
      // .catch(function (error) {
      //   setQueueDetailsList([]);
      //   // setApiLoaded(true);
      //   // setSpinner(false);
      // });
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const EpochToIST = (cell) => {

    
    if(cell){

     /*  var myDate = new Date( cell *1000);
      document.write(myDate.toGMTString()+"<br>"+myDate.toLocaleString());

      let utcSeconds = cell;
      let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);
      return d */

      return moment.unix(cell).format('dddd, MMMM Do, YYYY h:mm:ss A')

    } else {
      return "--"
    }

  }

  const columns = [
    {
    dataField: 'QueueName',
    text: 'Queue Name',
    sort: true,
    formatter: basicValidation
  }, 
    {
    dataField: 'url_list',
    text: 'Queue URL',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'ARN',
    text: 'Queue ARN',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'NumberOfMessages',
    text: 'Number Of Messages',
    sort: true,
    formatter: basicValidation
  },
  {
    dataField: 'NumberOfMessagesNotVisible',
    text: 'Number Of Messages (NotVisible)',
    sort: true,
    formatter: basicValidation
  },
  {
    dataField: 'NumberOfMessagesDelayed',
    text: 'Number Of Messages (Delayed)',
    sort: true,
    formatter: basicValidation
  },
  {
    dataField: 'CreatedTime',
    text: 'Created Date/Time',
    sort: true,
    formatter: EpochToIST
  },
  {
    dataField: 'UpdatedTime',
    text: 'Updated Date/Time',
    sort: true,
    formatter: EpochToIST
  },
];

   
  useEffect(() => { fetchQueues(); }, []) // eslint-disable-line

  useEffect(() => {   
    window.scrollTo(0, 0);   
     }, []);

  return (
    <div>
          <Hux>
              <Container fluid>
                  <Header>
                      <HeaderTitle>AWS Queues</HeaderTitle>
                      <Breadcrumb>
                          <BreadcrumbItem>
                              <Link to="/dashboard">Dashboard</Link>
                          </BreadcrumbItem>
                          <BreadcrumbItem active>aws queue</BreadcrumbItem>
                      </Breadcrumb>
                  </Header>                 
              </Container>
          </Hux>


          <Card className="adv-search-background-card">
          <CardHeader>
              <strong>
                <h4>

              AWS Queue Details
                </h4>
              </strong>
            </CardHeader>
            <CardBody className="patient-list">

                <div className="advanced-search-button">
                  {spinner
                  ? <Spinner />
                  : ""}
                </div>
            {queueDetailsList && apiLoaded 
                ? <>
                <br />
                <Row >
                    <Col>
                    <div className="patient-list-table">
                    
                    {queueDetailsList && apiLoaded ?
                      
                      <BootstrapTable
                        keyField='id' 
                        data={queueDetailsList} 
                        columns={columns}
                        bordered={ false }
                        wrapperClasses="Queue-table"
                      />
                      
                      :
                      
                      ""}
                    </div>
                    {!queueDetailsList && apiLoaded ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""}
                    </Col>
                </Row>
                </>
                : ""
            }
            {!queueDetailsList && apiLoaded && !spinner ? 
                <>
                <br />
                <Row className="advanced-search-table-height patient-list-table">
                <Col>
                    {!queueDetailsList && apiLoaded && !spinner ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""}
                </Col>
                </Row>
            </>
            : ""
            }
            
        </CardBody>
      </Card>
    </div>
  );
}

export default AWSQueue;