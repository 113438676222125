import React, { useEffect, useState } from "react";
import { Button, Row, Col, Card, CardHeader } from "reactstrap";
import ToggleButton from "react-toggle-button";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import Cookie from 'js-cookie';
import { default as ReactSelect } from "react-select";
import { CopyToClipboard } from "react-copy-to-clipboard";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ReferralOrderFullfiller = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { groupID, setGroupID, setcategoryTypePopulated } = props;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const EQUIPO_CARE_ENDPOIN = process.env.REACT_APP_EQUIPO_CARE_ENDPOINT;
  const EQUIPO_CARE_ENDPOIN_EXCLUSIVITY =
    process.env.REACT_APP_EQUIPO_CARE_ENDPOINT_EXCUSIVITY_LINK;
  const [enableAsFullfillerToggleButton, setEnableAsFullfillerToggleButton] =
    useState(false);

  const [enableDataExchangeToggleButton, setEnableDataExchangeToggleButton] =
    useState(true);
  const [isClinicalToggleButton, setIsClinicalToggleButton] = useState(true);
  const [isTransportToggleButton, setIsTransportToggleButton] = useState(true);
  const [enableRegistryMatchToggleButton, setEnableRegistryMatchToggleButton] =
    useState(true);
  const [
    enableApptRecapGenerationToggleButton,
    setEnableApptRecapGenerationToggleButton,
  ] = useState(true);
  const [
    enableReferraViaLinkToggleButton,
    setEnableReferraViaLinkToggleButton,
  ] = useState(true);
  const [
    enableReferraExclusivityLinkToggleButton,
    setEnableReferraExlusivityLinkToggleButton,
  ] = useState(true);
  const [subscriptionType, SetSubscriptionType] = useState("");
  const [clinicalTransmissionTypes, SetClinicalTransmissionTypes] =useState("");
  const [transportTransmissionTypes, SetTransportTransmissionTypes] =useState("");
  // eslint-disable-next-line no-unused-vars
  const [text, setText] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [fullfillerid, setFullFillerid] = useState(0);
  const [link, setLink] = useState("");
  const [exclusivityLink, setExclusivityLink] = useState("");
  const [transmissionOptions, setTransmissionOptions] = useState([{}]);
  const [categoryTypeOptions, setCategoryTypeOptions] = useState([
    { value: 1, label: "Clinical" },
    { value: 2, label: "Transport" },
    {value: 3, label: "Social" },
    {value: 4, label: "Prescription" },
    {value: 5, label: "Econsultant" },
    {value: 6, label: "Nursing Home" },
    {value: 7, label: "Private Hospital" },
    {value: 8, label: "Sdoh" },
  ]);
  const [categoryType, setCategoryType] = useState([]);
  const [transmissionTypes, SetTransmissionTypes] =useState([]); //eslint-disable-line
  const [transmissionType1, SetTransmissionType1] =useState([]);
  // const [options,setOptions]=useState([{}])
  const [enableMdmTransmisionToggleButton,setEnableMdmTransmisionToggleButton] = useState(false);
  const [MDMTransmissionType, setMDMTransmissionType] = useState([]);
  const [transmissionEnabledFulfiller, setTransmissionEnabledFulfiller] = useState([]);

  const SubscriptionTypeOptions = [
    { value: 'FULL', label: "Enterprise" },
    { value: 'LITE', label: "Community" },
    {value: 'LITE PLUS', label: "Community Plus" }
  ];
//   let arr=[]
// const setTransmission =(categoryVal,type)=>{
//   // console.log('starting...',categoryVal,type)
// // let found = false;
//  if (transmissionType1 && transmissionType1.length){
//   transmissionType1.forEach(transType=>{
//     if(transType.category==categoryVal){
//       let arr1=[]
//       type.forEach(transmissionT=>{
//         arr1.push(transmissionT.value)
//       })
//       // console.log('arr1',arr1)
//       transType.transmissions=arr1
//       // found=true
//       arr.push(transType)
//     }else{
//       let optValue ={category:categoryVal,transmissions:[type]};
//       arr.push(optValue)
//     }
//     // arr.push(transType)
//     // console.log("transType",transType)
    
//   })
//   // if (!found){
//   //   let optValue ={category:categoryVal,transmissions:[type]};
//   //   transmissionType1.push(optValue)
//   // }
//   SetTransmissionType1(arr)
//   console.log('98',arr)
//   console.log("transmissionType1 99",transmissionType1)

//  }else{let optValue ={category:categoryVal,transmissions:[type]};
//  arr.push(optValue)
//  SetTransmissionType1(arr)
// }
// console.log("transmissionType1",transmissionType1)
// }


const setTransmission = (categoryVal, type) => {
  let arr = [];
  if (transmissionType1 && transmissionType1.length) {
    SetTransmissionTypes(transmissionType1)

    transmissionType1.forEach((transType) => {
      if (transType.category == categoryVal) {//eslint-disable-line
        let arr1 = [];
        type.forEach((transmissionT) => {
          arr1.push(transmissionT.value.toString());
        });
        transType.transmissions = arr1;
        arr.push(transType);
      } else {
        arr.push(transType);
      }
    });

    // Check if a matching category was found
    const categoryObj = transmissionType1.find(
      (transType) => transType.category === categoryVal
    );

    // If no matching category was found, create a new object and push it into the array
    if (!categoryObj) {
      let optValue = { category: categoryVal, transmissions: [] };
      type.forEach((transmissionT) => {
        optValue.transmissions.push(transmissionT.value.toString());
      });
      arr.push(optValue);
    }

    SetTransmissionType1(arr);
  } else {
    let optValue = { category: categoryVal, transmissions: [] };
    type.forEach((transmissionT) => {
      optValue.transmissions.push(transmissionT.value.toString());
    });
    arr.push(optValue);
    SetTransmissionType1(arr);
  }
};


  const TransportTransmissionTypeOptions = [
    { value: "1", label: "API" },
    { value: "2", label: "DMSG" },
    { value: "3", label: "EFAX" },
    { value: "4", label: "HL7_SFTP" },
  ];
  const saveNotifications = () => {
    let ctTypes = [];
    if (
      // eslint-disable-next-line eqeqeq
      clinicalTransmissionTypes != "" ||
      // eslint-disable-next-line eqeqeq
      clinicalTransmissionTypes.length != 0
    ) {
      // eslint-disable-next-line array-callback-return
      clinicalTransmissionTypes.map((item) => {
        ctTypes.push(item.value);
      });
    }
    let transportTypes = [];
    if (
      // eslint-disable-next-line eqeqeq
      transportTransmissionTypes != "" ||
      // eslint-disable-next-line eqeqeq
      transportTransmissionTypes.length != 0
    ) {
      // eslint-disable-next-line array-callback-return
      transportTransmissionTypes.map((item) => {
        transportTypes.push(item.value);
      });
    }
    if (categoryType) {
      var categoryTypesResult = categoryType.map((item) => {
        return item.value.toString();
      });
    }
    let newfield = { category: '', transmission: '' }//eslint-disable-line
    
    // mdm button ON and not selected any of options then error
    if(enableMdmTransmisionToggleButton && MDMTransmissionType.length === 0){
      return toastr.warning("Please select MDM transmission types")
    }

    const saveFulfillerData = () => {
      AxiosInter
        .post(
          `${API_ENDPOINT}/admin-api/referral-fullfiller/`,
          {
            group_id: groupID ? groupID.value : "",
            Referral_account_type: subscriptionType ? subscriptionType.label : "",
            Enable_as_fulfiller: enableAsFullfillerToggleButton ? true : false,
            enable_data_exchange: enableDataExchangeToggleButton ? true : false,
            Enable_clinical_orders: isClinicalToggleButton ? true : false,
            Enable_transport_orders: isTransportToggleButton ? true : false,
            enable_referral_via_link: enableReferraViaLinkToggleButton ? true : false,
            enable_referral_exclusivity_link: enableReferraExclusivityLinkToggleButton ? true : false,
            enable_appt_recap_generation: enableApptRecapGenerationToggleButton ? true : false,
            enable_registry_match: enableRegistryMatchToggleButton ? true : false,
            category_type: categoryTypesResult.sort(),
            transmission_types: transmissionType1,
            clinical_transmission_types: clinicalTransmissionTypes ? ctTypes : "",
            transport_transmission_types: transportTransmissionTypes ? transportTypes : "",
            enable_mdm_transmission: enableMdmTransmisionToggleButton ? true : false,
            mdm_transmission_types: MDMTransmissionType ? MDMTransmissionType.flatMap(obj => obj?.label.toString()) : "",
            mdm_transmission_allowed_sender_groups: transmissionEnabledFulfiller ? transmissionEnabledFulfiller.flatMap(obj => obj?.value.toString()) : "",
          },
          {
            headers: {
              Authorization: API_TOKEN,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            toastr.success("Success", "changes saved");

          }
        })
        .catch((error) => {
          toastr.error("Failed", "Something went wrong");
        });
    }

    saveFulfillerData()
    
  };
  const gettransmissionoptions = () => {
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/referral-transmission-types/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setTransmissionOptions(response.data.data);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong!");
      });
  };

  const fetchReferralFullfillerById = async(grpid) => {
    await AxiosInter
      .get(
        `${API_ENDPOINT}/admin-api/referral-fullfiller-suggestion/${grpid}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          // this is for alert preference
          let categoryTypePopulated = JSON.parse(response?.data?.data[0]?.category_type)

          setcategoryTypePopulated(prevS=> [...prevS, categoryTypePopulated]);

          setFullFillerid(response.data.data[0]["id"]);

          // check MDM button clicked 
          if(response.data.data[0]?.enable_mdm_transmission){
            setEnableMdmTransmisionToggleButton(true)
          }
          
          // console.log(response.data.data[0].mdm_transmission_types);
          let populatedTransmissionType = JSON.parse(response.data.data[0]?.mdm_transmission_types)
          if(populatedTransmissionType){
            // eslint-disable-next-line array-callback-return
            populatedTransmissionType.map(eachName => {
              AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-mdm-transmissiontype-name/?typetopopulate=${eachName}`,
                {
                  headers: {
                    Authorization: API_TOKEN,
                  }
                })
                .then((response) => setMDMTransmissionType((prevS)=>[...prevS,response.data.data[0]]) )
                .catch((error) => {
  
                  throw error;
                });
            })
          }

          // console.log((response.data.data[0]?.mdm_transmission_allowed_sender_groups));
          let populatedTransmissionEnabledIds = JSON.parse(response.data.data[0]?.mdm_transmission_allowed_sender_groups)
          // we want to populate {value:'', label:''}
          if(populatedTransmissionEnabledIds){
            // eslint-disable-next-line array-callback-return
            populatedTransmissionEnabledIds.map(eachId => {
              AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-referralSender-async-options/?fulfillerid=${response.data.data[0].id}&groupID=${eachId}`,
                {
                  headers: {
                    Authorization: API_TOKEN,
                  }
                })
                .then((response) => setTransmissionEnabledFulfiller((prevS)=>[...prevS,response.data.data[0]]))
                .catch((error) => {
                  throw error;
                });
            })
          }
          //END check MDM button clicked 

          setEnableAsFullfillerToggleButton(response.data.data[0]["status"]);
          setEnableDataExchangeToggleButton(
            response.data.data[0]["appt_data_exchange_enabled"] // useEffect(() => {
            //   //Print data each time the checkbox is "checked" or "unchecked"

            // }, [clinicalTransmissionTypes, transportTransmissionTypes]);
          );
          setIsClinicalToggleButton(response.data.data[0]["is_clinical"]);
          
        setIsTransportToggleButton(response.data.data[0]["is_transport"]);
        SetTransmissionTypes(JSON.parse(response.data.data[0]["transmission_types"]));
        // console.log('transmission_types 268',response.data.data[0]["transmission_types"]);
        
          setEnableRegistryMatchToggleButton(
            response.data.data[0]["referral_registry_match"]
          );
          setEnableApptRecapGenerationToggleButton(
            response.data.data[0]["appt_recap_generation_enabled"]
          );
          setEnableReferraViaLinkToggleButton(
            response.data.data[0]["enable_referral_via_link"]
          );
          setEnableReferraExlusivityLinkToggleButton(
            response.data.data[0]["enable_referral_exclusivity_link"]
          );
          let catType=JSON.parse(response.data.data[0]["category_type"])
          
            var array = []
            // const data = editCategoryType
            
            if (catType.length > 0) {
              catType.map((id) => {               // eslint-disable-line
              categoryTypeOptions.map((item) => {   // eslint-disable-line
                    if (id.toString() === item.value.toString()) {
                        array.push({
                            value: item.value,
                            label: item.label
                        })
                    }
                })
            })
          }
            setCategoryType(array)
          // eslint-disable-next-line array-callback-return
          SubscriptionTypeOptions.map((items) => {
            if (response.data.data[0]["subscription_type"] === items.value)
              SetSubscriptionType(items);
          });
          let clinicaltype =
            response.data.data[0]["clinical_transmission_types"];
          let clinicaltypearr = clinicaltype.split("");

          let ctemp = [];

          // eslint-disable-next-line array-callback-return
          clinicaltypearr.map((item) => {
            // eslint-disable-next-line array-callback-return
            TransportTransmissionTypeOptions.map((options) => {
              if (item === options.value) {
                ctemp.push(options);
              }
            });
          });

          // eslint-disable-next-line eqeqeq
          if (ctemp.length != 0) {
            SetClinicalTransmissionTypes(ctemp);
          }

          let transporttype =
            response.data.data[0]["transport_transmission_types"];
          let transporttypearr = transporttype.split("");
          let ttemp = [];

          // eslint-disable-next-line array-callback-return
          transporttypearr.map((item) => {
            // eslint-disable-next-line array-callback-return
            TransportTransmissionTypeOptions.map((opt) => {
              if (item === opt.value) {
                ttemp.push(opt);
              }
            });
          });

          // eslint-disable-next-line eqeqeq
          if (ttemp.length != 0) {
            SetTransportTransmissionTypes(ttemp);
          }
        }
      })
      .catch((error) => {
        // toastr.error("Error", "Something went wrong in region listing !!");
      });
  };
  
  
  useEffect(() => {
    if (groupID !== "" && groupID !== null && groupID !== undefined) {
      fetchReferralFullfillerById(grpid);
    }
  }, [groupID]); // eslint-disable-line
  useEffect(() => {
    if (
      enableReferraViaLinkToggleButton === true ||
      enableReferraViaLinkToggleButton === 1
    ) {
      UrlGenerate();
    }
  }, [enableReferraViaLinkToggleButton]); // eslint-disable-line
  useEffect(() => {
    if (
      enableReferraExclusivityLinkToggleButton === true ||
      enableReferraExclusivityLinkToggleButton === 1
    ) {
      UrlGenerateForExclusivity();
    }
  }, [enableReferraExclusivityLinkToggleButton]); // eslint-disable-line

  const equipoEncode = (number) => {
    let dataProcess = (
      (((number + 512) * 33 - 89) * 2954 + 9874) *
      82
    ).toString();

    let firstPartNumber = dataProcess.slice(0, 4);
    let revNumber = dataProcess.split("").reverse().join("");
    let secondPartNumber = revNumber.slice(0, -4);
    try {
      let firstencode = Buffer.from(secondPartNumber, "binary").toString(
        "base64"
      );

      let comb = firstencode.concat(firstPartNumber);
      let array = [{ output: comb }];
      //  let seconden = Buffer.from(array[0].toString, "binary").toString("base64");
      //    console.log(seconden)

      // let jsonString = JSON.stringify({ ...array });
      let jsonString = JSON.stringify(array[0]);

      let secondencode = Buffer.from(jsonString, "binary").toString("base64");

      return secondencode;
    } catch (err) {
      console.log(err);
    }
  };

  const UrlGenerateForExclusivity = () => {
    let fid = fullfillerid;

    if (fid === "undefined" || fid === "") {
      setFullFillerid(0);
    }
    if (
      fid > 0 &&
      (enableReferraExclusivityLinkToggleButton === 1 ||
        enableReferraExclusivityLinkToggleButton === true)
    ) {
      // console.log("gidgbgb", EQUIPO_CARE_ENDPOIN_EXCLUSIVITY);
      let encodedlink = `${EQUIPO_CARE_ENDPOIN_EXCLUSIVITY}${equipoEncode(
        fid
      )}/${equipoEncode(groupID.value)}`;

      setExclusivityLink(encodedlink);
    }
  };

  const UrlGenerate = () => {
    let fid = fullfillerid;
    if (fid === "undefined" || fid === "") {
      setFullFillerid(0);
    }
    if (
      fid > 0 &&
      (enableReferraViaLinkToggleButton === 1 ||
        enableReferraViaLinkToggleButton === true)
    ) {
      let encodedlink = `${EQUIPO_CARE_ENDPOIN}${equipoEncode(
        fid
      )}/${equipoEncode(groupID.value)}`;

      setLink(encodedlink);
    }
  };
 
  const newFieldComponents = categoryType.reduce((obj, option) => {
    obj[option.value] = (label) => 
    <Row key={label}>
      <Col sm={12}><br></br>
        <p>{label+" Transmission Type"}</p>
        </Col>
        <Col >
                <Select
                  isMulti
                  name="Group"
                  placeholder="Select"
                  options={transmissionOptions}
                  isSearchable
                  isClearable
                  // value={transmissionTypes}
                  onChange={(e) => {
                    // Get the index of the selected value
                    // const index = e.target.selectedIndex;
                    // console.log('target',e)
                    // Call the setTransmission function with the category and selected type
                    setTransmission(option.value.toString(), e);

                  }}
                  
                  />
              </Col></Row>;
    return obj;
  }, {});
  const handleCategoryTypeChange = (event) => {
    setCategoryType(event);
  };
  // eslint-disable-next-line no-unused-vars
  const newFields = categoryType.map((option) => {
    const component = newFieldComponents[option.value];
    const label = option.label;
    return component(label);
  });

  // var id = $('#fulfillerId').val();
  // if(typeof id == 'undefined' || id == ''){
  //     id = 0;
  // }
  // if(id > 0 && fulfillerReferralViaLink > 0){
  //     $('#referralViaLink').val(getUrl.origin+'/referral/inboundLink/'+equipoEncode(id)+'/'+equipoEncode(groupId));
  //     $('.viaLinkSection').show();
  // }else{
  //     $('.viaLinkSection').hide();
  // }
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const functionForFullfiller = () => {
    setEnableAsFullfillerToggleButton(!enableAsFullfillerToggleButton);
  };
  const functionForDataExchange = () => {
    setEnableDataExchangeToggleButton(!enableDataExchangeToggleButton);
  };
  const functionForIsClinical = () => {//eslint-disable-line
    setIsClinicalToggleButton(!isClinicalToggleButton);
  };
  const functionForIsTransport = () => {//eslint-disable-line
    setIsTransportToggleButton(!isTransportToggleButton);
  };
  const functionForRefferelViaLink = () => {
    setEnableReferraViaLinkToggleButton(!enableReferraViaLinkToggleButton);
  };
  const functionForRefferelExlclusivityLink = () => {
    setEnableReferraExlusivityLinkToggleButton(
      !enableReferraExclusivityLinkToggleButton
    );
  };
  const functionForRegistryMatch = () => {
    setEnableRegistryMatchToggleButton(!enableRegistryMatchToggleButton);
  };
  const functionForApptRecap = () => {
    setEnableApptRecapGenerationToggleButton(
      !enableApptRecapGenerationToggleButton
    );
  };
  useEffect(() => {
    setEnableAsFullfillerToggleButton(false);

    setEnableDataExchangeToggleButton(false);
    setIsClinicalToggleButton(true);
    setIsTransportToggleButton(false);
    setEnableReferraViaLinkToggleButton(false);
    setEnableRegistryMatchToggleButton(false);
    setEnableApptRecapGenerationToggleButton(true);
    SetSubscriptionType({ value: "1", label: "FULL" });
    setEnableReferraExlusivityLinkToggleButton(false);
    gettransmissionoptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //

  // const onClickNotigicationP = (email) => {
  //   var temp = []
  //     if (email === null){
  //       SetClinicalTransmissionTypes('')
  //     }
  //     else {
  //       var parsedArray = JSON.parse(email)
  //       parsedArray.map((item) => {
  //         temp.push(item.trim())

  //       })
  //     }
  //     SetClinicalTransmissionTypes(temp)
  //   }

  const status = enableAsFullfillerToggleButton;
  const grpid = groupID ? groupID.value : "";
  // const CheckHandler = (e) => {
  //   SetClinicalTransmissionTypes((prev) => [...prev, e.target.value]);
  // };
  // const CheckHandler = (e) => {
  //   const value = e.target.value;
  //   SetClinicalTransmissionTypes((prev) =>
  //   clinicalTransmissionTypes.includes(value)
  //       ? prev.filter((cur) => cur !== value)
  //       : [...prev, e.target.value]
  //   );
  // };
  // const CheckHandlerTwo = (e) => {
  //   const value = e.target.value;
  //   SetTransportTransmissionTypes((prev) =>
  //   transportTransmissionTypes.includes(value)
  //       ? prev.filter((cur) => cur !== value)
  //       : [...prev, e.target.value]
  //   );
  // };
  // const CheckHandlerTwo = (e) => {
  //   SetTransportTransmissionTypes((prev) => [...prev, e.target.value]);
  // };

  
  const fetchCategoryTypeOptions = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-category-type-options/`, {
        headers: {
            Authorization: API_TOKEN,
        },
    })
        .then((response) => {
            if (response.status === 200) {

                setCategoryTypeOptions(response.data.data)

            }

        })
        .catch((error) => {
            console.log(error)
        });
}
useEffect(() =>{ fetchCategoryTypeOptions()},[status])//eslint-disable-line

  useEffect(() => {
    if (clinicalTransmissionTypes.length === 0) {
      SetClinicalTransmissionTypes("");
    }
  }, [clinicalTransmissionTypes]);
  useEffect(() => {
    if (transportTransmissionTypes.length === 0) {
      SetTransportTransmissionTypes("");
    }
  }, [transportTransmissionTypes]);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };
  useEffect(() => {
    if (enableAsFullfillerToggleButton) {
      gettransmissionoptions();

      // loadOptionsForTranmissionTypes().then((data) => setTransmissionOptions(data.data[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableAsFullfillerToggleButton]);
  // useEffect(() => {

  //   if (clinicalTransmissionTypes.length === 0) {
  //     SetClinicalTransmissionTypes("");
  //   }
  // }, []);

  const functionForEnableMdm = () => {
    setEnableMdmTransmisionToggleButton(!enableMdmTransmisionToggleButton);
    if(!enableMdmTransmisionToggleButton){
      setMDMTransmissionType([])
      setTransmissionEnabledFulfiller([])
    }
  };

  const [MDMTransmissionTypeOptions,setMDMTransmissionTypeOptions] =useState([])

  useEffect(()=>{
    const mdmTransmissionTypeLoadOptions = () => {
      // const searchParams = new URLSearchParams({ name: inputValue }); // eslint-disable-line
      AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-mdm-transmissiontype-name/`,
        {
          headers: {
            Authorization: API_TOKEN,
          }
        })
        .then((response) => setMDMTransmissionTypeOptions(response.data.data))
        .catch((error) => {
          throw error;
        });
    };
    mdmTransmissionTypeLoadOptions()
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  const mdmTransmissionEnabledLoadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue }); // eslint-disable-line
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-referralSender-async-options/?sendername=${inputValue}&fulfillerid=${fullfillerid}`,
        {
          headers: {
            Authorization: API_TOKEN,
          }
        })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  return (
    <Card style={{ border: "1px solid rgba(1, 0, 0, 0.25)" }}>
      <div>
        <CardHeader>
          <Row>
            <Col sm={12} className="text-center">
              <h5 style={{ fontWeight: 800, margin: 0 }}>Fulfiller</h5>
            </Col>
          </Row>
        </CardHeader>
        <hr
          style={{
            borderColor: "rgba(1, 0, 0, 0.25)",
            height: "3px",
            margin: "0",          }}
        />
        <br />
        <br />
        <br />
        <Row>
          <Col sm={1}></Col>

          <Col sm={5}>
            <p> Enable As Fulfiller</p>
          </Col>

          <Col sm={2}>
            <ToggleButton
              inactiveLabel={"OFF"}
              activeLabel={"ON"}
              //  value={(smsToggleButton ===1)?true:false}
              onToggle={() => {
                functionForFullfiller();
              }}
              value={enableAsFullfillerToggleButton}
            />
          </Col>
        </Row>
        <br />
        {status === true || status === 1 ? (
          <><Row>
          <Col sm={1}></Col>
          <Col sm={11}>
            <p>Referral Categories</p>
          </Col>
          <Col sm={1}></Col>
          <Col sm={8} style={{ paddingRight: 0 }}>
            <ReactSelect
              className="ovreflow-revert"
              options={categoryTypeOptions}
              isMulti
              closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
              allowSelectAll={true}
              onChange={handleCategoryTypeChange} 
              value={categoryType}
              placeholder="Type"
              isSearchable
              isClearable
            />
          </Col>
          
        </Row>
        <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                {/* this need to make dynamic based on Referral Categories selected */}
                {
                  // console.log(categoryType)
                  // [ {"value": 1,"label": "Clinical"}, {"value": 2,"label": "Transport"}]
                  // dynamically create select options
                  categoryType?.map(eachCategory =>{
                    
                    // this is the db stored data
                    // console.log(transmissionTypes)
                    // [{"category": "1", "transmissions": ["2"]}, {"category": "2", "transmissions": ["2"]}]

                    let matchedObject = Array.isArray(transmissionTypes) ? transmissionTypes?.filter(item => item.category === eachCategory.value.toString()):[]
                    // console.log(matchedObject)
                    // [{"category": "1", "transmissions": ["2"]}]

                    return(
                      <Row key={eachCategory.value}>
                        <Col sm={12}><br></br>
                          <p>{eachCategory.label} Transmission Type</p>
                        </Col>
                        <Col >
                          <Select
                            isMulti
                            name="Group"
                            placeholder="Select"
                            options={transmissionOptions}
                            isSearchable
                            isClearable
                            required
                            value={
                              transmissionOptions?.filter((option) => {
                                // console.log(option)
                                // {value: 2, label: "API"}
                                // {value: 4, label: 'DMSG'}
                                // {value: 3, label: 'EFAX'}
                                // {value: 1, label: 'HL7_SFTP'}

                                // converting to string
                                // console.log((option.value)+'')

                                // console.log(matchedObject)
                                // [{"category": "1", "transmissions": ["2"]}]

                                // console.log(matchedObject[0].transmissions)
                                // ["2"]

                                return Array.isArray(matchedObject) ? matchedObject[0]?.transmissions?.includes((option.value) + '') : []
                              })
                            }
                            onChange={(e) => {
                              setTransmission(eachCategory.value.toString(), e);
                            }}
                          />
                        </Col>
                      </Row>
                    )
                  })
                }
              </Col>
          </Row>
            <Row className="pt-4">
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Enable Data Exchange</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  // value={(emailToggleButton === 1)?true:false}
                  onToggle={() => {
                    functionForDataExchange();
                  }}
                  value={enableDataExchangeToggleButton}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Enable Clinical Orders</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  //   value={(ivrToggleButton === 1)?true:false}
                  onToggle={() => {
                    functionForIsClinical();
                  }}
                  value={isClinicalToggleButton}
                />
              </Col>
            </Row> */}
            {/* <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Enable Transport Orders</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  // value={(appToggleButton ===1)?true:false}
                  onToggle={() => {
                    functionForIsTransport();
                  }}
                  value={isTransportToggleButton}
                />
              </Col>
            </Row> */}
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Enable Refferal Via Link</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  // value={(appToggleButton ===1)?true:false}
                  onToggle={() => {
                    functionForRefferelViaLink();
                  }}
                  value={enableReferraViaLinkToggleButton}
                />
              </Col>
            </Row>
            {/* <br /> */}

            <Row className="col-xl-12">
              {fullfillerid > 0 &&
              (enableReferraViaLinkToggleButton === true ||
                enableReferraViaLinkToggleButton === 1) ? (
                <div className="copycontainer d-flex">
                  <Col xl={6}>
                    <input
                      type="text"
                      value={link}
                      placeholder=""
                      onChange={(event) => setLink(event.target.value)}
                    />
                  </Col>
                  <Col xl={6} className="float: right;display: flex;">
                    <CopyToClipboard text={link} onCopy={onCopyText}>
                      <div className="copy-area">
                        <button>copy</button>
                        <span
                          className={`copy-feedback ${
                            isCopied ? "active" : ""
                          }`}
                        >
                          Copied!
                        </span>
                      </div>
                    </CopyToClipboard>
                  </Col>
                </div>
              ) : (
                ""
              )}
            </Row>
            <br></br>

            <Row>
              <Col sm={1}></Col>

              <Col sm={5}>
                <p>Enable Registry Match</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  //   value={(ivrToggleButton === 1)?true:false}
                  onToggle={() => {
                    functionForRegistryMatch();
                  }}
                  value={enableRegistryMatchToggleButton}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={1}></Col>
              <Col sm={4}>
                <p>Referral Account Type</p>
              </Col>
              <Col sm={6}>
                <Select
                  options={SubscriptionTypeOptions}
                  onChange={(event) => SetSubscriptionType(event)}
                  value={subscriptionType}
                  placeholder="Type"
                  isSearchable
                  isClearable
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={1}></Col>

              <Col sm={5}>
                <p>Enable Appt. Recap Generation</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  //   value={(ivrToggleButton === 1)?true:false}
                  onToggle={() => {
                    functionForApptRecap();
                  }}
                  value={enableApptRecapGenerationToggleButton}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Clinical Transmission Types</p>
              </Col>
              <Col sm={6}>
                <ReactSelect
                  options={transmissionOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  value={clinicalTransmissionTypes}
                  onChange={(e) => {
                    SetClinicalTransmissionTypes(e);
                  }}
                  //  onChange={CheckHandler}
                  allowSelectAll={true}
                />
              </Col>
            </Row> */}
            <br></br>
            {/* <Row>
              <Col sm={1}></Col>

              <Col sm={5}>
                <p>Transport Transmission Types</p>
              </Col>
              <Col sm={6}>
                <ReactSelect
                  //options={TransportTransmissionTypeOptions}
                  options={transmissionOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  onChange={(e) => {
                    SetTransportTransmissionTypes(e);
                  }}
                  // onChange={CheckHandlerTwo}
                  allowSelectAll={true}
                  value={transportTransmissionTypes}
                />
              </Col>
            </Row> */}
            {/* <br></br> */}

            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Enable Refferal Exclusivity Link</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  // value={(appToggleButton ===1)?true:false}
                  onToggle={() => {
                    functionForRefferelExlclusivityLink();
                  }}
                  value={enableReferraExclusivityLinkToggleButton}
                />
              </Col>
            </Row>

            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Enable MDM Transmission</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  //   value={(ivrToggleButton === 1)?true:false}
                  onToggle={() => {
                    functionForEnableMdm();
                  }}
                  value={enableMdmTransmisionToggleButton}
                />
              </Col>
              {
                Boolean(enableMdmTransmisionToggleButton) &&
                <>
                  <Col sm={12} className="pl-5 pr-5 pb-3">
                    {/* <AsyncSelect
                      cacheOptions
                      isClearable
                      isMulti
                      value={MDMTransmissionType}
                      loadOptions={mdmTransmissionTypeLoadOptions}
                      onChange={(e) => {
                        setMDMTransmissionType(e)
                      }}
                      placeholder="Select MDM Transmission Type"
                    /> */}
                    <Select
                      value={MDMTransmissionType}
                      isMulti
                      isClearable
                      onChange={(e) => {
                        setMDMTransmissionType(e)
                      }}
                      options={MDMTransmissionTypeOptions}
                      placeholder="Select MDM Transmission Type"
                    />
                  </Col>
                  <Col sm={12} className="pl-5 pr-5 pb-3">
                    <AsyncSelect
                      cacheOptions
                      isClearable
                      isMulti
                      value={transmissionEnabledFulfiller}
                      loadOptions={mdmTransmissionEnabledLoadOptions}
                      onChange={(e) => {
                        setTransmissionEnabledFulfiller(e)
                      }}
                      placeholder="Select Transmission Enabled Senders"
                      noOptionsMessage={() => 'Enter 4 or more letters to search'}
                    />
                  </Col>
                </>
              }
            </Row>
        

            <Row className="col-xl-12">
              {fullfillerid > 0 &&
              (enableReferraExclusivityLinkToggleButton === true ||
                enableReferraExclusivityLinkToggleButton === 1) ? (
                <div className="copycontainer d-flex">
                  <Col xl={6}>
                    <input
                      type="text"
                      value={exclusivityLink}
                      placeholder=""
                      onChange={(event) =>
                        setExclusivityLink(event.target.value)
                      }
                    />
                  </Col>
                  <Col xl={6} className="float: right;display: flex;">
                    <CopyToClipboard text={exclusivityLink} onCopy={onCopyText}>
                      <div className="copy-area">
                        <button>copy</button>
                        <span
                          className={`copy-feedback ${
                            isCopied ? "active" : ""
                          }`}
                        >
                          Copied!
                        </span>
                      </div>
                    </CopyToClipboard>
                  </Col>
                </div>
              ) : (
                ""
              )}
            </Row>
            <br></br>
          </>
        ) : (
          ""
        )}
      </div>
      <Button
        // className="yes-button button-width"
        onClick={() => {
          saveNotifications();
        }}
      >
        Save
      </Button>{" "}
    </Card>
  );
};
export default ReferralOrderFullfiller;
