import React, { useState, useEffect, useContext} from 'react';
import { TabContent, TabPane,Spinner,Label, Nav, Container, NavItem, NavLink, Card, CardHeader, CardBody, Button, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import  RemotePagination  from '../../newComponents/utilities/RemotePagination'
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import Select from 'react-select-virtualized';
import { toastr } from 'react-redux-toastr';
import { Link } from "react-router-dom";
import SearchBarComponent from '../../newComponents/utilities/SearchBarComponent'
import moment from 'moment';
import StatusUpdateModal from '../../newComponents/utilities/StatusUpdateModal'
import ETLConfigModal from '../../newComponents/utilities/ETLConfigModal'
import { GlobalState } from '../../contextAPI/Context'
import MomentConfig from '../../common/MomentConfig';


const ETLComponent = () => {
  const {  groupNamesForDropDown } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

    const [fileExportName,setFileExportName]= useState("ETLAutobotConfig");

    let statusText = "etl config";
    let statusUpdateEndpoint = 'update-etl-config-status/';
    let idParamKey = 'etlConfigId';
    let statusParamKey = 'etlConfigStatus';


    const [activeTab, setActiveTab] = useState('1');
    const [offset,setOffset] = useState(0);
    const [totalSize,setTotalSize] = useState();
    const [sizePerPage,setSizePerPage] = useState(50);
    const [page,setPage] = useState(1);
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    const [reload,setReload] = useState(0);
    const [isGlobalSearch, setIsGlobalSearch] = useState(false);
    const [groupID,setGroupID] = useState("");
    const [prefix,setPrefix] = useState("");
    const [version,setVersion] = useState("");
    const [etlConfigStatus,setEtlConfigStatus] = useState("");
    const [etlConfigId,setEtlConfigId] = useState("");
    const [etlAllergy,setEtlAllergy] = useState(0);
    const [etlFacility,setEtlFacility] = useState(0);
    const [etlCPTCharge,setEtlCPTCharge] = useState(0);
    const [etlImmune,setEtlImmune] = useState(0);
    const [etlLabs,setEtlLabs] = useState(0);
    const [etlMedication,setEtlMedication] = useState(0);
    const [etlPatientCPT,setEtlPatientCPT] = useState(0);
    const [etlPatientICD,setEtlPatientICD] = useState(0);
    const [etlPatientList,setEtlPatientList] = useState(0);
    const [etlPatientInsuranceList,setEtlPatientInsuranceList] = useState(0);
    const [etlReferral,setEtlReferral] = useState(0);
    const [etlVital,setEtlVital] = useState(0);
    const [etlAppointments,setEtlAppointments] = useState(0);
    const [etlConfigSearchKeyword,setEtlConfigSearchKeyword] = useState("");
    const [userName,setUserName] = useState("");
    const [password,setPassword] = useState("");
    const [baseUrl,setBaseUrl] = useState("");
    const [loginUrl,setLoginUrl] = useState("");
    const [statusModal,setStatusModal] = useState(false);
    const [modal,setModal] = useState(false);
    const [modalState,setModalState] = useState('initial')
    const [etlConfigDetails,setEtlConfigDetails] = useState([]);
    const [etlConfigSpinner,setEtlConfigSpinner] = useState(false);  // eslint-disable-line
    const [groupNameList, setGroupNameList] = useState([]);
    const [groupDropDown, setGroupDropDown] = useState("");
    const [selectedGroupName, setSelectedGroupName] = useState("");
    const [groupNameForFilter, setGroupNameForFilter] = useState("");

    // eslint-disable-next-line no-unused-vars
    const [spinner,setSpinner] = useState(false);

    const [search, setSearch] = useState(false);
    const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`

    const validation = (cell) => {
        return cell ? cell : "N/A"
    }


    const handleTableChange = (type, newState ) => {
      setPage(newState.page);
      setSizePerPage(newState.sizePerPage);
      setOffset((newState.page - 1)*newState.sizePerPage);
      
      
   }


    const setDefaultGroupName = () => {

      groupDropDown.map((item)=>{ // eslint-disable-line
          if(item.value === groupDropDown.value) {
              setSelectedGroupName({value:item.value,label:item.label});
          }
      })
      
    };

    const statusFormatter = (cell, row) => {
        let status = "";
        let badgeclass = " ";
        if (cell === 1) {
          status = "Active";
          badgeclass = "badge badge-success statustoggle status-button-width";
        } else {
          status = "Inactive";
          badgeclass = "badge badge-danger statustoggle status-button-width";
        }
    
        return (
          <>
            <span
              className={badgeclass}
              onClick={() => {
               setEtlConfigStatus(row.etlStatus)
               setEtlConfigId(row.etlId)
               setStatusModal(true)
              }}
            >
              {status}
            </span>
          </>
        );
      };



      const actionsFormatter = (cell, row) => {
        return (
          <>
            <Row>
              <Col sm={2}>
              <Button
                    className="money-button"
                    outline
               
                onClick={()=>{
                   setModal(true)
                   setEtlConfigId(row.etlId)
                   setGroupID(row.etlGroupID)
                   setPrefix(row.etlPrefix)
                   setVersion(row.etlVersion)
                   setUserName(row.etlUserName)
                   setBaseUrl(row.etlBaseUrl)
                   setLoginUrl(row.etlLoginUrl)
                   setEtlAllergy(row.etlAllergy)
                   setEtlFacility(row.etlFacility)
                   setEtlCPTCharge(row.etlCptCharge)
                   setEtlImmune(row.etlImmunization)
                   setEtlLabs(row.etlLabs)
                   setEtlMedication(row.etlMedication)
                   setEtlPatientCPT(row.etlPatientCpt)
                   setEtlPatientICD(row.etlPatientIcd)
                   setEtlPatientList(row.etlPatientList)
                   setEtlPatientInsuranceList(row.etlInsurance)
                   setEtlReferral(row.etlReferral)
                   setEtlVital(row.etlVital)
                   setEtlAppointments(row.etlAppointments)
                   setPassword(atob(row.etlPassword))
                   setModalState('edit')
                   
                }}
                color="primary"
                title="Edit Subscription"
                >
    
                  <FontAwesomeIcon 
                  icon={faEdit} 
                  size={'md'}
                  
                  />
                </Button>
              </Col>
            </Row>
          </>
        )
      }




      const timeFormatter = (cell) => {
          if (cell) {
            var stillUtc = moment.utc(cell).toDate();
            var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
            return local;
          }
          else {
              return 'N/A'
          }
          

      }

    

    const columns = [
        {
          dataField: 'GroupName',
          text: 'Group',
          sort: true,
          formatter:validation
        },
        {
          dataField: 'etlVersion',
          text: 'Version',
          sort: true,
          formatter:validation
        },
        {
          dataField: 'etlPrefix',
          text: 'Prefix',
          sort: true,
          formatter:validation
        },
        {
          dataField: 'etlLastExStartTime',
          text: 'Last Executed',
          sort: true,
          formatter:timeFormatter
        },
        {
          dataField: 'etlStatus',
          text: 'Status',
          sort: true,
          formatter: statusFormatter
        },
       
        {
          //   dataField: 'SubscriptionName',
          text: 'Actions',
          sort: false,
          formatter: actionsFormatter
        }
      ];
    
    
    
    
    
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    // const fetchGroupNames = () => {
    //   setSpinner(true);
  
    //   AxiosInter.get(`${API_ENDPOINT}/admin-api/get-groups-list/`, {
    //     headers: {
    //       Authorization: API_TOKEN,
    //     },
    //   })
    //     .then(response => {
    //       if (response.data.length > 0) {
    //         setGroupNameList(response.data);
    //         setSpinner(false);
    //       }
    //     })
    //     .catch(error => {
    //       setGroupNameList("");
    //       setSpinner(false);
    //       // console.log(error);
    //     });
    // };

    const createGroupOption = () => {
      var array = groupNameList;
      const options = array.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      });
      setGroupDropDown(options);
    };


    const fetchEtlConfigDetails = () => {
        setEtlConfigSpinner(true);
        const params =
        {
            "groupID":groupNameForFilter ? groupNameForFilter.value:"",
            "prefix":prefix? prefix:"",
            "version":version ? version:"",
            "offset":offset,
            "is_pagination":1,
            "record_limit":sizePerPage ? sizePerPage:"",
            "search_keyword":etlConfigSearchKeyword ? etlConfigSearchKeyword:"",
        }
    
    //    setSpinner (true);
    
        AxiosInter.post(`${API_ENDPOINT}/admin-api/get-etl-config-details/`,params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
          .then(response => {
            if (response.data.data.length > 0) {
              setEtlConfigSpinner(false);
              setTotalSize(response.data.record_count)
              setEtlConfigDetails(response.data.data)
              localStorage.setItem('etlConfigOffset',offset);
            }
            else {
              setEtlConfigSpinner(false);
              setEtlConfigDetails("")
              setIsDataEmpty(true);
            }
            
          })
          .catch(error => {
           toastr.error('Something went wrong')
          });
    };
    const exportTableData = () => {

      const params =
      {
            "file_name": fileName ? fileName : "",
            "groupID":groupNameForFilter ? groupNameForFilter.value:"",
            "prefix":prefix? prefix:"",
            "version":version ? version:"",
            "offset":offset,
            "is_pagination":1,
            "record_limit":sizePerPage ? sizePerPage:"",
            "search_keyword":etlConfigSearchKeyword ? etlConfigSearchKeyword:"",
  
      }
  
      AxiosInter.post(`${API_ENDPOINT}/admin-api/export-etl-autobot-config-table-data/`,params,{
          headers: {
             
            Authorization: API_TOKEN,
  
          },
          responseType: "blob"
        })
          .then((response) => { 
            if (response.status === 200) {
              var fileDownload = require("js-file-download");
              fileDownload(
                response.data ,`${fileName}`,
                response["headers"]["x-suggested-filename"]
              );
              toastr.success(
                "Success",
                "Export successful"
              );
            
            }
            
          })
          .catch((error) => {
          
              toastr.error(
                  "Failed",
                  "Something went wrong"
                );
          });
      };

    const clearSearchValues = () => {
        setEtlConfigSearchKeyword("");
        setReload(reload + 1)
       
    }

    const clearValues = () => {
      setGroupNameForFilter("");
      // setSelectedStatus("");
      setReload(reload + 1)
    }

    const filterValidation = () => {
      if (groupNameForFilter) {
        setSearch(true)
      } else {
        setSearch(false)
      }
    }


    useEffect(() => {
     
      filterValidation();
     
    }, [groupNameForFilter])  // eslint-disable-line
    useEffect(() => {
     if(groupDropDown) {
      setDefaultGroupName();
     }
    
     
    }, [groupDropDown])  // eslint-disable-line


    // useEffect(()=>{
    //     fetchEtlConfigDetails();
      

    // },[]) // eslint-disable-line

    useEffect(() => {
      if (etlConfigSearchKeyword.length >= 2) {
        setIsGlobalSearch(true);
  
      }
  
    }, [etlConfigSearchKeyword]) // eslint-disable-line
  
    useEffect(() => {
      if (etlConfigSearchKeyword.length === 0) {
        setReload(reload + 1)
  
      }
  
    }, [etlConfigSearchKeyword]) // eslint-disable-line

    useEffect(() => {
      if (isGlobalSearch) {
        setOffset(0)
        setTotalSize(0);
        setReload(reload + 1)
        setIsGlobalSearch(false);
      }
    }, [isGlobalSearch]) // eslint-disable-line

    useEffect(() => { if (groupNameList) createGroupOption(); }, [groupNameList]) // eslint-disable-line

    useEffect(() => {
        if(reload) {
            fetchEtlConfigDetails();
            setReload(0)

        }
      }, [reload]) // eslint-disable-line

      useEffect(() => {   
        window.scrollTo(0, 0);   
         }, []);
         
      // CheckUserInactivity()

    return (
        <div>
            <Container fluid>
                <Header>
                    <HeaderTitle>
                        ETL
                        <Button className="Add-btn pull-right"
                        onClick={() => {
                            setModal(true)
                            setModalState("add")
                
                         }}
                       >
              Add New ETL Config
            </Button> 
                    </HeaderTitle>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/dashboard">Dashboard</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>ETL</BreadcrumbItem>
                    </Breadcrumb>
                </Header>
            </Container>
            <Card>
                <CardHeader><br/>
                  <Row>
                
                    <Col sm={3}>
                    <Label>Group Name</Label>
                    <Select
                    name="Group"
                    options={groupNamesForDropDown}
                    placeholder="Enter group name"
                    isSearchable
                    isClearable
                    value={ groupNameForFilter}
                    onChange={(e) => {
                    // console.log(e)
                    setGroupNameForFilter(e)
                  }}
                />
                    </Col>
                    <Col>
                <Button
                  className="patient-go-buttons"
                  onClick={() => {
                    setEtlConfigSearchKeyword("");
                    // setData("")
                    offset === 0 ? setReload(reload + 1) : setOffset(0);
                    setTotalSize(0);
                    // setIsSearch(true);
                    setPage(1);



                  }}
                  disabled={!search ? true : false}
                  title={spinner || !search ? "Choose at least one item to filter " : ""}
                >Go</Button> {" "}

                <Button
                  className="patient-reset-buttons"
                  onClick={() => { clearValues() }}
                  disabled={!search ? true : false}
                  title={spinner || !search ? "Nothing To Clear" : ""}
                >Reset</Button>

              </Col>
                  </Row>

                </CardHeader>
                <CardBody>
                <Nav tabs>
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === '1' })}
        onClick={()=>{
            toggle('1');
            setFileExportName('ETLAutobotConfig')

        }}
        style={{'cursor':'pointer'}}
      >
        ETL Autobot Config
      </NavLink>
    </NavItem>
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === '2' })}
        onClick={() => { 
          toggle('2');
          setFileExportName('Referral')
        }}
        style={{'cursor':'pointer'}}
      >
       Referral
      </NavLink>
    </NavItem>
  </Nav>
  <TabContent activeTab={activeTab}>
    <TabPane tabId="1">
                      <div className='groups-search'>
                        <SearchBarComponent
                         searchKeyword =  {etlConfigSearchKeyword}
                        setSearchKeyword = {setEtlConfigSearchKeyword}
                        totalSize = {totalSize}
                        

                        clearValues = {clearSearchValues}
                      /> 
                      </div>
                     {!spinner ? 
                      <RemotePagination
                                data={ etlConfigDetails ? etlConfigDetails:""}
                                columns = {columns}
                                page={ page }
                                sizePerPage={ sizePerPage }
                                FileExportName={fileExportName}
                                exportTableData = {exportTableData}
                                totalSize={totalSize ? totalSize :""}
                                isGlobalSearch = {isGlobalSearch}
                                setIsGlobalSearch = {setIsGlobalSearch}
                                searchKeyword = {etlConfigSearchKeyword}
                                setSearchKeyword = {setEtlConfigSearchKeyword}
                                reloadVariable = {reload}
                                isDataEmpty = {isDataEmpty}
                                // setReloadDetails = {setReloadICDTableData}
                                onTableChange={ (type,newState)=>{
                                    handleTableChange(type,newState);
               
                                }
            
                                }
                       /> :<Spinner/>}
    </TabPane>
    <TabPane tabId="2">
      <Row>
       <Col>
      
       </Col>
      </Row>
    </TabPane>
  </TabContent>
                </CardBody>
            </Card>

        <ETLConfigModal
        etlConfigId = {etlConfigId}
        setEtlConfigId = {setEtlConfigId}
        modal = {modal}
        setModal ={setModal}  
        modalState = {modalState}
        setModalState = {setModalState}
        userName = {userName}
        setUserName = {setUserName}
        password = {password}
        setPassword = {setPassword}
        baseUrl = {baseUrl}
        setBaseUrl ={setBaseUrl}
        loginUrl = {loginUrl}
        setLoginUrl = {setLoginUrl}
        version = {version}
        setVersion = {setVersion}
        prefix = {prefix}
        setPrefix = {setPrefix}
        groupID ={groupID}
        setGroupID ={setGroupID}
        etlAllergy = {etlAllergy}
        setEtlAllergy = {setEtlAllergy}
        etlFacility = {etlFacility}
        setEtlFacility = {setEtlFacility}
        etlCPTCharge = {etlCPTCharge}
        setEtlCPTCharge = {setEtlCPTCharge}
        etlImmune = {etlImmune}
        setEtlImmune = {setEtlImmune}
        etlLabs = {etlLabs}
        setEtlLabs = {setEtlLabs}
        etlMedication = {etlMedication}
        setEtlMedication = {setEtlMedication}
        etlPatientCPT = {etlPatientCPT}
        setEtlPatientCPT = {setEtlPatientCPT}
        etlPatientICD = {etlPatientICD}
        setEtlPatientICD= {setEtlPatientICD}
        etlPatientList = {etlPatientList}
        setEtlPatientList = {setEtlPatientList}
        etlPatientInsuranceList = {etlPatientInsuranceList}
        setEtlPatientInsuranceList = {setEtlPatientInsuranceList}
        etlReferral = {etlReferral}
        setEtlReferral = {setEtlReferral}
        etlVital = {etlVital}
        setEtlVital = {setEtlVital}
        etlAppointments = {etlAppointments}
        setEtlAppointments ={setEtlAppointments}
        reload = {reload}
        setReload = {setReload}
        groupNameList = {groupNameList}
        setGroupNameList = {setGroupNameList}
        groupDropDown = {groupDropDown}
        setGroupDropDown = {setGroupDropDown}
        selectedGroupName = {selectedGroupName}
        setSelectedGroupName = {setSelectedGroupName}
        setGroupNameForFilter = {setGroupNameForFilter}
        />



        <StatusUpdateModal
        statusModal = {statusModal}
        setStatusModal = {setStatusModal}
        status = {etlConfigStatus}
        text = {statusText}
        apiEndpoint = {statusUpdateEndpoint}
        idParamKey = {idParamKey}
        statusParamKey = {statusParamKey}
        id = {etlConfigId}
        reload ={reload}
        setReload = {setReload}
        />


          
          
        </div >
    );
}

export default ETLComponent;