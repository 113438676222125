import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import styles from "./ProviderDetails.module.css";
import Select from 'react-select';

const EditDrgModal = (props) => {
    const {
        drgID,
        mdcOptions,
        drgTypeOptions,
        editDrgModal,
        setEditDrgModal,
        editDrgCode,
        setEditDrgCode,
        editMdc,
        setEditMdc,
        editType,
        setEditType,
        editDescription,
        setEditDescription,
        editWeight,
        setEditWeight,
        editGlos,
        setEditGlos,
        editAlos,
        setEditAlos,
        reload,
        setReload


    } = props;
 
    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    const [addButtonStatus, setAddButtonStatus] = useState(true);

    const toggle = () => {
        (setEditDrgModal(!editDrgModal));

        setEditDrgCode("")
        setEditMdc("")
        setEditType("")
        setEditDescription("")
        setEditWeight("")
        setEditGlos("")
        setEditAlos("")

    }


    // const confirmation = () => { 

    //     if(!newDrgCode ) {
    //         toastr.error("DRG Code Is Required" , "Please enter the drg code!")
    //     }
    const UpdateDrgApiCall = () => {
        const params = {
            "drg_id": drgID ? drgID : 0,
            "drg_code": editDrgCode ? editDrgCode : "",
            "mdc_code": editMdc ? editMdc.value : "",
            "drg_type": editType ? editType.label : "",
            "description": editDescription ? editDescription : "",
            "weight": editWeight ? Number(editWeight) : 0,
            "glos": editGlos ? Number(editGlos) : 0,
            "alos": editAlos ? Number(editAlos) : 0

        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-drg-details/`, params, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(response => {
                // console.log(response.data,'before1')
                if (response.status === 201) {
                    toggle();
                    toastr.success("Success", "DRG Successfully Updated");
                    setReload(reload + 1);
                }
            })
            .catch(error => {
                // console.log(error.message,'line 103')
                toastr.error("Error", "Something went wrong !")


            });
    }

    const setInitialMdc = () => {
        mdcOptions.map((data) => { // eslint-disable-line
            if (data.label === editMdc) {
                setEditMdc({ value: data.value, label: data.label })

            }
        })
    }
    const setInitialType = () => {
        drgTypeOptions.map((data) => { // eslint-disable-line
            if (data.label === editType) {
                setEditType({ value: data.value, label: data.label })

            }
        })
    }

    const disableAddButton = () => {

        if (editDrgCode) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }


    useEffect(() => { disableAddButton() }, [editDrgCode]) // eslint-disable-line
    useEffect(() => {
        if (!editDrgModal)
            setEditDrgModal(false);
        // setModalState('initial')
        // setEditDrgCode("")
        // setEditMdc("") 
        // setEditType("") 
        // setEditDescription("") 
        // setEditWeight("") 
        // setEditGlos("") 
        // setEditAlos("") 
    }, [editDrgModal]) // eslint-disable-line

    useEffect(() => {
        if (editDrgModal && mdcOptions && drgTypeOptions) {
            setInitialMdc()
            setInitialType()
        }

    }, [editDrgModal, mdcOptions, drgTypeOptions]) // eslint-disable-line
    return (
        <div>
            <Modal isOpen={editDrgModal} size="md" scrollable={true} >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Edit DRG </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                    <Row  >
                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        DRG Code  <span className="required">*</span> :

                                    </Label>
                                </div>

                                {/* <Col md={9} sm={7}>
                            <div className={styles.detailProfValue} > */}
                                <Input
                                    id="drgCode"
                                    value={editDrgCode}
                                    placeholder="Enter the DRG Code"
                                    onChange={(e) => { if (e.target.value.length <= 150) setEditDrgCode(e.target.value) }}
                                />
                            </Col>
                            {/* </div> */}
                            {/* </Col> */}

                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        Description :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Input
                                        id="Description"
                                        value={editDescription}
                                        placeholder="Enter Description"
                                        onChange={(e) => { if (e.target.value.length <= 150) setEditDescription(e.target.value) }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br></br>
                        <Row className={styles.detailProfRow} >
                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        MDC  <span className=""></span> :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Select
                                        options={mdcOptions}
                                        onChange={(event) => setEditMdc(event)}
                                        value={editMdc}
                                        placeholder="Select MDC"
                                        isSearchable
                                        isClearable
                                    />
                                </div>
                            </Col>
                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        Type  <span className=""></span> :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Select
                                        id="type"
                                        options={drgTypeOptions}
                                        value={editType}
                                        placeholder="Choose Type"
                                        onChange={(e) => { setEditType(e) }}
                                    />
                                </div>
                            </Col>
                        </Row>


                        <Row className={styles.detailProfRow} >
                            <Col sm={4} >
                                <div className="" >
                                    <Label>

                                        Weight :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Input
                                        id="weight"
                                        value={editWeight}
                                        placeholder="Enter weight (lbs)"
                                        onChange={(e) => { setEditWeight(e.target.value) }}
                                    />
                                </div>
                            </Col>
                            <Col sm={4} >
                                <div className="" >
                                    <Label>

                                        Glos :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Input
                                        id="glos"
                                        value={editGlos}
                                        placeholder="Enter glos"
                                        onChange={(e) => { setEditGlos(e.target.value) }}
                                    />
                                </div>
                            </Col>
                            <Col sm={4} >
                                <div className="" >
                                    <Label>

                                        Alos :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Input
                                        id="alos"
                                        value={editAlos}
                                        placeholder="Enter alos"
                                        onChange={(e) => { setEditAlos(e.target.value) }}
                                    />
                                </div>
                            </Col>
                        </Row>

                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={UpdateDrgApiCall}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default EditDrgModal;