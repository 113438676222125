import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { toastr } from "react-redux-toastr";
import AsyncSelect from "react-select/async";
import { GlobalState } from '../../contextAPI/Context'
import axios from "axios";

const EditAuthGroupModal = (props) => {
  // const [isRecord, setIsRecord] = useState(true);
  const { spinner, setSpinner } = useContext(GlobalState); // eslint-disable-line
  const [inputValue, setInputValue] = useState(""); // eslint-disable-line
  const [updateButton, setUpdateButton] = useState(true)


  const {
    editAuthGroupModal,
    setEditAuthGroupModal,
    groupId,
    groupName,
    setGroupName,
    serviceName,
    groupStatus,
    groupEmail,
    services,
    setServices,
    fetchDRG,
    initialGroupNameLength,
    initialServiceNameLength,
    setInitialGroupNameLength,
    setInitialServiceNameLength,
    reload,
    setReload

  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;
  const API_TOKEN = localStorage.getItem("accessToken");

  const updateGroupCall = () => {

    setSpinner(true)

    let array = []
    if (serviceName != null) {

    }
    // eslint-disable-next-line array-callback-return
    services.map((item) => {
      array.push(item.value)
    })
    const params = {
      // "status": status,
      "status": groupStatus ? groupStatus : false,
      "name": groupName ? groupName : "",
      "email": groupEmail ? groupEmail : "",
      "services": services ? array : "",
      "gid": groupId ? groupId : "",
    }
    console.log(params)
    axios
      .put(
        `${API_ENDPOINT}api/groups/${groupId}`, params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        if (response.out !== null) {
          setSpinner(false);
          setReload(1);

          toastr.success(
            "Success",
            `User details successfully updated ${response.out}`
          );
        }
      })
      .catch(function (error) {
        if (error.response.data) {
          setSpinner(false);
          toastr.error("Update Failed", error.response.data.message);
        } else {
          setSpinner(false);
          toastr.error("Update Failed", "Something went wrong");
        }
      });

  }
  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handleServiceNameChange = (value) => {
    setServices(value);
  };
  useEffect(
    () => {
      if(reload){

        fetchDRG()
        setReload(0)
      }
    },
    [reload] // eslint-disable-line react-hooks/exhaustive-deps
    ) 
    useEffect(
      () => {
       setSpinner(true)
        fetchDRG()
      },[] // eslint-disable-line react-hooks/exhaustive-deps
      ) 
      //debugger
      const disableUpdateButton =() =>{
        let serviceNameLength = services.length;
        setInitialServiceNameLength(services.length);
        let groupNameLength = groupName.length;
        setInitialGroupNameLength(groupName.length)
        
        if (((serviceNameLength < initialServiceNameLength)||(groupNameLength < initialGroupNameLength)) && (serviceNameLength !==0) )
        {
          console.log('less')
        setUpdateButton(false)
      }
        else if ((serviceNameLength > initialServiceNameLength)||(groupNameLength > initialGroupNameLength))
        {
          console.log('greater')
        setUpdateButton(false)
        }
        else{
          console.log('equal')
          setUpdateButton(true)
        }
      }
      useEffect(
        () => {
          setInitialServiceNameLength(services.length);
          setInitialGroupNameLength(groupName.length);
          disableUpdateButton();
        },[services,groupName]     // eslint-disable-line
      )

  const fetchServiceOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return axios.get(`${API_ENDPOINT}api/services-load-options/?${searchParams.toString()}`,
       {
        headers: {
          Authorization: API_TOKEN,
        }
      })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const toggle = () => {
    //
    setEditAuthGroupModal(!editAuthGroupModal);
    //setStatus(!status);

  };
  const confirmation = () => {
    updateGroupCall()
    setEditAuthGroupModal(!editAuthGroupModal)
    setReload(reload + 1);
  }


  return (
    <Modal
      isOpen={editAuthGroupModal}
      toggle={toggle}
      size="md"
      scrollable={true}>
      <ModalHeader
        tag="h4"
        cssModule={{ "modal-title": "w-100 text-center" }}
        toggle={toggle}
      >
        Edit Group{" "}
      </ModalHeader>
      <ModalBody className="overflow-modal disable-scrollbars">
        {/* {spinner ? <Spinner /> : ""} */}
        <FormGroup>
          <Row>

            <Col sm={6}>
              <Label>
                Group Name:<span className="required">*</span>
              </Label>

              <Input
                onChange={(e) => {
                  setGroupName(e.target.value);
                }}
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="name"
                placeholder="Search with at least 4 characters."
                value={groupName}
                validate={{
                  maxLength: { value: 120 },
                }}
              //onInputChange={handleInputChange}
              //onChange={handleCategoryChange}
              //loadOptions={(inputValue) => categoryloadOptions(inputValue)}
              />
            </Col>
            <Col sm={6}>
              <Label>
                Group Email:<span className="required">*</span>
              </Label>

              <Input
                //     onChange={(e)=>{
                //         setGroupEmail(e.target.value);
                //    }}
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="name"
                placeholder="Search with at least 4 characters."
                value={groupEmail}
                validate={{
                  maxLength: { value: 120 },
                }}
                disabled={true}
              //onInputChange={handleInputChange}
              //onChange={handleCategoryChange}
              //loadOptions={(inputValue) => categoryloadOptions(inputValue)}
              />
            </Col>

            <Col sm={6}>
              <div className="mt-3">
                <Label>
                  Service Name:<span className="required">*</span>
                </Label>

                <AsyncSelect
                  cacheOptions
                  isSearchable
                  type="text"
                  name="name"
                  placeholder="Search with at least 4 characters."
                  value={services}
                  validate={{
                    maxLength: { value: 120 },
                  }}
                  isMulti
                  loadOptions={(inputValue) => fetchServiceOptions(inputValue)}
                  onInputChange={handleInputChange}
                  onChange={handleServiceNameChange}
                //loadOptions={(inputValue) => categoryloadOptions(inputValue)}
                />
              </div>
            </Col>


            {/* <Col sm={6}>
              <div className="mt-4 ml-3">
                <Label>
                  Group Status:<span className="required">*</span>
                </Label>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  value={groupStatus}
                  onToggle={() => {
                    setGroupStatus(!groupStatus)
                  }}

                />
              </div>
            </Col> */}

          </Row>
          <br />
        </FormGroup>
      </ModalBody>
      <ModalFooter className="advanced-search-button custom-modal-footer">
        <Button className="yes-button button-width"
          onClick={() => {
            confirmation()
          }}
          disabled={updateButton} > Update</Button>
        <Button outline className="no-button button-width" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>

  )
}
export default EditAuthGroupModal;