import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';



const EditReferralFulfillerModal = (props) => {
    const {
        editReferralID,
        ReferralFulfillerEditModal,
        setReferralFulFillerEditModal,
        editAppointmentSMSNote,
        editDataExchangeEnabled,
        editSubscription,
        editRecapGenerationEnabled,
        editClinical,
        editTransport,
        editName,
        referralFulfillerReload,
        setReferralFulfillerReload,
        editCategoryType
    } = props;

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");

  const toggle = () => {
    setCategoryType([]);
      setReferralFulFillerEditModal(!ReferralFulfillerEditModal);
  };

  const YesNoOptions = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];

  const SubOptions = [
    { value: 'LITE', label: "Community" },
    {value: 'LITE PLUS', label: "Community Plus" },
    { value: 'FULL', label: "Enterprise" },

]

  //EDIT REFERRAL SENDERS VARIABLES
  const [AppointmentSMSNote, setAppointmentSMSNote] = useState("");
  const [SubscriptionType, setSubscriptionType] = useState("");
  const [dataExchangeEnabled, setdataExchangeEnabled] = useState("");
  const [recapGenerationEnabled, setEditRecapGenerationEnabled] = useState("");
  // const [IsClinical, setIsClinical] = useState("");
  // const [IsTransport, setIsTransport] = useState("");
  const [categoryType, setCategoryType] = useState([]);
  const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);

  const confirmation = () => {
    if (!SubscriptionType) {
      toastr.error("Please Select Subscription Plan Type");
    }
    if (categoryType.length === 0) {
      toastr.error("Please Select Category");
    }

    if (SubscriptionType && categoryType.length !== 0) {
      updateReferralFulfillerApiCall();
    }
  };

  const updateReferralFulfillerApiCall = () => {
    if (categoryType) {
      var categoryTypesResult = categoryType.map((item) => {
        return item.value.toString();
      });
    }

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-referral-fulfiller/`,
        {
          referral_order_fulfiller_id: editReferralID,
          subscription_type: SubscriptionType ? SubscriptionType.label : "",
          appointment_sms_note: AppointmentSMSNote ? AppointmentSMSNote : "",
          data_exchange_enabled: Number(dataExchangeEnabled.value),
          recap_generation_enabled: Number(recapGenerationEnabled.value),
          // "is_clinical": Number(IsClinical.value),
          // "is_transport": Number(IsTransport.value),
          is_clinical: 1,
          is_transport: 0,
          category_type: categoryTypesResult.sort(),
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setReferralFulFillerEditModal(!ReferralFulfillerEditModal);
        toastr.success(
          "Successfully Updated",
          "Referral Fulfiller successfully updated"
        );
        setReferralFulfillerReload(referralFulfillerReload + 1);
      })
      .catch(function (error) {
        toastr.error("Update Failed", "Please try again");
      });
  };
  const categoryTypePopulate = () => {
    var array = [];
    // const data = editCategoryType
    if (editCategoryType.length > 0) {
      editCategoryType.map((id) => {  // eslint-disable-line
        categoryTypeOptions.map((item) => { // eslint-disable-line
          if (id.toString() === item.value.toString()) {
            array.push({
              value: item.value,
              label: item.label,
            });
          }
        });
      });
    }
    setCategoryType(array);
  };
  const Populate = () => {
    var array = YesNoOptions;
    if (editRecapGenerationEnabled !== null) {
      array.forEach((item) => {
        if (item.value.toString() === editRecapGenerationEnabled.toString()) {
          setEditRecapGenerationEnabled(item);
        }
      });
    };

    var array2 = YesNoOptions;
    if (editDataExchangeEnabled !== null) {
      array2.forEach((item) => {
        if (item.value.toString() === editDataExchangeEnabled.toString()) {
          setdataExchangeEnabled(item);
        }
      });
    };

    // var array3 = YesNoOptions
    // array3.map((item) => {       // eslint-disable-line
    //     if (item.value.toString() == editClinical) { // eslint-disable-line
    //         setIsClinical(item)
    //     }
    // })
    // var array4 = YesNoOptions
    // array4.map((item) => {       // eslint-disable-line
    //     if (item.value.toString() == editTransport) { // eslint-disable-line
    //         setIsTransport(item)
    //     }
    // })

    var array1 = SubOptions;
    array1.map((item) => {  // eslint-disable-line
      if (item.value.toLowerCase() === editSubscription.toLowerCase()) {
        setSubscriptionType(item);
      }
    });

    setAppointmentSMSNote(editAppointmentSMSNote);
  };

  useEffect(() => {
    Populate();
  }, [editAppointmentSMSNote,editDataExchangeEnabled,editSubscription,editClinical,editTransport,ReferralFulfillerEditModal,]); // eslint-disable-line

  const fetchCategoryTypeOptions = () => {
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/get-category-type-options/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCategoryTypeOptions(response.data.data);
        }
      })
      // .catch((error) => {
      //   console.log(error);
      // });
  };
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if(ReferralFulfillerEditModal === true && categoryTypeOptions.length == 0){
      fetchCategoryTypeOptions();
    }
    if (categoryTypeOptions) {
      // console.log("catTypeOptions", categoryTypeOptions);
      categoryTypePopulate();
    }
  }, [ReferralFulfillerEditModal, categoryTypeOptions]); // eslint-disable-line

  return (
    <div>
      <Modal isOpen={ReferralFulfillerEditModal} size="md">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit - {editName ? editName : "N/A"}{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          <FormGroup>
            <Row>
              <Col>
                <Label for="name">
                  Group Name <span className="required">*</span>{" "}
                </Label>
                <Input
                  id="name"
                  value={editName}
                  disabled={true}
                  placeholder="Enter the Name"
                  /*  onChange={(e) => setEditNa(e.target.value)} */
                  minLength="1"
                  maxLength="120"
                />
                <br></br>
                <Label for="name">Appointment SMS Note </Label>
                <Input
                  id="name"
                  value={AppointmentSMSNote ? AppointmentSMSNote : ""}
                  placeholder="Enter the Note"
                  onChange={(e) => setAppointmentSMSNote(e.target.value)}
                  minLength="1"
                  maxLength="120"
                />
                <br></br>
                <Label for="name">Data Exchange Enabled </Label>
                <Select
                  options={YesNoOptions}
                  onChange={(event) => setdataExchangeEnabled(event)}
                  value={dataExchangeEnabled}
                  placeholder="Select"
                  isClearable
                />
                <br></br>
                <Label for="name">
                  Subscription Type <span className="required">*</span>{" "}
                </Label>
                <Select
                  options={SubOptions}
                  onChange={(e) => setSubscriptionType(e)}
                  value={SubscriptionType}
                  placeholder="Select"
                  isClearable
                />
                <br></br>
                <Label for="name">Recap Generation Enabled </Label>
                <Select
                  options={YesNoOptions}
                  onChange={(event) => setEditRecapGenerationEnabled(event)}
                  value={recapGenerationEnabled}
                  placeholder="Select"
                  isClearable
                />
                <br></br>
                <Label for="name">
                  Category<span className="required">*</span>
                </Label>

                <Select
                  isMulti
                  options={categoryTypeOptions}
                  onChange={(event) => setCategoryType(event)}
                  value={categoryType}
                  placeholder="Select"
                  isClearable
                />
                <br></br>
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button className="yes-button button-width" onClick={confirmation}>
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditReferralFulfillerModal;
