import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Input,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import { GlobalState } from '../../contextAPI/Context';
import FaxLogFilters from "../../newComponents/utilities/FaxLogFilters"

const FaxLogDataTable = (props) => {

  const { blankStateMessage } = useContext(GlobalState)
  // LOCAL VARIABLES 
  const {
    totalSize,
    onTableChange,
    exportTableData,
    fetchFaxLogList,
    setOffset,
    setSortColumn,
    searchKeyword,
    setSearchKeyword,
    faxReload,
    setFaxReload
  } = props
  const [numberOfRecords, setNumberOfRecords] = useState(0)

  const MyExportCSV = (props) => {
    const handleClick = () => {
      exportTableData();
    };
    return (
      <div>
        <button className="btn btn-export mt-2 ml-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No data available in table</h3>
      </CardBody>
    </Card>
  );

  const options = {
    page: props.page,
    nextPageText: 'Next',
    prePageText: 'Previous',
    sizePerPage: props.sizePerPage,
    sizePerPageList: [25, 50, 150, 200],
    totalSize
  };


  useEffect(() => { setNumberOfRecords(totalSize) }, [totalSize]);
  return (
    <div className="physicians_table patient_table table-list-height">
      <Card>
        <ToolkitProvider
          keyField="id"
          data={props.data}
          columns={props.columns}
          search
          exportCSV={{
            fileName: `Equipo-Fax-Log-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
          }}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col>
                    <FaxLogFilters fetchFaxLogList={fetchFaxLogList} setOffset={setOffset} setSortColumn={setSortColumn} />
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <div className="number-of-records">
                      <p>Number of records: <strong>{numberOfRecords}</strong></p>
                    </div>
                  </Col>
                  <Col>
                    <div className="search-order-serial-number"></div>
                    <div className='c-search-field form-inline justify-content-end'>
                      <div className="form-group mr-2">
                        <Input
                          id="num"
                          autoFocus="autofocus"
                          value={searchKeyword}
                          placeholder="Search"
                          onChange={(e) => {
                            setSearchKeyword(e.target.value);
                          }}
                        />
                      </div>
                      <Button
                        className="clear-search-box"
                        onClick={() => {
                          setSearchKeyword("");
                          setFaxReload(faxReload + 1);
                        }}
                      >
                        Clear
                      </Button>
                      <div className="export-button">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="separator" />
                <div className="float-right pull-right">
                </div>
              </CardHeader>
              <CardBody className="patient-list">
                {blankStateMessage ? blankState : ""}
                {!blankStateMessage &&
                  <BootstrapTable
                    {...props.baseProps}
                    remote
                    bootstrap4
                    hover
                    pagination={paginationFactory(options)}
                    onTableChange={onTableChange}

                  />}
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </div>
  );
};

export default FaxLogDataTable;