import React, { useState, useEffect, useContext } from "react";
import { GlobalState } from "../../contextAPI/Context";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import styles from "./ProviderDetails.module.css";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import _ from "lodash"; // eslint-disable-line
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import ToggleButton from "react-toggle-button";
import Cookie from 'js-cookie';

const NewAppUserModal = (props) => {
  const { setNewAppUserModal, newAppUserModal } = props;

  // CONTEXT VARIABLES
  const {
    roleListForDropDown,
    appUserReload,
    setAppUserReload,
    countryCodeList,
    groupNamesForDropDown,
  } = useContext(GlobalState);

  const {
    signature,
    setSignature
  } = props

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const regXForNubers = /^[0-9]*$/;
  const regXForNames = /^[A-Za-z0-9 ]*$/;
  const regXForUserName = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]*$/; // eslint-disable-line
  const regXForEmail = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"); //eslint-disable-line
  var emailArray = [];
  var temp = [];
  const [newAppUserName, setNewAppUserName] = useState("");
  const [newFirstName, setNewFirstName] = useState("");
  const [newMiddleName, setNewMiddleName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newAppUserType, setAppUserType] = useState("");
  const [newAppUserDesignation, setAppUseDesignation] = useState("");
  const [newAppUserNPI, setAppUserNPI] = useState("");
  const [newAppUserEmail, setAppUseEmail] = useState("");
  const [newAppUserUserName, setAppUseUserName] = useState("");
  const [newAppUserGroupID, setAppUseGroupID] = useState("");
  const [newAppUserPassword, setAppUsePassword] = useState("");
  const [newCAppUserPassword, setCAppUsePassword] = useState("");
  const [newAppUserSSN, setAppUserSSN] = useState("");
  const [newAppUserPhone, setAppUserPhone] = useState("");
  const [newAppUserIsServiceProvider, setAppUserIsServiceProvider] = useState("");
  const [newAppUserNotificationEmail, setAppUserNotificationEmail] = useState("");
  const [newAppUserSecurityID, setAppUserSecurityID] = useState("");
  const [newAppUserSecurityEnable, setAppUserSecurityEnable] = useState(false);
  const [newAppUserSecurityOpts, setAppUserSecurityOpts] = useState("");
  const [addButtonStatus, setAddButtonStatus] = useState(true);
  const [npiInputStatus, setNPIInputStatus] = useState(false);
  const [newAppUserZIP, setAppUseZIP] = useState("");
  const [externalUserId, setExternalUserId] = useState("");
  const [newState, setNewState] = useState("");
  const [newCity, setNewCity] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [optForCountryCode, setOptForCountryCode] = useState("");
  const [arrayOfZipCodes, setArrayOfZipCodes] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [cPasswordShown, setCPasswordShown] = useState(false);
  const [spinner, setSpinner] = useState(false); //eslint-disable-line
  const [mailVerificationToggle, setMailVerificationToggle] = useState(true);
  const [restricPatientAccessToggle, setRestrictPatientAccessToggle] = useState(false);
  const [enableEmailDigestToggle, setEnableEmailDigestToggle] = useState(false);
  const [externalModelEnabled, setExternalModelEnabled] = useState(false);
  const [externalModelOptions, setEexternalModelOptions] = useState([ // eslint-disable-line
    {
      "value": 1,
      "label": "Clinical Deterioration"
    },
    {
      "value": 2,
      "label": "Malnutrition"
    },
    {
      "value": 3,
      "label": "Suicide Prevention"
    },
    {
      "value": 4,
      "label": "Fall Risk"
    }
  ]);
  const [externalModelSelected, setExternalModelSelected] = useState([]);
  const eyeForPassword = (<FontAwesomeIcon icon={passwordShown ? faEyeSlash : faEye} />);
  const eyeForConfirmPassword = (<FontAwesomeIcon icon={cPasswordShown ? faEyeSlash : faEye} />);
  const toggle = () => {
    setNewAppUserModal(!newAppUserModal);
    setNewAppUserName("");
    setAppUserType("");
    setAppUseDesignation("");
    setAppUserNPI("");
    setAppUseEmail("");
    setAppUseUserName("");
    setAppUseGroupID("");
    setAppUsePassword("");
    setAppUseZIP("");
    setAppUserSSN("");
    setAppUserPhone("");
    setAppUserIsServiceProvider("");
    setAppUserSecurityID("");
    setNewFirstName("");
    setNewMiddleName("");
    setNewLastName("");
    setNewAppUserName("");
    setAppUserSecurityEnable(false);
    setMailVerificationToggle(true);
    setRestrictPatientAccessToggle(false)
    setEnableEmailDigestToggle(false)
    setExternalModelEnabled(false)
    setExternalUserId("")
  };

  const IsServiceProvider = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];
  const styleForEyePassword = {
    background: "#ffffff",
    display: "flex",
    position: "absolute",
    bottom: "25%",
    right: "5%",
    opacity: "90%",
  };

  const CreateAppUserAPICall = () => {
    // Is AI model enabled clicked but not selected options
    // then show error
    if (externalModelEnabled && externalModelSelected.length === 0) {
      return toastr.warning("Please select AI model options")
    }

    let externalModelArray = externalModelSelected?.flatMap(item => item.label)
    var temp = [];
    var final_email_array = [];
    if (Number(newAppUserType.value) !== 1) {
      setAppUserNotificationEmail("");
    }
    if (typeof newEmail == "object") {
      temp = newAppUserNotificationEmail;
    } else {
      temp = newAppUserNotificationEmail.split(",");
    }

    if (temp) {
      temp.map(
        (
          item //eslint-disable-line
          // eslint-disable-next-line array-callback-return
        ) => {
          final_email_array.push(item.trim());
        }
      );
    }
    const result = newAppUserGroupID.map((item) => {
      return item.value.toString();
    });
    if (newCAppUserPassword !== newAppUserPassword) {
      toastr.error("Password and Confirm Password are not matching.");
    } else {
      const params = {
        app_user_name: newAppUserName ? newAppUserName.toString() : "",
        firstName: newFirstName ? newFirstName.toString() : "",
        middleName: newMiddleName ? newMiddleName.toString() : "",
        lastName: newLastName ? newLastName.toString() : "",
        app_user_type: newAppUserType ? Number(newAppUserType.value) : 0,
        app_user_designation: newAppUserDesignation ? newAppUserDesignation : "",
        app_user_npi: newAppUserNPI ? newAppUserNPI.toString() : "",
        app_user_email: newAppUserEmail ? newAppUserEmail.toString() : "",
        app_user_status: "1",
        app_user_username: newAppUserUserName ? newAppUserUserName.toString() : "",
        app_user_group_id: result ? result : "",
        password: newAppUserPassword,
        Zip: newAppUserZIP ? newAppUserZIP.value : "",
        Ssn: newAppUserSSN,
        phone: newAppUserPhone ? newAppUserPhone + '' : 0,
        is_service_provider: newAppUserIsServiceProvider ? Number(newAppUserIsServiceProvider.value) : 0,
        user_security_id: newAppUserSecurityID ? newAppUserSecurityID.value.toString() : "",
        country_code: countryCode ? countryCode.label.toString() : "",
        notificationEmail: newAppUserNotificationEmail ? final_email_array : [],
        mailVerificationToggle: mailVerificationToggle ? 1 : 0,
        isRestrictedPatientAccess: restricPatientAccessToggle ? 1 : 0,
        isenableEmailDigest: enableEmailDigestToggle ? 1 : 0,
        app_user_signature: signature ? signature : "",
        isExternalModelEnabled: externalModelEnabled,
        externalModel: (externalModelSelected.length > 0) ? externalModelArray : "",
        externalUserId: externalUserId ? Number(externalUserId) : ""
      };
      AxiosInter
        .post(`${API_ENDPOINT}/admin-api/create-app-user/`, params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then(function (response) {
          if (response.data.message !== null) {
            toastr.success(
              "Success",
              `App User successfully created ${response.data.message}`
            );
          } else {
            toastr.success("Success", "App User successfully created");
          }

          setAppUserReload(appUserReload + 1);
          toggle();
        })
        .catch(function (error) {
          if (error.response && error.response.data) {
            const errorMessage = error.response.data.message || error.response.data;
            toastr.error("Failed", errorMessage);
          } else {
            toastr.error("Failed", "An error occurred.");
          }
        });

    }
  };

  const disableAddButton = () => {

    if (
      newAppUserName &&
      newFirstName &&
      newLastName &&
      newAppUserEmail &&
      newAppUserUserName &&
      newAppUserPassword &&
      newCAppUserPassword &&
      newAppUserGroupID &&
      newAppUserSecurityID &&
      (newAppUserType !== "" ? newAppUserType : false) &&
      (npiInputStatus ? newAppUserNPI : true)
    ) {
      setAddButtonStatus(false);
    } else {
      setAddButtonStatus(true);
    }
  };
  const changeOnEmail = (e) => {
    setAppUserNotificationEmail(e);
  };
  const onSaveClick = () => {
    setSpinner(true)
    if (Number(newAppUserType.value) !== 1) {
      emailArray = [];
    }
    if (newAppUserNotificationEmail) {
      if (typeof newAppUserNotificationEmail == "object") {
        emailArray = newAppUserNotificationEmail;
      } else {
        emailArray = newAppUserNotificationEmail.split(",");
      }
      if (emailArray.length > 0) {
        emailArray.map(
          (
            item //eslint-disable-line
            // eslint-disable-next-line array-callback-return
          ) => {
            if (regXForEmail.test(item.trim()) === false) {
              temp.push(false);
            } else {
              temp.push(true);
            }
          }
        );
      }
    } else {
    }

    CreateAppUserAPICall();
    setSpinner(false)
  };

  const SecurityGroup = () => {
    let optns;
    if (Object.keys(newAppUserGroupID).length === 1) {
      optns = newAppUserGroupID.map((item) => {
        return item;
      });
      setAppUserSecurityID(newAppUserGroupID[0]);
    } else {
      optns = newAppUserGroupID.map((item) => {
        return item;
      });
    }
    setAppUserSecurityOpts(optns);
    setAppUserSecurityEnable(true);
  };

  const createCountryCodeOption = () => {
    if (countryCodeList) {
      var array = countryCodeList;
      const options = array.map((item) => {
        return {
          value: item.id,
          label: item.country_isd_code,
        };
      });
      setOptForCountryCode(options);
    }
  };

  const loadOptionsForZipcode = async (inputValue) => {
    let options = [];
    if (inputValue.length >= 4) {
      try {
        const response = await AxiosInter.get(`${API_ENDPOINT}/admin-api/get-zip-code/${inputValue}`, {
          headers: {
            Authorization: API_TOKEN,
          },
        });
        const data = response.data.data;
        if (data.length > 0) {
          var array = data;
          // eslint-disable-next-line array-callback-return
          array.map((item) => {
            options.push({
              value: item.id,
              label: item.zipcode,
            });
          });
        }
        setArrayOfZipCodes(data);
        return options;
      }
      catch (error) {
        return options;
      }
    }
  };

  const populateName = () => {
    // eslint-disable-next-line array-callback-return, no-unused-vars

    // eslint-disable-line

    if (newFirstName || newLastName) {
      if (newLastName == null) {
        setNewLastName("");

        const flname = `${newFirstName} ${newLastName}`;
        setNewAppUserName(flname);
      }
      else {
        const flname = `${newFirstName} ${newLastName}`;
        setNewAppUserName(flname);
      }
    }
  };
  useEffect(() => {
    if (newFirstName || newLastName) {
      populateName();
    }
    else {
      if (newAppUserName == null || newAppUserName.length <= 2) {
        setNewAppUserName("");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newFirstName, newLastName]);

  const populateStateAndCountry = () => {
    if (arrayOfZipCodes) {
      var array = arrayOfZipCodes;
      // eslint-disable-next-line no-unused-vars
      const opt = array.map(
        (
          item //eslint-disable-line
          // eslint-disable-next-line array-callback-return
        ) => {
          // eslint-disable-line

          if (newAppUserZIP.value === item.id) {
            setNewCity(item.city);
            setNewState(item.stateFullName);
          }
        }
      );
    }
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleCPasswordVisiblity = () => {
    setCPasswordShown(cPasswordShown ? false : true);
  };

  const changeOnFirstName = (e) => {
    if (regXForNames.test(e) === false) {
      toastr.warning("Invalid Entry", "Special characters are not allowed");
    } else {
      setNewFirstName(e);
    }
  };
  const changeOnLastName = (e) => {
    if (regXForNames.test(e) === false) {
      toastr.warning("Invalid Entry", "Special characters are not allowed");
    } else {
      setNewLastName(e);
    }
  };
  const changeOnMiddleName = (e) => {
    if (regXForNames.test(e) === false) {
      toastr.warning("Invalid Entry", "Special characters are not allowed");
    } else {
      setNewMiddleName(e);
    }
  };
  const changeOnUserName = (e) => {
    if (regXForUserName.test(e) === false) {
      toastr.warning("Invalid Entry", "Provide a proper entry");
    } else {
      setAppUseUserName(e);
    }
  };

  const onChangeForNPINumber = (e) => {
    if (regXForNubers.test(e) === false) {
      toastr.warning("Invalid NPI Number", "alphabets are not allowed");
    } else {
      setAppUserNPI(e);
    }
  };
  const onChangeZipcode = (value) => {
    setAppUseZIP(value);
  };

  useEffect(() => {
    if (newAppUserZIP) {
      populateStateAndCountry();
    } else {
      setNewCity("");
      setNewState("");
    }
  }, [newAppUserZIP]); // eslint-disable-line

  useEffect(() => {
    if (countryCodeList) createCountryCodeOption();
  }, [countryCodeList]); // eslint-disable-line

  useEffect(() => {
    disableAddButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    newFirstName,
    newLastName,
    newAppUserDesignation,
    newAppUserEmail,
    newAppUserUserName,
    newAppUserPassword,
    newCAppUserPassword,
    newAppUserGroupID,
    newAppUserSecurityID,
    npiInputStatus,
    newAppUserNPI,
    newAppUserType,
    externalUserId
  ]); // eslint-disable-line

  useEffect(() => {
    if (newAppUserGroupID) SecurityGroup();
  }, [newAppUserGroupID]); // eslint-disable-line

  useEffect(() => {
    if (newAppUserType) {
      // eslint-disable-next-line eqeqeq
      if (newAppUserType.value == 1) {
        // eslint-disable-line
        setNPIInputStatus(true);
      } else {
        setNPIInputStatus(false);
      }
    } else {
      setNPIInputStatus(false);
    }
  }, [newAppUserType]); // eslint-disable-line
  const userType = newAppUserType ? Number(newAppUserType.value) : 0;
  const minLength = 3;

  const customNoOptionsMessage = ({ inputValue }) => {
    if (inputValue.length < minLength) {
      return "Please enter at least 4 letters to search";
    } else {
      return "No option";
    }
  };

  useEffect(() => {
    if (externalModelEnabled === false) {
      setExternalModelSelected([])
    }
  }, [externalModelEnabled])

  return (
    <div>
      <Modal
        isOpen={newAppUserModal}
        size="md"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Add New App User{" "}
        </ModalHeader>
        <ModalBody className="disable-scrollbars">
          <FormGroup>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    Group <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Select
                    options={groupNamesForDropDown}
                    onChange={(event) => {
                      setAppUseGroupID(event)

                    }}
                    value={newAppUserGroupID}
                    placeholder="Select Group"
                    isSearchable
                    isClearable
                    isMulti
                    menuShouldBlockScroll={true}
                  />
                </div>
              </Col>
            </Row>
            {newAppUserSecurityEnable ? (
              <>
                <Row className={styles.detailProfRow}>
                  <Col md={3} sm={5}>
                    <div className={styles.detailProfLabel}>
                      <Label>
                        Security Group <span className="required">*</span>
                      </Label>
                    </div>
                  </Col>
                  <Col md={9} sm={7}>
                    <div className={styles.detailProfValue}>
                      <Select
                        options={newAppUserSecurityOpts}
                        onChange={(event) => setAppUserSecurityID(event)}
                        value={newAppUserSecurityID}
                        placeholder="Select Security Group"
                        isSearchable
                        isClearable
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    First Name <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="name"
                    value={newFirstName}
                    placeholder="Enter the First name"
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        changeOnFirstName(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Middle Name</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="mname"
                    value={newMiddleName}
                    placeholder="Enter the Middle name"
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        changeOnMiddleName(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    Last Name <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="lname"
                    value={newLastName}
                    placeholder="Enter the Last name"
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        changeOnLastName(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    Name <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="name"
                    value={newAppUserName}
                    placeholder="Enter name"
                    disabled
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        setNewAppUserName(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Type<span className="required">*</span>

                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Select
                    options={roleListForDropDown}
                    onChange={(event) => setAppUserType(event)}
                    value={newAppUserType}
                    placeholder="Select Type"
                    isSearchable
                    isClearable
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    User Name <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="user name "
                    value={newAppUserUserName}
                    placeholder="Enter the User Name "
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        changeOnUserName(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    Signature
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="sign "
                    value={signature}
                    placeholder="Enter the Signature "
                    onChange={(e) => {
                      setSignature(e.target.value)
                    }}
                  />
                </div>
              </Col>
            </Row>
            {npiInputStatus ? (
              <>
                <Row className={styles.detailProfRow}>
                  <Col md={3} sm={5}>
                    <div className={styles.detailProfLabel}>
                      <Label>
                        NPI <span className="required">*</span>
                      </Label>
                    </div>
                  </Col>
                  <Col md={9} sm={7}>
                    <div className={styles.detailProfValue}>
                      <Input
                        id="NPI"
                        value={newAppUserNPI}
                        placeholder="Enter the NPI"
                        onChange={(e) => {
                          if (e.target.value.length <= 150)
                            onChangeForNPINumber(e.target.value);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    Email <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="email"
                    value={newAppUserEmail}
                    placeholder="Enter the Email"
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        setAppUseEmail(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    Password <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="Password"
                    type={passwordShown ? "text" : "password"}
                    value={newAppUserPassword}
                    placeholder="Enter the Password"
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        setAppUsePassword(e.target.value);
                    }}
                  />
                  <i
                    style={styleForEyePassword}
                    onClick={togglePasswordVisiblity}
                  >
                    {eyeForPassword}
                  </i>{" "}
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={6}>
                <div className={styles.detailProfLabel}>
                  <Label className="c-pass-label">
                    Confirm Password <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={5}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="CPassword"
                    type={cPasswordShown ? "text" : "password"}
                    value={newCAppUserPassword}
                    placeholder="Enter Password again"
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        setCAppUsePassword(e.target.value);
                    }}
                  />
                  <i
                    style={styleForEyePassword}
                    onClick={toggleCPasswordVisiblity}
                  >
                    {eyeForConfirmPassword}
                  </i>{" "}
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    External User Id
                    {/* <span className="required">*</span> */}
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                <Input
                    id="externaluserid"
                    value={externalUserId}
                    placeholder="Enter the External User Id"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Check if the input consists only of digits or is empty (to allow deletion)
                      const isDigitsOnlyOrEmpty = /^\d*$/.test(inputValue);
                      // Check if the input length does not exceed 15 characters
                      const isLengthValid = inputValue.length <= 10;

                      const num = parseInt(inputValue, 10);
                      const isValueValid = inputValue === '' || (!isNaN(num) && num <= 2147483647);

                      if (!isDigitsOnlyOrEmpty) {
                        toastr.warning("Please enter digits only");
                      } else if(!isValueValid) {
                        toastr.warning("Maximum value exceeded");
                      }
                      else if (!isLengthValid) {
                        toastr.warning("Limit Exceeded");
                      } 
                      else {
                        setExternalUserId(inputValue);
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Country Code</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Select
                    options={optForCountryCode}
                    onChange={(event) => setCountryCode(event)}
                    value={countryCode}
                    placeholder="Country code"
                    isSearchable
                    isClearable
                    className="Select-menu-outer"
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Phone</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="phone"
                    value={newAppUserPhone}
                    type="Number"
                    placeholder="Enter the Phone Number"
                    onChange={(e) => {
                      if (e.target.value.length > 11) {
                        toastr.warning("Invalid Phone number", "Phone number length exceed");
                      }
                      setAppUserPhone(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Is service Provider</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Select
                    options={IsServiceProvider}
                    onChange={(event) => setAppUserIsServiceProvider(event)}
                    value={newAppUserIsServiceProvider}
                    placeholder="Is Service Provider"
                    isSearchable
                    isClearable
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Zip Code</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <AsyncSelect
                    cacheOptions
                    isClearable
                    isSearchable
                    noOptionsMessage={customNoOptionsMessage}
                    value={newAppUserZIP}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    loadOptions={loadOptionsForZipcode}
                    onChange={onChangeZipcode}
                    placeholder="Type zip code for options"
                  />
                </div>
              </Col>
            </Row>
            {newState && newCity ? (
              <>
                <Row className={styles.detailProfRow}>
                  <Col md={3} sm={5}>
                    <div className={styles.detailProfLabel}>
                      <Label>State:</Label>
                    </div>
                  </Col>
                  <Col md={9} sm={7}>
                    <div className={styles.detailProfValue}>
                      <Input id="State" value={newState} disabled />
                    </div>
                  </Col>
                </Row>

                <Row className={styles.detailProfRow}>
                  <Col md={3} sm={5}>
                    <div className={styles.detailProfLabel}>
                      <Label>City:</Label>
                    </div>
                  </Col>
                  <Col md={9} sm={7}>
                    <div className={styles.detailProfValue}>
                      <Input id="city" value={newCity} disabled />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>SSN</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="SSN"
                    value={newAppUserSSN}
                    placeholder="Enter the SSN"
                    onChange={(e) => {
                      if (e.target.value.length <= 20)
                        setAppUserSSN(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Allow user creation email</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <ToggleButton
                    inactiveLabel={"OFF"}
                    activeLabel={"ON"}
                    value={mailVerificationToggle}
                    onToggle={() => {
                      setMailVerificationToggle(!mailVerificationToggle)
                    }}
                  />
                </div>
              </Col>

            </Row>
            <Row>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Restrictive Patient Access</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <ToggleButton
                    inactiveLabel={"OFF"}
                    activeLabel={"ON"}
                    value={restricPatientAccessToggle}
                    onToggle={() => {
                      setRestrictPatientAccessToggle(!restricPatientAccessToggle)
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Enable Email Digest</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <ToggleButton
                    inactiveLabel={"OFF"}
                    activeLabel={"ON"}
                    value={enableEmailDigestToggle}
                    onToggle={() => {
                      setEnableEmailDigestToggle(!enableEmailDigestToggle)
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Is AI model enabled</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <ToggleButton
                    inactiveLabel={"OFF"}
                    activeLabel={"ON"}
                    value={externalModelEnabled}
                    onToggle={(e) => {
                      setExternalModelEnabled(!externalModelEnabled)
                    }}
                  />
                </div>
              </Col>
            </Row>
            {externalModelEnabled && <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Select Model</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Select
                    options={externalModelOptions}
                    onChange={(event) => {
                      setExternalModelSelected(event)

                    }}
                    value={externalModelSelected}
                    placeholder="Select Model"
                    isSearchable
                    isClearable
                    isMulti
                    menuShouldBlockScroll={true}
                  />
                </div>
              </Col>
            </Row>}
            <div>
              {userType === 1 ? (
                <>
                  <Row className={styles.detailProfRow}>
                    <Col md={3} sm={5}>
                      <div className={styles.detailProfLabel}>
                        <Label> Notification Email :</Label>
                      </div>
                    </Col>
                    <Col md={9} sm={5}>
                      <div className={styles.detailProfValue}>
                        <Input
                          placeholder="Emails (demo1@xyz.com,demo2@xyz.com)"
                          value={newAppUserNotificationEmail}
                          onChange={(e) => {
                            changeOnEmail(e.target.value);
                          }}
                        />

                        {newAppUserNotificationEmail === 0 ? (
                          <>
                            <div
                              style={{ float: "left", marginTop: "0px" }}
                              className="warning-label"
                            >
                              <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                              <Label className="warning-text">
                                Enter atleast 1 email
                              </Label>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button
            className="yes-button button-width"
            onClick={() => onSaveClick()}
            disabled={addButtonStatus}
            title={addButtonStatus ? "Fill the required fields" : ""}
          >
            Add
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewAppUserModal;