import React from "react";
import { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Hux } from "../../hoc/Hux";
import {
  
  Container,
  Breadcrumb,
  BreadcrumbItem,
 
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import AxiosInter from './../../AxiosInter.js';


function FacilityMap() {
  // eslint-disable-next-line no-unused-vars
  const [facilityMarker, setFacilityMarker] = useState(null);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  
  const [items, setItems] = useState([]);
  const fetchURL = `${API_ENDPOINT}/admin-api/facility-location/`;
  const getItems = () =>
    AxiosInter.get(fetchURL, {
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => res.data);

  useEffect(() => {
  
    getItems().then((data) => setItems(data.data.features));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    
     <Hux>
     <Container fluid>
     <Header>
     <HeaderTitle>
      Facility Locations
     </HeaderTitle>
     <Breadcrumb>
     <BreadcrumbItem>
     <Link to="/dashboard">Dashboard</Link>
     <BreadcrumbItem active>Facility Locations</BreadcrumbItem>
     </BreadcrumbItem>

     </Breadcrumb>
      </Header>
    
     <>
      <MapContainer
        //center={[30.97653406591645, -120.55680864667518]}
        center={[28.6138954, -77.2090057]}
        zoom={5}
        scrollWheelZoom={false}
        className="facility-map"
      >
        {/* <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        /> */}
          {/* <TileLayer
          url="https://gibs-{s}.earthdata.nasa.gov/wmts/epsg3857/best/BlueMarble_ShadedRelief_Bathymetry/default//EPSG3857_500m/{z}/{y}/{x}.jpeg"
          attribution="&copy; NASA Blue Marble, image service by OpenGeo"
          maxNativeZoom={8}
        /> */}
        {/* <TileLayer
        url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        maxZoom={20}
      /> */}
       {/* <TileLayer
        attribution="Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community"
        className="basemap"
        maxNativeZoom={19}
        maxZoom={19}
        subdomains={["clarity"]}
        url="https://{s}.maptiles.arcgis.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      /> */}
       <TileLayer
        url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"
        attribution='&copy; OpenStreetMap France | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        maxZoom={20}
      />
        {items.map((facility) => (
          <Marker
            //key={facility.properties.name}
            position={[
              facility.geometry.coordinates[1],
              facility.geometry.coordinates[0],
            ]}
            onClick={() => {
              
              setFacilityMarker(facility);
            }}
            // icon={FaMapMarkerAlt}
          >
            <Popup
              key={facility.properties.facilityName}
              position={[
                facility.geometry.coordinates[1],
                facility.geometry.coordinates[0],
              ]}
            >
              <>
                <h2>{facility.properties.facilityName}</h2>
              </>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      </>
      </Container>
      </Hux> 
   
  );
}

export default FacilityMap;
