import React, { useState, useEffect, useContext, useMemo } from "react";
import { GlobalState } from "../../contextAPI/Context";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import styles from "./ProviderDetails.module.css";
import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Cookie from 'js-cookie';
import _ from "lodash";
import ToggleButton from "react-toggle-button";

const EditAppUserModal = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [spinner, setSpinner] = useState(false);

  var emailArray = [];
  var temp = [];

  // const regXForEmail = new RegExp("[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]{2,3}")//eslint-disable-line
  const regXForEmail = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"); //eslint-disable-line

  // CONTEXT VARIABLES
  const {
    appUserReload,
    setAppUserReload,
    m_zipList,
    groupList,
    roleListForDropDown,
    groupNamesForDropDown,
    zipCodeOptions,
  } = useContext(GlobalState);
  const {
    editAppUserModal,
    setEditAppUserModal,
    editAppUserName,
    editAppUserType,
    editAppUserDesignation,
    editAppUserNPI,
    editAppUserEmail,
    editAppUserUserName,
    editAppUserGroupID,
    editAppUserZIP,
    editAppUserSSN,
    editAppUserPhone,
    editAppUserIsServiceProvider,
    editAppUserID,
    setEditAppUserName,
    setEditAppUserType,
    setEditAppUseDesignation,
    setEditAppUserNPI,
    setEditAppUseEmail,
    setEditAppUseUserName,
    setEditAppUseGroupID,
    setEditAppUseZIP,
    setEditAppUserSSN,
    setEditAppUserPhone,
    setEditAppUserIsServiceProvider,
    editAppUserSecurityID,
    setEditAppUserSecurityID,
    appUserEditStatus,
    setAppUserEditStatus,
    newnNotificationEmail,
    setNewNotificationEmail,
    setEditLastName,
    editLastName,
    setEditMiddleName,
    editMiddleName,
    setEditFirstName,
    editFirstName,
    userGroupStatus,
    editRestricPatientAccessToggle,
    setEditRestrictPatientAccessToggle,
    editEmailDigestToggle,
    setEditEmailDigestToggle,
    signature,
    setSignature,
    externalModelSelected,
    externalModelEnabled,
    setExternalModelEnabled,
    externalUserId,
    setExternalUserId
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const [newAppUserSecurityEnable, setAppUserSecurityEnable] = useState(false);
  const [optForZip, setOptForZip] = useState(""); // eslint-disable-line
  const [npi, setnpi] = useState("");
  const [npiInputStatus, setNPIInputStatus] = useState(false);
  const [updateButtonStatus, setUpdateButtonStatus] = useState(true);
  const [groupIDs, setGroupsIDs] = useState([]);
  const [zip, setZip] = useState("");
  const [security, setSecurity] = useState([]);
  const [optForGroup, setOptForGroup] = useState(""); // eslint-disable-line
  const [newState, setNewState] = useState("");
  const [newCity, setNewCity] = useState("");
  const [populatedExternalModel, setPopulatedExternalModel] = useState([])
  const [externalModelOptions, setEexternalModelOptions] = useState([ // eslint-disable-line
    {
      "value": 1,
      "label": "Clinical Deterioration"
    },
    {
      "value": 2,
      "label": "Malnutrition"
    },
    {
      "value": 3,
      "label": "Suicide Prevention"
    },
    {
      "value": 4,
      "label": "Fall Risk"
    }
  ]);

  useEffect(() => {
    // console.log(externalModelSelected)
    if (externalModelSelected) {
      let populatedExternalModel = externalModelOptions.filter(eachItem => externalModelSelected.includes(eachItem.label))
      setPopulatedExternalModel(populatedExternalModel)
    }
    else setPopulatedExternalModel([])
  }, [externalModelSelected]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (externalModelEnabled === false) {
      setPopulatedExternalModel([])
    }
  }, [externalModelEnabled])

  const regXForNubers = /^[0-9]*$/;
  const YesNoOptions = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];

  const toggle = () => {
    setSignature('')
    setEditAppUserModal(!editAppUserModal);
    setGroupsIDs("");
    setEditAppUserSecurityID("");
    setEditAppUserName("");
    setEditAppUserType("");
    setEditAppUseDesignation("");
    setEditAppUserNPI("");
    setEditAppUseEmail("");
    setEditAppUseUserName("");
    setEditAppUseGroupID("");
    setEditAppUseZIP("");
    setEditAppUserSSN("");
    setEditAppUserPhone("");
    setEditLastName("");
    setEditFirstName("");
    setEditMiddleName("");
    setEditAppUseUserName("");
    setZip("");
    setSecurity([]);
    setEditAppUserIsServiceProvider("");
    setAppUserSecurityEnable(false);
    setEditRestrictPatientAccessToggle(false)
    setExternalUserId("");
  };

  const minLengthforzip = 4;

  const customNoOptionsMessageforZip = ({ inputValue }) => {
    if (inputValue.length < minLengthforzip) {
      return "Please search with at least 4 characters";
    } else {
      return "No option";
    }
  };
  const createGroupOption = () => {
    if (groupList) {
      var array1 = groupList;
      const options1 = array1.map((item, index) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setOptForGroup(options1);
    }
  };

  const statusOptions = [
    { value: "0", label: `Inactive` },
    { value: "1", label: `Active` },
    { value: "99", label: `Locked` },
  ];

  const setDefaultStatusValue = (appUserEditStatus) => {
    statusOptions.map(
      (
        item // eslint-disable-line
        // eslint-disable-next-line array-callback-return
      ) => {
        if (appUserEditStatus.toString() === item.value) {
          setAppUserEditStatus(item);
        }
      }
    );
  };

  const disableAddButton = () => {
    if (
      editFirstName &&
      editLastName &&
      editAppUserName &&
      editAppUserEmail &&
      editAppUserUserName &&
      groupIDs &&
      security &&
      editAppUserType &&
      (npiInputStatus ? npi : true)
    ) {
      setUpdateButtonStatus(false);
    } else {
      setUpdateButtonStatus(true);
    }
  };

  const updateAppUserApiCall = () => {
    // Is AI model enabled clicked but not selected options
    // then show error
    if (externalModelEnabled && populatedExternalModel.length === 0) {
      return toastr.warning("Please select AI model options")
    }

    let externalModelArray = populatedExternalModel?.flatMap(item => item.label)

    if (groupIDs) {
      var result = groupIDs.map((item) => {
        return item.value.toString();
      });
    }
    var temp = [];
    var final_email_array = [];

    if (typeof newnNotificationEmail == "object") {
      temp = newnNotificationEmail;
    } else {
      temp = newnNotificationEmail.split(",");
    }

    if (temp) {
      temp.map(
        (
          item // eslint-disable-line
          // eslint-disable-next-line array-callback-return
        ) => {
          final_email_array.push(item.trim());
        }
      );
    }
    if (security.length === 0) {
      toastr.error("Security Group Field Is Required", "Please select the security field!")
    }
    else {
      setSpinner(false);

      AxiosInter
        .post(
          `${API_ENDPOINT}/admin-api/update-app-user/`,
          {
            app_user_id: editAppUserID ? Number(editAppUserID) : 0,
            app_user_name: editAppUserName ? editAppUserName : "",
            firstName: editFirstName ? editFirstName.toString() : "",
            middleName: editMiddleName ? editMiddleName.toString() : "",
            lastName: editLastName ? editLastName.toString() : "",
            app_user_type: editAppUserType ? Number(editAppUserType.value) : 0,
            app_user_designation: editAppUserDesignation ? editAppUserDesignation : "",
            app_user_npi: editAppUserType && Number(editAppUserType.value) !== 1 ? "" : (npi ? npi.toString() : ""),
            app_user_email: editAppUserEmail ? editAppUserEmail.toString() : "",
            app_user_username: editAppUserUserName ? editAppUserUserName.toString() : "",
            app_user_group_id: result,
            Zip: zip.value ? zip.value.toString() : "",
            Ssn: editAppUserSSN ? editAppUserSSN.toString() : "",
            phone: editAppUserPhone ? editAppUserPhone.toString() : "",
            is_service_provider: editAppUserIsServiceProvider ? Number(editAppUserIsServiceProvider.value) : 0,
            user_security_id: security.value ? security.value.toString() : "",
            app_user_status: appUserEditStatus ? Number(appUserEditStatus.value) : 0,
            notificationEmail: newnNotificationEmail ? final_email_array : [],
            isenableEmailDigest: editEmailDigestToggle ? 1 : 0,
            isRestrictedPatientAccess: editRestricPatientAccessToggle ? 1 : 0,
            app_user_signature: signature ? signature : "",
            isExternalModelEnabled: externalModelEnabled,
            externalModel: (populatedExternalModel.length > 0) ? externalModelArray : "",
            externalUserId: externalUserId ? externalUserId : ""
          },
          {
            headers: {
              Authorization: API_TOKEN,
            },
          }
        )
        .then(function (response) {
          if (response.data.data.message !== null) {
            setSpinner(false);
            toastr.warning(
              "Success",
              `User details successfully updated ${response.data.data.message}`
            );
          } else {
            toastr.success("Success", "User details successfully updated");
          }
          setEditAppUserModal(!editAppUserModal);
          setAppUserReload(appUserReload + 1);
          setPopulatedExternalModel([])
        })
        .catch(function (error) {
          if (error.response.data) {
            toastr.error("Update Failed", error.response.data.message);
          } else {
            toastr.error("Update Failed", "Something went wrong");
          }
        });
    };
  }
  const changeOnEmail = (e) => {
    setNewNotificationEmail(e);
  };

  const onSaveClick = () => {
    setSpinner(true);
    if (newnNotificationEmail) {
      if (typeof newnNotificationEmail == "object") {
        emailArray = newnNotificationEmail;
      } else {
        emailArray = newnNotificationEmail.split(",");
      }
      if (emailArray.length > 0) {
        emailArray.map(
          (
            item // eslint-disable-line
            // eslint-disable-next-line array-callback-return
          ) => {
            //eslint-disable-line
            if (regXForEmail.test(item.trim()) === false) {
              toastr.warning("Invalid Email", "Please enter a valid email");
              temp.push(false);
            } else {
              temp.push(true);

            }
          }
        );
      }
    }

    updateAppUserApiCall();

  };

  const isServiceProviderPopulate = () => {
    var array = YesNoOptions;
    array.map(
      (
        item // eslint-disable-line
        // eslint-disable-next-line array-callback-return
      ) => {
        // eslint-disable-line
        if (item.value.toString() === editAppUserIsServiceProvider.toString()) {
          setEditAppUserIsServiceProvider(item);
        }
      }
    );
  };

  const createZipOption = () => {
    var array = m_zipList;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.zipcode,
      };
    });
    setOptForZip(options);
  };

  const TypePopulate = () => {
    if (editAppUserType && roleListForDropDown) {
      roleListForDropDown.map(
        (
          roles // eslint-disable-line
          // eslint-disable-next-line array-callback-return
        ) => {
          // eslint-disable-line
          // eslint-disable-next-line eqeqeq
          if (roles.value == editAppUserType) {
            // eslint-disable-line
            setEditAppUserType(roles);
          }
        }
      );
    }
  };

  const groupPopulate = () => {
    if (editAppUserGroupID) {
      const ary = editAppUserGroupID.toString().split(",");

      const array = [];

      // eslint-disable-next-line array-callback-return
      groupNamesForDropDown.map((item) => {
        // eslint-disable-next-line array-callback-return
        ary.map((item1) => {
          // eslint-disable-line

          // eslint-disable-next-line eqeqeq
          if (item.value == item1) {
            // eslint-disable-line
            array.push({
              value: item.value,
              label: item.label,
            });
          }
        });
      });

      setGroupsIDs(array);
      setAppUserSecurityEnable(true);
    }
  };

  const zipPopulate = () => {
    if (editAppUserZIP && zipCodeOptions) {
      const array2 = [];
      // eslint-disable-next-line array-callback-return
      zipCodeOptions.map((item1) => {
        // eslint-disable-next-line eqeqeq
        if (editAppUserZIP == item1.value) {
          // eslint-disable-line
          array2.push({
            value: item1.value,
            label: item1.label,
          });
        }
      });
      setZip(array2[0]);
    }
  };

  const securityPopulate = () => {
    if (editAppUserSecurityID) {
      const ary = editAppUserSecurityID.toString().split(",");

      // eslint-disable-next-line array-callback-return
      groupNamesForDropDown.map((item) => {
        // eslint-disable-line
        // eslint-disable-next-line array-callback-return
        ary.map((item1) => {
          // eslint-disable-line

          // eslint-disable-next-line eqeqeq
          if (item.value == item1) {
            // eslint-disable-line
            setSecurity({
              value: item.value,
              label: item.label,
            });
          }
        });
      });
    }
  };

  const getAsyncOptions = (inputValue) => {
    if (zipCodeOptions && inputValue.length >= 4) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(zipCodeOptions, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const populateStateAndCountry = () => {
    if (m_zipList) {
      var array = m_zipList;
      // eslint-disable-next-line array-callback-return
      array.map((item) => {
        // eslint-disable-line
        if (zip.value === item.id) {
          setNewCity(item.city);
          setNewState(item.stateFullName);
        }
      });
    }
  };

  const onChangeGroup = (e) => {
    setGroupsIDs(e);
  };
  const changeOnNPI = (e) => {
    if (regXForNubers.test(e) === false) {
      toastr.warning("Invalid NPI Number", "alphabets are not allowed")
    } else {
      setnpi(e)
    }
  }
  useEffect(() => {
    if (editAppUserNPI !== "") {
      setnpi(editAppUserNPI); // eslint-disable-line
    }
  }, [editAppUserNPI]); // eslint-disable-line

  useEffect(() => {
    if (zip !== null && m_zipList) {
      populateStateAndCountry();
    } else {
      setNewCity("");
      setNewState("");
    }
  }, [zip]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (editAppUserType != null && editAppUserType.value == 1) {
      // eslint-disable-line
      setNPIInputStatus(true);
    } else {
      setNPIInputStatus(false);
      setEditAppUserNPI("");
    }
  }, [editAppUserType]); // eslint-disable-line

  useEffect(() => {
    disableAddButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editAppUserName,
    editFirstName,
    editLastName,
    editAppUserEmail,
    editAppUserUserName,
    groupIDs,
    security,
    npiInputStatus,
    npi,
    editAppUserType,
  ]); // eslint-disable-line

  useEffect(() => {
    if (m_zipList && groupList) createZipOption();
    createGroupOption();
  }, [groupList, m_zipList]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (editAppUserIsServiceProvider == 1 || editAppUserIsServiceProvider == 0)
      isServiceProviderPopulate();
  }, [editAppUserIsServiceProvider]); // eslint-disable-line

  useEffect(() => {
    if (roleListForDropDown && editAppUserType) TypePopulate();
  }, [editAppUserType, roleListForDropDown]); // eslint-disable-line

  useEffect(() => {
    if (editAppUserGroupID && groupNamesForDropDown && editAppUserModal)
      groupPopulate();
  }, [editAppUserGroupID, groupNamesForDropDown, editAppUserModal]); // eslint-disable-line

  useEffect(() => {
    if (groupNamesForDropDown && editAppUserSecurityID) securityPopulate();
  }, [editAppUserSecurityID, groupNamesForDropDown]); // eslint-disable-line
  // useEffect(() => { if(groupIDs ) securityPopulateForOneGroup(); }, [editAppUserSecurityID]) // eslint-disable-line

  useEffect(() => {
    if (zipCodeOptions && editAppUserZIP) zipPopulate();
  }, [editAppUserZIP, zipCodeOptions]); // eslint-disable-line
  /* useEffect(() => { 
  if(appUserEditStatus.toString() !== ''){ 
  
  setDefaultStatusValue();
  } 
  }, [appUserEditStatus]) // eslint-disable-line */

  useMemo(() => setDefaultStatusValue(appUserEditStatus), [appUserEditStatus]); // eslint-disable-line
  const userType = editAppUserType ? Number(editAppUserType.value) : 0;
  const userRole = editAppUserType ? editAppUserType.value : undefined;
  const fetchGroupbyId = async (editAppUserID) => {
    const params = {
      au_id: editAppUserID ? Number(editAppUserID) : 0,
    };

    await AxiosInter
      .post(`${API_ENDPOINT}/admin-api/update-notification-email/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        setNewNotificationEmail(null);
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong !!");
      });
  };

  useEffect(() => {
    if (userRole !== 1 && userRole !== undefined && editAppUserModal) {
      fetchGroupbyId(editAppUserID);
    }
  }, [editAppUserType, editAppUserModal]); // eslint-disable-line

  const populateName = () => {
    // eslint-disable-next-line array-callback-return, no-unused-vars

    // eslint-disable-line

    if (editFirstName || editLastName) {
      if (editLastName == null) {
        setEditLastName("");

        const flname = `${editFirstName} ${editLastName}`;
        setEditAppUserName(flname);
      } else {
        const flname = `${editFirstName} ${editLastName}`;
        setEditAppUserName(flname);
      }
    }

  };
  useEffect(() => {

    if (editFirstName || editLastName) {
      populateName();
    } else {
      // console.log(
      // editAppUserName.length
      // );
      if (editAppUserName == null || editAppUserName.length <= 2) {
        setEditAppUserName("");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editFirstName, editLastName]);

  return (
    <div>
      <Modal isOpen={editAppUserModal} size="md">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit App User{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          <FormGroup>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    Group {userGroupStatus === 0 ? <mark>Inactive</mark> : ""}{" "}
                    <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Select
                    options={groupNamesForDropDown}
                    onChange={(event) => onChangeGroup(event)}
                    value={groupIDs}
                    placeholder="Select Group"
                    isSearchable
                    isClearable
                    isMulti
                  />
                </div>
              </Col>
            </Row>

            {newAppUserSecurityEnable ? (
              <>
                <Row className={styles.detailProfRow}>
                  <Col md={3} sm={5}>
                    <div className={styles.detailProfLabel}>
                      <Label>
                        Security Group <span className="required">*</span>
                      </Label>
                    </div>
                  </Col>
                  <Col md={9} sm={7}>
                    <div className={styles.detailProfValue}>
                      <Select
                        options={groupIDs}
                        onChange={(event) => setSecurity(event)}
                        value={security}
                        placeholder="Select Security Group"
                        isClearable
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    First Name <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="name"
                    value={editFirstName}
                    placeholder="Enter the First name"
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        setEditFirstName(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Middle Name</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="mname"
                    value={editMiddleName}
                    placeholder="Enter the Middle name"
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        setEditMiddleName(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    Last Name <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="lname"
                    value={editLastName}
                    placeholder="Enter the Last name"
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        setEditLastName(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    Name <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="name"
                    value={editAppUserName}
                    placeholder="Enter name"
                    disabled
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        setEditAppUserName(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            {roleListForDropDown ? (
              <>
                <Row className={styles.detailProfRow}>
                  <Col md={3} sm={5}>
                    <div className={styles.detailProfLabel}>
                      <Label>Type
                        <span className="required">*</span>
                      </Label>
                    </div>
                  </Col>
                  <Col md={9} sm={7}>
                    <div className={styles.detailProfValue}>
                      <Select
                        options={roleListForDropDown}
                        onChange={(event) => setEditAppUserType(event)}
                        value={editAppUserType}
                        placeholder="Select Type"
                        isSearchable
                        isClearable
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Status</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Select
                    options={statusOptions}
                    value={appUserEditStatus}
                    placeholder="Status"
                    onChange={(e) => {
                      setAppUserEditStatus(e);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    User Name <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="user name "
                    value={editAppUserUserName}
                    placeholder="Enter the User Name "
                    onChange={(e) => {
                      if (e.target.value.length <= 20)
                        setEditAppUseUserName(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    Signature
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="sign "
                    placeholder="Enter the Signature "
                    value={signature}
                    onChange={(e) => {
                      setSignature(e.target.value)
                    }}
                  />
                </div>
              </Col>
            </Row>
            {npiInputStatus ? (
              <>
                <Row className={styles.detailProfRow}>
                  <Col md={3} sm={5}>
                    <div className={styles.detailProfLabel}>
                      <Label>
                        NPI <span className="required">*</span>
                      </Label>
                    </div>
                  </Col>
                  <Col md={9} sm={7}>
                    <div className={styles.detailProfValue}>
                      <Input
                        id="NPI"
                        value={npi}
                        placeholder="Enter the NPI"
                        onChange={(e) => {
                          if (e.target.value.length > 10) {

                            toastr.warning("Invalid Number", "Number length exceeded");
                          } else {
                            changeOnNPI(e.target.value);
                          }
                        }}

                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    Email <span className="required">*</span>
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="email"
                    value={editAppUserEmail}
                    placeholder="Enter the Email"
                    onChange={(e) => {
                      if (e.target.value.length <= 150)
                        setEditAppUseEmail(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>
                    External User Id
                    {/* <span className="required">*</span> */}
                  </Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                <Input
                    id="externaluserid"
                    value={externalUserId}
                    placeholder="Enter the External User Id"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      // Check if the input consists only of digits or is empty (to allow deletion)
                      const isDigitsOnlyOrEmpty = /^\d*$/.test(inputValue);
                      // Check if the input length does not exceed 15 characters
                      const isLengthValid = inputValue.length <= 10;

                      const num = parseInt(inputValue, 10);
                      const isValueValid = inputValue === '' || (!isNaN(num) && num <= 2147483647);

                      if (!isDigitsOnlyOrEmpty) {
                        toastr.warning("Please enter digits only");
                      } else if(!isValueValid) {
                        toastr.warning("Maximum value exceeded");
                      }
                      else if (!isLengthValid) {
                        toastr.warning("Limit Exceeded");
                      } 
                      else {
                        setExternalUserId(inputValue);
                      }
                    }}
                  />                </div>
              </Col>
            </Row>

            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Zip Code :</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <AsyncSelect
                    cacheOptions
                    isClearable
                    isSearchable
                    value={zip}
                    loadOptions={(inputValue) => getAsyncOptions(inputValue)}
                    onChange={(event) => setZip(event)}
                    placeholder="Type zip code for options"
                    noOptionsMessage={customNoOptionsMessageforZip}
                  />
                </div>
              </Col>
            </Row>
            {newState && newCity ? (
              <>
                <Row className={styles.detailProfRow}>
                  <Col md={3} sm={5}>
                    <div className={styles.detailProfLabel}>
                      <Label>State:</Label>
                    </div>
                  </Col>
                  <Col md={9} sm={7}>
                    <div className={styles.detailProfValue}>
                      <Input id="State" value={newState} disabled />
                    </div>
                  </Col>
                </Row>

                <Row className={styles.detailProfRow}>
                  <Col md={3} sm={5}>
                    <div className={styles.detailProfLabel}>
                      <Label>City:</Label>
                    </div>
                  </Col>
                  <Col md={9} sm={7}>
                    <div className={styles.detailProfValue}>
                      <Input id="city" value={newCity} disabled />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>SSN</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="SSN"
                    value={editAppUserSSN}
                    placeholder="Enter the SSN"
                    onChange={(e) => {
                      if (e.target.value.length <= 20)
                        setEditAppUserSSN(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Phone</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Input
                    id="phone"
                    type="number"
                    value={editAppUserPhone}
                    placeholder="Enter the Phone"
                    onChange={(e) => {
                      if (e.target.value.length <= 12)
                        setEditAppUserPhone(e.target.value);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Is service Provider</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Select
                    options={YesNoOptions}
                    onChange={(event) => setEditAppUserIsServiceProvider(event)}
                    value={editAppUserIsServiceProvider}
                    placeholder="Select"
                    isSearchable
                    isClearable
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Restrictive Patient Access</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <ToggleButton
                    inactiveLabel={"OFF"}
                    activeLabel={"ON"}
                    value={editRestricPatientAccessToggle}
                    onToggle={() => {
                      setEditRestrictPatientAccessToggle(!editRestricPatientAccessToggle)
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Enable Email Digest</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <ToggleButton
                    inactiveLabel={"OFF"}
                    activeLabel={"ON"}
                    value={editEmailDigestToggle}
                    onToggle={() => {
                      setEditEmailDigestToggle(!editEmailDigestToggle)
                    }}
                  />
                </div>
              </Col>
            </Row>

            {userType === 1 ? (
              <>
                <Row className={styles.detailProfRow}>
                  <Col md={3} sm={5}>
                    <div className={styles.detailProfLabel}>
                      <Label> Notification Email :</Label>
                    </div>
                  </Col>
                  <Col md={9} sm={7}>
                    <div className={styles.detailProfValue}>
                      <Input
                        placeholder="Emails (demo1@xyz.com,demo2@xyz.com)"
                        value={newnNotificationEmail}
                        onChange={(e) => {
                          changeOnEmail(e.target.value);
                        }}
                      />

                      {newnNotificationEmail === 0 ? (
                        <>
                          <div
                            style={{ float: "left", marginTop: "0px" }}
                            className="warning-label"
                          >
                            <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                            <Label className="warning-text">
                              Enter atleast 1 email
                            </Label>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>

              </>
            ) : (
              ""
            )}
            <Row>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Is AI model enabled</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <ToggleButton
                    inactiveLabel={"OFF"}
                    activeLabel={"ON"}
                    value={externalModelEnabled}
                    onToggle={() => {
                      setExternalModelEnabled(!externalModelEnabled)
                    }}
                  />
                </div>
              </Col>
            </Row>
            {Boolean(externalModelEnabled) && <Row className={styles.detailProfRow}>
              <Col md={3} sm={5}>
                <div className={styles.detailProfLabel}>
                  <Label>Select Model</Label>
                </div>
              </Col>
              <Col md={9} sm={7}>
                <div className={styles.detailProfValue}>
                  <Select
                    options={externalModelOptions}
                    onChange={(event) => {
                      setPopulatedExternalModel(event)

                    }}
                    value={populatedExternalModel}
                    placeholder="Select Model"
                    isSearchable
                    isClearable
                    isMulti
                    menuShouldBlockScroll={true}
                  />
                </div>
              </Col>
            </Row>}
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button
            onClick={() => onSaveClick()}
            disabled={updateButtonStatus ? true : false}
            title={updateButtonStatus ? "Fill the required fields" : ""}
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditAppUserModal;