import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import ReactTooltip from 'react-tooltip';

const NewFacilityModal = (props) => {
    const { setNewFacilityModal, newFacilityModal, timeZoneList, groupID, externalFacilityID, setexternalFacilityID } = props;

    // CONTEXT VARIABLES 
    const { facilityReload, setFacilityReload, m_zipList,} = useContext(GlobalState);


    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");

    const [newFacilityName, setNewFacilityName] = useState("");
   // const [region, setRegion] = useState("");
    const [newAddress1, setNewAddress1] = useState("");
    const [newAddress2, setNewAddress2] = useState("");
    const [newReferalFax, setNewReferalFax] = useState("");
    const [newNPI, setNewNPI] = useState("");
    const [newZipCode, setNewZipCode] = useState("");
    const [newType, setNewType] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [newTimeZone, setNewTimeZone] = useState("");
    const [newLatitude, setNewLatitude] = useState("");
    const [newLongitude, setNewLongitude] = useState("");
    const [newPrimaryPhone, setNewPrimaryPhone] = useState("");
    const [newContactPerson, setNewContactPerson] = useState("");
    const [newDepartment, setNewDepartment] = useState("");
    const [newFloor, setNewFloor] = useState("");
    const [newWing, setNewWing] = useState("");
    const [newRoom, setNewRoom] = useState("");
    const [newState, setNewState] = useState("");
    const [newCity, setNewCity] = useState("");
    const [newCenterID,setNewCenterID] =useState("")

    // eslint-disable-next-line no-unused-vars
    const [inputValue, setInputValue] = useState('');
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [timeZoneDropDown, setTimeZoneDropDown] = useState([]); // eslint-disable-line
    const [optForZip, setOptForZip] = useState("");
     const regXForNubers = /^[0-9]*$/;
    const regXForFax = /^[-+\d\s]*$/;
    const regXForNames = /^[A-Za-z0-9\s ]*$/; // eslint-disable-line
    const regXStrNum = /^[A-Za-z0-9\s,.;:'" ]*$/;

    const toggle = () => {
        setNewFacilityModal(!newFacilityModal);

        setNewFacilityName("")
        setNewAddress1("")
        setNewAddress2("")
        setNewReferalFax("")
        setNewFloor("")
        setNewNPI("")
        setNewZipCode("")
        setNewType("");
        setNewDescription("");
        setNewTimeZone("");
        setNewLatitude("");
        setNewPrimaryPhone("");
        setNewLongitude("");
        setNewContactPerson("");
        setNewDepartment("");
        setNewWing("");
        setNewRoom("");
        setNewState("");
        setNewCity("");
        setNewCenterID("")

    }

    const confirmation = () => {

        if (!newFacilityName) {
            toastr.error("Facility Name Is Required", "Please enter the facility name!")
        }
        if (!newAddress1) {
            toastr.error("Address Field Is Required", "Please enter the address!")
        }
        if (!newType) {
            toastr.error("Type Is Required", "Please select the Type!")
        }
        /* if(!newAddress2 ) {
            toastr.error("Country Code Field Is Required" , "Please enter the admin user country code!")
        }
        if(!newReferalFax ) {
            toastr.error("Phone Number Field Is Required" , "Please enter the admin user phone number!")
        }
        if(!newNPI ) {
            toastr.error("Password Field Is Required" , "Please enter the admin user password!")
        }
        */
        /*  if(!newZipCode ) {
             toastr.error("Zip Code Is Required" , "Please enter the zip code!")
         }  */

        /* if(!newDescription ) {
            toastr.error("Password Field Is Required" , "Please enter the admin user password!")
        }
        if(!newTimeZone ) {
            toastr.error("Change Password On Login Field Is Required" , "Please enter the change pwd on login!")
        }
        if(!newLatitude ) {
            toastr.error("Change Password On Login Field Is Required" , "Please enter the change pwd on login!")
        }
        if(!newLongitude ) {
            toastr.error("Change Password On Login Field Is Required" , "Please enter the change pwd on login!")
        }

        if(!newContactPerson ) {
            toastr.error("Change Password On Login Field Is Required" , "Please enter the change pwd on login!")
        }

        if(!newDepartment ) {
            toastr.error("Change Password On Login Field Is Required" , "Please enter the change pwd on login!")
        }

        if(!newFloor ) {
            toastr.error("Change Password On Login Field Is Required" , "Please enter the change pwd on login!")
        }

        if(!newWing ) {
            toastr.error("Change Password On Login Field Is Required" , "Please enter the change pwd on login!")
        }
        if(!newRoom ) {
            toastr.error("Change Password On Login Field Is Required" , "Please enter the change pwd on login!")
        } */


        if (newFacilityName && newAddress1 && newType) {

            CreateFacilityApiCall()
        }

    };

    // const createTimeZoneOption = () => {
    //     var array = timeZoneList;
    //     const options = array.map((item) => {
    //         return {
    //             value: item.id,
    //             label: item.shortName
    //         };
    //     });
    //     setTimeZoneDropDown(options);
    // };

    const TypeOptions = [
        { value: '1', label: "Hospital" },
        { value: '2', label: "Clinic" },
        { value: '3', label: "SNF" }
    ]


    const CreateFacilityApiCall = () => {
        let gp_num = newCenterID ? newCenterID.value : "";
        let gp_string = "" + gp_num;
    
        const params = {
            group_id: groupID ? groupID : "",
            facility_name: newFacilityName,
            facility_address1: newAddress1,
            facility_address2: newAddress2 ? newAddress2 : "",
            referral_fax: newReferalFax ? newReferalFax : "",
            npi: newNPI ? newNPI : "",
            zip_code: newZipCode ? newZipCode.label : "",
            description: newDescription ? newDescription : "",
            type: newType ? newType.label.toLowerCase() : "",
            time_Zone: newTimeZone ? newTimeZone.label : "",
            latitude: newLatitude ? Number(newLatitude) : null,
            longitude: newLongitude ? Number(newLongitude) : null,
            contact_person: newContactPerson ? newContactPerson : "",
            Primary_phone: newPrimaryPhone ? newPrimaryPhone : "",
            department: newDepartment ? newDepartment : "",
            floor: newFloor ? newFloor : "",
            wing: newWing ? newWing : "",
            room: newRoom ? newRoom : "",
            CenterID: gp_string ? gp_string : null,
            externalFacilityID: externalFacilityID ? externalFacilityID : "",
        };
    
        AxiosInter
            .post(`${API_ENDPOINT}/admin-api/create-Facility/`, params, {
                headers: {
                    Authorization: API_TOKEN,
                },
            })
            .then(function (response) {
                setFacilityReload(facilityReload + 1);
                toastr.success(
                    "Successfully Created",
                    "Facility successfully created"
                );
                toggle();
            })
            .catch(function (error) {
                if (error.response && error.response.status === 409) {
                    toastr.error(
                        "Failed",
                        "Facility already exists. Please enter a different name."
                    );
                } else {
                    toastr.error("Failed", "Please try again");
                }
            });
    };
    

    const disableAddButton = () => {

        if (newFacilityName && newAddress1 && newType) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }

    const createZipOption = () => {

        if (m_zipList) {


            var array = m_zipList;
            const options = array.map((item) => {
                return {
                    value: item.id,
                    label: item.zipcode
                };
            });
            setOptForZip(options);
        }
    };
  
    // const createCenterOption = () => {

    //     if (centerNamesForDropDown) {


    //         var array = centerNamesForDropDown;
    //         const options = array.map((item) => {
    //             return {
    //                 value: item.id,
    //                 label: item.centername
    //             };
    //         });
    //         setOptForCenter(options);
    //     }
    // };




    const populateStateAndCountry = () => {

        if (m_zipList) {

            var array = m_zipList;
            const opt = array.map((item) => { // eslint-disable-line
                if (newZipCode.value === item.id) {

                    setNewCity(item.city)
                    setNewState(item.stateFullName)
                }
            });

        }
    };

    const getAsyncOptions = (inputValue) => {
        if (optForZip) {
            return new Promise((resolve, reject) => {
                const filtered = _.filter(optForZip, o =>
                    _.startsWith(_.toLower(o.label), _.toLower(inputValue))
                );
                resolve(filtered);
            });
        }
    }

    const changeOnPhoneNumber = (e) => {
        if (regXForNubers.test(e) === false) {
            toastr.warning("Invalid Phone Number", "alphabets are not allowed")
        } else {
            setNewPrimaryPhone(e)
        }
    }
    // const changeOnName = (e) => {
    //     if (regXForNames.test(e) === false) {
    //         toastr.warning("Invalid Entry", "Special characters are not allowed")
    //     } else {
    //         setNewFacilityName(e)
    //     }
    // }
    const changeOnFax = (e) => {
        if (regXForFax.test(e) === false) {
            toastr.warning("Invalid Fax Number", "Please enter a valid fax number")
        } else {
            setNewReferalFax(e)
        }
    }
    const changeOnNPI = (e) => {
        if (regXForNubers.test(e) === false) {
            toastr.warning("Invalid NPI Number", "alphabets are not allowed")
        } else {
            setNewNPI(e)
        }
    }
    const changeOnContactPerson = (e) => {
        if (regXForNubers.test(e) === false) {
            toastr.warning("Invalid Number", "alphabets are not allowed")
        } else {
            setNewContactPerson(e)
        }
    }
    const changeOnDepartment = (e) => {
        if (regXStrNum.test(e) === false) {
            toastr.warning("Invalid Entry", "Special characters are not allowed")
        } else {
            setNewDepartment(e)
        }
    }
    const changeOnRoom = (e) => {
        if (regXStrNum.test(e) === false) {
            toastr.warning("Invalid Entry", "Special characters are not allowed")
        } else {
            setNewRoom(e)
        }
    }
    const changeOnFloor = (e) => {
        if (regXStrNum.test(e) === false) {
            toastr.warning("Invalid Entry", "Special characters are not allowed")
        } else {
            setNewFloor(e)
        }
    }
    const changeOnWing = (e) => {
        if (regXStrNum.test(e) === false) {
            toastr.warning("Invalid Entry", "Special characters are not allowed")
        } else {
            setNewWing(e)
        }
    }
    // const changeOnCenter = (e) => {
    //     if (regXStrNum.test(e) === false) {
    //         toastr.warning("Invalid Entry", "Special characters are not allowed")
    //     } else {
    //         setNewCenter(e)
    //     }
    // }
    useEffect(() => { disableAddButton() }, [newFacilityName,newAddress1,newType]) // eslint-disable-line

    useEffect(() => {
        if (newZipCode !== null && m_zipList) {
            populateStateAndCountry()
        } else {
            setNewCity("")
            setNewState("")
        }
    }, [newZipCode]) // eslint-disable-line

    useEffect(() => { if (m_zipList) createZipOption(); }, [m_zipList]) // eslint-disable-line
    // useEffect(() => { if (centerNamesForDropDown) createZipOption(); }, [centerNamesForDropDown]) 

const handleInputChange = value => {
    setInputValue(value);
  };
  const handleChange = value => {
    setNewCenterID(value);
  }

   const loadFacilityOptionsForCenter = (inputValue) => {
        if(inputValue.length >= 4){
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m-center-list/${inputValue}`, {
            headers: {
                Authorization: API_TOKEN,
            }
            })
            .then((response) => response.data.data)
            .catch((error) => {
                throw error;
            });
        }
      }

const minLength = 3;

const customNoOptionsMessage = ({ inputValue }) => {
  if (inputValue.length < minLength) {
    return "Please enter at least 4 letters to search";
  } else {
    return "No option";
  }
};
const minLengthforzip = 1;

const customNoOptionsMessageforZip = ({ inputValue }) => {
  if (inputValue.length < minLengthforzip) {
    return "Please enter at least a letters to search";
  } else {
    return "No option";
  }
};
    return (
        <div>
            <Modal isOpen={newFacilityModal}  size="lg" scrollable={true} >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add New Facility </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col sm={6}>
                                <Label>
                                    Name  <span className="required">*</span> :
                                </Label>
                                <Input
                                    id="name"
                                    value={newFacilityName}
                                    placeholder="Enter the facility name"
                                    onChange={(e) => { if (e.target.value.length <= 150) setNewFacilityName(e.target.value) }}
                                />

                            </Col>
                            <Col sm={6} >

                                <Label>
                                    Phone Number :
                                </Label>

                                <Input
                                    id="primaryPhone"
                                    value={newPrimaryPhone}
                                    placeholder="Enter the Primary Number"
                                    onChange={(e) => { 
                                        if (e.target.value.length >= 13) {
                                            toastr.warning("Invalid Number", "number length exceeded")
                                        }else{
                                                changeOnPhoneNumber(e.target.value);
                                        }
                                    }}
                                />
                            </Col>
                        </Row><br />
                        <Row>
                            <Col sm={6}>
                                <Label>
                                    Address 1  <span className="required">*</span> :
                                </Label>
                                <Input
                                    id="address1"
                                    type="textarea"
                                    rows = {4}
                                    cols = {2}
                                    value={newAddress1}
                                    placeholder="Enter the facility address 1"
                                    onChange={(e) => { if (e.target.value.length <= 150) setNewAddress1(e.target.value) }}
                                />

                            </Col>
                            <Col sm={6}>

                                <Label>
                                    Address 2 :
                                </Label>
                                <Input
                                    id="address2"
                                    type="textarea"
                                    value={newAddress2}
                                    rows = {4}
                                    cols = {2}
                                    placeholder="Enter the address 2"
                                    onChange={(e) => { if (e.target.value.length <= 150) setNewAddress2(e.target.value) }}
                                />

                            </Col>
                        </Row><br />
                        <Row>
                            <Col sm={4}>
                                <Label>
                                    Fax :
                                </Label>
                                <Input
                                    id="fax"
                                    type="text"
                                    value={newReferalFax}
                                    placeholder="Enter the referral fax"
                                    maxLength={15}
                                    onChange={(e) => {
                                        if (e.target.value.length >= 15) {
                                          toastr.warning(
                                            "Invalid attempt",
                                            "number length exceeded"
                                          );
                                        } else {
                                          changeOnFax(e.target.value);
                                        }
                                      }}
                                />

                            </Col>
       
                            <Col sm={4} >
                              
                                <Label>
                                    NPI :
                                </Label>
                                    <Input
                                        id="npi"
                                        value={newNPI}
                                        placeholder="Enter the NPI"
                                        onChange={(e) => { 
                                            if (e.target.value.length > 10) {
                                              
                                                toastr.warning("Invalid Number", "Number length exceeded");
                                            } else {
                                                changeOnNPI(e.target.value);
                                            }
                                        }}
                                    />
                         
                            </Col>
                            <Col sm={4}>
                                <Label>
                                External Facility Id :
                                </Label>
                                <Input
                                    id="externalFacilityID"
                                    value={externalFacilityID}
                                    placeholder="Enter the External Facility Id"
                                    onChange={(e) => setexternalFacilityID(e.target.value)}
                                />
                            </Col>
                        </Row><br/>
                        <Row>
                            <Col sm={3}>
                             
                                <Label>
                                    Zip Code :
                                </Label>
                                    <AsyncSelect
                                        cacheOptions
                                        isClearable
                                        isSearchable
                                        noOptionsMessage={customNoOptionsMessageforZip}
                                        placeholder="Select Zipcode"
                                        value={newZipCode}
                                        onChange={(event) => setNewZipCode(event)}
                                        loadOptions={inputValue => getAsyncOptions(inputValue)}
                                    />
                             
                            </Col>
                            <Col sm={3} >
                                <Label>
                                State:
                                </Label>
                                          
                  
                                <Input
                                id="State"
                                value={newState}
                                disabled
                                placeholder = "State"

                                />
                               
                            </Col>
                            <Col sm={3} >
                                <Label>
                                City:
                                </Label>
                                <Input
                                    id="city"
                                    value={newCity}
                                    disabled
                                    placeholder = "City"

                                />
                                          
                            </Col>
                         <Col sm={3} >
                       
                              <Label>
                                Center:
                              </Label>
                              <AsyncSelect
                            cacheOptions
                            isSearchable
                            placeholder="Center"
                             //loadingMessage={() => "Searching..."}
                            noOptionsMessage={customNoOptionsMessage}
                            value={newCenterID ? newCenterID:''}                               
                           // loadOptions={loadFacilityOptionsForCenter}
                            getOptionLabel={e => e.label}
                            getOptionValue={e => e.value}                               
                            // onChange={(e) => {
                            //   setNewCenterID(e)
                            // }}
                            onInputChange={handleInputChange}
                            onChange={handleChange}
                          loadOptions={inputValue => loadFacilityOptionsForCenter(inputValue)}
                            
                      /> 
                             {/* <Select
                                    options={createCenterOption}
                                    onChange={(event) => setNewCenterID(event)}
                                    value={newType}
                                    placeholder="Select Type"
                                    isSearchable
                                    isClearable
                                /> */}
                          </Col> 

                        </Row><br/>

                        <Row>

                        <Col sm={6}>
                            
                            <Label>
                                Description :
                            </Label>
                              
                            <Input
                                id="Description"
                                value={newDescription}
                                placeholder="Enter the Description"
                                onChange={(e) => { if (e.target.value.length <= 150) setNewDescription(e.target.value) }}
                            />
                        </Col>
                         
                        <Col sm={6} >
                            <Label>
                                Type  <span className="required">*</span> :
                            </Label>
                            <Select
                                options={TypeOptions}
                                onChange={(event) => setNewType(event)}
                                value={newType}
                                placeholder="Select Type"
                                isSearchable
                                isClearable
                            />
                              
                        </Col>

                        <Col sm={6} >
                            <br></br>
                            <Label>
                                Time Zone :
                            </Label>
                            <Select
                               options={timeZoneList}
                               onChange={(event) => setNewTimeZone(event)}
                               value={newTimeZone}
                               placeholder="Time Zone"
                               isSearchable
                               isClearable
                            />
                        </Col>

                        <Col sm={6} >
                          <br></br>
                              
                            <Label>
                                Latitude:
                            </Label>
                            <Input
                                id="Latitude"
                                type="number"
                                step="any"
                                value={newLatitude}
                                placeholder="Enter the Latitude"
                                onChange={(e) => { if (e.target.value.length <= 19) setNewLatitude(e.target.value) }}
                                data-tip="Enter values in decimal format"
                                
                            />
                                <ReactTooltip className="custom-tooltip" effect="solid" place="top" />
                              
                        </Col>

                    </Row><br/>
                    <Row>
                        <Col sm={6}>
                            <Label>
                                Longitude:
                            </Label>
                              
                            <Input
                                id="Longitude"
                                type="number"
                                step="any"
                                value={newLongitude}
                                placeholder="Enter the Longitude"
                                onChange={(e) => { if (e.target.value.length <= 19) setNewLongitude(e.target.value) }}
                                data-tip="Enter values in decimal format"
                            
                            />
                                <ReactTooltip className="custom-tooltip" effect="solid" place="top" />
                              
                        </Col>

                        <Col sm={6} >
                               
                                <Label>
                                Contact person phone number :
                                </Label>
                                
                                <Input
                                    id="contactperson "
                                    value={newContactPerson}
                                    placeholder="Enter the Contact person phone number "
                                    onChange={(e) => { 
                                        if (e.target.value.length >= 13) {
                                            toastr.warning("Invalid Number", "number length exceeded")
                                        }else{
                                            changeOnContactPerson(e.target.value);
                                        }
                                    }}
                                />
                              
                        </Col>

                    </Row><br/>
                    <Row>
                        <Col sm={6}>    
                            <Label>
                                Department : <small style={{fontSize: '13px', color: '#ff0000e6'}}>*Separate departments using commas</small>
                            </Label>
                            <Input
                                id="Department "
                                value={newDepartment}
                                type="textarea"
                                rows = {3}
                                cols = {2}
                                placeholder="Enter the Department "
                                onChange={(e) => { if (e.target.value.length <= 150) changeOnDepartment(e.target.value) }}
                            />
                               
                        </Col>

                        <Col sm={6} >
                               
                            <Label>
                                Room : <small style={{fontSize: '13px', color: '#ff0000e6'}}>*Separate rooms using commas</small>
                            </Label>
                              
                            <Input
                                id="room "
                                value={newRoom}
                                placeholder="Enter the Room "
                                type="textarea"
                                rows = {3}
                                cols = {2}
                                onChange={(e) => { if (e.target.value.length <= 150) changeOnRoom(e.target.value) }}
                            />
                               
                        </Col>
                    </Row><br/>
                    <Row>
                    
                        <Col sm={6} >
                              
                            <Label>
                                Floor :
                            </Label>
                            <Input
                                id="Floor"
                                type="textarea"
                                rows = {3}
                                cols = {2}
                                value={newFloor}
                                placeholder="Enter the Floor "
                                onChange={(e) => { if (e.target.value.length <= 150) changeOnFloor(e.target.value) }}
                            />
                           
                        </Col>
                        <Col sm={6} >
                              
                            <Label>
                                Wing :
                            </Label>
                               
                            <Input
                                id="Wing"
                                type="textarea"
                                value={newWing}
                                rows = {3}
                                cols = {2}
                                placeholder="Enter the Wing "
                                onChange={(e) => { if (e.target.value.length <= 150) changeOnWing(e.target.value) }}
                            />
                              
                        </Col>
                    
                    </Row><br/>
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        onClick={()=>{
                            confirmation()
                            
                        }}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Save</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewFacilityModal;