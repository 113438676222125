import React, { useEffect, useState } from "react";
import HeaderTitle from "../../components/HeaderTitle";
import Header from "../../components/Header";
import { Breadcrumb, BreadcrumbItem, CardHeader, Col, Row, Container, Card, Label, CardBody, Button, Input } from "reactstrap";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import EditAnalyticsModal from "../../newComponents/utilities/EditAnalyticsModal";
import AddAnalyticsModal from "../../newComponents/utilities/AddAnalyticsModal";

const Analytics = () => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");
    const [groupName, setGroupName] = useState("")
    const [searchKeyword, setSearchKeyword] = useState("")
    const [isExternalChecked, setIsExternalChecked] = useState(false);
    const [groupId, setGroupId] = useState(null);
    const [data, setData] = useState([])
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [totalSize, setTotalSize] = useState(0); // eslint-disable-line
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [reload, setReload] = useState(false) // eslint-disable-line
    const [offset, setOffset] = useState(0); // eslint-disable-line
    const [editAnalyticsModal, setEditAnalyticsModal] = useState(false)
    const [addAnalyticsModal, setAddAnalyticsModal] = useState(false)
    const [clickedRowData, setClickedRowData] = useState()
    const [numberOfRecords, setNumberOfRecords] = useState()
    const [subscriptionNamesList, setSubscriptionNamesList] = useState([]);
    const [selectedApiKeyOption, setSelectedApiKeyOption] = useState(null);
    let currentSearchKeyword = "";

    const BlankState = () => {
        return (
            <Card className="blank-state">
                <CardBody>
                    <h3>No Records Available</h3>
                </CardBody>
            </Card>
        );
    }

    const handleIsExternalChange = (checked = false) => {
        console.log(checked)
        setIsExternalChecked(!!checked);
    };

    const handleChangeGroup = (value) => {
        setGroupName(value);
        if (value) {
            setGroupId(value.value);
        } else {
            setGroupId(null);
        }
    };

    const clearValues = () => {
        setSearchKeyword("")
        setReload(true)
    }

    const resetValues = () => {
        setSearchKeyword("")
        setGroupName("")
        setGroupId("")
        setReload(true)
    }

    useEffect(() => {
        if (reload) {
            getAnalyticsData()
        }
    }, [reload]) // eslint-disable-line


    const getAnalyticsData = () => {
        const params = {
            "group_id": groupId ? groupId : null,
            "off_set": 0,
            "search_keyword": currentSearchKeyword,
            "sortColumn": sortColumn,
            "sortOrder": sortOrder,
            "is_pagination": 1,
            "record_limit": 100
        };

        AxiosInter.post(`${API_ENDPOINT}/admin-api/fetch-analytics-details/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((response) => {
                setData(response.data.data)
                setNumberOfRecords(response.data.record_count)
                setReload(false)
            })
            .catch((error) => {
                console.error("Error fetching analytics data:", error);
                setReload(false)
            });
    };

    const groupNameOptions = (inputValue) => {
        if (inputValue.length >= 4) {
            const searchParams = new URLSearchParams({ name: inputValue });
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`, {
                headers: {
                    Authorization: API_TOKEN,
                },
            })
                .then((response) => response.data.data)
        }
    };

    const getAllSubscriptionPlans = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-subscription-plans-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.data.data.length > 0) {
                    setSubscriptionNamesList(response.data.data)
                }
            })
    };


    useEffect(() => {
        getAllSubscriptionPlans()
    }, []) // eslint-disable-line

    const handleTableChange = (type, newState) => {
        setPage(newState.page);
        setSizePerPage(newState.sizePerPage);
        setOffset((newState.page - 1) * newState.sizePerPage);

        if (newState.sortField !== undefined && newState.sortOrder !== undefined) {
            setSortColumn(newState.sortField);
            setSortOrder(newState.sortOrder);
            setReload(true);
        } else {
            setSortColumn("");
            setSortOrder("");
        }

        getAnalyticsData();
    }


    const basicValidation = (cell) => {
        return cell ? cell : "N/A"
    }

    const subscriptionFormatter = (cell) => {
        if (!cell || cell.length === 0) {
          return "N/A";
        } else {
          return cell.map(sub => sub.name).join(', ');
        }
      };
     

    const actionsFormatter = (cell, row) => {
        return (
            <>
                <Container>
                    <Row className="justify-content-center">
                        <Button
                            outline
                            className="text-center mr-2"
                            onClick={() => {
                                setClickedRowData(row);
                                setEditAnalyticsModal(true)
                            }}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} className="align-middle" fixedWidth title="Edit" />
                        </Button>{" "}
                    </Row>
                </Container>
            </>
        )
    }

    const actionsFormatterStatus = (cell, row) => {
        if (cell === 1) {
            return 'Active';
        } else if (cell === 0) {
            return 'Inactive';
        } else {
            return 'N/A';
        }
    }

    const columns = [
        {
            dataField: 'groupName',
            text: 'Group',
            sort: true,
            formatter: basicValidation
        },
        {
            dataField: 'category',
            text: 'Category',
            sort: true,
            formatter: basicValidation
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            formatter: basicValidation
        },
        {
            dataField: 'subscription_values',
            text: 'Subscription',
            sort: true,
            formatter: subscriptionFormatter
        },
        {
            dataField: 'dashboardId',
            text: 'Dashboard Id',
            sort: true,
            formatter: basicValidation
        },
        {
            dataField: 'updatedBy',
            text: 'Updated By',
            sort: true,
            formatter: basicValidation
        },
        {
            dataField: 'updatedOn',
            text: 'Updated On',
            sort: true,
            formatter: basicValidation
        },
        {
            dataField: 'sortOrder',
            text: 'Sorting Order',
            sort: true,
            formatter: basicValidation
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            formatter: actionsFormatterStatus
        },
        {
            text: 'Actions',
            sort: false,
            formatter: actionsFormatter
        }
    ]

    return (
        <div>
            <Container fluid>
                <Header>
                    <HeaderTitle>
                        Analytics
                        <Button
                            className="Add-btn pull-right"
                            onClick={() => {
                                setAddAnalyticsModal(true);
                            }}
                        >
                            Add Analytics
                        </Button>
                    </HeaderTitle>
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/dashboard">Dashboard</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Analytics</BreadcrumbItem>
                    </Breadcrumb>
                </Header>
            </Container>
            <Card>
                <CardHeader>
                    <Row>
                        <Col sm="4">
                            <Label>Group</Label>
                            <AsyncSelect
                                loadOptions={groupNameOptions}
                                isClearable
                                isSearchable
                                value={groupName}
                                onChange={handleChangeGroup}
                                placeholder="Select Group"
                            />
                        </Col>
                        <Col>
                            <Button
                                className="patient-go-buttons"
                                onClick={getAnalyticsData}
                            >
                                Go
                            </Button>
                            <Button
                                className="patient-reset-buttons"
                                type="reset"
                                onClick={() => {
                                    resetValues()
                                }}
                                disabled={groupName? false : true}
                                title={groupName ? "" : "Nothing to Reset"}
                            >
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                {data.length > 0 ?
                    <>
                        <CardHeader>
                            <div className="separator" />
                            <Row>
                                <Col sm="2">
                                    <div className="number-of-records">
                                        <p>Number of Records: {numberOfRecords}</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="c-search-field form-inline justify-content-end">
                                        <div className="form-group mr-2">
                                            <Input
                                                placeholder="Search"
                                                autoFocus="autofocus"
                                                id="search"
                                                value={searchKeyword}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    setSearchKeyword(inputValue);
                                                    currentSearchKeyword = inputValue;
                                                    getAnalyticsData();
                                                }}

                                            />
                                        </div>
                                        <Button
                                            className="clear-search-button"
                                            onClick={() => {
                                                clearValues();
                                            }}
                                            disabled={searchKeyword ? false : true}
                                            title={searchKeyword ? "" : "Nothing to clear"}
                                        >
                                            Clear
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <div className="separator" />
                        </CardHeader>
                        <CardBody className="patient-list dropdown-menu-up">
                            {data.length > 0 ?
                                <div className="emitable">
                                    <BootstrapTable
                                        bootstrap4
                                        bordered={false}
                                        hover
                                        remote
                                        keyField="id"
                                        columns={columns}
                                        data={data}
                                        className="react-bootstrap-table"
                                        pagination={paginationFactory({
                                            page: page,
                                            sizePerPage: sizePerPage,
                                            sizePerPageList: [50, 100, 150, 200],
                                            totalSize
                                        })}
                                        onTableChange={(type, newState) => {
                                            handleTableChange(type, newState);
                                        }}
                                    />
                                </div>
                                : <BlankState/>}
                        </CardBody>
                    </>
                    : ""}
            </Card>

            <AddAnalyticsModal
                addAnalyticsModal={addAnalyticsModal}
                setAddAnalyticsModal={setAddAnalyticsModal}
                groupNameOptions={groupNameOptions}
                isExternalChecked={isExternalChecked}
                handleIsExternalChange={handleIsExternalChange}
                subscriptionNamesList={subscriptionNamesList}
                // categoryOptions={categoryOptions}
                groupId={groupId}
                selectedApiKeyOption={selectedApiKeyOption}
                setSelectedApiKeyOption={setSelectedApiKeyOption}
                getAnalyticsData={getAnalyticsData}
            />

            <EditAnalyticsModal
                editAnalyticsModal={editAnalyticsModal}
                setEditAnalyticsModal={setEditAnalyticsModal}
                groupNameOptions={groupNameOptions}
                isExternalChecked={isExternalChecked}
                handleIsExternalChange={handleIsExternalChange}
                clickedRowData={clickedRowData}
                subscriptionNamesList={subscriptionNamesList}
                // categoryOptions={categoryOptions}
                groupId={groupId}
                selectedApiKeyOption={selectedApiKeyOption}
                setSelectedApiKeyOption={setSelectedApiKeyOption}
                getAnalyticsData={getAnalyticsData}
            />

        </div>
    )

}

export default Analytics;
