import React, { useEffect, useState } from 'react'
import Cookie from 'js-cookie';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Row, Col, Spinner, Label } from 'reactstrap';
import Select from 'react-select'
import AxiosInter from './../../AxiosInter.js';
import { toastr } from 'react-redux-toastr';
const EditModuleSubscriptionModal = (props) => {
    const { setEditModule, editModule, rowData, setRowData, subscriptionModules } = props
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");
    const [spinner, setSpinner] = useState(false)
    const [modules, setModules] = useState()
    const [moduleList, setModuleList] = useState()
    let res = []
    const [buttonBlankState, setButtonBlankState] = useState(true);

    const toggle = () => {
        setEditModule(!editModule);
        setRowData("")
    };

    const disableUpdateButton = () => {
        if (modules?.length > 0) {
            setButtonBlankState(false)
        }
        else {
            setButtonBlankState(true)
        }
    }

    useEffect(() => { disableUpdateButton() }, [modules])  // eslint-disable-line

    const getModuleList = () => {
        setSpinner(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m-modules/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((res) => {
                var array = [];
                // eslint-disable-next-line array-callback-return
                res.data.data.map((item1) => {
                    // eslint-disable-line
                    array.push({
                        value: item1.id,
                        label: item1.name,
                    });
                });
                setModuleList(array);
                setSpinner(false);
            })
            .catch((error) => {
                setSpinner(false);
            });
    };

    useEffect(() => {
        if (editModule || rowData) {
            getModuleList();
        }
    }, [editModule, rowData]) // eslint-disable-line

    useEffect(() => {
        if (rowData || editModule) {
            modulesPopulate()
        }
    }, [rowData, editModule, moduleList])  // eslint-disable-line

    const confirmation = () => {
        if (!modules) {
            toastr.error("Please select modules");
        }
        if (modules) {
            modules.map((item) => { // eslint-disable-line
                res.push(
                    item.value
                )
            })
            if (res) {
                updateModuleList()
            }
        }
    };

    const updateModuleList = () => {
        setSpinner(true)
        const params = {
            subscriptionID: rowData?.subscriptionID,
            moduleIdList: res ? res : ''
        }
        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-subscription-modules/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        }).then(response => {
            setSpinner(false)
            toastr.success("Module successfully updated")
            setEditModule(!editModule)
            subscriptionModules()
        })
    }
    const modulesPopulate = () => {
        var array = [];
        const data = rowData?.moduleIDs ? rowData.moduleIDs.split(",") : [];
        data.map((id) => {   // eslint-disable-line
            if (Array.isArray(moduleList)) {
                moduleList.map((item) => {  // eslint-disable-line
                    if (id.toString() === item.value.toString()) {
                        array.push({
                            value: item.value,
                            label: item.label
                        });
                    }
                });
            }
        });
        setModules(array);
    };

    return (
        <div className="edit-module">
            <Modal style={{ width: 500 }} isOpen={editModule}  >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Edit {rowData ? rowData?.subcriptionPLan : "N/A"} - Modules</ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars overflow-modal" >
                    <div>
                        {spinner ? <Spinner color='primary' /> : ""}
                    </div>
                    <FormGroup>
                        <Row>
                            <Col sm={12}>
                                <Label>Modules <span className="required">*</span> </Label>
                                <Select
                                    value={modules}
                                    options={moduleList}
                                    onChange={(event) => setModules(event)}
                                    placeholder="Select Module"
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti
                                    styles={{
                                        menuList: (provided) => ({
                                            ...provided,
                                            maxHeight: 200, // Set the maximum height for the dropdown list
                                            overflowY: 'auto', // Enable scrolling if the content exceeds the maximum height
                                        }),
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={confirmation}
                        title={buttonBlankState ? "Fill the required fields" : ""}
                        disabled={buttonBlankState}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default EditModuleSubscriptionModal