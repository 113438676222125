import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap'

const DeleteModuleSubscriptionModal = (props) => {

    const { deleteModule, setDeleteModule, deleteModuleSubscription, spinner, setRowData } = props;

    const toggle = () => {
        setDeleteModule(!deleteModule);
        setRowData("")
    }
    return (
        <div>
            <Button color="danger" onClick={toggle}></Button>
            <Modal isOpen={deleteModule}>
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Delete Confirmation</ModalHeader>
                <ModalBody>
                    {spinner ? <Spinner color='primary' /> : ""}
                    <div style={{ 'textAlign': 'center' }}>
                        Are you sure you want to delete?
                    </div>
                </ModalBody>
                <ModalFooter className="advanced-search-button custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={() => {
                            deleteModuleSubscription()
                        }}>
                        Yes
                    </Button>{" "}
                    <Button outline className="no-button button-width" onClick={toggle}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default DeleteModuleSubscriptionModal