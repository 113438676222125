import React, { useState, useEffect,useContext } from 'react';
import { Button, Modal, ModalHeader, Container, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, Spinner } from 'reactstrap';
import { Select as SelectVirtualized } from 'react-select-virtualized';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import PackageSearchListingModal from '../../newComponents/utilities/PackageSearchListingModal'

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { GlobalState } from '../../contextAPI/Context'

const GroupsPackageModal = (props) => {
    const {  groupNamesForDropDown } = useContext(GlobalState);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    const [buttonBlankState, setButtonBlankState] = useState(false);
    const[grp,setGrp] = useState("")
    const [searchKeyword, setSearchKeyword] = useState("");
    const [listingModal, setListingModal] = useState(false);
    const [searchSpinner, setSearchSpinner] = useState(false);
    const [icdDetails, setIcdDetails] = useState("");
    const [icdDetailsListing, setIcdDetailsListing] = useState("");

    const [selectedIcdCodes,setSelectedIcdCodes] = useState([]);
 
   
 

    const {
        icdPackageModal,
        setIcdPackageModal,
        icdPackageModalState,
        setIcdPackageModalState,
        
        selectedGroupName,
        setSelectedGroupName,
        icdPackageGroup,
        selectedIcdPackage,
        setSelectedIcdPackage ,
        icdPackageId,
        icdPackageName,
        icdPackageReload,
        setIcdPackageReload,
        setIcdPackageName,
        setIcdPackageGroup,
        setIcdPackageNamesReload,
        icdPackageNamesReload,
       


    } = props

    const toggle = () => {
        setIcdPackageModal(!icdPackageModal)
    }


    const handleListItemClick = (event,value) => {
        if (event.target.checked) {
            addNewIcdPackageDetails(event.target.value)
           
        }
        else if(event.target.checked === false){
           
            setSelectedIcdPackage(selectedIcdPackage.filter((value)=>String(value.icdCode) !== String(event.target.value)))
           
           
        }
    
    }


    const handleDelete = (index) => {
        setSelectedIcdPackage(selectedIcdPackage.filter((_,i)=>i !== index))
     };

  



    const RenderIcdListingView = (icdDetailsObj,listLength) => {
        return (
            <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
                {[listLength].map(() => {
                     return (
                        <ListItem
                            disablePadding
                        >
                             <ListItemButton
                                dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        value={icdDetailsObj.code}
                                        onClick={(event) => handleListItemClick(event)}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                primary = {icdDetailsObj.code}
                                secondary = {icdDetailsObj.description}
                                >
                                
                              
                              
                                </ListItemText>


                            </ListItemButton>
                        </ListItem>
                        
                    );
                   
                })}
            </List>

        )
    }

    const setInitialGroupName = () => {
        groupNamesForDropDown.map((data) => { // eslint-disable-line
            if (data.label === icdPackageGroup) {
                setSelectedGroupName({ value: data.value, label: icdPackageGroup })

            }
        })
    }

    const fetchICDCodeDetails = () => {
        setSearchSpinner(true);
        const params =
        {
            "icd_search_keyword": searchKeyword ? searchKeyword : "",
            "selected_icd_codes": selectedIcdPackage ? selectedIcdPackage :""
        }

        //    setSpinner (true);

        AxiosInter.post(`${API_ENDPOINT}/admin-api/get-icd-details/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.data.data.length > 0) {
                    setIcdDetails(response.data.data)
                    setSearchSpinner(false);
                    setListingModal(true)
                }
                else {
                    setIcdDetails("")
                    setSearchSpinner(false);
                    setListingModal(false)

                }

            })
            .catch(error => {
                toastr.error('Something went wrong')
            });
    };


    const filterSelectedValues = (data) =>{
      if(selectedIcdPackage.length > 0) {

        const selectedIcds = selectedIcdPackage.map((selectedIcd)=>selectedIcd.icdCode);
        const filteredIcdsList = data.filter((icdItem)=>!selectedIcds.includes(icdItem.code))
        let icdDetails = filteredIcdsList.map((item) => {
            return RenderIcdListingView(item)
        });
        setIcdDetailsListing(icdDetails);

    }
    else {
        let icdDetails = data.map((item) => {
            return RenderIcdListingView(item)
        });
        setIcdDetailsListing(icdDetails);
        
    }

    }

    const addNewIcdPackageDetails = (newIcdCode) => {
            icdDetails.map((icdItem)=>{ // eslint-disable-line
                if (icdItem.code === newIcdCode) {
                    setSelectedIcdPackage(state => [...state,{'icdId':String(icdItem.id),'icdCode':icdItem.code,'icdDescription':icdItem.description}])
                    toastr.success('Success',"ICD code added")
    
                }
    
            });
       
    }

    // const removeIcdItem = (newIcdCode) => {
           
       
    // }



    const IcdCodeChip = ({icdPackage}) => (
        <>
                   <Row>
                            <Stack direction="row" spacing={3} sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
                                <Col sm={12}>
                                    <Label for="icd_search">ICD Packages<span className="required">*</span></Label><br/>
                                    
                                    {icdPackage ? icdPackage.map((item)=>{
                                        return(
                                        <Tooltip title={item.icdDescription} placement="top" arrow>
                                        <Chip 
                                        label={item.icdCode}
                                        style = {{'cursor':'pointer'}}
                                        onDelete={()=>{handleDelete(icdPackage.indexOf(item))}} 
                                        variant="outlined"  />
                                        </Tooltip>
                                        )
                                        

                                    }):<h1>No packages chosen</h1>
                                 
                                }
                                    
                                   
                                </Col>
                                </Stack>
                              
                            </Row>
        </>
    )



    const validate = () => {
        if (icdPackageName && selectedGroupName && selectedIcdPackage) {
            setButtonBlankState(false)
        }
        else {
            setButtonBlankState(true)
        }
      }



    const updateICDPackageDetails = () => {
        const params =
        {
            "icd_package_id": icdPackageId ? icdPackageId:"",
            "icd_package": selectedIcdPackage ? selectedIcdPackage:[],
            "group_id": selectedGroupName ? selectedGroupName.value:"",
            "icd_package_name": icdPackageName ? icdPackageName :""

        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/edit-icd-packages/`,params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
          .then(response => {
            toggle();
            toastr.success("Success","ICD Package details updated");
            setIcdPackageReload(icdPackageReload + 1)
            setIcdPackageNamesReload(icdPackageNamesReload + 1)
            

          })
          .catch(error => {
            if (error.response.data.message) {
                toastr.error("Error",error.response.data.message)
            } 
            else {
                toastr.error("Error","Something went wrong !")
            }  

          });

    }
    const fetchGroupbyId = (selectedGroupName) => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-pkggroupid-list/${(selectedGroupName)}`, {
            headers: {
                Authorization: API_TOKEN,
            },
            })
            .then(response => {
              
                if (response.data.data.length > 0) {
                   setGrp(response.data.data)
                }
            })
            .catch(error => {
                
                toastr.error('Error','Something went wrong in groups listing !!')
            
            });
        

      }
  
      useEffect(()=>{
        if(selectedGroupName !== '' && selectedGroupName !== undefined){

            fetchGroupbyId(selectedGroupName)
        }
      },[selectedGroupName])   // eslint-disable-line




    const addNewICDPackage = () => {
        // debugger
        const params =
        {
            "icd_package": selectedIcdPackage ? selectedIcdPackage:[],
            "group_id": grp ? grp[0].value:null,
            "icd_package_name": icdPackageName ? icdPackageName :""

        }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/add-icd-packages/`,params,{
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        toggle();
        toastr.success("Success","New ICD Package added");
        setIcdPackageReload(icdPackageReload + 1)
        setIcdPackageNamesReload(icdPackageNamesReload + 1)


      })
    .catch(error => {
        if (error.response.data.message) {
            toastr.error("Error",error.response.data.message)
        } 
        else {
            toastr.error("Error","Something went wrong !")
        }  
      });

    }


    // useEffect(()=>{
    //     console.log('325',selectedIcdPackage)

    // },[selectedIcdPackage])




    useEffect(() => {
        if (!icdPackageModal) {
            setIcdPackageModalState("initial");
            setSelectedIcdCodes([]);
            setSelectedIcdPackage([]);
            setIcdPackageName("");
            setSelectedGroupName("");
            setSearchKeyword("");
            setIcdPackageGroup("");


        };

    }, [icdPackageModal]) // eslint-disable-line

    useEffect(() => {
        if (groupNamesForDropDown && icdPackageGroup) {
           

            setInitialGroupName();
        }

    }, [groupNamesForDropDown, icdPackageGroup]) // eslint-disable-line

    useEffect(() => {
        if (searchKeyword.length > 2) {
            fetchICDCodeDetails();

        }

    }, [searchKeyword]) // eslint-disable-line

    useEffect(() => {
        if (icdDetails) {
            filterSelectedValues(icdDetails)

        }

    }, [icdDetails]) // eslint-disable-line

    useEffect(() => {
    
           validate();

    }, [icdPackageName,selectedGroupName,selectedIcdPackage]) // eslint-disable-line




    return (
        <div>
            <Modal isOpen={icdPackageModal} size="xl" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>{icdPackageModalState === "add" ? "Add New ICD Package " : "Edit ICD Package"} </ModalHeader>
                <ModalBody className="overflow-modal">
                    <Container fluid>
                        <FormGroup>
                            <Row>
                                <Col sm={6}>

                                    <Label for="icd_package_name">Name<span className="required">*</span></Label>
                                    <Input
                                        id="icd_package_name"
                                        type="text"
                                        placeholder="Name"
                                        value={icdPackageName}
                                        onChange={(e) => {
                                             setIcdPackageName(e.target.value)

                                         }}
                                    />
                                </Col>


                                <Col sm={6}>
                                    <Label for="groupDropDown">Group<span className="required">*</span></Label>
                                    <SelectVirtualized
                                        name="groupDropDown"
                                        options={
                                            groupNamesForDropDown
                                        }
                                        value={grp}
                                        
                                        placeholder="Choose a Group"
                                       
                                        isSearchable
                                        isClearable

                                        onChange={(e) => {
                                            setGrp(e)
                                        }}
                                    />

                                </Col>
                            </Row><br />
                            <Row>
                                <Col sm={6}>
                                    <Label for="icd_search">Search ICD Code</Label>
                                    <Input
                                        id="icd_search_keyword"
                                        type="text"
                                        placeholder="Search keyword"
                                        value={searchKeyword}
                                        onChange={(e) => {
                                            setSearchKeyword(e.target.value)

                                        }}
                                    />
                                     <small className="required"><span className="required">*</span>Length of search keyword should be atleast 3</small>
                                </Col>
                                <Col sm={2}>
                                    {searchSpinner ? <Spinner /> : ""}
                                </Col>
                            </Row><br/>

                            <IcdCodeChip
                             icdPackage = {selectedIcdPackage}
                            />
                        </FormGroup>
                    </Container>

                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button

                        onClick={() => {

                        icdPackageModalState === 'edit' ?  updateICDPackageDetails():addNewICDPackage()
                        }}
                       
                        disabled={buttonBlankState}
                        title={buttonBlankState ? "Please fill in all mandatory fields" : (!buttonBlankState && icdPackageModalState === 'add') ? "Click to save" : "Click to update"}

                    >{icdPackageModalState === 'edit' ? 'Update' : 'Add'}</Button>{' '}

                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>

            <PackageSearchListingModal
                listingModal={listingModal}
                setListingModal={setListingModal}
                icdDetailsListing={icdDetailsListing}
                icdDetails={icdDetails}
                selectedIcdCodes = {selectedIcdCodes}
                addNewIcdPackageDetails = {addNewIcdPackageDetails}
             

            />

        </div>
    );
}

export default GroupsPackageModal;