import React, { useState} from "react";
import {
  Button,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
  
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import AsyncSelect from "react-select/async";
import Cookie from 'js-cookie';

const FacilityAssignationEditModal = (props) => {
  const {
  
    reloadForFacilityAssignationTag,
    setReloadForFacilityAssignationTag,
    editFacilityAssignationTagGroupName,
    setEditFacilityAssignationTagGroupName,
    editFacilityAssignationTagFacilityName,
    setEditFacilityAssignationTagFacilityName,
    editFacilityAssignationTagAppUser_Name,
    setEditFacilityAssignationTagAppUser_Name,
    editFacilityAssignationTagTableOrderCount,
    setEditFacilityAssignationTagTableOrderCount,
    editFacilityAssignationEditModal,
    setEditFacilityAssignationEditModal,
    row
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [spinner, setSpinner] = useState(false); // eslint-disable-line
  const [updateButtonStatus, setUpdateButtonStatus] = useState(false); // eslint-disable-line
  
  const toggle = () => {
    setEditFacilityAssignationEditModal
    (!editFacilityAssignationEditModal)};

  const confirmation = () => {

    if (!editFacilityAssignationTagGroupName) {
      toastr.error("Group Name Field Is Required", "Please enter the group name!");
    }

    if (!editFacilityAssignationTagFacilityName) {
      toastr.error("Facility Name Field Is Required", "Please enter the facility name!");
    }
    if (!editFacilityAssignationTagAppUser_Name) {
        toastr.error("App user name is Required", "Please enter the appuser name!");
      }
  
      if (!editFacilityAssignationTagTableOrderCount) {
        toastr.error("Table Order Count Is Required", "Please enter the table order count!");
      }

    if (editFacilityAssignationTagGroupName&&
        editFacilityAssignationTagFacilityName&&
        editFacilityAssignationTagAppUser_Name && 
        editFacilityAssignationTagTableOrderCount) 
        {
            update();
        }
  };

  const update = () => {
    // debugger
      AxiosInter.put(`${API_ENDPOINT}/admin-api/referral_sender_assignee_allocation_rule-crud/${row}/`,
        {
          group_id: editFacilityAssignationTagGroupName? editFacilityAssignationTagGroupName.value:"",
          facility_id: editFacilityAssignationTagFacilityName? editFacilityAssignationTagFacilityName.value:"",
          sender_assignee_id: editFacilityAssignationTagAppUser_Name? editFacilityAssignationTagAppUser_Name.value:"",
          total_count_per_day: editFacilityAssignationTagTableOrderCount? editFacilityAssignationTagTableOrderCount: "",

        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setEditFacilityAssignationEditModal(!editFacilityAssignationEditModal);
       setReloadForFacilityAssignationTag(reloadForFacilityAssignationTag+1)
        toastr.success(
          "Successfully Updated",
          "Facility Assignation details successfully updated"
        );
      })
      .catch(function (error) {
        // debugger
        console.log(error)
        // setSpinner(false);
        toastr.error("Update Failed", "Please try again");
      });

      
    };

 
    const groupNameloadOptions = (inputValue) => {
      if (inputValue.length >= 4) {
        const searchParams = new URLSearchParams ({name: inputValue});
        return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
          {
            headers: {
              Authorization: API_TOKEN,
            },
          }
        )
          .then((response) => response.data.data)
          .catch((error) => {
            throw error;
          });
      }
    };



  const facilityNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      // const searchParams = new URLSearchParams ({name: inputValue});
        return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-by-fulfiller-name/${inputValue}/${editFacilityAssignationTagGroupName.value}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };
  

  const appUserloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue, groupID: editFacilityAssignationTagGroupName.value });
      
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-data-assignation/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  return (
    <div>
      <Modal
        isOpen={editFacilityAssignationEditModal}
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit Facility Assignation{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          {spinner ? <Spinner /> : ""}
          <FormGroup>
            <Row>
              <Col sm={6}>
                <Label>
                  Group Name :<span className="required">*</span>
                </Label>
                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  name="groupName"
                  placeholder="Select group name"
                  value={editFacilityAssignationTagGroupName}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  validate={{
                    maxLength: { value: 120 },
                  }}
                  loadOptions={(inputValue) =>
                    groupNameloadOptions(inputValue)
                  }
                  onChange={(e)=>{
                    setEditFacilityAssignationTagGroupName(e)
                  }}
                />
              </Col>
              <Col sm={6}>
                <Label>
                  Facility Name:<span className="required">*</span>
                </Label>
                <AsyncSelect
                     cacheOptions
                     isClearable
                     isSearchable
                      name="facilityName"
                      placeholder="Select facility name"
                      value={editFacilityAssignationTagFacilityName}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      validate={{
                        maxLength: { value: 120 },
                      }}
                      loadOptions={(inputValue) =>
                        facilityNameloadOptions(inputValue)
                      }
                      onChange={(e)=>{
                        setEditFacilityAssignationTagFacilityName(e)
                      }}
                />
              </Col>
              </Row>
              <Row>
              <Col sm={6}>
                <Label>
                  App User Name:<span className="required">*</span>
                </Label>
                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable  
                   type="text"
                   name="appUserName"
                   placeholder="Search with at least 4 characters."
                   value={editFacilityAssignationTagAppUser_Name}
                   getOptionLabel={(e) => e.label}
                   getOptionValue={(e) => e.value}
                   validate={{
                     maxLength: { value: 120 },
                   }}
                   loadOptions={(inputValue) => 
                    appUserloadOptions(inputValue)}
                   onChange={(e) => {
                    setEditFacilityAssignationTagAppUser_Name(e);
                   }}
                  
                />
              </Col>
               <Col sm={6}>
                <Label>
                  Total Order Count:<span className="required">*</span>
                </Label>
                <Input
                 id="orderCount"
                 value={editFacilityAssignationTagTableOrderCount}
                 placeholder="Enter the Order Count"
                 onChange={(e) => {
                  setEditFacilityAssignationTagTableOrderCount(e.target.value);
                  }}  
                            
                />
              </Col>
            </Row>
            <br />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button
            onClick={() => {
              confirmation();
            }}
            disabled={updateButtonStatus}
          >Update</Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default FacilityAssignationEditModal;
