/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  // eslint-disable-next-line no-unused-vars
  Spinner,
  Label,
  FormGroup,
} from "reactstrap";

import AxiosInter from './../../AxiosInter.js';
import "./DateRangeSelector.css";

// eslint-disable-next-line no-unused-vars
import { Select } from "react-select-virtualized";
import AsyncSelect from "react-select/async";
import { toastr } from "react-redux-toastr";
import Cookie from 'js-cookie';

const ReferralFulfillerOrdertypes = (props) => {
  // DEPENDENCY VARIABLES
  const {
    ReferralFulfillerOrderTypeModal,
    setReferralFulfillerOrderTypeModal,
    referralOrderFulfillerID,
    SetReferralFulfillerOrderOPtions,
    referralFulfillerOrderOptions,
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [addButtonStatus, setAddButtonStatus] = useState(false);

  const toggle = () => {
    // debugger;
    setReferralFulfillerOrderTypeModal(!ReferralFulfillerOrderTypeModal);
  };

  const getfulfillerOrderTypeOptions = (InputValue) => {
    if (InputValue?.length >= 4) {
     return AxiosInter.get(
        `${API_ENDPOINT}/admin-api/fetch-order-type-search/${InputValue}/${referralOrderFulfillerID}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const onSaveClick = () => {
    const params = {
      fulfillerId: referralOrderFulfillerID ? referralOrderFulfillerID : "",
      order_type_id: referralFulfillerOrderOptions
        ? referralFulfillerOrderOptions
        : "",
      // Object.entries(referralFulfillerOrderOptions) :""
    };
    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/create-fulfiller-order-type/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then(function (response) {
        console.log("asaslklllll .,.,", response.data);
        // eslint-disable-next-line eqeqeq
        if (response.data.status == "Success") {
          // debugger;
          toastr.success(
            "Success",
            `fulfiller order type successfully created ${response.data.message}`
          );
          toggle();
        }
      })
      .catch(function (error) {
        toastr.error("Failed", error.response.data.message);
      });
  };
  useEffect(() => {
    if (referralOrderFulfillerID || referralFulfillerOrderOptions) {
      getfulfillerOrderTypeOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralOrderFulfillerID, referralFulfillerOrderOptions]);
  const disableAddButton = () => {
    if (referralFulfillerOrderOptions) {
      setAddButtonStatus(false);
    } else {
      setAddButtonStatus(true);
    }
  };
  useEffect(() => {
    disableAddButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralFulfillerOrderOptions]);
  return (
    <div>
      <Modal
      id="fulfillerOrderTypeModal"
        isOpen={ReferralFulfillerOrderTypeModal}
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Referral Fulfiller Order Type{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          {/* {spinner ? <Spinner /> : ""} */}
          <FormGroup>
            <Row>
              <Col sm={12}>
                <Label>Fulfiller Order Types</Label>

                <AsyncSelect
                 classNamePrefix="testSelect"
                  isMulti
                  cacheOptions
                  isClearable
                  isSearchable
                  value={referralFulfillerOrderOptions}
                  placeholder={"Choose Order Types"}
                  onChange={(e) => {
                    SetReferralFulfillerOrderOPtions(e);
                  }}
                  loadOptions={(inputValue) =>
                    getfulfillerOrderTypeOptions(inputValue)
                  }
                />
              </Col>
            </Row>
            <Row></Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button
            // onClick={() => {
            //   confirmation();
            // }}
            // disabled={updateButtonStatus}
            className="yes-button button-width"
            onClick={() => {
              onSaveClick();
            }}
            //   const arr = Object.entries(referralFulfillerOrderOptions);
            //   // eslint-disable-next-line array-callback-return
            //   arr.map((item) => {
            //     console.log("skklskflksfskfmksfm", item);
            //   });
            // }}
            disabled={addButtonStatus}
            title={addButtonStatus ? "Fill the required fields" : ""}
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReferralFulfillerOrdertypes;
