import React, { useState, useEffect, useContext } from "react";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label, Row, Button, Input, Spinner } from "reactstrap";
import "./DateRangeSelector.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { GlobalState } from "../../contextAPI/Context";
import AxiosInter from './../../AxiosInter.js';
import Tooltip from "@mui/material/Tooltip";
import Cookie from 'js-cookie';
import AsyncSelect from "react-select/async";

const GroupAppUserFilters = (props) => {
  // CONTEXT VARIABLES
  const {
    setAppUserName,
    setAppUserRole,
    setAppUserUsername,
    setAppUserNPI,
    setAppUserEmail,
    setAppUserStatus,
    setAppUserGroup,
    appUserReload,
    setAppUserReload,
    roleListForDropDown,
    setRoleListForDropDown,
  } = useContext(GlobalState);

  const { offset, setOffset,groupID } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const animatedComponents = makeAnimated();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [userName, setUserName] = useState("");
  const [NPI, setNPI] = useState("");
  const [group, setGroup] = useState("");
  const [status, setStatus] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [roleList, setRoleList] = useState("");
  const [search, setSearch] = useState(false);
  const [inputValue, setInputValue] = useState(""); // eslint-disable-line
  const StatusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
    { value: "99", label: "Locked" },
  ];

  const runSearch = () => {
    if (name || email || role || NPI || status || group || userName) {
      setAppUserName(name ? name : "");
      setAppUserEmail(email ? email : "");
      setAppUserRole(role ? role : "");
      setAppUserNPI(NPI ? NPI : "");
      setAppUserUsername(userName ? userName : "");
      setAppUserStatus(status ? status : "");
      setAppUserGroup(group ? group : "");
      setAppUserReload(appUserReload + 1);
    }
  };

  const resetFields = () => {
    setName("");
    setEmail("");
    setRole("");
    setNPI("");
    setGroup("");
    setStatus("");
    setUserName("");
    setAppUserName("");
    setAppUserEmail("");
    setAppUserRole("");
    setAppUserNPI("");
    setAppUserUsername("");
    setAppUserStatus("");
    setAppUserGroup("");
    setAppUserReload(appUserReload + 1);
  };
  const getRoleList = () => {
    setSpinner(true);
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/get-m-role-list/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((res) => {
        setRoleList(res?.data?.data);
        setSpinner(false);
      })
      .catch((error) => {
        console.log(error);
        setSpinner(false);
      });
  };

  // const fetchGroupsAPI = () => {
  //     setSpinner(true)
  //     axiosinter.get(`${API_ENDPOINT}/admin-api/get-groups-list/`, {
  //       headers: {
  //         Authorization: API_TOKEN,
  //       },
  //     })
  //       .then(function (response) {
  //         if (response.data.length > 0) {
  //           setGroupList(response.data);
  //         }
  //         setSpinner(false)
  //       })
  //       .catch(function (error) {
  //         setGroupList("");
  //         setSpinner(false)
  //       });
  // };

  const createRoleOption = () => {
    setSpinner(true);
    var array = roleList;

    var SortedRoleDropDownList = array.sort(function (a, b) {
      if (a.designation < b.designation) return -1;
      if (a.designation > b.designation) return 1;
      return 0;
    });

    const options = SortedRoleDropDownList.map((item) => {
      return { value: item.id, label: item.designation };
    });
    setRoleListForDropDown(options);
    setSpinner(false);
  };

  // const createGroupOption = () => {
  //     if(groupList){
  //         setSpinner(true)
  //         var array1 = groupList
  //         const options1 = array1.map((item, index) => {
  //             return {
  //                 value: item.id,
  //                 label: item.name
  //             };
  //         });
  //         setGroupDropDown(options1);
  //         setSpinner(true)
  //     }
  // };

  const enableOrDisableSearch = () => {
    if (name || email || role || userName || NPI || group || status) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  // EFFECTS FOR CORPORATE / GROUP
  useEffect(() => {
    enableOrDisableSearch();
  }, [name, email, role, userName, NPI, group, status]); // eslint-disable-line
  useEffect(() => {
    if (!roleList) getRoleList();
  }, []); // eslint-disable-line
  useEffect(() => {
    if (roleList) createRoleOption();
  }, [roleList]); // eslint-disable-line
const appUserLoadOptions = (inputValue) => {
    if (inputValue.length >= 4) 
    {
      const searchParams = new URLSearchParams({ name: inputValue, groupID: groupID });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-data/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const UsernameLoadOptions = (inputValue) => {
    if (inputValue.length >= 4) 
    {
      const searchParams = new URLSearchParams({ username: inputValue, groupID: groupID });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-username-by-group/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };
  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handleAppuserChange = (value) => {
    setName(value);
  };
  const handluserNameChange = (value) => {
    setUserName(value);
  };
  return (
<AvForm>
  <AvGroup>
    {spinner ? <Spinner /> : ""}
    <Row className="mb-3">
      <Col xs={4}>
        <Label>Name</Label>
        <AsyncSelect
          cacheOptions
          isClearable
          isSearchable
          type="text"
          name="Name"
          placeholder="Search with at least 4 characters."
          value={name}
          getOptionLabel={(e) => e.label}
          getOptionValue={(e) => e.value}
          validate={{
            maxLength: { value: 120 },
          }}
          onInputChange={handleInputChange}
          onChange={handleAppuserChange}
          loadOptions={(inputValue) => appUserLoadOptions(inputValue)}
        />
      </Col>
      <Col xs={4}>
        <Label>User Name</Label>
        <AsyncSelect
          cacheOptions
          isClearable
          isSearchable
          type="text"
          name="user Name"
          placeholder="Search with at least 4 characters."
          value={userName}
          getOptionLabel={(e) => e.label}
          getOptionValue={(e) => e.value}
          validate={{
            maxLength: { value: 120 },
          }}
          onInputChange={handleInputChange}
          onChange={handluserNameChange}
          loadOptions={(inputValue) => UsernameLoadOptions(inputValue)}
        />
      </Col>
      <Col xs={4}>
        <Label>Email</Label>
        <AvField
          type="text"
          name="email"
          placeholder="Email"
          value={email}
          validate={{
            maxLength: { value: 254 },
          }}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </Col>
    </Row>
    <Row className="mb-3">
      <Col xs={4}>
        <Label>Type</Label>
        <Tooltip title={role ? role.label : ""} placement="top" arrow>
          <div>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={(event) => {
                setRole(event);
              }}
              isSearchable
              isClearable
              placeholder="Select"
              value={role}
              options={roleListForDropDown}
              components={animatedComponents}
            />
          </div>
        </Tooltip>
      </Col>
      <Col xs={4}>
        <Label>NPI</Label>
        <Input
          className="form-control"
          name="postCode"
          placeholder="NPI"
          value={NPI}
          onChange={(e) => {
            if (e.target.value.length <= 20) {
              var result = e.target.value.replace(/[^a-z0-9]/gi, "");
              setNPI(result);
            }
          }}
        />
      </Col>
      <Col xs={4}>
        <Label>Status</Label>
        <Select
          className="react-select-container"
          classNamePrefix="react-select"
          onChange={(event) => {
            setStatus(event);
          }}
          isSearchable
          placeholder="Select"
          value={status}
          options={StatusOptions}
          components={animatedComponents}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={1} className="mb-3">
    <Button
      type="submit"
      className="go-buttons"
      disabled={!search ? true : false}
      title={spinner || !search ? "Nothing To Search" : ""}
      onClick={() => {
        offset === "0" ? runSearch() : setOffset("0");
      }}
    >
      Go
    </Button>
  </Col>
  <Col xs={2} className="mb-3">
    <Button
      type="reset"
      className="reset-buttons"
      onClick={() => {
        resetFields();
      }}
      disabled={!search ? true : false}
      title={spinner || !search ? "Nothing To Clear" : ""}
    >
      Reset
    </Button>
  </Col>
    </Row>
  </AvGroup>
</AvForm>



   

  );
};

export default GroupAppUserFilters;
