import React, { useState, useEffect } from 'react';
// import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import styles from "./ProviderDetails.module.css";
import Select from 'react-select';

const NewDrgModal = (props) => {
    const {
        mdcOptions,
        newdrgModal,
        setNewDrgModal,
        drgTypeOptions,
        reload,
        setReload



    } = props

    // CONTEXT VARIABLES 
    // const { reload, setReload } = useContext(GlobalState);


    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");


    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [newDrgCode, setNewDrgCode] = useState("");
    const [newMdc, setNewMdc] = useState("");
    const [newType, setNewType] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [newWeight, setNewWeight] = useState("");
    const [newGlos, setNewGlos] = useState("");
    const [newAlos, setNewAlos] = useState("");
    const regXForNubers = /^\d*\.?\d*$/;
    // const [newdrgModal, setNewDrgModal] = useState(false);

    const toggle = () => {
        setNewDrgModal(!newdrgModal);
        setNewDrgCode("")
        setNewMdc("")
        setNewType("")
        setNewDescription("")
        setNewWeight("")
        setNewGlos("")
        setNewAlos("")

    }

    // const confirmation = () => { 

    //     if(!newDrgCode ) {
    //         toastr.error("DRG Code Is Required" , "Please enter the drg code!")
    //     }

    const CreateDrgApiCall = () => {
        const params = {

            "drg_code": newDrgCode,
            "mdc_code": newMdc ? newMdc.value : "",
            "drg_type": newType ? newType.label : "",
            "description": newDescription ? newDescription : "",
            "weight": newWeight ? Number(newWeight) : 0,
            "glos": newGlos ? Number(newGlos) : 0,
            "alos": newAlos ? Number(newAlos) : 0

        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/add-new-drg/`, params, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(response => {
                toastr.success("Success", "New DRG added");
                toggle();
                setReload(reload + 1);



            })
            .catch(error => {

                toastr.error("Failed", "Something went wrong !")

            });
    }


    const disableAddButton = () => {

        if (newDrgCode) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }
    const changeOnWeight = (e) => {
        if (regXForNubers.test(e) === false) {
            toastr.warning("Invalid Number", "alphabets are not allowed")
        } else {
            setNewWeight(e)
        }
    }
    const changeOnGlos = (e) => {
        if (regXForNubers.test(e) === false) {
            toastr.warning("Invalid Number", "alphabets are not allowed")
        } else {
            setNewGlos(e) 
        }
    }
    const changeOnAlos = (e) => {
        if (regXForNubers.test(e) === false) {
            toastr.warning("Invalid Number", "alphabets are not allowed")
        } else {
            setNewAlos(e)
        }
    }
    const changeOnDrgCode = (e) => {
        if (regXForNubers.test(e) === false) {
            toastr.warning("Invalid Number", "alphabets are not allowed")
        } else {
            setNewDrgCode(e)
        }
    }


    useEffect(() => { disableAddButton() }, [newDrgCode]) // eslint-disable-line


    return (
        <div>
            <Modal isOpen={newdrgModal}  size="md" scrollable={true} >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add New DRG </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row  >
                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        DRG Code  <span className="required">*</span> :

                                    </Label>
                                </div>

                                {/* <Col md={9} sm={7}>
                            <div className={styles.detailProfValue} > */}
                                <Input
                                    id="drgCode"
                                    value={newDrgCode}
                                    placeholder="Enter the DRG Code"
                                    onChange={(e) => { if (e.target.value.length <= 150) changeOnDrgCode(e.target.value) }}
                                />
                            </Col>
                            {/* </div> */}
                            {/* </Col> */}

                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        Description :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Input
                                        id="Description"
                                        value={newDescription}
                                        placeholder="Enter Description"
                                        onChange={(e) => { if (e.target.value.length <= 150) setNewDescription(e.target.value) }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br></br>
                        <Row className={styles.detailProfRow} >
                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        MDC  <span className=""></span> :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Select
                                        options={mdcOptions}
                                        onChange={(event) => setNewMdc(event)}
                                        value={newMdc}
                                        placeholder="Select MDC"
                                        isSearchable
                                        isClearable
                                    />
                                </div>
                            </Col>
                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        Type  <span className=""></span> :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Select
                                        id="type"
                                        options={drgTypeOptions}
                                        value={newType}
                                        placeholder="Choose Type"
                                        onChange={(e) => { setNewType(e) }}
                                    />
                                </div>
                            </Col>
                        </Row>


                        <Row className={styles.detailProfRow} >
                            <Col sm={4} >
                                <div className="" >
                                    <Label>

                                        Weight :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Input
                                        id="weight"
                                        value={newWeight}
                                        placeholder="Enter weight (lbs)"
                                        onChange={(e) => { changeOnWeight(e.target.value) }}
                                    />
                                </div>
                            </Col>
                            <Col sm={4} >
                                <div className="" >
                                    <Label>

                                        Glos :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Input
                                        id="glos"
                                        value={newGlos}
                                        placeholder="Enter glos"
                                        onChange={(e) => { changeOnGlos(e.target.value) }}
                                    />
                                </div>
                            </Col>
                            <Col sm={4} >
                                <div className="" >
                                    <Label>

                                        Alos :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Input
                                        id="alos"
                                        value={newAlos}
                                        placeholder="Enter alos"
                                        onChange={(e) => { changeOnAlos(e.target.value) }}
                                    />
                                </div>
                            </Col>
                        </Row>

                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={CreateDrgApiCall}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>

        </div>

    );
};

export default NewDrgModal;