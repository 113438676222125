import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import ToggleButton from "react-toggle-button";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Cookie from 'js-cookie';
// import { ReactMultiEmail } from "react-multi-email";
// import "react-multi-email/style.css";

const NotificationPreferenceModal = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [spinner, setSpinner] = useState(false);

  var emailArray = [];

  //const regXForEmail = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}*$/;
  // const regXForEmail = new RegExp("[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]{2,3}")//eslint-disable-line
  const regXForEmail = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"); //eslint-disable-line
  const [saveButtonStatus, setSaveButtonStatus] = useState(true);

  // eslint-disable-next-line no-unused-vars
  var apiStatus = false;
  var temp = [];

  const {
    notificationPreferenceModal,
    setNotificationPreferenceModal,

    id,
    facilityID,

    newEmail,
    setNewEmail,

    facilityReload,
    setFacilityReload,

    currentFacilityStatus,

    centralNotification,
    setCentralNotification,

    fetchFacilityByGroup,
  } = props;

  const saveNotifications = () => {
    // debugger
    var temp = [];
    var final_email_array = [];
    if (typeof newEmail == "object") {
      temp = newEmail;
    } else {
      temp = newEmail.split(",");
    }

    if (temp) {
      // eslint-disable-next-line array-callback-return
      temp.map((item) => {
        final_email_array.push(item.trim());
      });
    }
    setSpinner(false);
    // debugger
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/save-notification-preference-details/`,
        {
          facility_id: facilityID ? facilityID : 0,
          group_id: id ? window.atob(id) : 0,
          enableCentralNotification: centralNotification,
          status: currentFacilityStatus ? currentFacilityStatus : 0,
          email: newEmail ? final_email_array : [],
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          setSpinner(false);
          toastr.success("Success", "Notification preference saved");
          toggle();
          setFacilityReload(facilityReload + 1);
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
        setSpinner(false);
      });
  };

  const toggle = () => {
    setNotificationPreferenceModal(!notificationPreferenceModal);
  };

  const changeOnEmail = (e) => {
    setNewEmail(e);
  };

  function allAreTruthy(arr) {
    return arr.every((element) => element);
  }

  const onSaveClick = () => {
    // debugger
    setSpinner(true);
    // console.log(typeof ("TYPE ", newEmail));
    if (newEmail) {
      if (typeof newEmail == "object") {
        emailArray = newEmail;
      } else {
        emailArray = newEmail.split(",");
      }
      if (emailArray.length > 0) {
        // eslint-disable-next-line array-callback-return
        emailArray.map((item) => {
          //eslint-disable-line
          // console.log(regXForEmail.test(item.trim()));
          if (regXForEmail.test(item.trim()) === false) {
            // debugger
            toastr.warning("Invalid Email", "Please enter a valid email");
            temp.push(false);
          } else {
            temp.push(true);
            // toggle()
            // setFacilityReload(facilityReload + 1)
            // window.location.reload();
          }
        });
      }
    } else {
      toastr.warning("Failed", "Please enter atleast one email ");
    }

    apiStatus = allAreTruthy(temp);
    if (allAreTruthy(temp)) {
      // console.log(allAreTruthy(temp));
      saveNotifications();
    }
    setSpinner(false);
  };

  const functionForNotification = () => {
    if (centralNotification === 1) {
      setCentralNotification(0);
    } else {
      setCentralNotification(1);
    }
  };

  const disableSaveButton = () => {
    if (newEmail.length <= 0) {
      setSaveButtonStatus(true);
    } else {
      setSaveButtonStatus(false);
    }
  };

  useEffect(() => {
    disableSaveButton();
  }, [newEmail]); // eslint-disable-line
  useEffect(() => {
    fetchFacilityByGroup();
    setFacilityReload(true);
  }, [facilityReload, centralNotification]); // eslint-disable-line
  /*  useEffect(() => {
    debugger
    if (apiStatus === true) {
      saveNotifications()
    }

  }, [apiStatus]) // eslint-disable-line */

  return (
    <div>
      <Modal isOpen={notificationPreferenceModal} size="lg">
        {spinner ? <Spinner /> : ""}
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Notification Preference
        </ModalHeader>
        <ModalBody className="overflow-modal">
          <div style={{ marginLeft: "0.70rem" }}>
            <br />
            <br />
            <br />
            <Row style={{ marginLeft: "2rem" }}>
              {/* <Col lg={2}></Col> */}

              <p>
                <b>Enable Central Notification</b>
              </p>

              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  onToggle={() => {
                    functionForNotification();
                  }}
                  value={centralNotification}
                />
              </Col>
            </Row>
            {centralNotification === 1 ? (
              <>
                <div id="myDIV" style={{ marginLeft: "2rem" }}>
                  <Label>Email :</Label>
                  {/* <div style={styleForReactEmail}>
                    <ReactMultiEmail
                      placeholder='Emails (demo1@xyz.com,demo2@xyz.com)'
                      // emails={emails}
                      onChange={(e) => {
                        changeOnEmail(e)
                      }}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                    </div> */}
                  <Input
                    placeholder="Emails (demo1@xyz.com,demo2@xyz.com)"
                    // isMulti
                    type="textarea"
                    value={newEmail}
                    onChange={(e) => {
                      changeOnEmail(e.target.value);
                    }}
                  />
                  {
                  // eslint-disable-next-line eqeqeq
                  newEmail == 0 ? (
                    <>
                      <div
                        style={{ float: "left", marginTop: "0px" }}
                        className="warning-label"
                      >
                        <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                        <Label className="warning-text">
                          Enter atleast 1 email
                        </Label>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </ModalBody>
        <ModalFooter className="advanced-search-button  custom-modal-footer">
          <Button
            // className="yes-button button-width"
            disabled={saveButtonStatus}
            onClick={() => {
              onSaveClick();
            }}
          >
            Save
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NotificationPreferenceModal;
