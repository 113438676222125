import React, { useEffect, useState, useContext } from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';

import Select from "react-select";
// eslint-disable-next-line no-unused-vars
import _ from "lodash";
//import { NULL } from 'node-sass';
import { GlobalState } from "../../contextAPI/Context"


const NewTemplateModal = (props) => {
    const { setNewTemplateModal, newTemplateModal,userDataReload,setUserDataReload,newGroupName,setNewGroupName,templateDropDown} = props;

    const { groupNamesForDropDown } = useContext(GlobalState); 

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    //const [newGroupName, setNewGroupName] = useState("");
  
    const [newTemplate, setNewTemplate] = useState("");
    



    const [addButtonStatus, setAddButtonStatus] = useState(true);
  

    const toggle = () => {
        setNewTemplateModal(!newTemplateModal);

        setNewGroupName("")
        setNewTemplate("")
       

    }



    const confirmation = () => {

        if (!newGroupName) {
            toastr.error("group Name Is Required", "Please enter the Group name!")
        }
        if (!newTemplate) {
            toastr.error("Template Name Field Is Required", "Please enter the Name!")
        }
       
       


        if (newGroupName && newTemplate) {

            ConfigureTemplateApiCall()
        }

    };

   
    

    const ConfigureTemplateApiCall = () => {
        // let gp_num = newCenterID ? newCenterID.value : ""
        // let gp_string = '' + gp_num
    
        // debugger
   
        let groupId_array = []
        if (newGroupName.length >=1){
             groupId_array = newGroupName.map(item => item.value)
        }
        
        const params = {
            "group_id": newGroupName ? groupId_array:null,
            "template_id":newTemplate ? newTemplate.value:null
     
           
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/configure-group-with-edoc/`, params, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(function (response) {
                setUserDataReload(userDataReload + 1)
                toastr.success("Successfully Configured", "Template successfully Configured")
                toggle();
            })
            .catch(function (error) {
                toastr.error("Failed", "Please try again")
            });
    }


    const disableAddButton = () => {

        if (newGroupName && newTemplate ) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }

   
  

  






   

   
   
    useEffect(() => { disableAddButton() }, [newGroupName,newTemplate]) // eslint-disable-line


    const onChangeGroup = (e) => {
        setNewGroupName(e);
      };

    return (
        <div>
            <Modal isOpen={newTemplateModal}  size="lg" scrollable={true} >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Configure New Group </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col sm={6}>
                      
              <Label>Group Name</Label>
              <Select
                    options={groupNamesForDropDown}
                    onChange={(event) => onChangeGroup(event)}
                    value={newGroupName}
                    placeholder="Select Group"
                    isSearchable
                    isClearable
                    isMulti
                  />
       

                            </Col>
                            <Col sm={6} >

                            <Label>Template Name</Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(event) => {
                  setNewTemplate(event);
                }}
                isClearable
                isSearchable
                placeholder="Select"
                value={newTemplate}
                options={templateDropDown}
              />
                            </Col>


                        </Row><br />
                        
                       
                           



                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        onClick={()=>{
                            confirmation()
                            
                        }}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Save</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewTemplateModal;