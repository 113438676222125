/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Label,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import AxiosInter from './../../AxiosInter.js';
import "./DateRangeSelector.css";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "react-select-virtualized";
import { toastr } from "react-redux-toastr";
import DeleteConfirmationModal from "../../newComponents/utilities/DeleteConfirmationModal";
// import RemotePagination from "../../newComponents/utilities/RemotePagination";// eslint-disable-line
import Cookie from 'js-cookie';

const ReferralSenderTableModal = (props) => {
  // DEPENDENCY VARIABLES
  const {
    setReferralSenderTabelModal,
    ReferralSenderTabelModal,
    referralOrderSenderID,
    referralOrderSenderGpName,
    groupDropDownROF,
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [spinner, setSpinner] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [isTable, setIsTable] = useState("table");
  const [referralSenderGroupTabel, setReferralSenderGroupTabel] = useState("");
  const [referralSenderFulfillerGP, setReferralSenderFulfillerGP] =
    useState("");
  const [isSelfEnabled, setIsSelfEnabled] = useState({
    value: "1",
    label: "Yes",
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [rofcId, setRofcId] = useState();
  const [totalSize, setTotalSize] = useState(0);

  const toggle = () => {
    setReferralSenderTabelModal(!ReferralSenderTabelModal);
    setIsTable("table");
  };

  const deleteMapping = () => {
    setSpinner(true);
    const params = {
      rofc_id: rofcId ? rofcId : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/delete-referral-order-sender-fulfiller-mapping/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        toastr.success("Success", "Deleted successfully");
        setSpinner(false);
        setDeleteModal(false);
        setApiLoaded(false);
        fetchReferralSenderGroup();
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const selfReferralValidation = (cell) => {
    // eslint-disable-next-line eqeqeq
    if (cell == 0) {
  
      return "No";
    // eslint-disable-next-line eqeqeq
    } else if (cell == 1) {
   
      return "Yes";
    } else {
      return "--";
    }
  };

  const actionFormatter = (cell, row) => {
    return (
      <div style={{ gap: "20px", display: "flex", justifyContent: "center" }}>
        <FontAwesomeIcon
          icon={faTrash}
          color={"#E12C2C"}
          size={"1x"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setRofcId(row.id);
            console.log("rof id", row.id);
            setDeleteModal(true);
          }}
          title="Delete"
        />
      </div>
    );
  };

  const YesNoOptions = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];

  const columns = [
    {
      dataField: "name",
      text: "Group Name",
      formatter: basicValidation,
    },
    {
      dataField: "isSelfReferral",
      text: "Is Self Referral",
      formatter: selfReferralValidation,
    },
    // {
    //   dataField: 'network_rank',
    //   text: 'Network Rank',
    //   formatter: basicValidation
    // },
    {
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const fetchReferralSenderGroup = () => {
    setSpinner(true);
    setApiLoaded(false);

    const params = {
      order_sender_Id: referralOrderSenderID
        ? referralOrderSenderID.toString()
        : "",
    };
    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-referral-sender-group/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then(function (response) {
        if (response.data.length > 0) {
          setReferralSenderGroupTabel(response.data);
          setApiLoaded(true);
          setSpinner(false);
          setIsTable("table");
          setTotalSize(response.data[0].totalcount);
        } else {
          setReferralSenderGroupTabel("");
          setApiLoaded(true);
          setSpinner(false);
          setIsTable("table");
          setTotalSize(0);
        }
      })
      .catch(function (error) {
        setReferralSenderGroupTabel("");
        setApiLoaded(true);
        setSpinner(false);
      });
  };

  const addReferralSenderFulfiller = () => {
    const params = {
      order_sender_id: referralOrderSenderID
        ? referralOrderSenderID.toString()
        : "",
      isSelfReferral: isSelfEnabled ? isSelfEnabled.value.toString() : "",
      group_id: referralSenderFulfillerGP
        ? referralSenderFulfillerGP.value.toString()
        : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/create-referral-sender-fulfiller/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        if (response.status === 201) {
          setSpinner(false);
          toastr.success("Success", "Referral Fulfiller Added Successfully");
          //setReferralSenderReload(referralSenderReload+1)
          fetchReferralSenderGroup();
          setReferralSenderFulfillerGP("");
        } else {
          toastr.error("Error", "something went wrong please try again");
          setReferralSenderFulfillerGP("");
          setSpinner(false);
          setIsTable("table");
        }
      })
      .catch(function (error) {
        toastr.error("Error", "Referral Fulfiller Not Added");
        setSpinner(false);
      });
  };

  useEffect(() => {
    if(ReferralSenderTabelModal){
      fetchReferralSenderGroup();
    }
  }, [referralOrderSenderID, ReferralSenderTabelModal]); // eslint-disable-line

  // useEffect(() => { debugger
  //   console.log("total",totalSize); }, [totalSize]) // eslint-disable-line

  return (
    <div>
      <div className="advanced-search-button">
        {spinner ? <Spinner /> : "Loading"}
      </div>
      <Modal
        /* className="modal" */ isOpen={ReferralSenderTabelModal}
        size="md"
      >
        {apiLoaded && isTable === "table" ? (
          <>
            <ModalHeader
              tag="h4"
              cssModule={{ "modal-title": "w-100 text-center", height: "50%" }}
              toggle={toggle}
            >
              Fulfillers for {referralOrderSenderGpName}
            </ModalHeader>
            <ModalBody>
              {isTable !== "add" || referralSenderGroupTabel.length === 0 ? (
                <div>
                  <Button
                    style={{ marginBottom: "-25px" }}
                    className="referral-add-btn ml-2 "
                    onClick={() => {
                      setIsTable("add");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      fixedWidth
                      className="align-middle"
                      title="Add Referral Fulfiller"
                    />
                    Referral Fulfiller
                  </Button>
                </div>
              ) : (
                ""
              )}
              <></>
              <></>
              {referralSenderGroupTabel && apiLoaded ? (
                <>
                  <br />

                  <br />
                  <Row>
                    <Col>
                      <div
                        /* style={{ maxHeight: "4.5%" }} */ className="group-list-referral"
                      >
                        {referralSenderGroupTabel && apiLoaded ? (
                          <>
                            <p>
                              Total Record Count:{totalSize ? totalSize : 0}
                            </p>
                            <BootstrapTable
                              keyField="id"
                              totalSize={totalSize ? totalSize : ""}
                              data={referralSenderGroupTabel}
                              columns={columns}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {!referralSenderGroupTabel && apiLoaded ? (
                        <Card style={{ marginTop: "32px" }}>
                          {" "}
                          <h4 className="no-data-found-card">
                            No Data Found
                          </h4>{" "}
                        </Card>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}

              {referralSenderGroupTabel.length === 0 &&
              apiLoaded &&
              !spinner ? (
                <>
                  <br />
                  <Row className="advanced-search-table-height">
                    <Col>
                      {!referralSenderGroupTabel && apiLoaded && !spinner ? (
                        <Card>
                          {" "}
                          <h4 className="no-data-found-card">
                            No Data Found
                          </h4>{" "}
                        </Card>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}
            </ModalBody>
          </>
        ) : (
          ""
        )}

        {isTable === "add" ? (
          <>
            <ModalHeader
              tag="h4"
              cssModule={{ "modal-title": "w-100 text-center", height: "50%" }}
              toggle={toggle}
            >
              {" "}
              Add Fulfillers for {referralOrderSenderGpName}
            </ModalHeader>
            <ModalBody>
              <>
                <br />
                <Row>
                  <Col>
                    <Label for="Group name">
                      Group Name <span className="required">*</span>
                    </Label>
                    <Select
                      placeholder="Select"
                      options={groupDropDownROF.map((item) => ({
                        value: item.value,
                        label: item.label,
                      }))}
                      value={referralSenderFulfillerGP}
                      onChange={(e) => {
                        setReferralSenderFulfillerGP(e);
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Label for="self Enabled">Is Self Enabled </Label>
                    <Select
                      placeholder="Select"
                      options={YesNoOptions}
                      value={
                        isSelfEnabled
                          ? isSelfEnabled
                          : { value: "1", label: "Yes" }
                      }
                      onChange={(e) => {
                        setIsSelfEnabled(e);
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      className="referral-sender-fulfiller-add-btn ml-2 "
                      onClick={() => {
                        addReferralSenderFulfiller();
                      }}
                    >
                      Add
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ borderColor: "grey", backgroundColor: "gray" }}
                      className="referral-sender-fulfiller-back-btn ml-2"
                      onClick={() => {
                        setIsTable("table");
                        setReferralSenderFulfillerGP("");
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </>
            </ModalBody>
          </>
        ) : (
          ""
        )}
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button outline className="no-button button-width" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <DeleteConfirmationModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteFunction={deleteMapping}
      />
    </div>
  );
};

export default ReferralSenderTableModal;
