import React from 'react';
import {
    CardHeader,
    Row,
    Col,
    Button,
    Input
} from "reactstrap";



const SearchBarComponent = (props) => {

    const { searchKeyword, totalSize, setSearchKeyword, clearValues,SupportedInsurance,OrderAutoProcess} = props;
    let placeholderText = "Search";
    if (SupportedInsurance) {
        placeholderText = "Search with NPI";
    } else if (OrderAutoProcess) {
        placeholderText = "Search with order number";
    }

    return (
                <CardHeader>
                    <div>
                    <Row>
                        <Col
                            sm={{
                                size: '3',
                            }}
                        >
                            <div className="number-of-records">
                                <p>Number of records: <strong>{totalSize ? totalSize : 0}</strong></p>
                            </div>
                        </Col>
                        <Col>
                            <div className='c-search-field form-inline justify-content-end'>
                                <div className='form-group mr-2'>
                              <Input
                                        autoFocus="autofocus"
                                        type="text"
                                        placeholder={placeholderText}
                                        value={searchKeyword}
                                        onChange={
                                            (e) => {
                                                setSearchKeyword(e.target.value)

                                            }
                                        }
                                    >

                                    </Input>
                                </div>
                                <Button
                                    className="clear-search-button"
                                    onClick={() => { clearValues(); }}

                                    disabled={searchKeyword ? false : true}
                                    title={searchKeyword ? "" : "Nothing To Clear"}
                                >Clear</Button>
                                {/* <MyExportCSV className="mb-2" {...props.csvProps} /> */}
                            </div>
                        </Col>
                       
                    </Row>
                    <div className="separator" />
                    <div className="float-right pull-right">
                    </div>
                     
                   
                    </div>
                   
                </CardHeader>
               
                
       

        );
}

export default SearchBarComponent;