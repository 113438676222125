import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";


import AsyncSelect from "react-select/async";
import Cookie from "js-cookie";


const EditRerferralOrderType = (props) => {
  const {
    userDataReload,
    setUserDataReload,
    editReferralOrderTypeModal,
    setEditReferralOrderTypeModal,
 
    editOrderCategoryPopulate,
    setEditOrderCategoryPopulate,
    editOrderTypePopulate,
    setEditOrderTypePopulate,
    editOrderTypeID,

    OrderCategoryloadOptions,
    
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [spinner, setSpinner] = useState(false);
  const [updateButtonStatus, setUpdateButtonStatus] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");



  const toggle = () => {
    setEditReferralOrderTypeModal(!editReferralOrderTypeModal);
  };

  const confirmation = () => {
  
    updateReferralOrderType();
    
  };

  const updateReferralOrderType = () => {

    setSpinner(true);
    const valuesOnly = editOrderCategoryPopulate.map(item =>(item.value.toString()));

    AxiosInter
      .patch(
        `${API_ENDPOINT}/admin-api/referral-order-types-crud/${editOrderTypeID}/`,
        {
          type: editOrderTypePopulate ? editOrderTypePopulate : null,
          category_type: editOrderCategoryPopulate ? valuesOnly : null,
          
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setEditReferralOrderTypeModal(!editReferralOrderTypeModal);
        setUserDataReload(userDataReload + 1);
        toastr.success(
          "Successfully Updated",
          "Order type details successfully updated"
        );
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error("Update Failed", "Please try again");
      });
  };

  const disableUpdateButton = () => {
    if (editOrderTypePopulate && editOrderCategoryPopulate?.length>0) {
      setUpdateButtonStatus(false);
    } else {
      setUpdateButtonStatus(true);
    }
  };

  useEffect(() => {
    disableUpdateButton();
  }, [editOrderTypePopulate,editOrderCategoryPopulate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  
  const handleOrderCategoryChange = (value) => {
    setEditOrderCategoryPopulate(value);
  };
 

  return (
    <div>
      <Modal isOpen={editReferralOrderTypeModal} size="lg" scrollable={true}>
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit Order Type
        </ModalHeader>
        <ModalBody className="">
          {spinner && <Spinner />}
          <FormGroup>
            <Row>
            <Col sm={4}>
                            
                            <Label for="state">Order Type</Label>
                                        <Input
                                            id="state"
                                            type = "text"
                                            value = {editOrderTypePopulate}
                                            
                                            placeholder="Order Type"
                                            onChange={
                                              (e) => 
                                              { console.log(e.target.value)
                                                setEditOrderTypePopulate(e.target.value)}
                                            }
                                           
                                        />
                            </Col>
            </Row>
            <Row>
            <Col sm={4}>
              <Label>Order Category :</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
              isMulti
                type="text"
                name="Order Category"
                placeholder="Search with at least 4 characters."
                value={editOrderCategoryPopulate}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleOrderCategoryChange}
                loadOptions={(inputValue) => OrderCategoryloadOptions(inputValue)}
              />
            </Col>
            </Row>
            
            <br />
           
          </FormGroup>
        </ModalBody>

        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button
            onClick={() => {
              confirmation();
            }}
            disabled={updateButtonStatus}
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditRerferralOrderType;
