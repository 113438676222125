
import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  Container,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  CardBody,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import ToggleButton from "react-toggle-button";
import AsyncSelect from "react-select/async";
import { GlobalState } from "../../contextAPI/Context";
import Cookie from 'js-cookie';

const EditAutoProcessExceptionRule = (props) => {
  const {
    reload,
    setReload,
    preAuthRequestType,
    setPreAuthRequestType,
    referralTypeOptions,
    setEditAutoProcessExceptionRule,
    editAutoProcessExceptionRule,
    selectedConditions,
    setSelectedConditions,
    setSelectedInsurances,
    selectedInsurances,
    referralRuleData,
    buttonBlankState,
    setButtonBlankState,
    ruleTypeOptions,
    loadServiceTypeOptions,
    loadDiagnosisOptions,
    loadProcedureOptions,
    ageOptions,
    genderOptions,
    loadConditionOptions,
    loadInsuranceOptions,
    requestTypeOptions,
    toggleValue,
    setToggleValue,
    additionalDocsOptions,
    editgroupId,
    editruleName,
    editreferralType,
    editpatientAgeCondition,
    editpatientCondition,
    editpatientInsurance,
    editserviceTypes,
    editorderPriority,
    editpatientGender,
    editdiagnosis,
    editprocedures,
    editprocessStatus,
    editcreatedDate,
    editupdatedDate,
    editfulfillerGroupId,
    editfulfillerFacilityId,
    editfulfillerPhysicianId,
    editmandatoryItems,
    editeligibilityCheck,
    editpreAuthCheck,
    editpreAuthRequestType,
    editotherGenerateDocs,
    editautoProcessEnable,
    editallowedDiagnosis,
    editallowedProcedure,
    editallowedInsurances,
    editallowedServiceTypes,
    editallowedPos,
    editexcludedDiagnosis,
    editexcludedProcedure,
    editexcludedInsurances,
    editexcludedServiceTypes,
    editadditionalKeyData,
    editruleType,
    setEditGroupId,
    setEditRuleName,
    setEditReferralType,
    setEditPatientAgeCondition,
    setEditPatientCondition,
    setEditPatientInsurance,
    setEditServiceTypes,
    setEditOrderPriority,
    setEditPatientGender,
    setEditDiagnosis,
    setEditProcedures,
    setEditProcessStatus,
    setEditCreatedDate,
    setEditCreatedBy,
    setEditUpdatedDate,
    setEditUpdatedBy,
    setEditFulfillerGroupId,
    setEditFulfillerFacilityId,
    setEditFulfillerPhysicianId,
    setEditMandatoryItems,
    setEditEligibilityCheck,
    setEditPreAuthCheck,
    setEditPreAuthRequestType,
    setEditOtherGenerateDocs,
    setEditAutoProcessEnable,
    setEditAllowedDiagnosis,
    setEditAllowedProcedure,
    setEditAllowedServiceTypes,
    setEditAllowedNoOfVisitors,
    setEditAllowedPos,
    setEditExcludedDiagnosis,
    setEditExcludedProcedure,
    setEditExcludedServiceTypes,
    setEditAdditionalKeyData,
    setEditRuleType,
    selectedServiceTypes,
    setSelectedServiceTypes,
    setSelectedDiagnosis,
    selectedDiagnosis,
    orderPriorityOptions,
    selectedOrderPriority,
    setSelectedOrderPriority,
    selectedProcedure,
    setSelectedProcedure,
    selectedFulfillerGroup,
    loadFulfillerGroupOptions,
    setSelectedFulfillerGroup,
    selectedFulfillerFacility,
    loadFacilityOptionsForFacility,
    setSelectedFulfillerFacility,
    selectedPhysicianIds,
    loadPhysicianOptions,
    setSelectedPhysicianIds,
    editPatientAge,
    allowedNoOfVisitors,
    setAllowedNoOfVisitors,
    visitNumbers,
    fetchAutoProcessExceptionRules,
    customNoOptionsMessage,
    rowId
  } = props;
  const { groupNamesForDropDown } = useContext(GlobalState);

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  

  const [selectedAge, setSelectedAge] = useState(""); // eslint-disable-line
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [selectedAgeCondition, setSelectedAgeCondition] = useState("");

  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);
  const [checkedFour, setCheckedFour] = useState(false);
  const [checkedFive, setCheckedFive] = useState(false);
  const [checkedSix, setCheckedSix] = useState(false);
  const [checkedSeven, setCheckedSeven] = useState(false);
  const [checkedEight, setCheckedEight] = useState(false);
  const [checkedNine, setCheckedNine] = useState(false);

  const [tempDiagnosis, setTempDiagnosis] = useState([]); // eslint-disable-line

  const [selectedAllowedServiceTypes, setSelectedAllowedServiceTypes] = useState([]);
  const [selectedExcludedServiceTypes, setSelectedExcludedServiceTypes] = useState([]);
  const [selectedAllowedDiagnosis, setSelectedAllowedDiagnosis] = useState([]);
  const [selectedExcludedDiagnosis, setSelectedExcludedDiagnosis] = useState([]);
  const [selectedAllowedProcedure, setSelectedAllowedProcedure] = useState([]);
  const [selectedExcludedProcedure, setSelectedExcludedProcedure] = useState([]);
  const [selectedAllowedInsurance, setSelectedAllowedInsurance] = useState([]);
  const [selectedExcludedInsurance, setSelectedExcludedInsurance] = useState([]);
  const [selectedadditionalDocs, setSelectedadditionalDocs] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setEditAutoProcessExceptionRule(!editAutoProcessExceptionRule);
    setEditGroupId();
    setEditRuleName("");
    setEditReferralType("");
    setEditPatientAgeCondition("");
    setEditPatientCondition("");
    setEditPatientInsurance("");
    setEditServiceTypes("");
    setEditOrderPriority("");
    setEditPatientGender("");
    setEditDiagnosis("");
    setEditProcedures("");
    setEditProcessStatus();
    setEditCreatedDate("");
    setEditCreatedBy();
    setEditUpdatedDate("");
    setEditUpdatedBy();
    setEditFulfillerGroupId("");
    setEditFulfillerFacilityId([]);
    setEditFulfillerPhysicianId([]);
    setEditMandatoryItems("");
    setEditEligibilityCheck();
    setEditPreAuthCheck();
    setEditPreAuthRequestType("");
    setEditOtherGenerateDocs("");
    setEditAutoProcessEnable();
    setEditAllowedDiagnosis("");
    setEditAllowedProcedure("");
    // setEditAllowedInsurances("");
    setEditAllowedServiceTypes("");
    setEditAllowedNoOfVisitors("");
    setEditAllowedPos();
    setEditExcludedDiagnosis("");
    setEditExcludedProcedure("");
    // setEditExcludedInsurances("");
    setEditExcludedServiceTypes([]);
    setEditAdditionalKeyData();
    setEditRuleType("");
    setSelectedAge("");
    setSelectedAgeCondition("");
    setSelectedProcedure("");
    setSelectedAllowedProcedure("");
    setSelectedExcludedProcedure("");
    setSelectedConditions("");
    setSelectedInsurances("");
    setSelectedAllowedServiceTypes("");
    setSelectedExcludedServiceTypes([]);
    setSelectedAllowedDiagnosis("");
    setSelectedExcludedDiagnosis("");
    setSelectedAllowedInsurance("");
    setSelectedExcludedInsurance("");
    setSelectedOrderPriority("");
    setCheckedOne("");
    setCheckedTwo("");
    setCheckedThree("");
    setCheckedFour("");
    setCheckedFive("");
    setCheckedSix("");
    setCheckedSeven("");
    setCheckedEight("");
    setCheckedNine("");
  };

  const handelOnChangeForReferralRuleItem = (e, item) => {
    let name = item.item;
    let itemId = item.id;
    if (name === "Guarantor Details") {
      setCheckedOne(e.target.checked);

      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Pre Auth") {
      //debugger;
      setCheckedTwo(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        tempDiagnosis.push(JSON.stringify(itemId));
        // console.log('284',editmandatoryItems.filter(n => !tempDiagnosis.includes(n)))
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
      }
    }
    if (name === "Lab Report") {
      setCheckedThree(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Diagnosis") {
      setCheckedFour(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedDiagnosis([]);
        setSelectedExcludedDiagnosis([]);
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Emergency Contact") {
      setCheckedFive(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Procedure") {
      setCheckedSix(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedProcedure([]);
        setSelectedExcludedProcedure([]);
        setAllowedNoOfVisitors([]);
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Referral reason") {
      setCheckedSeven(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "Insurance Details") {
      setCheckedEight(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedInsurance([]);
        setSelectedExcludedInsurance([]);
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
    if (name === "PCP Notes") {
      setCheckedNine(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        editmandatoryItems.push(JSON.stringify(itemId));
      } else {
        // const index = editmandatoryItems.indexOf(itemId);
        // if (index > -1) { // only splice array when item is found
        //     editmandatoryItems.splice(index, 1); // 2nd parameter means remove one item only
        // }
        tempDiagnosis.push(JSON.stringify(itemId));
      }
    }
  };

  const handelCheckedForReferralRuleItem = (item) => {
    // eslint-disable-next-line no-unused-vars
    let itemId = item.id;

    // eslint-disable-next-line eqeqeq
    if (item == "Guarantor Details") {
      return checkedOne ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Pre Auth") {
      return checkedTwo ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Lab Report") {
      return checkedThree ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Diagnosis") {
      return checkedFour ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Emergency Contact") {
      return checkedFive ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Procedure") {
      return checkedSix ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Referral reason") {
      return checkedSeven ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Insurance Details") {
      return checkedEight ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "PCP Notes") {
     
      return checkedNine ? true : false;
    }
  };

  // const patientAgePopulate = () => {
  //     if (selectedAge && selectedAgeCondition) {
  //         setEditPatientAgeCondition([{ "age": selectedAge, "max": "0", "min": "0", "condition": selectedAgeCondition.value }])
  //     }
  //     else if (selectedAgeCondition.label == "Between") {
  //         setEditPatientAgeCondition([{ "age": "0", "max": maxValue, "min": minValue, "condition": selectedAgeCondition }])
  //     }
  //     else {
  //         setEditPatientAgeCondition("")
  //     }
  // }
  // useEffect(() => {
  //     if (selectedAge && selectedAgeCondition)
  //         patientAgePopulate()

  // }, [selectedAge, selectedAgeCondition]) // eslint-disable-line

  const editAutoProcessExceptionRules = () => {
    // eslint-disable-next-line eqeqeq
    if (preAuthRequestType == "SC") {
      setPreAuthRequestType("Referral");
    // eslint-disable-next-line eqeqeq
    } else if (preAuthRequestType == "HS") {
      setPreAuthRequestType("outPatient Authorization");
    }

    // var temp = []
    // var orderPriorityArray = []
    // if (typeof orderPriority == 'object') {
    //     temp = orderPriority
    // } else {
    //     temp = orderPriority.split(",")
    // }
    // if (temp) {
    //     temp.map((item) => {
    //         orderPriorityArray.push(item.trim())
    //     })
    // }
    let admin_id = localStorage.getItem("adminID");

    if (selectedServiceTypes) {
      var serviceTypesResult = selectedServiceTypes.map((item) => {
        return item.value.toString();
      });
    }
    // let patientAgeConditionArray=selectedAge
    if (editPatientAge && selectedAgeCondition) {
      editpatientAgeCondition.length = 0;
      //  patientAgeConditionArray.push([{ "age": editPatientAge, "max": "0", "min": "0", "condition": selectedAgeCondition.value }])
      editpatientAgeCondition.push({
        age: selectedAge,
        max: "0",
        min: "0",
        condition: selectedAgeCondition.value,
      });
    // eslint-disable-next-line eqeqeq
    } else if (selectedAgeCondition.label == "Between") {
      // patientAgeConditionArray.push([{ "age": "0", "max": maxValue, "min": minValue, "condition": selectedAgeCondition }])
      editpatientAgeCondition.push({
        age: "0",
        max: maxValue,
        min: minValue,
        condition: selectedAgeCondition,
      });
    } else {
      setEditPatientAgeCondition("");
    }
    // eslint-disable-next-line eqeqeq
    if (selectedAgeCondition == "less than") {
      setSelectedAgeCondition("Less than");
    // eslint-disable-next-line eqeqeq
    } else if (selectedAgeCondition == "greater than") {
      setSelectedAgeCondition("Greater than");
    // eslint-disable-next-line eqeqeq
    } else if (selectedAgeCondition == "equal to") {
      setSelectedAgeCondition("Equal to");
    // eslint-disable-next-line eqeqeq
    } else if (selectedAgeCondition == "between") {
      setSelectedAgeCondition("Between");
    }
    // eslint-disable-next-line eqeqeq
    if (selectedAgeCondition.label == "Between") {
      setMaxValue(maxValue);
      setMinValue(minValue);
    }
    if (selectedDiagnosis) {
      var diagnosisResult = selectedDiagnosis.map((item) => {
        return item;
      });
    }
    if (selectedOrderPriority) {
      var orderPriorityResult = selectedOrderPriority.map((item) => {
        return item.value;
      });
    }
    if (selectedProcedure) {
      var selectedProcedureResult = selectedProcedure.map((item) => {
        return item;
      });
    }

    if (selectedFulfillerGroup) {
      var fulfillerGroupResult = selectedFulfillerGroup.map((item) => {
        return item.value.toString();
      });
    }
    if (selectedFulfillerFacility) {
      var fulfillerFacilityResult = selectedFulfillerFacility.map((item) => {
        return item.value.toString();
      });
    }
    if (selectedPhysicianIds) {
      var physicianResult = selectedPhysicianIds.map((item) => {
        return item.value.toString();
      });
    }
    if (selectedConditions) {
      var conditionResult = selectedConditions.map((item) => {
        return item;
      });
    }

    if (selectedInsurances) {
      var insuranceResult = selectedInsurances.map((item) => {
        return item.label;
      });
    }

    // if (selectedAllowedServiceTypes) {
    //     selectedAllowedServiceTypes.map((item) => {
    //         editallowedServiceTypes.push({ "id": item.value, "type": item.label, "is_clinical": 1, "is_transport": 0 })
    //     })
    // }
    if (selectedAllowedServiceTypes) {
      var allowedServiceTypesResult = selectedAllowedServiceTypes.map(
        (item) => {
          return {
            id: item.value,
            type: item.label,
            is_clinical: 1,
            is_transport: 0,
          };
        }
      );
    }
    if (selectedExcludedServiceTypes) {
      var excludedServiceTypesResult = selectedExcludedServiceTypes.map(
        (item) => {
          return {
            id: item.value,
            type: item.label,
            is_clinical: 1,
            is_transport: 0,
          };
        }
      );
    }
    // if (selectedExcludedServiceTypes) {
    //     selectedExcludedServiceTypes.map((item) => {
    //         editexcludedServiceTypes.push({ "id": item.value, "type": item.label, "is_clinical": 1, "is_transport": 0 })
    //     })
    // }
    if (selectedAllowedDiagnosis) {
      var allowedDiagnosisResult = selectedAllowedDiagnosis.map((item) => {
        return item;
      });
    }
    if (selectedExcludedDiagnosis) {
      var excludedDiagnosisResult = selectedExcludedDiagnosis.map((item) => {
        return item;
      });
    }
    if (selectedAllowedInsurance) {
      var allowedInsuranceResult = selectedAllowedInsurance.map((item) => {
        return item.label;
      });
    }
    if (selectedExcludedInsurance) {
      var excludedInsuranceResult = selectedExcludedInsurance.map((item) => {
        return item.label;
      });
    }
    if (selectedAllowedProcedure) {
      var allowedProcedureResult = selectedAllowedProcedure.map((item) => {
        return item;
      });
    }
    if (selectedExcludedProcedure) {
      var excludedProcedureResult = selectedExcludedProcedure.map((item) => {
        return item;
      });
    }
    if (toggleValue === false) {
      //debugger;
      setEditOtherGenerateDocs([]);
    }
    // var allowedNoOfValues = []
    // if (selectedAllowedProcedure && visitNumbers) {
    //     selectedAllowedProcedure.map((item) => {
    //         // //debugger
    //         allowedNoOfValues.push({ "procCode": item.code, "visitNumber": visitNumbers })
    //     })
    // }
    // let checkedValueArray = []
    // if (editmandatoryItems) {
    //     editmandatoryItems.map((item) => {
    //         // //debugger
    //         tempDiagnosis.push(item)
    //     })
    // }
    // const uniqueSet = new Set(tempDiagnosis);
    // const backToArray = [...uniqueSet];

    const editMandatoryItemsArray = editmandatoryItems.filter(
      (n) => !tempDiagnosis.includes(n)
    );

    let procedureCode = [];
    if (selectedAllowedProcedure) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedProcedure.map((item) => {
        // //debugger
        procedureCode.push(item.code);
      });
    }
    let allowedVisitsNumbers = [];
    // if (selectedAllowedProcedure&&allowedNoOfVisitors) {
    //     selectedAllowedProcedure.map((item) => {

    //         procedureCode.push(item.code)
    //     })
    // }
    let allowedNoOfVisitsarray = [];
    let temp1 = [];
    let zip = (a1, a2) => a1.map((x, i) => [x, a2[i]]);
    if (typeof allowedNoOfVisitors == "object") {
      allowedVisitsNumbers = allowedNoOfVisitors;
    } else {
      allowedVisitsNumbers = allowedNoOfVisitors.split(",");
    }
    if (allowedVisitsNumbers) {
      // eslint-disable-next-line array-callback-return
      allowedVisitsNumbers.map((item) => {
        temp1.push(item);
      });
    }
    let combined = zip(procedureCode, allowedVisitsNumbers);
    combined.map((items) =>
      allowedNoOfVisitsarray.push({ procCode: items[0], visitNumber: items[1] })
    );

    // //debugger
    const params = {
      id: rowId ? rowId :"",
      group_id: editgroupId ? editgroupId.value : "",
      rule_name: editruleName ? editruleName : "",
      referral_type: editreferralType ? editreferralType.value : "",
      patient_age_condition: editpatientAgeCondition ? editpatientAgeCondition : "",
      patient_condition: conditionResult,
      patient_insurance: insuranceResult,
      service_types: serviceTypesResult,
      order_priority: orderPriorityResult,
      patient_gender: editpatientGender ? editpatientGender.value : "",
      diagnosis: diagnosisResult,
      procedures: selectedProcedureResult,
      status: editprocessStatus ? 1 : 1,
      created_date: editcreatedDate ? editcreatedDate : "",
      created_by: admin_id ? admin_id : "",
      updated_date: editupdatedDate ? editupdatedDate : "",
      updated_by: admin_id ? admin_id : "",
      fulfiller_group_id: fulfillerGroupResult,
      fulfiller_facility_id: fulfillerFacilityResult,
      fulfiller_physician_id: physicianResult,
      mandatory_items: editmandatoryItems ? editMandatoryItemsArray : [],
      is_enable_eligiblity_check: editeligibilityCheck ? 1 : 0,
      is_enable_pre_auth_check: editpreAuthCheck ? 1 : 0,
      pre_auth_request_type: editpreAuthRequestType ? editpreAuthRequestType.value : "",
      other_generate_docs: editotherGenerateDocs ? editotherGenerateDocs : "",
      is_auto_process_enable: editautoProcessEnable ? 1 : 0,
      allowed_diagnosis: allowedDiagnosisResult,
      allowed_procedure: allowedProcedureResult,
      allowed_insurances: allowedInsuranceResult,
      allowed_service_types: allowedServiceTypesResult,
      allowed_no_of_visits: allowedNoOfVisitsarray ? allowedNoOfVisitsarray : "",
      allowed_pos: editallowedPos ? editallowedPos : "",
      excluded_diagnosis: excludedDiagnosisResult,
      excluded_procedure: excludedProcedureResult,
      excluded_insurances: excludedInsuranceResult,
      excluded_service_types: excludedServiceTypesResult,
      additional_key_data: editadditionalKeyData ? "" : "",
      rule_type: editruleType ? editruleType.value : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-auto-process-exception-rules/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        toastr.success(
          "Success",
          "Auto Process Exception Rule Conditions Updated"
        );
        toggle();
        setReload(reload + 1);
        fetchAutoProcessExceptionRules();
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.success(
            "Success",
            "Auto Process Exception Rule Conditions Updated"
          );
          toggle();
          setReload(reload + 1);
          fetchAutoProcessExceptionRules();
        }
      });
  };

  // const populateServiceType = () => {
  //   var temp = editserviceTypes;
  //   if (editserviceTypes) {
  //     var res = [];
  //     // eslint-disable-next-line array-callback-return
  //     temp.map((item) => {
  //       fetch(
  //         `${API_ENDPOINT}/admin-api/fetch-order-type/${"null"}/${Number(
  //           item
  //         )}`,
  //         {
  //           headers: {
  //             Authorization: API_TOKEN,
  //           },
  //         }
  //       )
  //         .then((response) => response.json())
  //         .then((data) => res.push(data.data[0]));
  //     });
  //   }
  //   setSelectedServiceTypes(res);
  // };

  const populateServiceType = () => {
    var temp = editserviceTypes;
    if (editserviceTypes) {
      var res = [];
      Promise.all(temp.map((item) =>
      AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-order-type/${"null"}/${Number(item)}`,
              {
                headers: {
                  Authorization: API_TOKEN,
                },
              }
            )
            .then((response) => response.data)
        )
      ).then((dataList) => {
        res = dataList.map((data) => data.data[0]);
        setSelectedServiceTypes(res);
      });
    }
  };

   // const populateGroupId = () => {
  //   // //debugger
  //   var tempGroupId = editfulfillerGroupId;
  //   if (editfulfillerGroupId) {
  //     var resGroupId = [];
  //     // eslint-disable-next-line array-callback-return
  //     tempGroupId.map((item) => {
  //       fetch(
  //         `${API_ENDPOINT}/admin-api/get-fulfiller-group-by-name/${"null"}/${Number(
  //           item
  //         )}`,
  //         {
  //           headers: {
  //             Authorization: API_TOKEN,
  //           },
  //         }
  //       )
  //         .then((response) => response.json())
  //         .then((data) => resGroupId.push(data.data[0]));
  //     });
  //   }
  //   setSelectedFulfillerGroup(resGroupId);
  // };

  const populateGroupId = () => {
    var tempGroupId = editfulfillerGroupId;
    if (editfulfillerGroupId) {
      var resGroupId = [];
      Promise.all(
        tempGroupId.map((item) =>
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-fulfiller-group-by-name/${"null"}/${Number(item)}`, {
            headers: {
              Authorization: API_TOKEN,
            },
          })
          .then((response) => response.data)
        )
      ).then((dataList) => {
        resGroupId = dataList.map((data) => data.data[0]);
        setSelectedFulfillerGroup(resGroupId);
      });
    }
  };
  
  const populatePhysicianId = () => {
    var tempPhysicianId = editfulfillerPhysicianId;
    if (editfulfillerPhysicianId) {
      var resPhysicianId = [];
      Promise.all(
        tempPhysicianId.map((item) =>
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-physician-data/${"null"}/${Number(item)}`, {
            headers: {
              Authorization: API_TOKEN,
            },
          })
          .then((response) => response.data)
        )
      ).then((dataList) => {
        resPhysicianId = dataList.map((data) => data.data[0]);
        setSelectedPhysicianIds(resPhysicianId);
      });
    }
  };
  

  const populateFacilityId = () => {
    var tempFacilityId = editfulfillerFacilityId;
    if (editfulfillerFacilityId) {
      var resFacilityId = [];
      Promise.all(
        tempFacilityId.map((item) =>
        AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-fulfiller-facility-by-fulfiller-name/${"null"}/${Number(item)}`, {
            headers: {
              Authorization: API_TOKEN,
            },
          })
          .then((response) => response.data)
        )
      ).then((dataList) => {
        resFacilityId = dataList.map((data) => data.data[0]);
        setSelectedFulfillerFacility(resFacilityId);
      });
    }
  };
  
// const populatePhysicianId = () => {
//     // //debugger
//     var tempPhysicianId = editfulfillerPhysicianId;
//     if (editfulfillerPhysicianId) {
//       var resPhysicianId = [];
//       // eslint-disable-next-line array-callback-return
//       tempPhysicianId.map((item) => {
//         fetch(
//           `${API_ENDPOINT}/admin-api/get-physician-data/${"null"}/${Number(
//             item
//           )}`,
//           {
//             headers: {
//               Authorization: API_TOKEN,
//             },
//           }
//         )
//           .then((response) => response.json())
//           .then((data) => resPhysicianId.push(data.data[0]));
//       });
//     }
//     setSelectedPhysicianIds(resPhysicianId);
//   };

//   const populateFacilityId = () => {
//     var tempFacilityId = editfulfillerFacilityId;
//     if (editfulfillerFacilityId) {
//       var resFacilityId = [];
//       // eslint-disable-next-line array-callback-return
//       tempFacilityId.map((item) => {
//         fetch(
//           `${API_ENDPOINT}/admin-api/fetch-fulfiller-facility-by-fulfiller-name/${"null"}/${Number(
//             item
//           )}`,
//           {
//             headers: {
//               Authorization: API_TOKEN,
//             },
//           }
//         )
//           .then((response) => response.json())
//           .then((data) => resFacilityId.push(data.data[0]));
//       });
//     }
//     setSelectedFulfillerFacility(resFacilityId);
//   };

  const validate = () => {
    if (editruleName) {
      setButtonBlankState(false);
    } else {
      setButtonBlankState(true);
    }
  };
  useEffect(() => {
    validate();
  }, [editruleName, editreferralType]); // eslint-disable-line

  const setInitialReferralType = () => {
    // eslint-disable-next-line array-callback-return
    referralTypeOptions.map((data) => {
      // eslint-disable-line
      if (data.value === editreferralType) {
        setEditReferralType({ value: data.value, label: data.label });
      }
    });
  };
  const setInitialRuleType = () => {
    // eslint-disable-next-line array-callback-return
    ruleTypeOptions.map((data) => {
      // eslint-disable-line
      if (data.value === editruleType) {
        setEditRuleType({ value: data.value, label: data.label });
      }
    });
  };
  const setInitialGender = () => {
    // eslint-disable-next-line array-callback-return
    genderOptions.map((data) => {
      // eslint-disable-line
      if (data.value === editpatientGender) {
        setEditPatientGender({ value: data.value, label: data.label });
      }
    });
  };
  const setInitialAgeConditions = () => {
    //debugger
    // eslint-disable-next-line array-callback-return
    if (ageOptions.length > 0) {
    ageOptions.map((data) => {// eslint-disable-line
      
      if (data.value === editpatientAgeCondition != "" ? editpatientAgeCondition[0]["condition"] :"") {// eslint-disable-line
        setSelectedAgeCondition({ value: data.value, label: data.label });
      }
    });
  }
  };
  const setInitialRequestType = () => {
    // eslint-disable-next-line array-callback-return
    requestTypeOptions.map((data) => {
      // eslint-disable-line
      if (data.value === editpreAuthRequestType) {
        setEditPreAuthRequestType({ value: data.value, label: data.label });
      }
    });
  };

  const setInitialGroupName = () => {
    // eslint-disable-next-line array-callback-return
    groupNamesForDropDown.map((data) => {
      if (data.value === Number(editgroupId)) {
        // console.log(data.value,data.label)
        setEditGroupId({ value: data.value, label: data.label });
        // var x=JSON.parse(editpatientAgeCondition)
        // console.log('510',x[0]["age"])
      }
    });
  };

  const setInitialPriority = () => {
    var array = [];
    if (editorderPriority.length > 0) {
      // eslint-disable-next-line array-callback-return
      editorderPriority.map((item) => {
        // eslint-disable-line
        array.push({ value: item, label: item });
      });
    }
    setSelectedOrderPriority(array);
  };

  const setInitialDocs = () => {
    var array = [];
    // eslint-disable-next-line array-callback-return
    additionalDocsOptions.map((item) => {
      // eslint-disable-line
      array.push({ value: item.value, label: item.label });
    });

    setSelectedadditionalDocs(array);
  };
  const setCheckedOneValue = () => {
    // var array = []
    if (
      typeof editmandatoryItems != "undefined" &&
      // eslint-disable-next-line eqeqeq
      editmandatoryItems != "" &&
      editmandatoryItems != null
    ) {
      // eslint-disable-next-line array-callback-return
      editmandatoryItems.map((item) => {
        // eslint-disable-line
        // eslint-disable-next-line eqeqeq
        if (item == "1") {
          setCheckedOne(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "2") {
          setCheckedTwo(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "3") {
          setCheckedThree(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "4") {
          setCheckedFour(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "5") {
          setCheckedFive(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "6") {
          setCheckedSix(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "7") {
          setCheckedSeven(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "8") {
          setCheckedEight(true);
        }
        // eslint-disable-next-line eqeqeq
        if (item == "9") {
          setCheckedNine(true);
        }
      });
    }
  };

  useEffect(() => {
    setCheckedOneValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editmandatoryItems]);

  const populatePatientInsurance = () => {
    //debugger
    var array = [];
    if (editpatientInsurance != null && editpatientInsurance.length > 0) {
      // eslint-disable-next-line array-callback-return
      editpatientInsurance.map((item) => {
        // eslint-disable-line
        array.push({ value: item, label: item });
      });
    }
    setSelectedInsurances(array);
  };
  const populateAllowedInsurance = () => {
    //debugger
    var array = [];
    if (editallowedInsurances != null && editallowedInsurances.length > 0) {
    // eslint-disable-next-line array-callback-return
    editallowedInsurances.map((item) => {
      // eslint-disable-line
      array.push({ value: item, label: item });
    });
  }
    setSelectedAllowedInsurance(array);
  };
  const populateExcludedInsurance = () => {
    //debugger
    var array = [];
    if (editexcludedInsurances != null && editexcludedInsurances.length > 0) {
    // eslint-disable-next-line array-callback-return
    editexcludedInsurances.map((item) => {
      // eslint-disable-line
      array.push({ value: item, label: item });
    });
  }
    setSelectedExcludedInsurance(array);
  };

  const populatePatientCondition = () => {
    //debugger
    var array = [];
    if (editpatientCondition.length > 0) {
      // eslint-disable-next-line array-callback-return
      editpatientCondition.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, category: item.category });
      });
    }
    setSelectedConditions(array);
  };
  const populateAllowedServiceTypes = () => {
    var array = [];
    if (editallowedServiceTypes != null && editallowedServiceTypes.length > 0) {
      // eslint-disable-next-line array-callback-return
      editallowedServiceTypes.map((item) => {
        // eslint-disable-line
        array.push({ value: item.id, label: item.type });
      });
    }
    setSelectedAllowedServiceTypes(array);
  };
  const populateExcludedServiceTypes = () => {
    //debugger
    var array = [];
    if (editexcludedServiceTypes != null && editexcludedServiceTypes.length > 0) {
      // eslint-disable-next-line array-callback-return
      editexcludedServiceTypes.map((item) => {
        // eslint-disable-line
        array.push({ value: item.id, label: item.type });
      });
    }
    setSelectedExcludedServiceTypes(array);
  };
  const populateDiagnosis = () => {
    //debugger
    var array = [];
    if (editdiagnosis.length > 0) {
      // eslint-disable-next-line array-callback-return
      editdiagnosis.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code: item.code, code_show: item.code_show });
      });
    }
    setSelectedDiagnosis(array);
  };
  const populateAllowedDiagnosis = () => {
    //debugger
    var array = [];
    if (editallowedDiagnosis != null && editallowedDiagnosis.length > 0) {
      // eslint-disable-next-line array-callback-return
      editallowedDiagnosis.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code_show: item.code_show });
      });
    }
    setSelectedAllowedDiagnosis(array);
  };
  const populateExcludedDiagnosis = () => {
    //debugger
    var array = [];
    if (editexcludedDiagnosis != null && editexcludedDiagnosis.length > 0) {
      // eslint-disable-next-line array-callback-return
      editexcludedDiagnosis.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code_show: item.code_show });
      });
    }
    setSelectedExcludedDiagnosis(array);
  };
  const populateProcedure = () => {
    //debugger
    var array = [];
    if (editprocedures != null && editprocedures.length > 0) {
      // eslint-disable-next-line array-callback-return
      editprocedures.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code: item.code, code_show: item.code_show });
      });
    }
    setSelectedProcedure(array);
  };
  const populateAllowedProcedure = () => {
    //debugger
    var array = [];
    if (editallowedProcedure != null && editallowedProcedure.length > 0) {
      // eslint-disable-next-line array-callback-return
      editallowedProcedure.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code: item.code, code_show: item.code_show });
      });
    }
    setSelectedAllowedProcedure(array);
  };
  const populateExcludedProcedure = () => {
    //debugger
    var array = [];
    if (editexcludedProcedure != null && editexcludedProcedure.length > 0) {
      // eslint-disable-next-line array-callback-return
      editexcludedProcedure.map((item) => {
        // eslint-disable-line
        array.push({ id: item.id, code: item.code, code_show: item.code_show });
      });
    }
    setSelectedExcludedProcedure(array);
  };
  const setInitialFulfillerGroupNames = () => {
    var array = [];
    if (editfulfillerGroupId != null && editfulfillerGroupId.length > 0) {
      // eslint-disable-next-line array-callback-return
      editfulfillerGroupId.map((item) => {
        // eslint-disable-line
        array.push({ value: item, label: item });
      });
    }
    setSelectedFulfillerGroup(array);
  };
  const setInitialFulfillerFacilityNames = () => {
    //debugger
    var array = [];
    if (editfulfillerFacilityId != null && editfulfillerFacilityId.length > 0) {
    // eslint-disable-next-line array-callback-return
    editfulfillerFacilityId.map((item) => {
      // eslint-disable-line
      array.push({ value: item, label: item });
    });
  }
    setSelectedFulfillerFacility(array);
  };
  const setInitialPhysicianNames = () => {
    //debugger
    var array = [];
    // eslint-disable-next-line array-callback-return
    if (editfulfillerPhysicianId != null && editfulfillerPhysicianId.length > 0) {
    editfulfillerPhysicianId.map((item) => {// eslint-disable-line
      
      array.push({ value: item, label: item });
    });
  }
    setSelectedPhysicianIds(array);
  };
  // useEffect(()=>{
  //     if(selectedInsurances.length<=0){
  //         setEditAllowedPos();
  //         setEditPreAuthRequestType("");
  //         setEditEligibilityCheck();
  //         setEditPreAuthCheck();
  //     }
  // })

  useEffect(() => {
    setInitialPriority();
    setInitialFulfillerGroupNames();
    setInitialFulfillerFacilityNames();
    setInitialPhysicianNames();
    populateExcludedServiceTypes();
    populateAllowedServiceTypes();
    populateDiagnosis();
    populateAllowedDiagnosis();
    populateExcludedDiagnosis();
    populateExcludedProcedure();
    populateAllowedProcedure();
    populateProcedure();
    setInitialDocs();
    populatePatientInsurance();
    populatePatientCondition();
    populateAllowedInsurance();
    populateExcludedInsurance();
    // setInitialCondition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editorderPriority,
    editfulfillerGroupId,
    editfulfillerFacilityId,
    editfulfillerPhysicianId,
    editexcludedServiceTypes,
    editallowedServiceTypes,
    editallowedDiagnosis,
    editexcludedDiagnosis, // eslint-disable-line
    editdiagnosis,
    editexcludedProcedure,
    editallowedProcedure,
    editprocedures,
    editotherGenerateDocs,
    editpatientInsurance,
    editpatientCondition,
    editallowedInsurances,
    editexcludedInsurances,
  ]);

  useEffect(() => {
    if (editotherGenerateDocs) {
      setToggleValue(0);
    }
  }, [editotherGenerateDocs]); // eslint-disable-line
  // useEffect(() => {
  //     if ( toggleValue ==false) {
  //         //debugger
  //      setSelectedadditionalDocs([]) }
  // }, [])          // eslint-disable-line

  useEffect(() => {
    if (
      editAutoProcessExceptionRule &&
      referralTypeOptions &&
      ruleTypeOptions &&
      genderOptions &&
      requestTypeOptions &&
      groupNamesForDropDown &&
      ageOptions
    ) {
      setInitialRuleType();
      setInitialReferralType();
      setInitialGender();
      setInitialRequestType();
      setInitialGroupName();
      setInitialAgeConditions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editAutoProcessExceptionRule,
    referralTypeOptions,
    ruleTypeOptions,
    genderOptions,
    requestTypeOptions,
    groupNamesForDropDown,
    ageOptions,
  ]); // eslint-disable-line

  // const ageValue =()=>{
  //     var x=JSON.parse(editpatientAgeCondition)
  //     setSelectedAge(x[0]["age"])
  // }

  useEffect(() => {
    populateServiceType();
    populateGroupId();
    populatePhysicianId();
    populateFacilityId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editserviceTypes,
    editfulfillerGroupId,
    editfulfillerPhysicianId,
    editfulfillerFacilityId,
  ]); // eslint-disable-line
  // useEffect(() => {
  //     populateGroupId()
  // }, [editfulfillerGroupId])   // eslint-disable-line
  // useEffect(() => {
  //     populatePhysicianId()
  // }, [editfulfillerPhysicianId])   // eslint-disable-line
  // useEffect(() => {
  //     populateFacilityId()
  // }, [editfulfillerFacilityId])   // eslint-disable-line

  return (
    <div>
      <Modal isOpen={editAutoProcessExceptionRule} size="lg" >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          {" "}
          Edit Auto Process Exception Rule Conditions{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal">
          <Container fluid>
            <FormGroup>
              <div className="roles-list-card card">
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="groups">
                        Select Group<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        id="groups"
                        type="text"
                        placeholder="Select Groups"
                        options={groupNamesForDropDown}
                        value={editgroupId}
                        onChange={(e) => {
                          setEditGroupId(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Label for="apply_for">
                        Apply For<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        id="apply_for"
                        type="text"
                        placeholder="Select Referral Type"
                        options={referralTypeOptions}
                        value={editreferralType}
                        onChange={(e) => {
                          setEditReferralType(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col>
                      <Label for="name">
                        Name<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="name"
                        type="text"
                        placeholder="Name"
                        value={editruleName}
                        onChange={(e) => {
                          setEditRuleName(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col>
                      <Label for="ruleType">
                        Rule Type<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        id="ruleType"
                        type="text"
                        placeholder="Select Rule Type"
                        options={ruleTypeOptions}
                        value={editruleType}
                        onChange={(e) => {
                          setEditRuleType(e);
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details
                    open={true}
                    // onKeyDown={handleKey}
                    onClick={() => setOpen((o) => !o)}
                  >
                    <summary>
                      <h6 className="role-list-head-custom">Order Details</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="serviceType">
                          Service Type<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Select Service Type"
                          noOptionsMessage={customNoOptionsMessage}
                          value={selectedServiceTypes}
                          loadOptions={loadServiceTypeOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedServiceTypes(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="diagnosis">
                          Diagnosis<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Diagnosis"
                          noOptionsMessage={customNoOptionsMessage}
                          defaultValue={selectedDiagnosis}
                          loadOptions={loadDiagnosisOptions}
                          getOptionLabel={(e) => e.code_show}
                          getOptionValue={(e) => e.id}
                          getOptionCode={(e) => e.code}
                          onChange={(e) => {
                            setSelectedDiagnosis(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="priority">Priority:</Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          isMulti
                          id="priority"
                          type="text"
                          placeholder="Select Priority"
                          options={orderPriorityOptions}
                          // value={[{value: editorderPriority,label: editorderPriority}]}
                          value={selectedOrderPriority}
                          onChange={(e) => {
                            setSelectedOrderPriority(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="procedure">Procedure:</Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Procedure"
                          noOptionsMessage={customNoOptionsMessage}
                          value={selectedProcedure}
                          loadOptions={loadProcedureOptions}
                          getOptionLabel={(e) => e.code_show}
                          getOptionValue={(e) => e.id}
                          getOptionCode={(e) => e.code}
                          onChange={(e) => {
                            setSelectedProcedure(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="fulfillerGroup">Fulfiller Group:</Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Fulfiller Group"
                          noOptionsMessage={customNoOptionsMessage}
                          defaultValue={selectedFulfillerGroup}
                          loadOptions={loadFulfillerGroupOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedFulfillerGroup(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    {selectedFulfillerGroup ? (
                      <>
                        <Row>
                          <Col>
                            <Label for="fulfillerFacility">
                              Fulfiller Facility:
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              isSearchable
                              placeholder="Fulfiller Facility"
                              noOptionsMessage={customNoOptionsMessage}
                              value={selectedFulfillerFacility}
                              loadOptions={loadFacilityOptionsForFacility}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              onChange={(e) => {
                                setSelectedFulfillerFacility(e);
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Label for="fulfillerPhysician">
                              Fulfiller Physician:
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              isSearchable
                              placeholder="Fulfiller Physician"
                              noOptionsMessage={customNoOptionsMessage}
                              value={selectedPhysicianIds}
                              loadOptions={loadPhysicianOptions}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              onChange={(e) => {
                                setSelectedPhysicianIds(e);
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details open={true}>
                    <summary>
                      <h6 className="role-list-head-custom">Patient Details</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="age">
                          Age<span className="">:</span>
                        </Label>
                      </Col>
                      <Col xs="4">
                        <Select
                          id="age"
                          type="text"
                          placeholder="Select"
                          options={ageOptions}
                          value={selectedAgeCondition}
                          onChange={(e) => {
                            setSelectedAgeCondition(e);
                          }}
                        />
                      </Col>
                      {selectedAgeCondition.label !== "Between" ? (
                        <>
                          <Col xs="4">
                            <Input
                              id="age"
                              type="text"
                              placeholder="Age"
                              // value={editpatientAgeCondition ? editpatientAgeCondition[0]["age"] : ""}
                              defaultValue={editPatientAge}
                              onChange={(e) => {
                                setSelectedAge(e.target.value);
                              }}
                            />
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      {
                      // eslint-disable-next-line eqeqeq
                      selectedAgeCondition.label == "Between" ? (
                        <>
                          <Col xs="2">
                            <Input
                              id="age"
                              type="text"
                              placeholder="Min"
                              value={minValue}
                              onChange={(e) => {
                                setMinValue(e.target.value);
                              }}
                            />
                          </Col>
                          <Col xs="2">
                            <Input
                              id="age"
                              type="text"
                              placeholder="Max"
                              value={maxValue}
                              onChange={(e) => {
                                setMaxValue(e.target.value);
                              }}
                            />
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                    <br />

                    <Row>
                      <Col>
                        <Label for="gender">
                          Gender<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          id="gender"
                          type="text"
                          placeholder="Gender"
                          options={genderOptions}
                          value={editpatientGender}
                          onChange={(e) => {
                            setEditPatientGender(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="condition">
                          Condition<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Conditions"
                          noOptionsMessage={customNoOptionsMessage}
                          defaultValue={selectedConditions}
                          loadOptions={loadConditionOptions}
                          getOptionLabel={(e) => e.category}
                          getOptionValue={(e) => e.id}
                          onChange={(e) => {
                            setSelectedConditions(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details open={true}>
                    <summary>
                      <h6 className="role-list-head-custom">Financial Data</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="payor">
                          Payor<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Select Insurance"
                          noOptionsMessage={customNoOptionsMessage}
                          value={selectedInsurances}
                          loadOptions={loadInsuranceOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedInsurances(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    {selectedInsurances.length > 0 ? (
                      <>
                        <div style={{ paddingLeft: "37%" }}>
                          <Row>
                            <Col sm={4}>
                              <Input
                                id="eligibility_checkbox"
                                type="checkbox"
                                checked={editeligibilityCheck}
                                onChange={(e) => {
                                  setEditEligibilityCheck(e.target.checked);
                                }}
                              />
                              <Label for="eligibility_checkbox">
                                {" "}
                                Enable Eligibility Check
                              </Label>
                            </Col>
                          </Row>
                          <Row>
                            {
                              // eslint-disable-next-line eqeqeq
                            editeligibilityCheck == true ? (
                              <>
                                <Col sm={4}>
                                  <Input
                                    id="preAuth_checkbox"
                                    type="checkbox"
                                    checked={editpreAuthCheck}
                                    onChange={(e) => {
                                      setEditPreAuthCheck(e.target.checked);
                                    }}
                                  />

                                  <Label for="preAuth_checkbox">
                                    {" "}
                                    Enable Pre-Auth Check
                                  </Label>
                                </Col>
                              </>
                            ) : (
                              ""
                            )}
                            {editeligibilityCheck &&
                            // eslint-disable-next-line eqeqeq
                            editpreAuthCheck == true ? (
                              <>
                                <Col sm={6}>
                                  <Label for="requestType">
                                    Request Type<span className="">:</span>
                                  </Label>
                                  <Select
                                    id="requestType"
                                    type="text"
                                    placeholder="Request Type"
                                    options={requestTypeOptions}
                                    value={editpreAuthRequestType}
                                    onChange={(e) => {
                                      setEditPreAuthRequestType(e);
                                    }}
                                  />
                                </Col>
                                <Col sm={2}>
                                  <Label for="pos">
                                    POS<span className="">:</span>
                                  </Label>
                                  <Input
                                    id="pos"
                                    type="text"
                                    placeholder="pos"
                                    value={editallowedPos}
                                    onChange={(e) => {
                                      setEditAllowedPos(e.target.value);
                                    }}
                                  />
                                </Col>
                              </>
                            ) : (
                              ""
                            )}
                          </Row>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </details>
                </CardBody>
              </div>

              <div className="roles-list-card card">
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="">
                        Requires Add-on documents with Order
                        <span className="">:</span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <ToggleButton
                        inactiveLabel={""}
                        activeLabel={""}
                        value={toggleValue}
                        onToggle={() => {
                          if (editotherGenerateDocs?.length>0) {
                            setToggleValue(!toggleValue);
                          } else {
                            setToggleValue(!toggleValue);
                            setSelectedadditionalDocs([])

                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  {
                  // eslint-disable-next-line eqeqeq
                  toggleValue == 1 ? (
                    <>
                      <Row>
                        <Col>
                          <Label for="additionalDocuments">
                            Additional Documents:
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <Select
                            isClearable
                            id="additionalDocuments"
                            type="text"
                            placeholder="Select Documents"
                            options={additionalDocsOptions}
                            defaultValue={selectedadditionalDocs}
                            onChange={(e) => {
                              setSelectedadditionalDocs(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details open={true}>
                    <summary>
                      <h6 className="role-list-head-custom">
                        Required Fields<span className="required">*</span>
                      </h6>
                    </summary>

                    {/* ////////////////////////////////////////////////////////////////////////////////// */}
                    {/* //////////////////////////// NEW-render ReferralRule ///////////////////////////// */}
                    {/* ////////////////////////////////////////////////////////////////////////////////// */}
                    <Container fluid>
                      <Row>
                        <Col>
                          <Input
                            id="serviceType_checkbox"
                            type="checkbox"
                            checked={true}
                            onChange={(e) => {
                              setEditMandatoryItems(e.target.checked);
                            }}
                          />
                          <Label for="serviceType_checkbox">Service Type</Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Label for="serviceType">
                            Allowed Service Type<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select Service Type"
                            noOptionsMessage={customNoOptionsMessage}
                            value={selectedAllowedServiceTypes}
                            loadOptions={loadServiceTypeOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedAllowedServiceTypes(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col>
                          <Label for="excludedServiceType">
                            Excluded Service Type<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select Service Type"
                            noOptionsMessage={customNoOptionsMessage}
                            value={selectedExcludedServiceTypes}
                            loadOptions={loadServiceTypeOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedExcludedServiceTypes(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />

                      <>
                        {referralRuleData.length > 0
                          ? referralRuleData.map((item) => {
                              return (
                                <>
                                  <Row>
                                    <Col>
                                      <Input
                                        id={item.item}
                                        type="checkbox"
                                        checked={handelCheckedForReferralRuleItem(
                                          item.item
                                        )}
                                        defaultValue={handelCheckedForReferralRuleItem(
                                          item.item
                                        )}
                                        onChange={(e) => {
                                          // //debugger
                                          handelOnChangeForReferralRuleItem(
                                            e,
                                            item
                                          );
                                        }}
                                      />
                                      <Label for={item.item}>
                                        {
                                        // eslint-disable-next-line eqeqeq
                                        item.item == "Original Referral"
                                          ? "PCP Notes"
                                          : item.item}
                                      </Label>
                                    </Col>
                                  </Row>

                                  {checkedFour && item.item === "Diagnosis" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Diagnosis
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Diagnosis"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedAllowedDiagnosis}
                                            loadOptions={loadDiagnosisOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedAllowedDiagnosis(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Diagnosis
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Diagnosis"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedExcludedDiagnosis}
                                            loadOptions={loadDiagnosisOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedExcludedDiagnosis(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {checkedSix && item.item === "Procedure" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Procedure
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Allowed Procedure"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedAllowedProcedure}
                                            loadOptions={loadProcedureOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedAllowedProcedure(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <div id="allowedVisits">
                                        {/* {selectedAllowedProcedure.length > 0 ? */}

                                        {/* selectedAllowedProcedure.map((item)=>{ */}
                                        {/* return(<> */}
                                        <Row>
                                          <Col>
                                            <Label for="excludedServiceType">
                                              Allowed No. of Visits
                                              <span className="">:</span>
                                            </Label>
                                          </Col>
                                          <Col sm={8}>
                                            <Input
                                              id=""
                                              type="text"
                                              placeholder="allowed no of visits (1,2,3...)"
                                              defaultValue={visitNumbers}
                                              onChange={(e) => {
                                                // //debugger
                                                // this.setAllowedNoOfVisitors(prevState => ({ allowedNoOfVisitors: { ...prevState, e.target.value } }))

                                                setAllowedNoOfVisitors(
                                                  e.target.value
                                                );
                                                // setVisits({...allowedNoOfVisitors,visitNumber:e.target.value})
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        {/* </>) */}
                                        {/* }) */}

                                        {/* : null} */}
                                      </div>
                                      <br />
                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Procedure
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Procedure"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedExcludedProcedure}
                                            loadOptions={loadProcedureOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedExcludedProcedure(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {checkedEight &&
                                  item.item === "Insurance Details" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Insurances
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Insurance"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedAllowedInsurance}
                                            loadOptions={loadInsuranceOptions}
                                            getOptionLabel={(e) => e.label}
                                            getOptionValue={(e) => e.value}
                                            onChange={(e) => {
                                              setSelectedAllowedInsurance(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Insurances
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Insurance"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedExcludedInsurance}
                                            loadOptions={loadInsuranceOptions}
                                            getOptionLabel={(e) => e.label}
                                            getOptionValue={(e) => e.value}
                                            onChange={(e) => {
                                              setSelectedExcludedInsurance(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                          : ""}
                      </>
                    </Container>
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="">Auto Send Referral:</Label>
                    </Col>
                    <Col sm={8}>
                      <ToggleButton
                        inactiveLabel={"OFF"}
                        activeLabel={"ON"}
                        value={editautoProcessEnable}
                        onToggle={(e) => {
                          setEditAutoProcessEnable(!editautoProcessEnable);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                </CardBody>
              </div>
            </FormGroup>
          </Container>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            onClick={() => {
              // //debugger
              editAutoProcessExceptionRules();
              // patientAgePopulate()
            }}
            disabled={buttonBlankState}
            title={
              buttonBlankState
                ? "Please fill in all mandatory fields"
                : !buttonBlankState
                ? "Click to save"
                : ""
            }
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditAutoProcessExceptionRule;
