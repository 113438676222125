/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner,Label } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import "./DateRangeSelector.css";
import { faPlus,faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select } from "react-select-virtualized";
import { toastr } from 'react-redux-toastr';
import DeleteConfirmationModal from '../../newComponents/utilities/DeleteConfirmationModal'

const ReferralFulfillerTableModal = (props) => {
  
  // DEPENDENCY VARIABLES
  const { setReferralFulfillerTabelModal, ReferralFulfillerTabelModal, referralOrderFulfillerID, referralOrderFulfillerGpName, groupDropDownROS } = props
  
  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [spinner, setSpinner] = useState(false)
  const [apiLoaded, setApiLoaded] = useState(false)
  const [referralFulfillerGroupTabel, setReferralFulfillerGroupTabel] = useState("")
  

  const [isTable, setIsTable] = useState('table')
  const [referralFulfillerSenderGP, setReferralFulfillerSenderGP] = useState('')
  const [isSelfEnabled, setIsSelfEnabled] = useState({ value: '1', label: "Yes" })
  const [deleteModal, setDeleteModal] = useState(false);
  const [rofcId, setRofcId] = useState();
  const [totalSize, setTotalSize] = useState(0);


  const toggle = () => {
    setReferralFulfillerTabelModal(!ReferralFulfillerTabelModal);
    setIsTable('table')
  }

  const deleteMapping = () => {
    setSpinner(true);
    const params =
    {
      "rofc_id":rofcId ? rofcId : ""
    
    }
   
    AxiosInter.post(`${API_ENDPOINT}/admin-api/delete-referral-order-sender-fulfiller-mapping/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        toastr.success("Success","Deleted successfully")
        setSpinner(false);
        setDeleteModal(false);
        setApiLoaded(false);
        fetchReferralSenderGroup();
      })
      .catch(error => {
       toastr.error("Error","Something went wrong")
       setSpinner(false);
      });
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const selfReferralValidation  = (cell) => {
    if(cell == 0) { // eslint-disable-line
      return "No"
    }else if(cell == 1){ // eslint-disable-line
      return "Yes"
    }else {
      return "--"
    }

  }

  const actionFormatter = (cell, row) => {
    return (
      <div style={{'gap':'20px','display':'flex','justifyContent':'center'}}>

        <FontAwesomeIcon 
                icon={faTrash}
                color = {'#E12C2C'}
                size = {'1x'}
                style = {{'cursor':'pointer'}}
                
                onClick={() => {
                   setRofcId(row.id)
                   console.log('rof id',row.id)
                   setDeleteModal(true)
   
                 }}
              
                title = "Delete"

              />
      </div>
    )
  }

  const YesNoOptions = [
    { value: '1', label: "Yes" },
    { value: '0', label: "No" }
  ]



  const columns = [ {
    dataField: 'name',
    text: 'Group Name',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'isSelfReferral',
    text: 'Is Self Referral',
    sort: true,
    formatter: selfReferralValidation
  },
  {
    text: 'Actions',
    formatter: actionFormatter
  }];
  const fetchReferralSenderGroup = () => {
    setSpinner(true);
    setApiLoaded(false);
    const params =
    {
      "order_fulfiller_Id": referralOrderFulfillerID ? referralOrderFulfillerID.toString() : ""

    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-referral-fulfiller-group/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(function (response) {
        if (response.data.length > 0) {
          setReferralFulfillerGroupTabel(response.data);
          setApiLoaded(true);
          setSpinner(false);
          setIsTable('table')
          setTotalSize(response.data[0].totalcount);
        } else {
          setReferralFulfillerGroupTabel("");
          setApiLoaded(true);
          setSpinner(false);
          setIsTable('table')
          setTotalSize(0);
        }
      })
      .catch(function (error) {
        setReferralFulfillerGroupTabel("");
        setApiLoaded(true);
        setSpinner(false);
        setIsTable('table')
      });
  };

  const addReferralFulfillerSender = () => {
    setSpinner(true);
    const params =
    {
      "order_fulfiller_id": referralOrderFulfillerID ? referralOrderFulfillerID.toString() : "",
      "isSelfReferral": isSelfEnabled ? isSelfEnabled.value.toString() : "",
      "group_id": referralFulfillerSenderGP ? referralFulfillerSenderGP.value.toString() : "",
    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/create-referral-fulfiller-sender/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(function (response) {

        if (response.status === 201){
          setSpinner(false);
          //setReferralSenderReload(referralSenderReload+1)
          fetchReferralSenderGroup()
          setReferralFulfillerSenderGP('')
          toastr.success('Success', 'Referral Sender Added Successfully');
          setSpinner(false);
          
        }else{
          toastr.error('Error', 'something went wrong please try again');
          setSpinner(false);
          setReferralFulfillerSenderGP('')
          setIsTable('table')
        }
      })
      .catch(function (error) {
        toastr.error('Error', 'Referral Sender Not Added');
        setSpinner(false);
        setReferralFulfillerSenderGP('')
      });
  };
  




  useEffect(() => { 
    if(ReferralFulfillerTabelModal){
      fetchReferralSenderGroup(); 
    }
   }, [referralOrderFulfillerID]) // eslint-disable-line

  return (
    <div>
      <div >
        {spinner
          ? <Spinner />
          : ""}
      </div>
      <Modal isOpen={ReferralFulfillerTabelModal} >
      
        { apiLoaded && isTable === 'table'   ?
          <>
          <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}> Senders for {referralOrderFulfillerGpName}</ModalHeader>
            <ModalBody className="patient-list" >
              

              {
                isTable !== "add" || referralFulfillerGroupTabel.length === 0  ? 
               
                  
                  <Button style={{marginTop:'20px'}} className="referral-add-btn ml-2"
                    onClick={() => {
                      setIsTable("add");
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      fixedWidth
                      className="align-middle"
                      title="Add Referral Sender"
                    />
                    Referral Sender
                  </Button>
                
                : ""
              }

              {
              referralFulfillerGroupTabel && apiLoaded && isTable === 'table'
                ? <>
                  <br />
                  <Row >
                    <Col>
                    <div className="group-list-referral patient-list-table">
                      {referralFulfillerGroupTabel && apiLoaded ? <>                            <p>
                              Total Record Count:{totalSize ? totalSize : 0}
                            </p> <BootstrapTable keyField='id' data={referralFulfillerGroupTabel} columns={columns} totalSize={totalSize ? totalSize : ""} wrapperClasses="Queue-table" /> </>: ""}
                    </div>
                      {!referralFulfillerGroupTabel && apiLoaded ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""}
                    </Col>
                  </Row>
                </>
                : ""
              }
              {
               referralFulfillerGroupTabel.length === 0 && apiLoaded && !spinner ? 
                <>
                
                <Row className="advanced-search-table-height">
                  <Col>
                    {!referralFulfillerGroupTabel && apiLoaded && !spinner ? <Card style={{marginTop:"32px"}} > <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""}
                  </Col>
                </Row>
              </>
              : ""
              }
            </ModalBody>
          </> : ''
        }
        {
          isTable === "add" && isTable !== 'table' ? 
          <>
          
          <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center','height':'50%'}}  toggle={toggle}>  Add Senders for {referralOrderFulfillerGpName}</ModalHeader>
          <ModalBody>
           
              <>
                <br />
                <Row >
                  
                  <Col>
                  
                  <Label for="Group name">Group Name <span className="required">*</span></Label>
                    <Select
                      placeholder="Select"
                      options={groupDropDownROS.map((item) => ({
                          value: item.value,
                          label: item.label
                        }))}
                      value={referralFulfillerSenderGP}
                      onChange={(e) => {
                        setReferralFulfillerSenderGP(e)
                          
                      }}
                  />
                  </Col>
                </Row>
                <br />
                <Row >
                  
                  <Col>
                  
                  <Label for="self Enabled">Is Self Enabled  </Label>
                    <Select
          
                      placeholder="Select"
                      options={YesNoOptions}
                      value={isSelfEnabled ? isSelfEnabled : { value: '1', label: "Yes" }}
                      onChange={(e) => {
                        setIsSelfEnabled(e);
                      }}
                  />
                  </Col>
                </Row>
                <br />
                <Row >
                  
                  <Col style={{display:'flex',justifyContent:'end'}}>
                  
                  <Button className="referral-sender-fulfiller-add-btn ml-2 "
                      onClick={() => {
                        addReferralFulfillerSender();
                        
                      }}
                    >
                    Add
                  </Button>
                  </Col>
                  <Col>
                  
                  <Button style={{borderColor:'grey',backgroundColor:"gray"}} className="referral-sender-fulfiller-back-btn ml-2"
                      onClick={() => {
                        setIsTable("table");
                        setReferralFulfillerSenderGP('')
                      }}
                    >
                    Back
                  </Button>
                  </Col>

                </Row>
              </>
            
          </ModalBody>

          </> : ""
        }
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button outline className="no-button button-width" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
      <DeleteConfirmationModal
        deleteModal = {deleteModal}
        setDeleteModal = {setDeleteModal}
        deleteFunction = {deleteMapping}


        />
    </div>
  );
}

export default ReferralFulfillerTableModal;

