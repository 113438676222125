import React, { useState, useContext } from "react";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { Col, Row, Button, Label } from "reactstrap";
import "./DateRangeSelector.css";
import makeAnimated from "react-select/animated";
import { GlobalState } from "../../contextAPI/Context";
import { Select } from "react-select-virtualized";
import moment from "moment-timezone";
import AsyncSelect from "react-select/async";
import _ from "lodash";

const ReferralImportDataFilters = (props) => {
  // CONTEXT VARIABLES
  const {
    spinner,
    setSenderId,
    setFulFillerId,
    setYear,
    setMonth,
    setStatus,
    setTimeZone,
    /* senderDropDown, */
    fullFillerDropDown, // eslint-disable-line
    importlogReload,
    setImportlogReload,
    senderDropDownList,
    fullFillerDropDownList,
  } = useContext(GlobalState);

  // LOCAL VARIABLES
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  const { lyear, setLyear, lmonth, setLmonth } = props;
  let month = months[d.getMonth()];
  const [sender, setSender] = useState("");
  const [fullfiller, setFullfiller] = useState("");
  //const [lyear, setLyear] = useState({ value: (d.getFullYear()).toString(), label: (d.getFullYear()).toString() })
  //const [lmonth, setLmonth] = useState({ value: (d.getMonth()+1).toString(), label: month.toString() })
  const [lstatus, setLstatus] = useState("");

  //DROPDOWN FOR MONTHS
  const MonthOptions = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  //DROPDOWN FOR YEARS
  const YearOptions = [
    { value: "2015", label: "2015" },
    { value: "2016", label: "2016" },
    { value: "2017", label: "2017" },
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" }
  ];

  //DROPDOWN FOR STATUS
  const StatusOptions = [
    { value: "0", label: "Pending" },
    { value: "2", label: "Processed" },
    { value: "99", label: "Error" },
    { value: "3", label: "Duplicate" },
    { value: "1", label: "Queued" }
  ];

  var timeZone = moment.tz.guess();

  const animatedComponents = makeAnimated();

  const runSearch = () => {
    if (sender || fullfiller || lstatus || lmonth || lyear) {
      setSenderId(sender ? sender.value : 0);
      setFulFillerId(fullfiller ? fullfiller.value : 0);
      setYear(lyear ? lyear.value : 0);
      setMonth(lmonth ? lmonth.value : 0);
      setStatus(lstatus ? lstatus.value : 0);
      setTimeZone(timeZone ? timeZone : "US/Eastern");
      setImportlogReload(importlogReload + 1);
    }
  };

  const resetFields = () => {
    // setBlankStateMessage(true);
    setSenderId("");
    setFulFillerId("");
    setMonth("");
    setYear("");
    setStatus("");
    setFullfiller("");
    setSender("");
    setLyear({
      value: d.getFullYear().toString(),
      label: d.getFullYear().toString(),
    });
    setLmonth({
      value: (d.getMonth() + 1).toString(),
      label: month.toString(),
    });
    setLstatus("");
    setImportlogReload(importlogReload + 1);
  };

  const getAsyncOptions = (inputValue) => {
    if (senderDropDownList) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(senderDropDownList, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };
  const getAsyncOptionsForFulfiller = (inputValue) => {
    if (fullFillerDropDownList) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(fullFillerDropDownList, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };
  const minLengthforzip = 1;

  const customNoOptionsMessageforZip = ({ inputValue }) => {
    if (inputValue.length < minLengthforzip) {
      return "Please search with at least 1 character";
    } else {
      return "No option";
    }
  };
  return (
    <AvForm>
      <AvGroup>
        <Row>
          <Col sm="2">
            <Label>Year</Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={(event) => {
                setLyear(event ? event : 0);
              }}
              isSearchable
              placeholder="All"
              isClearable={false}
              value={lyear ? lyear : 0}
              options={YearOptions.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              components={animatedComponents}
            />
          </Col>
          <Col sm="2">
            <Label>Month</Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={(event) => {
                setLmonth(event ? event : 0);
              }}
              isSearchable
              isClearable={false}
              placeholder="All"
              value={lmonth ? lmonth : 0}
              options={MonthOptions.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              components={animatedComponents}
            />
          </Col>
          <Col sm="4">
            <Label>Sender</Label>

            <AsyncSelect
              cacheOptions
              isClearable
              isSearchable
              /* defaultOptions */
              placeholder="Start typing sender name for options"
              noOptionsMessage={customNoOptionsMessageforZip}
              value={sender}
              onChange={(event) => {
                setSender(event);
              }}
              loadOptions={(inputValue) => getAsyncOptions(inputValue)}
            />
          </Col>
          <Col sm="4">
            <Label>Fulfiller</Label>
            <div className="min-width">
              {/*  <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setFullfiller(event) }}
                            isSearchable
                            placeholder="All"
                            value={fullfiller}
                            
                            options={fullFillerDropDown.map((item) => ({
                                value: item.value,
                                label: item.label
                            }))}
                            components={animatedComponents}
                            />  */}
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                /* defaultOptions */
                placeholder="Start typing fulfiller name for options"
                noOptionsMessage={customNoOptionsMessageforZip}
                value={fullfiller}
                onChange={(event) => {
                  setFullfiller(event);
                }}
                loadOptions={(inputValue) =>
                  getAsyncOptionsForFulfiller(inputValue)
                }
              />
            </div>
          </Col>
        </Row>
        <Row className="second-row-filters">
          <Col sm="2">
            <Label>Status</Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              onChange={(event) => {
                setLstatus(event);
              }}
              isSearchable
              placeholder="Select"
              value={lstatus}
              options={StatusOptions.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              components={animatedComponents}
            />
          </Col>
          <Col>
            <Button
              type="submit"
              className="go-buttons"
              disabled={spinner ? true : false}
              onClick={() => {
                runSearch();
              }}
            >
              Go
            </Button>
            <Button
              type="reset"
              className="reset-buttons"
              disabled={spinner ? true : false}
              onClick={() => {
                resetFields();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </AvGroup>
    </AvForm>
  );
};

export default ReferralImportDataFilters;
