/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Col,
  Row,
  Button,
  Spinner,
  Breadcrumb,
  CardHeader,
  Label,
  BreadcrumbItem,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { GlobalState } from "../../contextAPI/Context";
import Select from "react-select-virtualized";
import moment from "moment";
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import DeleteConfirmationModal from "../../newComponents/utilities/DeleteConfirmationModal";
import ImmunizationModal from "../../newComponents/utilities/ImmunizationModal";

// eslint-disable-next-line no-unused-vars
import _ from "lodash";
import MomentConfig from "../../common/MomentConfig";
import Cookie from 'js-cookie';

const Immunizations = () => {
  const {
    fetchImmunizationDrugNamesList,
    fetchImmunizationDrugGenericNamesList,
    immunizationDrugNames,

    immunizationDrugGenericNames,
  } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  

  const fileExportName = "Immunizations";
  const [spinner, setSpinner] = useState(false);
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [filter, setFilter] = useState(false);
  const [immunizationDetails, setImmunizationDetails] = useState("");
  const [immunizationId, setImmunizationId] = useState();
  const [reload, setReload] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [isPagination, setIsPagination] = useState(1); // eslint-disable-line
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  //   const [isDataEmpty, setIsDataEmpty] = useState(false);

  const [selectedDrugName, setSelectedDrugName] = useState("");
  const [selectedGenericName, setSelectedGenericName] = useState("");
  const [drugName, setDrugName] = useState("");
  const [code, setCode] = useState("");
  const [brandName, setBrandName] = useState("");
  const [scientificName, setScientificName] = useState("");
  const [genericName, setGenericName] = useState("");
  const [genericNameCode, setGenericNameCode] = useState("");
  const [notes, setNotes] = useState("");
  const [dosage, setDosage] = useState("");
  const [unitOfMeasure, setUnitOfMeasure] = useState("");
  const [refillPointivity, setRefillPointivity] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;

  //   const [groupDropDown, setGroupDropDown] = useState("");

  const validation = (cell) => {
    return cell ? cell : "N/A";
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <div style={{ gap: "20px", display: "flex", justifyContent: "center" }}>
          <FontAwesomeIcon
            icon={faEdit}
            size={"1x"}
            color={"#3f86fa"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModal(true);
              setModalState("edit");
              setImmunizationId(row.id);
              setBrandName(row.brandName);
              setCode(row.code);
              setDosage(row.dosage);
              setDrugName(row.drugname);
              setGenericName(row.genericName);
              setGenericNameCode(row.genericNameCode);
              setNotes(row.notes);
              setRefillPointivity(row.refillPointivity);
              setScientificName(row.scientificName);
              setUnitOfMeasure(row.unitOfMeasure);
              setExpiryDate(row.expiryDate);
            }}
            title="Edit"
          />

          <FontAwesomeIcon
            icon={faTrash}
            color={"#E12C2C"}
            size={"1x"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setImmunizationId(row.id);
              setDeleteModal(true);
            }}
            title="Delete"
          />
        </div>
      </>
    );
  };

  const timeFormatter = (cell, row) => {
    return cell ? `${moment(cell).toDate()}` : "N/A";
  };

  const columns = [
    {
      dataField: "drugname",
      text: "Drug Name",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "code",
      text: "Code",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "genericName",
      text: "Generic Name",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "genericNameCode",
      text: "Generic Name Code",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "expiryDate",
      text: "Expiry Date",
      sort: false,
      formatter: validation,
      timeFormatter: timeFormatter,
    },
    {
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];


  const fetchImmunizationDetails = () => {
    setSpinner(true);
    const params = {
      drug_name: selectedDrugName ? selectedDrugName.label : "",
      generic_name: selectedGenericName ? selectedGenericName.label : "",
      is_pagination: isPagination ? isPagination : "",
      record_limit: sizePerPage ? sizePerPage : "",
      offset: offset,
      search_keyword: searchKeyword ? searchKeyword : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-immunization-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setImmunizationDetails(response.data.data);
          setTotalSize(response.data.record_count);
          setSpinner(false);
          localStorage.setItem("offset", offset);
        } else {
          setTotalSize(0);
          setImmunizationDetails("");
          setSpinner(false);
          //   setIsDataEmpty(true);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };

  const exportTableData = () => {
    const params = {
      file_name: fileName ? fileName : "",
      drug_name: selectedDrugName ? selectedDrugName.label : "",
      generic_name: selectedGenericName ? selectedGenericName.label : "",
      is_pagination: isPagination ? isPagination : "",
      record_limit: "",
      offset: offset,
      search_keyword: searchKeyword ? searchKeyword : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/export-immunizations-table-data/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const deleteImmunizationDetails = () => {
    setSpinner(true);
    const params = {
      immunization_id: immunizationId ? immunizationId : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/delete-immunization-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        toastr.success("Success", "Deleted successfully");
        setSpinner(false);
        setDeleteModal(false);
        setReload(reload + 1);
        fetchImmunizationDrugNamesList();
        fetchImmunizationDrugGenericNamesList();
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };

  const handleTableChange = (type, newState) => {
    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("");
      setSortOrder("");
    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setReload(reload + 1);
    }
  };

  const filterValidation = () => {
    if (selectedDrugName || selectedGenericName) {
      setFilter(true);
    } else {
      setFilter(false);
    }
  };

  const clearSearchValues = () => {
    setSearchKeyword("");
    setReload(reload + 1);
  };

  const clearFilterValues = () => {
    setSelectedDrugName("");
    setSelectedGenericName("");
    setReload(reload + 1);
  };

  // useEffect(() => {
  //   fetchImmunizationDetails();
  //   // createYearDropdownOptions();

  // }, []) // eslint-disable-line

  useEffect(() => {
    filterValidation();
  }, [selectedDrugName, selectedGenericName]); // eslint-disable-line

  //   useEffect(() => {
  //   if(groupNamesList) {
  //     createGroupOption();
  //   }
  //   }, [groupNamesList]) // eslint-disable-line

  useEffect(() => {
    if (reload) {
      fetchImmunizationDetails();
    }
  }, [reload]); // eslint-disable-line

  useEffect(() => {
    if (immunizationDrugNames.length <= 0) {
      fetchImmunizationDrugNamesList();
    }
  }, [immunizationDrugNames]); // eslint-disable-line

  useEffect(() => {
    if (immunizationDrugGenericNames.length <= 0) {
      fetchImmunizationDrugGenericNamesList();
    }
  }, [immunizationDrugGenericNames]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setReload(reload + 1);
    }
  }, [searchKeyword]); // eslint-disable-line

  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      setTotalSize(0);
      setReload(reload + 1);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset || sizePerPage) {
      setReload(reload + 1);
    }
  }, [offset, sizePerPage]); // eslint-disable-line
  useEffect(() => {   
    window.scrollTo(0, 0);   
     }, []);
  // CheckUserInactivity()

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Immunizations
            <Button
              className="Add-btn pull-right"
              onClick={() => {
                setModal(true);
                setModalState("add");
              }}
            >
              Add New Immunization
            </Button>
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Immunizations</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>

      <Card>
        <CardHeader>
          <Row>
            <Col sm="2" md="2" lg="2">
              <Label>Drug Name</Label>
              <Select
                name="Drug Name"
                options={immunizationDrugNames}
                placeholder="Drug Name"
                // isDisabled = {!m_zipList || !adminNames?true:false}
                isClearable
                value={selectedDrugName}
                onChange={(e) => {
                  setSelectedDrugName(e);
                }}
              />
            </Col>
            <Col sm="2" md="2" lg="2">
              <Label>Generic Name</Label>
              <Select
                name="Generic Name"
                options={immunizationDrugGenericNames}
                placeholder="Generic Name"
                // isDisabled = {!m_zipList || !adminNames?true:false}
                isClearable
                value={selectedGenericName}
                onChange={(e) => {
                  setSelectedGenericName(e);
                }}
              />
            </Col>

            <Col>
              <Button
                className="patient-go-buttons"
                onClick={() => {
                  setSearchKeyword("");
                  offset === 0 ? setReload(reload + 1) : setOffset(0);
                  // setIsSearch(true);
                  setPage(1);
                }}
                disabled={spinner || !filter ? true : false}
                title={
                  spinner || !filter
                    ? "Choose at least one item to filter "
                    : ""
                }
              >
                Go
              </Button>{" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => {
                  clearFilterValues();
                }}
                disabled={spinner || !filter ? true : false}
                title={spinner || !filter ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <div className="separator" />
        </CardHeader>
        <div className="groups-search">
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={columns}
            clearValues={clearSearchValues}
          />
        </div>

        {spinner ? <Spinner /> : ""}

        <RemotePagination
          data={immunizationDetails ? immunizationDetails : ""}
          columns={columns}
          page={page}
          sizePerPage={sizePerPage}
          FileExportName={fileExportName}
          exportTableData={exportTableData}
          // isGlobalSearch={isGlobalSearch}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            handleTableChange(type, newState);
          }}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
        />
      </Card>

      <DeleteConfirmationModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteFunction={deleteImmunizationDetails}
      />

      <ImmunizationModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteFunction={deleteImmunizationDetails}
        drugName={drugName}
        setDrugName={setDrugName}
        brandName={brandName}
        setBrandName={setBrandName}
        scientificName={scientificName}
        setScientificName={setScientificName}
        genericName={genericName}
        setGenericName={setGenericName}
        genericNameCode={genericNameCode}
        setGenericNameCode={setGenericNameCode}
        notes={notes}
        setNotes={setNotes}
        dosage={dosage}
        setDosage={setDosage}
        unitOfMeasure={unitOfMeasure}
        setUnitOfMeasure={setUnitOfMeasure}
        refillPointivity={refillPointivity}
        setRefillPointivity={setRefillPointivity}
        expiryDate={expiryDate}
        setExpiryDate={setExpiryDate}
        code={code}
        setCode={setCode}
        modal={modal}
        setModal={setModal}
        modalState={modalState}
        setModalState={setModalState}
        reload={reload}
        setReload={setReload}
        immunizationId={immunizationId}
        fetchImmunizationDrugNamesList={fetchImmunizationDrugNamesList}
        fetchImmunizationDrugGenericNamesList={
          fetchImmunizationDrugGenericNamesList
        }
      />
    </div>
  );
};

export default Immunizations;
