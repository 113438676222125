import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col, Input, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator"; // eslint-disable-line
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';


const blankState = (
  <Card className="blank-state">
    <CardBody>
      <h3>No Records Available</h3>
    </CardBody>
  </Card>
)

const AppointmentTagDataTable = (props) => {
  const { data, setSearchKeys, searchKeys, setReloadApptTag, reloadApptTag } = props
  const [numberOfRecords, setNumberOfRecords] = useState(0)
  const [blankStateStatus, setBlankStateStatus] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [newData, setNewData] = useState([])

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div className="card-header pad-rt-0">
        <button className="btn btn-export mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const handleDataChange = ({ dataSize }) => {
    setNumberOfRecords(dataSize ? dataSize : 0);

  }

  useEffect(() => {
    setNumberOfRecords(data.length)
  }, [data]);

  useEffect(() => {
    if (data.length === 0) {
      setBlankStateStatus(true)
    } else {
      setBlankStateStatus(false)
    }
  }, [data.length]);


  return (
    <div className="physicians_table adjustment">
      <ToolkitProvider
        keyField="id"
        data={props.data}
        columns={props.columns}
        search={{
          afterSearch: (newResult) => setNewData(newResult)
        }}
        exportCSV={{ fileName: `Equipo-Appointment-tags-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv` }}
      >{(props) => (
        <div>
          <Card>
            <CardHeader>
              <div className="separator" />
              <Row >
                <Col xs={2}>
                  <div className="number-of-records">
                    <p>Number of records: <strong>{numberOfRecords}</strong></p>
                  </div>
                </Col>
                <Col>
                  <div className='c-search-field form-inline justify-content-end'>
                    <div className='form-group mr-2'>
                      <Input
                        className="search-box-new"
                        type="text"
                        value={searchKeys}
                        onChange={(e) => setSearchKeys(e.target.value)}
                        placeholder="Search"

                      />
                    </div>
                    <Button
                      className="clear-search-box"
                      onClick={() => { setSearchKeys(""); setReloadApptTag(reloadApptTag + 1) }}
                      disabled={searchKeys ? false : true}
                      title={searchKeys ? "" : "Nothing to clear"}
                    >
                      Clear
                    </Button>
                    {!blankStateStatus ?
                      <div className="export-button">
                        <MyExportCSV {...props.csvProps} />
                      </div> : ""}
                  </div>
                </Col>
              </Row>
              <div className="separator" />
              <div className="float-right pull-right">
              </div>
            </CardHeader>
            <CardBody className="patient-list">
              {blankStateStatus && blankState}
              {!blankStateStatus ?
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4
                  bordered={false}
                  onDataSizeChange={handleDataChange}
                  pagination={paginationFactory({
                    sizePerPage: 25,
                    sizePerPageList: [25, 50, 100],
                  })}
                /> : ""}
            </CardBody>
          </Card>
        </div>
      )}
      </ToolkitProvider>
    </div>
  );
};

export default AppointmentTagDataTable;
