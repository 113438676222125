import React ,{useState, useEffect} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter ,Row, Col, Label} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'
import ToggleButton from "react-toggle-button";


const SsoLoginChangeForUser = (props) => {
  const {
    modalState,
    setModalState,
    AppUserID,
    appUserReload, setAppUserReload,
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [ssoLoginToggle, setSsoLoginToggle] = useState(false);

  const toggle = () => {
    setModalState(!modalState);
  };

  const savePreference = () => {
  //  debugger
   let ssoLogin = "";
   let groupId=localStorage.getItem("groupId")
   if (ssoLoginToggle === true){
    ssoLogin = 1
   }else {
    ssoLogin = 0
   }

    const updatedStatus = {
      group_id:groupId,
      user_id: AppUserID,
      sso_enabled: ssoLogin,
    };

    AxiosInter.post( `${API_ENDPOINT}/admin-api/update-sso-integration-for-user/`, updatedStatus,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
        if (res.status === 201) {
          setAppUserReload(appUserReload+1)
          setModalState(!modalState);
          {(ssoLogin ===1 || ssoLogin ===true) ? toastr.success('Login via sso enabled','login via sso enabled for user successfully') :toastr.success('Login via sso disabled','login via sso disabled for user successfully')}; //eslint-disable-line
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const getToggleValuesForUser = () => {
    let groupId=localStorage.getItem("groupId")
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/get-sso-toggle-for-group-appusers/${AppUserID}/${groupId}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          // console.log("isSsoEnabled",response.data.data[0]['isSsoEnabled'])
        //   setSsoIntegrationToggle(response.data.data[0]['isSsoEnabled'])
          setSsoLoginToggle(response.data.data[0]['isSsoEnabled'])
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong!");
      });
  };
  useEffect(() => {
    if (modalState)
    getToggleValuesForUser()
  }, [modalState])//eslint-disable-line

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={modalState}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Allow Login via SSO Only</ModalHeader>
        <ModalBody>
        <Row style={{ marginLeft: "6rem" }}>
                <Col sm={6}>
                  <Label >
                    Enable login via SSO only :
                  </Label>
                  </Col>
                  <Col sm={4}>
                  <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  value={ssoLoginToggle}
                  onToggle={() => {
                    setSsoLoginToggle(!ssoLoginToggle)
                  }}
                />
                  
                </Col>
              </Row>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
        <Button
            onClick={() => {
                savePreference()
            }}
            // disabled={updateButtonBlankState}
            title={
             "Save"
            }
          >
            Save
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SsoLoginChangeForUser;
