/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from 'react';
import { Button,  Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';


const NpiInfoTableModal = (props) => {
  
  // DEPENDENCY VARIABLES
  const { npiInfoTableModal, setNPIInfoTableModal } = props
  

  const toggle = () => {
    setNPIInfoTableModal(!npiInfoTableModal);
  }


  return (
    <div >
      <Modal isOpen={npiInfoTableModal}  size="lg" >
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center','height':'50%'}}  toggle={toggle}>Search Fields Info</ModalHeader>
        <ModalBody >
        <table className="info-table" >
          <tr>
            <th>Fields</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>NPI Number</td>
            <td>Exactly 10 digits</td>
          </tr>
          <tr>
            <td>Enumeration Type</td>
            <td>NPI-1 or NPI-2 <strong>(Other criteria required)</strong></td>
          </tr>
          <tr>
            <td>Taxonomy Description</td>
            <td>Exact Description or Exact Specialty or wildcard * after 2 characters</td>
          </tr>
          <tr>
            <td>First Name</td>
            <td>Exact name, or wildcard * after 2 characters</td>
          </tr>
          <tr>
            <td>First Name Alias</td>
            <td>True or False <strong>(Other criteria required)</strong></td>
          </tr>
          <tr>
            <td>Last Name</td>
            <td>Exact name, or wildcard * after 2 characters</td>
          </tr>
          <tr>
            <td>Organization Name</td>
            <td>Exact name, or wildcard * after 2 characters</td>
          </tr>
          <tr>
            <td>Address Purpose</td>
            <td>LOCATION, MAILING, PRIMARY or SECONDARY. <strong><strong>(Other criteria required)</strong></strong></td>
          </tr>
          <tr>
            <td>City</td>
            <td>Exact name, or wildcard * after 2 characters</td>
          </tr>
          <tr>
            <td>State</td>
            <td>2 Characters <strong>(Other criteria required)</strong>s</td>
          </tr>
          <tr>
            <td>Postal Code</td>
            <td>Exact Postal Code (5 digits will also return 9 digit zip + 4), or wildcard * after 2 characters</td>
          </tr>
          <tr>
            <td>Country Code</td>
            <td>Exactly 2 characters (if "US", other criteria required)</td>
          </tr>
          <tr>
            <td>Limit</td>
            <td>Limit results, default = 10, max = 200</td>
          </tr>
          <tr>
            <td>Skip</td>
            <td>Skip first N results, max = 1000</td>
          </tr>

        </table>
        </ModalBody>
        <ModalFooter className="advanced-search-button">
          <Button outline className="no-button button-width" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default NpiInfoTableModal;