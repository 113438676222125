import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'


const AppUserPasswordResetMailModal = (props) => {
  const {
    PasswordMailModal,
    setpasswordMailModal,
    rowData,
    setRowData,
    
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [spinner, setSpinner] = useState(false)


  const toggle = () => {
    setpasswordMailModal(!PasswordMailModal);
    setRowData("")
  };

  const OverlayLoader = () => (
    <div className="page">
    <div className="item">
        <i className="loader --1"></i>
    </div>
    </div>
);

  const confirmation = () => {
    setSpinner(true)
    const param = {
      "app_user_email":rowData ? rowData.email.toString() : "",
    };

    AxiosInter.post( `${API_ENDPOINT}/admin-api/app-user-reset-password/`, param,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
        if (res.status === 201) {
          setpasswordMailModal(!PasswordMailModal)
          toastr.success('Successful', 'Password reset link has been sent to provided Email');
        }
        setSpinner(false)
      })
      .catch((error) => {
        toastr.error('Error', 'Please try again');
        setSpinner(false)
      });
  };

  return (
    <div>
      {spinner ? <OverlayLoader className="overlay-z-index" /> : null}

      <Modal isOpen={PasswordMailModal}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Password Reset - {rowData.name}</ModalHeader>
        <ModalBody style={{"text-align": "center"}}>
            <strong>
              Password reset instructions will be sent to {rowData.email}
            </strong>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
        <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AppUserPasswordResetMailModal;
