import React, {useEffect, useContext } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { GlobalState } from '../../contextAPI/Context'



const AuthServiceStatusChangeModal = (props) => {
  const API_TOKEN = localStorage.getItem("accessToken")
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;
    const {spinner,setSpinner} = useContext(GlobalState); // eslint-disable-line

    const {
      ListServices,
        setModalState,
        modalState,
        status,
        serviceId,
        editServiceName,
        reload,
        setReload
    } = props;

    const updateServiceStatus = () => {
      setSpinner(true);
        setReload(reload +1)

        let service_status = status;
        if (service_status === true) {
            service_status = false;
        } else {
            service_status = true;
        }
        const params = {
            serviceId: serviceId,
            status: service_status,
            name:editServiceName,
        };
        debugger
        axios
            .put(`${API_ENDPOINT}api/services/${serviceId}`, params, {
                headers: {
                    Authorization: API_TOKEN,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    toastr.success("Success", "Status updated successfully");
                    setReload(0);
                    setSpinner(false);
                    setModalState(false);
                   
                }
            })
            .catch((error) => {
                toastr.error("Error", "Something went wrong!");
                setReload(0)
                setSpinner(false);
            });
    }
    const toggle = () => {
        setModalState(!modalState);
      };
      
     
    useEffect(() => {
    if (reload) {
      ListServices();
      setReload(0)
    }
    }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <div>
          <Modal isOpen={modalState} toggle={toggle}>
            <ModalHeader
              tag="h4"
              cssModule={{ "modal-title": "w-100 text-center" }}
              toggle={toggle}
            >
              Change Status
            </ModalHeader>
            <ModalBody>
              <div style={{ textAlign: "center" }}>
                {status === true
                  ? "Do you want to change the group status to inactive?"
                  : "Do you want to change the group status to active ?"}
              </div>
            </ModalBody>
            <ModalFooter className="custom-modal-footer">
              <Button
                className="yes-button button-width"
                onClick={() => updateServiceStatus()}
              >
                Yes
              </Button>{" "}
              <Button outline className="no-button button-width" onClick={toggle}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
}
export default AuthServiceStatusChangeModal;