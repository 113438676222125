import React, { useState, useEffect, useContext } from "react";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { Link } from "react-router-dom";
import {Container,Breadcrumb,BreadcrumbItem,Button,Spinner,Card, CardHeader,Row,Col,Label, Input} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
// import ExportCSVTable from "./UserDataTable";
import RemotePagination from '../../newComponents/utilities/RemotePagination'
import { faEdit, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalState } from '../../contextAPI/Context'
import StatusChangeModal from '../../newComponents/utilities/StatusChangeModal';
import EditAdminUserModal from '../../newComponents/utilities/EditAdminUserModal';
import NewAdminUserModal from '../../newComponents/utilities/NewAdminUserModal';
import PasswordResetMailModal from '../../newComponents/utilities/PasswordResetMailModal';
import SearchBarComponent from '../../newComponents/utilities/SearchBarComponent'
import Select from "react-select";
// import makeAnimated from 'react-select/animated';
import MomentConfig from '../../common/MomentConfig';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';

const AdminUsers = (props) => {
  // CONTEXT VARIABLES 
  const {
    userName,
    userEmail,
    userStatus,
    AdminUserType,
    userId, setUserId,  // eslint-disable-line
    reloadUserData, setReloadUserData,
    setBlankStateMessage,// eslint-disable-line
    countryCodeList,
    FetchCountryListAPICall,
    spinner, setSpinner, search,// eslint-disable-line
    setUserName,
    setUserEmail,
    setUserStatus,
    setAdminUserType,
  } = useContext(GlobalState);

  // LOCAL VARIABLES

  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true); // eslint-disable-line
  const [modalState, setModalState] = useState(false);
  const [adminID, setAdminID] = useState("");
  const [status, setStatus] = useState("");
  const [rowData, setRowData] = useState("");
  const [editAdminUserModal, setEditAdminUserModal] = useState(false);
  const [editName, setEditName] = useState("");
  const [editID, setEditID] = useState(""); // eslint-disable-line
  const [editEmail, setEditEmail] = useState("");
  const [editUserType, setEditUserType] = useState("");
  const [editUserCountryCode, setEditUserCountryCode] = useState("");
  const [editPhone, setEditPhone] = useState("");
  const [sortColumn, setSortColumn] = useState(""); // eslint-disable-line
  const [sortOrder, setSortOrder] = useState(""); // eslint-disable-line
  const [newAdminUserModal, setNewAdminUserModal] = useState(false);
  const [PasswordMailModal, setpasswordMailModal] = useState(false);
  const [searchKeyword,setSearchKeyword] = useState("");
  const [sizePerPage, setSizePerPage] = useState(50);// eslint-disable-line
  const [totalSize, setTotalSize] = useState(0);
  const [offset, setOffset] = useState(0);
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const fileExportName = 'Admin-Users'
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")

    // STATUS
    const StatusOptions = [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' }
    ]

    // const animatedComponents = makeAnimated();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

    const fetchUsers = () => {
    setLoading(true);
    const params = {
      user_type: AdminUserType ? Number(AdminUserType) : 0,
      user_status: userStatus ? userStatus : "",
      user_email: userEmail ? userEmail : "",
      user_name: userName ? userName.toString() : "",
      offset: offset,
      is_pagination: "1",
      record_limit: sizePerPage ? sizePerPage : "",
      search_keyword: searchKeyword ? searchKeyword : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "desc",
    };
  
    AxiosInter.post(`${API_ENDPOINT}/admin-api/list-all-admin-users/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => {
        if (response.data.data.length > 0) {
          setUserList(response.data.data);
          setTotalSize(response.data.record_count);
          setLoading(false);
          localStorage.setItem("offset", offset);
          localStorage.removeItem("subscriptionFilter");
          localStorage.removeItem("groupNameFilter");
          localStorage.removeItem("statusFilter");
          localStorage.removeItem("adminNameFilter");
          localStorage.removeItem("zipCodeFilter");
          localStorage.removeItem("timeZoneFilter");
        } else {
          setTotalSize(0);
          setUserList("");
          setLoading(false);
        }
      })
      // .catch((error) => {
        //   if(error && error.request){ if (error.response.status === 401) {
        //     new_token= Cookie.get("access_token_admin")  
        //     AxiosInter.get(`${API_ENDPOINT}/admin-api/list-all-admin-users/`, {
        //               headers: {
        //                 Authorization: new_token,
        //               },
        //             })
        //         }
  
  
        //   }
        //  else{
                  // setUserList("")})
        // });
    };
  
  
  const exportTableData = () => {
    
    const params =
    {
      "file_name": fileName ? fileName : "",
      "user_type": AdminUserType ? Number(AdminUserType) : 0,
      "user_status": userStatus ? userStatus : "",
      "user_email": userEmail ? userEmail : "",
      "user_name": userName ? userName.toString() : "",
      "offset": offset,
      "is_pagination": "1",
      "record_limit":sizePerPage ? sizePerPage : "",
      "search_keyword": searchKeyword ? searchKeyword : ""
      

    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-admin-users-table-data/`,params,{
        headers: {
           
          Authorization: API_TOKEN,

        },
        responseType: "blob"
      })
        .then((response) => { 
          if (response.status === 200) {
            var fileDownload = require("js-file-download");
            fileDownload(
              response.data ,`${fileName}`,
              response["headers"]["x-suggested-filename"]
            );
            toastr.success(
              "Success",
              "Export successful"
            );
          
          }
          
        })
        // .catch((error) => {
        
        //     toastr.error(
        //         "Failed",
        //         "Something went wrong"
        //       );
        // });
    };
    const handleTableChange = (type, newState) => {
      // console.log(newState)
      // setPage(newState.page);
      setSizePerPage(newState.sizePerPage);
      setOffset((newState.page - 1) * newState.sizePerPage);
      const currentIndex = 0;
  
      if (newState.sortField !== "" && newState.sortOrder !== "") {
        let result;
        if (newState.sortOrder === 'asc') {
          result = userList.sort((a, b) => {
            if (a[newState.sortField] > b[newState.sortField]) {
              return 1;
            } else if (b[newState.sortField] > a[newState.sortField]) {
              return -1;
            }
            return 0;
          });
        } else {
          result = userList.sort((a, b) => {
            if (a[newState.sortField] > b[newState.sortField]) {
              return -1;
            } else if (b[newState.sortField] > a[newState.sortField]) {
              return 1;
            }
            return 0;
          });
        }
    
        setUserList(result.slice(currentIndex, currentIndex + sizePerPage));
        }
      }
  

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setAdminID(row.id);
          setStatus(cell);
          setModalState(true);
        }}
      >
        {status}
      </span>
    );
  };


  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

//   const getValueFromArray = (array) => {
//     return array.map(item => { return item.label })
// }

const runSearch = () => {
    if (name || email || status) {
        setUserName(name ? name : "")
        setUserEmail(email ? email : "")
        setUserStatus(status ? status.value  : "")

    }
}

const clearValues = () => {
    // setBlankStateMessage(true);
    setName("")
    setEmail("")
    setStatus("")
    setUserName("")
    setUserEmail("")
    setUserStatus("")
    setAdminUserType("")
    setSearchKeyword("")
    setReloadUserData(reloadUserData + 1)
}

  const userTypeValidation = (cell) => {
    if(cell == 1) { // eslint-disable-line
      return 'Super Admin' 
    }else {
      return 'Admin'
    }
  }

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }

  const actionFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setRowData(row)
            setEditAdminUserModal(true)
            editUserData(row.id,row.name,row.email,row.is_superuser,row.phone,row.countryCode)
          }}
          color="primary"
          title="Edit User"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit User"
          />
        </Button>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setRowData(row)
            setpasswordMailModal(true)
          }}
          color="primary"
          title="Password Reset Mail"
        >
          <FontAwesomeIcon
            icon={faPaperPlane}
            fixedWidth
            className="align-middle"
            title="Password Reset Mail"
          />
        </Button>
      </>
    )
  }

  const editUserData=(id,name,address,is_superuser,phone,countryCode)=> {
    setEditID(id)
    setEditName(name);
    setEditEmail(address);
    setEditUserType(is_superuser);
    setEditPhone(phone)
    setEditUserCountryCode(countryCode)

  }


  const tableColumns = [
    {
      dataField: "is_superuser",
      text: "User Type",
      sort: true,
      formatter: userTypeValidation,
      csvFormatter: userTypeValidation,
    },
    {
      dataField: "name",
      text: "User Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    
    {
      dataField: "is_active",
      text: "Status",
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    }
    
  ];// eslint-disable-line

  let loader = null;// eslint-disable-line

  // if (loading) {
    // eslint-disable-next-line no-unused-vars
    // loader = <Spinner />;
  // }

  // DEFAULT API CALL
  useEffect(() => { 
    if(userName?.length > 0 || userEmail?.length > 0 || userStatus?.length > 0 || AdminUserType?.length > 0){
      
      fetchUsers() 
    }
  }, [userName, userEmail, userStatus, AdminUserType]);// eslint-disable-line
  useEffect(() => {if (searchKeyword.length >= 2) {setIsGlobalSearch(true);}}, [searchKeyword]) // eslint-disable-line
  useEffect(() => {if (searchKeyword.length === 0) {setReloadUserData(reloadUserData + 1)}}, [searchKeyword]) // eslint-disable-line
  useEffect(()=>  {if (reloadUserData > 0) {fetchUsers()} },[reloadUserData])// eslint-disable-line
  useEffect(() => {if (isGlobalSearch) { setOffset(0); setTotalSize(0);fetchUsers();setIsGlobalSearch(false);}}, [isGlobalSearch]) // eslint-disable-line
  useEffect(() => { 
    if(countryCodeList.length <= 0 ){

      FetchCountryListAPICall() 
    }
  
  }, [countryCodeList]);// eslint-disable-line

  useEffect(() => {   
    window.scrollTo(0, 0);   
     }, []);


  // CheckUserInactivity()

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Admin Users
            <Button className="Add-btn pull-right"
              onClick={() => {
                setNewAdminUserModal(true)
              }}
            >
              Add New Admin User
              </Button>

          </HeaderTitle>
          

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Users</BreadcrumbItem>
          </Breadcrumb>

        </Header>
        </Container>  
        <Card>
          <CardHeader>
          <Row>
                    <Col sm="3">
                        <Label>User Name</Label>
                        <Input
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={name}
                            validate={{
                                maxLength: {value: 120}
                              }} 
                            onChange={(e) => { setName(e.target.value) }}
                        />
                    </Col>
                    <Col sm="3">
                        <Label>Email</Label>
                        <Input
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={email}
                            validate={{
                                maxLength: {value: 254}
                              }} 
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                    </Col>
                    <Col sm="3">
                        <Label>Status</Label>
                        <Select
                            //className="pandadoc-select"
                            // classNamePrefix="react-select"
                            options={StatusOptions}
                            placeholder="Select"
                            isClearable
                            value={status}
                            onChange={(event) => { setStatus(event) }}
                            // components={animatedComponents}
                            // isMulti
                        />
                    </Col>
                    <Col>
                        <Button
                            type="submit"
                            className="go-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => {
                              setSearchKeyword("") 
                              runSearch() 
                              
                            }}
                        >Go</Button>
                        <Button
                            type="reset"
                            className="reset-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => { clearValues() }}
                            // disabled={spinner || !search ? true : false}
                            // title={spinner || !search ? "Nothing To Clear" : ""}
                        >Reset</Button>
                    </Col>
                </Row>

        <div className="separator" />
        </CardHeader>
          
        <div className='groups-search'>
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={tableColumns}
            clearValues={clearValues}
          />

        </div>

        {spinner ? <Spinner /> : ""}

        <RemotePagination
          data={userList}
          columns={tableColumns}
          // page={page}
          sizePerPage={sizePerPage}
          FileExportName={fileExportName}
          exportTableData = {exportTableData}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            handleTableChange(type, newState);
          }}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}



        />
        </Card>

        
        <EditAdminUserModal 
          editAdminUserModal={editAdminUserModal} 
          setEditAdminUserModal={setEditAdminUserModal} 
          rowData={rowData} setEditEmail={setEditEmail} 
          editEmail={editEmail} 
          setEditName={setEditName} 
          editName={editName} setReloadUserData={setReloadUserData} 
          reloadUserData={reloadUserData} setEditUserType={setEditUserType} 
          editUserType={editUserType} countryCodeList={countryCodeList}
          editUserCountryCode={editUserCountryCode} 
          setEditUserCountryCode={setEditUserCountryCode}
          editPhone={editPhone} 
          setEditPhone={setEditPhone}
        />
        <StatusChangeModal modalState={modalState} setModalState={setModalState} setStatus={setStatus} status={status} adminID={adminID} setReloadUserData={setReloadUserData} reloadUserData={reloadUserData} />
        {/* <ExportCSVTable data={userList} columns={tableColumns} /> */}
        <PasswordResetMailModal 
          PasswordMailModal={PasswordMailModal} 
          setpasswordMailModal={setpasswordMailModal}
          rowData={rowData}
          setRowData={setRowData}
        />
        <NewAdminUserModal
        newAdminUserModal={newAdminUserModal}
        setNewAdminUserModal={setNewAdminUserModal}
        />
   </div>
  );
};

export default AdminUsers;
