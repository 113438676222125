import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Input, Row, Spinner,Breadcrumb, BreadcrumbItem, Container, Label,CardHeader, CardBody, Modal, ModalBody, ModalFooter } from 'reactstrap';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { Hux } from "./../../hoc/Hux";
import { Link } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import { ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import QuerySave from './SqlQuerySaveModal.js'

const SQLQueryRun = () => {

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
  const [spinner, setSpinner] = useState(false)
  const [search, setSearch] = useState(false)
  const [apiLoaded, setApiLoaded] = useState(false)

  const [sqlQuery, setSQLQuery] = useState("")
  const [sqlQueryAPIData, setSQLQueryAPIData] = useState("")
  const [lengthOfOneObject, setLengthOfOneObject] = useState("")
  const [savedQueryModal,setSavedQueryModal] = useState(false);


  const fetchData = () => {
    setSpinner(true);
    setApiLoaded(false);

    const params =
    {
        "sql_query": sqlQuery
    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/sql-query/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(function (response) {
        if (response.data.length > 0) {
         
          setSQLQueryAPIData(response.data);
          setLengthOfOneObject(Object.keys(response.data[0]))
          setApiLoaded(true);
          setSpinner(false);
        } else {
          setSQLQueryAPIData("");
          setApiLoaded(true);
          setSpinner(false);
        }
      })
      .catch(function (error) {
        toastr.error('You Have Error In Your SQL Syntax','please try again')
        setApiLoaded(false);
        setSpinner(false);
      });
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

   
  const getHeaders = () => {
    var keys = lengthOfOneObject
    return keys.map((key, index)=>{
    if(key == null) {
      return "--"
    }else {
      return <th key={key}>{capitalizeFirstLetter(key)}</th>
    }
    })
  }
//   const RenderRow = (props) => {
//     return props.keys.map((key, index) => {
//       const cell = props.data[key];
//       let formattedCell = cell;
//       if (moment(cell, moment.ISO_8601, true).isValid()) {
//         formattedCell = moment.utc(cell).local().format('DD-MMM-YYYY hh:mm a');
//       }
//       return <td key={index}>{formattedCell}</td>;
//     });
//   };
const [newmodalState, setnewModalState] = useState(false)
const [viewMoreData, setViewMoreData] = useState('')
const viewMoreDatatoggleModal = ()=>{
  setnewModalState(false)
}
const moreDetails = (id, key,moreData) => {
  let data = moreData
  setViewMoreData(data)
  setnewModalState(true)
}
  const RenderRow = (props) => {
    return props.keys.map((key, index) => {
      if (props.data[key] == null) {
        return <td key={props.data[key]}>--</td>
      } else {
        let textData = (props.data[key].length) > 150 ? props.data[key].slice(0, 100) : props.data[key]
        return (
          <>
            <td key={props.data.id}>{textData}
              {
                (props.data[key].length) > 150 &&
                <p style={{color:'blue',cursor:'pointer'}} onClick={() => moreDetails(props.data.id, key,props.data[key])}>view more</p>
              }
            </td>
          </>
        )
      }
    })
  }

    const getRowsData = () => {
        var items = sqlQueryAPIData;
        var keys = lengthOfOneObject

        return items.map((row, index) => {
          if(row == null) {
            return "--"
          }else {
            return <tr key={index}><RenderRow key={index} data={row} keys={keys} /></tr>
          }
        })
    }

  
  const clearValues = () => {
    setSQLQuery("")
    setApiLoaded(false);
    setSQLQueryAPIData("")

  }

  const enableOrDisableSearch = () => {
    if (sqlQuery) {
      setSearch(true)
    } else {
      setSearch(false)
    }
  }

  useEffect(() => { enableOrDisableSearch(); }, [sqlQuery]) // eslint-disable-line
  // CheckUserInactivity()

  useEffect(() => {
    window.scrollTo(0, 0);
     }, []);
     
  return(
    <>
    <Hux>
      <Container fluid>
        <Header>
          <HeaderTitle>Execute SQL SELECT Querys</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>SQL query</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>
    </Hux>
  
    <Card className="adv-search-background-card">
      <CardHeader>
        <Row>
          <Col sm="9">
            <Label>SQL Query</Label>
            <Input
              type='textarea'
              name="sql-query"
              placeholder="Enter SELECT SQL Query"
              value={sqlQuery}
              onChange={(e) => {setSQLQuery(e.target.value)}}
            />
  
          </Col>
          <Col sm="3">
            <ButtonGroup>
              <Button
                className="go-buttons"
                onClick={() => { fetchData() }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Search" : ""}
                style={{ width: '100px', height: '40px', textAlign: 'center' }}
              >Run</Button>{" "}
              <Button
                className="reset-buttons"
                onClick={() => { clearValues() }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
                style={{ width: '100px', height: '40px', textAlign: 'center' }}
              >Clear</Button>{" "}
              <Button
                className="history-button"
                onClick={() => { setSavedQueryModal(true) }}
                title="Search History"
                style={{ width: '100px', height: '40px', marginTop: '2em', textAlign: 'center' }}
              >
                <FontAwesomeIcon icon={faHistory} style={{ height: '1.5rem' }} />
              </Button>
            </ButtonGroup>
          </Col>
  
        </Row>
        <Row>
  
        </Row>
        <div className="separator" />
      </CardHeader>
      <CardBody className="sqlquery">
  
        <div className="advanced-search-button">
          {spinner
            ? <Spinner />
            : ""}
        </div>
        {sqlQueryAPIData && apiLoaded
          ? <>
            <br />
            <Row className="advanced-search-table-height" style={{minHeight: '400px'}}>
              <Col className="p-0">
  
                {sqlQueryAPIData && apiLoaded ?
                  <div className="sqlquery-table" >
                    <table className='table' >
                      <thead>
                        <tr>{getHeaders()}</tr>
                      </thead>
                      <tbody>
                        {getRowsData()}
                      </tbody>
                    </table>
                  </div>
                  : ""}
  
                {!sqlQueryAPIData && apiLoaded ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""}
              </Col>
            </Row>
          </>
          : ""
        }
        {!sqlQueryAPIData && apiLoaded && !spinner ?
          <>
            <br />
            <Row className="advanced-search-table-height patient-list-table">
              <Col>
                {!sqlQueryAPIData && apiLoaded && !spinner ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""}
              </Col>
            </Row>
          </>
          : ""
        }
        <QuerySave
        savedQueryModal ={savedQueryModal}
        setSavedQueryModal ={setSavedQueryModal}/>
      </CardBody>
    </Card>
      <Modal isOpen={newmodalState} toggle={viewMoreDatatoggleModal}>
        <ModalBody>
          <pre  style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px'}}>
            {/* <code> */}
            {viewMoreData}
            {/* </code> */}
          </pre>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={viewMoreDatatoggleModal}>Close</Button>
        </ModalFooter>
      </Modal>
  </>
  
  )
}

export default SQLQueryRun
