import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'

const ActionDownloadModal = (props) => {
  const {
    modalState,
    setModalState,
    groupId,
    FileName,
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const toggle = () => {
    setModalState(!modalState);
  };

  const fieldDownload = ()=>{
    
    const params =
    {
      "group_id" : groupId ? groupId : "",
       "file_name" : FileName ? FileName : ""
     

    }
    
    AxiosInter.post(`${API_ENDPOINT}/admin-api/file-download-adminpanel/`, params, {
      headers: {

        Authorization: API_TOKEN,

      },
      responseType: "blob"
    })
      .then((response) => {
        if (response.status === 200) {
          var fieldDownload = require("js-file-download");
          fieldDownload(
            response.data, `${FileName}`,
            response["headers"]["x-suggested-filename"]
          );
          setModalState(!modalState);

          toastr.success(
            "Success",
            "Download successful"
          );

        }

      })
      .catch((error) => {

        toastr.error(
          "Failed",
          "Something went wrong"
        );
      });

  };
  

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={modalState} >
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Download</ModalHeader>
        <ModalBody>
            <div style={{"text-align": "center"}}>
            Are you sure you want to download ?
            </div>
        </ModalBody>
        <ModalFooter  className="custom-modal-footer">
          <Button className="yes-button button-width" onClick={fieldDownload}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ActionDownloadModal;
