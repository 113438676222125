/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Alert,
  CardBody,
  CardTitle, Modal, ModalBody, ModalFooter, ModalHeader, Label, Spinner
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import AxiosInter from './../../AxiosInter.js';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded";
import EditPermissionsForAppUsersModal from "../../newComponents/utilities/EditPermissionsForAppUsersModal";
import AddNewModuleForAppUsers from "../../newComponents/utilities/AddNewModuleForAppUsers";
import DeleteConfirmationModal from '../../newComponents/utilities/DeleteConfirmationModal';
import { faTrash, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chip from "@mui/material/Chip";
import { toastr } from 'react-redux-toastr';
import Cookie from 'js-cookie';

const ViewPermissionsForAppUsersModal = (props) => {
  const { viewPermissionsForAppUsersModal, setViewPermissionsForAppUsersModal, userName, auId} = props
  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [spinner, setSpinner] = useState(false); // eslint-disable-line
  const [apiLoaded, setApiLoaded] = useState(false); // eslint-disable-line
  const [roleList, setRoleList] = useState("");
  const [moduleList, setModuleList] = useState("");
  const [rowData, setRowData] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [multiSpecialityLength, setMultiSpecialityLength] = useState(""); // eslint-disable-line
  const [APIData, setAPIData] = useState("");
  const [selectedRole, setSelectedRole] = useState("Physician");
  const [loopRoleID, setLoopRoleID] = useState(""); // eslint-disable-line
  const [OptionsOfMPermission, setOptionsOfMPermission] = useState("");
  const [reload, setReload] = useState(1);
  const [selected, setSelected] = useState(false); // eslint-disable-line
  const [selectedIndex, setSelectedIndex] = useState(0); // eslint-disable-line
  const [selectedModuleId, setSelectedModuleId] = useState('1');// eslint-disable-line
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [singleGroupId, setSingleGroupId] = useState(0);
  const [groupDD, setGroupDD] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [editModuleId, setEditModuleId] = useState();
  const [addModule, setAddModule] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggle = () => {
    setViewPermissionsForAppUsersModal(false);
    setSelectedGroupId()
    setSingleGroupId()
  }
  const getRoleList = () => {
    setSpinner(true);
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/get-m-role-list/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((res) => {
        setRoleList(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(()=>{
        setSpinner(false);
      })
  };

  const getModuleList = () => { // eslint-disable-line
    setSpinner(true);
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/get-m-modules/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((res) => {
        var array = [];
        res.data.data.map((item1) => { // eslint-disable-line
         
          array.push({
            value: item1.id,
            label: item1.name,
          });
        });
        setModuleList(array);
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(()=>{
        setSpinner(false);
      })
  };
  const getM_Permission = () => {
    setSpinner(true);
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/get-m-permission/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((res) => {
        var array = [];
        res.data.data.map((item1) => {// eslint-disable-line
          
          array.push({
            value: item1.id,
            label: item1.displayName,
          });
        });
        setOptionsOfMPermission(array);
      })
      .catch((error) => {
        console.log('error', error)
      })
      .finally(()=>{
        setSpinner(false);
      })
  };
  const fetch_groupDropDownOptions = () => {
    setSpinner(true)
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-group-options-for-appuser/${auId}`, {
      headers: {
        Authorization: API_TOKEN,
      }
    })
      .then((response) => {
        if (response.status === 200) {
          setGroupDD(response.data.data)
          if (response.data.data.length === 1){
          setSingleGroupId(response.data.data[0]['value'])
        }
          // console.log('383',response.data.data[0]['value'])

        }

      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setSpinner(false)
      })
  }
  const FetchPermissionData = () => {
    setSpinner(true);
    setApiLoaded(false);
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/get-appuser-permission-list/`,
        {
          appUserId: auId ? auId : "1",
          groupId: groupDD.length>1 ? selectedGroupId: singleGroupId   
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        if (response?.data) {
          setAPIData(response?.data);
          setApiLoaded(true);
        } else {
          setAPIData("");
          setApiLoaded(true);
        }
      })
      .catch(function (error) {
        setApiLoaded(true);
      })
      .finally(()=>{
        setSpinner(false);
      })
  };
  const deleteModuleForRole = () => {

    const params =
    {
      "row_id": selectedRowIds ? selectedRowIds : ""

    }
    AxiosInter.post(`${API_ENDPOINT}/admin-api/delete-user-permissions/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        toastr.success("Success", "Deleted successfully")
        setDeleteModal(false);
        FetchPermissionData()
      })
      .catch(error => {
        toastr.error("Error", "Something went wrong")
      });
  };

  const handleListItemClick = (event, index) => {
    setSelectedRole(event.currentTarget.innerText);
    setSelectedIndex(index);
  };

  const GenerateRoleList = (item, index) => {
    return (
      <>
        <ListItemButton
          onClick={(event) => handleListItemClick(event, index)}
        >
          <div className="css-cveggr-MuiListItemIcon-root ">
            <ListItemIcon>
              <Brightness1RoundedIcon
                className="css-ptiqhd-MuiSvgIcon-root"
                fontSize="small"
              />
            </ListItemIcon>
          </div>
          <ListItemText>{item}</ListItemText>
        </ListItemButton>
        <Divider />
      </>
    );
  };

  const ListOfRole = () => {
    let multiSpecialityLengthh = roleList.map((item, index) => {
      return GenerateRoleList(item.designation, index);
    });
    setMultiSpecialityLength(multiSpecialityLengthh);
  };
  const LoopForRoleID = () => {
    if (roleList && selectedRole) {
      // eslint-disable-next-line no-unused-vars, array-callback-return
      const opt4 = roleList.map((item) => {
        // eslint-disable-line
        if (selectedRole === item.designation) {
          setLoopRoleID(item.id.toString());
        }
      });
    }
  };
  const formatter = (cell) => {
    // debugger
    var valuesForDD = [];
    let cellData = cell ? cell.split(",") : "N/A";
    // eslint-disable-next-line array-callback-return
    if (cellData !== "N/A") {
      cellData.map((item) => {// eslint-disable-line
        // eslint-disable-line
        // debugger
        // eslint-disable-next-line eqeqeq
        if (item == 1) {
          // eslint-disable-line
          valuesForDD.push("View");
          // eslint-disable-next-line eqeqeq
        } else if (item == 2) {
          // eslint-disable-line
          valuesForDD.push("Create/Edit");
          // eslint-disable-next-line eqeqeq
        } else if (item == 3) {
          // eslint-disable-line
          valuesForDD.push("Delete");
          // eslint-disable-next-line eqeqeq
        } else if (item == 4) {
          // eslint-disable-line

          valuesForDD.push("Import/Export");
        }
      });
    }
    else {
      valuesForDD.push("--")
    }
    return (
      <>
        {valuesForDD.map((item) => (
          <>
            <Chip color="success" variant="outlined" label={item} />{" "}
          </>
        ))}
      </>
    );
  };

  const actionFormatter = (cell, row) => {
    return (row.GroupID !== null ?
      <>
        <Button
          className="money-button-blue"
          outline
          onClick={() => {
            setRowData(row);
            setEditModal(true);
            setEditModuleId(row.moduleID)
          }}
          color="primary"
          title="Edit Permissions"
        >
          <FontAwesomeIcon
            icon={faUserEdit}
            fixedWidth
            className="align-middle"
            title="Edit Permissions"
          />
        </Button>
        <Button
          onClick={() => {
            setSelectedModuleId(row.moduleId)
            setSelectedRowIds(row.rowIDs)
            // selectedRowIds.push(row.rowIDs)
            // console.log('308',selectedRowIds)
            setDeleteModal(true);
          }}
          color={'#E12C2C'}
          title="Delete Permissions"
        >
          <FontAwesomeIcon
            icon={faTrash}
            color={'#E12C2C'}
            size={'1x'}
            // fixedWidth
            // className="align-middle"
            title="Delete Permissions"
          />
        </Button>
      </> : ""
    );
  };

  const tableColumns = [
    {
      dataField: "moduleName",
      text: "Module",
      sort: true,
    },
    {
      dataField: "permissionIds",
      text: "Permissions",
      formatter: formatter,
    },
    {
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const blankState = (
    <div>
      <div className="separator" />
      <Card className="blank-state">
        <CardBody>
          <h3>No Module Configured For This Role</h3>
        </CardBody>
      </Card>
    </div>
  );

  const SelectedOnce = () => {
    // eslint-disable-next-line array-callback-return
    let select = roleList.map((item) => {
      // eslint-disable-line
      // eslint-disable-next-line eqeqeq
      if (item.designation == selectedRole) {
        // eslint-disable-line
        return true;
      }
    });
    setSelected(select);
  };
  
  useEffect(() => {
    if (!OptionsOfMPermission) getM_Permission();
  }, [OptionsOfMPermission]); // eslint-disable-line

  useEffect(() => {

    if(singleGroupId||selectedGroupId){
      FetchPermissionData();
    }
  }, [auId, selectedGroupId,reload, singleGroupId]); // eslint-disable-line

  useEffect(() => {
    if (!roleList) getRoleList();
  }, [roleList]); // eslint-disable-line

  useEffect(() => {
    if (roleList) ListOfRole();
  }, [roleList]); // eslint-disable-line

  useEffect(() => {
    if (selectedRole) LoopForRoleID();
  }, [selectedRole]); // eslint-disable-line

  useEffect(() => {
    if (roleList) SelectedOnce();
  }, [selectedRole]); // eslint-disable-line

  useEffect(() => {
    if (viewPermissionsForAppUsersModal)
       fetch_groupDropDownOptions(); 
  }, [viewPermissionsForAppUsersModal]); // eslint-disable-line
 
  return (
      <div>
      <Modal isOpen={viewPermissionsForAppUsersModal}  size="lg" >
        <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center', 'height': '50%' }} toggle={toggle}><div className="div-alert">
          Configure User Permissions for <Alert className="alert-success">
            <h3>{userName}</h3>

          </Alert>
        </div>
          </ModalHeader>
        <ModalBody className="viewPermissionsForAppUsersModal">
        {spinner && <Spinner />}
          <Row>
            <Col >
              <Card className="roles-list-card">
                <CardBody>
                  <CardTitle>
                  {groupDD.length > 1 ?<>
                    <Row>
                      <Col sm={2}>
                        <Label for="groups">Select Group<span className="required">*</span>:</Label>
                      </Col>
                      <Col sm={6}>
                        <Select
                          id="groups"
                          options={groupDD}
                          placeholder="Select group"
                          // isClearable
                          isSearchable
                          defaultValue={selectedGroupId ? selectedGroupId.value : ""}
                          onChange={(e) => {
                            setSelectedGroupId(e.value)
                          }}
                        />
                      </Col>
                      <Col sm={4}>
                      {selectedGroupId ?
                        <Button
                          className="Add-btn-permission-manager pull-right"
                          onClick={() => {
                            setAddModule(true);
                          }}
                        >
                          + Permissions
                        </Button> : ""}
                      </Col>
                    </Row>
                    {selectedGroupId ? <>
                      {APIData.length > 0 ? (
                        <BootstrapTable
                          bootstrap4
                          keyField="id"
                          data={APIData}
                          className="table"
                          columns={tableColumns}
                          bordered={false}
                        />
                      ) : (
                        blankState
                        )}</> : ""} </> : <>
                        <Row>
                          <Button
                            className="Add-btn-permission-manager pull-right"
                            onClick={() => {
                              setAddModule(true);
                            }}
                          >
                            + Permissions
                          </Button> 
                        </Row>
                        {APIData.length > 0 ? (
                          <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={APIData}
                            className="table"
                            columns={tableColumns}
                            bordered={false}
                          />
                        ) : (
                          blankState
                        )}</>} 
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="advanced-search-button">
          <Button outline className="no-button button-width" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
      <EditPermissionsForAppUsersModal
        selectedGroupId={selectedGroupId}
        groupDD={groupDD}
        singleGroupId={singleGroupId}
        auId={auId}
        editModuleId={editModuleId}
        reload={reload}
        setReload={setReload}
        rowData={rowData}
        editModal={editModal}
        setEditModal={setEditModal}
        OptionsOfMPermission={OptionsOfMPermission}
      />
      <AddNewModuleForAppUsers
        selectedGroupId={selectedGroupId}
        groupDD={groupDD}
        singleGroupId={singleGroupId}
        auId={auId}
        reload={reload}
        setReload={setReload}
        setAddModule={setAddModule}
        addModule={addModule}
        moduleList={moduleList}
        setModuleList={setModuleList}
        roleList={roleList}
        selectedRole={selectedRole}
        OptionsOfMPermission={OptionsOfMPermission}
      />
      <DeleteConfirmationModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteFunction={deleteModuleForRole}
      />
      </div>
  );
};

export default ViewPermissionsForAppUsersModal;
