import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  FormFeedback,
  Container,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import { Select as SelectVirtualized } from "react-select-virtualized";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { GlobalState } from "../../contextAPI/Context";
import Cookie from 'js-cookie';

const InsuranceCompanyModal = (props) => {
  const { groupNamesForDropDown, zipCodeOptions } = useContext(GlobalState);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [buttonBlankState, setButtonBlankState] = useState(false);
  const [selectedZipCode, setSelectedZipCode] = useState("");

  // eslint-disable-next-line no-useless-escape
  const regexForEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  // const [zipCodeOptions, setZipCodeOptions] = useState([]);

  const {
    insuranceCompanyModal,
    setInsuranceCompanyModal,
    insuranceCompanyModalState,
    setInsuranceCompanyModalState,
    editInsuranceCompanyGroupName,
    setEditInsuranceCompanyGroupName,
    insuranceCompanyZipCode,
    groupOptions,
    selectedGroupName,
    setSelectedGroupName,
    editGroupInsuranceName,
    setEditGroupInsuranceName,
    insuranceGroupNames,
    insuranceCompanyAddress,
    insuranceCompanyProviderName,
    setInsuranceCompanyProviderName,
    insuranceCompanyPhone,
    setInsuranceCompanyPhone,
    insuranceCompanyFax,
    setInsuranceCompanyFax,
    setInsuranceCompanyAddress,
    insuranceCompanyContactFirst,
    setInsuranceCompanyContactFirst,
    insuranceCompanyContactMiddle,
    setInsuranceCompanyContactMiddle,
    insuranceCompanyContactLast,
    setInsuranceCompanyContactLast,
    insuranceCompanyEmail,
    setInsuranceCompanyEmail,
    selectedInsuranceCompanyPlanTypeDetails,
    setSelectedInsuranceCompanyPlanTypeDetails,
    insuranceCompanyPlanTypeNames,
    insuranceCompanyReload,
    setInsuranceCompanyReload,
    planTypes,
    insuranceCompanyId,
    setInsuranceCompanyZipCode,
    setEditGroupInsuranceCompanyPlanTypeName,
  } = props;

  const [isInsuranceCompanyEmailValid, setIsInsuranceCompanyEmailValid] =
    useState(false);

  const toggle = () => {
    setInsuranceCompanyModal(!insuranceCompanyModal);
  };

  const groupInsuranceCompanyPlanTypeAsync = (inputValue) => {
    if (insuranceCompanyPlanTypeNames) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(insuranceCompanyPlanTypeNames, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const setInitialZipCode = () => {
    // eslint-disable-next-line array-callback-return
    zipCodeOptions.map((data) => {
      // eslint-disable-line
      if (data.label === insuranceCompanyZipCode) {
        setSelectedZipCode({
          value: data.value,
          label: insuranceCompanyZipCode,
        });
      }
    });
  };

  const setInitialGroupName = () => {
    // eslint-disable-next-line array-callback-return
    groupNamesForDropDown.map((data) => {
      // eslint-disable-line
      if (data.label === editInsuranceCompanyGroupName) {
        setSelectedGroupName({
          value: data.value,
          label: editInsuranceCompanyGroupName,
        });
      }
    });
  };

  const setInitialInsuranceGroupName = () => {
    // eslint-disable-next-line array-callback-return
    insuranceGroupNames.map((data) => {
      // eslint-disable-line
      if (data.label === editGroupInsuranceName) {
        return setEditGroupInsuranceName({
          value: data.value,
          label: editGroupInsuranceName,
        });
      }
    });
  };

  const validate = () => {
    if (insuranceCompanyProviderName) {
      setButtonBlankState(false);
    } else {
      setButtonBlankState(true);
    }
  };

  const addNewInsuranceCompany = () => {
    if (insuranceCompanyEmail !== "") {
      if (regexForEmail.test(insuranceCompanyEmail) === false) {
        setIsInsuranceCompanyEmailValid(true);
        return false;
      } else {
        setIsInsuranceCompanyEmailValid(false);
      }
    }

    const params = {
      provider_name: insuranceCompanyProviderName
        ? insuranceCompanyProviderName
        : "",
      address: insuranceCompanyAddress ? insuranceCompanyAddress : "",
      email: insuranceCompanyEmail ? insuranceCompanyEmail : "",
      phone: insuranceCompanyPhone ? insuranceCompanyPhone : "",
      fax: insuranceCompanyFax ? insuranceCompanyFax : "",
      zipCode: selectedZipCode ? selectedZipCode.label : "",
      contactFirst: insuranceCompanyContactFirst
        ? insuranceCompanyContactFirst
        : "",
      contactLast: insuranceCompanyContactLast
        ? insuranceCompanyContactLast
        : "",
      contactMiddle: insuranceCompanyContactMiddle
        ? insuranceCompanyContactMiddle
        : "",
      groupID: selectedGroupName ? selectedGroupName.value : "",
      insuranceGroupId: editGroupInsuranceName
        ? editGroupInsuranceName.value
        : "",
      insurancePlanTypeIdList: planTypes ? planTypes : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/add-new-insurance-company-details/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        toggle();
        toastr.success("Success", "New Group Insurance saved");
        setInsuranceCompanyReload(insuranceCompanyReload + 1);
      })
      .catch((error) => {
        if (error.response.data.message) {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong !");
        }
      });
  };

  const editInsuranceCompany = () => {
    if (insuranceCompanyEmail !== "") {
      if (regexForEmail.test(insuranceCompanyEmail) === false) {
        // toastr.warning("Invalid entry","Invalid email");
        setIsInsuranceCompanyEmailValid(true);
        return false;
      } else {
        setIsInsuranceCompanyEmailValid(false);
      }
    }

    const params = {
      insurance_company_id: insuranceCompanyId ? insuranceCompanyId : "",
      provider_name: insuranceCompanyProviderName
        ? insuranceCompanyProviderName
        : "",
      address: insuranceCompanyAddress ? insuranceCompanyAddress : "",
      email: insuranceCompanyEmail ? insuranceCompanyEmail : "",
      phone: insuranceCompanyPhone ? insuranceCompanyPhone : "",
      fax: insuranceCompanyFax ? insuranceCompanyFax : "",
      zipCode: selectedZipCode ? selectedZipCode.label : "",
      contactFirst: insuranceCompanyContactFirst
        ? insuranceCompanyContactFirst
        : "",
      contactLast: insuranceCompanyContactLast
        ? insuranceCompanyContactLast
        : "",
      contactMiddle: insuranceCompanyContactMiddle
        ? insuranceCompanyContactMiddle
        : "",
      groupID: selectedGroupName ? selectedGroupName.value : "",
      insuranceGroupId: editGroupInsuranceName
        ? editGroupInsuranceName.value
        : "",
      insurancePlanTypeIdList: planTypes ? planTypes : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/edit-insurance-company-details/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        toggle();
        toastr.success("Success", "Insurance Company details updated");
        setInsuranceCompanyReload(insuranceCompanyReload + 1);
      })
      .catch((error) => {
        if (error.response.data.message) {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.error("Error", "Something went wrong !");
        }
      });
  };

  useEffect(() => {
    if (!insuranceCompanyModal) {
      setInsuranceCompanyModalState("initial");
      setInsuranceCompanyProviderName("");
      setInsuranceCompanyEmail("");
      setInsuranceCompanyAddress("");
      setInsuranceCompanyPhone("");
      setInsuranceCompanyFax("");
      setInsuranceCompanyContactFirst("");
      setInsuranceCompanyContactMiddle("");
      setInsuranceCompanyContactLast("");
      setSelectedZipCode("");
      setSelectedGroupName("");
      setEditGroupInsuranceName("");
      setSelectedInsuranceCompanyPlanTypeDetails([]);
      setEditInsuranceCompanyGroupName("");
      setInsuranceCompanyZipCode("");
      setEditGroupInsuranceCompanyPlanTypeName([]);

      setInsuranceCompanyModal(false);
      setIsInsuranceCompanyEmailValid(false);
      // setSelectedInsuranceCompanyPlanTypeDetails([]);
    }
  }, [insuranceCompanyModal]); // eslint-disable-line

  useEffect(() => {
    if (zipCodeOptions && insuranceCompanyZipCode) {
      setInitialZipCode();
    }
  }, [zipCodeOptions, insuranceCompanyZipCode]); // eslint-disable-line

  useEffect(() => {
    if (groupNamesForDropDown && editInsuranceCompanyGroupName) {
      setInitialGroupName();
    }
    if (insuranceGroupNames && editGroupInsuranceName) {
      setInitialInsuranceGroupName();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupOptions,
    editInsuranceCompanyGroupName,
    insuranceGroupNames,
    editGroupInsuranceName,
  ]); // eslint-disable-line

  useEffect(() => {
    validate();
  }, [insuranceCompanyProviderName]); // eslint-disable-line

  return (
    <div>
      <Modal isOpen={insuranceCompanyModal} size="lg" >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          {insuranceCompanyModalState === "add"
            ? "New Insurance Company "
            : "Edit Insurance Company"}{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal">
          <Container fluid>
            <FormGroup>
              <Row>
                <Col sm={6}>
                  <Label for="provider">
                    Provider<span className="required">*</span>
                  </Label>
                  <Input
                    id="provider"
                    type="text"
                    placeholder="Name"
                    value={insuranceCompanyProviderName}
                    onChange={(e) => {
                      setInsuranceCompanyProviderName(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Label for="email">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={insuranceCompanyEmail}
                    invalid={isInsuranceCompanyEmailValid ? true : false}
                    onChange={(e) => {
                      setInsuranceCompanyEmail(e.target.value);
                    }}
                  />
                  <FormFeedback invalid>Enter a valid email</FormFeedback>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={12}>
                  <Label for="address">Address</Label>
                  <Input
                    id="group_insurance_description"
                    type="textarea"
                    rows={4}
                    cols={3}
                    placeholder="Description"
                    value={insuranceCompanyAddress}
                    onChange={(e) => {
                      setInsuranceCompanyAddress(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Label for="phone">Phone</Label>
                  <Input
                    id="phone"
                    type="number"
                    placeholder="Phone"
                    value={insuranceCompanyPhone}
                    onChange={(e) => {
                      if(e.target.value.length<=12){
                        setInsuranceCompanyPhone(e.target.value);
                      }
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Label for="fax">Fax</Label>
                  <Input
                    id="Fax"
                    type="number"
                    placeholder="fax"
                    value={insuranceCompanyFax}
                    onChange={(e) => {
                      setInsuranceCompanyFax(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={4}>
                  <Label for="contact_first">Contact First</Label>
                  <Input
                    id="contact_first"
                    type="text"
                    placeholder="Contact First"
                    value={insuranceCompanyContactFirst}
                    onChange={(e) => {
                      setInsuranceCompanyContactFirst(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={4}>
                  <Label for="contact_middle">Contact Middle</Label>
                  <Input
                    id="contact_middle"
                    type="text"
                    placeholder="Contact Middle"
                    value={insuranceCompanyContactMiddle}
                    onChange={(e) => {
                      setInsuranceCompanyContactMiddle(e.target.value);
                    }}
                  />
                </Col>
                <Col sm={4}>
                  <Label for="contact_middle">Contact Last</Label>
                  <Input
                    id="contact_last"
                    type="text"
                    placeholder="Contact Last"
                    value={insuranceCompanyContactLast}
                    onChange={(e) => {
                      setInsuranceCompanyContactLast(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <br />

              <Row>
                <Col sm={6}>
                  <Label for="zip">ZIP</Label>
                  <SelectVirtualized
                    name="zip"
                    options={zipCodeOptions}
                    value={selectedZipCode}
                    placeholder="Choose zip code"
                    isSearchable
                    isClearable
                    onChange={(e) => {
                      setSelectedZipCode(e);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Label for="groupDropDown">Group</Label>
                  <SelectVirtualized
                    name="groupDropDown"
                    options={groupNamesForDropDown}
                    value={selectedGroupName}
                    placeholder="Choose a Group"
                    isSearchable
                    isClearable
                    onChange={(e) => {
                      setSelectedGroupName(e);
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Label for="insurance_group">Insurance Group</Label>

                  <SelectVirtualized
                    cacheOptions
                    isClearable
                    isSearchable
                    value={editGroupInsuranceName}
                    options={insuranceGroupNames}
                    placeholder={"Select Insurance Group Name"}
                    onChange={(event) => {
                      setEditGroupInsuranceName(event);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Label for="insurance_plan_type_name">
                    Insurance Plan Type Name
                  </Label>
                  <AsyncSelect
                    isMulti
                    cacheOptions
                    isClearable
                    isSearchable
                    value={selectedInsuranceCompanyPlanTypeDetails}
                    placeholder={"Choose Insurance Plan Type Names"}
                    onChange={(e) => {
                      setSelectedInsuranceCompanyPlanTypeDetails(e);
                    }}
                    loadOptions={(inputValue) =>
                      groupInsuranceCompanyPlanTypeAsync(inputValue)
                    }
                  />
                </Col>
              </Row>
              <br />
            </FormGroup>
          </Container>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            onClick={() => {
              insuranceCompanyModalState === "edit"
                ? editInsuranceCompany()
                : addNewInsuranceCompany();
            }}
            disabled={buttonBlankState}
            title={
              buttonBlankState
                ? "Please fill in all mandatory fields"
                : !buttonBlankState && insuranceCompanyModalState === "add"
                ? "Click to save"
                : "Click to update"
            }
          >
            {insuranceCompanyModalState === "edit" ? "Update" : "Add"}
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InsuranceCompanyModal;
