import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import AsyncSelect from 'react-select/async';
import _ from "lodash";

const NewAppointmentTagModal = (props) => {
    const { addAppTagModal, setAddAppTagModal, reloadApptTag ,setReloadApptTag } = props;

    // CONTEXT VARIABLES 
    const { setSpinner,Spinner } = useContext(GlobalState);

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");// eslint-disable-line
    const [addButtonStatus, setAddButtonStatus] = useState(true);

    const [newApptTagName, setNewApptTagName] = useState("");
    const [groupOpt, setGroupOpt] = useState("");  
    const [newGroup, setNewGroup] = useState("");

    const [groupList, setGroupList] = useState("");

    const fetchGroupData = async()=>{
        setSpinner(true)
        try{
            const response= await AxiosInter.post(`${API_ENDPOINT}/admin-api/graphql/`,{
                query:  `
                    query{
                        groups{
                            id
                            name
                        }
                    }
                `,
            });
            const data= response.data;
            setGroupList(data.data.groups);    
        }
        catch(error){
        }
        setSpinner(false)
    }
   

    const toggle = () => {
        setAddAppTagModal(!addAppTagModal);
        setNewGroup("")
        setNewApptTagName("")
        setGroupList("")


    }

    const OverlayLoader = () => (
        <div className="item">
            <i className="loader --1"></i>
        </div>
    );

    const confirmation = () => {
        if (!newApptTagName) {
            toastr.error("Tag Name Is Required", "Please enter the tag name!")
        }
        if (!newGroup) {
            toastr.error("Group field Is Required", "Please select the group!")
        }
        if (newApptTagName && newGroup ) {
            CreateApptTagApiCall();
        }

    };

    const optionsForGroup = () => { 
       
        if(groupList){
            let array = []
            groupList.map((item, index) => {  // eslint-disable-line
                array.push({
                    value: item.id,
                    label: item.name
                })
            })

            setGroupOpt(array)
        }
    }

    const getAsyncOptions = (inputValue) => { 
       
        if(groupOpt){
         return new Promise((resolve, reject) => {
             const filtered = _.filter(groupOpt, o =>
               _.startsWith(_.toLower(o.label), _.toLower(inputValue))
             );
             resolve(filtered);
           });
        }
    }

    const CreateApptTagApiCall = () => {
        setSpinner(true);
        const string4 = JSON.stringify(newApptTagName);
        AxiosInter.post(`${API_ENDPOINT}/admin-api/graphql/`,{
            query : `mutation updateGroupTag {
                addGroupTag(groupId: ${ newGroup.value ? Number(newGroup.value) : 0}, tag:${ string4 }){
                  groupTag{
                    id
                    tag
                    status
                    
                  }
                }
              }`
            })
               .then(res => {
                   if(res.data.data.addGroupTag.groupTag === null){
                       toastr.error("Error", "May be your adding duplicate entry");
                    
                   }
                    else if(res.status === 200 && res.data.data.addGroupTag.groupTag != null ) {
                        setAddAppTagModal(!addAppTagModal);
                        toastr.success("Success", "Appointment Tag Successfully Creating");
                        setReloadApptTag(reloadApptTag + 1);
                        toggle();
                    }
                    else {
                        setAddAppTagModal(!addAppTagModal);
                            toastr.error("Error", "Appointment Tag Failed Creating");
                    }
                    setSpinner(false)
        
               })
               .catch(err => {
                 console.log(err);
                 setAddAppTagModal(!addAppTagModal);
                 toastr.error("Error", "Appointment Tag Creating Failed");
                 setSpinner(false)
               })
               setSpinner(false)
    }


    const disableAddButton = () => {

        if (
            newApptTagName &&
            newGroup           
        ) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }

   

    useEffect(() => { disableAddButton() }, [newApptTagName, newGroup]) // eslint-disable-line

    useEffect(() => { optionsForGroup() } , [groupList]);    // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { fetchGroupData() } , []);    // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {Spinner ? <OverlayLoader className=".overlay-z-index" /> : null}
            <Modal isOpen={addAppTagModal}  size="sm"  >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add New Appointment Tags </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars center-align" >

                    <FormGroup>
                        <Row>
                            <Col xs="10">

                            <Label>
                                Group  <span className="required">*</span> 
                                </Label>
                                <AsyncSelect
                                    cacheOptions
                                    isClearable
                                    isSearchable
                                    /* defaultOptions */
                                    placeholder="start typing group name for options"
                                    value={newGroup}
                                    onChange={(event) => setNewGroup(event)} 
                                    loadOptions={inputValue => getAsyncOptions(inputValue)}
                                />
                            </Col>
                            
                        </Row>
                        <br></br>
                        <Row>
                            
                            <Col xs="10">
                            <Label>
                                   Tag Name  <span className="required">*</span> 
                                </Label>

                                <Input
                                     id="name"
                                     value={newApptTagName}
                                     placeholder="Enter the user name"
                                     onChange={(e) => {if (e.target.value.length <= 150) setNewApptTagName(e.target.value)}}
                                     />
                                <br></br>
                            </Col>
                                     </Row>
                  
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={confirmation}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>

        </div>
    );
}

export default NewAppointmentTagModal;
