import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Input, // eslint-disable-line
} from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit"; // eslint-disable-line
import paginationFactory from "react-bootstrap-table2-paginator"; // eslint-disable-line
import moment from "moment";
import MomentConfig from "../../common/MomentConfig";
import { GlobalState } from "../../contextAPI/Context";
import NewReferralSenderModal from "../../newComponents/utilities/NewReferralSenderModal";

const ReferralSenderDataTable = (props) => {
  const { blankStateMessage } = useContext(GlobalState); // eslint-disable-line
  const [blankStateStatus, setBlankStateStatus] = useState(false);

  // LOCAL VARIABLES

  const {
    totalSize,
    handleTableChangeForSender,
    setReferralSenderReload,
    referralSenderReload,
    setSearchKeysForSender,
    searchKeysForSender,
    exportReferralSenderTableData,
    fetchReferralSenderData
  } = props;
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [ReferralSenderAddModal, setReferralSenderAddModal] = useState(false);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      exportReferralSenderTableData();
    };

    return (
      <div>
        <button className="btn btn-export mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  const options = {
    page: props.senderPage,
    nextPageText: ">",
    prePageText: "<",
    sizePerPage: props.senderSizePerPage,
    sizePerPageList: [50,100, 150, 200],
    totalSize,
  };

  /* const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const OnSearch = (e)=>{
    setSearchValue(e.target.value);
  } */

  useEffect(() => {
    setNumberOfRecords(totalSize.toString());
  }, [totalSize]);
  useEffect(() => {
    if (props.data.length === 0) {
      setBlankStateStatus(true);
    } else {
      setBlankStateStatus(false);
    }
  }, [props.data.length]);

  return (
    <div className="physicians_table adjustment">
      <>
        <ToolkitProvider
          keyField="id"
          data={props.data}
          columns={props.columns}
          search
          exportCSV={{
            fileName: `Equipo-Referral-sender-Export-${moment().format(
              MomentConfig.MOMENT_FORMAT_DATEANDTIME
            )}.csv`,
          }}
        >
          {(props) => (
            <div>
              <Card>
                <CardHeader>
                  <div className="separator" />
                  <Row>
                    <Col xs={2}>
                      <div className="number-of-records">
                        <p>
                          Number of records: <strong>{numberOfRecords}</strong>
                        </p>
                      </div>
                    </Col>
                    <Col className="">
                      <div className="form-inline justify-content-end">
                        <div className="form-group mr-2">
                          <Input
                            className="search-box-new"
                            type="text"
                            value={searchKeysForSender}
                            onChange={(e) =>
                              setSearchKeysForSender(e.target.value)
                            }
                            placeholder="Search"
                          />
                        </div>
                        <Button
                          className="clear-search-box"
                          onClick={() => {
                            setSearchKeysForSender("");
                            setReferralSenderReload(referralSenderReload + 1);
                          }}
                        >
                          Clear
                        </Button>
                        <div className="export-button ml-2">
                          <MyExportCSV {...props.csvProps} />
                        </div>
                        <Button
                          className="referral-add-btn ml-2 pull-right"
                          onClick={() => {
                            setReferralSenderAddModal(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            fixedWidth
                            className="align-middle"
                            title="See Fulfillers"
                          />
                          Referral Sender
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <div className="separator" />
                  <div className="float-right pull-right"></div>
                </CardHeader>

                <CardBody className="patient-list">
                  {blankStateStatus ? (
                    blankState
                  ) : (
                    <div className="scrollTable">
                      <BootstrapTable
                        bootstrap4
                        {...props.baseProps}
                        remote
                        bordered={false}
                        keyField="id"
                        className="react-bootstrap-table"
                        pagination={paginationFactory(options)}
                        onTableChange={handleTableChangeForSender}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>
          )}
        </ToolkitProvider>
        <NewReferralSenderModal
          ReferralSenderAddModal={ReferralSenderAddModal}
          setReferralSenderAddModal={setReferralSenderAddModal}
          fetchReferralSenderData={fetchReferralSenderData}
        />
      </>
    </div>
  );
};

export default ReferralSenderDataTable;
