import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'


const AppointmentTagDeleteModal = (props) => {
  const {
    deleteAppTagModal,
    setDeleteAppTagModal,
        iD,
        reloadApptTag,
        setReloadApptTag,
        name
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const toggle = () => {
    setDeleteAppTagModal(!deleteAppTagModal);
  };

  const confirmation = () => {
    

    const updatedStatus = {
    "tag_id": iD ? Number(iD) :0
    };

    AxiosInter.post( `${API_ENDPOINT}/admin-api/appointment-tag-delete/`, updatedStatus,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
        if (res.status === 201) {
          toastr.success('Successful', 'appointment tag removed successfully');
          setDeleteAppTagModal(!deleteAppTagModal);
          setReloadApptTag(reloadApptTag+1)
        }
      })
      .catch((error) => {
        // console.log(error);
        toastr.error('Try again', 'status update error');
      });
  };

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={deleteAppTagModal}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Delete Appointment Tag ?</ModalHeader>
        <ModalBody>
            <div>
              Are you sure you want to delete <strong> {name} </strong>  ?
            </div>
        </ModalBody>
        <ModalFooter  className="custom-modal-footer">
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AppointmentTagDeleteModal;
