import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'

const ReferralAssigneStatusChangeModal = (props) => {
  const {
    ReferralAssigneeStatusModal,
    setReferralAssigneeStatusModal,
    referralAssigneTagID,
    referralAssigneTagStatus,
    setReloadForReferralAssigneeTag,
    reloadForReferralAssigneeTag
    } = props;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    

  const toggle = () => {
    setReferralAssigneeStatusModal(!ReferralAssigneeStatusModal);
  };

  const confirmation = () => {
    let newStatus = "";
    if (referralAssigneTagStatus === 0) {
      newStatus = 1
    } else {
      newStatus = 0
    }

    const updatedStatus = {
      'referral_assignee_tag_id': referralAssigneTagID,
      'status': newStatus,
    };

    AxiosInter.post( `${API_ENDPOINT}/admin-api/update-referral-assignee-tag-status/`, updatedStatus,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
        
        setReferralAssigneeStatusModal(!ReferralAssigneeStatusModal);
        setReloadForReferralAssigneeTag(reloadForReferralAssigneeTag+1)
        toastr.success('Status Updated', 'Referral Assignee Tag Status updated successfully');
        
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={ReferralAssigneeStatusModal} >
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Status</ModalHeader>
        <ModalBody>
            <div>
              Are you sure you want to change the Referral Assignee Tag Status to {referralAssigneTagStatus === 0 ? 'active ?':'inactive ?' } 
            </div>
        </ModalBody>
        <ModalFooter  className="custom-modal-footer">
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReferralAssigneStatusChangeModal;
