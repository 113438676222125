import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import './assets/style.css'
import store from "./redux/store/index";
import Rout from "./routes/Routes";
import ClearCache from "react-clear-cache";
import { GlobalStateProvider } from './contextAPI/Context';

class App extends React.Component {
  componentDidMount() {
    !document.querySelector(".splash") ||
      document.querySelector(".splash").classList.remove("active");
  }

  render() {
    var reloaded = false;
    const toggle = () => {
      reloaded = true;
    }

    return (
      <GlobalStateProvider>
        <Provider store={store}>
          <ClearCache>
            {({ isLatestVersion, emptyCacheStorage }) => (
              <>
                {
                !isLatestVersion && !reloaded && emptyCacheStorage(), toggle() // eslint-disable-line
                } 
              </>
            )}
          </ClearCache>
          <Rout/>
          <ReduxToastr
            timeOut={5000}
            newestOnTop={true}
            position="top-right"
            transitionIn="bounceIn"
            transitionOut="bounceOut"
          />
        </Provider>
      </GlobalStateProvider>
    );
  }
}

export default App;
