import React, { useState, useEffect, useContext } from "react";
import {
  TabContent,
  TabPane,
  Spinner,
  Label,
  Nav,
  Container,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import SearchBarComponentICDCode from "./SearchBarComponentICDCode";
import RemotepaginationICDCode from "./RemotePaginationICDCode";
import ICDCodeDetailsEditModal from "../../newComponents/utilities/ICDCodeDetailsEditModal";
import AddNewIcdModal from "../../newComponents/utilities/AddNewIcdModal";
import AddNewICDCategory from "../../newComponents/utilities/AddNewICDCategory";
import AxiosInter from './../../AxiosInter.js';
import classnames from "classnames";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { GlobalState } from "../../contextAPI/Context";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import _ from "lodash";
import MomentConfig from "../../common/MomentConfig";
import moment from "moment";
import Tooltip from '@mui/material/Tooltip';
import Cookie from 'js-cookie';



const ICDComponent = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [parentCategory, setParentCategory] =useState();


  const [fileExportName, setFileExportName] = useState("ICD Codes");

  const { icdCodeList, getICDCodesList } = useContext(GlobalState);

  const [activeTab, setActiveTab] = useState("1");
  const [icdCategoryDetails, setIcdCategoryDetails] = useState("");
  const [icdCategoryList, setIcdCategoryList] = useState("");

  const [icdCode, setIcdCode] = useState("");
  const [icdId, setIcdId] = useState("");
  const [icdDescription, setIcdDescription] = useState("");
  const [icdCategory, setIcdCategory] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [reloadICDTableData, setReloadICDTableData] = useState(0);
  const [reloadICDCategoryTableData, setReloadICDCategoryTableData] =
    useState(0);
  const [categoryOptions, setCategoryOptions] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryICD, setSelectedCategoryICD] = useState("");

  const [selectedIcdCode, setSelectedICDCode] = useState();

  const [offset, setOffset] = useState(0); // eslint-disable-line
  const [icdCodesOffset, setICDCodesOffset] = useState(0);
  const [page, setPage] = useState(1);

  const [data, setData] = useState([]);
  const [totalSize, setTotalSize] = useState();
  const [sizePerPage, setSizePerPage] = useState(50);

  const [icdCategoryDescription, setIcdCategoryDescription] = useState("");
  const [icdCategoryPid, setIcdCategoryPid] = useState("");
  const [icdCategoryId, setIcdCategoryId] = useState("");

  const [categoryOffset, setCategoryOffset] = useState(0);
  const [categoryPage, setCategoryPage] = useState(1);
  const [categoryTotalSize, setCategoryTotalSize] = useState(); // eslint-disable-line
  const [icdCategorySizePerPage, setIcdCategorySizePerPage] = useState(50);

  const [addState, setAddState] = useState(false);
  const [editState, setEditState] = useState(false);

  const [isData, setIsData] = useState(false); // eslint-disable-line
  const [search, setSearch] = useState(false);
  const [icdCategorySearch, setICDCategorySearch] = useState(false);

  const [icdConditions, setIcdConditions] = useState("");
  const [drgDetails, setDrgDetails] = useState("");

  const [searchKeywordICDCode, setSearchKeywordICDCode] = useState("");
  const [searchKeywordICDCategory, setSearchKeywordICDCategory] = useState("");
  const [isGlobalSearchICDCodes, setIsGlobalSearchICDCodes] = useState(false); // eslint-disable-line

  const [isGlobalSearch, setIsGlobalSearch] = useState(false);

  // const [icDCodeSearchRecordCount,setIcDCodeSearchRecordCount] = useState("");
  const [icdCategorySearchRecordCount, setIcdCategorySearchRecordCount] =
    useState(0);

  const [icdCodeSpinner, setIcdCodeSpinner] = useState(false);
  const [icdCategorySpinner, setIcdCategorySpinner] = useState(false);
  const [icdCondition, setIcdCondition] = useState("");
  const [chronicCondition, setChronicCondition] = useState("");
  const [icdDrgCode, setIcdDrgCode] = useState("");

  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [order, setOrder] = useState("desc");
  const [icdDrg,setIcdDrg] = useState("")

  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;
  // const [sortColumn,setSortColumn] =  useState("");
  // const [sortOrder,setSortOrder] =  useState("");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleTableChange = (type, newState) => {
    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
    setICDCodesOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("icdCode");
      setSortOrder("asc");
    } else {
      if (newState.sortOrder==order){ //eslint-disable-line
        setOrder("asc")
          }
      else{
        setOrder("desc") 
      }

      setSortColumn(newState.sortField);
      setSortOrder(order);
      setReloadICDTableData(reloadICDTableData + 1);
    }
  };

  const handleCategoryTableChange = (type, newState) => {
    setCategoryPage(newState.page);
    setIcdCategorySizePerPage(newState.sizePerPage);
    setCategoryOffset((newState.page - 1) * newState.sizePerPage);
    const currentIndex = 0;

    if (newState.sortField !== "" && newState.sortOrder !== "") {
      let result;
      if (newState.sortOrder === "asc") {
        result = icdCategoryDetails.sort((a, b) => {
          if (a[newState.sortField] > b[newState.sortField]) {
            return 1;
          } else if (b[newState.sortField] > a[newState.sortField]) {
            return -1;
          }
          return 0;
        });
      } else {
        result = icdCategoryDetails.sort((a, b) => {
          if (a[newState.sortField] > b[newState.sortField]) {
            return -1;
          } else if (b[newState.sortField] > a[newState.sortField]) {
            return 1;
          }
          return 0;
        });
      }

      setIcdCategoryDetails(
        result.slice(currentIndex, currentIndex + sizePerPage)
      );
    }
  };
  const minLength = 4;
  const customNoOptionsMessage = ({ inputValue }) => {
    if (inputValue.length < minLength) {
      return "Please enter at least 2 letters to search";
    } else {
      return "No option";
    }
  };
  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setIcdDescription(row.description);
            setIcdCode(row.icdCode);
            setIcdCategory(row.category);
            setEditModal(true);
            setIcdId(row.id);
            setIcdCondition(row.icdCondition);
            setChronicCondition(row.chronic);
            setIcdDrgCode(row.icdDrgCode);
            let data = row.drgCodeExport
            if(data != null){

              const cleanedData = data.replace(/\(|\)/g, "");
              setIcdDrg({
                "value":row.id,
                "label":cleanedData
              })
              console.log(cleanedData)
            }
          }}
          color="primary"
          title="Edit ICD Details"
        >
          <FontAwesomeIcon icon={faEdit} fixedWidth size={"sm"} />
        </Button>
      </>
    );
  };

  const categoryActionsFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setCategoryModal(true);
            setEditState(true);
            setIcdCategoryDescription(row.category);
            setIcdCategoryPid(row.parentCategory);
            setIcdCategoryId(row.icdcatId);
          }}
          color="primary"
          title="Edit ICD Category"
        >
          <FontAwesomeIcon icon={faEdit} fixedWidth />
        </Button>
      </>
    );
  };

  const parentCategoryFormatter = (cell, row) => {
    return cell && parentCategory ? parentCategory.find(x=> x.value === cell)?.label +" (" + cell+")": "N/A";
  };

  const validation = (cell, row) => {
    return cell ? cell : "N/A";
  };

  const chronicValidation = (cell, row) => {
    if (row.chronic === 1) {
      return (
        <>
          <h6 style={{ color: "red" }}>Chronic</h6>
        </>
      );
    } else if (row.chronic === 0) {
      return (
        <>
          <h6 style={{ color: "green" }}>Not Chronic</h6>
        </>
      );
    } else if (row.chronic === null) {
      return <>N/A</>;
    }
  };

  const columns = [
    {
      dataField: "category",
      text: "Category",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "icdCode",
      text: "Code",
      sort: true,

      formatter: validation
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      formatter: validation
    },
    {
      dataField: "icdCondition",
      text: "Condition",
      // sort: true,
      formatter: validation,
    },
    {
      dataField: "icdDrgCode",
      text: "DRG Code",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "drgType",
      text: "DRG Type",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "drgDescription",
      text: "DRG Description",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "chronic",
      text: "Chronic",
      // sort: true,
      formatter: chronicValidation,
    },
    {
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];

  const categoryColumns = [
    {
      dataField: "category",
      text: "Category",
      sort: true,
    },
    {
      dataField: "parentCategory",
      text: "Parent Category",
      sort: true,
      formatter: parentCategoryFormatter,
    },
    {
      text: "Actions",
      sort: false,
      formatter: categoryActionsFormatter,
    },
  ];

  const fetchICDCodeDetails = () => {
    // setIcdCodeSpinner(true);
    var temp = ''
    if (sortColumn === 'description') {
      temp = 'drgDescription'

    }
    else if (sortColumn === 'condition') {
      temp = 'm_icd_category.category'

    }
    else {
      temp = sortColumn
    }
    const params = {
      icd_code_id: selectedIcdCode ? selectedIcdCode.value : "",
      icd_category_id: selectedCategoryICD ? selectedCategoryICD.value : "",
      offset: icdCodesOffset,
      is_pagination: 1,
      record_limit: sizePerPage,
      search_keyword: searchKeywordICDCode ? searchKeywordICDCode : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: temp ? temp : "",
    };

    //    setSpinner (true);

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-icd-code-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setIcdCodeSpinner(false);
          setTotalSize(response.data.record_count);
          setData(response.data.data);
          localStorage.setItem("icdCodesOffset", icdCodesOffset);
        } else {
          setIcdCodeSpinner(false);
          setData("");
          setIsDataEmpty(true);
          setTotalSize(0);
        }
      })
      .catch((error) => {
        toastr.error("Something went wrong");
      });
  };
  const exportIcdCodeDetails = () => {
    const params = {
      file_name: fileName ? fileName : "",
      icd_code_id: selectedIcdCode ? selectedIcdCode.value : "",
      icd_category_id: selectedCategoryICD ? selectedCategoryICD.value : "",
      offset: icdCodesOffset ? icdCodesOffset : "0",
      is_pagination: "1",
      record_limit: "",
      search_keyword: searchKeywordICDCode ? searchKeywordICDCode : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/export-icd-code-table-data/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const fetchICDConditions = () => {
    AxiosInter
          .get(`${API_ENDPOINT}/admin-api/get-icd-conditions/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setIcdConditions(response.data.data);
        }
      })
      // .catch((error) => {
      //   toastr.error("Failed", "ICD Conditions fetch failed");
      // });
  };


  const fetchDrgDetails = () => {
    const params = {
      offset: "",
      is_pagination: "",
      record_limit: "",
      mdc_code: "",
      drg_type: "",
      search_keyword: "",
      sortOrder: "",
      sortColumn: "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-drg-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setDrgDetails(response.data.data);
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Drg details fetch failed");
      });
  };

  const fetchICDCategoryList = () => {
    // console.log(selectedSubscription)
    AxiosInter
          .get(`${API_ENDPOINT}/admin-api/get-icd-category-list/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setCategoryTotalSize(response.data.record_count);
          setIcdCategoryList(response.data.data);
        }
      })
      .catch((error) => {});
  };

  const fetchICDCategoryDetails = () => {
    // setIcdCategorySpinner(true);
    const params = {
      icd_category_id: selectedCategory ? selectedCategory.value : "",
      offset: categoryOffset,
      is_pagination: 1,
      record_limit: icdCategorySizePerPage,
      search_keyword: searchKeywordICDCategory ? searchKeywordICDCategory : "",
    };
    // console.log(selectedSubscription)
    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-icd-category-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setIcdCategorySpinner(false);
          setIcdCategoryDetails(response.data.data);
          setIcdCategorySearchRecordCount(response.data.record_count);
          localStorage.setItem("categoryOffset", categoryOffset);
        } else {
          setIcdCategoryDetails("");
          setIcdCategorySpinner(false);
          setIsDataEmpty(true);
          setIcdCategorySearchRecordCount(0);
        }
      })
      // .catch((error) => {
      //   setIcdCategorySpinner(false);
      //   toastr.error("Error", "Something went wrong!");
      // });
  };
  const exportIcdCategoriesDetails = () => {
    const params = {
      file_name: fileName ? fileName : "",
      icd_category_id: selectedCategory ? selectedCategory.value : "",
      offset: categoryOffset ? categoryOffset : "0",
      is_pagination: "1",
      record_limit: "",
      search_keyword: searchKeywordICDCategory ? searchKeywordICDCategory : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/export-icd-categories-table-data/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const setICDCategoryDropdown = (data) => {
    var array = data;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.description,
      };
    });
    setCategoryOptions(options);
  };

  const getAsyncOptions = (inputValue) => {
    if (icdCodeList && inputValue.length >= 2) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(icdCodeList, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    } else {
      return null; // or you can return a rejected promise
    }
  };

  useEffect(() => {
  
    loadICDCategory();
   
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadICDCategory = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-icdcat-pid/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setParentCategory(response.data.data)
        }
      })
      .catch((error) => {});
  };

  

  const clearICDPageValues = () => {
    setPage(1);
    setSearchKeywordICDCode("");
    setSelectedCategoryICD("");
    setSelectedICDCode("");
    setReloadICDTableData(reloadICDTableData + 1);
  };

  const clearICDCategoryPageValues = () => {
    setCategoryPage(1);
    setSearchKeywordICDCategory("");
    setSelectedCategory("");
    setReloadICDCategoryTableData(reloadICDCategoryTableData + 1);
  };

  const searchButtonValidationICDCodes = () => {
    if (selectedIcdCode || selectedCategoryICD) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  const searchButtonValidationICDCategory = () => {
    if (selectedCategory) {
      setICDCategorySearch(true);
    } else {
      setICDCategorySearch(false);
    }
  };

  const clearSearchValues = () => {
    setSearchKeywordICDCode("");
    setReloadICDTableData(reloadICDTableData + 1);
  };
  const clearICDCategorySearchValues = () => {
    setSearchKeywordICDCategory("");
    setReloadICDCategoryTableData(reloadICDCategoryTableData + 1);
  };

  useEffect(() => {
    if (icdCategoryList) {
      setICDCategoryDropdown(icdCategoryList);
    }
  }, [icdCategoryList]);

  useEffect(() => {
    searchButtonValidationICDCodes();
  }, [selectedIcdCode, selectedCategoryICD]); // eslint-disable-line

  useEffect(() => {
    searchButtonValidationICDCategory();
  }, [selectedCategory]); // eslint-disable-line

  useEffect(() => {
    fetchICDConditions();
    fetchDrgDetails();
    fetchICDCategoryList();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (activeTab === "1") {
      // fetchICDCodeDetails();
    } else if (activeTab === "2") {
      fetchICDCategoryDetails();
      fetchICDCategoryList();
    }
  }, [activeTab]); // eslint-disable-line

  useEffect(() => {
    if (reloadICDTableData) {
      fetchICDCodeDetails();
    }
  }, [reloadICDTableData]); // eslint-disable-line

  useEffect(() => {
    if (icdCodeList.length <= 0) {
      getICDCodesList();
    }
  }, [icdCodeList]); // eslint-disable-line

  useEffect(() => {
    if (reloadICDTableData>0) {
      getICDCodesList();
    }
  }, [reloadICDTableData]);  // eslint-disable-line

  useEffect(() => {
    if (activeTab === "2" && reloadICDCategoryTableData) {
      fetchICDCategoryDetails();
      fetchICDCategoryList();
      // setReloadICDCategoryTableData(0);
    }
  }, [reloadICDCategoryTableData]); // eslint-disable-line

  useEffect(() => {
    if (activeTab === "1") {
      var previousOffset = localStorage.getItem("icdCodesOffset");
      if (icdCodesOffset !== previousOffset || sizePerPage) {
        setReloadICDTableData(reloadICDTableData + 1);
        // fetchICDCodeDetails();
      }
    } else if (activeTab === "2") {
      var previousOffsetICDCategory = localStorage.getItem("categoryOffset");
      if (
        categoryOffset !== previousOffsetICDCategory ||
        icdCategorySizePerPage
      ) {
        setReloadICDCategoryTableData(reloadICDCategoryTableData + 1);
      }
    }
  }, [icdCodesOffset, sizePerPage, categoryOffset, icdCategorySizePerPage]); // eslint-disable-line

  useEffect(() => {
    if (isGlobalSearch && activeTab === "1") {
      setICDCodesOffset(0);
      setSizePerPage(50);
      setTotalSize(0);
      // setPage
      // setTotalSize(50);
      // setReloadICDTableData(true);
      setReloadICDTableData(reloadICDTableData + 1);
      setIsGlobalSearch(false);
    } else if (isGlobalSearch && activeTab === "2") {
      setCategoryOffset(0);
      setIcdCategorySizePerPage(50);
      setReloadICDCategoryTableData(reloadICDCategoryTableData + 1);
      // setPage
      setIcdCategorySearchRecordCount(0);

      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  useEffect(() => {
    if (searchKeywordICDCode.length >=4) {
      setIsGlobalSearch(true);
    }
  }, [searchKeywordICDCode]); // eslint-disable-line

  useEffect(() => {
    if (searchKeywordICDCode.length === 0) {
      setReloadICDTableData(reloadICDTableData + 1);
    }
  }, [searchKeywordICDCode]); // eslint-disable-line

  useEffect(() => {
    if (searchKeywordICDCategory.length >=4) {
      setIsGlobalSearch(true);
    }
  }, [searchKeywordICDCategory]); // eslint-disable-line

  useEffect(() => {
    if (searchKeywordICDCategory.length === 0) {
      setReloadICDCategoryTableData(reloadICDCategoryTableData + 1);
    }
  }, [searchKeywordICDCategory]); // eslint-disable-line

  useEffect(() => {   
    window.scrollTo(0, 0);   
     }, []);

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            ICD
            {activeTab === "1" ? (
              <Button
                className="Add-btn pull-right"
                onClick={() => {
                  setAddModal(true);
                  // setGroupAddModal(true)
                }}
                // disabled = {m_zipList?false:true}
              >
                Add new ICD
              </Button>
            ) : (
              <Button
                className="Add-btn pull-right"
                onClick={() => {
                  setCategoryModal(true);
                  setAddState(true);
                  // setGroupAddModal(true)
                }}
                // disabled = {m_zipList?false:true}
              >
                Add new Category
              </Button>
            )}
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>ICD</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>

      <Card className="adv-search-background-card">
        <CardHeader></CardHeader>
        {/* <CardBody className="patient-list"> */}

        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
                setFileExportName("ICD Codes");
                setReloadICDTableData(reloadICDTableData + 1);
              }}
              style={{ cursor: "pointer" }}
            >
              ICD Codes
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
                setFileExportName("ICD Categories");
              }}
              style={{ cursor: "pointer" }}
            >
              ICD Categories
            </NavLink>
          </NavItem>
        </Nav>
        <br />

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Container fluid>
              <Row>
                <Col
                  md={{
                    size: 2,
                  }}
                >
                  <Label>ICD Code</Label>
                  <AsyncSelect
                    cacheOptions
                    isClearable
                    isSearchable
                    value={selectedIcdCode}
                    placeholder={"Select ICD Code"}
                    noOptionsMessage={customNoOptionsMessage}
                    onChange={(event) => {
                      setSelectedICDCode(event);
                    }}
                    loadOptions={(inputValue) => getAsyncOptions(inputValue)}
                  />
                </Col>
                <Col
                  md={{
                    size: 3,
                  }}
                >
                  <Label>ICD Category</Label>
                  <Tooltip title={selectedCategoryICD ? selectedCategoryICD.label : ''} placement="top" arrow>
                    <div>
                  <Select
                    name="icd_category"
                    options={categoryOptions}
                    value={selectedCategoryICD}
                    placeholder="Choose ICD Category"
                    isSearchable
                    isClearable
                    onChange={(e) => {
                      setSelectedCategoryICD(e);
                    }}
                  />
                  </div>
                  </Tooltip>
               
                </Col>
                <Col
                  md={{
                    size: 4,
                  }}
                >
                  <Button
                    className="icd-go-buttons"
                    onClick={() => {
                      setTotalSize(0);
                      // setData("")

                      icdCodesOffset === 0
                        ? setReloadICDTableData(reloadICDTableData + 1)
                        : setICDCodesOffset(0);
                      setPage(1);
                    }}
                    disabled={!search ? true : false}
                    title={!search ? "Choose at least one item to search " : ""}
                  >
                    Go
                  </Button>
                  <Button
                    className="icd-reset-buttons"
                    onClick={() => {
                      clearICDPageValues();
                    }}
                    disabled={!search ? true : false}
                    title={!search ? "Nothing To Clear" : ""}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Container>
            <br />
            {/* <Row>
                            <Col>
                            <Button className="Add-btn pull-right"
                            onClick={() => {
                                setAddModal(true)
                            // setGroupAddModal(true)
                            }}
                            // disabled = {m_zipList?false:true}
                            >
                              Add new ICD
                          </Button>
                          </Col>

                        
                        </Row><br/> */}

            <div className="groups-search">
              <SearchBarComponentICDCode
                searchKeyword={searchKeywordICDCode}
                setSearchKeyword={setSearchKeywordICDCode}
                totalSize={totalSize}
                clearValues={clearSearchValues}
                exportTableData={activeTab === "1"
                ? exportIcdCodeDetails
                : exportIcdCategoriesDetails}
                data={data ? data : ""}

              />
            </div>
            {!icdCodeSpinner ? (
              <>
                <RemotepaginationICDCode
                  data={data ? data : ""}
                  columns={columns}
                  page={page}
                  sizePerPage={sizePerPage}
                  FileExportName={fileExportName}
                  exportTableData={
                    activeTab === "1"
                      ? exportIcdCodeDetails
                      : exportIcdCategoriesDetails
                  }
                  totalSize={totalSize ? totalSize : ""}
                  isGlobalSearch={isGlobalSearch}
                  setIsGlobalSearch={setIsGlobalSearch}
                  searchKeyword={searchKeywordICDCode}
                  setSearchKeyword={setSearchKeywordICDCode}
                  reloadVariable={reloadICDTableData}
                  isDataEmpty={isDataEmpty}
                  setReloadDetails={setReloadICDTableData}
                  onTableChange={(type, newState) => {
                    handleTableChange(type, newState);
                  }}
                />
                {/* </Col>
                             
                            </Row> */}
              </>
            ) : (
              <Spinner />
            )}
          </TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="2">
            <Container fluid>
              <Row>
                <Col sm="3" md="3">
                  <Label>ICD Category</Label>
                  <Tooltip title={selectedCategory ? selectedCategory.label : ''} placement="top" arrow>
                  <div>
                  <Select
                    name="icd_category"
                    options={categoryOptions}
                    value={selectedCategory}
                    placeholder="Choose ICD Category"
                    isSearchable
                    isClearable
                    onChange={(e) => {
                      setSelectedCategory(e);
                    }}
                  />
                   </div>
                  </Tooltip>
                </Col>
                <Col
                  sm={{
                    size: 4,
                  }}
                >
                  <Button
                    className="icd-go-buttons"
                    onClick={() => {
                      setTotalSize(0);
                      // setData("")

                      categoryOffset === 0
                        ? setReloadICDCategoryTableData(
                            reloadICDCategoryTableData + 1
                          )
                        : setCategoryOffset(0);
                      setCategoryPage(1);
                    }}
                    disabled={!icdCategorySearch ? true : false}
                    title={
                      !icdCategorySearch
                        ? "Choose at least one item to search "
                        : ""
                    }
                  >
                    Go
                  </Button>
                  <Button
                    className="icd-reset-buttons "
                    onClick={() => {
                      clearICDCategoryPageValues();
                    }}
                    disabled={!icdCategorySearch ? true : false}
                    title={!icdCategorySearch ? "Nothing To Clear" : ""}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Container>
            <br />
            {/* <Row>
                            <Col 
                            md={{
                              size:6,
                              offset:6
                             
                              
                            }}>
                            <Button className="Add-btn pull-right"
                            onClick={() => {
                              setCategoryModal(true)
                              setAddState(true)
                            // setGroupAddModal(true)
                            }}
                            // disabled = {m_zipList?false:true}
                        >
                         Add new Category
                         </Button>

                            </Col>
                        </Row><br/> */}
            <div className="groups-search">
              <SearchBarComponent
                searchKeyword={searchKeywordICDCategory}
                setSearchKeyword={setSearchKeywordICDCategory}
                totalSize={icdCategorySearchRecordCount}
                columns={categoryColumns}
                data={icdCategoryDetails ? icdCategoryDetails : ""}
                clearValues={clearICDCategorySearchValues}
              />
            </div>

            {!icdCategorySpinner ? (
              <>
                {/* {icdCategoryDetails ?
                            <Row>
                                <Col sm="12"> */}
                <RemotePagination
                  data={icdCategoryDetails}
                  columns={categoryColumns}
                  page={categoryPage}
                  isDataEmpty={isDataEmpty}
                  isGlobalSearch={isGlobalSearch}
                  setIsGlobalSearch={setIsGlobalSearch}
                  sizePerPage={icdCategorySizePerPage}
                  FileExportName={fileExportName}
                  exportTableData={
                    activeTab === "1"
                      ? exportIcdCodeDetails
                      : exportIcdCategoriesDetails
                  }
                  totalSize={
                    icdCategorySearchRecordCount
                      ? icdCategorySearchRecordCount
                      : ""
                  }
                  searchKeyword={searchKeywordICDCategory}
                  setSearchKeyword={setSearchKeywordICDCategory}
                  reloadVariable={reloadICDCategoryTableData}
                  setReloadDetails={setReloadICDCategoryTableData}
                  onTableChange={(type, newState) => {
                    handleCategoryTableChange(type, newState);
                  }}
                />
                {/* </Col>
                            </Row>:""} */}
              </>
            ) : (
              <Spinner />
            )}
          </TabPane>
        </TabContent>
        {/* 
                </CardBody> */}
      </Card>
      {activeTab === "1" ? (
        <>
          <ICDCodeDetailsEditModal
            modal={editModal}
            setModal={setEditModal}
            icdCode={icdCode}
            icdId={icdId}
            setIcdCode={setIcdCode}
            icdDescription={icdDescription}
            setIcdDescription={setIcdDescription}
            icdCategory={icdCategory}
            setIcdCategory={setIcdCategory}
            icdCategoryDetails={icdCategoryDetails}
            icdCategoryList={icdCategoryList}
            setIcdId={setIcdId}
            reloadTableData={reloadICDTableData}
            setReloadTableData={setReloadICDTableData}
            icdConditions={icdConditions}
            drgDetails={drgDetails}
            icdCondition={icdCondition}
            chronicCondition={chronicCondition}
            icdDrgCode={icdDrgCode}
            icdDrg ={icdDrg}
            setIcdDrg = {setIcdDrg}

          />
          <AddNewIcdModal
            addModal={addModal}
            setAddModal={setAddModal}
            icdCategoryDetails={icdCategoryDetails}
            icdCategoryList={icdCategoryList}
            setIcdCodeId={setIcdCode}
            setIcdCategory={setIcdCategory}
            reloadTableData={reloadICDTableData}
            setReloadTableData={setReloadICDTableData}
            icdConditions={icdConditions}
            drgDetails={drgDetails}
          />
        </>
      ) : (
        <>
          <AddNewICDCategory
            loadICDCategory={loadICDCategory}
            parentCategory={parentCategory}
            categoryModal={categoryModal}
            setCategoryModal={setCategoryModal}
            addState={addState}
            setAddState={setAddState}
            editState={editState}
            setEditState={setEditState}
            icdCategoryDescription={icdCategoryDescription}
            setIcdCategoryDescription={setIcdCategoryDescription}
            icdCategoryPid={icdCategoryPid}
            setIcdCategoryPid={setIcdCategoryPid}
            icdCategoryId={icdCategoryId}
            setIcdCategoryId={setIcdCategoryId}
            reloadICDCategoryTableData={reloadICDCategoryTableData}
            setReloadICDCategoryTableData={setReloadICDCategoryTableData}
          />
        </>
      )}
    </div>
  );
};

export default ICDComponent;
