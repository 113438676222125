/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Label,
  CardHeader,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import AxiosInter from './../../AxiosInter.js';
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import NPITableModal from "./NPITableModal";
import NpiUpdateModal from "./NpiUpdateModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import NpiInfoTableModal from "./../../newComponents/utilities/NpiInfoTableModal";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import Cookie from 'js-cookie';


const NPITableApiCall = () => {
  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [spinner, setSpinner] = useState(false);
  const [apiLoaded, setApiLoaded] = useState("idle");
  const [search, setSearch] = useState(false);
  const [NPIDocDataFromAPI, setNPIDocDataFromAPI] = useState("");
  const [numberOfRecords, setNumberOfRecords] = useState(0);
  const [npiNumber, setNpiNumber] = useState("");
  const [enumerationType, setEnumerationType] = useState("");
  const [taxonomyDescription, setTaxonomyDescription] = useState("");
  const [firstName, setfirstName] = useState("");
  const [useFirstNameAlias, setUseFirstNameAlias] = useState("");
  const [lastName, setlastName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [addressPurpose, setAddressPurpose] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [limit, setLimit] = useState("");
  const [skip, setSkip] = useState("");
  const [modal, setModal] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [basicInfo, setBasicInfo] = useState("");
  const [addressInfo1, setAddressInfo1] = useState("");
  const [addressInfo0, setAddressInfo0] = useState("");
  const [taxonomiesInfo, setTaxonomiesInfo] = useState("");
  const [otherInfo, setOtherInfo] = useState("");
  const [organizationInfo, setOrganizationInfo] = useState("");
  const [UpdateModal, setUpdateModal] = useState(false);
  const [npiInfoTableModal, setNPIInfoTableModal] = useState(false);
  const clearValues = () => {
    setEnumerationType("");
    setNpiNumber("");
    setfirstName("");
    setUseFirstNameAlias("");
    setlastName("");
    setOrganizationName("");
    setAddressPurpose("");
    setCity("");
    setState("");
    setPostalCode("");
    setCountryCode("");
    setLimit("");
    setSkip("");
    setApiLoaded("idle");
  };

  const NPIValidation = (cell) => {
    // eslint-disable-next-line eqeqeq
    if (cell == "NPI-1") {
      // eslint-disable-line
      return "1 - Individual";
    } else {
      return "2- Organization";
    }
  };

  const viewActions = (row) => {
    setBasicInfo(row.basic);
    setAddressInfo1(row.addresses[1]);
    setAddressInfo0(row.addresses[0]);
    setTaxonomiesInfo(row.taxonomies[0]);
    setOtherInfo(row.identifiers[0]);
    setOrganizationInfo(row.other_names[0]);
    setRowData(row);
    setModal(true);
  };

  const viewActionsForUpdate = (row) => {
    setBasicInfo(row.basic);
    setAddressInfo1(row.addresses[1]);
    setAddressInfo0(row.addresses[0]);
    setTaxonomiesInfo(row.taxonomies[0]);
    setOtherInfo(row.identifiers[0]);
    setOrganizationInfo(row.other_names[0]);
    setRowData(row);
    setUpdateModal(true);
  };

  const NPIFormatter = (cell, row) => {
    if (cell) {
      return (
        <span
          className="view-button"
          onClick={() => {
            viewActions(row);
          }}
        >
          {cell}
        </span>
      );
    } else {
      return "N/A";
    }
  };

  const addressFormatter = (cell) => {
    return `${cell[0].address_1}, ${cell[0].city}, ${cell[0].state}, ${cell[0].postal_code}`;
  };
  const telephoneFormatter = (cell) => {
    return `${cell[0].telephone_number}`;
  };
  const taxonomiesFormatter = (cell) => {
    return `${cell[0].desc}`;
  };
  const nameFormatter = (cell) => {
    if (cell.first_name) {
      return `${cell.first_name}${" "}${cell.last_name}`;
    } else {
      return `${cell.organization_name}`;
    }
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            viewActionsForUpdate(row);
          }}
          color="primary"
          title="Update Provider Info"
        >
          <FontAwesomeIcon
            icon={faArrowCircleUp}
            fixedWidth
            className="align-middle fa-lg"
            title="Update Provider Info"
          />
        </Button>
      </>
    );
  };

  const columns = [
    {
      dataField: "number",
      text: "NPI ",
      sort: true,
      formatter: NPIFormatter,
    },
    {
      dataField: "basic",
      text: "Name",

      /* sortFunc: (a, b, order, dataField, rowA, rowB) => {
      debugger
      if (order === 'asc') {
        return b.first_name - b.first_name;
      }
      return b.last_name - b.last_name; // desc
    }, */
      formatter: nameFormatter,
    },
    {
      dataField: "enumeration_type",
      text: "NPI Type	",
      formatter: NPIValidation,
    },
    {
      dataField: "addresses",
      text: "Primary Practice Address",
      formatter: addressFormatter,
    },
    {
      dataField: "addresses",
      text: "Phone",
      formatter: telephoneFormatter,
    },
    {
      dataField: "taxonomies",
      text: "Primary Taxonomy",
      formatter: taxonomiesFormatter,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    },
  ];

  const FetchDataOfNPPES = () => {
    setSpinner(true);
    setApiLoaded("loading");

    const params = {
      npiNumber: npiNumber ? npiNumber : "",
      enumeration_type: enumerationType ? enumerationType : "",
      city: city ? city : "",
      taxonomy_description: taxonomyDescription ? taxonomyDescription : "",
      first_name: firstName ? firstName : "",
      use_first_name_alias: useFirstNameAlias ? useFirstNameAlias : "",
      last_name: lastName ? lastName : "",
      organization_name: organizationName ? organizationName : "",
      address_purpose: addressPurpose ? addressPurpose : "",
      state: state ? state : "",
      postal_code: postalCode ? postalCode : "",
      country_code: countryCode ? countryCode : "",
      limit: limit ? limit : "",
      skip: skip ? skip : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/npi/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then(function (response) {
        if (response.data.results.length > 0) {
          setNPIDocDataFromAPI(response.data.results);
          setApiLoaded("loaded");
          setSpinner(false);
        } else {
          setApiLoaded("no data");
          setSpinner(false);
        }
      })
      .catch(function (error) {
        setSpinner(false);
        setApiLoaded("error");
      });
  };

  const enableOrDisableSearch = () => {
    if (
      npiNumber ||
      city ||
      enumerationType ||
      taxonomyDescription ||
      firstName ||
      useFirstNameAlias ||
      lastName ||
      organizationName ||
      addressPurpose ||
      city ||
      state ||
      postalCode ||
      countryCode ||
      skip ||
      limit
    ) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  const handleDataChange = ({ dataSize }) => {
    setNumberOfRecords(dataSize ? dataSize : 0);
  };

  useEffect(() => {
    setNumberOfRecords(NPIDocDataFromAPI.length);
  }, [NPIDocDataFromAPI]);

  useEffect(() => {   
    window.scrollTo(0, 0);   
     }, []);
     
  useEffect(() => {
    enableOrDisableSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    npiNumber,
    city,
    enumerationType,
    taxonomyDescription,
    firstName,
    useFirstNameAlias,
    lastName,
    organizationName,
    addressPurpose,
    city,
    state,
    postalCode,
    countryCode,
    skip,
    limit,
  ]); // eslint-disable-line

  return (
    <div>
      <Hux>
        <Container fluid>
          <Header>
            <HeaderTitle>NPPES</HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>NPPES</BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Container>
      </Hux>

      <Card className="adv-search-background-card">
        <CardHeader>
          <Row>
            <Col sm="2">
              <Label>NPI Number</Label>
              <Input
                type="number"
                name="NPI Number"
                placeholder="NPI Number"
                value={npiNumber}
                validate={{
                  maxLength: { value: 120 },
                }}
                onChange={(e) => {
                  setNpiNumber(e.target.value);
                }}
              />
            </Col>
            <Col sm="2">
              <Label>Enumeration Type</Label>
              <Input
                type="text"
                name="Enumeration"
                placeholder="Enumeration Type"
                value={enumerationType}
                validate={{
                  maxLength: { value: 254 },
                }}
                onChange={(e) => {
                  setEnumerationType(e.target.value);
                }}
              />
            </Col>
            <Col sm="2">
              <Label>Taxonomy Description</Label>
              <Input
                type="text"
                name="Taxonomy Description"
                placeholder="Taxonomy Description"
                value={taxonomyDescription}
                validate={{
                  maxLength: { value: 254 },
                }}
                onChange={(e) => {
                  setTaxonomyDescription(e.target.value);
                }}
              />
            </Col>
            <Col sm="2">
              <Label>First Name</Label>
              <Input
                name="first_name"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => {
                  setfirstName(e.target.value);
                }}
              />
              <br />
            </Col>
            <Col sm="2">
              <Label>First Name Alias</Label>
              <Input
                name="first_name_alias"
                placeholder="First Name Alias"
                value={useFirstNameAlias}
                onChange={(e) => {
                  setUseFirstNameAlias(e.target.value);
                }}
              />
            </Col>
            <Col sm="2">
              <Label>Last Name</Label>
              <Input
                name="last_name"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => {
                  setlastName(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <Label>Organization Name</Label>
              <Input
                name="Organization_name"
                placeholder="Organization Name"
                value={organizationName}
                onChange={(e) => {
                  setOrganizationName(e.target.value);
                }}
              />
            </Col>
            <Col sm="2">
              <Label>Address Purpose</Label>
              <Input
                name="address_purpose"
                placeholder="Address Purpose"
                value={addressPurpose}
                onChange={(e) => {
                  setAddressPurpose(e.target.value);
                }}
              />
            </Col>
            <Col sm="2">
              <Label>City </Label>
              <Input
                name="city"
                placeholder="City"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
            </Col>
            <Col sm="2">
              <Label>State </Label>
              <Input
                name="state"
                placeholder="State"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                }}
              />
            </Col>
            <Col sm="2">
              <Label>Postal Code </Label>
              <Input
                name="postal_code"
                placeholder="Postal Code"
                value={postalCode}
                onChange={(e) => {
                  setPostalCode(e.target.value);
                }}
              />
            </Col>
            <Col sm="2">
              <Label>Country Code</Label>
              <Input
                name="country_code"
                placeholder="Country Code"
                value={countryCode}
                onChange={(e) => {
                  setCountryCode(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm="2">
              <Label>Limit</Label>
              <Input
                name="limit"
                placeholder="Limit"
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                }}
              />
            </Col>
            <Col sm="2">
              <Label>Skip </Label>
              <Input
                name="skip	"
                placeholder="Skip	"
                value={skip}
                onChange={(e) => {
                  setSkip(e.target.value);
                }}
              />
            </Col>
            <Col sm="4">
              <Button
                className="patient-go-buttons"
                onClick={() => {
                  FetchDataOfNPPES();
                }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Search" : ""}
              >
                Go
              </Button>{" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => {
                  clearValues();
                }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>
              <Button
                className="info-button"
                onClick={() => {
                  setNPIInfoTableModal(!npiInfoTableModal);
                }}
                title="how to search"
              >
                {" "}
                <InfoRoundedIcon fontSize="medium" />
              </Button>
              <div className="warning-label">
                <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                <Label className="warning-text">
                  Search with at least 2 filters for better results.
                </Label>
              </div>
            </Col>
          </Row>
          <div className="separator" />
        </CardHeader>
        <CardBody className="patient-list">
          <Row>
            <Col sm="2">
              <div className="number-of-records">
                <p>
                  Number of records: <strong>{numberOfRecords}</strong>
                </p>
              </div>
            </Col>
          </Row>

          <div className="advanced-search-button">
            {spinner ? <Spinner /> : ""}
          </div>
          {apiLoaded === "loaded" ? (
            <>
              <br />
              <Row className="advanced-search-table-height disable-scrollbars">
                <Col>
                  <div className="patient-list-table disable-scrollbars">
                    {NPIDocDataFromAPI && !spinner ? (
                      <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={NPIDocDataFromAPI}
                        columns={columns}
                        bordered={false}
                        onDataSizeChange={handleDataChange}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          {apiLoaded === "no data" ? (
            <Card>
              {" "}
              <h4 className="no-data-found-card">No Data Found</h4>{" "}
            </Card>
          ) : (
            ""
          )}
          {apiLoaded === "error" ? (
            <>
              <br />
              <Row className="advanced-search-table-height patient-list-table">
                <Col>
                  <Card>
                    {" "}
                    <h4 className="no-data-found-card">
                      something is wrong, try again
                    </h4>{" "}
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
      <NPITableModal
        setModal={setModal}
        modal={modal}
        rowData={rowData}
        basicInfo={basicInfo}
        addressInfo1={addressInfo1}
        addressInfo0={addressInfo0}
        taxonomiesInfo={taxonomiesInfo}
        otherInfo={otherInfo}
        organizationInfo={organizationInfo}
      />
      <NpiUpdateModal
        setUpdateModal={setUpdateModal}
        UpdateModal={UpdateModal}
        rowData={rowData}
        basicInfo={basicInfo}
        addressInfo1={addressInfo1}
        addressInfo0={addressInfo0}
        taxonomiesInfo={taxonomiesInfo}
        otherInfo={otherInfo}
        organizationInfo={organizationInfo}
      />
      <NpiInfoTableModal
        setNPIInfoTableModal={setNPIInfoTableModal}
        npiInfoTableModal={npiInfoTableModal}
      />
    </div>
  );
};

export default NPITableApiCall;
