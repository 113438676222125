import React, { useState, useEffect, useCallback, useContext } from "react";//eslint-disable-line
import {
    Button,
    Modal,
    Spinner,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Label,
    FormGroup,
    Input,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import AsyncSelect from "react-select/async";
import Cookie from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

const NewFacilitySupportedInsurance = (props) => {
    const {
        userDataReload,
        setUserDataReload,
        newFacilityInsuranceModal,
        setNewFacilityInsuranceModal,
        newFacilityName,
        setNewFacilityName,
        newGroupName,
        newOrderType,
        setNewOrderType,
        newInsuranceName,
        setNewInsuranceName,
        newProviderMappingID,
        // loadOptionsForInsurance,
        // loadServiceTypeOptions,
        setshowRemoveButton,
        showRemoveButton,
        insuranceType
    } = props;
    // const {
    //     groupNamesForDropDown
    // } = useContext(GlobalState)

    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");
    const [spinner, setSpinner] = useState(false);
    const [updateButtonStatus, setUpdateButtonStatus] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [inputValue, setInputValue] = useState("");
    const [groupID, setGroupID] = useState("");
    const [selectedInsuranceType, setSelectedInsuranceType]=useState(null)
    const toggle = () => {
        setNewFacilityInsuranceModal(!newFacilityInsuranceModal);
        setNewOrderType("");
        setSelectedInsuranceType("")
        setNewInsuranceName([{}])
    };
    const confirmation = () => {
        if (!groupID) {
            toastr.error("Group Name Is Required", "Please select a group!");
        }
        if (!newFacilityName) {
            toastr.error("Facility Name Is Required", "Please select a facility!");
        }
        if (!newOrderType) {
            toastr.error("Order Type Is Required", "Please select order type!");
        }
        if (!newInsuranceName[0].insName) {
            toastr.error("Insurance Field Is Required", "Please select insurance!");
        }
        if (!newInsuranceName[0].code) {
            toastr.error("Insurance Code Is Required", "Please enter the insurance code!");
        }

        if (newInsuranceName[0].insName && newInsuranceName[0].code && groupID && newOrderType) {
            updateFacilityInsurance();
        }
    };
    const updateFacilityInsurance = () => {
        setSpinner(true);
        const result = newInsuranceName.map((item, index) => {      //eslint-disable-line
            if (item.code !== "" && item.insName !== "") {
            const providerMappingID = newProviderMappingID[index] ||-1 * Math.floor(Math.random() * 1000000);
            return {
                ProviderMappingID: providerMappingID,
                groupID: groupID,
                orderTypeId: newOrderType,
                insuranceCode: item.code,
                InsuranceName: item.insName,
                facilityID: newFacilityName,
                insuranceType:selectedInsuranceType?.value
            };
        }
    })
    .filter((item) => item !== undefined);
        console.table(
            "newProviderMappingID:",
            newProviderMappingID,
            "newGroupName:",
            newGroupName,
            "newFacilityName:",
            newFacilityName,
            "newOrderType:",
            newOrderType,
            "newInsuranceName:",
            newInsuranceName
        );
        console.log(result);

        AxiosInter.post(`${API_ENDPOINT}/admin-api/new-facility-insurance-mapping/`,
            {
                result: result || "",
            },  
            {
                headers: {
                    Authorization: API_TOKEN,
                },
            }
        )
            .then(function (response) {
                setSpinner(false);
                toggle();
                setUserDataReload(userDataReload + 1);
                toastr.success(
                    "Successfully Added",
                    "Insurance details successfully added"
                );
            })
            .catch(function (error) {
                setSpinner(false);
                toastr.error("Update Failed", "Please try again");
            });
    };

    const disableUpdateButton = () => {
        if (newInsuranceName) {
            setUpdateButtonStatus(false);
        } else {
            setUpdateButtonStatus(true);
        }
    };

    useEffect(() => {
        disableUpdateButton();
    }, [newInsuranceName]); //eslint-disable-line

    const handleAddItem = () => {
        // Check if the last item in the state array is not equal to { label: "", value: 0, code: "" }
        const lastItem = newInsuranceName[newInsuranceName.length - 1];
        if (
            lastItem &&
            lastItem.label === "" &&
            lastItem.value === 0 &&
            lastItem.code === "" &&
            lastItem.insName === ""
        ) {
            return; // If the last item is equal to { label: "", value: 0, code: "" }, exit the function
        }

        // Add a new item with empty values to the state array
        setNewInsuranceName([
            ...newInsuranceName,
            { label: "", value: 0, code: "", insName:"" },
        ]);
    };

    const handleDeleteItem = (index) => {

        // Remove the item at the specified index from the state array
        if (newInsuranceName.length <= 2) {
            setshowRemoveButton(false);
        }

        setNewInsuranceName([
            ...newInsuranceName.slice(0, index),
            ...newInsuranceName.slice(index + 1),
        ]);
    };

    const handleUpdateItem = useCallback((index, updatedItem) => {
        // Update the item at the specified index with the new values

        setNewInsuranceName([
            ...newInsuranceName.slice(0, index),
            updatedItem,
            ...newInsuranceName.slice(index + 1),
        ]);
    }, [newInsuranceName]);//eslint-disable-line

    const handleLabelChange = useCallback((index, label, value) => { //eslint-disable-line
        // Update the label and value of the item at the specified index

        handleUpdateItem(index, {
            ...newInsuranceName[index],
            label,
            value,
        });
        if (newInsuranceName.length >= 2) {
            setshowRemoveButton(true);
        }
    }, [newInsuranceName, handleUpdateItem]);//eslint-disable-line

    const handleCodeChange = useCallback((index, event) => {
        // Update the code of the item at the specified index

        handleUpdateItem(index, {
            ...newInsuranceName[index],
            code: event.target.value,
        });
        if (newInsuranceName.length >= 2) {
            setshowRemoveButton(true);
        }
    }, [newInsuranceName, handleUpdateItem]);//eslint-disable-line
    const handleInsNameChange = useCallback((index, event) => {
        // Update the code of the item at the specified index

        handleUpdateItem(index, {
            ...newInsuranceName[index],
            insName: event.target.value,
        });
        if (newInsuranceName.length >= 2) {
            setshowRemoveButton(true);
        }
    }, [newInsuranceName, handleUpdateItem]);//eslint-disable-line

    const loadGroupID = async (newGroupName) => {
        if (newGroupName) {
            const searchParams = new URLSearchParams({ name: newGroupName });
            const url = `${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`;
            try {
                const response = await AxiosInter.get(url, {
                    headers: {
                        Authorization: API_TOKEN,
                    },
                });
                const data = response.data;

                const groupNameOptions = data.data;
                if (groupNameOptions.length > 0) {
                    return groupNameOptions[0].value;
                }
            } catch (error) {
                // Handle and log the error appropriately
                console.error("An error occurred:", error);
            }
        }
        return null;
    };
    
    const loadFacilityOptionsForFacility = (inputValue) => {

        if (inputValue.length >= 4) {
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-by-fulfiller-name/${inputValue}/${groupID}`, {
                headers: {
                    Authorization: API_TOKEN,
                }
            })
                .then((response) => response.data.data)
                .catch((error) => {
                    throw error;
                });
        }
    }
    const loadOptionsForGroups = (inputValue) => {

        if (inputValue.length >= 4) {
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-referral-fulfiller-name/${inputValue}`, {
                headers: {
                    Authorization: API_TOKEN,
                }
            })
                .then((response) => response.data.data)
                .catch((error) => {
                    throw error;
                });
        }
    }
    const loadOptionsForOrderTypes = (inputValue) => {

        if (inputValue.length >= 1) {
            const searchParams = new URLSearchParams ({group_id:groupID,name: inputValue});
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-fulfiller-order-type-by-group/?${searchParams.toString()}`, {
                headers: {
                    Authorization: API_TOKEN,
                }
            })
                .then((response) => response.data.data)
                .catch((error) => {
                    throw error;
                });
        }
    }

    useEffect(() => {
        const fetchGroupID = async () => {
            const groupID = await loadGroupID(newGroupName);
            console.log("groupID: ", groupID);
            setGroupID(groupID);
        };
        fetchGroupID();
    }, [newGroupName]);//eslint-disable-line

    // const handleInputChange = value => {
    //     setInputValue(value);
    // };

    // const handleNameChange = value => {
    //     setNewFacilityName(value);
    // };
    // const handleOrderTypeChange = value => {
    //     setNewOrderType(value);
    // };

    return (
        <div>
            <Modal isOpen={newFacilityInsuranceModal} size="lg" scrollable={true}>
                <ModalHeader
                    tag="h4"
                    cssModule={{ "modal-title": "w-100 text-center" }}
                    toggle={toggle}
                >
                    Add Supported Insurance
                </ModalHeader>
                <ModalBody className="">
                    {spinner && <Spinner />}
                    <FormGroup>
                        <Row>
                            <Col sm={4}>
                                <Label>Group Name:<span className="required">*</span></Label>
                            </Col>
                            <Col sm={6}>
                            <AsyncSelect
                                    cacheOptions
                                    isClearable
                                    isSearchable
                                    getOptionLabel={e => e.label}
                                    getOptionValue={e => e.value}
                                    loadOptions={loadOptionsForGroups}
                                    onChange={(event) => { setGroupID(event.value) }}
                                    placeholder="Type group name for options"
                                />

                            </Col>
                        </Row><br></br>
                        <Row>
                            <Col sm={4}>
                                <Label>Facility Name:<span className="required">*</span></Label>
                            </Col>
                            <Col sm={6}>
                                <AsyncSelect
                                    cacheOptions
                                    isClearable
                                    isSearchable
                                    getOptionLabel={e => e.label}
                                    getOptionValue={e => e.value}
                                    loadOptions={loadFacilityOptionsForFacility}
                                    onChange={(event) => { setNewFacilityName(event.value) }}
                                    placeholder="Type facility name for options"
                                />
                            </Col>
                        </Row><br></br>
                        <Row>
                            <Col sm={4}>
                                <Label>Order Type:<span className="required">*</span></Label>
                            </Col>
                            <Col sm={6}>
                                <AsyncSelect
                                    cacheOptions
                                    isClearable
                                    isSearchable
                                    getOptionLabel={e => e.label}
                                    getOptionValue={e => e.value}
                                    loadOptions={loadOptionsForOrderTypes}
                                    onChange={(event) => { setNewOrderType(event.value) }}
                                    placeholder="Type order type for options"
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={4}>
                                <Label>Insurance Type:<span className="required">*</span></Label>
                            </Col>
                            <Col sm={6}>
                                <Select
                                    isClearable
                                    isSearchable
                                    options={insuranceType}
                                    value={selectedInsuranceType}
                                    onChange={(event) => { setSelectedInsuranceType(event) }}
                                />
                            </Col>
                        </Row>
                        <br />
                        {newInsuranceName.map((item, index) => (
                            <Row key={index}>
                                <Col sm={2}>
                                    <Label>
                                        Insurance Name <span className="required">*</span>
                                    </Label>
                                </Col>
                                <Col sm={4}>
                                <Input
                                        type="text"
                                        value={item.insName}
                                        onChange={(event) => handleInsNameChange(index, event)}
                                    />
                                    {/* <AsyncSelect
                                        cacheOptions
                                        isSearchable
                                        value={{ value: item.value, label: item.label }}
                                        onInputChange={handleInputChange}
                                        loadOptions={loadOptionsForInsurance}
                                        placeholder="Type insurance name for options"
                                        onChange={(selectedOption) => {
                                            if (selectedOption != null) {
                                                const { label, value } = selectedOption;
                                                handleLabelChange(index, label, value);
                                            }
                                            else {
                                                const value = null
                                                let label = null
                                                handleLabelChange(index, label, value);
                                            }
                                        }}
                                    /> */}
                                </Col>
                                <br></br>
                                <br></br>
                                <Col sm={2}>
                                    <Label>
                                        Insurance Code <span className="required">*</span>
                                    </Label>
                                </Col>
                                <Col sm={2}>
                                    <Input
                                        type="text"
                                        value={item.code}
                                        onChange={(event) => handleCodeChange(index, event)}
                                    />
                                </Col>
                                {showRemoveButton === true ? (
                                    <Col sm={1}>
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            color={"#E12C2C"}
                                            size={"1x"}
                                            style={{ cursor: "pointer" }}
                                            disabled={showRemoveButton}
                                            onClick={() => {
                                                handleDeleteItem(index);
                                            }}
                                            title="Remove"
                                        />
                                    </Col>
                                ) : (
                                    ""
                                )}
                                <Col sm={1}>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        size={"1x"}
                                        color={"#3f86fa"}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            handleAddItem();
                                            setshowRemoveButton(true);
                                        }}
                                        title="Add"
                                    />
                                </Col>
                            </Row>
                        ))}
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button custom-modal-footer">
                    <Button
                        onClick={() => {
                            confirmation();
                        }}
                        disabled={updateButtonStatus}
                    >
                        Add
                    </Button>{" "}
                    <Button outline className="no-button button-width" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default NewFacilitySupportedInsurance;