import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, Container, ModalBody, ModalFooter, Row, Col, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';


const AddNewModuleForAppUsers = (props) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    
    const [buttonBlankState, setButtonBlankState] = useState(true);
    const [roleID, setRoleID] = useState('1');
    const [selectedModuleId, setSelectedModuleId] = useState('1');
    const [permission, setPermission] = useState([])
    const [modules, setModules]=useState()

    const {reload, setReload, setAddModule, addModule, roleList, selectedRole, OptionsOfMPermission, auId, selectedGroupId,
            groupDD, singleGroupId
          } = props

    const toggle = () => {
        setAddModule(!addModule)
        setSelectedModuleId("")
        setPermission("")
    }
    const getRoleId = () => {
        if (selectedRole && roleList) {
            // eslint-disable-next-line array-callback-return
            roleList.map((item) => {
                if (item.designation === selectedRole) {
                    setRoleID(item.id)
                }
            })
        }

    }
    const addModuleForRole = () => {
        const params =
        {
            "groupId": groupDD.length > 1 ? selectedGroupId : singleGroupId,
            "appUserId": auId,
            "moduleId": selectedModuleId ? selectedModuleId.value : "",
            "permissionIdList": res ? res : ""
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/add-module-for-appuser/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        }).then(response => {

            if (response.status === 201) {
                setReload(reload + 1);
                toastr.success("Success", "Module Added Successfully");
                toggle();
            }
            else {
                toastr.error("Error", "Something went wrong!");
                toggle();
            }
        }).catch(error => {
            toastr.error("Error", "May be you are adding duplicate module");
        });

    }
    const listModules = () => {
        const params={
            "groupID":selectedGroupId?selectedGroupId:singleGroupId
        }
        AxiosInter.post(`${API_ENDPOINT}/admin-api/get-appuser-permission-module-list/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        }).then(response => {
            if(response?.status === 200){
                setModules(response?.data?.data.map(item => ({label: item.name, value: item.value})))
            }
        }).catch(error => {
            console.log('error', error)
        })
    }

    let res = []

    const confirmation = () => {
        if (!permission && !selectedModuleId) {
            toastr.error("Please select module and permission")
        }
        if (permission && selectedModuleId) {
            permission.map((item) => { // eslint-disable-line
                res.push(
                    item.value
                )
            })
            if (res) {
                addModuleForRole()
            }
        }

    };

    const disableAddButton = () => {
        if (selectedModuleId && roleID && permission) {
            setButtonBlankState(false)
        }
        else {
            setButtonBlankState(true)
        }
    }

    useEffect(() => { disableAddButton() }, [selectedModuleId, roleID, permission]) // eslint-disable-line
    useEffect(() => { getRoleId() }, [selectedRole]) // eslint-disable-line
    useEffect(() => { 
        if(addModule){
            listModules() 
        }
    }, [addModule]) // eslint-disable-line

    return (
        <div>
            <Modal isOpen={addModule} size="md">
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add User Permissions </ModalHeader>
                <ModalBody>
                    <Container fluid>
                        <FormGroup>
                            <Row>
                                <Col sm={12}>
                                    <Label for="module">Module <span className="required">*</span></Label>
                                    <Select
                                        name="module"
                                        options={modules}
                                        value={selectedModuleId}
                                        placeholder="Select Module"
                                        isClearable={true}
                                        onChange={(e) => { setSelectedModuleId(e) }}
                                    />
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col sm={12}>
                                    <Label for="Permissions">Permissions <span className="required">*</span></Label>
                                    <Select
                                        name="Permissions"
                                        options={OptionsOfMPermission}
                                        value={permission}
                                        isMulti
                                        placeholder="Select Permissions"
                                        isClearable={true}
                                        onChange={(e) => { setPermission(e) }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Container>
                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button
                        onClick={confirmation}
                        disabled={buttonBlankState}
                        title={buttonBlankState ? "Fill the required fields" : ""}
                    >Add
                    </Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default AddNewModuleForAppUsers;