import React, { useState, useEffect, useContext } from "react";
import { GlobalState } from "../../contextAPI/Context";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from "js-cookie";
import { toastr } from "react-redux-toastr";
import ToggleButton from "react-toggle-button";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const NewAdminUserModal = (props) => {
  const { setNewAdminUserModal, newAdminUserModal } = props;

  // CONTEXT VARIABLES
  const { reloadUserData, setReloadUserData, countryCodeList } =
    useContext(GlobalState);

  //const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const re = /[a-zA-Z.]+@equipohealth\.com/
  const re = /^[\w.+\-]+@equipohealth\.com$/; // eslint-disable-line

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [newAdminUserName, setNewAdminUserName] = useState("");
  const [newAdminUserType, setNewAdminUserType] = useState("");
  const [newAdminUserEmail, setNewAdminUserEmail] = useState("");
  const [newAdminUserPhone, setNewAdminUserPhone] = useState("");

  const [newAdminUserChangePwdOnLogin, setNewAdminUserChangePwdOnLogin] =
    useState(false);

  const [SpinnerEnable, setSpinnerEnable] = useState(false);

  const [addButtonStatus, setAddButtonStatus] = useState(true);

  const [newCountryCode, setNewCountryCode] = useState("");

  const [optForCountryCode, setOptForCountryCode] = useState("");

  const animatedComponents = makeAnimated();

  const UserTypeOptions = [
    { value: "1", label: "Super Admin" },
    { value: "0", label: "Admin" },
  ];

  // const toggle = () => {
  //     setNewAdminUserModal(!newAdminUserModal);
  //     setNewAdminUserName("")
  //     setNewAdminUserType("")
  //     setNewAdminUserEmail("")
  //     setNewAdminUserPhone("")
  //     setNewAdminUserChangePwdOnLogin(false)
  //     setAddButtonStatus(true);

  // }
  const toggle = () => {
    setNewAdminUserModal(!newAdminUserModal);
    setNewAdminUserName("");
    setNewAdminUserType("");
    setNewAdminUserEmail("");
    setNewAdminUserPhone("");
    setNewAdminUserChangePwdOnLogin(false);
    setAddButtonStatus(true);
    setNewCountryCode("");
  };
  const OverlayLoader = () => (
    <div className="item">
      <i className="loader --1"></i>
    </div>
  );

  const confirmation = () => {
    if (!newAdminUserName) {
      toastr.error(
        "Name Field Is Required",
        "Please enter the Admin User name!"
      );
    }
    if (!newAdminUserType) {
      toastr.error(
        "User Type Field Is Required",
        "Please enter the admin user type!"
      );
    }
    if (!newAdminUserEmail) {
      toastr.error(
        "Email Field Is Required",
        "Please enter the admin user email!"
      );
    }
    if (!newCountryCode) {
      toastr.error(
        "Country Code Field Is Required",
        "Please enter the admin user country code!"
      );
    }
    if (!newAdminUserPhone) {
      toastr.error(
        "Phone Number Field Is Required",
        "Please enter the admin user phone number!"
      );
    }

    /* if(!newAdminUserChangePwdOnLogin ) {
            toastr.error("Change Password On Login Field Is Required" , "Please enter the change pwd on login!")
        } */

    if (
      newAdminUserName &&
      newAdminUserType &&
      newAdminUserEmail &&
      newCountryCode &&
      newAdminUserPhone
    ) {
      CreateAdminUserApiCall();
    }
  };

  const createCountryCodeOption = () => {
    var array = countryCodeList;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.country_isd_code,
      };
    });
    setOptForCountryCode(options);
  };

  const CreateAdminUserApiCall = () => {
    if (re.test(newAdminUserEmail)) {
      setSpinnerEnable(true);
      const params = {
        is_superuser: newAdminUserType ? Number(newAdminUserType.value) : 1,
        email: newAdminUserEmail ? newAdminUserEmail : "",
        name: newAdminUserName ? newAdminUserName : "",
        countryCode: newCountryCode ? newCountryCode.label.toString() : "",
        phone: newAdminUserPhone ? Number(newAdminUserPhone) : 0,
        password: "",
        changePwdOnLogin: newAdminUserChangePwdOnLogin ? 1 : 0,
      };

      AxiosInter
        .post(`${API_ENDPOINT}/admin-api/create-admin-user/`, params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then(function (response) {
          if (response.status === 201) {
            setNewAdminUserModal(!newAdminUserModal);
            setReloadUserData(reloadUserData + 1);
            toastr.success(
              "Successfully Created",
              "Admin User successfully created"
            );
          }

          AxiosInter
            .post(
              `${API_ENDPOINT}/admin-api/admin-verify/`,
              {
                admin_email: newAdminUserEmail ? newAdminUserEmail : "",
              },
              {
                headers: {
                  Authorization: API_TOKEN,
                },
              }
            )
            .then(function (res) {
              if (res.status === 201) {
                toastr.success(
                  "Successful",
                  "Email Sent, please check inbox to verify the email"
                );
                toggle();
              } else {
                toastr.success("Failed", "Please try once again");
              }
            })
            .catch(function (error) {
              toastr.error("Failed", "Email can't be sent please check email");
            });
        })
        .catch(function (error) {
          toastr.error("Failed", "Maybe your adding duplicate entry");
        });

      setSpinnerEnable(false);
    } else {
      toastr.error("Failed", "Please Enter a valid email");
    }
  };

  const disableAddButton = () => {
    if (
      newAdminUserName &&
      newAdminUserType &&
      newAdminUserEmail &&
      newCountryCode &&
      newAdminUserPhone
    ) {
      setAddButtonStatus(false);
    } else {
      setAddButtonStatus(true);
    }
  };

  const functionForChangePWDOnLogin = () => {
    setNewAdminUserChangePwdOnLogin(!newAdminUserChangePwdOnLogin);
  };
  useEffect(() => {
    if (countryCodeList) createCountryCodeOption();
  }, [countryCodeList]); // eslint-disable-line

  useEffect(() => {
    disableAddButton();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    newAdminUserName,
    newAdminUserType,
    newAdminUserEmail,
    newAdminUserPhone,
    newCountryCode,
  ]); // eslint-disable-line

  return (
    <div>
      {SpinnerEnable ? <OverlayLoader className=".overlay-z-index" /> : null}
      <Modal isOpen={newAdminUserModal}  size="md">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Add New Admin User{" "}
        </ModalHeader>
        <ModalBody className="new-admin-user-modal disable-scrollbars">
          <FormGroup>
            <Row>
              <Col xs="6">
                <Label for="name">
                  User Name <span className="required">*</span>
                </Label>
                <Input
                  id="name"
                  placeholder="User name"
                  value={newAdminUserName}
                  onChange={(e) => {
                    if (e.target.value.length <= 250)
                      setNewAdminUserName(e.target.value);
                  }}
                  minLength="1"
                  maxLength="120"
                />
              </Col>
              <Col xs="6">
                <Label for="type">
                  User Type <span className="required">*</span>{" "}
                </Label>

                <Select
                  //className="pandadoc-select"
                  value={newAdminUserType}
                  options={UserTypeOptions}
                  onChange={(event) => setNewAdminUserType(event)}
                  placeholderText="Select"
                  components={animatedComponents}
                  isClearable={true}
                />
                <br></br>
              </Col>
            </Row>
            <Row>
              <Col xs="7">
                <Row>
                  <Col xs="6">
                    <Label for="code">
                      Country Code <span className="required">*</span>{" "}
                    </Label>

                    <Select
                      //className="pandadoc-select"
                      options={optForCountryCode}
                      onChange={(event) => setNewCountryCode(event)}
                      value={newCountryCode}
                      placeholder="Select"
                      isSearchable
                      isClearable
                      components={animatedComponents}
                    />
                  </Col>
                  <Col xs="6">
                    <Label for="number">
                      Phone No.<span className="required">*</span>{" "}
                    </Label>
                    <Input
                      id="number"
                      type="number"
                      value={newAdminUserPhone}
                      placeholder="Phone No"
                      onChange={(e) => {
                        if (e.target.value.length < 13)
                          setNewAdminUserPhone(e.target.value);
                      }}
                      maxLength="50"
                    />{" "}
                    <br></br>
                  </Col>
                </Row>
              </Col>
              <Col xs="5">
                <Label for="email">
                  User Email <span className="required">*</span>{" "}
                </Label>
                <Input
                  id="email"
                  value={newAdminUserEmail}
                  placeholder="Email"
                  onChange={(e) => {
                    if (e.target.value.length <= 250)
                      setNewAdminUserEmail(e.target.value);
                  }}
                  maxLength="50"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Label for="ChangePwdOnLogin">Change Password On Login </Label>
                <ToggleButton
                  inactiveLabel=""
                  activeLabel=""
                  onToggle={() => {
                    functionForChangePWDOnLogin();
                  }}
                  value={newAdminUserChangePwdOnLogin}
                />
                <br></br>
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button  custom-modal-footer">
          <Button
            className="yes-button button-width"
            onClick={confirmation}
            disabled={addButtonStatus}
            title={addButtonStatus ? "Fill the required fields" : ""}
          >
            Add
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewAdminUserModal;
