import React, {useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, CardBody, Card, Container, Row, Spinner  } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import { useMemo } from 'react';


const NpiUpdateModal = (props) => {

    const [spinner, setSpinner] = useState(true)
    const [isRecord, setIsRecord] = useState(false)
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    const { rowData, setUpdateModal, UpdateModal, addressInfo0, basicInfo, addressInfo1, taxonomiesInfo, otherInfo, organizationInfo } = props; // eslint-disable-line

    const toggle = () => setUpdateModal(!UpdateModal);

    const updateProviderInfo = () => {
        setSpinner(true);
        const params =
        {
            "search_by": rowData.number.toString(),
            "name": basicInfo.first_name + " " + basicInfo.middle_name + " " + basicInfo.last_name,
            "npi": rowData.number.toString(),
            "phone_home": addressInfo0.telephone_number ?  addressInfo0.telephone_number : "",
            "first_name": basicInfo.first_name ? basicInfo.first_name : "" ,
            "middle_name": basicInfo.middle_name ? basicInfo.middle_name : "" ,
            "last_name": basicInfo.last_name ? basicInfo.last_name : "" ,
            "address": addressInfo0.address_1 ?  addressInfo0.address_1 : "",
            "address2": addressInfo0.address_2 ?  addressInfo0.address_2 : "",
            "city": addressInfo0.city ?  addressInfo0.city : "",
            "state": addressInfo0.state ?  addressInfo0.state : "",
            "zipcode": addressInfo0.postal_code ?  addressInfo0.postal_code : "",
            "license_number": taxonomiesInfo.license ? taxonomiesInfo.license : "",
            "taxonomy_code": taxonomiesInfo.code ? taxonomiesInfo.code : ""
          }
        
          AxiosInter.post(`${API_ENDPOINT}/admin-api/update-provider-By-NPI-registry/`,  params,{
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then(function (response) {
            if (response.status === 200) {
            
            setSpinner(false);
            toggle();
            toastr.success('Update Successful', 'record updated successfully');
        } 
      
        })
        .catch(function (error) {
            setSpinner(false);
            toggle();
            toastr.error('Update Failed', 'record not found in DB');
        });
    
    };
    
    const searchProviderByNPI = () => {
        setSpinner(true);
        const params =
        {
            "npi": rowData.number.toString(),
        }
        
        AxiosInter.post(`${API_ENDPOINT}/admin-api/search-provider-by-NPI/`,  params,{
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then(function (response) {
            if (response.status === 200) {
            
            setSpinner(false);
            setIsRecord(true)
            
        }
      
        })
        .catch(function (error) {
            setSpinner(false);
            setIsRecord(false)
            
        });
    
    };

    useMemo(()=>{
        if(rowData.number){

            searchProviderByNPI()
        }
    },[rowData.number]) // eslint-disable-line

    
    return (
        <div>
            <Modal isOpen={UpdateModal}  size="lg" scrollable={true}>
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Update Provider Information for {rowData.number} </ModalHeader>
                <ModalBody>

                    <div className="NPI-info-card">
                        <Card>
                            {
                                spinner ? <>
                                <Container fluid className="d-flex">
                                    <Row className="justify-content-center align-self-center w-100 text-center">
                                    <Spinner color="primary" />
                                    </Row>
                                </Container>
                                </>: null
                            }
                            <CardBody>
                                {
                                    isRecord ? <strong>Are you sure you want to update the Provider Information?</strong> : <><strong>No Record Found for this {rowData.number} </strong></>
                                }
                            </CardBody>
                        </Card>

                    </div>

        
                </ModalBody>
                <ModalFooter className="advanced-search-button custom-modal-footer">
                    <Button className="yes-button button-width" disabled={isRecord ? false : true}  onClick={ ()=> { updateProviderInfo() } } > Update</Button>
                    <Button outline className="no-button button-width" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NpiUpdateModal;