import React, { useState } from "react";
import AuthServicesList from './AuthServicesList';
import { Container, Breadcrumb, BreadcrumbItem, Spinner, Button, Row, Col, Card, CardHeader } from "reactstrap";// eslint-disable-line
//import { Container, Breadcrumb, BreadcrumbItem, Button, Card,  } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Link } from "react-router-dom";
import AuthNewService from '../../newComponents/utilities/AuthNewService';

function AuthServices() {
    const [newServiceModal, setNewServiceModal] = useState(false)
    const [reload, setReload] = useState(false);
    //const [newServiceName, setNewServiceName] = useState('')
    return (

        <div>
            <Container fluid>
                <Header>
                    <HeaderTitle>
                        Services


                        <Button className="Add-btn pull-right"
                            onClick={() => {

                                setNewServiceModal(!newServiceModal)

                            }}
                        >
                            Add New Service
                        </Button>
                    </HeaderTitle>

                    <Breadcrumb>
                        <BreadcrumbItem>
                            <Link to="/dashboard">Dashboard</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Service</BreadcrumbItem>
                    </Breadcrumb>

                </Header>
            </Container>
            <Card>
                <CardHeader>


                    <div className="separator" />
                </CardHeader>
                <AuthServicesList />
            </Card>
            <AuthNewService
                newServiceModal={newServiceModal}
                setNewServiceModal={setNewServiceModal}
                // mdcOptions={mdcOptions}
                // drgTypeOptions={drgTypeOptions}
                reload={reload}
                setReload={setReload}
            />
        </div>

    )
}


export default AuthServices;