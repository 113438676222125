import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Breadcrumb, BreadcrumbItem, Spinner, Button } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import AppointmentTagDataTable from "./AppointmentTagDataTable";
import { GlobalState } from '../../contextAPI/Context'
import EditAppointmentTagModal from "../../newComponents/utilities/EditAppointmentTagModal"
import AppointmentTagStatusChangeModal from "../../newComponents/utilities/AppointmentTagStatusChangeModal"
import NewAppointmentTagModal from "../../newComponents/utilities/NewAppointmentTagModal"
import AppointmentTagDeleteModal from "../../newComponents/utilities/AppointmentTagDeleteModal"
import moment from "moment";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MomentConfig from "../../common/MomentConfig";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';


const AllAppointmentTag = () => {

  // CONTEXT VARIABLES 
  const {
    spinner,
    setSpinner
  } = useContext(GlobalState);

  const [data, setData] = useState([]);
  const [editAppTagModal, setEditAppTagModal] = useState(false);
  const [deleteAppTagModal, setDeleteAppTagModal] = useState(false);
  const [addAppTagModal, setAddAppTagModal] = useState(false);
  const [statusChangeAppTagModal, setStatusChangeAddAppTagModal] = useState(false);
  const [editGroupID, setEditGroupID] = useState(0);
  const [editTagName, setEditTagName] = useState("");
  const [editID, setEditID] = useState("");
  const [iD, setId] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [searchKeys, setSearchKeys] = useState("");
  const [reloadApptTag, setReloadApptTag] = useState(0);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");

  const fetchAppointmentTagData = async () => {
    setSpinner(true);
    try {
      const response = await AxiosInter.post(`${API_ENDPOINT}/admin-api/graphql/`, {
        query: `
        query {
          mGroupsTagList {
            id
            tag
            status
            createDate
            updateDate
            groupID {
              id
              name
            }
          }
        }
      `,
      });

      const data = response.data;
      setData(data.data.mGroupsTagList);
    } catch (error) {
      // Handle error
    }

    setSpinner(false);
  };


  const fetchAppointmentTagDataForSearch = async () => {
    setSpinner(true)
    const params =
    {
      "search_keyword": searchKeys ? searchKeys : "",
    }
    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-appointment-tag-list/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    }
    ).then(response => {
      setData(response.data.data)

    });
    setSpinner(false)
  }

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setId(row.id);
          setStatus(cell);
          setStatusChangeAddAppTagModal(true);
          setName(row)
        }}
      >
        {status}
      </span>
    );
  };


  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const statusFormatterCSV = (cell) => {
    return cell ? cell : "N/A"
  }

  const TimeFormatter = (cell) => {
    return cell ? `${moment(cell).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} (UTC)` : "--";
  };

  const editUserData = (id, tag, groupID) => {
    setEditGroupID(groupID);
    setEditTagName(tag);
    setEditID(id);

  }

  const actionFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setEditAppTagModal(true)
            editUserData(row.id, row.tag, row.groupID.id)
          }}
          color="primary"
          title="Edit Tag"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit Tag"
          />
        </Button> {` `}
        <Button
          className="delete-btn"
          outline
          onClick={() => {

            setDeleteAppTagModal(true)
            setId(row.id)
            setName(row.tag)
          }}
          color="primary"
          title="Delete Tag"
        >
          <FontAwesomeIcon
            icon={faTrash}
            fixedWidth
            className="align-middle"
            title="Delete Tag"
          />
        </Button>

      </>
    )
  }

  const dataFieldFormatter = () => {
    if (data.length > 0) {
      if (data[0]?.groupID?.id !== undefined) {
        return "groupID.name"
      } else if (data[0]?.groupID?.id === undefined) {
        return "name"
      }
      else {
        return "name"
      }

    }
  }
  const tableColumns = [
    {
      dataField: "tag",
      text: "Tag",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: dataFieldFormatter(),
      text: "Group Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,

    },

    {
      dataField: "createDate",
      text: "Created Date",
      formatter: TimeFormatter,
      csvFormatter: TimeFormatter,
    },

    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    }

  ];

  // DEFAULT API CALL
  useEffect(() => { 
    fetchAppointmentTagData(); 
  }, [reloadApptTag]); // eslint-disable-line

  useEffect(() => {
    if (searchKeys.length > 2) {
      fetchAppointmentTagDataForSearch();
    }
    if (searchKeys.length <= 0) {
      fetchAppointmentTagDataForSearch();
    }
  }, [searchKeys]); // eslint-disable-line

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Hux>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Appointment Tags
            <Button className="Add-btn pull-right"
              onClick={() => {
                setAddAppTagModal(true);
              }}
            >
              Add Appointment Tags
            </Button>
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Appointment tags</BreadcrumbItem>
          </Breadcrumb>

        </Header>
        {spinner ? <Spinner /> : ""}

        <AppointmentTagDataTable
          data={data}
          columns={tableColumns}
          setSearchKeys={setSearchKeys}
          searchKeys={searchKeys}
          reloadApptTag={reloadApptTag}
          setReloadApptTag={setReloadApptTag}

        />
      </Container>
      <EditAppointmentTagModal
        editAppTagModal={editAppTagModal}
        setEditAppTagModal={setEditAppTagModal}
        editGroupID={editGroupID}
        setEditGroupID={setEditGroupID}
        editTagName={editTagName}
        setEditTagName={setEditTagName}
        editID={editID}
        reloadApptTag={reloadApptTag}
        setReloadApptTag={setReloadApptTag}
      />
      <NewAppointmentTagModal
        addAppTagModal={addAppTagModal}
        setAddAppTagModal={setAddAppTagModal}
        reloadApptTag={reloadApptTag}
        setReloadApptTag={setReloadApptTag}
      />
      <AppointmentTagStatusChangeModal
        statusChangeAppTagModal={statusChangeAppTagModal}
        setStatusChangeAddAppTagModal={setStatusChangeAddAppTagModal}
        iD={iD}
        status={status}
        setStatus={setStatus}
        reloadApptTag={reloadApptTag}
        setReloadApptTag={setReloadApptTag}
        name={name}
      />
      <AppointmentTagDeleteModal
        deleteAppTagModal={deleteAppTagModal}
        setDeleteAppTagModal={setDeleteAppTagModal}
        iD={iD}
        reloadApptTag={reloadApptTag}
        setReloadApptTag={setReloadApptTag}
        name={name}
      />
    </Hux>

  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(AllAppointmentTag);
