import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Breadcrumb, BreadcrumbItem, Spinner, Button, Row, Col, Card, CardHeader, Label } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { GlobalState } from '../../contextAPI/Context'
import MoveToNewGroupModal from "../../newComponents/utilities/MoveToNewGroupModal"
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";// eslint-disable-line
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemotePagination from '../../newComponents/utilities/RemotePagination'
import SearchBarComponent from '../../newComponents/utilities/SearchBarComponent'
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import MomentConfig from '../../common/MomentConfig';
import moment from 'moment';
import AsyncSelect from 'react-select/async';


const ServiceForms = (props) => {
  // CONTEXT VARIABLES 
  const { spinner, setSpinner } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [reload, setReload] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [isPagination, setIsPagination] = useState(1); // eslint-disable-line
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("")
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [serviceFormsDetails, setServiceFormsDetails] = useState("")
  const [search, setSearch] = useState(false);
  const [serviceFormID, setServiceFormID] = useState("")
  const [editGroupModal, setEditGroupModal] = useState(false);
  const fileExportName = 'Service Forms'
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedFormTitle, setSelectedFormTitle] = useState("");
  const [editType, setEditType] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");


  const getServiceForms = () => {
    setSpinner(true);

    const params =
    {
      "group_name": selectedGroup ? selectedGroup.label : "",
      "form_title": selectedFormTitle ? selectedFormTitle.label : "",
      "is_pagination": isPagination ? isPagination : "",
      "record_limit": sizePerPage ? sizePerPage : "",
      "offset": offset,
      "search_keyword": searchKeyword ? searchKeyword : "",
      "sortOrder": sortOrder ? sortOrder : "",
      "sortColumn": sortColumn ? sortColumn : ""

    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-serviceform-details/`, params, {
      headers: {
        Authorization: API_TOKEN,
      }
    }).then(response => {
      if (response.data.data.length > 0) {
        setServiceFormsDetails(response.data.data);
        setTotalSize(response.data.record_count)
        setSpinner(false);
        localStorage.setItem('offset', offset);

      }
      else {
        setTotalSize(0);
        setServiceFormsDetails("")
        setSpinner(false);

      }
    })
    // .catch(error => {
    //   toastr.error("Error", "Something went wrong")
    //   setSpinner(false);
    // });
  };
  const exportTableData = () => {

    const params =
    {
      "file_name": fileName ? fileName : "",
      "group_name": selectedGroup ? selectedGroup.label : "",
      "form_title": selectedFormTitle ? selectedFormTitle.label : "",
      "is_pagination": isPagination ? isPagination : "",
      "record_limit": "",
      "offset": offset,
      "search_keyword": searchKeyword ? searchKeyword : "",
      "sortOrder": sortOrder ? sortOrder : "",
      "sortColumn": sortColumn ? sortColumn : ""
    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-service-form-table/`, params, {
      headers: {

        Authorization: API_TOKEN,

      },
      responseType: "blob"
    })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data, `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success(
            "Success",
            "Export successful"
          );

        }

      })
    // .catch((error) => {

    //     toastr.error(
    //         "Failed",
    //         "Something went wrong"
    //       );
    // });
  };


  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }
  const scorableFormatter = (cell) => {
    if (cell == 1) { // eslint-disable-line
      return 'Scorable'
    } else {
      return '--'
    }
  }
  const actionFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faLocationArrow}
              color={'#3f86fa'}
              style={{ cursor: 'pointer', transform: 'rotate(30deg)', transition: 'transform 0.5s cubic-bezier(0.76, 0, 0.24, 1)' }}

              onClick={() => {
                setEditGroupModal(true)
                setServiceFormID(row.form_id)
                setEditType(row.groupName)
              }}

              title="Move to group"
            />
          </Col>
          <Col>
          </Col>

        </Row>
      </>
    )
  }

  const clearValues = () => {
    setSelectedGroup("")
    setSelectedFormTitle("")
    setServiceFormsDetails("")
    setReload(reload + 1)
  }

  const tableColumns = [
    {
      dataField: "groupName",
      text: "Group",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "form_title",
      text: "Title",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "form_type_id",
      text: "Type",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "form_scorable",
      text: "Format",
      sort: true,
      formatter: scorableFormatter,
      csvFormatter: basicValidation,

    },
    {
      dataField: "form_last_modified_date",
      text: "Last Modified",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "form_status",
      text: "Status",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },

    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    }

  ];

const handleTableChange = (type, newState) => {
  setPage(newState.page);
  setSizePerPage(newState.sizePerPage);
  setOffset((newState.page - 1) * newState.sizePerPage);

  // Sorting logic
  if (newState.sortField === null && newState.sortOrder === undefined) {
    setSortColumn("");
    setSortOrder("");
  } else if (newState.sortField === "groupName") {
    setSortColumn("g.name");
    setSortOrder(newState.sortOrder);
  } else {
    setSortColumn(newState.sortField);
    setSortOrder(newState.sortOrder);
  }

  // Trigger data reload if needed
  setReload(reload + 1);
};

  

  const loadOptionsForGroupNames = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-group-names-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          }
        })
        .then(response => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  }


  const loadOptionsForFormTitle = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-serviceform-title/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          }
        })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  }

  const searchButtonValidation = () => {
    if (selectedGroup || selectedFormTitle) {
      setSearch(true)
    } else {
      setSearch(false)
    }
  }
  const clearSearchValues = () => {
    setSearchKeyword("");
    setReload(reload + 1)

  }
  const minLength = 4;
  const customNoOptionsMessage = ({ inputValue }) => {
    if (inputValue.length < minLength) {
      return "Please enter at least 4 letters to search";
    } else {
      return "No option";
    }
  };
  useEffect(() => { if (searchKeyword.length >= 2) { setIsGlobalSearch(true); } }, [searchKeyword]) // eslint-disable-line
  useEffect(() => { if (searchKeyword.length === 0) { setReload(reload + 1) } }, [searchKeyword]) // eslint-disable-line
  useEffect(() => { if (isGlobalSearch) { setOffset(0); setTotalSize(0); getServiceForms(); setIsGlobalSearch(false); } }, [isGlobalSearch]) // eslint-disable-line
  useEffect(() => { if (reload) { getServiceForms(); } }, [reload]) // eslint-disable-line
  useEffect(() => { searchButtonValidation(); }, [selectedGroup, selectedFormTitle])// eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem('offset')
    if (offset !== previousOffset || sizePerPage) {
      setReload(reload + 1)
    }
  }, [offset, sizePerPage]) // eslint-disable-line

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Service Forms
            {/* <Button className="Add-btn pull-right"
              onClick={() => {
                setNewGroupModal(!newGroupModal)

              }}
            >
              Add New Service Form
            </Button> */}
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Service Forms</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="2" md="2" lg="2">
              <Label>Group Name</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                value={selectedGroup}
                placeholder={'Search Group'}
                noOptionsMessage={customNoOptionsMessage}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                onChange={(e) => {
                  setSelectedGroup(e)
                }}
                loadOptions={loadOptionsForGroupNames}
              />
            </Col>
            <Col sm="2" md="2" lg="2">
              <Label>Search Form</Label>

              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                value={selectedFormTitle}
                placeholder={'Search Form Title'}
                noOptionsMessage={customNoOptionsMessage}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                onChange={(e) => {
                  setSelectedFormTitle(e)
                }}
                loadOptions={loadOptionsForFormTitle}
              />
            </Col>
            <Col>
              <Button
                className="patient-go-buttons"
                onClick={() => {
                  setSearchKeyword("")
                  setServiceFormsDetails("")
                  offset === 0 ? getServiceForms() : setOffset(0)
                  setPage(1);
                }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Choose at least one item to search " : ""}
              >Go</Button> {" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => { clearValues() }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >Reset</Button>
            </Col>
          </Row>
          <div className="separator" />
        </CardHeader>
        <div className='groups-search'>
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={tableColumns}
            clearValues={clearSearchValues}
          />
        </div>
        {spinner ? <Spinner /> : ""}
        <RemotePagination
  data={serviceFormsDetails}
  columns={tableColumns}
  page={page}
  sizePerPage={sizePerPage}
  FileExportName={fileExportName}
  exportTableData={exportTableData}
  totalSize={totalSize ? totalSize : ""}
  onTableChange={(type, newState) => {
    handleTableChange(type, newState);
  }}
  searchKeyword={searchKeyword}
  setSearchKeyword={setSearchKeyword}
/>
  


      </Card>
      <MoveToNewGroupModal
        serviceFormID={serviceFormID}
        editGroupModal={editGroupModal}
        setEditGroupModal={setEditGroupModal}
        editType={editType}
        reload={reload}
        setReload={setReload}
      />

    </div>
  );

}
export default ServiceForms;
