import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';

const StatusUpdateModal = (props) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    const { 
        statusModal, 
        setStatusModal, 
        text,
        apiEndpoint,
        id, 
        status,
        reload,
        statusParamKey,
        idParamKey,
        setReload 
    } = props

    const updateStatus = () => {
        let current_status = status
        if(current_status === 0){
            current_status = 1
        }
        else {
            current_status = 0
        }
        const params = {
            [idParamKey]: id ? id:"",
            [statusParamKey]: current_status
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/${apiEndpoint}`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.status === 200) {
                    setStatusModal(false)
                    setReload(reload + 1)
                }
            })
            .catch(error => {
                toastr.error("Error",error.response.data.message)
            });

    }

    const toggle = () => {
        setStatusModal(!statusModal)
    }


    return (
        <div>
            <Modal isOpen={statusModal} >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Change Status</ModalHeader>
                <ModalBody>
                <div style={{textAlign:'center'}}>
                    Do you want to change the {text} status to {status === 1 ? 'inactive?':'active ?'}
                    
                </div>
                </ModalBody>
                <ModalFooter  className="custom-modal-footer">
                    <Button className="yes-button button-width" 
                    onClick={()=>updateStatus()} 
                    >
                        Yes
                    </Button>{" "}
                    <Button outline className="no-button button-width" onClick={toggle}>
                        No
          </Button>
                </ModalFooter>
            </Modal>
        </div>
    );




}

export default StatusUpdateModal;