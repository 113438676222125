/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Col,
  Row,
  Button,
  Spinner,
  Breadcrumb,
  CardHeader,
  Label,
  BreadcrumbItem,
  Container,
} from "reactstrap";
import { Link,useNavigate } from "react-router-dom";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select-virtualized";
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import SubscriptionModal from "../../newComponents/utilities/SubscriptionModal";
import StatusUpdateModal from "../../newComponents/utilities/StatusUpdateModal";
import { GlobalState } from "../../contextAPI/Context";
import MomentConfig from "../../common/MomentConfig";
import moment from "moment";
import Cookie from 'js-cookie';


const SubscriptionPlanDetails = () => {
  const {
    subscriptionNamesList,
    subscriptionNamesListReload,
    setSubscriptionNamesListReload,
    getAllSubscriptionPlans,
  } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const fileExportName = "SubscriptionPlan";

  let statusText = "subscription plan";
  let statusUpdateEndpoint = "update-subscription-plan-status/";
  let idParamKey = "subscription_plan_id";
  let statusParamKey = "subscription_status";
  // eslint-disable-next-line no-unused-vars
  let history = useNavigate(); 

  const [spinner, setSpinner] = useState(false);
  const [search, setSearch] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalUseState, setModalUseState] = useState("");

  const [subscriptionDetails, setSubscriptionDetails] = useState("");
  const [subscriptionPlanId, setSubscriptionPlanId] = useState("");
  const [subscriptionPlanName, setSubscriptionPlanName] = useState("");
  const [subscriptionDescription, setSubscriptionDescription] = useState("");
  const [subscriptionValidity, setSubscriptionValidity] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [subscriptionPrice, setSubscriptionPrice] = useState("");

  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [reload, setReload] = useState(0);

  const [offset, setOffset] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(50);
  // eslint-disable-next-line no-unused-vars
  const [isPagination, setIsPagination] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(1);

  const [statusModal, setStatusModal] = useState(false);
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState("");
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;

  const validation = (cell) => {
    // eslint-disable-next-line eqeqeq
    if(cell !=undefined)
    {
      return cell
    }
    return  "N/A"
  }


  const filterValidation = () => {
    if (selectedStatus || selectedSubscription) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <>
        <span
          className={badgeclass}
          onClick={() => {
            setSubscriptionStatus(row.Status);
            setSubscriptionPlanId(row.subPlanId);
            setStatusModal(true);
          }}
        >
          {status}
        </span>
      </>
    );
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <Button
              className="money-button"
              outline
              onClick={() => {
                setModal(true);
                setSubscriptionPlanId(row.subPlanId);
                setSubscriptionPlanName(row.subscriptionName);
                setSubscriptionDescription(row.subscriptionDescription);
                setSubscriptionValidity(row.Validity);
                setSubscriptionStatus(row.Status);
                setSubscriptionPrice(row.Price);
                setModalUseState("edit");
              }}
              color="primary"
              title="Edit Subscription"
            >
              <FontAwesomeIcon icon={faEdit} size={"md"} />
            </Button>
          </Col>

          {/* <Col sm={2}>
            <Link style={{ color: 'red' }}
            // onClick = {()=>{
            //   delete
            // }}
            
            >
              <FontAwesomeIcon icon={faTrash} />
            </Link>
          </Col>  */}
        </Row>
      </>
    );
  };

  const columns = [
    {
      dataField: "subscriptionName",
      text: "Name",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "subscriptionDescription",
      text: "Description",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "Validity",
      text: "Validity",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
    },
    {
      dataField: "Price",
      text: "Price",
      sort: false,
      formatter: validation,
    },
    {
      //   dataField: 'SubscriptionName',
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];

  const statusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];



  const fetchSubscriptionDetails = () => {
    setSpinner(true);
    const params = {
      subscription_plan_id: selectedSubscription
        ? selectedSubscription.value
        : "",
      subscription_status: selectedStatus ? selectedStatus.value : "",
      is_pagination: isPagination ? isPagination : "",
      record_limit: sizePerPage ? sizePerPage : "",
      offset: offset,
      search_keyword: searchKeyword ? searchKeyword : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-subscription-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setSubscriptionDetails(response.data.data);
          setTotalSize(response.data.record_count);
          setSpinner(false);
          localStorage.setItem("offset", offset);
        } else {
          setTotalSize(0);
          setSubscriptionDetails("");
          setSpinner(false);
          setIsDataEmpty(true);
        }
      })
      // .catch((error) => {
      //   if (error.response.status === 401) {
      //     console.log('error moving to logout')
      //     history.push('/logout');
      //   }else{
      //   toastr.error("Error", "Something went wrong");
      //   setSpinner(false);}
      // });
  };
  const exportTableData = () => {
    const params = {
      file_name: fileName ? fileName : "",
      subscription_plan_id: selectedSubscription ? selectedSubscription.value : "",
      subscription_status: selectedStatus ? selectedStatus.value : "",
      is_pagination: isPagination ? isPagination : "",
      record_limit: sizePerPage ? sizePerPage : "",
      offset: offset,
      search_keyword: searchKeyword ? searchKeyword : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/export-subscription-plans-table-data/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const handleTableChange = (type, newState) => {
    // console.log(newState)
    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);
    const currentIndex = 0;

    if (newState.sortField !== "" && newState.sortOrder !== "") {
      let result;
      if (newState.sortOrder === 'asc') {
        result = subscriptionDetails.sort((a, b) => {
          if (a[newState.sortField] > b[newState.sortField]) {
            return 1;
          } else if (b[newState.sortField] > a[newState.sortField]) {
            return -1;
          }
          return 0;
        });
      } else {
        result = subscriptionDetails.sort((a, b) => {
          if (a[newState.sortField] > b[newState.sortField]) {
            return -1;
          } else if (b[newState.sortField] > a[newState.sortField]) {
            return 1;
          }
          return 0;
        });
      }
  
      setSubscriptionDetails(result.slice(currentIndex, currentIndex + sizePerPage));
      }
    }

  const clearSearchValues = () => {
    setSearchKeyword("");
    setReload(reload + 1);
  };

  const clearValues = () => {
    setSelectedSubscription("");
    setSelectedStatus("");
    setReload(reload + 1);
  };

  // useEffect(() => {
  //   fetchSubscriptionDetails();

  // }, []) // eslint-disable-line

  useEffect(() => {
    if (subscriptionNamesList.length <= 0) {
      getAllSubscriptionPlans();
    }
  }, [subscriptionNamesList]); // eslint-disable-line

  useEffect(() => {
    if (reload) {
      fetchSubscriptionDetails();
    }
  }, [reload]); // eslint-disable-line

  useEffect(() => {
    filterValidation();
  }, [selectedSubscription, selectedStatus]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setReload(reload + 1);
    }
  }, [searchKeyword]); // eslint-disable-line

  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      // setPage
      setTotalSize(0);
      setReload(reload + 1);
      // setReloadGroupDetails(true);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset || sizePerPage) {
      setReload(reload + 1);
    }
  }, [offset, sizePerPage]); // eslint-disable-line

  useEffect(() => {   
    window.scrollTo(0, 0);   
     }, []);
     

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Subscription Plans
            <Button
              className="Add-btn pull-right"
              onClick={() => {
                setModal(true);
                setModalUseState("add");
              }}
            >
              Add Subscription Plan
            </Button>
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Subscription Plans</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>
      <Card>
        <CardHeader>
          <Row>
            <Col sm="2" md="2" lg="3">
              <Label>Subscription Plan</Label>
              <Select
                name="Subscription Plan"
                options={subscriptionNamesList}
                placeholder="Choose Subscription Plan"
                // isDisabled = {!m_zipList || !adminNames?true:false}
                isClearable
                value={selectedSubscription}
                onChange={(e) => {
                  setSelectedSubscription(e);
                }}
              />
            </Col>
            <Col sm="2" md="2" lg="2">
              <Label>Status</Label>
              <Select
                name="Status"
                options={statusOptions}
                placeholder="Choose status"
                isClearable
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e);
                }}
              />
            </Col>
            <Col>
              <Button
                className="patient-go-buttons"
                onClick={() => {
                  setSearchKeyword("");
                  // setData("")
                  offset === 0 ? setReload(reload + 1) : setOffset(0);
                  // setIsSearch(true);
                  setPage(1);
                }}
                disabled={spinner || !search ? true : false}
                title={
                  spinner || !search
                    ? "Choose at least one item to search "
                    : ""
                }
              >
                Go
              </Button>{" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => {
                  clearValues();
                }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <div className="separator" />
        </CardHeader>
        <div className="groups-search">
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={columns}
            clearValues={clearSearchValues}
          />
        </div>

        {!spinner ? (
          <RemotePagination
            data={subscriptionDetails ? subscriptionDetails : ""}
            setSubscriptionDetails={setSubscriptionDetails}
            columns={columns}
            page={page}
            sizePerPage={sizePerPage}
            FileExportName={fileExportName}
            isGlobalSearch={isGlobalSearch}
            totalSize={totalSize}
            exportTableData={exportTableData}
            onTableChange={(type, newState) => {
              handleTableChange(type, newState);
            }}
            isDataEmpty={isDataEmpty}
          />
        ) : (
          <Spinner />
        )}
      </Card>

      <SubscriptionModal
        modal={modal}
        setModal={setModal}
        modalUseState={modalUseState}
        setModalUseState={setModalUseState}
        subscriptionPlanId={subscriptionPlanId}
        setSubscriptionPlanId={setSubscriptionPlanId}
        subscriptionPlanName={subscriptionPlanName}
        setSubscriptionPlanName={setSubscriptionPlanName}
        subscriptionDescription={subscriptionDescription}
        setSubscriptionDescription={setSubscriptionDescription}
        subscriptionValidity={subscriptionValidity}
        setSubscriptionValidity={setSubscriptionValidity}
        subscriptionStatus={subscriptionStatus}
        setSubscriptionStatus={setSubscriptionStatus}
        subscriptionPrice={subscriptionPrice}
        setSubscriptionPrice={setSubscriptionPrice}
        statusOptions={statusOptions}
        reload={reload}
        setReload={setReload}
        subscriptionNamesListReload={subscriptionNamesListReload}
        setSubscriptionNamesListReload={setSubscriptionNamesListReload}
      />

      <StatusUpdateModal
        statusModal={statusModal}
        setStatusModal={setStatusModal}
        status={subscriptionStatus}
        text={statusText}
        apiEndpoint={statusUpdateEndpoint}
        idParamKey={idParamKey}
        statusParamKey={statusParamKey}
        id={subscriptionPlanId}
        reload={reload}
        setReload={setReload}
      />
    </div>
  );
};

export default SubscriptionPlanDetails;
