import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Button
  , Spinner
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import { GlobalState } from '../../contextAPI/Context';
import AppUserFilters from "../../newComponents/utilities/AppUserFilters"
import { toastr } from "react-redux-toastr";
import AxiosInter from './../../AxiosInter.js';
import Cookie from "js-cookie";

const ExportCSVTable = (props) => {
  const setIsPayorIdnullFunction = props.setIsPayorIdnull
const { blankStateMessage } = useContext(GlobalState)
  // LOCAL VARIABLES 
  const { totalSize, onTableChange, searchKeys, setSearchKeys, appUserReload, setAppUserReload, offset, setOffset, exportTableData, selectedRows, setSelectedRows, unselectedRows } = props //eslint-disable-line
  const [numberOfRecords, setNumberOfRecords] = useState(0)
  const [showBulkAssignButton, setShowBulkAssignButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");

  const MyExportCSV = (props) => {
    const handleClick = () => {
      // props.onExport();
      exportTableData();
    };
    return (
      <div className="button-container">
        <button className="btn btn-export mt-2" onClick={handleClick}>
          Export
        </button>
        {showBulkAssignButton ? (
          <EmailDigestButton />
        ) : null}
      </div>
    );
  };
  useEffect(() => {
    setShowBulkAssignButton(selectedRows.length > 0);
  }, [selectedRows]);

  const handleEnableBulkDigest = async () => {
    try {
      setIsLoading(true);
      if (selectedRows.length === 0) {
        toastr.warning("No data to assign email digest");
        setIsLoading(false);
        return;
      }
      const response = await AxiosInter.post(`${API_ENDPOINT}/admin-api/bulk-enable-email-digest/`,{
        selectedRows,
      }, {
        headers: {
          Authorization: API_TOKEN,
        }
      } );
      if (response.status === 200) {
        setIsLoading(false);
        setSelectedRows([])
        setAppUserReload(appUserReload + 1);
        toastr.success("Email digest enabled");
      }
    } catch (error) {
      setIsLoading(false);
      toastr.error("Error occurred");
    }
  };
  const handleDisableBulkDigest = async () => {
    try {
      setIsLoading(true);
      if (selectedRows.length === 0) {
        toastr.warning("No data to assign email digest");
        setIsLoading(false);
        return;
      }
      const response = await AxiosInter.post(`${API_ENDPOINT}/admin-api/bulk-disable-email-digest/`,{
        selectedRows
      }, {
        headers: {
          Authorization: API_TOKEN,
        }
      } );
      if (response.status === 200) {
        setIsLoading(false);
        setSelectedRows([])
        setAppUserReload(appUserReload + 1);
        toastr.success("Email digest disabled");
      }
    } catch (error) {
      setIsLoading(false);
      toastr.error("Error occurred");
    }
  };
  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No data available in table</h3>
      </CardBody>
    </Card>
  );
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: selectedRows,
    onSelect: (row, isSelected) => {
      setSelectedRows(
        isSelected
          ? [...selectedRows, row]
          : selectedRows.filter((r) => r !== row)
      );
    },
    onSelectAll: (isSelected, rows) => {
      setSelectedRows(isSelected ? rows : []);
      setShowBulkAssignButton(false);
    }
  };





  useEffect(() => { setNumberOfRecords(totalSize) }, [totalSize]);


  function EmailDigestButton() {
    const [showOptions, setShowOptions] = useState(false);
  
    const handleClick = () => {
      setShowOptions(!showOptions);
    };
  
    return (
      <div className="email-digest-button">
        <button onClick={handleClick}>
          Bulk Assign Email Digest
          <span className={`arrow ${showOptions ? "up" : "down"}`}>&#x25BE;</span>
        </button>
        {showOptions && (
          <div className="email-digest-options">
            <button className="option enable" onClick={handleEnableBulkDigest}>
              Enable Email Digest
            </button>
            <button className="option disable"onClick={handleDisableBulkDigest}>
              Disable Email Digest
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="physicians_table patient_table table-list-height">
      <Card>
        <ToolkitProvider
          keyField="id"
          data={props.data}
          columns={props.columns}
          search
          exportCSV={{
            fileName: `Equipo-App-Users-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
          }}
          selectRow={selectRow}
        >
          {(props) => (
            <div>
              <CardHeader>

                <Row>
                  <Col>
                    <AppUserFilters offset={offset}
                      setOffset={setOffset}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <div className="number-of-records">
                      <p>Number of records: <strong>{numberOfRecords}</strong></p>
                    </div>
                  </Col>
                  <Col >
                    {/* <SearchBar {...props.searchProps} className="search-box" />
                    <ClearSearchButton {...props.searchProps} className="clear-search-box" /> */}
                    <div className="form-inline justify-content-end">
                      <input type="checkbox" onClick={(e)=>setIsPayorIdnullFunction((e.target.checked))}/>&nbsp;&nbsp;Show payor ID null &nbsp;&nbsp;
                      <div className="form-group mr-2">
                        <Input
                          className="search-box-new"
                          type="text"
                          value={searchKeys}
                          onChange={(e) => setSearchKeys(e.target.value)}
                          placeholder="Search"

                        />
                      </div>
                      <Button
                        className="clear-search-box"
                        onClick={() => { setSearchKeys(""); setAppUserReload(appUserReload + 1) }}
                      >
                        Clear
                      </Button>
                      <div className="export-button ml-2">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="separator" />
                <div className="float-right pull-right">
                </div>
              </CardHeader>
              <CardBody className="patient-list appUserptlist">
                {isLoading && <Spinner />}
                {blankStateMessage ? blankState : ""}
                {!blankStateMessage &&
                  <BootstrapTable
                    {...props.baseProps}
                    remote
                    bootstrap4
                    /* id={props.key} */
                    bordered={false}
                    keyField="id"

                    // onDataSizeChange={handleDataChange}
                    pagination={paginationFactory({
                      page: props.page,
                      sizePerPage: props.sizePerPage,
                      sizePerPageList: [50, 100, 150, 200],
                      totalSize
                    })}
                    onTableChange={onTableChange}
                  /* {!props.data && apiLoaded ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""} */
                  />}
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
        
      </Card>
    </div>
  );
};

export default ExportCSVTable;