import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader,Container, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';


const AddCareServiceModal = (props) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");

    const [buttonBlankState,setButtonBlankState] = useState(false);
    const {
        setModal,
        modal,
        modalState,
        newCareService,
        setNewCareService,
        editCptCategory,
        setEditCptCategory,
        setEditCptDescription,
        setEditCptLongDescription,
        setModalState,
        cptCategoryList,
        setReloadCareService,
        selectedId
        
    } = props

    const toggle = () => {
        setModal(!modal)
        setNewCareService("")
    }

    const setInitialCategory = () => {
        cptCategoryList.map((data) => { // eslint-disable-line
           
            if (data.label === editCptCategory) {
                setEditCptCategory({ value: data.value, label: editCptCategory })
  
            }
        })
    }


    const updateCarePlanProgram = () => {
        const params =
        {
            "row_id":selectedId ? selectedId:"",
            "title":newCareService ? newCareService:""
           
            
        }
 
        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-care-plan-program/`,params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
          .then(response => {
            setReloadCareService(true)
            toastr.success("Success","Updated successfully")
            toggle();

            
            
          })
          .catch(error => {
            toastr.error("Error",error.response.data.message)
           
          });

    }
    const addNewCpt = () => {
        const params =
        {
            "title": newCareService ? newCareService:"",
        }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/add-care-plan-program/`,params,{
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
          toastr.success("Success","New care service added")
          setReloadCareService(true)
          toggle();

        
        
      })
      .catch(error => {
        if (error.response.data.message) {
            toastr.error("Error",error.response.data.message)
        } 
        else {
            toastr.error("Error","Something went wrong !")
        }  
      
       
      });


    }

    const validate = () => {

        if (
            newCareService
    
        ) {
            setButtonBlankState(false)
        }
        else {
            setButtonBlankState(true)
        }
      }






    useEffect(() => {
        if (!modal) {
           setModal(false);
           setModalState("initial");
           setNewCareService("");
           setEditCptDescription("");
           setEditCptLongDescription("");
           setEditCptCategory("");
        };

    }, [modal]) // eslint-disable-line



  useEffect(() => {
    if (cptCategoryList && editCptCategory) {
        setInitialCategory();
    }

}, [cptCategoryList,editCptCategory]) // eslint-disable-line


  useEffect(() => {
  
        validate();
    

}, [newCareService]) // eslint-disable-line




    
    return (
        <div>
            <Modal isOpen={modal} size="md">
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>{modalState === "add"? "Add Care Service":"Edit Care Service"} </ModalHeader>
                <ModalBody className="overflow-modal">
                <Container fluid>
                    <FormGroup>
                        <Row>
                            <Col sm={12}>
                                <Label for="cpt_code">Title<span className="required">*</span></Label>
                                <Input
                                    id="cpt_code"
                                    placeholder="Enter Title"
                                    value={newCareService}
                                    onChange={(e) => {
                                        setNewCareService(e.target.value)

                                    }}
                                />
                            </Col>
                        </Row><br/>
                        
                        

                    </FormGroup>
                    </Container>

                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button
                  
                        onClick={() => {
                          modalState === 'edit' ? updateCarePlanProgram():addNewCpt()
                        }}
                    disabled = {buttonBlankState}
                    title= {buttonBlankState ? "Please fill in all mandatory fields":(!buttonBlankState && modalState)?"Click to save":"Click to update"}

                    >{modalState === 'edit' ?'Update':'Add'}</Button>{' '}

                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default AddCareServiceModal;