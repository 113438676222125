import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'

const HabitStatusChangeModal = (props) => {
  const {
    modalState,
    setModalState,
    habitID,
    status,
    userDataReload,
    setUserDataReload
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const toggle = () => {
    setModalState(!modalState);
  };

  const confirmation = () => {
    let newStatus = "";
    if (status === 0) {
      newStatus = 1
    } else {
      newStatus = 0
    }

    const updatedStatus = {
      'Habit_id': habitID,
      'status': newStatus,
    };
    
    AxiosInter.post( `${API_ENDPOINT}/admin-api/update-new-habit-status/`, updatedStatus,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
        if (res.status === 201) {
            setUserDataReload(userDataReload+1)
          setModalState(!modalState);
          toastr.success('Status Updated', 'Habit status updated successfully');
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={modalState} >
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Status</ModalHeader>
        <ModalBody>
            <div style= {{'textAlign':'center'}}>
              Do you want to change the Habit status to {status === 0? 'active ?':'inactive ?' }
            </div>
        </ModalBody>
        <ModalFooter  className="custom-modal-footer">
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default HabitStatusChangeModal;
