import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Input,
  Label,
  Form,
} from "reactstrap";
import AxiosInter from "../../AxiosInter.js";
import Cookie from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";

function EditReferralCategoryConfigurationModal({
  data,
  editableRowId,
  isEditModal,
  toggleEditModal,
  setApiLoaded,
}) {
  // local variables
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [ordercatId, setOrdercatId] = useState(null);
  const [category, setCategory] = useState(null);
  const [viewType, setViewType] = useState(null);
  const [viewSection, setViewSection] = useState(null);
  const [senderallowedStatus, setSenderallowedStatus] = useState(null);
  const [fulfillerallowedStatus, setFulfillerallowedStatus] = useState(null);
  const [allowedPriority, setAllowedPriority] = useState(null);
  const [catIcons, setCatIcons] = useState(null); // eslint-disable-line

  // all view type options hard-coded
  const viewTypeOptions = [
    { value: "patientData", label: "patientData" },
    { value: "orderData", label: "orderData" },
    { value: "financialData", label: "financialData" },
    { value: "eligibilityData", label: "eligibilityData" },
    { value: "preAuthData", label: "preAuthData" },
    { value: "guarantorInfo", label: "guarantorInfo" },
  ];
  const [preSelectedViewSecions, setpreSelectedViewSecions] = useState([]);

  const [staticDataRadioButtonClicked, setStaticDataRadioButtonClicked] =
    useState(false);
  const [dynamicFormRadioButtonClicked, setDynamicFormRadioButtonClicked] =
    useState(false);

  const [allReferralOrderStatus, setAllReferralOrderStatus] = useState(null);
  const [preSelectedSenderAllowedStatus, setPreSelectedSenderAllowedStatus] =
    useState([]);
  const [
    preSelectedFullfillerAllowedStatus,
    setPreSelectedFullfillerAllowedStatus,
  ] = useState([]);

  // data contains all rows, we need only one row based on id
  // if user click first row for editing then populate only that
  const populateSingleRow = (editableRowId) => {
    const selectedRow = data.find(
      (eachRow) => eachRow.ordercatId === editableRowId
    );

    if (selectedRow) {
      setOrdercatId(selectedRow.ordercatId);
      setCategory(selectedRow.categories);
      setViewType(selectedRow.viewType);

      // Convert comma-separated strings into arrays
      setViewSection(
        selectedRow.viewSection ? selectedRow.viewSection.split(", ") : []
      );
      setSenderallowedStatus(
        selectedRow.senderallowedStatus
          ? selectedRow.senderallowedStatus.split(",")
          : []
      );
      setFulfillerallowedStatus(
        selectedRow.fulfillerallowedStatus
          ? selectedRow.fulfillerallowedStatus.split(",")
          : []
      );

      // Parse allowedPriority string into an array of objects
      const allowedPriorityArray = selectedRow.allowedPriority
        ? JSON.parse(`[${selectedRow.allowedPriority}]`)
        : [];
      setAllowedPriority(allowedPriorityArray);

      setCatIcons(selectedRow.catIcons);
    }
  };

  useEffect(() => {
    // Call the function to populate the desired row
    populateSingleRow(editableRowId);
    getAllReferralAllowedStatusOptions();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllReferralAllowedStatusOptions = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get_referralorder_status/`)
      .then(function (response) {
        setAllReferralOrderStatus(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // this is for searching functionality in async select
  const filterAllowedStatus = (inputValue) => {
    return allReferralOrderStatus.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const filterBasedOnSearch = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterAllowedStatus(inputValue));
      }, 1000);
    });

  useEffect(() => {
    // senderallowedStatus contains array of strings
    // console.log(allReferralOrderStatus);
    // console.log(senderallowedStatus);
    // console.log(fulfillerallowedStatus);
    if (allReferralOrderStatus && senderallowedStatus) {
      const SelectedSenderAllowedStatus = allReferralOrderStatus.filter(
        (eachObj) =>
          senderallowedStatus.some(
            (eachItem) => eachItem.toLowerCase() === eachObj.label.toLowerCase()
          )
      );

      const SelectedFulfillerallowedStatus = allReferralOrderStatus.filter(
        (eachObj) =>
          fulfillerallowedStatus.some(
            (eachItem) => eachItem.toLowerCase() === eachObj.label.toLowerCase()
          )
      );

      setPreSelectedSenderAllowedStatus(SelectedSenderAllowedStatus);
      setPreSelectedFullfillerAllowedStatus(SelectedFulfillerallowedStatus);
    }

    if (viewType && viewSection) {
      if (viewType === "staticData") {
        setStaticDataRadioButtonClicked(true);

        // console.log(viewSection);
        // console.log(viewTypeOptions);
        const selectedViewSection = viewTypeOptions.filter((eachObj) =>
          viewSection.includes(eachObj.value)
        );
        setpreSelectedViewSecions(selectedViewSection);
      }
      if (viewType === "dynamicForm") {
        setDynamicFormRadioButtonClicked(true);
      }
    }
  }, [allReferralOrderStatus, viewType, viewSection]); // eslint-disable-line react-hooks/exhaustive-deps

  // update preselected values accordingly, user can select new option or delete existing one
  const handleSenderAllowedStatusChange = (itemSelected) => {
    setPreSelectedSenderAllowedStatus(itemSelected);
  };

  // update preselected values accordingly, user can select new option or delete existing one
  const handleFullfillerAllowedStatusChange = (itemSelected) => {
    setPreSelectedFullfillerAllowedStatus(itemSelected);
  };

  const handleAllowedPriorityInputChange = (index, event) => {
    const { name, value } = event.target;
    const newallowedPriorityInputFields = [...allowedPriority];
    newallowedPriorityInputFields[index][name] = value;
    setAllowedPriority(newallowedPriorityInputFields);
  };

  // add new row when click plus(+) button
  const handleAddNewRow = (index) => {
    const newallowedPriorityInputFields = [
      ...allowedPriority,
      { priority: "", value: "", description: "", intervalSpecifiedIn: "" },
    ];
    setAllowedPriority(newallowedPriorityInputFields);
  };

  // remove row when click minus(-) button
  const handleRemoveRow = (index) => {
    const newallowedPriorityInputFields = [...allowedPriority];
    newallowedPriorityInputFields.splice(index, 1);
    setAllowedPriority(newallowedPriorityInputFields);
  };

  const handleViewSectionChange = (itemSelected) => {
    setpreSelectedViewSecions(itemSelected);
  };

  const handleRadioButtonClicked = (name) => {
    if (name === "staticData") {
      setDynamicFormRadioButtonClicked(false);
      setStaticDataRadioButtonClicked(true);
      setViewType("staticData");
    }
    if (name === "dynamicForm") {
      setStaticDataRadioButtonClicked(false);
      setDynamicFormRadioButtonClicked(true);
      setpreSelectedViewSecions(null);
      setViewType("dynamicForm");
    }
  };

  // store newCategoryObj to database onsubmit of form
  const handleEditNewCategory = (e) => {
    e.preventDefault();

    const updatedNewCategory = {
      orderCatID: ordercatId,
      category: category,
      view_type: viewType,
      view_section: preSelectedViewSecions
        ? preSelectedViewSecions.map((eachObj) => eachObj.value)
        : "",
      sender_allowed_status:
        preSelectedSenderAllowedStatus &&
        preSelectedSenderAllowedStatus.map((eachObj) => eachObj.value + ""),
      fulfiller_allowed_status:
        preSelectedFullfillerAllowedStatus &&
        preSelectedFullfillerAllowedStatus.map((eachObj) => eachObj.value + ""),
      allowed_priority: allowedPriority,
      icons: "",
    };

    const validated = validateForm();

    if (validated) {
      AxiosInter.post(
        `${API_ENDPOINT}/admin-api/update-referralcategory/`,
        updatedNewCategory,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => {
          if (response.status === 201) {
            toastr.success("Category updated");
            setApiLoaded(true);
            toggleEditModal(false);
          } else {
            toastr.error("Error");
            setApiLoaded(true);
            toggleEditModal(false);
          }
        })
        .catch((err) => {
          toastr.error("Categoryname already exists");
          console.log(err);
        });
    }
  };

  // checking all mandatory fields are filled
  const validateForm = () => {
    if (category === "") {
      toastr.error("Please enter category name");
    }

    // if any of radio button not cliked then show an error
    else if (!staticDataRadioButtonClicked && !dynamicFormRadioButtonClicked) {
      toastr.error("Please select view type");
    }

    // if your click staticData radio button and not selected any option from dropdown show an error
    else if (
    // eslint-disable-next-line eqeqeq
      staticDataRadioButtonClicked && preSelectedViewSecions.length == 0
    ) {
      toastr.error("Please select from static data");
    }

    // if sender allowed status not selected show an error
    else if (preSelectedSenderAllowedStatus.length === 0) {
      toastr.error("Please select sender allowed status");
    }

    // if fullfiller allowed status not selected show an error
    else if (preSelectedFullfillerAllowedStatus.length === 0) {
      toastr.error("Please select fullfiller allowed status");
    }

    // if user did not enter allowed priority values show an error
    else if (allowedPriority) {
      let isValid = true;
    // eslint-disable-next-line array-callback-return
      allowedPriority.map((eachItem) => {
        if (
          eachItem.description === "" ||
          eachItem.intervalSpecifiedIn === "" ||
          eachItem.priority === "" ||
          eachItem.value === ""
        ) {
          toastr.error("Please enter all allowed priority fields");
          isValid = false;
        }
      });

      if (isValid) {
        // post data to database
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      {/* {loading && <Spinner style={{ zIndex: 1111 }} />} */}
      <Modal
        isOpen={isEditModal}
        size="md"
        className="referral-category-configuration"
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggleEditModal}
        >
          Edit Category Configuration
        </ModalHeader>
        <Form onSubmit={handleEditNewCategory}>
          <ModalBody>
            <Row>
              <Col className="bottom-space">
                <Label>
                  Category <span className="required">*</span>
                </Label>
                <Input
                  type="text"
                  name="categories"
                  value={category}
                  placeholder="Enter category"
                  onChange={(e) => setCategory(e.target.value)}
                />
              </Col>
              <Col xs={12} className="bottom-space">
                <Label>
                  View Type <span className="required">*</span>
                </Label>
                <Col className="viewtype-input">
                  <Input
                    type="radio"
                    name="viewType"
                    checked={staticDataRadioButtonClicked}
                    onClick={() => handleRadioButtonClicked("staticData")}
                  />
                  StaticData
                </Col>
                {staticDataRadioButtonClicked && (
                  <AsyncSelect
                    onChange={handleViewSectionChange}
                    isMulti
                    cacheOptions
                    value={preSelectedViewSecions}
                    defaultOptions={viewTypeOptions}
                    placeholder="Select static data"
                    loadOptions={filterBasedOnSearch}
                  />
                )}
                <Col className="viewtype-input">
                  <Input
                    type="radio"
                    name="viewType"
                    value="null"
                    checked={dynamicFormRadioButtonClicked}
                    onClick={() => handleRadioButtonClicked("dynamicForm")}
                  />
                  DynamicForm
                </Col>
              </Col>
              <Col xs={12} className="bottom-space">
                <Label>
                  Sender Allowed Status <span className="required">*</span>
                </Label>
                <AsyncSelect
                  onChange={handleSenderAllowedStatusChange}
                  isMulti
                  cacheOptions
                  value={preSelectedSenderAllowedStatus}
                  defaultOptions={allReferralOrderStatus}
                  placeholder="Select sender allowed status"
                  loadOptions={filterBasedOnSearch}
                />
              </Col>
              <Col xs={12} className="bottom-space">
                <Label>
                  Fullfiller Allowed Status <span className="required">*</span>
                </Label>
                <AsyncSelect
                  onChange={handleFullfillerAllowedStatusChange}
                  isMulti
                  cacheOptions
                  value={preSelectedFullfillerAllowedStatus}
                  defaultOptions={allReferralOrderStatus}
                  placeholder="Select sender allowed status"
                  loadOptions={filterBasedOnSearch}
                />
              </Col>
              <Col xs={12} className="bottom-space">
                <Label>
                  Allowed Priority <span className="required">*</span>
                </Label>
                {allowedPriority &&
                  allowedPriority.map((eachObj, index) => (
                    <Row className="pb-3" key={index}>
                      <Col>
                        <Input
                          type="text"
                          name="priority"
                          value={eachObj.priority}
                          placeholder="Priority"
                          onChange={(event) =>
                            handleAllowedPriorityInputChange(index, event)
                          }
                        />
                      </Col>
                      <Col>
                        <Input
                          type="number"
                          name="value"
                          value={eachObj.value}
                          placeholder="Value"
                          onChange={(event) =>
                            handleAllowedPriorityInputChange(index, event)
                          }
                        />
                      </Col>
                      <Col>
                        <Input
                          type="text"
                          name="description"
                          value={eachObj.description}
                          placeholder="Description"
                          onChange={(event) =>
                            handleAllowedPriorityInputChange(index, event)
                          }
                        />
                      </Col>
                      <Col>
                        <Input
                          type="text"
                          name="intervalSpecifiedIn"
                          value={eachObj.intervalSpecifiedIn}
                          placeholder="intervalSpecifiedIn"
                          onChange={(event) =>
                            handleAllowedPriorityInputChange(index, event)
                          }
                        />
                      </Col>
                      <Col xs={1}>
                        <FontAwesomeIcon
                          icon={faPlus}
                          size={"1x"}
                          color={"#3f86fa"}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleAddNewRow(index)}
                          title="Add"
                        />
                      </Col>
                      <Col xs={1}>
                        { // eslint-disable-next-line eqeqeq
                        !index == 0 && (
                          <FontAwesomeIcon
                            icon={faTrash}
                            color={"#E12C2C"}
                            size={"1x"}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleRemoveRow(index);
                            }}
                            title="Remove"
                          />
                        )}
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="advanced-search-button  custom-modal-footer">
            <Button
              type="submit"
              className="yes-button button-width"
              style={{ cursor: "pointer" }}
            >
              Update
            </Button>{" "}
            <Button
              outline
              style={{ cursor: "pointer" }}
              className="no-button button-width"
              onClick={toggleEditModal}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default EditReferralCategoryConfigurationModal;
