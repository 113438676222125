import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'

const InsurancePlanTypeDeleteModal = (props) => {
  const {
    insurancePlanTypeId,
    insurancePlanTypeReload, setInsurancePlanTypeReload,
    deleteInsurancePlanTypeModal,setDeleteInsurancePlanTypeModal,
    editInsurancePlanTypeName
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const toggle = () => {
    setDeleteInsurancePlanTypeModal(!deleteInsurancePlanTypeModal);
  };

  const confirmation = () => {
    

    const param = {
      "insurance_plan_type_id": insurancePlanTypeId ? Number(insurancePlanTypeId) :0
    };

    AxiosInter.post( `${API_ENDPOINT}/admin-api/delete-insurance-plan-type/`, param,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
      
        if (res.status === 200) {
          toastr.success('Successful', 'Insurance Group removed successfully');
          setDeleteInsurancePlanTypeModal(!deleteInsurancePlanTypeModal);
          setInsurancePlanTypeReload(insurancePlanTypeReload+1)
        }
      })
      .catch((error) => {
        console.log(error);
        toastr.error('Try again', 'deletion error');
      });
  };

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={deleteInsurancePlanTypeModal} >
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Delete Insurance Plan Type ?</ModalHeader>
        <ModalBody>
            <div>
              Are you sure you want to delete <strong> {editInsurancePlanTypeName} </strong>  ?
            </div>
        </ModalBody>
        <ModalFooter  className="custom-modal-footer">
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default InsurancePlanTypeDeleteModal;
