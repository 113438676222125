import React, { useState, useEffect, useContext } from "react";
import {
  TabContent,
  TabPane,
  Spinner,
  Label,
  Nav,
  Container,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import AxiosInter from './../../AxiosInter.js';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { GlobalState } from "../../contextAPI/Context";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import InsuranceGroupModal from "../../newComponents/utilities/InsuranceGroupModal";
import InsuranceGroupDeleteModal from "../../newComponents/utilities/InsuranceGroupDeleteModal";
import InsurancePlanTypeDeleteModal from "../../newComponents/utilities/InsurancePlanTypeDeleteModal";
import AsyncSelect from "react-select/async";
import InsurancePlanTypeModal from "../../newComponents/utilities/InsurancePlanTypeModal";
import InsuranceCompanyModal from "../../newComponents/utilities/InsuranceCompanyModal";
import _ from "lodash";
import MomentConfig from "../../common/MomentConfig";
import moment from "moment";
import Cookie from 'js-cookie';


const Insurance = () => {
  const {
    groupListLoading,
    insuranceGroupNames,
    setInsuranceGroupNames,
    groupNamesList,
    insuranceCompanyPlanTypeNames,
    insuranceCompanyPlanTypeNamesLoading,
    fetchInsuranceGroupNamesList,
    fetchInsuranceGroupPlanTypeNames,
    fetchInsuranceCompanyNamesList,
    insuranceCompanyNames,
  } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const [order, setOrder] = useState("desc");
  const [orderForIType, setOrderForIType] = useState("desc");
  const [orderForCType, setOrderForCType] = useState("desc");
  const [fileExportName, setFileExportName] = useState("Insurance Group");

  // eslint-disable-next-line no-unused-vars
  const [zipCodeOptions, setZipCodeOptions] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  //GroupInsurance Tab variables
  const [groupInsuranceId, setGroupInsuranceId] = useState("");
  const [groupInsuranceDetails, setGroupInsuranceDetails] = useState("");
  const [groupInsuranceName, setGroupInsuranceName] = useState("");
  const [groupInsuranceNameFilter, setGroupInsuranceNameFilter] = useState("");
  // const [editGroupInsuranceName, setEditGroupInsuranceName] = useState("");
  const [groupInsuranceSearchKeyword, setGroupInsuranceSearchKeyword] =
    useState("");
  const [groupInsuranceTotalSize, setGroupInsuranceTotalSize] = useState("");
  const [groupInsuranceSizePerPage, setGroupInsuranceSizePerPage] =
    useState(50);
  const [groupInsurancePage, setGroupInsurancePage] = useState(1);
  const [groupInsuranceOffset, setGroupInsuranceOffset] = useState(0);
  const [groupInsuranceSpinner, setGroupInsuranceSpinner] = useState(false);
  const [isGroupInsuranceDataEmpty, setIsGroupInsuranceDataEmpty] =
    useState(false);
  const [groupInsuranceReload, setGroupInsuranceReload] = useState(0);
  const [groupInsuranceDescription, setGroupInsuranceDescription] =
    useState("");
  // eslint-disable-next-line no-unused-vars
  const [groupInsuranceNamesDropDownList, setGroupInsuranceNamesDropDownList] =
    useState({});
  const [groupInsuranceFilter, setGroupInsuranceFilter] = useState(false);
  const [deleteInsuranceGroupModal, setDeleteInsuranceGroupModal] =
    useState(false);

  //Insurance Plan Tab variables
  const [insurancePlanTypeId, setInsurancePlanTypeId] = useState("");
  const [insurancePlanTypeDetails, setInsurancePlanTypeDetails] = useState("");
  const [insurancePlanTypeName, setInsurancePlanTypeName] = useState("");
  const [editInsurancePlanTypeName, setEditInsurancePlanTypeName] =
    useState("");
  const [insurancePlanTypeSearchKeyword, setInsurancePlanTypeSearchKeyword] =
    useState("");
  const [insurancePlanTypeTotalSize, setInsurancePlanTypeTotalSize] =
    useState("");
  const [insurancePlanTypeSizePerPage, setInsurancePlanTypeSizePerPage] =
    useState(50);
  const [insurancePlanTypePage, setInsurancePlanTypePage] = useState(1);
  const [insurancePlanTypeOffset, setInsurancePlanTypeOffset] = useState(0);
  const [insurancePlanTypeSpinner, setInsurancePlanTypeSpinner] = useState(0);
  const [isInsurancePlanTypeDataEmpty, setIsInsurancePlanTypeDataEmpty] =
    useState(false);
  const [insurancePlanTypeReload, setInsurancePlanTypeReload] = useState(0);
  const [insurancePlanTypeDescription, setInsurancePlanTypeDescription] =
    useState("");
  // eslint-disable-next-line no-unused-vars
  const [insurancePlanTypeDropDownList, setInsurancePlanTypeDropDownList] =
    useState({});
  const [insurancePlanTypeFilter, setInsurancePlanTypeFilter] = useState(false);
  const [deleteInsurancePlanTypeModal, setDeleteInsurancePlanTypeModal] =
    useState(false);

  //Insurance Company Tab variables
  const [insuranceCompanyId, setInsuranceCompanyId] = useState("");
  const [insuranceCompanyDetails, setInsuranceCompanyDetails] = useState("");
  const [insuranceCompanyPage, setInsuranceCompanyPage] = useState(1);
  const [insuranceCompanyName, setInsuranceCompanyName] = useState("");
  const [insuranceCompanySearchKeyword, setInsuranceCompanySearchKeyword] =
    useState("");
  const [insuranceCompanyTotalSize, setInsuranceCompanyTotalSize] =
    useState("");
  const [insuranceCompanySizePerPage, setInsuranceCompanySizePerPage] =
    useState(50);
  const [insuranceCompanyOffset, setInsuranceCompanyOffset] = useState(0);
  const [insuranceCompanySpinner, setInsuranceCompanySpinner] = useState(0);
  const [insuranceCompanyIsDataEmpty, setInsuranceCompanyIsDataEmpty] =
    useState(false);
  const [insuranceCompanyReload, setInsuranceCompanyReload] = useState(0);
  const [insuranceCompanyEmail, setInsuranceCompanyEmail] = useState("");
  const [insuranceCompanyZipCode, setInsuranceCompanyZipCode] = useState("");
  const [insuranceCompanyProviderName, setInsuranceCompanyProviderName] =
    useState("");
  const [insuranceCompanyPhone, setInsuranceCompanyPhone] = useState("");
  const [insuranceCompanyFax, setInsuranceCompanyFax] = useState("");
  const [insuranceCompanyAddress, setInsuranceCompanyAddress] = useState("");
  const [insuranceCompanyContactFirst, setInsuranceCompanyContactFirst] =
    useState("");
  const [insuranceCompanyContactMiddle, setInsuranceCompanyContactMiddle] =
    useState("");
  const [insuranceCompanyContactLast, setInsuranceCompanyContactLast] =
    useState("");
  const [groupDropDownOptions, setGroupDropDownOptions] = useState([]);
  const [editInsuranceCompanyGroupName, setEditInsuranceCompanyGroupName] =
    useState("");
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [editGroupInsuranceName, setEditGroupInsuranceName] = useState("");
  const [
    editGroupInsuranceCompanyPlanTypeName,
    setEditGroupInsuranceCompanyPlanTypeName,
  ] = useState([]);
  const [
    selectedInsuranceCompanyPlanTypeDetails,
    setSelectedInsuranceCompanyPlanTypeDetails,
  ] = useState([]);
  const [insuranceCompanyFilter, setInsuranceCompanyFilter] = useState("");
  const [modal, setModal] = useState(false);
  const [modalState, setModalState] = useState("initial");
  const [insurancePlanTypeModalState, setInsurancePlanTypeModalState] =
    useState("initial");
  const [insurancePlanTypeModal, setInsurancePlanTypeModal] = useState(false);
  const [insuranceCompanyModalState, setInsuranceCompanyModalState] =
    useState("initial");
  const [insuranceCompanyModal, setInsuranceCompanyModal] = useState(false);
  const [planTypes, setPlanTypes] = useState();
  const [sortColumnForInsuranceDetails, setSortColumnForInsuranceDetails] = useState("");
  const [sortOrderForInsuranceDetails, setSortOrderForInsuranceDetails] = useState("");
  const [sortColumnForInsurancePlanType, setSortColumnForInsurancePlanType] = useState("");
  const [sortOrderForInsurancePlanType, setSortOrderForInsurancePlanType] = useState("");
  const [sortColumnForInsuranceCompany, setSortColumnForInsuranceCompany] = useState("");
  const [sortOrderForInsuranceCompany, setSortOrderForInsuranceCompany] = useState("");
  let planTypeIds = [];
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;

  const getPlanTypeIdsForInsuranceCompany = () => {
    if (selectedInsuranceCompanyPlanTypeDetails) {
      selectedInsuranceCompanyPlanTypeDetails.map((item) => {
        planTypeIds.push(item.value);
        return setPlanTypes(planTypeIds);
      });
    } else {
      planTypeIds = [];
      setPlanTypes(planTypeIds);
    }
  };

  const validation = (cell) => {
    return cell ? cell : "N/A";
  };


  const createGroupOption = (data) => {
    var array = data;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setGroupDropDownOptions(options);
  };
  const handleTableChangeForGI = (type, newState) => {
    setGroupInsurancePage(newState.page);
    setGroupInsuranceSizePerPage(newState.sizePerPage);
    setGroupInsuranceOffset((newState.page - 1) * newState.sizePerPage);
    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumnForInsuranceDetails("ig.id");
      setSortOrderForInsuranceDetails("");
    } else {
      if (newState.sortOrder == order) { //eslint-disable-line
        setOrder("asc")
      }
      else {
        setOrder("desc")
      }
      setSortColumnForInsuranceDetails(newState.sortField);
      setSortOrderForInsuranceDetails(order);
      setGroupInsuranceReload(groupInsuranceReload + 1);
    }

  }

  const handleTableChange = (type, newState) => {
    if (activeTab === "1") {
      setGroupInsurancePage(newState.page);
      setGroupInsuranceSizePerPage(newState.sizePerPage);
      setGroupInsuranceOffset((newState.page - 1) * newState.sizePerPage);
      if (newState.sortField === null && newState.sortOrder === undefined) {
        setSortColumnForInsuranceDetails("ig.id");
        setSortOrderForInsuranceDetails("");
      } else {

        if (newState.sortOrder == order) { //eslint-disable-line
          setOrder("asc")
        }
        else {
          setOrder("desc")
        }
        setSortColumnForInsuranceDetails(newState.sortField);
        setSortOrderForInsuranceDetails(order);
        setGroupInsuranceReload(groupInsuranceReload + 1);
      }
    } else if (activeTab === "2") {
      setInsurancePlanTypePage(newState.page);
      setInsurancePlanTypeSizePerPage(newState.sizePerPage);
      setInsurancePlanTypeOffset((newState.page - 1) * newState.sizePerPage);
      if (newState.sortField === null && newState.sortOrder === undefined) {
        setSortColumnForInsurancePlanType("insurance_plan_type_id");
        setSortOrderForInsurancePlanType("");
      } else {

        if (newState.sortOrder == orderForIType) { //eslint-disable-line
          setOrderForIType("asc")
        }
        else {
          setOrderForIType("desc")
        }
        setSortColumnForInsurancePlanType(newState.sortField);
        setSortOrderForInsurancePlanType(orderForIType);
        setInsurancePlanTypeReload(insurancePlanTypeReload + 1);
      }
    } else if (activeTab === "3") {
      setInsuranceCompanyPage(newState.page);
      setInsuranceCompanySizePerPage(newState.sizePerPage);
      setInsuranceCompanyOffset((newState.page - 1) * newState.sizePerPage);
      if (newState.sortField === null && newState.sortOrder === undefined) {
        setSortColumnForInsuranceCompany("temp.planTypeID");
        setSortOrderForInsuranceCompany("");
      } else {
        if (newState.sortOrder == orderForCType) { //eslint-disable-line
          setOrderForCType("asc")
        }
        else {
          setOrderForCType("desc")
        }
        setSortColumnForInsuranceCompany(`temp.${newState.sortField}`);
        setSortOrderForInsuranceCompany(orderForCType);
        setInsuranceCompanyReload(insuranceCompanyReload + 1);
      }
    }
  };

  const createGroupInsuranceNameDropdown = () => {
    var array = groupInsuranceDetails;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.insurance_grp_name,
      };
    });
    setGroupInsuranceNamesDropDownList(options);
  };

  const createInsurancePlanTypeNameDropdown = () => {
    var array = insurancePlanTypeDetails;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.insurance_plan_type_name,
      };
    });
    setInsurancePlanTypeDropDownList(options);
  };

  const getAsyncOptions = (inputValue) => {
    fetchInsuranceGroupNamesList();
    if (insuranceGroupNames) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(insuranceGroupNames, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const getAsyncOptionsForInsurancePlanType = (inputValue) => {
    if (insuranceCompanyPlanTypeNames) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(insuranceCompanyPlanTypeNames, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const getAsyncOptionsForInsuranceCompany = (inputValue) => {
    if (insuranceCompanyNames) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(insuranceCompanyNames, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col>
            <Button
              className="money-button"
              outline
              onClick={() => {
                setModal(true);
                setModalState("edit");
                setGroupInsuranceDescription(row.insurance_grp_description);
                setGroupInsuranceName(row.insurance_grp_name);
                setGroupInsuranceId(row.insurance_grp_id);
              }}
              color="primary"
              title="Edit Insurance Group"
            >
              <FontAwesomeIcon icon={faEdit} size={"md"} />
            </Button>{" "}
            <Button
              className="delete-btn"
              outline
              onClick={() => {
                setDeleteInsuranceGroupModal(true);
                setGroupInsuranceId(row.insurance_grp_id);
                setGroupInsuranceName(row.insurance_grp_name);
              }}
              color="primary"
              title="Delete Record"
            >
              <FontAwesomeIcon
                icon={faTrash}
                fixedWidth
                className="align-middle"
                title="Delete Record"
              />
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const insuranceTypeActionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col>
            <Button
              className="money-button"
              outline
              onClick={() => {
                setInsurancePlanTypeModal(true);
                setInsurancePlanTypeModalState("edit");
                setModalState("edit");
                setEditInsurancePlanTypeName(row.insurance_plan_type_name);
                setInsurancePlanTypeDescription(
                  row.insurance_plan_type_description
                );
                setInsurancePlanTypeId(row.insurance_plan_type_id);
              }}
              color="primary"
              title="Edit Insurance plan type"
            >
              <FontAwesomeIcon icon={faEdit} size={"md"} />
            </Button>{" "}
            <Button
              className="delete-btn"
              outline
              onClick={() => {
                setDeleteInsurancePlanTypeModal(true);
                setInsurancePlanTypeId(row.insurance_plan_type_id);
                setEditInsurancePlanTypeName(row.insurance_plan_type_name);
              }}
              color="primary"
              title="Delete Record"
            >
              <FontAwesomeIcon
                icon={faTrash}
                fixedWidth
                className="align-middle"
                title="Delete Record"
              />
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const insuranceCompanyFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <Button
              className="money-button"
              outline
              onClick={() => {
                setInsuranceCompanyModal(true);
                setInsuranceCompanyModalState("edit");
                setInsuranceCompanyZipCode(row.ZipCode);
                setEditInsuranceCompanyGroupName(row.GroupName);
                setInsuranceCompanyProviderName(row.provider);
                setInsuranceCompanyPhone(row.phone);
                setInsuranceCompanyFax(row.fax);
                setInsuranceCompanyContactFirst(row.contactFirst);
                setInsuranceCompanyContactMiddle(row.contactMiddle);
                setInsuranceCompanyContactLast(row.contactLast);
                setEditGroupInsuranceName(row.insuranceGroupName);
                setEditGroupInsuranceCompanyPlanTypeName(
                  row.insurance_plan_types
                );
                setInsuranceCompanyAddress(row.address);
                setInsuranceCompanyId(row.insuranceCompanyId);
                setInsuranceCompanyEmail(row.email ? row.email : "");
              }}
              color="primary"
              title="Edit Insurance company"
            >
              <FontAwesomeIcon icon={faEdit} size={"md"} />
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const insuranceGroupColumns = [
    {
      dataField: "insurance_grp_name",
      text: "Name",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "insurance_grp_description",
      text: "Description",
      sort: true,
      formatter: validation,
    },
    {
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];

  const insuranceTypeColumns = [
    {
      dataField: "insurance_plan_type_name",
      text: "Name",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "insurance_plan_type_description",
      text: "Description",
      sort: true,
      formatter: validation,
    },
    {
      text: "Actions",
      sort: false,
      formatter: insuranceTypeActionsFormatter,
    },
  ];

  const insuranceCompanyColumns = [
    {
      dataField: "provider",
      text: "Provider",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      formatter: validation,
    },
    {
      text: "Actions",
      sort: false,
      formatter: insuranceCompanyFormatter,
    },
  ];

  const setInitialInsuranceCompanyPlanTypes = () => {
    // eslint-disable-next-line array-callback-return
    insuranceCompanyPlanTypeNames.map((insurancePlanTypes) => {
      // eslint-disable-line
      // eslint-disable-next-line array-callback-return
      editGroupInsuranceCompanyPlanTypeName.map((item) => {
        // eslint-disable-line
        if (insurancePlanTypes.value === Number(item.plan_type_id)) {
          setSelectedInsuranceCompanyPlanTypeDetails((oldArray) => [
            ...oldArray,
            insurancePlanTypes,
          ]);
        }
      });
    });
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  const fetchGroupInsuranceDetails = () => {
    setGroupInsuranceSpinner(true)
    const params = {
      group_insurance_name: groupInsuranceNameFilter
        ? groupInsuranceNameFilter.label
        : "",
      offset: groupInsuranceOffset,
      is_pagination: 1,
      sortOrder: sortOrderForInsuranceDetails ? sortOrderForInsuranceDetails : "desc",
      sortColumn: sortColumnForInsuranceDetails ? sortColumnForInsuranceDetails : "ig.id",
      record_limit: groupInsuranceSizePerPage ? groupInsuranceSizePerPage : "",
      search_keyword: groupInsuranceSearchKeyword
        ? groupInsuranceSearchKeyword
        : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-group-insurance-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setGroupInsuranceSpinner(false);
          setGroupInsuranceTotalSize(response.data.record_count);
          setGroupInsuranceDetails(response.data.data);
          localStorage.setItem("groupInsuranceOffset", groupInsuranceOffset);
        } else {
          setGroupInsuranceTotalSize(0);
          setGroupInsuranceSpinner(false);
          setGroupInsuranceDetails("");
          setIsGroupInsuranceDataEmpty(true);
        }
      })
      .catch((error) => {
        toastr.error("Something went wrong");
      });
  };

  const exportInsuranceGroup = () => {
    const params = {
      file_name: fileName ? fileName : "",
      group_insurance_name: groupInsuranceNameFilter
        ? groupInsuranceNameFilter.label
        : "",
      offset: groupInsuranceOffset ? groupInsuranceOffset : "0",
      is_pagination: "1",
      record_limit: "",
      search_keyword: groupInsuranceSearchKeyword
        ? groupInsuranceSearchKeyword
        : "",
      sortOrder: sortOrderForInsuranceDetails ? sortOrderForInsuranceDetails : "asc",
      sortColumn: sortColumnForInsuranceDetails ? sortColumnForInsuranceDetails : "ig.id",

    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/export-insurance-group-table-data/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const fetchInsurancePlanTypeDetails = () => {
    setInsurancePlanTypeSpinner(true)
    const params = {
      insurance_plan_type_name: insurancePlanTypeName
        ? insurancePlanTypeName.label
        : "",
      offset: insurancePlanTypeOffset,
      is_pagination: 1,
      sortOrder: sortOrderForInsurancePlanType ? sortOrderForInsurancePlanType : "asc",
      sortColumn: sortColumnForInsurancePlanType ? sortColumnForInsurancePlanType : "insurance_plan_type_id",

      record_limit: insurancePlanTypeSizePerPage
        ? insurancePlanTypeSizePerPage
        : "",
      search_keyword: insurancePlanTypeSearchKeyword
        ? insurancePlanTypeSearchKeyword
        : "",
    };
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/get-insurance-plan-type-details/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          setInsurancePlanTypeSpinner(false);
          setInsurancePlanTypeTotalSize(response.data.record_count);
          setInsurancePlanTypeDetails(response.data.data);
          localStorage.setItem("insuranceTypeOffset", groupInsuranceOffset);
        } else {
          setInsurancePlanTypeTotalSize(0);
          setInsurancePlanTypeSpinner(false);
          setInsurancePlanTypeDetails("");
          setIsInsurancePlanTypeDataEmpty(true);
        }
      })
      .catch((error) => {
        toastr.error("Something went wrong");
      });
  };
  const exportInsurancePlanType = () => {
    const params = {
      file_name: fileName ? fileName : "",
      insurance_plan_type_name: insurancePlanTypeName
        ? insurancePlanTypeName.label
        : "",
      offset: insurancePlanTypeOffset ? insurancePlanTypeOffset : "0",
      is_pagination: "1",
      record_limit: "",
      search_keyword: insurancePlanTypeSearchKeyword
        ? insurancePlanTypeSearchKeyword
        : "",
      sortOrder: sortOrderForInsurancePlanType ? sortOrderForInsurancePlanType : "asc",
      sortColumn: sortColumnForInsurancePlanType ? sortColumnForInsurancePlanType : "insurance_plan_type_id",

    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/export-insurance-plan-type-table-data/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const fetchInsuranceCompanyDetails = () => {
    setInsuranceCompanySpinner(true)
    const params = {
      record_limit: insuranceCompanySizePerPage
        ? insuranceCompanySizePerPage
        : "",
      offset: insuranceCompanyOffset,
      is_pagination: 1,
      sortOrder: sortOrderForInsuranceCompany ? sortOrderForInsuranceCompany : "asc",
      sortColumn: sortColumnForInsuranceCompany ? sortColumnForInsuranceCompany : "insuranceCompanyId",

      search_keyword: insuranceCompanySearchKeyword
        ? insuranceCompanySearchKeyword
        : "",
      provider_name: insuranceCompanyName ? insuranceCompanyName.label : "",
    };
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/get-insurance-company-details/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          setInsuranceCompanySpinner(false);
          setInsuranceCompanyTotalSize(response.data.record_count);
          setInsuranceCompanyDetails(response.data.data);
          localStorage.setItem("insuranceCompanyOffset", groupInsuranceOffset);
        } else {
          setInsuranceCompanyTotalSize(0);
          setInsuranceCompanySpinner(false);
          setInsuranceCompanyDetails("");
          setInsuranceCompanyIsDataEmpty(true);
        }
      })
      .catch((error) => {
        toastr.error("Something went wrong");
      });
  };
  const exportInsuranceCompany = () => {
    const params = {
      file_name: fileName ? fileName : "",
      record_limit: "",
      offset: insuranceCompanyOffset ? insuranceCompanyOffset : "0",
      is_pagination: "1",
      sortOrder: sortOrderForInsuranceCompany ? sortOrderForInsuranceCompany : "asc",
      sortColumn: sortColumnForInsuranceCompany ? sortColumnForInsuranceCompany : "sort_id",

      search_keyword: insuranceCompanySearchKeyword
        ? insuranceCompanySearchKeyword
        : "",
      provider_name: insuranceCompanyName ? insuranceCompanyName.label : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/export-insurance-company-table-data/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const clearSearchValues = () => {
    if (activeTab === "1") {
      setGroupInsuranceSearchKeyword("");
      setGroupInsuranceReload(groupInsuranceReload + 1);
    } else if (activeTab === "2") {
      setInsurancePlanTypeSearchKeyword("");
      setInsurancePlanTypeReload(insurancePlanTypeReload + 1);
    } else if (activeTab === "3") {
      setInsuranceCompanySearchKeyword("");
      setInsuranceCompanyReload(insuranceCompanyReload + 1);
    }
  };

  const clearGroupInsuranceFilter = () => {
    setGroupInsurancePage(1);
    setGroupInsuranceNameFilter("");
    setGroupInsuranceFilter(false);
    setGroupInsuranceReload(groupInsuranceReload + 1);
    setSortOrderForInsuranceDetails("");
  };

  const clearInsuranceTypeFilter = () => {
    setInsurancePlanTypePage(1);
    setInsurancePlanTypeName("");
    setInsurancePlanTypeFilter(false);
    setInsurancePlanTypeReload(insurancePlanTypeReload + 1);
  };

  const clearInsuranceCompanyFilter = () => {
    setInsuranceCompanyPage(1);
    setInsuranceCompanyName("");
    setInsuranceCompanyFilter(false);
    setInsuranceCompanyReload(insuranceCompanyReload + 1);
  };

  const filterButtonValidationGroupInsurance = () => {
    if (groupInsuranceNameFilter) {
      setGroupInsuranceFilter(true);
    } else {
      setGroupInsuranceFilter(false);
    }
  };
  const filterButtonValidationInsurancePlantype = () => {
    if (insurancePlanTypeName) {
      setInsurancePlanTypeFilter(true);
    } else {
      setInsurancePlanTypeFilter(false);
    }
  };
  const filterButtonValidationInsuranceCompany = () => {
    if (insuranceCompanyName) {
      setInsuranceCompanyFilter(true);
    } else {
      setInsuranceCompanyFilter(false);
    }
  };

  useEffect(() => {
    getPlanTypeIdsForInsuranceCompany();
  }, [selectedInsuranceCompanyPlanTypeDetails]); // eslint-disable-line

  useEffect(() => {
    if (
      insuranceCompanyPlanTypeNames &&
      editGroupInsuranceCompanyPlanTypeName
    ) {
      setInitialInsuranceCompanyPlanTypes();
    }
  }, [insuranceCompanyPlanTypeNames, editGroupInsuranceCompanyPlanTypeName]); // eslint-disable-line

  useEffect(() => {
    if (groupNamesList) {
      createGroupOption(groupNamesList);
    }
  }, [groupNamesList]); // eslint-disable-line

  useEffect(() => {
    if (
      activeTab === "1" &&
      insuranceGroupNames.length === 0 &&
      groupInsuranceReload > 0
    ) {
      fetchInsuranceGroupNamesList();
    }
  }, [activeTab, groupInsuranceReload]); // eslint-disable-line

  useEffect(() => {
    if (
      (activeTab === "2" && insuranceCompanyPlanTypeNames.length === 0) ||
      insurancePlanTypeReload > 0
    ) {
      fetchInsuranceGroupPlanTypeNames();
    }
  }, [activeTab, insurancePlanTypeReload]); // eslint-disable-line

  useEffect(() => {
    if (
      (activeTab === "3" && insuranceCompanyNames.length === 0) ||
      insuranceCompanyReload > 0
    ) {
      fetchInsuranceCompanyNamesList();
    }
  }, [activeTab, insuranceCompanyReload]); // eslint-disable-line

  /////////// Active Tab //////////////

  useEffect(() => {
    if (activeTab === "1") {
      fetchGroupInsuranceDetails();
    } else if (activeTab === "2") {
      fetchInsurancePlanTypeDetails();
    } else if (activeTab === "3") {
      fetchInsuranceCompanyDetails();
    }
  }, [activeTab]); // eslint-disable-line

  useEffect(() => {
    if (activeTab === "1" && groupInsuranceReload > 1) {
      fetchGroupInsuranceDetails();
      fetchInsuranceGroupNamesList()
    }
  }, [groupInsuranceReload]); // eslint-disable-line

  useEffect(() => {
    if (activeTab === "2") {
      fetchInsurancePlanTypeDetails();
    }
  }, [insurancePlanTypeReload]); // eslint-disable-line

  useEffect(() => {
    if (activeTab === "3") {
      fetchInsuranceCompanyDetails();
    }
  }, [insuranceCompanyReload]); // eslint-disable-line

  ///// drop down //////////////
  useEffect(() => {
    if (groupInsuranceDetails) {
      createGroupInsuranceNameDropdown();
    }
  }, [groupInsuranceDetails]); // eslint-disable-line

  useEffect(() => {
    if (insurancePlanTypeDetails) {
      createInsurancePlanTypeNameDropdown();
    }
  }, [insurancePlanTypeDetails]); // eslint-disable-line

  useEffect(() => {
    if (groupInsuranceNameFilter) {
      filterButtonValidationGroupInsurance();
    } else if (insurancePlanTypeName) {
      filterButtonValidationInsurancePlantype();
    } else if (insuranceCompanyName) {
      filterButtonValidationInsuranceCompany();
    }
  }, [groupInsuranceNameFilter, insurancePlanTypeName, insuranceCompanyName]); // eslint-disable-line

  useEffect(() => {
    if (activeTab === "1") {
      let previousOffset = localStorage.getItem("groupInsuranceOffset");
      if (
        groupInsuranceOffset !== previousOffset ||
        groupInsuranceSizePerPage
      ) {
        setGroupInsuranceReload(groupInsuranceReload + 1);
      }
    } else if (activeTab === "2") {
      let previousOffsetInsType = localStorage.getItem("insuranceTypeOffset");
      if (
        insurancePlanTypeOffset !== previousOffsetInsType ||
        insurancePlanTypeSizePerPage
      ) {
        setInsurancePlanTypeReload(insurancePlanTypeReload + 1);
      }
    } else if (activeTab === "3") {
      let previousOffsetInsType = localStorage.getItem(
        "insuranceCompanyOffset"
      );
      if (
        insuranceCompanyOffset !== previousOffsetInsType ||
        insuranceCompanySizePerPage
      ) {
        setInsuranceCompanyReload(insuranceCompanyReload + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupInsuranceOffset,
    groupInsuranceSizePerPage,
    insurancePlanTypeOffset,
    insurancePlanTypeSizePerPage,
    insuranceCompanyOffset,
    insuranceCompanySizePerPage,
  ]); // eslint-disable-line

  useEffect(() => {
    if (groupInsuranceSearchKeyword.length >= 2 && activeTab === "1") {
      setIsGlobalSearch(true);
    } else if (
      insurancePlanTypeSearchKeyword.length >= 2 &&
      activeTab === "2"
    ) {
      setIsGlobalSearch(true);
    } else if (insuranceCompanySearchKeyword.length >= 2 && activeTab === "3") {
      setIsGlobalSearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupInsuranceSearchKeyword,
    insurancePlanTypeSearchKeyword,
    insuranceCompanySearchKeyword,
  ]); // eslint-disable-line

  useEffect(() => {
    if (groupInsuranceSearchKeyword.length <= 0 && activeTab === "1") {
      setIsGlobalSearch(true);
    } else if (
      insurancePlanTypeSearchKeyword.length <= 0 &&
      activeTab === "2"
    ) {
      setIsGlobalSearch(true);
    } else if (insuranceCompanySearchKeyword.length <= 0 && activeTab === "3") {
      setIsGlobalSearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupInsuranceSearchKeyword,
    insurancePlanTypeSearchKeyword,
    insuranceCompanySearchKeyword,
  ]);

  useEffect(() => {
    if (isGlobalSearch && activeTab === "1") {
      setGroupInsuranceOffset(0);
      setGroupInsuranceSizePerPage(50);
      setGroupInsuranceReload(groupInsuranceReload + 1);
      setIsGlobalSearch(false);
    } else if (isGlobalSearch && activeTab === "2") {
      setInsurancePlanTypeOffset(0);
      setInsurancePlanTypeSizePerPage(50);
      setInsurancePlanTypeReload(insurancePlanTypeReload + 1);
      setIsGlobalSearch(false);
    } else if (isGlobalSearch && activeTab === "3") {
      setInsuranceCompanyOffset(0);
      setInsuranceCompanySizePerPage(50);
      setInsuranceCompanyReload(insuranceCompanyReload + 1);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Insurance
            {activeTab === "1" && (
              <Button
                className="Add-btn pull-right"
                onClick={() => {
                  setModal(true);
                  setModalState("add");
                }}
              >
                Add Insurance Group
              </Button>
            )}
            {activeTab === "2" && (
              <Button
                className="Add-btn pull-right"
                onClick={() => {
                  setInsurancePlanTypeModal(true);
                  setInsurancePlanTypeModalState("add");
                }}
              >
                Add Insurance Type
              </Button>
            )}
            {activeTab === "3" && (
              <Button
                className="Add-btn pull-right"
                onClick={() => {
                  setInsuranceCompanyModal(true);
                  setInsuranceCompanyModalState("add");
                }}
              >
                Add Insurance Company
              </Button>
            )}
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Insurance</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>
      <Card>
        <CardBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                  setFileExportName("Insurance Group");
                  clearInsuranceTypeFilter()
                  clearInsuranceCompanyFilter()

                }}
                style={{ cursor: "pointer" }}
              >
                Insurance Group
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                  setFileExportName("Insurance Plan Type");
                  clearGroupInsuranceFilter()
                  clearInsuranceCompanyFilter()
                }}
                style={{ cursor: "pointer" }}
              >
                Insurance Plan Type
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                  setFileExportName("Insurance Company");
                  clearGroupInsuranceFilter()
                  clearInsuranceTypeFilter()

                }}
                style={{ cursor: "pointer" }}
              >
                Insurance Company
              </NavLink>
            </NavItem>
          </Nav>
          <br />
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Container fluid>
                <Row>
                  <Col
                    md={{
                      size: 4,
                    }}
                  >
                    <Label>Insurance Group Name</Label>
                    <AsyncSelect
                      cacheOptions
                      isClearable
                      isSearchable
                      value={groupInsuranceNameFilter}
                      placeholder={"Select Insurance Group Name"}
                      onChange={(event) => {
                        setGroupInsuranceNameFilter(event);
                      }}
                      loadOptions={(inputValue) => getAsyncOptions(inputValue)}
                    />
                  </Col>

                  <Col
                    md={{
                      size: 4,
                    }}
                  >
                    <Button
                      className="icd-go-buttons"
                      onClick={() => {
                        setGroupInsuranceTotalSize(0);
                        groupInsuranceOffset === 0
                          ? setGroupInsuranceReload(groupInsuranceReload + 1)
                          : setGroupInsuranceOffset(0);
                        setGroupInsurancePage(1);
                      }}
                      disabled={!groupInsuranceFilter ? true : false}
                      title={
                        !groupInsuranceFilter
                          ? "Choose at least one item to search "
                          : ""
                      }
                    >
                      Go
                    </Button>
                    <Button
                      className="icd-reset-buttons"
                      onClick={() => {
                        clearGroupInsuranceFilter();
                      }}
                      disabled={!groupInsuranceFilter ? true : false}
                      title={!groupInsuranceFilter ? "Nothing To Clear" : ""}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Container>
              <div className="groups-search">
                <SearchBarComponent
                  searchKeyword={groupInsuranceSearchKeyword}
                  setSearchKeyword={setGroupInsuranceSearchKeyword}
                  totalSize={groupInsuranceTotalSize}
                  clearValues={clearSearchValues}
                />
              </div>
              {!groupInsuranceSpinner ? (
                <RemotePagination
                  data={groupInsuranceDetails ? groupInsuranceDetails : ""}
                  columns={insuranceGroupColumns}
                  page={groupInsurancePage}
                  sizePerPage={groupInsuranceSizePerPage}
                  FileExportName={fileExportName}
                  exportTableData={
                    activeTab === "1" ? exportInsuranceGroup : ""
                  }
                  totalSize={
                    groupInsuranceTotalSize ? groupInsuranceTotalSize : ""
                  }
                  isGlobalSearch={isGlobalSearch}
                  setIsGlobalSearch={setIsGlobalSearch}
                  searchKeyword={groupInsuranceSearchKeyword}
                  setSearchKeyword={setGroupInsuranceSearchKeyword}


                  reloadVariable={groupInsuranceReload}
                  isDataEmpty={isGroupInsuranceDataEmpty}
                  onTableChange={(type, newState) => {
                    handleTableChangeForGI(type, newState);
                  }}
                />
              ) : (
                <Spinner />
              )}
            </TabPane>
            <TabPane tabId="2">
              <Container fluid>
                <Row>
                  <Col
                    md={{
                      size: 4,
                    }}
                  >
                    <Label>Insurance Plan Type Name</Label>
                    <AsyncSelect
                      cacheOptions
                      isClearable
                      isSearchable
                      value={insurancePlanTypeName}
                      placeholder={"Select Insurance Plan Type Name"}
                      onChange={(event) => {
                        setInsurancePlanTypeName(event);
                      }}
                      loadOptions={(inputValue) =>
                        getAsyncOptionsForInsurancePlanType(inputValue)
                      }
                    />
                  </Col>

                  <Col
                    md={{
                      size: 4,
                    }}
                  >
                    <Button
                      className="icd-go-buttons"
                      onClick={() => {
                        setInsurancePlanTypeTotalSize(0);
                        insurancePlanTypeOffset === 0
                          ? setInsurancePlanTypeReload(
                            insurancePlanTypeReload + 1
                          )
                          : setInsurancePlanTypeOffset(0);
                        setInsurancePlanTypePage(1);
                      }}
                      disabled={!insurancePlanTypeFilter ? true : false}
                      title={
                        !insurancePlanTypeFilter
                          ? "Choose at least one item to search "
                          : ""
                      }
                    >
                      Go
                    </Button>
                    <Button
                      className="icd-reset-buttons"
                      onClick={() => {
                        clearInsuranceTypeFilter();
                      }}
                      disabled={!insurancePlanTypeFilter ? true : false}
                      title={!insurancePlanTypeFilter ? "Nothing To Clear" : ""}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Container>

              <div className="groups-search">
                <SearchBarComponent
                  searchKeyword={insurancePlanTypeSearchKeyword}
                  setSearchKeyword={setInsurancePlanTypeSearchKeyword}
                  totalSize={insurancePlanTypeTotalSize}
                  clearValues={clearSearchValues}
                />
              </div>
              {!insurancePlanTypeSpinner &&
                !insuranceCompanyPlanTypeNamesLoading ? (
                <RemotePagination
                  data={
                    insurancePlanTypeDetails ? insurancePlanTypeDetails : ""
                  }
                  columns={insuranceTypeColumns}
                  page={insurancePlanTypePage}
                  sizePerPage={insurancePlanTypeSizePerPage}
                  FileExportName={fileExportName}
                  exportTableData={
                    activeTab === "2" ? exportInsurancePlanType : ""
                  }
                  totalSize={
                    insurancePlanTypeTotalSize ? insurancePlanTypeTotalSize : ""
                  }
                  searchKeyword={insurancePlanTypeSearchKeyword}
                  setSearchKeyword={setInsurancePlanTypeSearchKeyword}
                  reloadVariable={insurancePlanTypeReload}
                  isDataEmpty={isInsurancePlanTypeDataEmpty}
                  onTableChange={(type, newState) => {
                    handleTableChange(type, newState);
                  }}
                />
              ) : (
                <Spinner />
              )}
            </TabPane>
            <TabPane tabId="3">
              <Container fluid>
                <Row>
                  <Col
                    md={{
                      size: 4,
                    }}
                  >
                    <Label>Insurance Provider Name</Label>
                    <AsyncSelect
                      cacheOptions
                      isClearable
                      isSearchable
                      value={insuranceCompanyName}
                      placeholder={"Select Insurance Provider Name"}
                      onChange={(event) => {
                        setInsuranceCompanyName(event);
                      }}
                      loadOptions={(inputValue) =>
                        getAsyncOptionsForInsuranceCompany(inputValue)
                      }
                    />
                  </Col>

                  <Col
                    md={{
                      size: 4,
                    }}
                  >
                    <Button
                      className="icd-go-buttons"
                      onClick={() => {
                        setInsuranceCompanyTotalSize(0);
                        insuranceCompanyOffset === 0
                          ? setInsuranceCompanyReload(
                            insuranceCompanyReload + 1
                          )
                          : setInsuranceCompanyOffset(0);
                        setInsuranceCompanyPage(1);
                      }}
                      disabled={!insuranceCompanyFilter ? true : false}
                      title={
                        !insuranceCompanyFilter
                          ? "Choose at least one item to search "
                          : ""
                      }
                    >
                      Go
                    </Button>
                    <Button
                      className="icd-reset-buttons"
                      onClick={() => {
                        clearInsuranceCompanyFilter();
                      }}
                      disabled={!insuranceCompanyFilter ? true : false}
                      title={!insuranceCompanyFilter ? "Nothing To Clear" : ""}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Container>

              <div className="groups-search">
                <SearchBarComponent
                  searchKeyword={insuranceCompanySearchKeyword}
                  setSearchKeyword={setInsuranceCompanySearchKeyword}
                  totalSize={insuranceCompanyTotalSize}
                  clearValues={clearSearchValues}
                />
              </div>
              {!insuranceCompanySpinner &&
                !groupListLoading &&
                !insuranceCompanyPlanTypeNamesLoading ? (
                <RemotePagination
                  data={insuranceCompanyDetails ? insuranceCompanyDetails : ""}
                  columns={insuranceCompanyColumns}
                  page={insuranceCompanyPage}
                  sizePerPage={insuranceCompanySizePerPage}
                  FileExportName={fileExportName}
                  exportTableData={
                    activeTab === "3" ? exportInsuranceCompany : ""
                  }
                  totalSize={
                    insuranceCompanyTotalSize ? insuranceCompanyTotalSize : ""
                  }
                  searchKeyword={insuranceCompanySearchKeyword}
                  setSearchKeyword={setInsuranceCompanySearchKeyword}
                  reloadVariable={insuranceCompanyReload}
                  isDataEmpty={insuranceCompanyIsDataEmpty}
                  onTableChange={(type, newState) => {
                    handleTableChange(type, newState);
                  }}
                />
              ) : (
                <Spinner />
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>

      <InsuranceGroupModal
        modal={modal}
        setModal={setModal}
        modalState={modalState}
        setModalState={setModalState}
        groupInsuranceName={groupInsuranceName}
        setGroupInsuranceName={setGroupInsuranceName} setSortOrderForInsuranceDetails
        groupInsuranceDescription={groupInsuranceDescription}
        setGroupInsuranceDescription={setGroupInsuranceDescription}
        groupInsuranceReload={groupInsuranceReload}
        setGroupInsuranceReload={setGroupInsuranceReload}
        groupInsuranceId={groupInsuranceId}
      />

      <InsuranceGroupDeleteModal
        setDeleteInsuranceGroupModal={setDeleteInsuranceGroupModal}
        deleteInsuranceGroupModal={deleteInsuranceGroupModal}
        groupInsuranceName={groupInsuranceName}
        groupInsuranceReload={groupInsuranceReload}
        setGroupInsuranceReload={setGroupInsuranceReload}
        groupInsuranceId={groupInsuranceId}
      />

      <InsurancePlanTypeModal
        insurancePlanTypeModal={insurancePlanTypeModal}
        setInsurancePlanTypeModal={setInsurancePlanTypeModal}
        insurancePlanTypeModalState={insurancePlanTypeModalState}
        setInsurancePlanTypeModalState={setInsurancePlanTypeModalState}
        editInsurancePlanTypeName={editInsurancePlanTypeName}
        setEditInsurancePlanTypeName={setEditInsurancePlanTypeName}
        insurancePlanTypeDescription={insurancePlanTypeDescription}
        setInsurancePlanTypeDescription={setInsurancePlanTypeDescription}
        insurancePlanTypeId={insurancePlanTypeId}
        setInsurancePlanTypeId={setInsurancePlanTypeId}
        insurancePlanTypeReload={insurancePlanTypeReload}
        setInsurancePlanTypeReload={setInsurancePlanTypeReload}
      />

      <InsurancePlanTypeDeleteModal
        deleteInsurancePlanTypeModal={deleteInsurancePlanTypeModal}
        setDeleteInsurancePlanTypeModal={setDeleteInsurancePlanTypeModal}
        insurancePlanTypeId={insurancePlanTypeId}
        insurancePlanTypeReload={insurancePlanTypeReload}
        setInsurancePlanTypeReload={setInsurancePlanTypeReload}
        editInsurancePlanTypeName={editInsurancePlanTypeName}
      />
      <InsuranceCompanyModal
        insuranceCompanyModal={insuranceCompanyModal}
        setInsuranceCompanyModal={setInsuranceCompanyModal}
        insuranceCompanyModalState={insuranceCompanyModalState}
        setInsuranceCompanyModalState={setInsuranceCompanyModalState}
        zipCodeOptions={zipCodeOptions}
        insuranceCompanyZipCode={insuranceCompanyZipCode}
        groupOptions={groupDropDownOptions}
        editInsuranceCompanyGroupName={editInsuranceCompanyGroupName}
        setEditInsuranceCompanyGroupName={setEditInsuranceCompanyGroupName}
        selectedGroupName={selectedGroupName}
        setSelectedGroupName={setSelectedGroupName}
        insuranceGroupAsync={getAsyncOptions}
        editGroupInsuranceName={editGroupInsuranceName}
        setEditGroupInsuranceName={setEditGroupInsuranceName}
        insuranceGroupNames={insuranceGroupNames}
        setInsuranceGroupNames={setInsuranceGroupNames}
        insuranceCompanyAddress={insuranceCompanyAddress}
        insuranceCompanyProviderName={insuranceCompanyProviderName}
        setInsuranceCompanyProviderName={setInsuranceCompanyProviderName}
        insuranceCompanyPhone={insuranceCompanyPhone}
        setInsuranceCompanyPhone={setInsuranceCompanyPhone}
        insuranceCompanyFax={insuranceCompanyFax}
        setInsuranceCompanyFax={setInsuranceCompanyFax}
        setInsuranceCompanyAddress={setInsuranceCompanyAddress}
        insuranceCompanyContactFirst={insuranceCompanyContactFirst}
        setInsuranceCompanyContactFirst={setInsuranceCompanyContactFirst}
        insuranceCompanyContactMiddle={insuranceCompanyContactMiddle}
        setInsuranceCompanyContactMiddle={setInsuranceCompanyContactMiddle}
        insuranceCompanyContactLast={insuranceCompanyContactLast}
        setInsuranceCompanyContactLast={setInsuranceCompanyContactLast}
        insuranceCompanyEmail={insuranceCompanyEmail}
        setInsuranceCompanyEmail={setInsuranceCompanyEmail}
        selectedInsuranceCompanyPlanTypeDetails={
          selectedInsuranceCompanyPlanTypeDetails
        }
        setSelectedInsuranceCompanyPlanTypeDetails={
          setSelectedInsuranceCompanyPlanTypeDetails
        }
        insuranceCompanyPlanTypeNames={insuranceCompanyPlanTypeNames}
        insuranceCompanyReload={insuranceCompanyReload}
        setInsuranceCompanyReload={setInsuranceCompanyReload}
        planTypes={planTypes}
        setInsuranceCompanyZipCode={setInsuranceCompanyZipCode}
        insuranceCompanyId={insuranceCompanyId}
        setEditGroupInsuranceCompanyPlanTypeName={
          setEditGroupInsuranceCompanyPlanTypeName
        }
      />
    </div>
  );
};

export default Insurance;
