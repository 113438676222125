import React, { useState,useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Input,
  
} from "reactstrap";
import { toastr } from 'react-redux-toastr';
import AxiosInter from './../../AxiosInter.js';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator"; 
import moment from "moment";
import Cookie from 'js-cookie';
import MomentConfig from "../../common/MomentConfig";
import FacilityAssignationTagFilters from "../../newComponents/utilities/FacilityAssignationTagFilters.js";
import NewFacilityAssignationTagModal from "../../newComponents/utilities/NewFacilityAssignationTagModal";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


const FacilityAssignationDataTable = (props) => {

  const [blankStateStatus, setBlankStateStatus] = useState(false);
  const fileExportName = 'FacilityAssignation'
  const assignFacility = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  
  const {
    data,
    columns,
    onTableChange,
    page,
    sizePerPage,
    setReloadForFacilityAssignationTag,
    reloadForFacilityAssignationTag,
    totalSize,
    setOffset,
    setTotalSize,
    offset,
    facilityAssignationTagGroupName,
    setFacilityAssignationTagGroupName,
    facilityAssignationTagFacilityName,
    setFacilityAssignationTagFacilityName,
    facilityAssignationTagAppUser_Name,
    setFacilityAssignationTagAppUser_Name,
    facilityAssignationTagTableOrderCount,
    setFacilityAssignationTagTableOrderCount,
    searchKeyword,
    setSearchKeyword,
    groupName,
    facilityName,
    appUser_name,
    tableOrderCount

  } = props;
  const [numberOfRecords, setNumberOfRecords] = useState("NaN");
  const [NewFacilityAssignationTagAddModal, setNewFacilityAssignationTagAddModal]=useState(false);
  const[isGlobalSearch,setIsGlobalSearch] = useState(false)
  const MyExportCSV = () => {

    const exportDatatable = () => {
    
      const params =
      {
        "file_name":assignFacility?assignFacility:"",
        "groupName": groupName ? groupName.label: "",
        "FacilityName": facilityName ? facilityName.label: "",
        "UserName" : appUser_name ? appUser_name.label: "",
        "OrderNumber": tableOrderCount ? tableOrderCount.value:"",
        "OFFSET": offset,
        "is_pagination": "1",
        "record_limit":sizePerPage ? sizePerPage : "",
        "searchKeyword": searchKeyword ? searchKeyword : "",
        sortOrder:"",
        sortColumn:"", 
      }
  
      AxiosInter.post(`${API_ENDPOINT}/admin-api/export-sender-assignee-allocation-list/`,params,{
          headers: {
             
            Authorization: API_TOKEN,
  
          },
          responseType: "blob"
        })
          .then((response) => { 
            if (response.status === 200) {
              var fileDownload = require("js-file-download");
              fileDownload(
                response.data ,`${assignFacility}`,
                response["headers"]["x-suggested-filename"]
              );
              toastr.success(
                "Success",
                "Export successful"
              );
            }          
          })
          .catch((error) => {
            toastr.error(
                  "Failed",
                  "Something went wrong"
            );
          });
      };
    
    return (
      <div>
        <button className="btn btn-export mt-2" onClick={exportDatatable}>
          Export
        </button>
      </div>
    );
  };

  
  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  useEffect(() => {
    setNumberOfRecords(totalSize);
  }, [totalSize]);

  useEffect(() => {
    if (data.length === 0) {
      setBlankStateStatus(true);
    } else {
      setBlankStateStatus(false);
    }
  }, [props.data.length]); // eslint-disable-line react-hooks/exhaustive-deps

  
   useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]); 
  
  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      setTotalSize(0);
      setReloadForFacilityAssignationTag(reloadForFacilityAssignationTag + 1);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line react-hooks/exhaustive-deps


  return ( 
    <div className="facility_assignaion">
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns}
        search
        exportCSV={{
          assignFacility: `Equipo-Facility-Assignation-Tag-Export-${moment().format(
            MomentConfig.MOMENT_FORMAT_DATEANDTIME
          )}.csv`,
        }}
      >{(props) => (
          <div>
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <FacilityAssignationTagFilters
                      facilityAssignationTagGroupName={facilityAssignationTagGroupName}
                      setFacilityAssignationTagGroupName={setFacilityAssignationTagGroupName}         
                      facilityAssignationTagFacilityName={facilityAssignationTagFacilityName}
                      setFacilityAssignationTagFacilityName={setFacilityAssignationTagFacilityName}
                      facilityAssignationTagAppUser_Name={facilityAssignationTagAppUser_Name}
                      setFacilityAssignationTagAppUser_Name={setFacilityAssignationTagAppUser_Name}
                      facilityAssignationTagTableOrderCount={facilityAssignationTagTableOrderCount}
                      setFacilityAssignationTagTableOrderCount={setFacilityAssignationTagTableOrderCount}
                      reloadForFacilityAssignationTag={reloadForFacilityAssignationTag}
                      setReloadForFacilityAssignationTag={setReloadForFacilityAssignationTag}
                    />
                  </Col>
                </Row>
                <div className="separator" />
                <Row>
                  <Col xs={2}>
                    <div className="number-of-records">
                      <p>Number of records: <strong>{numberOfRecords}</strong></p>
                    </div> 
                  </Col>
                  <Col className="">
                    <div className="form-inline justify-content-end"> 
                       <div className="form-group mr-2">
                        <Input
                          className="search-box-new"
                          type="text"
                          value={searchKeyword}
                          onInput={(e) => setSearchKeyword(e.target.value)}
                          placeholder="Search"
                        />
                      </div> 
                      <Button
                        className="clear-search-box"
                        onClick={() => {
                          setSearchKeyword("");
                          setReloadForFacilityAssignationTag(
                            reloadForFacilityAssignationTag + 1
                          );
                        }}
                      >clear</Button> 
                      <div className="export-button ml-2">
                        <MyExportCSV {...props.csvProps} /> 
                      </div>
                      <Button
                        className="referral-assignee-tag-add-btn ml-2 pull-right"
                        onClick={() => {
                          setNewFacilityAssignationTagAddModal(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          fixedWidth
                          className="align-middle"
                          title="See Fulfillers"
                        />Facility Assignation</Button>
                    </div>
                  </Col>
                </Row>
                <div className="separator" />
                <div className="float-right pull-right"></div>
              </CardHeader>
              <CardBody className="patient-list">
                {blankStateStatus ? (
                  blankState
                ) : (
                  <BootstrapTable
                    bootstrap4
                    // {...props.baseProps}
                    bordered={false}
                    hover
                    remote
                    keyField="id"
                    data={data}
                    columns={columns}
                    className="react-bootstrap-table"
                    pagination={paginationFactory({
                      page: page,
                      sizePerPage: sizePerPage,
                      sizePerPageList: [25,50,100,150,200],
                      totalSize
                      
                    })}
                    onTableChange={onTableChange}
                  />
                )}
              </CardBody>
            </Card>
          </div>
        )}
      </ToolkitProvider>
      
      

      <NewFacilityAssignationTagModal
        NewFacilityAssignationTagAddModal={NewFacilityAssignationTagAddModal}
        setNewFacilityAssignationTagAddModal={setNewFacilityAssignationTagAddModal}
        reloadForFacilityAssignationTag={reloadForFacilityAssignationTag}
        setReloadForFacilityAssignationTag={setReloadForFacilityAssignationTag}
        facilityAssignationTagGroupName={facilityAssignationTagGroupName}
        setFacilityAssignationTagGroupName={setFacilityAssignationTagGroupName}
        facilityAssignationTagFacilityName={facilityAssignationTagFacilityName}
        setFacilityAssignationTagFacilityName={setFacilityAssignationTagFacilityName}
        facilityAssignationTagAppUser_Name={facilityAssignationTagAppUser_Name}
        setFacilityAssignationTagAppUser_Name={setFacilityAssignationTagAppUser_Name}
        facilityAssignationTagTableOrderCount={facilityAssignationTagTableOrderCount}
        setFacilityAssignationTagTableOrderCount={setFacilityAssignationTagTableOrderCount}
      /> 
      
    </div>
  );
}

export default FacilityAssignationDataTable;
