import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'

const RemovePhyByFacilityModalProviderSpecialityRank = (props) => {
  const {
    providerID,
    providerNameID,
    facilityID,
    facilityNameID,
    fulfillerID,
    fulfillerNameID,
    removePhysicianByFacilityModal,
    setRemovePhysicianByFacilityModal,
    reload, setReload,
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const toggle = () => {
    setRemovePhysicianByFacilityModal(!removePhysicianByFacilityModal);
  };

  const confirmation = () => {
    
    const updatedProvider = {
      
      provider_id: providerID ? providerID :"",
      facility_ID: facilityID ? facilityID :'',
      fulfiller_id: fulfillerID ?fulfillerID : ''
      
    };
    

    AxiosInter.post( `${API_ENDPOINT}/admin-api/update-fulfiller-for-provider-given/`, updatedProvider,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
        if (res.status === 201) {
          setReload(reload+1)
          setRemovePhysicianByFacilityModal(!removePhysicianByFacilityModal);
          toastr.success('Records Updated', 'Successfully updated for given provider and facility');
        }
        else if (res.status === 400) {
          setRemovePhysicianByFacilityModal(!removePhysicianByFacilityModal);
          toastr.error('Update Failed', 'No record found for selected Provider, Facility and Fulfiller');
        }
      })
      .catch((error) => {
        // console.log(error);
        setRemovePhysicianByFacilityModal(!removePhysicianByFacilityModal);
        toastr.error('Update Failed', 'No record found for selected physician, facility and fulfiller');
      });
  };

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={removePhysicianByFacilityModal} >
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Update Records</ModalHeader>
        <ModalBody>
            <div>
              Are you sure you want to update the records for <strong>Provider: {providerNameID ? providerNameID : 'N/A'}, Facility: {facilityNameID ? facilityNameID : 'N/A'} and Fulfiller: {fulfillerNameID ? fulfillerNameID : 'N/A'}</strong> ?
            </div>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RemovePhyByFacilityModalProviderSpecialityRank;
