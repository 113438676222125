import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";

import AsyncSelect from "react-select/async";
import Cookie from 'js-cookie';

const EditAllServiceCategoryModal = (props) => {
  const {
    editServiceCategoryID,
    setUserDataReload,
    userDataReload,
    editServiceCategoryModal,
    setEditServiceCategoryModal,
    editGroupName,
    setEditGroupName,
    editCategoryName,
    setEditCategoryName,
    editCategoryDescription,
    setEditCategoryDescription,
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [spinner, setSpinner] = useState(false);
  const [updateButtonStatus, setUpdateButtonStatus] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");

  const toggle = () => {
    setEditServiceCategoryModal(!editServiceCategoryModal);
  };

  const confirmation = () => {
    if (!editGroupName) {
      toastr.error("Name Field Is Required", "Please enter the group name!");
    }

    if (!editCategoryName) {
      toastr.error("category Field Is Required", "Please enter the category!");
    }

    if (editGroupName && editCategoryName) {
      updateHabitCall();
    }
  };

  const updateHabitCall = () => {
    setSpinner(true);

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-new-service-category/`,
        {
          Service_Category_ID: editServiceCategoryID,
          groupID: editGroupName ? editGroupName.value : "",
          name: editCategoryName ? editCategoryName.label : "",
          description: editCategoryDescription ? editCategoryDescription : "",
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setEditServiceCategoryModal(!editServiceCategoryModal);
        setUserDataReload(userDataReload + 1);
        toastr.success(
          "Successfully Updated",
          "service Category details successfully updated"
        );
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error("Update Failed", "Please try again");
      });
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handleGroupNameChange = (value) => {
    setEditGroupName(value);
  };
  const handleCategoryChange = (value) => {
    setEditCategoryName(value);
  };

  const categoryloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-Servicecategory-list/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const groupNameloadOptions = (inputValue) => {
    if(inputValue.length >= 4){
        const searchParams = new URLSearchParams ({name: inputValue});
        return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
         {
         headers: {
           Authorization: API_TOKEN,
         }
       })
     .then((response) => response.data.data)
     .catch((error) => {
        throw error;
     });
    }
     
  };

  //   const fetchGroupById = (editGroupName) => {

  //     axiosinter.get(`${API_ENDPOINT}/admin-api/get-m-centerid-list/${Number(editCenter)}`, {
  //     headers: {
  //         Authorization: API_TOKEN,
  //     },
  //     })
  //     .then(response => {

  //         if (response.data.data.length > 0) {
  //            setCenter(response.data.data)
  //         }
  //     })
  //     .catch(error => {

  //         toastr.error('Error','Something went wrong in region listing !!')

  //     });
  //   };

  const disableUpdateButton = () => {
    if (editGroupName && editCategoryName) {
      setUpdateButtonStatus(false);
    } else {
      setUpdateButtonStatus(true);
    }
  };

  useEffect(() => {
    disableUpdateButton();
  }, [editGroupName, editCategoryName]); // eslint-disable-line

  //    useEffect(()=>{
  //     if(editGroupName !== '' && editGroupName !== undefined){

  //         handleGroupNameChange(inputValue)
  //     }
  //   },[editGroupName])   // eslint-disable-line

  return (
    <div>
      <Modal
        isOpen={editServiceCategoryModal}
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit Service Category{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          {spinner ? <Spinner /> : ""}
          <FormGroup>
            <Row>
              <Col sm={6}>
                <Label>
                  Group Name :<span className="required">*</span>
                </Label>
                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  type="text"
                  name="groupName"
                  placeholder="Search with at least 4 characters."
                  value={editGroupName}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  validate={{
                    maxLength: { value: 120 },
                  }}
                  onInputChange={handleInputChange}
                  onChange={handleGroupNameChange}
                  loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
                />
              </Col>
              <Col sm={6}>
                <Label>
                  Service Category Name:<span className="required">*</span>
                </Label>

                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  type="text"
                  name="name"
                  placeholder="Search with at least 4 characters."
                  value={editCategoryName}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  validate={{
                    maxLength: { value: 120 },
                  }}
                  onInputChange={handleInputChange}
                  onChange={handleCategoryChange}
                  loadOptions={(inputValue) => categoryloadOptions(inputValue)}
                />
              </Col>
              <Col sm={6}>
                <Label>Description</Label>
                <Input
                  id="Category Description"
                  type="textarea"
                  rows={4}
                  cols={2}
                  value={editCategoryDescription}
                  placeholder="Enter the description"
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditCategoryDescription(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button
            onClick={() => {
              confirmation();
            }}
            disabled={updateButtonStatus}
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditAllServiceCategoryModal;
