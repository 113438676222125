import Cookie from "js-cookie"
import React, { useEffect, useState } from 'react'
import AsyncSelect from "react-select/async"
import { Button, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import AxiosInter from '../../AxiosInter.js'
import { toastr } from "react-redux-toastr"

function EditHIMUser({ clickedRow, isEditModal, setIsEditModal, toggleEditModal, setReload }) {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    const API_TOKEN = Cookie.get("access_token_admin")

    const [populatedGroupName, setPopulatedGroupName] = useState([])
    const [populatedAppuser, setPopulatedAppuser] = useState([])

    const [populatedFacility, setPopulatedFacility] = useState([])
    const [loader, setLoader] = useState(false)

    const handleGroupChange = (value) => {
        setPopulatedGroupName([value])
    }

    const handleAppUserChange = (value) => {
        setPopulatedAppuser([value])
    }

    const handleFacilityChange = (value) => {
        setPopulatedFacility([value])
    }

    const populateGroupName = (inputValue) => {
        setLoader(true)
        const searchParams = new URLSearchParams({ name: inputValue })
        return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
            {
                headers: {
                    Authorization: API_TOKEN,
                },
            }
        )
            .then((response) => {
                setPopulatedGroupName(response.data.data)
                setLoader(false)
            })
            .catch((error) => {
                setLoader(false)
                throw error
            })
    }

    const populateAppuserName = (inputValue) => {
        setLoader(true)
        if (inputValue.length >= 4) {
            const searchParams = new URLSearchParams({ name: inputValue });
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-app-user-by-names-list/?${searchParams.toString()}`,
                {
                    headers: {
                        Authorization: API_TOKEN,
                    },
                }
            )
                .then((response) => {
                    setPopulatedAppuser(response.data.data)
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                    throw error;
                });
        }
    };

    const populateFacilityName = (inputValue) => {
        setLoader(true)
        if (inputValue.length >= 4) {
            const searchParams = new URLSearchParams({ name: inputValue });
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`,
                {
                    headers: {
                        Authorization: API_TOKEN,
                    },
                }
            )
                .then((response) => {
                    console.log(response.data.data)
                    setPopulatedFacility(response.data.data)
                    setLoader(false)
                })
                .catch((error) => {
                    setLoader(false)
                    throw error;
                });
        }
    };

    const facilityNameloadOptions = (inputValue) => {
        if (inputValue.length >= 4) {
           const searchParams = new URLSearchParams({ name: inputValue });
           return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`,
              {
                 headers: {
                    Authorization: API_TOKEN,
                 },
              }
           )
              .then((response) => response.data.data)
              .catch((error) => {
                 throw error;
              });
        }
     };

    useEffect(() => {
        populateGroupName(clickedRow?.group_name)
        populateAppuserName(clickedRow?.appUser_name)
        populateFacilityName(clickedRow?.facility_name)
    }, [clickedRow]) // eslint-disable-line

    const groupNameloadOptions = (inputValue) => {
        if (inputValue.length >= 4) {
            const searchParams = new URLSearchParams({ name: inputValue })
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
                {
                    headers: {
                        Authorization: API_TOKEN,
                    },
                }
            )
                .then((response) => response.data.data)
                .catch((error) => {
                    throw error
                })
        }
    }

    const appUserloadOptions = (inputValue) => {
        if (inputValue.length >= 4) {
            const searchParams = new URLSearchParams({ name: inputValue });
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-app-user-by-names-list/?${searchParams.toString()}`,
                {
                    headers: {
                        Authorization: API_TOKEN,
                    },
                }
            )
                .then((response) => response.data.data)
                .catch((error) => {
                    throw error;
                });
        }
    };

    const handleUpdate = (e) => {
        e.preventDefault()
        setLoader(true)

        // converting to string + ''
        const params = {
            "groupId": (populatedGroupName.length > 0) ? (populatedGroupName[0].value + '') : '',
            "userId": (populatedAppuser.length > 0) ? (populatedAppuser[0].value + '') : '',
            "facilityId": populatedFacility ? populatedFacility[0].value + '' : '',
            "id": (clickedRow.id + '')
        }

        AxiosInter
            .post(`${API_ENDPOINT}/admin-api/update-referral-sec-assignee-facility-mapping/`, params, {
                headers: {
                    Authorization: API_TOKEN,
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    toastr.success("Success", "Updated")
                    toggleEditModal()
                    setReload(true)
                    setLoader(false)
                }
            })
            .catch((error) => {
                toastr.error("Failed", "something went to wrong")
                setLoader(false)
            })
    }
    return (
        <>
            <Modal
                isOpen={isEditModal}
                size="md"
                className="referral-category-configuration"
            >
                <ModalHeader
                    tag="h4"
                    cssModule={{ "modal-title": "w-100 text-center" }}
                    toggle={toggleEditModal}
                >
                    Edit HIM User
                </ModalHeader>
                <Form
                    onSubmit={handleUpdate}
                >
                    <ModalBody>
                        {
                            loader && <Spinner />
                        }
                        <Row>
                            <Col xs={12} className="bottom-space">
                                <Label>
                                    Group Name <span className="required">*</span>
                                </Label>
                                <AsyncSelect
                                    cacheOptions
                                    isClearable
                                    isSearchable
                                    type="text"
                                    name="groupName"
                                    placeholder="Search with at least 4 characters."
                                    value={populatedGroupName}
                                    onChange={handleGroupChange}
                                    loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
                                    required
                                />
                            </Col>
                            <Col xs={12} className="bottom-space">
                                <Label>
                                    App User Name <span className="required">*</span>
                                </Label>
                                <AsyncSelect
                                    cacheOptions
                                    isClearable
                                    isSearchable
                                    type="text"
                                    name="appusername"
                                    placeholder="Search with at least 4 characters."
                                    value={populatedAppuser}
                                    onChange={handleAppUserChange}
                                    loadOptions={(inputValue) => appUserloadOptions(inputValue)}
                                    required
                                />
                            </Col>
                            <Col xs={12} className="bottom-space">
                                <Label>
                                    Facility <span className="required">*</span>
                                </Label>
                                <AsyncSelect
                                    cacheOptions
                                    isClearable
                                    isSearchable
                                    type="text"
                                    name="facility"
                                    placeholder="Search with at least 4 characters."
                                    value={populatedFacility}
                                    onChange={handleFacilityChange}
                                    loadOptions={(inputValue) => facilityNameloadOptions(inputValue)}
                                    required
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter className="advanced-search-button  custom-modal-footer">
                        <Button
                            type="submit"
                            className="yes-button button-width"
                            style={{ cursor: "pointer" }}
                        >
                            Update
                        </Button>{" "}
                        <Button
                            outline
                            style={{ cursor: "pointer" }}
                            className="no-button button-width"
                            onClick={toggleEditModal}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    )
}

export default EditHIMUser