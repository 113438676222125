import React, { useState, useEffect } from 'react';
import AsyncSelect from "react-select/async";
import { FormGroup, Label, Modal, ModalBody, ModalHeader, Form, Row, Col, Input, ModalFooter, Button } from "reactstrap";
import Select from 'react-select';
import Cookie from 'js-cookie';
import AxiosInter from './../../AxiosInter.js';
import { toastr } from 'react-redux-toastr';
import CreatableSelect from 'react-select/creatable';

const EditAnalyticsModal = (props) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");

    const {
        editAnalyticsModal,
        setEditAnalyticsModal,
        groupNameOptions,
        isExternalChecked,
        handleIsExternalChange,
        clickedRowData,
        subscriptionNamesList,
        groupId,setSelectedApiKeyOption,selectedApiKeyOption, // eslint-disable-line
        getAnalyticsData,
    } = props;

    const [formData, setFormData] = useState(clickedRowData);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [subscription, setSubscription] = useState();
    const [panelColor, setPanelColor] = useState();
    const [category, setCategory] = useState();
    const [name, setName] = useState();
    const [dashboardId, setDashboardId] = useState();
    const [apiKey, setApiKey] = useState();
    const [email, setEmail] = useState();
    const [url, setUrl] = useState();
    const [sortOrder, setSortOrder] = useState();
    const [distinctApiKey, setDistinctApiKey] = useState([]);

    useEffect(() => {
        handleGroupChange({ value: clickedRowData?.groupId, label: clickedRowData?.groupName });
        const sub = clickedRowData?.subscription_values?.map(x => ({ value: x.id, label: x.name }))
        handleSubscriptionChange(sub)
        setCategory(clickedRowData?.category)
        setPanelColor(clickedRowData?.panelColor);
        setFormData(clickedRowData);
        setName(clickedRowData?.name);
        setSortOrder(clickedRowData?.sortOrder);
        setDashboardId(clickedRowData?.dashboardId);
        setUrl(clickedRowData?.url);
        setApiKey({label:clickedRowData?.apiKey, value:clickedRowData?.apiKey })
        setEmail(clickedRowData?.email)
        handleIsExternalChange(clickedRowData?.isExternal)
    }, [clickedRowData]); // eslint-disable-line

    const handleGroupChange = (selectedOption) => {
        setSelectedGroup(selectedOption);
    };

    const handleSubscriptionChange = (subscription) => {
        setSubscription(subscription);
    };

    const handleApiKeyChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option') {
            setEmail("");
            setDistinctApiKey([...distinctApiKey, { value: newValue.value, label: newValue.label }]);
        } else {
            setEmail(newValue ? newValue.value : "");
        }
        setApiKey(newValue);
    };   

    const handleChangeUpdateInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'sortOrder') {
            setSortOrder(value);
        }
        else if (name === 'email') {
            setEmail(value); 
        }
        else if (name === 'dashboardId') {
            setDashboardId(value); 
        }
        else if (name === 'url') {
            setUrl(value); 
        }
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    }; 

    const getDistinctApiKeys = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-distinct-api-key/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
        .then(response => {
            setDistinctApiKey(response.data.data);
        })
        .catch(error => {
            console.error('Error fetching distinct API keys:', error);
        });
    };

    useEffect(() => {
        if(editAnalyticsModal){
            getDistinctApiKeys();
        }
    }, [editAnalyticsModal]); // eslint-disable-line

    const updateAnalytics = () => {

        const sub = subscription?.map(x => x.value + '') || [];

        const params = {
            "id": formData?.id,
            "subscription_Id": sub,
            "groupId": selectedGroup ? selectedGroup.value : "",
            "panelColor": panelColor,
            "category": category,
            "name": name,
            "dashboardId": isExternalChecked ? dashboardId : null,
            "apiKey": isExternalChecked ? apiKey.label : null,
            "email": isExternalChecked ? email : null,
            "url": !isExternalChecked ? url : null,
            "isExternal": isExternalChecked ? 1 : 0,
            "sortingOrder": sortOrder
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-analyics-detail/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
        .then(response => {
            if (response.status === 200) {
                setEditAnalyticsModal(!editAnalyticsModal)
                toastr.success("Success", "Analytics Updated Successfully")
                getAnalyticsData()
            }
        })
        .catch(error => {
            toastr.error("Error", error.response.data.message)
        });
    }

    return (
        <div>
            <Modal isOpen={editAnalyticsModal} size='md'>
                <ModalHeader tag="h4" cssModule={{ "modal-title": "w-100 text-center" }}> Edit Analytics</ModalHeader>
                <ModalBody className='overflow-modal'>
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Group</Label>
                                        <AsyncSelect
                                            isClearable
                                            isSearchable
                                            menuPosition="fixed"
                                            value={selectedGroup}
                                            onChange={handleGroupChange}
                                            loadOptions={groupNameOptions}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Category</Label>
                                        <Input
                                            type="text"
                                            value={category}
                                            onChange={handleCategoryChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={formData?.name}
                                            onChange={handleChangeUpdateInput}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label>Subscription</Label>
                                        <Select
                                            isMulti
                                            isClearable
                                            isSearchable
                                            value={subscription}
                                            onChange={handleSubscriptionChange}
                                            options={subscriptionNamesList}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label>Color</Label>
                                        <Input
                                            type='color'
                                            value={panelColor}
                                            onChange={(e) => {
                                                setPanelColor(e.target.value);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label>Sorting Order</Label>
                                        <Input
                                            value={sortOrder}
                                            name='sortOrder'
                                            onChange={handleChangeUpdateInput}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                checked={isExternalChecked}
                                                onChange={(event) => handleIsExternalChange(event?.target?.checked)}
                                                type="checkbox"
                                            />
                                            {' '}
                                            Is External
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isExternalChecked ?
                                <Row>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label>API Key</Label>
                                            <CreatableSelect
                                                value={apiKey}
                                                onChange={handleApiKeyChange}
                                                options={distinctApiKey}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Input
                                                value={email}
                                                name='email'
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label>Dashboard Id</Label>
                                            <Input
                                                value={dashboardId}
                                                onChange={handleChangeUpdateInput}
                                                name="dashboardId"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Label>URL</Label>
                                            <Input
                                                key={'url'}
                                                value={url}
                                                onChange={handleChangeUpdateInput}
                                                name="url"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter className='advanced-search-button  custom-modal-footer'>
                    <Button
                        onClick={() => {
                            updateAnalytics()
                        }}
                    >Update</Button>{" "}
                    <Button
                        outline
                        className="no-button button-width"
                        onClick={() => {
                            setEditAnalyticsModal(false)
                        }}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default EditAnalyticsModal;
