import React, { useState, useEffect, useContext } from "react";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { Link } from "react-router-dom";
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
  Alert,
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import FaxLogDataTable from "./FaxLogDataTable";
import { GlobalState } from "../../contextAPI/Context";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import MomentConfig from "../../common/MomentConfig";


const AllFaxLog = () => {
  // CONTEXT VARIABLES
  const {
    appUserId,
    patientId,
    physicianId,
    groupsId,
    facilityId,
    docName,
    recipientFaxNumber,
    statusC,
    faxReload,
    setFaxReload,
    setBlankStateMessage,
  } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [faxLogList, setFaxLogList] = useState([]);
  const [modalState, setModalState] = useState(false); // eslint-disable-line
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(25);
  const [spinner, setSpinner] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const fileExportName = "FaxLog";
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;

  // eslint-disable-next-line no-unused-vars
  const statusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");

  const fetchFaxLogList = async () => {
    setSpinner(true);
    const params = {
      app_user_id: appUserId ? Number(appUserId.value) : 0,
      patient_id: patientId ? Number(patientId.value) : 0,
      physician_id: physicianId ? Number(physicianId.value) : 0,
      group_id: groupsId ? Number(groupsId.value) : 0,
      facility_id: facilityId ? Number(facilityId.value) : 0,
      doc_name: docName ? docName.label : "",
      recipient_fax_number: recipientFaxNumber ? recipientFaxNumber : "",
      statusC: statusC ? statusC.value : "",
      record_limit: sizePerPage ? sizePerPage.toString() : "25",
      offset: offset ? offset.toString() : "0",
      is_pagination: "1",
      search_keyword: searchKeyword ? searchKeyword.toString() : "",
      sortOrder: sortOrder ? sortOrder : "desc",
      sortColumn: sortColumn ? sortColumn : "",
    };

    await AxiosInter.post(`${API_ENDPOINT}/admin-api/get-fax-log-list/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(function (response) {
        setBlankStateMessage(response.data.data.length > 0 ? false : true);
        if (response.data.data) {
          setTotalSize(response.data.record_count);
          setFaxLogList(response.data.data);
          localStorage.setItem("offset", offset);
          setSpinner(false);
          localStorage.removeItem("subscriptionFilter");
          localStorage.removeItem("groupNameFilter");
          localStorage.removeItem("statusFilter");
          localStorage.removeItem("adminNameFilter");
          localStorage.removeItem("zipCodeFilter");
          localStorage.removeItem("timeZoneFilter");
        } else {
          setFaxLogList([]);
          setBlankStateMessage(true);
          setSpinner(false);
        }
      })
    // .catch(function (error) {
    //   toastr.warning("Error Occurred", "Please try again");
    //   setSpinner(false);
    // });
  };
  const exportTableData = () => {
    const params = {
      file_name: fileName ? fileName : "",
      app_user_id: appUserId ? Number(appUserId.value) : "",
      patient_id: patientId ? Number(patientId.value) : "",
      physician_id: physicianId ? Number(physicianId.value) : "",
      group_id: groupsId ? Number(groupsId.value) : "",
      facility_id: facilityId ? Number(facilityId.value) : "",
      doc_name: docName ? docName.label : "",
      recipient_fax_number: recipientFaxNumber ? recipientFaxNumber : "",
      statusC: statusC ? statusC.value : "",
      record_limit: "",
      offset: offset ? offset.toString() : "",
      is_pagination: "1",
      sortOrder: sortOrder ? sortOrder : "desc",
      sortColumn: sortColumn ? sortColumn : "",
      search_keyword: searchKeyword ? searchKeyword.toString() : "",
    };

    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-fax-log-table-data/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
      responseType: "blob",
    })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const statusFormatter = (cell, row) => {
    return (
      <Alert className="alert">
        <div>{cell}</div>
      </Alert>
    );
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const statusFormatterCSV = (cell) => {
    return cell ? cell : "N/A";
  };

  const TimeFormatter = (cell) => {
    return cell
      ? `${moment(cell).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} (UTC)`
      : "--";
  };

  const tableColumns = [
    {
      dataField: "doclocation",
      text: "Document Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "AppUser_Name",
      text: "App User",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "PatientFullName",
      text: "Patient",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "GroupName",
      text: "Group",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "FacilityName",
      text: "Facility",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "ProviderFullName",
      text: "Provider",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "DocStatus",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
    },
    {
      dataField: "recipientFaxNumber",
      text: "Fax Number",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
      filterValue: (cell, row) => recipientFaxNumber[cell],
    },
    {
      dataField: "processedOn",
      text: "Processed Date",
      sort: true,
      formatter: TimeFormatter,
      csvFormatter: TimeFormatter,
    },
  ];

  const handleTableChange = (type, newState) => {
    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);
    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("fl.id");
      setSortOrder("desc");
      setFaxReload(faxReload + 1);
    } else if (newState.sortField === "AppUser_Name") {
      setSortColumn("app_users.name");
      setSortOrder(newState.sortOrder);
      setFaxReload(faxReload + 1);
    } else if (newState.sortField === "PatientFullName") {
      setSortColumn("patients.first");
      setSortOrder(newState.sortOrder);
      setFaxReload(faxReload + 1);
    } else if (newState.sortField === "GroupName") {
      setSortColumn("groups.name");
      setSortOrder(newState.sortOrder);
      setFaxReload(faxReload + 1);
    } else if (newState.sortField === "FacilityName") {
      setSortColumn("	facility.name");
      setSortOrder(newState.sortOrder);
      setFaxReload(faxReload + 1);
    } else if (newState.sortField === "ProviderFullName") {
      setSortColumn("m_provider.name");
      setSortOrder(newState.sortOrder);
      setFaxReload(faxReload + 1);
    } else if (newState.sortField === "DocStatus") {
      setSortColumn("m_fax_status.displayName");
      setSortOrder(newState.sortOrder);
      setFaxReload(faxReload + 1);
    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setFaxReload(faxReload + 1);
    }
  };

  // DEFAULT API CALL
  useEffect(() => {
    if (faxReload > 0) {
      fetchFaxLogList();
    }
  }, [faxReload]); // eslint-disable-line

  useEffect(() => {
    if (
      appUserId !== "" ||
      patientId !== "" ||
      physicianId !== "" ||
      groupsId !== "" ||
      facilityId !== "" ||
      docName !== "" ||
      recipientFaxNumber !== "" ||
      statusC !== ""
    ) {
      fetchFaxLogList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appUserId,
    patientId,
    physicianId,
    groupsId,
    facilityId,
    docName,
    recipientFaxNumber,
    statusC,
  ]); // eslint-disable-line

  useEffect(() => {
    if (faxLogList) {
      localStorage.setItem("sizePerPage", sizePerPage);
    }
  }, [faxLogList]); // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== Number(previousOffset)) {
      fetchFaxLogList();
    }
  }, [offset]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length >0) {
      setFaxReload(faxReload + 1);
    }
  }, [searchKeyword]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Hux>
      <Container fluid>
        <Header>
          <HeaderTitle>Fax Log</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Fax Log</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        {spinner ? <Spinner /> : ""}
        <FaxLogDataTable
          data={faxLogList}
          columns={tableColumns}
          onTableChange={(type, newState) => {
            handleTableChange(type, newState);
          }}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize ? totalSize : 0}
          setPage={setPage}
          setOffset={setOffset}
          setTotalSize={setTotalSize}
          exportTableData={exportTableData}
          fetchFaxLogList={fetchFaxLogList}
          setSortColumn={setSortColumn}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          faxReload={faxReload}
          setFaxReload={setFaxReload}
        />
      </Container>
    </Hux>
  );
};



export default AllFaxLog;
