import React, { useState, useEffect, useContext,useMemo } from "react"; // eslint-disable-line

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Spinner,
  Input
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Cookie from 'js-cookie';

const EditProviderSpecialityRankingModal = (props) => {
  const {
    editModal,
    setEditModal,
    reload,
    setReload,
    senderForEdit,
    setSenderForEdit,
    fulfillerForEdit,
    setFulfillerForEdit,
    facilityForEdit,
    setFacilityForEdit,
    orderTypeForEdit,
    setOrderTypeForEdit,
    providerForEdit,
    setProviderForEdit,
    rankingForEdit,
    id,
    setRankingForEdit,
    editStatus,
    setEditStatus,
    editNotificationMail,
    setEditNotificationMail,
    editNotificationFax, 
    setEditNotificationFax,
    editContactNumber, 
    setEditContactNumber,
    editVersion,
    setEditVersion,
    editInsuranceType,
    insuranceType

  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  /* {
    "psr_id": 0,
    "sender_id": 0,
    "sender_group_id": 0,
    "fulfiller_id": 0,
    "fulfiller_group_id": 0,
    "fulfiller_facility_id": 0,
    "order_type_id": 0,
    "fulfiller_physician_id": 0,
    "network_ranking": 0
    } */

  // const [id, setID] = useState(rowData.id.toString());

  const [addButtonStatus, setAddButtonStatus] = useState(true);
  const [SpinnerEnable, setSpinnerEnable] = useState("");

  const [dropDownDataROS, setDropDownDataROS] = useState("");
  const [dropDownDataROF, setDropDownDataROF] = useState("");
  const [groupDropDownROS, setGroupDropDownROS] = useState("");
  const [groupDropDownROF, setGroupDropDownROF] = useState("");
  const [facilityDropDown, setFacilityDropDown] = useState(""); // eslint-disable-line

  const [orderTypeList, setOrderTypeList] = useState("");
  const [orderTypeDropDown, setOrderTypeDropDown] = useState("");

  const [inputValue, setInputValue] = useState(""); // eslint-disable-line
  const [selectedInsuranceType, setSelectedInsuranceType]=useState(null)

  const RankingOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" }
  ];
  const StatusOptions = [
    { value: '1', label: 'Active' },
    { value: '0', label: 'Inactive' },
    { value: '97', label: 'Out of Network' },
    { value: '98', label: 'Max Threshold' },
    { value: '99', label: 'Out of Payor Network' }
]
  const toggle = () => {
    setEditModal(!editModal);
    setSenderForEdit("");
    setFulfillerForEdit("");
    setFacilityForEdit("");
    setOrderTypeForEdit("");
    setProviderForEdit("");
    setRankingForEdit("");
    setEditNotificationFax("");
    setEditNotificationMail("");
    setEditContactNumber("");
    setAddButtonStatus(true);
  };

  /* const OverlayLoader = () => (
        <div class="item">
            <i class="loader --1"></i>
        </div>
    ); */

  /*  const confirmation = () => {
        if (!sender) {
            toastr.error("Sender Is Required", "Please select sender name!")
        }
        if (!fulfiller) {
            toastr.error("Fulfiller Is Required", "Please select fulfiller name!")
        }
        if (!facility) {
            toastr.error("facility Is Required", "Please select facility!")
        }
        if (!orderType) {
            toastr.error("OrderType Is Required", "Please select order type!")
        }
        if (!provider) {
            toastr.error("Provider Is Required", "Please select provider!")
        }

        if(!ranking ) {
            toastr.error("Ranking Is Required" , "Please select ranking!")
        }


        if (sender && fulfiller && facility && orderType && provider && ranking) {
            CreateAdminUserApiCall()
        }

    }; */

  const getDDDate = async () => {
    setSpinnerEnable(true);
    await AxiosInter
      .get(
        `${API_ENDPOINT}/admin-api/referral-order-sender-senderGp-fulfiller-fulfillerGp/`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((res) => {
        setDropDownDataROS(res.data.data.ros);
        setDropDownDataROF(res.data.data.rof);
        setSpinnerEnable(false);
      })
      .catch((error) => {
        // console.log(error);
        setSpinnerEnable(false);
      });
  };

  // const fetchOrderTypeData = async () => {
  //   setSpinnerEnable(true);
  //   await fetch(`${API_ENDPOINT}/admin-api/graphql/`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },

  //     body: JSON.stringify({
  //       query: `  query{
  //                   referralOrderTypesList{
  //                     id
  //                     type
  //                   }
  //                 }          
  //                 `,
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setOrderTypeList(data.data.referralOrderTypesList);
  //     });

  //   setSpinnerEnable(false);
  // };

  const fetchOrderTypeData = async () => {
    setSpinnerEnable(true);
    try{
      const response= await AxiosInter.post(`${API_ENDPOINT}/admin-api/graphql/`, {
        query: `  query{
          referralOrderTypesList{
            id
            type
          }
        }          
        `,
      });
      const data=response.data;
      setOrderTypeList(data.data.referralOrderTypesList);
    }
    catch(error){
      console.error('Error:', error);
    }
    setSpinnerEnable(true);

  }; 
    
  const editProviderSpecialityRanking = () => {
   
  
    // Function to validate an email address
    const isValidEmail = (email) => {
      const emailPattern = new RegExp("^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\\.[a-zA-Z]{2,})$");

      console.log(emailPattern.test(email))
      return emailPattern.test(email);
  };
// // Function to validate an fax number
//   const isValidFax = (fax) => {
//     const faxPattern = /^[0-9,-]*$/;
//     return faxPattern.test(fax);
// };


const isValidFax  =  (fax) => {
  // eslint-disable-next-line eqeqeq
  if (fax != '' && typeof (fax) != 'undefined') {
      // eslint-disable-next-line eqeqeq
      if (fax.length == 10 && fax.match(/^\+?[0-9]{10}$/)) {
          return true;
      }
      else if (fax.match(/^[0-9]{1,3}-[0-9]{3}-[0-9]{4}$/)) {
          return true;
      }
      else {
          return false;
      }
  } else {
      return false;
  }
}

            // var regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
            // if (!regex.test(phoneNo)) {
            //     errorflagtotal = 1;
            //     COREJS.showModal("Error", "Please Enter Valid Partner Phone Number", "error");
            //     return false;
            // }
            // var temp = phoneNo;
            // if (phoneNo.indexOf("-") != -1) {
            //     temp = (phoneNo.split("-")).join("");
            // }
            // if (phoneNo.indexOf(" ") != -1) {
            //     temp = (phoneNo.split(" ")).join("");
            // }
            // if (phoneNo.indexOf("(") != -1) {
            //     temp = (phoneNo.split(" ")).join("");
            // }
            // if (phoneNo.indexOf(")") != -1) {
            //     temp = (phoneNo.split(" ")).join("");
            // }
            // if (temp.substring(0, 3) == "000") {
            //     errorflagtotal = 1;
            //     COREJS.showModal("Error", "Please Enter Valid Partner Phone Number", "error");
            //     return false;
            // }
            // if (temp.substring(3, 6) == "000") {
            //     errorflagtotal = 1;
            //     COREJS.showModal("Error", "Please Enter Valid Partner Phone Number", "error");
            //     return false;
            // }
            // if (temp.substring(6, 10) == "0000") {
            //     errorflagtotal = 1;
            //     COREJS.showModal("Error", "Please Enter Valid Partner Phone Number", "error");
            //     return false;
            // }
 // Function to validate contact number
    const isValidNumber = (email) => {
     
      const numberPattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

      console.log(numberPattern.test(email))
      return numberPattern.test(email);
  };

   // Validate and extract notification emails
  const notificationEmails = editNotificationMail ? editNotificationMail.split(",").map(email => email.trim()) : [];
  const invalidEmails = notificationEmails.filter(email => !isValidEmail(email));

   // Validate and extract notification fax
   const notificationFaxs = editNotificationFax ? editNotificationFax.split(",").map(fax => fax.trim()) : [];
   const invalidFax = notificationFaxs.filter(fax => !isValidFax(fax));
 // Validate and extract notification number
 const notificationNumber = editContactNumber ? editContactNumber.split(",").map(number => number.trim()) : [];
 const invalidNumber = notificationNumber.filter(number => !isValidNumber(number));


  // Check if any email is invalid
  if (invalidEmails.length > 0) {
      // Handle the error and return the corresponding messages
      invalidEmails.forEach(invalidEmail => {
          toastr.error("Invalid Email", `Invalid email address: ${invalidEmail}`);
      });
      return;
  }

   // Check if any email is invalid
   if (invalidFax.length > 0) {
    // Handle the error and return the corresponding messages
    invalidFax.forEach(invalidFax => {
        toastr.error("Invalid Fax", `Invalid Fax Number: ${invalidFax}`);
    });
    return;
}
 // Check if any email is invalid
 if (invalidNumber.length > 0) {
  // Handle the error and return the corresponding messages
  invalidNumber.forEach(invalidNumber => {
      toastr.error("Invalid Contact Number", `Invalid Contact Number: ${invalidNumber}`);
  });
  return;
}


    setSpinnerEnable(true);
    var senderid = senderForEdit.value.split("/")[0];
    var senderGroupid = senderForEdit.value.split("/")[1];
    var fulfillerid = fulfillerForEdit.value.split("/")[0];
    var fulfillerGroupid = fulfillerForEdit.value.split("/")[1];
    debugger
    const params = {
      psr_id: id,
      sender_id: senderid ? Number(senderid) : 0,
      sender_group_id: senderGroupid ? Number(senderGroupid) : 0,
      fulfiller_id: fulfillerid ? Number(fulfillerid) : 0,
      fulfiller_group_id: fulfillerGroupid ? Number(fulfillerGroupid) : 0,
      fulfiller_facility_id: facilityForEdit ? Number(facilityForEdit.value) : 0,
      order_type_id: orderTypeForEdit ? Number(orderTypeForEdit.value) : 0,
      fulfiller_physician_id: providerForEdit ? Number(providerForEdit.value): 0,
      network_ranking: rankingForEdit ? Number(rankingForEdit.value) : 0,
      status: editStatus ? Number(editStatus.value) : 0,
      notification_email:editNotificationMail ? notificationEmails.join(",") :"",
      notification_fax:editNotificationFax ? notificationFaxs.join(",") :"",
      contact_number:editContactNumber ? notificationNumber.join(",") :"",
      version:editVersion ? editVersion.value :"",
      insurance_type:selectedInsuranceType ? selectedInsuranceType.value :null
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-provider-speciality-ranking/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        if (response.status === 201) {
          setEditModal(!editModal);
          setReload(reload + 1);
          toastr.success(
            "Successfully Updated",
            "Provider Speciality Ranking successfully updated"
          );
        }
        setSpinnerEnable(false);
      })
      .catch(function (error) {
        toastr.error("Failed", "Maybe your adding duplicate entry");
        setSpinnerEnable(false);
      });
  };

  const disableAddButton = () => {
    if (
      senderForEdit &&
      fulfillerForEdit &&
      facilityForEdit &&
      orderTypeForEdit &&
      rankingForEdit
    ) {
      setAddButtonStatus(false);
    } else {
      setAddButtonStatus(true);
    }
  };

  const createGroupOptionROS = () => {
    if (dropDownDataROS) {
      setSpinnerEnable(true);
      var array1 = dropDownDataROS;
      const options1 = array1.map((item, index) => {
        return {
          value: item.SenderID + "/" + item.senderGroupID,
          label: item.senderGroupName,
        };
      });
      setGroupDropDownROS(options1);
      setSpinnerEnable(false);
    }
  };

  const createGroupOptionROF = () => {
    if (dropDownDataROF) {
      setSpinnerEnable(true);
      var array1 = dropDownDataROF;
      const options1 = array1.map((item, index) => {
        return {
          value: item.FulfillerID + "/" + item.fulfillerGroupID,
          label: item.fulfillerGroupName,
        };
      });
      setGroupDropDownROF(options1);
      setSpinnerEnable(false);
    }
  };

  const createOptionsForOrderType = () => {
    if (orderTypeList) {
      setSpinnerEnable(true);
      var array1 = orderTypeList;
      const options1 = array1.map((item, index) => {
        return {
          value: item.id,
          label: item.type,
        };
      });
      setOrderTypeDropDown(options1);
      setSpinnerEnable(false);
    }
  };

  const getAsyncOptions = (inputValue) => {
    if (groupDropDownROS) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(groupDropDownROS, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const getAsyncOptionsForFulfiller = (inputValue) => {
    if (groupDropDownROF) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(groupDropDownROF, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };
  const statusPopulate = (editStatus) => {
    // debugger
    if (editStatus !== null) {
      StatusOptions.map((item) => { // eslint-disable-line
        if (editStatus.toString() === item.value) {
          setEditStatus(item);
        }
      });
    }
  };

  const VersionOptions = [
    { value: 'O', label: 'Hierarchy' },
    { value: 'N', label: 'Non-Hierarchy' }
  ]
  useMemo(() => statusPopulate(editStatus), [editStatus]); // eslint-disable-line
  const minLengthforzip = 1;

  const customNoOptionsMessageforZip = ({ inputValue }) => {
    if (inputValue.length < minLengthforzip) {
      return "Please search with at least 1 character";
    } else {
      return "No option";
    }
  };
  /* const getAsyncOptionsForFacility = (inputValue) => { 
        if(facilityDropDown){
            
         return new Promise((resolve, reject) => {
             const filtered = _.filter(facilityDropDown, o =>
               _.startsWith(_.toLower(o.label), _.toLower(inputValue))
             );
             resolve(filtered);
           });
        }
    } */

  // handle input change event
  const handleInputChange = (value) => {
    setInputValue(value);
  };

  // handle selection
  const handleChange = (value) => {
    setProviderForEdit(value);
  };

  const loadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-and-npi-data/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const loadFacilityOptionsForFacility = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };
  

  const handleFacilityChange = (value) => {
    setFacilityForEdit(value);
  };
  const changeOnEmail = (e) => {
    setEditNotificationMail(e);
  };
  const changeOnFax= (e) => {
    setEditNotificationFax(e);
  };

  useEffect(() => {
    getDDDate();
    fetchOrderTypeData();
  }, []); // eslint-disable-line
  useEffect(() => {
    if (dropDownDataROS) createGroupOptionROS();
  }, [dropDownDataROS]); // eslint-disable-line
  useEffect(() => {
    if (dropDownDataROF) createGroupOptionROF();
  }, [dropDownDataROF]); // eslint-disable-line
  useEffect(() => {
    disableAddButton();
  }, [senderForEdit, fulfillerForEdit, facilityForEdit, orderTypeForEdit]); // eslint-disable-line
  useEffect(() => {
    if (orderTypeList) createOptionsForOrderType();
  }, [orderTypeList]); // eslint-disable-line

  useEffect(() => {
    setSelectedInsuranceType(editInsuranceType)
  }, [editInsuranceType]); // eslint-disable-line



  return (
    <div>
      {SpinnerEnable ? <Spinner /> : null}
      <Modal isOpen={editModal} size="xl">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit Provider Speciality Ranking{" "}
        </ModalHeader>
        <ModalBody className="disable-scrollbars">
          <FormGroup>
            <Row>
              <Col xs="3">
                <Label>
                  Sender Group <span className="required">*</span>
                </Label>

                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  /* defaultOptions */
                  placeholder="Type sender name for options"
                  noOptionsMessage={customNoOptionsMessageforZip}
                  value={senderForEdit}
                  onChange={(event) => {
                    setSenderForEdit(event);
                  }}
                  loadOptions={(inputValue) => getAsyncOptions(inputValue)}
                />
              </Col>
              <Col xs="3">
                <Label>
                  Fulfiller Group <span className="required">*</span>
                </Label>
                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  /* defaultOptions */
                  placeholder="Type fulfiller name for options"
                  noOptionsMessage={customNoOptionsMessageforZip}
                  value={fulfillerForEdit}
                  onChange={(event) => {
                    setFulfillerForEdit(event);
                  }}
                  loadOptions={(inputValue) =>
                    getAsyncOptionsForFulfiller(inputValue)
                  }
                />
              </Col>
              <Col xs="3">
                <Label>
                  Order Type <span className="required">*</span>
                </Label>

                <Select
                  name="order"
                  options={orderTypeDropDown}
                  value={orderTypeForEdit}
                  placeholder="Select order type"
                  isSearchable
                  isClearable
                  onChange={(e) => {
                    setOrderTypeForEdit(e);
                  }}
                />
              </Col>
              <Col xs="3">
                <Label>Insurance Type</Label>
                <Select
                  options={insuranceType}
                  value={selectedInsuranceType}
                  placeholder="Select insurance type"
                  isSearchable
                  isClearable
                  onChange={(e)=>{
                    setSelectedInsuranceType(e)
                  }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col xs="7">
                <Row>
                  <Col style={{ display: "grid" }} xs="6">
                    <div>
                      <Label>
                        Facility <span className="required">*</span>
                      </Label>
                      <div
                        style={{ float: "right", marginTop: "0px" }}
                        className="warning-label"
                      >
                        <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                        <Label className="warning-text">
                          Search with at least 4 characters.
                        </Label>
                      </div>
                    </div>
                    <AsyncSelect
                    className="mrg-btm-22"
                      cacheOptions
                      isClearable
                      isSearchable
                      placeholder="Type facility name for options"
                      value={facilityForEdit}
                      loadOptions={loadFacilityOptionsForFacility}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      onInputChange={handleInputChange}
                      onChange={handleFacilityChange}
                    />
                  </Col>
                  <Col xs="3">
                    <Label>
                      Network Rank <span className="required">*</span>
                    </Label>

                    <Select
                      name="order"
                      options={RankingOptions}
                      value={rankingForEdit}
                      placeholder="Select order type"
                      isSearchable
                      isClearable
                      onChange={(e) => {
                        setRankingForEdit(e);
                      }}
                    />
                    <br></br>
                  </Col>
                  <Col xs="3">
                    <Label>
                      Status 
                    </Label>

                    <Select
                      name="status"
                      options={StatusOptions}
                      value={editStatus}
                      placeholder="Status"
                      isSearchable
                      isClearable
                      onChange={(e) => {
                        setEditStatus(e);
                      }}
                    />
                    <br></br>
                  </Col>
                </Row>
              </Col>
              <Col style={{ display: "grid" }} xs="5">
                <div>
                  <Label>Provider</Label>
                  <div
                    style={{ float: "right", marginTop: "0px" }}
                    className="warning-label"
                  >
                    <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                    <Label className="warning-text">
                      Search with at least 4 characters.
                    </Label>
                  </div>
                </div>
                <AsyncSelect
                className="mrg-btm-22"
                  cacheOptions
                  /* defaultOptions */
                  isClearable
                  isSearchable
                  value={providerForEdit}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadOptions}
                  onInputChange={handleInputChange}
                  onChange={handleChange}
                  placeholder="Type provider name for options"
                />
              </Col>

              <Col xs="3">
                    <Label>
                      Notification Mail
                    </Label>

                    <Input
                          placeholder="Emails (demo1@xyz.com,demo2@xyz.com)"
                          // isMulti
                          style={{ width: '100%' }} 
                          value={editNotificationMail}
                          onChange={(e) => {
                            changeOnEmail(e.target.value);
                          }}
                        />
                    <br></br>
                  </Col>
                  <Col xs="3">
                    <Label>
                      Notification Fax
                    </Label>

                    <Input
                          placeholder="Fax (644124598,71217598)"
                          // isMulti
                          style={{ width: '100%' }} 
                          value={editNotificationFax}
                          onChange={(e) => {
                            changeOnFax(e.target.value);
                          }}
                        />
                    <br></br>
                  </Col>
                  <Col xs="3">
                    <Label>
                   Contact Number
                    </Label>

                    <Input
                          placeholder="Enter Contact Number"
                          // isMulti
                          value={editContactNumber}
                          onChange={(e) => {
                            setEditContactNumber(e.target.value);
                          }}
                        />
                    <br></br>
                  </Col>
                  <Col xs="3">
                        
                        
                        <Label>Version</Label>
                    
                        
                         
                            <div>

                         <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setEditVersion(event) }}
                            isSearchable
                            placeholder="Select Version"
                            value={editVersion}
                            options={VersionOptions}
                            />
                        </div>
                        
              
                    </Col>

            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button  custom-modal-footer">
          <Button
            className="yes-button button-width"
            onClick={() => {
              setSpinnerEnable(true);
              editProviderSpecialityRanking();
            }}
            disabled={addButtonStatus}
            title={addButtonStatus ? "Fill the required fields" : ""}
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditProviderSpecialityRankingModal;
