import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"; // eslint-disable-line
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'

const PatientStatusChangeModal = (props) => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");
    const { statusModal, setStatusModal, status, rowId, updatePatientStatus } = props

    const toggle = () => {
        setStatusModal(!statusModal)
    }
    const confirmStatusChange = () => {

        let newStatus = status === 0 ? 1 : 0;

        const params = {
            "id": rowId,
            "status": newStatus
        }
        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-patients-group-facility-status/`, params, {
            headers: {
                Authorization: API_TOKEN
            }
        }).then((response) => {
            if (response.status === 201) {
                updatePatientStatus(rowId, newStatus);
                setStatusModal(!statusModal);
                toastr.success("Status updated successfully");
            }
        }).catch((error) => {
            console.log("Error", error);
        });

    }

    return (
        <div>
            <Modal isOpen={statusModal}>
                <ModalHeader
                    tag='h4'
                    cssModule={{ "modal-title": "w-100 text-center" }}
                >
                    Change Patient Status
                </ModalHeader>
                <ModalBody>
                    <div style={{ 'textAlign': 'center' }}>
                        Do you want to change the patient status to {status === 0 ? 'active?' : 'inactive?'}
                    </div>
                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button className="yes-button button-width" type="button" onClick={confirmStatusChange}>Yes</Button>{" "}
                    <Button outline className="no-button button-width" onClick={toggle}>No</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default PatientStatusChangeModal