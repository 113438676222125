import Cookie from "js-cookie"
import React, { useState } from 'react'
import AsyncSelect from "react-select/async"
import { Button, Col, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import AxiosInter from '../../AxiosInter.js'
import { toastr } from "react-redux-toastr"

function AddNewHIMUser({ isOpen, toggleModal, setReload }) {
   const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
   const API_TOKEN = Cookie.get("access_token_admin")

   const [groupName, setGroupName] = useState("")
   const [appUser, setappUser] = useState("")
   const [facility, setFacility] = useState("")
   const [loader, setLoader] = useState(false)

   const handleGroupChange = (value) => {
      setGroupName(value)
   }

   const handleFacilityChange = (value) => {
      setFacility(value)
   }

   const handleAppUserChange = (appUser) => {
      setappUser(appUser)
   }

   const groupNameloadOptions = (inputValue) => {
      if (inputValue.length >= 4) {
         const searchParams = new URLSearchParams({ name: inputValue })
         return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
            {
               headers: {
                  Authorization: API_TOKEN,
               },
            }
         )
            .then((response) => response.data.data)
            .catch((error) => {
               throw error
            })
      }
   }

   const appUserloadOptions = (inputValue) => {
      if (inputValue.length >= 4) {
         const searchParams = new URLSearchParams({ name: inputValue });
         return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-app-user-by-names-list/?${searchParams.toString()}`,
            {
               headers: {
                  Authorization: API_TOKEN,
               },
            }
         )
            .then((response) => response.data.data)
            .catch((error) => {
               throw error;
            });
      }
   }

   const facilityNameloadOptions = (inputValue) => {
      if (inputValue.length >= 4) {
         const searchParams = new URLSearchParams({ name: inputValue });
         return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`,
            {
               headers: {
                  Authorization: API_TOKEN,
               },
            }
         )
            .then((response) => response.data.data)
            .catch((error) => {
               throw error;
            });
      }
   };

   const handleAddNewHIMUser = (e) => {
      e.preventDefault()
      setLoader(true)

      const params = {
         "groupId": (groupName?.value) ? (groupName.value + '') : '',
         "userId": (appUser?.value) ? (appUser.value + '') : '',
         "facilityId": (facility?.value) ? (facility.value + '') : '',
      }

      AxiosInter
         .post(`${API_ENDPOINT}/admin-api/create-referral-sec-assignee-facility-mapping/`, params, {
            headers: {
               Authorization: API_TOKEN,
            }
         })
         .then((response) => {
            if (response.status === 201) {
               toastr.success("Success", "New Entry created")
               setLoader(false)
               toggleModal()
               setReload(true)
            }
         })
         .catch((error) => {
            console.log(error)
            if (error?.response?.data?.message) {
               toastr.error("Failed", error.response.data.message)
               setLoader(false)
            }
            else {
               toastr.error("Failed", "something went to wrong")
               setLoader(false)
            }
         })
   }

   return (
      <>
         <Modal
            isOpen={isOpen}
            size="md"
            className="referral-category-configuration"
         >
            <ModalHeader
               tag="h4"
               cssModule={{ "modal-title": "w-100 text-center" }}
               toggle={toggleModal}
            >
               Add new HIM user
            </ModalHeader>
            <Form
               onSubmit={handleAddNewHIMUser}
            >
               <ModalBody>
                  {
                     loader && <Spinner />
                  }
                  <Row>
                     <Col xs={12} className="bottom-space">
                        <Label>
                           Group Name <span className="required">*</span>
                        </Label>
                        <AsyncSelect
                           cacheOptions
                           isClearable
                           isSearchable
                           type="text"
                           name="groupName"
                           placeholder="Search with at least 4 characters."
                           value={groupName}
                           onChange={handleGroupChange}
                           loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
                           required
                        />
                     </Col>
                     <Col xs={12} className="bottom-space">
                        <Label>
                           Facility Name <span className="required">*</span>
                        </Label>
                        <AsyncSelect
                           cacheOptions
                           isClearable
                           isSearchable
                           type="text"
                           name="facility"
                           placeholder="Search with at least 4 characters."
                           value={facility}
                           onChange={handleFacilityChange}
                           loadOptions={(inputValue) => facilityNameloadOptions(inputValue)}
                           required
                        />
                     </Col>
                     <Col xs={12} className="bottom-space">
                        <Label>
                           App User Name <span className="required">*</span>
                        </Label>
                        <AsyncSelect
                           cacheOptions
                           isClearable
                           isSearchable
                           type="text"
                           name="appusername"
                           placeholder="Search with at least 4 characters."
                           value={appUser}
                           onChange={handleAppUserChange}
                           loadOptions={(inputValue) => appUserloadOptions(inputValue)}
                           required
                        />
                     </Col>
                  </Row>
               </ModalBody>
               <ModalFooter className="advanced-search-button  custom-modal-footer">
                  <Button
                     type="submit"
                     className="yes-button button-width"
                     style={{ cursor: "pointer" }}
                  >
                     Add
                  </Button>{" "}
                  <Button
                     outline
                     style={{ cursor: "pointer" }}
                     className="no-button button-width"
                     onClick={toggleModal}
                  >
                     Cancel
                  </Button>
               </ModalFooter>
            </Form>
         </Modal>
      </>
   )
}

export default AddNewHIMUser