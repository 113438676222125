import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { Col, Row, Spinner, Card, CardBody,Alert } from "reactstrap";
import './DateRangeSelector.css';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import equipologo from "../../assets/img/logos/equipo-logo_low-res_with-tagline.png";
import { toastr } from 'react-redux-toastr';
import { useNavigate } from "react-router-dom";


const OTPVerify = () => {

    // LOCAL VARIABLES 
    let navigate = useNavigate();
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");

    const [spinner, setSpinner] = useState(false)

    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem("user_info"))) // eslint-disable-line
    const [code, setCode] = useState("");

    const [serviceSID, setServiceSID] = useState("");
    const [attemptsCount, setAttemptsCount] = useState(5);


    const createServiceAndGenerateOTP = () => {
        setSpinner(true)
        setAttemptsCount(5);
        const params = {
            "text": "Equipo Admin User",
            "clint_number": userInfo ? userInfo.phone : ""
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/twilio-service/`, params, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(function (response) {
                if (response.status === 201) {
                    setServiceSID(response.data.service_sid)
                    setSpinner(false)
                    toastr.success("Successful", "OTP sent to your mobile number again")
                }
            })
            .catch(function (error) {
                toastr.error("Failed", error)
                setSpinner(false)
            });
    }

    const verifyOTP = () => {

        setSpinner(true)
        const param = {
            "code": code ? code.toString() : "",
            "clint_number": userInfo ? userInfo.phone.toString() : "",
            "service_sid": serviceSID ? serviceSID.toString() : "",
            "email": userInfo ? userInfo.email.toString() :""
        }
        AxiosInter.post(`${API_ENDPOINT}/admin-api/twilio-verify-otp/`, param,{
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(function (response) {

                if (response.status === 201) {
                    if(response.data.status === "approved") {
                        toastr.success("Successful", "OTP verified successful")
                        setTimeout(() => {
                            navigate("/signIn");
                            setSpinner(false)
                        }, 2000);
                    }
                    
                    if (response.data.status === "pending") {
                        if (attemptsCount > 0) {
                          setAttemptsCount(attemptsCount - 1);
                        }
                        toastr.error('Failed', "Incorrect OTP, please enter the otp again");
                        setSpinner(false)
                        setCode("")
                    }
                }
            })
            .catch(function (error) {
                toastr.error("Failed", "OTP verification failed, please try once again")
                setSpinner(false)
            });
    };

    // USE EFFECTS
    useEffect(() => { if(userInfo) createServiceAndGenerateOTP() }, [userInfo])     // eslint-disable-line

    return (
        <AvForm>
            <AvGroup>
                {spinner
                    ? <Spinner />
                    : ""}

                <Row className="m-auto">

                    <div className="card-otp">
                        <Card className='otp-card'>
                            <CardBody>

                                <div >

                                    <Row >
                                        <Col className="otp-input" >
                                            <div >
                                            <Alert className="alert-success adjust">

                                                <div className="heading-div">
                                                
                                                <div className="text-center">
                                                    <img
                                                        src={equipologo}
                                                        alt="equipo logo"
                                                        className="img-fluid"
                                                        width="150"
                                                        height="150"
                                                    />
                                                </div>
                                                </div>
                                            </Alert>
                                               
                                                <br></br>
                                                <br></br>
                                                <h4>{`Please enter the verification code sent to your mobile ${userInfo.phone ? userInfo.phone : ""}`}</h4>


                                                <div className='otp-input'>

                                                {
                                                    <div className="text-center mt-4">
                                                        <small>You have {attemptsCount} attempt(s) left.</small>
                                                    </div> 
                                                }
                                                    <OtpInput
                                                        inputStyle={{
                                                            width: '3rem',
                                                            height: '3rem',
                                                            margin: '20px 1rem',
                                                            fontSize: '2rem',
                                                            borderRadius: 4,
                                                            border: '2px solid rgba(0,0,0,0.3)',
                                                        }}
                                                        value={code}
                                                        onChange={(e) => setCode(e)}
                                                        numInputs={6}
                                                        separator={<span>-</span>}
                                                    />
                                                </div>
                                                <br></br>

                                                <br></br>
                                                <div className="outer">

                                                    

                                                    {
                                                        attemptsCount === 0 ?
                                                            <buutton onClick={() => createServiceAndGenerateOTP()}> Resend OTP
                                                                <span></span>
                                                            </buutton> : <>
                                                                <buutton onClick={() => verifyOTP()}> Submit
                                                                    <span></span>
                                                                </buutton> <span className="opacity-zero" >..d..</span>
                                                            </>
                                                    }
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>


                                </div>

                            </CardBody>
                        </Card>

                    </div>
                </Row>

            </AvGroup>
        </AvForm>
    )
}

export default OTPVerify;
