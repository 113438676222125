import React, { useContext} from "react";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { Col, Row, Button, Label } from "reactstrap";
import "./DateRangeSelector.css";
import Select from "react-select";
import { GlobalState } from "../../contextAPI/Context";
import AsyncSelect from "react-select/async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Cookie from 'js-cookie';
import AxiosInter from './../../AxiosInter.js';
// import _ from "lodash";

const ReferralAssigneeTagFilters = (props) => {
  // CONTEXT VARIABLES
  const { spinner } = useContext(GlobalState);

  // LOCAL VARIABLES
  // const [selectedStatus, setSelectedStatus] = useState("");


  const {
    referralAssigneeTagGroupName,
    setReferralAssigneeTagGroupName,
    referralAssigneeTagName,
    setReferralAssigneeTagName,
    referralAssigneeTagAppUser_Name,
    setReferralAssigneeTagAppUser_Name,
    setReloadForReferralAssigneeTag,
    reloadForReferralAssigneeTag,
    statusFilter,
    setStatusFilter,
    setOffset

  } = props;

  const statusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  

  const runSearch = () => {
    if (
      referralAssigneeTagGroupName ||
      referralAssigneeTagName ||
      referralAssigneeTagAppUser_Name||
      statusFilter
    ) {  setOffset("0")
        setReloadForReferralAssigneeTag(reloadForReferralAssigneeTag + 1);
      }
  };

  const resetFields = () => {
    // setBlankStateMessage(true);
    setReferralAssigneeTagGroupName("");
    setReferralAssigneeTagName("");
    setReferralAssigneeTagAppUser_Name("");
    setStatusFilter("");
    setReloadForReferralAssigneeTag(reloadForReferralAssigneeTag + 1);
  };


  const groupNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-referral-order-sender-groups/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };


  const tagNameloadOptions = (inputValue) => {
    // debugger;
    let groupID = referralAssigneeTagGroupName.value;
    if (inputValue.length >= 4) {
      return AxiosInter.get(
        `${API_ENDPOINT}/admin-api/fetch-referral-assignee-tag-name/${inputValue}/${
          groupID ? groupID : "free"
        }`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };


  const appUserloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-app-user-by-names-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  return (
    <AvForm>
      <AvGroup>
        <Row>
          <Col sm="4">
            <Label>
                Tag Name</Label>&nbsp;&nbsp;
                  <FontAwesomeIcon style={{color:'red'}} icon={faExclamationTriangle} />
                  <strong className="warning-text" style={{fontSize: 12}}>
                    Select Sender Group to get group wise Tags.
                  </strong>
            <AsyncSelect
              cacheOptions
              isClearable
              isSearchable
              type="text"
              name="groupName"
              placeholder="Search with at least 4 characters."
              value={referralAssigneeTagName}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              validate={{
                maxLength: { value: 120 },
              }}
              onChange={(e) => {
                setReferralAssigneeTagName(e);
              }}
              loadOptions={(inputValue) => tagNameloadOptions(inputValue)}
            />
          </Col>
          <Col sm="3">
            <Label>Sender Group</Label>
            <AsyncSelect
              cacheOptions
              isClearable
              isSearchable
              type="text"
              name="groupName"
              placeholder="Search with at least 4 characters."
              value={referralAssigneeTagGroupName}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              validate={{
                maxLength: { value: 120 },
              }}
              onChange={(e) => {
                setReferralAssigneeTagGroupName(e);
              }}
              loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
            />
          </Col>
          <Col sm="3">
            <Label>App User</Label>

            <AsyncSelect
              cacheOptions
              isClearable
              isSearchable
              type="text"
              name="groupName"
              placeholder="Search with at least 4 characters."
              value={referralAssigneeTagAppUser_Name}
              getOptionLabel={(e) => e.label}
              getOptionValue={(e) => e.value}
              validate={{
                maxLength: { value: 120 },
              }}
              onChange={(e) => {
                setReferralAssigneeTagAppUser_Name(e);
              }}
              loadOptions={(inputValue) => appUserloadOptions(inputValue)}
            />
          </Col>
          <Col sm="2">
              <Label>Status</Label>
                <Select
                  options={statusOptions}
                  placeholder="Select Status"
                  isClearable
                  value={statusFilter}
                  onChange={(event) => { debugger 
                    console.log(event)
                    setStatusFilter(event) }}
                />
          </Col>
          <Col>
            <Button
              type="submit"
              className="go-buttons"
              disabled={spinner ? true : false}
              onClick={() => { runSearch()  }}
            >
              Go
            </Button>
            <Button
              type="reset"
              className="reset-buttons"
              disabled={spinner ? true : false}
              onClick={() => {
                resetFields();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </AvGroup>
    </AvForm>
  );
};

export default ReferralAssigneeTagFilters;
