import * as actionTypes from "./actionTypes";
import Axios from "axios";
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'
import AES from 'crypto-js/aes';
// CONTEXT VARIABLES
/* export const { setUserInfo } = useContext(GlobalState); */
const secretKey = 'equipo';
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};
export const authSuccess = (token, userid) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    userid: userid,
  };
};
export const authCheckTimeOut = (exptime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, exptime * 1000);
  };
};
/* // CONTEXT VARIABLES
export const {
  loginCount, setLoginCount,
} = useContext(GlobalState); */
/* const [loginCount, setLoginCount] = useState(0)*/
let loginCount = 0
export const authFail = (error) => {
  loginCount = loginCount + 1
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};
export const logout = () => {
  // let intervalId;
  Cookie.remove('access_token_admin');
  Cookie.remove('refresh_token_admin');
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("superuser_check");
  localStorage.removeItem('access_token_admin');
  localStorage.removeItem('refresh_token_admin');
  // Cookie.remove("loginStatus");
  // clearInterval(intervalId);
  // Cookie.set("loginStatus","401");
  toastr.success('Logout Successful', 'Admin logout successfully');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};
export const logoutOnly = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("superuser_check");
  // toastr.success('Logout Successful', 'Admin logout successfully');
  
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};
export const auth = (username, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const AuthData = {
      username: username,
      password: password,
    };
    //----------------------------------------------------- Setting access and refresh token on user login-----------------------------------------------------//
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin")                              //------* get access token from cookie
    const signinurl = `${API_ENDPOINT}/admin-api/admin-user-login/`;                //------* endpoint for admin user login
    Axios.post(signinurl, AuthData)
      .then((res) => {
        if (res.data.user_info.isPhoneVerified === "0") {                           //------* check if phone number is verified
          localStorage.setItem('user_info', JSON.stringify(res.data.user_info))     //------* store user info in local storage
          window.location = "/Verification"                                         //------* redirect to phone verification page
        }
        else {
          const expirationDate = new Date(new Date().getTime() + 86400000);         //------* if phone number is verified the below code is executed
          localStorage.setItem('token', API_TOKEN);
          localStorage.setItem('expirationDate', expirationDate);
          // debugger
          localStorage.setItem('superuser_check', res.data.superuser_check);
          localStorage.setItem('access_token_admin', 'Bearer ' + res.data.access_token_admin);
          // localStorage.setItem('refresh_token_admin', res.data.refresh_token_admin);
          localStorage.setItem('access_token_expiration_time', res.data.access_token_expiration_time);
          localStorage.setItem('refresh_token_expiration_time', res.data.refresh_token_expiration_time);
          localStorage.setItem('current_time', res.data.current_time);
          Cookie.set('access_token_admin', `${encryptToken(res.data.access_token_admin)}`); //------* set access token in cookie
          Cookie.set('refresh_token_admin', encryptToken(res.data.refresh_token_admin));           //------* set refresh token in cookie
          // Cookie.remove("loginStatus");
          // debugger
          // Cookie.set('loginStatus', res.status);           //------* set login status in cookie
          document.cookie = `access_token=${Cookie.get('access_token_admin')}; expires=${Cookie.get('access_token_admin').expires}; path=/; httpOnly; secure`;
          dispatch(authSuccess(API_TOKEN, res.data.userID));
          localStorage.setItem('adminID', res.data.adminId);
          localStorage.setItem('microServiceSidebar', 0);
          dispatch(authCheckTimeOut(86400));
          toastr.success('Login Successful', 'Logged in as an Admin user');
          window.location = "/dashboard"
        }
      })
      .catch((error) => {
        dispatch(authFail(error));
      });
  };
};
const encryptToken = (token) => {
  const encryptedToken = AES.encrypt(token, secretKey).toString();
  return encryptedToken;
};
//---------------------------------------------Interval function which calls api every 14 mins and get new access & refresh token---------------------------------------------//
// const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
// const responseData=Cookie.get("loginStatus")
// let intervalId;
// // const refresh_token_status=Cookie.get("refresh_token_status")
// if (responseData === "201"){
//   intervalId = setInterval(() => {
//   const API_TOKEN = Cookie.get("access_token_admin")
//   const new_refresh_token_admin = Cookie.get('refresh_token_admin')
//   const params = {
//     refresh_token_admin: new_refresh_token_admin ? new_refresh_token_admin : ""
//   };
//   Axios.post(`${API_ENDPOINT}/admin-api/refresh-token/`, params, {
//     headers: {
//       Authorization: API_TOKEN,
//     },
//   })
//     .then((response) => {
//       if (response) {
//         Cookie.remove('access_token_admin');                                    //------* remove old access token from cookie
//         Cookie.remove('refresh_token_admin');                                   //------* remove old refresh token from cookie
//         Cookie.set('access_token_admin','Bearer ' +response.data.access_token_admin);     //------* set new access token in cookie
//         Cookie.set('refresh_token_admin', response.data.refresh_token_admin);   //------* set new refresh token in cookie
//         // document.cookie = `access_token=${Cookie.get('access_token_admin')}; expires=${Cookie.get('access_token_admin').expires}; path=/; httpOnly; secure`;
//         // console.log('Refresh token')
//       }
//     })
//     .catch((error) => {
//       // debugger
//       // console.log(error.response.request.status)
//       // console.log('Error token',error.status)
//       var x=(error.response.request.status)
//       if (x===401){
//         Cookie.remove('access_token_admin');                                    //------* remove old access token from cookie
//         Cookie.remove('refresh_token_admin');
//         logout()
//       }
//     });
// }, 840000);}                                                                    //------* refresh time is 14 mins(840000 milli seconds)
export const authStateCheck = () => {
  return (dispatch) => {
    const token = Cookie.get("access_token_admin");
    // FORCE LOGOUT USER IF THE TOKEN IS MISSING
    let auth = true
    setInterval(() => {
      if (!Cookie.get("access_token_admin")) {
        if (auth && window.location.pathname !== '/') {
          // dispatch(logout());
          dispatch(logoutOnly());
          auth = false
        }
      }
    }, 2000);
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        const userId = localStorage.getItem("superuser_check");
        dispatch(authSuccess(token, userId));
        dispatch(
          authCheckTimeOut(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};