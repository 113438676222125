import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, Container, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';


const CPTCategoryModal = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");

  const [buttonBlankState, setButtonBlankState] = useState(false);
  const {
    categoryModal,
    setCategoryModal,
    categoryModalState,
    editCptCategoryDescription,
    setEditCPTCategoryDescription,
    editCptCategoryId,
    reloadCPTCategoryTableData,
    setReloadCPTCategoryTableData,
    getCPTCategoryList,
    parentCategory
  } = props

  const [parentCat, setParentCat] = useState();
  const toggle = () => {
    setCategoryModal(!categoryModal)
  }

  const editCptCategoryDetails = () => {
    const params =
    {
      "cpt_category_description": editCptCategoryDescription ? editCptCategoryDescription : "",
      "cpt_category_id": editCptCategoryId ? editCptCategoryId : "",
      "cpt_parent_category_id": parentCat ? parentCat.value : ""
    }
    AxiosInter.post(`${API_ENDPOINT}/admin-api/edit-cpt-category-details/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1)
        toastr.success("Success", "Updated successfully")
        toggle();
        setParentCat("");
        getCPTCategoryList()
      })
      .catch(error => {
        toastr.error("Error", error.response.data.message)

      });

  }
  const addNewCptCategory = () => {
    const params =
    {
      "cpt_category_description": editCptCategoryDescription ? editCptCategoryDescription : "",
      "cpt_category_id": editCptCategoryId ? editCptCategoryId : "",
      "cpt_parent_category_id": parentCat ? parentCat.value : ""
    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/add-cpt-category-details/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1)
        toastr.success("Success", "CPT category details saved successfully")
        toggle();
        setParentCat("");
        getCPTCategoryList()
      })
      .catch(error => {
        toastr.error("Error", error.response.data.message)

      });
  }

  const validate = () => {

    if (
      editCptCategoryDescription

    ) {
      setButtonBlankState(false)
    }
    else {
      setButtonBlankState(true)
    }
  }

  useEffect(() => {
    if (!categoryModal) {
      setCategoryModal(false);
      setEditCPTCategoryDescription("");
    };

  }, [categoryModal]) // eslint-disable-line

  useEffect(() => {
    validate();

  }, [editCptCategoryDescription]) // eslint-disable-line

  return (
    <div>
      <Modal isOpen={categoryModal} size="md">
        <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>{categoryModalState === "add" ? "New CPT Category" : "Edit CPT Category"} </ModalHeader>
        <ModalBody className="overflow-modal">
          <Container fluid>
            <FormGroup>
              <Row>
                <Col sm={12}>
                  <Label for="description">Description<span className="required">*</span></Label>
                  <Input
                    id="description"
                    type="textarea"
                    rows={4}
                    cols={2}
                    placeholder="Description"
                    value={editCptCategoryDescription}
                    onChange={(e) => {
                      setEditCPTCategoryDescription(e.target.value)
                    }}
                  />
                </Col>
              </Row><br />
              <Row>
                <Col sm={12}>
                  <Label for="cpt_pID">PID</Label>
                  <Select
                    name="cpt_pID"
                    options={
                      parentCategory
                    }
                    onChange={(e) => { setParentCat(e) }}
                  />
                </Col>
              </Row><br />
            </FormGroup>
          </Container>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            onClick={() => {
              categoryModalState === 'edit' ? editCptCategoryDetails() : addNewCptCategory()
            }}
            disabled={buttonBlankState}
            title={buttonBlankState ? "Please fill in all mandatory fields" : (!buttonBlankState && categoryModalState === "edit") ? "Click to save" : "Click to update"}

          >{categoryModalState === "edit" ? 'Update' : 'Add'}</Button>{' '}
          <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CPTCategoryModal;