import React, { useState, useEffect,useContext } from 'react';
import { Button, Modal, ModalHeader, Container, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select-virtualized';
import { GlobalState } from '../../contextAPI/Context';

const ETLConfigModal = (props) => {
    const {  groupNamesForDropDown } = useContext(GlobalState);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    const [buttonBlankState, setButtonBlankState] = useState(false); // eslint-disable-line
   
    const {
        setModal,
        modal,
        modalState,
        setModalState,
        userName,
        setUserName,
        password,
        setPassword,
        baseUrl,
        setBaseUrl,
        loginUrl,
        setLoginUrl,
        version,
        setVersion,
        prefix,
        setPrefix,
        groupID,
        setGroupID,
        etlAllergy,
        setEtlAllergy,
        etlFacility,
        setEtlFacility,
        etlCPTCharge ,
        setEtlCPTCharge ,
        setEtlImmune,
        etlLabs,
        setEtlLabs ,
        etlMedication ,
        setEtlMedication ,
        etlPatientCPT,
        setEtlPatientCPT ,
        etlPatientICD ,
        setEtlPatientICD,
        etlPatientList ,
        setEtlPatientList ,
        etlPatientInsuranceList ,
        setEtlPatientInsuranceList ,
        etlReferral ,
        setEtlReferral ,
        etlVital ,
        setEtlVital ,
        etlAppointments ,
        setEtlAppointments,
        etlImmune,
        etlConfigId,
        reload,
        setReload,
        selectedGroupName,
        setSelectedGroupName,
        

    } = props

    const toggle = () => {
        setModal(!modal)
    }

const setDefaultGroupName = () => {
    // debugger;
    groupNamesForDropDown.map((item)=>{ // eslint-disable-line
      if(item.value === Number(groupID)) {
            setSelectedGroupName({value:item.value,label:item.label}); 
        }
        
    })
    
  };


    const editEtlConfigDetails = () => {
        const params =
        {
            "etlConfigId": etlConfigId ? etlConfigId:"",
            "etlUserName": userName ? userName:"",
            "etlPassword": password ? btoa(password):"",
            "etlVital": etlVital ? 1 :0,
            "etlBaseUrl": baseUrl ? baseUrl: "",
            "etlLoginUrl": loginUrl ? loginUrl:"",
            "groupID": selectedGroupName ? selectedGroupName.value : "",
            "version": version ? version:"",
            "prefix": prefix ? prefix:"",
            "etlAllergy": etlAllergy  ? 1 :0,
            "etlFacility": etlFacility  ? 1 :0,
            "etlCPTCharge": etlCPTCharge  ? 1:0,
            "etlImmune": etlImmune ? 1:0,
            "etlLabs": etlLabs ? 1:0,
            "etlMedication": etlMedication  ? 1:0,
            "etlPatientCPT": etlPatientCPT  ? 1:0,
            "etlPatientICD": etlPatientICD ? 1:0,
            "etlPatientList": etlPatientList ? 1:0,
            "etlPatientInsuranceList": etlPatientInsuranceList ? 1:0,
            "etlReferral":etlReferral ? 1:0,
            "etlAppointments":etlAppointments ? 1:0,

        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-etl-config-details/`,params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
          .then(response => {
            toggle();
            toastr.success("Success","Updated Config details");
            setReload(reload + 1);
            
          })
          .catch(error => {
            if (error.response.data.message) {
                toastr.error("Error",error.response.data.message)
            } 
            else {
                toastr.error("Error","Something went wrong !")
            }  
    
    
          });

    }

    const addNewEtlConfigDetails = () => {

        if(userName === "" || password=== ""){
            toastr.warning("Please enter Username and password atleast!")
        }
        else{
            const params =
            {
                "etlConfigId": etlConfigId ? etlConfigId:"",
                "etlUserName": userName ? userName:"",
                "etlPassword": password ? btoa(password):"",
                "etlVital": etlVital ? 1 :0,
                "etlBaseUrl": baseUrl ? baseUrl: "",
                "etlLoginUrl": loginUrl ? loginUrl:"",
                "groupID": selectedGroupName ? selectedGroupName.value : "",
                "version": version ? version:"",
                "prefix": prefix ? prefix:"",
                "etlAllergy": etlAllergy  ? 1 :0,
                "etlFacility": etlFacility  ? 1 :0,
                "etlCPTCharge": etlCPTCharge  ? 1:0,
                "etlImmune": etlImmune ? 1:0,
                "etlLabs": etlLabs ? 1:0,
                "etlMedication": etlMedication  ? 1:0,
                "etlPatientCPT": etlPatientCPT  ? 1:0,
                "etlPatientICD": etlPatientICD ? 1:0,
                "etlPatientList": etlPatientList ? 1:0,
                "etlPatientInsuranceList": etlPatientInsuranceList ? 1:0,
                "etlReferral":etlReferral ? 1:0,
                "etlAppointments":etlAppointments ? 1:0,
    
            }
    
            AxiosInter.post(`${API_ENDPOINT}/admin-api/add-new-etl-config-details/`,params,{
            headers: {
                Authorization: API_TOKEN,
            },
            })
            .then(response => {
    
                toastr.success("Success","New Config details added");
                toggle();
                setReload(reload + 1);
            
    
            })
            .catch(error => {
                if (error.response.data.message) {
                    toastr.error("Error",error.response.data.message)
                } 
                else {
                    toastr.error("Error","Something went wrong !")
                }  
    
    
            });
        }
    }

    useEffect(() => {
        if (!modal) {
            setModal(false);
            setModalState("initial");
            setEtlAllergy(0);
            setEtlFacility(0);
            setEtlCPTCharge(0);
            setEtlFacility(0);
            setEtlImmune(0);
            setEtlLabs(0);
            setEtlMedication(0);
            setEtlCPTCharge(0);
            setEtlPatientICD(0);
            setGroupID("");
            setBaseUrl("");
            setPrefix("");
            setLoginUrl("");
            setVersion("");
            setEtlPatientICD(0);
            setEtlPatientCPT(0);
            setEtlPatientInsuranceList(0);
            setEtlAppointments(0);
            setEtlVital(0);
            setUserName("");
            setPassword("");
            setSelectedGroupName("");
            setEtlReferral(0);
            setEtlPatientList(0);
           
        };

    }, [modal]) // eslint-disable-line

    useEffect(()=>{
        if(modal && groupNamesForDropDown && modalState === "edit") {
            setDefaultGroupName()
        }
     
    },[modal,groupNamesForDropDown]) // eslint-disable-line

    return (
        <div>
            <Modal isOpen={modal} size="lg" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>{modalState === "add" ? "New ETL Config " : "Edit ETL Config"} </ModalHeader>
                <ModalBody className="overflow-modal">
                    <Container fluid>
                        <FormGroup>
                            <Row>
                                <Col sm={6}>
                                    <Label for="user_name">User Name</Label>
                                    <Input
                                        id="user_name"
                                        placeholder="User Name"
                                        value={userName}
                                        onChange={(e) => {
                                            setUserName(e.target.value)

                                        }}
                                    />
                                </Col>


                                <Col sm={6}>
                                    <Label for="password">Password</Label>
                                    <Input
                                        id="password"
                                        type="text"
                                        placeholder="Password"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value)
                                    }}

                                    />

                                </Col>

                            </Row><br />
                            <Row>
                                <Col sm={6}>
                                    <Label for="base_url">Base Url</Label>
                                    <Input
                                        id="base_url"
                                        type="text"
                                        placeholder="Base Url"
                                        value={baseUrl}
                                        onChange={(e) => {
                                            setBaseUrl(e.target.value)
                                        }}

                                    />

                                </Col>


                                <Col sm={6}>
                                    <Label for="login_url">Login Url</Label>
                                    <Input
                                        id="login_url"
                                        type="text"
                                        placeholder="Login Url"
                                        value={loginUrl}
                                        onChange={(e) => {
                                            setLoginUrl(e.target.value)
                                        }}

                                    />


                                </Col>
                            </Row><br />
                            <Row>
                                <Col sm={4}>
                                <Label>Group Name</Label>
                                    <Select
                                    className="pandadoc-select"
                                    name="Group"
                                    options={groupNamesForDropDown}
                                    placeholder="Enter group name"
                                    // isDisabled = {!m_zipList || !adminNames ?true:false}
                                    isSearchable
                                    isClearable
                                    value={ selectedGroupName
                                        }
                                    onChange={(e) => {
                                        // console.log(e)
                                        setSelectedGroupName(e)
                                    }}
                                    />
                                    {/* <Label for="group">Group</Label>
                                    <Input
                                        id="group"
                                        type="text"
                                        placeholder="Group"
                                        value={groupID}
                                        onChange={(e) => {
                                            setGroupID(e.target.value)
                                        }}

                                    /> */}

                                </Col>


                                <Col sm={4}>
                                    <Label for="version">Version</Label>
                                    <Input
                                        id="version"
                                        type="text"
                                        placeholder="Version"
                                        value={version}
                                        onChange={(e) => {
                                            setVersion(e.target.value)
                                        }}

                                    />


                                </Col>
                                <Col sm={4}>
                                    <Label for="prefix">Prefix</Label>
                                    <Input
                                        id="prefix"
                                        type="text"
                                        placeholder="Prefix"
                                        value={prefix}
                                        onChange={(e) => {
                                            setPrefix(e.target.value)
                                        }}

                                    />


                                </Col>
                            </Row><br />
                            
                            <Row>
                                <Col sm={6}>
                                    
                                    <Label>Reports</Label>
                                </Col>
                            </Row>
                            <Container fluid>
                            <Row>
                                <Col sm={2}>
                                <Input
                                        id="allergy_checkbox"
                                        type="checkbox"
                                        disabled = {true}
                                        checked = {etlAllergy ? true:false}
                                        onChange={(e) => {
                                         setEtlAllergy(e.target.checked)
                                           
                                        }}

                                    />
                                 <Label for="allergy_checkbox">Allergy</Label>
                                </Col>
                                <Col sm={2}>
                                <Input
                                        id="facility_checkbox"
                                        type="checkbox"
                                        checked = {etlFacility ? true:false}
                                        disabled = {true}
                                        onChange={(e) => {
                                         setEtlFacility(e.target.checked)
                                            
                                        }}

                                    />
                                 <Label for="allergy_checkbox">Facility</Label>
                                </Col>
                                <Col sm={2}>
                                <Input
                                        id="cpt_charge_checkbox"
                                        type="checkbox"
                                        checked = {etlCPTCharge ? true:false}
                                        onChange={(e) => {
                                         setEtlCPTCharge(e.target.checked)
                                            
                                        }}

                                    />
                                 <Label for="allergy_checkbox">CPT Charge</Label>
                                </Col>
                                <Col sm={2}>
                                <Input
                                        id="immune_checkbox"
                                        type="checkbox"
                                        checked = {etlImmune ? true:false}
                                        onChange={(e) => {
                                         setEtlImmune(e.target.checked)
                                            
                                        }}

                                    />
                                 <Label for="allergy_checkbox">Immunization</Label>
                                </Col>
                                <Col sm={2}>
                                <Input
                                        id="labs_checkbox"
                                        type="checkbox"
                                        checked = {etlLabs ? true:false}
                                        onChange={(e) => {
                                         setEtlLabs(e.target.checked)
                                            
                                        }}

                                    />
                                 <Label for="allergy_checkbox">Labs</Label>
                                </Col>
                                <Col sm={2}>
                                <Input
                                        id="medication_checkbox"
                                        type="checkbox"
                                        checked = {etlMedication ? true:false}
                                        onChange={(e) => {
                                         setEtlMedication(e.target.checked)
                                          
                                        }}

                                    />
                                 <Label for="allergy_checkbox">Medication</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2}>
                                <Input
                                        id="patient_cpt_checkbox"
                                        type="checkbox"
                                        checked = {etlPatientCPT ? true:false}
                                        onChange={(e) => {
                                         setEtlPatientCPT(e.target.checked)
                                           
                                        }}

                                    />
                                 <Label for="allergy_checkbox">Patient CPT</Label>
                                </Col>
                              
                                <Col sm={2}>
                                <Input
                                        id="patient_icd_checkbox"
                                        type="checkbox"
                                        checked = {etlPatientICD ? true:false}
                                        onChange={(e) => {
                                         setEtlPatientICD(e.target.checked)
                                           
                                        }}

                                    />
                                 <Label for="patient_icd_checkbox">Patient ICD</Label>
                                </Col>
                                <Col sm={2}>
                                <Input
                                        id="patient_list_checkbox"
                                        type="checkbox"
                                        checked = {etlPatientList ? true:false}
                                        onChange={(e) => {
                                            setEtlPatientList(e.target.checked)
                                            
                                        }}

                                    />
                                 <Label for="patient_list_checkbox">Patient List</Label>
                                </Col>
                                <Col sm={2}>
                                <Input
                                        id="patient_insurance_checkbox"
                                        type="checkbox"
                                        checked = {etlPatientInsuranceList ? true:false}
                                        onChange={(e) => {
                                         setEtlPatientInsuranceList(e.target.checked)
                                          
                                        }}

                                    />
                                 <Label for="patient_insurance_checkbox"> Patient Insurance List</Label>
                                </Col>
                                <Col sm={2}>
                                <Input
                                        id="patient_referral_checkbox"
                                        type="checkbox"
                                        checked = {etlReferral ? true:false} 
                                        onChange={(e) => {
                                         setEtlReferral(e.target.checked)
                                            
                                        }}

                                    />
                                 <Label for="patient_referral_checkbox">Referral</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2}>
                                <Input
                                        id="vital_checkbox"
                                        type="checkbox"
                                        checked = {etlVital ? true:false}
                                        onChange={(e) => {
                                         setEtlVital(e.target.checked)
                                           
                                        }}

                                    />
                                 <Label for="vital_checkbox">Vital</Label>
                                </Col>
                                <Col sm={2}>
                                <Input
                                        id="appointments_checkbox"
                                        type="checkbox"
                                        checked = {etlAppointments ? true:false}
                                        onChange={(e) => {
                                         setEtlAppointments(e.target.checked)
                                           
                                        }}

                                    />
                                 <Label for="appointments_checkbox">Appointments</Label>
                                </Col>

                               

                            </Row>
                            </Container>

                                   
                             
                          <br />

                        </FormGroup>
                    </Container>

                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button

                        onClick={() => {
                          modalState === 'edit' ? editEtlConfigDetails():addNewEtlConfigDetails()
                        }}
                        disabled={buttonBlankState}
                    // title= {buttonBlankState ? "Please fill in all mandatory fields":(!buttonBlankState && addState)?"Click to save":"Click to update"}

                    >{modalState === 'edit' ? 'Update' : 'Add'}</Button>{' '}

                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ETLConfigModal;