
        
import React, { useState, useEffect } from 'react';
import { Button, Modal,Spinner, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, FormGroup, Input} from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';

import AsyncSelect from 'react-select/async';

const EditAllPatientIndicationsModal = (props) => {
    const {
        
        editPatientIndicationID,
        setUserDataReload,
        userDataReload,
        editPatientIndicationsModal,
        setEditPatientIndicationsModal,
        editGroupName,
        setEditGroupName,
        editPatientIndicationDescription,
        setEditPatientIndicationDescription,
        editPatientIndicationTitle,
        setEditPatientIndicationTitle
       
       
    } = props;

    
    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
   
    const [spinner,setSpinner] = useState(false);
    const [updateButtonStatus, setUpdateButtonStatus] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [inputValue, setInputValue] = useState('');
    const toggle = () => { setEditPatientIndicationsModal(!editPatientIndicationsModal); }   
    const confirmation = () => {
        if (!editGroupName) {
            toastr.error("Name Field Is Required", "Please enter the group name!")
        }

        if (!editPatientIndicationDescription) {
            toastr.error("description Field Is Required", "Please enter the description!")
        }
        if (!editPatientIndicationTitle) {
            toastr.error("Title Field Is Required", "Please enter the Title!")
        }
       

        if (editGroupName && editPatientIndicationDescription && editPatientIndicationTitle) {

            updatePatientIndicationCall()
        }

    };

    const updatePatientIndicationCall = () => {
        setSpinner(true)
        
       
        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-new-patient-indication/`, {

            "Patient_Indications_ID": editPatientIndicationID,
            "groupID": editGroupName ? editGroupName.value : "",
            "description": editPatientIndicationDescription ?editPatientIndicationDescription:"",
            "title": editPatientIndicationTitle ? editPatientIndicationTitle:""
            

        }, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                setSpinner(false)
                setEditPatientIndicationsModal(!editPatientIndicationsModal)
                setUserDataReload(userDataReload + 1)
                toastr.success("Successfully Updated", "patient Indications details successfully updated")
            })
            .catch(function (error) {
               
                setSpinner(false)
                toastr.error("Update Failed", "Please try again")
            });
    }

   
    const handleInputChange = value => {
        setInputValue(value);
      };
      const handleGroupNameChange = value => {
        setEditGroupName(value);
      }
     



    const groupNameloadOptions = (inputValue) => {
        if(inputValue.length >= 4){
            const searchParams = new URLSearchParams ({name: inputValue});
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
             {
             headers: {
               Authorization: API_TOKEN,
             }
           })
         .then((response) => response.data.data)
         .catch((error) => {
            throw error;
         });
        }
         
      };

    //   const fetchGroupById = (editGroupName) => {

    //     axiosinter.get(`${API_ENDPOINT}/admin-api/get-m-centerid-list/${Number(editCenter)}`, {
    //     headers: {
    //         Authorization: API_TOKEN,
    //     },
    //     })
    //     .then(response => {
          
    //         if (response.data.data.length > 0) {
    //            setCenter(response.data.data)
    //         }
    //     })
    //     .catch(error => {
            
    //         toastr.error('Error','Something went wrong in region listing !!')
        
    //     });
    //   };
    

    const disableUpdateButton = () => {

        if (editGroupName && editPatientIndicationDescription && editPatientIndicationTitle ) {
            setUpdateButtonStatus(false)
        }
        else {
            setUpdateButtonStatus(true)
        }
    }

    useEffect(() => { disableUpdateButton();}, [editGroupName,editPatientIndicationDescription,editPatientIndicationTitle]) // eslint-disable-line
   


//    useEffect(()=>{
//     if(editGroupName !== '' && editGroupName !== undefined){

//         handleGroupNameChange(inputValue)
//     }
//   },[editGroupName])   // eslint-disable-line
   
    
    
    
   
   

    return (
        <div>
            <Modal isOpen={editPatientIndicationsModal} size="lg" scrollable={true}>
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Edit Patient indications </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                {spinner ? <Spinner/>:""}
                    <FormGroup>
                        <Row>
                            <Col sm={6} >
                                    <Label>
                                       Group Name :<span className="required">*</span> 
                                    </Label>
                                    <AsyncSelect
                        cacheOptions
                        isClearable
                        isSearchable
                      
                          type="text"
                          name="groupName"
                          placeholder="Search with at least 4 characters."
                          value={editGroupName}
                          getOptionLabel={e => e.label}
                          getOptionValue={e => e.value}
                          validate={{
                              maxLength: {value: 120}
                            }} 
                            onInputChange={handleInputChange}
                            onChange={handleGroupNameChange}
                          loadOptions={inputValue =>groupNameloadOptions(inputValue)}
                            />
                               
                            </Col>
                            <Col sm={6}>
                                    <Label>
                                        Title  :<span className="required">*</span>
                                    </Label>
                                    <Input
                                        id="title"
                                        
                                        value={editPatientIndicationTitle}
                                        placeholder="Enter the description"
                                        onChange={(e) => { if (e.target.value.length <= 150) setEditPatientIndicationTitle(e.target.value) }}
                                    />
                               
                            </Col>
                      <Col sm={6}>
                                    <Label>
                                    Description:<span className="required">*</span> 
                                    </Label>
                                    <Input
                                        id="Description"
                                        type="textarea"
                                        rows = {3}
                                        cols = {2}
                                        
                                        value={editPatientIndicationDescription}
                                        placeholder="Enter the description"
                                        onChange={(e) => { if (e.target.value.length <= 150) setEditPatientIndicationDescription(e.target.value) }}
                                    />
                               
                            </Col>
                         
                        </Row><br/>
                        
                         


                         
                        
                        
                   
                       
                    </FormGroup>


                </ModalBody>
                <ModalFooter className="advanced-search-button custom-modal-footer">
                    <Button
                        onClick={()=>{
                           confirmation()
                        }}
                        disabled={updateButtonStatus}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditAllPatientIndicationsModal;