import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'

const ReferralSenderStatusChangeModal = (props) => {
  const {
    ReferralSenderStatusModal,
    setReferralSenderStatusModal,
    referralReferralSenderID,
    referralSenderStatus,
    setReferralSenderReload,
    referralSenderReload
    } = props;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");

  const toggle = () => {
    setReferralSenderStatusModal(!ReferralSenderStatusModal);
  };

  const confirmation = () => {
    let newStatus = "";
    if (referralSenderStatus === 0) {
      newStatus = 1
    } else {
      newStatus = 0
    }

    const updatedStatus = {
      'referral_sender_id': referralReferralSenderID,
      'status': newStatus,
    };

    AxiosInter.post( `${API_ENDPOINT}/admin-api/referral-sender-status-update/`, updatedStatus,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
        
        setReferralSenderStatusModal(!ReferralSenderStatusModal);
        setReferralSenderReload(referralSenderReload+1)
        toastr.success('Status Updated', 'Referral Sender Status updated successfully');
        
      })
      .catch((error) => {
        toastr.error('Status Update failed', 'Please try again');
      });
  };

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={ReferralSenderStatusModal} >
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Status</ModalHeader>
        <ModalBody>
            <div>
              Are you sure you want to change the Referral Sender Status to {referralSenderStatus === 0? 'active ?':'inactive ?' } 
            </div>
        </ModalBody>
        <ModalFooter  className="custom-modal-footer">
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReferralSenderStatusChangeModal;
