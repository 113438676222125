import React, { useState, useEffect, useContext } from "react";
import AxiosInter from './../../AxiosInter.js';
import Cookie from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Spinner,
} from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import ExportCSVTable from "./AppUsersDataTable";
import {
  faEdit,
  faUserShield,
  faKey,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalState } from "../../contextAPI/Context";
import NewAppUserModal from "../../newComponents/utilities/NewAppUserModal";
import EditAppUserModal from "../../newComponents/utilities/EditAppUserModal";
import StatusChangeModalAppUsers from "../../newComponents/utilities/StatusChangeModalAppUsers";
import CSVUploadModal from "../../newComponents/utilities/CSVUploadModal";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import MomentConfig from "../../common/MomentConfig";
import AddPayerIdForAppUsersModal from "../../newComponents/utilities/AddPayerIdForAppUsersModal";
import AppUserPasswordResetMailModal from "../../newComponents/utilities/AppUserPasswordResetMailModal";
import ViewPermissionsForAppUsersModal from "../../newComponents/utilities/ViewPermissionsForAppUsersModal";

const AppUsers = () => {
  // CONTEXT VARIABLES
  const {
    appUserName,
    appUserRole,
    appUserUsername,
    appUserDesignation,
    appUserNPI,
    appUserEmail,
    appUserStatus,
    appUserGroup,
    spinner,
    setSpinner,
    appUserReload,
    setAppUserReload,
    setBlankStateMessage,
    groupNamesList,
    countryCodeList,
    FetchCountryListAPICall,
    appUserEmailDigest,
  } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [signature, setSignature] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [unselectedRows, setUnselectedRows] = useState([]);
  const [appUsersList, setAppUserList] = useState([]);
  const [auId, setAuId] = useState();
  // const [auEmail, setAuEmail] = useState(""); // eslint-disable-line
  const [PasswordMailModal, setpasswordMailModal] = useState(false);
  const [rowData, setRowData] = useState("");
  const [userName, setUserName] = useState("");
  const [payerAssignedProviderId, setPayerAssignedProviderId] = useState([]); // eslint-disable-line
  const [insuranceValue, setInsuranceValue] = useState([]);
  const [payorIds, setPayorIds] = useState([]);
  const [newAppUserModal, setNewAppUserModal] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [AppUserID, setAppUserID] = useState("");
  const [status, setStatus] = useState("");
  const [addPayerIdForAppUsersModal, setAddPayerIdForAppUsersModal] = useState(false);
  const [viewPermissionsForAppUsersModal, setViewPermissionsForAppUsersModal] = useState(false);
  const [editAppUserModal, setEditAppUserModal] = useState(false);
  const [editAppUserID, setEditAppUserID] = useState("");
  const [editAppUserName, setEditAppUserName] = useState("");
  const [editFirstName, setEditFirstName] = useState("");
  const [editMiddleName, setEditMiddleName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editAppUserType, setEditAppUserType] = useState("");
  const [editAppUserDesignation, setEditAppUseDesignation] = useState("");
  const [editAppUserNPI, setEditAppUserNPI] = useState("");
  const [editAppUserEmail, setEditAppUseEmail] = useState("");
  const [editAppUserUserName, setEditAppUseUserName] = useState("");
  const [editAppUserGroupID, setEditAppUseGroupID] = useState("");
  const [editAppUserPassword, setEditAppUsePassword] = useState("");
  const [editAppUserZIP, setEditAppUseZIP] = useState("");
  const [editAppUserSSN, setEditAppUserSSN] = useState("");
  const [editAppUserPhone, setEditAppUserPhone] = useState("");
  const [editAppUserIsServiceProvider, setEditAppUserIsServiceProvider] = useState("");
  const [editAppUserSecurityID, setEditAppUserSecurityID] = useState("");
  const [externalUserId, setExternalUserId] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [newnNotificationEmail, setNewNotificationEmail] = useState([]);
  const [selectedInsurances, setSelectedInsurances] = useState([]); // eslint-disable-line
  const [offset, setOffset] = useState("0");
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [sizePerPage, setSizePerPage] = useState("50");
  const [searchKeys, setSearchKeys] = useState("");
  const [uploadModal, setUploadModal] = useState(false);
  const [groupDropDownOptions, setGroupDropDownOptions] = useState([]);
  const [appUserEditStatus, setAppUserEditStatus] = useState("");
  const [inputList, setInputList] = useState([{ payerId: "", stdPayerId: "" }]);
  const [userGroupStatus, setUserGroupStatus] = useState();
  const [editRestricPatientAccessToggle, setEditRestrictPatientAccessToggle] = useState(false);
  const [editEmailDigestToggle, setEditEmailDigestToggle] = useState(false);
  const [appUserIdList, setAppUserIdList] = useState([]);
  const fileExportName = "App-Users";
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const secretKey = 'equipo'; // eslint-disable-line
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const [externalModelEnabled, setExternalModelEnabled] = useState(false);
  const [externalModelSelected, setExternalModelSelected] = useState([]);
  const [isPayorIdnull, setIsPayorIdnull] = useState(false);

  const fetchAppUsersList = async () => {
    setSpinner(true);
    var temp = "";
    if (sortColumn === "designation") {
      temp = "m_role.designation";
    } else {
      temp = sortColumn;
    }
    const params = {
      app_user_name: appUserName ? appUserName : "",
      app_user_type: appUserRole ? Number(appUserRole.value) : 0,
      app_user_designation: appUserDesignation ? appUserDesignation : "",
      app_user_npi: appUserNPI ? appUserNPI : "",
      app_user_email: appUserEmail ? appUserEmail : "",
      app_user_status: appUserStatus ? appUserStatus.value : "",
      appUserEmailDigest: appUserEmailDigest ? appUserEmailDigest.value : "",
      app_user_username: appUserUsername ? appUserUsername : "",
      app_user_group_id: appUserGroup ? Number(appUserGroup.value) : 0,
      record_limit: sizePerPage ? sizePerPage.toString() : "50",
      off_set: offset ? offset.toString() : "0",
      search_keyword: searchKeys ? searchKeys : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: temp ? temp : "",
      is_pagination: "1",
      is_appUserPayerId_null: isPayorIdnull ? 1 : 0,
    };

    await AxiosInter.post(`${API_ENDPOINT}/admin-api/app-users-list/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => {
        setBlankStateMessage(response.data.data.length > 0 ? false : true);
        if (response.data.data) {
          setTotalSize(response.data.record_count);

          setAppUserList(response.data.data);
          localStorage.setItem("offset", offset);
          localStorage.removeItem("subscriptionFilter");
          localStorage.removeItem("groupNameFilter");
          localStorage.removeItem("statusFilter");
          localStorage.removeItem("adminNameFilter");
          localStorage.removeItem("zipCodeFilter");
          localStorage.removeItem("timeZoneFilter");
          setSpinner(false);
        } else {
          setAppUserList([]);
          setBlankStateMessage(true);
          setSpinner(false);
        }
      })
      .catch((error) => {

      });
  };
  const exportTableData = () => {
    var temp = "";
    if (sortColumn === "designation") {
      temp = "m_role.designation";
    } else {
      temp = sortColumn;
    }
    const params = {
      file_name: fileName ? fileName : "",
      app_user_name: appUserName ? appUserName : "",
      app_user_type: appUserRole ? Number(appUserRole.value) : 0,
      app_user_npi: appUserNPI ? appUserNPI : "",
      app_user_email: appUserEmail ? appUserEmail : "",
      app_user_status: appUserStatus ? appUserStatus.value : "",
      appUserEmailDigest: appUserEmailDigest ? appUserEmailDigest.value : "",
      app_user_username: appUserUsername ? appUserUsername : "",
      app_user_group_id: appUserGroup ? Number(appUserGroup.value) : 0,
      record_limit: "",
      off_set: offset ? offset.toString() : "0",
      search_keyword: searchKeys ? searchKeys : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: temp ? temp : "",
      is_pagination: "1",
      is_appUserPayerId_null: isPayorIdnull ? 1 : 0,
    };

    AxiosInter.post(
      `${API_ENDPOINT}/admin-api/export-appusers-table-data/`,
      params,
      {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      }
    )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/logout");
        } else {
          toastr.error("Failed", "Something went wrong");
        }
      });
  };

  const createGroupOption = () => {
    var array = groupNamesList;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setGroupDropDownOptions(options);
  };

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else if (cell === 0) {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    } else {
      status = "Locked";
      badgeclass = "badge badge-warning statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setAppUserID(row.id);
          setStatus(cell);
          setModalState(true);
        }}
      >
        {status}
      </span>
    );
  };
  const EmailFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";

    if (row.digestEmailEnabled === 1) {
      status = "Enabled";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "disabled";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
      >
        {status}
      </span>
    );
  };
  const EmailFormatterCSV = (cell) => {
    return cell ? "Enabled" : "Disabled";
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive";
  };

  const TimeFormatter = (cell) => {
    return cell
      ? `${moment(cell).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)} (UTC)`
      : "--";
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button-blue"
          size={"1x"}
          onClick={() => {
            // storing external model informations to states
            setExternalModelEnabled(row.isExternalModelEnabled)
            setExternalModelSelected(JSON.parse(row?.externalModel))
            setEditAppUserModal(true);
            editUserData(
              row.GroupID,
              row.id,
              row.TypeID,
              row.designation,
              row.status,
              row.email,
              row.name,
              row.npi,
              row.password,
              row.username,
              row.zipID,
              row.ssn,
              row.phone,
              row.isServiceProvider,
              row.SecurityID,
              row.userGroupStatus,
              row.firstName,
              row.middleName,
              row.lastName,
              row.restricted_patient_access,
              row.digestEmailEnabled,
              row.signatureCredential,
              row.externalUserId,
            );
            onClickNotigicationP(row.notificationEmail);
          }}
          title="Edit App User"
        >
          <FontAwesomeIcon
            icon={faEdit}
            className="align-middle"
            title="Edit App User"
          />
        </Button>
        {
          // eslint-disable-next-line eqeqeq
          row.TypeID == 1 ? (
            <>
              <Button
                className="money-button-blue"
                onClick={() => {
                  setAddPayerIdForAppUsersModal(true);
                  setAuId(row.id);
                  setUserName(row.name);
                  setInsuranceValue(
                    row.InsuranceNumber ? JSON.parse(row.InsuranceNumber) : ""
                  );
                  setPayorIds(row.payerIds ? JSON.parse(row.payerIds) : "");
                  setInputList(
                    row.payerAssignedProviderId
                      ? JSON.parse(row.payerAssignedProviderId)
                      : ""
                  );
                }}
                color="#3B7DDD"
                size={"1x"}
                title=""
              >
                <FontAwesomeIcon
                  icon={faUserShield}
                  fixedWidth
                  className="align-middle"
                  title="Update Payer Id"
                />
              </Button>
            </>
          ) : (
            ""
          )
        }
        <Button
          className="money-button-blue"
          onClick={() => {
            setViewPermissionsForAppUsersModal(true);
            setAuId(row.id);
            setUserName(row.name);
          }}
          color="#3B7DDD"
          size={"1x"}
          title=""
        >
          <FontAwesomeIcon
            icon={faCog}
            fixedWidth
            className="align-middle"
            title="User Permissions"
          />
        </Button>
        <Button
          className="money-button-blue"
          onClick={() => {
            setRowData(row);
            setpasswordMailModal(true);
          }}
          color="#3B7DDD"
          size={"1x"}
          title=""
        >
          <FontAwesomeIcon
            icon={faKey}
            fixedWidth
            className="align-middle"
            title="Reset Password"
          />
        </Button>
      </>
    );
  };
  const onClickNotigicationP = (email) => {
    var temp = [];
    if (email === null) {
      setNewNotificationEmail("");
    } else {
      var parsedArray = JSON.parse(email);
      // eslint-disable-next-line array-callback-return
      parsedArray.map((item) => {
        temp.push(item.trim());
      });
    }
    setNewNotificationEmail(temp);
  };

  const editUserData = (
    GroupIDs,
    id,
    TypeID,
    designation,
    status,
    email,
    name,
    npi,
    password,
    username,
    zipID,
    ssn,
    phone,
    isServiceProvider,
    securityID,
    userGroupStatus,
    firstName,
    middleName,
    lastName,
    restricted_patient_access,
    digestEmailEnabled,
    signatureCredential,
    externalUserId
  ) => {
    if (signatureCredential) {
      setSignature(signatureCredential)
    }
    else {
      setSignature('')
    }
    setEditAppUserID(id);
    setEditAppUserName(name);
    setEditAppUserType(TypeID);
    setAppUserEditStatus(status.toString());
    setEditAppUseDesignation(designation);
    setEditAppUserNPI(npi);
    setEditAppUseEmail(email);
    setEditAppUseUserName(username);
    if (securityID) {
      setEditAppUserSecurityID(securityID.toString());
    } else {
      setEditAppUserSecurityID(securityID);
    }
    setEditAppUseGroupID(GroupIDs);
    setEditAppUsePassword(password);
    setEditAppUseZIP(zipID);
    setEditAppUserSSN(ssn);
    setEditAppUserPhone(phone);
    setEditAppUserIsServiceProvider(isServiceProvider);
    setUserGroupStatus(userGroupStatus);
    setEditFirstName(firstName);
    setEditMiddleName(middleName);
    setEditLastName(lastName);
    setEditRestrictPatientAccessToggle(restricted_patient_access);
    setEditEmailDigestToggle(digestEmailEnabled);
    setExternalUserId(externalUserId)
  };

  const tableColumns = [
    {
      dataField: "username",
      text: "User Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "GroupName",
      text: "Group Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "designation",
      text: "Role Type",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "externalUserId",
      text: "External Userid",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
    },
    {
      dataField: "digestEmailEnabled",
      text: "Email Digest",
      formatter: EmailFormatter,
      csvFormatter: EmailFormatterCSV,
    },
    {
      dataField: "lastLoginTime",
      text: "Last Login",
      formatter: TimeFormatter,
      csvFormatter: TimeFormatter,
    },
    {
      dataField: "createDate",
      text: "Create Date",
      formatter: TimeFormatter,
      csvFormatter: TimeFormatter,
    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    }, {
      text: "Select All",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const [selectedRows, setSelectedRows, unselectedRows, setUnselectedRows] = formatExtraData; // eslint-disable-line
        const handleSelect = (e) => {
          if (e.target.checked) {
            setSelectedRows((rows) => [...rows, row.id]);
            setUnselectedRows((rows) => rows.filter((r) => r !== row.id));
          } else {
            setSelectedRows((rows) => rows.filter((r) => r !== row.id));
            setUnselectedRows((rows) => [...rows, row.id]);
          }
        };
        return (
          <input
            type="checkbox"
            checked={selectedRows.includes(row.id)}
            onChange={handleSelect}
          />
        );
      },
      headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
        const handleSelectAll = (e) => {
          if (e.target.checked) {
            const allIds = appUsersList.map((row) => row.id);
            setSelectedRows(allIds);
            setUnselectedRows([]);
          } else {
            setSelectedRows([]);
            setUnselectedRows(appUsersList.map((row) => row.id));
            let allid = [appUsersList.map((row) => row.id)]

            if (allid.length > 0) {
            }
          }
        };
        const allChecked = appUsersList.length > 0 && selectedRows.length === appUsersList.length;
        const indeterminate = selectedRows.length > 0 && !allChecked;
        return (
          <div className="custom-control custom-checkbox">
            <input
              className="custom-control-input"
              type="checkbox"
              id={`select-all-${column.dataField}`}
              checked={allChecked}
              onChange={handleSelectAll}
              ref={(el) => {
                if (el) {
                  el.indeterminate = indeterminate;
                }
              }}
            />
            <label
              className="custom-control-label"
              htmlFor={`select-all-${column.dataField}`}
            >
              Select All
            </label>
          </div>
        );
      },
      formatExtraData: [selectedRows, setSelectedRows, unselectedRows, setUnselectedRows]
    }
  ];
  useEffect(() => {
  }, [selectedRows])
  useEffect(() => {

  }, [unselectedRows])
  const handleTableChange = (type, newState) => {

    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("");
      setSortOrder("");
    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setAppUserReload(appUserReload + 1);
    }

  };

  // DEFAULT API CALL

  useEffect(() => {
    if (appUserReload > 0) {
      fetchAppUsersList();
    }
  }, [appUserReload]); // eslint-disable-line

  useEffect(() => {
    if (countryCodeList.length <= 0) {
      FetchCountryListAPICall();
    }
  }, [countryCodeList]); // eslint-disable-line

  useEffect(() => {
    if (
      appUserName !== "" ||
      appUserRole !== "" ||
      appUserNPI !== "" ||
      appUserEmail !== "" ||
      appUserStatus !== "" ||
      appUserEmailDigest !== "" ||
      appUserUsername !== "" ||
      appUserGroup !== ""
    ) {
      fetchAppUsersList();
    }
  },
    [appUserName, appUserRole, appUserDesignation, appUserNPI, appUserEmail, appUserStatus, appUserEmailDigest, appUserUsername, appUserGroup] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    // eslint-disable-next-line eqeqeq 
    if (searchKeys.length > 3 || searchKeys == "")
      setAppUserReload(appUserReload + 1);
  }, [searchKeys]); // eslint-disable-line

  useEffect(() => {
    if (groupNamesList) {
      createGroupOption();
    }
  }, [groupNamesList]); // eslint-disable-line

  useEffect(() => {
    if (appUsersList) {
      localStorage.setItem("sizePerPage", sizePerPage);
    }
  }, [appUsersList]); // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset) {
      fetchAppUsersList();
    }
  }, [offset, sizePerPage]); // eslint-disable-line
  let appuserid = [];
  let digiestenabled = []; // eslint-disable-line
  useEffect(() => {

    // eslint-disable-next-line array-callback-return
    appUsersList.map((item) => {
      appuserid.push(item.id);
    });
    setAppUserIdList(appuserid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUsersList]);
  useEffect(() => {

  }, [appUserIdList]);
  const handleBulkDigest = async () => { // eslint-disable-line
    try {

      setIsLoading(true);
      if (appUserIdList.length === 0) {
        toastr.warning("No data to assign email digest");
        setIsLoading(false);
        return;
      }

      const response = await AxiosInter.post(`${API_ENDPOINT}/admin-api/bulk-enable-email-digest/`, {
        selectedRows: [], unselectedRows: appUserIdList,
      }, {
        headers: {
          Authorization: API_TOKEN,
        }
      });
      if (response.status === 200) {
        setIsLoading(false);
        setAppUserReload(appUserReload + 1);
        toastr.success("Email digest updated");
      }
    } catch (error) {
      setIsLoading(false);
      toastr.error("Error occurred");
    }
  };
  useEffect(() => {
    fetchAppUsersList()
  }, [isPayorIdnull]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Hux>
      <Container fluid>
        <Header>
          <HeaderTitle>
            App Users
            <Button
              className="Add-btn pull-right"
              onClick={() => {
                setNewAppUserModal(true);
              }}
            >
              Add App User
            </Button>{" "}
            <Button
              className="Add-btn pull-right"
              style={{ marginRight: "0.225rem" }}
              onClick={() => {
                setUploadModal(true);
              }}
            >
              Import App Users
            </Button>{" "}
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>App Users</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        {spinner ? <Spinner /> : ""}
        {isLoading && <Spinner />}
        <ExportCSVTable
          data={appUsersList}
          columns={tableColumns}
          onTableChange={(type, newState) => {
            handleTableChange(type, newState);
          }}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize ? totalSize : 0}
          setPage={setPage}
          setOffset={setOffset}
          setTotalSize={setTotalSize}
          setSearchKeys={setSearchKeys}
          searchKeys={searchKeys}
          appUserReload={appUserReload}
          setAppUserReload={setAppUserReload}
          offset={offset}
          exportTableData={exportTableData}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          unselectedRows={unselectedRows}
          setUnselectedRows={setUnselectedRows}
          setIsPayorIdnull={setIsPayorIdnull}
        />
      </Container>
      <NewAppUserModal
        newAppUserModal={newAppUserModal}
        setNewAppUserModal={setNewAppUserModal}
        signature={signature}
        setSignature={setSignature}
      />
      <EditAppUserModal
        signature={signature}
        setSignature={setSignature}
        editAppUserModal={editAppUserModal}
        setEditAppUserModal={setEditAppUserModal}
        editAppUserName={editAppUserName}
        setEditAppUserName={setEditAppUserName}
        editAppUserType={editAppUserType}
        setEditAppUserType={setEditAppUserType}
        editAppUserNPI={editAppUserNPI}
        editAppUserDesignation={editAppUserDesignation}
        setEditAppUseDesignation={setEditAppUseDesignation}
        setEditAppUserNPI={setEditAppUserNPI}
        editAppUserEmail={editAppUserEmail}
        setEditAppUseEmail={setEditAppUseEmail}
        editAppUserUserName={editAppUserUserName}
        setEditAppUseUserName={setEditAppUseUserName}
        editAppUserGroupID={editAppUserGroupID}
        setEditAppUseGroupID={setEditAppUseGroupID}
        editAppUserPassword={editAppUserPassword}
        setEditAppUsePassword={setEditAppUsePassword}
        editAppUserZIP={editAppUserZIP}
        setEditAppUseZIP={setEditAppUseZIP}
        editAppUserSSN={editAppUserSSN}
        setEditAppUserSSN={setEditAppUserSSN}
        editAppUserPhone={editAppUserPhone}
        setEditAppUserPhone={setEditAppUserPhone}
        editAppUserIsServiceProvider={editAppUserIsServiceProvider}
        setEditAppUserIsServiceProvider={setEditAppUserIsServiceProvider}
        setEditAppUserID={setEditAppUserID}
        editAppUserID={editAppUserID}
        editAppUserSecurityID={editAppUserSecurityID}
        setEditAppUserSecurityID={setEditAppUserSecurityID}
        appUserEditStatus={appUserEditStatus}
        setAppUserEditStatus={setAppUserEditStatus}
        newnNotificationEmail={newnNotificationEmail}
        setNewNotificationEmail={setNewNotificationEmail}
        userGroupStatus={userGroupStatus}
        editFirstName={editFirstName}
        setEditFirstName={setEditFirstName}
        editLastName={editLastName}
        setEditLastName={setEditLastName}
        editMiddleName={editMiddleName}
        setEditMiddleName={setEditMiddleName}
        setEditRestrictPatientAccessToggle={setEditRestrictPatientAccessToggle}
        editRestricPatientAccessToggle={editRestricPatientAccessToggle}
        editEmailDigestToggle={editEmailDigestToggle}
        setEditEmailDigestToggle={setEditEmailDigestToggle}
        externalModelSelected={externalModelSelected}
        externalModelEnabled={externalModelEnabled}
        setExternalModelSelected={setExternalModelSelected}
        setExternalModelEnabled={setExternalModelEnabled}
        externalUserId={externalUserId}
        setExternalUserId={setExternalUserId}
      />
      <StatusChangeModalAppUsers
        modalState={modalState}
        setModalState={setModalState}
        status={status}
        AppUserID={AppUserID}
        appUserReload={appUserReload}
        setAppUserReload={setAppUserReload}
      />
      <CSVUploadModal
        uploadModal={uploadModal}
        setUploadModal={setUploadModal}
        groupDropDownOptions={groupDropDownOptions}
        setGroupDropDownOptions={setGroupDropDownOptions}
        appUserReload={appUserReload}
        setAppUserReload={setAppUserReload}
      />
      <AddPayerIdForAppUsersModal
        addPayerIdForAppUsersModal={addPayerIdForAppUsersModal}
        setAddPayerIdForAppUsersModal={setAddPayerIdForAppUsersModal}
        auId={auId}
        payerAssignedProviderId={payerAssignedProviderId}
        insuranceValue={insuranceValue}
        payorIds={payorIds}
        setPayorIds={setPayorIds}
        userName={userName}
        inputList={inputList}
        setInputList={setInputList}
      />
      <AppUserPasswordResetMailModal
        PasswordMailModal={PasswordMailModal}
        setpasswordMailModal={setpasswordMailModal}
        rowData={rowData}
        setRowData={setRowData}
      />
      <ViewPermissionsForAppUsersModal
        viewPermissionsForAppUsersModal={viewPermissionsForAppUsersModal}
        setViewPermissionsForAppUsersModal={setViewPermissionsForAppUsersModal}
        userName={userName}
        auId={auId}
      />
    </Hux>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

export default connect(mapStateToProps, null)(AppUsers);

