import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./../layouts/Dashboard";
import Index from "../pages/dashboards/Default/index";
import PatientNew from "./../pages/tables/PatientNew";
import AWSQueue from "./../pages/tables/AWSQueue";
import AllPandaDoc from "./../pages/tables/AllPandaDoc";
import NPITableApiCall from "./../pages/tables/NPITableApiCall";
import PermissionManagerDataTable from "./../pages/tables/PermissionManagerDataTable";
import AllAdminUser from "./../pages/tables/AllAdminUser";
import AllReferral from "./../pages/tables/AllReferral";
import AllAppointmentTag from "./../pages/tables/AllAppointmentTag";
import Groups from './../pages/tables/Groups';
import GroupFacilities from './../pages/tables/GroupFacilities';
import GroupSecurity from './../pages/tables/GroupSecurity';
import AllFacility from "./../pages/tables/AllFacility";
import Encryption from "./../pages/tables/Encryption";
import AllAppUsers from "./../pages/tables/AllAppUsers";
import AllProviderSpecialityRanking from "./../pages/tables/AllProviderSpecialityRanking";
import SQLQueryRun from "./../newComponents/utilities/SQLQueryRun";
import OTPVerify from "./../newComponents/utilities/OTPVerify";
import AdminUserReg from "./../newComponents/utilities/AdminUserReg";
import AdminUserPasswordReset from "./../newComponents/utilities/AdminUserPasswordReset";
import UserLog from "../pages/tables/AccessLog";
import GroupApp from "../pages/tables/GroupApp";
import GroupPackages from "../pages/tables/GroupPackages";
import AllHabit from "../pages/tables/allhabit";
import AllProviderSupportedInsurances from "../pages/tables/AllProviderSupportedInsurances";
import AllServiceCategory from "../pages/tables/AllServiceCategory";
import AllPatientDocumentType from "../pages/tables/AllPatientDocumentType";
import { connect } from "react-redux";
import Logout from "./../pages/auth/Logout";
import * as actions from "./../redux/actions/index";
import SignIn from "./../pages/auth/SignIn";
import CPTComponent from "./../pages/tables/CPTComponent";
import Page404 from "./../newComponents/ErrorPages/Page404";
import ICDComponent from "./../pages/tables/ICDComponent";
import ETLComponent from "./../pages/tables/ETLComponent";
import SubscriptionPlanDetails from "./../pages/tables/SubscriptionPlanDetails";
import InAppCallLog from "./../pages/tables/InAppCallLog";
import Insurance from "./../pages/tables/Insurance";
import Packages from "./../pages/tables/Packages";
import FacilityMap from "./../pages/tables/facilitymap";
import LocationMarker from "./../pages/tables/LocationMarker"
import SetupSheet from "./../pages/tables/SetupSheet"

import AllFaxLog from "./../pages/tables/AllFaxLog";
import Immunizations from "./../pages/tables/Immunizations";
import DRG from "./../pages/tables/DRG"
import AllPatientNotes from "../pages/tables/AllPatientNotes";
import AllPatientIndications from "../pages/tables/AllPatientIndications";
import AllPatientEvaluations from "../pages/tables/AllPatientEvaluations";
import AutoProcessExceptionRule from "../pages/tables/AutoProcessExceptionRule";
import AutoProcessRule from "../pages/tables/AutoProcessRule";
import ReferralPreferance from "../pages/tables/ReferralPreferance";
import AppUserPasswordReset from "./../newComponents/utilities/AppUserPasswordReset";
import AllProvider from "../pages/tables/ProviderList";
import ReferralByTag from "../pages/tables/ReferralByTag";
import ProgramComponent from "../pages/tables/ProgramComponent";
import CareServiceProgramCategory from "../pages/tables/CareServiceProgramCategory";
import CareServiceProgramServiceProvided from "../pages/tables/CareServiceProgramServiceProvided";
import CareRosterServiceProvided from "../pages/tables/CareRosterServiceProvided";
import ServiceForms from "../pages/tables/ServiceForms";
import MicroServiceGroups from "../pages/tables/MicroServiceGroups";
import AuthServices from "../pages/tables/AuthServices";
import MicroServiceUsers from "../pages/tables/MicroServiceUsers";
import FormExchange from "../pages/tables/FormExchange";
import AllRefOrderTypes from "../pages/tables/AllRefOrderTypes.js";
import EmiApproval from "../pages/tables/EMIApproval";
import AllTemplate from "../pages/tables/AllPandaDocTemplates.js"
import Analytics from "../pages/tables/Analytics.js";
import HL7 from "../pages/tables/HL7.js";
import ModuleSubscriptions from "../pages/tables/ModuleSubscriptions.js";

class Rout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      autoSignInComplete: false,
    };
  }

  async componentDidMount() {
    await this.props.onTryAutoSignIn();
    this.setState({ autoSignInComplete: true });
  }

  render() {
    if (!this.state.autoSignInComplete) {
      // Loading state or indicator
      return (
        <div style={styles.loadingContainer}>
          <div className="spinner" style={styles.spinner}></div>
          <p></p>
        </div>
      );
    }
    return (
      <Router>
        <Routes>
          <Route path="/signup" element={<AdminUserReg />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/" element={this.props.isAuthenticated ? <Navigate to="/dashboard" /> : <SignIn />} />
          <Route path="/Verification" element={<OTPVerify />} />
          <Route path="/password-reset" element={<AdminUserPasswordReset />} />
          <Route path="/app-user-password-reset" element={<AppUserPasswordReset />} />
          <Route path="/dashboard" element={this.props.isAuthenticated ? <Dashboard><Index /></Dashboard> : <SignIn />} />
          <Route path="/patients" element={this.props.isAuthenticated ? <Dashboard><PatientNew /></Dashboard> : <SignIn />} />
          <Route path="/queues" element={this.props.isAuthenticated ? <Dashboard><AWSQueue /></Dashboard> : <SignIn />} />
          <Route path="/doc-signature" element={this.props.isAuthenticated ? <Dashboard><AllPandaDoc /></Dashboard> : <SignIn />} />
          <Route path="/npi" element={this.props.isAuthenticated ? <Dashboard><NPITableApiCall /></Dashboard> : <SignIn />} />
          <Route path="/permission-manager" element={this.props.isAuthenticated ? <Dashboard><PermissionManagerDataTable /></Dashboard> : <SignIn />} />
          <Route path="/users" element={this.props.isAuthenticated ? <Dashboard><AllAdminUser /></Dashboard> : <SignIn />} />
          <Route path="/referral-import" element={this.props.isAuthenticated ? <Dashboard><AllReferral /></Dashboard> : <SignIn />} />
          <Route path="/groups" element={this.props.isAuthenticated ? <Dashboard><Groups /></Dashboard> : <SignIn />} />
          <Route path="/icd" element={this.props.isAuthenticated ? <Dashboard><ICDComponent /></Dashboard> : <SignIn />} />
          <Route path="/appusers" element={this.props.isAuthenticated ? <Dashboard><AllAppUsers /></Dashboard> : <SignIn />} />
          <Route path="/groups/security/:id" element={this.props.isAuthenticated ? <Dashboard><GroupSecurity /></Dashboard> : <SignIn />} />
          <Route path="/facility" element={this.props.isAuthenticated ? <Dashboard><AllFacility /></Dashboard> : <SignIn />} />
          <Route path="/groups/facility/:id" element={this.props.isAuthenticated ? <Dashboard><GroupFacilities /></Dashboard> : <SignIn />} />
          <Route path="/sqlquery" element={this.props.isAuthenticated ? <Dashboard><SQLQueryRun /></Dashboard> : <SignIn />} />
          <Route path="/logout" element={this.props.isAuthenticated ? (
            <>
              <Logout />
              <Navigate to="/signin" replace />
            </>
          ) : (
            <SignIn />
          )} />
          <Route path="/Encryption" element={this.props.isAuthenticated ? <Dashboard><Encryption /></Dashboard> : <SignIn />} />
          <Route path={`/aws-s3/:bucketName/:folderName`} element={this.props.isAuthenticated ? <Dashboard /> : <SignIn />} />
          <Route path="/aws-s3/:bucketName" element={this.props.isAuthenticated ? <Dashboard /> : <SignIn />} />
          <Route path="/aws-s3" element={this.props.isAuthenticated ? <Dashboard /> : <SignIn />} />
          <Route path="/cpt" element={this.props.isAuthenticated ? <Dashboard><CPTComponent /></Dashboard> : <SignIn />} />
          <Route path="/subscription-plan-details" element={this.props.isAuthenticated ? <Dashboard><SubscriptionPlanDetails /></Dashboard> : <SignIn />} />
          <Route path="/fax-log" element={this.props.isAuthenticated ? <Dashboard><AllFaxLog /></Dashboard> : <SignIn />} />
          <Route path="/etl" element={this.props.isAuthenticated ? <Dashboard><ETLComponent /></Dashboard> : <SignIn />} />
          <Route path="/in-app-call-log" element={this.props.isAuthenticated ? <Dashboard><InAppCallLog /></Dashboard> : <SignIn />} />
          <Route path="/insurance" element={this.props.isAuthenticated ? <Dashboard><Insurance /></Dashboard> : <SignIn />} />
          <Route path="/packages" element={this.props.isAuthenticated ? <Dashboard><Packages /></Dashboard> : <SignIn />} />
          <Route path="/appointment-tag" element={this.props.isAuthenticated ? <Dashboard><AllAppointmentTag /></Dashboard> : <SignIn />} />
          <Route path="/provider-speciality-ranking" element={this.props.isAuthenticated ? <Dashboard><AllProviderSpecialityRanking /></Dashboard> : <SignIn />} />
          <Route path="/immunizations" element={this.props.isAuthenticated ? <Dashboard><Immunizations /></Dashboard> : <SignIn />} />
          <Route path="/drg" element={this.props.isAuthenticated ? <Dashboard><DRG /></Dashboard> : <SignIn />} />
          <Route path="/userlog" element={this.props.isAuthenticated ? <Dashboard><UserLog /></Dashboard> : <SignIn />} />
          <Route path="/groups/habits/:id" element={this.props.isAuthenticated ? <Dashboard><AllHabit /></Dashboard> : <SignIn />} />
          <Route path="/groups/ServiceCategory/:id" element={this.props.isAuthenticated ? <Dashboard><AllServiceCategory /></Dashboard> : <SignIn />} />
          <Route path="/groups/PatientDocumentType/:id" element={this.props.isAuthenticated ? <Dashboard><AllPatientDocumentType /></Dashboard> : <SignIn />} />
          <Route path="/groups/PatientNotes/:id" element={this.props.isAuthenticated ? <Dashboard><AllPatientNotes /></Dashboard> : <SignIn />} />
          <Route path="/groups/indications/:id" element={this.props.isAuthenticated ? <Dashboard><AllPatientIndications /></Dashboard> : <SignIn />} />
          <Route path="/groups/evaluations/:id" element={this.props.isAuthenticated ? <Dashboard><AllPatientEvaluations /></Dashboard> : <SignIn />} />
          <Route path="/groups/appusers/:id" element={this.props.isAuthenticated ? <Dashboard><GroupApp /></Dashboard> : <SignIn />} />
          <Route path="/groups/Packages/:GroupName" element={this.props.isAuthenticated ? <Dashboard><GroupPackages /></Dashboard> : <SignIn />} />
          <Route path="/facilitymap" element={this.props.isAuthenticated ? <Dashboard><FacilityMap /></Dashboard> : <SignIn />} />
          <Route path="/LocationMarker" element={this.props.isAuthenticated ? <Dashboard><LocationMarker /></Dashboard> : <SignIn />} />
          <Route path="/auto-process-exception-rule" element={this.props.isAuthenticated ? <Dashboard><AutoProcessExceptionRule /></Dashboard> : <SignIn />} />
          <Route path="/auto-process-rule" element={this.props.isAuthenticated ? <Dashboard><AutoProcessRule /></Dashboard> : <SignIn />} />
          <Route path="/ReferralPreferance" element={this.props.isAuthenticated ? <Dashboard><ReferralPreferance /></Dashboard> : <SignIn />} />
          <Route path="/Providerlist" element={this.props.isAuthenticated ? <Dashboard><AllProvider /></Dashboard> : <SignIn />} />
          <Route path="/ReferralByTag" element={this.props.isAuthenticated ? <Dashboard><ReferralByTag /></Dashboard> : <SignIn />} />
          <Route path="/programs" element={this.props.isAuthenticated ? <Dashboard><ProgramComponent /></Dashboard> : <SignIn />} />
          <Route path="/programs/category/:id" element={this.props.isAuthenticated ? <Dashboard><CareServiceProgramCategory /></Dashboard> : <SignIn />} />
          <Route path="/programs/service-provided/:id" element={this.props.isAuthenticated ? <Dashboard><CareServiceProgramServiceProvided /></Dashboard> : <SignIn />} />
          <Route path="/programs/roster-service-provided/:id" element={this.props.isAuthenticated ? <Dashboard><CareRosterServiceProvided /></Dashboard> : <SignIn />} />
          <Route path="/microservicegroups" element={this.props.isAuthenticated ? <Dashboard><MicroServiceGroups /></Dashboard> : <SignIn />} />
          <Route path="/authservices" element={this.props.isAuthenticated ? <Dashboard><AuthServices /></Dashboard> : <SignIn />} />
          <Route path="/microserviceusers" element={this.props.isAuthenticated ? <Dashboard><MicroServiceUsers /></Dashboard> : <SignIn />} />
          <Route path="/serviceforms" element={this.props.isAuthenticated ? <Dashboard><ServiceForms /></Dashboard> : <SignIn />} />
          <Route path="/FormExchange" element={this.props.isAuthenticated ? <Dashboard><FormExchange /></Dashboard> : <SignIn />} />
          <Route path="/AllProviderSupportedInsurances" element={this.props.isAuthenticated ? <Dashboard><AllProviderSupportedInsurances /></Dashboard> : <SignIn />} />
          <Route path="/orderTypes" element={this.props.isAuthenticated ? <Dashboard><AllRefOrderTypes/></Dashboard> : <SignIn />} />
          <Route path="/SetupSheet" element={this.props.isAuthenticated ? <Dashboard><SetupSheet/></Dashboard> : <SignIn />} />
          <Route path="/emiapproval" element={this.props.isAuthenticated ? <Dashboard><EmiApproval/></Dashboard>:<SignIn/>}/>
          <Route path="/edoc-template-details" element={this.props.isAuthenticated ? <Dashboard><AllTemplate/></Dashboard>:<SignIn/>}/>
          <Route path="/analytics" element={this.props.isAuthenticated ? <Dashboard><Analytics/></Dashboard>:<SignIn/>}/>
          <Route path="/hl7" element={this.props.isAuthenticated ? <Dashboard><HL7/></Dashboard>:<SignIn/>}/>
          <Route path="/subscriptionConfiguration" element={this.props.isAuthenticated ? <Dashboard><ModuleSubscriptions/></Dashboard>:<SignIn/>}/>
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
    );
  }
}
const styles = {
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  spinner: {
    borderTop: '4px solid #3498db',
    borderRight: '4px solid transparent',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
  },
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    authtoken: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignIn: () => {
      return dispatch(actions.authStateCheck());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rout);
