import React, { useState, useEffect } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";

import AsyncSelect from "react-select/async";
import Cookie from 'js-cookie';

const NewReferralAssigneeTagModal = (props) => {
  const {
    ReferralAssigneeTagAddModal,
    setReferralAssigneeTagAddModal,
    reloadForReferralAssigneeTag,
    setReloadForReferralAssigneeTag,
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [referralAssigneeTagGroupName, setReferralAssigneeTagGroupName] =
    useState("");
  const [referralAssigneeTagName, setReferralAssigneeTagName] = useState("");
  const [referralAssigneeTagAppUser_Name, setReferralAssigneeTagAppUser_Name] =
    useState("");

  const [addButtonStatus, setAddButtonStatus] = useState(true);

  const toggle = () => {
    setReferralAssigneeTagGroupName("");
    setReferralAssigneeTagName("");
    setReferralAssigneeTagAppUser_Name("");
    setReferralAssigneeTagAddModal(!ReferralAssigneeTagAddModal);
  };

  const confirmation = () => {
    if (
      referralAssigneeTagGroupName &&
      referralAssigneeTagName &&
      referralAssigneeTagAppUser_Name
    ) {
      CreateReferralAssigneeTag();
    }
  };

  const CreateReferralAssigneeTag = () => {
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/create-referral-assignee-tag/`,
        {
          tag_name: referralAssigneeTagName
            ? referralAssigneeTagName.label.toString()
            : "",
          group_id: referralAssigneeTagGroupName
            ? referralAssigneeTagGroupName.value.toString()
            : "",
          user_id: referralAssigneeTagAppUser_Name
            ? referralAssigneeTagAppUser_Name.value.toString()
            : "",
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        // console.log(response);
        setReloadForReferralAssigneeTag(reloadForReferralAssigneeTag + 1);
        toastr.success(
          "Successfully Added",
          "Referral Assignee tag details successfully added"
        );
        toggle();
      })
      .catch(function (error) {
        // console.log(error);
        toastr.error("Failed", "Please try again");
      });
  };

  const disableAddButton = () => {
    if (
      referralAssigneeTagName &&
      referralAssigneeTagGroupName &&
      referralAssigneeTagAppUser_Name
    ) {
      setAddButtonStatus(false);
    } else {
      setAddButtonStatus(true);
    }
  };

  const groupNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-referral-order-sender-groups/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const tagNameloadOptions = (inputValue) => {
    // debugger;
    let groupID = referralAssigneeTagGroupName.value;
    if (inputValue.length >= 4) {
      return AxiosInter.get(
        `${API_ENDPOINT}/admin-api/fetch-referral-assignee-tag-name/${inputValue}/${
          groupID ? groupID : "free"
        }`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const appUserloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-app-user-by-names-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };


  useEffect(() => {
    disableAddButton();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    referralAssigneeTagGroupName,
    referralAssigneeTagName,
    referralAssigneeTagAppUser_Name,
  ]); // eslint-disable-line

  return (
    <div>
      <Modal isOpen={ReferralAssigneeTagAddModal}  size="md">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Add New Referral Assignee tag{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          <FormGroup>
            <Row>
              <Col>
                <Label>Sender Group</Label>
                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  type="text"
                  name="groupName"
                  placeholder="Search with at least 4 characters."
                  value={referralAssigneeTagGroupName}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  validate={{
                    maxLength: { value: 120 },
                  }}
                  onChange={(e) => {
                    setReferralAssigneeTagGroupName(e);
                  }}
                  loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col>
                <Label>Tag Name <span className="required">*</span></Label>
                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  type="text"
                  name="groupName"
                  placeholder="Search with at least 4 characters."
                  value={referralAssigneeTagName}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  validate={{
                    maxLength: { value: 120 },
                  }}
                  onChange={(e) => {
                    setReferralAssigneeTagName(e);
                  }}
                  loadOptions={(inputValue) => tagNameloadOptions(inputValue)}
                />
              </Col>
              <Col>
                <Label>App User <span className="required">*</span></Label>

                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  type="text"
                  name="groupName"
                  placeholder="Search with at least 4 characters."
                  value={referralAssigneeTagAppUser_Name}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  validate={{
                    maxLength: { value: 120 },
                  }}
                  onChange={(e) => {
                    setReferralAssigneeTagAppUser_Name(e);
                  }}
                  loadOptions={(inputValue) => appUserloadOptions(inputValue)}
                />
              </Col>
            </Row>
            <br></br>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button  custom-modal-footer">
          <Button
            className="yes-button button-width"
            onClick={confirmation}
            disabled={addButtonStatus}
            title={addButtonStatus ? "Fill the required fields" : ""}
          >
            Add
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewReferralAssigneeTagModal;
