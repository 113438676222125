import React, { useState, useEffect, useContext } from 'react';
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { Col, Label, Row, Button, Spinner } from "reactstrap";
import './DateRangeSelector.css';
import Select from "react-select";
import { GlobalState } from '../../contextAPI/Context';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from '@mui/material/Tooltip';

const ProviderSpecialityRankingFilters = (props) => {

    // CONTEXT VARIABLES
    const {
        setSenderGroup,
        setfulfillerGroup,
        setfulfillerFacility,
        setOrderType,
        setProvider,
        setPSRStatus,
        insuranceType,
        setInsuranceType,
        selectedInsuranceType,
        setSelectedInsuranceType
    } = useContext(GlobalState)

    const { reload, setReload ,offset,setOffset} = props

    // LOCAL VARIABLES 
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");

    const StatusOptions = [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' },
        { value: '97', label: 'Out of Network' },
        { value: '98', label: 'Max Threshold' },
        { value: '99', label: 'Out of Payor Network' }
    ]
    
    const [spinner, setSpinner] = useState(false)
    const [search, setSearch] = useState(false)
    const [sender, setSender] = useState("")
    const [fulfiller, setfulfiller] = useState("")
    const [Facility, setFacility] = useState("")
    const [orderTypeID, setOrderTypeID] = useState("")
    const [provideID, setProviderID] = useState("")
    const [status, setStatus] = useState("")
    const [dropDownDataROS, setDropDownDataROS] = useState("")    
    const [dropDownDataROF, setDropDownDataROF] = useState("")    
    const [groupDropDownROS, setGroupDropDownROS] = useState("");
    const [groupDropDownROF, setGroupDropDownROF] = useState(""); 
    const [orderTypeList, setOrderTypeList] = useState("");
    const [orderTypeDropDown, setOrderTypeDropDown] = useState("");
    const [inputValue, setInputValue] = useState(''); // eslint-disable-line

    const runSearch = () => {
        if (sender || fulfiller || Facility || orderTypeID || provideID || status || selectedInsuranceType) {
            setSenderGroup(sender ? sender : "");
            setfulfillerGroup(fulfiller ? fulfiller : "");
            setfulfillerFacility(Facility ? Facility : "");
            setOrderType(orderTypeID ? orderTypeID : "");
            setProvider(provideID ? provideID : "");
            setPSRStatus(status ? status : "");
            setSelectedInsuranceType(selectedInsuranceType)
            setReload(reload + 1);
        }
    };  

    const resetFields = () => {
        setSender("")
        setfulfiller("")
        setFacility("")
        setOrderTypeID("")
        setProviderID("")
        setStatus("")
        setPSRStatus("")
        setSenderGroup("")
        setfulfillerGroup("")
        setfulfillerFacility("")
        setOrderType( "")
        setProvider("")
        setInputValue("")
        setSelectedInsuranceType("")
        setReload(reload+1)      
    }

    const handleChangeForInsuranceType = (selectedOptions) => {
            setSelectedInsuranceType(selectedOptions);       
    };  
    
    const getInsuranceTypes = () => {
      AxiosInter.get(`${API_ENDPOINT}/admin-api/get-insurance-types/`, {
          headers: {
              Authorization: API_TOKEN,
          },
      })
      .then(response => {
          if (response.data.data.length > 0) {
              setInsuranceType(response.data.data)
          }
      })
    };
    
    useEffect(() => {
      getInsuranceTypes();
    }, []); // eslint-disable-line

    const getDDDate = async () => {
        setSpinner(true)
        await AxiosInter.get(`${API_ENDPOINT}/admin-api/referral-order-sender-fulfiller/`,
            {
                headers: {
                    Authorization: API_TOKEN,
                },
            })
            .then((res) => {
                setDropDownDataROS(res.data.data.ros);
                setDropDownDataROF(res.data.data.rof);
                setSpinner(false)
            })
            .catch((error) => {
                console.log(error);
                setSpinner(false)
            });
    }

    const fetchOrderTypeData = async () =>{
        setSpinner(true)
        try{
            const response = await AxiosInter.post(`${API_ENDPOINT}/admin-api/graphql/`, {
                query : `  query{
                    referralOrderTypesList{
                      id
                      type
                    }
                  }          
                  `,       
            });
            const data= response.data; 
            setOrderTypeList(data.data.referralOrderTypesList);       
        }catch(error){

        }
        setSpinner(false)    
    }

    const createGroupOptionROS = () => {
        if(dropDownDataROS){
            setSpinner(true)
            var array1 = dropDownDataROS
            const options1 = array1.map((item, index) => {
                return {
                    value: item.SenderID,
                    label: item.senderGroupName
                };
            });
            setGroupDropDownROS(options1);
            setSpinner(false)
        }
    };
    const createGroupOptionROF = () => {
        if(dropDownDataROF){
            setSpinner(true)
            var array1 = dropDownDataROF
            const options1 = array1.map((item, index) => {
                return {
                    value: item.FulfillerID,
                    label: item.fulfillerGroupNAme
                };
            });
            setGroupDropDownROF(options1);
            setSpinner(false)
        }
    };
    
    const createOptionsForOrderType = () => {
        
        if(orderTypeList){
            setSpinner(true)
            var array1 = orderTypeList
            const options1 = array1.map((item, index) => {
                return {
                    value: item.id,
                    label: item.type
                };
            });
            setOrderTypeDropDown(options1);
            setSpinner(false)
        }
    };

    const enableOrDisableSearch = () => {
        if (sender || fulfiller || Facility || orderTypeID || provideID ) {
          setSearch(true)
        } else {
          setSearch(false)
        }
    }

    const getAsyncOptions = (inputValue) => { 
    
         if(groupDropDownROS){
          return new Promise((resolve, reject) => {
              const filtered = _.filter(groupDropDownROS, o =>
                _.startsWith(_.toLower(o.label), _.toLower(inputValue))
              );
              resolve(filtered);
            });
         }
    }
    const getAsyncOptionsForFulfiller = (inputValue) => { 
         if(groupDropDownROF){
          return new Promise((resolve, reject) => {
              const filtered = _.filter(groupDropDownROF, o =>
                _.startsWith(_.toLower(o.label), _.toLower(inputValue))
              );
              resolve(filtered);
            });
         }
    }

    // handle input change event
    const handleInputChange = value => {
        setInputValue(value);
    };
    
    // handle selection
    const handleChange = value => {
        setProviderID(value);
    }
    const minLengthforzip = 1;
    const customNoOptionsMessageforZip = ({ inputValue }) => {
        if (inputValue.length < minLengthforzip) {
          return "Please search with at least 1 character";
        } else {
          return "No option";
        }
      };
        
    const loadOptions = (inputValue) => {
        if (inputValue.length >= 4) {
          const searchParams = new URLSearchParams ({name: inputValue});
          return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-and-npi-data/?${searchParams.toString()}`,
            {
              headers: {
                Authorization: API_TOKEN,
              },
            }
          )
            .then((response) => response.data.data)
            .catch((error) => {
              throw error;
            });
        }
      };

      // handle selection
    const handleFacilityChange = value => {
        setFacility(value ? value : "");
    }

    const loadFacilityOptions = (inputValue) => {
        if (inputValue.length >= 4) {
            const searchParams = new URLSearchParams ({name: inputValue});
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`, {
                headers: {
                    Authorization: API_TOKEN,
                }
            })
                .then((response) => response.data.data)
                .catch((error) => {
                    throw error;
                });
        }

    };

    useEffect(() => { getDDDate(); fetchOrderTypeData();} , []) // eslint-disable-line
    useEffect(() => { enableOrDisableSearch(); }, [sender , fulfiller , Facility , orderTypeID , provideID, insuranceType]) // eslint-disable-line
    useEffect(() => { if ( dropDownDataROS) createGroupOptionROS(); }, [ dropDownDataROS]) // eslint-disable-line
    useEffect(() => { 
        if ( dropDownDataROF) createGroupOptionROF(); 
    }, [ dropDownDataROF]) // eslint-disable-line
    useEffect(() => { if ( orderTypeList) createOptionsForOrderType(); }, [ orderTypeList]) // eslint-disable-line

    return (
        <AvForm>
            <AvGroup>
            {spinner
              ? <Spinner />
              : ""}
                <Row>
                    <Col sx={1.5} >
                        <Label>Sender Group</Label>                                            
                        <Tooltip title={sender ? sender.label : ''}  placement="top" arrow>
                          <div>
                          <AsyncSelect
                            cacheOptions
                            isClearable
                            isSearchable
                            placeholder="Type sender name for options"
                            noOptionsMessage={customNoOptionsMessageforZip}
                            value={sender}
                            onChange={(event) => { setSender(event) }}
                            loadOptions={inputValue => getAsyncOptions(inputValue)}
                        />
                        </div>  
                        </Tooltip>
                          
                    </Col>
                    <Col sx={1.5} >
                        <Label>Fulfiller Group</Label>
                       
                        <Tooltip title={fulfiller ? fulfiller.label : ''}  placement="top" arrow>
                          <div>
                          <AsyncSelect
                            cacheOptions
                            isClearable
                            isSearchable
                            placeholder="Type fulfiller name for options"
                            noOptionsMessage={customNoOptionsMessageforZip}
                            value={fulfiller}
                            onChange={(event) => { setfulfiller(event) }}
                            loadOptions={inputValue => getAsyncOptionsForFulfiller(inputValue)}
                        /> 
                        </div>  
                        </Tooltip>
                        
                    </Col>
                    
                    <Col sx={1.5}>
                        <Label>Order Type</Label>                                                
                        <Tooltip title={orderTypeID ? orderTypeID.label : ''}  placement="top" arrow>
                          <div>
                          <Select
                            name="order"
                            options={ orderTypeDropDown }
                            value = {orderTypeID}
                            placeholder="Select order type"
                            isSearchable
                            isClearable
                            onChange={(e) => {
                            setOrderTypeID(e)
                               
                            }}
                        />
                        </div>  
                        </Tooltip>
                    </Col>                                      
                </Row>
                <br></br>
                <Row>
                    <Col style={{ display: "grid" }} sx={1.5}>
                        <div>
                            <Label>
                                Facility
                            </Label>
                            <div style={{ float: "right", marginTop: "0px" }} className="warning-label">
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                /> {" "} {" "}
                                <Label className="warning-text">Search with at least 4 characters.</Label>
                            </div>
                        </div>
                        <Tooltip title={Facility ? Facility.label : ''} placement="top" arrow>
                            <div>
                                <AsyncSelect
                                    cacheOptions
                                    isClearable
                                    isSearchable
                                    getOptionLabel={e => e.label}
                                    getOptionValue={e => e.value}
                                    placeholder="Type facility name for options"
                                    loadOptions={loadFacilityOptions}
                                    value={Facility}
                                    onInputChange={handleInputChange}
                                    onChange={handleFacilityChange}
                                />
                            </div>
                        </Tooltip>
                    </Col>
                    <Col style={{ display: "grid" }} sx={1.5}>
                        <div>
                            <Label>Provider</Label>
                            <div style={{ float: "right", marginTop: "0px" }} className="warning-label">
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                /> {" "} {" "}
                                <Label className="warning-text">Search with at least 4 characters.</Label>
                            </div>
                        </div>
                        <Tooltip title={provideID ? provideID.label : ''} placement="top" arrow>
                            <div>
                                <AsyncSelect
                                    cacheOptions
                                    isClearable
                                    isSearchable
                                    value={provideID}
                                    getOptionLabel={e => e.label}
                                    getOptionValue={e => e.value}
                                    loadOptions={loadOptions}
                                    onInputChange={handleInputChange}
                                    onChange={handleChange}
                                    placeholder="Type provider name for options"
                                />
                            </div>
                        </Tooltip>
                    </Col>
                    <Col sx={1.5}>
                        <Label>Insurance Type</Label>
                        <Select
                             options={insuranceType}
                             value={selectedInsuranceType}
                             placeholder="Select insurance type"
                             isSearchable
                             isClearable
                             onChange={handleChangeForInsuranceType}
                        />
                    </Col>
                    <Col sx={1.5}>                                               
                        <Label>Status</Label>                                           
                         <Tooltip title={status ? status.label : ''}  placement="top" arrow>
                        <div>
                         <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setStatus(event) }}
                            isSearchable
                            placeholder="Select"
                            value={status}
                            options={StatusOptions}
                            />
                        </div>                        
                        </Tooltip> 
                    </Col>
                    <Col xs={0.4} >
                        <Button
                            type="submit"
                            className="go-buttons"
                            disabled={spinner ? true : false}
                            onClick={() => { offset === '0' ? runSearch() : setOffset('0') }}
                        >Go</Button>
                    </Col>
                    <Col xs={1.5}>
                        <Button
                            type="reset"
                            className="reset-buttons"
                            onClick={() => { resetFields() }}
                            title={spinner || !search ? "Nothing To Clear" : ""}
                        >Reset</Button>
                    </Col>
                </Row>
                <Row>
                
                </Row>
            </AvGroup>
        </AvForm>
    )
}

export default ProviderSpecialityRankingFilters;
