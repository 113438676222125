import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { FormGroup, Label, Modal, ModalBody, ModalHeader, Form, Row, Col, Input, ModalFooter, Button } from "reactstrap";
import Select from 'react-select';
import AxiosInter from './../../AxiosInter.js';
import { toastr } from 'react-redux-toastr';
import Cookie from 'js-cookie';
import CreatableSelect from 'react-select/creatable';

const AddAnalyticsModal = (props) => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");

    const [selectedGroup, setSelectedGroup] = useState(null); 
    const [category, setCategory] = useState("");
    const [name, setName] = useState("");
    const [subscription, setSubscription] = useState(null); 
    const [apiKey, setApiKey] = useState("");
    const [email, setEmail] = useState("");
    const [dashboardId, setDashboardId] = useState("");
    const [panelColor, setPanelColor] = useState("");
    const [url, setUrl] = useState("");
    const [sortingOrder, setSortingOrder] = useState();
    const [distinctApiKey, setDistinctApiKey] = useState([])

    const { addAnalyticsModal, setAddAnalyticsModal, groupNameOptions, isExternalChecked, handleIsExternalChange,
        subscriptionNamesList, categoryOptions, groupId, setSelectedApiKeyOption, selectedApiKeyOption, getAnalyticsData, // eslint-disable-line
        selectedGroupName // eslint-disable-line
    } = props


    const clearInitialData = () => {
        setSelectedGroup("")
        setCategory("")
        setName("")
        setSubscription("")
        setApiKey("")
        setEmail("")
        setDashboardId("")
        setPanelColor("")
        setUrl("")
        handleIsExternalChange()
    }


    const handleChangeGroup = (selectedGroup) => {
        setSelectedGroup(selectedGroup);
    };

    const handleChangeCategory = (e) => {
        setCategory(e.target.value); // Update the category state with the input value
    };

    const handleChangeSubscription = (subscription) => {
        setSubscription(subscription);
    };

    const handleApiKeyChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'create-option') {
            setEmail("");
            setDistinctApiKey([...distinctApiKey, { value: newValue.value, label: newValue.label }]);
        } else {
            setEmail(newValue ? newValue.value : "");
        }
        setApiKey(newValue);
    };   
 
    const getDistinctApiKeys = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-distinct-api-key/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                setDistinctApiKey(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching distinct API keys:', error);
            });
    };

    useEffect(()=>{
        if(addAnalyticsModal){
            getDistinctApiKeys()
        }
    },[addAnalyticsModal]) // eslint-disable-line

     const addAnalytics = () => {

        const sub = subscription?.map(x => x.value + '') || [];

        const params = {
            "subscription_Id": sub,
            "groupId": selectedGroup ? selectedGroup.value : "",
            "panelColor": panelColor,
            "category": category,
            "name": name,
            "dashboardId": props.isExternalChecked ? dashboardId : null,
            "apiKey": props.isExternalChecked ? apiKey.label : null,
            "email": props.isExternalChecked ? email : null,
            "url": !props.isExternalChecked ? url : null,
            "isExternal": props.isExternalChecked ? 1 : 0,
            "sortingOrder": sortingOrder
        }


        AxiosInter.post(`${API_ENDPOINT}/admin-api/add-new-analytics-detail/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.status === 201) {
                    toastr.success("Success", "Analytics Added Successfully")
                    clearInitialData()
                    setAddAnalyticsModal(false)
                    getAnalyticsData()
                }

            })
            .catch(error => {
                toastr.error("Error", error.response.data.message)

            });
    }

    return (
        <div>
            <Modal isOpen={addAnalyticsModal} size="md">
                <ModalHeader tag="h4" cssModule={{ "modal-title": "w-100 text-center" }}>Add Analytics</ModalHeader>
                <ModalBody className="overflow-modal">
                    <Form>
                        <FormGroup>
                            <Row>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Group</Label>
                                        <AsyncSelect
                                            isClearable
                                            isSearchable
                                            menuPosition="fixed"
                                            loadOptions={groupNameOptions}
                                            value={selectedGroup}
                                            onChange={handleChangeGroup}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Label>Category</Label>
                                        <Input
                                            type="text"
                                            placeholder="Category"
                                            value={category}
                                            onChange={handleChangeCategory}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Input
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value)
                                            }}
                                        ></Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label>Subscription</Label>
                                        <Select
                                            isMulti
                                            options={subscriptionNamesList}
                                            value={subscription}
                                            onChange={handleChangeSubscription}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label>Color</Label>
                                        <Input
                                            type='color'
                                            value={panelColor}
                                            onChange={(e) => {
                                                setPanelColor(e.target.value);
                                            }}
                                        ></Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="6">
                                    <FormGroup>
                                        <Label>Sorting Order</Label>
                                        <Input
                                            value={sortingOrder}
                                            onChange={(e) => {
                                                setSortingOrder(e.target.value)
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                checked={isExternalChecked}
                                                onChange={(event) => handleIsExternalChange(event?.target?.checked)}
                                                type="checkbox"
                                            />
                                            {' '}
                                            Is External
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {isExternalChecked ?
                                <Row>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label>API Key</Label>
                                            <CreatableSelect
                                                value={apiKey}
                                                onChange={handleApiKeyChange}
                                                options={distinctApiKey}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Input
                                                type="email"
                                                key={'email'}
                                                value={email}
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                }}
                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label>Dashboard Id</Label>
                                            <Input
                                                value={dashboardId}
                                                onChange={(e) => {
                                                    setDashboardId(e.target.value)
                                                }}
                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Label>URL</Label>
                                            <Input
                                                key={'url'}
                                                value={url}
                                                onChange={(e) => {
                                                    setUrl(e.target.value)
                                                }}
                                            ></Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        onClick={() => {
                            addAnalytics()
                        }}
                    >Add</Button>{" "}
                    <Button
                        outline
                        className="no-button button-width"
                        onClick={() => {
                            setAddAnalyticsModal(false)
                            clearInitialData()
                        }}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default AddAnalyticsModal;