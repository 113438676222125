import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from "react-select";

const NewReferralSenderModal = (props) => {
    const { ReferralSenderAddModal, setReferralSenderAddModal,fetchReferralSenderData } = props;

    // CONTEXT VARIABLES 
    const { referralSenderReload, setReferralSenderReload, } = useContext(GlobalState);


    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    
    const [optionsForGroupList, setOptionsForGroupList] = useState([]);
    const [newGroupName, setNewGroupName] = useState("");
    const [newGroupID, setNewGroupID] = useState("");
    const [newNPI, setNewNPI] = useState("");
    const [newAutoProcessEnable, setNewAutoProcessEnable] = useState("");
    const [newSubscriptionType, setNewSubscriptionType] = useState("");
    const [newEnableEmailNotification, setNewEnableEmailNotification] = useState("");
    const [newIsClinical, setNewIsClinical] = useState("");
    const [newIsTransport, setNewIsTransport] = useState("");
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [OptionsForDropDownGroup, setOptionsForDropDownGroup] = useState([]); // eslint-disable-line
    const [categoryType, setCategoryType] = useState([]);
    const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);
    const regXForNubers = /^[0-9]*$/;

    const toggle = () => {
        setNewGroupName("")
        setReferralSenderAddModal(!ReferralSenderAddModal);
        setNewGroupID("");
        setNewNPI("")
        setNewAutoProcessEnable("")
        setNewSubscriptionType("")
        setNewEnableEmailNotification("")
        setNewIsClinical("")
        setNewIsTransport("")
        setCategoryType([])
    }



    const confirmation = () => {
        if (categoryType.length===0) {
            toastr.error("Please Select Category");
          }
        if (newGroupName && newSubscriptionType && categoryType.length!==0) {
            CreateReferralSender()
        }
    };


    const FetchGroupListAPI = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-referral-senders-only/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                setOptionsForGroupList(response.data.data)

            })
            .catch(function (error) {
                toastr.error("Something Is Wrong", "Please try again")
            });
    }
    const onChangeForNPINumber = (e) => {
        if(regXForNubers.test(e) === false) {
            toastr.warning("Invalid NPI Number", "alphabets are not allowed")
        } else{
            setNewNPI(e)
        }
    }

    const CreateReferralSender = () => {
        if (categoryType) {
            var categoryTypesResult = categoryType.map((item) => {
                return item.value.toString();
            });
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/create-referral-senders/`, {

            "group_id": newGroupID ? Number(newGroupID.value) : 0,
            "NPI": newNPI ? newNPI : 0,
            "auto_process_enable": newAutoProcessEnable ? Number(newAutoProcessEnable.value) : 0,
            "subscription_type": newSubscriptionType.label ? newSubscriptionType.label.toString() : "FULL",
            "enable_email_notification": newEnableEmailNotification.value ? Number(newEnableEmailNotification.value) : 1,
            "is_clinical": newIsClinical.value ? Number(newIsClinical.value) : 1,
            "is_transport": newIsTransport.value ? Number(newIsTransport.value) : 0,
            "category_type": categoryTypesResult.sort()

        }, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(function (response) {
                // console.log(response);
                setReferralSenderAddModal(!ReferralSenderAddModal)
                setReferralSenderReload(referralSenderReload + 1)
                toastr.success("Successfully Added", "Referral sender details successfully added")
                fetchReferralSenderData();
                toggle();
            })
            .catch(function (error) {
                toastr.error("Failed", 'Duplication Entry')
            });
    }

    const YesNoOptions = [
        { value: '1', label: "Yes" },
        { value: '0', label: "No" }
    ]

    const SubOptions = [
        { value: 'LITE', label: "Community" },
        {value: 'LITE PLUS', label: "Community Plus" },
        { value: 'FULL', label: "Enterprise" },
    
    ]
    

    const optionsForGroupDropDown = () => {
        let array = optionsForGroupList
        array.map((item) => { // eslint-disable-line
            setOptionsForDropDownGroup([{
                value: item.id,
                label: item.name
            }])


        })
    }

    const GroupNpiLoop = () => {
        let array = optionsForGroupList

        if (newGroupName) {
            array.map((item) => { // eslint-disable-line
                if (item.name.toLowerCase() == newGroupName.label.toLowerCase()) { // eslint-disable-line
                    setNewNPI(item.npi ? item.npi : "")
                }
            })
        }
    }

    const NPIField = () => {
        return (
            <>

                <Label for="phone">NPI  </Label>
                <Input
                    name="npi"
                    id="npi"
                    placeholder='Enter NPI'
                    value={newNPI}
                    type="text"
                    onChange={(e) => { 
                        if (e.target.value.length >= 20) {
                            toastr.warning("Invalid Number", "number length exceeded")
                        }else{
                            onChangeForNPINumber(e.target.value)
                        }
                    }}
                />
                <br></br>
            </>
        )
    }


    const disableAddButton = () => {

        if (
            newSubscriptionType &&
            newGroupName
        ) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }

    
    const fetchCategoryTypeOptions = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-category-type-options/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((response) => {
                if (response.status === 200) {

                    setCategoryTypeOptions(response.data.data)

                }

            })
            .catch((error) => {
                console.log(error)
            });
    }
    useEffect(() => {

        if (ReferralSenderAddModal === true) {
            //   console.log('catTypeOptions',categoryTypeOptions)
            fetchCategoryTypeOptions();
            FetchGroupListAPI(); 

        }

    }, [ReferralSenderAddModal])// eslint-disable-line

    useEffect(() => { disableAddButton() }, [newSubscriptionType, newGroupName]) // eslint-disable-line
    useEffect(() => { optionsForGroupDropDown(); GroupNpiLoop(); }, [optionsForGroupList, newGroupName]) // eslint-disable-line
    useEffect(() => { NPIField(); }, [newGroupName, newNPI]) // eslint-disable-line 
    useEffect(() => {
        setNewAutoProcessEnable({ value: '1', label: "Yes" })
    }, [])
    return (
        <div>
            <Modal isOpen={ReferralSenderAddModal}  size="md" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add New Referral Sender </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Row>
                            <Col>
                                <Label for="name">Group Name <span className="required">*</span></Label>
                                <Select
                                    placeholder="Select"
                                    options={optionsForGroupList.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                    }))}
                                    value={newGroupName}
                                    onChange={(e) => {
                                        setNewGroupID(e)
                                        setNewGroupName(e);
                                    }}
                                />
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col >
                                {NPIField()}
                            </Col>
                            <Col >
                                <Label for="address">Subscription Type <span className="required">*</span> </Label>
                                <Select
                                    name="doc-type"
                                    placeholder="Select"
                                    options={SubOptions}
                                    value={newSubscriptionType}
                                    onChange={(e) => {
                                        setNewSubscriptionType(e);
                                    }}
                                />
                            </Col>
                            <Col >
                                <Label for="address">Auto Process Enabled </Label>
                                <Select
                                    name="doc-type"
                                    placeholder="Select"
                                    options={YesNoOptions}
                                    value={newAutoProcessEnable}
                                    onChange={(e) => {
                                        setNewAutoProcessEnable(e);
                                    }}
                                />
                            </Col>
                            <br></br>
                        </Row>

                        <Row>
                            <Col sm={8}>
                                <Label for="categoryType">Category <span className="required">*</span> </Label>
                                <Select
                                    isMulti
                                    options={categoryTypeOptions}
                                    onChange={(event) => setCategoryType(event)}
                                    value={categoryType}
                                    placeholder="Select"
                                    isClearable
                                />
                            </Col>
                            {/* </Row> */}
                            {/* <Col>
                                <Label for="address">Is Transport </Label>
                                 <Select
                                    name="doc-type"
                                    placeholder="Select"
                                    options={YesNoOptions}
                                    value={newIsTransport}
                                    onChange={(e) => {
                                        setNewIsTransport(e);
                                    }}
                                />
                                </Col>  */}
                                {/* <Row> */}
                            <Col>
                                <Label for="address">Enable Mail Notification  </Label>
                                <Select
                                    name="doc-type"
                                    placeholder="Select"
                                    options={YesNoOptions}
                                    value={newEnableEmailNotification}
                                    onChange={(e) => {
                                        setNewEnableEmailNotification(e);
                                    }}
                                />
                            </Col>

                        </Row>
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={confirmation}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewReferralSenderModal;