import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, Container, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';




const InsurancePlanTypeModal = (props) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");

    const [buttonBlankState, setButtonBlankState] = useState(false);
    
   
    const {
        editInsurancePlanTypeName ,
        setEditInsurancePlanTypeName,
        insurancePlanTypeDescription,
        setInsurancePlanTypeDescription,
        insurancePlanTypeId,
        insurancePlanTypeReload,
        setInsurancePlanTypeReload,
        insurancePlanTypeModal,
        setInsurancePlanTypeModal,
        insurancePlanTypeModalState,
        setInsurancePlanTypeModalState
        
      

    } = props

    const toggle = () => {
        setInsurancePlanTypeModal(!insurancePlanTypeModal)
    }


    const validate = () => {
        if (
            editInsurancePlanTypeName
        ) {
            setButtonBlankState(false)
        }
        else {
            setButtonBlankState(true)
        }
      }

  





    const updateInsurancePlanTypeDetails = () => {
        const params =
        {
            "insurancePlanTypeId": insurancePlanTypeId ? insurancePlanTypeId:"",
            "insurancePlanTypeName": editInsurancePlanTypeName ? editInsurancePlanTypeName:"",
            "insurancePlanTypeDescription": insurancePlanTypeDescription ? insurancePlanTypeDescription:"",
           
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-insurance-plan-type-details/`,params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
          .then(response => {
            toggle();
            toastr.success("Success","Insurance plan type details updated");
            setInsurancePlanTypeReload(insurancePlanTypeReload + 1)
        
          })
          .catch(error => {
            if (error.response.data.message) {
                toastr.error("Error",error.response.data.message)
            } 
            else {
                toastr.error("Error","Something went wrong !")
            }  
    
          });

    }

    const addNewInsurancePlanTypeDetails = () => {
        const params =
        {
            "insurancePlanTypeName": editInsurancePlanTypeName ? editInsurancePlanTypeName:"",
            "insurancePlanTypeDescription": insurancePlanTypeDescription ? insurancePlanTypeDescription:"",
           
        }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/add-new-insurance-plan-type-details/`,params,{
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        toggle();
        toastr.success("Success","New Insurance plan type added");
        setInsurancePlanTypeReload(insurancePlanTypeReload + 1) 
        
      })
    .catch(error => {
        if (error.response.data.message) {
            toastr.error("Error",error.response.data.message)
        } 
        else {
            toastr.error("Error","Something went wrong !")
        }  
      });

    }








    useEffect(() => {
        if (!insurancePlanTypeModal) {
            setInsurancePlanTypeModalState("initial")
            setInsurancePlanTypeModal(false);
            setInsurancePlanTypeDescription("");
            setEditInsurancePlanTypeName("");
            
        };

    }, [insurancePlanTypeModal]) // eslint-disable-line

    useEffect(() => {
        validate()
    
      }, [editInsurancePlanTypeName]) // eslint-disable-line

    // useEffect(()=>{
    //     if(modal && groupDropDown && modalState === "edit") {
    //         setDefaultGroupName()
    //     }
     
    // },[modal,groupDropDown]) // eslint-disable-line




    return (
        <div>
            <Modal isOpen={insurancePlanTypeModal} size="md" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>{insurancePlanTypeModalState === "add" ? "New Insurance Plan Type " : "Edit Insurance Plan Type"} </ModalHeader>
                <ModalBody className="overflow-modal">
                    <Container fluid>
                        <FormGroup>
                            <Row>
                                <Col>
                              
                                    <Label for="group_insurance_plan_type_name">Name<span className="required">*</span></Label>
                                    <Input
                                        id="group_insurance_plan_type_name"
                                        type="text"
                                        placeholder="Name"
                                        value={editInsurancePlanTypeName}
                                        onChange={(e) => {
                                            setEditInsurancePlanTypeName(e.target.value)

                                        }}
                                    />
                                </Col>
                            </Row><br />
                            <Row>
                                <Col>
                                    <Label for="group_insurance_plan_type_description">Description</Label>
                                    <Input
                                        id="group_insurance_plan_type_description"
                                        type="textarea"
                                        rows= {4}
                                        cols = {3}
                                        placeholder="Description"
                                        value={insurancePlanTypeDescription}
                                        onChange={(e) => {
                                            setInsurancePlanTypeDescription(e.target.value)

                                        }}
                                    />
                                </Col>


                              

                            </Row><br />
                           
                        </FormGroup>
                    </Container>

                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button

                        onClick={() => {
    
                            insurancePlanTypeModalState === 'edit' ? updateInsurancePlanTypeDetails():addNewInsurancePlanTypeDetails()
                        }}
                        disabled={buttonBlankState}
                    title= {buttonBlankState ? "Please fill in all mandatory fields":(!buttonBlankState &&  insurancePlanTypeModalState === 'add' )?"Click to save":"Click to update"}

                    >{insurancePlanTypeModalState === 'edit' ? 'Update' : 'Add'}</Button>{' '}

                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default InsurancePlanTypeModal;