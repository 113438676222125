import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  Container,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  CardBody,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import ToggleButton from "react-toggle-button";
import AsyncSelect from "react-select/async";
import { GlobalState } from "../../contextAPI/Context";
import Cookie from 'js-cookie';

const NewAutoProcessRule = (props) => {
  const {
    referralCategory,
    reload,
    setReferralCategory,
    setReload,
    groupId,
    ruleName,
    referralType,
    patientAgeCondition,
    patientCondition,
    patientInsurance,
    serviceTypes,
    orderPriority,
    patientGender,
    diagnosis,
    procedures,
    processStatus,
    createdDate,
    updatedDate,
    mandatoryItems,
    eligibilityCheck,
    preAuthCheck,
    preAuthRequestType,
    otherGenerateDocs,
    autoProcessEnable,
    allowedDiagnosis,
    allowedProcedure,
    allowedInsurances,
    allowedServiceTypes,
    allowedNoOfVisitors,
    allowedPos,
    excludedDiagnosis,
    excludedProcedure,
    excludedInsurances,
    excludedServiceTypes,
    additionalKeyData,
    ruleType,
    setGroupId,
    setRuleName,
    setReferralType,
    setPatientAgeCondition,
    setPatientCondition,
    setPatientInsurance,
    setServiceTypes,
    setOrderPriority,
    setPatientGender,
    setDiagnosis,
    setProcedures,
    setProcessStatus,
    setCreatedDate,
    setCreatedBy,
    setUpdatedDate,
    setUpdatedBy,
    setEligibilityCheck,
    setPreAuthCheck,
    setPreAuthRequestType,
    setAutoProcessEnable,
    setAllowedNoOfVisitors,
    setAllowedPos,
    setAdditionalKeyData,
    setRuleType,
    referralTypeOptions,
    selectedServiceTypes,
    setSelectedServiceTypes,
    selectedOrderPriority,
    setSelectedOrderPriority,
    selectedPhysicianIds,
    setSelectedPhysicianIds,
    selectedConditions,
    setSelectedConditions,
    setSelectedInsurances,
    selectedInsurances,
    setSelectedProcedure,
    selectedProcedure,
    setSelectedDiagnosis,
    selectedDiagnosis,
    selectedFulfillerGroup,
    setSelectedFulfillerGroup,
    selectedFulfillerFacility,
    setSelectedFulfillerFacility,
    referralRuleData,
    setNewAutoProcessRule,
    newAutoProcessRule,
    buttonBlankState,
    setButtonBlankState,
    loadServiceTypeOptions,
    loadDiagnosisOptions,
    orderPriorityOptions,
    loadProcedureOptions,
    loadFulfillerGroupOptions,
    loadPhysicianOptions,
    ageOptions,
    genderOptions,
    loadConditionOptions,
    loadInsuranceOptions,
    requestTypeOptions,
    toggleValue,
    setToggleValue,
    additionalDocsOptions,
    loadFacilityOptionsForFacility,
    senderFacilityId, setSenderFacilityId,
    transmitEmiForm,seTransmitEmiForm,
    faxNumber,setFaxNumber
  } = props;
  const { groupNamesForDropDown } = useContext(GlobalState);

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [selectedAge, setSelectedAge] = useState("");
  const [configuredDocOnSent, setConfiguredDocOnSent] = useState(false);
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [selectedAgeCondition, setSelectedAgeCondition] = useState("");
  const [patientConditions, setPatientConditions] = useState(""); // eslint-disable-line
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);
  const [checkedFour, setCheckedFour] = useState(false);
  const [checkedFive, setCheckedFive] = useState(false);
  const [checkedSix, setCheckedSix] = useState(false);
  const [checkedSeven, setCheckedSeven] = useState(false);
  const [checkedEight, setCheckedEight] = useState(false);
  const [checkedNine, setCheckedNine] = useState(false);
  const [auto_Assign_Fullfiller, seAuto_Assign_Fullfiller] =
  useState("");
  const [assign_FulfillerGroupId, setAassign_FulfillerGroupId] =
    useState({ value: "0", label: "0" });
  const [selectedAllowedServiceTypes, setSelectedAllowedServiceTypes] =
    useState([]);
  const [selectedExcludedServiceTypes, setSelectedExcludedServiceTypes] =
    useState([]);
  const [selectedAllowedDiagnosis, setSelectedAllowedDiagnosis] = useState([]);
  const [selectedExcludedDiagnosis, setSelectedExcludedDiagnosis] = useState(
    []
  );
  const [selectedAllowedProcedure, setSelectedAllowedProcedure] = useState([]);
  const [selectedExcludedProcedure, setSelectedExcludedProcedure] = useState(
    []
  );
  const [selectedAllowedInsurance, setSelectedAllowedInsurance] = useState([]);
  const [selectedExcludedInsurance, setSelectedExcludedInsurance] = useState(
    []
  );
  const [selectedReferralType, setSelectedReferralType] = useState([]);
  const [selectedadditionalDocs, setSelectedadditionalDocs] = useState([]);
  const [tempDiagnosis, setTempDiagnosis] = useState([]); // eslint-disable-line
  const [selectedAllowedFulfillerGroup, setSelectedAllowedFulfillerGroup] =
  useState([]);
const [
  selectedAllowedFulfillerFacility,
  setSelectedAllowedFulfillerFacility,
] = useState([]);
const [
  selectedAllowedFulfillerPhysician,
  setSelectedAllowedFulfillerPhysician,
] = useState([]);
const [selectedExcludedFulfillerGroup, setSelectedExcludedFulfillerGroup] =
  useState([]);
const [
  selectedExcludedFulfillerFacility,
  setSelectedExcludedFulfillerFacility,
] = useState([]);
const [
  selectedExcludedFulfillerPhysician,
  setSelectedExcludedFulfillerPhysician,
] = useState([]);
const [transmitAdditionalAttachments, setTransmitAdditionalAttachments] =
useState(false);
const [selectAttachmentType, setSelectAttachmentType] = useState([]);
const [allowedFulfillerGroupResult, setAllowedFulfillerGroupResult] = useState([]); // eslint-disable-line
const [allowedFulfillerFacilityResult, setAllowedFulfillerFacilityResult] = useState([]); // eslint-disable-line
const [allowedFulfillerPhysicianResult, setAllowedFulfillerPhysicianResult] = useState([]); // eslint-disable-line
const [excludedFulfillerGroupResult, setExcludedFulfillerGroupResult] = useState([]); // eslint-disable-line
const [excludedFulfillerfacilityResult, setExcludedFulfillerfacilityResult] = useState([]); // eslint-disable-line
const [excludedFulfillerPhysicianResult, setExcludedFulfillerPhysicianResult] = useState([]); // eslint-disable-line
const [attachmentTypeResult, setAttachmentTypeResult] = useState([]); // eslint-disable-line
const [selectedSenderGroup, setSelectedSenderGroup] = useState([]);
const [suggestingForm,setSuggestingForm] = useState("")


  const event = new Date();
  const dateUpdate=event.toISOString().slice(0,-5)
  const toggle = () => {
    setNewAutoProcessRule(!newAutoProcessRule);
    setGroupId();
    setRuleName("");
    setReferralType("");
    setPatientAgeCondition("");
    setPatientCondition("");
    setPatientInsurance("");
    setServiceTypes("");
    setOrderPriority("");
    setPatientGender("");
    setDiagnosis("");
    setProcedures("");
    setProcessStatus();
    setCreatedDate("");
    setCreatedBy();
    setUpdatedDate("");
    setUpdatedBy();
    setSelectedFulfillerGroup([]);
    setSelectedFulfillerFacility([]);
    setSelectedPhysicianIds([]);
    setEligibilityCheck();
    setPreAuthCheck();
    setPreAuthRequestType("");
    setSelectedPhysicianIds("")
    setAutoProcessEnable();
    setSelectedAllowedDiagnosis([]);
    setSelectedAllowedProcedure([]);
    setSelectedAllowedInsurance([]);
    setSelectedAllowedServiceTypes([]);
    setAllowedNoOfVisitors([]);
    setAllowedPos();
    setSelectedExcludedDiagnosis([]);
    setSelectedExcludedProcedure([]);
    setSelectedExcludedInsurance([]);
    setSelectedExcludedServiceTypes([]);
    setAdditionalKeyData("");
    setRuleType("");
    setReload(reload + 1);
  };

  const patientAgePopulate = () => {
    if (selectedAge && selectedAgeCondition) {
      setPatientAgeCondition([
        {
          age: selectedAge,
          max: "0",
          min: "0",
          condition: selectedAgeCondition.value,
        },
      ]);
    // eslint-disable-next-line eqeqeq
    } else if (selectedAgeCondition.label == "Between") {
      setPatientAgeCondition([
        {
          age: "0",
          max: maxValue,
          min: minValue,
          condition: selectedAgeCondition,
        },
      ]);
    } else {
      setPatientAgeCondition("");
    }
  };
  useEffect(() => {
    if (selectedAge && selectedAgeCondition) patientAgePopulate();
  }, [selectedAge, selectedAgeCondition]); // eslint-disable-line

  const loadFulfillerOptions = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(
        `${API_ENDPOINT}/admin-api/get-fulfillergroup-type-list/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const handelOnChangeForReferralRuleItem = (e, item) => {
    let name = item.item;
    let itemId = item.id;

    if (name === "Guarantor Details") {
      setCheckedOne(e.target.checked);

      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Pre Auth") {
      setCheckedTwo(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Lab Report") {
      setCheckedThree(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Diagnosis") {
      setCheckedFour(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedDiagnosis();
        setSelectedExcludedDiagnosis();
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Emergency Contact") {
      setCheckedFive(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Procedure") {
      setCheckedSix(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedProcedure();
        setSelectedExcludedProcedure();
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Referral reason") {
      setCheckedSeven(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Insurance Details") {
      setCheckedEight(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedInsurance();
        setSelectedExcludedInsurance();
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Original Referral") {
      setCheckedNine(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
  };


  const loadOptionsForFaxAttachment = (inputValue) => {
    if (inputValue.length >= 3) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-fax-attachment-list/${inputValue}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  }; 


const loadSenderGroupOptions = (inputValue) => {
  inputValue= groupId.value
    return AxiosInter.get(
      `${API_ENDPOINT}/admin-api/get-sender-group-name/${inputValue}`,
      {
        headers: {
          Authorization: API_TOKEN,
        },
      }
    )
      .then((response) => response.data.data)
      .catch((error) => {
        throw error;
      });
};

useEffect(()=>{
// eslint-disable-next-line eqeqeq  
  if(groupId !="" && groupId != undefined&& groupId.value != undefined) {
    loadSenderGroupOptions(groupId.value);
}},[groupId]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadOptionsForReferralCategory = (inputValue) => {
    if (inputValue.length >= 3) { 
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-category-type-options-async/${inputValue.toString()}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then((response) => response.data.data)
        // .catch((error) => {
        //   throw error;
        // });
    }
  };

  const handelCheckedForReferralRuleItem = (item) => {
    // eslint-disable-next-line no-unused-vars
    let itemId = item.id;
    item = item.item;

    // eslint-disable-next-line eqeqeq
    if (item == "Guarantor Details") {
      return checkedOne ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Pre Auth") {
      return checkedTwo ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Lab Report") {
      return checkedThree ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Diagnosis") {
      return checkedFour ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Emergency Contact") {
      return checkedFive ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Procedure") {
      return checkedSix ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Referral reason") {
      return checkedSeven ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Insurance Details") {
      return checkedEight ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Original Referral") {
      return checkedNine ? true : false;
    }
  };
  let gid =0

  const loadSuggestingFormOptions = (inputValue) => {
    if (inputValue.length >= 1) {
      return AxiosInter.get(
        `${API_ENDPOINT}/admin-api/get-suggesting-form-load-options/${gid}/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        // .catch((error) => {
        //   throw error;
        // });
    }
  };

  useEffect(() =>{
  // eslint-disable-next-line eqeqeq  
    if(groupId !="" && groupId !=undefined && groupId.value!=""){
      gid =groupId.value; // eslint-disable-line react-hooks/exhaustive-deps
  }},[groupId])
  // #########  ADD API  ######### //

  const addAutoProcessExceptionRules = () => {
    let admin_id = localStorage.getItem("adminID");
    let fulfillerGroupId = []
    let senderGroupId = []
    let fulfillerFacilityId = []
    let fulfillerPhysicianId = []
    if (selectedServiceTypes) {
  
      // eslint-disable-next-line array-callback-return
      selectedServiceTypes.map((item) => {
 
        serviceTypes.push(JSON.stringify(item.value));
      });
    }
    if (selectedDiagnosis) {
      // eslint-disable-next-line array-callback-return
      selectedDiagnosis.map((item) => {
        diagnosis.push(item);
      });
    }
    if (selectedOrderPriority) {
      // eslint-disable-next-line array-callback-return
      selectedOrderPriority.map((item) => {
        orderPriority.push(item.value);
      });
    }
    if (selectedProcedure) {
      // eslint-disable-next-line array-callback-return
      selectedProcedure.map((item) => {
        procedures.push(item);
      });
    }
    if (selectedFulfillerGroup) {
      // eslint-disable-next-line array-callback-return
      selectedFulfillerGroup.map((item) => {
      
        console.log(item.value.toString())
        fulfillerGroupId.push(item.value.toString());
      });
    }
    if (selectedSenderGroup) {
      // eslint-disable-next-line array-callback-return
      selectedSenderGroup.map((item) => {
      
        console.log(item.value.toString())
        senderGroupId.push(item.value.toString());
      });
    }
    if (selectedFulfillerFacility) {
      // eslint-disable-next-line array-callback-return
      selectedFulfillerFacility.map((item) => {
        fulfillerFacilityId.push(item.value.toString());
      });
    }
    if (selectedPhysicianIds) {
      // eslint-disable-next-line array-callback-return
      selectedPhysicianIds.map((item) => {
        fulfillerPhysicianId.push(item.value.toString());
      });
    }
    if (selectedConditions) {
      // eslint-disable-next-line array-callback-return
      selectedConditions.map((item) => {
        patientCondition.push(item);
      });
    }
    if (selectedInsurances) {
      // eslint-disable-next-line array-callback-return
      selectedInsurances.map((item) => {
        patientInsurance.push(item.label);
      });
    }
    if (selectedAllowedServiceTypes) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedServiceTypes.map((item) => {
        allowedServiceTypes.push({
          id: item.value,
          type: item.label,
          is_clinical: 1,
          is_transport: 0,
        });
      });
    }
    if (selectedExcludedServiceTypes) {
      // eslint-disable-next-line array-callback-return
      selectedExcludedServiceTypes.map((item) => {
        excludedServiceTypes.push({
          id: item.value,
          type: item.label,
          is_clinical: 1,
          is_transport: 0,
        });
      });
    }
    if (selectedAllowedDiagnosis) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedDiagnosis.map((item) => {
        allowedDiagnosis.push(item);
      });
    }
    if (selectedExcludedDiagnosis) {
      // eslint-disable-next-line array-callback-return
      selectedExcludedDiagnosis.map((item) => {
        excludedDiagnosis.push(item);
      });
    }
    if (selectedAllowedInsurance) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedInsurance.map((item) => {
        allowedInsurances.push(item.label);
      });
    }
    if (selectedExcludedInsurance) {
      // eslint-disable-next-line array-callback-return
      selectedExcludedInsurance.map((item) => {
        excludedInsurances.push(item.label);
      });
    }
    if (selectedAllowedProcedure) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedProcedure.map((item) => {
        allowedProcedure.push(item);
      });
    }
    if (selectedExcludedProcedure) {
      // eslint-disable-next-line array-callback-return
      selectedExcludedProcedure.map((item) => {
        excludedProcedure.push(item);
      });
    }
    if (selectedadditionalDocs) {
      // Object.values(selectedadditionalDocs).map((item) => {
      otherGenerateDocs.push(selectedadditionalDocs.value);
    }

    let procedureCode = [];
    if (selectedAllowedProcedure) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedProcedure.map((item) => {
       
        procedureCode.push(item.code);
      });
    }
    if (selectedAllowedFulfillerGroup) {
      // eslint-disable-next-line array-callback-return
    selectedAllowedFulfillerGroup.map((item) => {
    allowedFulfillerGroupResult.push(item);

      });
    }
    if (selectedAllowedFulfillerFacility) {
      // eslint-disable-next-line array-callback-return
    selectedAllowedFulfillerFacility.map((item) => {
      allowedFulfillerFacilityResult.push(item);

      });
    } if (selectedAllowedFulfillerPhysician) {
      // eslint-disable-next-line array-callback-return
     selectedAllowedFulfillerPhysician.map((item) => {
      allowedFulfillerPhysicianResult.push(item);

      });
    } if (selectedExcludedFulfillerGroup) {
      // eslint-disable-next-line array-callback-return
      selectedExcludedFulfillerGroup.map((item) => {
        excludedFulfillerGroupResult.push(item);

      });
    } if (selectedExcludedFulfillerFacility) {
      // eslint-disable-next-line array-callback-return
       selectedExcludedFulfillerFacility.map((item) => {
        excludedFulfillerfacilityResult.push(item);

      });
    } if (selectedExcludedFulfillerPhysician) {
      // eslint-disable-next-line array-callback-return
     selectedExcludedFulfillerPhysician.map((item) => {
      excludedFulfillerPhysicianResult.push(item);

      });
    }
    if (selectAttachmentType) {
      // eslint-disable-next-line array-callback-return
      selectAttachmentType.map((item) => {
        attachmentTypeResult.push(item);

      });
    }
    let autogID = 0
    if (auto_Assign_Fullfiller ===0){
      autogID = 0
    }
  else if(auto_Assign_Fullfiller ===1){
    autogID = 0
  }
  else {
    autogID = assign_FulfillerGroupId.value
  }
    let allowedVisitsNumbers = [];
    let allowedNoOfVisitsarray = [];
    let temp1 = [];
    let zip = (a1, a2) => a1.map((x, i) => [x, a2[i]]);
    if (typeof allowedNoOfVisitors == "object") {
      allowedVisitsNumbers = allowedNoOfVisitors;
    } else {
      allowedVisitsNumbers = allowedNoOfVisitors.split(",");
    }
    if (allowedVisitsNumbers) {
      // eslint-disable-next-line array-callback-return
      allowedVisitsNumbers.map((item) => {
        temp1.push(item);
      });
    }
    let combined = zip(procedureCode, allowedVisitsNumbers);
    combined.map((items) =>
      allowedNoOfVisitsarray.push({ procCode: items[0], visitNumber: items[1] })
    );

  
    const params = {
       // first section
      group_id: groupId ? groupId.value : "",
      referral_type: selectedReferralType ? selectedReferralType.value : "",
      category:referralCategory ?referralCategory.value : "",
      senderFacility:senderFacilityId.value ? senderFacilityId.value : "",
      rule_name: ruleName ? ruleName : "",
      
      
      // order details section
      service_types: serviceTypes ? serviceTypes : null,
      diagnosis: diagnosis ? diagnosis : null,
      order_priority: orderPriority ? orderPriority : null,
      procedures: procedures ? procedures : null,
      sender_group_id:senderGroupId?senderGroupId:null,
      fulfiller_group_id: fulfillerGroupId ? fulfillerGroupId : null,
      fulfiller_facility_id: fulfillerFacilityId ? fulfillerFacilityId : null,
      fulfiller_physician_id: fulfillerPhysicianId
        ? fulfillerPhysicianId
        : null,

     //patient details section 
     patient_age_condition: patientAgeCondition ? patientAgeCondition : null,
     patient_condition: patientCondition ? patientCondition : null,
     patient_gender: patientGender ? patientGender.value : null,

     // extra fields
      status: processStatus ? 1 : 1,
      created_date: createdDate ? dateUpdate : dateUpdate,
      created_by: admin_id ? admin_id : "",
      updated_date: updatedDate ? dateUpdate : dateUpdate,
      updated_by: admin_id ? admin_id : "",
      
      //  Financial Data
      patient_insurance: patientInsurance ? patientInsurance : null,
      is_enable_eligiblity_check: eligibilityCheck ? 1 : 0,
      is_enable_pre_auth_check: preAuthCheck ? 1 : 0,
      pre_auth_request_type: preAuthRequestType
        ? preAuthRequestType.value
        : null,
      allowed_pos: allowedPos ? allowedPos : null,
      
      
   
      
      // additional documents and fax 
      other_generate_docs: otherGenerateDocs ? otherGenerateDocs : null,
      configuredDocOnSent:configuredDocOnSent?configuredDocOnSent:"",
      tranimitEmiForm : transmitEmiForm ? transmitEmiForm:"",
      transmitFaxNumber : faxNumber ? faxNumber:"",
   
      // Fulfiller Selection
      auto_assign_fulfiller : auto_Assign_Fullfiller ? auto_Assign_Fullfiller : "",
      assign_fulfiller_group_id : assign_FulfillerGroupId ? autogID :"",

      // required Fields
      mandatory_items: mandatoryItems ? tempDiagnosis : [],
      allowed_service_types: allowedServiceTypes ? allowedServiceTypes : null,
      excluded_service_types: excludedServiceTypes
      ? excludedServiceTypes
      : null,
      allowed_fulfiller_group:allowedFulfillerGroupResult,
      excluded_fulfiller_group:excludedFulfillerGroupResult,
      allowed_fulfiller_facility:allowedFulfillerFacilityResult,
      excluded_fulfiller_facility:excludedFulfillerfacilityResult,
      allowed_fulfiller_physician:allowedFulfillerPhysicianResult,
      excluded_fulfiller_physician:excludedFulfillerPhysicianResult,
      allowed_diagnosis: allowedDiagnosis ? allowedDiagnosis : null,
      excluded_diagnosis: excludedDiagnosis ? excludedDiagnosis : null,
      allowed_procedure: allowedProcedure ? allowedProcedure : null,
      allowed_no_of_visits: allowedNoOfVisitsarray
      ? allowedNoOfVisitsarray
      : null,
      excluded_procedure: excludedProcedure ? excludedProcedure : null,
      allowed_insurances: allowedInsurances ? allowedInsurances : null,
      excluded_insurances: excludedInsurances ? excludedInsurances : null,
      
      additional_key_data: additionalKeyData ? additionalKeyData : null,
      transmitAdditionalAttachments:transmitAdditionalAttachments ? transmitAdditionalAttachments : "",
      attachmentTypeResult:attachmentTypeResult ? attachmentTypeResult: "",
      suggestingForm:suggestingForm?suggestingForm.value:"",
      is_auto_process_enable: autoProcessEnable ? 1 : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/add-autoprocess-rule/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        toastr.success(
          "Success",
          "Auto Process  Rule Conditions added"
        );
        toggle();
        setReload(reload + 1);
      
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toastr.error("Error", error.response.data.message);
        } else {
          toastr.success(
            "Success",
            "Auto Process  Rule Conditions added"
          );
          toggle();
          setReload(reload + 1);
        }
      });
  };

  const validate = () => {
    if (ruleName && groupId && selectedReferralType) {
      setButtonBlankState(false);
    } else {
      setButtonBlankState(true);
    }
  };

  useEffect(() => {
    validate();
  }, [ruleName, groupId, referralType, ruleType]); // eslint-disable-line

  const loadOptionsForSenderFacility = (inputValue) => {
    if (inputValue.length >= 3) {
     
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-sender-facility-list/${inputValue.toString()}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  return (
    <div>
      <Modal isOpen={newAutoProcessRule} size="lg" toggle={toggle}>
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          {" "}
          New Auto Process Rule Conditions{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal" style={{maxHeight: '72vh'}}>
          <Container fluid>
            <FormGroup>
              <div className="roles-list-card card">
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="groups">
                        Select Group<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        id="groups"
                        type="text"
                        placeholder="Select Groups"
                        options={groupNamesForDropDown}
                        value={groupId}
                        onChange={(e) => {
                           gid = e.value
                          setGroupId(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Label for="apply_for">
                        Apply For<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        id="apply_for"
                        type="text"
                        placeholder="Select Referral Type"
                        options={referralTypeOptions}
                        value={selectedReferralType}
                        onChange={(e) => {
                          setSelectedReferralType(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Label for="Category type">Category type:</Label>
                    </Col>
                    <Col sm={8}>
                      <AsyncSelect
                        cacheOptions
                        isSearchable
                        placeholder="Select Category"
                        value={referralCategory}
                        loadOptions={loadOptionsForReferralCategory}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        onChange={(e) => {
                          setReferralCategory(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  { // eslint-disable-next-line eqeqeq
                    selectedReferralType.value=="sender" ? (<>
                    <Row>
                    <Col>
                      <Label for="Category type">Sender Facility:</Label>
                    </Col>
                    <Col sm={8}>
                      <AsyncSelect
                        cacheOptions
                        isSearchable
                        placeholder="Select Sender Facility"
                        value={senderFacilityId}
                        loadOptions={loadOptionsForSenderFacility}
                        getOptionLabel={(e) => e.label}
                        getOptionValue={(e) => e.value}
                        onChange={(e) => {
                          setSenderFacilityId(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br /></>):("")}
                  
                  <Row>
                    <Col>
                      <Label for="name">
                        Name<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="name"
                        type="text"
                        placeholder="Name"
                        value={ruleName}
                        onChange={(e) => {
                          setRuleName(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />

                 
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details>
                    <summary>
                      <h6 className="role-list-head-custom">Order Details</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="serviceType">
                          Service Type<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Select Service Type"
                          value={selectedServiceTypes}
                          loadOptions={loadServiceTypeOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedServiceTypes(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="diagnosis">
                          Diagnosis<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Diagnosis"
                          value={selectedDiagnosis}
                          loadOptions={loadDiagnosisOptions}
                          getOptionLabel={(e) => e.code_show}
                          getOptionValue={(e) => e.id}
                          getOptionCode={(e) => e.code}
                          onChange={(e) => {
                            setSelectedDiagnosis(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="priority">Priority:</Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          isMulti
                          id="priority"
                          type="text"
                          placeholder="Select Priority"
                          options={orderPriorityOptions}
                          value={selectedOrderPriority}
                          onChange={(e) => {
                            setSelectedOrderPriority(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="procedure">Procedure:</Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Procedure"
                          value={selectedProcedure}
                          loadOptions={loadProcedureOptions}
                          getOptionLabel={(e) => e.code_show}
                          getOptionValue={(e) => e.id}
                          getOptionCode={(e) => e.code}
                          onChange={(e) => {
                            setSelectedProcedure(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                   
                    { // eslint-disable-next-line eqeqeq
                      selectedReferralType.value=="sender" ? (<>
                      <Row>
                      <Col>
                        <Label for="fulfillerGroup">Fulfiller Group:</Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Fulfiller Group"
                          value={selectedFulfillerGroup}
                          loadOptions={loadFulfillerGroupOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedFulfillerGroup(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    {selectedFulfillerGroup ? (
                      <>
                        <Row>
                          <Col>
                            <Label for="fulfillerFacility">
                              Fulfiller Facility:
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              isSearchable
                              placeholder="Fulfiller Facility"
                              value={selectedFulfillerFacility}
                              loadOptions={loadFacilityOptionsForFacility}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              onChange={(e) => {
                                setSelectedFulfillerFacility(e);
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Label for="fulfillerPhysician">
                              Fulfiller Physician:
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              isSearchable
                              placeholder="Fulfiller Physician"
                              value={selectedPhysicianIds}
                              loadOptions={loadPhysicianOptions}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              onChange={(e) => {
                                setSelectedPhysicianIds(e);
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                      </>
                    ) : (
                      ""
                    )} </>):(<>
                    <Row>
                      <Col>
                        <Label for="fulfillerGroup">Sender:</Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="sender Group"
                          value={selectedSenderGroup}
                          loadOptions={loadSenderGroupOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedSenderGroup(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    
                    </>)}

                  
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details>
                    <summary>
                      <h6 className="role-list-head-custom">Patient Details</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="age">
                          Age<span className="">:</span>
                        </Label>
                      </Col>
                      <Col xs="4">
                        <Select
                          id="age"
                          type="text"
                          placeholder="Select"
                          options={ageOptions}
                          value={selectedAgeCondition}
                          onChange={(e) => {
                            setSelectedAgeCondition(e);
                          }}
                        />
                      </Col>
                      {selectedAgeCondition.label !== "Between" ? (
                        <>
                          <Col xs="4">
                            <Input
                              id="age"
                              type="text"
                              placeholder="Age"
                              value={selectedAge}
                              onChange={(e) => {
                                setSelectedAge(e.target.value);
                              }}
                            />
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      {
                      // eslint-disable-next-line eqeqeq
                      selectedAgeCondition.label == "Between" ? (
                        <>
                          <Col xs="2">
                            <Input
                              id="age"
                              type="text"
                              placeholder="Min"
                              value={minValue}
                              onChange={(e) => {
                                setMinValue(e.target.value);
                              }}
                            />
                          </Col>
                          <Col xs="2">
                            <Input
                              id="age"
                              type="text"
                              placeholder="Max"
                              value={maxValue}
                              onChange={(e) => {
                                setMaxValue(e.target.value);
                              }}
                            />
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                    <br />

                    <Row>
                      <Col>
                        <Label for="gender">
                          Gender<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          id="gender"
                          type="text"
                          placeholder="Gender"
                          options={genderOptions}
                          value={patientGender}
                          onChange={(e) => {
                            setPatientGender(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="condition">
                          Condition<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Conditions"
                          value={selectedConditions}
                          loadOptions={loadConditionOptions}
                          getOptionLabel={(e) => e.category}
                          getOptionValue={(e) => e.id}
                          onChange={(e) => {
                            setSelectedConditions(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details>
                    <summary>
                      <h6 className="role-list-head-custom">Financial Data</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="payor">
                          Payor<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Select Insurance"
                          value={selectedInsurances}
                          loadOptions={loadInsuranceOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedInsurances(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    {// eslint-disable-next-line eqeqeq
                    selectedReferralType.value=="sender" ? (<>
                      <div style={{ paddingLeft: "37%" }}>
                      <Row>
                        <Col sm={2}>
                          <Input
                            id="eligibility_checkbox"
                            type="checkbox"
                            checked={eligibilityCheck}
                            onChange={(e) => {
                              setEligibilityCheck(e.target.checked);
                            }}
                          />
                          <Label for="eligibility_checkbox">
                            {" "}
                            Enable Eligibility Check
                          </Label>
                        </Col>
                        {
                        // eslint-disable-next-line eqeqeq
                        eligibilityCheck == true ? (
                          <>
                            <Col sm={2}>
                              <Input
                                id="preAuth_checkbox"
                                type="checkbox"
                                checked={preAuthCheck}
                                onChange={(e) => {
                                  setPreAuthCheck(e.target.checked);
                                }}
                              />

                              <Label for="preAuth_checkbox">
                                {" "}
                                Enable Pre-Auth Check
                              </Label>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        {
                        // eslint-disable-next-line eqeqeq
                        eligibilityCheck && preAuthCheck == true ? (
                          <>
                            <Col sm={4}>
                              <Label for="requestType">
                                Request Type<span className="">:</span>
                              </Label>
                              <Select
                                id="requestType"
                                type="text"
                                placeholder="Request Type"
                                options={requestTypeOptions}
                                value={preAuthRequestType}
                                onChange={(e) => {
                                  setPreAuthRequestType(e);
                                }}
                              />
                            </Col>
                            <Col sm={4}>
                              <Label for="pos">
                                POS<span className="">:</span>
                              </Label>
                              <Input
                                id="pos"
                                type="text"
                                placeholder="pos"
                                value={allowedPos}
                                onChange={(e) => {
                                  setAllowedPos(e.target.value);
                                }}
                              />
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                    </>):("")}

                    
                  </details>
                </CardBody>
              </div>

              <div className="roles-list-card card">
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="">
                        Requires Add-on documents with Order
                        <span className="">:</span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <ToggleButton
                        inactiveLabel={""}
                        activeLabel={""}
                        value={toggleValue}
                        onToggle={(e) => {
                          if (otherGenerateDocs) {
                            setToggleValue(!toggleValue);
                          } else {
                            setToggleValue(!toggleValue);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  {
                  // eslint-disable-next-line eqeqeq
                  toggleValue == 1 ? (
                    <>
                      <Row>
                        <Col>
                          <Label for="additionalDocuments">
                            Additional Documents:
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <Select
                            isClearable
                            id="additionalDocuments"
                            type="text"
                            placeholder="Select Documents"
                            options={additionalDocsOptions}
                            value={selectedadditionalDocs}
                            onChange={(e) => {
                              setSelectedadditionalDocs(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                          <Col>
                            <Label for="">
                              Transmit Configured Documents on sent
                              <span className="">:</span>
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <ToggleButton
                              inactiveLabel={""}
                              activeLabel={""}
                              value={configuredDocOnSent}
                              onToggle={() => {
                             
                                let a = !configuredDocOnSent
                                setConfiguredDocOnSent(!configuredDocOnSent);
                                // eslint-disable-next-line eqeqeq
                            if (a == 1 || a == true){
                              setConfiguredDocOnSent(true);
                            }
                            else {
                              setConfiguredDocOnSent(false);
                            }
                              }}
                            />
                          </Col>
                        </Row>
                        {(configuredDocOnSent === 1 || configuredDocOnSent === true) ? (<>  <Row>
                            <Col sm={4}>
                              <Input
                                id="eligibility_checkbox"
                                type="checkbox"
                                checked={transmitEmiForm}
                                onChange={(e) => {
                                  seTransmitEmiForm(e.target.checked);
                                }}
                              />
                              <Label for="eligibility_checkbox">
                                {" "}
                              Transmit Emi form via fax
                              </Label>
                            </Col>
                          </Row>
                     
                          {// eslint-disable-next-line eqeqeq
                            (transmitEmiForm == 1 || transmitEmiForm == true)?(<>
                                 <Row>
                                  <Col>
                                 
                              <Input
                                id="age"
                                type="text"
                                placeholder="enter multiple fax number with comma"
                                value={faxNumber}
                                onChange={(e) => {
                                  setFaxNumber(e.target.value);
                                }}
                              />
                              </Col>
                              </Row>
                            </>):("")
                          }</> ): ""}
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details open={true}>
                    <summary>
                      <h6 className="role-list-head-custom">
                        Fulfiller Selection
                      </h6>
                    </summary>
                    <Row>
                      <div>
                        <label>
                          <input
                            type="radio"
                            value="1"
                            checked={auto_Assign_Fullfiller === 1}
                            onChange={() => seAuto_Assign_Fullfiller(1)}
                          />
                          Auto Select by Distance & Ranking
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value="0"
                            checked={auto_Assign_Fullfiller === 0}
                            onChange={() => seAuto_Assign_Fullfiller(0)}
                          />
                          Disable Auto Selection
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value="2"
                            checked={auto_Assign_Fullfiller === 2}
                            onChange={() => seAuto_Assign_Fullfiller(2)}
                          />
                          Custom
                        </label>
                        {auto_Assign_Fullfiller === 2 && (
                          <div>
                            <label>
                              Custom Fulfiller:
                              <AsyncSelect
                                cacheOptions
                                isSearchable
                                placeholder="Select Fulfiller"
                                value={assign_FulfillerGroupId}
                                loadOptions={loadFulfillerOptions}
                                getOptionLabel={(e) => e.label}
                                getOptionValue={(e) => e.value}
                                onChange={(e) => {
                                  setAassign_FulfillerGroupId(e);
                                }}
                              />
                            </label>
                          </div>
                        )}
                      </div>
                    </Row>
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details>
                    <summary>
                      <h6 className="role-list-head-custom">
                        Required Fields<span className="required">*</span>
                      </h6>
                    </summary>

                    {/* ////////////////////////////////////////////////////////////////////////////////// */}
                    {/* //////////////////////////// NEW-render ReferralRule ///////////////////////////// */}
                    {/* ////////////////////////////////////////////////////////////////////////////////// */}
                    <Container fluid>
                      <Row>
                        <Col>
                          <Input
                            id="serviceType_checkbox"
                            type="checkbox"
                            checked={true}
                          />
                          <Label for="serviceType_checkbox">Service Type</Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Label for="serviceType">
                            Allowed Service Type<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select Service Type"
                            value={selectedAllowedServiceTypes}
                            loadOptions={loadServiceTypeOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedAllowedServiceTypes(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col>
                          <Label for="excludedServiceType">
                            Excluded Service Type<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select Service Type"
                            value={selectedExcludedServiceTypes}
                            loadOptions={loadServiceTypeOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedExcludedServiceTypes(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />
                      <br />
                      {
                      // eslint-disable-next-line eqeqeq
                      selectedReferralType.value=="sender" ? (<>
                        <Row>
                        <Col>
                          <Label for="serviceType">
                            Allowed fulfiller Group<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select fulfiller Group"
                            value={selectedAllowedFulfillerGroup}
                            loadOptions={loadFulfillerGroupOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedAllowedFulfillerGroup(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br /><Row>
                        <Col>
                          <Label for="fulfillerfacility">
                          Allowed fulfiller facility<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select fulfiller facility"
                            value={selectedAllowedFulfillerFacility}
                            loadOptions={loadFacilityOptionsForFacility}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedAllowedFulfillerFacility(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br /><Row>
                        <Col>
                          <Label for="serviceType">
                          Allowed fulfiller Physician<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select fulfiller Physician"
                            value={selectedAllowedFulfillerPhysician}
                            loadOptions={loadPhysicianOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedAllowedFulfillerPhysician(e);
                            }}
                          />
                        </Col>
                      </Row>
                   
                      <br /><Row>
                        <Col>
                          <Label for="serviceType">
                        Excluded fulfiller group<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select Fulfiller Group"
                            value={selectedExcludedFulfillerGroup}
                            loadOptions={loadFulfillerGroupOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedExcludedFulfillerGroup(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br /><Row>
                        <Col>
                          <Label for="serviceType">
                          Excluded fulfiller facility<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select Fulfuiller facility"
                            value={selectedExcludedFulfillerFacility}
                            loadOptions={loadFacilityOptionsForFacility}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedExcludedFulfillerFacility(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br /><Row>
                        <Col>
                          <Label for="serviceType">
                          Excluded fulfiller Physician<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select fulfiller physiciam"
                            value={selectedExcludedFulfillerPhysician}
                            loadOptions={loadPhysicianOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedExcludedFulfillerPhysician(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br /></>):("")}
                      

                      <>
                        {referralRuleData.length > 0
                          ? referralRuleData.map((item) => {
                              return (
                                <>
                                  <Row>
                                    <Col>
                                      <Input
                                        id={item.item}
                                        type="checkbox"
                                        checked={handelCheckedForReferralRuleItem(
                                          item
                                        )}
                                        value={item.id}
                                        onChange={(e) => {
                                          handelOnChangeForReferralRuleItem(
                                            e,
                                            item
                                          );
                                        }}
                                      />

                                      <Label for={item.item}>
                                        {
                                        // eslint-disable-next-line eqeqeq
                                        item.item == "Original Referral"
                                          ? "PCP Notes"
                                          : item.item}
                                      </Label>
                                    </Col>
                                  </Row>

                                  {checkedFour && item.item === "Diagnosis" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Diagnosis
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Diagnosis"
                                            value={selectedAllowedDiagnosis}
                                            loadOptions={loadDiagnosisOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedAllowedDiagnosis(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Diagnosis
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Diagnosis"
                                            value={selectedExcludedDiagnosis}
                                            loadOptions={loadDiagnosisOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedExcludedDiagnosis(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {checkedSix && item.item === "Procedure" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Procedure
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Allowed Procedure"
                                            value={selectedAllowedProcedure}
                                            loadOptions={loadProcedureOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedAllowedProcedure(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <div id="allowedVisits">
                                        <Row>
                                          <Col>
                                            <Label for="excludedServiceType">
                                              Allowed No. of Visits
                                              <span className="">:</span>
                                            </Label>
                                          </Col>
                                          <Col sm={8}>
                                            <Input
                                              id=""
                                              type="text"
                                              placeholder="allowed no of visits (1,2,3...)"
                                              onChange={(e) => {
                                                  setAllowedNoOfVisitors(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                      <br />

                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Procedure
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Procedure"
                                            value={selectedExcludedProcedure}
                                            loadOptions={loadProcedureOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedExcludedProcedure(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {checkedEight &&
                                  item.item === "Insurance Details" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Insurances
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Insurance"
                                            value={selectedAllowedInsurance}
                                            loadOptions={loadInsuranceOptions}
                                            getOptionLabel={(e) => e.label}
                                            getOptionValue={(e) => e.value}
                                            onChange={(e) => {
                                              setSelectedAllowedInsurance(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Insurances
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Insurance"
                                            value={selectedExcludedInsurance}
                                            loadOptions={loadInsuranceOptions}
                                            getOptionLabel={(e) => e.label}
                                            getOptionValue={(e) => e.value}
                                            onChange={(e) => {
                                              setSelectedExcludedInsurance(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                          : ""}
                      </>
                    </Container>
                  </details>
                </CardBody>
              </div>

              <div className="roles-list-card card">
                <CardBody>
                {
                // eslint-disable-next-line eqeqeq
                selectedReferralType.value=="sender" ? (<>
                  <Row>
                          <Col>
                            <Label for="">
                            Transmit Additional Attachments on Sent Via Fax
                              <span className="">:</span>
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <ToggleButton
                              inactiveLabel={"OFF"}
                              activeLabel={"ON"}
                              value={transmitAdditionalAttachments}
                              onToggle={() => {
                             
                                let a = !transmitAdditionalAttachments
                                setTransmitAdditionalAttachments(!transmitAdditionalAttachments);
                                // eslint-disable-next-line eqeqeq
                            if (a == 1 || a == true){
                              setTransmitAdditionalAttachments(true);
                            }
                            else {
                              setTransmitAdditionalAttachments(false);
                            }
                              }}
                            />
                          </Col>
                        </Row>
                
                    <br/>
                    {
                    // eslint-disable-next-line eqeqeq
                    (transmitAdditionalAttachments == 1 || transmitAdditionalAttachments == true) ?(<>
                    
                      <Row>
                  
                  <Col sm={8}>
                    <AsyncSelect
                    isMulti
                      cacheOptions
                      isSearchable
                      placeholder="Select Attachment Type"
                      value={selectAttachmentType}
                      loadOptions={loadOptionsForFaxAttachment}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      onChange={(e) => {
                       
                        console.log(e)
                        setSelectAttachmentType(e);
                      }}
                    />
                  </Col>
                </Row>
                <br/>
                    
                    </>):("")}
                  <Row>
                    <Col>
                      <Label for="">Auto Send Referral:</Label>
                    </Col>
                    <Col sm={8}>
                      <ToggleButton
                        inactiveLabel={"OFF"}
                        activeLabel={"ON"}
                        value={autoProcessEnable}
                        onToggle={(e) => {
                          setAutoProcessEnable(!autoProcessEnable);
                        }}
                      />
                    </Col>
                  </Row>
                  <br /></>):(<> 
                    <Row>
                    <Col>
                      <Label for="serviceType">
                      Suggesting Form:<span className="">:</span>
                      </Label>
                  </Col>
                  <Col sm={8}>
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      isSearchable
                      placeholder="Suggesting Form:"
                      value={suggestingForm}
                      loadOptions={loadSuggestingFormOptions}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      onChange={(e) => {
                      setSuggestingForm(e);
                      }}
                    />
                  </Col>
                </Row>
                <br/>
                  </>)
                             }             
                     
                </CardBody>
              </div>
            </FormGroup>
          </Container>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            onClick={() => {
              patientAgePopulate();
              addAutoProcessExceptionRules();
            }}
            disabled={buttonBlankState}
            title={
              buttonBlankState
                ? "Please fill in all mandatory fields"
                : !buttonBlankState
                ? "Click to save"
                : ""
            }
          >
            Add
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewAutoProcessRule;
