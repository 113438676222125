import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Container,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Cookie from 'js-cookie';

const EditFacilityDepartmentAssignRoomModal = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const {
    departmentOptions,
    roomOptions,
    editFacilityDepartmentModal,
    setEditFacilityDepartmentModal,
    editDepartment,
    setEditDepartment,
    editRoom,
    // eslint-disable-next-line no-unused-vars
    facilityID,
    setEditRoom,
    setFacilityDepartmentModal,
    facilityDepartmentModal,
    getAssignRoomDetails,
  } = props;
  // const [assignRoomModal, setAssignRoomModal] = useState(false);

  const [tempEditRoom, setTempEditRoom] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [newRoom, setNewRoom] = useState("");
  const [addButtonStatus, setAddButtonStatus] = useState(false);

  const [spinner, setSpinner] = useState(false);

  const [roomForUpdates, setRoomForUpdates] = useState([]);

  const facility_id = localStorage.getItem("facilityId");
  let rooms = editRoom;
  let roomsArray = rooms.toString().split(",");

  const toggle = () => {
    setEditFacilityDepartmentModal(!editFacilityDepartmentModal);
  };
  const disableAddButton = () => {
    if (newRoom) {
      setAddButtonStatus(false);
    } else {
      setAddButtonStatus(true);
    }
  };

  const setInitialDepartment = () => {
    // eslint-disable-next-line array-callback-return
    departmentOptions.map((item) => {
      // eslint-disable-line
      if (item.label === editDepartment) {
        setEditDepartment(item);
      }
    });
  };
  const setInitialRoom = (roomsArray) => {
    // eslint-disable-next-line array-callback-return
    roomOptions.map((item) => {
      // eslint-disable-line
      // eslint-disable-next-line array-callback-return
      roomsArray.map((roomNumber) => {
        // eslint-disable-line
        if (Number(item.label) === Number(roomNumber)) {
          setTempEditRoom((prevItem) => [...prevItem, item]);
        }
      });
    });
  };
  // const confirmation = () => {

  //     if (newDepartment && newRoom ) {

  //         editDepartments()
  //     }

  // };

  const editDepartments = () => {
    setSpinner(true);
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/edit-facility-assign-room/`,
        {
          facility_id: facility_id ? Number(facility_id) : "",
          department: editDepartment ? editDepartment.label : "",
          room: roomForUpdates ? roomForUpdates.toString() : "",
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        toggle();
        setSpinner(false);
        toastr.success("Success", "Room updated");

        setFacilityDepartmentModal(!facilityDepartmentModal);

        getAssignRoomDetails();
      })
      .catch(function (error) {
        setSpinner(false);
        toastr.error("Update Failed", error.response.data.message);
      });
  };

  useEffect(() => {
    if (!editFacilityDepartmentModal) {
      setEditDepartment("");
      setEditRoom("");
      setTempEditRoom([]);
    }
  }, [editFacilityDepartmentModal]); // eslint-disable-line

  useEffect(() => {
    if (departmentOptions && editDepartment) {
      setInitialDepartment();
    }
  }, [departmentOptions, editDepartment]); // eslint-disable-line

  useEffect(() => {
    if (editRoom) {
      setInitialRoom(roomsArray);
    }
  }, [editRoom]); // eslint-disable-line

  useEffect(() => {
    if (tempEditRoom) {
      const array2 = [];
      // eslint-disable-next-line array-callback-return
      tempEditRoom.map((item) => {
        // eslint-disable-line
        array2.push(item.label);
      });
      setRoomForUpdates(array2);
    }
  }, [tempEditRoom]); // eslint-disable-line

  useEffect(() => {
    disableAddButton();
  }, [newRoom]); // eslint-disable-line

  return (
    <div>
      <Modal isOpen={editFacilityDepartmentModal} size="md">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit Details{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          {spinner ? <Spinner /> : ""}
          <Container fluid>
            <FormGroup>
              <Row>
                <Col sm={12}>
                  <Label>
                    Department <span className=""></span> :
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Select
                    options={departmentOptions}
                    onChange={(event) => setEditDepartment(event)}
                    value={editDepartment}
                    placeholder="Select Department"
                    isSearchable
                    isClearable
                    isDisabled
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={12}>
                  <Label>
                    Room <span className="required">*</span> :
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Select
                    isMulti
                    options={roomOptions}
                    id="room"
                    value={tempEditRoom}
                    placeholder="Select room"
                    onChange={(event) => setTempEditRoom(event)}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Container>
        </ModalBody>
        <ModalFooter className="advanced-search-button  custom-modal-footer">
          <Button
            onClick={() => {
              editDepartments();
            }}
            // disabled={addButtonStatus}
            title={addButtonStatus ? "Fill the required fields" : ""}
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditFacilityDepartmentAssignRoomModal;
