import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


const MicroserviceStatusChangeModal = (props) => {
  const {
    status,
    statusModal,
    setStatusModal,
    confirmation

  } = props;


  const toggle = () => {
    setStatusModal(!statusModal);
  };




 

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={statusModal}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Delete Confirmation</ModalHeader>
        <ModalBody>
            <div style={{'textAlign':'center'}}>
            Are you sure you want to change the user status to {status === 0? 'active ?':'inactive ?' } 
            </div>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button 
          className="yes-button button-width" 
          onClick={()=>{
            confirmation()
          }}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MicroserviceStatusChangeModal;