import React, { useState, useEffect, useContext } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import DropJpg from "../utilities/DropJpg";

import AsyncSelect from "react-select/async";
import Select from "react-select";
import { GlobalState } from "../../contextAPI/Context";
import _ from "lodash";
import moment from "moment";
import Cookie from 'js-cookie';
//import { NULL } from 'node-sass';

const NewProviderModal = (props) => {
  const {
    setNewProviderModal,
    newProviderModal,
    userDataReload,
    setUserDataReload,
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  // CONTEXT VARIABLES
  const { m_zipList } = useContext(GlobalState); // eslint-disable-line
  //const [newGroupName, setNewGroupName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [spinner, setSpinner] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newNpi, setNewNpi] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newFirstName, setNewFirstName] = useState("");
  const [newMiddleName, setNewMiddleName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newDegree, setNewDegree] = useState("");
  const [newCredentials, setNewCredentials] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newAddress2, setNewAddress2] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newState, setNewState] = useState("");
  const [newZipCode, setNewZipCode] = useState("");
  const [newProviderType, setNewProviderType] = useState("");
  const [newDoctorGroup, setNewDoctorGroup] = useState("");
  const [newFacility, setNewFacility] = useState("");
  const [newMarketingRep, setNewMarketingRep] = useState("");
  const [newLicenseNumber, setNewLicenseNumber] = useState("");
  const [newExpiration, setNewExpiration] = useState("");
  const [newDeaNumber, setNewDeaNumber] = useState("");
  const [newUpin, setNewUpin] = useState("");
  const [newStateMedicaidId, setNewStateMedicaidId] = useState("");
  const [newTaxonomyCode, setNewTaxonomyCode] = useState("");
  const [newFax, setNewFax] = useState("");
  const [newPhoneHome, setNewPhoneHome] = useState("");
  const [newContactNumber, setNewContactNumber] = useState("");
  const [newProviderId, setNewProviderId] = useState("");
  const [newReferralStatus, setNewReferralStatus] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [newUpdateDate, setNewUpdateDate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [newCreateDate, setNewCreateDate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [newSignature, setNewSignature] = useState();
  const [newNote, setNewNote] = useState("");
  const [jpgFile, setJpgFile] = useState("");
  const [fileReady, setFileReady] = useState(false); // eslint-disable-line
  const [optForZip, setOptForZip] = useState("");
  const [addButtonStatus, setAddButtonStatus] = useState(true);
  const [timeZoneDropDown, setTimeZoneDropDown] = useState([]); // eslint-disable-line
  const [isProviderEmailValid, setIsProviderEmailValid] = useState(false);
  const regXForNubers = /^[0-9]*$/;
  // eslint-disable-next-line no-unused-vars
  const regXForFaxNumbers = /^[0-9,-]*$/;
  // eslint-disable-next-line no-useless-escape
  const regexForEmail = new RegExp("^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-zA-Z0-9-]+\\.)+[a-zA-Z0-9]{2,}$");
 

  const toggle = () => {
    setNewProviderModal(!newProviderModal);
    setNewTitle("");
    setNewNpi("");
    setNewEmail("");
    setNewFirstName("");
    setNewMiddleName("");
    setNewLastName("");
    setNewDegree("");
    setNewCredentials("");
    setNewAddress("");
    setNewAddress2("");
    setNewCity("");
    setNewState("");
    setNewZipCode("");
    setNewProviderType("");
    setNewDoctorGroup("");
    setNewFacility("");
    setNewMarketingRep("");
    setNewLicenseNumber("");
    setNewExpiration("");
    setNewDeaNumber("");
    setNewUpin("");
    setNewStateMedicaidId("");
    setNewTaxonomyCode("");
    setNewPhoneHome("");
    setNewFax("");
    setNewContactNumber("");
    setNewProviderId("");
    setNewReferralStatus("");
    setNewUpdateDate("");
    setNewCreateDate("");
    setNewNote("");
    setIsProviderEmailValid(false);
  };

  const confirmation = () => {
    if (!newFirstName && newLastName) {
      toastr.error(
        "Name Is Required",
        "Please enter the FirstName and LastName!"
      );
    }
    if (!newNpi) {
      toastr.error("NPI Is Required", "Please enter the NPI!");
    }

    if (newFirstName && newLastName && newNpi && newZipCode) {
      // CreateProviderApiCall();
      CreateProviderSignatureApiCall();
    }
  };
  const ProviderTitleOptions = [
    { value: "1", label: "Dr." },
    { value: "2", label: "MS." },
    { value: "3", label: "MR." },
    { value: "4", label: "PROF." },
  ];

  const documentUpload = (
    <>
      <Card className="kyc-bg-color2">
        <CardHeader tag="h5">Upload Signature </CardHeader>
        <CardBody>
          {/* DRAG AND DROP COMPONENT  */}
          <DropJpg
            setFileReady={setFileReady}
            // documentTypeId={documentTypeId}
            setJpgFile={setJpgFile}
          />
        </CardBody>
      </Card>
      <Label>The file should be in .jpg format </Label>
    </>
  );
  const minLengthforzip = 1;

  const customNoOptionsMessageforZip = ({ inputValue }) => {
    if (inputValue.length < minLengthforzip) {
      return "Please search with at least 1 character";
    } else {
      return "No option";
    }
  };
  const CreateProviderSignatureApiCall = () => {
    if (newEmail && newEmail.length > 1) {
      if (!regexForEmail.test(newEmail)) {
        setIsProviderEmailValid(true);
        return false;
      } else {
        setIsProviderEmailValid(false);
      }
    }
    
    var date2 = new Date();
    const format1 = "YYYY-MM-DD HH:mm:ss";
    let dateTime1 = moment(date2).format(format1);
    const FileFormData = new FormData();
    FileFormData.set("file", jpgFile[0] ? jpgFile[0] : "");
    FileFormData.set("title", newTitle.label);
    FileFormData.set("email", newEmail);
    FileFormData.set("fax", newFax);
    FileFormData.set("npi", newNpi);
    FileFormData.set("first_name", newFirstName);
    FileFormData.set("middle_name", newMiddleName);
    FileFormData.set("last_name", newLastName);
    FileFormData.set("degree", newDegree);
    FileFormData.set("credentials", newCredentials);
    FileFormData.set("address", newAddress);
    FileFormData.set("address2", newAddress2);
    FileFormData.set("city", newCity);
    FileFormData.set("state", newState);
    FileFormData.set("zipcode", newZipCode.label);
    FileFormData.set("providertype", newProviderType);
    FileFormData.set("doctor_group", newDoctorGroup);
    FileFormData.set("facility", newFacility);
    FileFormData.set("marketing_rep", newMarketingRep);
    FileFormData.set("license_number", newLicenseNumber);
    FileFormData.set("expiration", newExpiration);
    FileFormData.set("dea_number", newDeaNumber);
    FileFormData.set("upin", newUpin);
    FileFormData.set("state_medicaid_id", newStateMedicaidId);
    FileFormData.set("taxonomy_code", newTaxonomyCode);
    FileFormData.set("phone_home", newPhoneHome);
    FileFormData.set("contactnumber", newContactNumber);
    FileFormData.set("providerid", newProviderId);
    FileFormData.set("referralStatus",newReferralStatus.value ? newReferralStatus.value : 2);
    FileFormData.set("updateDate", dateTime1);
    FileFormData.set("createDate", dateTime1);
    FileFormData.set("note", newNote);
    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/create-provider-image/`, FileFormData, {
        headers: {
          Authorization: API_TOKEN,
          "Content-Type": "multipart/form-data",

          // "Content-Type": "multipart/form-data",
          // Accept: "application/json",
          // type: "formData",
        },
      })
      .then(function (response) {
        setUserDataReload(userDataReload + 1);
        toastr.success(
          "Successfully Created",
          "New Provider successfully Added"
        );
        toggle();
      })
      .catch(function (error) {
        toastr.error("Failed", error.response.data.message);
      });
  };

  const disableAddButton = () => {
    if (newFirstName && newLastName && newNpi && newZipCode) {
      setAddButtonStatus(false);
    } else {
      setAddButtonStatus(true);
    }
  };

  useEffect(() => {
    disableAddButton();
  }, [newFirstName, newLastName, newNpi, newZipCode]); // eslint-disable-line
  //
  //
  //
  // , ,

  //\
  // ,referralStatus
  // , note, createDate,
  //     updateDate,

  const createZipOption = () => {
    if (m_zipList) {
      var array = m_zipList;
      const options = array.map((item) => {
        return {
          value: item.id,
          label: item.zipcode,
        };
      });
      setOptForZip(options);
    }
  };
  const populateStateAndCountry = () => {
    if (m_zipList) {
      var array = m_zipList;
      // eslint-disable-next-line array-callback-return, no-unused-vars
      const opt = array.map((item) => {
        // eslint-disable-line
        if (newZipCode.value === item.id) {
          setNewCity(item.city);
          setNewState(item.stateFullName);
        }
      });
    }
  };
  const getAsyncOptions = (inputValue) => {
    if (optForZip) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(optForZip, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const changeOnPhoneNumber = (e) => {
    if (regXForNubers.test(e) === false) {
      toastr.warning("Invalid Phone Number", "alphabets are not allowed");
    } else {
      setNewPhoneHome(e);
    }
  };
  const changeOnNPI = (e) => {
    if (regXForNubers.test(e) === false) {
      toastr.warning("Invalid attempt", "alphabets are not allowed");
    } else {
      setNewNpi(e);
    }
  };
  const changeOnFax = (e) => {
    if (regXForFaxNumbers.test(e) === false) {
      toastr.warning("Invalid Fax Number", "alphabets are not allowed");
    } else {
      setNewFax(e);
    }
  };

  useEffect(() => {
    if (newZipCode !== null && m_zipList) {
      populateStateAndCountry();
    } else {
      setNewCity("");
      setNewState("");
    }
  }, [newZipCode]); // eslint-disable-line

  useEffect(() => {
    if (m_zipList) createZipOption();
  }, [m_zipList]); // eslint-disable-line
  // use

  // useEffect(() => {
  //   setNewReferralStatus({ value: "1", label: "Active" });
  // }, []);
  //
  return (
    <div>
      <Modal
        isOpen={newProviderModal}
        id="providermodal"
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Add New Provider{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          <FormGroup>
            <Row>
              <Col sm={2}>
                <Label>Title:</Label>
                <Select
                  options={ProviderTitleOptions}
                  onChange={(event) => setNewTitle(event)}
                  value={newTitle}
                  placeholder="Title"
                  isSearchable
                  isClearable
                />
              </Col>
              <Col sm={10}>
                <Label>
                  First Name:<span className="required">*</span>
                </Label>
                <Input
                  id="fName"
                  type="text"
                  value={newFirstName}
                  placeholder="Enter First Name"
                  onChange={(e) => {
                    setNewFirstName(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Middle Name:</Label>
                <Input
                  id="MName"
                  type="text"
                  value={newMiddleName}
                  placeholder="Enter Middle Name "
                  onChange={(e) => {
                    setNewMiddleName(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>
                  Last Name:<span className="required">*</span>
                </Label>
                <Input
                  id="LName"
                  type="text"
                  value={newLastName}
                  placeholder="Enter Last Name"
                  onChange={(e) => {
                    setNewLastName(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Credentials:</Label>
                <Input
                  id="credentials"
                  type="text"
                  value={newCredentials}
                  placeholder="Enter Credential"
                  onChange={(e) => {
                    setNewCredentials(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Email:</Label>
                <Input
                  id="email"
                  type="email"
                  value={newEmail}
                  placeholder="Enter Email"
                  onChange={(e) => {
                    setNewEmail(e.target.value);
                  }}
                  invalid={isProviderEmailValid ? true : false}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Degree:</Label>
                <Input
                  id="Degree"
                  type="text"
                  value={newDegree}
                  placeholder="Enter Degree"
                  onChange={(e) => {
                    setNewDegree(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Contact Number:</Label>
                <Input
                  id="contact"
                  type="number"
                  value={newContactNumber}
                  placeholder="Enter the contact number "
                  onChange={(e) => {
                    if (e.target.value.length >= 13) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } else {
                      setNewContactNumber(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Phone Home:</Label>
                <Input
                  id="Phone Home"
                  value={newPhoneHome}
                  placeholder="Enter the Phone Home "
                  onChange={(e) => {
                    if (e.target.value.length >= 13) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } else {
                      changeOnPhoneNumber(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Fax:</Label>
                <Input
                  id="contact"
                  value={newFax}
                  placeholder="Enter the Fax "
                  onChange={(e) => {
                    if (e.target.value.length >= 11) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } else {
                      changeOnFax(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label for="zip">
                  ZIP:<span className="required">*</span> :
                </Label>
                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  placeholder="Select Zipcode"
                  noOptionsMessage={customNoOptionsMessageforZip}
                  value={newZipCode}
                  onChange={(event) => setNewZipCode(event)}
                  loadOptions={(inputValue) => getAsyncOptions(inputValue)}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label> City:</Label>
                <Input
                  id="city"
                  type="text"
                  value={newCity}
                  disabled
                  placeholder="Enter The City"
                  onChange={(e) => {
                    setNewCity(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>State:</Label>
                <Input
                  id="state"
                  type="text"
                  disabled
                  value={newState}
                  placeholder="Enter The zip code"
                  onChange={(e) => {
                    setNewState(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Address:</Label>
                <Input
                  id="address"
                  type="textarea"
                  rows={4}
                  cols={2}
                  value={newAddress}
                  placeholder="Enter The Address"
                  onChange={(e) => {
                    setNewAddress(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Address2:</Label>
                <Input
                  id="address2"
                  type="textarea"
                  rows={4}
                  cols={2}
                  value={newAddress2}
                  placeholder="Enter The Second Address "
                  onChange={(e) => {
                    setNewAddress2(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Provider Id:</Label>
                <Input
                  id="Providerid"
                  type="number"
                  value={newProviderId}
                  placeholder="Enter Provider id"
                  onChange={(e) => {
                    setNewProviderId(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Provider Type:</Label>
                <Input
                  id="Providertype"
                  value={newProviderType}
                  placeholder="Enter Provider Type"
                  onChange={(e) => {
                    setNewProviderType(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Doctor Group:</Label>
                <Input
                  id="doctorGroup"
                  value={newDoctorGroup}
                  placeholder="Enter Doctor Group "
                  onChange={(e) => {
                    setNewDoctorGroup(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Facility:</Label>
                <Input
                  id="Facility"
                  type="text"
                  value={newFacility}
                  placeholder="Enter Facility"
                  onChange={(e) => {
                    setNewFacility(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Marketing Rep:</Label>
                <Input
                  id="marketing rep"
                  type="text"
                  value={newMarketingRep}
                  placeholder="Enter Marketing Rep"
                  onChange={(e) => {
                    setNewMarketingRep(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>license Number:</Label>
                <Input
                  id="Licence Number"
                  value={newLicenseNumber}
                  placeholder="Enter License Number"
                  onChange={(e) => {
                    setNewLicenseNumber(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>
                  NPI:<span className="required">*</span> :
                </Label>
                <Input
                  id="npi"
                  value={newNpi}
                  placeholder="Enter the NPI number "
                  onChange={(e) => {
                    changeOnNPI(e.target.value);
                    if (e.target.value.length >=11) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    }
                    else{
                      changeOnNPI(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Taxonomy Code:</Label>

                <Input
                  id="taxonomy code"
                  value={newTaxonomyCode}
                  placeholder="Enter the Taxonomy code "
                  onChange={(e) => {
                    if (e.target.value.length >= 50) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } else {
                      setNewTaxonomyCode(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Dea Number:</Label>
                <Input
                  id="dea"
                  value={newDeaNumber}
                  placeholder="Enter the Dea number "
                  onChange={(e) => {
                    if (e.target.value.length >= 50) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } else {
                      setNewDeaNumber(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Expiration Date:</Label>
                <Input
                  id="expiration"
                  type="date"
                  value={newExpiration}
                  min={new Date().toISOString().split("T")[0]}
                  placeholder="Enter the Expiration Date "
                  onChange={(e) => {
                    setNewExpiration(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Upin:</Label>
                <Input
                  id="upin"
                  value={newUpin}
                  placeholder="Enter the upin "
                  onChange={(e) => {
                    setNewUpin(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>State Medicaid ID:</Label>
                <Input
                  id="medicaid id"
                  value={newStateMedicaidId}
                  placeholder="Enter The Medicaid id "
                  onChange={(e) => {
                    setNewStateMedicaidId(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            {/* <Row>
              <Col sm={12}>
                <Label>Referral Status:</Label>
                <Select
                  options={ReferralStatusOptions}
                  onChange={(event) => setNewReferralStatus(event)}
                  value={newReferralStatus}
                  placeholder="Enter Referral Status"
                  isSearchable
                  isClearable
                />
              </Col>
            </Row> */}
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Note:</Label>
                <Input
                  id="note"
                  type="textarea"
                  rows={4}
                  cols={2}
                  value={newNote}
                  placeholder="Enter The Note "
                  onChange={(e) => {
                    setNewNote(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
              {/* <Col sm={4}>
                <label for="signature">Select a file:</label>
                <Input
                  id="signature"
                  type="file"
                  accept=".jpg"
                  value={newSignature}
                  placeholder="choose signature"
                  onChange={(e) => {
                    setNewSignature(e.target.value);
                  }}
                  style={{ margin: "0" }}
                
                />
              </Col> */}
            </Row>
            <br></br>

            <Row>
              <Col sm={12}>
                {documentUpload}
                <br></br>
              </Col>
            </Row>

            <br />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button  custom-modal-footer">
          <Button
            onClick={() => {
              confirmation();
            }}
            disabled={addButtonStatus}
            title={addButtonStatus ? "Fill the required fields" : ""}
          >
            Save
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewProviderModal;
