import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import { GlobalState } from "../../contextAPI/Context";
import { Select as SelectVirtualized } from "react-select-virtualized";
import AsyncSelect from "react-select/async";
import Cookie from 'js-cookie';
import ReactTooltip from 'react-tooltip';
const EditFacilityModal = (props) => {
  const {
    timeZoneList,
    editGroupID,

    editFacilityID,
    setFacilityReload,
    facilityReload,
    editFacilityModal,
    setEditFacilityModal,
    editFacilityName,
    setEditFacilityName,
    editAddress1,
    setEditAddress1,
    editAddress2,
    setEditAddress2,
    editReferalFax,
    setEditReferalFax,
    editNPI,
    setEditNPI,
    editType,
    editDescription,
    setEditDescription,
    editTimeZone,
    setEditTimeZone,
    editLatitude,
    setEditLatitude,
    editLongitude,
    setEditLongitude,
    editPrimaryPhone,
    setEditPrimaryPhone,
    editContactPerson,
    setEditContactPerson,
    editDepartment,
    setEditDepartment,
    editFloor,
    setEditFloor,
    editWing,
    setEditWing,
    editRoom,
    setEditRoom,
    editZipID,
    zipCodeList,
    setEditType,
    accessType,
    setAccessType,
    isPrimary,
    setIsPrimary,
    // facilityAccessType,
    editCenter,
    accessTypeOptions
  } = props;

  // CONTEXT VARIABLES
  const { m_zipList, getTimeZoneList } = useContext(GlobalState);

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [zipCodeOptions, setZipCodeOptions] = useState([]);
  const [selectedZip, setSelectedZip] = useState({});
  const [spinner, setSpinner] = useState(false);

  const [center, setCenter] = useState("");
  const [newState, setNewState] = useState("");
  const [newCity, setNewCity] = useState("");

  const [updateButtonStatus, setUpdateButtonStatus] = useState(false);

  const regXForNubers = /^[0-9]*$/;
  // const regXForFaxNumbers = /^[0-9,-]*$/;
  const regXForFax = /^[-+\d\s]*$/;


  const TypeOptions = [
    { value: "hospital", label: "Hospital" },
    { value: "clinic", label: "Clinic" },
    { value: "snf", label: "SNF" },
  ];

  // const accessTypeOptions = [
  //       {
  //         value: 'N/A',
  //         label: `N/A`,
  //       },
  //         {
  //           value: 'O',
  //           label: `Owner`,
  //         },
  //         {
  //           value: 'S',
  //           label: `Supervisor`,
  //         },
  //         {
  //           value: 'E',
  //           label: `External`,
  //         },
  //     ]

  const isPrimaryOptions = [
    {
      value: 1,
      label: `Yes`,
    },
    {
      value: 0,
      label: `No`,
    },
  ];

  const toggle = () => {
    setEditFacilityModal(!EditFacilityModal);
  };

  const setDefaultFacilityAccessTypeValue = () => {
    accessTypeOptions.map((item) =>   // eslint-disable-line
    {
      if (accessType === "O") {
        setAccessType("Owner");
      } else if (accessType === "E") {
        setAccessType("External");
      } else if (accessType === "S") {
        setAccessType("Supervisor");
      }
      if (item.label === accessType) {
        setAccessType({ value: item.value, label: item.label });
      }
    });
  };
  const setDefaultIsPrimaryValue = () => {
    isPrimaryOptions.map((item) =>   // eslint-disable-line
    {
      if (isPrimary === 0) {
        setIsPrimary("No");
      } else if (isPrimary === 1) {
        setIsPrimary("Yes");
      }
      if (item.label === isPrimary) {
        setIsPrimary({ value: item.value, label: item.label });
      }
    });
  };
  const confirmation = () => {
    if (!editFacilityName) {
      toastr.error("Name Field Is Required", "Please enter the user name!");
    }

    if (!editAddress1) {
      toastr.error(
        "Address1 Field Is Required",
        "Please enter the user address!"
      );
    }
    if (!editType) {
      toastr.error("Type Field Is Required", "Please enter the user type!");
    }

    if (editFacilityName && editAddress1 && editType) {
      updateFacilityCall();
    }
  };

  const updateFacilityCall = () => {
    setSpinner(true);

    // debugger
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/facility-update/`,
        {
          group_id: window.atob(editGroupID),
          facility_id: editFacilityID,
          facility_name: editFacilityName ? editFacilityName : "",
          facility_address1: editAddress1 ? editAddress1 : "",
          facility_address2: editAddress2 ? editAddress2 : "",
          referral_fax: editReferalFax ? editReferalFax : "",
          npi: editNPI ? editNPI : "",
          zip_code: selectedZip ? selectedZip.label : "",
          description: editDescription ? editDescription : "",
          type: editType ? (editType.value).toLowerCase() : "",
          time_Zone: editTimeZone ? editTimeZone.label : "",
          latitude: editLatitude ? Number(editLatitude) : null,
          longitude: editLongitude ? Number(editLongitude) : null,
          contact_person: editContactPerson ? editContactPerson : "",
          Primary_phone: editPrimaryPhone ? editPrimaryPhone : "",
          department: editDepartment ? editDepartment : "",
          floor: editFloor ? editFloor : "",
          wing: editWing ? editWing : "",
          room: editRoom ? editRoom : "",
          CenterID: center ? Number(center.value) : null,
          facility_access_type: accessType ? accessType.value : "",
          facility_isPrimary: isPrimary ? isPrimary.value : "",
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setEditFacilityModal(!editFacilityModal);
        setFacilityReload(facilityReload + 1);
        toastr.success(
          "Successfully Updated",
          "Facility details successfully updated"
        );
      })
      .catch(function (error) {
        // console.log(error);
        setSpinner(false);
        toastr.error("Update Failed", "Please try again");
      });
  };

  const setInitialZipCode = () => {
    zipCodeOptions.map((data) =>   // eslint-disable-line
    {
      // eslint-disable-line
      if (data.value === editZipID) {
        setSelectedZip({ value: data.value, label: data.label });
      }
    });
  };

  const changeOnPhoneNumber = (e) => {
    if (regXForNubers.test(e) === false) {
      toastr.warning("Invalid Phone Number", "alphabets are not allowed");
    } else {
      setEditPrimaryPhone(e);
    }
  };
  const onChangeForNPINumber = (e) => {
    if (regXForNubers.test(e) === false) {
      toastr.warning("Invalid NPI Number", "alphabets are not allowed");
    } else {
      setEditNPI(e);
    }
  };
  const onChangeForContactPerson = (e) => {
    if (regXForNubers.test(e) === false) {
      toastr.warning(
        "Invalid Contact Person Number",
        "alphabets are not allowed"
      );
    } else {
      setEditContactPerson(e);
    }
  };
  const minLength = 4;
  const customNoOptionsMessage = ({ inputValue }) => {
   if (inputValue.length < minLength) {
     return "Please enter at least 4 letters to search";
   } else {
     return "No option";
   }
 };

  // const onChangeForCenter = (e) => {
  //     if(regXForNames.test(e) === false) {
  //         toastr.warning("Invalid Entry", "special characters are not allowed")
  //     } else{
  //         setEditCenter(e)
  //     }
  // }

  // const changeOnName = (e) => {
  //     if(regXForNames.test(e) === false) {
  //         toastr.warning("Invalid Entry", "Special characters are not allowed")
  //     } else{
  //         setEditFacilityName(e)
  //     }
  // }

  // const createTimeZoneOption = () => {
  //     var array = timeZoneList;
  //     const options = array.map((item) => {
  //         return {
  //             value: item.id,
  //             label: item.shortName
  //         };
  //     });
  //     setTimeZoneDropDown(options);
  // };
  const onChangeForFaxNumber = (e) => {
    if (regXForFax.test(e) === false) {
      toastr.warning("Invalid Fax Number", "Please enter a valid fax number");
    } else {
      setEditReferalFax(e);
    }
  };
  const timeZonePopulate = () => {
    var array = timeZoneList;
    array.map((item) =>  // eslint-disable-line
     {
     
      if (item.label === editTimeZone) {
        setEditTimeZone(item);
      }
    });
  };
  const typePopulate = () => {
    var array = TypeOptions;
    array.map((item) =>   // eslint-disable-line
    {
      
      if (item.label.toLowerCase() === editType?.toLowerCase()) {
        setEditType(item);
      }
    });
  };

  const fetchCenterById = (editCenter) => {
    AxiosInter
      .get(
        `${API_ENDPOINT}/admin-api/get-m-centerid-list/${Number(editCenter)}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          setCenter(response.data.data);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong in region listing !!");
      });
  };

  const setZipCodeDropDownOptions = (data) => {
    var array = data;
    const options = array?.map((item) => {
      return {
        value: item.id,
        label: item.zipcode,
      };
    });
    setZipCodeOptions(options);
  };

  const populateStateAndCountry = () => {
    var array = m_zipList;
    // eslint-disable-next-line no-unused-vars
    const opt = array.map((item) =>      // eslint-disable-line
     {


      if (selectedZip.value === item.id) {
        setNewCity(item.city);
        setNewState(item.stateFullName);
      }
    });
  };

  const disableUpdateButton = () => {
    if (editFacilityName && editAddress1 && editType) {
      setUpdateButtonStatus(false);
    } else {
      setUpdateButtonStatus(true);
    }
  };

  // const loadOptionsForCenter = (inputValue) => {
  //   if (inputValue.length >= 4) {
  //     var temp = fetch(
  //       `${API_ENDPOINT}/admin-api/get-m-center-list/${inputValue}`,
  //       {
  //         headers: {
  //           Authorization: API_TOKEN,
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => data.data);
  //     return temp;
  //   }
  // };

  const loadOptionsForCenter = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(
        `${API_ENDPOINT}/admin-api/get-m-center-list/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  useEffect(() => {
    disableUpdateButton();
  }, [editFacilityName, editAddress1, editType]); // eslint-disable-line

  useEffect(() => {
    if (editCenter !== "" && editCenter !== undefined) {
      fetchCenterById(editCenter);
    }
  }, [editCenter]); // eslint-disable-line

  useEffect(() => {
    if (selectedZip !== null && m_zipList) {
      populateStateAndCountry();
    } else {
      setNewCity("");
      setNewState("");
    }
  }, [selectedZip]); // eslint-disable-line

  useEffect(() => {
    if (editFacilityModal) {
      setZipCodeDropDownOptions(zipCodeList);
      typePopulate();
    }
  }, [editFacilityModal]); // eslint-disable-line

  useEffect(() => {
    if (timeZoneList && editTimeZone) {
      timeZonePopulate();
    }
  }, [timeZoneList, editTimeZone]); // eslint-disable-line

  useEffect(() => {
    if (zipCodeOptions) {
      // console.log('249')
      setInitialZipCode();
      //   console.log(editZipID)
    }
  }, [zipCodeOptions]); // eslint-disable-line

  useEffect(() => {
    if (!editFacilityModal) {
      setSelectedZip("");
    }
  }, [editFacilityModal]); // eslint-disable-line
  // useEffect(()=>{
  //     if(isPrimary) {

  //       setDefaultIsPrimaryValue();
  //     }

  //   },[isPrimary])

  useEffect(() => {
    if (accessType) {
      setDefaultFacilityAccessTypeValue();
      setDefaultIsPrimaryValue();
    }
  }, [accessType]); // eslint-disable-line

  useEffect(() => {
    if (timeZoneList.length <= 0) {
      getTimeZoneList();
    }
  }, [timeZoneList]); // eslint-disable-line

  return (
    <div>
      <Modal
        isOpen={editFacilityModal}
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit Facility{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          {spinner ? <Spinner /> : ""}
          <FormGroup>
            <Row>
              <Col sm={6}>
                <Label>
                  Name<span className="required">*</span> :
                </Label>
                <Input
                  id="name"
                  value={editFacilityName}
                  placeholder="Enter the facility name"
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditFacilityName(e.target.value);
                  }}
                />
              </Col>
              <Col md={6} sm={6}>
                <Label>Phone Number :</Label>

                <Input
                  id="primaryPhone"
                  value={editPrimaryPhone}
                  placeholder="Enter the phone number"
                  onChange={(e) => {
                    changeOnPhoneNumber(e.target.value);
                    if (e.target.value.length >= 13) {
                      toastr.warning(
                        "Invalid Phone Number",
                        "Phone number length exceeded"
                      );
                    }
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <Label>
                  Address 1 <span className="required">*</span> :
                </Label>
                <Input
                  id="address"
                  type="textarea"
                  rows={4}
                  cols={2}
                  value={editAddress1}
                  placeholder="Enter the facility address 1"
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditAddress1(e.target.value);
                  }}
                />
              </Col>

              <Col sm={6}>
                <Label>Address 2 :</Label>
                <Input
                  id="address"
                  type="textarea"
                  rows={4}
                  cols={2}
                  value={editAddress2}
                  placeholder="Enter the address 2"
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditAddress2(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />

            <Row>
              <Col sm={6}>
                <Label>Fax :</Label>

                <Input
                  id="fax"
                  value={editReferalFax}
                  placeholder="Enter the referral fax"
                  onChange={(e) => {
                    if (e.target.value.length >= 15) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } else {
                      onChangeForFaxNumber(e.target.value);
                    }
                  }}
                />
              </Col>
              <Col sm={6}>
                <Label>NPI :</Label>
                <Input
                  id="npi"
                  value={editNPI}
                  placeholder="Enter the NPI"
                  onChange={(e) => {
                    onChangeForNPINumber(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <Label>
                  Type <span className="required">*</span> :
                </Label>
                <Select
                  options={TypeOptions}
                  onChange={(event) => setEditType(event)}
                  value={editType}
                  placeholder="Type"
                  isSearchable
                  isClearable
                />
              </Col>
              <Col sm={6}>
                <Label>Description :</Label>

                <Input
                  id="Description"
                  type="textarea"
                  rows={2}
                  cols={2}
                  value={editDescription}
                  placeholder="Enter the Description"
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditDescription(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={3}>
                <Label>Zip Code :</Label>
                <SelectVirtualized
                  name="zip"
                  options={zipCodeOptions}
                  value={selectedZip}
                  placeholder="Choose Zip Code"
                  isSearchable
                  isClearable
                  // value={selectedTimeZone}
                  onChange={(e) => {
                    //  console.log(e)
                    setSelectedZip(e);
                  }}
                />
              </Col>

              <Col sm={3}>
                <Label>State :</Label>
                <Input
                  id="State"
                  value={newState}
                  placeholder="State"
                  disabled
                />
              </Col>

              <Col sm={3}>
                <Label>City :</Label>

                <Input id="city" value={newCity} placeholder="City" disabled />
              </Col>

              <Col sm={3}>
                <Label>Center:</Label>
                <AsyncSelect
                  isClearable
                  isSearchable
                  placeholder="Search Center"
                  noOptionsMessage={customNoOptionsMessage}
                  value={center ? center : ""}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  onChange={(e) => {
                    setCenter(e);
                  }}
                  loadOptions={loadOptionsForCenter}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={3}>
                <Label>Time Zone :</Label>
                <Select
                  options={timeZoneList}
                  onChange={(event) => setEditTimeZone(event)}
                  value={editTimeZone}
                  placeholder="Time Zone"
                  isSearchable
                  isClearable
                />
              </Col>

              <Col sm={3}>
                <Label>Contact person :</Label>
                <Input
                  id="contactperson "
                  value={editContactPerson}
                  placeholder="Enter the Contact Person "
                  onChange={(e) => {
                    onChangeForContactPerson(e.target.value);
                  }}
                />
              </Col>

              <Col sm={3}>
                <Label>Latitude :</Label>
                <Input
                  id="Latitude"
                  type="number"
                  step="any"
                  value={editLatitude}
                  placeholder="Enter the Latitude"
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditLatitude(e.target.value);
                  }}
                  data-tip="Enter values in decimal format"

                />
                                <ReactTooltip className="custom-tooltip" effect="solid" place="top" />

              </Col>
              <Col sm={3}>
                <Label>Longitude :</Label>
                <Input
                  id="Longitude"
                  type="number"
                  step="any"
                  value={editLongitude}
                  placeholder="Enter the Longitude"
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditLongitude(e.target.value);
                  }}
                  data-tip="Enter values in decimal format"

                />
                <ReactTooltip className="custom-tooltip" effect="solid" place="top" />

              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <Label>Department :</Label>
                <Input
                  id="Department "
                  value={editDepartment}
                  placeholder="Enter the Department "
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditDepartment(e.target.value);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Label>Room :</Label>

                <Input
                  id="room "
                  type="textarea"
                  value={editRoom}
                  placeholder="Enter the Room "
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditRoom(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <Label>Access Type:</Label>
                <Select
                  id="Access Type"
                  options={accessTypeOptions}
                  onChange={(e) => {
                    setAccessType(e);
                  }}
                  value={accessType}
                  placeholder="Access Type"
                  isSearchable
                  isClearable
                />
              </Col>
              <Col sm={6}>
                <Label>Is Primary:</Label>
                <Select
                  id="Is Primary"
                  options={isPrimaryOptions}
                  onChange={(e) => {
                    setIsPrimary(e);
                  }}
                  value={isPrimary}
                  placeholder="Is Primary"
                  isSearchable
                  isClearable
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm={6}>
                <Label>Floor :</Label>
                <Input
                  id="Floor "
                  value={editFloor}
                  placeholder="Enter the Floor "
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditFloor(e.target.value);
                  }}
                />
              </Col>
              <Col sm={6}>
                <Label>Wing :</Label>
                <Input
                  id="Wing "
                  value={editWing}
                  placeholder="Enter the wing "
                  onChange={(e) => {
                    if (e.target.value.length <= 150)
                      setEditWing(e.target.value);
                  }}
                />
              </Col>

              {/* <Col sm={6}>
                            <Label>
                            Center:
                            </Label>
                            <Input
                            id="Center "
                            value={editCenter}
                            placeholder="Enter the Center "
                            onChange={(e) => { if (e.target.value.length <= 150) setEditCenter(e.target.value) }}
                            />

                            </Col> */}
            </Row>
            <br />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button
            onClick={() => {
              confirmation();
            }}
            disabled={updateButtonStatus}
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditFacilityModal;
