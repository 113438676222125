import React, { useEffect, useState, useContext } from "react";
import AxiosInter from "./../../AxiosInter.js";
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Container,
  Label,
  CardHeader
  ,NavLink,Nav,NavItem
} from "reactstrap";
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import { Hux } from "./../../hoc/Hux";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import NewTemplateModal from "../../newComponents/utilities/NewTemplateModal";
import EditTemplateModal from "../../newComponents/utilities/EditTemplateModal";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useParams, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css"; //import reat-datepicker css
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MomentConfig from "../../common/MomentConfig";
import RemotePaginationForPandaDocTemplates from "../../newComponents/utilities/RemotePaginationPandaDocTemplates";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import Cookie from "js-cookie";
import { Select as SelectVirtualized } from "react-select-virtualized";
import { GlobalState } from "../../contextAPI/Context";
import classnames from "classnames";
const AllTemplate = (props) => {
  const {activeTab,toggle}=props
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const fileExportName = "PandaDoc_Template";
  // eslint-disable-next-line no-unused-vars
  const [spinner, setSpinner] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [search, setSearch] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [editTemplateModal, setEditTemplateModal] = useState(false);
  const [newTemplateModal, setNewTemplateModal] = useState(false);

  const [editTemplateID, setEditTemplateID] = useState("");
  const [editGroupName, setEditGroupName] = useState("");
  const [editTemplatName,setEditTemplateName] = useState("");
  const [rowData, setRowData] = useState("");

  const [groupName, setGroupName] = useState("");
  const [folder_name, setFolder_Name] = useState("");
  const [typeDropDown, setTypeDropDown] = useState([]);
  const [templateDropDown, setTemplateDropDown] =  useState([]); 
  const [template_name, setTemplate_Name] = useState("");
 
  const [selectedStatus, setSelectedStatus] = useState(""); // eslint-disable-line
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [groupID, setGroupID] = useState("");

  const [userDataReload, setUserDataReload] = useState(0);
  const [userSizePerPage, setUserSizePerPage] = useState("50");
  const [offset, setOffset] = useState("0");
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  // const [activeTab, setActiveTab] = useState("2");
//   const toggle = (tab) => {
//     if (activeTab !== tab) setActiveTab(tab);
// };


  const { groupNamesForDropDown } = useContext(GlobalState);
  // let navigate = useNavigate();
  let location = useLocation();
  let { id } = useParams(); // eslint-disable-line
  let loader = null;

  const loadTemplateAndTypeOptions = async () => {
    try {
      const response = await AxiosInter.get(`${API_ENDPOINT}/admin-api/list-pandaDoc-templates/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      });
      return response.data.data;
    } catch (error) {
      throw error;
    }
  };
  
  useEffect(() => {
    if (apiLoaded){
    const fetchData = async () => {
      try {
        const data = await loadTemplateAndTypeOptions();
        const templateOptionsArray = data.map(item => ({
          value: item.id,
          label: item.template_name
        }));
  
        const typeOptionsArray = [{value:1,label:"consent"},{value:2,label:"pre-auth"}]
        setTypeDropDown(typeOptionsArray);
        setTemplateDropDown(templateOptionsArray);
      } catch (error) {
        // Handle error gracefully
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
    
  }
  }, [apiLoaded]); // eslint-disable-line
  

  if (apiLoaded) {
    loader = <Spinner />;
  }
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;

  const clearValues = () => {


    setGroupName("");
    setFolder_Name("")
    setTemplate_Name("")
    setSelectedStatus("");

    setTemplateList("");

    setGroupID("");

    setTemplateList("");
    setSortOrder("");
    setTotalSize(0);
    setUserDataReload(userDataReload + 1);

    //setApiLoaded(true)
  };
  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faEdit}
              size={"1x"}
              color={"#3f86fa"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                // debugger;
                setRowData(row);
                setEditTemplateModal(true);
                // editFacilityData(row.address1, row.address2, row.contactPerson, row.department, row.description1, row.floor, row.id, row.latitude, row.longitude, row.name1, row.npi, row.primaryPhone, row.referralFax, row.room, row.type1, row.wing, row.workTime, row.zipID, row.facilityType, row.isPrimary)
                setEditTemplateID(row.id);
                // setEditGroupName(row.name);
                // setEditCategory(row.category);

                // if (row.category) {
                //   setEditCategory({
                //     value: (row ? row.id : "N/A").toString(),
                //     label: (row ? row.category : "N/A").toString(),
                //   });
                // }
                if (row.group_name_dropdown) {
                  setEditGroupName(row.group_name_dropdown);
                }
               if (row.template_id){
                 setEditTemplateName({
                     "value": row.template_id,
                     "label": row.template_name                   
                 })
               } 

                // debugger
              }}
              title="Edit PandaDoc Template"
            />
          </Col>
          {/* <Col sm={2}>
            <FontAwesomeIcon
              icon={faTrash}
              color = {'#E12C2C'}
              style = {{'cursor':'pointer'}}
              onClick={() => {
                setHabitID(row.id);
                setDeleteModal(true);
               }}
            
              title = "Delete"

            />
        </Col> */}
        </Row>
      </>
    );
  };

  const columns = [
    {
      dataField: "group_name",
      text: "Groups",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "template_name",
      text: "Template Name",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "folder_name",
      text: "Document Type",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "signers_count",
      text: "Number Of Signers",
      sort: true,
      formatter: basicValidation,
    },
    {
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];

  const onTableChangeForuser = (type, newState) => {
    // console.log(newState)
    // console.log(newState.sortOrder)
    setPage(newState.page);
    setUserSizePerPage(newState.sizePerPage);
    // if(newState.sortOrder == "desc")
    //  { newState.sortOrder = "asc"}

    setOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("a.id");
      setSortOrder("desc");
      setUserDataReload(userDataReload + 1);
  
    } else if (newState.sortField === "group_name") {
      setSortColumn("internal_group_ids");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    }
    else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    }
  };

  const exportTableData = () => {
    const params = {
      file_name: fileName ? fileName : "",
      folder_name: folder_name ? folder_name.label : "",
      template_name: template_name ? template_name.label : "",
      group_id: groupName ? groupName.value : "",
      record_limit: userSizePerPage ? userSizePerPage : "50",
      off_set: offset ? offset.toString() : "0",
      search_keyword: searchKeyword ? searchKeyword : "",
      is_pagination: "",
      sortOrder: sortOrder ? sortOrder : "DESC",
      sortColumn: sortColumn ? sortColumn : "a.id",
    };

    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-edoc-details/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
      responseType: "blob",
    })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const searchTemplate = () => {

    setApiLoaded(true);

    const params = {
      folder_name: folder_name ? folder_name.label : "",
      template_name: template_name ? template_name.label : "",
      group_id: groupName ? groupName.value : "",
      record_limit: userSizePerPage ? userSizePerPage : "50",
      off_set: offset ? offset.toString() : "0",
      search_keyword: searchKeyword ? searchKeyword : "",
      is_pagination: "",
      sortOrder: sortOrder ? sortOrder : "DESC",
      sortColumn: sortColumn ? sortColumn : "a.id",
    };

    // }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/fetch-edoc-details/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(function (response) {
        if (response.data.data.length > 0) {
          debugger
          console.log(response.data.data)
          setTemplateList(response.data.data);
          localStorage.setItem("offset", offset.toString());
          setTotalSize(response.data.total_count);
          setApiLoaded(false);
          //setSpinner(false);
        } else {
          setTemplateList("");
          setApiLoaded(false);
          // setSpinner(false);
          // setIsDataEmpty(true);
        }
      })
      .catch(function (error) {

        setTemplateList("");
        setApiLoaded(false);
        // setSpinner(false);
      });
  };

  const enableOrDisableSearch = () => {
    if (groupName || template_name || folder_name) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  useEffect(() => {
    enableOrDisableSearch();
  }, [groupName, template_name, folder_name]); // eslint-disable-line
  // useEffect(() => { if(groupNamesForDropDown)createGroupOption(); }, [groupNamesForDropDown])

  useEffect(() => {
    if (userDataReload >= 1) {
      searchTemplate();
      localStorage.setItem("offset", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataReload]);

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset) {
      searchTemplate();
    }
  }, [offset]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length === 0) setUserDataReload(userDataReload + 1);
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      // setPage
      setTotalSize(0);
      setUserDataReload(userDataReload + 1);
      // setReloadGroupDetails(true);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  useEffect(() => {
    if (location.state) {
      localStorage.setItem(
        "isFilterApplied",
        JSON.stringify(location.state.detail)
      );
    }
  }, [location]);

  const handleInputChange = (value) => { // eslint-disable-line
    setInputValue(value);
  };


  // const TemplateNameAndTypeloadOptions =  () => {
 
  //   return   AxiosInter.get(`${API_ENDPOINT}/admin-api/list-pandaDoc-templates/`, {
  //       headers: {
  //         Authorization: API_TOKEN,
  //       },
  //     }).then((response) => response.data.data)
  //     .catch((error) => {
  //       throw error;
  //     });
  
  
  // };
  
  const clearSearchValues = () => {
    setSearchKeyword("");
    setUserDataReload(userDataReload + 1);
  };
  return (
    <div>
      

      <Card className="adv-search-background-card">
      <Hux>
        {loader}
        <Container fluid style={{position:'absolute', right:'0px'}}>
          <Header>
              <Button
                className="Add-btn pull-right"
                onClick={() => {
                  setNewTemplateModal(true);
                }}
              >
                Configure New Group
              </Button>
            {/* <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Template</BreadcrumbItem>

            </Breadcrumb> */}
          </Header>
        </Container>
      </Hux>
        <CardHeader>
        
        
        <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "1" })}
                            onClick={() => {
                                toggle("1");
                                localStorage.removeItem('actTab')
                                // toggleCloseForRoster()
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            All pandadoc
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "2" })}
                            onClick={() => {
                                toggle("2");
                                // toggleClose()
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            Templates
                        </NavLink>
                    </NavItem>
                </Nav>
          <Row>
            <Col sm="3">
              <Label>Group Name</Label>
              <SelectVirtualized
                name="Group"
                options={groupNamesForDropDown}
                placeholder="Enter group name"
                isSearchable
                isClearable
                value={groupName}
                onChange={(e) => {
        
                  setGroupName(e);
                }}
              />
            </Col>
            <Col sm="2" md="2" lg="2">
              <Label>Document Type</Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(event) => {
                  setFolder_Name(event);
                }}
                isClearable
                isSearchable
                placeholder="Select"
                value={folder_name}
                options={typeDropDown}
              />
            </Col>
            
            <Col sm="2" md="2" lg="2">
              <Label>Template Name</Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(event) => {
                  setTemplate_Name(event);
                }}
                isClearable
                isSearchable
                placeholder="Select"
                value={template_name}
                options={templateDropDown}
              />
            </Col>

            <Col sm="3">
              <Button
                className="patient-go-buttons"
                disabled={!search ? true : false}
                title={
                  spinner || !search
                    ? "Choose at least one item to search "
                    : ""
                }
                onClick={() => {
                  offset === "0" ? searchTemplate() : setOffset("0");
                }}
              >
                Go
              </Button>{" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => {
                  clearValues();
                }}
                disabled={!search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <div className="separator" />
        </CardHeader>

        <div className="groups-search">
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={columns}
            clearValues={clearSearchValues}
          />
        </div>

        <div>{spinner ? <Spinner /> : ""}</div>

        <RemotePaginationForPandaDocTemplates
          data={templateList ? templateList : ""}
          columns={columns}
          page={page}
          sizePerPage={userSizePerPage}
          FileExportName={fileExportName}
          exportTableData={exportTableData}
          isGlobalSearch={isGlobalSearch}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            onTableChangeForuser(type, newState);
          }}
          isDataEmpty={isDataEmpty}
          setIsGlobalSearch={setIsGlobalSearch}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
        />
      </Card>
        
      <div>
        <EditTemplateModal
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
          editTemplateID={editTemplateID}
          setEditTemplateID={setEditTemplateID}
          editTemplateModal={editTemplateModal}
          setEditTemplateModal={setEditTemplateModal}
          rowData={rowData}
          editGroupName={editGroupName}
          setEditGroupName={setEditGroupName}
          editTemplatName={editTemplatName}
          setEditTemplateName={setEditTemplateName}
          templateDropDown={templateDropDown}
        />

        <NewTemplateModal
          newTemplateModal={newTemplateModal}
          setNewTemplateModal={setNewTemplateModal}
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
          setNewGroupName={setGroupName}
          newGroupName={groupName}
          templateDropDown={templateDropDown}
        />
      </div>
    </div>
  );
};

export default AllTemplate;
