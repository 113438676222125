import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const StatusChangeModalPrograms = (props) => {
  const {
    modalState,
    setModalState,
    status,
    updateCarePlanProgramStatus
  } = props;


  const toggle = () => {
    setModalState(!modalState);
  };


  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={modalState} >
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Status</ModalHeader>
        <ModalBody>
            <div style={{"text-align": "center"}}>
              Are you sure you want to change the status to {status === 0? 'active ?':'inactive ?' } 
            </div>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button 
          className="yes-button button-width" 
          onClick={()=>{
            
            updateCarePlanProgramStatus()
            
            }}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default StatusChangeModalPrograms;
