import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Cookie from 'js-cookie';

const GroupStatusChangeModal = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");  const {
    statusModal,
    setStatusModal,
    groupId,
    groupStatus,
    setReload,
    reload,
  } = props;

  const updateGroupStatus = () => {
    let group_status = groupStatus;
    if (group_status === 0) {
      group_status = 1;
    } else {
      group_status = 0;
    }
    const params = {
      group_id: groupId,
      status: group_status,
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/group-status-update/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toastr.success("Success", "Status updated successfully");
          setStatusModal(false);
          setReload(reload + 1);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong!");
      });
  };

  const toggle = () => {
    setStatusModal(!statusModal);
  };

  return (
    <div>
      <Modal isOpen={statusModal} >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Change Status
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            {groupStatus === 1
              ? "Do you want to change the group status to inactive?"
              : "Do you want to change the group status to active ?"}
          </div>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            className="yes-button button-width"
            onClick={() => updateGroupStatus()}
          >
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default GroupStatusChangeModal;
