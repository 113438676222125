import React, { useState, useEffect, useContext } from "react";
import {
  TabContent,
  TabPane,
  Spinner,
  Label,
  Nav,
  Container,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import AxiosInter from './../../AxiosInter.js';
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { toastr } from "react-redux-toastr";
import { Link, useParams, useLocation } from "react-router-dom";
import { GlobalState } from "../../contextAPI/Context";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import GroupsPackageModal from "../../newComponents/utilities/GroupsPackageModal";
import GroupsCptPackagesModal from "../../newComponents/utilities/GroupsCptPackagesModal";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import DeleteConfirmationModal from "../../newComponents/utilities/DeleteConfirmationModal";
import MomentConfig from "../../common/MomentConfig";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Cookie from 'js-cookie';


const GroupPackages = () => {
  const {
    fetchCPTPackageNamesList,
    fetchICDPackageNamesList,
    groupNamesList,
    groupListLoading,
    icdPackageNames,
    icdPackageNamesReload,
    setIcdPackageNamesReload,
    cptPackageNames,

    cptPackageNamesReload,
    setCptPackageNamesReload,
  } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [fileExportName, setFileExportName] = useState("ICD Packages");

  const [activeTab, setActiveTab] = useState("1");

  //ICDPackage Tab
  const [icdPackageName, setIcdPackageName] = useState("");
  const [icdPackageNameForFilter, setIcdPackageNameForFilter] = useState("");
  const [icdPackageTotalSize, setIcdPackageTotalSize] = useState("");
  const [icdPackageSizePerPage, setIcdPackageSizePerPage] = useState(50);
  const [icdPackagePage, setIcdPackagePage] = useState(1);
  const [icdPackageOffset, setIcdPackageOffset] = useState(0);
  const [icdPackageDetails, setIcdPackageDetails] = useState("");
  const [icdPackageSpinner, setIcdPackageSpinner] = useState(false);
  const [icdPackageReload, setIcdPackageReload] = useState(0);
  const [selectedIcdPackage, setSelectedIcdPackage] = useState([]);
  const [icdPackageId, setIcdPackageId] = useState("");
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [icdPackageSearchKeyword, setIcdPackageSearchKeyword] = useState("");
  // const [icdPackageNameDropDownOptions,setIcdPackageNameDropDownOptions] = useState({})
  const [icdPackageFilter, setIcdPackageFilter] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isIcdPackageDataEmpty, setIsIcdPackageDataEmpty] = useState(false);

  const [groupDropDownOptions, setGroupDropDownOptions] = useState([]);
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [icdPackageGroup, setIcdPackageGroup] = useState("");
  const [icdPackageModal, setIcdPackageModal] = useState(false);
  const [icdPackageModalState, setIcdPackageModalState] = useState("initial");

  //CPTPackage Tab
  const [cptPackageName, setCptPackageName] = useState("");
  const [cptPackageNameForFilter, setCptPackageNameForFilter] = useState("");
  const [cptPackageTotalSize, setCptPackageTotalSize] = useState("");
  const [cptPackageSizePerPage, setCptPackageSizePerPage] = useState(50);
  const [cptPackagePage, setCptPackagePage] = useState(1);
  const [cptPackageOffset, setCptPackageOffset] = useState(0);
  const [cptPackageDetails, setCptPackageDetails] = useState("");
  const [cptPackageSpinner, setCptPackageSpinner] = useState(false);
  const [cptPackageReload, setCptPackageReload] = useState(0);
  const [selectedCptPackage, setSelectedCptPackage] = useState([]);
  const [cptPackageId, setCptPackageId] = useState("");
  const [isCptPackageDataEmpty, setIsCptPackageDataEmpty] = useState(false);
  // const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [cptPackageSearchKeyword, setCptPackageSearchKeyword] = useState("");

  const [cptPackageFilter, setCptPackageFilter] = useState(false);
  const [cptPackageModal, setCptPackageModal] = useState(false);
  const [cptPackageModalState, setCptPackageModalState] = useState(false);
  const [cptPackageGroup, setCptPackageGroup] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;

  let navigate = useNavigate();
  let location = useLocation();
  let { GroupName } = useParams();

  const goBack = () => {
    navigate(-1);
  };

  const selectedName = () => {
    setSelectedGroupName(window.atob(GroupName));
  };

  const validation = (cell) => {
    return cell ? cell : "N/A";
  };

  const createGroupOption = () => {
    var array = groupNamesList;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setGroupDropDownOptions(options);
  };

  const handleTableChange = (type, newState) => {
    if (activeTab === "1") {
      setIcdPackagePage(newState.page);
      setIcdPackageSizePerPage(newState.sizePerPage);
      setIcdPackageOffset((newState.page - 1) * newState.sizePerPage);
    } else if (activeTab === "2") {
      setCptPackagePage(newState.page);
      setCptPackageSizePerPage(newState.sizePerPage);
      setCptPackageOffset((newState.page - 1) * newState.sizePerPage);
    }
  };

  const getAsyncOptions = (inputValue) => {
    if (icdPackageNames) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(icdPackageNames, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const getAsyncOptionsForCPTPackages = (inputValue) => {
    if (cptPackageNames) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(cptPackageNames, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faEdit}
              color={"#3f86fa"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIcdPackageModal(true);
                setIcdPackageModalState("edit");
                setIcdPackageGroup(row.GroupName);
                setSelectedIcdPackage(
                  row.icdPackageDetails ? row.icdPackageDetails : []
                );
                setIcdPackageName(row.icdPackageName);
                setIcdPackageId(row.icdPackageID);
              }}
              title="Edit ICD Package"
            />
          </Col>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faTrash}
              color={"#E12C2C"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setIcdPackageId(row.icdPackageID);
                setDeleteModal(true);
              }}
              title="Delete"
            />
          </Col>
        </Row>
      </>
    );
  };

  const cptActionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faEdit}
              color={"#3f86fa"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCptPackageModal(true);
                setCptPackageModalState("edit");
                setCptPackageGroup(row.GroupName);
                setSelectedCptPackage(
                  row.cptPackageDetails ? row.cptPackageDetails : []
                );
                setCptPackageName(row.cptPackageName);
                setCptPackageId(row.cptPackageID);
              }}
              title="Edit CPT Package"
            />
          </Col>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faTrash}
              color={"#E12C2C"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCptPackageId(row.cptPackageID);
                setDeleteModal(true);
              }}
              title="Delete"
            />
          </Col>
        </Row>
      </>
    );
  };

  const icdPackageColumns = [
    {
      dataField: "icdPackageName",
      text: "Name",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "GroupName",
      text: "Group",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "icdCodes",
      text: "ICD Codes",
      sort: true,
      formatter: validation,
    },
    {
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];

  const cptPackageColumns = [
    {
      dataField: "cptPackageName",
      text: "Name",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "GroupName",
      text: "Group",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "cptCodes",
      text: "CPT Codes",
      sort: true,
      formatter: validation,
    },
    {
      text: "Actions",
      sort: false,
      formatter: cptActionsFormatter,
    },
  ];

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const deleteIcdPackageDetails = () => {
    const params = {
      icd_package_id: icdPackageId ? icdPackageId : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/delete-icd-packages/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        toastr.success("Success", "Deleted successfully");
        setDeleteModal(false);
        setIcdPackageReload(icdPackageReload + 1);
        setIcdPackageNamesReload(icdPackageNamesReload + 1);
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
      });
  };

  const deleteCptPackageDetails = () => {
    const params = {
      cpt_package_id: cptPackageId ? cptPackageId : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/delete-cpt-packages/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        toastr.success("Success", "Deleted successfully");
        setDeleteModal(false);
        setCptPackageReload(cptPackageReload + 1);
        setCptPackageNamesReload(cptPackageNamesReload + 1);
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
      });
  };

  const fetchICDPackageDetails = () => {
    setIcdPackageSpinner(true);
    selectedName();
    console.log(selectedGroupName);
    const params = {
      offset: icdPackageOffset,
      is_pagination: 1,
      record_limit: icdPackageSizePerPage ? icdPackageSizePerPage : "",
      icd_package_name: icdPackageNameForFilter
        ? icdPackageNameForFilter.label
        : "",
      group_name: GroupName ? GroupName : "",
      search_keyword: icdPackageSearchKeyword ? icdPackageSearchKeyword : "",
    };

    //    setSpinner (true);

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-icd-details-by-group/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setIcdPackageSpinner(false);
          setIcdPackageTotalSize(response.data.record_count);
          setIcdPackageDetails(response.data.data);
          localStorage.setItem("icdPackageOffset", icdPackageOffset);
        } else {
          setIcdPackageTotalSize("");
          setIcdPackageSpinner(false);
          setIcdPackageDetails("");
          setIsIcdPackageDataEmpty(true);
        }
      })
      .catch((error) => {
        toastr.error("Something went wrong");
      });
  };
  const exportIcdPackageDetails = () => {
    const params = {
      file_name: fileName ? fileName : "",
      offset: icdPackageOffset ? icdPackageOffset : "0",
      is_pagination: "1",
      record_limit: "",
      icd_package_name: icdPackageNameForFilter
        ? icdPackageNameForFilter.label
        : "",
      group_name: GroupName ? window.atob(GroupName) : "",
      search_keyword: icdPackageSearchKeyword ? icdPackageSearchKeyword : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/get-export-icd-details-by-group/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const fetchCPTPackageDetails = () => {
    setCptPackageSpinner(true);
    const params = {
      offset: cptPackageOffset,
      is_pagination: 1,
      record_limit: cptPackageSizePerPage ? cptPackageSizePerPage : "",
      cpt_package_name: cptPackageNameForFilter
        ? cptPackageNameForFilter.label
        : "",
      group_name: GroupName ? GroupName : "",
      search_keyword: cptPackageSearchKeyword ? cptPackageSearchKeyword : "",
    };

    //    setSpinner (true);

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-cpt-details-by-group/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setCptPackageSpinner(false);
          setCptPackageTotalSize(response.data.record_count);
          setCptPackageDetails(response.data.data);
          localStorage.setItem("cptPackageOffset", cptPackageOffset);
        } else {
          setCptPackageTotalSize("");
          setCptPackageSpinner(false);
          setCptPackageDetails("");
          setIsCptPackageDataEmpty(true);
        }
      })
      .catch((error) => {
        toastr.error("Something went wrong");
      });
  };
  const exportCptPackageDetails = () => {
    const params = {
      file_name: fileName ? fileName : "",
      offset: cptPackageOffset ? cptPackageOffset : "",
      is_pagination: "1",
      record_limit: "",
      cpt_package_name: cptPackageNameForFilter
        ? cptPackageNameForFilter.label
        : "",
      group_name: GroupName ? window.atob(GroupName) : "",
      search_keyword: cptPackageSearchKeyword ? cptPackageSearchKeyword : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/get-export-cpt-details-by-group/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const clearSearchValues = () => {
    if (activeTab === "1") {
      setIcdPackageSearchKeyword("");
      setIcdPackageReload(icdPackageReload + 1);
    } else if (activeTab === "2") {
      setCptPackageSearchKeyword("");
      setCptPackageReload(cptPackageReload + 1);
    }
  };

  const clearIcdPackageFilter = () => {
    setIcdPackagePage(1);
    setIcdPackageNameForFilter("");
    setIcdPackageFilter(false);
    setIcdPackageReload(icdPackageReload + 1);
  };

  const clearCptPackageFilter = () => {
    setCptPackagePage(1);
    setCptPackageNameForFilter("");
    setCptPackageFilter(false);
    setCptPackageReload(cptPackageReload + 1);
  };

  const filterButtonValidationICDPackage = () => {
    if (icdPackageNameForFilter) {
      setIcdPackageFilter(true);
    } else {
      setIcdPackageFilter(false);
    }
  };
  const filterButtonValidationCPTPackage = () => {
    if (cptPackageNameForFilter) {
      setCptPackageFilter(true);
    } else {
      setCptPackageFilter(false);
    }
  };
  useEffect(() => {
    if (GroupsPackageModal) {
      selectedName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GroupsPackageModal]);

  useEffect(() => {
    if (groupNamesList) {
      createGroupOption();
    }
  }, [groupNamesList]); // eslint-disable-line

  useEffect(() => {
    if (activeTab === "1") {
      fetchICDPackageDetails();
    } else if (activeTab === "2") {
      fetchCPTPackageDetails();
    }
  }, [activeTab]); // eslint-disable-line

  useEffect(() => {
    if (icdPackageNameForFilter) {
      filterButtonValidationICDPackage();
    }
  }, [icdPackageNameForFilter]); // eslint-disable-line

  useEffect(() => {
    if (cptPackageNameForFilter) {
      filterButtonValidationCPTPackage();
    }
  }, [cptPackageNameForFilter]); // eslint-disable-line

  useEffect(() => {
    if (activeTab === "1") {
      let previousOffset = localStorage.getItem("icdPackageOffset");
      if (icdPackageOffset !== previousOffset || icdPackageSizePerPage) {
        setIcdPackageReload(icdPackageReload + 1);
      }
    } else if (activeTab === "2") {
      let previousCptPackageOffset = localStorage.getItem("cptPackageOffset");
      if (
        cptPackageOffset !== previousCptPackageOffset ||
        cptPackageSizePerPage
      ) {
        setCptPackageReload(cptPackageReload + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    icdPackageOffset,
    icdPackageSizePerPage,
    cptPackageOffset,
    cptPackageSizePerPage,
  ]); // eslint-disable-line

  useEffect(() => {
    if (icdPackageSearchKeyword.length >= 2 && activeTab === "1") {
      setIsGlobalSearch(true);
    } else if (cptPackageSearchKeyword.length >= 2 && activeTab === "2") {
      setIsGlobalSearch(true);
    }
  }, [icdPackageSearchKeyword, cptPackageSearchKeyword]); // eslint-disable-line

  useEffect(() => {
    if (icdPackageSearchKeyword.length === 0 && activeTab === "1") {
      setIcdPackageReload(icdPackageReload + 1);
    } else if (cptPackageSearchKeyword.length === 0 && activeTab === "2") {
      setCptPackageReload(cptPackageReload + 1);
    }
  }, [icdPackageSearchKeyword, cptPackageSearchKeyword]); // eslint-disable-line

  useEffect(() => {
    if (icdPackageNames.length <= 0 || cptPackageNames.length <= 0) {
      fetchICDPackageNamesList();
      fetchCPTPackageNamesList();
    }
  }, [icdPackageNames, cptPackageNames]); // eslint-disable-line

  useEffect(() => {
    if (isGlobalSearch) {
      setIcdPackageOffset(0);
      setIcdPackageSizePerPage(50);
      setIcdPackageReload(icdPackageReload + 1);
      setIsGlobalSearch(false);
    } else if (isGlobalSearch && activeTab === "2") {
      setCptPackageOffset(0);
      setCptPackageSizePerPage(50);
      setCptPackageReload(cptPackageReload + 1);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  //   // useEffect(() => { if (groupNameList) createGroupOption(); }, [groupNameList]) // eslint-disable-line

  useEffect(() => {
    if (icdPackageReload && activeTab === "1") {
      fetchICDPackageDetails();
    } else if (cptPackageReload && activeTab === "2") {
      fetchCPTPackageDetails();
    }
  }, [icdPackageReload, cptPackageReload]); // eslint-disable-line
  useEffect(() => {
    if (location.state) {
      localStorage.setItem(
        "isFilterApplied",
        JSON.stringify(location.state.detail)
      );
    }
  }, [location]);
  
  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Groups
            {!groupListLoading ? (
              <>
                {activeTab === "1" ? (
                  <Button
                    className="Add-btn pull-right"
                    onClick={() => {
                      setIcdPackageModal(true);
                      setIcdPackageModalState("add");
                    }}
                  >
                    Add New ICD Package
                  </Button>
                ) : (
                  <Button
                    className="Add-btn pull-right"
                    onClick={() => {
                      setCptPackageModal(true);
                      setCptPackageModalState("add");
                    }}
                  >
                    Add New CPT Package
                  </Button>
                )}
              </>
            ) : (
              ""
            )}
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/groups">Groups</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Packages</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <Card className="adv-search-background-card-pt2">
          <Container fluid>
            <Row style={{ marginBottom: "0rem" }}>
              <div style={{ justifyContent: "left" }}>
                <FontAwesomeIcon
                  size={"lg"}
                  style={{ cursor: "pointer" }}
                  title={"Go Back"}
                  icon={faArrowLeft}
                  onClick={goBack}
                />
              </div>
              <Col>
                <h2 style={{ textAlign: "center" }}>
                  {localStorage.getItem("groupName")}
                </h2>
              </Col>
            </Row>
          </Container>
        </Card>
      </Container>
      <Card>
        <CardBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                  setFileExportName("ICD Packages");
                }}
                style={{ cursor: "pointer" }}
              >
                ICD Packages
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                  setFileExportName("CPT Packages");
                }}
                style={{ cursor: "pointer" }}
              >
                CPT Packages
              </NavLink>
            </NavItem>
          </Nav>
          <br />
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Container fluid>
                <Row>
                  <Col
                    sm={{
                      size: 3,
                    }}
                  >
                    <Label>ICD Package Name</Label>
                    <AsyncSelect
                      cacheOptions
                      isClearable
                      isSearchable
                      value={icdPackageNameForFilter}
                      placeholder={"Select ICD Package Name"}
                      onChange={(event) => {
                        setIcdPackageNameForFilter(event);
                      }}
                      loadOptions={(inputValue) => getAsyncOptions(inputValue)}
                    />
                  </Col>

                  <Col
                    md={{
                      size: 4,
                    }}
                  >
                    <Button
                      className="icd-go-buttons"
                      onClick={() => {
                        setIcdPackageTotalSize(0);
                        icdPackageOffset === 0
                          ? setIcdPackageReload(icdPackageReload + 1)
                          : setIcdPackageOffset(0);
                        setIcdPackagePage(1);
                      }}
                      disabled={!icdPackageFilter ? true : false}
                      title={
                        !icdPackageFilter
                          ? "Choose at least one item to search "
                          : ""
                      }
                    >
                      Go
                    </Button>
                    <Button
                      className="icd-reset-buttons"
                      onClick={() => {
                        clearIcdPackageFilter();
                      }}
                      disabled={!icdPackageFilter ? true : false}
                      title={!icdPackageFilter ? "Nothing To Clear" : ""}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Container>

              <div className="groups-search">
                <SearchBarComponent
                  setSearchKeyword={setIcdPackageSearchKeyword}
                  searchKeyword={icdPackageSearchKeyword}
                  totalSize={icdPackageTotalSize}
                  clearValues={clearSearchValues}
                />
              </div>
              {!icdPackageSpinner && !groupListLoading ? (
                <>
                  {/* <RemotePagination
                  data={icdPackageDetails ? icdPackageDetails : ""}
                  columns={icdPackageColumns}
                  page={icdPackagePage}
                  sizePerPage={icdPackageSizePerPage}
                  FileExportName={fileExportName}
                  totalSize={icdPackageTotalSize ? icdPackageTotalSize : ""}
                  isGlobalSearch={isGlobalSearch}
                  setIsGlobalSearch={setIsGlobalSearch}
                  reloadVariable={icdPackageReload}
                  isDataEmpty={isIcdPackageDataEmpty}
                  onTableChange={(type, newState) => {
                    handleTableChange(type, newState);

                  }

                  }
                /> */}
                  <RemotePagination
                    data={
                      activeTab === "1" ? icdPackageDetails : cptPackageDetails
                    }
                    columns={
                      activeTab === "1" ? icdPackageColumns : cptPackageColumns
                    }
                    page={activeTab === "1" ? icdPackagePage : cptPackagePage}
                    sizePerPage={
                      activeTab === "1"
                        ? icdPackageSizePerPage
                        : cptPackageSizePerPage
                    }
                    FileExportName={fileExportName}
                    exportTableData={
                      activeTab === "1"
                        ? exportIcdPackageDetails
                        : exportCptPackageDetails
                    }
                    totalSize={
                      activeTab === "1"
                        ? icdPackageTotalSize
                        : cptPackageTotalSize
                    }
                    isGlobalSearch={isGlobalSearch}
                    setIsGlobalSearch={setIsGlobalSearch}
                    reloadVariable={
                      activeTab === "1" ? icdPackageReload : cptPackageReload
                    }
                    isDataEmpty={isCptPackageDataEmpty}
                    onTableChange={(type, newState) => {
                      handleTableChange(type, newState);
                    }}
                  />
                </>
              ) : (
                <Spinner />
              )}
            </TabPane>
          </TabContent>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="2">
              <Container fluid>
                <Row>
                  <Col
                    sm={{
                      size: 3,
                    }}
                  >
                    <Label>CPT Package Name</Label>
                    <AsyncSelect
                      cacheOptions
                      isClearable
                      isSearchable
                      value={cptPackageNameForFilter}
                      placeholder={"Select CPT Package Name"}
                      onChange={(event) => {
                        setCptPackageNameForFilter(event);
                      }}
                      loadOptions={(inputValue) =>
                        getAsyncOptionsForCPTPackages(inputValue)
                      }
                    />
                  </Col>

                  <Col
                    md={{
                      size: 4,
                    }}
                  >
                    <Button
                      className="icd-go-buttons"
                      onClick={() => {
                        setCptPackageTotalSize(0);
                        cptPackageOffset === 0
                          ? setCptPackageReload(icdPackageReload + 1)
                          : setCptPackageOffset(0);
                        setCptPackagePage(1);
                      }}
                      disabled={!cptPackageFilter ? true : false}
                      title={
                        !cptPackageFilter
                          ? "Choose at least one item to search "
                          : ""
                      }
                    >
                      Go
                    </Button>
                    <Button
                      className="icd-reset-buttons"
                      onClick={() => {
                        clearCptPackageFilter();
                      }}
                      disabled={!cptPackageFilter ? true : false}
                      title={!cptPackageFilter ? "Nothing To Clear" : ""}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Container>

              <div className="groups-search">
                <SearchBarComponent
                  setSearchKeyword={setCptPackageSearchKeyword}
                  searchKeyword={cptPackageSearchKeyword}
                  totalSize={cptPackageTotalSize}
                  clearValues={clearSearchValues}
                />
              </div>
              {!cptPackageSpinner && !groupListLoading ? (
                <>
                  <RemotePagination
                    data={
                      activeTab === "1" ? icdPackageDetails : cptPackageDetails
                    }
                    columns={
                      activeTab === "1" ? icdPackageColumns : cptPackageColumns
                    }
                    page={activeTab === "1" ? icdPackagePage : cptPackagePage}
                    sizePerPage={
                      activeTab === "1"
                        ? icdPackageSizePerPage
                        : cptPackageSizePerPage
                    }
                    FileExportName={fileExportName}
                    exportTableData={
                      activeTab === "1"
                        ? exportIcdPackageDetails
                        : exportCptPackageDetails
                    }
                    totalSize={
                      activeTab === "1"
                        ? icdPackageTotalSize
                        : cptPackageTotalSize
                    }
                    isGlobalSearch={isGlobalSearch}
                    setIsGlobalSearch={setIsGlobalSearch}
                    reloadVariable={
                      activeTab === "1" ? icdPackageReload : cptPackageReload
                    }
                    isDataEmpty={isCptPackageDataEmpty}
                    onTableChange={(type, newState) => {
                      handleTableChange(type, newState);
                    }}
                  />
                </>
              ) : (
                <Spinner />
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>

      <GroupsPackageModal
        icdPackageModal={icdPackageModal}
        setIcdPackageModal={setIcdPackageModal}
        icdPackageModalState={icdPackageModalState}
        setIcdPackageModalState={setIcdPackageModalState}
        groupOptions={groupDropDownOptions}
        selectedGroupName={selectedGroupName}
        setSelectedGroupName={setSelectedGroupName}
        icdPackageGroup={icdPackageGroup}
        setIcdPackageGroup={setIcdPackageGroup}
        selectedIcdPackage={selectedIcdPackage}
        setSelectedIcdPackage={setSelectedIcdPackage}
        icdPackageName={icdPackageName}
        setIcdPackageName={setIcdPackageName}
        icdPackageId={icdPackageId}
        icdPackageReload={icdPackageReload}
        setIcdPackageReload={setIcdPackageReload}
        setIcdPackageNamesReload={setIcdPackageNamesReload}
        icdPackageNamesReload={icdPackageNamesReload}
      />
      <GroupsCptPackagesModal
        cptPackageModal={cptPackageModal}
        setCptPackageModal={setCptPackageModal}
        cptPackageModalState={cptPackageModalState}
        setCptPackageModalState={setCptPackageModalState}
        groupOptions={groupDropDownOptions}
        selectedGroupName={selectedGroupName}
        setSelectedGroupName={setSelectedGroupName}
        cptPackageGroup={cptPackageGroup}
        setCptPackageGroup={setCptPackageGroup}
        selectedCptPackage={selectedCptPackage}
        setSelectedCptPackage={setSelectedCptPackage}
        cptPackageName={cptPackageName}
        setCptPackageName={setCptPackageName}
        cptPackageId={cptPackageId}
        cptPackageReload={cptPackageReload}
        setCptPackageReload={setCptPackageReload}
        setCptPackageNamesReload={setCptPackageNamesReload}
        cptPackageNamesReload={cptPackageNamesReload}
      />

      <DeleteConfirmationModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteFunction={
          activeTab === "1" ? deleteIcdPackageDetails : deleteCptPackageDetails
        }
      />
    </div>
  );
};

export default GroupPackages;
