import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'

const StatusChangeModalProviderSpecialityRank = (props) => {
  const {
    statusChangeModal,
    setStatusChangeModal,
    id,
    status,
    reload, setReload,
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const toggle = () => {
    setStatusChangeModal(!statusChangeModal);
  };

  const confirmation = () => {
    let newStatus = "";
    if (status === 0) {
      newStatus = 1
    } else {
      newStatus = 0
    }

    const updatedStatus = {
      psr_id: id,
      status: newStatus,
    };

    AxiosInter.post( `${API_ENDPOINT}/admin-api/provider-speciality-ranking-status-update/`, updatedStatus,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
        if (res.status === 201) {
          setReload(reload+1)
          setStatusChangeModal(!statusChangeModal);
          toastr.success('Status Updated', 'status updated successfully');
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const returnText = () => {
    // debugger
    if(status === 0){
      return "Are you sure you want to change the status to Active ?"
    }
    else if(status === 1){
      return "Are you sure you want to change the status to Inactive ?"
    }
    else {
      return "Are you sure you want to change the status to Inactive ?"
    }
  }


  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={statusChangeModal} >
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Status</ModalHeader>
        <ModalBody>
            <div style={{"text-align": "center"}}>
              {returnText()}
            </div>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default StatusChangeModalProviderSpecialityRank;
