import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Button
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit"; 
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import { GlobalState } from '../../contextAPI/Context';
import GroupAppUserFilters from "../../newComponents/utilities/GroupAppUserFilters.js"



const ExportCSVGPTable = (props) => {
  const { blankStateMessage } = useContext(GlobalState)
  // LOCAL VARIABLES 
  const { totalSize,onTableChange,searchKeys,setSearchKeys,appUserReload,setAppUserReload,offset,setOffset,exportTableData,groupID } = props
  const [numberOfRecords, setNumberOfRecords] = useState(0)
 
  const MyExportCSV = (props) => {
    const handleClick = () => {
      // props.onExport();
      exportTableData();
    };
    return (
      <div>
        <button className="btn btn-export mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No data available in table</h3>
      </CardBody>
    </Card>
  );

  /* const handleDataChange = ({ dataSize }) => {
    setNumberOfRecords(dataSize ? dataSize : 0);
  } */



  useEffect(() => { setNumberOfRecords(totalSize) }, [totalSize]);

  return (
    <div className="physicians_table patient_table table-list-height">
      <Card>
        <ToolkitProvider
          keyField="id"
          data={props.data}
          columns={props.columns}
          search
          exportCSV={{
            fileName: `Equipo-App-Users-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
          }}
        >
          {(props) => (
            <div>
              <CardHeader>
              
              <Row>
                  <Col>
                    <GroupAppUserFilters offset={offset}
                    setOffset={setOffset}
                    groupID={groupID}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="2">
                    <div className="number-of-records">
                      <p>Number of records: <strong>{numberOfRecords}</strong></p>
                    </div>
                  </Col>
                  <Col >
                    {/* <SearchBar {...props.searchProps} className="search-box" />
                    <ClearSearchButton {...props.searchProps} className="clear-search-box" /> */}
                    <div className="form-inline justify-content-end">
                      <div className="form-group mr-2">
                        <Input
                          className="search-box-new"
                          type="text"
                          value={searchKeys}
                          onChange={(e) => setSearchKeys(e.target.value)}
                          placeholder="Search"

                        />
                      </div>
                      <Button
                        className="clear-search-box"
                        onClick={() => {setSearchKeys("");  setAppUserReload(appUserReload+1) }}
                      >
                        Clear
                      </Button>
                      <div className="export-button ml-2">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="separator" />
                <div className="float-right pull-right">
                </div>
              </CardHeader>
              <CardBody className="patient-list appUserptlist group-list-tbody-height">
                
                {blankStateMessage ? blankState : ""}
                {!blankStateMessage && 
                <BootstrapTable
                  {...props.baseProps}
                  remote
                  bootstrap4
                  /* id={props.key} */
                  bordered={false}
                  keyField="id"

                  // onDataSizeChange={handleDataChange}
                  pagination={paginationFactory({
                    page: props.page,
                    sizePerPage: props.sizePerPage,
                    sizePerPageList: [50,100,150,200],
                    totalSize
                  })}
                  onTableChange={ onTableChange }
                  /* {!props.data && apiLoaded ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""} */
                />}
              </CardBody>
            </div>
          )}
        </ToolkitProvider>
      </Card>
    </div>
  );
};

export default ExportCSVGPTable;