import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row ,Col, CardBody ,Card } from 'reactstrap';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";


const NPITableModal = (props) => {
  
  const { rowData, setModal, modal, addressInfo0, basicInfo, addressInfo1, taxonomiesInfo, otherInfo, organizationInfo } = props;

  const toggle = () => setModal(!modal);

  const genderFun =() => {
      if(basicInfo.gender == "F") { // eslint-disable-line
          return "Female"
      } else {
          return "Male"
      }
  }
  const NPITypeFun =() => {
    if (rowData.enumeration_type == "NPI-1") { // eslint-disable-line
        return (
          "1 - Individual"
        )
      } else {
        return (
          "2- Organization"
        )
      }
  }

 
  return (
    <div>
      <Modal isOpen={modal}  size="lg" scrollable={true}>
        <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Provider Information for {rowData.number} </ModalHeader>
        <ModalBody>

            <div className="NPI-info-card">
            <Card>
                      <CardBody>
                      <h4>
                      {basicInfo.organization_name ? basicInfo.organization_name : `${basicInfo.first_name}${" "}${basicInfo.last_name}` }
                      </h4>
                          {
                              basicInfo.organization_name ? 
                              <Row>
                              <Col xs="5">

                                  <table>
                                      <tbody>
                                          <tr>
                                              <td> Doing Business As</td>
                                              <td>:</td>
                                              <td>{organizationInfo.organization_name}</td>
                                          </tr>
                                          <tr>
                                              <td>Last Updated</td>
                                              <td>:</td>
                                              <td>{basicInfo.last_updated}</td>
                                          </tr>
                                          <tr>
                                             
                                              <td>
                                                <FontAwesomeIcon
                                                    icon={faBuilding}
                                                    className="align-middle"
                                                />  
                                                  {" "}NPI
                                                </td>
                                              <td>:</td>
                                              <td>{rowData.number}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </Col>
                          </Row> : 
                          <Row>
                          <Col xs="5">

                              <table>
                                  <tbody>
                                      <tr>
                                          <td> Gender</td>
                                          <td>:</td>
                                          <td>{genderFun()}</td>
                                      </tr>
                                      <tr>
                                          <td>Last Updated</td>
                                          <td>:</td>
                                          <td>{basicInfo.last_updated}</td>
                                      </tr>
                                      <tr>
                                         
                                          <td>
                                            <FontAwesomeIcon
                                                icon={faBuilding}
                                                className="align-middle"
                                            />  
                                              {" "}NPI
                                            </td>
                                          <td>:</td>
                                          <td>{rowData.number}</td>
                                      </tr>
                                  </tbody>
                              </table>
                          </Col>
                      </Row>
                          }
                      </CardBody>
                </Card>

            </div>
        
        <Table bordered>
            <thead>
                <tr>
                <th>Name</th>
                <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>NPI</td>
                    <td>{rowData.number}</td>
                </tr>
                <tr>
                    <td>Enumeration Date</td>
                    <td>{basicInfo.enumeration_date}</td>
                </tr>
                <tr>
                    <td>NPI Type</td>
                    <td>{NPITypeFun()}</td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>{basicInfo.status}</td>
                </tr>
                {
                    basicInfo.authorized_official_first_name ? 
                    <tr>
                        <td>Authorized Official Information</td>
                        <td>
                            <Row>
                                <Col xs="2">
                                    Name :
                                </Col>
                            
                                <Col xs="9">
                                    {`${basicInfo.authorized_official_first_name} ${" "}${basicInfo.authorized_official_middle_name} ${" "} ${basicInfo.authorized_official_last_name} ${" "} ${basicInfo.authorized_official_credential}`}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2">
                                    Title :
                                </Col>
                                <Col xs="9">
                                    {`${basicInfo.authorized_official_title_or_position}`}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="2">
                                    Phone :
                                </Col>
                                <Col xs="9">
                                    {`${basicInfo.authorized_official_telephone_number}`}
                                </Col>
                            </Row>
                        </td>
                    </tr> 
                    : 
                    ""
                }
                <tr>
                    <td>Mailing Address</td>
                    <td>
                        <Row>
                            <Col xs="9">
                                {addressInfo1.address_1}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="9">
                                
                                {`${addressInfo1.city}, ${addressInfo1.state} ${" "} ${addressInfo1.postal_code}`}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="9">
                                
                                {`${addressInfo1.country_name}`}
                            </Col>
                        </Row>
                       
                    </td>
                </tr>
                <tr>
                    <td>Primary Practice Address</td>
                    <td>
                        <Row>
                            <Col xs="9">
                                {addressInfo0.address_1}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="9">
                                
                                {`${addressInfo0.city}, ${addressInfo0.state} ${" "} ${addressInfo0.postal_code}`}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="9">
                                
                                {`${addressInfo0.country_name}`}
                            </Col>
                        </Row>
                       
                    </td>
                </tr>
                <tr>
                    <td>Taxonomy</td>
                    <td>
                        <table>
                                <tbody>
                                    <tr>
                                        <th>Selected Taxonomy</th>
                                       
                                        <td>{taxonomiesInfo.taxonomy_group}</td>
                                    </tr>
                                    <tr>
                                        <th>State</th>
                                       
                                        <td>{taxonomiesInfo.state}</td>
                                    </tr>
                                    <tr>
                                        <th>License Number</th>
                                       
                                        <td>{taxonomiesInfo.license}</td>
                                    </tr>
                                </tbody>
                            </table>
                    </td>
                </tr>
              { otherInfo ?
                    <tr>
                    <td>Other Identifiers</td>
                    <td>
                        <table>
                                <tbody>
                                    <tr>
                                        <th>Issuer</th>
                                       
                                        <td>{otherInfo.desc}</td> 
                                        
                                    </tr>
                                    <tr>
                                        <th>State</th>
                                       
                                        <td>{otherInfo.state ? otherInfo.state : "--"}</td>
                                    </tr>
                                    <tr>
                                        <th>Number</th>
                                       
                                        <td>{otherInfo.identifier ? otherInfo.identifier : "--"}</td>
                                    </tr>
                                </tbody>
                            </table>
                    </td>
                </tr> : ""
              }
            </tbody>
        </Table>
        

        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default NPITableModal;