import React, { useState , useEffect, useContext} from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,Input,Label, FormGroup, } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import styles from "./ProviderDetails.module.css";
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';

const EditAppointmentTagModal = (props) => {
    const {
        
        setEditAppTagModal,
        setEditGroupID,
        editID,
        setEditTagName,
        editAppTagModal,
        editGroupID,
        editTagName,
        reloadApptTag,
        setReloadApptTag,

        
    } = props;

    // CONTEXT VARIABLES 
    const { setSpinner } = useContext(GlobalState);
    
    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");     // eslint-disable-line
    const [addButtonStatus, setAddButtonStatus] = useState(true);

    const [groupID, setGroupsID] = useState([]);   
    const [groupOpt, setGroupOpt] = useState("");   
    const [tag, setTag] = useState("");


    const [groupList, setGroupList] = useState("");
    
    const toggle = () => { 
        setEditAppTagModal(!editAppTagModal);
        setEditGroupID(0);
        setEditTagName("");
    }

    const fetchGroupData = async () => {
      setSpinner(true);
      try {
        const response = await AxiosInter.post(`${API_ENDPOINT}/admin-api/graphql/`, {
          query: `
            query {
              groups {
                id
                name
              }
            }
          `,
        });
       const data = response.data;
        setGroupList(data.data.groups);
      } catch (error) {
        // Handle error
        console.error('Error:', error);
      }
     setSpinner(false);
    };
    

    const updateAppUserApiCall = () => {
        setSpinner(true)
        const string4 = JSON.stringify(tag)
        AxiosInter.post(`${API_ENDPOINT}/admin-api/graphql/`, {
            query: `mutation updateGroupTag {
                        updateGroupTag(groupId: ${groupID.value ? Number(groupID.value) : groupID[0].value}, id:${Number(editID)}, tag:${string4}){
                        groupTag { id tag status }
                    }
                }`
        })
            .then(res => {
                if (res.status === 200) {
                    setEditAppTagModal(!editAppTagModal);
                    toastr.success("Success", "Appointment Tag Updated Successfully");
                    setReloadApptTag(reloadApptTag + 1);
                } else {
                    setEditAppTagModal(!editAppTagModal);
                    toastr.error("Error", "Appointment Tag Update Failed");
                }
                setSpinner(false)

            })
            .catch(err => {
                // console.log(err);
                setEditAppTagModal(!editAppTagModal);
                toastr.error("Error", "Appointment Tag Update Failed");
                setSpinner(false)
            })
        setSpinner(false)

    }

    const disableAddButton = () => {
        if ( groupID && tag ) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }

    const optionsForGroup = () => { 
       
        if(groupList){
            let array = []
            groupList.map((item, index) => {  // eslint-disable-line
                array.push({
                    value: item.id,
                    label: item.name
                })
            })

            setGroupOpt(array)
        }
    }

    const getAsyncOptions = (inputValue) => { 
       
        if(groupOpt){
         return new Promise((resolve, reject) => {
             const filtered = _.filter(groupOpt, o =>
               _.startsWith(_.toLower(o.label), _.toLower(inputValue))
             );
             resolve(filtered);
           });
        }
    }

    const groupPopulate = () => {
        if(groupList && editGroupID){
            const array2 = []
            groupList.map((item1)=>{ // eslint-disable-line
                if (editGroupID == item1.id){ // eslint-disable-line
                    array2.push(
                        {
                            value: item1.id,
                            label: item1.name
                        }
                    )
                }
            })
            setGroupsID(array2)
        }
    }
 

    useEffect(() => { fetchGroupData() } , []);    // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { if(groupList && editGroupID )  groupPopulate() } , [editGroupID]);    // eslint-disable-line 

    useEffect(() => { optionsForGroup() } , [groupList]);    // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { if(editTagName) setTag(editTagName) } , [editTagName]);    // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { disableAddButton() }, [ groupID,tag ]) // eslint-disable-line



    
    return (
        <div>
            <Modal isOpen={editAppTagModal} size="md" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}} toggle={toggle} >Edit - { editTagName ? editTagName : "N/A"} </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>

                    <Row className={styles.detailProfRow} >
                        <Col md={3} sm={5} >
                            <div className={styles.detailProfLabel} >
                                <Label>
                                Group  <span className="required">*</span> 
                                </Label>
                            </div>
                        </Col>
                        <Col md={9} sm={7}>
                            <div className={styles.detailProfValue} >
                            <AsyncSelect
                                    cacheOptions
                                    isClearable
                                    isSearchable
                                    /* defaultOptions */
                                    placeholder="start typing group name for options"
                                    value={groupID}
                                    onChange={(event) => setGroupsID(event)} 
                                    loadOptions={inputValue => getAsyncOptions(inputValue)}
                                />
                            </div>
                        </Col>
                    </Row>                       
                                     

                    <Row className={styles.detailProfRow} >
                        <Col md={3} sm={5} >
                            <div className={styles.detailProfLabel} >
                                <Label>
                                    Tag Name  <span className="required">*</span> 
                                </Label>
                            </div>
                        </Col>
                        <Col md={9} sm={7}>
                            <div className={styles.detailProfValue} >
                                <Input
                                     id="name"
                                     value={tag}
                                     placeholder="Enter the user name"
                                     onChange={(e) => {if (e.target.value.length <= 150) setTag(e.target.value)}}
                                />
                            </div>
                        </Col>
                    </Row>
                         
                    </FormGroup>
                 </ModalBody>
                <ModalFooter className="advanced-search-button custom-modal-footer">
                    <Button 
                        className="yes-button button-width"  
                        onClick={ () => updateAppUserApiCall() }
                        disabled={addButtonStatus}
                        title={ addButtonStatus ? "Fill the required fields" : "" }
                    >Update
                    </Button>
                        {' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditAppointmentTagModal;