import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
//   Button
} from "reactstrap";






const RemotePaginationForProvider = ({ onTableChange,exportTableData,data,columns, page, sizePerPage, totalSize }) => {
  const MyExportCSV = (props) => {
    const handleClick = () => {// eslint-disable-line
      exportTableData();
    };
    return (
      <>
        {/* <Button 
        className="btn btn-export" 
        disabled = {data ? false:true}
        title = {data ? 'Export Data':'Nothing to export'}
        onClick={handleClick}>
          Export
      </Button> */}
      </>
    );
  };



  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  


  return (
    <div>
     
      <Card>
        {/* <ToolkitProvider
          keyField="id"
          data={data ? data:""}
          columns={columns}
          search
          exportCSV={{
            fileName: `Equipo-${FileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
          }}
        > */}
          
          {/* {(props) => ( */}
            <div className="table-position">
              <CardHeader className="table-header-position">

                <Row>
                    <Col sm={12}>
                    <MyExportCSV className="mt-8" />
                    </Col>
                      
                 
              </Row>
              </CardHeader>
              <CardBody className="patient-list dropdown-menu-up">
              {data ?
                <BootstrapTable
                  // {...props.baseProps}
                  remote
                  bootstrap4
                  hover
                  bordered={false}
                  keyField="id"
                  data={data}
                  columns={columns}
                  //  noDataIndication={indication(isDataEmpty)}
                 

                  pagination={paginationFactory({
                    page: page,
                    sizePerPage: sizePerPage,
                    sizePerPageList: [50,100,150,200],
                    totalSize
                    
                  })}
                  onTableChange={ onTableChange }
                /> :blankState}
              </CardBody>
            </div>
          {/* //  )}
        </ToolkitProvider> */}
      </Card>
    
    </div>
  );



};



export default RemotePaginationForProvider;
