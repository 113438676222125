import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,

  FormGroup,
  Table,
} from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Cookie from "js-cookie";
import moment from 'moment';
import AxiosInter from './../../AxiosInter.js';

const QuerySave = (props) => {
  const { savedQueryModal, setSavedQueryModal } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  // eslint-disable-next-line no-unused-vars
  const [spinner, setSpinner] = useState(false);
  const [queries, setQueries] = useState([]);
  const [copiedIndex, setCopiedIndex] = useState(null);

  const toggle = () => {
    setSavedQueryModal(!savedQueryModal);
  };

  useEffect(() => {
    // Fetch data from your API endpoint
    if(savedQueryModal){
      setSpinner(true);
      AxiosInter.get(`${API_ENDPOINT}/admin-api/query-list/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then((response) => {
          setQueries(response.data.data);
          setSpinner(false);
        })
        .catch((error) => {
          console.log(error)
          setSpinner(false);
        });
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedQueryModal]);

  const handleCopy = (index) => {
    setCopiedIndex(index);
    setTimeout(() => {
      setCopiedIndex(null);
    }, 5000);
  };

  return (
    <div>
      <Modal
        isOpen={savedQueryModal}
        toggle={toggle}
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Query History{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          <FormGroup>
            <Row>
              <Col>
                <h3>Search History</h3>
              </Col>
            </Row>
            <Table>
              <thead>
                <tr>
                  <th>Index</th>
                  <th>Query</th>
                  <th>Executed By</th>
                  <th>Executed At</th>
                  <th>Action</th>
                </tr>
              </thead>
              {spinner ? <Spinner /> : ""}
              <tbody>
                {queries.map((sqlquer, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{sqlquer.sqlquer}</td>
                    <td> {sqlquer.executed_by}</td>
                    <td>{moment.utc(sqlquer.executed_at).local().format('DD-MMM-YYYY hh:mm a')}</td>
                    <td>
                      <CopyToClipboard
                        text={sqlquer.sqlquer}
                        onCopy={() => handleCopy(index)}
                      >
                        <Button outline className="no-button">
                          {copiedIndex === index ? "Copied!" : "Copy"}
                        </Button>
                      </CopyToClipboard>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button
            outline
            className="no-button button-width"
            onClick={toggle}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default QuerySave;
