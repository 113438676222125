import React, { useEffect, useState } from "react";

import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Label,
  Spinner,
  CardBody,
  Input
} from "reactstrap";
import AsyncSelect from "react-select/async";
import { Link } from "react-router-dom";
import AxiosInter from './../../AxiosInter.js';
import { Hux } from "./../../hoc/Hux";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import MomentConfig from "../../common/MomentConfig";
// eslint-disable-next-line no-unused-vars
import moment from "moment";
import ReferralByTagDataTable from "./ReferralByTagDataTable";
import toast, { Toaster } from "react-hot-toast";
import ReferralOrderProcessing from "./ReferralOrderProcessing.js";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import Cookie from 'js-cookie';
import Select from "react-select";

// eslint-disable-next-line no-unused-vars
//import { ToastContainer, toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";
//toast.configure();
toast();
const ReferralByTag = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const API_ORDER_AUTOPROCESS = process.env.REACT_APP_REFERRAL_ENDPOINT;
  const [spinner, setSpinner] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [apiLoaded, setApiLoaded] = useState(false);
  const [referralsList, setReferralsList] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [referralOrderID, setReferralOrderID] = useState(0);
  const [referralOrderIDList, setReferralOrderIDList] = useState([]);
  const [referralOrderiiList, setReferralOrderiList] = useState([]);
  const [referralOrderResponseStatus, setReferralOrderResponceStatus] =
    useState([]);
  const [referralOrderResponseDetails, setReferralOrderResponceDetails] =
    useState([]);

  const [orderProcessModal, setOrderProcessModal] = useState(false);

  const [groupID, setGroupID] = useState(0);
  const [tagID, setTagID] = useState(0);

  const [search, setSearch] = useState(false);
  // eslint-disable-next-line no-unused-vars

  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [rowData, setRowData] = useState("");

  const [userDataReload, setUserDataReload] = useState(0);
  const [userSizePerPage, setUserSizePerPage] = useState("50");
  const [offset, setOffset] = useState("0");
  const [page, setPage] = useState(1);

  const [totalSize, setTotalSize] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [userDate, setUserDate] = useState("");
  const [orderTypeList, setOrderTypeList] = useState("");
  const [orderTypeDropDown, setOrderTypeDropDown] = useState("");
  const [orderTypeID, setOrderTypeID] = useState("")

  const clearValues = () => {
    setGroupID(0);
    setTagID(0);
    setOrderTypeID(0);
    setReferralsList("");
    setSortOrder("");
    setTotalSize(0);
    setApiLoaded(false);
    setUserDate("")
    setPage(1)
  };

  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };
  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faFileImport}
              size={"1x"}
              color={"#3f86fa"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                let td = toast.loading("Processing");

                setRowData(row);
                setReferralOrderID(row.OrderNumber);
                let refoID = row.id;
                let order_NUmber = row.OrderNumber;
                if (refoID) {
                  const params = {
                    orderId: refoID,
                  };
                  AxiosInter.post(`${API_ORDER_AUTOPROCESS}`, params
                    // {
                    // headers: {
                    // Authorization: API_TOKEN,
                    // },
                    // }
                  )
                    .then(function (response) {
                      if (response.data) {
                        // eslint-disable-next-line eqeqeq
                        if (response.data.status == "success") {
                          toast.dismiss(td)
                          toast.success(`Order ${order_NUmber} Processed with status ${response.data.status}
Details: ${response.data.data.details} `)
                          // toast.update(id, {
                          // render: `Order ${order_NUmber} Processed with status ${response.data.status}
                          // Details: ${response.data.data.details} `,
                          // type: "success",
                          // isLoading: false,
                          // closeOnClick: true,
                          // position: toast.POSITION.TOP_CENTER,
                          // });
                        }
                      } else {
                        console.log("not data present", response.data);
                        // setReferralsList("");
                        // setApiLoaded(false);
                        // setSpinner(false);
                        // setIsDataEmpty(true);
                      }
                    })
                    .catch(function (error) {
                      toast.error(
                        `some Error Occured For Order ${order_NUmber} `
                      );
                      // setReferralsList("")
                      // toast.update(id, {
                      // render: `some Error Occured For Order ${order_NUmber} `,
                      // type: "error",
                      // isLoading: false,
                      // closeOnClick: true,
                      // position: toast.POSITION.TOP_CENTER,
                      // });
                      console.log("error", error);
                      // setApiLoaded(false);
                      // setSpinner(false);
                    });
                }
              }}
              title="Process"
            />
          </Col>
        </Row>
      </>
    );
  };

  const dobFormatter = (cell) => {
    return cell
      ? moment(cell).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)
      : "N/A";
  };

  const columns = [
    {
      dataField: "OrderNumber",
      text: "Order Number",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "OrderType",
      text: "Speciality",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "orderDate",
      text: "Order Creation Date",
      sort: true,
      formatter: dobFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "Reason_For_Draft",
      text: "Reason For Draft",
      sort: true,
      formatter: basicValidation,
    },

    {
      text: "Process Order",
      sort: false,
      formatter: actionsFormatter,
    },
  ];
  const onTableChangeForuser = (type, newState) => {
    setPage(newState.page);
    setUserSizePerPage(newState.sizePerPage);

    setOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("ro.id");
      setSortOrder("desc");
      setUserDataReload(userDataReload + 1);
    } else if (newState.sortField === "OrderNumber") {
      setSortColumn("order_serial_number");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    } else if (newState.sortField === "OrderType") {
      setSortColumn("rt.type");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    } else if (newState.sortField === "orderDate") {
      setSortColumn("creation_date");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    } else if (newState.sortField === "Reason_For_Draft") {
      setSortColumn("additional_comments");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    } else if (newState.sortField === "status") {
      setSortColumn("rs.status");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    }
  };

  const searchOrder = () => {
    setSpinner(true);
    setApiLoaded(true);
    let apiFormat = ""
    if (userDate) {
      let dat = moment(userDate).format("DD-MM-YYYY");
      let localDateAndTime = `${dat} 00:00:00`;

      apiFormat = moment(localDateAndTime, "DD-MM-YYYY H:mm:ss").format(
        "YYYY-MM-DD"
      );

    }
    const params = {
      groupID: groupID ? groupID.value : "",
      tagID: tagID ? tagID.value : "",
      typeID: orderTypeID ? orderTypeID.value : "",
      orderCreationDate: userDate ? apiFormat : "",
      searchKeyword: searchKeyword ? searchKeyword : "",
      is_pagination: "",
      record_limit: userSizePerPage ? userSizePerPage : "50",
      OFFSET: offset ? offset.toString() : "0",
      sortOrder: sortOrder ? sortOrder : "desc",
      sortColumn: sortColumn ? sortColumn : "ro.id",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-orderlist-bytag/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then(function (response) {
        if (response.data.data.length > 0) {
          setReferralsList(response.data.data);

          localStorage.setItem("offset", offset.toString());
          setTotalSize(response.data.record_count);
          setApiLoaded(true);
          setSpinner(false);
        } else {
          setTotalSize(0);
          setReferralsList("");
          setApiLoaded(false);
          setSpinner(false);
          setIsDataEmpty(true);
        }
      })
      .catch(function (error) {
        setReferralsList("");
        setApiLoaded(false);
        setSpinner(false);
      });
  };
  useEffect(() => {
    if (searchKeyword.length > 3) setUserDataReload(userDataReload + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword]);
  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]); //
  const clearSearchValues = () => {
    setSearchKeyword("");
    setUserDataReload(userDataReload + 1);
  };
  const enableOrDisableSearch = () => {
    if (groupID || tagID || userDate) {
      setSearch(true);
    } else {
      setSearch(true);
    }
  };
  let refIdList = [];
  let refId = []
  useEffect(() => {
    if (referralsList.length > 1) {
      // eslint-disable-next-line array-callback-return
      referralsList.map((item) => {
        refIdList.push(item.OrderNumber);
        refId.push(item.id)
      });
      setReferralOrderIDList(refIdList);
      setReferralOrderiList(refId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralsList]);
  // useEffect(() => {
  // console.log(referralOrderIDList);
  // referralOrderIDList.map((item) => {
  // if (item) {
  // const params = {
  // orderId: item,
  // };

  // AxiosInter
  // .post(
  // `https://alpha-referral-api.equipo.io/api/autoProcessRule/autoProcessDraftOrders`,
  // params
  // // {
  // // headers: {
  // // Authorization: API_TOKEN,
  // // },
  // // }
  // )
  // .then(function (response) {
  // if (response.data) {
  // debugger;
  // console.log("data present", response.data["status"]);
  // } else {
  // debugger;
  // console.log("not data present", response.data);
  // // setReferralsList("");
  // // setApiLoaded(false);
  // // setSpinner(false);
  // // setIsDataEmpty(true);
  // }
  // })
  // .catch(function (error) {
  // debugger;
  // // setReferralsList("");
  // console.log("error", error);
  // // setApiLoaded(false);
  // // setSpinner(false);
  // });
  // }
  // });
  // }, [referralOrderIDList]);

  useEffect(() => {
    enableOrDisableSearch();
  }, [groupID, tagID]); // eslint-disable-line

  useEffect(() => {
    if (userDataReload >= 1) {
      searchOrder();
      localStorage.setItem("offset", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataReload]);

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset) {
      searchOrder();
    }
  }, [offset]); // eslint-disable-line

  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      // setPage
      setTotalSize(totalSize);
      setUserDataReload(userDataReload + 1);
      // setReloadGroupDetails(true);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGroupNameChange = (value) => {
    setGroupID(value);
  };
  const handleTagNameChange = (value) => {
    setTagID(value);
  };
  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const minLength = 4;
  const customNoOptionsMessage = ({ inputValue }) => {
    if (inputValue.length < minLength) {
      return "Please enter at least 4 letters to search";
    } else {
      return "No option";
    }
  };

  const tagNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-referral-assignee-tag-name/${inputValue}/${groupID ? groupID.value : "free"}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)

    }
  };

  const groupNameloadOptions = async (inputValue) => {
    if (inputValue.length >= 4) {
      try {
        const searchParams = new URLSearchParams({ name: inputValue });
        const response = await AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-referral-order-sender-groups/?${searchParams.toString()}`, {
          headers: {
            Authorization: API_TOKEN,
          },
        });
        return response.data.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  };

  const fetchOrderTypeData = async () => {
    setSpinner(true)
    try {
      const response = await AxiosInter.post(`${API_ENDPOINT}/admin-api/graphql/`, {
        query: ` query{
                      referralOrderTypesList(isClinical: 1)
  {
                    id
                    type
                  }
                } 
              `,
      });
      const data = response.data;
      setOrderTypeList(data.data.referralOrderTypesList);
    } catch (error) {

    }
    setSpinner(false)
  }

  const createOptionsForOrderType = () => {
    if (orderTypeList) {
      setSpinner(true)
      var array1 = orderTypeList
      const options1 = array1.map((item, index) => {
        return {
          value: item.id,
          label: item.type
        };
      });
      setOrderTypeDropDown(options1);
      setSpinner(false)
    }
  };

  useEffect(() => {
    fetchOrderTypeData()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (orderTypeList) createOptionsForOrderType();
  }, [orderTypeList]) // eslint-disable-line

  useEffect(() => {

    // eslint-disable-next-line array-callback-return
    referralOrderiiList.map((item) => {
      console.log("hjhjhjkhj", item)
    })
  }, [referralOrderiiList])
  const handleClick = () => {
    let status = [];
    let details = [];
    // eslint-disable-next-line no-unused-vars
    let final = [];
    // eslint-disable-next-line array-callback-return
    var promises = referralOrderiiList.map((item) => {
      if (item) {
        const params = {
          orderId: item,
        };

        AxiosInter
          .post(`${API_ORDER_AUTOPROCESS}`, params)
          .then(function (response) {
            if (response.data) {
              if (response.data.status === "success") {
                console.log("data present", item, response.data["status"]);
                console.log("restypeee", typeof response.data.title);
                status.push(response.data.title);
                details.push(response.data.data.details);
                final = [...referralOrderResponseStatus, status];
                setReferralOrderResponceStatus(
                  (referralOrderResponseStatus) => [
                    ...referralOrderResponseStatus,
                    response.data.title,
                  ]
                );
                setReferralOrderResponceDetails(
                  (referralOrderResponseDetails) => [
                    ...referralOrderResponseDetails,
                    response.data.data.details,
                  ]
                );
              }
            } else {
              console.log("not data present", response.data);
            }
          })
          .catch(function (error) {
            console.log("error", error);
          });
      }
    });

    Promise.all(promises).then(function (results) {
      console.log("Result", results);

    });
  };

  return (
    <div>
      <Hux>
        <Container fluid>
          <Header>
            <HeaderTitle>Referral Order Autoprocess</HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Referral Order Autoprocess</BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Container>
      </Hux>
      <Card className="adv-search-background-card">
        <CardHeader>
          <Row>
            <Col sm="3">
              <Label>Group Name</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="groupName"
                placeholder="Select Group."
                noOptionsMessage={customNoOptionsMessage}
                value={groupID}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleGroupNameChange}
                loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
              />
            </Col>
            <Col sm="3">
              <Label>Tag Name</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="groupName"
                placeholder="Select Tag."
                noOptionsMessage={customNoOptionsMessage}
                value={tagID}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleTagNameChange}
                loadOptions={(inputValue) => tagNameloadOptions(inputValue)}
              />
            </Col>
            <Col sm="3">
              <Label>Speciality</Label>
              <Select
                name="order"
                options={orderTypeDropDown}
                value={orderTypeID}
                placeholder="Select Speciality"
                isSearchable
                isClearable
                onChange={(e) => {
                  setOrderTypeID(e)
                }}
              />
            </Col>
            <Col sm="3">
              <Label>Order Creation Date</Label>
              <Input
                id="expiration"
                type="date"
                value={userDate}
                placeholder="Order Creation Date "
                onChange={(e) => {
                  setUserDate(e.target.value)

                }}
                style={{ margin: "0", height: "38px" }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <Button
                className="patient-go-buttons"
                disabled={!search ? true : false}
                title={
                  spinner || !search
                    ? "Choose at least one item to search "
                    : ""
                }
                onClick={() => {
                  offset === "0" ? searchOrder() : setOffset("0");
                }}
              >
                Go
              </Button>{" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => {
                  clearValues();
                }}
                disabled={!search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>{" "}
              <Button style={{ marginTop: "32px", marginLeft: "5px" }}
                onClick={() => {
                  handleClick();
                  setOrderProcessModal(true);

                }}
              >
                Bulk Process
              </Button>
              <Col></Col>
            </Col>
          </Row>
          <div className="separator" />
        </CardHeader>
        <div>{spinner ? <Spinner /> : ""}</div>
        <CardBody>
          {" "}
          <div className="groups-search">
            <SearchBarComponent
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              totalSize={totalSize}
              columns={columns}
              clearValues={clearSearchValues}
              OrderAutoProcess={true}
            />
          </div>
          <ReferralByTagDataTable
            data={referralsList ? referralsList : ""}
            columns={columns}
            handleTableChangeForReferralOrder={(type, newState) => {
              onTableChangeForuser(type, newState);
            }}
            page={page}
            sizePerPage={userSizePerPage}
            totalSize={totalSize ? totalSize : 0}
            setPage={setPage}
            setOffset={setOffset}
            setTotalSize={setTotalSize}
            referralDataReload={userDataReload}
            setReferralDataReload={setUserDataReload}
          />
        </CardBody>
      </Card>
      <Toaster
        style={{ width: "auto", height: "auto" }}
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 5000,
          style: {
            background: "#363636",
            color: "#fff",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-all",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <ReferralOrderProcessing
        setOrderProcessModal={setOrderProcessModal}
        orderProcessModal={orderProcessModal}
        setReferralOrderResponceStatus={setReferralOrderResponceStatus}
        referralOrderResponseStatus={referralOrderResponseStatus}
        setReferralOrderResponceDetails={setReferralOrderResponceDetails}
        referralOrderResponseDetails={referralOrderResponseDetails}
        setReferralOrderIDList={setReferralOrderIDList}
        referralOrderIDList={referralOrderIDList}

      />
    </div>
  );
};

export default ReferralByTag;
