import React, { useState, useEffect, useContext } from "react";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation"; // eslint-disable-line
// eslint-disable-next-line no-unused-vars
import { Col, Label, Row, Button, Input, Spinner } from "reactstrap";
import "./DateRangeSelector.css";
// eslint-disable-next-line no-unused-vars
import makeAnimated from "react-select/animated";
import { GlobalState } from "../../contextAPI/Context";
 // eslint-disable-line
import AxiosInter from './../../AxiosInter.js';
import _ from "lodash";

import AsyncSelect from "react-select/async";
import Cookie from 'js-cookie';

const FaxLogFilters = (props) => {
// debugger
  // CONTEXT VARIABLES
  const {
    setAppUserId,
    setPatientId,
    setPhysicianId,
    setGroupsId,
    setFacilityId,
    setDocName,
    setRecipientFaxNumber,
    setStatusC,
    faxReload,
    setFaxReload,
  } = useContext(GlobalState);
  const { fetchFaxLogList,setOffset, setSortColumn} = props

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  // eslint-disable-next-line no-unused-vars
  const animatedComponents = makeAnimated();
  const [appUserName, setAppUserName] = useState("");
  const [patient, setPatientID] = useState("");
  const [provider, setProvider] = useState("");
  const [groupID, setGroupID] = useState("");
  const [facility, setFacility] = useState("");
  const [docName, setDoctName] = useState("");
  const [status, setStatus] = useState("");
  const [faxNumber, setFaxNumber] = useState(""); // eslint-disable-line
  const [spinner, setSpinner] = useState(false);

  const [appUserList, setAppUserList] = useState("");
  const [appUserDD, setAppUserDD] = useState("");

  const [groupList, setGroupList] = useState("");
  const [groupDD, setGroupDD] = useState("");

  const [facilityList, setFacilityList] = useState("");
  const [facilityDD, setFacilityDD] = useState("");

  const [statusList, setStatusList] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [statusDD, setStatusDD] = useState("");

  const runSearch = () => {
    if (
      appUserName ||
      patient ||
      provider ||
      facility ||
      status ||
      docName ||
      groupID
    ) {
      setAppUserId(appUserName ? appUserName : 0);
      setPatientId(patient ? patient : 0);
      setPhysicianId(provider ? provider : 0);
      setFacilityId(facility ? facility : 0);
      setGroupsId(groupID ? groupID : 0);
      setStatusC(status ? status : "");
      setOffset(0)
      setRecipientFaxNumber(faxNumber ? faxNumber : "");
      setDocName(docName ? docName : "");
      setFaxReload(faxReload + 1);
    }
  };

  const resetFields = () => {
    setAppUserName("");
    setPatientID("");
    setProvider("");
    setFacility("");
    setDoctName("");
    setStatus("");
    setGroupID("");
    setAppUserName("");
    setAppUserId("");
    setPatientId("");
    setPatientId("");
    setFacilityId("");
    setGroupsId("");
    setStatusC("");
    setRecipientFaxNumber("");
    setDocName("");
    // setOffset(0)
    setSortColumn("");
    setFaxReload(faxReload + 1);
    fetchFaxLogList();
    // window.location.reload()
  };


  const fetchData = async () => {
      setSpinner(true);
      try{
        const response= await AxiosInter.post(`${API_ENDPOINT}/admin-api/graphql/`, {
          query: `  query{
                      appUsers{
                        id
                        name
                      }
                      groups{
                        id
                        name
                      }
                      facility{
                        id
                        name
                      }
                      faxStatusList{
                        id
                        displayName
                        displayCode
                      }
                    }           
                    `,
        });
        const data=response.data;  
        setAppUserList(data.data.appUsers);
        setGroupList(data.data.groups);
        setFacilityList(data.data.facility);
        setStatusList(data.data.faxStatusList);
      }
      catch(error){
        console.error('Error:', error);
      }
          setSpinner(false);
  };  


  /* const fetchGroupsAPI = () => {
        setSpinner(true)
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-groups-list/`, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
          .then(function (response) {
            if (response.data.length > 0) {
              setGroupList(response.data);
            }
            setSpinner(false)
          })
          .catch(function (error) {
            setGroupList("");
            setSpinner(false)
          });
    }; */

  /* const createRoleOption = () => {

        setSpinner(true)
        var array = roleList

        var SortedRoleDropDownList =array.sort(function(a, b) {

            if (a.designation < b.designation) return -1;
            if (a.designation > b.designation) return 1;
            return 0;
        })

        const options = SortedRoleDropDownList.map((item) => {
            return { value: item.id, label: item.designation };
        });
        setRoleListForDropDown(options)
        setSpinner(false)
    } */

  const createAppUserOption = () => {
    var array1 = appUserList;
    const options1 = array1.map((item, index) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

    setAppUserDD(options1);
  };

  const setAppUserOptions = (inputValue) => {
    if (appUserDD) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(appUserDD, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const createGroupsOption = () => {
    var array1 = groupList;
    const options1 = array1.map((item, index) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

    setGroupDD(options1);
  };

  const setGroupsOptions = (inputValue) => {
    if (groupDD) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(groupDD, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const createFacilityOption = () => {
    var array1 = facilityList;
    const options1 = array1.map((item, index) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

    setFacilityDD(options1);
  };

  const setFacilityOptions = (inputValue) => {
    if (facilityDD) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(facilityDD, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  // const createStatusOption = () => {

  //         var array1 = statusList
  //         const options1 = array1.map((item, index) => {
  //             return {
  //                 value: item.displayCode,
  //                 label: item.displayName
  //             };
  //         });

  //         setStatusDD(options1);

  // };


  const createStatusOption = (inputValue) => {
    // eslint-disable-next-line eqeqeq
    if (inputValue != undefined) {
      if (inputValue.length >= 4) {
        return AxiosInter.get(
          `${API_ENDPOINT}/admin-api/fetch-fax-status-list/${inputValue}`,
          {
            headers: {
              Authorization: API_TOKEN,
            },
          }
        )
          .then((response) => response.data.data)
          .catch((error) => {
            throw error;
          });
      }
    }
  };


  const createDocNameOption = (inputValue) => {
    // eslint-disable-next-line eqeqeq
    if (inputValue != undefined) {
      if (inputValue.length >= 4) {
        const searchParams = new URLSearchParams ({name: inputValue});
        return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-fax-Doc-list/?${searchParams.toString()}`,
          {
            headers: {
              Authorization: API_TOKEN,
            },
          }
        )
          .then((response) => response.data.data)
          .catch((error) => {
            throw error;
          });
      }
    }
  };

  // EFFECTS FOR CORPORATE / GROUP

  useEffect(() => {
    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (appUserList && groupList && facilityList && statusList) {
      createAppUserOption();
      createGroupsOption();
      createFacilityOption();
      createStatusOption();
    }
  }, [appUserList, groupList, facilityList, statusList]); // eslint-disable-line

  return (
    <AvForm>
      <AvGroup>
        {spinner ? <Spinner /> : ""}
        <Row>
          <Col sx={1.5}>
            <Label>App Users</Label>

            <AsyncSelect
              cacheOptions
              isClearable
              isSearchable
              value={appUserName}
              placeholder={"Type the app users name "}
              onChange={(event) => {
                setAppUserName(event);
              }}
              loadOptions={(inputValue) => setAppUserOptions(inputValue)}
            />
          </Col>
          {/*  <Col sx={1.5} >
                        <Label>Patient</Label>
                        <AvField
                            type="text"
                            appUserName="groupID"
                            placeholder="User Name"
                            value={groupID}
                            validate={{
                                maxLength: {value: 120}
                              }} 
                            onChange={(e) => {setGroupID(e.target.value)}}
                        />
                    </Col> */}
          {/* <Col sx={1.5}>
                        <Label>Physician</Label>
                        <AvField
                            type="text"
                            appUserName="patient"
                            placeholder="Email"
                            value={patient}
                            validate={{
                                maxLength: {value: 254}
                              }} 
                            onChange={(e) => {setEmail(e.target.value)}}
                        />
                    </Col> */}
          <Col sx={1.5}>
            <Label>Group</Label>

            <AsyncSelect
              cacheOptions
              isClearable
              isSearchable
              value={groupID}
              placeholder={"Type the group name "}
              onChange={(event) => {
                setGroupID(event);
              }}
              loadOptions={(inputValue) => setGroupsOptions(inputValue)}
            />
          </Col>
          <Col sx={1.5}>
            <Label>Facility</Label>

            <AsyncSelect
              cacheOptions
              isClearable
              isSearchable
              value={facility}
              placeholder={"Type the facility name "}
              onChange={(event) => {
                setFacility(event);
              }}
              loadOptions={(inputValue) => setFacilityOptions(inputValue)}
            />
          </Col>
          <Col sx={1.5}>
            <Label>Document Name</Label>
            <AsyncSelect
              cacheOptions
              isClearable
              isSearchable
              value={docName}
              placeholder={"Type Document Name "}
              onChange={(event) => {
                setDoctName(event);
              }}
              loadOptions={(inputValue) => createDocNameOption(inputValue)}
            />
          </Col>
          <Col sx={1.5}>
            <Label>Status</Label>
            <AsyncSelect
              cacheOptions
              isClearable
              isSearchable
              value={status}
              placeholder={"Type Status Name "}
              onChange={(event) => {
                setStatus(event);
              }}
              loadOptions={(inputValue) => createStatusOption(inputValue)}
            />
          </Col>
          <Col xs={0.4}>
            <Button
              type="submit"
              className="go-buttons"
              disabled={spinner ? true : false}
              onClick={() => {
                runSearch();
              }}
            >
              Go
            </Button>
          </Col>
          <Col xs={1.5}>
            <Button
              type="reset"
              className="reset-buttons"
              disabled={spinner ? true : false}
              onClick={() => {
                resetFields();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
        <Row></Row>
      </AvGroup>
    </AvForm>
  );
};

export default FaxLogFilters;
