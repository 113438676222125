import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Breadcrumb, BreadcrumbItem, Spinner, Button, Row, Col, Card, CardHeader, Label } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { GlobalState } from '../../contextAPI/Context'
import EditDrgModal from "../../newComponents/utilities/EditDRGModal"
import NewDrgModal from "../../newComponents/utilities/NewDRGModal"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";// eslint-disable-line
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RemotePagination from '../../newComponents/utilities/RemotePagination'
import SearchBarComponent from '../../newComponents/utilities/SearchBarComponent'
import DeleteConfirmationModal from '../../newComponents/utilities/DeleteConfirmationModal'
import Select from 'react-select';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import MomentConfig from '../../common/MomentConfig';
import moment from 'moment';


const DRG = (props) => {
  // CONTEXT VARIABLES 
  const {spinner,setSpinner} = useContext(GlobalState);

  // LOCAL VARIABLES
  const [data, setData] = useState([]);// eslint-disable-line
  const [reload, setReload] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [isPagination, setIsPagination] = useState(1); // eslint-disable-line
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("")
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [drgDetails, setDRGDetails] = useState("")
  const [isSearch, setIsSearch] = useState(false);// eslint-disable-line
  const [search, setSearch] = useState(false);
  const [drgID, setDrgID] = useState("")
  const [newdrgModal, setNewDrgModal] = useState(false);
  const [editDrgModal, setEditDrgModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const fileExportName = 'DRG'
  const [selectedMdc, setSelectedMdc] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [mdcCodeList, setMdcCodeList] = useState([]);
  const [mdcOptions, setMdcOptions] = useState([]);
  const [editDrgCode, setEditDrgCode] = useState("");
  const [editMdc, setEditMdc] = useState("");
  const [editType, setEditType] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editWeight, setEditWeight] = useState("");
  const [editGlos, setEditGlos] = useState("");
  const [editAlos, setEditAlos] = useState("");
  const [sortColumn,setSortColumn] =  useState("");
  const [sortOrder,setSortOrder] =  useState("");
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const fetchDRG = () => {
    setSpinner(true);
    var temp=''
    if (sortColumn==='code'){
        temp = 'drg.code'
      
    }
    else if (sortColumn==='MDCCode'){
      temp = 'mdc.code'
    
  }
    else {
      temp= sortColumn
    }
    const params =
    {
      "mdc_code": selectedMdc ? selectedMdc.label : "",
      "drg_type": selectedType ? selectedType.label : "",
      "is_pagination": isPagination ? isPagination : "",
      "record_limit": sizePerPage ? sizePerPage : "",
      "offset": offset,
      "search_keyword": searchKeyword ? searchKeyword : "",
      "sortOrder":sortOrder ? sortOrder : "",
      "sortColumn":temp ? temp:""

    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-drg-details/`, params, {
      headers: {
        Authorization: API_TOKEN,
      }
    }
    ).then(response => {
      if (response.data.data.length > 0) {
        setDRGDetails(response.data.data);
        setTotalSize(response.data.record_count)
        setSpinner(false);
        localStorage.setItem('offset', offset);

      }
      else {
        setTotalSize(0);
        setDRGDetails("")
        setSpinner(false);

      }
    })
      .catch(error => {
        toastr.error("Error", "Something went wrong")
        setSpinner(false);
      });
  };
  const exportTableData = () => {
    var temp=''
    if (sortColumn==='code'){
        temp = 'drg.code'
      
    }
    else if (sortColumn==='MDCCode'){
      temp = 'mdc.code'
    
  }
    else {
      temp= sortColumn
    }
    const params =
    {
      "file_name": fileName ? fileName : "",
      "mdc_code": selectedMdc ? selectedMdc.label : "",
      "drg_type": selectedType ? selectedType.label : "",
      "is_pagination":"1",
      "record_limit": "",
      "offset": offset,
      "search_keyword": searchKeyword ? searchKeyword : "",
      "sortOrder":sortOrder ? sortOrder : "",
      "sortColumn":temp ? temp:""
      

    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-drg-table-data/`,params,{
        headers: {
           
          Authorization: API_TOKEN,

        },
        responseType: "blob"
      })
        .then((response) => { 
          if (response.status === 200) {
            var fileDownload = require("js-file-download");
            fileDownload(
              response.data ,`${fileName}`,
              response["headers"]["x-suggested-filename"]
            );
            toastr.success(
              "Success",
              "Export successful"
            );
          
          }
          
        })
        .catch((error) => {
        
            toastr.error(
                "Failed",
                "Something went wrong"
              );
        });
    };
  const deleteDrgDetails = () => {
    setSpinner(true);
    const params =
    {
      "drg_id": drgID ? drgID : ""

    }


    AxiosInter.post(`${API_ENDPOINT}/admin-api/delete-drg-details/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        toastr.success("Success", "Deleted successfully")
        setSpinner(false);
        setDeleteModal(false);
        setReload(reload + 1)
      })
      // .catch(error => {
      //   toastr.error("Error", "Something went wrong")
      //   setSpinner(false);
      // });
  };

  const fetchMdc = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-mdc-code/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    }
    ).then((res) => {
      setMdcCodeList(res.data.data);
    });
  }
  const setMdcDropDown = (data) => {
    var array = data;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.code
      };
    });
    setMdcOptions(options);
  };
  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }
  const actionFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faEdit}
              color={'#3f86fa'}
              style={{ 'cursor': 'pointer' }}
              onClick={() => {
                setEditDrgModal(true)
                setDrgID(row.id)
                setEditDrgCode(row.code)
                setEditMdc(row.MDCCode)
                setEditType(row.type)
                setEditDescription(row.description)
                setEditWeight(row.weight)
                setEditGlos(row.glos)
                setEditAlos(row.alos)



              }}

              title="Edit"

            />

          </Col>
          {/* <Col sm={2}>
              <FontAwesomeIcon
                icon={faTrash}
                color = {'#E12C2C'}
                style = {{'cursor':'pointer'}}
                onClick={() => {
                   setDrgID(row.id)
                   setDeleteModal(true)
   
                 }}
              
                title = "Delete"

              />
          </Col> */}
        </Row>
      </>
    )
  }

  const clearValues = () => {
    setSelectedMdc("")
    setSelectedType("")
    setData("")
    setReload(reload + 1)
  }
  const drgTypeOptions = [
    { value: '1', label: "SURG" },
    { value: '2', label: "MED" }
  ]
  const tableColumns = [
    {
      dataField: "code",
      text: "DRG Code",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "MDCCode",
      text: "MDC",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },

    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    }

  ];
  const handleTableChange = (type, newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
    setOffset((newState.page - 1) * newState.sizePerPage)

    if(newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("")
      setSortOrder("")
    }
    else {
      setSortColumn(newState.sortField)
      setSortOrder(newState.sortOrder)
      setReload(reload+1)
    }

  }
  const searchButtonValidation = () => {
    if (selectedMdc || selectedType) {
      setSearch(true)
    } else {
      setSearch(false)
    }
  }
  const clearSearchValues = () => {
    setSearchKeyword("");
    setReload(reload + 1)

  }
  useEffect(() => {if (searchKeyword.length >= 2) {setIsGlobalSearch(true);}}, [searchKeyword]) // eslint-disable-line
  useEffect(() => {if (searchKeyword.length === 0) {setReload(reload + 1)}}, [searchKeyword]) // eslint-disable-line
  useEffect(() => {if (isGlobalSearch) { setOffset(0); setTotalSize(0);fetchDRG();setIsGlobalSearch(false);}}, [isGlobalSearch]) // eslint-disable-line
  useEffect(() => {if (reload) {fetchDRG();fetchMdc();}}, [reload]) // eslint-disable-line
  useEffect(() => { if (mdcCodeList) setMdcDropDown(mdcCodeList); }, [mdcCodeList])
  // useEffect(() => {fetchDRG();fetchMdc();}, []) // eslint-disable-line
  useEffect(() => {searchButtonValidation();}, [selectedMdc, selectedType])// eslint-disable-line
  
  useEffect(() => {
    var previousOffset = localStorage.getItem('offset')
    if (offset !== previousOffset || sizePerPage) {
      setReload(reload + 1)
    }
  }, [offset, sizePerPage]) // eslint-disable-line

  useEffect(() => {   
    window.scrollTo(0, 0);   
     }, []);
     
  // CheckUserInactivity()

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            DRG


            <Button className="Add-btn pull-right"
              onClick={() => {
                setNewDrgModal(!newdrgModal)

              }}
            >
              Add New DRG
            </Button>
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>DRG</BreadcrumbItem>
          </Breadcrumb>

        </Header>
      </Container>



      <Card>
        <CardHeader>

          <Row>
            <Col sm="2" md="2" lg="2">
              <Label>MDC</Label>
              <Select
                name="MDC"
                options={mdcOptions}
                placeholder="MDC"
                isClearable
                value={selectedMdc}
                onChange={(e) => {

                  setSelectedMdc(e)
                }}
              />
            </Col>
            <Col sm="2" md="2" lg="2">
              <Label>Type</Label>
              <Select
                name="type"
                options={drgTypeOptions}
                placeholder="Choose Type"
                isClearable
                value={selectedType}
                onChange={(e) => {

                  setSelectedType(e)
                }}
              />
            </Col>


            <Col>
              <Button
                className="patient-go-buttons"
                onClick={() => {
                  setSearchKeyword("")
                  setData("")
                  offset === 0 ? fetchDRG() : setOffset(0)
                  setIsSearch(true);
                  setPage(1);



                }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Choose at least one item to search " : ""}
              >Go</Button> {" "}

              <Button
                className="patient-reset-buttons"
                onClick={() => { clearValues() }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >Reset</Button>

            </Col>

          </Row>

          <div className="separator" />
        </CardHeader>
        <div className='groups-search'>
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={tableColumns}
            clearValues={clearSearchValues}
          />

        </div>

        {spinner ? <Spinner /> : ""}

        <RemotePagination
          data={drgDetails ? drgDetails : ""}
          columns={tableColumns}
          page={page}
          sizePerPage={sizePerPage}
          FileExportName={fileExportName}
          exportTableData = {exportTableData}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            handleTableChange(type, newState);
          }}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}



        />




      </Card>
      <EditDrgModal
        drgID={drgID}
        editDrgModal={editDrgModal}
        setEditDrgModal={setEditDrgModal}
        mdcOptions={mdcOptions}
        drgTypeOptions={drgTypeOptions}
        editDrgCode={editDrgCode}
        setEditDrgCode={setEditDrgCode}
        editMdc={editMdc}
        setEditMdc={setEditMdc}
        editType={editType}
        setEditType={setEditType}
        editDescription={editDescription}
        setEditDescription={setEditDescription}
        editWeight={editWeight}
        setEditWeight={setEditWeight}
        editGlos={editGlos}
        setEditGlos={setEditGlos}
        editAlos={editAlos}
        setEditAlos={setEditAlos}
        reload={reload}
        setReload={setReload}


      />
      <NewDrgModal
        newdrgModal={newdrgModal}
        setNewDrgModal={setNewDrgModal}
        mdcOptions={mdcOptions}
        drgTypeOptions={drgTypeOptions}
        reload={reload}
        setReload={setReload}
      />
      <DeleteConfirmationModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteFunction={deleteDrgDetails}


      />



    </div>
  );

}
export default DRG;