import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import { toastr } from "react-redux-toastr";



const AuthGroupStatusChangeModal = (props) => {
  const API_TOKEN = localStorage.getItem("accessToken")
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;

  const {
    setModalState,
    modalState,
    groupId,
    groupStatus,
    reload,
    setReload
  } = props;
  const updateGroupStatus = () => {
    let service_status = groupStatus;
    if (service_status === true) {
      service_status = false;
    } else {
      service_status = true;
    }
    const params = {
      gid: groupId,
      status: service_status,
    };
    axios
      .post(`${API_ENDPOINT}api/change-group-status/${groupId}`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toastr.success("Success", "Status updated successfully");
          setModalState(false);
          setReload(reload+1);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong!");
      });
  }

  const toggle = () => {
    setModalState(!modalState);
  };
  // useEffect(() => {
  //   if (reload === true) {
  //     window.location.reload();
  //   }
  // }, [reload]);
  return (
    <div>
      <Modal isOpen={modalState} toggle={toggle}>
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Change Status
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            {groupStatus === true
              ? "Do you want to change the group status to inactive?"
              : "Do you want to change the group status to active ?"}
          </div>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            className="yes-button button-width"
            onClick={() => updateGroupStatus()}
          >
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );

}
export default AuthGroupStatusChangeModal;