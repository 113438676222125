import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Breadcrumb, BreadcrumbItem, Spinner, Button, Row, Col, Card, CardHeader } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { GlobalState } from '../../contextAPI/Context'
import EditMicroServiceUser from "../../newComponents/utilities/EditMicroServiceUser"
import AddMicroServiceUser from "../../newComponents/utilities/AddMicroServiceUser"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";// eslint-disable-line
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastr } from 'react-redux-toastr';
import Chip from "@mui/material/Chip";
import RemotePaginationAuth from "./RemotePaginationAuth";
import MicroserviceStatusChangeModal from "../../newComponents/utilities/MicroserviceUserStatusChangeModal";
import axios from "axios";


const MicroServiceUsers = (props) => {
  // CONTEXT VARIABLES 
  const { spinner, setSpinner } = useContext(GlobalState);

  // LOCAL VARIABLES

  const [reload, setReload] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("")
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [userDetails, setUserDetails] = useState("")
  const [userID, setUserID] = useState("")

  const [newUserModal, setNewUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [groupNameOptions, setGroupNameOptions] = useState([]);
  const [editName, setEditName] = useState("");
  const [editGroup, setEditGroup] = useState([]);
  const [editEmail, setEditEmail] = useState("");
  const [sortColumn, setSortColumn] = useState("");// eslint-disable-line
  const [sortOrder, setSortOrder] = useState("");// eslint-disable-line
  const [statusModal,setStatusModal] = useState(false);
  const [status, setStatus] = useState("");


  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;
  const API_TOKEN = localStorage.getItem("accessToken");

  const fetchUsersList = () => {

    setSpinner(true);

    axios.get(`${API_ENDPOINT}api/get-user-list/`, {
      headers: {
        Authorization: API_TOKEN,
      }
    }
    ).then(response => {
      if (response.data.data.length > 0) {
        setUserDetails(response.data.data);
        setTotalSize(response.data.record_count)
        setSpinner(false);
        localStorage.setItem('offset', offset);

      }
      else {
        setTotalSize(0);
        setUserDetails("")
        setSpinner(false);

      }
    })
      .catch(error => {
        toastr.error("Error", "Something went wrong")
        setSpinner(false);
      });
  };


  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }
  const actionFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faEdit}
              color={'#3f86fa'}
              style={{ 'cursor': 'pointer' }}
              onClick={() => {
                setEditUserModal(true);
                setUserID(row.id);
                setEditName(row.name);
                setEditEmail(row.email);
                setEditGroup(row.group_names);
                // fetchGroupNameOptions();


              }}

              title="Edit"

            />

          </Col>

        </Row>
      </>
    )
  }
  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    }
    else if (cell === 0) {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }
    else {
      status = "Locked";
      badgeclass = "badge badge-warning statustoggle status-button-width";

    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setUserID(row.id);
          setStatus(cell);
          setStatusModal(true)
        }}
      >
        {status}
      </span>
    );
  };
  const formatter = (cell) => {
    var valuesForDD = [];
    var cellData = cell.split(",");

    cellData.map((item) => { // eslint-disable-line
      if (item) {
        valuesForDD.push(item);
      }
    });

    return (
      <>
        {valuesForDD.map((item) => (
          <>
            <Chip color="primary" variant="outlined" label={item} />{" "}
          </>
        ))}
      </>
    );
  };


  const tableColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "group_names",
      text: "Groups",
      sort: true,
      formatter: formatter,
      csvFormatter: basicValidation,

    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      csvFormatter: basicValidation,

    },
    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    }

  ];
  const handleTableChange = (type, newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
    setOffset((newState.page - 1) * newState.sizePerPage)

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("")
      setSortOrder("")
    }
    else {
      setSortColumn(newState.sortField)
      setSortOrder(newState.sortOrder)
      setReload(reload + 1)
    }

  }
  const fetchGroupNameOptions = () => {

    axios.get(`${API_ENDPOINT}api/get-group-name-options/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => {
        if (response.status === 200) {

          setGroupNameOptions(response.data.data)

        }

      })
      .catch((error) => {
        console.log(error)
      });
  }
  const confirmation = () => {
    let newStatus = "";
    if (status === 0) {
      newStatus = 1
    } else {
      newStatus = 0
    }

    const updatedStatus = {
      user_id: userID,
      is_active: newStatus,
    };

    axios.post( `${API_ENDPOINT}api/microservice-user-status-update/`, updatedStatus,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
        if (res.status === 201) {
          setReload(reload+1)
          setStatusModal(!statusModal);
          toastr.success('Status Updated', 'User status updated successfully');
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => { if (searchKeyword.length >= 2) { setIsGlobalSearch(true); } }, [searchKeyword]) // eslint-disable-line
  useEffect(() => { if (searchKeyword.length === 0) { setReload(reload + 1) } }, [searchKeyword]) // eslint-disable-line
  useEffect(() => { if (isGlobalSearch) { setOffset(0); setTotalSize(0); fetchUsersList(); setIsGlobalSearch(false); } }, [isGlobalSearch]) // eslint-disable-line
  useEffect(() => { if (reload) { fetchGroupNameOptions(); fetchUsersList(); } }, [reload]) // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem('offset')
    if (offset !== previousOffset || sizePerPage) {
      setReload(reload + 1)
    }
  }, [offset, sizePerPage]) // eslint-disable-line

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Users


            <Button className="Add-btn pull-right"
              onClick={() => {
                setNewUserModal(!newUserModal);
                // fetchGroupNameOptions();

              }}
            >
              Add New Users
            </Button>
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Users</BreadcrumbItem>
          </Breadcrumb>

        </Header>
      </Container>



      <Card>
        <CardHeader>


        </CardHeader>


        {spinner ? <Spinner /> : ""}

        <RemotePaginationAuth
          data={userDetails ? userDetails : ""}
          columns={tableColumns}
          page={page}
          sizePerPage={sizePerPage}
          // FileExportName={fileExportName}
          // exportTableData = {exportTableData}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            handleTableChange(type, newState);
          }}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}

        />

      </Card>
      <EditMicroServiceUser
        userID={userID}
        editUserModal={editUserModal}
        setEditUserModal={setEditUserModal}
        editName={editName}
        setEditName={setEditName}
        editEmail={editEmail}
        setEditEmail={setEditEmail}
        editGroup={editGroup}
        setEditGroup={setEditGroup}
        reload={reload}
        setReload={setReload}
        groupNameOptions={groupNameOptions}


      />
      <AddMicroServiceUser
        newUserModal={newUserModal}
        setNewUserModal={setNewUserModal}
        reload={reload}
        setReload={setReload}
        groupNameOptions={groupNameOptions}
      />
      <MicroserviceStatusChangeModal
        statusModal={statusModal}
        setStatusModal={setStatusModal}
        confirmation={confirmation}
        status={status}
      />
      

    </div>
  );

}
export default MicroServiceUsers;