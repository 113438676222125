import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';






const CPTCodeListModal = (props) => {
    const {
        listingModal,
        setListingModal,
        cptDetailsListing,
    
       
    } = props

    const toggle = () => {
        setListingModal(!listingModal)
    }



    return (
        <div>
            <Modal isOpen={listingModal} size="lg">
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>{cptDetailsListing.length > 0 ? 'Choose CPT Package':""}</ModalHeader>
                <ModalBody className="overflow-modal">
                    {cptDetailsListing.length > 0 ? cptDetailsListing: <h4 style={{'textAlign':'center','marginTop':'50px'}}>No data found</h4>}
                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button outline className="no-button button-width" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default CPTCodeListModal;