import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup,Input,Spinner } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { GlobalState } from '../../contextAPI/Context';
import { toastr } from 'react-redux-toastr';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";


const AddPayerIdForAppUsersModal = (props) => {
    const {
        addPayerIdForAppUsersModal,
        setAddPayerIdForAppUsersModal,
        auId,
        insuranceValue, payerAssignedProviderId,payorIds,setPayorIds, // eslint-disable-line
        userName,
        setInputList,
        inputList
    } = props;

    // ------------------Removing duplicate inputList--------------

    const removeDuplicatesInputList = (arr) => {
        if(arr.length > 1){
            return arr.reduce((acc, current) => {
                const x = acc.find(item => item.payerId === current.payerId && item.stdPayerId === current.stdPayerId);
                if (!x) {
                    return [...acc, current];
                } else {
                    return acc;
                }
            }, []);
        }
        return [
            {
                "payerId": "",
                "stdPayerId": ""
            }
        ]
    };

    useEffect(() => {
        const newData = removeDuplicatesInputList(inputList)
        // Update the state only if newData is different from inputList
        if (newData?.length !== inputList?.length) {
            setInputList(newData);
        }
        populateInsuranceAfter()
    }, [inputList]); // eslint-disable-line
   

    const { appUserReload, setAppUserReload,spinner, setSpinner } = useContext(GlobalState);
    const regXForNames = /^[A-Za-z0-9 ]*$/

    //----------------------------------------------------- LOCAL VARIABLES-----------------------------------------------------//
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    const [selectedInsurances, setSelectedInsurances] = useState([]);// eslint-disable-line
    let stdPayerIdsArray=[] // eslint-disable-line
    const [selectedInsurancesArray, setSelectedInsurancesArray] = useState([]); // eslint-disable-line
    const [selectedPayorId, setSelectedPayorId] = useState([]); // eslint-disable-line
    const [insurance, setInsurance ] =useState([])
    const [demIns, setDemIns] = useState([]);
    const [updateButtonStatus, setUpdateButtonStatus] = useState(true);
    let tmp=[]// eslint-disable-line

    const toggle = () => {
        setAddPayerIdForAppUsersModal(!addPayerIdForAppUsersModal);
        setInputList([]);
        setDemIns([])

    }

    //------------------------------------------------------- UPDATE API-----------------------------------------------------//
    const updatePayerId = () => {
        let payerIdCheck=[],stdPayerIdCheck=[]
        if (inputList!== null && inputList !== ""){
        payerIdCheck=inputList.map(({ payerId }) => payerId)
        stdPayerIdCheck=inputList.map(({ stdPayerId }) => stdPayerId)
    }
        var pIdCheck=payerIdCheck.includes("")
        var stdPIdCheck=stdPayerIdCheck.includes("")
        if (pIdCheck){
            toastr.warning("Payer Id is required")
            // setUpdateButtonStatus(true)
            
        }else if(stdPIdCheck){
            toastr.warning("Select an insurance")
        }
        else{
        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-payor-id/`, {

            "appUser_id": auId,
            "payerAssignedProvider_Id": JSON.stringify(inputList),

        }, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                toastr.success("Successfully Updated", "Payer Id successfully updated")
                setAddPayerIdForAppUsersModal(!addPayerIdForAppUsersModal)
                setAppUserReload(appUserReload + 1)
                setSpinner(false)
            })
            .catch(function (error) {
                toastr.error("Update Failed", "Please try again")
            });
    }
}
    //------------------------------------------------------- Load Options-----------------------------------------------------//

    const loadOptionsForInsurance = (inputValue) => {
        if (inputValue.length >= 4) {
            const searchParams = new URLSearchParams ({name: inputValue});
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-std-payer-list/?${searchParams.toString()}`,
             {
                headers: {
                    Authorization: API_TOKEN,
                }
            })
                .then((response) => response.data.data)
                .catch((error) => {
                    throw error;
                });
        }
    }

    //------------------------------------------------------- Populate Options-----------------------------------------------------//

    const populateInsuranceAfter = async () => {
        setSpinner(true);
          const insuranceData = [];
          try {
            for (let i = 0; i < inputList.length; i++) {
                if (inputList[i].stdPayerId) {
                    const response = await AxiosInter.get(
                        `${API_ENDPOINT}/admin-api/get-standard-payor-list/null/${Number(
                            inputList[i].stdPayerId
                            )}`,
                            {
                                headers: {
                                    Authorization: API_TOKEN,
                                },
                            }
                            );
                            const data = response?.data?.data[0];
                            const item = insuranceData.find(x=> x?.value === data?.value);
                            if (!item) {
                                insuranceData.push(data);
                            }
                }
            }
            setInsurance(insuranceData);
            setSpinner(false);
        } catch (error) {
            setSpinner(false);
            // Handle and log the error appropriately
            console.error("An error occurred:", error);
          }
      };
      
    const disableUpdateButton = () => {          // eslint-disable-line

        
        if (demIns) {
            setUpdateButtonStatus(true)
        }
        else {
            setUpdateButtonStatus(false)
        }
    }
    // useEffect(() => { disableUpdateButton() }, [demIns]) // eslint-disable-line

    //------------------------------------------------------- Handle Input Change-----------------------------------------------------//
    
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    const handleInputChangeForInsurance = (e,index) => {
        const { value } = e;
        const list = [...inputList];
        list[index]['stdPayerId'] = JSON.stringify(value);
        setInputList(list);
        setDemIns(e)
        stdPayerIdsArray = list.map(({ stdPayerId }) => stdPayerId);
        populateInsuranceAfter()
        setUpdateButtonStatus(false)
    };
 
    //------------------------------------------------------- Handle Add & Remove-----------------------------------------------------//
    
    const handleAddClick = () => {
        
        let payerIdCheck = [], stdPayerIdCheck = []
        let newfield = { payerId: '', stdPayerId: '' }
        var pIdCheck = payerIdCheck.includes("")
        var stdPIdCheck = stdPayerIdCheck.includes("")

        if (pIdCheck) {
            toastr.warning("Payer Id is required")
        }else if (stdPIdCheck) {
            toastr.warning("Select an insurance")
        }else {
            setInputList([...inputList, newfield]);
        }
    };
    
    const handleRemoveClick = index => {
        setInputList([]);
        const list = [...inputList];
        list.splice(index, 1);
        setTimeout(() => {
            setInputList(list);
        }, 0);
        setUpdateButtonStatus(false)
    };

    const getInsuranceValue = i => {
        return insurance[i];
    }    
        
    //------------------------------------------------------- Return Statement-------------------------------------------------------//
    return (
        <div>
            <Modal isOpen={addPayerIdForAppUsersModal} size="md" >
                <ModalHeader className="custom-modal-footer" tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Update Payer Id for: <h6>{userName}</h6>  </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col>
                                {inputList ? inputList.map((x, i) => {

                                    return (
                                        <div className="">
                                            <Row key={i}>
                                                <Col sm={6}>
                                                    <AsyncSelect key={i}
                                                        isSearchable
                                                        name="stdPayerId"
                                                        placeholder="Select Insurance"
                                                        loadOptions={loadOptionsForInsurance}
                                                        value={getInsuranceValue(i)}
                                                        onChange={e => handleInputChangeForInsurance(e, i)}
                                                        getOptionLabel={e => e.label}
                                                        getOptionValue={e => e.value}
                                                    />
                                                </Col>
                                                <Col sm={4}>
                                                    <Input key={i}
                                                        className=""
                                                        name="payerId"
                                                        placeholder="payer id"
                                                        value={x.payerId}
                                                        onChange={e => {
                                                            if (regXForNames.test(e.target.value) === false) {
                                                                toastr.warning("Invalid Entry", "Special characters are not allowed")
                                                            } else if (e.target.value) {

                                                                setUpdateButtonStatus(false)
                                                                handleInputChange(e, i)

                                                            } else {
                                                                handleInputChange(e, i)
                                                            }
                                                        }
                                                        }
                                                    />
                                                </Col>
                                                <Col>
                                                    <div style={{ 'display': 'flex', marginBottom: "25px" }}>
                                                        <Col xs={2}>
                                                            {inputList.length !== 1 ?

                                                                <FontAwesomeIcon
                                                                    icon={faTrash}
                                                                    color={'#E12C2C'}
                                                                    size={'1x'}
                                                                    style={{ 'cursor': 'pointer' }}
                                                                    onClick={() => {
                                                                        handleRemoveClick(i)
                                                                    }}

                                                                    title="Remove"

                                                                /> : null}
                                                        </Col>
                                                        <Col xs={2}>

                                                            {inputList.length - 1 === i ?
                                                                <FontAwesomeIcon
                                                                    icon={faPlus}
                                                                    size={'1x'}
                                                                    color={'#3f86fa'}
                                                                    style={{ 'cursor': 'pointer' }}
                                                                    onClick={() => {
                                                                        handleAddClick()
                                                                    }}
                                                                    title="Add"
                                                                /> : null}
                                                        </Col>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                }) :

                                    handleAddClick()

                                }
                                <br></br>
                            </Col>
                        </Row>
                    </FormGroup>
                </ModalBody>
                {spinner ? <Spinner /> : ""}
                <ModalFooter className="advanced-search-button custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={updatePayerId}
                        disabled={updateButtonStatus}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default AddPayerIdForAppUsersModal;
