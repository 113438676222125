import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";


const DeleteConfirmationModal = (props) => {
  const {

    deleteModal,
    setDeleteModal,
    deleteFunction

  } = props;


  const toggle = () => {
    setDeleteModal(!deleteModal);
  };




 

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={deleteModal}>
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Delete Confirmation</ModalHeader>
        <ModalBody>
            <div style={{'textAlign':'center'}}>
              Are you sure you want to delete?
            </div>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button 
          className="yes-button button-width" 
          onClick={()=>{
            deleteFunction()
          }}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteConfirmationModal;
