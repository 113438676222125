/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Alert,
  CardBody,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import AxiosInter from './../../AxiosInter.js';
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Brightness1RoundedIcon from "@mui/icons-material/Brightness1Rounded";
import EditPermissionManagerModal from "../../newComponents/utilities/EditPermissionManagerModal";
import AddNewModuleForRoleModal from "../../newComponents/utilities/AddNewModuleForRoleModal";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chip from "@mui/material/Chip";
import Cookie from 'js-cookie';
import DeleteConfirmationModal from "../../newComponents/utilities/DeleteConfirmationModal.js";
import { toastr } from "react-redux-toastr";

const PermissionManagerDataTable = () => {
  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [spinner, setSpinner] = useState(false);

  const [apiLoaded, setApiLoaded] = useState(false);
  const [roleList, setRoleList] = useState("");
  const [moduleList, setModuleList] = useState("");

  const [rowData, setRowData] = useState("");
  const [editModal, setEditModal] = useState(false);

  const [multiSpecialityLength, setMultiSpecialityLength] = useState("");

  const [APIData, setAPIData] = useState("");

  const [selectedRole, setSelectedRole] = useState("Physician");

  const [loopRoleID, setLoopRoleID] = useState("");

  const [OptionsOfMPermission, setOptionsOfMPermission] = useState("");
  const [reload, setReload] = useState(1);

  const [selected, setSelected] = useState(false); // eslint-disable-line

  const [selectedIndex, setSelectedIndex] = useState(0); // eslint-disable-line

  const [addModule, setAddModule] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);



  const getRoleList = () => {
    setSpinner(true);
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/get-m-role-list/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((res) => {
        setRoleList(res.data.data);
        setSpinner(false);
      })
      .catch((error) => {
        // console.log(error);
        setSpinner(false);
      });
  };

  const getModuleList = () => {
    setSpinner(true);
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/get-m-modules/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((res) => {
        var array = [];
        // eslint-disable-next-line array-callback-return
        res.data.data.map((item1) => {
          // eslint-disable-line
          array.push({
            value: item1.id,
            label: item1.name,
          });
        });
        setModuleList(array);
        setSpinner(false);
      })
      .catch((error) => {
        // console.log(error);
        // setSpinner(false);
      });
  };

  const getM_Permission = () => {
    setSpinner(true);
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/get-m-permission/`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((res) => {
        var array = [];
        // eslint-disable-next-line array-callback-return
        res.data.data.map((item1) => {
          // eslint-disable-line
          array.push({
            value: item1.id,
            label: item1.displayName,
          });
        });
        setOptionsOfMPermission(array);
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
      });
  };

  const FetchPermissionData = () => {
    setSpinner(true);
    setApiLoaded(false);
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/role-module-user-permissions/`,
        {
          RoleID: loopRoleID ? loopRoleID.toString() : "1",
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        if (response.data) {
          setAPIData(response.data);
          setApiLoaded(true);
          setSpinner(false);
          localStorage.removeItem("subscriptionFilter");
          localStorage.removeItem("groupNameFilter");
          localStorage.removeItem("statusFilter");
          localStorage.removeItem("adminNameFilter");
          localStorage.removeItem("zipCodeFilter");
          localStorage.removeItem("timeZoneFilter");
        } else {
          setAPIData("");
          setApiLoaded(true);
          setSpinner(false);
        }
      })
      // .catch(function (error) {
      //   setApiLoaded(true);
      //   setSpinner(false);
      // });
  };

  const handleListItemClick = (event, index) => {
    setSelectedRole(event.currentTarget.innerText);
    setSelectedIndex(index);
  };

  const GenerateRoleList = (item, index) => {
    return (
      <>
        <ListItemButton
          onClick={(event) => handleListItemClick(event, index)}
          /* selected={selectedIndex === index} */
        >
          <div className="css-cveggr-MuiListItemIcon-root ">
            <ListItemIcon>
              <Brightness1RoundedIcon
                className="css-ptiqhd-MuiSvgIcon-root"
                fontSize="small"
              />
            </ListItemIcon>
          </div>
          <ListItemText>{item}</ListItemText>
        </ListItemButton>
        <Divider />
      </>
    );
  };

  const ListOfRole = () => {
    let multiSpecialityLengthh = roleList.map((item, index) => {
      return GenerateRoleList(item.designation, index);
    });
    setMultiSpecialityLength(multiSpecialityLengthh);
  };

  const LoopForRoleID = () => {
    if (roleList && selectedRole) {
      // eslint-disable-next-line no-unused-vars, array-callback-return
      const opt4 = roleList.map((item) => {
        // eslint-disable-line
        if (selectedRole === item.designation) {
          setLoopRoleID(item.id.toString());
        }
      });
    }
  };

  const formatter = (cell) => {
    var valuesForDD = [];
    var cellData = cell.split(",");
    // eslint-disable-next-line array-callback-return
    cellData.map((item) => {
      // eslint-disable-line
      // debugger
      // eslint-disable-next-line eqeqeq
      if (item == 1) {
        // eslint-disable-line
        valuesForDD.push("View");
      // eslint-disable-next-line eqeqeq
      } else if (item == 2) {
        // eslint-disable-line
        valuesForDD.push("Create/Edit");
      // eslint-disable-next-line eqeqeq
      } else if (item == 3) {
        // eslint-disable-line
        valuesForDD.push("Delete");
      // eslint-disable-next-line eqeqeq
      } else if (item == 4) {
        // eslint-disable-line

        valuesForDD.push("Import/Export");
      }
    });
    // console.log(valuesForDD);
    /* var edited = valuesForDD.replace(/^,|,$/g,''); */

    return (
      <>
        {valuesForDD.map((item) => (
          <>
            <Chip style={{marginBottom:14}} label={item} />{" "}
          </>
        ))}
      </>
    );
  };

  const actionFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setRowData(row);
            setEditModal(true);
          }}
          color="primary"
          title="Edit Medication"
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            className="align-middle"
            title="Edit Medication"
          />
        </Button>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setRowData(row);
            setDeleteModal(true)
          }}
          color="primary"
          title="Delete Medication"
        >
          <FontAwesomeIcon
            icon={faTrash}
            fixedWidth
            className="align-middle"
            title="Delete Medication"
          />
        </Button>
      </>
    );
  };

  const handleDeletePermission = () => {
    setSpinner(true);
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/delete-role-module-user-permissions/`,
        {
          "role_id": rowData.roleID + "",
          "module_id": rowData.moduleID + ""
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        if (response.data) {
          setSpinner(false);
          setDeleteModal(false);
          // after delete perticular row in backend 
          // updating state using setAPIData & filter method so refresh is not needed to show changes
          setAPIData(APIData.filter(item=> item.moduleID !== rowData.moduleID))
        }
      })
      .catch(err => {
        setSpinner(false);
        setDeleteModal(false);
        toastr.error('Something went to wrong!');
      })
  }

  const tableColumns = [
    {
      dataField: "moduleName",
      text: "Module",
      sort: true,
    },
    {
      dataField: "permissionIDs",
      text: "Permissions",
      formatter: formatter,
    },
    {
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const blankState = (
    <div>
      <div className="separator" />
      <Card className="blank-state">
        <CardBody>
          <h3>No Module Configured For This Role</h3>
        </CardBody>
      </Card>
    </div>
  );

  const SelectedOnce = () => {
    // eslint-disable-next-line array-callback-return
    let select = roleList.map((item) => {
      // eslint-disable-line
      // eslint-disable-next-line eqeqeq
      if (item.designation == selectedRole) {
        // eslint-disable-line
        return true;
      }
    });
    setSelected(select);
  };

  useEffect(() => {
    if (!OptionsOfMPermission) getM_Permission();
  }, [OptionsOfMPermission]); // eslint-disable-line

  useEffect(() => {
    FetchPermissionData();
  }, [loopRoleID, reload]); // eslint-disable-line

  useEffect(() => {
    if (!roleList) getRoleList();
  }, [roleList]); // eslint-disable-line

  useEffect(() => {
    if (!moduleList) getModuleList();
  }, [moduleList]); // eslint-disable-line

  useEffect(() => {
    if (roleList) ListOfRole();
  }, [roleList]); // eslint-disable-line

  useEffect(() => {
    if (selectedRole) LoopForRoleID();
  }, [selectedRole]); // eslint-disable-line

  useEffect(() => {
    if (roleList) SelectedOnce();
  }, [selectedRole]); // eslint-disable-line

  return (
    <div>
      <Hux>
        <Container fluid>
          <Header>
            <HeaderTitle>Permission Manager</HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Permission Manager</BreadcrumbItem>
            </Breadcrumb>
            <Button
              className="Add-btn-permission-manager pull-right top-0 right-0"
              onClick={() => {
                setAddModule(true);
              }}
            >
              Add Module
            </Button>
          </Header>
        </Container>
      </Hux>

      <Row>
        <Col xs={3}>
          <Card className="roles-list-card otp-card width-for-sml ht-560">
            <CardBody>
              <div>{spinner ? <Spinner color="primary" /> : ""}</div>
              {APIData && apiLoaded ? (
                <>
                  <br />
                  <Row>
                    <Col>
                      <div>
                        {APIData && apiLoaded ? (
                          <>
                            <CardTitle
                              tag="h4"
                              style={{ "modal-title": "w-100 text-center" }}
                              className="role-card-header role-head-style"
                            >
                              <Alert className="alert-success">
                                <div className="heading-div">
                                  <h4>Role's</h4>
                                </div>
                              </Alert>
                            </CardTitle>
                            <div className="separator" />
                            <Box
                              sx={{
                                width: "100%",
                                maxWidth: 360,
                                bgcolor: "background.paper",
                                borderRadius: "10px",
                              }}
                            >
                              <List component="nav">
                                {multiSpecialityLength}
                              </List>
                            </Box>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {!APIData && apiLoaded ? (
                        <Card>
                          {" "}
                          <h4 className="no-data-found-card">
                            No Data Found
                          </h4>{" "}
                        </Card>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>
        <Col xs={9}>
          <Card className="roles-list-card">
            <CardBody>
              <CardTitle>
                <div className="div-alert">
                  <Alert className="alert-success">
                    <h3>{selectedRole}</h3>
                  </Alert>
                </div>

                {APIData.length > 0 ? (
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={APIData}
                    className="table"
                    columns={tableColumns}
                    bordered={false}
                  />
                ) : (
                  blankState
                )}
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <EditPermissionManagerModal
        reload={reload}
        setReload={setReload}
        rowData={rowData}
        editModal={editModal}
        setEditModal={setEditModal}
        OptionsOfMPermission={OptionsOfMPermission}
      />
      <AddNewModuleForRoleModal
        reload={reload}
        setReload={setReload}
        setAddModule={setAddModule}
        addModule={addModule}
        moduleList={moduleList}
        setModuleList={setModuleList}
        roleList={roleList}
        selectedRole={selectedRole}
        OptionsOfMPermission={OptionsOfMPermission}
      />
      <DeleteConfirmationModal 
        deleteModal = {deleteModal}
        setDeleteModal = {setDeleteModal}
        deleteFunction = {handleDeletePermission}
      />
    </div>
  );
};

export default PermissionManagerDataTable;
