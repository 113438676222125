import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Spinner,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import { Select as ReactSelect } from "react-select-virtualized";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { GlobalState } from "../../contextAPI/Context";
import Cookie from 'js-cookie';
import ToggleButton from "react-toggle-button";

const GroupDetailsEditModal = (props) => {
  const { groupNamesReload, setGroupNamesReload } = useContext(GlobalState);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const regXForNumbers = /^[0-9]*$/;

  const {
    setGroupEditModal,
    groupEditModal,
    editReferralFax,
    setEditGroupName,
    editGroupName,
    npi,
    editAddress1,
    editAddress2,
    setEditAddress1,
    setEditAddress2,
    setEditNpi,
    editZipCode,
    editPhoneNumber,
    setEditPhoneNumber,
    editExpiryDate,
    setEditExpiryDate,
    editSubscriptionName,
    subscriptionNames,
    editAdminName,
    setEditAdminName,
    editSupervisorName,
    timeZoneList,
    groupNameList,
    zipCodeList,
    groupId,
    oldFacilityNamesListForAPI,
    setEditReferralFax,
    isPrimary,
    // minDate,
    setSelectedFacilities,
    selectedFacilities,
    setFacilityDetails,
    adminListForGroup,
    editTimeZone,
    setReload,
    reload,
    setFacilities,
    editRegion,
    setEditRegion,
    isWhiteLabelingEnabled,
    setIsWhiteLabelingEnabled,
    setPointOfContactName,
    pointOfContactName,
    setPointOfContactPhone,
    pointOfContactPhone,
    setPointOfContactEmail,
    pointOfContactEmail,
    setSelectedStatusPoint,
    selectedStatusPoint,
    selectedInvoiceDate,
    setSelectedInvoiceDate

  } = props;

  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [region, setRegion] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [selectedZip, setSelectedZip] = useState({});
  const [selectedTimeZone, setSelectedTimeZone] = useState({});
  const [selectedSupervisorName, setSelectedSupervisorName] = useState({});
  const [updateButtonBlankState, setUpdateButtonBlankState] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [facilityIdList, setFacilityIdList] = useState([]);
  const [pointOfContactEmailValid, setPointOfContactEmailValid] = useState(false)
  const isPayingClient = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  const selectInvoiceDates = [
    { value:'30-60 days', label:"30-60 days" },
    { value:'60-90 days', label:"60-90 days" },
    { value:'> 90 days', label:"> 90 days" }
  ]
  const regexForEmail = new RegExp("^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-zA-Z0-9-]+\\.)+[a-zA-Z0-9]{2,}$");

  const regXForNubers = /^[0-9]*$/;
  const changeOnNPIField = (e) => {
    if (regXForNumbers.test(e) === false) {
      toastr.warning("Warning", "Invalid NPI,accepts only numbers");
    } else {
      setEditNpi(e);
    }
  };
  const changeOnFaxField = (e) => {
    if (regXForNumbers.test(e) === false) {
      toastr.warning("Warning", "Invalid fax,accepts only numbers");
      // setEditReferralFax("")
    } else {
      setEditReferralFax(e);
    }
  };

  // const changeOnRegionField = (e) => {
  //   if(regXForNumbers.test(e) === false) {
  //       toastr.warning('Warning',"Invalid fax,accepts only numbers")
  //       // setEditReferralFax("")

  //   } else{
  //     setEditRegion(e)
  //   }
  // }

  const setInitialZipCode = () => {
    zipCodeList.map((data) =>  // eslint-disable-line
     {
    
      if (data.label === editZipCode) {
        setSelectedZip({ value: data.value, label: editZipCode });
      }
    });
  };

  const setCurrentSuperVisorGroup = () => {
    groupNameList.map((data) =>  // eslint-disable-line
     {
    
      if (data.label === editSupervisorName) {
        setSelectedSupervisorName({
          value: data.value,
          label: editSupervisorName,
        });
      }
    });
  };

  const setCurrentSubscriptionPlan = () => {
    subscriptionNames.map((data) =>  // eslint-disable-line
     {
    
      if (data.label === editSubscriptionName) {
        // console.log(data.label)
        setSelectedSubscription({
          value: data.value,
          label: editSubscriptionName,
        });
      }
    });
  };

  // const setCurrentAdminUser = () => {
  //   adminListForGroup.map((data) =>     // eslint-disable-line
  //   {
  
  //     if (data.label === String(editAdminName)) {
  //       setSelectedAdmin({ value: data.value, label: editAdminName });
  //     }
  //   });
  // };
  const minLength = 4;
  const customNoOptionsMessage = ({ inputValue }) => {
   if (inputValue.length < minLength) {
     return "Please enter at least 4 letters to search";
   } else {
     return "No option";
   }
 };
  const setCurrentTimeZone = () => {
    timeZoneList.map((data) =>      // eslint-disable-line
     {

      if (data.label === editTimeZone) {
        setSelectedTimeZone({ value: data.value, label: editTimeZone });
      }
    });
  };

  const toggle = () => {
    setGroupEditModal(!groupEditModal);
    setPointOfContactEmailValid(false)
  };

  const updateGroupDetails = () => {
    if (editPhoneNumber.length > 15) {
      return toastr.error(
        "Failed",
        "Phone number should be less than 15 digits"
      );
    }
    if (npi.length > 15) {
      return toastr.error("Failed", "NPI should be less than 15 digits");
    }
    if (editReferralFax.length > 15) {
      return toastr.error(
        "Failed",
        "Referral fax should be less than 15 digits"
      );
    }
    if (pointOfContactEmail && pointOfContactEmail.length > 1) {
      if (!regexForEmail.test(pointOfContactEmail)) {
        setPointOfContactEmailValid(true);
        return false;
      } else {
        setPointOfContactEmailValid(false);
      }
    }
    

    setSpinner(true);
    const updateParameters = {
      
      
      group_id: groupId ? groupId : "",
      group_name: editGroupName ? editGroupName : "",
      address: editAddress1 ? editAddress1 : "",
      address2: editAddress2 ? editAddress2 : "",
      phone_number: editPhoneNumber ? editPhoneNumber : "",
      npi: npi ? npi : "",
      zipId: selectedZip ? selectedZip.value : "",
      referralFax: editReferralFax ? editReferralFax : "",
      subscription_plan_name: selectedSubscription
        ? selectedSubscription.label
        : "",
      facility_id_list: facilityIdList ? facilityIdList : [],
      old_facility_id_list: oldFacilityNamesListForAPI
        ? oldFacilityNamesListForAPI
        : "",
      supervisor_id: selectedSupervisorName ? selectedSupervisorName.value : "",
      admin_id: editAdminName ? editAdminName.value : "",
      time_zone_id: selectedTimeZone ? selectedTimeZone.value : "",
      expire_time: editExpiryDate
        ? moment(editExpiryDate).format("YYYY-MM-DD")
        : "",
      is_primary: isPrimary ? isPrimary : 0,
      regionID: region ? Number(region.value) : "",
      "isWhiteLabelingEnabled":isWhiteLabelingEnabled ?isWhiteLabelingEnabled :"",
      "point_of_contact_name": pointOfContactName ? pointOfContactName : "",
      "point_of_contact_email": pointOfContactEmail ? pointOfContactEmail : "",
      "point_of_contact_phone": pointOfContactPhone ? pointOfContactPhone : "",
      "paying_client": selectedStatusPoint ? selectedStatusPoint.value : "",
      "invoice_pending":selectedInvoiceDate ? selectedInvoiceDate.value : ""
      // "regionID": region ? region.value :''
      // "regionID":selectedRegion ? selectedRegion.value : ""
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-group-details/`,
        updateParameters,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          toggle();
          setGroupNamesReload(groupNamesReload + 1);
          setReload(reload + 1);
          toastr.success("Success", "Group changes saved");
          setSpinner(false);
          // setApiCallDone(true)
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };

  const GetZipDetails = (e) => {
    setSpinner(true);
    const params = {
      group_zip_code: e ? e.label : "",
    };
    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-zip-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        setSpinner(false);
        if (response.status === 200) {
          if (response.data.data.state === "") {
            setState("");
          }
          if (response.data.data.city === "") {
            setCity("");
          } else {
            setState(response.data.data.state);
            setCity(response.data.data.city);
          }
        }
      })
      .catch((error) => {
        setSpinner(false);
        toastr.error("Error", "Something went wrong !");
      });
  };

  const validate = () => {
    if (
      editGroupName &&
      editAddress1 &&
      selectedSubscription &&
      selectedTimeZone
    ) {
      setUpdateButtonBlankState(false);
    } else {
      setUpdateButtonBlankState(true);
    }
  };

  const fetchRegionById = (editRegion) => {
    AxiosInter
      .get(
        `${API_ENDPOINT}/admin-api/get-m-regionid-list/${Number(editRegion)}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        if (response.data.data.length > 0) {
          setRegion(response.data.data);
        }
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong in region listing !!");
      });
  };

  const loadFacilityOptionsForFacility = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const handleChangeForRegion = (e) => {
    setRegion(e);
  };

  const regionloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(
        `${API_ENDPOINT}/admin-api/get-m-region-list/${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  useEffect(() => {
    // if (groupEditModal && adminListForGroup) {
    //   setCurrentAdminUser();
    // }
    if (groupEditModal && timeZoneList && editTimeZone) {
      setCurrentTimeZone();
    }
    if (groupEditModal && subscriptionNames && editSubscriptionName) {
      setCurrentSubscriptionPlan();
    }
    if (groupEditModal && groupNameList && editSupervisorName) {
      setCurrentSuperVisorGroup();
    }

    if (groupEditModal && zipCodeList && editZipCode) {
      setInitialZipCode();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subscriptionNames,
    adminListForGroup,
    editRegion,
    zipCodeList,
    timeZoneList,
    groupEditModal,
  ]); // eslint-disable-line

  useEffect(() => {
    if (selectedZip && groupEditModal) {
      GetZipDetails(selectedZip);
    }
  }, [groupEditModal, selectedZip]); // eslint-disable-line

  useEffect(() => {
    fetchRegionById(editRegion);
  }, [editRegion]); // eslint-disable-line

  useEffect(() => {
    if (!selectedZip) {
      setState("");
      setCity("");
    }
  }, [selectedZip]); // eslint-disable-line

  // useEffect(() => {
  //   if(!region) {
  //     setRegion("");

  //   }
  // },[region])

  useEffect(() => {
    if (!groupEditModal) {
      setEditGroupName("");
      setSelectedSubscription("");
      setSelectedZip("");
      setSelectedSupervisorName("");
      setEditAdminName("");
      setSelectedTimeZone("");
      setEditExpiryDate("");
      setSelectedFacilities([]);
      setFacilityDetails([]);
      setFacilities([]);
      setEditRegion("");
      setRegion("");
      setIsWhiteLabelingEnabled(false);
      //setSelectedRegion("")// eslint-disable-line
      // setSelectedRegion([])// eslint-disable-line
      //setRegionOptions([])// eslint-disable-line
    }
  }, [groupEditModal]); // eslint-disable-line

  useEffect(() => {
    validate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editGroupName,
    editPhoneNumber,
    editAddress1,
    selectedSubscription,
    selectedTimeZone
  ]); // eslint-disable-line

  useEffect(() => {
    if (selectedFacilities) {
      const array2 = [];
      // eslint-disable-next-line array-callback-return
      selectedFacilities.map((item) => {
        // eslint-disable-line
        array2.push(item.value);
      });
      setFacilityIdList(array2);
    }
  }, [selectedFacilities]); // eslint-disable-line

  /* useEffect(()=>{
    if(timeZoneList.length <= 0 ) {
        getTimeZoneList()
    }
    },[timeZoneList]) // eslint-disable-line */

    
    const changeOnPhoneNumber = (e) => {
      if (regXForNubers.test(e) === false) {
          toastr.warning("Invalid Phone Number", "alphabets are not allowed")
      } else {
        setEditPhoneNumber(e)
      }
  }

  const loadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue, groupID: groupId });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-data/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          }
        })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }

  };
  const changeOnPointOFContactNumber = (e) => {
    if (regXForNubers.test(e) === false) {
        toastr.warning("Invalid point of contact Phone Number", "alphabets are not allowed")
    } else {
      setPointOfContactPhone(e)
      
    }
  }

  return (
    <div>
      <Modal isOpen={groupEditModal} size="lg">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          Edit Group Details
        </ModalHeader>
        <ModalBody className="overflow-modal">
          {spinner ? <Spinner /> : ""}
          <Form>
            <FormGroup>
              <Row>
                <Col sm={6}>
                  <Label for="group_name">
                    Group Name<span className="required">*</span>
                  </Label>
                  <Input
                    id="group_name"
                    // defaultValue={rowData.GroupName}
                    placeholder="Group name"
                    value={editGroupName}
                    onChange={(e) => {
                      setEditGroupName(e.target.value);
                    }}
                    minLength="1"
                    maxLength="120"
                  />
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="phone_number">Phone Number</Label>
                    <Input
                      id="phone_number"
                      type="text"
                      value={editPhoneNumber}
                      placeholder="Enter the Phone Number"
                      onChange={(e) => {
                        if (e.target.value.length >= 13) {
                          toastr.warning("Invalid Number", "number length exceeded")
                        } else {
                          changeOnPhoneNumber(e.target.value);
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Label for="address1">
                    Address 1<span className="required">*</span>{" "}
                  </Label>
                  <Input
                    id="address1"
                    type="textarea"
                    rows={4}
                    cols={2}
                    placeholder="Enter the group address"
                    value={editAddress1}
                    onChange={(e) => {
                      setEditAddress1(e.target.value);
                    }}
                    minLength="1"
                    maxLength="120"
                  />
                </Col>
                <Col sm={6}>
                  <Label for="address2">Address 2</Label>
                  <Input
                    id="address2"
                    type="textarea"
                    rows={4}
                    cols={2}
                    value={editAddress2}
                    placeholder="Enter the group address"
                    onChange={(e) => {
                      setEditAddress2(e.target.value);
                    }}
                    maxLength="50"
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Label for="npi">NPI</Label>
                  <Input
                    id="npi"
                    value={npi}
                    placeholder="Enter the NPI number "
                    onChange={(e) => {
                      if (e.target.value.length >= 11) {
                        toastr.warning(
                          "Invalid Number",
                          "number length exceeded"
                        );
                      } else {
                        changeOnNPIField(e.target.value);
                      }
                    }}
                  />
                </Col>

                <Col sm={6}>
                  <Label for="referral_fax">Referral Fax</Label>
                  <Input
                    id="referral_fax"
                    value={editReferralFax}
                    placeholder="Enter the Referral fax"
                    onChange={(e) => {
                      if (e.target.value.length >= 20) {
                        toastr.warning(
                          "Invalid Number",
                          "number length exceeded"
                        );
                      } else {
                        changeOnFaxField(e.target.value);
                      }
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={3}>
                  <Label for="zip">ZIP</Label>
                  <ReactSelect
                    name="zip"
                    options={zipCodeList}
                    value={selectedZip}
                    placeholder="Choose Zip Code"
                    isSearchable
                    isClearable
                    onChange={(e) => {
                      setSelectedZip(e);
                    }}
                  />
                </Col>
                <Col sm={3}>
                  <Label for="state">State</Label>
                  <Input
                    id="state"
                    type="text"
                    value={state}
                    disabled
                    placeholder="State"
                  />
                </Col>

                <Col sm={3}>
                  <Label for="city">City</Label>
                  <Input
                    id="city"
                    value={city}
                    disabled
                    placeholder="City"
                    maxLength="250"
                  />
                </Col>

                <Col sm={3}>
                  <Label for="Region">
                    Region<span className=""></span>{" "}
                  </Label>
                  <AsyncSelect
                    isClearable
                    isSearchable
                    placeholder="Search Region"
                    noOptionsMessage={customNoOptionsMessage}
                    value={region ? region : ""}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                    onChange={(e) => {
                      handleChangeForRegion(e);
                    }}
                    loadOptions={regionloadOptions}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={6}>
                  <Label for="subscription_plan">
                    Subscription Plan<span className="required">*</span>{" "}
                  </Label>
                  <ReactSelect
                    value={selectedSubscription}
                    name="subscription_plan"
                    options={subscriptionNames}
                    placeholder="Subscription Plan"
                    isClearable={true}
                    onChange={(e) => {
                      setSelectedSubscription(e);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Label for="time_zone">
                    Time Zone<span className="required">*</span>{" "}
                  </Label>
                  <ReactSelect
                    name="time_zone"
                    options={timeZoneList}
                    placeholder="Time Zone"
                    isSearchable
                    isClearable
                    value={selectedTimeZone}
                    onChange={(e) => {
                      setSelectedTimeZone(e);
                    }}
                  />
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col sm={6}>
                  <Label for="supervisor_group">Supervisor Group</Label>
                  <ReactSelect
                    name="supervisor_group"
                    options={groupNameList}
                    placeholder="Supervisor Group"
                    isSearchable
                    isClearable
                    value={selectedSupervisorName}
                    onChange={(e) => {
                      setSelectedSupervisorName(e);
                    }}
                  />
                </Col>
                <Col sm={6}>
                  <Label for="group_admin">Group Admin</Label>
                  <AsyncSelect
                    cacheOptions
                    isClearable
                    value={editAdminName}
                    getOptionLabel={e => e.label}
                    getOptionValue={e => e.value}
                    loadOptions={loadOptions}
                    onChange={(e) => {
                      setEditAdminName(e)
                    }}
                    placeholder="Type Admin name for options"
                  />
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col sm={6}>
                  <Label>Facilities</Label>
                  <AsyncSelect
                    cacheOptions
                    isMulti
                    isSearchable
                    isClearable
                    placeholder="Type facility name for options"
                    noOptionsMessage={customNoOptionsMessage}
                    value={selectedFacilities}
                    onChange={(e) => {
                      setSelectedFacilities(e);
                    }}
                    loadOptions={loadFacilityOptionsForFacility}
                    getOptionLabel={(e) => e.label}
                    getOptionValue={(e) => e.value}
                  />
                </Col>

                <Col sm={6}>
                  <Label for="expiryDate">
                    Expiry Date<span className="required">*</span>
                  </Label>
                  <div className="sign-up-dob-input">
                    <DatePicker
                      dateFormat="dd MMM yyyy"
                      selected={
                        editExpiryDate ? moment(editExpiryDate).toDate() : ""
                      }
                      // minDate={moment(minDate).toDate()}
                      onSelect={(date) => {
                        setEditExpiryDate(date);
                      }}
                      onChange={(date) => {
                        setEditExpiryDate("");
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col sm={6}>
                  <Label for="point_of_contact_name">Point Of Contact Name</Label>
                  <Input
                    id="pont_of_contact_name"
                    placeholder="Enter point of contact name"
                    value={pointOfContactName}
                    onChange={(e) => {
                      setPointOfContactName(e.target.value)
                    }}
                    minLength="1"
                    maxLength="120"
                  />
                </Col>
                <Col sm={6}>
                  <Label for="point_of_contact_phone">Point Of Contact Phone Number</Label>
                  <Input
                    id="point_of_contact_phone"
                    type="text"
                    value={pointOfContactPhone}
                    placeholder="Enter point of contact phone number"
                    onChange={(e) => {
                      if (e.target.value.length >= 13) {
                        toastr.warning("Invalid Number", "number length exceeded")
                      } else {
                        changeOnPointOFContactNumber(e.target.value)
                      }
                    }}
                  />
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col sm={6}>
                  <Label>Point Of Contact Email</Label>
                  <Input
                    id="email"
                    type="email"
                    value={pointOfContactEmail}
                    placeholder="Enter point of contact email"
                    onChange={(e) => {
                      setPointOfContactEmail(e.target.value);
                    }}
                    invalid={pointOfContactEmailValid ? true : false}
                    style={{ margin: "0" }}
                  />
                </Col>
                <Col sm="6">
                  <Label>Paying Client</Label>
                  <ReactSelect
                    name="paying_client"
                    options={isPayingClient}
                    placeholder="Select Paying Client"
                    isClearable
                    value={selectedStatusPoint}
                    onChange={(e) => {
                      setSelectedStatusPoint(e);
                    }}
                  />
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col sm={4}>
                  <Label>Is White Labeling Enabled</Label>
                  </Col>
                  <Col sm={2}>
                  <ToggleButton
                  inactiveLabel={"No"}
                  activeLabel={"Yes"}
                  value={isWhiteLabelingEnabled}
                  onToggle={() => {
                    setIsWhiteLabelingEnabled(!isWhiteLabelingEnabled)
                  }}
                />
                </Col>
                <Col sm="6">
                  <Label>Invoice Pending</Label>
                  <ReactSelect
                    name="invoice_pending"
                    options={selectInvoiceDates}
                    placeholder="Select invoice pending dates"
                    isClearable
                    value={selectedInvoiceDate}
                    onChange={(e) => {
                      setSelectedInvoiceDate(e);
                    }}
                  />
                </Col>
                </Row>
              <br></br>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="advanced-search-button  custom-modal-footer">
          <Button
            onClick={() => {
              updateGroupDetails();
            }}
            disabled={updateButtonBlankState}
            title={
              updateButtonBlankState
                ? "Please fill in all mandatory fields"
                : ""
            }
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default GroupDetailsEditModal;
