import React, { useState, useEffect, useContext } from 'react'; // eslint-disable-line
import { GlobalState } from '../../contextAPI/Context';  // eslint-disable-line
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, FormGroup, Spinner,Input } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { event } from 'jquery'; // eslint-disable-line

const NewProviderSpecialityRankingModal = (props) => {
    const { addModal, setAddModal,reload,setReload, insuranceType} = props;
 // LOCAL VARIABLES
 const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
 const API_TOKEN=Cookie.get("access_token_admin");

    const [sender, setSender] = useState("");
    const [senderGroup, setSenderGroup] = useState(""); // eslint-disable-line
    const [fulfiller, setFulfiller] = useState(""); 
    const [fulfillerGroup, setFulfillerGroup] = useState(""); // eslint-disable-line
    const [facility, setFacility] = useState("");
    const [orderType, setOrderType] = useState("");
    const [provider, setProvider] = useState("");
    const [ranking, setRanking] = useState("");
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [SpinnerEnable, setSpinnerEnable] = useState("");

    const [dropDownDataROS, setDropDownDataROS] = useState("")    
    const [dropDownDataROF, setDropDownDataROF] = useState("")    
    const [groupDropDownROS, setGroupDropDownROS] = useState("");
    const [groupDropDownROF, setGroupDropDownROF] = useState("");
    const [facilityDropDown, setFacilityDropDown] = useState(""); // eslint-disable-line

    const [orderTypeList, setOrderTypeList] = useState("");
    const [orderTypeDropDown, setOrderTypeDropDown] = useState("");

    const [inputValue, setInputValue] = useState(''); // eslint-disable-line
    const [newNotificationMail, setNewNotificationMail] = useState(""); // eslint-disable-line
    const [newNotificationFax, setNewNotificationFax] = useState(""); // eslint-disable-line
    const [newContactNumber, setNewContactNumber] = useState(""); // eslint-disable-line
    const [newVersion, setNewVersion] = useState(""); // eslint-disable-line
    const [selectedInsuranceType, setSelectedInsuranceType]=useState(null)

    const RankingOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
    ]

  const VersionOptions = [
    { value: 'O', label: 'Hierarchy' },
    { value: 'N', label: 'Non-Hierarchy' }
  ]


    const toggle = () => {
        setAddModal(!addModal);
        setSender("")
        setSenderGroup("")
        setFulfiller("")
        setFulfillerGroup("")
        setFacility("")
        setOrderType("")
        setProvider("")
        setRanking("")
        setAddButtonStatus(true);
        setSelectedInsuranceType("")

    }

    /* const OverlayLoader = () => (
        <div class="item">
            <i class="loader --1"></i>
        </div>
    ); */

    const confirmation = () => {
        // debugger
        setSpinnerEnable(true)
        if (!sender) {
            toastr.error("Sender Is Required", "Please select sender name!")
        }
        if (!fulfiller) {
            toastr.error("Fulfiller Is Required", "Please select fulfiller name!")
        }
        if (!facility) {
            toastr.error("facility Is Required", "Please select facility!")
        }
        if (!orderType) {
            toastr.error("OrderType Is Required", "Please select order type!")
        }
       /*  if (!provider) {
            toastr.error("Provider Is Required", "Please select provider!")
        } */

        if(!ranking ) {
            toastr.error("Ranking Is Required" , "Please select ranking!")
        }


        if (sender && fulfiller && facility && orderType && ranking) {
            createProviderSpecialityRanking();
            
        }

    };
    const getDDDate = async () => {
        setSpinnerEnable(true)
        await AxiosInter.get(`${API_ENDPOINT}/admin-api/referral-order-sender-senderGp-fulfiller-fulfillerGp/`,
            {
                headers: {
                    Authorization: API_TOKEN,
                },
            })
            .then((res) => {
                setDropDownDataROS(res.data.data.ros);
                setDropDownDataROF(res.data.data.rof);
                setSpinnerEnable(false)
            })
            .catch((error) => {
                console.log(error);
                setSpinnerEnable(false)
            });
    }
    const minLength = 4;
    const customNoOptionsMessage = ({ inputValue }) => {
        if (inputValue.length < minLength) {
            return "Please enter at least 4 letters to search";
        } else {
            return "No option";
        }
    };

    const fetchOrderTypeData = async () =>{
        setSpinnerEnable(true)
        try{
            const response = await AxiosInter.post(`${API_ENDPOINT}/admin-api/graphql/`, {
                query : `  query{
                    referralOrderTypesList{
                      id
                      type
                    }
                  }          
                  `,       
            });
            const data= response.data; 
            setOrderTypeList(data.data.referralOrderTypesList);       
        }catch(error){

        }
        setSpinnerEnable(false)    
    }

    const createProviderSpecialityRanking = () => {


    // Function to validate an email address
    const isValidEmail = (email) => {
      const emailPattern = new RegExp("^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\\.[a-zA-Z]{2,})$");

      console.log(emailPattern.test(email))
      return emailPattern.test(email);
  };
  const isValidFax  =  (fax) => {
     // eslint-disable-next-line eqeqeq
    if (fax != '' && typeof (fax) != 'undefined') {
       // eslint-disable-next-line eqeqeq
        if (fax.length == 10 && fax.match(/^\+?[0-9]{10}$/)) {
            return true;
        }
        else if (fax.match(/^[0-9]{1,3}-[0-9]{3}-[0-9]{4}$/)) {
            return true;
        }
        else {
            return false;
        }
    } else {
        return false;
    }
  }
   // Function to validate contact number
   const isValidNumber = (email) => {
     
    const numberPattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    console.log(numberPattern.test(email))
    return numberPattern.test(email);
};

   // Validate and extract notification emails
   const notificationEmails = newNotificationMail ? newNotificationMail.split(",").map(email => email.trim()) : [];
   const invalidEmails = notificationEmails.filter(email => !isValidEmail(email));
 
    // Validate and extract notification fax
    const notificationFaxs = newNotificationFax ? newNotificationFax.split(",").map(fax => fax.trim()) : [];
    const invalidFax = notificationFaxs.filter(fax => !isValidFax(fax));
  // Validate and extract notification number
  const notificationNumber = newContactNumber ? newContactNumber.split(",").map(number => number.trim()) : [];
  const invalidNumber = notificationNumber.filter(number => !isValidNumber(number));

    // Check if any email is invalid
    if (invalidEmails.length > 0) {
        // Handle the error and return the corresponding messages
        invalidEmails.forEach(invalidEmail => {
            toastr.error("Invalid Email", `Invalid email address: ${invalidEmail}`);
        });
        return;
    }
  
     // Check if any email is invalid
     if (invalidFax.length > 0) {
      // Handle the error and return the corresponding messages
      invalidFax.forEach(invalidFax => {
          toastr.error("Invalid Fax", `Invalid Fax Number: ${invalidFax}`);
      });
      return;
  }
   // Check if any email is invalid
   if (invalidNumber.length > 0) {
    // Handle the error and return the corresponding messages
    invalidNumber.forEach(invalidNumber => {
        toastr.error("Invalid Contact Number", `Invalid Contact Number: ${invalidNumber}`);
    });
    return;
  }
 
        var senderid = sender.value.split("/")[0];
        var senderGroupid = sender.value.split("/")[1];
        var fulfillerid = fulfiller.value.split("/")[0];
        var fulfillerGroupid = fulfiller.value.split("/")[1];

        setSpinnerEnable(true)
        const params = {
            "sender_id": senderid ? Number(senderid) : 0,
            "sender_group_id": senderGroupid ? Number(senderGroupid) : 0,
            "fulfiller_id": fulfillerid ? Number(fulfillerid) : 0,
            "fulfiller_group_id": fulfillerGroupid ? Number(fulfillerGroupid) : 0,
            "fulfiller_facility_id": facility ? Number(facility.value) : 0,
            "order_type_id": orderType ? Number(orderType.value) :0,
            "fulfiller_physician_id": provider ? Number(provider.value) : 0,
            "network_ranking": ranking ? Number(ranking.value) : 0,
            "notification_email":newNotificationMail ? newNotificationMail :"",
            "notification_fax":newNotificationFax ? newNotificationFax :"",
            "contact_number":newContactNumber ? newContactNumber :"",
            "version":newVersion ? newVersion.value :"",
            "insurance_type": selectedInsuranceType ? selectedInsuranceType.value: "",      
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/create-provider-speciality-ranking/`, params, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(function (response) {
                if (response.status === 201) {
                    addReferralSenderFulfiller()
                    setReload(reload + 1)
                    toggle()
                    setSpinnerEnable(false)
                    toastr.success("Successfully Created", "Provider Speciality Ranking successfully created")
                } 
            })
            .catch(function (error) {
                toastr.error("Failed", "Maybe you are adding duplicate entry")
                setSpinnerEnable(false)
            });



    }

    const addReferralSenderFulfiller = () => {
        var senderid = sender.value.split("/")[0];
        var senderGroupid = sender.value.split("/")[1]; // eslint-disable-line
        var fulfillerid = fulfiller.value.split("/")[0]; // eslint-disable-line
        var fulfillerGroupid = fulfiller.value.split("/")[1];
        const params =
        {
          "order_sender_id": senderid ? senderid.toString() : "",
          "isSelfReferral": "0",
          "group_id": fulfillerGroupid ? fulfillerGroupid.toString() : "",
        }
    
        AxiosInter.post(`${API_ENDPOINT}/admin-api/create-referral-sender-fulfiller/`, params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
          .then(function (response) {
    
            if (response.status === 201){
              
              
            }
          })
          .catch(function (error) {
            // toastr.error('Error', 'Referral Fulfiller Not Added');
            
          });
      };

    const disableAddButton = () => {
        if (
            sender &&
            fulfiller &&
            facility &&
            orderType&&
            ranking
        ) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }
    const minLengthforzip = 1;

    const customNoOptionsMessageforZip = ({ inputValue }) => {
      if (inputValue.length < minLengthforzip) {
        return "Please search with at least 1 character";
      } else {
        return "No option";
      }
    };

    const createGroupOptionROS = () => {
        if(dropDownDataROS){
            setSpinnerEnable(true)
            var array1 = dropDownDataROS
            const options1 = array1.map((item, index) => {
                
                return {
                    value: item.SenderID+"/"+item.senderGroupID,
                    label: item.senderGroupName
                };
            });
            setGroupDropDownROS(options1);
            setSpinnerEnable(false)
        }
    };

    const createGroupOptionROF = () => {
        
        if(dropDownDataROF){
            setSpinnerEnable(true)
            var array1 = dropDownDataROF
            const options1 = array1.map((item, index) => {
                return {
                    value: item.FulfillerID+"/"+item.fulfillerGroupID,
                    label: item.fulfillerGroupName
                };
            });
            setGroupDropDownROF(options1);
            setSpinnerEnable(false)
        }
    };

    
    const createOptionsForOrderType = () => {
        
        if(orderTypeList){
            setSpinnerEnable(true)
            var array1 = orderTypeList
            const options1 = array1.map((item, index) => {
                return {
                    value: item.id,
                    label: item.type
                };
            });
            setOrderTypeDropDown(options1);
            setSpinnerEnable(false)
        }
    };


    const getAsyncOptions = (inputValue) => { 
    
        if(groupDropDownROS){
         return new Promise((resolve, reject) => {
             const filtered = _.filter(groupDropDownROS, o =>
               _.startsWith(_.toLower(o.label), _.toLower(inputValue))
             );
             resolve(filtered);
           });
        }
    }

    const getAsyncOptionsForFulfiller = (inputValue) => { 
        if(groupDropDownROF){
         return new Promise((resolve, reject) => {
             const filtered = _.filter(groupDropDownROF, o =>
               _.startsWith(_.toLower(o.label), _.toLower(inputValue))
             );
             resolve(filtered);
           });
        }
    }

    // handle input change event
    const handleInputChange = value => {
        setInputValue(value);
    };
    
    // handle selection
    const handleChange = value => {
        setProvider(value);
    }
        


    const loadOptions = (inputValue) => {
        if (inputValue.length >= 4) {
          const searchParams = new URLSearchParams({ name: inputValue });
          return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-and-npi-data/?${searchParams.toString()}`, {
              headers: {
                Authorization: API_TOKEN,
              },
            })
            .then((response) => response.data.data)
            .catch((error) => {
              throw error;
            });
        }
        // Return a default value when inputValue length is less than 4
        return null;
      };

    const loadFacilityOptionsForFacility = (inputValue) => {

        if(inputValue.length >= 4){
            return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-by-fulfiller-name/${inputValue}/${fulfiller.value.split("/")[1]}`, {
            headers: {
                Authorization: API_TOKEN,
            }
            })
            .then((response) => response.data.data)
            .catch((error) => {
                throw error;
            });
        }
    }


    const handleFacilityChange = value => {
        setFacility(value);
    }

   
    useEffect(() => { getDDDate(); fetchOrderTypeData();  }, []) // eslint-disable-line
    useEffect(() => { if ( dropDownDataROS) createGroupOptionROS(); }, [ dropDownDataROS]) // eslint-disable-line
    useEffect(() => { if ( dropDownDataROF) createGroupOptionROF(); }, [ dropDownDataROF]) // eslint-disable-line
    useEffect(() => { disableAddButton() }, [sender, fulfiller, facility, orderType,ranking]) // eslint-disable-line
    useEffect(() => { if ( orderTypeList) createOptionsForOrderType(); }, [ orderTypeList]) // eslint-disable-line



    return (
        <div>
            {SpinnerEnable ? <Spinner className=".overlay-z-index" /> : null}
            <Modal isOpen={addModal}  size="xl" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add New Provider Speciality Ranking </ModalHeader>
                <ModalBody className="disable-scrollbars" >

                    <FormGroup>
                        <Row>
                            <Col xs="3">

                            <Label>Sender Group <span className="required">*</span></Label>
                    
                            <AsyncSelect
                                cacheOptions
                                isClearable
                                isSearchable
                                /* defaultOptions */
                                placeholder="Type sender name for options"
                                noOptionsMessage={customNoOptionsMessageforZip}
                                value={sender}
                                onChange={(event) => { setSender(event) }}
                                loadOptions={inputValue => getAsyncOptions(inputValue)}
                            />
                            </Col>
                            <Col xs="3">
                            <Label>Fulfiller Group <span className="required">*</span></Label>
                            <AsyncSelect
                                cacheOptions
                                isClearable
                                isSearchable
                                /* defaultOptions */
                                placeholder="Type fulfiller name for options"
                                noOptionsMessage={customNoOptionsMessageforZip}
                                value={fulfiller}
                                onChange={(event) => { setFulfiller(event) }}
                                loadOptions={inputValue => getAsyncOptionsForFulfiller(inputValue)}
                            /> 
                            </Col>
                            <Col xs="3">
                            <Label>Order Type <span className="required">*</span></Label>
                        
                                    <Select
                                        name="order"
                                        options={
                                            orderTypeDropDown
                                        }
                                        value = {orderType}
                                        placeholder="Select order type"
                                        noOptionsMessage={customNoOptionsMessageforZip}
                                        isSearchable
                                        isClearable
                                        onChange={(e) => {
                                        setOrderType(e)
                                        
                                    }}
                                /> 
                            </Col>
                            <Col xs="3">
                                <Label>Insurance Type</Label>
                                <Select
                                    options={insuranceType}
                                    value={selectedInsuranceType}
                                    placeholder="Select insurance type"
                                    isSearchable
                                    isClearable
                                    onChange={(event)=>{
                                        setSelectedInsuranceType(event)
                                    }}
                                />
                            </Col>
                        </Row>
                                <br></br>
                        <Row>
                            <Col xs="7">
                                <Row>
                                    <Col style={{display:"grid"}} xs="6">
                                    <div>
                                    <Label>Facility <span className="required">*</span></Label>
                                        <div style={{float:"right",marginTop:"0px"}} className="warning-label">
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                    /> {" "} {" "}
                                                <Label  className="warning-text">Search with at least 4 characters.</Label>
                                        </div>
                                    </div>
                                    <AsyncSelect
                                    className="mrg-btm-22"
                                        cacheOptions
                                        isClearable
                                        isSearchable
                                        isDisabled={fulfiller ? false : true}
                                        /* defaultOptions */
                                        placeholder={fulfiller ? "Type facility name for options" : "Select Fulfiller Group to enable"}                          
                                        getOptionLabel={e => e.label}
                                        getOptionValue={e => e.value}
                                        loadOptions={loadFacilityOptionsForFacility}
                                        value={facility}
                                        onInputChange={handleInputChange}
                                        onChange={handleFacilityChange}
                                    /> 
                                    </Col>
                                    <Col xs="6">
                                    
                                    <Label>Network Rank <span className="required">*</span></Label>
                            
                                    <Select
                                        name="order"
                                        options={
                                            RankingOptions
                                        }
                                        value = {ranking}
                                        placeholder="Select network rank"
                                        isSearchable
                                        isClearable
                                        onChange={(e) => {
                                        setRanking(e)
                                        
                                    }}/>
                                <br></br>
                                    </Col>
                                </Row>

                            </Col>
                            <Col style={{display:"grid"}} xs="5">
                            
                            <div >
                        <Label>Provider</Label>
                            <div style={{float:"right",marginTop:"0px"}} className="warning-label">
                                    <FontAwesomeIcon
                                        icon={faExclamationTriangle}
                                        /> {" "} {" "}
                                    <Label  className="warning-text">Search with at least 4 characters.</Label>
                            </div>
                        </div>
                            <AsyncSelect
                            className="mrg-btm-22"
                                cacheOptions
                                /* defaultOptions */
                                isClearable
                                isSearchable
                                value={provider}
                                getOptionLabel={e => e.label}
                                getOptionValue={e => e.value}
                                placeholder="Type provider name for options"
                                noOptionsMessage={customNoOptionsMessage}
                                loadOptions={loadOptions}
                                onChange={handleChange}
                                onInputChange={handleInputChange}
                            /> 
                            </Col>
                            <Col xs="3">
                    <Label>
                      Notification Mail
                    </Label>

                    <Input
                          placeholder="Emails (demo1@xyz.com,demo2@xyz.com)"
                          // isMulti
                          style={{ width: '100%' }} 
                          value={newNotificationMail}
                          onChange={(e) => {
                            setNewNotificationMail(e.target.value);
                          }}
                        />
                    <br></br>
                  </Col>
                  <Col xs="3">
                    <Label>
                      Notification Fax
                    </Label>

                    <Input
                          placeholder="Fax (644124598,71217598)"
                          // isMulti
                          style={{ width: '100%' }} 
                          value={newNotificationFax}
                          onChange={(e) => {
                            setNewNotificationFax(e.target.value);
                          }}
                        />
                    <br></br>
                  </Col>
                  <Col xs="3">
                    <Label>
                   Contact Number
                    </Label>

                    <Input
                          placeholder="Enter Contact Number"
                          // isMulti
                          value={newContactNumber}
                          onChange={(e) => {
                            setNewContactNumber(e.target.value);
                          }}
                        />
                    <br></br>
                  </Col>
                  <Col xs="3">
                    <Label>
                   Version
                    </Label>

                    <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setNewVersion(event) }}
                            isSearchable
                            placeholder="Select Version"
                            defaultValue={{value:"O",label:"O"}}
                            value={newVersion}
                            options={VersionOptions}
                            />
                    <br></br>
                  </Col>
                        </Row>
                       
                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={confirmation}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>

        </div>
    );
}

export default NewProviderSpecialityRankingModal;
