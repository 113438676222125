import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom

import App from "./App";

// Import 'bootstrap/dist/css/bootstrap.min.css' if needed

// Use createRoot to render your application
const root = document.getElementById("root");
const rootInstance = createRoot(root);
rootInstance.render(<App />);
