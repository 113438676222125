import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader,Container, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';

const SubscriptionModal = (props) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");

    const [buttonBlankState,setButtonBlankState] = useState(false);
  


    
    const {
        modal ,
        setModal,
        modalUseState,
        subscriptionPlanId,
        subscriptionPlanName,
        setSubscriptionPlanName,
        subscriptionDescription,
        setSubscriptionDescription,
        subscriptionValidity,
        setSubscriptionValidity,
        subscriptionStatus,
        setSubscriptionStatus,
        subscriptionPrice,
        setSubscriptionPrice,
        statusOptions,
        setModalUseState,
        reload,
        setReload,
        subscriptionNamesListReload,
        setSubscriptionNamesListReload,


        

        
    } = props

    const toggle = () => {
        setModal(!modal)
    }

    const setInitialSubscriptionStatus = () => {
        statusOptions.map((item)=>{ // eslint-disable-line
            if(item.label === subscriptionStatus) {
                setSubscriptionStatus(item)
            }
        })
    }

    const validate = () => {

        if (
          subscriptionPlanName && subscriptionPrice && subscriptionValidity
    
        ) {
            setButtonBlankState(false)
        }
        else if(subscriptionPrice === 0 || subscriptionValidity === 0){
            setButtonBlankState(false)
        }
        else {
            setButtonBlankState(true)
        }
      }


    const editSubscriptionPlan = () => {
     const params =
        {
            "subscription_plan_id": subscriptionPlanId ? subscriptionPlanId:"",
            "subscription_plan_name":subscriptionPlanName ? subscriptionPlanName:"",
            "subscription_status":subscriptionStatus ? subscriptionStatus:"",
            "subscription_description":subscriptionDescription ? subscriptionDescription:"",
            "subscription_validity":subscriptionValidity ? subscriptionValidity:"",
            "subscription_price":subscriptionPrice ? subscriptionPrice:"",
        }
 
        AxiosInter.post(`${API_ENDPOINT}/admin-api/edit-subscription-plan/`,params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
          .then(response => {
            setReload(reload + 1);
            setSubscriptionNamesListReload(subscriptionNamesListReload + 1)
            toastr.success("Success","Updated successfully")
            toggle();

            
            
          })
          .catch(error => {
            toastr.error("Error",error.response.data.message)
           
          });

    }
    const addNewSubscriptionPlan = () => {
        // debugger
        const params =
        {
            "subscription_plan_name": subscriptionPlanName ? subscriptionPlanName:"",
            "subscription_description":subscriptionDescription ? subscriptionDescription:"",
            "subscription_validity":subscriptionValidity ? subscriptionValidity:"",
            "subscription_price":subscriptionPrice ? subscriptionPrice:""
        }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/add-subscription-plan/`,params,{
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        toastr.success("Success","New subscription plan added")
        setReload(reload+1)
        setSubscriptionNamesListReload(subscriptionNamesListReload + 1)
        toggle();
       

        
        
      })
      .catch(error => {
        if (error.response.data.message) {
            toastr.error("Error",error.response.data.message)
        } 
        else {
            toastr.error("Error","Something went wrong !")
        }  
      
       
      });


    }


    useEffect(() => {
        if (!modal) {
           setModal(false);
           setInitialSubscriptionStatus();
           setSubscriptionPrice("")
           setSubscriptionPlanName("")
           setSubscriptionDescription("")
           setSubscriptionValidity("")
           setModalUseState("")
          
        };

    }, [modal]) // eslint-disable-line

    useEffect(() => {
        validate();

    }, [setSubscriptionPlanName,subscriptionValidity,subscriptionPrice]) // eslint-disable-line



    
    return (
        <div>
            <Modal isOpen={modal} size="md" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>{modalUseState === "add"? "New Subscription Plan":"Edit Subscription Plan"} </ModalHeader>
                <ModalBody className="overflow-modal">
                <Container fluid>
                    <FormGroup>
                        <Row>
                            <Col sm={12}>
                                <Label for="sub_plan_name">Subscription Plan Name<span className="required">*</span></Label>
                                <Input
                                    id="sub_plan_name"
                                    placeholder="Subscription Plan Name"
                                    value={subscriptionPlanName}
                                    onChange={(e) => {
                                        setSubscriptionPlanName(e.target.value)

                                    }}
                                />
                            </Col>
                        </Row><br/>
                          <Row>          
                            <Col sm={12}>
                            <Label for="description">Description</Label>
                                    <Input
                                        id="description"
                                        type="textarea"
                                        rows={4}
                                        cols={2}
                                        placeholder="Description"
                                        value={subscriptionDescription}
                                        onChange={(e) => {
                                            setSubscriptionDescription(e.target.value)
                                        }}

                                    />

                            </Col>

                        </Row><br/>
                        <Row>
                            <Col sm={6}>
                            <Label for="sub_validity">Validity(in Days)<span className="required">*</span></Label>
                                    <Input
                                        id="sub_validity"
                                        type="number"
                                        rows={4}
                                        cols={2}
                                        placeholder="Validity"
                                        value={subscriptionValidity}
                                        onChange={(e) => {
                                            setSubscriptionValidity(e.target.value)
                                        }}

                                    />

                            </Col>

                      
                            <Col sm={6}>
                                <Label for="sub_price">Price<span className="required">*</span></Label>
                                <Input
                                        id="sub_price"
                                        type="number"
                                        placeholder="Subscription Price"
                                        value={subscriptionPrice}
                                        onChange={(e) => {
                                            setSubscriptionPrice(e.target.value)
                                        }}

                                    />

                              

                            </Col>
                            {/* <Col sm={4}>
                                <Label for="sub_status">Status</Label>
                                 <Select
                                    name="sub_price"
                                    options={
                                        statusOptions
                                    }
                                    // value={subscriptionStatus}
                                    placeholder="Choose Status"
                                    isSearchable
                                    isClearable
                                    // onChange={(e) => {
                                    //   setEditCptCategory(e)
                                       
                                    // }}
                                />

                              

                            </Col> */}
                        </Row><br/>
                       

                    </FormGroup>
                    </Container>

                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button
                  
                        onClick={() => {
                            modalUseState === 'edit' ? editSubscriptionPlan():addNewSubscriptionPlan()
                        }}
                    disabled = {buttonBlankState}
                    title= {buttonBlankState ? "Please fill in all mandatory fields":(!buttonBlankState && modalUseState)?"Click to save":"Click to update"}

                    >{modalUseState === 'edit' ?'Update':'Add'}</Button>{' '}

                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default SubscriptionModal;