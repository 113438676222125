import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Label, Button, ModalFooter, ModalHeader, FormGroup, ModalBody, Container, Spinner } from 'reactstrap'
import Select from 'react-select'
import Cookie from 'js-cookie';
import AxiosInter from './../../AxiosInter.js';
import { toastr } from 'react-redux-toastr';

const AddModuleSubscriptionModal = (props) => {
    const [moduleList, setModuleList] = useState()
    const [spinner, setSpinner] = useState(false)
    const [subscriptionNamesList, setSubscriptionNamesList] = useState()
    const [selectedSubscription, setSelectedSubscription] = useState()
    let res = []
    const [buttonBlankState, setButtonBlankState] = useState(true);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");

    const { setAddModule, addModule, subscriptionModules, selectedModule, setSelectedModule } = props

    const toggle = () => {
        setAddModule(!addModule)
        setSelectedModule("")
        setSelectedSubscription("")
    }

    const disableAddButton = () => {
        const isModuleCleared = selectedModule?.every(module => module === null || module === undefined);

        if (selectedModule && selectedModule?.length > 0 && !isModuleCleared && selectedSubscription) {
            setButtonBlankState(false); // Enable the button if there are selected modules and a subscription
        } else {
            setButtonBlankState(true); // Disable the button otherwise
        }
    }

    useEffect(() => { 
        if(selectedModule && selectedSubscription){
            disableAddButton() 
        }
    }, [selectedModule, selectedSubscription]) // eslint-disable-line

    const getModuleList = () => {
        setSpinner(true);
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m-modules/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((res) => {
                var array = [];
                // eslint-disable-next-line array-callback-return
                res.data.data.map((item1) => {
                    // eslint-disable-line
                    array.push({
                        value: item1.id,
                        label: item1.name,
                    });
                });
                setModuleList(array);
                setSpinner(false);
            })
            .catch((error) => {
                setSpinner(false);
            });
    };

    const getAllSubscriptionPlans = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-subscription-plans-list/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.data.data.length > 0) {
                    setSubscriptionNamesList(response?.data?.data)
                }
            })
    }

    useEffect(() => {
        if (addModule) {
            getModuleList()
        }
    }, [addModule]) // eslint-disable-line

    useEffect(() => {
        if (addModule) {
            getAllSubscriptionPlans()
        }
    }, [addModule]) // eslint-disable-line    

    const addNewModule = () => {
        const params = {
            subscriptionID: selectedSubscription ? selectedSubscription.value : '',
            moduleIdList: res ? res : ''
        };
        AxiosInter.post(`${API_ENDPOINT}/admin-api/add-subscription-modules/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                if (response.status === 201) {
                    setAddModule(false);
                    toastr.success("Configuration Added Successfully");
                    subscriptionModules();
                    toggle()
                }
            })
            .catch(error => {
                if (error?.response?.data?.status === "Failed") {
                    toastr.error(error?.response?.data?.message);
                }
            });
    };

    const confirmation = () => {
        if (!selectedSubscription && !selectedModule) {
            toastr.error("Please select subscription and modules");
        }
        if ((selectedSubscription) && Array.isArray(selectedModule)) {
            selectedModule.map((item) => { // eslint-disable-line
                res.push(item.value);
            });

            if (res.length > 0) {
                addNewModule();
            }
        }
    };


    return (
        <div>
            <Modal isOpen={addModule} size="md">
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add Modules</ModalHeader>
                <ModalBody>
                    <div>
                        {spinner ? <Spinner color='primary' /> : ""}
                    </div>
                    <Container fluid>
                        <FormGroup>
                            <Row>
                                <Col sm={12}>
                                    <Label for="Permissions">Subscriptions <span className="required">*</span></Label>
                                    <Select
                                        name="Permissions"
                                        options={subscriptionNamesList}
                                        value={selectedSubscription}
                                        placeholder="Select Subscription"
                                        isClearable={true}
                                        onChange={(e) => {
                                            setSelectedSubscription(e)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col sm={12}>
                                    <Label for="module">Module <span className="required">*</span></Label>
                                    <Select
                                        name="module"
                                        options={moduleList}
                                        value={selectedModule}
                                        placeholder="Select Module"
                                        isClearable={true}
                                        isMulti
                                        onChange={(e) => {
                                            setSelectedModule(e)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Container>
                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button
                        onClick={confirmation}
                        disabled={buttonBlankState}
                        title={buttonBlankState ? "Fill the required fields" : ""}
                    >Add
                    </Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default AddModuleSubscriptionModal