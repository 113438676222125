/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState, useContext } from 'react';
import { Card, Col, Row, Button, Breadcrumb, BreadcrumbItem, Container, Label, Input, Spinner } from 'reactstrap';
import { Link, useParams, useLocation } from "react-router-dom";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { GlobalState } from '../../contextAPI/Context'
import FacilityStatusChangeModal from "../../../src/newComponents/utilities/FacilityStatusChangeModal"
import EditFacilityModal from '../../newComponents/utilities/EditFacilityModal';
import RemotePaginationForProgram from '../../newComponents/utilities/RemotePaginationForProgram'
import NewFacilityModal from '../../newComponents/utilities/NewFacilityModal';
import SearchBarComponentForProgram from '../../newComponents/utilities/SearchBarComponentForProgram';
import { useNavigate } from "react-router-dom";
import MomentConfig from '../../common/MomentConfig';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import NotificationPreferenceModal from '../../../src/newComponents/utilities/NotificationPreferenceModal'
import EditCareRosterServiceProvided from '../../../src/newComponents/utilities/EditCareRosterServiceProvided'
import DeleteConfirmationModal from "../../newComponents/utilities/DeleteConfirmationModal";
import StatusChangeModalPrograms from '../../newComponents/utilities/StatusChangeModalPrograms';

const CareRosterServiceProvided = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");

  const [facilityDetails, setFacilityDetails] = useState([]);
  const [facilityID, setFacilityID] = useState("");
  const [modalState, setModalState] = useState(false);
  const [status, setStatus] = useState(0);
  const [editFacilityModal, setEditFacilityModal] = useState(false);
  const [editType, setEditType] = useState("");
  const [editGroupID, setEditGroupID] = useState("");//eslint-disable-line
  const [editFacilityID, setEditFacilityID] = useState("");
  const [editFacilityName, setEditFacilityName] = useState("");
  const [editAddress1, setEditAddress1] = useState("");
  const [editAddress2, setEditAddress2] = useState("");
  const [editPrimaryPhone, setEditPrimaryPhone] = useState("");
  const [editReferalFax, setEditReferalFax] = useState("");
  const [editContactPerson, setEditContactPerson] = useState("");
  const [editDepartment, setEditDepartment] = useState("");
  const [editFloor, setEditFloor] = useState("");
  const [editWing, setEditWing] = useState("");
  const [editRoom, setEditRoom] = useState("");
  const [editLatitude, setEditLatitude] = useState("");
  const [editCenter, setEditCenter] = useState("")
  const [editLongitude, setEditLongitude] = useState("");
  const [editTimeZone, setEditTimeZone] = useState("");
  const [editNPI, setEditNPI] = useState("");;
  const [editZipID, setEditZipID] = useState("");//eslint-disable-line
  const [editZipCode, setEditZipCode] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [totalSize, setTotalSize] = useState();
  const [offset, setOffset] = useState(0);
  const [newFacilityModal, setNewFacilityModal] = useState(false);
  const [selectedFacilityName, setSelectedFacilityName] = useState("");//eslint-disable-line
  const [selectedType, setSelectedType] = useState("");//eslint-disable-line
  const [selectedTimeZone, setSelectedTimeZone] = useState("");//eslint-disable-line
  const [selectedStatus, setSelectedStatus] = useState("");//eslint-disable-line
  const [searchKeyword, setSearchKeyword] = useState("");//eslint-disable-line
  const [selectedAccessType, setSelectedAccessType] = useState("");//eslint-disable-line
  const [selectedIsPrimary, setSelectedIsPrimary] = useState("");//eslint-disable-line
  const [data, setData] = useState([]);//eslint-disable-line
  const [spinner, setSpinner] = useState(false);//eslint-disable-line
  const [search, setSearch] = useState(false);//eslint-disable-line
  const [reloadFacilityDetails, setReloadFacilityDetails] = useState(false);
  const [groupDetailsList, setGroupDetailsList] = useState([]);//eslint-disable-line
  const [reloadGroupDetails, setReloadGroupDetails] = useState(false);//eslint-disable-line
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [statusChangeModal, setStatusChangeModal] = useState(false);
  const [accessType, setAccessType] = useState("");
  const [isPrimary, setIsPrimary] = useState("");
  const [reload, setReload] = useState(0);
  const fileExportName = 'Group-Facilities'
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv` //eslint-disable-line
  const [sortColumn, setSortColumn] = useState("");//eslint-disable-line
  const [sortOrder, setSortOrder] = useState("");//eslint-disable-line
  const [notificationPreferenceModal, setNotificationPreferenceModal] = useState(false);
  const [notificationToggleButton, setNotificationToggleButton] = useState(false);//eslint-disable-line
  const [newEmail, setNewEmail] = useState([])
  const [centralNotification, setCentralNotification] = useState(2)
  const [currentFacilityStatus, setFacilityCurrentStatus] = useState(0) //eslint-disable-line
  const [selectedId, setSelectedId] = useState()
  const [modal, setModal] = useState(false)
  const [newAction, setNewAction] = useState("")
  const [deleteModal, setDeleteModal] = useState(false);
  const [newServiceProvided, setNewServiceProvided] = useState("")

  let navigate = useNavigate();
  let location = useLocation();
  
  const goBack = () => {
    navigate(-1);
  };

  const { fetchFacilityAccessType, facilityAccessType,
    setFacilityReload, facilityReload, m_zipList, facilityListLoading, timeZoneList
  } = useContext(GlobalState);

  const toggleClose = (tab) => {
    setModalState("")
    setNewAction("")
  };
  const toggleCloseForServiceProvided = (tab) => {
    setModalState("")
    setNewAction("")
  };


  let { id } = useParams()

  let groupDetailsDataCheck = false;//eslint-disable-line

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive"
  }
  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button-blue"
          outline
          title={"Edit Service Provided"}
          onClick={() => {

            setModal(true);
            setNewServiceProvided(row.action);
            setModalState("edit");
            setSelectedId(row.id)
          }}
        >

          <FontAwesomeIcon icon={faEdit} title={"Edit Service Provided"} />
        </Button>
        {row.status === 0 ? <Button
          className="money-button-blue"
          color="primary"
          title="Inactive"
        >
          <FontAwesomeIcon icon={faTrash} style={{ color: "#808080" }} />
        </Button> : <Button
          className="money-button-blue"
          outline
          title={"Delete Service Provided"}
          onClick={() => {
            setSelectedId(row.id)
            setDeleteModal(true);
          }}
        >

          <FontAwesomeIcon icon={faTrash} style={{ color: "#E12C2C" }} title={"Delete Service Provided"} />
        </Button>}

      </>
    )


  }

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else if (cell === 0) {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }
    else {
      status = "N/A";
    }

    return (
      <>
        <span
          className={badgeclass}
          onClick={() => {
            setStatus(row.status)
            setFacilityID(row.id)
            setStatusChangeModal(true)
          }}
        >
          {status}
        </span>
      </>
    );
  };

  const columns = [
    {
      dataField: 'action',
      text: 'Service Provided',
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,
    },

    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
    },

    {
      text: 'Actions',
      sort: false,
      csvExport: false,
      formatter: actionsFormatter
    }
  ];



  const getServiceProvided = () => {
    const params =
    {
      "program_id": id ? id : "",



    }

    setSpinner(true);

    AxiosInter.post(`${API_ENDPOINT}/admin-api/get-care-plan-roster-service-provided/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        if (response.data.data.length > 0) {
          setFacilityDetails(response.data.data);
          setTotalSize(response.data.record_count)
          setGroupDetailsList(response.data.data);
          setSpinner(false);
          localStorage.setItem('offset', offset);

        }
        else {
          setTotalSize(0)
          setFacilityDetails("");
          setGroupDetailsList("");
          setSpinner(false);

        }
      })
      .catch(error => {

        setSpinner(false);
      });
  };

  const addNewService = () => {
    const params =
    {
      "program_id": id ? id : "",
      "action": newAction ? newAction : "",
      "value": 1
    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/add-care-plan-roster-service-provided/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        setReload(reload + 1)
        toastr.success("Success", "New service added")
        toggleCloseForServiceProvided();
        window.location.reload()



      })
      .catch(error => {
        if (error.response.data.message) {
          toastr.error("Error", error.response.data.message)
        }
        else {
          toastr.error("Error", "Something went wrong !")
        }


      });


  }
  const deleteServiceProvided = () => {
    setSpinner(true);
    const params = {
      row_id: selectedId ? selectedId : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/delete-care-plan-program-service-provided/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        toastr.success("Success", "Deleted successfully");
        setSpinner(false);
        setDeleteModal(false);
        window.location.reload()
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };
  const updateCareRosterServiceStatus = () => {
    setSpinner(true);
    const params = {
      "row_id": facilityID ? facilityID : "",
      "status": status === 0 ? 1 : 0
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/update-program-service-status/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        setReloadFacilityDetails(reloadFacilityDetails + 1)
        setStatusChangeModal(false);
        setSpinner(false);
        toastr.success('Status Updated', 'Status updated successfully');
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
        setSpinner(false);
      });
  };

  const handleCategoryTableChange = (type, newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
    setOffset((newState.page - 1) * newState.sizePerPage)
    const currentIndex = 0;

    if (newState.sortField !== "" && newState.sortOrder !== "") {
      let result;
      if (newState.sortOrder === 'asc') {
        result = facilityDetails.sort((a, b) => {
          if (a[newState.sortField] > b[newState.sortField]) {
            return 1;
          } else if (b[newState.sortField] > a[newState.sortField]) {
            return -1;
          }
          return 0;
        });
      } else {
        result = facilityDetails.sort((a, b) => {
          if (a[newState.sortField] > b[newState.sortField]) {
            return -1;
          } else if (b[newState.sortField] > a[newState.sortField]) {
            return 1;
          }
          return 0;
        });
      }

      setFacilityDetails(result.slice(currentIndex, currentIndex + sizePerPage));
    }
  }
  const searchButtonValidation = () => {
    if (selectedFacilityName || selectedType || selectedTimeZone || selectedStatus || selectedAccessType || selectedIsPrimary) {
      setSearch(true)
    } else {
      setSearch(false)
    }
  }

  useEffect(() => {
    if (location.state) {
      localStorage.setItem('isFilterApplied', JSON.stringify(location.state.detail))
    }

  }, [location])


  useEffect(() => {
    if (facilityAccessType.length <= 0) {
      fetchFacilityAccessType();
    }

  }, [facilityAccessType]) // eslint-disable-line

  useEffect(() => {
    if (facilityReload >= 1) {
      getServiceProvided();
    }
  }, [facilityReload])//eslint-disable-line

  useEffect(() => { if (reloadFacilityDetails) { getServiceProvided(); setReloadFacilityDetails(false); } }, [reloadFacilityDetails])// eslint-disable-line
  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0)
      setTotalSize(0);
      getServiceProvided();
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch])//eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem('offset')
    if (offset !== previousOffset) {
      getServiceProvided();
    }

  }, [offset, sizePerPage]) // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);


    }
  }, [searchKeyword])

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setReloadFacilityDetails(true)

    }

  }, [searchKeyword])

  useEffect(() => {
    searchButtonValidation();
  }, [selectedFacilityName, selectedType, selectedTimeZone, selectedStatus, selectedAccessType, selectedIsPrimary])//eslint-disable-line

  useEffect(() => {
    document.title = "Equipo Admin | Programs"
  }, [])


  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Programs
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem><Link to="/programs">Programs</Link></BreadcrumbItem>
            <BreadcrumbItem active>Service Provided</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <Card className="adv-search-background-card-pt2">
          <Container fluid>
            <Row style={{ 'marginBottom': '0rem' }}>
              <div style={{ 'justifyContent': 'left' }}>
                <FontAwesomeIcon
                  size={'lg'}
                  style={{ 'cursor': 'pointer' }}
                  title={'Go Back'}
                  icon={faArrowLeft}
                  onClick={goBack}
                />
              </div>
              <Col>
                <Button className="Add-btn pull-right"
                  onClick={() => {
                    setModalState("addCSService");

                  }}

                >
                  Add new service
                </Button>
                <h2 style={{ 'textAlign': 'center' }}>{localStorage.getItem("title")}</h2>
              </Col>
            </Row>
            <SearchBarComponentForProgram
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              totalSize={totalSize}
              columns={columns}
            />
          </Container>
        </Card>

        <Card>
          {!facilityListLoading ?
            <>
              <>

                {modalState === "addCSService" ?
                  <Row style={{ paddingLeft: 40, paddingTop: 30 }}>
                    <Col sm={1.5}>
                      <Label for="cpt_code">Service Provided<span className="required">*</span></Label>
                    </Col>
                    <Col sm={4}>
                      <Input
                        id="cpt_code"
                        placeholder="Enter Service Name"
                        value={newAction}
                        onChange={(e) => {
                          setNewAction(e.target.value)

                        }}
                      />
                    </Col>
                    <Col sm={4} className="program-tick-minus" style={{ paddingLeft: 0 }}>
                      <Button
                        className="tick-button"
                        onClick={() => {
                          addNewService()
                        }}
                        title="Click to save"
                      ><FontAwesomeIcon icon={faCheck} title="Click to save" /></Button>
                      <Button
                        className="minus-button"
                        fixedWidth
                        title="Close"
                        onClick={toggleClose}><FontAwesomeIcon icon={faTimes} title="Close" /></Button></Col>

                  </Row> : ""}<br />





              </>
            </> : <Spinner />}
          <Row>
            <Col>
              <RemotePaginationForProgram
                data={facilityDetails ? facilityDetails : ""}
                columns={columns}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize ? totalSize : ""}
                fetchFacilityByGroup={getServiceProvided}
                FileExportName={fileExportName}
                onTableChange={(type, newState) => {
                  handleCategoryTableChange(type, newState);

                }
                }

              />


            </Col>

          </Row>
        </Card>

        <NewFacilityModal
          newFacilityModal={newFacilityModal}
          setNewFacilityModal={setNewFacilityModal}
          timeZoneList={timeZoneList}
          groupID={id}
        />
        <EditFacilityModal
          facilityAccessType={facilityAccessType}
          setEditFacilityModal={setEditFacilityModal}
          editFacilityModal={editFacilityModal}
          timeZoneList={timeZoneList}
          editType={editType}
          zipCodeList={m_zipList}
          editFacilityName={editFacilityName}
          setEditFacilityName={setEditFacilityName}
          editAddress1={editAddress1}
          setEditAddress1={setEditAddress1}
          editAddress2={editAddress2}
          setEditAddress2={setEditAddress2}
          editPrimaryPhone={editPrimaryPhone}
          setEditPrimaryPhone={setEditPrimaryPhone}
          editReferalFax={editReferalFax}
          setEditReferalFax={setEditReferalFax}
          editDepartment={editDepartment}
          setEditDepartment={setEditDepartment}
          editRoom={editRoom}
          setEditRoom={setEditRoom}
          editLatitude={editLatitude}
          setEditLatitude={setEditLatitude}
          editLongitude={editLongitude}
          setEditLongitude={setEditLongitude}
          editTimeZone={editTimeZone}
          setEditTimeZone={setEditTimeZone}
          editNPI={editNPI}
          setEditNPI={setEditNPI}
          editZipID={editZipID}
          editZipCode={editZipCode}
          setEditZipCode={setEditZipCode}
          editFloor={editFloor}
          setEditFloor={setEditFloor}
          editWing={editWing}
          setEditWing={setEditWing}
          editContactPerson={editContactPerson}
          setEditContactPerson={setEditContactPerson}
          editFacilityID={editFacilityID}
          setEditFacilityID={setEditFacilityID}
          facilityReload={facilityReload}
          setFacilityReload={setFacilityReload}
          editDescription={editDescription}
          setEditDescription={setEditDescription}
          setEditType={setEditType}
          accessType={accessType}
          setAccessType={setAccessType}
          isPrimary={isPrimary}
          setIsPrimary={setIsPrimary}
          editGroupID={id}
          setEditGroupID={setEditGroupID}
          editCenter={editCenter}
          setEditCenter={setEditCenter}

        />
        <FacilityStatusChangeModal
          facilityID={facilityID}
          facilityReload={facilityReload}
          setFacilityReload={setFacilityReload}
          status={status}
        />
        <EditCareRosterServiceProvided
          newServiceProvided={newServiceProvided}
          setNewServiceProvided={setNewServiceProvided}
          modal={modal}
          setModal={setModal}
          setReload={setReload}
          reload={reload}
          selectedId={selectedId}
          getServiceProvided={getServiceProvided}

        />
        <NotificationPreferenceModal

          notificationPreferenceModal={notificationPreferenceModal}
          setNotificationPreferenceModal={setNotificationPreferenceModal}
          newEmail={newEmail}
          setNewEmail={setNewEmail}
          id={id}
          facilityID={facilityID}
          currentFacilityStatus={currentFacilityStatus}
          facilityReload={facilityReload}
          setFacilityReload={setFacilityReload}
          centralNotification={centralNotification}
          setCentralNotification={setCentralNotification}
          setReloadFacilityDetails={setReloadFacilityDetails}
          reloadFacilityDetails={reloadFacilityDetails}
          fetchFacilityByGroup={getServiceProvided}
        />
        <DeleteConfirmationModal
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          deleteFunction={deleteServiceProvided}
        />
        <StatusChangeModalPrograms
          modalState={statusChangeModal}
          setModalState={setStatusChangeModal}
          status={status}
          updateCarePlanProgramStatus={updateCareRosterServiceStatus}
        />
      </Container>
    </div>
  );
}

export default CareRosterServiceProvided;