import React, { useState, useEffect } from "react";
import { Bar } from 'react-chartjs-2';
import {
    CardBody,
    Card,
    CardHeader,
    CardTitle,
    Spinner
} from "reactstrap";
import { RefreshCw } from "react-feather";
import AxiosInter from './../../../AxiosInter.js';
import Cookie from 'js-cookie';

const ReferralTrendBarChart = () => {
  // debugger

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  
  const [spinner, setSpinner] = useState(false);
  const [chartData, setChartData] = useState({});
  const [reloadChart, setReloadChart] = useState(false);
  const PreviousYear = new Date().getFullYear()-1;
  const CurrentYear = new Date().getFullYear();

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
  const fetchGraphDataAPI = () => {
    
    // debugger

    const apptCountNew  = []
    const apptCountOld  = []

    const previousYear = []
    const thisYear  = []
    setSpinner(true)
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-referral-monthly-trend/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    }).then(function (response) {

      // loop for seperating 2020 and 2021 data
      if( response.data)
      {
        for (const dataobj of response.data){ 
          if(dataobj.eachYearMonth?.includes(PreviousYear.toString())){
          previousYear.push(dataobj)
          } else if(dataobj.eachYearMonth?.includes(CurrentYear.toString())){
          thisYear.push(dataobj)
          }
        }
      }
        
        
        // loop for ordering 2020 data by month
        for (  const item of months ){
          for (const olditem of previousYear ){
            if( olditem.eachYearMonth.includes(item) ){
              apptCountOld.push(olditem.OrderCount)
            }
          }
        }

        // loop for ordering 2021 data by month
        for (  const item of months ){
          for (const newitem of thisYear ){
            if( newitem.eachYearMonth.includes(item) ){
              apptCountNew.push(newitem.OrderCount)
            }
          }
        }
        
        const forPreviousApptCount  = apptCountOld
        const forThisApptCount  = apptCountNew
        // debugger

        setChartData ({
          labels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sept','Oct','Nov','Dec'],
            datasets: [
              {
                label: PreviousYear,
                
                backgroundColor: [
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  'rgba(60, 187, 201,0.6)',
                  
              ],
              borderColor: [
                  'rgba(60, 187, 201,1)',
                  'rgba(60, 187, 201,1)',
                  'rgba(60, 187, 201,1)',
                  'rgba(60, 187, 201,1)',
                  'rgba(60, 187, 201,1)',
                  'rgba(60, 187, 201,1)',
                  'rgba(60, 187, 201,1)',
                  'rgba(60, 187, 201,1)',
                  'rgba(60, 187, 201,1)',
                  'rgba(60, 187, 201,1)',
                  'rgba(60, 187, 201,1)',
                  'rgba(60, 187, 201,1)',

              ],
                borderWidth: 1,
                data: forPreviousApptCount,
              },
              {
                label: CurrentYear,
                backgroundColor: [
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',
                  'rgba(34, 80, 201,0.455)',

              ],
              borderColor: [
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
                'rgba(34, 80, 201,1)',
              ],
                borderWidth: 1,
                data: forThisApptCount,
              }
            ]
              
          });


        setSpinner(false)
      })
      // .catch(function (error) {
      //   setSpinner(false)

      // });
  };


    const options = {
        maintainAspectRatio: false ,
        legend: {
            display: true
         },
         scales: {
            xAxes: [{
              
              /* gridLines: {
                color: "rgba(0,0,0,0.0)"
              }, */
              scaleLabel:{
                  display:true,
                  labelString:"Months",
                  fontSize:15,
                  fontColor:  "#3e4676",
                  fontFamily: "Jost, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif",
                  padding: 2,
              },
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                  min: 0
              },
              display: true,
             /*  gridLines: {
                color: "rgba(0,0,0,0)",
                fontColor: "#fff"
              }, */
              scaleLabel:{
                  display:true,
                  labelString:"Referral Count",
                  fontSize:15,
                  fontColor:  "#3e4676",
                  fontFamily: "Jost, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif",
                  padding: 4,
              },
            }]
          },
        
    };

    useEffect(() => {
    if (reloadChart) {
        fetchGraphDataAPI();
        setReloadChart(false);
    }
}, [reloadChart]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { fetchGraphDataAPI(); }, []) // eslint-disable-line
    return (
        <>
            <Card className="flex-fill w-100 referral-card">
                <CardHeader>
                    <div className="card-actions float-right">
                        <span className="cursor-pointer mr-1">
                            <RefreshCw
                                onClick={() => { setReloadChart(true); }}
                            />
                        </span>{" "}
                    </div>
                    <CardTitle tag="h5" className="mb-0">
                        Referral Trend Graph
                        <div className="separator" />
                    </CardTitle>
                </CardHeader>
                <CardBody className="d-flex bar-card-body-graph">
                    <div className="align-self-center w-100">
                       
                            <div className="chart chart-xs">
                                {spinner ? <Spinner /> : ""}
                                <div className="bar-chart-referral">
                                <Bar key={reloadChart} data={chartData} options={options} />

                                </div>
                            
                            </div>
                        
                    </div>
                </CardBody>
            </Card>
        </>
    )

};

export default ReferralTrendBarChart;