/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import FacilityDepartmentAssignRoomModal from "../../newComponents/utilities/FacilityDepartmentAssignRoomModal";
import BootstrapTable from 'react-bootstrap-table-next';
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditFacilityDepartmentModal from './EditFacilityDepartmentModal';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import DeleteConfirmationModal from '../../newComponents/utilities/DeleteConfirmationModal'


const FacilityDepartmentModal = (props) => {

  // DEPENDENCY VARIABLES
  const { facilityDepartmentModal,
    setFacilityDepartmentModal,
    setFacilityID,
    assignRoomModal,
    setAssignRoomModal,
    facilityID,
    departmentRoomDetails,
    setDepartmentRoomDetails,
    roomOptions,
    setDepartmentOptions,
    departmentOptions,
    editFacilityDepartmentModal,
    setEditFacilityDepartmentModal,
    getAssignRoomDetails

  } = props

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const [spinner, setSpinner] = useState(false);// eslint-disable-line


  const [editDepartment, setEditDepartment] = useState("");
  const [editRoom, setEditRoom] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [roomsArray, setRoomsArray] = useState();
  const [deleteModal,setDeleteModal] = useState(false);


  const toggle = () => {
    setFacilityDepartmentModal(!facilityDepartmentModal);
  }

  const deleteAssignRoomDetails = (id) => {
      setSpinner(true);
      const params =
      {
        "facility_id":localStorage.getItem('facilityId') ? localStorage.getItem('facilityId') : "",
        "id": id ? id : ""
      
      }
  
  
      AxiosInter.post(`${API_ENDPOINT}/admin-api/delete-facility-assign-room/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then(response => {
          toastr.success("Success","Deleted successfully");
          setSpinner(false);
          setDeleteModal(false);
          getAssignRoomDetails();

        })
        .catch(error => {
         toastr.error("Error","Something went wrong")
         setSpinner(false);
        });
    };
  
  
  const departmentActionsFormatter = (cell, row) => {

    return (
      <>
      <div style={{'gap':'20px','display':'flex','justifyContent':'center'}}>
        
            <FontAwesomeIcon
              icon={faEdit}
              color={'#3f86fa'}
              style={{ 'cursor': 'pointer' }}
              onClick={() => {
                // debugger;
                setEditRoom(row.room)
                setEditDepartment(row.department)
                setEditFacilityDepartmentModal(true);



              }}

              title="Edit"

            />
              <FontAwesomeIcon
                icon={faTrash}
                color = {'#E12C2C'}
                size = {'1x'}
                style = {{'cursor':'pointer'}}
                onClick={() => {
                  setFacilityID(row.facilityID)
                  deleteAssignRoomDetails(row.id);
                 }}
              
                title = "Delete"

              />
         

        </div>

      
      </>
    )
  }

  const columns = [

    {
      dataField: 'department',
      text: 'Department'
    },
    {
      dataField: 'room',
      text: 'Room'
    },
    {
      text: 'Actions',
      formatter: departmentActionsFormatter
    }
  ];





  useEffect(() => {
    if (!roomOptions && !departmentOptions) {
      setSpinner(true)
    }

  }, [roomOptions, departmentOptions])

  useEffect(() => {
    if (!facilityDepartmentModal) {
      setFacilityID("")
      setDepartmentRoomDetails([])
    }
  }, [facilityDepartmentModal]// eslint-disable-line
  )
  // useEffect(() => {
  
      
  //     setRoomsArray(editRoom.toString().split(","))
     
    
  // }, [editRoom]// eslint-disable-line
  // )

  return (
    <div >
      <Modal isOpen={facilityDepartmentModal}  size="lg" >
        <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center', 'height': '50%' }} toggle={toggle}>Departments</ModalHeader>
        {spinner ? <Spinner /> : ""}
        <ModalBody >
          <Button className="Add-btn pull-right"
            disabled={spinner ? true : false}
            onClick={() => {
              setAssignRoomModal(true)
            }}
          >Assign Room</Button><br /><br />
          <BootstrapTable
            keyField='id'
            data={departmentRoomDetails}
            columns={columns}
            noDataIndication="No records found"
          />
        </ModalBody>
        <ModalFooter className="advanced-search-button">
          <Button outline className="no-button button-width" onClick={toggle}>Close</Button>
        </ModalFooter>
      </Modal>
      <FacilityDepartmentAssignRoomModal
        assignRoomModal={assignRoomModal}
        setAssignRoomModal={setAssignRoomModal}
        setDepartmentOptions={setDepartmentOptions}
        departmentOptions={departmentOptions}
        roomOptions={roomOptions}
        facilityID={facilityID}
        setFacilityDepartmentModal={setFacilityDepartmentModal}
        facilityDepartmentModal={facilityDepartmentModal}
        getAssignRoomDetails={getAssignRoomDetails}

      />

      <EditFacilityDepartmentModal
        departmentOptions={departmentOptions}
        roomOptions={roomOptions}
        editFacilityDepartmentModal={editFacilityDepartmentModal}
        setEditFacilityDepartmentModal={setEditFacilityDepartmentModal}
        editDepartment={editDepartment}
        setEditDepartment={setEditDepartment}
        editRoom={editRoom}
        setEditRoom={setEditRoom}
        roomsArray = {roomsArray}
        facilityID = {facilityID}
        setFacilityDepartmentModal = {setFacilityDepartmentModal}
        facilityDepartmentModal = {facilityDepartmentModal}
        getAssignRoomDetails = {getAssignRoomDetails}
      />
        <DeleteConfirmationModal
        deleteModal = {deleteModal}
        setDeleteModal = {setDeleteModal}
        deleteFunction = {deleteAssignRoomDetails}


        />
    </div>

  );
}

export default FacilityDepartmentModal;