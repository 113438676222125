import React, { useEffect, useState } from "react";
import AxiosInter from './../../AxiosInter.js';
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Label,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import HabitStatusChangeModal from "../../newComponents/utilities/HabitStatusChangeModal";
import DeleteConfirmationModal from "../../newComponents/utilities/DeleteConfirmationModal";
import NewHabitModal from "../../newComponents/utilities/NewHabitModal";
import EditAllHabitModal from "../../newComponents/utilities/EditHabit";
import { faEdit, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css"; //import reat-datepicker css
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MomentConfig from "../../common/MomentConfig";
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import Cookie from 'js-cookie';


const AllHabit = () => {
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const API_TOKEN=Cookie.get("access_token_admin");
  const fileExportName = "Habit";
  // eslint-disable-next-line no-unused-vars
  const [spinner, setSpinner] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [search, setSearch] = useState(false);
  const [habitList, setHabitList] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [habitID, setHabitID] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [editHabitModal, setEditHabitModal] = useState(false);
  const [newHabitModal, setNewHabitModal] = useState(false);

  const [editHabitID, setEditHabitID] = useState("");
  const [editGroupName, setEditGroupName] = useState("");
  const [editCategory, setEditCategory] = useState("");
  const [rowData, setRowData] = useState("");

  const [groupName, setGroupName] = useState("");
  const [category, setCategory] = useState("");

  const [status, setStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [groupID, setGroupID] = useState("");

  const [userDataReload, setUserDataReload] = useState(0);
  const [userSizePerPage, setUserSizePerPage] = useState("50");
  const [offset, setOffset] = useState("0");
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isDataEmpty, setIsDataEmpty] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  let { id } = useParams();
  let loader = null;
  const selectedName = () => {
    setGroupName(window.atob(id));
  };

  const goBack = () => {
    navigate(-1);
  };


  if (apiLoaded) {
    loader = <Spinner />;
  }
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;
  const statusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];

  const clearValues = () => {
    setCategory("");

    setGroupName("");
    setSelectedStatus("");

    setHabitList("");

    setGroupID("");

    setHabitList("");
    setSortOrder("");
    setTotalSize(0);
    setUserDataReload(userDataReload + 1)

    //setApiLoaded(true)
  };
  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setHabitID(row.id);
          setStatus(cell);
          setModalState(true);
          // console.log(row.ID);
        }}
      >
        {status}
      </span>
    );
  };

  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive";
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faEdit}
              size={"1x"}
              color={"#3f86fa"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                // debugger;
                setRowData(row);
                setEditHabitModal(true);
                // editFacilityData(row.address1, row.address2, row.contactPerson, row.department, row.description1, row.floor, row.id, row.latitude, row.longitude, row.name1, row.npi, row.primaryPhone, row.referralFax, row.room, row.type1, row.wing, row.workTime, row.zipID, row.facilityType, row.isPrimary)
                setEditHabitID(row.id);
                // setEditGroupName(row.name);
                // setEditCategory(row.category);

                if (row.category) {
                  setEditCategory({
                    value: (row ? row.id : "N/A").toString(),
                    label: (row ? row.category : "N/A").toString(),
                  });
                }
                if (row.name) {
                  setEditGroupName({
                    value: (row ? row.groupID : "N/A").toString(),
                    label: (row ? row.name : "N/A").toString(),
                  });
                }

                // debugger
              }}
              title="Edit Habit"
            />
          </Col>
          {/* <Col sm={2}>
            <FontAwesomeIcon
              icon={faTrash}
              color = {'#E12C2C'}
              style = {{'cursor':'pointer'}}
              onClick={() => {
                setHabitID(row.id);
                setDeleteModal(true);
               }}
            
              title = "Delete"

            />
        </Col> */}
        </Row>
      </>
    );
  };

  const deleteHabitDetails = () => {
    const params = {
      habit_ID: habitID ? habitID : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/delete-habit/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        toastr.success("Success", "Deleted successfully");
        setDeleteModal(false);
        setUserDataReload(userDataReload + 1);
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong");
      });
  };

  const columns = [
    {
      dataField: "name",
      text: "Group Name",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "category",
      text: "Category",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormatter,
      align: "center",
      csvFormatter: statusFormatterCSV,
    },
    {
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];

  const onTableChangeForuser = (type, newState) => {
    // console.log(newState)
    // console.log(newState.sortOrder)
    setPage(newState.page);
    setUserSizePerPage(newState.sizePerPage);
    // if(newState.sortOrder == "desc")
    //  { newState.sortOrder = "asc"}

    setOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("habit.groupID");
      setSortOrder("desc");
      setUserDataReload(userDataReload + 1);
    } else if (newState.sortField === "name") {
      setSortColumn("g.name");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    }
  };

  const exportTableData = () => {
    const params = {
      file_name: fileName ? fileName : "",
      groupID: id ? window.atob(id) : "",
      category: category ? category.label : "",
      habit_status: selectedStatus ? selectedStatus.value : "",
      record_limit: userSizePerPage ? userSizePerPage : "50",
      offset: offset ? offset.toString() : "0",
      search_keyword: searchKeyword ? searchKeyword : "",
      is_pagination: "",
      sortOrder: sortOrder ? sortOrder : "DESC",
      sortColumn: sortColumn ? sortColumn : "habit.groupID",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/export-Habit-table-data/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const searchHabit = () => {
    //setSpinner(true);
    setApiLoaded(true);

    // let params = {}

    // if(searchKeyword.length > 0){

    //     params = {
    //     "groupID":"",
    //     "category":"",
    //     "habit_status":"",
    //     "record_limit": userSizePerPage ? userSizePerPage: "50",
    //     "offset": offset ? offset.toString() : "0",
    //     "search_keyword": searchKeyword ? searchKeyword : "",
    //     "is_pagination": "" ,
    //     "sortOrder": sortOrder ? sortOrder : "desc",
    //     "sortColumn": sortColumn ? sortColumn : "habit.groupID",

    //   }

    //   }
    //   else {
    const params = {
      // "groupID":groupName ? groupName.value:"",
      groupID: id ? id : "",

      category: category ? category.label : "",
      habit_status: selectedStatus ? selectedStatus.value : "",
      //  "group_ID":gp_string,
      record_limit: userSizePerPage ? userSizePerPage : "50",
      offset: offset ? offset.toString() : "0",
      search_keyword: searchKeyword ? searchKeyword : "",
      is_pagination: "",
      sortOrder: sortOrder ? sortOrder : "desc",
      sortColumn: sortColumn ? sortColumn : "habit.groupID",
    };

    // }

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/list-habit-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then(function (response) {
        if (response.data.data.length > 0) {
          setHabitList(response.data.data);
          localStorage.setItem("offset", offset.toString());
          setTotalSize(response.data.record_count);
          setApiLoaded(false);
          //setSpinner(false);
        } else {
          setHabitList("");
          setApiLoaded(false);
          // setSpinner(false);
          // setIsDataEmpty(true);
        }
      })
      .catch(function (error) {
        setHabitList("");
        setApiLoaded(false);
        // setSpinner(false);
      });
  };

  const enableOrDisableSearch = () => {
    if (groupName || category || selectedStatus) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };
  useEffect(() => {
    if (newHabitModal) {
      selectedName();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newHabitModal]);

  useEffect(() => {
    enableOrDisableSearch();
  }, [category, selectedStatus, groupName]); // eslint-disable-line
  // useEffect(() => { if(groupNamesForDropDown)createGroupOption(); }, [groupNamesForDropDown])

  useEffect(() => {
    if (userDataReload >= 1) {
      searchHabit();
      localStorage.setItem("offset", "0");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataReload]);

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset) {
      searchHabit();
    }
  }, [offset]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length === 0) setUserDataReload(userDataReload + 1);
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      // setPage
      setTotalSize(0);
      setUserDataReload(userDataReload + 1);
      // setReloadGroupDetails(true);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  useEffect(() => {
    if (location.state) {
      localStorage.setItem(
        "isFilterApplied",
        JSON.stringify(location.state.detail)
      );
    }
  }, [location]);

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const categoryloadOptions = async (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-category-list/${inputValue}`,
          {
            headers: {
              Authorization: API_TOKEN,
            },
          })
          .then((response)=>response.data.data)
          .catch ((error) =>{
            throw error;
          });
    }
  };
  

  const clearSearchValues = () => {
    setSearchKeyword("");
    setUserDataReload(userDataReload + 1);
  };
  return (
    <div>
      <Hux>
        {loader}
        <Container fluid>
          <Header>
            <HeaderTitle>
              Habits
              <Button
                className="Add-btn pull-right"
                onClick={() => {
                  setNewHabitModal(true);
                }}
              >
                Add New Habit
              </Button>
            </HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>habit</BreadcrumbItem>

              <BreadcrumbItem>
                <Link to="/groups">Groups</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Group Habits</BreadcrumbItem>
            </Breadcrumb>
          </Header>
          <Card className="adv-search-background-card-pt2">
            <Container fluid>
              <Row style={{ marginBottom: "0rem" }}>
                <div style={{ justifyContent: "left" }}>
                  <FontAwesomeIcon
                    size={"lg"}
                    style={{ cursor: "pointer" }}
                    title={"Go Back"}
                    icon={faArrowLeft}
                    onClick={goBack}
                  />
                </div>
                <Col>
                  <h2 style={{ textAlign: "center" }}>
                    {localStorage.getItem("groupName")}
                  </h2>
                </Col>
              </Row>
            </Container>
          </Card>
        </Container>
      </Hux>

      <Card className="adv-search-background-card">
        <CardHeader>
          <Row>
            <Col sm="3">
              <Label>Habit category</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="name"
                placeholder="Search with at least 4 characters."
                value={category}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleCategoryChange}
                loadOptions={(inputValue) => categoryloadOptions(inputValue)}
              />
            </Col>

            {/*                   
              <Col sm="2">
                      <Label>Group Name</Label>
                      <AsyncSelect
                        cacheOptions
                        isClearable
                        isSearchable
                      
                          type="text"
                          name="groupName"
                          placeholder="Search with at least 4 characters."
                          value={groupName}
                          getOptionLabel={e => e.label}
                          getOptionValue={e => e.value}
                          validate={{
                              maxLength: {value: 120}
                            }} 
                            onInputChange={handleInputChange}
                            onChange={handleGroupNameChange}
                          loadOptions={inputValue =>groupNameloadOptions(inputValue)}
                            />
                    </Col>*/}
            <Col sm="2" md="2" lg="2">
              <Label>Status</Label>
              <Select
                name="Status"
                options={statusOptions}
                placeholder="Choose status"
                isClearable
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e);
                }}
              />
            </Col>

            <Col sm="3">
              <Button
                className="patient-go-buttons"
                disabled={!search ? true : false}
                title={
                  spinner || !search
                    ? "Choose at least one item to search "
                    : ""
                }
                onClick={() => {
                  offset === "0" ? searchHabit() : setOffset("0");
                }}
              >
                Go
              </Button>{" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => {
                  clearValues();
                }}
                disabled={!search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <div className="separator" />
        </CardHeader>

        <div className="groups-search">
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={columns}
            clearValues={clearSearchValues}
          />
        </div>

        <div>{spinner ? <Spinner /> : ""}</div>

        <RemotePagination
          data={habitList ? habitList : ""}
          columns={columns}
          page={page}
          sizePerPage={userSizePerPage}
          FileExportName={fileExportName}
          exportTableData={exportTableData}
          isGlobalSearch={isGlobalSearch}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            onTableChangeForuser(type, newState);
          }}
          isDataEmpty={isDataEmpty}
          setIsGlobalSearch={setIsGlobalSearch}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
        />
      </Card>
      <div>
        <EditAllHabitModal
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
          editHabitID={editHabitID}
          setEditHabitID={setEditHabitID}
          editHabitModal={editHabitModal}
          setEditHabitModal={setEditHabitModal}
          rowData={rowData}
          editGroupName={editGroupName}
          setEditGroupName={setEditGroupName}
          editCategory={editCategory}
          setEditCategory={setEditCategory}
        />

        <NewHabitModal
          newHabitModal={newHabitModal}
          setNewHabitModal={setNewHabitModal}
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
          setNewGroupName={setGroupName}
          newGroupName={groupName}
        />
        <HabitStatusChangeModal
          modalState={modalState}
          setModalState={setModalState}
          setStatus={setStatus}
          status={status}
          habitID={habitID}
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
        />
      </div>

      <DeleteConfirmationModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteFunction={deleteHabitDetails}
      />
    </div>
  );
};

export default AllHabit;
