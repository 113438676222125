import React, { useEffect, useState } from "react";
import { Container, Breadcrumb, BreadcrumbItem, Card, CardHeader, Row, Col, Label, Input, Button, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Cookie from 'js-cookie';
import moment from "moment";
import AxiosInter from './../../AxiosInter';
import MomentConfig from "../../common/MomentConfig";
import { toastr } from "react-redux-toastr";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "react-datepicker/dist/react-datepicker.css";
import CustomDataRangePicker from "../../components/CustomDataRangePicker";

const blankState = (
    <Card className="blank-state">
        <CardBody>
            <h3>No Records Available</h3>
        </CardBody>
    </Card>
)

function EmiApproval() {

    const [data, setData] = useState([])
    const [numberOfRecords, setNumberOfRecords] = useState(0)
    const [searchKeyword, setSearchKeyword] = useState("")
    const [blankStateStatus, setBlankStateStatus] = useState(false)
    const [loading, setLoading] = useState(false) // eslint-disable-line
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(50);
    const [totalSize, setTotalSize] = useState(0);
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState("");
    const [reload, setReload] = useState(false)
    const [selectedReason, setSelectedReason] = useState("")
    const [selectedStatus, setSelectedStatus] = useState("")
    const [offset, setOffset] = useState(0);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [buttonClickReset, setButtonClickReset]=useState(false)

    const fileExportName = "EmiData";
    const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`;

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");

    const statusOptions = [
        { value: '0', label: 'Not Processed' },
        { value: '1', label: 'Success' },
        { value: '2', label: 'Failure' },
    ]

    const reasonForFailureOptions = [
        { value: '10', label: 'Referral order is missing' },
        { value: '20', label: 'Patient insurance is missing' },
        { value: '30', label: 'RefCatDesc or RefStatDesc is not satisfing' },
        { value: '40', label: 'EMAIL SEND FAILED' },
        { value: '50', label: 'Patient is missing' },
        { value: '60', label: 'patient found but no order in new or processed with opthamplogy and eyemanagement' },
        { value: '70', label: 'Error in script' },
        { value: '80', label: 'Duplicate Entry' },
        { value: '100', label: 'NULL' }
    ]

    const resetValues = () => {
        setSearchKeyword("")
        setSelectedReason("")
        setSelectedStatus("")
        setStartDate("")
        setEndDate("")
        setReload(true)
    }

    const clearValues = () => {
        setSearchKeyword("")
        setReload(true)
    }


    const fetchEmiData = () => {

        const params = {
            ReasonForFailure: selectedReason ? selectedReason.label : "",
            EmiStatus: selectedStatus ? selectedStatus.value : "",
            startDate: startDate ? startDate : "",
            endDate: endDate ? endDate : "",
            is_pagination: "1",
            record_limit: sizePerPage ? sizePerPage : "",
            search_keyword: searchKeyword ? searchKeyword : "",
            offset: offset ? offset.toString() : "0",
            sortOrder: sortOrder ? sortOrder : "",
            sortColumn: sortColumn ? sortColumn : ""
        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/get-referral-emi-approval-data/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((res) => {
                if (res.data.data.length > 0) {
                    setNumberOfRecords(res.data.record_count)
                    setTotalSize(res.data.record_count)
                    setData(res.data.data)
                    setLoading(false)
                    setReload(false)
                }
                else {
                    setData([])
                    setNumberOfRecords(0)
                    setLoading(false)
                    setReload(false)
                }
            })
            .catch(function (error) {
                setData([])
                setLoading(false)
                setReload(false)
            })
    };

    const exportEmiData = () => {
        const params = {
            ReasonForFailure: selectedReason ? selectedReason.label : "",
            EmiStatus: selectedStatus ? selectedStatus.value : "",
            startDate: startDate ? startDate : "",
            endDate: endDate ? endDate : "",
            file_name: fileName ? fileName : "",
            is_pagination: "1",
            record_limit: "",
            search_keyword: searchKeyword ? searchKeyword : "",
            offset: offset ? offset.toString() : "0",
            sortOrder: sortOrder ? sortOrder : "",
            sortColumn: sortColumn ? sortColumn : ""
        }
        AxiosInter.post(`${API_ENDPOINT}/admin-api/export-referral-emi-approval-data/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
            responseType: "blob",
        })
            .then((res) => {
                if (res.status === 200) {
                    var fileDownload = require("js-file-download");
                    fileDownload(res.data, `${fileName}`, res["headers"]["x-suggested-filename"]);
                    toastr.success("Success", "Export successful");
                }
            })
    }

    const basicValidation = (cell) => {
        return cell ? cell : "N/A";
    };

    const dobFormatter = (cell) => {
        return cell ? moment(cell).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME) : "N/A";
    };

    const statusFormatter = (cell, row) => {
        // eslint-disable-next-line eqeqeq
        if (cell == 1) {
            return "Success"
        // eslint-disable-next-line eqeqeq
        } else if (cell == 0) {
            return "Not Processed"
         // eslint-disable-next-line eqeqeq          
        } else if (cell == 2) {
            return "Failure"
        } else {
            return "N/A"
        }
    }


    const columns = [
        {
            dataField: "AuthNum",
            text: "AuthNum",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "PatNameF",
            text: "PatNameF",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "PatNameL",
            text: "PatNameL",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "PatDob",
            text: "Patient DOB",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "DetermDate",
            text: "Determ Date",
            sort: true,
            formatter: dobFormatter,
            csvFormatter: dobFormatter,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "MemNum",
            text: "MemNum",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "RefCatDesc",
            text: "RefCatDesc",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "PcpNum",
            text: "PcpNum",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "PcpName",
            text: "PcpName",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "PcpPhone",
            text: "PcpPhone",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "PcpFax",
            text: "PcpFax",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "ReqRcvd",
            text: "Request Received",
            sort: true,
            formatter: dobFormatter,
            csvFormatter: dobFormatter,
            headerStyle: { width: '125px' },
            style: { width: '125px' },

        },
        {
            dataField: "RefStatDesc",
            text: "RefStatDesc",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },

        },
        {
            dataField: "ValidFrom",
            text: "ValidFrom",
            sort: true,
            formatter: dobFormatter,
            csvFormatter: dobFormatter,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "ValidTo",
            text: "ValidTo",
            sort: true,
            formatter: dobFormatter,
            csvFormatter: dobFormatter,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "ValidDays",
            text: "ValidDays",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "Diag1",
            text: "Diag1",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "Diag2",
            text: "Diag2",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "Diag3",
            text: "Diag3",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "ProvName",
            text: "ProvName",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "ProvNameF",
            text: "ProvNameF",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "ProvNameL",
            text: "ProvNameL",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "ProvFax",
            text: "ProvFax",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "ProvPhone",
            text: "ProvPhone",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "created_at",
            text: "Created at",
            sort: true,
            formatter: dobFormatter,
            csvFormatter: dobFormatter,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "updated_at",
            text: "Updated at",
            sort: true,
            formatter: dobFormatter,
            csvFormatter: dobFormatter,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "reason_for_failure",
            text: "Reason for Failure",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "status",
            text: "Status",
            sort: true,
            formatter: (cell, row) => statusFormatter(cell, row),
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
        {
            dataField: "order_id",
            text: "Order Id",
            sort: true,
            formatter: basicValidation,
            headerStyle: { width: '125px' },
            style: { width: '125px' },
        },
    ]

    const handleTableChange = (type, newState) => {
        setPage(newState.page);
        setSizePerPage(newState.sizePerPage);
        setOffset((newState.page - 1) * newState.sizePerPage);

        if (newState.sortField !== undefined && newState.sortOrder !== undefined) {
            setSortColumn(newState.sortField);
            setSortOrder(newState.sortOrder);
            setReload(true);
        } else {
            setSortColumn("");
            setSortOrder("");
        }       
    }


    useEffect(() => {
        if (data.length === 0) {
            setBlankStateStatus(true)
        } else {
            setBlankStateStatus(false)
        }
    }, [data]);

    useEffect(() => {
        if (reload) {
            fetchEmiData()
        }
    }, [reload]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchEmiData()

    }, [searchKeyword]) // eslint-disable-line react-hooks/exhaustive-deps


    const checkDataExist = () => {

        if (selectedReason.length <= 0 && selectedStatus.length <= 0 && startDate.length <= 0 && endDate.length <= 0) {
            return true
        } else {
            return false
        }
    }

    return (
        <Container fluid>
            <Header>
                <HeaderTitle>
                    EMI Approval
                </HeaderTitle>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <Link to="/dashboard">Dashboard</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>EMI Approval</BreadcrumbItem>
                </Breadcrumb>
            </Header><ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
            >{(props) => (
                <div>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col sm="4">
                                    <Label>Reason for Failure</Label>
                                    <Select placeholder="Select Reason"
                                        options={reasonForFailureOptions}
                                        value={selectedReason}
                                        onChange={(e) => {
                                            setSelectedReason(e)
                                        }}
                                    />
                                </Col>
                                <Col sm="2">
                                    <Label>Status</Label>
                                    <Select placeholder="Select Status"
                                        options={statusOptions}
                                        value={selectedStatus}
                                        onChange={(e) => {
                                            setSelectedStatus(e)
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Label>Create Date:</Label>
                                    <CustomDataRangePicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                        buttonClickReset={buttonClickReset}
                                    />
                                </Col>

                                <Col>
                                    <Button
                                        className="patient-go-buttons"
                                        onClick={() => {
                                            setSearchKeyword("")
                                            fetchEmiData()

                                        }}
                                        disabled={checkDataExist()}
                                        title={checkDataExist() ? 'Choose at least one item to search' : ""}
                                    >Go</Button>
                                    <Button className="patient-reset-buttons"
                                        type="reset"
                                        onClick={() => {
                                            resetValues()
                                            setButtonClickReset((prevState)=>(!prevState))
                                        }}
                                        disabled={checkDataExist()}
                                        title={checkDataExist() ? "Nothing to reset" : ""}
                                    >Reset</Button>
                                </Col>
                            </Row>
                            <div className="separator" />
                        </CardHeader>
                        <CardHeader>
                            <Row>
                                <Col sm="2">
                                    <div className="number-of-records">
                                        <p>Number of Records: {numberOfRecords}</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="c-search-field form-inline justify-content-end">
                                        <div className="form-group mr-2">
                                            <Input placeholder="Search"
                                                autoFocus="autofocus"
                                                id="search"
                                                value={searchKeyword}
                                                onChange={(e) => {
                                                    setSearchKeyword(e.target.value)
                                                }}
                                            />
                                        </div>
                                        <Button className="clear-search-button"
                                            onClick={() => {
                                                clearValues()
                                            }}
                                            disabled={searchKeyword ? false : true}
                                            title={searchKeyword ? "" : "Nothing to clear"}
                                        >Clear</Button>
                                        <Button className="btn btn-export"
                                            onClick={() => {
                                                exportEmiData()
                                            }}
                                            disabled={data.length>0? false:true}
                                            title={data.length>0? '': 'Nothing to Export'}
                                        >Export</Button>
                                    </div>
                                </Col>
                            </Row>
                            <div className="separator" />
                        </CardHeader>
                        <CardBody className="patient-list dropdown-menu-up">
                            {blankStateStatus ? blankState : ""}
                            {!blankStateStatus ?
                                <div className="emitable">
                                    <BootstrapTable
                                        bootstrap4
                                        bordered={false}
                                        hover
                                        remote
                                        keyField="id"
                                        data={data}
                                        columns={columns}
                                        className="react-bootstrap-table"
                                        pagination={paginationFactory({
                                            page: page,
                                            sizePerPage: sizePerPage,
                                            sizePerPageList: [50, 100, 150, 200],
                                            totalSize

                                        })}
                                        onTableChange={(type, newState) => {
                                            handleTableChange(type, newState);
                                        }}
                                    /> </div> : ""}
                        </CardBody>
                    </Card>
                </div>
            )}
            </ToolkitProvider>
        </Container>
    )
}

export default EmiApproval;

