import React, { useState, useEffect, useContext } from 'react';
import { GlobalState } from '../../contextAPI/Context';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,Input,Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from "react-select";

const NewReferralFulfillerModal = (props) => {
    const { ReferralFulfillerAddModal, setReferralFulfillerAddModal } = props;

    // CONTEXT VARIABLES 
    const { referralFulfillerReload, setReferralFulfillerReload, } = useContext(GlobalState);
    
    
    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    const [optionsForGroupList, setOptionsForGroupList] = useState([]);
    const [newGroupName, setNewGroupName] = useState("");
    const [newGroupID, setNewGroupID] = useState("");
    const [newAppointmentSMSNote, setNewAppointmentSMSNote] = useState("");
    const [newDataExchangeEnabled, setNewDataExchangeEnabled] = useState({ value: '0', label: "No" });
    const [newSubscriptionType, setNewSubscriptionType] = useState("");
    const [newRecapGenerationEnabled, setNewRecapGenerationEnabled] = useState({ value: '1', label: "Yes" });
    const [newIsClinical, setNewIsClinical] = useState({ value: '1', label: "Yes" });
    const [newIsTransport, setNewIsTransport] = useState({ value: '1', label: "Yes" });
    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [categoryType, setCategoryType] = useState([]);
    const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);
 
    const toggle = () => { 
        setNewGroupName("")
        setReferralFulfillerAddModal(!ReferralFulfillerAddModal); 
        setNewGroupID("");
        setNewAppointmentSMSNote("")
        setNewDataExchangeEnabled("")
        setNewSubscriptionType("")
        setNewRecapGenerationEnabled("")
        setNewIsClinical({ value: '1', label: "Yes" })
        setNewIsTransport({ value: '1', label: "Yes" })
        setCategoryType([])
    }

    

    const confirmation = () => { 
        if (categoryType.length===0) {
            toastr.error("Please Select Category");
          }
        if(newGroupName && newSubscriptionType &&categoryType.length!==0){
            CreateReferralSender()
        }
    };

    const FetchGroupListAPI = () => {

        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-referral-fulfiller-only/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(function (response) {
                setOptionsForGroupList(response.data.data)

            })
            .catch(function (error) {
                toastr.error("Something Is Wrong", "Please try again")
            });
    }


    const CreateReferralSender = () => {
        if (categoryType) {
            var categoryTypesResult = categoryType.map((item) => {
                return item.value.toString();
            });
        }
        
        AxiosInter.post(`${API_ENDPOINT}/admin-api/create-referral-fulfiller/`, {

            "group_id": newGroupID ? Number(newGroupID.value) : 0,
            "appointment_sms_note": newAppointmentSMSNote ? newAppointmentSMSNote.toString() : "",
            "data_exchange_enabled": Number(newDataExchangeEnabled.value),
            "subscription_type": newSubscriptionType.label.toString(),
            "recap_generation_enabled": Number(newRecapGenerationEnabled.value),
            "is_clinical": Number(newIsClinical.value),
            "is_transport": Number(newIsTransport.value),
            "category_type": categoryTypesResult.sort()         
          }, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(function (response) {
                // console.log(response);
                setReferralFulfillerAddModal(!ReferralFulfillerAddModal)
                setReferralFulfillerReload(referralFulfillerReload + 1)
                toastr.success("Successfully Added", "Referral fulfiller details successfully added")
                toggle();
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Failed", "Please try again")
            });
    }

    const YesNoOptions = [
        { value: '1', label: "Yes" },
        { value: '0', label: "No" }
    ]

    const SubOptions = [
        { value: 'LITE', label: "Community" },
        {value: 'LITE PLUS', label: "Community Plus" },
        { value: 'FULL', label: "Enterprise" },
    
    ]
    
       
    const disableAddButton = () => {

        if (
            newSubscriptionType &&
            newGroupName 
        ) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }
    const fetchCategoryTypeOptions = () => {
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-category-type-options/`, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then((response) => {
                if (response.status === 200) {

                    setCategoryTypeOptions(response.data.data)

                }

            })
            .catch((error) => {
                console.log(error)
            });
    }
    useEffect(() => {
        if(ReferralFulfillerAddModal === true){
            fetchCategoryTypeOptions();
            FetchGroupListAPI()
        }

    }, [ReferralFulfillerAddModal]) // eslint-disable-line

    useEffect(() => { disableAddButton() }, [newSubscriptionType, newGroupName]) // eslint-disable-line
    useEffect(() => { setNewIsClinical({ value: '1', label: "Yes" }) }, []) // eslint-disable-line

     return (
        <div>
            <Modal isOpen={ReferralFulfillerAddModal}   size="md" >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Add New Referral Fulfiller </ModalHeader>
                <ModalBody >
                    <FormGroup>
                        
                         <Row>
                            <Col>
                            <Label for="name">Group Name <span className="required">*</span></Label>
                                 <Select
                                    placeholder="Select"
                                    
                                    options={optionsForGroupList.map((item) => ({
                                        value: item.id,
                                        label: item.name
                                      }))}
                                    value={newGroupName}
                                    onChange={(e) => {
                                        setNewGroupID(e)
                                        setNewGroupName(e);
                                    }}
                                />
                            </Col>
                        </Row>
                        <br></br>
                         <Row>
                                <Col >
                                <Label for="address">Appointment SMS Note </Label>
                                 <Input
                                    name="sms"
                                    value={newAppointmentSMSNote}
                                    onChange={(e) => {
                                        setNewAppointmentSMSNote(e.target.value);
                                    }}
                                />
                                </Col> 
                                <Col >
                                <Label for="address">Subscription Type <span className="required">*</span> </Label>
                                 <Select
                                    name="doc-type"
                                    placeholder="Select"
                                    options={SubOptions}
                                    value={newSubscriptionType}
                                    onChange={(e) => {
                                        setNewSubscriptionType(e);
                                    }}
                                />
                                </Col> 
                                <Col >
                                <Label for="data">Data Exchange Enabled </Label>
                                 <Select
                                    name="data"
                                    placeholder="Select"
                                    options={YesNoOptions}
                                    value={newDataExchangeEnabled}
                                    onChange={(e) => {
                                        setNewDataExchangeEnabled(e);
                                    }}
                                />
                                </Col> 
                              
                         </Row>
                         <br></br>
                         <Row>
                                <Col >
                                <Label for="recap">Recap Generation Enabled </Label>
                                 <Select
                                    name="recap"
                                    placeholder="Select"
                                    options={YesNoOptions}
                                    value={newRecapGenerationEnabled}
                                    onChange={(e) => {
                                        setNewRecapGenerationEnabled(e);
                                    }}
                                />
                                </Col> 
                                <Col sm={8}>
                                <Label for="categoryType">Category <span className="required">*</span> </Label>
                                <Select
                                    isMulti
                                    options={categoryTypeOptions}
                                    onChange={(event) => setCategoryType(event)}
                                    value={categoryType}
                                    placeholder="Select"
                                    isClearable
                                />
                            </Col> 
                                {/* <Col>
                                <Label for="transport">Is Transport </Label>
                                 <Select
                                    name="clinical"
                                    placeholder="Select"
                                    options={YesNoOptions}
                                    value={newIsTransport}
                                    onChange={(e) => {
                                        setNewIsTransport(e);
                                    }}
                                />
                                </Col>  */}
                               
                         </Row>

                    </FormGroup>
                 </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer" >
                    <Button 
                    className="yes-button button-width"  
                    onClick={confirmation}
                    disabled={addButtonStatus}
                    title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewReferralFulfillerModal;