import React, { useState , useEffect} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,Label,Input, FormGroup, Spinner } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
// import Select from 'react-select';

  

const EditServiceProvidedModal = (props) => {
    const {
        // rowData,
        newServiceProvided,
        setNewServiceProvided,
        modal,
        setModal,
        // OptionsOfMPermission,
        setReload,
        reload,
        selectedId
    } = props;

    
    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    const [permission, setPermission] = useState("")//eslint-disable-line
    const [spinner, setSpinner] = useState(false)

    
    let res = ""//eslint-disable-line

    const [buttonBlankState,setButtonBlankState] = useState(true);


    const toggle = () => { setModal(!modal); setNewServiceProvided("") }
 
    const confirmation = () => { 

        if(!newServiceProvided){
            toastr.error("Please enter a service")
        }else{
                updateCarePlanCategory()
            }

        }
    

    const updateCarePlanCategory = () => {
        setSpinner(true)
            AxiosInter.post(`${API_ENDPOINT}/admin-api/update-care-plan-program-service-provided/`, {
                "row_id": selectedId ? selectedId : 0,
                "action": newServiceProvided ? newServiceProvided : ""
            },{
                headers: {
                Authorization: API_TOKEN,
                },
            } )
                .then(function (response) {
                    setModal(!modal)
                    setReload(reload+1)
                    toastr.success("Successfully Updated", "Service updated successfully")
                    window.location.reload()
                    setSpinner(false)
                })
                .catch(function (error) {
                    // console.log(error);
                    toastr.error("Update Failed", "Please try again")
                    setSpinner(false)
                });
        
    }

   

    const disableAddButton = () => {
        if (newServiceProvided.length > 0) {
            setButtonBlankState(false)
        }
        else {
            setButtonBlankState(true)
        }
    }
  

    useEffect(() => { disableAddButton() }, [ newServiceProvided]) // eslint-disable-line


    // useEffect(() => { if (rowData) permissionsPopulate() }, [rowData])  // eslint-disable-line
    
   
     return (
        <div className="edit-module">
            
            <Modal style={{ width: 500}}  isOpen={modal}  >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Edit Service Provided </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars overflow-modal" >
                <div>
                  {spinner
                    ? <Spinner color='primary' />
                    : ""}
                </div>
                <FormGroup>
                        <Row>
                            <Col sm={12}>
                                <Label for="cpt_code">Service Provided<span className="required">*</span></Label>
                                <Input
                                    id="cpt_code"
                                    placeholder="Enter Service Provided"
                                    value={newServiceProvided}
                                    onChange={(e) => {
                                        setNewServiceProvided(e.target.value)

                                    }}
                                />
                            </Col>
                        </Row><br/>
                        
                        

                    </FormGroup>
                 </ModalBody>
                <ModalFooter className="advanced-search-button custom-modal-footer">
                    <Button 
                    className="yes-button button-width"  
                    onClick={confirmation}
                    title={buttonBlankState ? "Fill the required fields" : ""}
                    disabled = {buttonBlankState}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}


export default EditServiceProvidedModal;