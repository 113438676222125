import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  Container,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  CardBody,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import ToggleButton from "react-toggle-button";
import AsyncSelect from "react-select/async";
import { GlobalState } from "../../contextAPI/Context";
import Cookie from 'js-cookie';

const NewAutoProcessExceptionRule = (props) => {
  const {
    reload,
    setReload,
    groupId,
    ruleName,
    referralType,
    patientAgeCondition,
    patientCondition,
    patientInsurance,
    serviceTypes,
    orderPriority,
    patientGender,
    diagnosis,
    procedures,
    processStatus,
    createdDate,

    updatedDate,

    fulfillerGroupId,
    fulfillerFacilityId,
    fulfillerPhysicianId,
    mandatoryItems,
    eligibilityCheck,
    preAuthCheck,
    preAuthRequestType,
    otherGenerateDocs,
    autoProcessEnable,
    allowedDiagnosis,
    allowedProcedure,
    allowedInsurances,
    allowedServiceTypes,
    allowedNoOfVisitors,
    allowedPos,
    excludedDiagnosis,
    excludedProcedure,
    excludedInsurances,
    excludedServiceTypes,
    additionalKeyData,
    ruleType,
    setGroupId,
    setRuleName,
    setReferralType,
    setPatientAgeCondition,
    setPatientCondition,
    setPatientInsurance,
    setServiceTypes,
    setOrderPriority,
    setPatientGender,
    setDiagnosis,
    setProcedures,
    setProcessStatus,
    setCreatedDate,
    setCreatedBy,
    setUpdatedDate,
    setUpdatedBy,
    setFulfillerGroupId,
    setFulfillerFacilityId,
    setFulfillerPhysicianId,

    setEligibilityCheck,
    setPreAuthCheck,
    setPreAuthRequestType,
    setOtherGenerateDocs,
    setAutoProcessEnable,
    setAllowedDiagnosis,
    setAllowedProcedure,
    setAllowedInsurances,
    setAllowedServiceTypes,
    setAllowedNoOfVisitors,
    setAllowedPos,
    setExcludedDiagnosis,
    setExcludedProcedure,
    setExcludedInsurances,
    setExcludedServiceTypes,
    setAdditionalKeyData,
    setRuleType,
    referralTypeOptions,
    selectedServiceTypes,
    setSelectedServiceTypes,
    selectedOrderPriority,
    setSelectedOrderPriority,
    selectedPhysicianIds,
    setSelectedPhysicianIds,
    selectedConditions,
    setSelectedConditions,
    setSelectedInsurances,
    selectedInsurances,
    setSelectedProcedure,
    selectedProcedure,
    setSelectedDiagnosis,
    selectedDiagnosis,
    selectedFulfillerGroup,
    setSelectedFulfillerGroup,
    selectedFulfillerFacility,
    setSelectedFulfillerFacility,
    referralRuleData,
    setNewAutoProcessExceptionRule,
    newAutoProcessExceptionRule,
    buttonBlankState,
    setButtonBlankState,
    ruleTypeOptions,
    loadServiceTypeOptions,
    loadDiagnosisOptions,
    orderPriorityOptions,
    loadProcedureOptions,
    loadFulfillerGroupOptions,
    loadPhysicianOptions,
    ageOptions,
    genderOptions,
    loadConditionOptions,
    loadInsuranceOptions,
    requestTypeOptions,
    toggleValue,
    setToggleValue,
    additionalDocsOptions,
    loadFacilityOptionsForFacility,
    customNoOptionsMessage
    // selectedMandatoryItems,
  } = props;
  const { groupNamesForDropDown } = useContext(GlobalState);

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [selectedAge, setSelectedAge] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [selectedAgeCondition, setSelectedAgeCondition] = useState("");
  const [patientConditions, setPatientConditions] = useState(""); // eslint-disable-line
  const [checkedOne, setCheckedOne] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [checkedThree, setCheckedThree] = useState(false);
  const [checkedFour, setCheckedFour] = useState(false);
  const [checkedFive, setCheckedFive] = useState(false);
  const [checkedSix, setCheckedSix] = useState(false);
  const [checkedSeven, setCheckedSeven] = useState(false);
  const [checkedEight, setCheckedEight] = useState(false);
  const [checkedNine, setCheckedNine] = useState(false);

  const [selectedAllowedServiceTypes, setSelectedAllowedServiceTypes] =
    useState([]);
  const [selectedExcludedServiceTypes, setSelectedExcludedServiceTypes] =
    useState([]);
  const [selectedAllowedDiagnosis, setSelectedAllowedDiagnosis] = useState([]);
  const [selectedExcludedDiagnosis, setSelectedExcludedDiagnosis] = useState(
    []
  );
  const [selectedAllowedProcedure, setSelectedAllowedProcedure] = useState([]);
  const [selectedExcludedProcedure, setSelectedExcludedProcedure] = useState(
    []
  );
  const [selectedAllowedInsurance, setSelectedAllowedInsurance] = useState([]);
  const [selectedExcludedInsurance, setSelectedExcludedInsurance] = useState(
    []
  );
  const [selectedReferralType, setSelectedReferralType] = useState([]);
  const [selectedadditionalDocs, setSelectedadditionalDocs] = useState([]);
  const [tempDiagnosis, setTempDiagnosis] = useState([]); // eslint-disable-line

  // const currDate = new Date();
  // const dateFormat="YYYY-MM-DD HH:mm:ss"
  // const dateTimeValue = moment(currDate).format(dateFormat);
  const event = new Date();
  const dateUpdate=event.toISOString().slice(0,-5)
  // console.log('iso',dateUpdate.slice(0,-5) );
  // console.log(dateTimeValue)

  //var tempDiagnosis = []

  const toggle = () => {
    setNewAutoProcessExceptionRule(!newAutoProcessExceptionRule);
    setGroupId();
    setRuleName("");
    setReferralType("");
    setPatientAgeCondition("");
    setPatientCondition("");
    setPatientInsurance("");
    setServiceTypes("");
    setOrderPriority("");
    setPatientGender("");
    setDiagnosis("");
    setProcedures("");
    setProcessStatus();
    setCreatedDate("");
    setCreatedBy();
    setUpdatedDate("");
    setUpdatedBy();
    setFulfillerGroupId("");
    setFulfillerFacilityId("");
    setFulfillerPhysicianId("");
    //setMandatoryItems();
    setEligibilityCheck();
    setPreAuthCheck();
    setPreAuthRequestType("");
    setOtherGenerateDocs("");
    setAutoProcessEnable();
    setAllowedDiagnosis("");
    setAllowedProcedure("");
    setAllowedInsurances("");
    setAllowedServiceTypes("");
    setAllowedNoOfVisitors("");
    setAllowedPos();
    setExcludedDiagnosis("");
    setExcludedProcedure("");
    setExcludedInsurances("");
    setExcludedServiceTypes("");
    setAdditionalKeyData("");
    setRuleType("");
  };

  const patientAgePopulate = () => {
    if (selectedAge && selectedAgeCondition) {
      setPatientAgeCondition([
        {
          age: selectedAge,
          max: "0",
          min: "0",
          condition: selectedAgeCondition.value,
        },
      ]);
    // eslint-disable-next-line eqeqeq
    } else if (selectedAgeCondition.label == "Between") {
      setPatientAgeCondition([
        {
          age: "0",
          max: maxValue,
          min: minValue,
          condition: selectedAgeCondition,
        },
      ]);
    } else {
      setPatientAgeCondition("");
    }
  };
  useEffect(() => {
    if (selectedAge && selectedAgeCondition) patientAgePopulate();
  }, [selectedAge, selectedAgeCondition]); // eslint-disable-line

  const handelOnChangeForReferralRuleItem = (e, item) => {
    let name = item.item;
    let itemId = item.id;

    if (name === "Guarantor Details") {
      setCheckedOne(e.target.checked);

      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Pre Auth") {
      setCheckedTwo(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Lab Report") {
      setCheckedThree(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Diagnosis") {
      setCheckedFour(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedDiagnosis();
        setSelectedExcludedDiagnosis();
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Emergency Contact") {
      setCheckedFive(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Procedure") {
      setCheckedSix(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedProcedure();
        setSelectedExcludedProcedure();
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Referral reason") {
      setCheckedSeven(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Insurance Details") {
      setCheckedEight(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        setSelectedAllowedInsurance();
        setSelectedExcludedInsurance();
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
    if (name === "Original Referral") {
      setCheckedNine(e.target.checked ? e.target.checked : false);
      if (e.target.checked) {
        tempDiagnosis.push(JSON.stringify(itemId));
      } else {
        const index = tempDiagnosis.indexOf(itemId);
        if (index > -1) {
          // only splice array when item is found
          tempDiagnosis.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }
  };

  const handelCheckedForReferralRuleItem = (item) => {
    // eslint-disable-next-line no-unused-vars
    let itemId = item.id;
    item = item.item;

    // eslint-disable-next-line eqeqeq
    if (item == "Guarantor Details") {
      return checkedOne ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Pre Auth") {
      return checkedTwo ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Lab Report") {
      return checkedThree ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Diagnosis") {
      return checkedFour ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Emergency Contact") {
      return checkedFive ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Procedure") {
      return checkedSix ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Referral reason") {
      return checkedSeven ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Insurance Details") {
      return checkedEight ? true : false;
    }
    // eslint-disable-next-line eqeqeq
    if (item == "Original Referral") {
      return checkedNine ? true : false;
    }
  };

  // #########  ADD API  ######### //

  const addAutoProcessExceptionRules = () => {
    let admin_id = localStorage.getItem("adminID");
    // debugger

    // if (selectedServiceTypes) {
    //     if(serviceTypes.length>0){
    //     selectedServiceTypes.map((item) => {
    //         serviceTypes.push(item.value)
    //     })}
    // }
    if (selectedServiceTypes) {
      // eslint-disable-next-line array-callback-return
      selectedServiceTypes.map((item) => {
        serviceTypes.push(JSON.stringify(item.value));
      });
    }
    if (selectedDiagnosis) {
      // eslint-disable-next-line array-callback-return
      selectedDiagnosis.map((item) => {
        diagnosis.push(item);
      });
    }
    if (selectedOrderPriority) {
      // eslint-disable-next-line array-callback-return
      selectedOrderPriority.map((item) => {
        orderPriority.push(item.value);
      });
    }
    if (selectedProcedure) {
      // eslint-disable-next-line array-callback-return
      selectedProcedure.map((item) => {
        procedures.push(item);
      });
    }
    if (selectedFulfillerGroup) {
      // eslint-disable-next-line array-callback-return
      selectedFulfillerGroup.map((item) => {
        fulfillerGroupId.push(item.value.toString());
      });
    }
    if (selectedFulfillerFacility) {
      // eslint-disable-next-line array-callback-return
      selectedFulfillerFacility.map((item) => {
        fulfillerFacilityId.push(item.value.toString());
      });
    }
    if (selectedPhysicianIds) {
      // eslint-disable-next-line array-callback-return
      selectedPhysicianIds.map((item) => {
        fulfillerPhysicianId.push(item.value.toString());
      });
    }
    if (selectedConditions) {
      // eslint-disable-next-line array-callback-return
      selectedConditions.map((item) => {
        patientCondition.push(item);
      });
    }
    if (selectedInsurances) {
      // eslint-disable-next-line array-callback-return
      selectedInsurances.map((item) => {
        patientInsurance.push(item.label);
      });
    }
    if (selectedAllowedServiceTypes) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedServiceTypes.map((item) => {
        allowedServiceTypes.push({
          id: item.value,
          type: item.label,
          is_clinical: 1,
          is_transport: 0,
        });
      });
    }
    if (selectedExcludedServiceTypes) {
      // eslint-disable-next-line array-callback-return
      selectedExcludedServiceTypes.map((item) => {
        excludedServiceTypes.push({
          id: item.value,
          type: item.label,
          is_clinical: 1,
          is_transport: 0,
        });
      });
    }
    if (selectedAllowedDiagnosis) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedDiagnosis.map((item) => {
        allowedDiagnosis.push(item);
      });
    }
    if (selectedExcludedDiagnosis) {
      // eslint-disable-next-line array-callback-return
      selectedExcludedDiagnosis.map((item) => {
        excludedDiagnosis.push(item);
      });
    }
    if (selectedAllowedInsurance) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedInsurance.map((item) => {
        allowedInsurances.push(item.label);
      });
    }
    if (selectedExcludedInsurance) {
      // eslint-disable-next-line array-callback-return
      selectedExcludedInsurance.map((item) => {
        excludedInsurances.push(item.label);
      });
    }
    if (selectedAllowedProcedure) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedProcedure.map((item) => {
        allowedProcedure.push(item);
      });
    }
    if (selectedExcludedProcedure) {
      // eslint-disable-next-line array-callback-return
      selectedExcludedProcedure.map((item) => {
        excludedProcedure.push(item);
      });
    }
    if (selectedadditionalDocs) {
      // Object.values(selectedadditionalDocs).map((item) => {
      otherGenerateDocs.push(selectedadditionalDocs.value);
    }

    let procedureCode = [];
    if (selectedAllowedProcedure) {
      // eslint-disable-next-line array-callback-return
      selectedAllowedProcedure.map((item) => {
        // debugger
        procedureCode.push(item.code);
      });
    }
    let allowedVisitsNumbers = [];
    // if (selectedAllowedProcedure&&allowedNoOfVisitors) {
    //     selectedAllowedProcedure.map((item) => {

    //         procedureCode.push(item.code)
    //     })
    // }
    let allowedNoOfVisitsarray = [];
    let temp1 = [];
    let zip = (a1, a2) => a1.map((x, i) => [x, a2[i]]);
    if (typeof allowedNoOfVisitors == "object") {
      allowedVisitsNumbers = allowedNoOfVisitors;
    } else {
      allowedVisitsNumbers = allowedNoOfVisitors.split(",");
    }
    if (allowedVisitsNumbers) {
      // eslint-disable-next-line array-callback-return
      allowedVisitsNumbers.map((item) => {
        temp1.push(item);
      });
    }
    let combined = zip(procedureCode, allowedVisitsNumbers);
    combined.map((items) =>
      allowedNoOfVisitsarray.push({ procCode: items[0], visitNumber: items[1] })
    );

    // debugger
    const params = {
      group_id: groupId ? groupId.value : "",
      rule_name: ruleName ? ruleName : "",
      referral_type: selectedReferralType ? selectedReferralType.value : "",
      patient_age_condition: patientAgeCondition ? patientAgeCondition : null,
      patient_condition: patientCondition ? patientCondition : null,
      patient_insurance: patientInsurance ? patientInsurance : null,
      service_types: serviceTypes ? serviceTypes : null,
      order_priority: orderPriority ? orderPriority : null,
      patient_gender: patientGender ? patientGender.value : null,
      diagnosis: diagnosis ? diagnosis : null,
      procedures: procedures ? procedures : null,
      status: processStatus ? 1 : 1,
      created_date: createdDate ? dateUpdate : dateUpdate,
      created_by: admin_id ? admin_id : "",
      updated_date: updatedDate ? dateUpdate : dateUpdate,
      updated_by: admin_id ? admin_id : "",
      fulfiller_group_id: fulfillerGroupId ? fulfillerGroupId : null,
      fulfiller_facility_id: fulfillerFacilityId ? fulfillerFacilityId : null,
      fulfiller_physician_id: fulfillerPhysicianId
        ? fulfillerPhysicianId
        : null,
      mandatory_items: mandatoryItems ? tempDiagnosis : [],
      is_enable_eligiblity_check: eligibilityCheck ? 1 : 0,
      is_enable_pre_auth_check: preAuthCheck ? 1 : 0,
      pre_auth_request_type: preAuthRequestType
        ? preAuthRequestType.value
        : null,
      other_generate_docs: otherGenerateDocs ? otherGenerateDocs : null,
      is_auto_process_enable: autoProcessEnable ? 1 : "",
      allowed_diagnosis: allowedDiagnosis ? allowedDiagnosis : null,
      allowed_procedure: allowedProcedure ? allowedProcedure : null,
      allowed_insurances: allowedInsurances ? allowedInsurances : null,
      allowed_service_types: allowedServiceTypes ? allowedServiceTypes : null,
      allowed_no_of_visits: allowedNoOfVisitsarray
        ? allowedNoOfVisitsarray
        : null,
      allowed_pos: allowedPos ? allowedPos : null,
      excluded_diagnosis: excludedDiagnosis ? excludedDiagnosis : null,
      excluded_procedure: excludedProcedure ? excludedProcedure : null,
      excluded_insurances: excludedInsurances ? excludedInsurances : null,
      excluded_service_types: excludedServiceTypes
        ? excludedServiceTypes
        : null,
      additional_key_data: additionalKeyData ? additionalKeyData : null,
      rule_type: ruleType ? ruleType.value : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/add-auto-process-exception-rules/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then((response) => {
        toastr.success(
          "Success",
          "Auto Process Exception Rule Conditions added"
        );
        toggle();
        setReload(reload + 1);
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toastr.error("Error", error.response.data.message);
          // toastr.error("Failed", "Maybe you are adding a duplicate entry")
        } else {
          // toastr.error("Error", "Something went wrong !")
          toastr.success(
            "Success",
            "Auto Process Exception Rule Conditions added"
          );
          toggle();
          setReload(reload + 1);
        }
      });
  };

  const validate = () => {
    if (ruleName && groupId && selectedReferralType && ruleType) {
      setButtonBlankState(false);
    } else {
      setButtonBlankState(true);
    }
  };

  // const [form, setState] = useState([{

  //     }]
  //   );

  useEffect(() => {
    validate();
  }, [ruleName, groupId, referralType, ruleType]); // eslint-disable-line

  return (
    <div>
      <Modal isOpen={newAutoProcessExceptionRule} size="lg" >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          {" "}
          New Auto Process Exception Rule Conditions{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal" style={{maxHeight: '72vh'}}>
          <Container fluid>
            <FormGroup>
              <div className="roles-list-card card">
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="groups">
                        Select Group<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        id="groups"
                        type="text"
                        placeholder="Select Groups"
                        options={groupNamesForDropDown}
                        value={groupId}
                        onChange={(e) => {
                          setGroupId(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Label for="apply_for">
                        Apply For<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        id="apply_for"
                        type="text"
                        placeholder="Select Referral Type"
                        options={referralTypeOptions}
                        value={selectedReferralType}
                        onChange={(e) => {
                          setSelectedReferralType(e);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col>
                      <Label for="name">
                        Name<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Input
                        id="name"
                        type="text"
                        placeholder="Name"
                        value={ruleName}
                        onChange={(e) => {
                          setRuleName(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />

                  <Row>
                    <Col>
                      <Label for="ruleType">
                        Rule Type<span className="required">*</span>:
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <Select
                        id="ruleType"
                        type="text"
                        placeholder="Select Rule Type"
                        options={ruleTypeOptions}
                        value={ruleType}
                        onChange={(e) => {
                          setRuleType(e);
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details>
                    <summary>
                      <h6 className="role-list-head-custom">Order Details</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="serviceType">
                          Service Type<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Select Service Type"
                          noOptionsMessage={customNoOptionsMessage}
                          value={selectedServiceTypes}
                          loadOptions={loadServiceTypeOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedServiceTypes(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="diagnosis">
                          Diagnosis<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Diagnosis"
                          noOptionsMessage={customNoOptionsMessage}
                          value={selectedDiagnosis}
                          loadOptions={loadDiagnosisOptions}
                          getOptionLabel={(e) => e.code_show}
                          getOptionValue={(e) => e.id}
                          getOptionCode={(e) => e.code}
                          onChange={(e) => {
                            setSelectedDiagnosis(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="priority">Priority:</Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          isMulti
                          id="priority"
                          type="text"
                          placeholder="Select Priority"
                          options={orderPriorityOptions}
                          value={selectedOrderPriority}
                          onChange={(e) => {
                            setSelectedOrderPriority(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="procedure">Procedure:</Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Procedure"
                          noOptionsMessage={customNoOptionsMessage}
                          value={selectedProcedure}
                          loadOptions={loadProcedureOptions}
                          getOptionLabel={(e) => e.code_show}
                          getOptionValue={(e) => e.id}
                          getOptionCode={(e) => e.code}
                          onChange={(e) => {
                            setSelectedProcedure(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="fulfillerGroup">Fulfiller Group:</Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Fulfiller Group"
                          noOptionsMessage={customNoOptionsMessage}
                          value={selectedFulfillerGroup}
                          loadOptions={loadFulfillerGroupOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            // debugger
                            setSelectedFulfillerGroup(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    {selectedFulfillerGroup ? (
                      <>
                        <Row>
                          <Col>
                            <Label for="fulfillerFacility">
                              Fulfiller Facility:
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              isSearchable
                              placeholder="Fulfiller Facility"
                              noOptionsMessage={customNoOptionsMessage}
                              value={selectedFulfillerFacility}
                              loadOptions={loadFacilityOptionsForFacility}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              onChange={(e) => {
                                setSelectedFulfillerFacility(e);
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Label for="fulfillerPhysician">
                              Fulfiller Physician:
                            </Label>
                          </Col>
                          <Col sm={8}>
                            <AsyncSelect
                              isMulti
                              cacheOptions
                              isSearchable
                              placeholder="Fulfiller Physician"
                              noOptionsMessage={customNoOptionsMessage}
                              value={selectedPhysicianIds}
                              loadOptions={loadPhysicianOptions}
                              getOptionLabel={(e) => e.label}
                              getOptionValue={(e) => e.value}
                              onChange={(e) => {
                                setSelectedPhysicianIds(e);
                              }}
                            />
                          </Col>
                        </Row>
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details>
                    <summary>
                      <h6 className="role-list-head-custom">Patient Details</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="age">
                          Age<span className="">:</span>
                        </Label>
                      </Col>
                      <Col xs="4">
                        <Select
                          id="age"
                          type="text"
                          placeholder="Select"
                          options={ageOptions}
                          value={selectedAgeCondition}
                          onChange={(e) => {
                            setSelectedAgeCondition(e);
                          }}
                        />
                      </Col>
                      {selectedAgeCondition.label !== "Between" ? (
                        <>
                          <Col xs="4">
                            <Input
                              id="age"
                              type="text"
                              placeholder="Age"
                              value={selectedAge}
                              onChange={(e) => {
                                setSelectedAge(e.target.value);
                              }}
                            />
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      {
                      // eslint-disable-next-line eqeqeq
                      selectedAgeCondition.label == "Between" ? (
                        <>
                          <Col xs="2">
                            <Input
                              id="age"
                              type="text"
                              placeholder="Min"
                              value={minValue}
                              onChange={(e) => {
                                setMinValue(e.target.value);
                              }}
                            />
                          </Col>
                          <Col xs="2">
                            <Input
                              id="age"
                              type="text"
                              placeholder="Max"
                              value={maxValue}
                              onChange={(e) => {
                                setMaxValue(e.target.value);
                              }}
                            />
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                    <br />

                    <Row>
                      <Col>
                        <Label for="gender">
                          Gender<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <Select
                          id="gender"
                          type="text"
                          placeholder="Gender"
                          options={genderOptions}
                          value={patientGender}
                          onChange={(e) => {
                            setPatientGender(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Label for="condition">
                          Condition<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Conditions"
                          noOptionsMessage={customNoOptionsMessage}
                          value={selectedConditions}
                          loadOptions={loadConditionOptions}
                          getOptionLabel={(e) => e.category}
                          getOptionValue={(e) => e.id}
                          onChange={(e) => {
                            setSelectedConditions(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                  </details>
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details>
                    <summary>
                      <h6 className="role-list-head-custom">Financial Data</h6>
                    </summary>
                    <Row>
                      <Col>
                        <Label for="payor">
                          Payor<span className="">:</span>
                        </Label>
                      </Col>
                      <Col sm={8}>
                        <AsyncSelect
                          isMulti
                          cacheOptions
                          isSearchable
                          placeholder="Select Insurance"
                          noOptionsMessage={customNoOptionsMessage}
                          value={selectedInsurances}
                          loadOptions={loadInsuranceOptions}
                          getOptionLabel={(e) => e.label}
                          getOptionValue={(e) => e.value}
                          onChange={(e) => {
                            setSelectedInsurances(e);
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    <div style={{ paddingLeft: "37%" }}>
                      <Row>
                        <Col sm={2}>
                          <Input
                            id="eligibility_checkbox"
                            type="checkbox"
                            checked={eligibilityCheck}
                            onChange={(e) => {
                              setEligibilityCheck(e.target.checked);
                            }}
                          />
                          <Label for="eligibility_checkbox">
                            {" "}
                            Enable Eligibility Check
                          </Label>
                        </Col>
                        {
                        // eslint-disable-next-line eqeqeq
                        eligibilityCheck == true ? (
                          <>
                            <Col sm={2}>
                              <Input
                                id="preAuth_checkbox"
                                type="checkbox"
                                checked={preAuthCheck}
                                onChange={(e) => {
                                  setPreAuthCheck(e.target.checked);
                                }}
                              />

                              <Label for="preAuth_checkbox">
                                {" "}
                                Enable Pre-Auth Check
                              </Label>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        {
                        // eslint-disable-next-line eqeqeq
                        eligibilityCheck && preAuthCheck == true ? (
                          <>
                            <Col sm={4}>
                              <Label for="requestType">
                                Request Type<span className="">:</span>
                              </Label>
                              <Select
                                id="requestType"
                                type="text"
                                placeholder="Request Type"
                                options={requestTypeOptions}
                                value={preAuthRequestType}
                                onChange={(e) => {
                                  setPreAuthRequestType(e);
                                }}
                              />
                            </Col>
                            <Col sm={4}>
                              <Label for="pos">
                                POS<span className="">:</span>
                              </Label>
                              <Input
                                id="pos"
                                type="text"
                                placeholder="pos"
                                value={allowedPos}
                                onChange={(e) => {
                                  setAllowedPos(e.target.value);
                                }}
                              />
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </div>
                  </details>
                </CardBody>
              </div>

              <div className="roles-list-card card">
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="">
                        Requires Add-on documents with Order
                        <span className="">:</span>
                      </Label>
                    </Col>
                    <Col sm={8}>
                      <ToggleButton
                        inactiveLabel={""}
                        activeLabel={""}
                        value={toggleValue}
                        onToggle={(e) => {
                          if (otherGenerateDocs) {
                            setToggleValue(!toggleValue);
                          } else {
                            setToggleValue(!toggleValue);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  {
                  // eslint-disable-next-line eqeqeq
                  toggleValue == 1 ? (
                    <>
                      <Row>
                        <Col>
                          <Label for="additionalDocuments">
                            Additional Documents:
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <Select
                            isClearable
                            id="additionalDocuments"
                            type="text"
                            placeholder="Select Documents"
                            options={additionalDocsOptions}
                            value={selectedadditionalDocs}
                            onChange={(e) => {
                              setSelectedadditionalDocs(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                </CardBody>
              </div>
              <div className="roles-list-card card">
                <CardBody>
                  <details>
                    <summary>
                      <h6 className="role-list-head-custom">
                        Required Fields<span className="required">*</span>
                      </h6>
                    </summary>

                    {/* ////////////////////////////////////////////////////////////////////////////////// */}
                    {/* //////////////////////////// NEW-render ReferralRule ///////////////////////////// */}
                    {/* ////////////////////////////////////////////////////////////////////////////////// */}
                    <Container fluid>
                      <Row>
                        <Col>
                          <Input
                            id="serviceType_checkbox"
                            type="checkbox"
                            checked={true}
                            /* onChange={(e) => {
                                                        debugger
                                                        setMandatoryItems(e.target.checked)

                                                    }} */
                          />
                          <Label for="serviceType_checkbox">Service Type</Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Label for="serviceType">
                            Allowed Service Type<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select Service Type"
                            noOptionsMessage={customNoOptionsMessage}
                            value={selectedAllowedServiceTypes}
                            loadOptions={loadServiceTypeOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedAllowedServiceTypes(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col>
                          <Label for="excludedServiceType">
                            Excluded Service Type<span className="">:</span>
                          </Label>
                        </Col>
                        <Col sm={8}>
                          <AsyncSelect
                            isMulti
                            cacheOptions
                            isSearchable
                            placeholder="Select Service Type"
                            noOptionsMessage={customNoOptionsMessage}
                            value={selectedExcludedServiceTypes}
                            loadOptions={loadServiceTypeOptions}
                            getOptionLabel={(e) => e.label}
                            getOptionValue={(e) => e.value}
                            onChange={(e) => {
                              setSelectedExcludedServiceTypes(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />

                      <>
                        {referralRuleData.length > 0
                          ? referralRuleData.map((item) => {
                              return (
                                <>
                                  <Row>
                                    <Col>
                                      <Input
                                        id={item.item}
                                        type="checkbox"
                                        checked={handelCheckedForReferralRuleItem(
                                          item
                                        )}
                                        value={item.id}
                                        onChange={(e) => {
                                          handelOnChangeForReferralRuleItem(
                                            e,
                                            item
                                          );
                                        }}
                                      />

                                      <Label for={item.item}>
                                        {
                                        // eslint-disable-next-line eqeqeq
                                        item.item == "Original Referral"
                                          ? "PCP Notes"
                                          : item.item}
                                      </Label>
                                    </Col>
                                  </Row>

                                  {checkedFour && item.item === "Diagnosis" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Diagnosis
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Diagnosis"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedAllowedDiagnosis}
                                            loadOptions={loadDiagnosisOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedAllowedDiagnosis(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Diagnosis
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Diagnosis"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedExcludedDiagnosis}
                                            loadOptions={loadDiagnosisOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedExcludedDiagnosis(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {checkedSix && item.item === "Procedure" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Procedure
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Allowed Procedure"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedAllowedProcedure}
                                            loadOptions={loadProcedureOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedAllowedProcedure(e);
                                              // allowedProcedureComponent()
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <div id="allowedVisits">
                                        {/* {selectedAllowedProcedure.length > 0 ? */}

                                        {/* selectedAllowedProcedure.map((item)=>{ */}
                                        {/* return(<> */}
                                        <Row>
                                          <Col>
                                            <Label for="excludedServiceType">
                                              Allowed No. of Visits
                                              <span className="">:</span>
                                            </Label>
                                          </Col>
                                          <Col sm={8}>
                                            <Input
                                              id=""
                                              type="text"
                                              placeholder="allowed no of visits (1,2,3...)"
                                              // value={allowedNoOfVisitors}
                                              onChange={(e) => {
                                                // debugger
                                                // this.setAllowedNoOfVisitors(prevState => ({ allowedNoOfVisitors: { ...prevState, e.target.value } }))

                                                setAllowedNoOfVisitors(
                                                  e.target.value
                                                );
                                                // setVisits({...allowedNoOfVisitors,visitNumber:e.target.value})
                                              }}
                                            />
                                          </Col>
                                        </Row>
                                        {/* </>) */}
                                        {/* }) */}

                                        {/* : null} */}
                                      </div>
                                      <br />

                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Procedure
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Procedure"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedExcludedProcedure}
                                            loadOptions={loadProcedureOptions}
                                            getOptionLabel={(e) => e.code_show}
                                            getOptionValue={(e) => e.id}
                                            getOptionCode={(e) => e.code}
                                            onChange={(e) => {
                                              setSelectedExcludedProcedure(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {checkedEight &&
                                  item.item === "Insurance Details" ? (
                                    <>
                                      <Row>
                                        <Col>
                                          <Label for="serviceType">
                                            Allowed Insurances
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Insurance"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedAllowedInsurance}
                                            loadOptions={loadInsuranceOptions}
                                            getOptionLabel={(e) => e.label}
                                            getOptionValue={(e) => e.value}
                                            onChange={(e) => {
                                              setSelectedAllowedInsurance(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col>
                                          <Label for="excludedServiceType">
                                            Excluded Insurances
                                            <span className="">:</span>
                                          </Label>
                                        </Col>
                                        <Col sm={8}>
                                          <AsyncSelect
                                            isMulti
                                            cacheOptions
                                            isSearchable
                                            placeholder="Select Insurance"
                                            noOptionsMessage={customNoOptionsMessage}
                                            value={selectedExcludedInsurance}
                                            loadOptions={loadInsuranceOptions}
                                            getOptionLabel={(e) => e.label}
                                            getOptionValue={(e) => e.value}
                                            onChange={(e) => {
                                              setSelectedExcludedInsurance(e);
                                            }}
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                          : ""}
                      </>
                    </Container>
                  </details>
                </CardBody>
              </div>

              <div className="roles-list-card card">
                <CardBody>
                  <Row>
                    <Col>
                      <Label for="">Auto Send Referral:</Label>
                    </Col>
                    <Col sm={8}>
                      <ToggleButton
                        inactiveLabel={"OFF"}
                        activeLabel={"ON"}
                        value={autoProcessEnable}
                        onToggle={(e) => {
                          setAutoProcessEnable(!autoProcessEnable);
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                </CardBody>
              </div>
            </FormGroup>
          </Container>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            onClick={() => {
              // debugger
              patientAgePopulate();
              // patientAgeConditionPopulate()
              addAutoProcessExceptionRules();
            }}
            disabled={buttonBlankState}
            title={
              buttonBlankState
                ? "Please fill in all mandatory fields"
                : !buttonBlankState
                ? "Click to save"
                : ""
            }
          >
            Add
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewAutoProcessExceptionRule;
