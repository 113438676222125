import React, { useEffect, useContext, useState } from "react";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { Link } from "react-router-dom";
import { Container, Breadcrumb, BreadcrumbItem, Alert, Spinner, Button, Card, CardHeader, Row, Col, Label,NavLink, Input,Nav,NavItem } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "../../hoc/Hux";
import { toastr } from 'react-redux-toastr';
import { GlobalState } from '../../contextAPI/Context'
import MomentConfig from '../../common/MomentConfig';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import SearchBarComponent from '../../newComponents/utilities/SearchBarComponent'
import RemotePaginationForPandaDoc from '../../newComponents/utilities/RemotePaginationForPandaDoc'
import Select from "react-select";
import AllTemplate from "./AllPandaDocTemplates.js"
import classnames from "classnames";
;

const AllPandaDoc = () => {
  // CONTEXT VARIABLES 
  const { pandaDocList, setPandaDocList, setSenderFulfillerData,// eslint-disable-line
    pandaDocSpinner,
    setPandaDocSpinner,
    senderName,
    fulfillerName,
    docStatus,
    patientFirstName,
    patientLastName,
    patientAccNumber,
    docType, spinner,
    panDocReload,
    setPanDocReload,
    setBlankStateMessage, // eslint-disable-line
    setSenderName,
    setFulfillerName,
    setDocStatus,
    setPatientFirstName,
    setPatientLastName,
    setPatientAccNumber,
    senderFulfillerData,
    m_statusList,
    FetchM_status,
    m_typeList,
    FetchTypeList,
    setDocType,
  } = useContext(GlobalState);

  // LOCAL VARIABLES

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const fileExportName = 'PandaDoc'
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
  const [searchKeyword, setSearchKeyword] = useState("")
  const [offset, setOffset] = useState("0");
  const [sizePerPage, setSizePerPage] = useState("50");
  const [isPagination, setIsPagination] = useState("1"); // eslint-disable-line
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState(''); // eslint-disable-line
  const [inputValueForPatient,setInputValueForPatient]= useState(''); // eslint-disable-line
  const [data, setData] = useState([]); // eslint-disable-line
  const [senderFulfillerDropDown, setSenderFulfillerDropDown] = useState("");
  const [statusDropDown, setStatusDropDown] = useState([]);
  const [typeDropDown, setTypeDropDown] = useState([]);
  const [pandaDocDetails, setPandaDocDetails] = useState("")
  const [filter, setFilter] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
};

  const fetchPandaDocDetails = async () => {
    setPandaDocSpinner(true);
    var temp = ''
    if (sortColumn === 'senderName') {
      temp = 'au.name'
    } else if (sortColumn === 'fulfillerName') {
      temp = 'au1.name'
    } else if (sortColumn === 'groupName') {
      temp = 'g.name'
    } else if (sortColumn === 'documentName') {
      temp = 'gda.documentName'
    } else if (sortColumn === 'docStatus') {
      temp = 'ms.displayName'
    } else if (sortColumn === 'patientAccountNumber') {
      temp = 'p.accountNumber'
    } else {
      temp = sortColumn
    }
    const params = {
      "sender_name": senderName ? senderName.value : "",
      "fulfiller_name": fulfillerName ? fulfillerName.value : "",
      "doc_status": docStatus ? Number(docStatus.value) : 0,
      "patient_first_name": patientFirstName ? patientFirstName.value : "",
      "patient_last_name": patientLastName ? patientLastName.toString() : "",
      "patient_account_num": patientAccNumber ? patientAccNumber.toString() : "",
      "type": docType ? Number(docType.value) : 0,
      "search_keyword": searchKeyword ? searchKeyword : "",
      "is_pagination": "1",
      "record_limit": sizePerPage ? sizePerPage.toString() : "50",
      "offset": offset ? offset.toString() : "0",
      "sortOrder": sortOrder ? sortOrder : "",
      "sortColumn": temp ? temp : ""
    }
    await AxiosInter.post(`${API_ENDPOINT}/admin-api/get-documents-signature/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        if (response.data.data.length > 0) {
          setPandaDocDetails(response.data.data);
          setTotalSize(response.data.record_count)
          setPandaDocSpinner(false);
          localStorage.setItem('offset', offset);
          localStorage.removeItem("subscriptionFilter");
          localStorage.removeItem("groupNameFilter");
          localStorage.removeItem("statusFilter");
          localStorage.removeItem("adminNameFilter");
          localStorage.removeItem("zipCodeFilter");
          localStorage.removeItem("timeZoneFilter");
        } else {
          setTotalSize(0);
          setPandaDocDetails("");
          setPandaDocSpinner(false);
        }
      })
    // .catch(function (error) {
    //   setPandaDocDetails("");
    //   toastr.warning('Error Occurred', 'Please try again');
    //   setBlankStateMessage(true);
    //   setPandaDocSpinner(false);
    // });
  };
  const exportTableData = () => {
    var temp = ''
    if (sortColumn === 'senderName') {
      temp = 'au.name'
    } else if (sortColumn === 'fulfillerName') {
      temp = 'au1.name'
    } else if (sortColumn === 'groupName') {
      temp = 'g.name'
    } else if (sortColumn === 'documentName') {
      temp = 'gda.documentName'
    } else if (sortColumn === 'docStatus') {
      temp = 'ms.displayName'
    } else if (sortColumn === 'patientAccountNumber') {
      temp = 'p.accountNumber'
    } else {
      temp = sortColumn
    }
    const params =
    {
      "file_name": fileName ? fileName : "",
      "sender_name": senderName ? senderName.value : "",
      "fulfiller_name": fulfillerName ? fulfillerName.value : "",
      "doc_status": docStatus ? Number(docStatus.value) : 0,
      "patient_first_name": patientFirstName ? patientFirstName.value : "",
      "patient_last_name": patientLastName ? patientLastName.toString() : "",
      "patient_account_num": patientAccNumber ? patientAccNumber.toString() : "",
      "type": docType ? Number(docType.value) : 0,
      "search_keyword": searchKeyword ? searchKeyword : "",
      "is_pagination": isPagination ? isPagination : "",
      "record_limit": "",
      "offset": offset,
      "sortOrder": sortOrder ? sortOrder : "",
      "sortColumn": temp ? temp : ""

    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-panda-doc-table-data/`, params, {
      headers: {

        Authorization: API_TOKEN,

      },
      responseType: "blob"
    })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data, `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success(
            "Success",
            "Export successful"
          );

        }

      })
      .catch((error) => {

        toastr.error(
          "Failed",
          "Something went wrong"
        );
      });
  };

  const basicValidation = (cell) => {
    return cell ? cell : "--"
  }
  const filterValidation = () => {
    if (senderName || fulfillerName || docStatus || patientFirstName || patientLastName || patientAccNumber || docType) {
      setFilter(true)
    } else {
      setFilter(false)
    }
  }
  const statusFormatter = (cell, row) => {

    return (
      <Alert className="alert">

        <div >

          {cell}

        </div>
      </Alert>

    );
  };

  const tableColumns = [
    {
      dataField: 'patient_name',
      text: 'Patient Name',
      sort: true,
      formatter: basicValidation
    },
    {
      dataField: 'patientAccountNumber',
      text: 'Patient Account Number',
      sort: true,
      formatter: basicValidation
    },
    {
      dataField: 'dob',
      text: 'DOB',
      sort: true,
      formatter: basicValidation
    },
    {
      dataField: 'group_name',
      text: 'Group Name',
      sort: true,
      formatter: basicValidation
    },
    {
      dataField: 'sender_name',
      text: 'Preparer Name',
      sort: true,
      formatter: basicValidation
    },
    {
      dataField: 'sender_email',
      text: 'Preparer Email',
      sort: true,
      formatter: basicValidation
    },
    {
      dataField: 'signer_name',
      text: 'Receiver Name',
      sort: true,
      formatter: basicValidation
    },
    {
      dataField: 'signer_email',
      text: 'Receiver Email',
      sort: true,
      formatter: basicValidation
    },

    {
      dataField: 'documentName',
      text: 'Document Name',
      sort: true,
      formatter: basicValidation
    },
    {
      dataField: 'docStatus',
      text: 'Doc Status',
      sort: true,
      formatter: statusFormatter
    },
    {
      dataField: 'templateName',
      text: 'Template Used',
      sort: true,
      formatter: basicValidation
    },


  ];

  const handleTableChange = (type, newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
    setOffset((newState.page - 1) * newState.sizePerPage)

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("")
      setSortOrder("")
    }
    else {
      setSortColumn(newState.sortField)
      setSortOrder(newState.sortOrder)
      setPanDocReload(panDocReload + 1)
    }
  }
  const OverlayLoader = () => (
    <div className="page">
      <div className="item">
        <i className="loader --1"></i>
      </div>
    </div>
  );


  // DEFAULT API CALL
  useEffect(() => {
    if (senderName !== '' || fulfillerName !== '' || docStatus !== '' || patientFirstName !== '' || patientLastName !== '' || patientAccNumber !== '' || docType !== '') {

      // fetchPandaDocDetails();
    }
  }, [senderName, fulfillerName, docStatus, patientFirstName, patientLastName, patientAccNumber, docType]); // eslint-disable-line
  useEffect(() => {
    if (searchKeyword.length >= 4 || searchKeyword === "") {
      setPandaDocDetails("");
      setPanDocReload(panDocReload + 1)
    }
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => { if (panDocReload > 0) { fetchPandaDocDetails() } }, [panDocReload]); // eslint-disable-line
  useEffect(() => { if (pandaDocDetails) { localStorage.setItem('sizePerPage', sizePerPage) } }, [pandaDocDetails]) // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem('offset')
    if (offset !== previousOffset) {

      setPanDocReload(panDocReload + 1)
    }
  }, [offset, sizePerPage]) // eslint-disable-line
  useEffect(() => { if (m_statusList.length <= 0) FetchM_status(); }, [m_statusList]) // eslint-disable-line
  useEffect(() => { if (m_typeList.length <= 0) FetchTypeList(); }, [m_typeList]) // eslint-disable-line
  useEffect(() => { if (senderFulfillerData && !senderFulfillerDropDown) createSenderFulfillerOption(); }, [senderFulfillerData]) // eslint-disable-line
  useEffect(() => { if (m_statusList && m_typeList) createStatusOption(); createTypeOption(); }, [m_statusList, m_typeList]) // eslint-disable-line
  useEffect(() => {
    filterValidation();
  }, [senderName, fulfillerName, docStatus, patientFirstName, patientLastName, patientAccNumber, docType]) // eslint-disable-line
  // eslint-disable-line

  const runSearch = () => {// eslint-disable-line
    if (senderName || fulfillerName || patientFirstName || patientLastName || patientAccNumber || docStatus || docType) {
      setSenderName(senderName)
      setFulfillerName(fulfillerName)
      setPatientFirstName(patientFirstName)
      setDocStatus(docStatus)
      setPatientLastName(patientLastName)
      setPatientAccNumber(patientAccNumber)
      setDocType(docType)
      setPanDocReload(panDocReload + 1);
    }
  }

  const clearValues = () => {
    setSenderName("")
    setFulfillerName("")
    setPatientFirstName("")
    setDocStatus("")
    setPatientLastName("")
    setDocType("")
    setPatientAccNumber("")
    setData([])
    setInputValue("")
    setInputValueForPatient("")
    setSearchKeyword("")
    setPanDocReload(panDocReload + 1);
  }

  const createSenderFulfillerOption = () => {
    if (senderFulfillerData) {
      setPandaDocSpinner(true)
      var array1 = senderFulfillerData
      const options1 = array1.map((item, index) => {
        return {
          value: item.id,
          label: item.name
        };
      });
      setSenderFulfillerDropDown(options1);
      setPandaDocSpinner(false)
    }
  };

  // handle selection
  const handleChangeForSender = (value) => {
    setSenderName(value);
  }
  const handleChangeForFulfiller = (value) => {
    setFulfillerName(value);
  
  }
  const handleChangeForPatient = (value) => {
    setPatientFirstName(value);
  }

  const loadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-sender-fulfiller-names/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
        .catch((error) => {
          // throw error;
        });
    }
  }
  const loadOptionsForPatient = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-patients-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
        .catch((error) => {
          // throw error;
        });
    }
  };

  

  // handle input change event
  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handlePatientInputChange = (value) => {
    setInputValueForPatient(value);
  };

  const createStatusOption = () => {
    if (m_statusList) {
      setPandaDocSpinner(true)
      var array2 = m_statusList
      const options2 = array2.map((item) => {

        return {
          value: item.id,
          label: item.displayName
        };
      });
      setStatusDropDown(options2);
      setPandaDocSpinner(false)
    }
  };

  const createTypeOption = () => {
    if (m_typeList) {
      setPandaDocSpinner(true)
      var array3 = m_typeList
      const options3 = array3.map((item, index) => {

        return {
          value: item.id,
          label: item.type
        };
      });
      setTypeDropDown(options3);
      setPandaDocSpinner(false)
    }
  };

  return (
    <Hux>
      <Container fluid>
        {pandaDocSpinner ? <OverlayLoader className="overlay-z-index busy" /> : null}
        <Header>
          <HeaderTitle>PandaDoc Documents</HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>documents</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>
      {activeTab ==1 ? // eslint-disable-line 
      (<Card> 
        <CardHeader>
        <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "1" })}
                            onClick={() => {
                                toggle("1");
                                localStorage.removeItem('actTab')
                                // toggleCloseForRoster()
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            All pandadoc
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === "2" })}
                            onClick={() => {
                                toggle("2");
                                // toggleClose()
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            Templates
                        </NavLink>
                    </NavItem>
                </Nav>
          <Row>
            <Col xs="2">
              <Label>Preparer</Label>
              <AsyncSelect
                cacheOptions
                className="pandadoc-select"
                name="Preparer"
                loadOptions={loadOptions}
                placeholder="Select"
                isSearchable
                isClearable
                value={senderName}
                onInputChange={handleInputChange}
                onChange={handleChangeForSender}
              />
            </Col>
            <Col xs="2">
              <Label>Receiver</Label>
              <AsyncSelect
                cacheOptions
                className="pandadoc-select"
                name="Receiver"
                getOptionLabel={e => e.label}
                getOptionValue={e => e.value}
                loadOptions={loadOptions}
                placeholder="Select"
                isSearchable
                isClearable
                value={fulfillerName}
                onInputChange={handleInputChange}
                onChange={handleChangeForFulfiller}
              />
            </Col>
            <Col xs="2">
              <Label>Status</Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(event) => { setDocStatus(event) }}
                isClearable
                isSearchable
                placeholder="Select"
                value={docStatus}
                options={statusDropDown}
              />
            </Col>
            <Col xs="2">
              <Label>Type</Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                onChange={(event) => { setDocType(event) }}
                isClearable
                isSearchable
                placeholder="Select"
                value={docType}
                options={typeDropDown}
              />
            </Col>
            <Col xs="2">
              <Label>Patient Name</Label>
              <AsyncSelect
                cacheOptions
                className="pandadoc-select"
                name="Patient Name"
                getOptionLabel={e => e.label}
                getOptionValue={e => e.value}
                loadOptions={loadOptionsForPatient}
                placeholder="Select Patient Name"
                isSearchable
                isClearable
                value={patientFirstName}
                onInputChange={handlePatientInputChange}
                onChange={handleChangeForPatient}
              />
            </Col>
            <Col xs="2">
              <Label>Patient Account Number</Label>
              <Input
                id="accountNum"
                value={patientAccNumber}
                placeholder="Account number"
                onChange={(e) => { if (e.target.value.length <= 150) setPatientAccNumber(e.target.value) }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                type="submit"
                className="go-buttons"
                onClick={() => {
                  setPandaDocDetails("")
                  offset === '0' ? setPanDocReload(panDocReload + 1) : setOffset('0')
                  setPage(1)
                }}
                isDisabled={pandaDocSpinner || !filter ? true : false}
              >Go</Button>
              <Button
                type="reset"
                is
                className="reset-buttons"
                isDisabled={pandaDocSpinner || !filter ? true : false}
                onClick={() => { setPandaDocDetails(""); clearValues() }}
              >Reset</Button>
            </Col>
          </Row>
          <div className="separator" />
        </CardHeader>
        <div className='groups-search'>
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={tableColumns}
            clearValues={clearValues}
          />
        </div>
        {spinner ? <Spinner /> : ""}
        <RemotePaginationForPandaDoc
          data={pandaDocDetails}
          columns={tableColumns}
          page={page}
          sizePerPage={sizePerPage}
          FileExportName={fileExportName}
          exportTableData={exportTableData}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            handleTableChange(type, newState);
          }}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
        />
      </Card>
         ):(
           
          <>
      <AllTemplate
      activeTab={activeTab}
      toggle={toggle}
      />
      </> 
      )}
    </Hux>
  );
};

export default AllPandaDoc;