import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import AsyncSelect from "react-select/async";
import Cookie from 'js-cookie';

const NewFacilityAssignationTagModal = (props) => {
  const {
    NewFacilityAssignationTagAddModal,
    setNewFacilityAssignationTagAddModal,
    reloadForFacilityAssignationTag,
    setReloadForFacilityAssignationTag,
    facilityAssignationTagGroupName,
    setFacilityAssignationTagGroupName,
    facilityAssignationTagFacilityName,
    setFacilityAssignationTagFacilityName,
    facilityAssignationTagAppUser_Name,
    setFacilityAssignationTagAppUser_Name,
    setFacilityAssignationTagTableOrderCount,
    facilityAssignationTagTableOrderCount, 
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  
  const [addButtonStatus, setAddButtonStatus] = useState(true);

  const toggle = () => {
    setFacilityAssignationTagGroupName("");
    setFacilityAssignationTagFacilityName("");
    setFacilityAssignationTagAppUser_Name("");
    setFacilityAssignationTagTableOrderCount("");
    setNewFacilityAssignationTagAddModal(!NewFacilityAssignationTagAddModal);
  };

  const confirmation = () => {
    if (
      facilityAssignationTagGroupName &&
      facilityAssignationTagFacilityName &&
      facilityAssignationTagAppUser_Name &&
      facilityAssignationTagTableOrderCount
    ) {
      CreateFacilityAssignationTag();
    }
  };

  const CreateFacilityAssignationTag = () => {
    // debugger
    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/referral_sender_assignee_allocation_rule-crud/`,
        {
          group_id: facilityAssignationTagGroupName
          ? facilityAssignationTagGroupName.value
          : null,
          facility_id: facilityAssignationTagFacilityName
            ? facilityAssignationTagFacilityName.value
            : null,
          sender_assignee_id: facilityAssignationTagAppUser_Name
            ? facilityAssignationTagAppUser_Name.value
            : null,
          total_count_per_day: facilityAssignationTagTableOrderCount
            ? facilityAssignationTagTableOrderCount
            : null,
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setNewFacilityAssignationTagAddModal(!NewFacilityAssignationTagAddModal);
        setReloadForFacilityAssignationTag(reloadForFacilityAssignationTag + 1);   
        toastr.success(
          "Successfully Added",
          "Facility assignation tag details successfully added"
        );
        toggle();
      })
      .catch(function (error) {
        toastr.error("Failed", "Please try again");
      });
  };

  const disableAddButton = () => {
    if (
        facilityAssignationTagFacilityName &&
        facilityAssignationTagGroupName &&
        facilityAssignationTagAppUser_Name &&
        facilityAssignationTagTableOrderCount
    ) {
      setAddButtonStatus(false);
    } else {
      setAddButtonStatus(true);
    }
  };


  const groupNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };


  const facilityNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      // const searchParams = new URLSearchParams ({name: inputValue});
        return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-by-fulfiller-name/${inputValue}/${facilityAssignationTagGroupName.value}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };


  const appUserloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue, groupID: facilityAssignationTagGroupName.value });
      console.log("tst")
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-data-assignation/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  useEffect(() => {
    disableAddButton();
  }, [facilityAssignationTagGroupName,facilityAssignationTagFacilityName,facilityAssignationTagAppUser_Name,facilityAssignationTagTableOrderCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Modal isOpen={NewFacilityAssignationTagAddModal}  size="md">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >Add New Facility Assignation Tag{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          <FormGroup>
            <Row>
            <Col sm={6}>       
             <Label>Group Name</Label>
                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  type="text"
                  name="groupName"
                  placeholder="Search with at least 4 characters."
                  value={facilityAssignationTagGroupName}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  validate={{
                    maxLength: { value: 120 },
                  }}
                  onChange={(e) => {
                    setFacilityAssignationTagGroupName(e);
                  }}
                  loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
                />
              </Col>
              <Col sm={6}>
                <Label>Facility Name</Label>
                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  type="text"
                  name="facilityName"
                  placeholder="Search with at least 4 characters."
                  value={facilityAssignationTagFacilityName}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  validate={{
                    maxLength: { value: 120 },
                  }}
                  onChange={(e) => {
                    setFacilityAssignationTagFacilityName(e);
                  }}
                  loadOptions={(inputValue) => facilityNameloadOptions(inputValue)}
                />
              </Col>
              </Row>
              <Row>
              <Col sm={6}>
                <Label>App User <span className="required">*</span></Label>
                <AsyncSelect
                  cacheOptions
                  isClearable
                  isSearchable
                  type="text"
                  name="appUserName"
                  placeholder="Search with at least 4 characters."
                  value={facilityAssignationTagAppUser_Name}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  validate={{
                    maxLength: { value: 120 },
                  }}
                  onChange={(e) => {
                    setFacilityAssignationTagAppUser_Name(e);
                  }}
                  loadOptions={(inputValue) => appUserloadOptions(inputValue)}
                />
              </Col>
              <Col sm={6}>
                <Label>Total Order Count <span className="required">*</span></Label>
                <Input
                   id="ordernumber"
                   type = "text"
                   value = {facilityAssignationTagTableOrderCount}
                   placeholder="Enter the order number "
                   onChange={(e) => { 
                     if (e.target.value.length >= 20) {
                         toastr.warning("Invalid Number")
                     }else{
                         setFacilityAssignationTagTableOrderCount(e.target.value)
                     }
                 }}
                  
               />                         
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button  custom-modal-footer">
          <Button
            className="yes-button button-width"
            onClick={confirmation}
            disabled={addButtonStatus}
            title={addButtonStatus ? "Fill the required fields" : ""}
          >Add</Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NewFacilityAssignationTagModal;
