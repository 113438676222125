import React, { useEffect, useState } from "react";
import AxiosInter from './../../AxiosInter.js';
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Label,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import moment from "moment";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import { toastr } from "react-redux-toastr";
import "react-datepicker/dist/react-datepicker.css"; 
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import MomentConfig from "../../common/MomentConfig";
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import Cookie from 'js-cookie';

const UserLog = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  
  const fileExportName = "UserLog";
  const [spinner, setSpinner] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [apiLoaded, setApiLoaded] = useState(false);
  const [search, setSearch] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userName, setUserName] = useState("");
  const [emailID, setEmailID] = useState("");
  const [groupName, setGroupName] = useState("");
  const [designation, setDesignation] = useState("");
  const [startDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [userToDateAPI, setUserToDateAPI] = useState("");
  const [userFromDateAPI, setUserFromDateAPI] = useState("");
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [groupID, setGroupID] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [userID, setUserID] = useState("");
  const [userDataReload, setUserDataReload] = useState(0);
  const [userSizePerPage, setUserSizePerPage] = useState("50");
  const [offset, setOffset] = useState("0");
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;

 
  const clearValues = () => {
    setUserName("");
    setEmailID("");
    setDesignation("");
    setGroupName("");
    setFromDate("");
    setToDate("");
    setUserList("");
    setUserToDateAPI("");
    setUserFromDateAPI("");
    setGroupID("");
    setUserID("");
    setUserList("");
    setSortOrder("");
    setTotalSize(0);
    setApiLoaded(false);
    setUserDataReload(userDataReload+1)
  };
  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const dobFormatter = (cell) => {
    return cell
      ? moment(cell).format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)
      : "N/A";
  };

  const columns = [
    {
      dataField: "username",
      text: "User Name",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "state",
      text: "State",
      sort: true,
      formatter: basicValidation,
    },

    {
      dataField: "city",
      text: "City",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "name",
      text: "Group Name",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "timeOn",
      text: "Last Login Date",
      sort: true,
      formatter: dobFormatter,
      csvFormatter: dobFormatter,
    },
    {
      dataField: "ipAddress",
      text: "IpAddress",
      sort: true,
      formatter: basicValidation,
    },
  ];

  const onTableChangeForuser = (type, newState) => {
    setPage(newState.page);
    setUserSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("aual.timeOn");
      setSortOrder("desc");
      setUserDataReload(userDataReload + 1);
    } else if (newState.sortField === "name") {
      setSortColumn("g.name");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    }
  };

  const exportTableData = () => {
    const params = {
      file_name: fileName ? fileName : "",
      username: userName ? userName.label : "",
      email: emailID ? emailID.label : "",
      fromDate: userFromDateAPI ? userFromDateAPI : "",
      toDate: userToDateAPI ? userToDateAPI : "",
      role: designation ? designation.value : "",
      group_name: groupName ? groupName.label : "",
      offset: offset ? offset.toString() : "0",
      record_limit: userSizePerPage ? userSizePerPage : "50",

      search_keyword: searchKeyword ? searchKeyword : "",
      sortOrder: sortOrder ? sortOrder : "DESC",
      sortColumn: sortColumn ? sortColumn : "aual.timeOn",
      is_pagination: "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/export-user-log-table-data/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      // .catch((error) => {
      //   toastr.error("Failed", "Something went wrong");
      // });
  };

  const searchUsers = () => {
    setSpinner(true);
    setApiLoaded(true);

    let params = {};

    if (searchKeyword.length > 0) {
      params = {
        username: "",
        email: "",
        fromDate: "",
        toDate: "",
        role: "",
        group_name: "",
        user_id: "",
        group_ID: "",
        offset: offset ? offset.toString() : "0",
        record_limit: userSizePerPage ? userSizePerPage : "50",
        search_keyword: searchKeyword ? searchKeyword : "",
        sortOrder: sortOrder ? sortOrder : "desc",
        sortColumn: sortColumn ? sortColumn : "aual.timeOn",
        is_pagination: "",
      };
    } else {
      params = {
        username: userName ? userName.label : "",
        email: emailID ? emailID.label : "",
        fromDate: userFromDateAPI ? userFromDateAPI : "",
        toDate: userToDateAPI ? userToDateAPI : "",
        role: designation ? designation.value : "",
        user_id: "",
        group_name: groupName ? groupName.label : "",
        offset: offset ? offset.toString() : "0",
        record_limit: userSizePerPage ? userSizePerPage : "50",
        search_keyword: searchKeyword ? searchKeyword : "",
        sortOrder: sortOrder ? sortOrder : "desc",
        sortColumn: sortColumn ? sortColumn : "aual.timeOn",
        is_pagination: "",
      };
    }

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/filter-userlog-list/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then(function (response) {
        if (response.data.data.length > 0) {
          setUserList(response.data.data);
          localStorage.setItem("offset", offset.toString());
          setTotalSize(response.data.record_count);
          setApiLoaded(true);
          setSpinner(false);
        } else {
          setUserList("");
          setApiLoaded(true);
          setSpinner(false);
          setIsDataEmpty(true);
        }
      })
  };
  const convert = (dob) => {
    let date = moment(dob).format("DD-MM-YYYY");
    let localDateAndTime = `${date} 00:00:00`;
    setToDate(dob);
    let apiFormat = moment(localDateAndTime, "DD-MM-YYYY H:mm:ss").format(
      "YYYY-MM-DD HH:mm:ss"
    );
    setUserToDateAPI(apiFormat);
  };

  const dconvert = (dte) => {
    let dat = moment(dte).format("DD-MM-YYYY");
    let localDateAndTime = `${dat} 00:00:00`;
    setFromDate(dte);

    let apiFormat = moment(localDateAndTime, "DD-MM-YYYY H:mm:ss").format(
      "YYYY-MM-DD HH:mm:ss"
    );
    setUserFromDateAPI(apiFormat);
  };

  const enableOrDisableSearch = () => {
    if (
      userName ||
      emailID ||
      designation ||
      startDate ||
      toDate ||
      groupName
    ) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  useEffect(() => {
    enableOrDisableSearch();
  }, [userName, emailID, designation, startDate, toDate, groupName]); // eslint-disable-line

  useEffect(() => {
    if (userDataReload > 0) {
      searchUsers();
      localStorage.setItem("offset", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataReload]);
  
  useEffect(() => {
    
      searchUsers();
      
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset) {
      searchUsers();
    }
  }, [offset]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length > 3) setUserDataReload(userDataReload + 1);
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      setTotalSize(0);
      setUserDataReload(userDataReload + 1);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line
  useEffect(() => {   
    window.scrollTo(0, 0);   
     }, []);
  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleChange = (value) => {
    setUserName(value);
  };

  const handleEmailChange = (value) => {
    setEmailID(value);
  };
  const handledesignationChange = (value) => {
    setDesignation(value);
  };
  const handleGroupNameChange = (value) => {
    setGroupName(value);
  };


const loadOptions = (inputValue) => {
  if (inputValue.length >= 4) {
    const searchParams = new URLSearchParams({ name: inputValue });
    return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-user-names-list/?${searchParams.toString()}`,
    {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => response.data.data)
      // .catch((error) => {
      //   console.error('Error fetching options:', error);
      //   throw error;
      // });
  }
};


  const designationloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-user-designation-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
        // .catch((error) => {
        //   console.error('Error fetching options:', error);
        //   throw error;
        // });
    }
  };
  

  const groupNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
        // .catch((error) => {
        //   console.error('Error fetching options:', error);
        //   throw error;
        // });
    }
  };

  const emailloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-user-email-list/?${searchParams.toString()}`,
       {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
        // .catch((error) => {
        //   throw error;
        // });
    }
  };
  
  const clearSearchValues = () => {
    setSearchKeyword("");
    setUserDataReload(userDataReload + 1);
  };

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <CustomDatePickDiv>
        <label
          className="plhldr"
          onClick={props.onClick}
          ref={ref}
          style={{ color: "hsl(0, 0%, 35%)" }}
        >
          {props.value || props.placeholder}
        </label>
        <FontAwesomeIcon icon={faCalendarAlt} onClick={props.onClick} />
      </CustomDatePickDiv>
    );
  });

  return (
    <div>
      <Hux>
        <Container fluid>
          <Header>
            <HeaderTitle>Access Log</HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Access Log</BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Container>
      </Hux>

      <Card className="adv-search-background-card">
        <CardHeader>
          <Row>
            <Col sm="3" md="4">
              <Label>User Name</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="name"
                placeholder="Search with at least 4 characters."
                value={userName}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleChange}
                loadOptions={(inputValue) => loadOptions(inputValue)}
              />
            </Col>
            <Col sm="3" md="4">
              <Label>Email</Label>

              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="email"
                placeholder="Search with at least 4 characters."
                value={emailID}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleEmailChange}
                loadOptions={(inputValue) => emailloadOptions(inputValue)}
              />
            </Col>

            <Col sm="2">
              <Label> Start Date</Label>

              <div className="aclog-datepicker">
                <DatePickerDiv>
                  <DatePicker
                    dateFormat="dd MMM YYY"
                    className="dob-date"
                    selected={startDate ? startDate : ""}
                    placeholderText="Select Start Date"
                    todayButton="Today"
                    maxDate={new Date()}
                    yearDropdownItemNumber={20}
                    scrollableYearDropdown={true}
                    showYearDropdown
                    showMonthDropdown
                    onChange={(dat) => {
                      dconvert(dat);
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={toDate}
                    customInput={<CustomInput />}
                  />
                </DatePickerDiv>
              </div>
            </Col>

            <Col sm="2">
              <Label>To Date</Label>
              <div className="aclog-datepicker">
                <DatePickerDiv>
                  <DatePicker
                    dateFormat="dd MMM YYY"
                    selected={toDate ? toDate : ""}
                    placeholderText="Select End Date"
                    todayButton="Today"
                    maxDate={new Date()}
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    showYearDropdown
                    showMonthDropdown
                    onChange={(date) => {
                      convert(date);
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={toDate}
                    customInput={<CustomInput />}
                  />
                </DatePickerDiv>
              </div>
            </Col>

            <Col sm="3" md="4" style={{ marginTop: 15 }}>
              <Label>Designation</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="designation"
                placeholder="Search with at least 4 characters."
                value={designation}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handledesignationChange}
                loadOptions={(inputValue) => designationloadOptions(inputValue)}
              />
            </Col>

            <Col sm="3" md="4" style={{ marginTop: 15 }}>
              <Label>Group Name</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="groupName"
                placeholder="Search with at least 4 characters."
                value={groupName}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleGroupNameChange}
                loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
              />
            </Col>

            <Col sm="3" style={{ marginTop: 15 }}>
              <Button
                className="patient-go-buttons"
                disabled={!search ? true : false}
                title={
                  spinner || !search
                    ? "Choose at least one item to search "
                    : ""
                }
                onClick={() => {
                  offset === "0" ? searchUsers() : setOffset("0");
                }}
              >
                Go
              </Button>{" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => {
                  clearValues();
                }}
                disabled={!search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <div className="separator" />
        </CardHeader>
        <br></br>
        <div className="groups-search">
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={columns}
            clearValues={clearSearchValues}
          />
        </div>

        <div>{spinner ? <Spinner /> : ""}</div>
        <RemotePagination
          data={userList ? userList : ""}
          columns={columns}
          page={page}
          sizePerPage={userSizePerPage}
          FileExportName={fileExportName}
          exportTableData={exportTableData}
          isGlobalSearch={isGlobalSearch}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            onTableChangeForuser(type, newState);
          }}
          isDataEmpty={isDataEmpty}
          setIsGlobalSearch={setIsGlobalSearch}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
        />

        {/*  */}
      </Card>
    </div>
  );
};

export default UserLog;

const DatePickerDiv = styled.div`
  position: relative;
`;

const CustomDatePickDiv = styled.div`
  background-color: white;
  border: solid 0.1em #cbd4c9;
  border-radius: 0.25em;
  padding: 0.25rem 0.7rem;
  height: calc(1.90625rem + 2px);
`;
