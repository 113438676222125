import React, { useEffect, useState } from "react";
import { Button, Row, Col, Card, CardHeader } from "reactstrap";
import ToggleButton from "react-toggle-button";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Cookie from 'js-cookie';
import { components } from "react-select";
import { default as ReactSelect } from "react-select";


const ReferralOrderSender = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { groupID, setGroupID, setcategoryTypePopulated } = props;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [statusToggleButton, setStatusToggleButton] = useState(false);
  const [enableAutoProcessToggleButton, setEnableAutoProcessToggleButton] = useState(true);
  const [isClinicalToggleButton, setIsClinicalToggleButton] = useState(true);
  const [isTransportToggleButton, setIsTransportToggleButton] = useState(true);
  const [enableMailNotificationToggleButton,setEnableMailNotificationToggleButton,] = useState(true);
  const [autoAssignFullfillerToggleButton,setAutoAssignFullfillerToggleButton] = useState(true);
  const [enableMdmTransmisionToggleButton,setEnableMdmTransmisionToggleButton] = useState(true);
  const [subscriptionType, SetSubscriptionType] = useState("");
  const [MDMTransmissionType, setMDMTransmissionType] = useState("");
  const [transmissionEnabledFulfiller, setTransmissionEnabledFulfiller] = useState("");
  const [categoryType, setCategoryType] = useState([]);
  const [editCategoryType, setEditCategoryType] = useState([]);//eslint-disable-line
  const [senderid, setSenderid] = useState(0);

  const TypeOptions = [
    { value: 'FULL', label: "Enterprise" },
    { value: 'LITE', label: "Community" },
    {value: 'LITE PLUS', label: "Community Plus" }
  ];

  // const categoryTypeOptions = [
  //   { value: 1, label: "Clinical" },
  //   { value: 2, label: "Transport" },
  //   {value: 3, label: "Social" },
  //   {value: 4, label: "Nursing" },
  //   {value: 5, label: "Physician" },
  // ];
  const [categoryTypeOptions, setCategoryTypeOptions] = useState([
    { value: 1, label: "Clinical" },
    { value: 2, label: "Transport" },
    {value: 3, label: "Social" },
    {value: 4, label: "Nursing" },
    {value: 5, label: "Physician" },
  ]);
  

  const fetchCategoryTypeOptions = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-category-type-options/`, {
        headers: {
            Authorization: API_TOKEN,
        },
    })
        .then((response) => {
            if (response.status === 200) {

                setCategoryTypeOptions(response.data.data)


            }

        })
        .catch((error) => {
            console.log(error)
        });
}

  const saveNotifications = () => {
    if (categoryType) {
      var categoryTypesResult = categoryType.map((item) => {
        return item.value.toString();
      });
    }
    // mdm button ON and not selected any of options then error
    if(enableMdmTransmisionToggleButton && MDMTransmissionType.length === 0){
      return toastr.warning("Please select MDM transmission types")
    }
    const saveReferralData = ()=>{
      AxiosInter
        .post(
          `${API_ENDPOINT}/admin-api/referral-sender/`,
          {
            group_id: groupID ? groupID.value : "",
            subscription_type: subscriptionType ? subscriptionType.label : "",
            status: statusToggleButton ? true : false,
            enable_auto_process: enableAutoProcessToggleButton ? true : false,
            // is_clinical: isClinicalToggleButton ? true : false,
            // is_transport: isTransportToggleButton ? true : false,
            category_type: categoryTypesResult.sort(),
            enable_mail_notification: enableMailNotificationToggleButton ? true : false,
            auto_assign_if_no_fulfiller: autoAssignFullfillerToggleButton ? true : false,
            enable_mdm_transmission: enableMdmTransmisionToggleButton ? true : false,
            mdm_transmission_types: MDMTransmissionType ? MDMTransmissionType.flatMap(obj=> obj.label.toString()) : "",
            mdm_transmission_allowed_fulfiller_groups: transmissionEnabledFulfiller ? transmissionEnabledFulfiller.flatMap(obj=> obj.value.toString()) : "",
          },
          {
            headers: {
              Authorization: API_TOKEN,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            //   toggle();
            //   setPatientDataReload(patientDataReload + 1)
            toastr.success("Success", "changes saved");

            // setApiCallDone(true)
          }
        })
        .catch((error) => {
          toastr.error("Failed", "Something went wrong");
        });
    }
    saveReferralData()
    
  };
  
  const fetchReferralSenderById = (grpid) => {
    AxiosInter
      .get(`${API_ENDPOINT}/admin-api/referral-sender-suggestion/${grpid}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          // this is for alert preference
          let categoryTypePopulated = JSON.parse(response?.data?.data[0]?.category_type)

          setcategoryTypePopulated(prevS=> [...prevS, categoryTypePopulated]);

          // if MDM button clicked 
          let populatedTransmissionType = JSON.parse(response.data.data[0]?.mdm_transmission_types)
          if (populatedTransmissionType) 
          // eslint-disable-next-line array-callback-return
            {populatedTransmissionType.map(eachName => {
              AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-mdm-transmissiontype-name/?typetopopulate=${eachName}`,
                {
                  headers: {
                    Authorization: API_TOKEN,
                  }
                })
                .then((response) => {
                  setMDMTransmissionType((prevS) => [...prevS, response.data.data[0]])})
                .catch((error) => {

                  throw error;
                });
            })
          }

          let populatedTransmissionEnabledIds = JSON.parse(response.data.data[0]?.mdm_transmission_allowed_fulfiller_groups)
          // we want to populate {value:'', label:''}
          if (populatedTransmissionEnabledIds) 
          // eslint-disable-next-line array-callback-return
            {populatedTransmissionEnabledIds.map(eachId => {
              AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-referralFullfiller-async-options/?senderid=${response.data.data[0].id}&groupID=${eachId}`,
                {
                  headers: {
                    Authorization: API_TOKEN,
                  }
                })
                .then((response) => setTransmissionEnabledFulfiller((prevS) => [...prevS, response.data.data[0]]))
                .catch((error) => {
                  throw error;
                });
            })
          }
          //END check MDM button clicked 


          setSenderid(response.data.data[0].id)

          setStatusToggleButton(response.data.data[0]["status"]);
          setEnableAutoProcessToggleButton(
            response.data.data[0]["enable_auto_process"]
          );
          // setEditCategoryType( JSON.parse(response.data.data[0]["category_type"]))
        //   axiosinter.get(`${API_ENDPOINT}/admin-api/get-category-type-options/`, {
        //     headers: {
        //         Authorization: API_TOKEN,
        //     },
        // })
        //     .then((response) => {
        //         if (response.status === 200) {
    
        //             setCategoryTypeOptions(response.data.data)
                    
    
        //         }
    
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //     });
          let catType=JSON.parse(response.data.data[0]["category_type"])
          
            var array = []
            // const data = editCategoryType
            
            if (catType.length > 0) {
              catType.map((id) => {               // eslint-disable-line
              categoryTypeOptions.map((item) => {   // eslint-disable-line
                    if (id.toString() === item.value.toString()) {
                        array.push({
                            value: item.value,
                            label: item.label
                        })
                    }
                })
            })
          }
            setCategoryType(array)
        
          // console.log('cttttttt',JSON.parse(response.data.data[0]["category_type"]))
          setIsClinicalToggleButton(response.data.data[0]["is_clinical"]);
          setIsTransportToggleButton(response.data.data[0]["is_transport"]);
          setAutoAssignFullfillerToggleButton(
            response.data.data[0]["auto_assign_if_no_fulfiller"]
          );
          setEnableMdmTransmisionToggleButton(
            response.data.data[0]["enable_mdm_transmission"]
          );
          // eslint-disable-next-line eqeqeq
          if (response.data.data[0]["subscription_type"] == "FULL") {
            SetSubscriptionType( { value: 'FULL', label: "Enterprise" });
          }
          // eslint-disable-next-line eqeqeq
          if (response.data.data[0]["subscription_type"] == "LITE") {
            SetSubscriptionType( { value: 'LITE', label: "Community" });
          }
          // eslint-disable-next-line eqeqeq
          if (response.data.data[0]["subscription_type"] == "LITE PLUS") {
            SetSubscriptionType( { value: 'LITE', label: "Community Plus" });
          }
        }
      })
      .catch((error) => {
        // toastr.error("Error", "Something went wrong in region listing !!");
      });
  };
  const categoryTypePopulate = () => {
    var array = []
    // const data = editCategoryType
    
    if (editCategoryType.length > 0) {
      editCategoryType.map((id) => {               // eslint-disable-line
      categoryTypeOptions.map((item) => {   // eslint-disable-line
            if (id.toString() === item.value.toString()) {
                array.push({
                    value: item.value,
                    label: item.label
                })
            }
        })
    })
  }
    setCategoryType(array)
}

useEffect(() => {
  if (groupID !== "" && groupID !== null && groupID !== undefined) {
    Promise.all([fetchReferralSenderById(grpid), fetchCategoryTypeOptions()])
      .then(() => {
        categoryTypePopulate();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}, [groupID]);//eslint-disable-line
// useEffect(() => {
//   if (groupID !== "" && groupID !== null && groupID !== undefined) {
//     fetchReferralSenderById(grpid);
//   }
// }, [groupID]);

 // eslint-disable-line
 const handleCategoryTypeChange = (event) => {
  setCategoryType(event);
};
  const functionForStatus = () => {
    setStatusToggleButton(!statusToggleButton);
  };
  const functionForAutoprocess = () => {
    setEnableAutoProcessToggleButton(!enableAutoProcessToggleButton);
  };
  const functionForIsClinical = () => {//eslint-disable-line
    setIsClinicalToggleButton(!isClinicalToggleButton);
  };
  const functionForIsTransport = () => {//eslint-disable-line
    setIsTransportToggleButton(!isTransportToggleButton);
  };

  const functionForAutoAssignFulfiller = () => {
    setAutoAssignFullfillerToggleButton(!autoAssignFullfillerToggleButton);
  };

  const functionForEnableMdm = () => {
    setEnableMdmTransmisionToggleButton(!enableMdmTransmisionToggleButton);
    if(!enableMdmTransmisionToggleButton){
      setMDMTransmissionType([])
      setTransmissionEnabledFulfiller([])
    }
  };
  const Option = (props) => {//eslint-disable-line
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  useEffect(() => {
    setStatusToggleButton(false);
    
    setEnableAutoProcessToggleButton(true);
    setIsClinicalToggleButton(true);
    setIsTransportToggleButton(false);
    setEnableMailNotificationToggleButton(false);
    setAutoAssignFullfillerToggleButton(false);
    setEnableMdmTransmisionToggleButton(false);
    SetSubscriptionType({ value: "1", label: "FULL" });
  }, []); // eslint-disable-line
  const status = statusToggleButton;
  const grpid = groupID ? groupID.value : "";

  const [MDMTransmissionTypeOptions,setMDMTransmissionTypeOptions] =useState([])

  useEffect(()=>{
    const mdmTransmissionTypeLoadOptions = () => {
      // const searchParams = new URLSearchParams({ name: inputValue }); // eslint-disable-line
      AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-mdm-transmissiontype-name/`,
        {
          headers: {
            Authorization: API_TOKEN,
          }
        })
        .then((response) => setMDMTransmissionTypeOptions(response.data.data))
        .catch((error) => {
          throw error;
        });
    };
    mdmTransmissionTypeLoadOptions()
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  const mdmTransmissionEnabledLoadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue }); // eslint-disable-line
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-referralFullfiller-async-options/?senderid=${senderid}&fulfillername=${inputValue}`,
        {
          headers: {
            Authorization: API_TOKEN,
          }
        })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  return (
    <Card style={{ border: "1px solid rgba(1, 0, 0, 0.25)" }}>
      <div>
        <CardHeader>
          <Row>
          <Col sm={12} className="text-center">
              <h5 style={{ fontWeight: 800 , margin: 0 }}>Sender</h5>
            </Col>
          </Row>
        </CardHeader>
        <hr
          style={{
            borderColor: "rgba(1, 0, 0, 0.25)",
            height: "3px",
            margin: "0",  
          }}
        />
        <br />
        <br />
        <br />
        <Row>
          <Col sm={1}></Col>

          <Col sm={5}>
            <p> Enable As Sender</p>
          </Col>
          <Col sm={2}>
            <ToggleButton
              inactiveLabel={"OFF"}
              activeLabel={"ON"}
              //  value={(smsToggleButton ===1)?true:false}
              onToggle={() => {
                functionForStatus();
              }}
              value={statusToggleButton}
            />
          </Col>
        </Row>
        <br />
        {status === true || status === 1 ? (
          <><Row>
          <Col sm={1}></Col>
          <Col sm={11}>
            <p>Referral Categories</p>
          </Col>
          <Col sm={1}></Col>
          <Col sm={8} style={{ paddingRight: 0 }}>
          <ReactSelect
              className="ovreflow-revert"
              options={categoryTypeOptions}
              isMulti
              closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
              allowSelectAll={true}
              onChange={handleCategoryTypeChange} 
              value={categoryType}
              placeholder="Type"
              isSearchable
              isClearable
            />
          </Col>
        </Row>
        <br></br>
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Enable Order Auto Process</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  // value={(emailToggleButton === 1)?true:false}
                  onToggle={() => {
                    functionForAutoprocess();
                  }}
                  value={enableAutoProcessToggleButton}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Enable Clinical Order</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  //   value={(ivrToggleButton === 1)?true:false}
                  onToggle={() => {
                    functionForIsClinical();
                  }}
                  value={isClinicalToggleButton}
                />
              </Col>
            </Row> */}
            {/* <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Enable Transport Orders</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  // value={(appToggleButton ===1)?true:false}
                  onToggle={() => {
                    functionForIsTransport();
                  }}
                  value={isTransportToggleButton}
                />
              </Col>
            </Row> */}
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Enable Auto Assign If No Fullfiller</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  // value={(appToggleButton ===1)?true:false}
                  onToggle={() => {
                    functionForAutoAssignFulfiller();
                  }}
                  value={autoAssignFullfillerToggleButton}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={1}></Col>
              <Col sm={5}>
                <p>Enable MDM Transmission</p>
              </Col>
              <Col sm={2}>
                <ToggleButton
                  inactiveLabel={"OFF"}
                  activeLabel={"ON"}
                  //   value={(ivrToggleButton === 1)?true:false}
                  onToggle={() => {
                    functionForEnableMdm();
                  }}
                  value={enableMdmTransmisionToggleButton}
                />
              </Col>
              {
                Boolean(enableMdmTransmisionToggleButton) &&
                <>
                  <Col sm={12} className="pl-5 pr-5 pb-3">
                    {/* <AsyncSelect
                      cacheOptions
                      isClearable
                      isMulti
                      value={MDMTransmissionType}
                      loadOptions={mdmTransmissionTypeLoadOptions}
                      onChange={(e) => {
                        setMDMTransmissionType(e)
                      }}
                      placeholder="Select MDM Transmission Type"
                    /> */}
                    <Select
                      value={MDMTransmissionType}
                      isMulti
                      isClearable
                      onChange={(e) => {
                        setMDMTransmissionType(e)
                      }}
                      options={MDMTransmissionTypeOptions}
                      placeholder="Select MDM Transmission Type"
                    />
                  </Col>
                  <Col sm={12} className="pl-5 pr-5 pb-3">
                      <AsyncSelect
                      cacheOptions
                      isClearable
                      isMulti
                      value={transmissionEnabledFulfiller}
                      loadOptions={mdmTransmissionEnabledLoadOptions}
                      onChange={(e) => {
                        setTransmissionEnabledFulfiller(e)
                      }}
                      placeholder="Select Transmission Enabled Fulfillers"
                      noOptionsMessage={() => 'Enter 4 or more letters to search'}
                    />
                  </Col>
                </>
              }
            </Row>
            <Row>
              <Col sm={1}></Col>
              <Col sm={4}>
                <p>Referral Account Type</p>
              </Col>
              <Col sm={6}>
                <Select
                  options={TypeOptions}
                  onChange={(event) => SetSubscriptionType(event)}
                  value={subscriptionType}
                  placeholder="Type"
                  isSearchable
                  isClearable
                />
              </Col>
            </Row>
            <br />
          </>
        ) : (
          ""
        )}
      </div>
      <Button
        // className="yes-button button-width"
        onClick={() => {
          saveNotifications();
        }}
      >
        Save
      </Button>{" "}
    </Card>
  );
};
export default ReferralOrderSender;
