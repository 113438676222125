import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  // eslint-disable-next-line no-unused-vars
  Button,
  // eslint-disable-next-line no-unused-vars
  Input,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator"; // eslint-disable-line
import moment from "moment";
import MomentConfig from "../../common/MomentConfig";
import { GlobalState } from "../../contextAPI/Context";

const ReferralByTagDataTable = (props) => {
  const { blankStateMessage } = useContext(GlobalState); // eslint-disable-line
  const [blankStateStatus, setBlankStateStatus] = useState(false);

  // LOCAL VARIABLES
  const { totalSize, handleTableChangeForReferralOrder } = props;
  // eslint-disable-next-line no-unused-vars
  const [numberOfRecords, setNumberOfRecords] = useState("NaN");

  //   const MyExportCSV = (props) => {
  //     const handleClick = () => {
  //       exportReferralFulfillerTableData();
  //     };

  //     return (
  //       <div>
  //         <button className="btn btn-export mt-2" onClick={handleClick}>
  //           Export
  //         </button>
  //       </div>
  //     );
  //   };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  const options = {
    page: props.page,
    nextPageText: ">",
    prePageText: "<",
    sizePerPage: props.sizePerPage,
    sizePerPageList: [25, 50, 150, 200],
    totalSize,
  };

  useEffect(() => {
    setNumberOfRecords(totalSize);
  }, [totalSize]);
  useEffect(() => {
    if (props.data.length === 0) {
      setBlankStateStatus(true);
    } else {
      setBlankStateStatus(false);
    }
  }, [props.data.length]);

  return (
    <div className="physicians_table adjustment">
      <ToolkitProvider
        keyField="id"
        data={props.data}
        columns={props.columns}
        search
        exportCSV={{
          fileName: `Equipo-Referral-fulfiller-Export-${moment().format(
            MomentConfig.MOMENT_FORMAT_DATEANDTIME
          )}.csv`,
        }}
      >
        {(props) => (
          <div>
            <Card>
              <CardHeader>
                <Row>
                  <Col className=""></Col>
                </Row>
                <div className="float-right pull-right"></div>
              </CardHeader>

              <CardBody className="patient-list">
                {blankStateStatus ? (
                  blankState
                ) : (
                  <BootstrapTable
                    {...props.baseProps}
                    bordered={false}
                    key={props.id}
                    remote
                    pagination={paginationFactory(options)}
                    onTableChange={handleTableChangeForReferralOrder}
                  />
                )}
              </CardBody>
            </Card>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default ReferralByTagDataTable;
