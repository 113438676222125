import React, { Component } from 'react'
import * as actions from './../../redux/actions/index'
import {connect} from 'react-redux'
import SignIn from './SignIn';
import Dashboard from '../../layouts/Dashboard';


class Logout extends Component {

    componentDidMount(){
        this.props.onLogout();
    }


    render() {
        return (
            <Dashboard><SignIn /></Dashboard>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: ()=>dispatch(actions.logout())
    };
}



export default connect(null, mapDispatchToProps)(Logout);