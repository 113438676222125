import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'

const ProcessStatusChangeModal = (props) => {
  const {
    modalState,
    setModalState,
    status,
    reloadUserData,
    setReloadUserData,
    tableID,
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const toggle = () => {
    setModalState(!modalState);
  };

  const confirmation = () => {
    
   
    const updatedStatus = {
      
        row_id: tableID,
    };

    AxiosInter.post( `${API_ENDPOINT}/admin-api/file-set-processed-adminpanel/`, updatedStatus,{
      headers: {
        Authorization: API_TOKEN,
      },
    }).then((res) => {
        if (res.status === 200) {
          setReloadUserData(reloadUserData+1)
          setModalState(!modalState);
          toastr.success('Status Updated', 'User status updated successfully');
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <div>
      <Button color="danger" onClick={toggle}></Button>
      <Modal isOpen={modalState} >
        <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Change Process Status</ModalHeader>
        <ModalBody>
            <div style={{"text-align": "center"}}>
            Are you sure you want to mark the entry as {status === 1? 'Processed ?':'' } 
            </div>
        </ModalBody>
        <ModalFooter  className="custom-modal-footer">
          <Button className="yes-button button-width" onClick={confirmation}>
            Yes
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ProcessStatusChangeModal;
