import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import styles from "./ProviderDetails.module.css";
import Select from 'react-select';
import axios from "axios";

const EditMicroServiceUser = (props) => {
    const {
        userID,
        editUserModal,
        setEditUserModal,
        editName,
        setEditName,
        editEmail,
        setEditEmail,
        editGroup,
        setEditGroup,
        reload,
        setReload,
        groupNameOptions


    } = props;


    // LOCAL VARIABLES

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;
    const API_TOKEN = localStorage.getItem("accessToken");
  
    const [addButtonStatus, setAddButtonStatus] = useState(true);

    const toggle = () => {
        (setEditUserModal(!editUserModal));
        setEditName("")
        setEditEmail("")
        setEditGroup([])

    }
    // POPULATE GROUPNAME IN EDIT MODAL
    const setInitialGroupName = () => {
       
        const selectedGroups = editGroup.split(",");
        const selectedOptions = [];

        selectedGroups.forEach((groupName) => {
            const groupOption = groupNameOptions.find(
                (option) => option.label === groupName
            );
            if (groupOption) {
                selectedOptions.push({ value: groupOption.value, label: groupOption.label });
            }
        });

        setEditGroup(selectedOptions);
    }

    // UPDATE API CALL
    const UpdateUserApiCall = () => {
        let groupsArray = []
        if (editGroup) {
            editGroup.map((item) => {   // eslint-disable-line
                groupsArray.push(item.value);
            });
        }
        const params = {
            // "id": userID ? userID : 0,
            "name": editName ? editName : "",
            "email": editEmail ? editEmail : "",
            "groups": editGroup ? groupsArray : "",

        }

        axios.put(`${API_ENDPOINT}api/user/${userID}`, params, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(response => {
                console.log(response.status, 'status')
                if (response.status === 205) {
                    toggle();
                    toastr.success("Success", "User Successfully Updated");
                    setReload(reload + 1);
                }
            })
            .catch(error => {
                toastr.error("Error", "Something went wrong !")


            });
    }


    const disableAddButton = () => {

        if (editName) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }


    useEffect(() => { disableAddButton() }, [editName]) // eslint-disable-line
    useEffect(() => { if (!editUserModal) setEditUserModal(false) }, [editUserModal]) // eslint-disable-line
    useEffect(() => { if (editUserModal) { setInitialGroupName(); } }, [editUserModal]);    // eslint-disable-line


    return (
        <div>
            <Modal isOpen={editUserModal} size="md" scrollable={true} >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Edit User </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row  >
                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        User Name    <span className="required">*</span> :

                                    </Label>
                                </div>

                                <Input
                                    id="drgCode"
                                    value={editName}
                                    placeholder="Enter the Name"
                                    onChange={(e) => { if (e.target.value.length <= 150) setEditName(e.target.value) }}
                                />
                            </Col>

                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        Email :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Input
                                        id="Description"
                                        value={editEmail}
                                        placeholder="Enter Email"
                                        onChange={(e) => { if (e.target.value.length <= 150) setEditEmail(e.target.value) }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br></br>
                        <Row className={styles.detailProfRow} >
                            <Col sm={12} >
                                <div className="" >
                                    <Label>

                                        Groups   <span className=""></span> :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Select
                                        isMulti
                                        options={groupNameOptions}
                                        onChange={(event) => setEditGroup(event)}
                                        value={editGroup}
                                        placeholder="Select Group"
                                        isSearchable
                                        isClearable
                                    />
                                </div>
                            </Col>
                        </Row>

                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={UpdateUserApiCall}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default EditMicroServiceUser;