import React from 'react';
import {
    CardHeader,
    Row,
    Col,
    // Button,
    // Input
} from "reactstrap";



const SearchBarComponentForProgram = (props) => {

    const { 
        // searchKeyword,
        totalSize, 
        // setSearchKeyword, 
        // clearValues
    } = props;

    return (
                <CardHeader>
                    <div>
                    <Row>
                        <Col
                            sm={{
                                size: '2',
                            }}
                        >
                            <div className="number-of-records">
                                <p>Number of records: <strong>{totalSize ? totalSize : 0}</strong></p>
                            </div>
                        </Col>
                  
                       
                    </Row>
                    <div className="separator" />
                    <div className="float-right pull-right">
                    </div>
                     
                   
                    </div>
                   
                </CardHeader>
               
                
       

        );
}

export default SearchBarComponentForProgram;