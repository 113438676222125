import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import ToggleButton from 'react-toggle-button';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';


const PatientEngagementModal = (props) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");

    const {
        patientEngagementModal,
        setPatientEngagementModal,
        id,
        smsToggleButton,
        ivrToggleButton,
        appToggleButton,
        emailToggleButton,
        setSmsToggleButton,
        setAppToggleButton,
        setEmailToggleButton,
        setIvrToggleButton,
        patientDataReload,
        setPatientDataReload
        
    } = props;

    
   
    
    
    const toggle = () => {(
        
        setPatientEngagementModal(!patientEngagementModal));
      

    }
    
    
    const saveNotifications =() => {

        
            AxiosInter.post(`${API_ENDPOINT}/admin-api/update-patientengagement-notifications/`,{
                "id": id ? id : "",
                "isSMSEnabled": smsToggleButton ? true : false,
                "isEmailEnabled": emailToggleButton ? true : false,
                "isIVREnabled": ivrToggleButton ? true : false,
                "isAppEnabled": appToggleButton ? true : false,

        },{
            headers: {
                Authorization: API_TOKEN,
              },
            })
              .then(response => {
                if (response.status === 200) {
                  
                  toggle();
                  setPatientDataReload(patientDataReload + 1)
                  toastr.success('Success','Patient changes saved')
        
                  // setApiCallDone(true)
                }
              })
              .catch(error => {
                toastr.error('Failed','Something went wrong')
              });
        }
    

    const functionForSms = () => {
        setSmsToggleButton(!smsToggleButton)
    }
    const functionForEmail = () => {
        setEmailToggleButton(!emailToggleButton)
    }
    const functionForIvr = () => {
        setIvrToggleButton(!ivrToggleButton)
    }
    const functionForApp = () => {
        setAppToggleButton(!appToggleButton)
    }

    useEffect(()=>{
        if(!patientEngagementModal) {
        setSmsToggleButton(true)
        setIvrToggleButton(true)
        setEmailToggleButton(true)
        setAppToggleButton(true)

        }
         
    },[patientEngagementModal]) // eslint-disable-line

    return (
        <div>
          <Modal isOpen={patientEngagementModal} size="sm" >
            <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Patient Engagement</ModalHeader>
            <ModalBody className="overflow-modal">
            <div style={{ marginLeft: '0.70rem' }}>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <Row>
                                                <Col sm={1}></Col>
                                                <Col sm={2}>
                                                    <p>Sms</p>
                                                </Col>
                                                <Col sm={2}>
                                                    <ToggleButton
                                                         inactiveLabel={'OFF'}
                                                         activeLabel={'ON'}
                                                        //  value={(smsToggleButton ===1)?true:false}
                                                        onToggle={()=> {functionForSms()}}
                                                        value={smsToggleButton}
                                                        
                                                            
                                                        
                                                    />
                                                </Col>
                                                <Col sm={1}></Col>
                                                <Col sm={2}>
                                                    <p>
                                                        Email
                                                    </p>
                                                </Col>
                                                <Col sm={2}>
                                                    <ToggleButton
                                                        inactiveLabel={'OFF'}
                                                        activeLabel={'ON'}
                                                        // value={(emailToggleButton === 1)?true:false}
                                                        onToggle={()=> {functionForEmail()}}
                                                        value={emailToggleButton}
                                                        
                                                    />

                                                </Col>
                                            
                                            
                                            </Row>
                                            <br/>
                                            <Row>
                                            <Col sm={1}></Col>
                                                <Col sm={2}>
                                                    <p>Call</p>
                                                </Col>
                                                <Col sm={2}>
                                                    <ToggleButton
                                                          inactiveLabel={'OFF'}
                                                          activeLabel={'ON'}
                                                        //   value={(ivrToggleButton === 1)?true:false}
                                                        onToggle={()=> {functionForIvr()}}  
                                                        value={ivrToggleButton}
                                                          
                                                    />


                                                </Col>
                                                <Col sm={1}></Col>
                                                <Col sm={2}>
                                                    <p>
                                                        App
                                                    </p>
                                                </Col>
                                                <Col sm={2}>
                                                    <ToggleButton
                                                        inactiveLabel={'OFF'}
                                                        activeLabel={'ON'}
                                                        // value={(appToggleButton ===1)?true:false}
                                                        onToggle={()=> {functionForApp()}}
                                                        value={appToggleButton}
                                                        
                                                    />

                                                </Col>
                                            </Row>

                                            
                                        </div>
               
              
            </ModalBody>
            <ModalFooter className="advanced-search-button  custom-modal-footer">
              <Button
                // className="yes-button button-width"
                onClick={() => {
                    saveNotifications()
                  
                }}
                
                
    
              >Save</Button>{' '}
    
              <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </div>)}
    


export default PatientEngagementModal;