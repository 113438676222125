import React, { useState, useEffect, useContext } from 'react';
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { Col, Label, Row, Button, Input, Spinner } from "reactstrap";
import './DateRangeSelector.css';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { GlobalState } from '../../contextAPI/Context';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import Tooltip from '@mui/material/Tooltip';
import { toastr } from "react-redux-toastr";
import { useLocation } from "react-router-dom";


const AppUserFilters = (props) => {

    // CONTEXT VARIABLES
    const {
        setAppUserName,
        setAppUserRole,
        setAppUserUsername,
        setAppUserNPI,
        setAppUserEmail,
        setAppUserStatus,
        setAppUserEmailDigest,
        setAppUserGroup,
        appUserReload, setAppUserReload,
        roleListForDropDown, setRoleListForDropDown,
        groupNamesForDropDown
    } = useContext(GlobalState)

    const { offset,setOffset } = props

    // LOCAL VARIABLES 
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    const animatedComponents = makeAnimated();
    const location = useLocation();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [role, setRole] = useState("")
    const [userName, setUserName] = useState("")
    const [NPI, setNPI] = useState("")
    const [group, setGroup] = useState("")
    const [status, setStatus] = useState("")
    const [spinner, setSpinner] = useState(false)
    const [roleList, setRoleList] = useState("")
    const [search, setSearch] = useState(false)
    const [digestEnable,setDigestEnable] = useState("") 
    const regXForNubers = /^[0-9]*$/;

    


    const StatusOptions = [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' },
        { value: '99', label: 'Locked' }
    ]
    const DigestOptions = [
        { value: '1', label: 'Enabled' },
        { value: '0', label: 'Disabled' }
    ]

    const runSearch = () => {
        if (name || email || role || NPI || status || group || userName||digestEnable) {
            
            setAppUserName(name ? name : "")
            setAppUserEmail(email ? email : "")
            setAppUserRole(role ? role : "")
            setAppUserNPI(NPI ? NPI : "")
            setAppUserUsername(userName ? userName : "")
            setAppUserStatus(status ? status : "")
            setAppUserEmailDigest(digestEnable ? digestEnable : "")
            setAppUserGroup(group ? group : "")
            setAppUserReload(appUserReload+1)
        }
    }

    const resetFields = () => {
        setName("")
        setEmail("")
        setRole("")
        setNPI("")
        setGroup("")
        setStatus("")
        setDigestEnable("")
        setUserName("")
        setAppUserName("")
        setAppUserEmail( "")
        setAppUserRole( "")
        setAppUserNPI("")
        setAppUserUsername("")
        setAppUserStatus("")
        setAppUserEmailDigest("")
        setAppUserGroup("")
        setAppUserReload(appUserReload+1)
       
    }
    useEffect(() => {
        // debugger
          if (location.pathname === '/appusers') {
            resetFields();
          }
        }, [location.pathname]);//eslint-disable-line
    
            
    const getRoleList = () => {
        setSpinner(true)
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-m-role-list/`,
            {
                headers: {
                    Authorization: API_TOKEN,
                },
            })
            .then((res) => {
                setRoleList(res.data.data);
                setSpinner(false)
            })
            // .catch((error) => {
            //     // console.log(error);
            //     setSpinner(false)
            // });
    }

    // const fetchGroupsAPI = () => {
    //     setSpinner(true)
    //     axiosinter.get(`${API_ENDPOINT}/admin-api/get-groups-list/`, {
    //       headers: {
    //         Authorization: API_TOKEN,
    //       },
    //     })
    //       .then(function (response) {
    //         if (response.data.length > 0) {
    //           setGroupList(response.data);
    //         }
    //         setSpinner(false)
    //       })
    //       .catch(function (error) {
    //         setGroupList("");
    //         setSpinner(false)
    //       });
    // };

    const createRoleOption = () => {

        setSpinner(true)
        var array = roleList

        var SortedRoleDropDownList =array.sort(function(a, b) {

            if (a.designation < b.designation) return -1;
            if (a.designation > b.designation) return 1;
            return 0;
        })

        const options = SortedRoleDropDownList.map((item) => {
            return { value: item.id, label: item.designation };
        });
        setRoleListForDropDown(options)
        setSpinner(false)
    }

    // const createGroupOption = () => {
    //     if(groupList){
    //         setSpinner(true)
    //         var array1 = groupList
    //         const options1 = array1.map((item, index) => {
    //             return {
    //                 value: item.id,
    //                 label: item.name
    //             };
    //         });
    //         setGroupDropDown(options1);
    //         setSpinner(true)
    //     }
    // };

    const enableOrDisableSearch = () => {
        if (name || email || role || userName || NPI || group || status || digestEnable ) {
          setSearch(true)
        } else {
          setSearch(false)
        }
    }
    const changeOnNPI = (e) => {
        // debugger
        if (regXForNubers.test(e) === false) {
            toastr.warning("Invalid NPI Number", "alphabets are not allowed")
        } else {
            setNPI(e)
        }
    }
    
    // EFFECTS FOR CORPORATE / GROUP
    useEffect(() => { enableOrDisableSearch(); }, [name,email,role,userName,NPI,group,status,digestEnable]) // eslint-disable-line
    useEffect(() => { if ( !roleList  ) getRoleList();  }, []) // eslint-disable-line
    useEffect(() => { if ( roleList ) createRoleOption(); }, [roleList]) // eslint-disable-line



    return (
        <AvForm>
            <AvGroup>
            {spinner
              ? <Spinner />
              : ""}
                <Row>
                    <Col sx={1.5} >
                        <Label>Name</Label>
                      

                        <AvField
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={name}
                            validate={{
                                maxLength: {value: 120}
                            }} 
                            onChange={(e) => {setName(e.target.value)}}
                            />
                          
                    </Col>
                    <Col sx={1.5} >
                        <Label>User Name</Label>
                        <AvField
                            type="text"
                            name="userName"
                            placeholder="User Name"
                            value={userName}
                            validate={{
                                maxLength: {value: 120}
                              }} 
                            onChange={(e) => {setUserName(e.target.value)}}
                        />
                    </Col>
                    <Col sx={1.5}>
                        <Label>Email</Label>
                        <AvField
                            type="text"
                            name="email"
                            placeholder="Email"
                            value={email}
                            validate={{
                                maxLength: {value: 254}
                              }} 
                            onChange={(e) => {setEmail(e.target.value)}}
                        />
                    </Col>
                    <Col sx={1.5} >
                        <Label>Type</Label>
                        <Tooltip title={role ? role.label : ''} placement="top" arrow>
                        <div>
                        <Select 
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setRole(event) }}
                            isSearchable
                            isClearable
                            placeholder="Select"
                            value={role}
                            options={roleListForDropDown}
                            components={animatedComponents}

                        /></div>
                        </Tooltip>
                    </Col>
                    <Col sx={1.5} >
                        <Label>NPI</Label>
                        <Input
                            className="form-control"
                            type="text"
                            name="postCode"
                            placeholder="NPI"
                            value={NPI}
                            onChange={(e) => { 
                                if (e.target.value.length >= 20) {
                                    toastr.warning("Invalid Number", "number length exceeded")
                                }else{
                                    changeOnNPI(e.target.value);
                                }
                            }}
                        />
                    </Col>
                    <Col sx={1.5} >
                        <Label>Groups</Label>
                        <Tooltip title={group ? group.label : ''} placement="top" arrow>
                        <div>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setGroup(event) }}
                            isSearchable
                            isClearable
                            placeholder="Select"
                            value={group}
                            options={groupNamesForDropDown}
                            components={animatedComponents}
                        /></div>
                        </Tooltip>
                    </Col>
                    <Col  sx={1.5} >
                        <Label>Status</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setStatus(event) }}
                            isSearchable
                            placeholder="Select"
                            value={status}
                            options={StatusOptions}
                            components={animatedComponents}
                        />
                    </Col>
                    <Col  sx={1.5} >
                        <Label>Email Digest</Label>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            onChange={(event) => { setDigestEnable(event) }}
                            isSearchable
                            placeholder="Select"
                            value={digestEnable}
                            options={DigestOptions}
                            components={animatedComponents}
                        />
                    </Col>
                    <Col xs={0.4} >
                        <Button
                            type="submit"
                            className="go-buttons"
                            disabled={!search ? true : false}
                            title={spinner || !search ? "Nothing To Search" : ""}
                            onClick={() => { offset === '0' ? runSearch() : setOffset('0') }}
                            >Go</Button>
                    </Col>
                    <Col xs={1.5}>
                        <Button
                            type="reset"
                            className="reset-buttons"
                            onClick={() => { resetFields() }}
                            disabled={!search  ? true : false}
                            title={spinner || !search ? "Nothing To Clear" : ""}
                        >Reset</Button>
                    </Col>
                       
                    
                </Row>
                <Row>
                
                </Row>
            </AvGroup>
        </AvForm>
    )
}

export default AppUserFilters
