import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Input,
  Label,
  Form,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AxiosInter from "./../../AxiosInter.js";
import Cookie from "js-cookie";
import { toastr } from "react-redux-toastr";

function NewReferralCategoryConfigurationModal({
  isOpen,
  setIsOpen,
  toggleModal,
  setApiLoaded,
}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  // store newcategory
  const [newCategory, setNewCategory] = useState("");

  // getting sender allowed status data from api call
  const [senderAllowedStatus, setSenderAllowedStatus] = useState([]);

  // getting fullfiller allowed status data from api call
  const [fullfillerAllowedStatus, setFullfillerAllowedStatus] = useState([]);

  // if user check static data then only display dropdown for viewtype
  const [staticDataRadioButtonClicked, setStaicDataRadioButtonClicked] =
    useState(false);
  const [dynamicRadioButtonClicked, setDynamicRadioButtonClicked] =
    useState(false);

  // viewtype selected values stored here
  const [selectedViewTypeOptions, setSelectedViewTypeOptions] = useState([]);

  // sender allowed status selected values stored here
  const [selectedSenderAllowedStatus, setSelectedSenderAllowedStatusOptions] =
    useState([]);

  // fullfiller allowed status selected values stored here
  const [
    selectedFullfillerAllowedStatus,
    setSelectedFullfillerAllowedStatusOptions,
  ] = useState([]);

  //----- for 1st async select -----
  //  view type options are hard coded
  const viewTypeOptions = [
    { value: "patientData", label: "patientData" },
    { value: "orderData", label: "orderData" },
    { value: "financialData", label: "financialData" },
    { value: "eligibilityData", label: "eligibilityData" },
    { value: "preAuthData", label: "preAuthData" },
    { value: "guarantorInfo", label: "guarantorInfo" },
  ];

  const filterViewType = (inputValue) => {
    return viewTypeOptions.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptionsViewType = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterViewType(inputValue));
      }, 1000);
    });

  // storing selected viewtypes
  const handleViewTypeChange = (itemSelected) => {
    setSelectedViewTypeOptions(itemSelected);
  };

  // is user check staticData then only display dropdown for viewtype
  const handleViewTypeStaticData = (e) => {
    setStaicDataRadioButtonClicked(true);
    setDynamicRadioButtonClicked(false);
  };

  // if user check dynamicData dropdown of staticData will hidden
  const handleViewTypeDynamicData = (e) => {
    setStaicDataRadioButtonClicked(false);
    setDynamicRadioButtonClicked(true);
    setSelectedViewTypeOptions([]);
  };

  //-----  for 2nd async select -----
  // this is for searching functionality in async select
  const filterSenderAllowedStatus = (inputValue) => {
    return senderAllowedStatus.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptionsSenderAllowedStatus = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterSenderAllowedStatus(inputValue));
      }, 1000);
    });

  // storing selected sender allowed status
  const handleSenderAllowedStatusChange = (itemSelected) => {
    setSelectedSenderAllowedStatusOptions(itemSelected);
  };

  //----- for 3rd async select -----
  const filterFullfillerAllowedStatus = (inputValue) => {
    return fullfillerAllowedStatus.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptionsFullfillerAllowedStatus = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterFullfillerAllowedStatus(inputValue));
      }, 1000);
    });

  // storing selected sender allowed status
  const handleFullfillerAllowedStatusChange = (itemSelected) => {
    setSelectedFullfillerAllowedStatusOptions(itemSelected);
  };

  // Allowed priority input fields first have one row with 4 input fields
  // if user click plus button then new row with 4 input fields come
  const [allowedPriorityInputFields, setAllowedPriorityInputFields] = useState([
    { priority: "", value: "", description: "", intervalSpecifiedIn: "" },
  ]);
  // add new row when click plus(+) button
  const handleAddNewRow = (index) => {
    const newallowedPriorityInputFields = [
      ...allowedPriorityInputFields,
      { priority: "", value: "", description: "", intervalSpecifiedIn: "" },
    ];
    setAllowedPriorityInputFields(newallowedPriorityInputFields);
  };
  // remove row when click minus(-) button
  const handleRemoveRow = (index) => {
    const newallowedPriorityInputFields = [...allowedPriorityInputFields];
    newallowedPriorityInputFields.splice(index, 1);
    setAllowedPriorityInputFields(newallowedPriorityInputFields);
  };
  // values storing to state when user fill input fields
  const handleAllowedPriorityInputChange = (index, event) => {
    const { name, value } = event.target;
    const newallowedPriorityInputFields = [...allowedPriorityInputFields];
    newallowedPriorityInputFields[index][name] = value;
    setAllowedPriorityInputFields(newallowedPriorityInputFields);
  };

  // backend call to get all Sender Allowed Status data and fullfiller allowed status
  // both have same data table
  const getAllSenderAllowedStatusOptions = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get_referralorder_status/`)
      .then(function (response) {
        setSenderAllowedStatus(response.data.data);
        setFullfillerAllowedStatus(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllSenderAllowedStatusOptions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // store newCategoryObj to database onsubmit of form
  const handleAddNewCategory = (e) => {
    e.preventDefault();

    // object to array, interger stored as string
    const viewTypeArray = selectedViewTypeOptions.map((option) => option.value);

    // object to array, interger stored as string
    const senderAllowedStatusArray = selectedSenderAllowedStatus.map(
      (option) => option.value + ""
    );
    const fullfillerAllowedStatusArray = selectedFullfillerAllowedStatus.map(
      (option) => option.value + ""
    );

    const newCategoryObj = {
      category: newCategory,
      view_type: staticDataRadioButtonClicked
        ? "staticData"
        : dynamicRadioButtonClicked
        ? "dynamicForm"
        : "",
      view_section: viewTypeArray.length === 0 ? null : viewTypeArray,
      sender_allowed_status: senderAllowedStatusArray,
      fulfiller_allowed_status: fullfillerAllowedStatusArray,
      allowed_priority: allowedPriorityInputFields,
      icons: "",
    };

    // checking all mandatory fields are filled
    validateForm(newCategoryObj, viewTypeArray);
  };

  // checking all mandatory fields are filled
  const validateForm = (newCategoryObj, viewTypeArray) => {
    if (newCategoryObj.category === "") {
      toastr.error("Please enter category name");
    }

    // if any of radio button not cliked then show an error
    else if (!staticDataRadioButtonClicked && !dynamicRadioButtonClicked) {
      toastr.error("Please select view type");
    }

    // if your click staticData radio button and not selected any option from dropdown show an error
    // eslint-disable-next-line eqeqeq
    else if (staticDataRadioButtonClicked && viewTypeArray.length == 0) {
      toastr.error("Please select from static data");
    }

    // if sender allowed status not selected show an error
    else if (newCategoryObj.sender_allowed_status.length === 0) {
      toastr.error("Please select sender allowed status");
    }

    // if fullfiller allowed status not selected show an error
    else if (newCategoryObj.fulfiller_allowed_status.length === 0) {
      toastr.error("Please select fullfiller allowed status");
    }

    // if user did not enter allowed priority values show an error
    else if (newCategoryObj.allowed_priority) {
      const allowed_priority = newCategoryObj.allowed_priority;
      let isValid = true;
    // eslint-disable-next-line array-callback-return
      allowed_priority.map((eachItem) => {
        if (
          eachItem.description === "" ||
          eachItem.intervalSpecifiedIn === "" ||
          eachItem.priority === "" ||
          eachItem.value === ""
        ) {
          toastr.error("Please enter all allowed priority fields");
          isValid = false;
        }
      });

      if (isValid) {
        // post data to database
        storeNewCategory(newCategoryObj, viewTypeArray);
      }
    }
  };

  const storeNewCategory = (newCategoryObj, viewTypeArray) => {
    AxiosInter.post(
      `${API_ENDPOINT}/admin-api/add-referral_category/`,
      newCategoryObj,
      {
        headers: {
          Authorization: API_TOKEN,
        },
      }
    )
      .then((response) => {
        // eslint-disable-next-line eqeqeq
        if (response == undefined) {
          toastr.error("Category already exist");
        } else {
          toastr.success("Category added");
          setApiLoaded(true);
          setIsOpen(false);
        }
      })
      .catch((err) => {
        if(err.response.data.message === 'Category already exists'){
          toastr.error("Category already exist");
        }
        else
          toastr.error("Something went to wrong!");
      });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="md"
        className="referral-category-configuration"
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggleModal}
        >
          Add New Category Configuration
        </ModalHeader>
        <Form onSubmit={handleAddNewCategory}>
          <ModalBody>
            <Row>
              <Col className="bottom-space">
                <Label>
                  Category <span className="required">*</span>
                </Label>
                <Input
                  type="text"
                  name="categories"
                  value={newCategory}
                  placeholder="Enter category"
                  onChange={(e) => setNewCategory(e.target.value)}
                />
              </Col>
              <Col xs={12} className="bottom-space">
                <Label>
                  View Type <span className="required">*</span>
                </Label>
                <Col className="viewtype-input">
                  <Input
                    type="radio"
                    name="viewType"
                    onChange={handleViewTypeStaticData}
                  />
                  StaticData
                </Col>
                {staticDataRadioButtonClicked && (
                  <AsyncSelect
                    onChange={handleViewTypeChange}
                    isMulti
                    cacheOptions
                    defaultOptions
                    placeholder="Select static data"
                    loadOptions={loadOptionsViewType}
                  />
                )}
                <Col className="viewtype-input">
                  <Input
                    type="radio"
                    name="viewType"
                    value="null"
                    onChange={handleViewTypeDynamicData}
                  />
                  DynamicForm
                </Col>
              </Col>
              <Col xs={12} className="bottom-space">
                <Label>
                  Sender Allowed Status <span className="required">*</span>
                </Label>
                <AsyncSelect
                  onChange={handleSenderAllowedStatusChange}
                  isMulti
                  cacheOptions
                  defaultOptions={senderAllowedStatus}
                  placeholder="Select sender allowed status"
                  loadOptions={loadOptionsSenderAllowedStatus}
                />
              </Col>
              <Col xs={12} className="bottom-space">
                <Label>
                  Fullfiller Allowed Status <span className="required">*</span>
                </Label>
                <AsyncSelect
                  onChange={handleFullfillerAllowedStatusChange}
                  isMulti
                  cacheOptions
                  defaultOptions={fullfillerAllowedStatus}
                  placeholder="Select fulfiller allowed status"
                  loadOptions={loadOptionsFullfillerAllowedStatus}
                />
              </Col>
              <Col xs={12} className="bottom-space">
                <Label>
                  Allowed Priority <span className="required">*</span>
                </Label>
                {allowedPriorityInputFields.map((inputField, index) => (
                  <Row className="pb-3" key={index}>
                    <Col>
                      <Input
                        type="text"
                        name="priority"
                        value={inputField.priority}
                        placeholder="Priority"
                        onChange={(event) =>
                          handleAllowedPriorityInputChange(index, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Input
                        type="number"
                        name="value"
                        value={inputField.value}
                        placeholder="Value"
                        onChange={(event) =>
                          handleAllowedPriorityInputChange(index, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Input
                        type="text"
                        name="description"
                        value={inputField.description}
                        placeholder="Description"
                        onChange={(event) =>
                          handleAllowedPriorityInputChange(index, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Input
                        type="text"
                        name="intervalSpecifiedIn"
                        value={inputField.intervalSpecifiedIn}
                        placeholder="intervalSpecifiedIn"
                        onChange={(event) =>
                          handleAllowedPriorityInputChange(index, event)
                        }
                      />
                    </Col>
                    <Col xs={1}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        size={"1x"}
                        color={"#3f86fa"}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAddNewRow(index)}
                        title="Add"
                      />
                    </Col>
                    {/* first row not need delete button */}
                    <Col xs={1}>
                      { // eslint-disable-next-line eqeqeq
                      !index == 0 && (
                        <FontAwesomeIcon
                          icon={faTrash}
                          color={"#E12C2C"}
                          size={"1x"}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleRemoveRow(index);
                          }}
                          title="Remove"
                        />
                      )}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="advanced-search-button  custom-modal-footer">
            <Button
              type="submit"
              className="yes-button button-width"
              style={{ cursor: "pointer" }}
            >
              Add
            </Button>{" "}
            <Button
              outline
              style={{ cursor: "pointer" }}
              className="no-button button-width"
              onClick={toggleModal}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default NewReferralCategoryConfigurationModal;
