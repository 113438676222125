import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  Spinner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import moment from "moment";
import { GlobalState } from "../../contextAPI/Context";
import { Select as SelectVirtualized } from "react-select-virtualized";
import Cookie from 'js-cookie';

const EditAllProviderModal = (props) => {
  const {
    editProviderListID,
    setUserDataReload,
    userDataReload,
    editProviderModal,
    setEditProviderModal,
    setEditNote,
    editNote,
    setEditContactNumber,
    editContactNumber,
    setEditPhoneHome,
    editPhoneHome,
    setEditFax,
    editFax,
    setEditTaxonomyCode,
    editTaxonomyCode,
    setEditStateMedicaidId,
    editStateMedicaidId,
    setEditUpin,
    editUpin,
    setEditDeaNumber,
    editDeaNumber,
    setEditExpiration,
    editExpiration,
    setEditLicenseNumber,
    editLicenseNumber,
    setEditMarketingRep,
    editMarketingRep,
    setEditFacility,
    editFacility,
    setEditDoctorGroup,
    editDoctorGroup,
    setEditProviderType,
    editProviderType,
    setEditState,
    editState,
    setEditCity,
    editCity,
    setEditAddress2,
    editAddress2,
    setEditAddress,
    editAddress,
    setEditCredentials,
    editCredentials,
    setEditDegree,
    editDegree,
    setEditLastName,
    editLastName,
    setEditMiddleName,
    editMiddleName,
    setEditFirstName,
    editFirstName,
    setEditEmail,
    editEmail,
    setEditNpi,
    editNpi,
    editTitle,
    setEditTitle,
    editReferralStatus,
    // eslint-disable-next-line no-unused-vars
    setEditReferralStatus,
    editProviderID,
    setEditProviderID,
    zipCodeList,
    editZipID,
  } = props;
  // CONTEXT VARIABLES
  const { m_zipList } = useContext(GlobalState); // eslint-disable-line

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [zipCodeOptions, setZipCodeOptions] = useState([]);
  const [selectedZip, setSelectedZip] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [updateButtonStatus, setUpdateButtonStatus] = useState(false);
  const [isProviderEmailValid, setIsProviderEmailValid] = useState(false);
  const regXForNubers = /^[0-9]*$/;
  const regXForFaxNumbers = /^[0-9,-]*$/;
  // eslint-disable-next-line no-useless-escape
  const regexForEmail = new RegExp("^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-zA-Z0-9-]+\\.)+[a-zA-Z0-9]{2,}$");
  

  const toggle = () => {
    setEditProviderModal(!EditAllProviderModal);
    setIsProviderEmailValid(false);
  };
  const ProviderTitleOptions = [
    { value: "1", label: "Dr." },
    { value: "2", label: "MS." },
    { value: "3", label: "MR." },
    { value: "4", label: "PROF." },
  ];

  const confirmation = () => {
    if (!editFirstName && editLastName) {
      toastr.error("Name Field Is Required", "Please enter the group name!");
    }

    if (!editNpi) {
      toastr.error("npi Field Is Required", "Please enter the category!");
    }

    if (editFirstName && editLastName && editNpi) {
      updateProviderCall();
    }
  };

  const updateProviderCall = () => {
    var date2 = new Date();
    const format1 = "YYYY-MM-DD HH:mm:ss";
    let dateTime1 = moment(date2).format(format1);

    const phoneformat = (number) => {
      return (
        "(" +
        number.slice(0, 3) +
        ")" +
        " " +
        number.slice(3, 6) +
        "-" +
        number.slice(6, 10)
      );
    };

    if (editEmail && editEmail.length > 1) {
      if (!regexForEmail.test(editEmail)) {
        setIsProviderEmailValid(true);
        return false;
      } else {
        setIsProviderEmailValid(false);
      }
    }
    
    setSpinner(true);

    AxiosInter.post(`${API_ENDPOINT}/admin-api/update-provider/`,
        {
          provider_List_ID: editProviderListID ? editProviderListID : "",
          title: editTitle ? editTitle.label : "",
          email: editEmail ? editEmail : "",
          npi: editNpi ? editNpi : "",
          first_name: editFirstName ? editFirstName : "",
          middle_name: editMiddleName ? editMiddleName : "",
          last_name: editLastName ? editLastName : "",
          degree: editDegree ? editDegree : "",
          credentials: editCredentials ? editCredentials : "",
          address: editAddress ? editAddress : "",
          address2: editAddress2 ? editAddress2 : "",
          city: editCity ? editCity : "",
          state: editState ? editState : "",
          zipcode: selectedZip ? selectedZip.label : "",
          providertype: editProviderType ? editProviderType : "",
          doctor_group: editDoctorGroup ? editDoctorGroup : "",
          facility: editFacility ? editFacility : "",
          marketing_rep: editMarketingRep ? editMarketingRep : "",
          license_number: editLicenseNumber ? editLicenseNumber : "",
          expiration: editExpiration ? editExpiration : "",
          dea_number: editDeaNumber ? editDeaNumber : "",
          upin: editUpin ? editUpin : "",
          state_medicaid_id: editStateMedicaidId ? editStateMedicaidId : "",
          taxonomy_code: editTaxonomyCode ? editTaxonomyCode : "",
          fax: editFax ? phoneformat(editFax) : "",
          phone_home: editPhoneHome ? phoneformat(editPhoneHome) : "",
          contactnumber: editContactNumber ? editContactNumber : 0,
          providerID: editProviderID ? editProviderID : "",

          referralStatus: editReferralStatus ? editReferralStatus.value : "",
          updateDate: dateTime1 ? dateTime1 : "",
          note: editNote ? editNote : "",
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setSpinner(false);
        setEditProviderModal(!editProviderModal);
        setUserDataReload(userDataReload + 1);
        toastr.success(
          "Successfully Updated",
          "Provider details successfully updated"
        );
      })
      .catch(function (error) {
        setSpinner(false);
        let errormessage = error.response.data['message'] ? error.response.data['message'] : "Please try again"
        toastr.error("Update Failed", errormessage);
      });
  };
  const setInitialZipCode = () => {
    // eslint-disable-next-line array-callback-return
    zipCodeOptions.map((data) => {
      // eslint-disable-line
      if (data.label === editZipID) {
        setSelectedZip({ value: data.value, label: data.label });
      }
    });
  };
  const changeOnPhoneNumber = (e) => {
    if (regXForNubers.test(e) === false) {
      toastr.warning("Invalid Phone Number", "alphabets are not allowed");
    } else {
      setEditPhoneHome(e);
    }
  };
  const changeOnNPI = (e) => {
    if (regXForNubers.test(e) === false) {
      toastr.warning("Invalid attempt", "alphabets are not allowed");
    } else {
      setEditNpi(e);
    }
  };
  const onChangeForFaxNumber = (e) => {
    if (regXForFaxNumbers.test(e) === false) {
      toastr.warning("Invalid Fax Number", "alphabets are not allowed");
    } else {
      setEditFax(e);
    }
  };

  const setZipCodeDropDownOptions = (data) => {
    var array = data;
    const options = array.map((item) => {
      return {
        value: item.id,
        label: item.zipcode,
      };
    });
    setZipCodeOptions(options);
  };
  const populateStateAndCountry = () => {
    var array = m_zipList;
    // eslint-disable-next-line array-callback-return, no-unused-vars
    const opt = array.map((item) => {
      // eslint-disable-line

      if (selectedZip.value === item.id) {
        setEditCity(item.city);
        setEditState(item.stateFullName);
      }
    });
  };
  useEffect(() => {
    if (selectedZip !== null && m_zipList) {
      populateStateAndCountry();
    } else {
      setEditCity("");
      setEditState("");
    }
  }, [selectedZip]); // eslint-disable-line

  useEffect(() => {
    if (editProviderModal) {
      setZipCodeDropDownOptions(zipCodeList);
    }
  }, [editProviderModal]); // eslint-disable-line
  useEffect(() => {
    if (zipCodeOptions) {
      setInitialZipCode();
    }
  }, [zipCodeOptions, editZipID]); // eslint-disable-line

  useEffect(() => {
    if (!editProviderModal) {
      setSelectedZip("");
    }
  }, [editProviderModal]); // eslint-disable-line

  const disableUpdateButton = () => {
    if (editFirstName && editLastName && editNpi) {
      setUpdateButtonStatus(false);
    } else {
      setUpdateButtonStatus(true);
    }
  };

  useEffect(() => {
    disableUpdateButton();
  }, [editFirstName, editLastName, editNpi]); // eslint-disable-line

  return (
    <div>
      <Modal
        id="providermodal"
        isOpen={editProviderModal}
        size="lg"
        scrollable={true}
      >
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit Provider{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          {spinner ? <Spinner /> : ""}
          <FormGroup>
            <Row>
              <Col sm={2}>
                <Label>Title</Label>
                <Select
                  options={ProviderTitleOptions}
                  onChange={(event) => setEditTitle(event)}
                  value={editTitle}
                  placeholder="Title"
                  isSearchable
                  isClearable
                />
              </Col>
              <Col sm={10}>
                <Label>
                  First Name:<span className="required">*</span>
                </Label>
                <Input
                  id="fName"
                  type="text"
                  value={editFirstName}
                  placeholder="Enter First Name"
                  onChange={(e) => {
                    setEditFirstName(e.target.value);
                  }}
                
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Middle Name:</Label>
                <Input
                  id="MName"
                  type="text"
                  value={editMiddleName}
                  placeholder="Enter Middle Name "
                  onChange={(e) => {
                    setEditMiddleName(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>
                  Last Name:<span className="required">*</span>
                </Label>
                <Input
                  id="LName"
                  type="text"
                  value={editLastName}
                  placeholder="Enter Last Name"
                  onChange={(e) => {
                    setEditLastName(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>{" "}
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Credentials:</Label>
                <Input
                  id="credentials"
                  type="text"
                  value={editCredentials}
                  placeholder="Enter Credential"
                  onChange={(e) => {
                    setEditCredentials(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Email:</Label>
                <Input
                  id="email"
                  type="email"
                  value={editEmail}
                  placeholder="Enter Email"
                  onChange={(e) => {
                    setEditEmail(e.target.value);
                  }}
                  invalid={isProviderEmailValid ? true : false}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Degree:</Label>
                <Input
                  id="Degree"
                  type="text"
                  value={editDegree}
                  placeholder="Enter Degree"
                  onChange={(e) => {
                    setEditDegree(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Contact Number:</Label>
                <Input
                  id="contact"
                  type="number"
                  value={editContactNumber}
                  placeholder="Enter the contact number "
                  onChange={(e) => {
                    if (e.target.value.length >= 13) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } else {
                      setEditContactNumber(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Phone Home:</Label>
                <Input
                  id="Phone Home"
                  value={editPhoneHome}
                  placeholder="Enter the Phone Home "
                  onChange={(e) => {
                    if (e.target.value.length >= 13) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } else {
                      changeOnPhoneNumber(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Fax:</Label>
                <Input
                  id="contact"
                  type="text"
                  value={editFax}
                  placeholder="Enter the fax number "
                  onChange={(e) => {
                    if (e.target.value.length >= 11) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } else {
                      onChangeForFaxNumber(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label for="zip">
                  ZIP:<span className="required">*</span> :
                </Label>
                <SelectVirtualized
                  name="zip"
                  options={zipCodeOptions}
                  value={selectedZip}
                  placeholder="Choose Zip Code"
                  isSearchable
                  isClearable
                  onChange={(e) => {
                    setSelectedZip(e);
                  }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label> City:</Label>
                <Input
                  id="city"
                  type="text"
                  value={editCity}
                  disabled
                  placeholder="Enter The City"
                  onChange={(e) => {
                    setEditCity(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>State:</Label>
                <Input
                  id="state"
                  type="text"
                  disabled
                  value={editState}
                  placeholder="Enter The State"
                  onChange={(e) => {
                    setEditState(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Address:</Label>
                <Input
                  id="address"
                  type="textarea"
                  rows={4}
                  cols={2}
                  value={editAddress}
                  placeholder="Enter The Address"
                  onChange={(e) => {
                    setEditAddress(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Address2:</Label>
                <Input
                  id="address2"
                  type="textarea"
                  rows={4}
                  cols={2}
                  value={editAddress2}
                  placeholder="Enter The Second Address "
                  onChange={(e) => {
                    setEditAddress2(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Provider Id:</Label>
                <Input
                  id="Providerid"
                  type="number"
                  value={editProviderID}
                  placeholder="Enter Provider id"
                  onChange={(e) => {
                    setEditProviderID(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Provider Type:</Label>
                <Input
                  id="Providertype"
                  type="text"
                  value={editProviderType}
                  placeholder="Enter Provider Type"
                  onChange={(e) => {
                    setEditProviderType(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Doctor Group:</Label>
                <Input
                  id="doctorGroup"
                  value={editDoctorGroup}
                  placeholder="Enter Doctor Group"
                  onChange={(e) => {
                    setEditDoctorGroup(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Facility:</Label>
                <Input
                  id="Facility"
                  type="text"
                  value={editFacility}
                  placeholder="Enter Facility"
                  onChange={(e) => {
                    setEditFacility(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Marketing Rep:</Label>
                <Input
                  id="marketing rep"
                  type="text"
                  value={editMarketingRep}
                  placeholder="Enter marketing Representative"
                  onChange={(e) => {
                    setEditMarketingRep(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>License Number:</Label>
                <Input
                  id="Licence Number"
                  value={editLicenseNumber}
                  placeholder="Enter License Number"
                  onChange={(e) => {
                    setEditLicenseNumber(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>
                  NPI: <span className="required">*</span> :
                </Label>
                <Input
                  id="npi"
                  value={editNpi}
                  placeholder="Enter the NPI number "
                  onChange={(e) => {
                    changeOnNPI(e.target.value);
                    if (e.target.value.length >= 11) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } 
                    else{
                      changeOnNPI(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Taxonomy Code:</Label>

                <Input
                  id="taxonomy code"
                  type="text"
                  value={editTaxonomyCode}
                  placeholder="Enter the Taxonomy code "
                  onChange={(e) => {
                    if (e.target.value.length >= 50) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } else {
                      setEditTaxonomyCode(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Dea Number:</Label>
                <Input
                  id="dea"
                  value={editDeaNumber}
                  placeholder="Enter the dea number "
                  onChange={(e) => {
                    if (e.target.value.length >= 50) {
                      toastr.warning(
                        "Invalid Number",
                        "number length exceeded"
                      );
                    } else {
                      setEditDeaNumber(e.target.value);
                    }
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Expiration Date:</Label>
                <Input
                  id="expiration"
                  type="date"
                  value={editExpiration}
                  placeholder="Enter the Expiration Date "
                  min={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setEditExpiration(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Upin:</Label>
                <Input
                  id="upin"
                  value={editUpin}
                  placeholder="Enter the upin "
                  onChange={(e) => {
                    setEditUpin(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>State Medicaid ID:</Label>
                <Input
                  id="medicaid id"
                  value={editStateMedicaidId}
                  placeholder="Enter The Medicaid id "
                  onChange={(e) => {
                    setEditStateMedicaidId(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
            <br></br>
            {/* <Row>
              <Col sm={12}>
                <Label>Referral Status:</Label>
                <Select
                  options={ReferralStatusOptions}
                  onChange={(event) => setEditReferralStatus(event)}
                  value={editReferralStatus}
                  placeholder="Enter Status"
                  isSearchable
                  isClearable
                />
              </Col>
            </Row> */}
            <br></br>
            <Row>
              <Col sm={12}>
                <Label>Note:</Label>
                <Input
                  id="note"
                  type="textarea"
                  rows={4}
                  cols={2}
                  value={editNote}
                  placeholder="Enter The Note "
                  onChange={(e) => {
                    setEditNote(e.target.value);
                  }}
                  style={{ margin: "0" }}
                />
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button
            onClick={() => {
              confirmation();
            }}
            disabled={updateButtonStatus}
          >
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditAllProviderModal;
