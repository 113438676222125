import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Input,
} from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from "react-bootstrap-table-next";
// eslint-disable-next-line no-unused-vars
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator"; // eslint-disable-line
import moment from "moment";
import MomentConfig from "../../common/MomentConfig";
import { GlobalState } from "../../contextAPI/Context";
import ReferralAssigneeTagFilters from "../../newComponents/utilities/ReferralAssigneeTagFilters";
import NewReferralAssigneeTagModal from "../../newComponents/utilities/NewReferralAssigneeTagModal";

const ReferralAssigneeTagDataTable = (props) => {
  const { blankStateMessage } = useContext(GlobalState); // eslint-disable-line
  const [blankStateStatus, setBlankStateStatus] = useState(false);

  // LOCAL VARIABLES
  // eslint-disable-next-line no-unused-vars
  const {
    setSearchKeys,
    searchKeys,
    setReloadForReferralAssigneeTag,
    reloadForReferralAssigneeTag,
    totalSize,
    onTableChange,
    statusFilter,
    setStatusFilter,
    referralAssigneeTagGroupName,
    setReferralAssigneeTagGroupName,
    referralAssigneeTagName,
    setReferralAssigneeTagName,
    referralAssigneeTagAppUser_Name,
    setReferralAssigneeTagAppUser_Name,
    exportReferralAssigneeTagDatatable,
    offset,setOffset
  } = props;
  const [numberOfRecords, setNumberOfRecords] = useState("NaN");
  const [ReferralAssigneeTagAddModal, setReferralAssigneeTagAddModal] =
    useState(false);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      exportReferralAssigneeTagDatatable();
    };

    return (
      <div>
        <button className="btn btn-export mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  const options = {
    page: props.page,
    nextPageText: ">",
    prePageText: "<",
    sizePerPage: props.sizePerPage,
    sizePerPageList: [25, 50, 150, 200],
    totalSize,
  };

  useEffect(() => {
    setNumberOfRecords(totalSize);
  }, [totalSize]);
  useEffect(() => {
    if (props.data.length === 0) {
      setBlankStateStatus(true);
    } else {
      setBlankStateStatus(false);
    }
  }, [props.data.length]);

  return (
    <div className="physicians_table adjustment">
      <ToolkitProvider
        keyField="id"
        data={props.data}
        columns={props.columns}
        search
        exportCSV={{
          fileName: `Equipo-Referral-Assignee-Tag-Export-${moment().format(
            MomentConfig.MOMENT_FORMAT_DATEANDTIME
          )}.csv`,
        }}
      >
        {(props) => (
          <div>
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                    <ReferralAssigneeTagFilters
                      referralAssigneeTagGroupName={
                        referralAssigneeTagGroupName
                      }
                      statusFilter={statusFilter}
                      setStatusFilter={setStatusFilter}
                      setReferralAssigneeTagGroupName={
                        setReferralAssigneeTagGroupName
                      }
                      referralAssigneeTagName={referralAssigneeTagName}
                      setReferralAssigneeTagName={setReferralAssigneeTagName}
                      referralAssigneeTagAppUser_Name={
                        referralAssigneeTagAppUser_Name
                      }
                      setReferralAssigneeTagAppUser_Name={
                        setReferralAssigneeTagAppUser_Name
                      }
                      reloadForReferralAssigneeTag={
                        reloadForReferralAssigneeTag
                      }
                      setReloadForReferralAssigneeTag={
                        setReloadForReferralAssigneeTag
                      }
                      setOffset={setOffset}
                      offset={offset}
                      
                    />
                  </Col>
                </Row>
                <div className="separator" />
                <Row>
                  <Col xs={2}>
                    <div className="number-of-records">
                      <p>
                        Number of records: <strong>{numberOfRecords}</strong>
                      </p>
                    </div>
                  </Col>
                  <Col className="">
                    <div className="form-inline justify-content-end">
                      <div className="form-group mr-2">
                        <Input
                          className="search-box-new"
                          type="text"
                          value={searchKeys}
                          onChange={(e) => setSearchKeys(e.target.value)}
                          placeholder="Search"
                        />
                      </div>
                      <Button
                        className="clear-search-box"
                        onClick={() => {
                          setSearchKeys("");
                          setReloadForReferralAssigneeTag(
                            reloadForReferralAssigneeTag + 1
                          );
                        }}
                      >
                        clear
                      </Button>
                      <div className="export-button ml-2">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                      <Button
                        className="referral-assignee-tag-add-btn ml-2 pull-right"
                        onClick={() => {
                          setReferralAssigneeTagAddModal(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          fixedWidth
                          className="align-middle"
                          title="See Fulfillers"
                        />
                        Referral Assignee Tag
                      </Button>
                    </div>
                  </Col>
                </Row>
                <div className="separator" />
                <div className="float-right pull-right"></div>
              </CardHeader>

              <CardBody className="patient-list">
                {blankStateStatus ? (
                  blankState
                ) : (
                  <BootstrapTable
                    {...props.baseProps}
                    bordered={false}
                    remote
                    pagination={paginationFactory(options)}
                    onTableChange={onTableChange}
                  />
                )}
              </CardBody>
            </Card>
          </div>
        )}
      </ToolkitProvider>
      <NewReferralAssigneeTagModal
        ReferralAssigneeTagAddModal={ReferralAssigneeTagAddModal}
        setReferralAssigneeTagAddModal={setReferralAssigneeTagAddModal}
        reloadForReferralAssigneeTag={reloadForReferralAssigneeTag}
        setReloadForReferralAssigneeTag={setReloadForReferralAssigneeTag}
      />
    </div>
  );
};

export default ReferralAssigneeTagDataTable;
