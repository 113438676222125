import React, { useEffect, useState } from "react";
import AxiosInter from './../../AxiosInter.js';
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Label,
  CardHeader,
} from "reactstrap";
import "../../newComponents/utilities/DateRangeSelector.css";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { toastr } from "react-redux-toastr";
import EditAllProviderSupportedInsurance from "../../newComponents/utilities/EditAllProviderSupportedInsurance";
import NewProviderSupportedInsurance from "../../newComponents/utilities/NewProviderSupportedInsurance";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css"; //import reat-datepicker css
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MomentConfig from "../../common/MomentConfig";
import SupportedInsuranceStatusChangeModal from '../../newComponents/utilities/SupportedInsuranceStatusChangeModal';
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import Cookie from "js-cookie";
import Select from "react-select";

const AllProviderSupportedInsurances = ({activeTab}) => {
  // CONTEXT VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const fileExportName = "Supported-Insurances";
  // eslint-disable-next-line no-unused-vars
  const [spinner, setSpinner] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [search, setSearch] = useState(false);
  const [providerInsuranceList, setProviderInsuranceList] = useState([]);
  const [editProviderInsuranceModal, setEditProviderInsuranceModal] = useState(false);
  const [editProviderName, setEditProviderName] = useState("");
  const [editGroupName, setEditGroupName] = useState("");
  const [editProviderNpi, setEditProviderNpi] = useState("");
  const [newProviderName, setNewProviderName] = useState("");
  const [newGroupName, setNewGroupName] = useState("");
  const [newProviderNpi, setNewProviderNpi] = useState("");
  const [editInsuranceName, setEditInsuranceName] = useState([]);
  const [newInsuranceName, setNewInsuranceName] = useState([{ label: "", value: "", code: "", insCode:"" }]);
  const [editProviderMappingID, setEditProviderMappingID] = useState([]);
  const [rowData, setRowData] = useState("");
  const [groupName, setGroupName] = useState("");
  const [providerName, setProviderName] = useState("");
  const [insuranceName, setInsuranceName] = useState("");
  const [status, setStatus] = useState("");//eslint-disable-line
  const [selectedStatus, setSelectedStatus] = useState("");//eslint-disable-line
  const [newSupportedInsurance, setNewSupportedInsurance] = useState(true);
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [newProviderInsuranceModal, setNewProviderInsuranceModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [groupID, setGroupID] = useState("");
  const [userDataReload, setUserDataReload] = useState(0);
  const [userSizePerPage, setUserSizePerPage] = useState("50");
  const [offset, setOffset] = useState("0");
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [SupportedInsurance, setSupportedInsurance] = useState(true);//eslint-disable-line
  const [showRemoveButton, setshowRemoveButton] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [insuranceType, setInsuranceType]=useState(null)
  const [selectedInsuranceType, setSelectedInsuranceType]=useState(null)
  const [editProviderInsuranceType, setEditProviderInsuranceType]=useState(null)

  let history = useNavigate();//eslint-disable-line
  let location = useLocation();
  let { id } = useParams();//eslint-disable-line
  let loader = null;//eslint-disable-line


  if (apiLoaded) {
    loader = <Spinner />;
  }
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(
    MomentConfig.MOMENT_FORMAT_DATEANDTIME
  )}.csv`;

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = "  ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <span
        className={badgeclass}
        onClick={() => {
          setNewProviderNpi(row.providerNpi);
          // console.log('rOw',row)
          setGroupID(row.groupId)
          setStatus(cell);
          setModalState(true);
        }}
      >
        {status}
      </span>
    );
  };

  const clearValues = () => {
    setProviderInsuranceList("");
    setProviderName("")
    setGroupName("");
    setProviderInsuranceList("");
    setInsuranceName("");
    setSortOrder("");
    setTotalSize(0);
    setSelectedInsuranceType("")
    setUserDataReload(userDataReload + 1);

    //setApiLoaded(true)
  };
  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };
  
  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faEdit}
              size={"1x"}
              color={"#3f86fa"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                // debugger
                setRowData(row);
                setEditProviderInsuranceModal(true);
                setEditProviderMappingID(row.providermappingid?.split(","))
                setEditProviderName(row.providerName);
                setEditGroupName(row.groupName);
                setEditProviderNpi(row.providerNpi);
                setEditProviderInsuranceType(row.insuranceType ? { value: row.insuranceType, label: row.insuranceType } : { value: "O", label: "select an insurance type" });
                let si = row.supportedInsurances?.split(",");
                let ic = row.Codeinsurance.split(",");

                const data = si.map((label, index) => ({
                  label: label,
                  value: Math.random(), // some random generated number
                  code: ic[index],
                }));


                if (data.length > 1) {
                  setshowRemoveButton(true)

                }
                setEditInsuranceName(data);
                // if (row.name) {
                //   setEditGroupName({
                //     value: (row ? row.groupID : "N/A").toString(),
                //     label: (row ? row.name : "N/A").toString(),
                //   });
                // }


              }}
              title="Edit Supported Insurance"
            />
          </Col>
        </Row>
      </>
    );
  };

  const columns = [
    {
      dataField: "groupName",
      text: "Group Name",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "providerName",
      text: "Provider Name",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "providerNpi",
      text: "NPI",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField:"insuranceType",
      text:"Insurance Type",
      sort:true,
      formatter:basicValidation
    },
    {
      dataField: "supportedInsurances",
      text: "Supported Insurances",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "Codeinsurance",
      text: "Insurance Code",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "rowStatus",
      text: "Status",
      // sort: true,
      formatter: statusFormatter,
    },
    {
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];

  const onTableChangeForuser = (type, newState) => {
    setPage(newState.page);
    setUserSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);
    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("pim.id");
      setSortOrder("desc");
      setUserDataReload(userDataReload + 1);
    } else if (newState.sortField === "providerName") {
      setSortColumn("au.name");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    } else if (newState.sortField === "groupName") {
      setSortColumn("g.name");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    }
    else if (newState.sortField === "supportedInsurances") {
      setSortColumn("pim.insuranceName");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    }
  };

  const handleChangeForInsuranceType = (selectedOptions) => {
    setSelectedInsuranceType(selectedOptions);
  };

  const getInsuranceTypes = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-insurance-types/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        // console.log('Response:', response);
        if (response.data.data.length > 0) {
          setInsuranceType(response.data.data)
        }
      })
  };

  useEffect(() => {
    getInsuranceTypes();
  }, []); // eslint-disable-line

  const appUserLoadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-app-user-name/${inputValue.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const groupNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const loadOptionsForInsurance = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-std-payer-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const exportTableData = () => {
    const params = {
      file_name: fileName ? fileName : "",
      groupName: groupName ? groupName.label : "",
      providerName: providerName ? providerName.label : "",
      providerNpi: "",
      insuranceName: insuranceName ? insuranceName.label : "",
      record_limit: userSizePerPage ? userSizePerPage : "50",
      OFFSET: offset ? offset.toString() : "0",
      searchKeyword: searchKeyword ? searchKeyword : "",
      is_pagination: "",
      sortOrder: sortOrder ? sortOrder : "desc",
      sortColumn: sortColumn ? sortColumn : "pim.id",
      insuranceType:selectedInsuranceType ? selectedInsuranceType.value :null
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/export-provider-insurance-mapping/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
    // .catch((error) => {
    //   toastr.error("Failed", "Something went wrong");
    // });
  };

  const searchProviderInsurance = () => {
    //setSpinner(true);
    setApiLoaded(true);
    const params = {
      groupName: groupName ? groupName.label : "",
      providerName: providerName ? providerName.label : "",
      providerNpi: "",
      insuranceName: insuranceName ? insuranceName.label : "",
      record_limit: userSizePerPage ? userSizePerPage : "50",
      OFFSET: offset ? offset.toString() : "0",
      searchKeyword: searchKeyword ? searchKeyword : "",
      is_pagination: "",
      sortOrder: sortOrder ? sortOrder : "desc",
      sortColumn: sortColumn ? sortColumn : "pim.id",
      insuranceType:selectedInsuranceType ? selectedInsuranceType.value :null
    };
    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-provider-insurance-list/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then(function (response) {
        if (response.data.data.length > 0) {
          setProviderInsuranceList(response.data.data);
          localStorage.setItem("offset", offset.toString());
          setTotalSize(response.data.record_count);
          setApiLoaded(false);
          //setSpinner(false);
        } else {
          setProviderInsuranceList("");
          setTotalSize(response.data.record_count);
          setApiLoaded(false);
          // setSpinner(false);
          // setIsDataEmpty(true);
        }
      })
    // .catch(function (error) {
    //   setProviderInsuranceList("");
    //   setApiLoaded(false);
    //   // setSpinner(false);
    // });
  };

  const enableOrDisableSearch = () => {
    if (groupName) {
      setSearch(true);
    } else {
      setSearch(true);
    }
  };

  useEffect(() => {
    enableOrDisableSearch();
  }, [groupName]); // eslint-disable-line
  // useEffect(() => { if(groupNamesForDropDown)createGroupOption(); }, [groupNamesForDropDown])

  useEffect(() => {
      // eslint-disable-next-line eqeqeq    
    if (userDataReload >= 1 && activeTab == 8) {
      searchProviderInsurance();
      localStorage.setItem("offset", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataReload, activeTab]);

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset) {
      searchProviderInsurance();
    }
  }, [offset]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length === 0) setUserDataReload(userDataReload + 1);
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      // setPage
      setTotalSize(0);
      setUserDataReload(userDataReload + 1);
      // setReloadGroupDetails(true);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  useEffect(() => {
    if (location.state) {
      localStorage.setItem(
        "isFilterApplied",
        JSON.stringify(location.state.detail)
      );
    }
  }, [location]);

  const handleInputChange = (value) => {
    setInputValue(value);
  };
  const handleAppuserChange = (value) => {
    setProviderName(value);
  };
  const handleGroupNameChange = (value) => {
    setGroupName(value);
  };
  const handleInputChangeForInsurance = (value) => {
    setInsuranceName(value);
  };
  // const handleCategoryChange = (value) => {
  //   setCategory(value);
  // };handleInputChangeForInsurance

  const clearSearchValues = () => {
    setSearchKeyword("");
    setUserDataReload(userDataReload + 1);
  };

  // CheckUserInactivity();
  return (
    // <div>
    <>
      {/* <Hux>
        {loader}
        <Container fluid>
          <Header>
            <HeaderTitle>Provider Supported Insurances</HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {" "}
                Provider Supported Insurances
              </BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Container>
      </Hux> */}

      <Card>
        <CardHeader>
          <Row>
          <Col sm={3}>
              <Label>
                Group Name:
              </Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="groupName"
                placeholder="Search with at least 4 characters."
                value={groupName}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleGroupNameChange}
                loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
              />
            </Col>
            <Col sm={3}>
              <Label>Provider Name :</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="provider Name"
                placeholder="Search with at least 4 characters."
                value={providerName}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleAppuserChange}
                loadOptions={(inputValue) => appUserLoadOptions(inputValue)}
              />
            </Col>
            <Col sm={3}>
              <Label>Insurances :</Label>
              <AsyncSelect
                isSearchable
                name="Insurance"
                placeholder="Select Insurance"
                loadOptions={loadOptionsForInsurance}
                //   value={x.stdPayerId}
                value={insuranceName}
                onChange={handleInputChangeForInsurance}
                // onChange={e => setDemIns(e)}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
              />
            </Col>
            <Col sm={3}>
              <Label>Insurance Type:</Label>
              <Select
                options={insuranceType}
                isSearchable
                isClearable
                value={selectedInsuranceType}
                onChange={handleChangeForInsuranceType}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <Button
                className="patient-go-buttons"
                disabled={!search ? true : false}
                title={
                  spinner || !search
                    ? "Choose at least one item to search "
                    : ""
                }
                onClick={() => {
                  offset === "0" ? searchProviderInsurance() : setOffset("0");
                }}
              >
                Go
              </Button>{" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => {
                  clearValues();
                }}
                disabled={!search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <div className="separator" />
        </CardHeader>

        <div className="supported-insurace-search">
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={columns}
            clearValues={clearSearchValues}
            SupportedInsurance={SupportedInsurance}
          />
        </div>

        <div>{spinner ? <Spinner /> : ""}</div>

        <RemotePagination
          data={providerInsuranceList ? providerInsuranceList : ""}
          columns={columns}
          page={page}
          sizePerPage={userSizePerPage}
          FileExportName={fileExportName}
          exportTableData={exportTableData}
          isGlobalSearch={isGlobalSearch}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            onTableChangeForuser(type, newState);
          }}
          isDataEmpty={isDataEmpty}
          setIsGlobalSearch={setIsGlobalSearch}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          newSupportedInsurance={newSupportedInsurance}
          setNewSupportedInsurance={setNewSupportedInsurance}
          setNewProviderInsuranceModal={setNewProviderInsuranceModal}
        />

      </Card>
        <EditAllProviderSupportedInsurance
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
          editProviderInsuranceModal={editProviderInsuranceModal}
          setEditProviderInsuranceModal={setEditProviderInsuranceModal}
          rowData={rowData}
          editProviderName={editProviderName}
          setEditProviderName={setEditProviderName}
          editGroupName={editGroupName}
          setEditGroupName={setEditGroupName}
          editProviderNpi={editProviderNpi}
          setEditProviderNpi={setEditProviderNpi}
          editInsuranceName={editInsuranceName}
          setEditInsuranceName={setEditInsuranceName}
          editProviderMappingID={editProviderMappingID}
          setEditProviderMappingID={setEditProviderMappingID}
          loadOptionsForInsurance={loadOptionsForInsurance}
          showRemoveButton={showRemoveButton}
          setshowRemoveButton={setshowRemoveButton}
          insuranceType={insuranceType}
          editProviderInsuranceType={editProviderInsuranceType}
          setEditProviderInsuranceType={setEditProviderInsuranceType}
        />
        <NewProviderSupportedInsurance
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
          newProviderInsuranceModal={newProviderInsuranceModal}
          setNewProviderInsuranceModal={setNewProviderInsuranceModal}
          rowData={rowData}
          newProviderName={newProviderName}
          setNewProviderName={setNewProviderName}
          newGroupName={newGroupName}
          setNewGroupName={setNewGroupName}
          newProviderNpi={newProviderNpi}
          setNewProviderNpi={setNewProviderNpi}
          newInsuranceName={newInsuranceName}
          setNewInsuranceName={setNewInsuranceName}
          editProviderMappingID={editProviderMappingID}
          setEditProviderMappingID={setEditProviderMappingID}
          loadOptionsForInsurance={loadOptionsForInsurance}
          showRemoveButton={showRemoveButton}
          setshowRemoveButton={setshowRemoveButton}
          insuranceType={insuranceType}
        />
      <SupportedInsuranceStatusChangeModal 
          modalState={modalState} 
          setModalState={setModalState} 
          setStatus={setStatus} 
          status={status} 
          newProviderNpi={newProviderNpi} 
          setNewProviderNpi={setNewProviderNpi}
          groupID={groupID}
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
          />
    </>
  );
};

export default AllProviderSupportedInsurances;
