import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator"; // eslint-disable-line
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import { GlobalState } from '../../contextAPI/Context';
import NewReferralFulfillerModal from "../../newComponents/utilities/NewReferralFulfillerModal"


const ReferralFulfillerDataTable = (props) => {
  const { blankStateMessage } = useContext(GlobalState)   // eslint-disable-line
  const [blankStateStatus,setBlankStateStatus] = useState(false); 

  
  // LOCAL VARIABLES 
  const { totalSize,handleTableChangeForFulfiller,referralFulfillerReload,  setReferralFulfillerReload,setSearchKeysForFulfiller,searchKeysForFulfiller,exportReferralFulfillerTableData} = props
  const [numberOfRecords, setNumberOfRecords] = useState("NaN")
  const [ReferralFulfillerAddModal, setReferralFulfillerAddModal] = useState(false);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      exportReferralFulfillerTableData();
    };

    return (
      <div>
        <button className="btn btn-export mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
     
  );

  const options = {
    page: props.page,
    nextPageText: '>',
    prePageText: '<',
    sizePerPage: props.sizePerPage,
    sizePerPageList: [25, 50, 150, 200],
    totalSize
  };


  useEffect(() => { setNumberOfRecords(totalSize); }, [totalSize]);
  useEffect(() => { 
    if(props.data.length === 0 ){
      setBlankStateStatus(true)
    }else {
      setBlankStateStatus(false)
    } 
  }, [props.data.length]);

  return (
    <div className="physicians_table adjustment">
      <ToolkitProvider
        keyField="id"
        data={props.data}
        columns={props.columns}
        search
        exportCSV={{
          fileName: `Equipo-Referral-fulfiller-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
        }}
      >
        {(props) => (
          <div>
            <Card>
              <CardHeader>
              <div className="separator" />
                <Row >
                  <Col xs={2}>
                    <div className="number-of-records">
                      <p>Number of records: <strong>{numberOfRecords}</strong></p>
                    </div>
                  </Col>
                  <Col className="">
                  <div className="form-inline justify-content-end">
                      <div className="form-group mr-2">
                    <Input
                      className="search-box-new"
                      type="text"
                      value={searchKeysForFulfiller}
                      onChange={(e) => setSearchKeysForFulfiller(e.target.value)}
                      placeholder="Search"

                    />
                    </div>
                    <Button
                      className="clear-search-box"
                      onClick={() => {setSearchKeysForFulfiller(""); setReferralFulfillerReload(referralFulfillerReload+1) }}
                    >
                      Clear
                    </Button>
                    <div className="export-button ml-2">
                      <MyExportCSV {...props.csvProps} />
                    </div>
                    <Button className="referral-add-btn ml-2 pull-right"
                      onClick={() => {
                        setReferralFulfillerAddModal(true)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        fixedWidth
                        className="align-middle"
                        title="See Fulfillers"
                      />
                      Referral Fulfiller
                    </Button>
                    </div>
                  </Col>
                </Row>
                <div className="separator" />
                <div className="float-right pull-right">
                </div>
              </CardHeader>

              <CardBody className="patient-list">
              
                {blankStateStatus ? blankState : 
                  <BootstrapTable
                    {...props.baseProps}
                    bordered={false}

                    remote
                    pagination={paginationFactory(options)}

                    onTableChange={handleTableChangeForFulfiller}
                  />
                }
                
              </CardBody>
            </Card>
          </div>

        )}
      </ToolkitProvider>
      <NewReferralFulfillerModal 
      ReferralFulfillerAddModal={ReferralFulfillerAddModal}
      setReferralFulfillerAddModal={setReferralFulfillerAddModal}
      
      />
    </div>
  );
};


export default ReferralFulfillerDataTable;
