import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import * as actions from "./../../redux/actions/index";
import AuthLayout from "./../../layouts/Auth";


import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
  Spinner,
  Row,
  Col
} from "reactstrap";

import equipologo from "../../assets/img/logos/equipo-logo_low-res_with-tagline.png";

class SignIn extends Component {
  state = {
    username: "",
    password: "",
    ip: "127.0.0.1",
  };

  onFormSubmit = (event) => {
    event.preventDefault();
    this.props.onAuth(this.state.username, this.state.password, this.state.ip);
  };

  onChangeUsername = (event) => {
    this.setState({ username: event.target.value });
  };
  onChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  render() {

    let errormessage = null;
    if (this.props.error != null) {
      errormessage = (
        <UncontrolledAlert color="danger">
          <div className="alert-message">Invalid Username or Password</div>
        </UncontrolledAlert>
      );
    }


    let loader = null;
    if (this.props.loading) {
      loader = <Spinner />;
    }

    return (
      <AuthLayout>
        <React.Fragment>
          <div className="sign-in-card">
          {loader}
          {errormessage}
          {/* <div className="text-center mt-4">
            <p className="lead">Sign in to Equipo Admin Dashboard</p>
          </div> */}

          <Card>
            <CardBody>
          <div className="card-for-sign-in">
              <Row>
                  
                
                <Col xs="4">
                <div className="only-card">
                    <div className="m-sm-4">
                    <div className="text-center">
                      <img
                        src={equipologo}
                        alt="equipo logo"
                        className="img-fluid "
                        width="150"
                        height="150"
                      />
                    </div>
                    <br></br>
                    <Form onSubmit={this.onFormSubmit}>
                      <FormGroup>
                        <Label>Email</Label>
                        <Input
                          name="username"
                          placeholder="Enter Username"
                          value={this.state.username}
                          onChange={this.onChangeUsername}
                          maxLength={254}
                          bsSize="lg"
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Password</Label>
                        <Input
                          bsSize="lg"
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.onChangePassword}
                          placeholder="Enter your password"
                          maxLength={24}
                          required
                        />
                        <small>
                          {/* <Link to="/auth/reset-password">Forgot password?</Link> */}
                        </small>
                      </FormGroup>
                      {/* <div>
                        <CustomInput
                          type="checkbox"
                          className="CustomInput"
                          id="rememberMe"
                          label="Remember me next time"
                          defaultChecked
                        />
                      </div> */}
                      <div className="text-center mt-3">
                        <FormGroup>

                          <Button   size="lg" type="submit">
                            Sign in
                          </Button>
                          
                        </FormGroup>
                      </div>
                      {/* <p className="newto">New to videoDoc? </p>
                        <Link className="button-link" to="/signup">
                          <Button color="primary" size="lg" outline>
                          Create an account
                          </Button>
                        </Link> */}
                    </Form>
                  </div>
                  </div>
                </Col>
                    <Col xs="8">

                      <div className="Section_top">
                        <div className="layer">

                        </div>
                      </div>

                      <div className="textt">


                        <h1>

                        </h1>

                        <p>

                        </p>

                      </div>

                    </Col>
              </Row>
          </div>
            </CardBody>
          </Card>
          </div>
        </React.Fragment>
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    // authRedirectPath: state.auth.authRedirectPath,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password, ip) =>
      dispatch(actions.auth(username, password, ip)),
    // onSetAuthDirectPath: () => dispatch(actions.setAuthRedirect("/")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
