import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,Button
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator"; // eslint-disable-line
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import ReferralImportDataFilters from '../../newComponents/utilities/ReferralImportDataFilters';
import { GlobalState } from '../../contextAPI/Context';

// eslint-disable-next-line no-unused-vars
const { SearchBar, ClearSearchButton } = Search;
const ReferralImportLogDataTable = (props) => {
  const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  const d = new Date();
  let month = months[d.getMonth()];

  const { blankStateMessage } = useContext(GlobalState) // eslint-disable-line
  const [blankStateStatus,setBlankStateStatus] = useState(false); // eslint-disable-line

  // LOCAL VARIABLES 
  const { data,senderDropDown,importlogReload,setImportlogReload,searchImport,setSearchImport } = props
  const [numberOfRecords, setNumberOfRecords] = useState(0)

  // For Filter Variables
  const [lyear, setLyear] = useState({ value: (d.getFullYear()).toString(), label: (d.getFullYear()).toString() })
  const [lmonth, setLmonth] = useState({ value: (d.getMonth()+1).toString(), label: month.toString() })

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };

    return (
      <div>
        <button className="btn btn-export mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
     
  );

  const defaultSorted = [{
    dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
    order: 'desc' // desc or asc
  }];

  const handleDataChange = ({ dataSize }) => {
    setNumberOfRecords(dataSize ? dataSize : 0);
  }

  useEffect(() => { setNumberOfRecords(data.length) }, [data]);
  useEffect(() => { 
    if(props.data.length === 0 ){
      setBlankStateStatus(true)
    }else {
      setBlankStateStatus(false)
    } 
  }, [props.data.length]);

  return (
    <div className="physicians_table adjustment">
      <ToolkitProvider
        keyField="id"
        data={props.data}
        columns={props.columns}
        search
        exportCSV={{
          fileName: `Equipo-Referral-import-data-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
        }}
      >
        {(props) => (
          <div>
            <Card>
              <CardHeader>
                <Row>
                  <Col>
                  {
                    senderDropDown ?   <ReferralImportDataFilters senderDropDown={senderDropDown} lyear={lyear} setLyear={setLyear} lmonth={lmonth} setLmonth={setLmonth} /> : ""
                  }
                   
                  </Col>
                </Row>
                <div className="separator" />
                <div>
                <Row >
                  <Col sm={2}>
                    <div className="number-of-records">
                      <p>Number of records: <strong>{numberOfRecords}</strong></p>
                    </div>
                  </Col>
                  <Col>
                  <div className="form-inline justify-content-end">
                      <div className="form-group mr-2">
                        <Input
                          id="num"
                          value={searchImport}

                          placeholder="Search"
                          onChange={(e) => {

                            setSearchImport(e.target.value)
                            setLyear('')
                            setLmonth('')

                          }}
                        />
                      </div>
                      <Button
                        className="clear-search-box"
                        onClick={() => { setSearchImport(""); setImportlogReload(importlogReload + 1) }}
                      >
                        Clear
                      </Button>
                      <div className="export-button ml-2">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                    </div>
                  </Col>
                </Row>
                </div>
                <div className="separator" />
                <div className="float-right pull-right">
                </div>
              </CardHeader>

              <CardBody className="admin-users-list">
              
                {blankStateStatus ?  blankState : <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    id={props.key}
                    bordered={false}
                    defaultSorted={defaultSorted}
                    onDataSizeChange={handleDataChange}
                    pagination={paginationFactory({
                      sizePerPage: 50,
                      sizePerPageList: [50, 100, 150, 200],
                    })}
                  />}
                
              </CardBody>
            </Card>
          </div>

        )}
      </ToolkitProvider>
    </div>
  );
};


export default ReferralImportLogDataTable;
