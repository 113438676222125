import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader,Container, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
// import Select from 'react-select';




const EditCareRosterModal = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

    const [buttonBlankState,setButtonBlankState] = useState(false);


    
    const {
        categoryModal,
        setCategoryModal,
        categoryModalState,
        editCptCategoryDescription,
        setEditCPTCategoryDescription,
        // eslint-disable-next-line no-unused-vars
        setEditCptCategoryId,
        selectedId,
        editStatus,
        setEditStatus,
        reloadCPTCategoryTableData,
        setReloadCPTCategoryTableData
    } = props



    const toggle = () => {
        setCategoryModal(!categoryModal)
    }

    const StatusOptions = [
      { value: '1', label: 'Active' },
      { value: '0', label: 'Inactive' }
  ]

   
  const setDefaultStatus = () => {
    // debugger
    StatusOptions.map((item) =>   // eslint-disable-line
    {
      if (editStatus === 0) {
        setEditStatus("Inactive");
      } else if (editStatus === 1) {
        setEditStatus("Active");
      }
      if (item.label === editStatus) {
        setEditStatus({ value: item.value, label: item.label });
      }
    });
  };





    const updateCareRoster = () => {
        const params =
        {
          "row_id": selectedId ? selectedId : 0,
          "title": editCptCategoryDescription ? editCptCategoryDescription : ""
           
            
        }
 
        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-care-plan-roster/`,params, {
          headers: {
            Authorization: API_TOKEN,
          },
        })
          .then(response => {
            setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1)
            toastr.success("Success","Updated successfully")
            toggle();

            
            
          })
          .catch(error => {
            toastr.error("Error",error.response.data.message)
           
          });

    }
    const addNewCptCategory = () => {
        const params =
        {
            "cpt_category_description":editCptCategoryDescription ? editCptCategoryDescription:"",
            "cpt_parent_category_id":""
           
            
        }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/add-cpt-category-details/`,params,{
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(response => {
        setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1)
        toastr.success("Success","CPT category details saved successfully")
        toggle();

        
        
      })
      .catch(error => {
        toastr.error("Error",error.response.data.message)
       
      });


    }

    const validate = () => {

        if (
            editCptCategoryDescription
    
        ) {
            setButtonBlankState(false)
        }
        else {
            setButtonBlankState(true)
        }
      }






    useEffect(() => {
        if (!categoryModal) {
           setCategoryModal(false);
           setEditCPTCategoryDescription("");
           
          
        };

    }, [categoryModal]) // eslint-disable-line
    useEffect(() => {
        if (categoryModal) {
          //  setCategoryModal(false);
          //  setEditCPTCategoryDescription("");
          setDefaultStatus()
           
          
        };

    }, [categoryModal]) // eslint-disable-line

    useEffect(() => {
      validate();

  }, [editCptCategoryDescription]) // eslint-disable-line


    // useEffect(() => {
    //     if (modal && cptCategoryList ) {
    //         setCPTCategoryDropdown(cptCategoryList)
    //     };

    // }, [modal,cptCategoryList]) // eslint-disable-line







    return (
        <div>
            <Modal isOpen={categoryModal} size="md" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>{categoryModalState === "add"? "New Care Roster":"Edit Care Roster"} </ModalHeader>
                <ModalBody className="overflow-modal">
                <Container fluid>
                    <FormGroup>
                        <Row>
                            <Col sm={12}>
                            <Label for="description">Title<span className="required">*</span></Label>
                                    <Input
                                        id="description"
                                        type="text"
                                        placeholder="Description"
                                        value={editCptCategoryDescription}
                                        onChange={(e) => {
                                            setEditCPTCategoryDescription(e.target.value)
                                        }}

                                    />

                            </Col>

                        </Row><br/>
                        {/* <Row>
                            <Col sm={12}>
                                <Label for="cpt_pID">Status</Label>
                                <Select
                                    name="cpt_pID"
                                    options={StatusOptions}
                                    value={editStatus}
                                    // placeholder="Choose Category"
                                    // isSearchable
                                    // isClearable
                                    onChange={(e) => {
                                      setEditStatus(e.target.label)
                                       
                                    }}
                                />

                            </Col>
                        </Row><br/> */}

                    </FormGroup>
                    </Container>

                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button
                  
                  onClick={() => {
                    categoryModalState === 'edit' ? updateCareRoster():addNewCptCategory()
                  }}
                    disabled = {buttonBlankState}
                    title= {buttonBlankState ? "Please fill in all mandatory fields":(!buttonBlankState && categoryModalState === "edit")?"Click to save":"Click to update"}

                    >{categoryModalState === "edit" ?'Update':'Add'}</Button>{' '}

                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditCareRosterModal;