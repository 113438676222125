import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  Container,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import Cookie from 'js-cookie';


const AddNewICDCategory = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  

  const [buttonBlankState, setButtonBlankState] = useState(false);
  const [parentCat, setParentCat] = useState();

  const {
    categoryModal,
    loadICDCategory,
    setCategoryModal,
    setAddState,
    setEditState,
    addState,
    editState,
    icdCategoryDescription,
    setIcdCategoryDescription,
    icdCategoryId,
    setIcdCategoryId,
    reloadICDCategoryTableData,
    setReloadICDCategoryTableData,
    setIcdCategoryPid,
    parentCategory,
  } = props;

  const toggle = () => {
    setCategoryModal(!categoryModal);
  };

  const parentOptions = [ // eslint-disable-line
    {
      value: 0,
      label: "N/A",
    },
  ];

  const editIcdCategoryDetails = () => {
    const params = {
      icd_category_description: icdCategoryDescription
        ? icdCategoryDescription
        : "",
      icd_category_id: icdCategoryId ? icdCategoryId : "",
      icd_category_pID :parentCat ? parentCat.value:"",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/edit-icd-category-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        setReloadICDCategoryTableData(reloadICDCategoryTableData + 1);
        toastr.success("Success", "Updated successfully");
        toggle();
        setParentCat("");
        loadICDCategory();
      })
      .catch((error) => {
        toastr.error("Error", error.response.data.message);
      });
  };
  
  const AddNewICDCategory = () => {
    const params = {
      icd_category_description: icdCategoryDescription
        ? icdCategoryDescription
        : "",
      icd_category_id: icdCategoryId ? icdCategoryId : "",
      icd_category_pID :parentCat ? parentCat.value:"",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/add-icd-category/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        setReloadICDCategoryTableData(true);
        toastr.success("Success", "ICD details saved successfully");
        toggle();
        setParentCat("");
        loadICDCategory();
      })
      .catch((error) => {
        toastr.error("Error", error.response.data.message);
      });
  };

  const validate = () => {
    if (icdCategoryDescription) {
      setButtonBlankState(false);
    } else {
      setButtonBlankState(true);
    }
  };

  // useEffect(() => {
  //     if (addModal) {
  //         setICDCategoryDropdown(icdCategoryList);
  //     }

  // }, [addModal])

  useEffect(() => {
    if (!categoryModal) {
      setAddState(false);
      setEditState(false);
      setIcdCategoryPid("");
      setIcdCategoryDescription("");
      setIcdCategoryId("");
    }
  }, [categoryModal]); // eslint-disable-line

  useEffect(() => {
    validate();
  }, [icdCategoryDescription]); // eslint-disable-line

  return (
    <div>
      <Modal isOpen={categoryModal} size="md">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          {addState ? "Add new Category" : "Edit Category Details"}{" "}
        </ModalHeader>
        <ModalBody >
          <Container fluid>
            <FormGroup>
              <Row>
                <Col sm={12}>
                  <Label for="category_description">
                    Category<span className="required">*</span>
                  </Label>
                  <Input
                    id="category_description"
                    type="textarea"
                    rows={4}
                    cols={2}
                    placeholder="Category Description"
                    value={icdCategoryDescription}
                    onChange={(e) => {
                      setIcdCategoryDescription(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={12}>
                  <Label for="p_id">PID</Label>
                  <Select
                    name="p_id"
                    options={parentCategory}
                    // value={parentCategory}
                    // loadOptions={loadICDCategory}
                    placeholder="Choose Parent Category"
                    isSearchable
                    isClearable
                    onChange={(e) => {setParentCat(e)}}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Container>
        </ModalBody>
        <ModalFooter className="custom-modal-footer">
          <Button
            onClick={() => {
              editState ? editIcdCategoryDetails() : AddNewICDCategory();
            }}
            disabled={buttonBlankState}
            title={
              buttonBlankState
                ? "Please fill in all mandatory fields"
                : !buttonBlankState && addState
                ? "Click to save"
                : "Click to update"
            }
          >
            {editState ? "Update" : "Add"}
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddNewICDCategory;
