import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col, Input, Button
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import { GlobalState } from '../../contextAPI/Context';
// import CheckUserInactivity from "../../redux/actions/checkUserInactivity";


/* const { SearchBar, ClearSearchButton } = Search; */
const ExportCSVTableForPatient = (props) => {
  const { blankStateMessage } = useContext(GlobalState)
  // LOCAL VARIABLES 
  const { totalSize,onTableChange,patientSizePerPage, page, setPatientDataReload,patientDataReload,setSearchKeyword,searchKeyword,exportTableData } = props
  const [numberOfRecords, setNumberOfRecords] = useState(0)

  const MyExportCSV = (props) => {
    const handleClick = () => {
      // props.onExport();
      exportTableData();
    };
    return (
      <div>
        <button className="btn btn-export mt-2" onClick={handleClick}>
          Export
        </button>
      </div>
    );
  };

  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  /* const handleDataChange = ({ dataSize }) => {
    setNumberOfRecords(dataSize ? dataSize : 0);
  } */

  useEffect(() => { setNumberOfRecords(totalSize);  }, [totalSize]);
  // CheckUserInactivity()
  return (
    <div  >
   
        <ToolkitProvider
          keyField="id"
          data={props.data}
          columns={props.columns}
          exportCSV={{
            fileName: `Equipo-Patient-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
          }}
        >
          {(props) => (
            <div>
              <CardHeader>
                <Row>
                  <Col sm="4">
                    <div className="number-of-records">
                      <p>Number of records: <strong>{numberOfRecords}</strong></p>
                    </div>
                  </Col>
                
               
                  <Col>
                    <div className="search-order-serial-number" >
                      </div>
                    <div className="form-inline justify-content-end">
                      <div className="form-group mr-2">
                        <Input
                          id="num"
                          value={searchKeyword}

                          placeholder="Search"
                          onChange={(e) => {
                            setSearchKeyword(e.target.value)
                          }}
                        />
                      </div>
                      <Button
                        className="clear-search-box"
                        onClick={() => { setSearchKeyword(""); setPatientDataReload(patientDataReload + 1) }}
                      >
                        Clear
                      </Button>
                      <div className="export-button ml-3">
                        <MyExportCSV {...props.csvProps} />
                      </div>
                    </div>
                  </Col>
                  </Row>
                  
              </CardHeader>
              <div className="separator" />
              <div className="patient-table-only" >
                
                {blankStateMessage && blankState}
                {!blankStateMessage && 
                <BootstrapTable
                  {...props.baseProps}
                  bordered={false}
                  remote
                  bootstrap4
                  hover
                  pagination={paginationFactory({
                    page: page,
                    sizePerPage: patientSizePerPage,
                    sizePerPageList: [50, 100, 150, 200],
                    totalSize
                  })}
                  onTableChange={ onTableChange }
                />}
              </div>
            </div>
          )}
        </ToolkitProvider>
  
    </div>
  );
};

export default ExportCSVTableForPatient;