import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Breadcrumb, BreadcrumbItem, Spinner, Button, Row, Col, Card, CardHeader } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { GlobalState } from '../../contextAPI/Context'
import NewMicroServiceGroup from "../../newComponents/utilities/NewMicroServiceGroup"
import { faEdit } from "@fortawesome/free-solid-svg-icons";// eslint-disable-line
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteConfirmationModal from '../../newComponents/utilities/DeleteConfirmationModal'
import { toastr } from 'react-redux-toastr';
import AuthGroupStatusChangeModal from "../../newComponents/utilities/AuthGroupStatusChangeModal";
import EditAuthGroupModal from "../../newComponents/utilities/EditAuthGroupModal";
import RemotePaginationAuth from "../../newComponents/utilities/RemotePaginationAuth";
import Chip from "@mui/material/Chip";
import axios from "axios";



const MicroServiceGroups = (props) => {
  // CONTEXT VARIABLES 
  const { spinner, setSpinner } = useContext(GlobalState);

  // LOCAL VARIABLES
  const [data, setData] = useState([]);// eslint-disable-line
  const [reload, setReload] = useState(0);
  const [offset, setOffset] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(50);
  // const [isPagination, setIsPagination] = useState(1); // eslint-disable-line
  const [totalSize, setTotalSize] = useState(0);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("")
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [drgDetails, setDRGDetails] = useState("")
  // const [isSearch, setIsSearch] = useState(false);// eslint-disable-line
  const [search, setSearch] = useState(false); // eslint-disable-line
  const [drgID, setDrgID] = useState("") // eslint-disable-line

  const [newdrgModal, setNewDrgModal] = useState(false);
  // const [editDrgModal, setEditDrgModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const fileExportName = 'Groups'

  const [selectedMdc, setSelectedMdc] = useState(""); // eslint-disable-line
  const [selectedType, setSelectedType] = useState(""); // eslint-disable-line
 
  // const [serviceOptions, setServiceOptions] = useState([]);
  // const [mdcOptions, setMdcOptions] = useState([]);

  // const [editDrgCode, setEditDrgCode] = useState("");
  // const [editMdc, setEditMdc] = useState("");
  // const [editType, setEditType] = useState("");
  // const [editDescription, setEditDescription] = useState("");
  // const [editWeight, setEditWeight] = useState("");
  // const [editGlos, setEditGlos] = useState("");
  // const [editAlos, setEditAlos] = useState("");
  const [sortColumn, setSortColumn] = useState(""); // eslint-disable-line
  const [sortOrder, setSortOrder] = useState(""); // eslint-disable-line
  const [editAuthGroupModal, setEditAuthGroupModal] = useState(false);
  const [serviceGroupId, setServiceGroupId] = useState('')
  const [groupId, setGroupId] = useState('')
  const [serviceId, setServiceId] = useState('')
  const [groupName, setGroupName] = useState('')
  const [serviceName, setServiceName] = useState([])
  const [groupStatus, setGroupStatus] = useState(true)
  const [groupEmail, setGroupEmail] = useState('')
  const [modalState, setModalState] = useState(false)
  const [serviceDict, setServiceDict] = useState([])
  const [services,setServices] = useState([])
  const [initialServiceNameLength,setInitialServiceNameLength] = useState(services.length)
  const [initialGroupNameLength, setInitialGroupNameLength] = useState(groupName.length)
  // const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;
  const API_TOKEN = localStorage.getItem("accessToken")

  const fetchDRG = () => {

     setSpinner(true);
     setReload(reload +1)
   

    axios.get(`${API_ENDPOINT}api/groups`, {
      headers: {
        Authorization: API_TOKEN,
      }
    }
    ).then(response => {
      if (response) {
        console.log('responSE', response)
        setDRGDetails(response.data);
        console.log('drgdetails', drgDetails)
        setTotalSize(response.data.record_count)
        setSpinner(false);
        localStorage.setItem('offset', offset);
        setReload(0)
        


      }
      else {
        setTotalSize(0);
        setDRGDetails("")
        setSpinner(false);


      }
    })
      .catch(error => {
        toastr.error("Error", "Something went wrong")
        setSpinner(false);
      });
  };


  const deleteDrgDetails = () => {
    setSpinner(true);
    const params ={"drg_id": drgID ? drgID : ""} // eslint-disable-line
  };

  // const fetchServiceOptions = () => {
  //   axios.get(`${API_ENDPOINT}/admin-api/get-service-options/`, 
  //   // {
  //   //   headers: {
  //   //     Authorization: API_TOKEN,
  //   //   },
  //   // }
  //   ).then((res) => {
  //     setServiceOptions(res.data.data);
  //   });
  // }

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }
  const ServiceValidation = (cell, row) => {
    const serviceNames = row.services.map(service => service.name);
    //console.log('thes service names' + serviceNames);
    if (serviceNames.length > 0) {
      
      const serviceName = serviceNames.join(', '); // eslint-disable-line
      //console.log(serviceName + ' this is service name');
      return (
        <>
          {serviceNames.map((item) => (
            <>
              <Chip color="primary" variant="outlined" label={item} />{" "}
            </>
          ))}
        </>
      )
    } else {
      return "--";
    }
  }






  const actionFormatter = (cell, row) => {


    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faEdit}
              color={'#3f86fa'}
              style={{ 'cursor': 'pointer' }}
              onClick={() => {
                setEditAuthGroupModal(true)
                setServices(row.services.map(service => ({label:service.name, value:service.id})))
                // setDrgID(row.id)
                // setEditDrgCode(row.code)
                // setEditMdc(row.MDCCode)
                // setEditType(row.type)
                // setEditDescription(row.description)
                // setEditWeight(row.weight)
                // setEditGlos(row.glos)
                // setEditAlos(row.alos)
                setGroupId(row.id)
                setGroupName(row.name)
                setInitialServiceNameLength(row.services.length)
                setInitialGroupNameLength(row.name.length)
                // setServiceName(services)
                setGroupStatus(row.status)
                setGroupEmail(row.email)
              }}

              title="Edit"

            />

          </Col>
          {/* <Col sm={2}>
              <FontAwesomeIcon
                icon={faTrash}
                color = {'#E12C2C'}
                style = {{'cursor':'pointer'}}
                onClick={() => {
                   setDrgID(row.id)
                   setDeleteModal(true)
   
                 }}
              
                title = "Delete"

              />
          </Col> */}
        </Row>
      </>
    )
    
  }

  useEffect(() => {
    console.log('these are services '+ services)
   }, [services])
  

  // const clearValues = () => {
  //   setSelectedMdc("")
  //   setSelectedType("")
  //   setData("")
  //   setReload(reload + 1)
  // }
  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (row.status === true) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else if (row.status === false) {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }
    else {
      status = "N/A";
    }

    return (
      <>
        <span
          className={badgeclass}
          onClick={() => {
            // setGroupStatus(row.status);
            // setGroupId(row.id);
            setModalState(true);
            setGroupName(row.name);
            setGroupEmail(row.email);
            setGroupStatus(row.status);
            setServiceId(row.service);
            setGroupId(row.id);

          }}
        >
          {status}
        </span>
      </>
    );
  };

  const drgTypeOptions = [
    { value: '1', label: "SURG" },
    { value: '2', label: "MED" }
  ]
  const tableColumns = [
    {
      dataField: "name",
      text: "Group Name",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "services",
      text: "Services",
      sort: true,
      formatter: ServiceValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "email",
      text: "Group Email",
      sort: true,
      formatter: basicValidation,
      csvFormatter: basicValidation,

    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: statusFormatter,
      // csvFormatter: statusFormatterCSV,

    },

    {
      text: "Actions",
      csvExport: false,
      formatter: actionFormatter,
    }

  ];
  const handleTableChange = (type, newState) => {
    setPage(newState.page)
    setSizePerPage(newState.sizePerPage)
    setOffset((newState.page - 1) * newState.sizePerPage)

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("")
      setSortOrder("")
    }
    else {
      setSortColumn(newState.sortField)
      setSortOrder(newState.sortOrder)
      // setReload(reload + 1)
    }

  }
  const searchButtonValidation = () => {
    if (selectedMdc || selectedType) {
      setSearch(true)
    } else {
      setSearch(false)
    }
  }
  // const clearSearchValues = () => {
  //   setSearchKeyword("");
  //   setReload(reload + 1)

  // }
  useEffect(
    () => {
      if(reload){

        fetchDRG()
        setReload(0)
      }
    },
    [reload] // eslint-disable-line react-hooks/exhaustive-deps
    ) 
    // useEffect(
    //   () => {
       
    //     fetchDRG()
    //   },
    //   []
    //   ) 

  // useEffect(() => { if (searchKeyword.length >= 2) { setIsGlobalSearch(true); } }, [searchKeyword]) // eslint-disable-line
  // useEffect(() => { if (searchKeyword.length === 0) { setReload(reload + 1) } }, [searchKeyword]) // eslint-disable-line
  useEffect(() => { if (isGlobalSearch) { setOffset(0); setTotalSize(0); fetchDRG(); setIsGlobalSearch(false); } }, [isGlobalSearch]) // eslint-disable-line
// eslint-disable-line
  // useEffect(() => { if (mdcCodeList) setMdcDropDown(mdcCodeList); }, [mdcCodeList])
  // useEffect(() => {fetchDRG();fetchMdc();}, []) // eslint-disable-line
  useEffect(() => { searchButtonValidation(); }, [selectedMdc, selectedType])// eslint-disable-line

  // useEffect(() => {
  //   var previousOffset = localStorage.getItem('offset')
  //   if (offset !== previousOffset || sizePerPage) {
  //     setReload(reload + 1)
  //   }
  // }, [offset, sizePerPage]) // eslint-disable-line
  // CheckUserInactivity()

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Groups


            <Button className="Add-btn pull-right"
              onClick={() => {
                setNewDrgModal(!newdrgModal)

              }}
            >
              Add New Group
            </Button>
          </HeaderTitle>

          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Groups</BreadcrumbItem>
          </Breadcrumb>

        </Header>
      </Container>



      <Card>
        <CardHeader>


          <div className="separator" />
        </CardHeader>
        {/* <div className='groups-search'>
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={tableColumns}
            clearValues={clearSearchValues}
          />

        </div> */}

        {spinner ? <Spinner /> : ""}

        <RemotePaginationAuth
          data={drgDetails ? drgDetails : ""}
          columns={tableColumns}
          page={page}
          sizePerPage={sizePerPage}
          FileExportName={fileExportName}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            handleTableChange(type, newState);
          }}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}



        />




      </Card>
      <EditAuthGroupModal
        editAuthGroupModal={editAuthGroupModal}
        setEditAuthGroupModal={setEditAuthGroupModal}
        serviceGroupId={serviceGroupId}
        setServiceGroupId={setServiceGroupId}
        groupId={groupId}
        setGroupId={setGroupId}
        serviceId={serviceId}
        setServiceId={setServiceId}
        groupName={groupName}
        setGroupName={setGroupName}
        serviceName={serviceName}
        setServiceName={setServiceName}
        groupStatus={groupStatus}
        setGroupStatus={setGroupStatus}
        setGroupEmail={setGroupEmail}
        groupEmail={groupEmail}
        serviceDict={serviceDict}
        setServiceDict={setServiceDict}
        services = {services}
        setServices = {setServices}
        fetchDRG = {fetchDRG}
        initialGroupNameLength = {initialGroupNameLength}
        initialServiceNameLength = {initialServiceNameLength}
        setInitialServiceNameLength = {setInitialServiceNameLength}
        setInitialGroupNameLength = {setInitialGroupNameLength}
        reload = {reload}
        setReload = {setReload}

      />
      <AuthGroupStatusChangeModal
        setModalState={setModalState}
        groupStatus={groupStatus}
        setGroupStatus={setGroupStatus}
        serviceId={serviceId}
        modalState={modalState}
        setServiceId={setServiceId}
        serviceName={serviceName}
        groupName={groupName}
        setGroupName={setGroupName}
        groupEmail={groupEmail}
        groupId={groupId}
        reload={reload}
        setReload={setReload}

      />
      <NewMicroServiceGroup
        newdrgModal={newdrgModal}
        setNewDrgModal={setNewDrgModal}
        //mdcOptions={mdcOptions}
        drgTypeOptions={drgTypeOptions}
        reload={reload}
        setReload={setReload}
      />
      <DeleteConfirmationModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        deleteFunction={deleteDrgDetails}


      />



    </div>
  );

}
export default MicroServiceGroups;