import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap'; // eslint-disable-line
import BootstrapTable from 'react-bootstrap-table-next';
import PatientStatusChangeModal from '../../newComponents/utilities/PatientStatusChangeModal'
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';

const FacilityStatusModal = (props) => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");
    const { facilityAccessModal, setFacilityAccessModal, patientId, groupId,fName } = props
    const [facility, setFacility] = useState([])
    const [statusModal, setStatusModal] = useState(false);
    const [rowId, setRowId] = useState()
    const [status, setStatus] = useState()

    const toggle = () => {
        setFacilityAccessModal(!facilityAccessModal);
    }

    const updatePatientStatus = (rowId, newStatus) => {
        const currentPatientList = facility;
        const updateIndex = currentPatientList.findIndex((item) => item.id === rowId)
        currentPatientList[updateIndex].status = newStatus
        setFacility(currentPatientList)
    };

    const facilityData = () => {
        const params = {
            patient_id: patientId,
            group_id: groupId
        }
        AxiosInter.post(`${API_ENDPOINT}/admin-api/list-patients-group-facility/`, params, {
            headers: {
                Authorization: API_TOKEN
            }
        }).then((response) => {
            setFacility(response.data.data)
        })
    }

    useEffect(() => {
        if (facilityAccessModal) {
            facilityData()
        }
    }, [facilityAccessModal]) // eslint-disable-line

    useEffect(() => {
    }, [facility])

    const statusFormatter = (cell, row) => {
        let status = ""
        let badgeclass = ""
        if (cell === 1) {
            status = "Active";
            badgeclass = "badge badge-success statustoggle status-button-width";
        } else {
            status = "Inactive";
            badgeclass = "badge badge-danger statustoggle status-button-width";
        }
        return (
            <span
                className={badgeclass}
                onClick={() => {
                    setStatusModal(true);
                    setRowId(row.id);
                    setStatus(cell);
                }}
            >
                {status}
            </span>
        )
    }

    const columns = [

        {
            dataField: 'name',
            text: 'Facility',
            headerStyle: { width: '150px' },
            style: { width: '150px' },
        },
        {
            dataField: 'status',
            text: 'Status',
            formatter: statusFormatter,
            headerStyle: { width: '100px' },
            style: { width: '100px' },
        }
    ];

    return (
        <div>
            <Modal isOpen={facilityAccessModal} size="md" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center', 'height': '50%' }}>Facilities of {fName}</ModalHeader>
                <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <BootstrapTable
                        keyField='id'
                        data={facility}
                        columns={columns}
                        noDataIndication="No records found"
                    />
                </ModalBody>
                <ModalFooter className="advanced-search-button">
                    <Button outline className="no-button button-width" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
            <PatientStatusChangeModal
                statusModal={statusModal}
                setStatusModal={setStatusModal}
                status={status}
                setStatus={setStatus}
                rowId={rowId}
                // setApiLoaded={setApiLoaded}
                // setSpinner={setSpinner}
                updatePatientStatus={updatePatientStatus}
                // searchPatients={searchPatients}
            />
        </div>
    )
}

export default FacilityStatusModal