/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Card,
  CardBody,
  UncontrolledPopover,
  PopoverBody,
  Col,
  Label,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  CardHeader,
} from "reactstrap";
// eslint-disable-next-line no-unused-vars
import { Link, useNavigate } from "react-router-dom";
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import AxiosInter from './../../AxiosInter.js';
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import moment from "moment";
import GroupDetailsEditModal from "../../newComponents/utilities/GroupDetailsEditModal";
import GroupPreferenceModal from "../../newComponents/utilities/GroupPreferenceModal";
import GroupStatusChangeModal from "../../newComponents/utilities/GroupStatusChangeModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faMapMarkerAlt,
  faShieldAlt,
  faCheck,
  faTimes,
  faUsers,
  faWallet,
  faHiking,
  faCommentMedical,
  faBriefcaseMedical,
  faStickyNote,
  faHeadSideCough,
  faDiagnoses,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { GlobalState } from "../../contextAPI/Context";
import AddNewGroupModal from "../../newComponents/utilities/AddNewGroupModal";
import { Select as SelectVirtualized } from "react-select-virtualized";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import { toastr } from "react-redux-toastr";
import MomentConfig from "../../common/MomentConfig";
import AsyncSelect from "react-select/async";
import Tooltip from "@mui/material/Tooltip";
import Cookie from 'js-cookie';

const blankState = (
  <Card className="blank-state">
    <CardBody>
      <h3>No Records Available</h3>
    </CardBody>
  </Card>
)

const Groups = () => {
  const {
    m_zipList,
    zipListLoaded,
    groupNamesForDropDown,
    subscriptionNamesList,
    timeZoneList,
    zipCodeOptions,
    RegionNames,
    getAllSubscriptionPlans,
    getTimeZoneList,
  } = useContext(GlobalState);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");

  const fileExportName = "Groups";
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`;

  const [blankStateStatus, setBlankStateStatus] = useState(false)
  const [spinner, setSpinner] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [groupStatus, setGroupStatus] = useState("");
  const [selectedGroupDetails, setSelectedGroupDetails] = useState("");

  let selectedGroupObject = selectedGroupDetails;

  const [groupDetailsList, setGroupDetailsList] = useState([]);
  const [groupEditModal, setGroupEditModal] = useState(false);
  const [groupPreferenceModal, setGroupPreferenceModal] = useState(false);
  const [groupAddModal, setGroupAddModal] = useState(false);
  const [editGroupName, setEditGroupName] = useState("");
  const [editNpi, setEditNpi] = useState("");
  const [editAddress1, setEditAddress1] = useState("");
  const [editAddress2, setEditAddress2] = useState("");
  const [editZipCode, setEditZipCode] = useState("");
  const [editPhoneNumber, setEditPhoneNumber] = useState("");
  const [editExpiryDate, setEditExpiryDate] = useState("");
  const [editReferralFax, setEditReferralFax] = useState("");
  const [editSubscriptionName, setEditSubscriptionName] = useState("");
  const [editAdminName, setEditAdminName] = useState("");
  const [editSupervisorName, setEditSupervisorName] = useState("");
  const [editTimeZone, setEditTimeZone] = useState("");
  const [editRegion, setEditRegion] = useState("");
  const [editInboundFax, setEditInboundFax] = useState("");
  const [groupId, setGroupId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [selectedZipCode, setSelectedZipCode] = useState("");
  const [search, setSearch] = useState(false);
  const [isPrimary, setIsPrimary] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [minDate, setMinDate] = useState("");
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchRecordCount, setSearchRecordCount] = useState("");
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [facilityDetails, setFacilityDetails] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedFacilitiesForAdd, setSelectedFacilitiesForAdd] = useState([]);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [count, setCount] = useState(0);
  const [reload, setReload] = useState(0);
  const [isFilterSaved, setIsFilterSaved] = useState(false);
  const [adminListForGroup, setAdminListForGroup] = useState([]);
  const [regionListForGroup, setRegionListForGroup] = useState([]);
  const [isWhiteLabelingEnabled, setIsWhiteLabelingEnabled] = useState(false);
  const [pointOfContactName, setPointOfContactName] = useState("")
  const [pointOfContactPhone, setPointOfContactPhone] = useState("")
  const [pointOfContactEmail, setPointOfContactEmail] = useState("")
  const [selectedStatusPoint, setSelectedStatusPoint] = useState("");
  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState("");
// eslint-disable-next-line no-unused-vars
  const isPayingClient = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];
  // eslint-disable-next-line no-unused-vars
  const selectInvoiceDates = [
    { value: '30-60 days', label: "30-60 days" },
    { value: '60-90 days', label: "60-90 days" },
    { value: '> 90 days', label: "> 90 days" }
  ]
  const tableName = "Groups";

  let navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  let groupDetailsDataCheck = false;

  const [facilities, setFacilities] = useState();

  var facilityIds = [];
  const [facilitiesForSaving, setFacilitiesForSaving] = useState();

  var facilityIdsFromAdd = [];

  const statusOptions = [
    { value: "1", label: "Active" },
    { value: "0", label: "Inactive" },
  ];

  const getFacilityIds = () => {
    if (selectedFacilities) {
      selectedFacilities.map((item) => {
        facilityIds.push(item.value);
        return setFacilities(facilityIds);
      });
    } else {
      facilityIds = [];
      setFacilities(facilityIds);
    }
  };

  const getFacilityIdsForSaving = () => {
    if (selectedFacilitiesForAdd) {
      // eslint-disable-next-line array-callback-return
      selectedFacilitiesForAdd.map((item) => {
        facilityIdsFromAdd.push(item.value);
        setFacilitiesForSaving(facilityIdsFromAdd);
      });
    } else {
      setFacilitiesForSaving(facilityIdsFromAdd);
    }
  };

  const preserveFilters = () => {
    if (selectedSubscription) {
      localStorage.setItem(
        "subscriptionFilter",
        JSON.stringify(selectedSubscription)
      );
    } else if (
      !selectedSubscription &&
      localStorage.getItem("subscriptionFilter") !== null
    ) {
      localStorage.removeItem("subscriptionFilter");
    }

    if (selectedGroupDetails) {
      localStorage.setItem(
        "groupNameFilter",
        JSON.stringify(selectedGroupDetails)
      );
    } else if (
      !selectedGroupDetails &&
      localStorage.getItem("groupNameFilter") !== null
    ) {
      localStorage.removeItem("groupNameFilter");
    }

    if (selectedStatus) {
      localStorage.setItem("statusFilter", JSON.stringify(selectedStatus));
    } else if (
      !selectedStatus &&
      localStorage.getItem("statusFilter") !== null
    ) {
      localStorage.removeItem("statusFilter");
    }
    if (selectedAdmin) {
      localStorage.setItem("adminNameFilter", JSON.stringify(selectedAdmin));
    } else if (
      !selectedAdmin &&
      localStorage.getItem("adminNameFilter") !== null
    ) {
      localStorage.removeItem("adminNameFilter");
    }
    if (selectedZipCode) {
      localStorage.setItem("zipCodeFilter", JSON.stringify(selectedZipCode));
    } else if (
      !selectedZipCode &&
      localStorage.getItem("zipCodeFilter") !== null
    ) {
      localStorage.removeItem("zipCodeFilter");
    }
    if (selectedTimeZone) {
      localStorage.setItem("timeZoneFilter", JSON.stringify(selectedTimeZone));
    } else if (
      !selectedTimeZone &&
      localStorage.getItem("timeZoneFilter") !== null
    ) {
      localStorage.removeItem("timeZoneFilter");
    }
  };
  const minLength = 4;
  const customNoOptionsMessage = ({ inputValue }) => {
    if (inputValue.length < minLength) {
      return "Please enter at least 4 letters to search";
    } else {
      return "No option";
    }
  };

  const removePreservedFilters = () => {
    if (localStorage.getItem("subscriptionFilter") !== null) {
      localStorage.removeItem("subscriptionFilter");
    }

    if (localStorage.getItem("groupNameFilter") !== null) {
      localStorage.removeItem("groupNameFilter");
    }

    if (localStorage.getItem("statusFilter") !== null) {
      localStorage.removeItem("statusFilter");
    }

    if (localStorage.getItem("adminNameFilter") !== null) {
      localStorage.removeItem("adminNameFilter");
    }

    if (localStorage.getItem("zipCodeFilter") !== null) {
      localStorage.removeItem("zipCodeFilter");
    }

    if (localStorage.getItem("timeZoneFilter") !== null) {
      localStorage.removeItem("timeZoneFilter");
    }
    if (localStorage.getItem("isFilterApplied") !== null) {
      localStorage.removeItem("isFilterApplied");
    }

    localStorage.removeItem("isFilterApplied");
  };

  const getPreservedFilters = () => {
    if (localStorage.getItem("subscriptionFilter")) {
      let subscriptionFilterObject = JSON.parse(
        localStorage.getItem("subscriptionFilter")
      );
      // eslint-disable-next-line array-callback-return
      subscriptionNamesList.map((item) => {
        // eslint-disable-line

        if (item.label === subscriptionFilterObject.label) {
          setSelectedSubscription(item);
        }
      });
    }

    if (localStorage.getItem("groupNameFilter")) {
      let groupFilterObject = JSON.parse(
        localStorage.getItem("groupNameFilter")
      );
      // eslint-disable-next-line array-callback-return
      groupNamesForDropDown.map((item) => {
        // eslint-disable-line

        if (item.label === groupFilterObject.label) {
          setSelectedGroupDetails(item);
        }
      });
      // localStorage.removeItem('groupNameFilter');
    }
    if (localStorage.getItem("statusFilter")) {
      let statusFilterObject = JSON.parse(localStorage.getItem("statusFilter"));
      // eslint-disable-next-line array-callback-return
      statusOptions.map((item) => {
        // eslint-disable-line

        if (item.label === statusFilterObject.label) {
          setSelectedStatus(item);
        }
      });
      // localStorage.removeItem('statusFilter');
    }
    /* if (localStorage.getItem('adminNameFilter')) {
       let adminFilterObject = JSON.parse(localStorage.getItem('adminNameFilter'))
       appUsersNames.map((item)=>{ // eslint-disable-line

         if(item.label === adminFilterObject.label) {
           setSelectedAdmin(item)
         }
       })
       // localStorage.removeItem('adminNameFilter');
    } */
    if (localStorage.getItem("zipCodeFilter")) {
      let zipCodeFilterObject = JSON.parse(
        localStorage.getItem("zipCodeFilter")
      );
      // eslint-disable-next-line array-callback-return
      zipCodeOptions.map((item) => {
        // eslint-disable-line

        if (item.label === zipCodeFilterObject.label) {
          setSelectedZipCode(item);
        }
      });
      // localStorage.removeItem('zipCodeFilter');
    }
    if (localStorage.getItem("timeZoneFilter")) {
      let timeZoneFilterObject = JSON.parse(
        localStorage.getItem("timeZoneFilter")
      );
      // eslint-disable-next-line array-callback-return
      timeZoneList.map((item) => {
        // eslint-disable-line

        if (item.label === timeZoneFilterObject.label) {
          setSelectedTimeZone(item);
        }
      });
      // localStorage.removeItem('timeZoneFilter');
    }
    //  setIsSearch(true);
    localStorage.removeItem("isFilterApplied");
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Container>
          <Row>
            <Button
              className="money-button"
              outline
              onClick={() => {
                setGroupEditModal(true);
                setEditGroupName(row.GroupName ? row.GroupName : "");
                setEditNpi(row.npi ? row.npi : "");
                setEditAddress1(row.address ? row.address : "");
                setEditAddress2(row.address2 ? row.address2 : "");
                setEditZipCode(row.ZipCode ? row.ZipCode : "");
                setEditPhoneNumber(row.phoneNumber ? row.phoneNumber : "");
                setEditSubscriptionName(
                  row.SubscriptionName ? row.SubscriptionName : ""
                );
                if (row.AdminName) {
                  setEditAdminName({ value: row.apusrid, label: row.AdminName });

                }
                setEditSupervisorName(
                  row.SuperVisorName ? row.SuperVisorName : ""
                );
                setEditTimeZone(row.TimeZone ? row.TimeZone : "");
                setMinDate(moment().toDate());
                setGroupId(row.id);
                setEditReferralFax(row.referralFax ? row.referralFax : "");
                setIsPrimary(row.IsPrimary ? row.IsPrimary : "");
                setEditExpiryDate(row.ExpireDate ? row.ExpireDate : "");
                setFacilityDetails(
                  row.facility_details ? row.facility_details : ""
                );
                setEditRegion(row.regionID ? row.regionID : "");
                setEditInboundFax(row.inboundFax ? row.inboundFax : "");
                setIsWhiteLabelingEnabled(row.isWhiteLabelingEnabled ? row.isWhiteLabelingEnabled : 0);
                setPointOfContactName(row.PointOfContactName ? row.PointOfContactName : "")
                setPointOfContactPhone(row.PointOfContactPhone ? row.PointOfContactPhone : "")
                setPointOfContactEmail(row.PointOfContactEmail ? row.PointOfContactEmail : "")
                // setSelectedStatusPoint(row.PayingClient ? row.PayingClient : "")
                if (row.PayingClient === 1) {
                  setSelectedStatusPoint({ value: 1, label: "Yes" })
                }
                else if (row.PayingClient === 0) {
                  setSelectedStatusPoint({ value: 0, label: "No" })
                }
                else {
                  setSelectedStatusPoint("")
                }
                if (row.InvoicePending === "30-60 days") {
                  console.log("1st")
                  setSelectedInvoiceDate({ value: "30-60 days", label: "30-60 days" })
                }
                else if (row.InvoicePending === "60-90 days") {
                  console.log("2st")
                  setSelectedInvoiceDate({ value: "60-90 days", label: "60-90 days" })
                }
                else if (row.InvoicePending === "> 90 days") {
                  console.log("3st")
                  setSelectedInvoiceDate({ value: "> 90 days", label: "> 90 days" })
                }
                else {
                  console.log("4st")
                  setSelectedInvoiceDate("")
                }
              }}
              color="primary"
              title="Edit Group"
            >
              <FontAwesomeIcon icon={faEdit} size={"sm"} />
            </Button>{" "}
            <UncontrolledDropdown title="Options">
              <DropdownToggle className="dropdown-options">
                <FontAwesomeIcon icon={faEllipsisV} size={"lg"} />
              </DropdownToggle>
              <DropdownMenu align="end" container="body">
                <DropdownItem
                  onClick={() => {
                    navigate(`/groups/security/${window.btoa(row.id)}`, { state: { detail: isFilterSaved } });
                    localStorage.setItem("groupName", row.GroupName);
                    // preserveFilters(row.id, '/groups/security/');
                  }}
                >
                  <FontAwesomeIcon icon={faShieldAlt} /> User Security
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate(`/groups/facility/${window.btoa(row.id)}`, { state: { detail: isFilterSaved } });
                    localStorage.setItem("groupName", row.GroupName);

                    // preserveFilters(row.id, '/groups/facility/');
                  }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} /> Group Facilities
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate(`/groups/habits/${window.btoa(row.id)}`, { state: { detail: isFilterSaved } });
                    localStorage.setItem("groupName", row.GroupName);

                    // preserveFilters(row.id, '/groups/facility/');
                  }}
                >
                  <FontAwesomeIcon icon={faHiking} /> Habits
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate(`/groups/ServiceCategory/${window.btoa(row.id)}`, {
                      state: { detail: isFilterSaved },
                    });
                    localStorage.setItem("groupName", row.GroupName);

                    // preserveFilters(row.id, '/groups/facility/');
                  }}
                >
                  <FontAwesomeIcon icon={faCommentMedical} /> Patient Service
                  Category
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate(`/groups/PatientDocumentType/${window.btoa(row.id)}`, { state: { detail: isFilterSaved } });
                    localStorage.setItem("groupName", row.GroupName);

                    // preserveFilters(row.id, '/groups/facility/');
                  }}
                >
                  <FontAwesomeIcon icon={faBriefcaseMedical} /> Patient Document
                  Type
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate(`/groups/PatientNotes/${window.btoa(row.id)}`, { state: { detail: isFilterSaved } });
                    localStorage.setItem("groupName", row.GroupName);

                    // preserveFilters(row.id, '/groups/facility/');
                  }}
                >
                  <FontAwesomeIcon icon={faStickyNote} /> Patient Notes
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate(`/groups/indications/${window.btoa(row.id)}`, { state: { detail: isFilterSaved } });
                    localStorage.setItem("groupName", row.GroupName);

                    // preserveFilters(row.id, '/groups/facility/');
                  }}
                >
                  <FontAwesomeIcon icon={faHeadSideCough} /> Patient Indications
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate(`/groups/evaluations/${window.btoa(row.id)}`, { state: { detail: isFilterSaved } });
                    localStorage.setItem("groupName", row.GroupName);

                    // preserveFilters(row.id, '/groups/facility/');
                  }}
                >
                  <FontAwesomeIcon icon={faDiagnoses} /> Patient Evaluations
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate(`/groups/Packages/${window.btoa(row.GroupName)}`, { state: { detail: isFilterSaved } });
                    localStorage.setItem("groupName", row.GroupName);

                    // preserveFilters(row.id, '/groups/facility/');
                  }}
                >
                  <FontAwesomeIcon icon={faWallet} /> Packages
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate(`/groups/Appusers/${window.btoa(row.id)}`, { state: { detail: isFilterSaved } });
                    localStorage.setItem("groupName", row.GroupName);
                    localStorage.setItem("groupId", row.id);
                    // window.location.reload()
                  }}
                >
                  <FontAwesomeIcon icon={faUsers} /> App Users
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setGroupPreferenceModal(true);
                    setEditGroupName(row.GroupName)
                    setGroupId(row.id)
                    setCount(1)
                    setEditInboundFax(row.inboundFax ? row.inboundFax : "");
                  }}
                >
                  <FontAwesomeIcon icon={faStar} /> Group Preference
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            {/* </Col> */}
          </Row>
        </Container>
      </>
    );
  };

  const statusFormatter = (cell, row) => {
    let status = "";
    let badgeclass = " ";
    if (cell === 1) {
      status = "Active";
      badgeclass = "badge badge-success statustoggle status-button-width";
    } else {
      status = "Inactive";
      badgeclass = "badge badge-danger statustoggle status-button-width";
    }

    return (
      <>
        <span
          className={badgeclass}
          onClick={() => {
            setGroupStatus(row.status);
            setGroupId(row.id);
            setStatusModal(true);
            setEditGroupName(row.GroupName);
          }}
        >
          {status}
        </span>
      </>
    );
  };
  const payingClientFormatter = (cell) => {
    if (cell === 1) {
      return cell = "Yes"
    }
    else {
      return cell = "No"
    }

  };

  const validation = (cell) => {
    return cell ? cell : "N/A";
  };
  const logoValidation = (cell) => {
    return cell ? "Yes" : "N/A";
  };
  const statusFormatterCSV = (cell) => {
    return cell ? "Active" : "Inactive";
  };
  const payingClientFormatterCSV = (cell) => {
    return cell ? "Yes" : "No";
  };

  const setImage = (row, cell) => {
    return cell && cell.Logo ? (
      <div>
        <FontAwesomeIcon
          id="UncontrolledPopover"
          icon={faCheck}
          color={"#28a745"}
          style={{ cursor: "pointer" }}
        />
        <UncontrolledPopover
          container="tbody"
          placement="right"
          trigger="legacy"
          target="UncontrolledPopover"
        >
          <PopoverBody>
            <img
              src={cell.logo_url}
              width={60 + "px"}
              height={50 + "px"}
              alt="logo"
            />
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    ) : (
      <FontAwesomeIcon
        icon={faTimes}
        color={"#dc3545"}
        title={"Logo not found"}
        style={{ cursor: "pointer" }}
      />
    );
  };

  const columns = [
    {
      dataField: "Logo",
      text: "Logo",
      formatter: setImage,
      csvFormatter: logoValidation,
      style: {
        textAlign: "center",
      },
    },
    {
      dataField: "GroupName",
      text: "Group Name",
      sort: true,
      formatter: validation,
      csvFormatter: validation,
    },
    {
      dataField: "SuperVisorName",
      text: "Supervisor Group",
      sort: false,
      formatter: validation,
      csvFormatter: validation,
    },
    {
      dataField: "npi",
      text: "NPI",
      sort: false,
      formatter: validation,
      csvFormatter: validation,
    },
    {
      dataField: "SubscriptionName",
      text: "Subscription Plan",
      sort: true,
      formatter: validation,
      csvFormatter: validation,
    },
    {
      dataField: "address",
      text: "Address",
      sort: true,
      formatter: validation,
      csvFormatter: validation,
    },
    {
      dataField: "AdminName",
      text: "Admin Name",
      sort: true,
      formatter: validation,
      csvFormatter: validation,
    },
    {
      dataField: "TimeZone",
      text: "Timezone",
      sort: true,
      formatter: validation,
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: statusFormatter,
      csvFormatter: statusFormatterCSV,
      align: "center",
    },
    {
      dataField: "PointOfContactName",
      text: "Point Of Contact Name",
      sort: false,
      formatter: validation,
      csvFormatter: validation
    },
    {
      dataField: "PointOfContactPhone",
      text: "Point Of Contact Phone",
      sort: false,
      formatter: validation,
      csvFormatter: validation
    },
    {
      dataField: "PointOfContactEmail",
      text: "Point Of Contact Email",
      sort: false,
      formatter: validation,
      csvFormatter: validation
    },
    {
      dataField: "PayingClient",
      text: "Paying Client",
      sort: false,
      formatter: payingClientFormatter,
      csvFormatter: payingClientFormatterCSV,
      align: "center",
    },
    {
      text: "Actions",
      sort: false,
      csvExport: false,
      formatter: actionsFormatter,
      align: "center",
    },
  ];

  const fetchGroupDetails = () => {
    setSpinner(true);
    const params = {
      group_id: selectedGroupObject ? selectedGroupObject.value : "",
      zip_id: selectedZipCode ? selectedZipCode.value : "",
      admin_id: selectedAdmin ? selectedAdmin.value : "",
      group_status: selectedStatus ? selectedStatus.value : "",
      subscription_id: selectedSubscription ? selectedSubscription.value : "",
      timezone_id: selectedTimeZone ? selectedTimeZone.value : "",
      offset: offset,
      is_pagination: 1,
      record_limit: sizePerPage,
      search_keyword: searchKeyword ? searchKeyword : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-group-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
              // eslint-disable-next-line eqeqeq
      if (response != undefined) {
          setTotalSize(response.data.record_count);
          setGroupDetailsList(response.data.data);
          setSearchRecordCount(response.data.search_record_count);
          setSpinner(false);
          localStorage.setItem("offset", offset);
        } else {
          setTotalSize(0);
          setGroupDetailsList("");
          if (isSearch) {
            groupDetailsDataCheck = false;
          }
          setIsDataEmpty(true);
          setSpinner(false);
        }
      })
    // .catch((error) => {
    //   if (error.response.status === 401) {
    //     new_token= Cookie.get("access_token_admin")  
    //     AxiosInter.get(`${API_ENDPOINT}/admin-api/get-group-details/`, {
    //               headers: {
    //                 Authorization: new_token,
    //               },
    //             })
    //         }else{
    //   setGroupDetailsList("")}
    // });
  };

  const exportTableData = () => {
    const params = {
      file_name: fileName ? fileName : "",
      group_id: selectedGroupObject ? selectedGroupObject.value : "",
      zip_id: selectedZipCode ? selectedZipCode.value : "",
      admin_id: selectedAdmin ? selectedAdmin.value : "",
      group_status: selectedStatus ? selectedStatus.value : "",
      subscription_id: selectedSubscription ? selectedSubscription.value : "",
      timezone_id: selectedTimeZone ? selectedTimeZone.value : "",
      offset: offset,
      is_pagination: "1",
      record_limit: sizePerPage,
      search_keyword: searchKeyword ? searchKeyword : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/export-groups-table-data/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const getAdminListForGroup = () => {
    setSpinner(true);
    const params = {
      group_id: groupId ? Number(groupId) : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-admin-users-for-group/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        setAdminListForGroup(response.data.data);
        setSpinner(false);
      })
      .catch((error) => {
        toastr.error("Error", "Something went wrong!");
      });
  };

  const setInitialFacilityNames = () => {
    var array = [];
    // eslint-disable-next-line array-callback-return
    facilityDetails.map((item) => {
      // eslint-disable-line
      if (item.facility_name && item.facility_id) {
        array.push({ value: item.facility_id, label: item.facility_name });
      }
    });
    setSelectedFacilities(array);
  };

  const loadFacilityOptionsForFacility = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        })
    }
  };

  const reactMultiSelectForNewGroup = () => {
    return (
      <>
        <Label for="facilityAdd">Facilities</Label>

        <AsyncSelect
          isMulti
          cacheOptions
          isSearchable
          placeholder="Type facility name for options"
          noOptionsMessage={customNoOptionsMessage}
          value={selectedFacilitiesForAdd}
          loadOptions={loadFacilityOptionsForFacility}
          getOptionLabel={(e) => e.label}
          getOptionValue={(e) => e.value}
          onChange={(e) => {
            setSelectedFacilitiesForAdd(e);
          }}
        />
      </>
    );
  };

  const clearValues = () => {
    setSelectedGroupDetails("");
    setSelectedAdmin("");
    setSelectedStatus("");
    setSelectedSubscription("");
    setSelectedTimeZone("");
    setSelectedZipCode("");
    setReload(reload + 1);
  };

  const searchButtonValidation = () => {
    if (
      selectedGroupDetails ||
      selectedAdmin ||
      selectedStatus ||
      selectedSubscription ||
      selectedTimeZone ||
      selectedZipCode
    ) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  const handleTableChange = (type, newState) => {
    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);
    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("");
      setSortOrder("");
    } else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setReload(reload + 1);
    }
  };

  const loadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      // const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-app-user-name/${inputValue.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  useEffect(() => {
    if (groupDetailsList.length === 0) {
      setBlankStateStatus(true)
    } else {
      setBlankStateStatus(false)
    }
  }, [groupDetailsList]);

  useEffect(() => {
    let isFilterSavedFromStorage = JSON.parse(
      localStorage.getItem("isFilterApplied")
    );
    if (isFilterSavedFromStorage) {
      getPreservedFilters();
    }
  }, []); // eslint-disable-line

  // useEffect(()=>{
  //   removePreservedFilters();

  // },[]) // eslint-disable-line

  useEffect(() => {
    if (timeZoneList.length <= 0) {
      getTimeZoneList();
    }
  }, [timeZoneList]); // eslint-disable-line

  useEffect(() => {
    if (groupEditModal) {
      getFacilityIds();
    }
  }, [selectedFacilities, groupEditModal]); // eslint-disable-line

  useEffect(() => {
    if (subscriptionNamesList.length <= 0) {
      getAllSubscriptionPlans();
    }
  }, [subscriptionNamesList]); // eslint-disable-line

  useEffect(() => {
    setIsFilterSaved(true);
  }, [
    selectedSubscription,
    selectedSubscription,
    selectedStatus,
    selectedAdmin,
    selectedZipCode,
    selectedTimeZone,
  ]); // eslint-disable-line

  useEffect(() => {
    if (selectedFacilitiesForAdd.length > 0) {
      getFacilityIdsForSaving();
    }
  }, [selectedFacilitiesForAdd]); // eslint-disable-line

  useEffect(() => {
    if (groupId) {
      getAdminListForGroup();
    }
  }, [groupId]); // eslint-disable-line

  useEffect(() => {
    if (facilityDetails && groupEditModal) {
      setInitialFacilityNames();
    }
  }, [facilityDetails, groupEditModal]); // eslint-disable-line

  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      setTotalSize(0);
      fetchGroupDetails();
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length === 0) {
      setReload(reload + 1);
    }
  }, [searchKeyword]); // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset) {
      setReload(reload + 1);
    }
  }, [offset, sizePerPage]); // eslint-disable-line

  useEffect(() => {
    if (reload) {
      fetchGroupDetails();
      setReload(0);
    }
  }, [reload]); // eslint-disable-line

  useEffect(() => {
    searchButtonValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedGroupDetails,
    selectedAdmin,
    selectedStatus,
    selectedSubscription,
    selectedTimeZone,
    selectedZipCode,
    selectedStatus,
  ]); // eslint-disable-line

  const clearSearchValues = () => {
    setSearchKeyword("");
    setReload(reload + 1);
  };

  return (
    <Hux>
      <Container fluid>
        <Header>
          <HeaderTitle>
            Groups
            <Button
              className="Add-btn pull-right"
              onClick={() => {
                setGroupAddModal(true);
              }}
              disabled={m_zipList ? false : true}
            >
              Add New Group
            </Button>
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Groups</BreadcrumbItem>
          </Breadcrumb>
        </Header>
        <br />
        <Card>
          <CardHeader>
            <Row>
              <Col sm="2" md="3" lg="3">
                <Tooltip
                  title={selectedGroupDetails ? selectedGroupDetails.label : ""}
                  placement="top"
                  arrow
                >
                  <div>
                    <Label for="Group">Group Name</Label>
                    <SelectVirtualized
                      name="Group"
                      options={groupNamesForDropDown}
                      placeholder="Enter group name"
                      isSearchable
                      isClearable
                      value={selectedGroupDetails}
                      onChange={(e) => {
                        setSelectedGroupDetails(e);
                      }}
                    />
                  </div>
                </Tooltip>
              </Col>
              <Col sm="2" md="3" lg="2">
                <Label for="Status">Status</Label>
                <SelectVirtualized
                  name="Status"
                  options={statusOptions}
                  placeholder="Choose status"
                  isClearable
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e);
                  }}
                />
              </Col>
              <Col sm="2" md="3" lg="3">
                <Label for="Subscription Plan">Subscription Plan</Label>
                <SelectVirtualized
                  name="Subscription Plan"
                  options={subscriptionNamesList}
                  placeholder="Choose Subscription Plan"
                  isClearable
                  value={selectedSubscription}
                  onChange={(e) => {
                    setSelectedSubscription(e);
                  }}
                />
              </Col>
              <Col sm="2" md="3" lg="4">
                <Label for="Admin Name">Admin Name</Label>

                <AsyncSelect
                  cacheOptions
                  isClearable
                  value={selectedAdmin}
                  getOptionLabel={(e) => e.label}
                  getOptionValue={(e) => e.value}
                  loadOptions={loadOptions}
                  onChange={(e) => {
                    setSelectedAdmin(e);
                  }}
                  placeholder="Search with at least 4 characters"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="4" md="3">
                <Label for="zip">Zip Code</Label>
                <SelectVirtualized
                  name="zip"
                  options={zipCodeOptions}
                  value={selectedZipCode}
                  placeholder="Choose zip code"
                  isSearchable
                  isClearable
                  onChange={(e) => {
                    setSelectedZipCode(e);
                  }}
                />
              </Col>

              <Col sm="4" md="3">
                <Label for="TimeZone">Time Zone</Label>
                <SelectVirtualized
                  name="TimeZone"
                  options={timeZoneList}
                  placeholder="Choose TimeZone"
                  isClearable
                  value={selectedTimeZone}
                  onChange={(e) => {
                    setSelectedTimeZone(e);
                  }}
                />
              </Col>
              <Col>
                <Button
                  className="patient-go-buttons"
                  onClick={() => {
                    offset === 0 ? setReload(reload + 1) : setOffset(0);
                    setIsSearch(true);
                    setPage(1);
                    preserveFilters();
                  }}
                  title={spinner || !search ? "Choose at least one item to search " : ""}
                >
                  Go
                </Button>{" "}
                <Button
                  className="patient-reset-buttons"
                  onClick={() => {
                    clearValues();
                    removePreservedFilters();
                  }}
                  disabled={spinner || !search ? true : false}
                  title={spinner || !search ? "Nothing To Clear" : ""}
                >
                  Reset
                </Button>
              </Col>
            </Row>
            <div className="separator" />
          </CardHeader>
          <div className="groups-search">
            <SearchBarComponent
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              totalSize={totalSize}
              columns={columns}
              clearValues={clearSearchValues}
            />
          </div>
          {zipListLoaded || spinner ? <Spinner /> : ""}
          {blankStateStatus ? blankState : ""}
          {!blankStateStatus ?
            <RemotePagination
              data={groupDetailsList ? groupDetailsList : ""}
              setGroupDetailsList={setGroupDetailsList}
              columns={columns}
              page={page}
              sizePerPage={sizePerPage}
              tableName={tableName}
              FileExportName={fileExportName}
              totalSize={totalSize ? totalSize : searchRecordCount}
              dataState={isDataEmpty}
              setPage={setPage}
              setOffset={setOffset}
              setTotalSize={setTotalSize}
              isGlobalSearch={isGlobalSearch}
              setIsGlobalSearch={setIsGlobalSearch}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              setSizePerPage={setSizePerPage}
              exportTableData={exportTableData}
              onTableChange={(type, newState) => {
                handleTableChange(type, newState);
              }}
            />
            : ""}
        </Card>

        <GroupStatusChangeModal
          statusModal={statusModal}
          setStatusModal={setStatusModal}
          groupId={groupId}
          groupStatus={groupStatus}
          setGroupStatus={setGroupStatus}
          groupName={editGroupName}
          setReload={setReload}
          reload={reload}
        />
        <GroupPreferenceModal
          setGroupEditModal={setGroupPreferenceModal}
          groupEditModal={groupPreferenceModal}
          groupId={groupId}
          setReload={setReload}
          reload={reload}
          editGroupName={editGroupName}
          setGroupId={setGroupId}
          setCount={setCount}
          count={count}
          editInboundFax={editInboundFax}
          setEditInboundFax={setEditInboundFax}
        />
        <GroupDetailsEditModal
          groupEditModal={groupEditModal}
          setGroupEditModal={setGroupEditModal}
          groupDetailsList={groupDetailsList}
          editGroupName={editGroupName}
          npi={editNpi}
          editRegion={editRegion}
          setEditRegion={setEditRegion}
          editAddress1={editAddress1}
          editAddress2={editAddress2}
          editZipCode={editZipCode}
          setEditZipCode={setEditZipCode}
          setEditGroupName={setEditGroupName}
          setEditNpi={setEditNpi}
          editPhoneNumber={editPhoneNumber}
          editExpiryDate={editExpiryDate}
          editSubscriptionName={editSubscriptionName}
          editAdminName={editAdminName}
          setEditAdminName={setEditAdminName}
          setEditPhoneNumber={setEditPhoneNumber}
          setEditAddress1={setEditAddress1}
          setEditAddress2={setEditAddress2}
          setEditExpiryDate={setEditExpiryDate}
          setEditSubscriptionName={setEditSubscriptionName}
          groupId={groupId}
          subscriptionNames={subscriptionNamesList}
          editTimeZone={editTimeZone}
          editSupervisorName={editSupervisorName}
          timeZoneList={timeZoneList}
          groupNameList={groupNamesForDropDown}
          editReferralFax={editReferralFax}
          zipCodeList={zipCodeOptions}
          setEditReferralFax={setEditReferralFax}
          setSelectedFacilities={setSelectedFacilities}
          facilities={facilities}
          isPrimary={isPrimary}
          minDate={minDate}
          selectedFacilities={selectedFacilities}
          setFacilityDetails={setFacilityDetails}
          setAdminListForGroup={setAdminListForGroup}
          setRegionListForGroup={setRegionListForGroup}
          regionListForGroup={regionListForGroup}
          adminListForGroup={adminListForGroup}
          setReload={setReload}
          reload={reload}
          facilityIds={facilityIds}
          setFacilities={setFacilities}
          isWhiteLabelingEnabled={isWhiteLabelingEnabled}
          setIsWhiteLabelingEnabled={setIsWhiteLabelingEnabled}
          setPointOfContactName={setPointOfContactName}
          pointOfContactName={pointOfContactName}
          setPointOfContactPhone={setPointOfContactPhone}
          pointOfContactPhone={pointOfContactPhone}
          setPointOfContactEmail={setPointOfContactEmail}
          pointOfContactEmail={pointOfContactEmail}
          setSelectedStatusPoint={setSelectedStatusPoint}
          selectedStatusPoint={selectedStatusPoint}
          setSelectedInvoiceDate={setSelectedInvoiceDate}
          selectedInvoiceDate={selectedInvoiceDate}
        />
        <AddNewGroupModal
          groupAddModal={groupAddModal}
          setGroupAddModal={setGroupAddModal}
          subscriptionNames={subscriptionNamesList}
          groupNameList={groupNamesForDropDown}
          timeZoneList={timeZoneList}
          zipCodeList={zipCodeOptions}
          selectedFacilities={selectedFacilities}
          reactMultiSelectForNewGroup={reactMultiSelectForNewGroup}
          setFacilityDetails={setFacilityDetails}
          setSelectedFacilities={setSelectedFacilities}
          setSelectedFacilitiesForAdd={setSelectedFacilitiesForAdd}
          facilities={facilities}
          facilitiesForSaving={facilitiesForSaving}
          setReload={setReload}
          reload={reload}
          RegionNames={RegionNames}
          isWhiteLabelingEnabled={isWhiteLabelingEnabled}
          setIsWhiteLabelingEnabled={setIsWhiteLabelingEnabled}
        />
      </Container>
    </Hux>
  );
};

export default Groups;
