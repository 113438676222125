import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import axios from "axios";
import { toastr } from 'react-redux-toastr';
import styles from "./ProviderDetails.module.css";
import Select from 'react-select';

const AddMicroServiceUser = (props) => {
    const {
        newUserModal,
        setNewUserModal,
        reload,
        setReload,
        groupNameOptions

    } = props


    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT_MICROSERVICE;
    const API_TOKEN = localStorage.getItem("accessToken");


    const [addButtonStatus, setAddButtonStatus] = useState(true);
    const [newName, setNewName] = useState("");
    const [groupsList, setGroupsList] = useState([]);
    const [newEmail, setNewEmail] = useState("");
    const regXForSpecialCharacters = /^ *([a-zA-Z0-9][a-zA-Z0-9 ]*)?$/;


    const toggle = () => {
        setNewUserModal(!newUserModal);
        setNewName("")
        setGroupsList("")
        setNewEmail("")

    }
    
    // CREATE NEW USER
    const CreateUserApiCall = () => {
        let groupsArray = []
        if (groupsList) {
            groupsList.map((item) => {   // eslint-disable-line
                groupsArray.push(item.value);
            });
        }
        const params = {
            name: newName,
            email: newEmail ? newEmail : "",
            groups: groupsList ? groupsArray : "",

        }

        axios.post(`${API_ENDPOINT}api/user/`, params, {
            headers: {
                Authorization: API_TOKEN,
            }
        })
            .then(response => {

                if (response) {
                    toggle();
                    toastr.success("Success", "New user created Successfully");
                    setReload(reload + 1);
                }
            })
            .catch(error => {
                toastr.error("Failed", error.response.data.email[0])

            });
    }


    const disableAddButton = () => {

        if (newName) {
            setAddButtonStatus(false)
        }
        else {
            setAddButtonStatus(true)
        }
    }
    const confirmation = () => {
        if (!newName) {
          toastr.error("Name Field Is Required", "Please enter the user name!");
        }
    
        if (!newEmail) {
          toastr.error("Email Field Is Required", "Please enter the email!");
        }
        if (groupsList.length===0) {
          toastr.error("Group Field Is Required", "Please select at least 1 group!");
        }
    
        if (newEmail && groupsList.length!==0) {
            CreateUserApiCall();
        }
      };

    const changeOnName = (e) => {
        if (regXForSpecialCharacters.test(e) === false) {
            toastr.warning("Invalid Attempt", "special characters are not allowed")
        } else {
            setNewName(e)
        }
    }

    useEffect(() => { disableAddButton() }, [newName]) // eslint-disable-line

    return (
        <div>
            <Modal isOpen={newUserModal} size="md" scrollable={true} >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Add New User </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row  >
                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        User Name  <span className="required">*</span> :

                                    </Label>
                                </div>

                                <Input
                                    id="drgCode"
                                    value={newName}
                                    placeholder="Enter the Name"
                                    onChange={(e) => { if (e.target.value.length <= 150) changeOnName(e.target.value) }}
                                />
                            </Col>

                            <Col sm={6} >
                                <div className="" >
                                    <Label>

                                        Email <span className="required">*</span> :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Input
                                        id="Description"
                                        value={newEmail}
                                        placeholder="Enter Email"
                                        onChange={(e) => { if (e.target.value.length <= 150) setNewEmail(e.target.value) }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br></br>
                        <Row className={styles.detailProfRow} >
                            <Col sm={12} >
                                <div className="" >
                                    <Label>

                                        Groups  <span className="required">*</span> :

                                    </Label>
                                </div>

                                <div className="" >
                                    <Select
                                        isMulti
                                        options={groupNameOptions}
                                        onChange={(event) => setGroupsList(event)}
                                        value={groupsList}
                                        placeholder="Select Group"
                                        isSearchable
                                        isClearable
                                    />
                                </div>
                            </Col>

                        </Row>




                    </FormGroup>
                </ModalBody>
                <ModalFooter className="advanced-search-button  custom-modal-footer">
                    <Button
                        className="yes-button button-width"
                        onClick={confirmation}
                        disabled={addButtonStatus}
                        title={addButtonStatus ? "Fill the required fields" : ""}
                    >Add</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>

        </div>

    );
};

export default AddMicroServiceUser;