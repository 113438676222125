import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input, Label, FormGroup, } from 'reactstrap';
import Select from 'react-select-virtualized';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';



const ICDCodeDetailsEditModal = (props) => {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    const [categoryOptions, setCategoryOptions] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("")
    const [conditionsOptions, setConditionsOptions] = useState("");
    const [selectedCondition, setSelectedCondition] = useState("");
    const [isChronic, setIsChronic] = useState("");
    // const [drgCode, setDrgCode] = useState("");
    const [drgCodeOptions, setDrgCodeOptions] = useState("");

    const [editButtonBlankState, setEditButtonBlankState] = useState(false)
    
    const {
        setIcdDrg,
        icdDrg,
        modal,
        setModal,
        icdCode,
        icdId,
        setIcdId,
        icdDescription,
        icdCategory,
        icdCategoryList,
        setIcdDescription,
        setIcdCategory,
        setIcdCode,
        icdConditions,
        reloadTableData,
        drgDetails,
        setReloadTableData,
        icdCondition,
        chronicCondition      
    } = props


    const toggle = () => {
        setModal(!modal)
    }

    const chronicOptions = [{
        value: 1,
        label: 'Yes'

    },
    {
        value: 0,
        label: 'No'

    },

    ]


//   const statusOptions = [
//     { value: "1", label: 'Active' },
//     { value: "0", label: 'Inactive' }
//   ]


    const updateICDDetails = () => {
        const params =
        {
            "icd_code": icdCode ? icdCode : "",
            "icd_id": icdId ? icdId : null,
            "icd_category_id": selectedCategory ? selectedCategory.value : "",
            "icd_description": icdDescription ? icdDescription : "",
            "icd_condition_id":selectedCondition ? selectedCondition.value:"",
            "drg_code":icdDrg ? icdDrg.label:"",
            "isChronic":isChronic ? isChronic.value:""

        }

        AxiosInter.post(`${API_ENDPOINT}/admin-api/edit-icd-details/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                setReloadTableData(reloadTableData + 1)
                toastr.success("Success", "Updated successfully")
                toggle();



            })
            .catch(error => {
                // setSpinner(false);
                toastr.error("Error", error.response?.data?.message)

            });


    }

    const setICDCategoryDropdown = (data) => {
        var array = data;
        const options = array.map((item) => {
            return {
                value: item.id,
                label: item.description
            };
        });
        setCategoryOptions(options)
    };

    const setInitialCategory = () => {
        categoryOptions.map((data) => { // eslint-disable-line
            if (data.label === icdCategory) {
                setSelectedCategory({ value: data.value, label: icdCategory })

            }
        })
    }

    const setICDConditionsDropDown = (data) => {
        var array = data;
        const options = array.map((item) => {
            return {
                value: item.id,
                label: item.category
            };
        });
        setConditionsOptions(options)
    };

    const setDrgCodeDropDown = (data) => {
        var array = data;
        const options = array.map((item) => {
            return {
                value: item.id,
                label: item.code
            };
        });
        setDrgCodeOptions(options)
    };

    const setInitialCondition = () => {
        conditionsOptions.map((data) => { // eslint-disable-line
            if (data.label === icdCondition) {
                setSelectedCondition({ value: data.value, label: icdCondition })

            }
        })

    }
    // const setInitialDrgCode = () => {
    //     debugger
    //     drgCodeOptions.map((data) => { // eslint-disable-line
    //        debugger
    //         if (data.label === icdDrgCode) {
    //             debugger
    //             setIcdDrg({ value: data.value, label: icdDrgCode })

    //         }
    //     })
    // }

    const setInitialChronicValue = () => {
        if(chronicOptions) {
        chronicOptions.map((data) => {  // eslint-disable-line
            if (data.value === chronicCondition) {
                setIsChronic({ value: data.value, label: data.label })
            }
        })
    }
    }

    const validate = () => {

        if (
            icdCode && selectedCategory && icdDescription

        ) {
            setEditButtonBlankState(false)
        }
        else {
            setEditButtonBlankState(true)
        }
    }


    useEffect(() => {
        if (modal) {
            setICDCategoryDropdown(icdCategoryList);
            setICDConditionsDropDown(icdConditions);
            setDrgCodeDropDown(drgDetails);
      
           
            
        }

    }, [modal]) // eslint-disable-line

    useEffect(() => {
        if (!modal) {
            setIcdCode("");
            setIcdId("");
            setIcdCategory("");
            setSelectedCategory("");
            setIcdDescription("");
            setIcdDrg("");
            setIsChronic("");
        };

    }, [modal]) // eslint-disable-line


    useEffect(() => {
        if (drgCodeOptions && conditionsOptions && categoryOptions ) {
            // setInitialDrgCode();
            setInitialCondition();
            setInitialCategory();
            setInitialChronicValue();
        }
    }, [drgCodeOptions,conditionsOptions,categoryOptions]) // eslint-disable-line


    useEffect(() => {
        validate();

    }, [icdCode, icdDescription, selectedCategory]) // eslint-disable-line






    return (
        <div>
            <Modal isOpen={modal} size="lg">
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Edit ICD Details</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Row>
                            <Col sm={6}>
                                <Label for="icd_code">ICD Code<span className="required">*</span></Label>
                                <Input
                                    id="icd_code"
                                    placeholder="ICD Code"
                                    value={icdCode}
                                    onChange={(e) => {
                                        setIcdCode(e.target.value)

                                    }}
                                />
                            </Col>
                            <Col sm={6}>
                                <Label for="icd_category">ICD Category</Label>
                                <Select
                                    name="icd_category"
                                    options={
                                        categoryOptions
                                    }
                                    value={selectedCategory}
                                    placeholder="Choose ICD Category"
                                    isSearchable
                                    isClearable
                                    onChange={(e) => {
                                        setSelectedCategory(e)

                                    }}
                                />

                            </Col>
                        </Row><br />
                        <Row>
                            <Col sm={6}>
                                <Label for="group_name">ICD Description<span className="required">*</span></Label>
                                <Input
                                    id="address1"
                                    type="textarea"
                                    rows={4}
                                    cols={2}
                                    placeholder="ICD Description"
                                    value={icdDescription}
                                    onChange={(e) => {
                                        setIcdDescription(e.target.value);
                                    }}

                                />
                            </Col>
                            <Col sm={6}>
                                <Label for="icd_condition">Condition</Label>
                                <Select
                                    name="icd_condition"
                                    options={
                                        conditionsOptions
                                    }
                                    value={selectedCondition}
                                    placeholder="Choose ICD Condition"
                                    isSearchable
                                    isClearable
                                    onChange={(e) => {
                                        setSelectedCondition(e)

                                    }}
                                />
                            </Col>

                        </Row><br />

                        <Row>
                            <Col sm={6}>
                                <Label for="drg_code">DRG Code</Label>
                                <Select
                                    name="drg_code"
                                    options={
                                        drgCodeOptions
                                    }
                                    value={icdDrg}
                                    placeholder="Choose DRG Code"
                                    isSearchable
                                    isClearable
                                    onChange={(e) => {
                                        setIcdDrg(e)

                                    }}
                                />
                            </Col>
                            <Col sm={6}>
                                <Label for="is_chronic">Chronic</Label>
                                <Select
                                    name="is_chronic"
                                    options={  chronicOptions }
                                    placeholder="Choose Chronic"
                                    isSearchable
                                    isClearable
                                    value={isChronic}
                                    onChange={(e) => {
                                        setIsChronic(e)

                                    }}
                                />

                            </Col>
                        </Row><br />

                    </FormGroup>
                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button
                        onClick={() => updateICDDetails()}
                        disabled={editButtonBlankState}
                        title={editButtonBlankState ? "Please fill in all mandatory fields" : "Click to update ICD details"}>
                        Update
                    </Button>{" "}
                    <Button outline className="no-button button-width" onClick={toggle}>
                        Cancel
          </Button>
                </ModalFooter>
            </Modal>
        </div>
    );




}

export default ICDCodeDetailsEditModal;