import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { toastr } from 'react-redux-toastr'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// import { faCog, faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

function DropJpg(props) {
    // DEPENDENCY VARIABLES
    const {
        setJpgFile,
        
    } = props


    // LOCAL VARIABLES
    const [fileName, setFileName] = useState("")

    var fileExtension = false
    var tempFile = ""

    

    const validateFileExtension = (filename) => {
        let extension = filename.split('.').pop();
        fileExtension = false

        if (extension.toLowerCase() === "jpg") {
            fileExtension = true
            setFileName(filename)
            /* setMainFileReady(true) */
        }
    }

    const saveFile = () => {
        // SAVE SELFIE FILE
        setJpgFile(tempFile)
    }

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            let size = file.size
            if (size > 5242880) {
                toastr.error("File size exceeded", "Please upload a file lesser than 5MB in size")
            } else {
                validateFileExtension(file.name)
                if (fileExtension) {
                    // SAVE FILES ON A STATE VARIABLE
                    tempFile = acceptedFiles.map((file) => (file));// eslint-disable-line
                    saveFile();
                } else {
                    toastr.error(
                        "Unsupported File",
                        "File is not supported for the upload"
                    );
                }
            }

        })

    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1 })

    return (
        <>
            <div className="drop-zone-area" {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <div>
                {fileName ? <li className="drop-zone-file">{fileName}{"  "}<FontAwesomeIcon style={{'cursor':'pointer'}} title={'Remove'} icon={faTimesCircle} onClick={()=>{setFileName("") ;setJpgFile("") }}/> </li>: ""}
            </div>

        </>
    )
}

export default DropJpg;
