/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState, useContext } from 'react';
import { Button, Card, Col, Input, Row, Spinner, Breadcrumb, BreadcrumbItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container, Label, CardHeader } from 'reactstrap';
import { Link } from "react-router-dom";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import AsyncSelect from 'react-select/async'; // eslint-disable-line
import "../../newComponents/utilities/DateRangeSelector.css";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { Hux } from "./../../hoc/Hux";
import Select from "react-select";
import moment from 'moment';
import MomentConfig from '../../common/MomentConfig';
import PatientEngagementModal from '../../../src/newComponents/utilities/PatientEngagementModal'
import DatePicker from "react-datepicker";
import { GlobalState } from '../../contextAPI/Context';
import ExportCSVTableForPatient from "./PatientDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faShieldAlt, faKey } from "@fortawesome/free-solid-svg-icons";
import { toastr } from 'react-redux-toastr';
import PatientListModal from '../../newComponents/utilities/PatientsListModal.js';
import FacilityStatusModal from '../../newComponents/utilities/FacilityStatusModal.js';

const PATIENT360 = process.env.REACT_APP_PATIENT360;


const PatientNew = () => {

  const [patientListModal, setPatientListModal] = useState();
  const [id, setID] = useState("");
  const [smsToggleButton, setSmsToggleButton] = useState(true);
  const [ivrToggleButton, setIvrToggleButton] = useState(true);
  const [emailToggleButton, setEmailToggleButton] = useState(true);
  const [appToggleButton, setAppToggleButton] = useState(true);

  // CONTEXT VARIABLES 
  const { groupNamesForDropDown } = useContext(GlobalState)

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");

  const [spinner, setSpinner] = useState(false)
  const [apiLoaded, setApiLoaded] = useState(false)
  const [search, setSearch] = useState(false)
  const [patientList, setPatientList] = useState("")
  const [patientName, setPatinetName] = useState("")
  const [patientMiddleName, setPatinetMiddleName] = useState("")
  const [patientLastName, setPatinetLastName] = useState("")
  const [patientAccountNumber, setPatientAccountNumber] = useState("")
  const [patientDob, setPatientDob] = useState("")
  const [patientDobAPI, setPatientDobAPI] = useState("")
  const [patientSsn, setPatientSsn] = useState("")
  const [groupID, setGroupID] = useState("");
  const [facilityID, setFacilityID] = useState("");
  const [status, setStatus] = useState('');
  const [patientDataReload, setPatientDataReload] = useState(0);
  const [patientEngagementModal, setPatientEngagementModal] = useState(false);
  const [patientSizePerPage, setPatientSizePerPage] = useState("50");
  const [offset, setOffset] = useState("0");
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [order, setOrder] = useState("desc");
  const fileExportName = 'Patients'
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
  const [clickedRowData, setClickedRowData] = useState()
  const [facilityAccessModal, setFacilityAccessModal] = useState(false)
  const [patientId, setPatientId] = useState()
  const [groupId, setGroupId] = useState()
  const [fName, setFName]=useState()

  const StatusOptions = [
    { value: '1', label: 'Active' },
    { value: '0', label: 'Inactive' }
  ]

  const clearValues = () => {
    setGroupID("")
    setPatientList("")
    setPatinetMiddleName("")
    setPatinetLastName("")
    setPatinetName("")
    setPatientAccountNumber("")
    setPatientDob("")
    setPatientSsn("")
    setFacilityID("")
    setPatientDobAPI("")
    setStatus("")
    setApiLoaded(false)
  }

  const basicValidation = (cell) => {
    return cell ? cell : "N/A"
  }

  const dobFormatter = (cell) => {
    return cell ? moment(cell).format(MomentConfig.MOMENT_FORMAT_DATE) : "N/A"
  }
  const statusFormatter = (cell) => {
    if (cell === 0) {
      return "Inactive"
    } else {
      return "Active"
    }
  }

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Container>
          <Row>
            <Col>
              <UncontrolledDropdown title="Options">
                <DropdownToggle className="dropdown-options">
                  <FontAwesomeIcon
                    icon={faEllipsisV}
                    size={'lg'}
                  />
                </DropdownToggle>
                <DropdownMenu align="end" container="body">
                  <DropdownItem
                    onClick={() => {
                      setPatientEngagementModal(true)
                      editPatientEngagement(row.id, row.isSMSEnabled, row.isEmailEnabled, row.isIVREnabled, row.isAppEnabled)
                    }}
                  >
                    <FontAwesomeIcon icon={faShieldAlt} />{" "}
                    Patient Engagement
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col>
              <Button
                className='money-button'
                outline
                color='primary'
                title='Access to change status'
                style={{
                  textAlign: 'center', position: 'sticky',
                  top: 0,
                  right: 0,
                  zIndex: 1,
                  marginLeft: '-20px',
                  marginRight: '10px'
                }}
                onClick={() => {
                  setFacilityAccessModal(true)
                  setPatientId(row.id)
                  setGroupId(row.patientGroupID)
                  setFName(row.fullName)
                }}
              >
                <FontAwesomeIcon
                  icon={faKey}
                  size={'lg'}
                />
              </Button>
            </Col>
            <Col>
              {row?.inviteID === null && PATIENT360 === "true" && (
                <Button
                  onClick={() => {
                    setClickedRowData(row);
                    setPatientListModal(true);
                  }}
                  style={{
                    position: 'sticky',
                    top: 0,
                    right: 0,
                    zIndex: 1,
                    marginLeft: '-30px'
                  }}
                  title='Invite to Patient 360'
                >
                  Invite
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  const editPatientEngagement = (id, isSMSEnabled, isEmailEnabled, isIVREnabled, isAppEnabled) => {
    setID(id);
    setSmsToggleButton(isSMSEnabled);
    setEmailToggleButton(isEmailEnabled);
    setIvrToggleButton(isIVREnabled);
    setAppToggleButton(isAppEnabled)
  }

  const columns = [{
    dataField: 'first',
    text: 'First Name',
    sort: true,
    formatter: basicValidation,
  }, {
    dataField: 'last',
    text: 'Last Name',
    sort: true,
    formatter: basicValidation,
  }, {
    dataField: 'address1',
    text: 'Address',
    sort: true,
    formatter: basicValidation
  }, {
    dataField: 'dob',
    text: 'Date of Birth',
    sort: false,
    formatter: dobFormatter,
    csvFormatter: dobFormatter,
  },
  {
    dataField: 'status',
    text: 'Status',
    sort: false,
    formatter: statusFormatter,
    csvFormatter: statusFormatter,
  },
  {
    dataField: 'groupName',
    text: 'Group Name',
    sort: true,
    formatter: basicValidation,
  },
  // {
  //   dataField: 'facilityName',
  //   text: 'Facility Name',
  //   sort: true,
  //   formatter: basicValidation
  // },
  {
    dataField: 'accountNumber',
    text: 'Account Number',
    sort: true,
    formatter: basicValidation
  },
  {

    text: 'Actions',
    sort: false,
    csvExport: false,
    headerStyle: { width: '200px' },
    style: { width: '200px' },
    formatter: actionsFormatter,
  }
  ];

  const onTableChangeForPatient = (type, newState) => {

    console.log(newState)
    setPage(newState.page)
    setPatientSizePerPage(newState.sizePerPage)
    setOffset((newState.page - 1) * newState.sizePerPage)

    if (newState.sortField === null && newState.sortOrder === undefined) {

      setSortColumn(sortColumn)
      setSortOrder(sortOrder)
    }
    else {
      if (newState.sortOrder == order) { //eslint-disable-line
        setOrder("asc")
      }
      else {
        setOrder("desc")
      }
      setSortColumn(newState.sortField)
      setSortOrder(order);
      setPatientDataReload(patientDataReload + 1)
    }

  }
  const minLength = 4;
  const customNoOptionsMessage = ({ inputValue }) => { // eslint-disable-line
    if (inputValue.length < minLength) {
      return "Please enter at least 4 letters to search";
    } else {
      return "No option";
    }
  };

  const searchPatients = () => {
    setSpinner(true);
    setApiLoaded(false);

    let gp_num = groupID ? groupID.value : ""
    let gp_string = '' + gp_num
    let facility_num = facilityID ? facilityID.value : ""
    let facility_string = '' + facility_num

    let params = {}
    if (searchKeyword.length > 0) {
      params =
      {
        "patient_first_name": "",
        "patient_last_name": "",
        "patient_middle_name": "",
        "patient_account_number": "",
        "patient_dob": "",
        "patient_ssn": "",
        "patient_groupID": "",
        "patient_facilityID": "",
        "patient_mrn": "",
        "patient_status": 2,
        "offset": offset ? offset : "0",
        "record_limit": patientSizePerPage ? patientSizePerPage : "50",
        "search_keyword": searchKeyword ? searchKeyword : "",
        "is_pagination": "1",
        "sortOrder": sortOrder ? sortOrder : "desc",
        "sortColumn": sortColumn ? sortColumn : "id",
      }
    } else {
      params =
      {
        "patient_first_name": patientName,
        "patient_last_name": patientLastName,
        "patient_middle_name": patientMiddleName,
        "patient_account_number": patientAccountNumber,
        "patient_dob": patientDobAPI ? patientDobAPI : "",
        "patient_ssn": patientSsn,
        "patient_groupID": gp_string,
        "patient_facilityID": facility_string,
        "patient_mrn": "",
        "patient_status": status ? Number(status.value) : 2,
        "offset": offset ? offset : "0",
        "record_limit": patientSizePerPage ? patientSizePerPage : "50",
        "search_keyword": searchKeyword ? searchKeyword : "",
        "is_pagination": "1",
        "sortOrder": sortOrder ? sortOrder : "desc",
        "sortColumn": sortColumn ? sortColumn : "id",
      }
    }


    AxiosInter.post(`${API_ENDPOINT}/admin-api/list-all-patients/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(function (response) {
        if (response.data.data.length > 0) {
          setPatientList(response.data.data);
          // setPatientId(response?.data?.data[0]?.id)
          // setGroupId(response?.data?.data[0]?.groupid)
          localStorage.setItem('offset', offset.toString());
          setTotalSize(response.data.record_count)
          setApiLoaded(true);
          setSpinner(false);
        } else {
          setPatientList("");
          setApiLoaded(true);
          setSpinner(false);
        }
      })
      .catch(function (error) {
        setPatientList("");
        setApiLoaded(true);
        setSpinner(false);
      });
  };
  const exportTableData = () => {
    setSpinner(true)
    let gp_num = groupID ? groupID.value : ""
    let gp_string = '' + gp_num
    let facility_num = facilityID ? facilityID.value : ""
    let facility_string = '' + facility_num
    const params =
    {
      "file_name": fileName ? fileName : "",
      "patient_first_name": patientName,
      "patient_last_name": patientLastName,
      "patient_middle_name": patientMiddleName,
      "patient_account_number": patientAccountNumber,
      "patient_dob": patientDobAPI ? patientDobAPI : "",
      "patient_ssn": patientSsn,
      "patient_groupID": gp_string,
      "patient_facilityID": facility_string,
      "patient_mrn": "",
      "patient_status": status ? Number(status.value) : 2,
      "offset": offset,
      "record_limit": "",
      "search_keyword": searchKeyword ? searchKeyword : "",
      "is_pagination": "1",
      "sortOrder": sortOrder ? sortOrder : "",
      "sortColumn": sortColumn ? sortColumn : "",
    }

    AxiosInter.post(`${API_ENDPOINT}/admin-api/export-patient-table-data/`, params, {
      headers: {

        Authorization: API_TOKEN,

      },
      responseType: "blob"
    })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data, `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success(
            "Success",
            "Export successful"
          );
          setSpinner(false)
        }

      })
      .catch((error) => {
        if (error.response && error.response.data.error) {
          toastr.error("Failed", error.response.data.error);
        } else {
          toastr.error("Failed", "Something went wrong");
        }

        setSpinner(false)
      });
  };

  const convert = (dob) => {
    let date = moment(dob).format("DD-MM-YYYY")
    let localDateAndTime = `${date} 00:00:00`


    // let currentTimeZone = moment(localDateAndTime, "DD-MM-YYYY H:mm:ss").format("DD-MM-YYYY H:mm:ss")
    setPatientDob(dob ? moment(dob).toDate() : "")

    let apiFormat = moment(localDateAndTime, 'DD-MM-YYYY H:mm:ss').format("YYYY-MM-DD HH:mm:ss")
    setPatientDobAPI(apiFormat)
  }



  const enableOrDisableSearch = () => {
    if (patientName || patientDob || patientAccountNumber || patientSsn || groupID || facilityID || status || patientLastName || patientMiddleName) {
      setSearch(true)
    } else {
      setSearch(false)
    }
  }

  // const loadFacilityOptionsForFacility = (inputValue) => {
  //   if(inputValue.length >= 4){
  //     const searchParams = new URLSearchParams ({ name: inputValue }); 
  //     const url = `${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`;
  //       var temp =  fetch(url, {
  //       headers: {
  //           Authorization: API_TOKEN,
  //       }
  //       })
  //       .then(response => response.json())
  //       .then(data => data.data);
  //       return temp
  //   }
  // }

  const loadFacilityOptionsForFacility = (inputValue) => {  // eslint-disable-line
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });

      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  useEffect(() => { enableOrDisableSearch(); }, [patientName, patientDob, patientAccountNumber, patientSsn, groupID, facilityID, status, patientMiddleName, patientLastName]) // eslint-disable-line

  useEffect(() => {
    if (patientDataReload > 0) {
      debugger
      searchPatients();
      localStorage.setItem('offset', "0");
    }
  }, [patientDataReload]) // eslint-disable-line

  /* useEffect(() => {
    if (patientList) { localStorage.setItem('patientSizePerPage', patientSizePerPage) }
  }, [patientList]) // eslint-disable-line */

  useEffect(() => {
    var previousOffset = localStorage.getItem('offset')
    if (offset !== previousOffset && previousOffset!==null) {
      debugger
      searchPatients();
    }

  }, [offset]) // eslint-disable-line

  useEffect(() => { if (searchKeyword.length > 3) setPatientDataReload(patientDataReload + 1) }, [searchKeyword]); // eslint-disable-line

  return (
    <div>
      <Hux>
        <Container fluid>
          <Header>
            <HeaderTitle>Patients</HeaderTitle>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/dashboard">Dashboard</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Patients</BreadcrumbItem>
            </Breadcrumb>
          </Header>
        </Container>
      </Hux>

      <Card className="adv-search-background-card">
        <CardHeader>
          <Row>
            <Col sm="2">
              <Label>First Name</Label>
              <Input
                type="text"
                name="name"
                placeholder="First Name"
                value={patientName}
                validate={{
                  maxLength: { value: 120 }
                }}
                onChange={(e) => { setPatinetName(e.target.value) }}
              />
            </Col>
            <Col sm="2">
              <Label>Middle Name</Label>
              <Input
                type="text"
                name="middle"
                placeholder="Middle Name"
                value={patientMiddleName}
                validate={{
                  maxLength: { value: 120 }
                }}
                onChange={(e) => { setPatinetMiddleName(e.target.value) }}
              />
            </Col>
            <Col sm="2">
              <Label>Last Name</Label>
              <Input
                type="text"
                name="last"
                placeholder="Last Name"

                value={patientLastName}
                validate={{
                  maxLength: { value: 120 }
                }}
                onChange={(e) => { setPatinetLastName(e.target.value) }}
              />
            </Col>
            <Col sm="2">
              <Label>Account number</Label>
              <Input
                type="text"
                name="Account Number"
                placeholder="Account Number"
                value={patientAccountNumber}
                validate={{
                  maxLength: { value: 254 }
                }}
                onChange={(e) => { setPatientAccountNumber(e.target.value) }}
              />
            </Col>
            <Col sm="2">
              <Label>DOB</Label>
              <Col>

                <DatePicker
                  dateFormat="dd MMM YYY"
                  className="dob-date"
                  selected={patientDob ? patientDob : ""}
                  placeholderText="Select Date"
                  todayButton="Today"
                  maxDate={new Date()}
                  yearDropdownItemNumber={100}
                  scrollableYearDropdown={true}
                  showYearDropdown
                  showMonthDropdown
                  onChange={(date) => { convert(date) }}
                />

              </Col>
            </Col>
            <Col sm="2">
              <Label>SSN</Label>
              <Input
                name="patientssn"
                placeholder="SSN"
                value={patientSsn}
                /*  onChange={(e) => {
                     if (e.target.value.length <= 10) {
                         var result = e.target.value.replace(/[^a-z0-9]/gi, '');
                         setPostCode(result)
                     }
                 }} */
                onChange={(e) => { setPatientSsn(e.target.value) }}
              />
              <br />
            </Col>
            {/* <Col sm="4">
              <Label>Facility</Label>

              <AsyncSelect

                cacheOptions
                isSearchable
                isClearable
                placeholder="Type facility name for options"
                noOptionsMessage={customNoOptionsMessage}
                value={facilityID}
                loadOptions={loadFacilityOptionsForFacility}
                getOptionLabel={e => e.label}
                getOptionValue={e => e.value}
                onChange={(e) => {
                  setFacilityID(e)
                }}
              />

            </Col> */}
            <Col sm="2">
              <Label>Group</Label>
              <Select
                name="Group"
                placeholder="Select"
                options={groupNamesForDropDown}
                isSearchable
                isClearable
                value={groupID}
                onChange={(e) => {
                  setGroupID(e)
                }}
              />
            </Col>
            <Col sm="2">
              <Label>Status</Label>
              <Select
                name="status"
                placeholder="Select"
                options={StatusOptions}
                isSearchable
                isClearable
                value={status}
                onChange={(e) => {
                  setStatus(e)
                }}
              />
            </Col>
            <Col>
              <Button
                className="patient-go-buttons"
                //onClick={() => { searchPatients() }}
                disabled={spinner || !search ? true : false}
                onClick={() => { offset === '0' ? searchPatients() : setOffset('0') }}
                title={spinner || !search ? "Nothing To Search" : ""}
              >Go</Button> {" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => { clearValues(); }}
                disabled={spinner || !search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >Reset</Button>
            </Col>

          </Row>



        </CardHeader>



        {spinner
          ? <Spinner />
          : ""}

        {patientList && apiLoaded
          ? <>

            {patientList && apiLoaded ?

              <ExportCSVTableForPatient
                data={patientList}
                columns={columns}
                onTableChange={(type, newState) => {
                  onTableChangeForPatient(type, newState);
                }
                }
                page={page}
                setPage={setPage}
                patientSizePerPage={patientSizePerPage}
                setOffset={setOffset}
                totalSize={totalSize ? totalSize : 0}
                setTotalSize={setTotalSize}
                patientDataReload={patientDataReload}
                setPatientDataReload={setPatientDataReload}
                setSearchKeyword={setSearchKeyword}
                searchKeyword={searchKeyword}
                exportTableData={exportTableData}
              />

              :
              ""}

            {!patientList && apiLoaded ? <Card> <h4 className="no-data-found-card">No Data Found</h4> </Card> : ""}


          </>
          : ""
        }
        {!patientList && apiLoaded && !spinner ?
          <>
            <br />
            <Row className=" patient-list-table">
              <Col>
                {!patientList && apiLoaded && !spinner ? <Card> <h4 className="no-data-found-card">No data is available</h4> </Card> : ""}
              </Col>
            </Row>
          </>
          : ""
        }


      </Card>
      <PatientEngagementModal
        patientEngagementModal={patientEngagementModal}
        setPatientEngagementModal={setPatientEngagementModal}
        id={id}
        setSmsToggleButton={setSmsToggleButton}
        setEmailToggleButton={setEmailToggleButton}
        setIvrToggleButton={setIvrToggleButton}
        setAppToggleButton={setAppToggleButton}
        smsToggleButton={smsToggleButton}
        ivrToggleButton={ivrToggleButton}
        emailToggleButton={emailToggleButton}
        appToggleButton={appToggleButton}
        patientDataReload={patientDataReload}
        setPatientDataReload={setPatientDataReload}

      />
      <PatientListModal
        patientListModal={patientListModal}
        setPatientListModal={setPatientListModal}
        clickedRowData={clickedRowData}
        patientList={patientList}
        setPatientList={setPatientList}
      />
      <FacilityStatusModal
        facilityAccessModal={facilityAccessModal}
        setFacilityAccessModal={setFacilityAccessModal}
        groupId={groupId}
        patientId={patientId}
        fName={fName}
      />
    </div>
  );
}

export default PatientNew;