import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import AxiosInter from './../../AxiosInter.js';
import { toastr } from "react-redux-toastr";
import Select from "react-select";
import Cookie from 'js-cookie';

const EditReferralSenderModal = (props) => {
  const {
    setReferralSenderEditModal,
    ReferralSenderEditModal,
    rowData,
    editNPI,
    setEditNPI,
    editAutoProcess,
    editSubscriptionType,
    editEnableEmail,
    editIsClinical,
    editIsTransport,
    editCategoryType,
    referralSenderReload,
    setReferralSenderReload,
  } = props;

  // LOCAL VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");

  const toggle = () => {
    setReferralSenderEditModal(!ReferralSenderEditModal);
    setCategoryType([])
  };

  const YesNoOptions = [
    { value: "1", label: "Yes" },
    { value: "0", label: "No" },
  ];
  // const categoryOptions = [
  //   { value: "1", label: "Clinical" },
  //   { value: "2", label: "Transport" },
  // ];

  const SubOptions = [
    { value: 'LITE', label: "Community" },
    {value: 'LITE PLUS', label: "Community Plus" },
    { value: 'FULL', label: "Enterprise" },

]

  //EDIT REFERRAL SENDERS VARIABLES
  const [AutoProcess, setAutoProcess] = useState("");
  const [npi, setNpi] = useState(""); // eslint-disable-line
  const [SubscriptionType, setSubscriptionType] = useState("");
  const [EnableEmail, setEnableEmail] = useState("");
  // const [IsClinical, setIsClinical] = useState("");
  const [IsTransport, setIsTransport] = useState("");  // eslint-disable-line
  const [categoryType, setCategoryType] = useState([]);
  const [categoryTypeOptions, setCategoryTypeOptions] = useState([]);
  const regXForNubers = /^[0-9]*$/;
  // const [categoryTypeRes, setCategoryTypeRes] = useState([])

  const confirmation = () => {
    if (!SubscriptionType) {
      toastr.error("Please Select Subscription Plan Type");
    }
    if (categoryType.length===0) {
      toastr.error("Please Select Category");
    }

    if (SubscriptionType && rowData.GroupName && categoryType.length!==0) {
      updateReferralSenderApiCall();
    }
  };

  const updateReferralSenderApiCall = () => {

    if (categoryType) {
      var categoryTypesResult = categoryType.map((item) => {
        return item.value.toString();
      });
    }

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/update-referral-senders/`,
        {
          referral_order_sender_id: Number(rowData.id),
          group_id: rowData.group_id,
          npi: Number(editNPI),
          status: "1",
          enable_auto_process: AutoProcess.value.toString(),
          subscription_type:SubscriptionType? SubscriptionType.label.toString() : "",
          enable_mail_notification: Number(EnableEmail.value),
          // is_clinical: Number(IsClinical.value),
          // is_transport: Number(IsTransport.value),
          is_clinical: 1,
          is_transport: 0,
          category_type: categoryTypesResult.sort()
        },
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
      .then(function (response) {
        setReferralSenderEditModal(!ReferralSenderEditModal);
        setReferralSenderReload(referralSenderReload + 1);
        toastr.success(
          "Successfully Updated",
          "Referral Sender successfully updated"
        );
      })
      .catch(function (error) {
        toastr.error("Update Failed", "Please try again");
      });
  };

  /* const setNPI = (e) => {
        setEditNPI(e.target.value)
    } */
    const categoryTypePopulate = () => {
      var array = []
      // const data = editCategoryType
      if (editCategoryType.length > 0) {
      editCategoryType.map((id) => {               // eslint-disable-line
        categoryTypeOptions.map((item) => {   // eslint-disable-line
              if (id.toString() === item.value.toString()) {
                  array.push({
                      value: item.value,
                      label: item.label
                  })
              }
          })
      })
    }
      setCategoryType(array)
  }
  const onChangeForNPINumber = (e) => {
    if(regXForNubers.test(e) === false) {
        toastr.warning("Invalid NPI Number", "alphabets are not allowed")
    } else{
        setEditNPI(e)
    }
}
  const Populate = () => {
    var array = YesNoOptions;

    array.map(
      (
        item 
      ) => // eslint-disable-line
      {
        if (item.value.toString() === editAutoProcess.toString()) {
          setAutoProcess(item);
        }
      }
    );

    var array2 = YesNoOptions;
    array2.map(
      (
        item 
      ) => // eslint-disable-line
      {
        if (item.value.toString() === editEnableEmail.toString()) {
          setEnableEmail(item);
        }
      }
    );

    // var array3 = categoryTypeOptions;
    // debugger
    // array3.map(
    //   (item ) => // eslint-disable-line
    //   {editCategoryType.map((item2)=>{
    //     if (item.value == item2){
    //       console.log("sdfsdfs")
    //     }

     
    //     // console.log('ITemVal',item.value)
    //     // console.log("editCategoryType",editCategoryType)
    //     // if (item.value == editCategoryType) {
    //     //   console.log('sdfsdf')
    //     //   setCategoryType(item);
          
    //     // }
    //   }
    //   );
    // })
    var array4 = YesNoOptions;
    array4.map((item) => // eslint-disable-line
    {
      if (item.value.toString() === editIsTransport.toString()) {
        setIsTransport(item);
      }
    });

    var array1 = SubOptions;
    array1.map(
      (
        item 
      ) =>// eslint-disable-line
       {
        if (item.value.toLowerCase() === editSubscriptionType.toLowerCase()) {
          setSubscriptionType(item);
        }
      }
    );
  };

  useEffect(() => {
    Populate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editAutoProcess,
    editSubscriptionType,
    editIsClinical,
    editCategoryType,
    editEnableEmail,
    editIsTransport,
    ReferralSenderEditModal,
  ]); // eslint-disable-line
  // useEffect(()=>{
  //   setEditNPI(editNPI);
  // },[editNPI])

  const fetchCategoryTypeOptions = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-category-type-options/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => {
        if (response.status === 200) {

          setCategoryTypeOptions(response.data.data)

        }

      })
      .catch((error) => {
        console.log(error)
      });
  }
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if(ReferralSenderEditModal === true && categoryTypeOptions.length == 0){
      fetchCategoryTypeOptions();
    }
    if (categoryTypeOptions) {
      // console.log('catTypeOptions',categoryTypeOptions)
      categoryTypePopulate();

    }

  }, [ReferralSenderEditModal, categoryTypeOptions]) // eslint-disable-line
  return (
    <div>
      <Modal isOpen={ReferralSenderEditModal}  size="md">
        <ModalHeader
          tag="h4"
          cssModule={{ "modal-title": "w-100 text-center" }}
          toggle={toggle}
        >
          Edit - {rowData ? rowData.GroupName : "N/A"}{" "}
        </ModalHeader>
        <ModalBody className="overflow-modal disable-scrollbars">
          <FormGroup>
            <Row>
              <Col>
                <Label for="name">
                  Group Name <span className="required">*</span>{" "}
                </Label>
                <Input
                  id="name"
                  value={rowData.GroupName}
                  placeholder="Enter the Name"
                  /*  onChange={(e) => setEditNa(e.target.value)} */
                  disabled
                  minLength="1"
                  maxLength="120"
                />
                <br></br>
                <Label for="name">
                  NPI {/* <span className="required">*</span> */}{" "}
                </Label>
                <Input
                  id="npi"
                   // eslint-disable-next-line eqeqeq
                  value={editNPI}
                  placeholder="Enter the NPI"
                  type="text"
                  onChange={(e) => { 
                    if (e.target.value.length >= 20) {
                        toastr.warning("Invalid Number", "number length exceeded")
                    }else{
                        onChangeForNPINumber(e.target.value)
                    }
                }}
                />
                <br></br>
                <Label for="name">Auto Process Enabled </Label>
                <Select
                  options={YesNoOptions}
                  onChange={(event) => setAutoProcess(event)}
                  value={AutoProcess}
                  placeholder="Select"
                  isClearable
                />
                <br></br>
                <Label for="name">
                  Subscription Type <span className="required">*</span>{" "}
                </Label>
                <Select
                  options={SubOptions}
                  onChange={(e) => setSubscriptionType(e)}
                  value={SubscriptionType}
                  placeholder="Select"
                  isClearable
                />
                <br></br>
                <Label for="name">Enable Mail Notification </Label>
                <Select
                  options={YesNoOptions}
                  onChange={(event) => setEnableEmail(event)}
                  value={EnableEmail}
                  placeholder="Select"
                  isClearable
                />
                <br></br>
                <Label for="name">Category<span className="required">*</span></Label>

                <Select
                  isMulti
                  options={categoryTypeOptions}
                  onChange={(event) => setCategoryType(event)}
                  value={categoryType}
                  placeholder="Select"
                  isClearable
                />
                <br></br>
                
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="advanced-search-button custom-modal-footer">
          <Button className="yes-button button-width" onClick={confirmation}>
            Update
          </Button>{" "}
          <Button outline className="no-button button-width" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default EditReferralSenderModal;
