import React from 'react';
import { Button, Modal, ModalHeader,ModalBody, ModalFooter} from 'reactstrap';



const PackageSearchListingModal = (props) => {
   
 
    const {
        listingModal,
        setListingModal,
        icdDetailsListing,
     
      
    } = props

    const toggle = () => {
        setListingModal(!listingModal)
       
    }



    return (
        <div>
            <Modal isOpen={listingModal} size="lg" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>{icdDetailsListing.length > 0 ? 'Choose ICD Package':""}</ModalHeader>
                <ModalBody className="overflow-modal">
                    {icdDetailsListing.length > 0 ? icdDetailsListing : <h4 style={{'textAlign':'center','marginTop':'50px'}}>No data found</h4>}
                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button outline className="no-button button-width" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default PackageSearchListingModal;