import React, { useState , useEffect} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,Input,Label, FormGroup, } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';

  

const EditAdminUserModal = (props) => {
    const {
        editAdminUserModal,
        setEditAdminUserModal,
        rowData,
        editName,
        setEditName,
        editEmail,
        setEditEmail,// eslint-disable-line
        reloadUserData,
        setReloadUserData,
        setEditUserType, // eslint-disable-line
        editUserType,
        editPhone,
        editUserCountryCode,
        countryCodeList,
        setEditPhone
    } = props;

    
    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");

    const UserTypeOptions = [
        { value: '1', label: 'Super Admin' },
        { value: '0', label: 'Admin' }
    ]
    const [usersRolesObject, setUsersRolesObject] = useState("");
    const [countryCodeObject, setCountryCodeObject] = useState("");

    const [optForCountryCode, setOptForCountryCode] = useState("");



    const toggle = () => { setEditAdminUserModal(!editAdminUserModal); }
 
    const confirmation = () => { 
        if(!editName){
            toastr.error("Name Field Is Required" , "Please enter the user name!")
        }
        
        if(!editEmail ) {
            toastr.error("Email Field Is Required" , "Please enter the user email!")
        }
        if(!editPhone ) {
            toastr.error("Phone Field Is Required" , "Please enter the user Phone no!")
        }
        if(!countryCodeObject ) {
            toastr.error("country code Field Is Required" , "Please select country code!")
        }
        
        if(editName && editEmail && editPhone && countryCodeObject){

            updateAdminUserApiCall()
        }
    
    };

    const updateAdminUserApiCall = () => {
                
        AxiosInter.post(`${API_ENDPOINT}/admin-api/update-admin-user/`, {
            "admin_user_id": rowData.id,
            "email": editEmail ,
            "name": editName,
            "is_superuser": usersRolesObject ? Number(usersRolesObject.value) : 0,
            "countryCode" : countryCodeObject ? countryCodeObject.label.toString() : "",
            "phone" : editPhone ? editPhone.toString() : "" 
        },{
            headers: {
              Authorization: API_TOKEN,
            },
          } )
            .then(function (response) {
                setEditAdminUserModal(!editAdminUserModal)
                setReloadUserData(reloadUserData + 1)
                toastr.success("Successfully Updated", "User details successfully updated")
            })
            .catch(function (error) {
                // console.log(error);
                toastr.error("Update Failed", "Please try again")
            });
    }

    const userTypePopulate = () => {

        UserTypeOptions.map((roles) => { // eslint-disable-line
            if (roles.value == editUserType) { // eslint-disable-line
                setUsersRolesObject(roles)
            }
        })
    }

    const createCountryCodeOption = () => {

        var array = countryCodeList;
        const options = array.map((item) => {
            return {
                value: item.id,
                label: item.country_isd_code
            };
        });
        setOptForCountryCode(options);
    };

    const countryCodePopulate = () => {
        var array = optForCountryCode
        array.map((item) => { // eslint-disable-line
            if (item.label === editUserCountryCode) {
                setCountryCodeObject(item)
            }
        })
    }

    useEffect(() => { if (UserTypeOptions && optForCountryCode) countryCodePopulate(); userTypePopulate() }, [editUserType, optForCountryCode])  // eslint-disable-line

    useEffect(() => { if(countryCodeList) createCountryCodeOption(); }, [countryCodeList]) // eslint-disable-line 

    
   
     return (
        <div>
            <Modal isOpen={editAdminUserModal} size="md" >
                <ModalHeader className="custom-modal-footer" tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Edit - {rowData ? rowData.name : "N/A"} </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars" >
                    <FormGroup>
                        <Row>
                            <Col>

                            <Label for="type">User Type <span className="required">*</span> </Label>
                                <Select
                                    value={usersRolesObject}
                                    options={UserTypeOptions}
                                    onChange={(event) => setUsersRolesObject(event)}
                                    placeholderText="Select"
                                    isClearable={true}
                                />
                                
                                <br></br>
                            
                                 
                                <Label for="name">User Name <span className="required">*</span> </Label>
                                <Input
                                id="name"
                                value={editName}
                                placeholder="Enter the Name"
                                onChange={(e) => { if(e.target.value.length <= 250)  setEditName(e.target.value)}}
                                minLength= "1"
                                />
                                <br></br>

                                {/* <Label for="phone">Email <span className="required">*</span> </Label>
                                <Input
                                id="email"
                                value={editEmail}
                                placeholder="Enter the Email"
                                onChange={(e) => { if(e.target.value.length <= 250) setEditEmail(e.target.value)}}
                                />
                                <br></br> */}

                                <Label for="type">Country Code <span className="required">*</span> </Label>
                                <Select
                                    value={countryCodeObject}
                                    options={optForCountryCode}
                                    onChange={(event) => setCountryCodeObject(event)}
                                    placeholderText="Select"
                                    isClearable={true}
                                />
                                
                                <br></br>
                                <Label for="phone">Phone <span className="required">*</span> </Label>
                                <Input
                                id="phone"
                                value={editPhone}
                                placeholder="Enter the Phone no"
                                onChange={(e) => { if(e.target.value.length < 11) setEditPhone(e.target.value)}}
                                />
                                <br></br>
                                

                            </Col>
                        </Row>
                    </FormGroup>
                 </ModalBody>
                <ModalFooter className="advanced-search-button custom-modal-footer">
                    <Button 
                    className="yes-button button-width"  
                    onClick={confirmation}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditAdminUserModal;