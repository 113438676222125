import React, { useEffect, useState} from "react";
import AxiosInter from './../../AxiosInter.js';
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Label,
  CardHeader,
} from "reactstrap";
import "../../newComponents/utilities/DateRangeSelector.css";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { toastr } from "react-redux-toastr";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MomentConfig from "../../common/MomentConfig";
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import Cookie from "js-cookie";
import EditRerferralOrderType from "../../newComponents/utilities/EditRefOrderType"
import NewOrderTypeModal from "../../newComponents/utilities/NewOrderTypeModal"


const AllRefOrderTypes = ({activeTab}) => {

  // CONTEXT VARIABLES
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const fileExportName = "referral_order_types";
  // eslint-disable-next-line no-unused-vars
  const [spinner, setSpinner] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [search, setSearch] = useState(false);
  const [referralOrderTypeList, setReferralOrderTypeList] = useState([]);
  const [editReferralOrderTypeModal, setEditReferralOrderTypeModal] = useState(false);
  const [editOrderType, setEditOrderType] = useState(""); // eslint-disable-line
  const [editOrderTypeID, setEditOrderTypeID] = useState("");
  const [editOrderCategory, setEditOrderCategory] = useState([]); // eslint-disable-line
  const [editOrderCategoryPopulate, setEditOrderCategoryPopulate] = useState([]);
  const [editOrderTypePopulate, setEditOrderTypePopulate] = useState({});
  const [rowData, setRowData] = useState("");
  const [orderCategory, setOrderCategory] = useState("");
  const [newOrderCategory, setNewOrderCategory] = useState("");
  const [newOrderType, setNewOrderType] = useState("");
  const [newReferralOrderTypeModal, setNewEditReferralOrderTypeModal] = useState(false);
  const [newOrderTypeBox, setNewOrderTypeBox] = useState(true);
  const [isGlobalSearch, setIsGlobalSearch] = useState(false);
  const [userDataReload, setUserDataReload] = useState(0);
  const [userSizePerPage, setUserSizePerPage] = useState("50");
  const [offset, setOffset] = useState("0");
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const [showRemoveButton, setshowRemoveButton] = useState(false);
  // eslint-disable-next-line no-unused-vars
  let loader = null;

  if (apiLoaded) {
    loader = <Spinner />;
  }
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`;
  const clearValues = () => {
    setOrderCategory("");
    setSortOrder("");
    setTotalSize(0);
    setUserDataReload(userDataReload + 1);
  };
  const basicValidation = (cell) => {
    return cell ? cell : "N/A";
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Row>
          <Col sm={2}>
            <FontAwesomeIcon
              icon={faEdit}
              size={"1x"}
              color={"#3f86fa"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setRowData(row);
                setEditReferralOrderTypeModal(true);
                setEditOrderTypeID(row.ordertypeID)
                setEditOrderType(row.orderType);
                setEditOrderCategory(row.categories);
                const OrderCategoryPopulate = async (inputValues) => {
                  const result = [];

                  for (const inputValue of inputValues) {
                    try {
                      const searchParams = new URLSearchParams({ id: inputValue });
                      const response = await AxiosInter.get(
                        `${API_ENDPOINT}/admin-api/referral-order-category/?${searchParams.toString()}`,
                        {
                          headers: {
                            Authorization: API_TOKEN,
                          },
                        }
                      );

                      const data = response.data.data[0];
                      result.push(data);
                    } catch (error) {
                      throw error;
                    }
                  }

                  console.log("Result:", result);
                  setEditOrderCategoryPopulate(result)
                  return result;
                };

                if (row.orderCategoryID !== undefined && row.orderCategoryID !== null) {
                  OrderCategoryPopulate(JSON.parse(row.orderCategoryID))
                }
                setEditOrderTypePopulate(
                  row.orderType
                )
              }}
              title="Edit Referral Order Type"
            />
          </Col>
        </Row>
      </>
    );
  };

  const columns = [
    {
      dataField: "orderType",
      text: "order type",
      sort: true,
      formatter: basicValidation,
    },
    {
      dataField: "categories",
      text: "order category",
      sort: true,
      formatter: basicValidation,
    },
    {
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];

  const onTableChangeForuser = (type, newState) => {
    setPage(newState.page);
    setUserSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);
    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("rot.id");
      setSortOrder("desc");
      setUserDataReload(userDataReload + 1);
    }

    else if (newState.sortField === "orderType") {
      setSortColumn("rot.type");
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    }
    else {
      setSortColumn(newState.sortField);
      setSortOrder(newState.sortOrder);
      setUserDataReload(userDataReload + 1);
    }

  };

  const OrderCategoryloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue });
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/referral-order-category/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        })
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const exportReferralOrderType = () => {
    const params = {
      file_name: fileName ? fileName : "",
      orderCategory: orderCategory ? orderCategory.label : "",
      record_limit: userSizePerPage ? userSizePerPage : "50",
      OFFSET: offset ? offset.toString() : "0",
      searchKeyword: searchKeyword ? searchKeyword : "",
      is_pagination: "",
      sortOrder: sortOrder ? sortOrder : "desc",
      sortColumn: sortColumn ? sortColumn : "rot.id",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/export-referral-order-types/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };


  const searchReferralOrderType = () => {
    setApiLoaded(true);
    const params = {

      orderCategory: orderCategory ? orderCategory.label : "",
      record_limit: userSizePerPage ? userSizePerPage : "50",
      OFFSET: offset ? offset.toString() : "0",
      searchKeyword: searchKeyword ? searchKeyword : "",
      is_pagination: "",
      sortOrder: sortOrder ? sortOrder : "desc",
      sortColumn: sortColumn ? sortColumn : "rot.id",
    };
    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/referral-order-types-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then(function (response) {
        if (response.data.data.length > 0) {
          setReferralOrderTypeList(response.data.data);
          localStorage.setItem("offset", offset.toString());
          setTotalSize(response.data.record_count);
          setApiLoaded(false);
        } else {
          setReferralOrderTypeList("");
          setApiLoaded(false);
        }
      })
      .catch(function (error) {
        setReferralOrderTypeList("");
        setApiLoaded(false);
      });
  };

  const enableOrDisableSearch = () => {
    if (orderCategory) {
      setSearch(true);
    } else {
      setSearch(true);
    }
  };

  useEffect(() => {
    enableOrDisableSearch();
  }, [orderCategory]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (userDataReload >= 1 && activeTab == 11) {
      searchReferralOrderType();
      localStorage.setItem("offset", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataReload, activeTab]);

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset) {
      searchReferralOrderType();
    }
  }, [offset]); // eslint-disable-line

  useEffect(() => {
    if (searchKeyword.length === 0) setUserDataReload(userDataReload + 1);
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (searchKeyword.length >= 2) {
      setIsGlobalSearch(true);
    }
  }, [searchKeyword]); // eslint-disable-line
  useEffect(() => {
    if (isGlobalSearch) {
      setOffset(0);
      setTotalSize(0);
      setUserDataReload(userDataReload + 1);
      setIsGlobalSearch(false);
    }
  }, [isGlobalSearch]); // eslint-disable-line


  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleCategoryChange = (value) => {
    setOrderCategory(value);
  };



  const clearSearchValues = () => {
    setSearchKeyword("");
    setUserDataReload(userDataReload + 1);
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <Row>
            <Col sm={4}>
              <Label>Order Category :</Label>
              <AsyncSelect
                cacheOptions
                isClearable
                isSearchable
                type="text"
                name="Order Category"
                placeholder="Search with at least 4 characters."
                value={orderCategory}
                getOptionLabel={(e) => e.label}
                getOptionValue={(e) => e.value}
                validate={{
                  maxLength: { value: 120 },
                }}
                onInputChange={handleInputChange}
                onChange={handleCategoryChange}
                loadOptions={(inputValue) => OrderCategoryloadOptions(inputValue)}
              />
            </Col>
            <Col sm="3">
              <Button
                className="patient-go-buttons"
                disabled={!search ? true : false}
                title={
                  spinner || !search
                    ? "Choose at least one item to search "
                    : ""
                }
                onClick={() => {
                  offset === "0" ? searchReferralOrderType() : setOffset("0");
                }}
              >
                Go
              </Button>{" "}
              <Button
                className="patient-reset-buttons"
                onClick={() => {
                  clearValues();
                }}
                disabled={!search ? true : false}
                title={spinner || !search ? "Nothing To Clear" : ""}
              >
                Reset
              </Button>
            </Col>
          </Row>

          <div className="separator" />
        </CardHeader>

        <div className="groups-search-ordertype">
          <SearchBarComponent
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            totalSize={totalSize}
            columns={columns}
            clearValues={clearSearchValues}

          />
        </div>

        <div>{spinner ? <Spinner /> : ""}</div>

        <RemotePagination
          data={referralOrderTypeList ? referralOrderTypeList : ""}
          columns={columns}
          page={page}
          sizePerPage={userSizePerPage}
          FileExportName={fileExportName}
          exportTableData={exportReferralOrderType}
          isGlobalSearch={isGlobalSearch}
          totalSize={totalSize ? totalSize : ""}
          onTableChange={(type, newState) => {
            onTableChangeForuser(type, newState);
          }}
          isDataEmpty={isDataEmpty}
          setIsGlobalSearch={setIsGlobalSearch}
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          newReferralOrderTypeModal={newReferralOrderTypeModal}
          setNewEditReferralOrderTypeModal={setNewEditReferralOrderTypeModal}
          newOrderTypeBox={newOrderTypeBox}
          setNewOrderTypeBox={setNewOrderTypeBox}
        />
      </Card>
      <div>
        <EditRerferralOrderType
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
          editReferralOrderTypeModal={editReferralOrderTypeModal}
          setEditReferralOrderTypeModal={setEditReferralOrderTypeModal}
          rowData={rowData}
          editOrderCategoryPopulate={editOrderCategoryPopulate}
          setEditOrderCategoryPopulate={setEditOrderCategoryPopulate}
          editOrderTypePopulate={editOrderTypePopulate}
          setEditOrderTypePopulate={setEditOrderTypePopulate}
          showRemoveButton={showRemoveButton}
          setshowRemoveButton={setshowRemoveButton}
          setEditOrderTypeID={setEditOrderTypeID}
          editOrderTypeID={editOrderTypeID}
          OrderCategoryloadOptions={OrderCategoryloadOptions}
        />
        <NewOrderTypeModal
          userDataReload={userDataReload}
          setUserDataReload={setUserDataReload}
          newReferralOrderTypeModal={newReferralOrderTypeModal}
          setNewEditReferralOrderTypeModal={setNewEditReferralOrderTypeModal}
          newOrderCategory={newOrderCategory}
          setNewOrderCategory={setNewOrderCategory}
          newOrderType={newOrderType}
          setNewOrderType={setNewOrderType}
          showRemoveButton={showRemoveButton}
          setshowRemoveButton={setshowRemoveButton}
          OrderCategoryloadOptions={OrderCategoryloadOptions}
        />
      </div>
    </div>
  );
};

export default AllRefOrderTypes;
