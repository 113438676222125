import React, { useState, useEffect, useContext } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  Label,
  Container,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
} from "reactstrap";
import RemotePagination from "../../newComponents/utilities/RemotePagination";
import CPTCodeModal from "../../newComponents/utilities/CPTCodeModal";
import CPTCategoryModal from "../../newComponents/utilities/CPTCategoryModal";
import AxiosInter from './../../AxiosInter.js';
import classnames from "classnames";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { toastr } from "react-redux-toastr";
import { Link } from "react-router-dom";
import { GlobalState } from "../../contextAPI/Context";
import _ from "lodash";
import SearchBarComponent from "../../newComponents/utilities/SearchBarComponent";
import MomentConfig from "../../common/MomentConfig";
import moment from "moment";
import Tooltip from '@mui/material/Tooltip';
import Cookie from 'js-cookie';
import RemotepaginationCPTCode from "../../newComponents/utilities/RemotepaginationCPTCode";
import SearchBarComponentCPTCode from "./SearchBarComponentCPTCode";

const CPTComponent = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN = Cookie.get("access_token_admin");
  const cptCodeExportName = "cpt";
  const [fileExportName, setFileExportName] = useState("CPT Code");
  const {
    cptCodesList,
    cptDescription,
    cptCategoryList,
    getCPTCategoryList,
    getCPTCodesList,
    getCPTDescription,
  } = useContext(GlobalState);

  const [activeTab, setActiveTab] = useState("1");
  const [modal, setModal] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [modalState, setModalState] = useState("initial");
  const [categoryModalState, setCategoryModalState] = useState("initial");
  const [editCptCode, setEditCptCode] = useState("");
  const [editCptCategory, setEditCptCategory] = useState("");
  const [editCptDescription, setEditCptDescription] = useState("");
  const [editCptLongDescription, setEditCptLongDescription] = useState("");
  const [editCptId, setEditCptId] = useState("");
  const [search, setSearch] = useState(false);
  const [cptCategoryOptions, setCptCategoryOptions] = useState(""); // eslint-disable-line
  const [selectedCPTCategory, setSelectedCPTCategory] = useState("");
  const [editCptCategoryDescription, setEditCPTCategoryDescription] = useState("");
  const [editCptCategoryId, setEditCptCategoryId] = useState("");
  const [reloadCPTCodeTableData, setReloadCPTCodeTableData] = useState(0);
  const [reloadCPTCategoryTableData, setReloadCPTCategoryTableData] = useState(0);
  const [selectedCPTCode, setSelectedCPTCode] = useState();
  const [selectedCPTCodeDescription, setSelectedCPTCodeDescription] = useState();
  const [spinner, setSpinner] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [order, setOrder] = useState("desc");
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [cptDetails, setCptDetails] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(50);
  const [cptCategoryDetails, setCptCategoryDetails] = useState([]);
  const [cptCategoryOffset, setCptCategoryOffset] = useState(0);
  const [cptCategoryPage, setCptCategoryPage] = useState(1);
  const [cptCategoryTotalSize, setCptCategoryTotalSize] = useState(0);
  const [cptCategorySizePerPage, setCptCategorySizePerPage] = useState(50);
  const [searchKeywordCPTCode, setSearchKeywordCPTCode] = useState("");
  const [searchKeywordCPTCategory, setSearchKeywordCPTCategory] = useState("");
  const [isGlobalSearchCPTCodes, setIsGlobalSearchCPTCodes] = useState(false);
  const [isGlobalSearchCPTCategory, setIsGlobalSearchCPTCategory] = useState(false);
  const [parentCategory, setParentCategory] = useState();
  const [cptCategorySearch, setCptCategorySearch] = useState(false);
  const [isDataEmpty, setIsDataEmpty] = useState(false);
  const fileName = `Equipo-${fileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`;

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleTableChange = (type, newState) => {
    setPage(newState.page);
    setSizePerPage(newState.sizePerPage);
    setOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("code");
      setSortOrder("asc");
    } else {
      if (newState.sortOrder == order) { //eslint-disable-line
        setOrder("asc")
      }
      else {
        setOrder("desc")
      }

      setSortColumn(newState.sortField);
      setSortOrder(order);
      setReloadCPTCodeTableData(reloadCPTCodeTableData + 1);
    }
  };

  const handleCategoryTableChange = (type, newState) => {
    setCptCategoryPage(newState.page);
    setCptCategorySizePerPage(newState.sizePerPage);
    setCptCategoryOffset((newState.page - 1) * newState.sizePerPage);

    if (newState.sortField === null && newState.sortOrder === undefined) {
      setSortColumn("Category");
      setSortOrder("asc");
    } else {
      if (newState.sortOrder == order) { //eslint-disable-line
        setOrder("asc")
      }
      else {
        setOrder("desc")
      }

      setSortColumn(newState.sortField);
      setSortOrder(order);
      setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1);
    }
  };

  const actionsFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setModal(true);
            setEditCptCode(row.code);
            setEditCptCategory(row.Category);
            setEditCptDescription(row.description);
            setEditCptLongDescription(row.longDescription);
            setModalState("edit");
            setEditCptId(row.cptId);
          }}
          color="primary"
          title="Edit CPT Details"
        >
          <FontAwesomeIcon icon={faEdit} fixedWidth />
        </Button>
      </>
    );
  };

  const cptCategoryActionsFormatter = (cell, row) => {
    return (
      <>
        <Button
          className="money-button"
          outline
          onClick={() => {
            setCategoryModal(true);
            setCategoryModalState("edit");
            setEditCPTCategoryDescription(row.Category);
            setEditCptCategoryId(row.cptCatId);
          }}
          color="primary"
          title="Edit CPT Category"
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </>
    );
  };

  const parentCategoryFormatterCategory = (cell, row) => {
    // return cell ? cell : "N/A";
    return cell && parentCategory ? parentCategory.find(x=> x.label === cell)?.label : "N/A";

  };

  const parentCategoryFormatter = (cell, row) => {
    return cell ? cell : "N/A";
  };

  const columns = [
    {
      dataField: "code",
      text: "Code",
      sort: true,
      formatter: parentCategoryFormatter,
    },
    {
      dataField: "description",
      text: "Description",
      sort: true,
      formatter: parentCategoryFormatter,
    },
    {
      dataField: "Category",
      text: "Category",
      sort: false,
      formatter: parentCategoryFormatter,
    },
    {
      text: "Actions",
      sort: false,
      formatter: actionsFormatter,
    },
  ];

  const cptCategoryColumns = [
    {
      dataField: "Category",
      text: "Category",
      sort: true,
    },
    {
      dataField: "parentCategory",
      text: "Parent Category",
      formatter: parentCategoryFormatterCategory,
    },
    {
      text: "Actions",
      sort: false,
      formatter: cptCategoryActionsFormatter,
    },
  ];

  const fetchCPTDetails = () => {
    const params = {
      cpt_code_id: selectedCPTCode ? selectedCPTCode.value : "",
      cpt_description: selectedCPTCodeDescription ? selectedCPTCodeDescription.label : "",
      offset: offset,
      is_pagination: 1,
      record_limit: sizePerPage ? sizePerPage : "",
      search_keyword: searchKeywordCPTCode ? searchKeywordCPTCode : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-cpt-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setSpinner(false);
          setTotalSize(response.data.record_count);
          setCptDetails(response.data.data);
          localStorage.setItem("offset", offset);
          setReloadCPTCodeTableData(0);
        } else {
          setTotalSize(0);
          setSpinner(false);
          setCptDetails("");
          setIsDataEmpty(true);
          setReloadCPTCodeTableData(0);
        }
      })
      .catch((error) => {
        setSpinner(false);
        toastr.error("Error", "Something went wrong");
      });
  };
  const exportCptCodeDetails = () => {
    const params = {
      file_name: fileName ? fileName : "",
      cpt_code_id: selectedCPTCode ? selectedCPTCode.value : "",
      cpt_description: selectedCPTCodeDescription ? selectedCPTCodeDescription.label : "",
      offset: offset ? offset : "0",
      is_pagination: "1",
      record_limit: "",
      search_keyword: searchKeywordCPTCode ? searchKeywordCPTCode : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/export-cpt-code-table-data/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
        responseType: "blob",
      })
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const fetchCPTCategoryDetails = () => {
    const params = {
      cpt_category_id: selectedCPTCategory ? selectedCPTCategory.value : "",
      cpt_category_description: "",
      offset: cptCategoryOffset,
      is_pagination: 1,
      record_limit: cptCategorySizePerPage ? cptCategorySizePerPage : "",
      search_keyword: searchKeywordCPTCategory ? searchKeywordCPTCategory : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };
    AxiosInter
      .post(`${API_ENDPOINT}/admin-api/get-cpt-category-details/`, params, {
        headers: {
          Authorization: API_TOKEN,
        },
      })
      .then((response) => {
        if (response.data.data.length > 0) {
          setSpinner(false);
          setCptCategoryTotalSize(response.data.record_count);
          setSortColumn("")
          setCptCategoryDetails(response.data.data);
          localStorage.setItem("cptCategoryOffset", cptCategoryOffset);
        } else {
          setCptCategoryTotalSize(0);
          setSpinner(false);
          setCptCategoryDetails("");
          setIsDataEmpty(true);
        }
      })
      .catch((error) => {
        setSpinner(false);
        toastr.error("Error", "Something went wrong");
      });
  };
  const exportCptCategoryDetails = () => {
    const params = {
      file_name: fileName ? fileName : "",
      cpt_category_id: selectedCPTCategory ? selectedCPTCategory.value : "",
      cpt_category_description: "",
      offset: cptCategoryOffset ? cptCategoryOffset : "0",
      is_pagination: "1",
      record_limit: "",
      search_keyword: searchKeywordCPTCategory ? searchKeywordCPTCategory : "",
      sortOrder: sortOrder ? sortOrder : "",
      sortColumn: sortColumn ? sortColumn : "",
    };

    AxiosInter
      .post(
        `${API_ENDPOINT}/admin-api/export-cpt-categories-table-data/`,
        params,
        {
          headers: {
            Authorization: API_TOKEN,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status === 200) {
          var fileDownload = require("js-file-download");
          fileDownload(
            response.data,
            `${fileName}`,
            response["headers"]["x-suggested-filename"]
          );
          toastr.success("Success", "Export successful");
        }
      })
      .catch((error) => {
        toastr.error("Failed", "Something went wrong");
      });
  };

  const setCPTOptions = (inputValue) => {
    getCPTCodesList()
    if (cptCodesList && inputValue.length >= 4) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(cptCodesList, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };
  const minLength = 4;
  const customNoOptionsMessage = ({ inputValue }) => {
    if (inputValue.length < minLength) {
      return "Please enter at least 4 letters to search";
    } else {
      return "No option";
    }
  };

  const setCPTDescription = (inputValue) => {
    getCPTDescription()
    if (cptDescription) {
      return new Promise((resolve, reject) => {
        const filtered = _.filter(cptDescription, (o) =>
          _.startsWith(_.toLower(o.label), _.toLower(inputValue))
        );
        resolve(filtered);
      });
    }
  };

  const createCptCategoryOptions = (data) => {
    var array = data;
    const options = array.map((item) => {
      return {
        value: item.cptCatId,
        label: item.Category,
      };
    });
    setCptCategoryOptions(options);
  };

  useEffect(() => {

    loadCPTCategory();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCPTCategory = () => {
    AxiosInter.get(`${API_ENDPOINT}/admin-api/get-cpt-category-list/`, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then((response) => {
        if (response.data.data.length > 0) {
          setParentCategory(response.data.data)
        }
      })
      .catch((error) => { });
  };


  const clearCptSearchValues = () => {
    setSearchKeywordCPTCode("");
    setReloadCPTCodeTableData(true);
  };
  const clearCptCategorySearchValues = () => {
    setSearchKeywordCPTCategory("");
    setReloadCPTCategoryTableData(true);
  };

  const clearCPTPageValues = () => {
    setPage(1);
    setSelectedCPTCode("");
    setSelectedCPTCodeDescription("");
    setReloadCPTCodeTableData(true);
  };

  const clearCPTCategoryPageValues = () => {
    setSelectedCPTCategory("");

    setReloadCPTCategoryTableData(true);
  };

  const searchButtonValidationCPTCodes = () => {
    if (selectedCPTCode || selectedCPTCodeDescription) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  const searchButtonValidationCPTCategory = () => {
    if (selectedCPTCategory) {
      setCptCategorySearch(true);
    } else {
      setCptCategorySearch(false);
    }
  };

  useEffect(() => {
    searchButtonValidationCPTCodes();
  }, [selectedCPTCodeDescription, selectedCPTCode]); // eslint-disable-line

  useEffect(() => {
    searchButtonValidationCPTCategory();
  }, [selectedCPTCategory]); // eslint-disable-line

  useEffect(() => {
    if (cptCategoryDetails) {
      createCptCategoryOptions(cptCategoryDetails);
    }
  }, [cptCategoryDetails]);

  useEffect(() => {
    if (activeTab === "2") fetchCPTCategoryDetails();
  }, []); // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem("offset");
    if (offset !== previousOffset || sizePerPage) {
      setReloadCPTCodeTableData(reloadCPTCodeTableData + 1);
    }
  }, [offset, sizePerPage]); // eslint-disable-line

  useEffect(() => {
    var previousOffset = localStorage.getItem("cptCategoryOffset");
    if (cptCategoryOffset !== previousOffset || cptCategorySizePerPage) {
      setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1);
    }
  }, [cptCategoryOffset, cptCategorySizePerPage]); // eslint-disable-line

  useEffect(() => {
    if (reloadCPTCodeTableData) {
      fetchCPTDetails();
    }
  }, [reloadCPTCodeTableData]); // eslint-disable-line

  useEffect(() => {
    if (cptCodesList.length <= 0 || cptDescription.length <= 0) {
      getCPTCodesList();
      getCPTDescription();
    }
  }, [cptCodesList, cptDescription]); // eslint-disable-line

  useEffect(() => {
    if (activeTab === "2" && reloadCPTCategoryTableData) {
      fetchCPTCategoryDetails();
    }
  }, [reloadCPTCategoryTableData]); // eslint-disable-line

  useEffect(() => {
    if (cptCategoryList.length <= 0) {
      getCPTCategoryList();
    }
  }, [cptCategoryList]); // eslint-disable-line

  useEffect(() => {
    if (isGlobalSearchCPTCodes) {
      setOffset(0);
      setTotalSize(0);
      setReloadCPTCodeTableData(reloadCPTCodeTableData + 1);
      setIsGlobalSearchCPTCodes(false);
    }
  }, [isGlobalSearchCPTCodes]); // eslint-disable-line

  useEffect(() => {
    if (isGlobalSearchCPTCategory) {
      setOffset(0);
      setCptCategoryTotalSize(0);
      setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1);
      setIsGlobalSearchCPTCategory(false);
    }
  }, [isGlobalSearchCPTCategory]); // eslint-disable-line

  useEffect(() => {
    if (searchKeywordCPTCode.length >= 4) {
      setIsGlobalSearchCPTCodes(true);
    }
  }, [searchKeywordCPTCode]); // eslint-disable-line

  useEffect(() => {
    if (searchKeywordCPTCode.length === 0) {
      setReloadCPTCodeTableData(reloadCPTCodeTableData + 1);
    }
  }, [searchKeywordCPTCode]); // eslint-disable-line

  useEffect(() => {
    if (searchKeywordCPTCategory.length >= 4) {
      setIsGlobalSearchCPTCategory(true);
    }
  }, [searchKeywordCPTCategory]); // eslint-disable-line

  useEffect(() => {
    if (searchKeywordCPTCategory.length === 0) {
      setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1);
    }
  }, [searchKeywordCPTCategory]); // eslint-disable-line

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container fluid>
        <Header>
          <HeaderTitle>
            CPT
            {activeTab === "1" ? (
              <Button
                className="Add-btn pull-right"
                onClick={() => {
                  setModal(true);
                  setModalState("add");
                }}
              >
                Add CPT code
              </Button>
            ) : (
              <Button
                className="Add-btn pull-right"
                onClick={() => {
                  setCategoryModal(true);
                  setCategoryModalState("add");
                }}
              >
                Add CPT Category
              </Button>
            )}
          </HeaderTitle>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/dashboard">Dashboard</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>CPT</BreadcrumbItem>
          </Breadcrumb>
        </Header>
      </Container>

      <Card className="adv-search-background-card">
        <CardHeader></CardHeader>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
                setFileExportName("CPT Code");
              }}
              style={{ cursor: "pointer" }}
            >
              CPT Code
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
                setFileExportName("CPT Categories");
                setSortColumn("");
                setReloadCPTCategoryTableData(reloadCPTCategoryTableData + 1);
              }}
              style={{ cursor: "pointer" }}
            >
              CPT Categories
            </NavLink>
          </NavItem>
        </Nav>
        <br />
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Container fluid>
              <Row>
                <Col
                  md={{
                    size: 3,
                  }}
                >
                  <Label>CPT Code</Label>
                  <AsyncSelect
                    cacheOptions
                    isClearable
                    isSearchable
                    value={selectedCPTCode}
                    placeholder={"Choose CPT code"}
                    noOptionsMessage={customNoOptionsMessage}
                    onChange={(event) => {
                      setSelectedCPTCode(event);
                    }}
                    loadOptions={(inputValue) => setCPTOptions(inputValue)}
                  />
                </Col>
                <Col
                  sm={{
                    size: 3,
                  }}
                >
                  <Label>CPT Description</Label>
                  <Tooltip title={selectedCPTCodeDescription ? selectedCPTCodeDescription.label : ''} placement="top" arrow>
                    <div>
                      <AsyncSelect
                        cacheOptions
                        isClearable
                        isSearchable
                        value={selectedCPTCodeDescription}
                        placeholder={"Choose CPT description"}
                        onChange={(event) => {
                          setSelectedCPTCodeDescription(event);
                        }}
                        loadOptions={(inputValue) => setCPTDescription(inputValue)}
                      />
                    </div>
                  </Tooltip>
                </Col>
                <Col
                  md={{
                    size: 4,
                  }}
                >
                  <Button
                    className="icd-go-buttons "
                    onClick={() => {
                      setTotalSize(0);
                      setCptDetails("");
                      offset === 0 ? fetchCPTDetails() : setOffset(0);
                      setPage(1);
                    }}
                    disabled={!search ? true : false}
                    title={!search ? "Choose at least one item to search " : ""}
                  >
                    Go
                  </Button>
                  <Button
                    className="icd-reset-buttons"
                    onClick={() => {
                      clearCPTPageValues();
                    }}
                    disabled={!search ? true : false}
                    title={!search ? "Nothing To Clear" : ""}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Container>
            <br />
            <div className="groups-search">
              <SearchBarComponentCPTCode
                data={cptDetails ? cptDetails : ""}
                exportTableData={
                  activeTab === "1"
                    ? exportCptCodeDetails
                    : exportCptCategoryDetails
                }
                searchKeyword={searchKeywordCPTCode}
                setSearchKeyword={setSearchKeywordCPTCode}
                totalSize={totalSize}
                clearValues={clearCptSearchValues}
              />
            </div>
            {!spinner ? (
              <>
                <RemotepaginationCPTCode
                  data={cptDetails ? cptDetails : ""}
                  columns={columns}
                  page={page}
                  isDataEmpty={isDataEmpty}
                  sizePerPage={sizePerPage}
                  FileExportName={fileExportName}
                  exportTableData={
                    activeTab === "1"
                      ? exportCptCodeDetails
                      : exportCptCategoryDetails
                  }
                  totalSize={totalSize ? totalSize : ""}
                  searchKeyword={searchKeywordCPTCode}
                  setSearchKeyword={setSearchKeywordCPTCode}
                  reloadVariable={reloadCPTCodeTableData}
                  setReloadDetails={setReloadCPTCodeTableData}
                  onTableChange={(type, newState) => {
                    handleTableChange(type, newState);
                  }}
                />
              </>
            ) : (
              <Spinner />
            )}
          </TabPane>
        </TabContent>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="2">
            <Container fluid>
              <Row>
                <Col sm="2" md="4" lg="2">
                  <Label>CPT Category</Label>
                  <Tooltip title={selectedCPTCategory ? selectedCPTCategory.label : ''} placement="top" arrow>
                    <div>
                      <Select
                        name="cpt_category"
                        options={cptCategoryList}
                        value={selectedCPTCategory}
                        placeholder="Choose CPT Category"
                        isSearchable
                        isClearable
                        onChange={(e) => { setSelectedCPTCategory(e); }}
                      />
                    </div>
                  </Tooltip>
                </Col>

                <Col
                  sm={{
                    size: 4,
                  }}
                >
                  <Button
                    className="icd-go-buttons"
                    onClick={() => {
                      setCptCategoryTotalSize(0);
                      cptCategoryOffset === 0
                        ? setReloadCPTCategoryTableData(
                          reloadCPTCategoryTableData + 1
                        )
                        : setCptCategoryOffset(0);
                      setCptCategoryPage(1);
                    }}
                    disabled={!cptCategorySearch ? true : false}
                    title={
                      !cptCategorySearch
                        ? "Choose at least one item to search "
                        : ""
                    }
                  >
                    Go
                  </Button>
                  <Button
                    className="icd-reset-buttons"
                    onClick={() => {
                      clearCPTCategoryPageValues();
                    }}
                    disabled={!cptCategorySearch ? true : false}
                    title={!cptCategorySearch ? "Nothing To Clear" : ""}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </Container>
            <br />
            <div className="groups-search">
              <SearchBarComponent
                searchKeyword={searchKeywordCPTCategory}
                setSearchKeyword={setSearchKeywordCPTCategory}
                totalSize={cptCategoryTotalSize}
                columns={columns}
                data={cptCategoryDetails ? cptCategoryDetails : ""}
                clearValues={clearCptCategorySearchValues}
              />
            </div>
            {!spinner ? (
              <>
                <RemotePagination
                  data={cptCategoryDetails ? cptCategoryDetails : ""}
                  columns={cptCategoryColumns}
                  page={cptCategoryPage}
                  isDataEmpty={isDataEmpty}
                  sizePerPage={cptCategorySizePerPage}
                  cptCodeExportName={cptCodeExportName}
                  FileExportName={fileExportName}
                  exportTableData={
                    activeTab === "1"
                      ? exportCptCodeDetails
                      : exportCptCategoryDetails
                  }
                  totalSize={cptCategoryTotalSize ? cptCategoryTotalSize : ""}
                  searchKeyword={searchKeywordCPTCategory}
                  setSearchKeyword={setSearchKeywordCPTCategory}
                  reloadVariable={reloadCPTCategoryTableData}
                  setReloadDetails={setReloadCPTCategoryTableData}
                  onTableChange={(type, newState) => {
                    handleCategoryTableChange(type, newState);
                  }}
                />
              </>
            ) : (
              <Spinner />
            )}
          </TabPane>
        </TabContent>
      </Card>

      {activeTab === "1" ? (
        <>
          <CPTCodeModal
            modal={modal}
            setModal={setModal}
            editCptCode={editCptCode}
            setEditCptCode={setEditCptCode}
            editCptCategory={editCptCategory}
            setEditCptCategory={setEditCptCategory}
            editCptDescription={editCptDescription}
            setEditCptDescription={setEditCptDescription}
            editCptLongDescription={editCptLongDescription}
            setEditCptLongDescription={setEditCptLongDescription}
            setModalState={setModalState}
            modalState={modalState}
            cptCategoryList={cptCategoryList}
            editCptId={editCptId}
            setReloadCPTCodeTableData={setReloadCPTCodeTableData}
          />
        </>
      ) : (
        <>
          <CPTCategoryModal
            categoryModal={categoryModal}
            parentCategory={parentCategory}
            setCategoryModal={setCategoryModal}
            categoryModalState={categoryModalState}
            editCptCategoryDescription={editCptCategoryDescription}
            setEditCPTCategoryDescription={setEditCPTCategoryDescription}
            editCptCategoryId={editCptCategoryId}
            setEditCptCategoryId={setEditCptCategoryId}
            reloadCPTCategoryTableData={reloadCPTCategoryTableData}
            setReloadCPTCategoryTableData={setReloadCPTCategoryTableData}
            getCPTCategoryList={getCPTCategoryList}
          />
        </>
      )}
    </div>
  );
};

export default CPTComponent;
