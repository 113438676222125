import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader,Spinner, ModalBody, ModalFooter, Row, Col,Container } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapTable from 'react-bootstrap-table-next';



const ImportAppUsersLogDetails = (props) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
 
    // eslint-disable-next-line no-unused-vars
    const [spinner,setSpinner] = useState(false);

    const {
        logDetailsModal,
        setLogDetailsModal,
        logFileName,
        // setLogFileName
    } = props;


    const actionsFormatter = (cell, row) => {
        return (
            <>
             <Container>
                       <Row>
                           <Col>
                           <FontAwesomeIcon
                           icon = {faDownload}
                           size = {'sm'}
                           style ={{'cursor':'pointer'}}
                           onClick = {
                               ()=>downloadLogFile()
                            }
                           />
                           </Col>

                          
                       </Row>
            </Container>

             
            
        </>

        )
        
    }

    const columns = [
       {
        dataField: 'fileName',
        text: 'File Name'
      }, 
       {
        // dataField: '',
        text: 'Status'
      }, 
      {
        text: 'Actions',
        formatter:actionsFormatter
      }];


      const items = [
        {
            'id':1,
            'fileName':logFileName

        }
    ]

    const toggle = () => { setLogDetailsModal(!logDetailsModal); }



    const downloadLogFile = () => {
        
        AxiosInter.get(`${API_ENDPOINT}/admin-api/get-import-app-user-log-file/${logFileName}`,{
            headers: {
               
              Authorization: API_TOKEN,
            },
            responseType: "blob"
          })
            .then((response) => { 
              if (response.status === 200) {
                var fileDownload = require("js-file-download");
                fileDownload(
                  response.data ,`${logFileName}`,
                  response["headers"]["x-suggested-filename"]
                );
                toastr.success(
                  "Success",
                  "Document downloaded successfully"
                );
                toggle();
              }
            })
            .catch((error) => {
                toastr.error(
                    "Failed",
                    "Something went wrong"
                  );
            });
        };
  

    // const uploadCSVFile = () => {   
    //     setSpinner(true);
        
    //     const FileFormData = new FormData();
    //     FileFormData.set("file", csvFile[0]);
    //     FileFormData.set("group_id_list",groupIds);

    //     AxiosInter.post(`${API_ENDPOINT}/admin-api/import-app-users/`, FileFormData, {
    //         headers: {
    //           Authorization: API_TOKEN,
    //         },
    //       })
    //       .then(function (response) {
    //         toastr.success(
    //           "Success",
    //           response.data.message
    //         );
    //         setSpinner(false);
    //         toggle();
    //         setAppUserReload(appUserReload + 1)
    //       })
    //       .catch(function (error) {
    //         toastr.error(
    //           "Error",
    //           error.response.data.message
    //         );
    //         setSpinner(false);
    
    //       });
    //   }


    useEffect(() => {
        if (!logDetailsModal) {
            setLogDetailsModal(false);
            
        };

    }, [logDetailsModal]) // eslint-disable-line


    

   

    return (
        <div>
            <Modal isOpen={logDetailsModal} size="lg" >
                <ModalHeader tag="h4" cssModule={{ 'modal-title': 'w-100 text-center' }} toggle={toggle}>Import Status</ModalHeader>
                {spinner ? <Spinner/>:""}
                <ModalBody className="overflow-modal">
                <BootstrapTable 
                keyField='id' 
                data={ items } 
                columns={ columns } />
                  
                </ModalBody>
                <ModalFooter className="custom-modal-footer">
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ImportAppUsersLogDetails;