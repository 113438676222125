import React, { useState , useEffect} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col,Label, FormGroup, Spinner } from 'reactstrap';
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';

const EditPermissionManagerModal = (props) => {
    const {
        rowData,
        editModal,
        setEditModal,
        OptionsOfMPermission,
        setReload,
        reload
    } = props;

    
    // LOCAL VARIABLES
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN=Cookie.get("access_token_admin");
    

    const [permission, setPermission] = useState("")
    const [spinner, setSpinner] = useState(false)

    
    let res = ""

    const [buttonBlankState,setButtonBlankState] = useState(true);


    const toggle = () => { setEditModal(!editModal); }
 
    const confirmation = () => { 

        if(!permission){
            toastr.error("Please select permission")
        }       
        if(permission){
            var array = []
            permission.map((item)=>{ // eslint-disable-line
                array.push([
                    item.value
                ])
            })

            res = array.toString()
            

            if(res){
                updatePermissionApiCall()
            }

        }
    
    };

    const updatePermissionApiCall = () => {
        setSpinner(true)
            AxiosInter.post(`${API_ENDPOINT}/admin-api/update-m-role-permission/`, {
                "role_id": rowData ? Number(rowData.roleID) : 0,
                "module_id": rowData ? Number(rowData.moduleID) : 0,
                "permission_list": res ? res : ""
            },{
                headers: {
                Authorization: API_TOKEN,
                },
            } )
                .then(function (response) {
                    setEditModal(!editModal)
                    setReload(reload+1)
                    toastr.success("Successfully Updated", "Permission's successfully updated")
                    setSpinner(false)
                })
                .catch(function (error) {
                    // console.log(error);
                    toastr.error("Update Failed", "Please try again")
                    setSpinner(false)
                });
        
    }

    const permissionsPopulate = () => {
        var array = []
        const data = rowData.permissionIDs.split(",")
        data.map((id) => {               // eslint-disable-line
            OptionsOfMPermission.map((item)=>{   // eslint-disable-line
                if(id.toString() === item.value.toString()){
                    array.push({
                        value: item.value,
                        label: item.label
                    })
                }
            })
        })
        setPermission(array)
    }

    const disableAddButton = () => {
        if (permission.length > 0) {
            setButtonBlankState(false)
        }
        else {
            setButtonBlankState(true)
        }
    }
  

    useEffect(() => { disableAddButton() }, [ permission]) // eslint-disable-line


    useEffect(() => { if (rowData) permissionsPopulate() }, [rowData])  // eslint-disable-line
    
   
     return (
        <div className="edit-module">
            
            <Modal style={{ width: 500}}  isOpen={editModal}   >
                <ModalHeader tag="h4" cssModule={{'modal-title': 'w-100 text-center'}}  toggle={toggle}>Editing - {rowData ? rowData.moduleName : "N/A"} - Module </ModalHeader>
                <ModalBody className="overflow-modal disable-scrollbars overflow-modal" >
                <div>
                  {spinner
                    ? <Spinner color='primary' />
                    : ""}
                </div>
                    <FormGroup>
                        <Row>
                            <Col>

                            <Label>Permission's <span className="required">*</span> </Label>
                                <Select
                                    value={permission}
                                    options={OptionsOfMPermission}
                                    onChange={(event) => setPermission(event)}
                                    placeholder="Select"
                                    isClearable={true}
                                    isMulti
                                />                    

                            </Col>
                        </Row>
                    </FormGroup>
                 </ModalBody>
                <ModalFooter className="advanced-search-button custom-modal-footer">
                    <Button 
                    className="yes-button button-width"  
                    onClick={confirmation}
                    title={buttonBlankState ? "Fill the required fields" : ""}
                    disabled = {buttonBlankState}
                    >Update</Button>{' '}
                    <Button outline className="no-button button-width" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditPermissionManagerModal;