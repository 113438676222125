import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHospital,
  faEdit,
  faAmbulance,
  faUsers,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import NewReferralCategoryConfigurationModal from "../../newComponents/utilities/NewReferralCategoryConfigurationModal";
import AxiosInter from "./../../AxiosInter.js";
import Cookie from "js-cookie";
import EditReferralCategoryConfigurationModal from "../../newComponents/utilities/EditReferralCategoryConfigurationModal";


const ReferralCategoryConfiguration = (props) => {
  const activeTab = props.activeTab

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const [searchKey, setSearchKey] = useState("");
  const [numberOfRecord, setNumberOfRecord] = useState(0);
  const [apiLoaded, setApiLoaded] = useState(true);
  const [editableRowId, setEditableRowId] = useState(null);

  // super admin can only able to edit data
  const isSuperAdmin = localStorage.getItem("superuser_check");

  // modal for add new
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  // modal for edit
  const [isEditModal, setIsEditModal] = useState(false);
  const toggleEditModal = () => {
    setIsEditModal(!isEditModal);
  };



  // to display all referral category configuration
  // backend call to get all data
  const getAllCategoryConfiguration = () => {
    const params = {
      orderCatID: "",
      orderCategory: "",
      is_pagination: "",
      record_limit: "",
      off_set: "",
      searchKeyword: "",
      sortOrder: "",
      sortColumn: "",
    };
    AxiosInter.post(`${API_ENDPOINT}/admin-api/referral-categories/`, params, {
      headers: {
        Authorization: API_TOKEN,
      },
    })
      .then(function (response) {
        setNumberOfRecord(response?.data?.record_count);
        setApiLoaded(false);
        if (response.data.data.length > 0) {
          setReferralCategoryConfigurationData(response.data.data);
        } else {
          setReferralCategoryConfigurationData([]);
        }
      })
      // .catch(function (error) {
      //   console.log(error);
      //   setReferralCategoryConfigurationData([]);
      // });
  };

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (apiLoaded && activeTab == 12) {
      getAllCategoryConfiguration();
    } // eslint-disable-next-line eqeqeq
    if (searchKey.length === 0 && activeTab == 12) {
      getAllCategoryConfiguration();
    }
  }, [apiLoaded, editableRowId, searchKey, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Store referral order category data from api call
  const [
    ReferralCategoryConfigurationData,
    setReferralCategoryConfigurationData,
  ] = useState([]);

  // Define your table data
  // const data = ReferralCategoryConfigurationData;

  // Define the table columns
  const column = [
    // {
    //    dataField: "catIcons",
    //    text: "Icon",
    //    headerStyle: { width: '60px' }, style: { width: '60px', textAlign: 'center' },
    //    // formatter is used for dynamically render an custom element in react-bootstrap-table
    //    formatter: customIconFormatter,
    // },
    {
      dataField: "categories",
      text: "Category",
      headerStyle: { width: "130px" },
      style: { width: "130px" },
      // sort: true,
      // sortCaret: (order, column) => {
      //    console.log(order);
      //    console.log(column);
      //    if (!order) return <span>&nbsp;&nbsp;&#9660;</span>; // default caret for no sort
      //    else if (order === 'asc') return <span>&nbsp;&nbsp;&#9650;</span>; // caret for ascending sort
      //    else if (order === 'desc') return <span>&nbsp;&nbsp;&#9660;</span>; // caret for descending sort
      //    return null;
      // },
    },
    {
      dataField: "viewType",
      text: "View Type",
      headerStyle: { width: "130px" },
      style: { width: "130px" },
    },
    {
      dataField: "viewSection",
      text: "View Sections",
      // formatter is used for dynamically render an custom element in react-bootstrap-table
      formatter: customViewSectionFormatter,
    },
    {
      dataField: "senderallowedStatus",
      text: "Sender Allowed Status",
      formatter: customSenderAllowedStatusFormatter,
    },
    {
      dataField: "fulfillerallowedStatus",
      text: "Fulfiller Allowed Status",
      formatter: customFullfillerAllowedStatusFormatter,
    },
    {
      dataField: "allowedPriority",
      text: "Allowed Priority",
      formatter: customAllowedPriorityFormatter,
    },
    {
      dataField: "ordercatId",
      text: "Actions",
      formatter: customEditFormatter,
      headerStyle: { width: "100px" },
      style: { width: "100px" },
    },
  ];

  // custom elements for view_sections column
  // This function return <span> element with inside data dynamically from array data
  function customViewSectionFormatter(cell, row) {
    if (cell === null) {
      return <>null</>;
    } else {
      // cell contains string
      // converting string to array of items
      const viewSectionArray = cell.split(", ");
      const cellLength = viewSectionArray.length - 1;
      return (
        <>
          {viewSectionArray.map((eachItem, index) => (
            <span key={index}>
              {eachItem}
              {/* last item not need comma */}
              {cellLength === index ? "" : ", "}
            </span>
          ))}
        </>
      );
    }
  }

  // custom elements for sender_allowed_status column from array data
  function customSenderAllowedStatusFormatter(cell, row) {
    if (cell) {
      const senderAllowedStatusArray = cell.split(",");
      const cellLength = senderAllowedStatusArray.length - 1;
      return (
        <>
          {senderAllowedStatusArray.map((eachItem, index) => (
            <span key={index}>
              {eachItem}
              {/* last item not need comma */}
              {cellLength === index ? "" : ", "}
            </span>
          ))}
        </>
      );
    } else return null;
  }

  // custom elements for fulfiller_allowed_status column from array data
  function customFullfillerAllowedStatusFormatter(cell, row) {
    if (cell) {
      const fullfillerAllowedStatusArray = cell.split(",");
      const cellLength = fullfillerAllowedStatusArray.length - 1;

      return (
        <>
          {fullfillerAllowedStatusArray.map((eachItem, index) => (
            <span key={index}>
              {eachItem}
              {/* last item not need comma */}
              {cellLength === index ? "" : ", "}
            </span>
          ))}
        </>
      );
    } else return null;
  }

  // custom elements for icon column from string
  // here we get icon code in string format
    // eslint-disable-next-line no-unused-vars
  function customIconFormatter(cell, row) {
    if (cell) {
      const isFaHospital = cell.includes("fa fa-hospital-o");
      const isFaAmbulance = cell.includes("fa fa-ambulance");
      const isFaUsers = cell.includes("fa fa-users");

      return (
        // showing icon using ternary operator
        // if isFaHospital == true the show faHospital icon
        // else isFaAmbulance == true  show faAmbulance icon
        // else isFaUsers == true  show faUsers icon
        // else show nothing
        <FontAwesomeIcon
          icon={
            isFaHospital
              ? faHospital
              : isFaAmbulance
              ? faAmbulance
              : isFaUsers
              ? faUsers
              : ""
          }
          className="align-middle"
          style={{ fontSize: "18px" }}
        />
      );
    } else return null;
  }

  function customAllowedPriorityFormatter(cell, row) {
    if (cell) {
      const allowedPriorityArray = JSON.parse(`[${cell}]`);
      return (
        <>
          {allowedPriorityArray.map((eachItem, index) => (
            <>
              <div
                key={index}
                title={`description: ${eachItem.description} \n intervalSpecifiedIn: ${eachItem.intervalSpecifiedIn}`}
                style={{ cursor: "pointer" }}
              >
                {eachItem.priority}
              </div>
            </>
          ))}
        </>
      );
    } else return null;
  }

  function customEditFormatter(cell, row) {
    return (
      <>
        <button
          type="button"
          title="Edit referral category"
          className="money-button btn btn-outline-primary"
          disabled={!isSuperAdmin}
          onClick={() => onEditButtonClick(cell)}
        >
          <FontAwesomeIcon
            icon={faEdit}
            fixedWidth
            style={{ cursor: "pointer" }}
            className="align-middle"
            title="Edit referral category"
          />
        </button>
      </>
    );
  }

  // if no data in table then show blankState
  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );

  const options = {
    page: 1, //default showing page on first render
    nextPageText: ">",
    prePageText: "<",
    sizePerPage: 50,
    sizePerPageList: [50, 100, 150, 200],
    totalSize: 50,
  };

  const onEditButtonClick = (cell) => {
    // cell contain id of row
    setEditableRowId(cell);

    // setting to true will show modal for edit
    setIsEditModal(true);
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value;
    setSearchKey(searchQuery);
    const regex = new RegExp(searchQuery, "i");
    setReferralCategoryConfigurationData(
      ReferralCategoryConfigurationData.filter((eachObj) =>
        regex.test(eachObj.categories)
      )
    );
    setNumberOfRecord(ReferralCategoryConfigurationData.length);
  };

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={ReferralCategoryConfigurationData}
        columns={column}
        search
      >
        {(props) => (
          <div>
            <Card>
              <CardHeader>
                <div className="separator" />
                <Row>
                  <Col xs={2}>
                    <div className="number-of-records">
                      <p>
                        Number of records: <strong>{numberOfRecord}</strong>
                      </p>
                    </div>
                  </Col>
                  <Col>
                    <div className="form-inline justify-content-end">
                      <div className="form-group mr-2">
                        <Input
                          className="search-box-new"
                          type="text"
                          value={searchKey}
                          onChange={handleSearch}
                          placeholder="Search"
                        />
                      </div>
                      <button
                        type="button"
                        className="clear-search-box btn btn-secondary"
                        onClick={() => setSearchKey("")}
                      >
                        Clear
                      </button>
                    </div>
                  </Col>
                  <Button
                    className="referral-add-btn ml-2 pull-right"
                    onClick={() => setIsOpen(true)}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      fixedWidth
                      className="align-middle"
                      title="See Fulfillers"
                    />
                    Add Category
                  </Button>
                </Row>
                <div className="separator" />
              </CardHeader>
              {ReferralCategoryConfigurationData.length > 0 ? (
                <CardBody>
                  <div className="scrollTable">
                    <BootstrapTable
                      bootstrap4
                      {...props.baseProps}
                      keyField="id"
                      data={ReferralCategoryConfigurationData}
                      columns={column}
                      pagination={paginationFactory(options)}
                    />
                  </div>
                </CardBody>
              ) : (
                blankState
              )}
            </Card>
          </div>
        )}
      </ToolkitProvider>
      {isOpen && (
        <NewReferralCategoryConfigurationModal
          isOpen={isOpen}
          toggleModal={toggleModal}
          setApiLoaded={setApiLoaded}
          setIsOpen={setIsOpen}
        />
      )}
      {isEditModal && (
        <EditReferralCategoryConfigurationModal
          editableRowId={editableRowId}
          isEditModal={isEditModal}
          setIsEditModal={setIsEditModal}
          toggleEditModal={toggleEditModal}
          data={ReferralCategoryConfigurationData}
          setApiLoaded={setApiLoaded}
        />
      )}
    </>
  );
};

export default ReferralCategoryConfiguration;
