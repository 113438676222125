import React, { useState, useEffect } from 'react'
import { Breadcrumb, BreadcrumbItem, Card, Container, Button, Row, Col, CardBody, CardTitle, Alert, Spinner } from 'reactstrap'
import Header from '../../components/Header'
import HeaderTitle from '../../components/HeaderTitle'
import { Link } from 'react-router-dom'
import { Hux } from '../../hoc/Hux'
import BootstrapTable from "react-bootstrap-table-next";
import Chip from "@mui/material/Chip";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AxiosInter from './../../AxiosInter.js';
import Cookie from 'js-cookie';
import { toastr } from 'react-redux-toastr'
import AddModuleSubscriptionModal from '../../newComponents/utilities/AddModuleSubscriptionModal.js'
import EditModuleSubscriptionModal from '../../newComponents/utilities/EditModuleSubscriptionModal.js'
import DeleteModuleSubscriptionModal from '../../newComponents/utilities/DeleteModuleSubscriptionModal.js'

const ModuleSubscriptions = () => {

    const [APIData, setAPIData] = useState("");
    const [spinner, setSpinner] = useState(false);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const API_TOKEN = Cookie.get("access_token_admin");
    const [addModule, setAddModule] = useState(false);
    const [editModule, setEditModule] = useState(false);
    const [rowData, setRowData] = useState("");
    const [deleteModule, setDeleteModule] = useState(false);
    const [rowId, setRowId] = useState()
    const [selectedModule, setSelectedModule] = useState()
    const superuser_check = localStorage.getItem("superuser_check")
    const formatter = (cell) => {

        var valuesForDD = [];
        if (!cell || cell.trim() === "") {
            valuesForDD.push(""); // Add an empty string to valuesForDD
        } else {
            // Proceed with splitting the cell data
            var cellData = cell.split(",");

            cellData.map((item) => {   // eslint-disable-line
                valuesForDD.push(item.trim()); // Trim whitespace from each item
            });
        }

        return (
            <>
                {valuesForDD.map((item, index) => (
                    <Chip
                        key={index}
                        color="primary"
                        variant='outlined'
                        style={{ marginBottom: 14, marginRight: 8 }}
                        label={item || <>...</>} />
                ))}
            </>
        );
    };


    const actionFormatter = (cell, row) => {
        return (
                <div>
                    {row?.rowIDs ? (
                        <>
                            <Button
                                className="money-button"
                                outline
                                onClick={() => {
                                    setRowData(row);
                                    setEditModule(true);
                                }}
                                color="primary"
                                title="Edit Medication"
                            >
                                <FontAwesomeIcon
                                    icon={faEdit}
                                    fixedWidth
                                    className="align-middle"
                                    title="Edit Medication"
                                />
                            </Button>
                        {superuser_check === "true" ?
                            <Button
                                className="money-button"
                                outline
                                onClick={() => {
                                    setRowData(row);
                                    setRowId(row.rowIDs)
                                    setDeleteModule(true)
                                }}
                                color="primary"
                                title="Delete Medication"
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    color={'#E12C2C'}
                                    fixedWidth
                                    className="align-middle"
                                    title="Delete Medication"
                                />
                            </Button> : ""}
                        </>

                    ) : ""}

                </div>
        );
    };

    const tableColumns = [
        {
            dataField: "subscriptionPLan",
            text: "Subscriptions",
            sort: true,
            headerStyle: { width: '300px' },
            style: { width: '300px' },
        },
        {
            dataField: "modules",
            text: "Modules",
            formatter: formatter,
        },
        {
            text: "Actions",
            formatter: actionFormatter,
            headerStyle: { width: '250px' },
            style: { width: '250px' },
        },
    ];

    const subscriptionModules = () => {
        setSpinner(true);
        AxiosInter
            .post(`${API_ENDPOINT}/admin-api/list-subscription-modules/`, {
                headers: {
                    Authorization: API_TOKEN,
                },
            })
            .then((res) => {
                setAPIData(res?.data);
                setSpinner(false);
            })
            .catch((error) => {
                // console.log(error);
                setSpinner(false);
            });
    };

    useEffect(() => {
        if (!APIData) subscriptionModules();
    }, [APIData]);  // eslint-disable-line

    const deleteModuleSubscription = () => {
        setSpinner(true)
        const rowIDArray = rowId.split(',').map(Number);
        const params = {
            row_id: rowId ? rowIDArray : '',
        }
        AxiosInter.post(`${API_ENDPOINT}/admin-api/delete-subscription-modules/`, params, {
            headers: {
                Authorization: API_TOKEN,
            },
        })
            .then(response => {
                toastr.success("Success", "Deleted successfully")
                setDeleteModule(false);
                subscriptionModules()
                setSpinner(false)
                setSelectedModule("")
            })
            .catch(error => {
                toastr.error("Error", "Something went wrong")
            });
    }

    return (
        <div>
            <Hux>
                <Container fluid>
                    <Header>
                        <HeaderTitle>Subscription Configuration</HeaderTitle>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to="/dashboard">Dashboard</Link>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>Subscription Configuration</BreadcrumbItem>
                        </Breadcrumb>
                        <Button
                            className="Add-btn-permission-manager pull-right top-0 right-0"
                            onClick={() => {
                                setAddModule(true);
                            }}
                        >
                            Add Module
                        </Button>
                    </Header>
                </Container>
            </Hux>
            <Row>
                <Col xs={12}>
                    <Card className="roles-list-card">
                        <CardBody>
                            {spinner ? (<Spinner />) : (
                                <CardTitle>
                                    <div className="div-alert">
                                        <Alert className="alert-success">
                                            <h3>Subscription Configuration</h3>
                                        </Alert>
                                    </div>
                                    <BootstrapTable
                                        bootstrap4
                                        keyField="id"
                                        data={APIData}
                                        className="table"
                                        columns={tableColumns}
                                        bordered={false}
                                    />
                                </CardTitle>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <AddModuleSubscriptionModal
                setAddModule={setAddModule}
                addModule={addModule}
                subscriptionModules={subscriptionModules}
                setSelectedModule={setSelectedModule}
                selectedModule={selectedModule}
            />
            <EditModuleSubscriptionModal
                setEditModule={setEditModule}
                editModule={editModule}
                rowData={rowData}
                setRowData={setRowData}
                subscriptionModules={subscriptionModules}
            />
            <DeleteModuleSubscriptionModal
                deleteModule={deleteModule}
                setDeleteModule={setDeleteModule}
                deleteModuleSubscription={deleteModuleSubscription}
                spinner={spinner}
                setRowData={setRowData}
            />
        </div>
    )
}

export default ModuleSubscriptions