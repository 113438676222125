import React, { useContext } from "react";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { Col, Row, Button, Label} from "reactstrap";
import { GlobalState } from "../../contextAPI/Context";
import AsyncSelect from "react-select/async";
import Cookie from 'js-cookie';
import AxiosInter from './../../AxiosInter.js';

const FacilityAssignationTagFilters = (props) => {

//   // CONTEXT VARIABLES

  const { spinner } = useContext(GlobalState);

//   // LOCAL VARIABLES

  const {
    facilityAssignationTagGroupName,
    setFacilityAssignationTagGroupName,
    facilityAssignationTagFacilityName,
    setFacilityAssignationTagFacilityName,
    facilityAssignationTagAppUser_Name,
    setFacilityAssignationTagAppUser_Name,
    setReloadForFacilityAssignationTag,
    reloadForFacilityAssignationTag,
    
  } = props;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const API_TOKEN=Cookie.get("access_token_admin");
  const runSearch = () => {
    if (
      facilityAssignationTagGroupName ||
      facilityAssignationTagFacilityName ||
      facilityAssignationTagAppUser_Name
    ) {
      setReloadForFacilityAssignationTag(reloadForFacilityAssignationTag + 1);
    }
  };

  const resetFields = () => {
    setFacilityAssignationTagGroupName("");
    setFacilityAssignationTagFacilityName("");
    setFacilityAssignationTagAppUser_Name("");
    setReloadForFacilityAssignationTag(reloadForFacilityAssignationTag + 1);
  };


  const groupNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
      return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-group-name-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };


  const facilityNameloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams ({name: inputValue});
        return AxiosInter.get(`${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  const appUserloadOptions = (inputValue) => {
    if (inputValue.length >= 4) {
      const searchParams = new URLSearchParams({ name: inputValue, groupID: facilityAssignationTagGroupName.value });

      return AxiosInter.get(`${API_ENDPOINT}/admin-api/get-provider-data-assignation/?${searchParams.toString()}`,
        {
          headers: {
            Authorization: API_TOKEN,
          },
        }
      )
        .then((response) => response.data.data)
        .catch((error) => {
          throw error;
        });
    }
  };

  // const totalOrderCountloadOptions = (inputValue) => {
  //   if (inputValue.length > 1) {
  //     const searchParams = new URLSearchParams ({name: inputValue});
  //       const url =  `${API_ENDPOINT}/admin-api/fetch-facility-names-list/?${searchParams.toString()}`;
  //         var temp = fetch(url,
  //       {
  //         headers: {
  //           Authorization: API_TOKEN,
  //         },
  //       }
  //     )
  //       .then((response) => response.json())
  //       .then((data) => data.data);
  //     return temp;
  //   }
  // };

  return (
    <AvForm>
    <AvGroup>  
      <Row>
      <Col sm="4">
        <Label>Group Name</Label>
        <AsyncSelect
          cacheOptions
          isClearable
          isSearchable
          type="text"
          name="groupName"
          placeholder="Search with at least 4 characters."
          value={facilityAssignationTagGroupName}
          getOptionLabel={(e) => e.label}
          getOptionValue={(e) => e.value}
          validate={{
            maxLength: { value: 120 },
          }}
          onChange={(e) => {
            setFacilityAssignationTagGroupName(e);
          }}
          loadOptions={(inputValue) => groupNameloadOptions(inputValue)}
          />
      </Col>
      <Col sm="3">
        <Label>Facility Name</Label>
        <AsyncSelect
          cacheOptions
          isClearable
          isSearchable
          type="text"
          name="facilityName"
          placeholder="Search with at least 4 characters."
          value={facilityAssignationTagFacilityName}
          getOptionLabel={(e) => e.label}
          getOptionValue={(e) => e.value}
          validate={{
            maxLength: { value: 120 },
          }}
          onChange={(e) => {
            setFacilityAssignationTagFacilityName(e);
          }}
          loadOptions={(inputValue) => facilityNameloadOptions(inputValue)}
        />
      </Col>
      <Col sm="3">
        <Label>App User</Label>
        <AsyncSelect
          cacheOptions
          isClearable
          isSearchable
          type="text"
          name="app_username"
          placeholder="Search with at least 4 characters."
          value={facilityAssignationTagAppUser_Name}
          getOptionLabel={(e) => e.label}
          getOptionValue={(e) => e.value}
          validate={{
            maxLength: { value: 120 },
          }}
          onChange={(e) => {
            setFacilityAssignationTagAppUser_Name(e);
          }}
          loadOptions={(inputValue) => appUserloadOptions(inputValue)}
        />
      </Col>
      <Col>
        <Button
          type="submit"
          className="go-buttons"
          disabled={spinner ? true : false}
          onClick={(props) => {
            runSearch();
          }}>
          Go
        </Button>
        <Button
          type="reset"
          className="reset-buttons"
          disabled={spinner ? true : false}
          onClick={() => {
            resetFields();
          }}>
          Reset
        </Button>
      </Col>
      </Row>
    </AvGroup>
    </AvForm>
  );

};

export default FacilityAssignationTagFilters;
