import React, { useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button
} from "reactstrap";

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";




const RemotepaginationICDCode = ({ onTableChange,exportTableData,data,columns, page, sizePerPage, totalSize,  newReferralOrderTypeModal,
  setNewEditReferralOrderTypeModal,
  newOrderTypeBox,
  setNewOrderTypeBox }) => {
  const MyExportCSV = (props) => {
    const handleClick = () => { // eslint-disable-line
      exportTableData();
    };
    return (
      <>
       
      { newOrderTypeBox &&  <Button
                        className="referral-assignee-tag-add-btn ml-2 pull-right"
                        onClick={() => {
                            setNewEditReferralOrderTypeModal(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          fixedWidth
                          className="align-middle"
                          title="See Fulfillers"
                        />Referral Order Types</Button>}
      </>
    );
  };



  const blankState = (
    <Card className="blank-state">
      <CardBody>
        <h3>No records available</h3>
      </CardBody>
    </Card>
  );
  useEffect(()=>{
    // console.log("totannl",totalSize)
  },[totalSize])

  useEffect(()=>{
    // console.log("dmmmata",data)
  },[data])
  return (
    <div>
     
      <Card>
        {/* <ToolkitProvider
          keyField="id"
          data={data ? data:""}
          columns={columns}
          search
          exportCSV={{
            fileName: `Equipo-${FileExportName}-Export-${moment().format(MomentConfig.MOMENT_FORMAT_DATEANDTIME)}.csv`
          }}
        > */}
          
          {/* {(props) => ( */}
            <div className="table-position">
              <CardHeader className="table-header-position">

                <Row>
                    <Col sm={12}>
                    <MyExportCSV className="mt-8" />
                    </Col>
                      
                 
              </Row>
              </CardHeader>
              <CardBody className="patient-list dropdown-menu-up">
              {data ?
                <BootstrapTable
                  // {...props.baseProps}
                  remote
                  bootstrap4
                  hover
                  bordered={false}
                  keyField="id"
                  data={data}
                  columns={columns}
                  //  noDataIndication={indication(isDataEmpty)}
                 

                  pagination={paginationFactory({
                    page: page,
                    sizePerPage: sizePerPage,
                    sizePerPageList: [50,100,150,200],
                    totalSize
                    
                  })}
                  onTableChange={ onTableChange }
                /> :blankState}
              </CardBody>
            </div>
          {/* //  )}
        </ToolkitProvider> */}
      </Card>
    
    </div>
  );



};



export default RemotepaginationICDCode;
